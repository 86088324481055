import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { NewSupplementaryFormStep } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/_index";
import { useNewSupplementaryDialogStore } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/store";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import {
  getInitialDataNewSupplementary,
  postNewSupplementary,
} from "@app/products/property/supplementary-rates/components/form-elements/new-supplementary/api";
import {
  DTO_Create_Supplementary_LOVs_Response,
  DTO_WorkflowDetail_New_Supplementary,
  DTO_Workflow_New_Supplementary,
  EKeysOfSteps,
  INewSuplementaryFormStep,
  IWorkflowNewSupplementary,
  keysOfSendSteps,
} from "@app/products/property/supplementary-rates/components/form-elements/new-supplementary/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface INewSupplementaryDialog {
  onClose: () => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
  draftId?: number;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  isReadOnly?: boolean;
  isToBeApprovalMode?: boolean;
  isShowCancelWorkflowButton?: boolean;
  componentId?: number;
  statusBadge?: IBadgeDialog[];
  prefixTitle?: string;
  suffixTitle?: string;
}

export const NewSupplementaryDialog = observer(
  ({
    onClose,
    isFromActionList = false,
    isIncompleteMode = false,
    dataFromActionList,
    isSaveOnNextStep = false,
    isReadOnly = false,
    isToBeApprovalMode = false,
    isShowCancelWorkflowButton = false,
    componentId,
    statusBadge,
    prefixTitle,
    suffixTitle,
  }: INewSupplementaryDialog) => {
    const [newSupplementaryDraft, setNewSupplementaryDraft] =
      useState<DTO_Workflow_New_Supplementary>();
    const { currentFormTitle } = useCommonProductStore();
    const { setCreateSupplementaryLOVs } = useNewSupplementaryDialogStore();
    const { dataForCancelDialog, setDataForCancelDialog } =
      useConfirmCancelDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { pushNotification } = useCCAppNotificationStore();
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [isLoadingFinish, setIsLoadingFinish] = useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);
    const [finishConfirmDialogData, setFinishConfirmDialogData] =
      useState<any>();

    const steps: IStep[] = [
      {
        label: "New supplementary",
        component: NewSupplementaryFormStep,
        options: {
          dataForCancelDialog: dataForCancelDialog,
          isReadOnly,
        },
        visible: true,
        key: EKeysOfSteps.NewSupplementary,
      },
      {
        label: "Comments",
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfSteps.Comments,
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Supplementary,
        },
        customClassName: "cc-comment-step-fixed-height-grid",
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        visible: true,
        key: EKeysOfSteps.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.New_Supplementary,
        },
      },
    ];

    const handleSubmit = async (
      events: INewSuplementaryFormStep,
      buttonId?: string
    ) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          setFinishConfirmDialogData(events);
          break;
        case EListSubmitButton.Cancel:
          handleCancelButton(processData(events));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.ConfirmCloseYes:
        case EListSubmitButton.Close:
          await sendParkSupplementary(processData(events));
          break;
        default:
          break;
      }
    };

    const handleCancelButton = (data: DTO_Workflow_New_Supplementary) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postNewSupplementary,
          dataCancel: data,
          defaultSuccessMessage:
            "The new supplementary was cancelled successfully",
          defaultErrorMessage: "New supplementary cancel failed",
        });
      } else {
        onClose();
      }
    };

    const sendParkSupplementary = async (
      payload: DTO_Workflow_New_Supplementary
    ) => {
      setIsLoadingPark(true);
      setIsLoadingClose(true);
      const defaultSuccessMessage = "Supplementary parked successfully";
      const defaultFailedMessage = "Park supplementary failed";
      const response = await postNewSupplementary(
        WorkflowProcessMode.Park,
        payload
      );
      setIsLoadingClose(false);
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          onClose();
          pushNotification({
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response?.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      setIsLoadingPark(false);
      setDataForCloseDialog(undefined);
    };

    const sendFinishSupplementary = async (
      payload: DTO_Workflow_New_Supplementary
    ) => {
      const defaultSuccessMessage = "Supplementary added successfully";
      const defaultFailedMessage = "Add supplementary failed";

      const response = await postNewSupplementary(
        WorkflowProcessMode.Approve,
        payload
      );
      setIsLoadingFinish(false);

      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          onClose();
          eventEmitter.emit(CCGridEventType.RefreshOData);
          pushNotification({
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      setFinishConfirmDialogData(undefined);
    };

    const handleFinish = async () => {
      setIsLoadingFinish(true);
      await sendFinishSupplementary(processData(finishConfirmDialogData));
    };

    const processData = (data: INewSuplementaryFormStep) => {
      let workflowDetail: any = {};
      const sendSteps = pickBy(data, function (value, key) {
        if (keysOfSendSteps.includes(key as EKeysOfSteps)) {
          return { [key]: value };
        }
      });
      if (sendSteps) {
        for (const [key, value] of Object.entries(sendSteps)) {
          const dataStep = { ...value };
          if (key === EKeysOfSteps.NewSupplementary) {
            workflowDetail = {
              ...workflowDetail,
              ...sendSteps,
            };
          } else {
            workflowDetail[key as keyof DTO_WorkflowDetail_New_Supplementary] =
              dataStep;
          }
        }
      }

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    const handleNextButton = async (data: INewSuplementaryFormStep) => {
      setIsLoadingOnNext(true);
      let workflowDetail: any = {};
      const sendSteps = pickBy(data, function (value, key) {
        if (keysOfSendSteps.includes(key as EKeysOfSteps)) {
          return { [key]: value };
        }
      });
      if (sendSteps) {
        for (const [key, value] of Object.entries(sendSteps)) {
          const dataStep = { ...value };
          if (key === EKeysOfSteps.NewSupplementary) {
            workflowDetail = {
              ...workflowDetail,
              ...sendSteps,
            };
          } else {
            workflowDetail[key as keyof DTO_WorkflowDetail_New_Supplementary] =
              dataStep;
          }
        }
      }

      return sendSaveSupplementary({
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      });
    };

    const sendSaveSupplementary = async (
      payload: DTO_Workflow_New_Supplementary,
      isCloseDialog: boolean = false
    ) => {
      const response = await postNewSupplementary(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingOnNext(false);
      const defaultSuccessMessage = "Supplementary saved successfully";
      const defaultFailedMessage =
        "The create supplementary process could not be saved";

      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          if (isCloseDialog) {
            onClose();
            pushNotification({
              title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
              type: "success",
            });
          }
          if (isFirstSave) {
            setIsFirstSave(false);
            setWorkflowDraftId(response?.data?.ID ?? 0);
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID ?? 0,
              },
            });
          }
          return true;
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
          return false;
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        return false;
      }
    };

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    const getWorkflowData = async () => {
      const id = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);

      getInitialDataNewSupplementary(id).then((response) => {
        if (Array.isArray(response)) {
          const [lovsSupplementary, workflowData] = response;
          if (
            isSuccessResponse(response[0]) &&
            isSuccessResponse(response[1]) &&
            lovsSupplementary?.data &&
            workflowData?.data
          ) {
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            setCreateSupplementaryLOVs(
              lovsSupplementary.data?.dtoCreate_Supplementary_LOVs
            );
            let newData: IWorkflowNewSupplementary = {
              WorkflowDetail: {
                ...workflowData.data?.WorkflowDetail,
              },
              WorkflowHeader: workflowData.data?.WorkflowHeader,
            };
            setNewSupplementaryDraft(newData);

            if (workflowData.data?.WorkflowHeader) {
              setWorkflowHeader(workflowData.data?.WorkflowHeader);
              setWorkflowDraftId(
                workflowData.data?.WorkflowHeader?.WorkflowDraft
                  ?.Workflow_Draft_Id ?? 0
              );
            }
          } else {
            let responseError: APIResponse<
              | DTO_Create_Supplementary_LOVs_Response
              | DTO_Workflow_New_Supplementary
              | ResponsePacket
            > = response[0];
            if (!isSuccessResponse(response[1])) responseError = response[1];
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error:
                  (responseError.data as ResponsePacket)?.Errors ??
                  "Load workflow failed",
              },
            });
          }
        } else {
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: response?.status,
              error: "Load workflow failed",
            },
          });
        }
      });
    };

    const initData: INewSuplementaryFormStep = useMemo(() => {
      const { NewSupplementary } = {
        ...newSupplementaryDraft?.WorkflowDetail,
      };
      const initNewSuplementary = {
        ...NewSupplementary,
        TransferRemainingBalance: NewSupplementary?.BalanceTransferMethodId
          ? true
          : false,
        Date: NewSupplementary?.Date ?? new Date(), //Default Current Date
      };
      return {
        [EKeysOfSteps.NewSupplementary]: initNewSuplementary,
        [EKeysOfSteps.Comments]: {},
        [EKeysOfSteps.Documents]: {},
      };
      // eslint-disable-next-line
    }, [newSupplementaryDraft]);

    /**
     * process title dialog
     */
    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "Select Supplementary";
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, workflowHeader]);

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        listButtonId={listSubmitButton}
        onSubmit={handleSubmit}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        initialSteps={steps}
        initialValues={initData}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            titleHeader={titleHeader}
            badge={statusBadge}
            onClose={() => handleCloseDialog(renderProps)}
            bodyElement={renderProps.children}
            footerElement={
              <>
                <div className={"cc-dialog-footer-actions-right"}>
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={isLoadingPark ? "fas fa-spinner fa-spin" : ""}
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled || isLoadingPark
                      }
                    >
                      Park
                    </Button>
                  )}
                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Cancel}
                      disabled={
                        isLoadingFinish || isLoadingOnNext || isLoadingPark
                      }
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}
                  {isToBeApprovalMode || isReadOnly ? (
                    !renderProps.isLastStep && (
                      <Button
                        themeColor="primary"
                        id="cc-next-step-button"
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        className={"cc-dialog-button"}
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )
                  ) : (
                    <Button
                      themeColor="primary"
                      id={renderProps.nextButton.idButton}
                      disabled={
                        isLoadingOnNext || renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingOnNext
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
                {finishConfirmDialogData && (
                  <ConfirmDialog
                    title="Confirmation"
                    subMessage={
                      "The supplementary will be created based on the information provided. Are you sure you want to submit?"
                    }
                    isLoadingYes={isLoadingFinish}
                    onClosePopup={() => setFinishConfirmDialogData(undefined)}
                    onAsyncConfirm={handleFinish}
                  />
                )}
              </>
            }
          />
        )}
      />
    );
  }
);
