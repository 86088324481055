import { DTO_LOV, DTO_LOV_Number } from "@common/models/odataResponse";

export interface ILovDetailPIC {
  PICTypes: DTO_LOV_Number[];
  PICLandUses: DTO_LOV_Number[];
  Districts: DTO_LOV_Number[];
  GISType: DTO_LOV[];
}

export enum EPicDetailDialog {
  NewGISReference = 1,
  NewSwineBrand = 2,
}
export interface IPicDetailDialogProps {
  mode: EPicDetailDialog;
  data?: any;
}
export enum EPICDetailGridMode {
  GISReference = 1,
  SwineBrand = 2,
}
