import { apiCoreLinkList } from "@app/core/components/common/utils";
import {
  AnimalsLOVs,
  CoreLink,
  Registration,
  RegistrationHandlerRequest,
  RegistrationHandlerResponse,
  Svc_Registration,
  Svc_Registration_Workflow_DateFromDateTo,
  Svc_Registration_Workflow_Issue,
  Svc_Registration_Workflow_IssueRegistration,
  Svc_Registration_Workflow_ProvideReason,
} from "@app/products/animals/[id]/model";
import { getTotalRelatedAnimalRecordsUrl } from "@app/products/animals/[id]/util";
import { AnimalsApiUrl } from "@app/products/animals/config";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { LinkType } from "@common/constants/linkType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { Address_BuildAddress } from "@common/input-pickers/address/model";
import { IIdentityPacket } from "@common/models/identityPacket";
import { globalStoreInstance } from "@common/stores/global/store";

export type IGetAnimalById =
  | [
      APIResponse<IIdentityPacket<Svc_Registration> | undefined>,
      APIResponse<AnimalsLOVs>
    ]
  | APIResponse<IIdentityPacket<Svc_Registration> | undefined>
  | undefined;

export const getAnimalsInfo = async (id: number): Promise<IGetAnimalById> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<
        IIdentityPacket<Svc_Registration> | undefined
      >(
        `${
          globalStoreInstance.allSetting.baseAPIUrl
        }${AnimalsApiUrl.GET_ANIMAL.replace("{id}", id.toString())}`
      ),
      CoreAPIService.getClient().get<AnimalsLOVs>(
        `${globalStoreInstance.allSetting.baseAPIUrl}${AnimalsApiUrl.GET_ANIMAL_LOVS}`
      ),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAnimalLOVs = async (): Promise<
  APIResponse<IIdentityPacket<AnimalsLOVs> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<AnimalsLOVs> | undefined
    >(
      `${globalStoreInstance.allSetting.baseAPIUrl}${AnimalsApiUrl.GET_ANIMAL_LOVS}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAnimalById = async (
  id: number
): Promise<APIResponse<IIdentityPacket<Svc_Registration> | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<Svc_Registration> | undefined
    >(
      `${
        globalStoreInstance.allSetting.baseAPIUrl
      }${AnimalsApiUrl.GET_ANIMAL.replace("{id}", id.toString())}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postAnimal = async (
  requestBody: Registration
): Promise<APIResponse | undefined> => {
  try {
    return await CoreAPIService.getClient().post(
      `${
        globalStoreInstance.allSetting.baseAPIUrl
      }${AnimalsApiUrl.SAVE_ANIMAL.replace(
        "{id}",
        requestBody.Registration_ID.toString()
      )}`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const registrationHandler = async (
  register: RegistrationHandlerRequest
): Promise<
  APIResponse<IIdentityPacket<RegistrationHandlerResponse> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<RegistrationHandlerResponse>
    >("api/animals/internal/registration/form-handler", register);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const setupRegistrationPickSiteAddress = async (
  registration: Registration
): Promise<APIResponse<IIdentityPacket<Address_BuildAddress> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<Address_BuildAddress>
    >("api/animals/internal/registration/setup-pick-address", registration);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const checkMaxNoOfAnimals = async (
  registration: Registration
): Promise<APIResponse<string>> => {
  try {
    return await CoreAPIService.getClient().post<string>(
      "api/animals/internal/registration/check-max-no-of-animals",
      registration
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCoreLinkList = async (
  id: number,
  recordType: RECORDTYPE,
  linkType: LinkType
): Promise<APIResponse<CoreLink[]>> => {
  try {
    return await CoreAPIService.getClient().get<CoreLink[]>(
      apiCoreLinkList(id, recordType, linkType)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getTotalRelatedAnimalRecords = async (
  id: number,
  pid?: string
): Promise<APIResponse<number>> => {
  try {
    return await CoreAPIService.getClient().get<number>(
      getTotalRelatedAnimalRecordsUrl(id, pid)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

//Workflow
export const postWorkflowDeregister = async (
  requestBody: Svc_Registration_Workflow_ProvideReason
): Promise<APIResponse<IIdentityPacket<Registration> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/animals/internal/registration/deregister`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postWorkflowLostTag = async (
  requestBody: Svc_Registration_Workflow_ProvideReason
): Promise<APIResponse<IIdentityPacket<Registration> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/animals/internal/registration/lost-tag`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postWorkflowNewTagIssue = async (
  requestBody: Registration
): Promise<APIResponse<IIdentityPacket<Registration> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/animals/internal/registration/new-tag-issue`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postWorkflowRenew = async (
  requestBody: Svc_Registration_Workflow_DateFromDateTo
): Promise<APIResponse<IIdentityPacket<Registration> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/animals/internal/registration/renew`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postWorkflowDeceased = async (
  requestBody: Svc_Registration_Workflow_ProvideReason
): Promise<APIResponse<IIdentityPacket<Registration> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/animals/internal/registration/deceased`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postIssueRegistrationSetup = async (
  registration: Registration
): Promise<
  APIResponse<
    IIdentityPacket<Svc_Registration_Workflow_IssueRegistration> | undefined
  >
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/animals/internal/registration/issue-registration-setup`,
      registration
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postWorkflowIssue = async (
  registration: Registration
): Promise<
  APIResponse<IIdentityPacket<Svc_Registration_Workflow_Issue> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/animals/internal/registration/issue`,
      registration
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postWorkflowIssueRegistration = async (
  requestBody: Svc_Registration_Workflow_Issue
): Promise<APIResponse<IIdentityPacket<Registration> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/animals/internal/registration/issue-registration`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postWorkflowReactivate = async (
  requestBody: Svc_Registration_Workflow_ProvideReason
): Promise<APIResponse<IIdentityPacket<Registration> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/animals/internal/registration/reactivate`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postUpdateRegistration = async (
  requestBody: Svc_Registration_Workflow_Issue
): Promise<APIResponse<IIdentityPacket<Registration> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/animals/internal/registration/tool-update-registration`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
