import { getViewConfigurations } from "@app/products/property/api";
import { ChangeOfOwnerShipButton } from "@app/products/property/assessments/components/action-bar/buttons/change-of-ownership/_index";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { PropertyPicContactsTab } from "@app/products/property/pic/[id]/components/reference-sidebar/contact/_index";
import { PropertyPicDetailTab } from "@app/products/property/pic/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_PIC_ROUTE } from "@app/products/property/pic/[id]/constant";
import { activePICListFilterState } from "@app/products/property/pic/active/config";
import { PROPERTY_PIC_ACTIVE_URL } from "@app/products/property/pic/active/constant";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
} from "@app/products/property/pic/active/util";
import { AmalgamatePICListButton } from "@app/products/property/pic/list/components/action-bar/buttons/amalgamate-pic/_index";
import { ModifyPICButton } from "@app/products/property/pic/list/components/action-bar/buttons/modify-pic/_index";
import { CreatePICButton } from "@app/products/property/pic/list/components/action-bar/buttons/new-pic/_index";
import { ReactivePICButton } from "@app/products/property/pic/list/components/action-bar/buttons/reactive-pic/_index";
import { colListPic } from "@app/products/property/pic/list/config";
import { VO_PIC } from "@app/products/property/pic/list/model";
import { picRoute } from "@app/products/property/pic/route";
import { getBookmarkListViewDisplayName } from "@app/products/property/pic/util";
import { propertyRoute } from "@app/products/property/route";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<VO_PIC>();
export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedPicCol, setProcessedPicCol] =
    useState<IColumnFields[]>(colListPic);

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [<PropertyActionBarNavDropdown category={picRoute.path} />],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <CCNavButton title={"PIC"} type="dropdown">
          <AmalgamatePICListButton />
          <CreatePICButton />
          <ModifyPICButton />
          <ReactivePICButton />
        </CCNavButton>
        <ChangeOfOwnerShipButton componentNumber={eComponent.PIC} />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      //TODO waiting confirm with customer
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_PIC_ROUTE}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_PIC}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <PropertyPicDetailTab /> },
      {
        title: "Map",
        component: <MapTab addressField={nameOf("PropertyAddress")} />,
      },
      {
        title: "Contacts",
        component: <PropertyPicContactsTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab recordType={RECORDTYPE.CommunityProperty_PIC} />
        ),
      },
    ],
  });
  const loadConfiguration = () => {
    setIsLoading(true);
    getViewConfigurations(ViewConfiguration.OData_PIC).then((response) => {
      if (isSuccessResponse(response)) {
        const viewConfig: ICCViewColumn[] | undefined =
          response?.data?.ColumnDefinitions?.Columns;
        if (!viewConfig || viewConfig?.length === 0) {
          setProcessedPicCol([]);
          setIsLoading(false);
          return;
        }
        setProcessedPicCol(processDynamicColumns(colListPic, viewConfig));
      } else {
        setResponseLoadError({
          status: response.status,
          error: response.error ?? "Load failed",
        });
      }
      setIsLoading(false);
    });
  };

  useEffectOnce(() => {
    (async () => {
      await loadConfiguration();
    })();
  });

  if (isLoading) return <Loading isLoading isFullScreen />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadConfiguration();
        }}
      />
    );
  return (
    <CCProductListView
      columnFields={processedPicCol}
      dataUrl={PROPERTY_PIC_ACTIVE_URL}
      primaryField={nameOf("PIC_Id")}
      state={{ filter: activePICListFilterState }}
    />
  );
};
