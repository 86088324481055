import { getChargeBalancesData } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/components/form-elements/charge-balance-adjustments/util";
import {
  colChargeBalances,
  colCharges,
  colInstalmentSummary,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/components/form-elements/charges/config";
import { DIGIT_AFTER_COMMA } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/components/form-elements/charges/constant";
import {
  getNewAssessmentChargeInstallments,
  getSumWithInstalmentNumberData,
  roundNumberWithDecimalPlaces,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/components/form-elements/charges/util";
import {
  DTO_AssessmentCharge,
  DTO_AssessmentChargeBalances,
  DTO_ChargeInstallments,
  EKeysOfStepsAdjustChargeBalances,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/model";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { FieldArray } from "@progress/kendo-react-form";
import { findIndex, isNil } from "lodash";
import React, { useCallback, useEffect } from "react";

const nameOfCharge = nameOfFactory<DTO_AssessmentCharge>();
const nameOfAssessmentChargeBalances =
  nameOfFactory<DTO_AssessmentChargeBalances>();
const nameOfChargeInstallments = nameOfFactory<DTO_ChargeInstallments>();
export const ChargesFormStep = (props: IFormStepElement) => {
  const assessmentChargeBalancesData = getChargeBalancesData(
    props.formRenderProps.valueGetter(
      EKeysOfStepsAdjustChargeBalances.AssessmentCharges
    )
  );
  const chargeValidator = useCallback(() => {
    return assessmentChargeBalancesData.length > 0 ? "" : "Invalid step";
  }, [assessmentChargeBalancesData.length]);
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? chargeValidator : undefined}
    />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = {
    isReadOnly: false,
  },
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const parentId = getFieldValue("chargeItemSelected")?.Charge_Id;

  const handleChangeChargeBalanceDataRow = (
    dataRow: DTO_AssessmentChargeBalances,
    fieldChange: string,
    valueChange?: any
  ) => {
    onChangeInstalmentSummary(
      dataRow,
      fieldChange,
      !isNil(valueChange) ? valueChange : 0
    );
  };

  const onChangeInstalmentSummary = (
    dataRowChange?: DTO_AssessmentChargeBalances,
    fieldChange?: string,
    valueChange?: any
  ) => {
    if (parentId) {
      const oldAssessmentChargeBalances =
        getFieldValue("chargeItemSelected")?.AssessmentChargeBalances ?? [];
      const oldAssessmentChargeInstallments =
        getFieldValue("chargeItemSelected")?.Charge_Installments ?? [];

      let newAssessmentChargeBalances = oldAssessmentChargeBalances;

      if (!isNil(dataRowChange) && !isNil(fieldChange) && !isNil(valueChange)) {
        newAssessmentChargeBalances = oldAssessmentChargeBalances.map(
          (item: DTO_AssessmentChargeBalances) => {
            if (item.Charge_Balances_Id === dataRowChange.Charge_Balances_Id) {
              // Formula: Current Balance/Interest + Amend Balance/Interest By = Amend Balance/Interest
              switch (fieldChange) {
                case nameOfAssessmentChargeBalances("CB_AmendedBalance"):
                  if (!isNil(dataRowChange?.CB_Balance)) {
                    item.CB_AmendBalanceBy = roundNumberWithDecimalPlaces(
                      dataRowChange.CB_AmendedBalance -
                        dataRowChange.CB_Balance,
                      DIGIT_AFTER_COMMA
                    );
                  }
                  break;
                case nameOfAssessmentChargeBalances("CB_AmendBalanceBy"):
                  if (!isNil(dataRowChange?.CB_Balance)) {
                    item.CB_AmendedBalance = roundNumberWithDecimalPlaces(
                      dataRowChange.CB_AmendBalanceBy +
                        dataRowChange.CB_Balance,
                      DIGIT_AFTER_COMMA
                    );
                  }
                  break;
                case nameOfAssessmentChargeBalances("CB_AmendedInterest"):
                  if (!isNil(dataRowChange?.CB_Interest)) {
                    item.CB_AmendInterestBy = roundNumberWithDecimalPlaces(
                      dataRowChange.CB_AmendedInterest -
                        dataRowChange.CB_Interest,
                      DIGIT_AFTER_COMMA
                    );
                  }
                  break;
                case nameOfAssessmentChargeBalances("CB_AmendInterestBy"):
                  if (!isNil(dataRowChange?.CB_Interest)) {
                    item.CB_AmendedInterest = roundNumberWithDecimalPlaces(
                      dataRowChange.CB_AmendInterestBy +
                        dataRowChange.CB_Interest,
                      DIGIT_AFTER_COMMA
                    );
                  }
                  break;
                default:
                  break;
              }
              return {
                ...item,
                [fieldChange]: valueChange,
              };
            }
            return item;
          }
        );
      }

      const sumWithInstalmentNumberData = getSumWithInstalmentNumberData(
        newAssessmentChargeBalances
      );

      const newAssessmentChargeInstallments =
        getNewAssessmentChargeInstallments(
          oldAssessmentChargeInstallments,
          sumWithInstalmentNumberData,
          fieldChange
        );
      const parentData = getFieldValue("") ?? [];
      const parentIndex = findIndex(parentData, {
        Charge_Id: parentId,
      });
      parentData.splice(parentIndex, 1, {
        ...parentData[parentIndex],
        AssessmentChargeBalances: newAssessmentChargeBalances,
        Charge_Installments: newAssessmentChargeInstallments,
      });
      onChange(nameOf("chargeItemSelected"), {
        value: parentData[parentIndex],
      });
    }
  };

  useEffect(() => {
    onChangeInstalmentSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  return (
    <section className="cc-field-group">
      <label className="cc-label">
        Charges - select the charge to be adjusted
      </label>
      <div className="cc-property-panel-charges">
        <CCGrid
          data={getFieldValue("")}
          columnFields={colCharges}
          primaryField={nameOfCharge("Charge_Id")}
          selectableMode={"single"}
          onSelectionChange={(dataItem: any[]) => {
            onChange(nameOf("chargeItemSelected"), {
              value: dataItem?.[0],
            });
          }}
        />
      </div>
      {getFieldValue("chargeItemSelected") ? (
        <>
          <div className="cc-field">
            <label className="cc-label">
              Charge Balance Adjustments - click in the amend fields to adjust
              the amounts
            </label>
            <CCGrid
              editableMode="cell"
              data={
                getFieldValue("chargeItemSelected")?.AssessmentChargeBalances ??
                []
              }
              columnFields={colChargeBalances}
              primaryField={nameOfAssessmentChargeBalances(
                "Charge_Balances_Id"
              )}
              onDataRowChange={handleChangeChargeBalanceDataRow}
              readOnly={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Instalment Summary</label>
            <CCGrid
              data={
                getFieldValue("chargeItemSelected")?.Charge_Installments ?? []
              }
              columnFields={colInstalmentSummary}
              primaryField={nameOfChargeInstallments("Installment_No")}
              readOnly={options?.isReadOnly}
            />
          </div>
        </>
      ) : null}
    </section>
  );
};
