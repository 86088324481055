import { useCertificateAdverseAffectionsStore } from "@app/products/property/certificates/[id]/components/child-screens/adverse-affections/store";
import { useCertificateStore } from "@app/products/property/certificates/[id]/store";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

export const CertificateAdverseAffections = observer(() => {
  const {
    isLoading,
    data,
    responseLoadError,
    colAdverseAffectionProcess,
    resetStore,
    loadViewsAndData,
  } = useCertificateAdverseAffectionsStore();

  const { certificateId } = useCertificateStore();

  useEffect(() => {
    loadViewsAndData(certificateId);
    return () => resetStore();
  }, [certificateId, loadViewsAndData, resetStore]);

  return (
    <>
      {isLoading ? (
        <Loading isLoading />
      ) : responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadViewsAndData(certificateId);
          }}
        />
      ) : (
        <CCGrid
          data={data ?? []}
          primaryField={"Id"}
          columnFields={colAdverseAffectionProcess}
        />
      )}
    </>
  );
});
