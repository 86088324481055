import { loadViewConfiguresColumns } from "@app/products/property/api";
import { fieldName } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/components/form-elements/names/config";
import { TitleDetail } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/components/form-elements/property/title-detail/_index";
import {
  DTO_Assessment,
  DTO_Entity_Details,
  DTO_PIC,
  DTO_Title,
  EKeysOfSteps,
} from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import { colPicLookup } from "@app/products/property/components/dialogs/add-pic-lookup/config";
import { ECustomColNameProperty } from "@app/products/property/config";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import Loading from "@components/loading/Loading";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { unionBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";
import { colAssessments, colTitles } from "./config";

const nameOfAssessment = nameOfFactory<DTO_Assessment>();
const nameOfTitle = nameOfFactory<DTO_Title>();
const nameOfPIC = nameOfFactory<DTO_PIC>();
export const PropertyFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const currentTitles = getFieldValue("Titles") ?? [];
    const currentAssessments = getFieldValue("Assessments") ?? [];

    //Get labels
    const [
      assessmentsLabel,
      titlesLabel,
      assessmentNumberLabel,
      assessmentIDLabel,
      titleIDLabel,
    ] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Assessments,
      ECustomColNameProperty.Titles,
      ECustomColNameProperty.AssessmentNumber,
      ECustomColNameProperty.AssessmentID,
      ECustomColNameProperty.Title_ID,
    ]);

    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const [processedPICCols, setProcessedPICCols] =
      useState<IColumnFields[]>(colPicLookup);
    const [isLoadingViewPic, setIsLoadingViewPic] = useState<boolean>(false);
    const currentPIC = getFieldValue("PICs") ?? [];

    const initAssociatedNames =
      getFieldValue(`_option.${fieldName.InitAssociatedNamesDetails}`) || [];
    const associatedNames =
      valueGetter(EKeysOfSteps.AssociatedNamesDetails) || [];
    const currentNames = valueGetter(EKeysOfSteps.NamesGridData) || [];
    const selectedType = valueGetter(`${EKeysOfSteps.Type}._option.Type`);
    const isPreventReloadUseEffect = valueGetter(
      `${EKeysOfSteps.Details}._option.IsPreventReloadUseEffect`
    );

    useEffectOnce(() => {
      if (isPreventReloadUseEffect) return;
      if (selectedType?.COOT_Retain_AssociatedNames) {
        handlePopulateAssociatedNames();
      }
      onChange(`${EKeysOfSteps.Details}._option.IsPreventReloadUseEffect`, {
        value: true,
      });
    });

    const handlePopulateAssociatedNames = () => {
      const newAssociatedNames = unionBy(
        associatedNames,
        initAssociatedNames,
        fieldName.PrimaryKey
      );
      onChange(EKeysOfSteps.AssociatedNamesDetails, {
        value: newAssociatedNames,
      });
      const newNames = unionBy(
        valueGetter(EKeysOfSteps.NamesGridData) || [],
        newAssociatedNames,
        fieldName.PrimaryKey
      );
      onChange(EKeysOfSteps.NamesGridData, {
        value: newNames,
      });
      onChange(`${EKeysOfSteps.Names}.${fieldName.isNeedToResetFormatted}`, {
        value: true,
      });
    };
    const handleRemoveAssociatedNames = () => {
      const initAssociatedIds = initAssociatedNames.map(
        (nameItem: DTO_Entity_Details) => nameItem.Entity_Id
      );
      const newAssociatedNames = associatedNames.filter(
        (nameItem: DTO_Entity_Details) =>
          !initAssociatedIds.includes(nameItem.Entity_Id)
      );
      onChange(EKeysOfSteps.AssociatedNamesDetails, {
        value: newAssociatedNames,
      });

      const newNames = currentNames.filter(
        (nameItem: DTO_Entity_Details) =>
          !initAssociatedIds.includes(nameItem.Entity_Id)
      );
      onChange(EKeysOfSteps.NamesGridData, {
        value: newNames,
      });
      onChange(`${EKeysOfSteps.Names}.${fieldName.isNeedToResetFormatted}`, {
        value: true,
      });
    };

    const loadViewConfigurationPIC = async () => {
      setIsLoadingViewPic(true);
      const response = await loadViewConfiguresColumns(
        ViewConfiguration.PIC_Lookup,
        colPicLookup
      );
      setIsLoadingViewPic(false);
      if (Array.isArray(response)) {
        setProcessedPICCols(response);
      } else {
        setResponseLoadError({
          status: response?.status,
          error: response?.error ?? "Load failed",
        });
      }
    };

    useEffectOnce(() => {
      loadViewConfigurationPIC();
    });

    //@TODO: Waiting for view config API
    const newAssessmentCols = useMemo(() => {
      return colAssessments.map((column: IColumnFields) => {
        switch (column.field) {
          case nameOfAssessment("Assessment_Number"):
            return {
              ...column,
              title: assessmentNumberLabel ?? column.title,
              id: `cc-grid-cell-${ECustomColNameProperty.AssessmentNumber}`,
            };

          case nameOfAssessment("Assessment_Id"):
            return {
              ...column,
              title: assessmentIDLabel ?? column.title,
              id: `cc-grid-cell-${ECustomColNameProperty.AssessmentID}`,
            };
          default:
            return column;
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //@TODO: Waiting for view config API
    const newTitleCols = useMemo(() => {
      return colTitles.map((column: IColumnFields) => {
        switch (column.field) {
          case nameOfTitle("Title_Id"):
            return {
              ...column,
              title: titleIDLabel ?? column.title,
              id: `cc-grid-cell-${ECustomColNameProperty.Title_ID}`,
            };
          case nameOfTitle("Assessment_Id"):
            return {
              ...column,
              title: assessmentIDLabel ?? column.title,
              id: `cc-grid-cell-${ECustomColNameProperty.AssessmentID}`,
            };
          default:
            return column;
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title={`${titlesLabel} for which change is to apply`} />
            <CCGrid
              className="cc-title-change-of-ownership"
              data={currentTitles}
              columnFields={newTitleCols}
              detail={TitleDetail}
              readOnly={options?.isReadOnly}
              primaryField={nameOfTitle("Id")}
            />
          </div>
          {selectedType?.COOT_Display_Retain_AssociatedNames && (
            <div className="cc-field">
              <CCLabel title="Retain associated names" />
              <Field
                name={nameOf("RetainAssociatedNames")}
                component={CCSwitch}
                checked={getFieldValue("RetainAssociatedNames")}
                disabled={options?.isReadOnly}
                onChange={(event: SwitchChangeEvent) => {
                  onChange(nameOf("RetainAssociatedNames"), {
                    value: event.value,
                  });
                  if (event.value) {
                    handlePopulateAssociatedNames();
                  } else {
                    handleRemoveAssociatedNames();
                  }
                }}
              />
            </div>
          )}
          <div className="cc-field">
            <CCLabel title={assessmentsLabel} />
            <CCGrid
              className="cc-assessment-change-of-ownership"
              data={currentAssessments ?? []}
              columnFields={newAssessmentCols}
              readOnly={options?.isReadOnly}
              primaryField={nameOfAssessment("Assessment_Id")}
            />
          </div>
          {isLoadingViewPic ? (
            <Loading isLoading />
          ) : responseLoadError ? (
            <CCLoadFailed
              responseError={responseLoadError}
              onReload={() => {
                loadViewConfigurationPIC();
              }}
            />
          ) : (
            <div className="cc-field">
              <CCLabel title="PICs" />
              <CCGrid
                className="cc-pic-change-of-ownership"
                data={currentPIC}
                columnFields={processedPICCols}
                readOnly={options?.isReadOnly}
                primaryField={nameOfPIC("PIC_Id")}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
