import {
  postViewOnMap,
  postViewOnMapInView,
} from "@app/core/view-on-map/components/buttons/view-map-by-gis/api";
import {
  Svc_ListView_ViewOnMap,
  Svc_ViewOnMap,
} from "@app/core/view-on-map/components/buttons/view-map-by-gis/model";
import { GIS } from "@app/products/town-planning/ppr/permit-referrals/components/action-bar/buttons/view-on-map/model";
import { APIResponse } from "@common/apis/model";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IIdentityPacket } from "@common/models/identityPacket";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IViewOnMapByGISButtonProps {
  objViewOnMapRequest?: Svc_ViewOnMap;
  recordType?: RECORDTYPE;
  isInManagePage?: boolean;
}

export const ViewOnMapByGISButton = observer(
  ({
    objViewOnMapRequest,
    recordType,
    isInManagePage = false,
  }: IViewOnMapByGISButtonProps) => {
    const { settings } = useCommonCoreStore();
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const { gridSelectedRows, gridSelectedIds } = useCCProductListViewStore();
    const oCorpGIS = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_GIS]
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const title =
      oCorpGIS && GIS[oCorpGIS]
        ? `View in ${GIS[oCorpGIS].replace(/([a-z])([A-Z])/g, "$1 $2")}`
        : "";

    const isShowButtonInManagePage =
      !isNil(oCorpGIS) &&
      ![GIS.None, GIS.Exponare, GIS.Latitude, GIS.MapInfo].includes(oCorpGIS) &&
      isInManagePage;

    const isShowButtonInViewPage =
      !isNil(oCorpGIS) &&
      ![
        GIS.None,
        GIS.Exponare,
        GIS.Latitude,
        GIS.MapInfo,
        GIS.GoogleMaps,
      ].includes(oCorpGIS);

    const handleOnClickInFormButton = async () => {
      if (isNil(objViewOnMapRequest)) return;
      setIsLoading(true);
      const response = await postViewOnMap(objViewOnMapRequest);
      setIsLoading(false);
      executeResponse(response);
    };

    const handleOnClickInViewButton = async () => {
      setIsLoading(true);
      const bodyRequest = {
        RecordIds: gridSelectedIds,
        RecordType: recordType,
      } as Svc_ListView_ViewOnMap;
      const response = await postViewOnMapInView(bodyRequest);
      setIsLoading(false);
      executeResponse(response);
    };

    const executeResponse = (
      response: APIResponse<IIdentityPacket<string> | undefined>
    ) => {
      if (isSuccessIdentityPacket(response) && response?.data?.ReturnObj) {
        if (
          [
            GIS.Weave,
            GIS.IntraMaps,
            GIS.Geocortex,
            GIS.Pozi,
            GIS.SpectrumSpatial,
            GIS.Dekho,
            GIS.GoogleMaps,
            GIS.Bing,
            GIS.ArcGIS,
          ].includes(oCorpGIS)
        ) {
          window.open(`${response.data.ReturnObj}`, "_blank");
        }
      } else {
        clearErrorNotification();
        pushNotification({
          title: "Load view map failed.",
          type: "error",
          autoClose: false,
          description: response.data?.Errors ?? response?.error,
        });
      }
    };

    return isShowButtonInManagePage ? (
      <CCNavButton
        isLoading={isLoading}
        title={title}
        onClick={handleOnClickInFormButton}
        disabled={isLoading}
      />
    ) : isShowButtonInViewPage ? (
      <CCNavButton
        isLoading={isLoading}
        title={title}
        onClick={handleOnClickInViewButton}
        disabled={!gridSelectedRows?.length || isLoading}
      />
    ) : null;
  }
);
