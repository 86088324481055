import { ExistedCRMSEvent } from "@app/products/crms/[id]/components/forms/existed/_index";
import { NewCRMSEvent } from "@app/products/crms/[id]/components/forms/new/_index";
import { IEventsParentSection } from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageCRMSEvent = observer(() => {
  const isNew = useIsNew();
  const { resetStore, setParentSection } = useCRMSEventStore();
  const { resetMenu } = useCCSubActionBarStore();
  const location = useLocation();
  const parentsState = location.state as IEventsParentSection;

  useEffect(() => {
    if (parentsState) {
      setParentSection(parentsState);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentsState]);

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
    };
  });

  if (isNew) {
    return <NewCRMSEvent />;
  }
  return <ExistedCRMSEvent />;
});

export default ManageCRMSEvent;
