import { useAnimalsSettingStore } from "@app/products/animals/system-admin/settings/_category/store";
import { DynamicGridFields } from "@app/products/animals/system-admin/settings/_id/components/dynamic-grid-fields/_index";
import { AnimalRenderSwitch } from "@app/products/animals/system-admin/settings/_id/components/switch/_index";
import { switchOptionsValueNumberString } from "@app/products/animals/system-admin/settings/_id/main/config";
import {
  ICommonFormProps,
  ISwitchCustomeFieldProps,
} from "@app/products/animals/system-admin/settings/_id/main/model";
import { useAnimalsSettingManageStore } from "@app/products/animals/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { getConfigDataField } = useAnimalsSettingStore();
  const { isEditing } = useAnimalsSettingManageStore();

  const eDisplayAndMandatoryFields: ISwitchCustomeFieldProps[] = [
    {
      eSettingsField: ECorporateSettingsField.Animals_Display_Purebreed,
    },
    {
      eSettingsField: ECorporateSettingsField.Animals_Mandatory_Purebreed,
    },
  ];

  const ePortalAndRenewalFields: ISwitchCustomeFieldProps[] = [
    {
      eSettingsField: ECorporateSettingsField.Animals_RenewalLocked_Purebreed,
    },
    {
      eSettingsField: ECorporateSettingsField.Animals_EnablePortal_Purebreed,
    },
  ];
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <AnimalRenderSwitch
          switchList={eDisplayAndMandatoryFields}
          formRenderProps={formRenderProps}
          optionsValue={switchOptionsValueNumberString}
        />

        <InputGlobalSettings
          data={getConfigDataField(
            ECorporateSettingsField.Animals_LabelPureBreed
          )}
          isEditing={isEditing}
        />

        <AnimalRenderSwitch
          switchList={ePortalAndRenewalFields}
          formRenderProps={formRenderProps}
          optionsValue={switchOptionsValueNumberString}
        />
      </div>

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <DynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
