import { WorkflowTypes } from "@app/products/property/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const CreateDefermentListButton = observer(() => {
  const { gridSelectedRows, isEmptyData } = useCCProductListViewStore();
  const { setListDialog } = usePropertyWorkflowStore();
  const isDisabled = useMemo(() => {
    return !isEmptyData && gridSelectedRows.length !== 1;
  }, [gridSelectedRows, isEmptyData]);

  return (
    <CCNavButton
      title="Create Deferment"
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Create_Deferment,
            data: {},
          },
        ]);
      }}
      disabled={isDisabled}
    />
  );
});
