import { loadSettings } from "@app/products/property/assessments/list/api";
import {
  defaultAddAssessment,
  searchByData,
} from "@app/products/property/components/action-bar/dialog/add-assessment/config";
import {
  IAddAssessmentDataForm,
  ISearchBy,
  eSearchAssessmentBy,
} from "@app/products/property/components/action-bar/dialog/add-assessment/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { Label } from "@common/stores/products/config";
import { upperFirst } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AddAssessmentDialogStore {
  private _isUseAssessRefAsPrimary: boolean = false;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _listDataSearch: ISearchBy[] = searchByData;
  private _initialDataForm?: IAddAssessmentDataForm = defaultAddAssessment;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isUseAssessRefAsPrimary() {
    return this._isUseAssessRefAsPrimary;
  }
  setIsUseAssessRefAsPrimary = (isAssessmentRef: boolean) => {
    runInAction(() => {
      this._isUseAssessRefAsPrimary = isAssessmentRef;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  private setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get initialDataForm() {
    return this._initialDataForm;
  }
  private setInitialDataForm = (initialDataForm?: IAddAssessmentDataForm) => {
    this._initialDataForm = initialDataForm;
  };

  get listDataSearch() {
    return this._listDataSearch;
  }
  private setListDataSearch = (listDataSearch: ISearchBy[]) => {
    this._listDataSearch = listDataSearch;
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isUseAssessRefAsPrimary = false;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._listDataSearch = searchByData;
      this._initialDataForm = defaultAddAssessment;
    });
  };

  loadOption = async () => {
    this.setIsLoading(true);
    const [assessmentIDLabel, assessmentReferenceLabel, assessmentNumberLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.AssessmentID,
        ECustomColNameProperty.Assessment_Reference,
        ECustomColNameProperty.AssessmentNumber,
      ]);
    const response = await loadSettings();
    let isUseAssessRef = false;
    let errorResponse = undefined;
    if (isSuccessResponse(response)) {
      isUseAssessRef =
        response?.data?.SettingFields?.find(
          (settingField) => settingField.FieldName === "UseAssessRefAsPrimary"
        )?.Value ?? false;
      const { AssessmentNumber, AssessmentReference } = eSearchAssessmentBy;
      const listDataSearch =
        searchByData?.map((searchItem: ISearchBy) => {
          if (searchItem.Key === eSearchAssessmentBy.AssessmentId) {
            //Lookup Assessment-Holding label
            return {
              ...searchItem,
              Value: assessmentIDLabel,
            };
          }
          return searchItem;
        }) ?? [];
      if (isUseAssessRef) {
        listDataSearch.unshift({
          Key: AssessmentReference,
          Value: upperFirst(assessmentReferenceLabel.toLowerCase()),
        });
      } else {
        listDataSearch.unshift({
          Key: AssessmentNumber,
          Value: upperFirst(assessmentNumberLabel.toLowerCase()),
        });
      }
      this.setListDataSearch(listDataSearch);
      const dataForm = { ...defaultAddAssessment };
      dataForm._option.SearchBy = {
        Data: listDataSearch,
        Value: listDataSearch[0],
      };
      this.setInitialDataForm(dataForm);
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsUseAssessRefAsPrimary(isUseAssessRef);
    this.setIsLoading(false);
  };

  reloadOptions = () => {
    this.loadOption();
  };
}

const addAssessmentDialogStoreContext = createContext(
  new AddAssessmentDialogStore()
);
export const useAddAssessmentDialogStore = () => {
  return useContext(addAssessmentDialogStoreContext);
};
