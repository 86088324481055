import { ECustomColNameProperty } from "@app/products/property/config";
import { nameOfLov } from "@app/products/property/model";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { getDropdownValue } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const GeneralFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    //Use store
    const { assessmentAdjustmentLOVs } = useSupplementaryRatesStore();

    //Get label
    const assessmentLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Assessment
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field cc-col-span-2">
            <label className="cc-label">{`${assessmentLabel} number`}</label>
            <Field
              name={nameOf("AssessmentNumber")}
              component={CCInput}
              placeholder="Assessment number"
              readOnly
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Formatted address</label>
            <Field
              name={nameOf("Address")}
              component={CCTextArea}
              placeholder="Formatted address"
              readOnly
              rows={3}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Owners</label>
            <Field
              name={nameOf("Owners")}
              component={CCTextArea}
              placeholder="Owners"
              readOnly
              rows={3}
            />
          </div>

          <div className="cc-field cc-col-span-2">
            <label className="cc-label">Reason for supplementary</label>
            <Field
              name={nameOf("Reason")}
              component={CCTextArea}
              rows={3}
              placeholder="Reason for supplementary"
              readOnly={options?.isReadOnly}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Effective date</label>
            <Field
              name={nameOf("EffectiveDate")}
              format={DATE_FORMAT.DATE_CONTROL}
              value={getFieldValue("EffectiveDate")}
              onChange={(e: DatePickerChangeEvent) => {
                onChange(nameOf("EffectiveDate"), {
                  value: e.value ? e.value : new Date(),
                });
              }}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Instalment plan</label>
            <Field
              name={nameOf("InstalmentPlanId")}
              component={CCSearchComboBox}
              disabled={options?.isReadOnly}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              data={assessmentAdjustmentLOVs?.InstallmentPlan ?? []}
              value={getDropdownValue(
                "" + getFieldValue("InstalmentPlanId"),
                assessmentAdjustmentLOVs?.InstallmentPlan ?? [],
                nameOfLov("Code")
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("InstalmentPlanId"), {
                  value: event.target.value?.Code ?? null,
                });
              }}
            />
          </div>
        </div>
      </section>
    );
  }
);
