import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { WorkflowTypes } from "@app/products/property/model";
import { Label } from "@common/stores/products/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export const ModifyAssessmentButton = observer(() => {
  const { gridSelectedRows, isEmptyData } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const { setListDialog } = usePropertyWorkflowStore();

  //Get label
  const modifyAssessmentLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Modify_Assessment
  );

  const isDisabled = useMemo(() => {
    return (
      (!isEmptyData && gridSelectedRows.length !== 1 && !id) ||
      (!id && isEmptyData)
    );
  }, [gridSelectedRows.length, id, isEmptyData]);

  return (
    <CCNavButton
      title={modifyAssessmentLabel}
      disabled={isDisabled}
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Modify_Assessment,
            data: {},
            props: {
              assessmentID: +id || gridSelectedRows[0]?.Assessment_Id || 0,
              statusID: gridSelectedRows[0]?.Assessment_Status || 0,
            },
          },
        ]);
      }}
    />
  );
});
