import { colWriteOffSmallBalances } from "@app/products/property/assessments/components/dialogs/write-off-small-balances/config";
import {
  defaultSelectedRows,
  mockData,
  mockDropDownData,
} from "@app/products/property/assessments/components/dialogs/write-off-small-balances/mock";
import { IWriteOffSmallBalances } from "@app/products/property/assessments/components/dialogs/write-off-small-balances/model";
import { changeGridData } from "@app/products/property/assessments/components/dialogs/write-off-small-balances/util";
import { nameOfLov } from "@app/products/property/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  Checkbox,
  CheckboxChangeEvent,
  InputChangeEvent,
  TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IWriteOffSmallBalancesProps {
  onClose: () => void;
}

interface IChildComponent {
  isUseCCSearchComboBox?: boolean;
  isUseCCInput?: boolean;
  isUseCCCurrencyInput?: boolean;
  isUseCheckbox?: boolean;
  valueGetter?: (name: string) => any;
  onChange: (
    name: string,
    options: {
      value: any;
    }
  ) => void;
}

const nameOf = nameOfFactory<IWriteOffSmallBalances>();
export const WriteOffSmallBalancesDialog = observer(
  ({ onClose }: IWriteOffSmallBalancesProps) => {
    const { pushNotification } = useCCAppNotificationStore();
    const [lastSelectedRow, setLastSelectedRow] =
      useState<IWriteOffSmallBalances>();
    const [isShowConfirmDialog, setIsShowConfirmDialog] =
      useState<boolean>(false);
    const [data, setData] = useState<IWriteOffSmallBalances[]>(mockData);
    const [selectedRows, setSelectedRows] =
      useState<IWriteOffSmallBalances[]>(defaultSelectedRows);

    const childComponent = ({
      isUseCCSearchComboBox,
      isUseCCInput,
      isUseCCCurrencyInput,
      isUseCheckbox,
      valueGetter,
      onChange,
    }: IChildComponent) => {
      return (
        <div>
          <div className="cc-form-cols-5">
            <div className="cc-field cc-col-span-2">
              <label className="cc-label custom-label">
                {lastSelectedRow?.Prompt}
                {isUseCheckbox && (
                  <>
                    &emsp;
                    <Field
                      component={Checkbox}
                      name="checkbox"
                      onChange={({ value }: CheckboxChangeEvent) => {
                        changeGridData(data, lastSelectedRow, +value, setData);
                        onChange("checkbox", { value });
                      }}
                    />
                  </>
                )}
              </label>
            </div>
            <div className="cc-field cc-col-span-2">
              {isUseCCSearchComboBox && valueGetter && (
                <>
                  <Field
                    name="dropdown"
                    component={CCSearchComboBox}
                    data={mockDropDownData}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    value={getDropdownValue(
                      valueGetter("dropdown"),
                      mockDropDownData,
                      nameOfLov("Code")
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      onChange("dropdown", {
                        value: event.value?.Code ?? null,
                      });
                    }}
                  />
                  <br />
                  <Field
                    name="textarea"
                    component={CCTextArea}
                    rows={3}
                    onChange={({ value }: TextAreaChangeEvent) => {
                      changeGridData(data, lastSelectedRow, value, setData);
                      onChange("textarea", { value });
                    }}
                  />
                </>
              )}
              {isUseCCInput && (
                <Field
                  name="input"
                  component={
                    isUseCCCurrencyInput ? CCCurrencyInput : CCNumericTextBox
                  }
                  onChange={({ value }: InputChangeEvent) => {
                    changeGridData(data, lastSelectedRow, value, setData);
                    onChange("input", { value });
                  }}
                />
              )}
            </div>
            <div className="cc-field">
              <Checkbox label="Save on default" />
            </div>
          </div>
        </div>
      );
    };

    const renderComponent = (
      valueGetter: (name: string) => any,
      onChange: (
        name: string,
        options: {
          value: any;
        }
      ) => void
    ) => {
      if (!valueGetter("dropdown")) {
        onChange("dropdown", {
          value: mockDropDownData[0].Code ?? null,
        });
      }

      switch (lastSelectedRow?.Variable_Id) {
        case 2:
        case 3:
          return childComponent({
            isUseCCSearchComboBox: true,
            valueGetter,
            onChange,
          });
        case 4:
        case 5:
          return childComponent({
            isUseCCInput: true,
            isUseCCCurrencyInput: true,
            onChange,
          });
        case 6:
          return childComponent({ isUseCheckbox: true, onChange });
        case 7:
          return childComponent({ isUseCCInput: true, onChange });
        default:
          return null;
      }
    };

    const handleConfirmFinishProcess = () => {
      onClose();
      pushNotification({
        title: "Write off small balances was completed successfully.",
        type: "success",
      });
    };

    return (
      <Form
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onChange } = formRenderProps;

          return (
            <>
              <CCDialog
                maxWidth="60%"
                height={"auto"}
                titleHeader={
                  "Set External Process Variable - Write off small balances"
                }
                onClose={onClose}
                bodyElement={
                  <FormElement className="cc-form">
                    <div className="cc-field-group">
                      <div className="cc-field">
                        <CCGrid
                          columnFields={colWriteOffSmallBalances}
                          data={data}
                          primaryField={nameOf("Variable_Id")}
                          selectableMode="multiple"
                          itemPerPage={10}
                          onSelectionChange={(
                            dataItem: IWriteOffSmallBalances[]
                          ) => {
                            const lastItem = dataItem[dataItem.length - 1];
                            setLastSelectedRow(lastItem);
                            setSelectedRows([...dataItem]);
                            const index = _.findIndex(data, {
                              Variable_Id: lastItem?.Variable_Id,
                            });
                            if (index !== -1) {
                              onChange("textarea", {
                                value: data[index].Value ?? "",
                              });
                              onChange("input", {
                                value: data[index].Value ?? "",
                              });
                              onChange("checkbox", {
                                value: !!data[index].Value ?? false,
                              });
                            }
                          }}
                          selectedRows={selectedRows}
                        />
                      </div>
                      {renderComponent(valueGetter, onChange)}
                    </div>
                  </FormElement>
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button
                      className="cc-dialog-button"
                      themeColor="primary"
                      onClick={() => setIsShowConfirmDialog(true)}
                    >
                      OK
                    </Button>
                  </div>
                }
              />
              {isShowConfirmDialog && (
                <ConfirmDialog
                  title="Confirmation"
                  subMessage={"Are you sure you want to submit?"}
                  onClosePopup={() => setIsShowConfirmDialog(false)}
                  onAsyncConfirm={() => {
                    return handleConfirmFinishProcess();
                  }}
                />
              )}
            </>
          );
        }}
      />
    );
  }
);
