import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { eComponent } from "@app/products/property/components/associations/model";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export interface IAddToSupplementaryProps {
  componentNumber: eComponent.Master_Property | eComponent.Assessment;
  statusId?: number;
}

export const AddToSupplementaryButton = observer(
  ({ componentNumber, statusId }: IAddToSupplementaryProps) => {
    const { gridSelectedRows, isEmptyData, lastSelectedId } =
      useCCProductListViewStore();
    const { setListDialog } = usePropertyWorkflowStore();
    const { id } = useParams<{ id: string }>();
    const isDisabled = useMemo(() => {
      return (
        (!isEmptyData && gridSelectedRows.length !== 1 && !id) ||
        (!id && isEmptyData)
      );
    }, [gridSelectedRows.length, isEmptyData, id]);

    return (
      <CCNavButton
        title={"Add to supplementary"}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Associate_Supplementary,
              data: {},
              props: {
                componentNumber,
                componentId: +(id ?? lastSelectedId),
                statusId: statusId ?? null,
              },
            },
          ]);
        }}
        disabled={isDisabled}
      />
    );
  }
);
