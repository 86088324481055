import { DTO_ChargeRunLevySummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/model";
import {
  CURRENCY_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleProminent } from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOf = nameOfFactory<DTO_ChargeRunLevySummary>();

export const colLevySumary: IColumnFields[] = [
  {
    field: nameOf("Levy"),
    title: "Levy",
  },
  {
    field: nameOf("Assessment_Group"),
    title: "Region",
  },
  {
    field: nameOf("RLP_Board"),
    title: "RLP Board",
  },
  {
    field: nameOf("Rate"),
    title: "Rate",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("LevyAmount"),
    title: "Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NumberOfLevies"),
    title: "Number of Levies",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Quantity"),
    title: "Quantity",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Valuation"),
    title: "Valuation",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Valuation", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("BaseAmount"),
    title: "Base Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("BaseAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("RateByValuationAmount"),
    title: "Rate by Valuation Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("RateByValuationAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("IncrementsToMinimum"),
    title: "Increments to Minimum",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("IncrementsToMinimum", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("NumberOfIncrementsToMinimum"),
    title: "Number of Increments to Minimum",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("GrossLevyAmount"),
    title: "Gross Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("GrossLevyAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Rebates"),
    title: "Rebates",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Rebates", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("NettLevyAmount"),
    title: "Nett Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("NettLevyAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("AttributableValue"),
    title: "Attributable Value",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("AttributableValue", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("PostponedAmount"),
    title: "Postponed Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("PostponedAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("NumberOfPostponedAmount"),
    title: "Number of Postponed Amount",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
