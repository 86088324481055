import { CCGrid, ICCGridProps } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { State } from "@progress/kendo-data-query";
import { Field } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { isNil, uniqBy } from "lodash";
import React, { useState } from "react";
import "./_index.scss";

interface IGridSearch extends Omit<ICCGridProps, "primaryField"> {
  grid: {
    primaryField?: string;
    itemPerPage?: number;
    isAutoHiddenPager?: boolean;
  };
  search: {
    hasSearchField?: boolean;
    filterCol?: string;
    sortCol?: string;
  };
  setSelectedItemCount?: (data: any) => void;
  handleSelectionChange: (data: any) => void;
}

export const GridSearch = ({
  grid,
  search = {
    hasSearchField: false,
    filterCol: undefined,
    sortCol: undefined,
  },
  columnFields,
  dataUrl,
  data,
  selectableMode = "single",
  state = {},
  selectedRows,
  setSelectedItemCount,
  handleSelectionChange,
  onDataStateChange,
}: IGridSearch) => {
  const [searchKey, setSearchKey] = useState("");

  const previousState = state?.filter?.filters ?? [];
  return (
    <div className="cc-grid-search">
      {search.hasSearchField ? (
        <div className="cc-grid-search-input">
          <label className="cc-label">Search</label>
          <Field
            name={"searchKey"}
            component={CCInput}
            placeholder="Search"
            onChange={(e: InputChangeEvent) => {
              setSearchKey(e.value);
            }}
          />
        </div>
      ) : null}
      <div className="cc-grid-search-list">
        <CCGrid
          columnFields={columnFields}
          selectableMode={selectableMode}
          data={data}
          dataUrl={dataUrl}
          primaryField={grid.primaryField || "ID"}
          onSelectionChange={handleSelectionChange}
          onTotalSelectedRowChange={setSelectedItemCount}
          state={
            search.sortCol
              ? searchKey.trim() !== ""
                ? {
                    ...state,
                    filter: {
                      logic: "and",
                      filters: uniqBy(
                        [
                          ...previousState,
                          {
                            field: search.filterCol,
                            operator: "contains",
                            value: searchKey,
                          },
                        ],
                        "field"
                      ),
                    },
                  }
                : ({
                    ...state,
                    filter: {
                      logic: "and",
                      filters:
                        state?.filter?.filters?.filter(
                          (item: any) =>
                            !isNil(item?.filters) &&
                            item?.field !== search.sortCol
                        ) ?? [],
                    },
                  } as State)
              : state
          }
          isAutoHiddenPager={grid.isAutoHiddenPager ?? false}
          selectedRows={selectedRows}
          itemPerPage={grid.itemPerPage ?? 10}
          onDataStateChange={onDataStateChange}
        />
      </div>
    </div>
  );
};
