import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.Assessment_Id
    ? `- ${selectedRow.Assessment_Id}`
    : "";
  return `Property - Assessments ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.Assessment_Number_Formatted,
    selectedRow.Property_Name_Address_Locality,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return "Property - Assessments";
};

export const getBookmarkListViewDetail = () => {
  return "Property - Assessments - List";
};
