import { useAnimalsSettingStore } from "@app/products/animals/system-admin/settings/_category/store";
import { DynamicGridFields } from "@app/products/animals/system-admin/settings/_id/components/dynamic-grid-fields/_index";
import { ICommonFormProps } from "@app/products/animals/system-admin/settings/_id/main/model";
import { useAnimalsSettingManageStore } from "@app/products/animals/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { isEditing } = useAnimalsSettingManageStore();
  const { getConfigDataField } = useAnimalsSettingStore();

  const eInputFields = [
    ECorporateSettingsField.Animals_ClassificationLabelPensioner_Sterilised,
    ECorporateSettingsField.Animals_ClassificationLabelPensioner_NotSterilised,
    ECorporateSettingsField.Animals_ClassificationLabelNotPensioner_Sterilised,
    ECorporateSettingsField.Animals_ClassificationLabelNotPensioner_NotSterilised,
  ];
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        {eInputFields.map((eField: ECorporateSettingsField) => (
          <InputGlobalSettings
            data={getConfigDataField(eField)}
            isEditing={isEditing}
          />
        ))}
      </div>

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <DynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
