import { getDisplayTextWithDashes } from "@common/utils/common";
import { VO_Assessment_RebateEntitlement } from "@app/products/property/assessments/rebates/list/model";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.Assessment_Id
    ? `- ${selectedRow.Assessment_Id}`
    : "";
  return `Property - Assessments ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (
  selectedRow: VO_Assessment_RebateEntitlement
) => {
  return getDisplayTextWithDashes([
    selectedRow.Assessment_Id,
    selectedRow.Formatted_Address,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return "Property - Assessments - Rebates";
};

export const getBookmarkListViewDetail = () => {
  return "Property - Assessments - Rebates - List";
};
