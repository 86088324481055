import { useMergeContactStore } from "@app/core/contacts/merge-contact/store";
import { CCPanel } from "@components/cc-panel/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import "./_index.scss";
import { MasterContactForm } from "./components/form/_index";

export const MasterContact = observer(() => {
  const { masterContact } = useMergeContactStore();
  const [isExpanded, setIsExpanded] = useState(true);
  const title = useMemo(() => {
    return `Person - ${
      masterContact?.DisplayName ? masterContact?.DisplayName + " -" : ""
    } Used ${masterContact?.ContactRoles_Count ?? 0} times (Master Contact)`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterContact]);

  return (
    <CCPanel
      className="cc-contact-panel"
      key={masterContact?.Contact_ID}
      component={<MasterContactForm />}
      onToggle={() => {
        setIsExpanded(!isExpanded);
      }}
      title={title}
      isExpanded={isExpanded}
      isKeepMounted
    />
  );
});
