import { CCComboBox } from "@components/cc-combo-box/_index";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";
import { loadSearchName } from "./api";
interface ISearchName {
  fieldName: any;
  onChange: (values: any) => void;
  formRenderProps: FormRenderProps;
  nameOf: (name?: string) => string;
  isDisabled?: boolean;
}
export const SearchNameContact = ({
  fieldName,
  onChange,
  formRenderProps,
  nameOf,
  isDisabled = false,
}: ISearchName) => {
  const { onChange: onChangeValue, valueGetter } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const handleSearchName = (event: ComboBoxFilterChangeEvent) => {
    let numLetters = 1;
    if (event.filter.value.length >= numLetters) {
      onChangeValue(nameOf("_option.Name.Loading"), { value: true });
      loadSearchName(event.filter).then((data) => {
        onChangeValue(nameOf("_option.Name.Data"), { value: data });
        onChangeValue(nameOf("_option.Name.Loading"), { value: false });
      });
    } else {
      onChangeValue(nameOf("_option.Name.Data"), { value: [] });
    }
  };
  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren =
      itemProps.dataItem.IsExist !== false ? (
        <span>{li.props.children}</span>
      ) : (
        <span>
          {li.props.children} <i className={"text-primary"}>- New</i>
        </span>
      );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <section className="cc-field-group">
      <div className="cc-field">
        <label className="cc-label">Search name</label>
        <Field
          name={fieldName}
          filterable
          suggest
          onFilterChange={handleSearchName}
          data={getFieldValue("_option.Name.Data")}
          loading={getFieldValue("_option.Name.Loading")}
          textField={"Name"}
          placeholder={`Name`}
          component={CCComboBox}
          itemRender={itemRender}
          onChange={(event: ComboBoxChangeEvent) => {
            onChange(event.target.value);
          }}
          disabled={isDisabled}
        />
      </div>
    </section>
  );
};
