import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { PROPERTY_CHARGE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/constant";
import {
  getInitialDataCreateChargeRun,
  postProcessCreateChargeRun,
} from "@app/products/property/charge-and-notice-runs/charge-runs/components/form-steps/create-charge-run/api";
import { DetailsFormStep } from "@app/products/property/charge-and-notice-runs/charge-runs/components/form-steps/create-charge-run/components/form-elements/details/_index";
import { EKeysOfStepsCreateChargeRun } from "@app/products/property/charge-and-notice-runs/charge-runs/components/form-steps/create-charge-run/config";
import {
  DTO_ChargeRun_Lovs,
  DTO_LOV_ChargeRunType,
  DTO_Workflow_ChargeRun,
} from "@app/products/property/charge-and-notice-runs/charge-runs/components/form-steps/create-charge-run/model";
import { PROPERTY_PIC_CHARGE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/constant";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import {
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import {
  convertValueLOVToNumber,
  isShowParkButton,
} from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { DTO_LOV } from "@common/models/odataResponse";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface ICreateChargeRunDialogProps {
  onClose: () => void;
  dataFromActionList?: VO_Workflow_Draft;
  isReadOnly?: boolean;
  statusBadge?: IBadgeDialog[];
  isSaveOnNextStep?: boolean;
  isIncompleteMode?: boolean;
  isFromActionList?: boolean;
  isShowCancelWorkflowButton?: boolean;
  isToBeApprovalMode?: boolean;
  isRedirectManagePage?: boolean;
  suffixTitle?: string;
  prefixTitle?: string;
  isPICChargeRun?: boolean;
}

export const CreateChargeRunDialog = observer(
  ({
    onClose,
    dataFromActionList,
    isReadOnly = false,
    statusBadge,
    prefixTitle,
    suffixTitle,
    isSaveOnNextStep = false,
    isFromActionList = false,
    isIncompleteMode = false,
    isShowCancelWorkflowButton = false,
    isToBeApprovalMode = false,
    isRedirectManagePage = true,
    isPICChargeRun,
  }: ICreateChargeRunDialogProps) => {
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { currentFormTitle } = useCommonProductStore();
    const history = useHistory();
    const [lovsChargeRunData, setLovsChargeRunData] =
      useState<DTO_ChargeRun_Lovs>();
    const [workflowInitData, setWorkflowInitData] =
      useState<DTO_Workflow_ChargeRun>();
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
    const [isFromPICChargeRun, setIsFromPICChargeRun] =
      useState<boolean>(false);
    const isFirstSave = useMemo(() => {
      const draftId =
        workflowInitData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id;
      return isNil(draftId) || draftId === 0;
    }, [workflowInitData]);
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    const getWorkflowData = async () => {
      const id = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      const response = await getInitialDataCreateChargeRun(id);
      notificationFormStepRef?.current?.setLoadingFormStep(false);

      if (Array.isArray(response)) {
        const [lovsChargeRun, workflowData] = response;
        if (
          isSuccessResponse(lovsChargeRun) &&
          isSuccessResponse(workflowData) &&
          lovsChargeRun?.data &&
          workflowData?.data
        ) {
          const lovsChargeRunWithNumber: DTO_ChargeRun_Lovs | any = {};
          for (const [key, value] of Object.entries(lovsChargeRun.data)) {
            lovsChargeRunWithNumber[key] = convertValueLOVToNumber(value);
          }
          setLovsChargeRunData(lovsChargeRunWithNumber);
          const isFromPICChargeRunChecked =
            //From PIC Charge run button
            isPICChargeRun ||
            //From PIC Charge run in workflow list
            lovsChargeRunWithNumber?.ChargeRunType?.find(
              (type: DTO_LOV_ChargeRunType) =>
                +type.Code ===
                workflowData?.data?.WorkflowDetail?.Charge_Run_TypeId
            )?.CRT_Is_Pic ||
            false;
          setIsFromPICChargeRun(isFromPICChargeRunChecked);
          setWorkflowInitData({
            WorkflowDetail: workflowData?.data?.WorkflowDetail,
            WorkflowHeader: workflowData?.data?.WorkflowHeader,
          });
        }
      } else {
        const responseError = response as APIResponse<
          DTO_Workflow_ChargeRun | ResponsePacket
        >;
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error:
              (responseError.data as ResponsePacket)?.Errors ??
              "Load workflow failed",
          },
        });
      }
    };

    useEffectOnce(() => {
      getWorkflowData();
    });

    const initialValues = useMemo(() => {
      const assessmentGroupsToBeIncluded =
        workflowInitData?.WorkflowDetail?.AssessmentGroupsToBeIncluded ?? [];
      const assessmentGroups = lovsChargeRunData?.AssessmentGroups ?? [];
      const initDetails = {
        ...workflowInitData?.WorkflowDetail,
        AssessmentGroupsToBeIncluded: assessmentGroups?.filter(
          (assessmentGroup: DTO_LOV) =>
            assessmentGroupsToBeIncluded?.includes(+assessmentGroup?.Code)
        ),
      };

      return {
        [EKeysOfStepsCreateChargeRun.Details]: initDetails,
        [EKeysOfStepsCreateChargeRun.Comments]: {},
        [EKeysOfStepsCreateChargeRun.Documents]: {},
      };
    }, [workflowInitData, lovsChargeRunData]);

    const steps: IStep[] = [
      {
        label: "Details",
        initialValues: initialValues.Details,
        component: DetailsFormStep,
        visible: true,
        key: EKeysOfStepsCreateChargeRun.Details,
        options: {
          isReadOnly,
          lovsChargeRunData,
          isFromPICChargeRun,
        },
      },
      {
        label: "Comments",
        component: CommentsFormStep,
        initialValues: initialValues.Comments,
        visible: true,
        key: EKeysOfStepsCreateChargeRun.Comments,
        options: {
          isReadOnly,
          workflowDraftId:
            workflowInitData?.WorkflowHeader?.WorkflowDraft
              ?.Workflow_Draft_Id ?? 0,
          recordType: isFromPICChargeRun
            ? RECORDTYPE.CommunityProperty_Charge_Run_PIC
            : RECORDTYPE.CommunityProperty_Charge_Run,
        },
        customClassName: "cc-comment-step-fixed-height-grid",
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValues.Documents,
        visible: true,
        key: EKeysOfStepsCreateChargeRun.Documents,
        options: {
          isReadOnly,
          workflowDraftId:
            workflowInitData?.WorkflowHeader?.WorkflowDraft
              ?.Workflow_Draft_Id ?? 0,
          workflowType: WorkflowTypes.Create_ChargeRun,
        },
      },
    ];
    const workflowName = useMemo(() => {
      const formId =
        workflowInitData?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id;
      return currentFormTitle(formId ?? 0) ?? "Create Charge Run";
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflowInitData]);

    const titleHeader = useMemo(() => {
      return getTitleWorkflow(workflowName, prefixTitle, suffixTitle);
    }, [prefixTitle, suffixTitle, workflowName]);

    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Approve:
          await handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleConfirmRetainProcess(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
        default:
          break;
      }
    };

    const handleApproveProcess = async (payload: DTO_Workflow_ChargeRun) => {
      setIsLoadingApprove(true);

      const approveProps: IProcessWorkflow<DTO_Workflow_ChargeRun> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ?? "Create charge run approved successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Approve create charge run failed.",
        modeProcess: WorkflowProcessMode.Approve,
      };

      const setLoading = () => {
        setIsLoadingApprove(false);
      };
      await handleProcessWorkflow(approveProps, setLoading);
    };

    const handleParkProcess = async (payload: DTO_Workflow_ChargeRun) => {
      setIsLoadingPark(true);

      const parkProps: IProcessWorkflow<DTO_Workflow_ChargeRun> = {
        payload: payload,
        actionSuccess: () => {
          onClose();
          pushNotification({
            title: "Create charge run was parked successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Create charge run could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => setIsLoadingPark(false);

      await handleProcessWorkflow(parkProps, setLoading);
    };

    const handleConfirmRetainProcess = async (
      payload: DTO_Workflow_ChargeRun
    ) => {
      setIsLoadingClose(true);

      const retainProps: IProcessWorkflow<DTO_Workflow_ChargeRun> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ?? "Create charge run was parked successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Create charge run could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog(undefined);
      };

      await handleProcessWorkflow(retainProps, setLoading);
    };

    const handleConfirmFinishProcess = (payload: DTO_Workflow_ChargeRun) => {
      const dataProcessed = processData(payload);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage:
          "The charge run will be created based on the information provided. Are you sure you want to submit?",
      });
    };

    const handleFinishProcess = async (payload: DTO_Workflow_ChargeRun) => {
      const finishProps: IProcessWorkflow<DTO_Workflow_ChargeRun> = {
        payload: payload,
        actionSuccess: (e) => {
          const notificationContent: IAppNotificationItemAddProps = {
            title:
              "Created charge run successfully sent to job queue. You will be notified once the job has been completed.",
            type: "success",
          };
          onClose();
          if (!isRedirectManagePage) {
            pushNotification(notificationContent);
          } else if (isFromPICChargeRun) {
            history.push(
              `${PROPERTY_PIC_CHARGE_RUNS_ROUTE}/${e?.Component_ID}`,
              {
                notification: notificationContent,
              }
            );
          } else {
            history.push(`${PROPERTY_CHARGE_RUNS_ROUTE}/${e?.Component_ID}`, {
              notification: notificationContent,
            });
          }
        },
        defaultFailedMessage: "Create charge run failed.",
        modeProcess: WorkflowProcessMode.Finish,
      };

      const setLoading = () => {};
      await handleProcessWorkflow(finishProps, setLoading);
    };

    const processData: DTO_Workflow_ChargeRun | any = (data: any) => {
      const assessmentGroupsToBeIncluded =
        data?.Details?.AssessmentGroupsToBeIncluded?.map(
          (assessmentGroup: DTO_LOV) => assessmentGroup?.Code
        );
      const workflowDetail: any = {
        ...data.Details,
        AssessmentGroupsToBeIncluded: assessmentGroupsToBeIncluded,
      };
      return {
        WorkflowHeader: workflowInitData?.WorkflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_ChargeRun>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;
      const response = await postProcessCreateChargeRun(modeProcess, payload);

      setLoading();
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    const handleNextButton = async (data: any) => {
      setIsLoadingOnNext(true);
      const newData = { ...data };
      const processPayload = processData(newData);

      return handleSaveAndNext(processPayload);
    };

    const handleSaveAndNext = async (
      payload: DTO_Workflow_ChargeRun,
      isCloseDialog: boolean = false
    ): Promise<boolean> => {
      const response = await postProcessCreateChargeRun(
        WorkflowProcessMode.Save,
        payload
      );

      setIsLoadingOnNext(false);

      const defaultSuccessMessage = "Create charge run was saved successfully.";
      const defaultFailedMessage =
        "The create charge run process could not be saved.";

      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        setWorkflowInitData(response?.data?.ReturnData);
        if (isCloseDialog) {
          onClose();
          pushNotification({
            title:
              response?.data?.Notification ??
              response?.data?.SuccessMessage ??
              defaultSuccessMessage,
            type: "success",
          });
        }
        return true;
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        return false;
      }
    };

    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessCreateChargeRun,
          dataCancel: data,
          defaultSuccessMessage:
            "Create charge run was cancelled successfully.",
          defaultErrorMessage: "Create charge run could not be cancelled.",
        });
      } else {
        onClose();
      }
    };

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        listButtonId={listSubmitButton}
        onSubmit={handleSubmit}
        initialSteps={steps}
        initialValues={initialValues}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => {
          return (
            <CCDialog
              maxWidth="65%"
              disabled={isLoadingApprove}
              titleHeader={titleHeader}
              onClose={() => handleCloseDialog(renderProps)}
              badge={statusBadge}
              bodyElement={renderProps.children}
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={isLoadingPark ? "fas fa-spinner fa-spin" : ""}
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled || isLoadingPark
                      }
                    >
                      Park
                    </Button>
                  )}
                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      disabled={
                        isLoadingPark || isLoadingOnNext || isLoadingApprove
                      }
                      id={EListSubmitButton.Cancel}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}
                  {isToBeApprovalMode && (
                    <Button
                      themeColor="primary"
                      id={EListSubmitButton.Approve}
                      disabled={
                        isLoadingApprove || renderProps.nextButton.disabled
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.submitButton.onClick}
                      iconClass={
                        isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                      }
                    >
                      Approve
                    </Button>
                  )}
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}
                  {isToBeApprovalMode || isReadOnly ? (
                    !renderProps.isLastStep && (
                      <Button
                        themeColor="primary"
                        id="cc-next-step-button"
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        className={"cc-dialog-button"}
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )
                  ) : (
                    <Button
                      themeColor="primary"
                      id={renderProps.nextButton.idButton}
                      disabled={
                        isLoadingOnNext || renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingOnNext
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
