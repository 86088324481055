import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { Documents } from "@app/core/documents/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { AddFeeWithParentButton } from "@app/core/fees/fee-button/_index";
import { BubbleUpType } from "@app/core/inspections/[id]/model";
import { InvoiceForm } from "@app/core/invoice/[id]/components/child-screens/general/_index";
import { InvoiceDetailsTab } from "@app/core/invoice/[id]/components/reference-sidebar/details/_index";
import { InvoiceHistoryTab } from "@app/core/invoice/[id]/components/reference-sidebar/history/_index";
import { useInvoiceStore } from "@app/core/invoice/[id]/store";
import { mapEnum, renderBubbleUps } from "@app/core/invoice/[id]/util";
import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { PPRActions } from "@app/products/town-planning/ppr/[id]/components/child-screens/actions/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useGlobalStore } from "@common/stores/global/store";
import { GetMappedProductNumber } from "@common/utils/mapping";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistManageInvoice = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, invoice, responseLoadError, loadInvoiceById, onSubmit } =
    useInvoiceStore();
  const { currentUserInfo } = useGlobalStore();
  const isNew = useIsNew();
  const managePageUrl = window.location.pathname;

  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_Invoice,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: invoice?.Description ?? "",
      LinkUrl: managePageUrl,
      LinkText: `Invoice - ${id ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE?.Core,
      RecordType_ENUM: RECORDTYPE.CORE_Invoice,
      Record_ID: id ? +id : 0,
    },
  ];

  const productTypeAncestor = GetMappedProductNumber(
    invoice?._BubbleUps[0]?.SourceIdentifier?._RecordSourceType_ENUM as
      | RECORDTYPE
      | undefined
  );

  const recordType = invoice?._BubbleUps?.find(
    (item) =>
      mapEnum(item.BubbleUpType_ENUM, BubbleUpType) === BubbleUpType?.Parent
  )?.SourceIdentifier?._RecordSourceType_ENUM;
  const recordId = invoice?._BubbleUps?.find(
    (item) =>
      mapEnum(item.BubbleUpType_ENUM, BubbleUpType) === BubbleUpType?.Parent
  )?.SourceIdentifier?._RecordSource_ID;

  const handleOnReload = () => {
    loadInvoiceById(parseInt(id), isNew);
  };

  const [showSlideBar, setShowSlideBar] = useState(true);

  const listPanelBar: ITabProps[] = [
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      component: (
        <PPRActions id={parseInt(id)} recordType={RECORDTYPE.CORE_Invoice} />
      ),
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: (
        <Comments id={parseInt(id)} recordType={RECORDTYPE.CORE_Invoice} />
      ),
    },
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents id={parseInt(id)} recordType={RECORDTYPE.CORE_Invoice} />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading || isLoadingPermission} isFullScreen />
      <FormNavigation title="Invoice" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={handleOnReload}
        />
      ) : (
        <>
          <FormTitle title={invoice?.InvoiceNumber} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              ></CCNavButton>,
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
                disabled={!checkPermissions(FormAction.CORE_ALLOW_SAVE)}
              />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Invoice}
                  bubbleUps={invoice?._BubbleUps}
                />
                <AddAttachmentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Invoice}
                  bubbleUps={invoice?._BubbleUps}
                />
                {/* TODO: out of scope task 13232 */}
                {/* <CCNavButton title={"Bond"} /> */}
                <AddCommentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Invoice}
                  bubbleUps={renderBubbleUps(
                    recordId ?? 0,
                    recordType,
                    parseInt(id),
                    RECORDTYPE.CORE_Invoice
                  )}
                />
                <AddDocumentButton
                  // TODO: change dataSetFilter
                  dataSetFilter={MAILMERGEDATASET?.CORE_Invoice}
                  recordType={RECORDTYPE.CORE_Invoice}
                  productType={PRODUCT_TYPE_NUMBER.Core}
                  id={parseInt(id)}
                  bubbleUps={invoice?._BubbleUps}
                />
                <AddFeeWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Invoice}
                  productType={productTypeAncestor as PRODUCT_TYPE_NUMBER}
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {invoice && <InvoiceForm />}
                <TabTable
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Invoice}
                  initialPanels={listPanelBar}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <InvoiceDetailsTab /> },
                    {
                      title: "History",
                      component: <InvoiceHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
