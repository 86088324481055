import { EventView } from "@app/products/crms/all-events/by-refno/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<EventView>();
export const duplicateEventColumns: IColumnFields[] = [
  {
    field: nameOf("RefNo"),
    title: "Reference Number",
  },
  {
    field: nameOf("ActionOfficer"),
    title: "Action Officer",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("DateRecorded"),
    title: "Recorded",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Category"),
    title: "Category",
  },
  {
    field: nameOf("Location"),
    title: "Location",
  },
  {
    field: nameOf("RequestedBy"),
    title: "Contact",
  },
  {
    field: nameOf("DateTarget"),
    title: "Target",
    format: DATE_FORMAT.DATE,
  },
];
