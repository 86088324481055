import { colAssociationsEntityNameAndAddress } from "@app/products/property/components/associations/components/entity-name-and-address/config";
import { DTO_AssociatedItem_Generic } from "@app/products/property/components/associations/components/entity-name-and-address/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IAssociationsEntityNameAndAddressProps {
  associationsEntityNameAndAddressInfo:
    | DTO_AssociatedItem_Generic[]
    | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_Generic>();

export const AssociationsEntityNameAndAddress = ({
  associationsEntityNameAndAddressInfo,
}: IAssociationsEntityNameAndAddressProps) => {
  return (
    <CCGrid
      className="cc-master-property-grid"
      data={associationsEntityNameAndAddressInfo || []}
      columnFields={colAssociationsEntityNameAndAddress}
      primaryField={nameOf("Id")}
    />
  );
};
