import { MailMerge_SelectMergeDocument_Ext } from "@app/core/new-mail-merge/dialogs/model";
import {
  MAIL_MERGE_OPTIONS_FORM_STEP,
  OptionsFormStep,
} from "@app/core/new-mail-merge/form-steps/options/_index";
import {
  MAIL_MERGE_TEMPLATE_FORM_STEP,
  TemplateFormStep,
} from "@app/core/new-mail-merge/form-steps/template/_index";
import {
  MAIL_MERGE_WORKFLOW_FORM_STEP,
  WorkflowFormStep,
} from "@app/core/new-mail-merge/form-steps/workflow/_index";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";

interface IMailMergeDialog {
  count?: number;
  isMultiple?: boolean;
  productType?: PRODUCT_TYPE_NUMBER;
  isSubmitting?: boolean;
  initialMailMerge?: MailMerge_SelectMergeDocument_Ext;
  onClose: () => void;
  onSubmit: (data: MailMerge_SelectMergeDocument_Ext) => void;
}

export const MailMergeDialog = ({
  isMultiple = false,
  productType = PRODUCT_TYPE_NUMBER.Core,
  isSubmitting = false,
  initialMailMerge,
  onClose,
  onSubmit,
}: IMailMergeDialog) => {
  const { settings } = useCommonCoreStore();
  const isShowWorkflow = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableWorkflow]
  );

  const steps: IStep[] = [
    {
      label: "Template",
      component: TemplateFormStep,
      visible: true,
      options: {
        isMultiple: isMultiple,
        productType: productType,
      },
      key: MAIL_MERGE_TEMPLATE_FORM_STEP,
    },
    {
      label: "Options",
      component: OptionsFormStep,
      visible: true,
      key: MAIL_MERGE_OPTIONS_FORM_STEP,
    },
    {
      label: "Workflow",
      component: WorkflowFormStep,
      visible: isShowWorkflow,
      key: MAIL_MERGE_WORKFLOW_FORM_STEP,
    },
  ];

  return (
    <CCFormStep
      onSubmit={onSubmit}
      initialSteps={steps}
      initialValues={initialMailMerge}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="70%"
          maxHeight="70%"
          titleHeader={"Mail Merge Documents"}
          onClose={onClose}
          disabled={isSubmitting}
          bodyElement={
            <div className="cc-mail-merge-stepper">{renderProps.children}</div>
          }
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              <Button className={"cc-dialog-button"} onClick={onClose}>
                Cancel
              </Button>
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
              )}
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                disabled={renderProps.nextButton.disabled}
                className={"cc-dialog-button"}
                iconClass={isSubmitting ? "fas fa-spinner fa-spin" : ""}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.nextButton.label}
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
