import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { Documents } from "@app/core/documents/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { BubbleUpType } from "@app/core/inspections/[id]/model";
import { mapEnum, renderBubbleUps } from "@app/core/inspections/[id]/util";
import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { NoticeForm } from "@app/core/notices/[id]/components/child-screens/general/_index";
import { NoticeDetailTab } from "@app/core/notices/[id]/components/reference-sidebar/details/_index";
import { NoticHistoryTab } from "@app/core/notices/[id]/components/reference-sidebar/history/_index";
import { useNoticesStore } from "@app/core/notices/[id]/store";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { PPRActions } from "@app/products/town-planning/ppr/[id]/components/child-screens/actions/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedManageNotice = observer(() => {
  const { currentUserInfo } = useGlobalStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_Notice,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });

  const isNew = useIsNew();
  const {
    isLoading,
    responseLoadError,
    notices,
    reloadNotices,
    onSubmit,
    isDisableSaveButton,
    isLoadingSuperAdmin,
  } = useNoticesStore();
  const { id } = useParams<{ id: string }>();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const managePageUrl = window.location.pathname;

  const recordType = notices?._BubbleUps?.find(
    (item) =>
      mapEnum(item.BubbleUpType_ENUM, BubbleUpType) === BubbleUpType?.Parent
  )?.SourceIdentifier?._RecordSourceType_ENUM;
  const recordId = notices?._BubbleUps.find(
    (item) =>
      mapEnum(item.BubbleUpType_ENUM, BubbleUpType) === BubbleUpType?.Parent
  )?.SourceIdentifier?._RecordSource_ID;

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: getDisplayTextWithDashes(["Notices", notices?.Description]),
      LinkUrl: managePageUrl,
      LinkText: `Notices - ${id ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE?.Core,
      RecordType_ENUM: RECORDTYPE.CORE_Notice,
      Record_ID: id ? +id : 0,
    },
  ];

  const listPanelBar: ITabProps[] = [
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      component: (
        <PPRActions id={parseInt(id)} recordType={RECORDTYPE.CORE_Notice} />
      ),
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: (
        <Comments id={parseInt(id)} recordType={RECORDTYPE.CORE_Notice} />
      ),
    },
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents id={parseInt(id)} recordType={RECORDTYPE.CORE_Notice} />
      ),
    },
  ];

  return (
    <>
      <Loading
        isLoading={isLoading || isLoadingSuperAdmin || isLoadingPermission}
        isFullScreen
      />
      <FormNavigation title="Notices" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => reloadNotices(parseInt(id), isNew)}
        />
      ) : (
        <>
          <FormTitle title={notices?.NoticeCategory_Name ?? ""} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
                disabled={
                  isDisableSaveButton ||
                  !checkPermissions([
                    FormAction.CORE_ALLOW_SAVE,
                    FormAction.CORE_ALLOW_EDIT,
                  ])
                }
              />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Notice}
                  bubbleUps={notices?._BubbleUps}
                  disabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ACTION)
                  }
                />
                <AddAttachmentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Notice}
                  bubbleUps={notices?._BubbleUps}
                  disabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ATTACHMENT)
                  }
                />
                <AddCommentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Notice}
                  bubbleUps={renderBubbleUps(
                    recordId ?? 0,
                    recordType,
                    parseInt(id),
                    RECORDTYPE.CORE_Notice
                  )}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMENT)
                  }
                />
                <AddDocumentButton
                  // TODO: check dataSetFilter later
                  dataSetFilter={MAILMERGEDATASET?.CORE_Notice}
                  recordType={RECORDTYPE.CORE_Notice}
                  productType={PRODUCT_TYPE_NUMBER.Core}
                  id={parseInt(id)}
                  bubbleUps={notices?._BubbleUps}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_DOCUMENT)
                  }
                />
              </CCNavButton>,
              // TODO: Out of scope, it will be implemented later
              // <CCNavButton title={"More Options"} type="more">
              //   <CCNavButton title={"Add NC item"} />
              //   <CCNavButton title={"Add compliance instruction"} />
              // </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {notices && <NoticeForm />}
                <TabTable
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Notice}
                  initialPanels={listPanelBar}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <NoticeDetailTab /> },
                    {
                      title: "History",
                      component: <NoticHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
