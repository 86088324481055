import { LOCAL_LAWS_MANAGE_ROUTE } from "@app/products/local-laws/[id]/constant";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
export const colLocalLawsComplaintsByStatus: IColumnFields[] = [
  {
    field: "DateRecorded",
    title: "Recorded",
    format: DATE_FORMAT.DATE,
    linkTo: (dataItem) => ({
      pathname: `${LOCAL_LAWS_MANAGE_ROUTE}/` + dataItem.ID,
    }),
    locked: true,
  },
  { field: "RecordedBy", title: "Recorded By" },
  {
    field: "OrgStructure",
    title: "Org Structure",
  },
  {
    field: "ActionOfficer",
    title: "Action Officer",
  },
  {
    field: "Coordinator",
    title: "Coordinator",
  },
  { field: "DateWork", title: "Work Date" },
  { field: "FileNumber", title: "File Number" },
  {
    field: "EnquiryRefNo",
    title: "Assets Reference Number",
  },
  {
    field: "InsuranceDueStatus", //current column is different from old system. we will change when integrating this
    title: "Status",
  },
  {
    field: "Event_ReferenceNoAdditional",
    title: "Additional Reference Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "OnBehalfOf",
    title: "On Behalf Of",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "Flag_ITSupport",
    title: "IT Support",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "Flag_Safety",
    title: "Safety",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "Flag_Risk",
    title: "Risk",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "Flag_VIP",
    title: "VIP",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];
