import {
  ElementDisplayStatus,
  Event,
  EventHandlerRequest,
  EventMapObj,
  Svc_EventFormAction,
} from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { ContactRelationshipType } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { IFormStepElement } from "@components/cc-form-step/model";
import { isHTML } from "@components/cc-input-picker/util";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import Loading from "@components/loading/Loading";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";

export const DETAIL_STEP = "DetailStep";

const nameOfEventMapObj = nameOfFactory<EventMapObj>();
const nameOfEvent = nameOfFactory<Event>();

export const DetailStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const requiredValidatorCategory = (value: any) => {
  if (value === 0) return "This field is required.";
  return requiredValidator(value);
};

const FormStepElement = observer(
  ({ formRenderProps, localNotificationRef }: IFormStepElement) => {
    const { settings } = useCommonCoreStore();
    const simpleEventHelpURLValue = getStringValueSetting(
      settings[ECorporateSettingsField.CUSTOMERSERVICE_SimpleEvent_HelpURL]
    );

    const isOnbehalfOfRequire = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_CouncillorRequests_MandatoryOBO
      ]
    );

    const { valueGetter, onChange } = formRenderProps;
    const eventFormObj = valueGetter(nameOfEventMapObj("Event"));

    const {
      crmsEventLovs,
      simpleEventChangeHandler,
      uiControl,
      crmsEvent,
      isLoading,
    } = useCRMSEventStore();

    const { pushNotification } = useCCAppNotificationStore();
    const prevOnBehalfOfDisplayName = useRef("");

    const handleTypeOfRequestChange = (event: ComboBoxChangeEvent) => {
      onChange(`${nameOfEventMapObj("Event")}.${nameOfEvent("Type_ID")}`, {
        value: event.target.value?.CustomerService_LookupTable_Id ?? null,
      });
      onChange(`${nameOfEventMapObj("Event")}.${nameOfEvent("Type_Display")}`, {
        value: event.target.value?.Description ?? null,
      });
    };

    const handleSourceChange = (event: ComboBoxChangeEvent) => {
      onChange(`${nameOfEventMapObj("Event")}.${nameOfEvent("Source_ID")}`, {
        value: event.target.value?.Key ?? null,
      });
      onChange(
        `${nameOfEventMapObj("Event")}.${nameOfEvent("Source_Display")}`,
        {
          value: event.target.value?.Value ?? null,
        }
      );
    };

    const handleCategoryChange = (event: ComboBoxChangeEvent) => {
      onChange(
        `${nameOfEventMapObj("Event")}.${nameOfEvent("ServiceStandard_Id")}`,
        {
          value: event.target.value?.Key ?? null,
        }
      );
      onChange(
        `${nameOfEventMapObj("Event")}.${nameOfEvent(
          "ServiceStandardType_Name"
        )}`,
        {
          value: event.target.value?.Value ?? null,
        }
      );

      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_CategoryChange,
        Event: eventFormObj,
        EventArgs: {
          ServiceStandard_Id: event.target?.value.Key,
        },
      };

      simpleEventChangeHandler(
        params,
        "Change category type failed.",
        localNotificationRef
      );
    };

    const handleSelectOnBehalfOf = (event: ComboBoxChangeEvent | null) => {
      let onBehalfOfContactRID = 0;
      let sysTimeStamp = "";
      if (
        eventFormObj?.OnBehalfOf_Contact_RID &&
        eventFormObj?.OnBehalfOf?.Contact?.Contact_ID ===
          event?.value?.Contact_ID &&
        eventFormObj?.OnBehalfOf?.Sys_TimeStamp
      ) {
        onBehalfOfContactRID = eventFormObj.OnBehalfOf_Contact_RID;
        sysTimeStamp = eventFormObj.OnBehalfOf.Sys_TimeStamp;
      }
      let params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_PickOnBehalfOfExternal,
        Event: eventFormObj,
        EventArgs: {},
        isFirstTimeLoad: false,
      };

      if (event?.value) {
        params = {
          ...params,
          EventArgs: {
            OnBehalfOf: {
              ContactRelationshipType_ENUM:
                ContactRelationshipType.CUSTOMERSERVICE_OnBehalfOf,
              Contact: event?.value,
              RID: onBehalfOfContactRID,
              Sys_TimeStamp: sysTimeStamp,
            },
          },
        };
      }

      simpleEventChangeHandler(
        params,
        "Change requestor failed.",
        localNotificationRef
      );
    };

    const handleOpenSimpleEventHelpURL = () => {
      if (!isNil(simpleEventHelpURLValue)) {
        window.open(simpleEventHelpURLValue);
      }
    };

    if (isLoading) {
      return <Loading isLoading={isLoading} />;
    }

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          {uiControl?.DdlCategory?.DisplayStatus ===
            ElementDisplayStatus.Visible &&
            crmsEventLovs?.SimpleCategories &&
            crmsEventLovs?.SimpleCategories.length > 0 && (
              <div className="cc-field">
                <CCLabel title="Category" isMandatory />
                <Field
                  name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                    "ServiceStandard_Id"
                  )}`}
                  textField="Value"
                  dataItemKey="Key"
                  data={crmsEventLovs?.SimpleCategories}
                  component={CCSearchComboBox}
                  validator={requiredValidatorCategory}
                  placeholder="Select category"
                  value={getDropdownValue(
                    valueGetter(
                      `${nameOfEventMapObj("Event")}.${nameOfEvent(
                        "ServiceStandard_Id"
                      )}`
                    ),
                    crmsEventLovs?.SimpleCategories ?? [],
                    "Key"
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    handleCategoryChange(event);
                  }}
                />
              </div>
            )}

          <div className="cc-field">
            <div className="cc-flex cc-justify-between cc-align-item">
              <CCLabel title="Description" isMandatory />
              {!!simpleEventHelpURLValue && (
                <label className="label" onClick={handleOpenSimpleEventHelpURL}>
                  <span className="btn-link">How to log an event</span>
                </label>
              )}
            </div>

            <Field
              name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                "Event_Description"
              )}`}
              placeholder="Description"
              rows={2}
              value={valueGetter(
                `${nameOfEventMapObj("Event")}.${nameOfEvent(
                  "Event_Description"
                )}`
              )}
              component={CCTextArea}
              validator={requiredValidator}
            />
          </div>
        </div>

        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Type of request" isMandatory />
            <Field
              name={`${nameOfEventMapObj("Event")}.${nameOfEvent("Type_ID")}`}
              textField="Description"
              dataItemKey="CustomerService_LookupTable_Id"
              data={crmsEventLovs?.Type}
              component={CCSearchComboBox}
              validator={requiredValidator}
              placeholder="Select type"
              value={getDropdownValue(
                valueGetter(
                  `${nameOfEventMapObj("Event")}.${nameOfEvent("Type_ID")}`
                ),
                crmsEventLovs?.Type ?? [],
                "CustomerService_LookupTable_Id"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                handleTypeOfRequestChange(event);
              }}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Source" isMandatory />
            <Field
              name={`${nameOfEventMapObj("Event")}.${nameOfEvent("Source_ID")}`}
              textField="Value"
              dataItemKey="Key"
              data={crmsEventLovs?.Source}
              component={CCSearchComboBox}
              validator={requiredValidator}
              placeholder="Select source"
              value={getDropdownValue(
                valueGetter(
                  `${nameOfEventMapObj("Event")}.${nameOfEvent("Source_ID")}`
                ),
                crmsEventLovs?.Source ?? [],
                "Key"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                handleSourceChange(event);
              }}
            />
          </div>
        </div>

        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCValueField
              label="Requested by"
              value={valueGetter(
                `${nameOfEventMapObj("Event")}.${nameOfEvent(
                  "RequestedBy_SiteUser_Description"
                )}`
              )}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="On behalf of" isMandatory={isOnbehalfOfRequire} />
            <Field
              uniqueKey="CRMSOnBehalfOf"
              name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                "OnBehalfOf"
              )}.Contact`}
              component={ContactPicker}
              onChange={handleSelectOnBehalfOf}
              onError={(error: any) => {
                pushNotification({
                  type: "error",
                  title: "Pick contact errors",
                  description: error,
                  autoClose: false,
                });
              }}
              placeholder="Select on behalf of"
              displayValue={uiControl?.LitOnBehalfOf.Value}
              removeDisplayValue={() => {
                if (uiControl) {
                  prevOnBehalfOfDisplayName.current =
                    uiControl.LitOnBehalfOf.Value;
                  uiControl.LitOnBehalfOf.Value =
                    crmsEvent?.OnBehalfOf?.Contact?.DisplayName;
                }
              }}
              restoreDisplayValue={() => {
                if (
                  uiControl &&
                  isHTML(prevOnBehalfOfDisplayName.current) &&
                  valueGetter(
                    `${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "OnBehalfOf"
                    )}.Contact`
                  )
                ) {
                  uiControl.LitOnBehalfOf.Value =
                    prevOnBehalfOfDisplayName.current;
                }
              }}
              validator={isOnbehalfOfRequire ? requiredValidator : undefined}
            />
          </div>
        </div>
      </section>
    );
  }
);
