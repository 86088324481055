import { RequestAmalgamateAssessmentObj } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { WorkflowTypes } from "@app/products/property/model";
import { Label } from "@common/stores/products/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

interface IAmalgamateAssessmentCommonButtonProps {
  requestAmalgamateObj: RequestAmalgamateAssessmentObj;
}
export const AmalgamateAssessmentCommonButton = observer(
  ({ requestAmalgamateObj }: IAmalgamateAssessmentCommonButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();
    const { gridSelectedRows, isEmptyData } = useCCProductListViewStore();
    const { id } = useParams<{ id: string }>();

    //Get label
    const assessmentLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Assessment
    );

    const isDisabled = useMemo(() => {
      return (
        (!isEmptyData && gridSelectedRows.length <= 0 && !id) ||
        (!id && isEmptyData)
      );
    }, [gridSelectedRows, id, isEmptyData]);

    return (
      <CCNavButton
        title={`Amalgamate ${assessmentLabel}`}
        disabled={isDisabled}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Amalgamate_Assessment,
              data: {},
              props: {
                requestAmalgamateObj: requestAmalgamateObj,
              },
            },
          ]);
        }}
      />
    );
  }
);
