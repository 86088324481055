import { colLevySumary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/components/levy-sumary/config";
import {
  DTO_ChargeRunLevySummary,
  eLevySummaryTypes,
} from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { NoData } from "@components/no-data/NoData";
import { isNil } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";

interface ILevySummaryProps {
  data: any;
  groupTypeId: eLevySummaryTypes;
}
const nameOf = nameOfFactory<DTO_ChargeRunLevySummary>();
export const LevySummary = ({ data, groupTypeId }: ILevySummaryProps) => {
  const { id } = useParams<{ id: string }>();

  if (isNil(data.dataItem?.GroupId)) return <NoData />;

  return (
    <div className="cc-grid-charge-run-levy-sumary-detail">
      <CCGrid
        dataUrl={`odata/property/internal/chargerunlevysummarybygroupregister/GetLevySummaryByGroup(key=${id},view=${groupTypeId},groupId=${data.dataItem?.GroupId})?$count=true&`}
        columnFields={colLevySumary}
        primaryField={nameOf("Levy")}
        itemPerPage={10}
      />
    </div>
  );
};
