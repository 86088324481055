import { ProvisionalHoldingNumberCell } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/components/form-elements/parcels-to-be-split/components/editor-percentage-cell/_index";
import { FactorCell } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/components/form-elements/parcels-to-be-split/components/factor-cell/_index";
import { DTO_SplitAssessment_Parcels } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/model";
import {
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_SplitAssessment_Parcels>();
export const colParcels: IColumnFields[] = [
  {
    field: nameOf("Provisional_Assessment_Number"),
    title: "Provisional Holding Number",
    format: NUMBER_FORMAT.NUMBER2,
    editable: true,
    editCell: ProvisionalHoldingNumberCell,
  },
  {
    field: nameOf("Factor"),
    title: "Factor",
    minWidth: 120,
    editable: true,
    format: PERCENTAGE_FORMAT.PERCENTAGE_NOT_DECIMAL,
    editCell: FactorCell,
  },
  {
    field: nameOf("Lot"),
    title: "Lot",
  },
  {
    field: nameOf("Plan"),
    title: "Plan",
  },
  {
    field: nameOf("Section"),
    title: "Section",
  },
  {
    field: nameOf("Description"),
    title: "Legal Description",
  },
  {
    field: nameOf("Owner"),
    title: "Owner",
  },
  {
    field: nameOf("Existing_Assessment_NumberX"),
    title: "From Holding Number",
  },
  {
    field: nameOf("Parcel_Id"),
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colUnaffectedParcels: IColumnFields[] = [
  {
    field: nameOf("Lot"),
    title: "Lot",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Plan"),
    title: "Plan",
  },
  {
    field: nameOf("Section"),
    title: "Section",
  },
  {
    field: nameOf("Description"),
    title: "Legal Description",
  },
  {
    field: nameOf("Owner"),
    title: "Owner",
  },
  {
    field: nameOf("Existing_Assessment_NumberX"),
    title: "From Holding Number",
  },
  {
    field: nameOf("Parcel_Id"),
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
