import { history } from "@/AppRoutes";
import { eventHandler, postCRMSEvent } from "@app/products/crms/[id]/api";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import {
  Event,
  EventHandlerRequest,
  EventUpdateTriggers,
  Svc_EventFormAction,
} from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { uniqueArrayNumber } from "@app/products/crms/components/dialogs/access-control/util";
import { colGroupsAndUser } from "@app/products/crms/components/dialogs/special-interest/config";
import { isSuccessResponse } from "@common/apis/util";
import { useIsNew } from "@common/hooks/useIsNew";
import { AccessControl } from "@common/models/accessControl";
import { PickUserGroupOrgButton } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/buttons/pick-user-group-org/_index";
import { IDataUserGroupOrgForm } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/model";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";
export interface ISecurityDialogProps {
  onClosePopup: () => void;
  eventForm: any;
}

export enum AccessControlType {
  Reader = "reader",
  Writer = "writer",
}

const nameOfAccessControl = nameOfFactory<AccessControl>();

export const CrmsSecurityDialog = observer(
  ({ onClosePopup, eventForm }: ISecurityDialogProps) => {
    const { crmsEvent, reLoadCRMS, setIsShowSecurityDialog, setNotification } =
      useCRMSEventStore();
    const isNew = useIsNew();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [newEventForm, setNewEventForm] = useState<Event>(eventForm);

    // Reader Grid
    const [isLoadingReaderGrid, setIsLoadingReaderGrid] =
      useState<boolean>(false);
    const [dataReadersGrid, setDataReadersGrid] = useState<AccessControl[]>([]);
    const [selectedReaderRows, setSelectedReaderRows] = useState<
      AccessControl[]
    >([]);
    const [saveTriggerReader, setSaveTriggerReader] = useState<
      EventUpdateTriggers[]
    >([]);

    // Writer Grid
    const [isLoadingWriterGrid, setIsLoadingWriterGrid] =
      useState<boolean>(false);
    const [dataWritersGrid, setDataWritersGrid] = useState<AccessControl[]>([]);
    const [selectedWriterRows, setSelectedWriterRows] = useState<
      AccessControl[]
    >([]);
    const [saveTriggerWriter, setSaveTriggerWriter] = useState<
      EventUpdateTriggers[]
    >([]);

    const renderDataGrid = (
      eventObj: Event,
      accessControlType: AccessControlType
    ) => {
      switch (accessControlType) {
        case AccessControlType.Reader:
          const svcStdReaderIDs = eventObj?.ServiceStandard?.EventReaders?.map(
            (item: AccessControl) => item.EntityID
          );
          const readers =
            eventObj?.Readers?.map((item: AccessControl) => ({
              ...item,
              _FlagIsDisabled: svcStdReaderIDs?.includes(item.EntityID),
            })) ?? [];
          setDataReadersGrid(readers);
          break;

        case AccessControlType.Writer:
          const svcStdWriterIDs = eventObj?.ServiceStandard?.EventWriters?.map(
            (item: AccessControl) => item.EntityID
          );
          const writers =
            eventObj?.Writers?.map((item: AccessControl) => ({
              ...item,
              _FlagIsDisabled: svcStdWriterIDs?.includes(item.EntityID),
            })) ?? [];
          setDataWritersGrid(writers);
          break;
      }
    };

    const setLoading = (
      accessControlType: AccessControlType,
      isLoading: boolean
    ) => {
      if (accessControlType === AccessControlType.Reader) {
        setIsLoadingReaderGrid(isLoading);
      } else {
        setIsLoadingWriterGrid(isLoading);
      }
    };

    const handleSubmit = async () => {
      appNotificationStore.clearNotifications();
      setIsLoading(true);
      const saveTrigger: EventUpdateTriggers[] = [];
      if (saveTriggerReader?.length > 0) saveTrigger.push(...saveTriggerReader);
      if (saveTriggerWriter?.length > 0) saveTrigger.push(...saveTriggerWriter);
      //Handle Submit dialog
      const newCrmsEvent = {
        ...newEventForm,
        Readers: dataReadersGrid.map((item: any) => {
          delete item._FlagIsDisabled;
          return item;
        }),
        Writers: dataWritersGrid.map((item: any) => {
          delete item._FlagIsDisabled;
          return item;
        }),
        SaveTriggers: uniqueArrayNumber(saveTrigger),
      };
      const response = await postCRMSEvent(newCrmsEvent);
      setIsLoading(false);
      if (isSuccessResponse(response)) {
        setIsShowSecurityDialog(false);
        if (isNew) {
          history.replace(CRMS_ROUTE + "/" + response?.data?.ID.toString(), {
            notification: [
              {
                title: "Record successfully saved.",
                type: "success",
              },
            ],
          });
        } else {
          reLoadCRMS(false);
          setNotification({
            title: "Record successfully saved.",
            type: "success",
          });
        }
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          type: "error",
          description:
            response.data?.Errors ?? response.statusText ?? "Saved failed.",
        });
      }
    };

    const handleAdd = async (
      value: IDataUserGroupOrgForm,
      accessControlType: AccessControlType
    ) => {
      setLoading(accessControlType, true);

      const groups = value?.Groups ?? [];
      const orgStructure = value?.OrgStructure ?? [];
      const users = value?.Users ?? [];
      const data = [...groups, ...orgStructure];

      let hierarchiesIds = data.map((item) => item.ID);
      const userIds = users.map((item) => item.ID);

      if (hierarchiesIds.length === 0 && userIds.length === 0) return;

      const params: EventHandlerRequest = {
        EventFormAction:
          accessControlType === AccessControlType.Reader
            ? Svc_EventFormAction.Form_PickReaderSiteUser
            : Svc_EventFormAction.Form_PickWriterSiteUser,
        Event: newEventForm,
        EventArgs: {
          SiteUsers: userIds ?? [],
          Hierarchies: hierarchiesIds ?? [],
        },
        isFirstTimeLoad: false,
      };
      const response = await eventHandler(params);

      setLoading(accessControlType, false);

      if (isSuccessResponse(response) && response?.data?.ReturnObj?.Event) {
        setNewEventForm(response.data.ReturnObj.Event);

        //On change data to grid
        switch (accessControlType) {
          case AccessControlType.Reader:
            renderDataGrid(
              response.data.ReturnObj.Event,
              AccessControlType.Reader
            );
            setSaveTriggerReader(response.data.ReturnObj.Event.SaveTriggers);
            break;
          case AccessControlType.Writer:
            renderDataGrid(
              response.data.ReturnObj.Event,
              AccessControlType.Writer
            );
            setSaveTriggerWriter(response?.data.ReturnObj.Event.SaveTriggers);
            break;
        }
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          type: "error",
          title: `Add ${accessControlType} failed.`,
        });
      }
    };

    const handleRemove = async (accessControlType: AccessControlType) => {
      const dataSelected =
        accessControlType === AccessControlType.Reader
          ? selectedReaderRows
          : selectedWriterRows;
      let idsSelected = dataSelected.map((value: AccessControl) => {
        return value.EntityID;
      });

      if (idsSelected && idsSelected.length > 0) {
        const newDataGrid =
          accessControlType === AccessControlType.Reader
            ? dataReadersGrid?.filter(
                (item: AccessControl) => !idsSelected.includes(item.EntityID)
              )
            : dataWritersGrid?.filter(
                (item: AccessControl) => !idsSelected.includes(item.EntityID)
              );
        if (accessControlType === AccessControlType.Reader) {
          setDataReadersGrid(newDataGrid);
          setNewEventForm({ ...newEventForm, Readers: newDataGrid });
          setSaveTriggerReader([
            ...saveTriggerReader,
            EventUpdateTriggers.SaveEvent,
            EventUpdateTriggers.UpdateAccessControl,
          ]);
          setSelectedReaderRows([]);
        } else if (accessControlType === AccessControlType.Writer) {
          setDataWritersGrid(newDataGrid);
          setNewEventForm({ ...newEventForm, Writers: newDataGrid });
          setSaveTriggerWriter([
            ...saveTriggerWriter,
            EventUpdateTriggers.SaveEvent,
            EventUpdateTriggers.UpdateAccessControl,
          ]);
          setSelectedWriterRows([]);
        }
      }
    };

    const handleSelectionReaderChange = (dataItem: AccessControl[]) => {
      setSelectedReaderRows(dataItem);
    };
    const handleSelectionWriterChange = (dataItem: AccessControl[]) => {
      setSelectedWriterRows(dataItem);
    };

    useEffectOnce(() => {
      if (crmsEvent) {
        renderDataGrid(crmsEvent, AccessControlType.Reader);
        renderDataGrid(crmsEvent, AccessControlType.Writer);
      }
    });

    return (
      <Form
        onSubmitClick={handleSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { valid, onSubmit } = formRenderProps;
          return (
            <CCDialog
              maxWidth="45%"
              height="auto"
              disabled={isLoading}
              titleHeader="Access Control"
              onClose={onClosePopup}
              bodyElement={
                <FormElement className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <CCLabel title="Readers" />
                        <CCGrid
                          data={dataReadersGrid}
                          canNotSelectField="_FlagIsDisabled"
                          selectableMode={"multiple"}
                          columnFields={colGroupsAndUser}
                          isLoading={isLoadingReaderGrid}
                          selectedRows={selectedReaderRows}
                          primaryField={nameOfAccessControl("EntityID")}
                          onSelectionChange={handleSelectionReaderChange}
                          state={{
                            sort: [
                              {
                                field: nameOfAccessControl("EntityDisplayName"),
                                dir: "asc",
                              },
                            ],
                          }}
                          toolbar={
                            <div className="cc-grid-tools-bar">
                              <PickUserGroupOrgButton
                                maxHeight="85%"
                                onSubmit={(dataItem: IDataUserGroupOrgForm) => {
                                  if (dataItem) {
                                    handleAdd(
                                      dataItem,
                                      AccessControlType.Reader
                                    );
                                  }
                                }}
                              />
                              <Button
                                type="button"
                                iconClass="fas fa-minus"
                                disabled={
                                  dataReadersGrid.length === 0 ||
                                  selectedReaderRows.length === 0
                                }
                                onClick={() =>
                                  handleRemove(AccessControlType.Reader)
                                }
                              />
                            </div>
                          }
                        />
                      </div>

                      <div className="cc-field">
                        <CCLabel title="Writers" />
                        <CCGrid
                          data={dataWritersGrid}
                          canNotSelectField="_FlagIsDisabled"
                          selectableMode={"multiple"}
                          columnFields={colGroupsAndUser}
                          isLoading={isLoadingWriterGrid}
                          selectedRows={selectedWriterRows}
                          primaryField={nameOfAccessControl("EntityID")}
                          onSelectionChange={handleSelectionWriterChange}
                          state={{
                            sort: [
                              {
                                field: nameOfAccessControl("EntityDisplayName"),
                                dir: "asc",
                              },
                            ],
                          }}
                          toolbar={
                            <>
                              <div className="cc-grid-tools-bar">
                                <PickUserGroupOrgButton
                                  maxHeight="85%"
                                  onSubmit={(
                                    dataItem: IDataUserGroupOrgForm
                                  ) => {
                                    if (dataItem) {
                                      handleAdd(
                                        dataItem,
                                        AccessControlType.Writer
                                      );
                                    }
                                  }}
                                />
                                <Button
                                  type="button"
                                  iconClass="fas fa-minus"
                                  disabled={
                                    dataWritersGrid.length === 0 ||
                                    selectedWriterRows.length === 0
                                  }
                                  onClick={() =>
                                    handleRemove(AccessControlType.Writer)
                                  }
                                />
                              </div>
                            </>
                          }
                        />
                      </div>
                    </div>
                  </section>
                </FormElement>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    className={"cc-dialog-button"}
                    onClick={() => {
                      onClosePopup();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!valid}
                    themeColor="primary"
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    onClick={onSubmit}
                    className={"cc-dialog-button"}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
