import { history } from "@/AppRoutes";
import {
  postSvcStandardbatchUpdate,
  serviceStandardBatchUpdateLovs,
  serviceStandardBatchUpdateNew,
  svcStandardBatchUpdateHandler,
} from "@app/products/crms/service-standards/batch-update/api";
import {
  ServiceStandardBatchUpdate,
  ServiceStandardBatchUpdateHandlerRequest,
  ServiceStandardBatchUpdateLovs,
  ServiceStandardBatchUpdateUIControl,
  Svc_FormAction_ServiceStandardBatchUpdate,
} from "@app/products/crms/service-standards/batch-update/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ServiceStandardBatchUpdateStore {
  private _isLoading: boolean = false;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _svcStandardBatchUpdate?: ServiceStandardBatchUpdate = undefined;
  private _svcStandardBatchUpdateLovs?: ServiceStandardBatchUpdateLovs =
    undefined;
  private _responseLoadError?: APIResponseError = undefined;
  private _isFormModified: boolean = false;
  private _uiControl?: ServiceStandardBatchUpdateUIControl = undefined;

  private _isExpandedSystemInterface: boolean = false;
  private _isExpandedSpecialInterest: boolean = false;
  private _isExpandedWorkflowSecurity: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isExpandedSystemInterface() {
    return this._isExpandedSystemInterface;
  }
  setIsExpandedSystemInterface = (isExpandedSystemInterface: boolean) => {
    runInAction(() => {
      this._isExpandedSystemInterface = isExpandedSystemInterface;
    });
  };

  get isExpandedSpecialInterest() {
    return this._isExpandedSpecialInterest;
  }
  setIsExpandedSpecialInterest = (isExpandedSpecialInterest: boolean) => {
    runInAction(() => {
      this._isExpandedSpecialInterest = isExpandedSpecialInterest;
    });
  };

  get isExpandedWorkflowSecurity() {
    return this._isExpandedWorkflowSecurity;
  }
  setIsExpandedWorkflowSecurity = (isExpandedWorkflowSecurity: boolean) => {
    runInAction(() => {
      this._isExpandedWorkflowSecurity = isExpandedWorkflowSecurity;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._svcStandardBatchUpdate = undefined;
      this._isLoading = false;
      this._onSubmit = undefined;
      this._responseLoadError = undefined;
      this._svcStandardBatchUpdateLovs = undefined;
      this._isFormModified = false;
      this._uiControl = undefined;
      this._isExpandedSpecialInterest = false;
      this._isExpandedSystemInterface = false;
      this._isExpandedWorkflowSecurity = false;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isFormModified() {
    return this._isFormModified;
  }
  setIsFormModified = (isFormModified: boolean) => {
    runInAction(() => {
      this._isFormModified = isFormModified;
    });
  };

  get uiControl() {
    return this._uiControl;
  }
  setUIControl = (uiControl?: ServiceStandardBatchUpdateUIControl) => {
    runInAction(() => {
      this._uiControl = uiControl;
    });
  };

  get svcStandardBatchUpdate() {
    return toJS(this._svcStandardBatchUpdate);
  }
  setSvcStandardBatchUpdate = (
    svcStandardBatchUpdate?: ServiceStandardBatchUpdate
  ) => {
    runInAction(() => {
      this._svcStandardBatchUpdate = svcStandardBatchUpdate;
    });
  };

  get svcStandardBatchUpdateLovs() {
    return toJS(this._svcStandardBatchUpdateLovs);
  }
  setSvcStandardBatchUpdateLovs = (
    svcStandardBatchUpdateLovs?: ServiceStandardBatchUpdateLovs
  ) => {
    runInAction(() => {
      this._svcStandardBatchUpdateLovs = svcStandardBatchUpdateLovs;
    });
  };

  loadSvcStandardBatchUpdate = async () => {
    this.setIsLoading(true);
    const [response, responseLovs] = await Promise.all([
      serviceStandardBatchUpdateNew(),
      serviceStandardBatchUpdateLovs(),
    ]);
    if (
      isSuccessResponse(response) &&
      response.data?.ReturnObj &&
      isSuccessResponse(responseLovs) &&
      responseLovs.data
    ) {
      this.setSvcStandardBatchUpdateLovs(responseLovs.data);
      const params: ServiceStandardBatchUpdateHandlerRequest = {
        ServiceStandardBatchUpdateFormAction:
          Svc_FormAction_ServiceStandardBatchUpdate.SystemInitialise,
        ServiceStandardBatchUpdate: response.data?.ReturnObj,
        EventArgs: {},
        IsFirstTimeLoad: true,
      };
      const responseHandler = await svcStandardBatchUpdateHandler(params);
      if (
        isSuccessResponse(responseHandler) &&
        responseHandler.data?.ReturnObj
      ) {
        this.setSvcStandardBatchUpdate(
          responseHandler.data?.ReturnObj?.ServiceStandardBatchUpdate
        );
        this.setUIControl(responseHandler.data?.ReturnObj?.UIControl);
      } else {
        this.setResponseLoadError({
          status: responseHandler.status,
          error: responseHandler.data?.Errors ?? responseHandler.error,
        });
      }
    } else {
      this.setResponseLoadError({
        status: response.status,
        error: response.data?.Errors ?? response.error,
      });
    }
    this.setIsLoading(false);
  };

  svcStandardBatchUpdateChangeHandler = async (
    params: ServiceStandardBatchUpdateHandlerRequest,
    errorMsg: string
  ) => {
    this.setIsLoading(true);
    let isSuccess = false;
    const response = await svcStandardBatchUpdateHandler(params);

    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setIsFormModified(true);
      this.setSvcStandardBatchUpdate(
        response.data.ReturnObj?.ServiceStandardBatchUpdate
      );
      this.setUIControl(response.data.ReturnObj.UIControl);
      isSuccess = true;
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: errorMsg,
        type: "error",
        description: response.data?.Errors || response.error,
      });
      isSuccess = false;
    }
    this.setIsLoading(false);
    return isSuccess;
  };

  saveSvcStandardbatchUpdate = async (params: ServiceStandardBatchUpdate) => {
    this.setIsLoading(true);
    const response = await postSvcStandardbatchUpdate(params);
    if (isSuccessIdentityPacket(response)) {
      if (this.isFormModified) this.setIsFormModified(false);
      history.goBack();
    } else {
      this.setIsLoading(false);
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Service standard batch update failed.",
        type: "error",
        description: response.data?.Errors ?? response.error,
      });
    }
  };
}

const serviceStandardBatchUpdateStoreContext = createContext(
  new ServiceStandardBatchUpdateStore()
);
export const useSvcStandardBatchUpdateStore = () => {
  return useContext(serviceStandardBatchUpdateStoreContext);
};
