import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const postDeactiveSecurityTemplate = async (
  securityTemplateIds: number[]
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `api/core/internal/security-templates/deactivate`,
      securityTemplateIds
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
