import { useCRMSServiceStandardStore } from "@app/products/crms/service-standards/[id]/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export const CRMSServiceStandardsDetailsTab = observer(() => {
  const isNew = useIsNew();
  const { lastSelectedId } = useCCProductListViewStore();
  const {
    crmsServiceStandard,
    isLoadingDetails,
    loadCRMSServiceStandardDetails,
  } = useCRMSServiceStandardStore();
  const params: { id: string } = useParams();
  const serviceStandardId = params.id || lastSelectedId;

  useEffect(() => {
    if (!isNew && !params.id) loadCRMSServiceStandardDetails(serviceStandardId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceStandardId]);

  if (isLoadingDetails) return <Loading isLoading={isLoadingDetails} />;
  if (!crmsServiceStandard) return <NoData />;

  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Send to coordinator:"}
          value={crmsServiceStandard?.SendToCoordinator ? "Yes" : "No"}
        />
        <ProductReferenceRow
          title={"Send to action officer:"}
          value={crmsServiceStandard?.SendToActionOfficer ? "Yes" : "No"}
        />
        <ProductReferenceRow
          title={"Allow anonymous:"}
          value={crmsServiceStandard?.AllowAnonymous ? "Yes" : "No"}
        />
        <ProductReferenceRow
          title={"Coordinator:"}
          value={crmsServiceStandard?.Coordinator?.DisplayName}
        />
        {!!crmsServiceStandard?.ActionOfficer?.DisplayName && (
          <ProductReferenceRow
            title={"Action officer:"}
            value={crmsServiceStandard?.ActionOfficer?.DisplayName}
          />
        )}
        <ProductReferenceRow
          title={"Created date:"}
          value={formatDisplayValue(
            crmsServiceStandard?.Sys_CreatedDate,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title={"Created by:"}
          value={crmsServiceStandard?.Sys_CreatedBy}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
