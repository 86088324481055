import { useAnimalsSettingStore } from "@app/products/animals/system-admin/settings/_category/store";
import { DynamicGridFields } from "@app/products/animals/system-admin/settings/_id/components/dynamic-grid-fields/_index";
import { switchOptionsValueNumberString } from "@app/products/animals/system-admin/settings/_id/main/config";
import { ICommonFormProps } from "@app/products/animals/system-admin/settings/_id/main/model";
import { useAnimalsSettingManageStore } from "@app/products/animals/system-admin/settings/_id/store";
import { DECIMAL_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { NumericTextboxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/numeric-textbox/_index";
import { DatePickerGlobalSetting } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/pick-date-input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { getConfigDataField } = useAnimalsSettingStore();
  const { isEditing } = useAnimalsSettingManageStore();
  const { valueGetter, onChange } = formRenderProps;

  const restrictDateFields = [
    ECorporateSettingsField.Animals_RestrictDateRangeStart,
    ECorporateSettingsField.Animals_RestrictDateRangeEnd,
  ];

  const animalsProfilePhotoAttachmentPrefixData = getConfigDataField(
    ECorporateSettingsField.Animals_ProfilePhoto_Attachment_Prefix
  );
  const animalsLandareaData = getConfigDataField(
    ECorporateSettingsField.Animals_Landarea
  );
  const animalsMicrochipNumberMaxLengthData = getConfigDataField(
    ECorporateSettingsField.Animals_MicrochipNumberMaxLength
  );
  const animalsInvoiceBasedOnOwnerData = getConfigDataField(
    ECorporateSettingsField.Animals_InvoiceBasedOnOwner
  );

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <NumericTextboxGlobalSettings
          data={animalsLandareaData}
          isEditing={isEditing}
          format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
        />
        {restrictDateFields.map((field: ECorporateSettingsField) => (
          <DatePickerGlobalSetting
            data={getConfigDataField(field)}
            isEditing={isEditing}
          />
        ))}

        <InputGlobalSettings
          data={animalsProfilePhotoAttachmentPrefixData}
          isEditing={isEditing}
        />

        <NumericTextboxGlobalSettings
          data={animalsMicrochipNumberMaxLengthData}
          isEditing={isEditing}
        />

        <SwitchGlobalSettings
          data={animalsInvoiceBasedOnOwnerData}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
          customChecked={
            valueGetter(
              `${ECorporateSettingsField.Animals_InvoiceBasedOnOwner}`
            ) === switchOptionsValueNumberString.TRUE
          }
          customOnChange={(event: SwitchChangeEvent) => {
            onChange(`${ECorporateSettingsField.Animals_InvoiceBasedOnOwner}`, {
              value: event?.value
                ? switchOptionsValueNumberString.TRUE
                : switchOptionsValueNumberString.FALSE,
            });
          }}
        />
      </div>
      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <DynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
