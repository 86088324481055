import {
  DropDownList,
  DropDownListChangeEvent,
  DropDownListProps,
} from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import React, { useCallback, useMemo } from "react";
interface ICCDropDownListProps {
  validationMessage?: string | null;
  visited?: boolean;
  isKeyValueDropdown?: boolean;
}
const DEFAULT_TEXT_FIELD = "Value";
const DEFAULT_VALUE_FIELD = "Key";

export const CCDropDownList = (
  props: ICCDropDownListProps & DropDownListProps
) => {
  const {
    validationMessage,
    visited,
    isKeyValueDropdown,
    value,
    onChange,
    data,
    textField,
    dataItemKey,
    ...others
  } = props;
  const getPropsDataFields = (data?: string, defaultValue?: string) => {
    if (isKeyValueDropdown) return data ? data : defaultValue;
    if (data) return data;
    return undefined;
  };

  const newTextField = getPropsDataFields(textField, DEFAULT_TEXT_FIELD);
  const newDataItemKey = getPropsDataFields(dataItemKey, DEFAULT_VALUE_FIELD);

  const dropdownValue = useMemo(() => {
    if (isKeyValueDropdown)
      return data?.find(
        (obj) => obj[newDataItemKey ?? DEFAULT_VALUE_FIELD] === value
      );
    return value;
  }, [value, data, isKeyValueDropdown, newDataItemKey]);

  const handleOnChange = useCallback(
    (event: DropDownListChangeEvent) => {
      if (!onChange) return;
      if (isKeyValueDropdown)
        return onChange({
          ...event,
          value: event.value[newDataItemKey ?? DEFAULT_VALUE_FIELD],
        });
      onChange(event);
    },
    [onChange, newDataItemKey, isKeyValueDropdown]
  );

  return (
    <>
      <DropDownList
        data={data}
        value={dropdownValue}
        onChange={handleOnChange}
        textField={newTextField}
        dataItemKey={newDataItemKey}
        {...others}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
