import { eventEmitter } from "@/App";
import { postWorkflowIssue } from "@app/products/animals/[id]/api";
import {
  Registration,
  Svc_Registration_Workflow_Issue,
} from "@app/products/animals/[id]/model";
import {
  calculationRegistrationFeeOutstanding,
  getRegistrationFeeOutstandingList,
} from "@app/products/animals/components/forms/components/form-element/components/workflow-elements/animal-registration-details/api";
import { colRegistrationFeeOutstanding } from "@app/products/animals/components/forms/components/form-element/components/workflow-elements/animal-registration-details/config";
import { Svc_Registration_FeeOutstanding } from "@app/products/animals/components/forms/components/form-element/components/workflow-elements/animal-registration-details/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { clearHoursOfDate, getUUID, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { dateRangeValidator } from "@common/utils/form-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { cloneDeep, isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IAnimalsIssueDialogProps {
  onClose: () => void;
  onSubmit: (
    value: Svc_Registration_Workflow_Issue,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => void;
  isLoading?: boolean;
  data?: Registration;
  dialogTitle?: string;
  isShowLodgeDate?: boolean;
  isShowFee?: boolean;
  isOnlyUpdateRegistration?: boolean;
}

const OUTSTANDING_FEES_GRID_ID = getUUID();
const nameOf = nameOfFactory<Registration>();
export const AnimalsIssueDialog = observer(
  ({
    onClose,
    onSubmit,
    isLoading,
    data,
    dialogTitle = "Animal Registration Details",
    isShowLodgeDate,
    isShowFee = false,
    isOnlyUpdateRegistration = false,
  }: IAnimalsIssueDialogProps) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    var processInitialData: Registration | undefined = cloneDeep(data);

    const { settings } = useCommonCoreStore();
    const animalsEnableDiscount = getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_EnableDiscount]
    );
    const animalsAnimalsDiscountName = getStringValueSetting(
      settings[ECorporateSettingsField.Animals_Discount_Name]
    );

    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const [feesData, setFeesData] =
      useState<Svc_Registration_FeeOutstanding[]>();
    const [initialData, setInitialData] = useState<Registration | undefined>(
      processInitialData
    );
    const [initialOldData, setInitialOldData] = useState<
      Registration | undefined
    >(processInitialData);
    const [isShowOSFeeGrid, setIsShowOSFeeGrid] = useState<boolean>(isShowFee);
    const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
    const [isLoadingCalculate, setIsLoadingCalculate] =
      useState<boolean>(false);
    const [isShowCalculateButton, setIsShowCalculateButton] = useState<boolean>(
      animalsEnableDiscount
    );

    const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
      const newRegistration = cloneDeep(event.values) as Registration;
      newRegistration.Date_EffectiveFrom = clearHoursOfDate(
        newRegistration.Date_EffectiveFrom
      );
      newRegistration.Date_EffectiveTo = clearHoursOfDate(
        newRegistration.Date_EffectiveTo
      );
      var submitValue = {
        NewRegistration: newRegistration,
        OldRegistration: initialOldData,
      } as Svc_Registration_Workflow_Issue;
      onSubmit(submitValue, notificationRef);
    };

    const calculateOnClick = async () => {
      setIsLoadingCalculate(true);
      const response = await calculationRegistrationFeeOutstanding(
        processInitialData
      );
      setIsLoadingCalculate(false);
      if (isSuccessResponse(response) && response?.data) {
        setFeesData(response?.data?.ReturnObj ?? []);
        setIsShowCalculateButton(false);
        setIsShowOSFeeGrid(true);
        eventEmitter.emit(CCGridEventType.RefreshOData, {
          gridIds: [OUTSTANDING_FEES_GRID_ID],
        });
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: "Calculated fees failed.",
          type: "error",
          description: response.error ?? response.statusText,
        });
      }
    };

    const loadRegistrationFeeOutstandingList = async () => {
      let errorResponse = undefined;
      const response = await getRegistrationFeeOutstandingList(
        data?.Registration_ID
      );
      if (isSuccessResponse(response) && response?.data) {
        setFeesData(response?.data ?? []);
      } else {
        errorResponse = {
          status: response.status,
          error: response.error ?? "Load registration fee outstanding failed.",
        };
      }
      setResponseLoadError(errorResponse);
    };

    const loadWorkflowIssue = async () => {
      if (isOnlyUpdateRegistration) {
        var newData: Registration | undefined = cloneDeep(processInitialData);
        if (processInitialData) {
          newData = {
            ...processInitialData,
            Date_EffectiveFrom: new Date(),
          };
        }
        setInitialData(newData);
      } else {
        let errorResponse = undefined;
        if (!isNil(processInitialData)) {
          const response = await postWorkflowIssue(processInitialData);
          if (
            isSuccessResponse(response) &&
            response.data?.ReturnObj?.NewRegistration
          ) {
            var responseNewData: Registration | undefined = cloneDeep(
              response.data?.ReturnObj?.NewRegistration
            );
            if (response.data?.ReturnObj) {
              responseNewData = {
                ...response.data?.ReturnObj?.NewRegistration,
                Date_EffectiveFrom: new Date(),
              };
            }
            setInitialData(responseNewData);
            setInitialOldData(response.data?.ReturnObj?.OldRegistration);
          } else {
            errorResponse = {
              status: response.status,
              error: response.error ?? "Load issue failed.",
            };
          }
        } else {
          errorResponse = {
            status: APIResponseStatus.INTERNAL_SERVER_ERROR,
            error: "Load registration failed.",
          };
        }
        setResponseLoadError(errorResponse);
      }
    };

    const firstStateLoad = async () => {
      setIsLoadingDialog(true);
      await loadRegistrationFeeOutstandingList();
      await loadWorkflowIssue();
      setIsLoadingDialog(false);
    };

    useEffectOnce(() => {
      firstStateLoad();
      if (initialData?.AnimalType?.Flag_Discount === false) {
        setIsShowCalculateButton(false);
      }
    });

    const formValidator = useCallback((value) => {
      return dateRangeValidator(
        value,
        nameOf("Date_EffectiveFrom"),
        nameOf("Date_EffectiveTo")
      );
    }, []);

    return (
      <Form
        onSubmitClick={handleOnSubmitClick}
        initialValues={initialData}
        validator={formValidator}
        key={JSON.stringify(initialData)}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                height="auto"
                maxWidth="40%"
                titleHeader={dialogTitle}
                onClose={onClose}
                disabled={isLoading}
                bodyElement={
                  isLoadingDialog ? (
                    <Loading isLoading={isLoadingDialog} />
                  ) : responseLoadError ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={async () => {
                        setIsLoadingDialog(true);
                        await loadRegistrationFeeOutstandingList();
                        setIsLoadingDialog(false);
                      }}
                    />
                  ) : (
                    <div className="cc-form">
                      <CCLocalNotification ref={notificationRef} />
                      <section className="cc-field-group">
                        <div className="cc-form-cols-1">
                          {isShowLodgeDate && (
                            <div className="cc-field">
                              <CCLabel title="Lodged on" isMandatory />
                              <Field
                                name={nameOf("Date_Lodged")}
                                format={DATE_FORMAT.DATE_CONTROL}
                                component={CCDatePicker}
                                validator={requiredValidator}
                              />
                            </div>
                          )}
                          <div className="cc-field">
                            <CCLabel
                              title="Effective from"
                              isMandatory={!isOnlyUpdateRegistration}
                            />
                            <Field
                              name={nameOf("Date_EffectiveFrom")}
                              format={DATE_FORMAT.DATE_CONTROL}
                              component={CCDatePicker}
                              validator={
                                !isOnlyUpdateRegistration
                                  ? requiredValidator
                                  : undefined
                              }
                              max={
                                valueGetter(nameOf("Date_EffectiveTo"))
                                  ? new Date(
                                      valueGetter(nameOf("Date_EffectiveTo"))
                                    )
                                  : undefined
                              }
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel
                              title="Effective to"
                              isMandatory={!isOnlyUpdateRegistration}
                            />
                            <Field
                              name={nameOf("Date_EffectiveTo")}
                              format={DATE_FORMAT.DATE_CONTROL}
                              component={CCDatePicker}
                              validator={
                                !isOnlyUpdateRegistration
                                  ? requiredValidator
                                  : undefined
                              }
                              min={
                                valueGetter(nameOf("Date_EffectiveFrom"))
                                  ? new Date(
                                      valueGetter(nameOf("Date_EffectiveFrom"))
                                    )
                                  : undefined
                              }
                            />
                          </div>
                        </div>

                        {isShowOSFeeGrid && (
                          <>
                            <br />
                            <hr className="cc-divider" />
                            <div className="cc-form-cols-1">
                              <div className="cc-field">
                                <label className="cc-label">
                                  Outstanding fees
                                </label>
                                <CCGrid
                                  gridId={OUTSTANDING_FEES_GRID_ID}
                                  data={feesData ?? []}
                                  columnFields={colRegistrationFeeOutstanding}
                                  primaryField="Id"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </section>
                    </div>
                  )
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button className={"cc-dialog-button"} onClick={onClose}>
                      Cancel
                    </Button>
                    {isShowCalculateButton && (
                      <Button
                        className={"cc-dialog-button"}
                        onClick={calculateOnClick}
                        iconClass={
                          isLoadingCalculate ? "fas fa-spinner fa-spin" : ""
                        }
                        disabled={
                          isLoading || isLoadingDialog || isLoadingCalculate
                        }
                      >
                        {!isEmpty(animalsAnimalsDiscountName)
                          ? animalsAnimalsDiscountName
                          : "Discount"}
                      </Button>
                    )}
                    <Button
                      className="cc-dialog-button"
                      themeColor="primary"
                      disabled={
                        !formRenderProps.valid ||
                        isLoading ||
                        isLoadingDialog ||
                        isLoadingCalculate
                      }
                      onClick={formRenderProps.onSubmit}
                      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
