import { setupKennelPickSiteAddress } from "@app/products/animals/kennels/[id]/api";
import { renderContact } from "@app/products/animals/kennels/[id]/component/child-screens/general/form-element/components/animal-owner-section/util";
import {
  AnimalsKennel,
  KennelFormHandlerRequest,
  KennelUpdateTriggers,
  Svc_AnimalKennel,
  Svc_FormAction_Kennel,
} from "@app/products/animals/kennels/[id]/model";
import { useAnimalsKennelStore } from "@app/products/animals/kennels/[id]/store";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { PropertyDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/_index";
import { isSuccessResponse } from "@common/apis/util";
import { Address_BuildAddress } from "@common/input-pickers/address/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { isHTML } from "@components/cc-input-picker/util";
import { CCLabel } from "@components/cc-label/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./_index.scss";

interface IAnimalParentSectionProps {
  formRenderProps: FormRenderProps;
  isOwner?: boolean;
}

const nameOf = nameOfFactory<AnimalsKennel>();
const nameOfMapObj = nameOfFactory<Svc_AnimalKennel>();
const getNameOf = (name: keyof AnimalsKennel) =>
  `${nameOfMapObj("Kennel")}.${nameOf(name)}`;

export const AnimalKennelOwnerSection = observer(
  (props: IAnimalParentSectionProps) => {
    const { formRenderProps, isOwner = false } = props;
    const { valueGetter, onChange } = formRenderProps;
    const { UIControl, loadKennelHandler, animalsKennel } =
      useAnimalsKennelStore();
    const { pushNotification } = useCCAppNotificationStore();

    const [initialBuildAddress, setInitialBuildAddress] =
      useState<Address_BuildAddress>();
    const [isLoadingDialog, setIsLoadingDialog] = useState(false);

    // contact and property alert
    const prevHolderDisplayName = useRef<string>("");
    const prevAddressDisplayName = useRef<string>("");
    const prevAdditionalHolderDisplayName = useRef<string>("");
    const [displayAddress, setDisplayAddress] = useState<string>(
      UIControl?.LitAddressDisplay?.Value ?? ""
    );
    const [displayHolder, setDisplayHolder] = useState<string>(
      UIControl?.LitContact?.Value ?? ""
    );
    const [displayAdditionalHolder, setDisplayAdditionalHolder] =
      useState<string>(UIControl?.LitAdditionalContact.Value ?? "");

    const kennel = useMemo(() => {
      return {
        kennelAdditionalOrOwner: isOwner
          ? "Owner"
          : ("AdditonalHolder" as keyof AnimalsKennel),
        kennelHolderDisplay: isOwner ? "holder" : "additional holder",
        kennelAddress: isOwner
          ? UIControl?.LitContactAddress?.Value
          : UIControl?.LitAdditionalContactAddress?.Value,
        kennelUpdateTriggersContact: isOwner
          ? KennelUpdateTriggers.UpdateOwner
          : KennelUpdateTriggers.UpdateAdditionalHolder,
      };
    }, [UIControl, isOwner]);
    const animalKennelObj = valueGetter(nameOfMapObj("Kennel"));
    const handleOpenDialog = async () => {
      if (!animalKennelObj) return;
      setIsLoadingDialog(true);
      const response = await setupKennelPickSiteAddress(
        valueGetter(getNameOf("Address"))
      );
      setIsLoadingDialog(false);

      if (isSuccessResponse(response) && response.data) {
        setInitialBuildAddress(response.data.ReturnObj);
      } else {
        pushNotification({
          autoClose: false,
          title: "Setup address fail.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    };

    const handleSubmitSiteAddress = (
      buildAddress: Address_BuildAddress | null
    ) => {
      const address = buildAddress?.Address;
      let params: KennelFormHandlerRequest = {
        KennelFormAction: Svc_FormAction_Kennel.Form_PickAddress,
        Kennel: animalKennelObj,
        KennelArgs: { Address: address },
        IsFirstTimeLoad: false,
      };

      loadKennelHandler(params, "Change address failed.");
    };

    const handleChangeContact = (event: ComboBoxChangeEvent) => {
      const { value } = event;
      const saveTriggers = valueGetter(getNameOf("SaveTriggers")) ?? [];
      if (!saveTriggers.includes(kennel.kennelUpdateTriggersContact)) {
        saveTriggers.push(kennel.kennelUpdateTriggersContact);
        onChange(getNameOf("SaveTriggers"), { value: saveTriggers });
      }

      onChange(`${getNameOf(kennel.kennelAdditionalOrOwner)}.Contact`, {
        value: value,
      });

      const params: KennelFormHandlerRequest = {
        KennelFormAction: isOwner
          ? Svc_FormAction_Kennel.Form_PickContact
          : Svc_FormAction_Kennel.Form_PickAdditionalContact,
        Kennel: animalKennelObj,
        KennelArgs: {
          [isOwner ? "Owner" : "AdditonalHolder"]: {
            Contact: value,
          },
        },
        IsFirstTimeLoad: false,
      };

      loadKennelHandler(
        params,
        `Change ${isOwner ? "holder" : "additional holder"} failed.`
      );
    };

    const updateSaveTriggers = (triggers: KennelUpdateTriggers) => {
      let saveTriggers: KennelUpdateTriggers[] =
        valueGetter(getNameOf("SaveTriggers")) ?? [];

      if (!Array.isArray(saveTriggers)) saveTriggers = [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange(getNameOf("SaveTriggers"), {
          value: saveTriggers,
        });
      }
    };

    const siteAddressValidator = useCallback(() => {
      return requiredValidator(displayAddress);
    }, [displayAddress]);

    const contactOwnerValidator = useCallback(
      (data: any) => {
        if (isOwner) {
          return requiredValidator(UIControl?.LitContact?.Value);
        }
        return requiredValidator(data);
      },
      [UIControl?.LitContact?.Value, isOwner]
    );

    const handleRemoveContactDisplayValue = () => {
      if (UIControl) {
        if (isOwner) {
          prevHolderDisplayName.current = displayHolder;
          setDisplayHolder(animalsKennel?.Owner?.Contact.DisplayName ?? "");
        } else {
          prevAdditionalHolderDisplayName.current = displayAdditionalHolder;
          setDisplayAdditionalHolder(
            animalsKennel?.AdditonalHolder?.Contact.DisplayName ?? ""
          );
        }
      }
    };

    const handleRestoreContactDisplayValue = () => {
      if (
        UIControl &&
        isHTML(
          isOwner
            ? prevHolderDisplayName.current
            : prevAdditionalHolderDisplayName.current
        ) &&
        valueGetter(`${getNameOf(kennel.kennelAdditionalOrOwner)}.Contact`)
      ) {
        if (isOwner) {
          setDisplayHolder(prevHolderDisplayName.current);
        } else {
          setDisplayAdditionalHolder(prevAdditionalHolderDisplayName.current);
        }
      }
    };

    useEffect(() => {
      setDisplayHolder(UIControl?.LitContact?.Value);
      setDisplayAddress(UIControl?.LitAddressDisplay?.Value);
      setDisplayAdditionalHolder(UIControl?.LitAdditionalContact?.Value);
    }, [UIControl]);

    return (
      <section className="cc-field-group cc-animal-kennel-form-elements">
        {/* ROW 1 */}
        {isOwner && (
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Registered address" isMandatory />
              <Field
                uniqueKey="AddressKennelPicker"
                name={getNameOf("Address")}
                component={PropertyDetail}
                initialData={initialBuildAddress}
                placeholder={"Select site address"}
                formRenderProps={formRenderProps}
                validator={siteAddressValidator}
                isSearchPropertyAddresses={true}
                onError={(error: any) => {
                  pushNotification({
                    type: "error",
                    title: "Select site address failed.",
                    description: error,
                    autoClose: false,
                  });
                }}
                value={displayAddress}
                onChangeEventHandler={handleSubmitSiteAddress}
                updateSaveTriggers={() => {
                  updateSaveTriggers(KennelUpdateTriggers.UpdateAddress);
                }}
                onSubmit={handleSubmitSiteAddress}
                onButtonClick={handleOpenDialog}
                isLoadingDialog={isLoadingDialog}
                removeDisplayValue={() => {
                  if (UIControl) {
                    prevAddressDisplayName.current = displayAddress;
                    setDisplayAddress(
                      animalsKennel?.Address?.Formatted_SingleLine ?? ""
                    );
                  }
                }}
                restoreDisplayValue={() => {
                  if (
                    UIControl &&
                    isHTML(prevAddressDisplayName.current) &&
                    valueGetter(`${getNameOf("Address")}.Formatted_SingleLine`)
                  ) {
                    setDisplayAddress(prevAddressDisplayName.current);
                  }
                }}
              />
            </div>
            <CCValueField
              value={
                isHTML(UIControl?.LitAddress?.Value)
                  ? sanitizeHtml(UIControl?.LitAddress?.Value)
                  : UIControl?.LitAddress?.Value
              }
              label="Registered address details"
            />
          </div>
        )}
        {/* ROW 2 */}
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <div className="cc-same-as-property">
              <CCLabel
                title={capitalize(kennel.kennelHolderDisplay)}
                isMandatory
              />
              {/* Justin said if address won't return Owner id, then no need for
              this button. keep this in case it come back */}
              {/*     {isOwner && (
                <Button
                  fillMode="flat"
                  className="cc-animal-kennel-owner-button"
                  iconClass="fa fa-chevron-circle-right"
                  onClick={() => {
                    let params: KennelFormHandlerRequest = {
                      KennelFormAction:
                        Svc_FormAction_Kennel.Form_KennelSameAsPropertyOwner,
                      Kennel: animalKennelObj,
                      KennelArgs: {},
                      IsFirstTimeLoad: false,
                    };

                    loadKennelHandler(params, "Change address failed.");
                  }}
                />
              )} */}
            </div>
            <Field
              uniqueKey={
                isOwner ? "KennelOwnerPicker" : "KennelAdditionalPicker"
              }
              name={`${getNameOf(kennel.kennelAdditionalOrOwner)}.Contact`}
              component={ContactPicker}
              onError={(error: any) => {
                pushNotification({
                  type: "error",
                  title: `Pick ${kennel.kennelHolderDisplay} errors`,
                  description: error,
                  autoClose: false,
                });
              }}
              placeholder={`Select ${kennel.kennelHolderDisplay}`}
              validator={contactOwnerValidator}
              onChange={handleChangeContact}
              displayValue={isOwner ? displayHolder : displayAdditionalHolder}
              removeDisplayValue={handleRemoveContactDisplayValue}
              restoreDisplayValue={handleRestoreContactDisplayValue}
            />
          </div>
          <CCValueField
            value={
              isHTML(kennel.kennelAddress)
                ? sanitizeHtml(kennel.kennelAddress)
                : kennel.kennelAddress
            }
            label="Postal address"
          />
          <CCValueField
            label="Contact"
            value={renderContact(
              valueGetter(getNameOf(`${kennel.kennelAdditionalOrOwner}`))
                ?.Contact
            )}
          />
        </div>
      </section>
    );
  }
);
