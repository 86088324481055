import { useModifyAssessmentDialogStore } from "@app/products/property/assessments/components/form-steps/modify-assessment/store";
import { useNewAssessmentDialogStore } from "@app/products/property/assessments/components/form-steps/new-assessment/store";
import { useNewMasterPropertyDialogStore } from "@app/products/property/assessments/master-properties/components/form-steps/add-new-master-property/store";
import { useModifyMasterPropertyDialogStore } from "@app/products/property/assessments/master-properties/components/form-steps/modify-master-property/store";
import { useModifyDeferredDutyDialogStore } from "@app/products/property/deferred-duty/components/action-bar/dialogs/modify-deferred-duty/store";
import { useRaiseDeferredDutyInterestDialogStore } from "@app/products/property/deferred-duty/components/action-bar/dialogs/raise-interest/store";
import { EListSubmitButton } from "@app/products/property/model";
import { useModifyParcelDialogStore } from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React from "react";
interface ConfirmCloseButtonProps {
  onClose: () => void;
  onConfirmYes: (e: any) => void;
  onConfirmNo: () => void;
}

export const ConfirmCloseButton = observer(
  ({ onConfirmYes, onClose, onConfirmNo }: ConfirmCloseButtonProps) => {
    // TODO: Refactor later
    const { isLoadingOnDialogCancel, isLoadingOnDialog } =
      useNewAssessmentDialogStore();
    const {
      isLoadingOnDialogCancel: isLoadingOnDialogCancelAssessment,
      isLoadingOnDialog: isLoadingOnDialogAssessment,
    } = useModifyAssessmentDialogStore();
    const {
      isLoadingOnDialogCancel: isLoadingOnDialogCancelParcel,
      isLoadingOnDialog: isLoadingOnDialogParcel,
    } = useModifyParcelDialogStore();
    const {
      isLoadingOnDialogCancel: isLoadingOnDialogCancelModifyMasterProperty,
      isLoadingOnDialog: isLoadingOnDialogModifyMasterProperty,
    } = useModifyMasterPropertyDialogStore();
    const {
      isLoadingOnDialogCancel: isLoadingOnDialogCancelNewMasterProperty,
      isLoadingOnDialog: isLoadingOnDialogNewMasterProperty,
    } = useNewMasterPropertyDialogStore();
    const {
      isLoadingOnDialogCancel: isLoadingOnDialogCancelRaiseDeferredDutyInterest,
      isLoadingOnDialog: isLoadingOnDialogRaiseDeferredDutyInterest,
    } = useRaiseDeferredDutyInterestDialogStore();
    const {
      isLoadingOnDialogCancel: isLoadingOnDialogCancelModifyDeferredDuty,
      isLoadingOnDialog: isLoadingOnDialogModifyDeferredDuty,
    } = useModifyDeferredDutyDialogStore();
    return (
      <ConfirmDialog
        title={"Confirmation"}
        message={"Do you wish to retain this workflow?"}
        height={"auto"}
        btnCancelVisible={false}
        btnYesId={EListSubmitButton.ConfirmCloseYes}
        isLoadingNo={
          isLoadingOnDialogCancel ||
          isLoadingOnDialogCancelAssessment ||
          isLoadingOnDialogCancelParcel ||
          isLoadingOnDialogCancelModifyMasterProperty ||
          isLoadingOnDialogCancelNewMasterProperty ||
          isLoadingOnDialogCancelRaiseDeferredDutyInterest ||
          isLoadingOnDialogCancelModifyDeferredDuty
        }
        isLoadingYes={
          isLoadingOnDialog ||
          isLoadingOnDialogAssessment ||
          isLoadingOnDialogParcel ||
          isLoadingOnDialogModifyMasterProperty ||
          isLoadingOnDialogNewMasterProperty ||
          isLoadingOnDialogRaiseDeferredDutyInterest ||
          isLoadingOnDialogModifyDeferredDuty
        }
        onConfirmNo={() => onConfirmNo()}
        onClosePopup={() => onClose()}
        onAsyncConfirm={(e) => onConfirmYes(e)}
      />
    );
  }
);
