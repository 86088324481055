import {
  mockLRSChangeOfOwnershipLOVResponse,
  mockNoticeOfSaleNewResponse,
} from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/mock";
import {
  DTO_ChangeOfOwnership_LOVs,
  DTO_Workflow_ChangeOfOwnership,
  RequestChangeOfOwnershipObj,
} from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import { eComponent } from "@app/products/property/components/associations/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { ResponsePacket } from "@common/models/identityPacket";
import { sleep } from "@common/utils/common";

export const getNewWorkflowLRSChangeOfOwnership = async (
  requestObjSpatial: RequestChangeOfOwnershipObj | object = {}
): Promise<APIResponse<DTO_Workflow_ChangeOfOwnership>> => {
  try {
    //TODO: Use after have api
    // return await CoreAPIService.getClient().post(
    //   `/api/property/int/workflow/changeofOwnership/new`,
    //   requestObjSpatial
    // );
    const response = {
      data: mockNoticeOfSaleNewResponse,
      status: APIResponseStatus.SUCCESS,
      statusText: "Ok",
      headers: {},
      config: {},
    };
    await sleep(1500);
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowLRSChangeOfOwnership = async (
  workflowId: number
): Promise<APIResponse<DTO_Workflow_ChangeOfOwnership>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/int/workflow/changeofownership/${workflowId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as APIResponse<ResponsePacket>;
    return {
      ...errorResponse,
      error: (errorResponse.data as ResponsePacket)?.Errors,
    };
  }
};
export const getLRSChangeOfOwnershipLovs = async (
  componentNumber: eComponent
): Promise<APIResponse<DTO_ChangeOfOwnership_LOVs>> => {
  try {
    //TODO: Use after have api
    // return await CoreAPIService.getClient().get(
    //   `/api/property/int/changeofownership/${componentNumber}/lovs`
    // );
    const response = {
      data: mockLRSChangeOfOwnershipLOVResponse,
      status: APIResponseStatus.SUCCESS,
      statusText: "Ok",
      headers: {},
      config: {},
    };
    await sleep(1500);
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataLRSChangeOfOwnership =
  | [
      APIResponse<DTO_ChangeOfOwnership_LOVs>,
      APIResponse<DTO_Workflow_ChangeOfOwnership>
    ]
  | APIResponse<DTO_ChangeOfOwnership_LOVs | DTO_Workflow_ChangeOfOwnership>
  | undefined;
export const getInitialDataLRSChangeOfOwnership = async (
  assessmentId: number | null,
  contactId: number | null,
  titleId: number | null,
  picId: number | null,
  componentNumber: eComponent
): Promise<IGetInitialDataLRSChangeOfOwnership> => {
  try {
    return await Promise.all([
      getLRSChangeOfOwnershipLovs(componentNumber),
      getNewWorkflowLRSChangeOfOwnership({
        AssessmentId: assessmentId,
        Entity_Id: contactId,
        Title_Id: titleId,
        PIC_Id: picId,
      }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessLRSChangeOfOwnership = async (
  workflowType: WorkflowProcessMode,
  holdingData: DTO_Workflow_ChangeOfOwnership
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_ChangeOfOwnership>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/int/workflow/changeofownership/process/${workflowType}`,
      holdingData
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
