import {
  DTO_ChangeOfOwnership_TitleOwners,
  RequestTitleOwnersObj,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getOwnersForPartialType = async (
  requestObj: RequestTitleOwnersObj
): Promise<APIResponse<DTO_ChangeOfOwnership_TitleOwners>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/int/changeofownership/titleOwners`,
      requestObj
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
    };
  }
};
