import { useAssessmentTransferStore } from "@app/products/property/assessments/[id]/components/child-screens/transfer/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { WorkflowTypes } from "@app/products/property/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const EditChangeOfOwnershipAssessmentButton = observer(() => {
  const { setListDialog } = usePropertyWorkflowStore();
  const { transferSelectedID, reLoadTransfer } = useAssessmentTransferStore();
  const { assessmentId } = useAssessmentStore();
  const isDisabled = useMemo(() => {
    return isNil(transferSelectedID);
  }, [transferSelectedID]);

  return (
    <CCNavButton
      title="Edit change of ownership"
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Edit_Change_Of_Ownership,
            data: {},
            props: {
              changeOfOwnershipId: transferSelectedID,
              actionAfterFinish: () => {
                reLoadTransfer(assessmentId);
              },
            },
          },
        ]);
      }}
      disabled={isDisabled}
    />
  );
});
