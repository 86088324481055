import {
  DTO_Supplementary_AssessmentSummary,
  DTO_Supplementary_LevyCalculationSummary,
  DTO_Supplementary_LevySummary,
  DTO_Supplementary_ValuationSummary,
} from "@app/products/property/supplementary-rates/[id]/components/child-screens/general/model";
import { formatDynamicValuations } from "@app/products/property/util";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleProminent } from "@common/utils/formatting";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import React from "react";

const nameOfAssessmentSummary =
  nameOfFactory<DTO_Supplementary_AssessmentSummary>();
const nameOfValuationSummary =
  nameOfFactory<DTO_Supplementary_ValuationSummary>();
const nameOfLevySummary = nameOfFactory<DTO_Supplementary_LevySummary>();
const nameOfLevyAdjustments =
  nameOfFactory<DTO_Supplementary_LevyCalculationSummary>();

export const colAssessmentSummary: IColumnFields[] = [
  {
    field: nameOfAssessmentSummary("AssessmentType"),
    title: "Type",
  },
  {
    field: nameOfAssessmentSummary("AssessmentStatus"),
    title: "Status",
  },
  {
    field: nameOfAssessmentSummary("NumberOfAssessments"),
    title: "Number Of",
    format: NUMBER_FORMAT.NUMBER2,
  },
];

export const colValuationSummary: IColumnFields[] = [
  {
    field: nameOfValuationSummary("ValuationType"),
    title: "Type",
  },
  {
    field: nameOfValuationSummary("BeforeValuationAmount"),
    title: "Before",
    format: formatDynamicValuations,
    filter: "numeric",
  },
  {
    field: nameOfValuationSummary("AfterValuationAmount"),
    title: "After",
    format: formatDynamicValuations,
    filter: "numeric",
  },
  {
    field: nameOfValuationSummary("ValuationMovement"),
    title: "Movement",
    format: formatDynamicValuations,
    filter: "numeric",
  },
];

export const colLevySummary: IColumnFields[] = [
  {
    field: nameOfLevySummary("Levy_Category"),
    title: "Category",
  },
  {
    field: nameOfLevySummary("Levy_Name"),
    title: "Name",
  },
  {
    field: nameOfLevySummary("Assessment_Group"),
    title: "Assessment Group",
  },
  {
    field: nameOfLevySummary("Differential_Category"),
    title: "Differential Category",
  },

  {
    field: nameOfLevySummary("RatingPeriod"),
    title: "Rating Period",
  },
  {
    field: nameOfLevySummary("NumberOf"),
    title: "Number Of",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfLevySummary("NettAmount"),
    title: "Nett Adjustment",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
];

export const colLevyAdjustments: IColumnFields[] = [
  {
    field: nameOfLevyAdjustments("Levy_Category"),
    title: "Category",
  },
  {
    field: nameOfLevyAdjustments("Levy_Name"),
    title: "Name",
  },
  {
    field: nameOfLevyAdjustments("Assessment_Group"),
    title: "Assessment Group",
  },
  {
    field: nameOfLevyAdjustments("Differential_Category"),
    title: "Differential Category",
  },

  {
    field: nameOfLevyAdjustments("RP_Name"),
    title: "Rating Period",
  },
  {
    field: nameOfLevyAdjustments("NumberOfLevies"),
    title: "Number Of",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfLevyAdjustments("Base_Amount"),
    title: "Base Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfLevyAdjustments("Rate_by_Valuation"),
    title: "Rate X Valn Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfLevyAdjustments("Increment_to_Minimum"),
    title: "Increment to Minimum",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfLevyAdjustments("Reduction_to_Maximum"),
    title: "Reduction to Maximum",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfLevyAdjustments("Adjustment_for_Capping"),
    title: "Adjustment for Capping",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfLevyAdjustments("Tax_Amount"),
    title: "Tax Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfLevyAdjustments("Gross_Amount"),
    title: "Gross Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfLevyAdjustments("Rebate_Amount"),
    title: "Rebate Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfLevyAdjustments("Nett_Amount"),
    title: "Nett Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
];
