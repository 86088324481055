import { NoticeOfSaleStatus } from "@app/products/property/changes-of-ownership/notice-of-sales/constant";
import { VO_NoticeOfSale } from "@app/products/property/changes-of-ownership/notice-of-sales/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";

const nameOf = nameOfFactory<VO_NoticeOfSale>();

export const noticeOfSaleFilterState: CompositeFilterDescriptor = {
  filters: [
    {
      field: nameOf("Status"),
      operator: "neq",
      value: NoticeOfSaleStatus.Cancelled,
    },
    {
      field: nameOf("Status"),
      operator: "neq",
      value: NoticeOfSaleStatus.Completed,
    },
  ],
  logic: "and",
};

export const noticeOfSalesColumns: IColumnFields[] = [
  {
    field: nameOf("File_Name"),
    title: "File Name",
  },
  {
    field: nameOf("Extract_Date"),
    title: "Extract Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Dealing_Number"),
    title: "Dealing Number",
  },
  {
    field: nameOf("Dealing_Type"),
    title: "Dealing Type",
  },
  //TODO: API will handle in the notice of sale update task
  {
    field: "Warnings",
    title: "Warnings",
  },
  {
    field: nameOf("Purchase_Price"),
    title: "Purchase Price",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Acquisition_Date"),
    title: "Acquisition Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Agent_Name"),
    title: "Agent Name",
  },
  {
    field: nameOf("Agent_Address"),
    title: "Agent Address",
  },
  {
    field: nameOf("Agent_Postcode"),
    title: "Agent Postcode",
  },
  {
    field: nameOf("Agent_Phone_Number"),
    title: "Agent Phone Number",
  },
  {
    field: nameOf("Share_Percentage_Acquired"),
    title: "Share Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("Property_Type"),
    title: "Property Type",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Comment"),
    title: "Comment",
  },
  {
    field: nameOf("Change_of_Ownership_Id"),
    title: "Change of Ownership ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("File_Id"),
    title: "File ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
