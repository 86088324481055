import { eventEmitter } from "@/App";
import { COMMENT_ACCORDION_GRID_ID } from "@app/core/comments/constant";
import {
  EWorkflow_Approval_Status_Name,
  VO_Workflow_Draft,
} from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { ConfirmCloseButton } from "@app/products/property/assessments/components/form-steps/new-assessment/dialog/confirm-close-button/_index";
import { abandonWorkflow } from "@app/products/property/components/action-bar/property-workflow/api";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import {
  getSuffixTitle,
  secondaryWorkflowUtilProcessing,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import {
  PROPERTY_ACTIONS_APPROVED_LIST_ROUTE,
  PROPERTY_ACTION_CANCELLED_LIST_ROUTE,
} from "@app/products/property/components/action-bar/property-workflow/constant";
import {
  IListDialog,
  IProcessWorkflow,
} from "@app/products/property/components/action-bar/property-workflow/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { PropertyDocumentEventType } from "@app/products/property/components/child-screen/documents/constant";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import {
  getInitialDataParcel,
  postProcessWorkflowParcel,
} from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/api";
import { AddressesFormStep } from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/components/form-elements/addresses/_index";
import { AssociationsFormStep } from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/components/form-elements/associations/_index";
import { DetailsFormStep } from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/components/form-elements/details/_index";
import {
  EKeysOfStepsParcel,
  keysOfSendStepsParcel,
} from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/config";
import {
  DTO_Parcel_LOVs,
  DTO_WorkflowDetail_ModifyParcel,
  DTO_Workflow_ModifyParcel,
} from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/model";
import { useModifyParcelDialogStore } from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/store";
import { useParcelsStore } from "@app/products/property/parcels/[id]/store";
import { ConstraintsFormStep } from "@app/products/property/parcels/components/form-steps/create-parcel/components/form-elements/constraints/_index";
import { LandUseFormStep } from "@app/products/property/parcels/components/form-steps/create-parcel/components/form-elements/land-use/_index";
import { ZonesFormStep } from "@app/products/property/parcels/components/form-steps/create-parcel/components/form-elements/zones/_index";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { cloneDeep, head, isEqual, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface IEditParcelDialogProps {
  onClose: () => void;
  onSubmitFinishOpenWorkflow?: (data: any) => void;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  isReadOnly?: boolean;
  isShowCancelWorkflowButton?: boolean;
  statusBadge?: IBadgeDialog[];
  isSaveOnNextStep?: boolean;
  isIncompleteMode?: boolean;
  suffixTitle?: string;
  isFromActionList?: boolean;
  isRedirectManagePage?: boolean;
}

export const ModifyParcelDialog = observer(
  ({
    onClose,
    dataFromActionList,
    prefixTitle,
    isReadOnly = false,
    statusBadge,
    isSaveOnNextStep = false,
    suffixTitle,
    isFromActionList = false,
    isShowCancelWorkflowButton = false,
    isIncompleteMode = false,
    isRedirectManagePage = false,
  }: IEditParcelDialogProps) => {
    const history = useHistory();

    //Get data from store
    const { setModifyParcelLOVs, setIsLoadingOnDialogCancel } =
      useModifyParcelDialogStore();
    const { parcelId } = useParcelsStore();
    const { currentOrganisationMode, currentFormTitle } =
      useCommonProductStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setListDialog } = usePropertyWorkflowStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { clearSelectedItems } = useCCProductListViewStore();

    //check State
    const isActro = currentOrganisationMode(OrganisationMode.ACTRO);

    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    //local state
    const [isLoadingInStep, setIsLoadingInStep] = useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
    const [isShowCloseDialog, setIsShowCloseDialog] = useState<boolean>(false);
    const [modifyParcelInitData, setModifyParcelInitData] =
      useState<DTO_Workflow_ModifyParcel>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const isToBeApprovalMode = isEqual(
      dataFromActionList?.Workflow_Approval_Status_Name,
      EWorkflow_Approval_Status_Name.To_Be_Approved
    );

    /**
     * function process initial Data for form in each step
     */
    const initialValues = useMemo(() => {
      let selectDetails = {};
      let initAssociations = {};
      let initAddress = {};
      let selectLandUses = {};
      let selectZones = {};
      let selectConstraints = {};
      let initComments = {};
      let secondaryWorkflow: any = {};
      if (modifyParcelInitData) {
        const workflowDetail = modifyParcelInitData?.WorkflowDetail;

        selectDetails = {
          ...workflowDetail?.SelectDetails,
          ReasonForChange:
            workflowDetail?.SelectDetails?.ReasonForChange !== 0
              ? workflowDetail?.SelectDetails?.ReasonForChange
              : undefined,
        };
        initAssociations = workflowDetail?.Associations;
        initAddress = { Address: [workflowDetail?.Address] ?? [] };
        selectLandUses = {
          SelectLandUses: workflowDetail?.SelectLandUses ?? [],
        };
        selectZones = { SelectZones: workflowDetail?.SelectZones ?? [] };
        selectConstraints = {
          SelectConstraints: workflowDetail?.SelectConstraints ?? [],
        };
        initComments = workflowDetail?.Memos ?? [];
        //---step Secondary Workflow---
        secondaryWorkflow =
          secondaryWorkflowUtilProcessing.processDataInit(modifyParcelInitData);
      }

      return {
        [EKeysOfStepsParcel.SelectDetails]: selectDetails,
        [EKeysOfStepsParcel.Associations]: initAssociations,
        [EKeysOfStepsParcel.Address]: initAddress,
        [EKeysOfStepsParcel.SelectLandUses]: selectLandUses,
        [EKeysOfStepsParcel.SelectZones]: selectZones,
        [EKeysOfStepsParcel.SelectConstraints]: selectConstraints,
        [EKeysOfStepsParcel.Comments]: initComments,
        [EKeysOfStepsParcel.Documents]: {},
        [EKeysOfStepsParcel.SecondaryWorkflow]: secondaryWorkflow,
      };
    }, [modifyParcelInitData]);

    /**
     * List define list all steps
     */
    const steps: IStep[] = [
      {
        label: "Details",
        initialValues: initialValues.SelectDetails,
        component: DetailsFormStep,
        visible: true,
        key: EKeysOfStepsParcel.SelectDetails,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Related",
        initialValues: initialValues.Associations,
        component: AssociationsFormStep,
        visible: true,
        key: EKeysOfStepsParcel.Associations,
        options: {
          isReadOnly,
          setIsLoadingInStep,
        },
      },
      {
        label: "Addresses",
        initialValues: initialValues.Address,
        component: AddressesFormStep,
        visible: true,
        key: EKeysOfStepsParcel.Address,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Zones",
        initialValues: initialValues.SelectZones,
        component: ZonesFormStep,
        visible: !isActro,
        key: EKeysOfStepsParcel.SelectZones,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Land Use",
        initialValues: initialValues.SelectLandUses,
        component: LandUseFormStep,
        visible: !isActro,
        key: EKeysOfStepsParcel.SelectLandUses,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Constraints",
        initialValues: initialValues.SelectConstraints,
        component: ConstraintsFormStep,
        visible: !isActro,
        key: EKeysOfStepsParcel.SelectConstraints,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Comments",
        initialValues: initialValues.Comments,
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfStepsParcel.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Parcel,
        },
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValues.Documents,
        visible: true,
        key: EKeysOfStepsParcel.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          //Only use for modify workflow
          componentId: parcelId,
          workflowType: WorkflowTypes.Modify_Parcel,
        },
      },
      {
        label: "Workflow",
        initialValues: initialValues.SecondaryWorkflow,
        component: WorkflowStepFormStep,
        visible: true,
        key: EKeysOfStepsParcel.SecondaryWorkflow,
        options: {
          isReadOnly,
          isFromActionList,
          dataFromActionList,
        },
      },
    ];

    /**
     * get workflow data
     * return LOVs and initial data of existed parcel
     * @returns
     */
    const getWorkflowData = async () => {
      //must get id (2 places form action list and manage page)
      const id = dataFromActionList?.Workflow_Draft_Id ?? parcelId;
      if (!id) return;
      notificationFormStepRef?.current?.setLoadingFormStep(true);

      //get calling api to get data workflow
      return await getInitialDataParcel(
        WorkflowTypes.Modify_Parcel,
        id,
        isFromActionList
      ).then((response) => {
        notificationFormStepRef?.current?.setLoadingFormStep(false);
        if (Array.isArray(response)) {
          const [lovsParcel, workflowData] = response;
          if (
            isSuccessResponse(response[0]) &&
            isSuccessResponse(response[1]) &&
            lovsParcel?.data &&
            workflowData?.data
          ) {
            //set lovs parcel
            setModifyParcelLOVs(response[0].data?.dtoModify_Parcel_LOVs);
            //set parcel initial data
            setModifyParcelInitData({
              WorkflowDetail: workflowData.data?.WorkflowDetail,
              WorkflowHeader: workflowData.data?.WorkflowHeader,
            });
            //set workflow header and workflow Draft Id
            if (workflowData.data?.WorkflowHeader) {
              setWorkflowHeader(workflowData.data?.WorkflowHeader);
              setWorkflowDraftId(
                workflowData.data?.WorkflowHeader?.WorkflowDraft
                  ?.Workflow_Draft_Id ?? 0
              );
            }
          } else {
            let responseError: APIResponse<
              DTO_Parcel_LOVs | DTO_Workflow_ModifyParcel | ResponsePacket
            > = response[0];
            //Check any api failed will be throw error
            if (!isSuccessResponse(response[1])) responseError = response[1];
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error:
                  (responseError.data as ResponsePacket)?.Errors ??
                  "Load workflow failed",
              },
            });
          }
        } else {
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: response?.status ?? 400,
              error: "Load workflow failed",
            },
          });
        }
      });
    };

    /**
     * handle submit to control all button in dialog
     * @param data
     * @param buttonId
     */
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Approve:
          await handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          await handleSaveAndNext(processData(data), true);
          break;
        case EListSubmitButton.SaveWorkflow:
          await handleSaveAndNext(processData(data), false, true);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.ConfirmCloseYes:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
        default:
          break;
      }
    };

    /**
     * Handle reject process
     */
    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessWorkflowParcel,
          dataReject: data,
          defaultSuccessMessage:
            "Create parcel application was rejected successfully",
          defaultErrorMessage: "Create parcel reject failed",
        });
      } else {
        onClose();
      }
    };

    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_ModifyParcel
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessWorkflowParcel,
        dataSendBack: payload,
        defaultSuccessMessage: "Parcel approval sent back successfully",
        defaultErrorMessage: "Send parcel back failed",
      });
    };

    /**
     * handle confirm reallocate workflow process
     * @param payload
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_ModifyParcel
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postProcessWorkflowParcel,
        dataReallocate: payload,
        defaultSuccessMessage: "Parcel approved successfully",
        defaultErrorMessage: "Approve parcel failed",
      });
    };

    /**
     * handle finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (payload: DTO_Workflow_ModifyParcel) => {
      const dataProcessed = processData(payload, WorkflowProcessMode.Finish);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage: `The parcel will be updated based on the information provided. Are you sure you want to submit?`,
      });
    };

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (payload: DTO_Workflow_ModifyParcel) => {
      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_ModifyParcel> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          clearSelectedItems();
          eventEmitter.emit(CCGridEventType.RefreshOData);
          const notificationContent: IAppNotificationItemAddProps = {
            title:
              e?.Notification ?? `New master property created successfully`,
            type: "success",
          };
          !isRedirectManagePage && pushNotification(notificationContent);
        },
        defaultFailedMessage: `Create new master property failed`,
        modeProcess: WorkflowProcessMode.Finish,
      };

      const setLoading = () => {};
      //calling api process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };

    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_ModifyParcel>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;
      let secondaryWorkflowSelectedList: IListDialog[] = [];
      if (modeProcess === WorkflowProcessMode.Finish) {
        if (payload.WorkflowDetail?.SecondaryWorkflowSelectedList) {
          secondaryWorkflowSelectedList =
            payload.WorkflowDetail.SecondaryWorkflowSelectedList ?? [];
          delete payload.WorkflowDetail.SecondaryWorkflowSelectedList;
        }
      }
      const response = await postProcessWorkflowParcel(modeProcess, payload);

      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          eventEmitter.emit(PropertyDocumentEventType.RefreshData);
          eventEmitter.emit(CCGridEventType.RefreshOData, {
            gridIds: [COMMENT_ACCORDION_GRID_ID],
          });
          actionSuccess(response?.data);
          //Show secondary workflow
          if (
            modeProcess === WorkflowProcessMode.Finish &&
            secondaryWorkflowSelectedList?.length
          ) {
            setListDialog(secondaryWorkflowSelectedList);
          }
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    /**
     * process payload before sending to api
     * @param data
     * @returns
     */
    const processData = (data: any): DTO_Workflow_ModifyParcel => {
      const newData = { ...data };
      let workflowDetail: any = {
        ParcelId: modifyParcelInitData?.WorkflowDetail?.ParcelId,
      };
      const sendSteps = pickBy(newData, function (value, key) {
        if (keysOfSendStepsParcel.includes(key as EKeysOfStepsParcel)) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        const dataStep: any = cloneDeep(value);
        if (dataStep && dataStep._option) {
          delete dataStep._option;
        }
        switch (key) {
          case EKeysOfStepsParcel.Address:
            workflowDetail[key] = value[key][0]; // TODO: Check again when API update
            break;
          case EKeysOfStepsParcel.SelectConstraints:
          case EKeysOfStepsParcel.SelectLandUses:
          case EKeysOfStepsParcel.SelectZones:
            workflowDetail[key] = value[key];
            break;
          case EKeysOfStepsParcel.SecondaryWorkflow:
            secondaryWorkflowUtilProcessing.processData(
              value,
              workflowHeader,
              workflowDetail
            );
            break;
          default:
            workflowDetail[key as keyof DTO_WorkflowDetail_ModifyParcel] =
              dataStep;
            break;
        }
      }

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    /**
     * handle save parcel
     * @param payload
     * @param isCloseDialog
     * @returns Promise<boolean>
     */
    const handleSaveAndNext = async (
      payload: DTO_Workflow_ModifyParcel,
      isCloseDialog: boolean = false,
      isRefreshWorkflowData: boolean = false
    ): Promise<boolean> => {
      //Calling process Save at next button
      const response = await postProcessWorkflowParcel(
        WorkflowProcessMode.Save,
        payload
      );

      setIsLoadingOnNext(false);

      //set default notification
      const defaultSuccessMessage = "Parcel saved successfully";
      const defaultFailedMessage = "The parcel process could not be saved";

      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (isCloseDialog) {
          onClose();
          pushNotification({
            title: response?.data?.Notification ?? defaultSuccessMessage,
            type: "success",
          });
        }
        // check is the first saving
        if (isFirstSave) {
          setIsFirstSave(false);
          //set current workflowDraft Id
          setWorkflowDraftId(response?.data?.ID ?? 0);
          // set payload to send
          setWorkflowHeader({
            ...workflowHeader,
            WorkflowDraft: {
              ...workflowHeader.WorkflowDraft,
              Workflow_Draft_Id: response?.data?.ID,
            },
          });
        }
        // TODO: Show notification after reloading the step -> enhance later
        if (isRefreshWorkflowData) {
          getWorkflowData().then(() => {
            notificationFormStepRef?.current
              ?.getNotificationFormStep()
              ?.current?.pushNotification({
                title: response?.data?.Notification ?? defaultSuccessMessage,
                type: "success",
              });
          });
        }
        return true;
      } else {
        const showNotification = () => {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title:
                (isRefreshWorkflowData
                  ? head(response?.data?.Errors)
                  : response.data?.ErrorMessage) ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        };
        if (isRefreshWorkflowData) {
          getWorkflowData().then(showNotification);
        } else {
          showNotification();
        }
        return false;
      }
    };

    /**
     * handle park process
     * @param payload
     */
    const handleParkProcess = async (payload: DTO_Workflow_ModifyParcel) => {
      //set loading button and dialog
      setIsLoadingPark(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_ModifyParcel> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title: e?.Notification ?? "Parcel parked successfully",
            type: "success",
          });
        },
        defaultFailedMessage: "Park parcel failed",
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingPark(false);
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };

    /**
     * handle approve process
     */
    const handleApproveProcess = async (payload: DTO_Workflow_ModifyParcel) => {
      //set loading button
      setIsLoadingApprove(true);

      //props send to process workflow
      const approveProps: IProcessWorkflow<DTO_Workflow_ModifyParcel> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          history.push(PROPERTY_ACTIONS_APPROVED_LIST_ROUTE);
          pushNotification({
            title: e?.Notification ?? "Parcel approved successfully",
            type: "success",
          });
        },
        defaultFailedMessage: "Approve parcel failed",
        modeProcess: WorkflowProcessMode.Approve,
      };

      const setLoading = () => {
        setIsLoadingApprove(false);
      };
      //calling api process workflow
      await handleProcessWorkflow(approveProps, setLoading);
    };

    /**
     * handle when hitting next button
     * @param data
     * @returns
     */
    const handleNextButton = async (data: any) => {
      //loading on next form
      setIsLoadingOnNext(true);
      const newData = { ...data };
      const processPayload = processData(newData);

      //send data to call api save
      return handleSaveAndNext(processPayload);
    };

    /**
     * process title dialog
     */
    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "Modify Parcel";

      return getTitleWorkflow(
        title,
        prefixTitle,
        getSuffixTitle(
          suffixTitle,
          isToBeApprovalMode,
          workflowHeader?.WorkflowApprovals
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, isToBeApprovalMode, workflowHeader]);

    /**
     * handle close dialog
     * @param renderProps
     */
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setIsShowCloseDialog(true);
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name &&
        dataFromActionList.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    /**
     * handle cancel button
     */
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          //@TODO implement again after api update
          cancelAPI: postProcessWorkflowParcel,
          dataCancel: data,
          defaultSuccessMessage:
            "The parcel application was cancelled successfully",
          defaultErrorMessage: "Modify parcel could not be cancelled.",
        });
      } else {
        onClose();
      }
    };

    /**
     * handle confirm no retain dialog
     */
    const handleConfirmNoRetain = async () => {
      setIsLoadingOnDialogCancel(true);
      //TODO: mock api
      await abandonWorkflow().then(() => {
        setIsLoadingOnDialogCancel(false);
        history.push(PROPERTY_ACTION_CANCELLED_LIST_ROUTE);
        onClose();
      });
    };

    //Calling api get data the first time
    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <>
        <CCFormStep
          onSubmit={handleSubmit}
          ref={notificationFormStepRef}
          listButtonId={listSubmitButton}
          initialValues={initialValues}
          initialSteps={steps}
          saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
          renderForm={(renderProps: ICCFormStepRender) => (
            <CCDialog
              maxWidth="60%"
              maxHeight="70%"
              disabled={isLoadingApprove || isLoadingInStep}
              titleHeader={titleHeader}
              badge={statusBadge}
              onClose={() => handleCloseDialog(renderProps)}
              bodyElement={renderProps.children}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {/* Park button */}
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}

                    {/* Cancel button */}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          isLoadingPark || isLoadingApprove || isLoadingOnNext
                        }
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}

                    {isToBeApprovalMode &&
                      workflowHeader?.OfficerCanApprove && (
                        <>
                          {/* Send Back button */}
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.SendBack}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Send Back
                          </Button>
                          {/* Reallocate button */}
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reallocate}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reallocate
                          </Button>
                          {/* Approve button */}
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Approve}
                            disabled={
                              isLoadingApprove ||
                              renderProps.nextButton.disabled
                            }
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                            iconClass={
                              isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                            }
                          >
                            Approve
                          </Button>
                          {/* Reject button */}
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reject}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reject
                          </Button>
                        </>
                      )}
                    {/* Previous button */}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {/* Next button */}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                  {isShowCloseDialog && (
                    <ConfirmCloseButton
                      onClose={() => setIsShowCloseDialog(false)}
                      onConfirmYes={renderProps.submitButton.onClick}
                      onConfirmNo={handleConfirmNoRetain}
                    />
                  )}
                </>
              }
            />
          )}
        />
      </>
    );
  }
);
