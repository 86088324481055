import { WorkflowTypes } from "@app/products/property/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewContactButton = observer(() => {
  const { setListDialog } = usePropertyWorkflowStore();
  return (
    <CCNavButton
      title="New contact"
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Create_Entity,
            data: {},
          },
        ]);
      }}
    />
  );
});
