import { history } from "@/AppRoutes";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import {
  CS_RecentRequestsFilter,
  Event,
  SpecialCharacter,
} from "@app/products/crms/[id]/model";
import { EventView } from "@app/products/crms/all-events/by-refno/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";

const nameOf = nameOfFactory<EventView>();

export const renderColSimilarEventsGrid = (
  actionOfficerLabel: string | null
) => {
  return [
    {
      field: nameOf("RefNo"),
      title: "Reference Number",
      locked: true,
      handleOnClick: (dataItem: EventView) => {
        if (dataItem.IsHideInfo) return undefined;
        history.push(`${CRMS_ROUTE}/${dataItem.ID}`, {
          isFromSimilarEvent: true,
        });
      },
    },
    {
      field: nameOf("ActionOfficer"),
      title: actionOfficerLabel ?? "Action Person",
    },
    {
      field: nameOf("Status"),
      title: "Status",
    },
    {
      field: nameOf("Description"),
      title: "Description",
    },
    {
      field: nameOf("DateRecorded"),
      title: "Recorded",
      format: DATE_FORMAT.DATE,
    },
    {
      field: nameOf("Category"),
      title: "Category",
    },
    {
      field: nameOf("Location"),
      title: "Location",
    },
    {
      field: nameOf("RequestedBy"),
      title: "Contact",
    },
    {
      field: nameOf("DateTarget"),
      title: "Target",
      format: DATE_FORMAT.DATE,
    },
  ];
};

const replaceSpecialCharacter = (strItem: string) => {
  return strItem
    .replaceAll("/", SpecialCharacter.SlashMark)
    .replaceAll("?", SpecialCharacter.QuestionMark)
    .replaceAll(":", SpecialCharacter.ColonMark)
    .replaceAll("&", SpecialCharacter.PlusSymbolMark)
    .replaceAll("+", SpecialCharacter.PlusMark)
    .replaceAll("#", SpecialCharacter.HashtagMark)
    .replaceAll(".", SpecialCharacter.DotMark);
};

const renderByRequestorOdataURL = (
  eventID: number,
  requestorContactId: number
) => {
  return `odata/crms/internal/events/GetViewRecentEventsFilterByRequestor(eventID=${eventID},requestedByContactID=${requestorContactId})?$count=true&`;
};

const renderByRequestorAddressOdataURL = (
  eventID: number,
  assessmentNo?: string,
  addressLine1?: string,
  suburb?: string
) => {
  return `odata/crms/internal/events/GetViewRecentEventsFilterByRequestorAddress(eventID=${eventID}${
    assessmentNo ? `,assessmentNo='${assessmentNo}'` : ""
  }${addressLine1 ? `,addressLine1='${addressLine1}'` : ""}${
    suburb ? `,suburb='${suburb}'` : ""
  })?$count=true&`;
};

const renderByLocationOdataURL = (
  eventID: number,
  assessmentNo?: string,
  addressLine1?: string,
  suburb?: string
) => {
  return `odata/crms/internal/events/GetViewRecentEventsFilterByLocation(eventID=${eventID}${
    assessmentNo ? `,assessmentNo='${assessmentNo}'` : ""
  }${addressLine1 ? `,addressLine1='${addressLine1}'` : ""}${
    suburb ? `,suburb='${suburb}'` : ""
  })?$count=true&`;
};

export const renderByCategoryAndLocationOdataURL = (
  eventID: number,
  serviceStandardID: number,
  assessmentNo?: string,
  addressLine1?: string,
  suburb?: string,
  showOpenEventsOnly?: boolean
) => {
  return `odata/crms/internal/events/GetViewRecentEventsFilterByCategoryAndLocation(eventID=${eventID},serviceStandardID=${serviceStandardID}${
    assessmentNo ? `,assessmentNo='${assessmentNo}'` : ""
  }${addressLine1 ? `,addressLine1='${addressLine1}'` : ""}${
    suburb ? `,suburb='${suburb}'` : ""
  }${
    showOpenEventsOnly !== undefined
      ? `,showOpenEventsOnly=${showOpenEventsOnly}`
      : ""
  })?$count=true&`;
};

const renderByWasteWaterOdataURL = (
  eventID: number,
  wasteWaterInstallationID?: number | null,
  wasteWaterProductID?: number | null,
  wasteWaterSchemeID?: number | null
) => {
  return `odata/crms/internal/events/GetViewRecentEventsFilterByWasteWater(eventID=${eventID}${
    wasteWaterInstallationID
      ? ",wasteWaterInstallationID=" + wasteWaterInstallationID
      : ""
  }${wasteWaterProductID ? ",wasteWaterProductID=" + wasteWaterProductID : ""}${
    wasteWaterSchemeID ? ",wasteWaterSchemeID=" + wasteWaterSchemeID : ""
  })?$count=true&`;
};

const renderByControlledSubstance = (
  eventID: number,
  controlledSubstanceLicenceID?: number
) => {
  return `odata/crms/internal/events/GetViewRecentEventsFilterByControlledSubstance(eventID=${eventID}${
    controlledSubstanceLicenceID
      ? ",controlledSubstanceLicenceID=" + controlledSubstanceLicenceID
      : ""
  })?$count=true&`;
};

const renderByAnimalSubstance = (
  eventID: number,
  animalRegisterIDs?: string
) => {
  return `odata/crms/internal/events/GetViewRecentEventsFilterByAnimal(eventID=${eventID}${
    animalRegisterIDs
      ? `,animalRegisterIDs='${encodeURI(animalRegisterIDs)}'`
      : ""
  })?$count=true&`;
};

const renderByKennelSubstance = (
  eventID: number,
  kennelRegisterIDs?: string
) => {
  return `odata/crms/internal/events/GetViewRecentEventsFilterByKennel(eventID=${eventID}${
    kennelRegisterIDs
      ? `,kennelRegisterIDs='${encodeURI(kennelRegisterIDs)}'`
      : ""
  })?$count=true&`;
};

export const renderSimilarEventOdataUrl = (
  eventObj?: Event,
  recentRequestFilter?: CS_RecentRequestsFilter
) => {
  if (!eventObj || !recentRequestFilter) return "";
  switch (recentRequestFilter) {
    case CS_RecentRequestsFilter.Requestor:
      if (eventObj.RequestedBy?.Contact?.Contact_ID) {
        return renderByRequestorOdataURL(
          eventObj.Event_Id,
          eventObj.RequestedBy?.Contact?.Contact_ID
        );
      }
      break;
    case CS_RecentRequestsFilter.RequestorAddress:
      if (eventObj.RequestedBy?.Contact?.PreferredAddress) {
        return renderByRequestorAddressOdataURL(
          eventObj.Event_Id,
          eventObj.RequestedBy?.Contact?.PreferredAddress?.AssessmentNo,
          replaceSpecialCharacter(
            eventObj.RequestedBy?.Contact?.PreferredAddress
              ?.Formatted_AddressLine1
          ),
          eventObj.RequestedBy?.Contact?.PreferredAddress?.Suburb
        );
      }

      if (eventObj.RequestedBy_SiteUser?.PreferredAddress) {
        return renderByRequestorAddressOdataURL(
          eventObj.Event_Id,
          eventObj.RequestedBy_SiteUser.PreferredAddress.AssessmentNo,
          replaceSpecialCharacter(
            eventObj.RequestedBy_SiteUser.PreferredAddress
              .Formatted_AddressLine1
          ),
          eventObj.RequestedBy_SiteUser.PreferredAddress.Suburb
        );
      }

      if (eventObj.RequestedBy_PortalUser?.PreferredAddress) {
        return renderByRequestorAddressOdataURL(
          eventObj.Event_Id,
          eventObj.RequestedBy_PortalUser.PreferredAddress.AssessmentNo,
          replaceSpecialCharacter(
            eventObj.RequestedBy_PortalUser.PreferredAddress
              .Formatted_AddressLine1
          ),
          eventObj.RequestedBy_PortalUser.PreferredAddress.Suburb
        );
      }
      break;
    case CS_RecentRequestsFilter.Location:
      if (eventObj.IssueLocation?.LocationAddress) {
        return renderByLocationOdataURL(
          eventObj.Event_Id,
          eventObj.IssueLocation.LocationAddress.AssessmentNo,
          replaceSpecialCharacter(
            eventObj.IssueLocation.LocationAddress.Formatted_AddressLine1
          ),
          eventObj.IssueLocation.LocationAddress.Suburb
        );
      }
      break;
    case CS_RecentRequestsFilter.CategoryAndLocation:
      if (eventObj.ServiceStandard && eventObj.IssueLocation?.LocationAddress) {
        return renderByCategoryAndLocationOdataURL(
          eventObj.Event_Id,
          eventObj.ServiceStandard_Id,
          eventObj.IssueLocation.LocationAddress.AssessmentNo,
          replaceSpecialCharacter(
            eventObj.IssueLocation.LocationAddress.Formatted_AddressLine1
          ),
          eventObj.IssueLocation.LocationAddress.Suburb
        );
      }
      break;
    case CS_RecentRequestsFilter.WasteWater:
      return renderByWasteWaterOdataURL(
        eventObj.Event_Id,
        eventObj.WasteWater_InstallationID,
        eventObj.WasteWater_ProductID,
        eventObj.WasteWater_SchemeID
      );
    case CS_RecentRequestsFilter.ControlledSubstance:
      if (eventObj.ControlledSubstance_LicenceID) {
        return renderByControlledSubstance(
          eventObj.Event_Id,
          eventObj.ControlledSubstance_LicenceID
        );
      }
      break;
    case CS_RecentRequestsFilter.Animal:
      if (eventObj.Animal_RegisterIDs) {
        return renderByAnimalSubstance(
          eventObj.Event_Id,
          eventObj.Animal_RegisterIDs
        );
      }
      break;
    case CS_RecentRequestsFilter.Kennel:
      if (eventObj.Kennel_RegisterIDs) {
        return renderByKennelSubstance(
          eventObj.Event_Id,
          eventObj.Kennel_RegisterIDs
        );
      }
      break;
    default:
      return "";
  }
};
