import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDateByKendo } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loadLocalLawsDetailsById } from "./api";
import { ILocalLawsDetails } from "./model";

export const LocalLawsDetailsTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState<ILocalLawsDetails | undefined>();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const localLawsId = params.id;
  useEffect(() => {
    if (lastSelectedRow?.ID || localLawsId) {
      const id: any = lastSelectedRow?.ID || localLawsId;
      setIsLoading(true);
      loadLocalLawsDetailsById(id).then((data) => {
        if (lastSelectedRow) {
          // @TODO: Remove when integrate with api
          setInfo({
            PermitNo: lastSelectedRow?.PermitNumber,
            Status: lastSelectedRow?.StatusDueStatus,
            Lodged: lastSelectedRow?.DateInspected,
            EffectiveFrom: lastSelectedRow?.EffectiveFrom,
            Expires: lastSelectedRow?.DateRevoked,
          });
        } else {
          setInfo(data);
        }

        setIsLoading(false);
      });
    }
  }, [lastSelectedRow, localLawsId]);
  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!info) return <NoData />;
  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Permit Number:"}
              value={info?.PermitNo + ""}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Status:"}
              value={info?.Status}
              success
            />
            <ProductReferenceRow
              title={"Lodged:"}
              value={formatDateByKendo(info?.Lodged, DATE_FORMAT.DATE)}
            />
            <ProductReferenceRow
              title={"Effective From:"}
              value={formatDateByKendo(info?.EffectiveFrom, DATE_FORMAT.DATE)}
            />
            <ProductReferenceRow
              title={"Expires:"}
              value={formatDateByKendo(info?.Expires, DATE_FORMAT.DATE)}
              danger={new Date(info?.Expires!) < new Date()}
              success={new Date(info?.Expires!) >= new Date()}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
