import { IAddAttachmentDialog } from "@app/core/attachment/components/dialogs/model";
import { checkIsValidFile } from "@app/core/attachment/components/dialogs/util";
import { Attachment, FileStreamEntry } from "@app/core/documents/model";

import { CoreKeyword } from "@common/models/coreKeyword";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import {
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import { encode } from "base64-arraybuffer";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { read, utils } from "xlsx";
import "./_index.scss";
export interface IImportKeywordsDialogProps {
  onClose: () => void;
  onSubmit: (
    data: Attachment,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => void;
  primaryImportFieldName?: string;
  isLoadingButton?: boolean;
  description?: React.ReactNode;
}

export const ImportDialog = observer(
  ({
    onClose,
    onSubmit,
    isLoadingButton,
    description,
    primaryImportFieldName = "ID",
  }: IImportKeywordsDialogProps) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const nameOf = nameOfFactory<IAddAttachmentDialog>();
    useState<CoreKeyword[]>();
    const allowedExtensions = [".xls", ".xlsx"];

    const handleOnSubmitClick = (
      event: FormSubmitClickEvent,
      notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
    ) => {
      let fileEntry: FileStreamEntry = {};
      fileEntry.FileName = event.values.File[0]?.name;
      fileEntry.ContentType = event.values.File[0]?.getRawFile().type;
      fileEntry.FileSize = event.values.File[0]?.size;
      let reader = new FileReader();
      reader.readAsArrayBuffer(event.values.File[0].getRawFile());
      reader.onload = () => {
        if (reader.result) {
          /* ====  Validate content excel  ==== */
          const objExcel = read(reader.result, { type: "string" });
          const result = Object.keys(objExcel.Sheets).map((name) => ({
            name,
            data: utils.sheet_to_json(objExcel.Sheets[name]),
          }));
          const errorExcel = result[0].data.some(
            (item: any) => !item[primaryImportFieldName]
          );
          if (errorExcel) {
            notificationRef?.current?.pushNotification({
              autoClose: false,
              title: `${primaryImportFieldName} can not be blank`,
              type: "error",
            });
            return;
          }
          const attachmentBase64 = encode(reader.result as ArrayBuffer);
          fileEntry.FileAttachment = attachmentBase64;
          let data = { FileEntry: fileEntry } as Attachment;
          onSubmit(data, notificationRef);
        }
      };
    };

    return (
      <Form
        onSubmitClick={(event) => handleOnSubmitClick(event, notificationRef)}
        render={(formRenderProps: FormRenderProps) => {
          const { onChange, valueGetter } = formRenderProps;
          const isDisabled =
            !formRenderProps.valid ||
            !checkIsValidFile(valueGetter(nameOf("File")));
          return (
            <CCDialog
              titleHeader={"File Import"}
              height={"auto"}
              maxWidth={"35%"}
              onClose={onClose}
              bodyElement={
                <FormElement className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          File
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("File")}
                          component={CCUploadFile}
                          autoUpload={false}
                          batch={false}
                          multiple={false}
                          defaultFiles={[]}
                          withCredentials={false}
                          files={valueGetter(nameOf("File"))}
                          restrictions={{
                            allowedExtensions: allowedExtensions,
                            maxFileSize: 5000000,
                          }}
                          onAdd={(event: UploadOnAddEvent) => {
                            onChange(nameOf("File"), {
                              value: [
                                Object.assign(event.newState[0], {
                                  status: 4,
                                }),
                              ],
                            });
                          }}
                          onRemove={(event: UploadOnRemoveEvent) => {
                            onChange(nameOf("File"), {
                              value: event.newState,
                            });
                          }}
                          showActionButtons={false}
                          validator={requiredValidator}
                        />
                      </div>
                      {description}
                    </div>
                  </section>
                </FormElement>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    themeColor="primary"
                    disabled={isDisabled}
                    className={"cc-dialog-button"}
                    type={"submit"}
                    onClick={formRenderProps.onSubmit}
                    iconClass={isLoadingButton ? "fas fa-spinner fa-spin" : ""}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
