import { APIResponse, APIResponseError } from "@common/apis/model";
import { isCancelResponse, isSuccessResponse } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import React, { useCallback, useEffect, useState } from "react";

interface IUseCallApiHandleLoadFailed {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  api: () => Promise<APIResponse<any>>;
  handler: (response: APIResponse<any>) => Promise<any>;
  watch: any[];
  isActive?: boolean;
  eventType?: string;
}

export const useCallApiHandleLoadFailed = ({
  setIsLoading,
  api,
  handler,
  watch,
  isActive = true,
  eventType,
}: IUseCallApiHandleLoadFailed) => {
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  useEffect(() => {
    if (isActive) handleLoadAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watch)]);

  useAddUniqueEventEmitter([
    {
      eventType: eventType ?? "NoEvent",
      listener: () => {
        if (isActive) handleLoadAPI();
      },
    },
  ]);

  const handleLoadAPI = useCallback(async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await api();
    if (isSuccessResponse(response)) {
      await handler(response);
    } else {
      const errorResponse = {
        status: response.status,
        error: response.error,
      };
      if (!isCancelResponse(response)) setResponseLoadError(errorResponse);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watch)]);

  if (isActive && responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={handleLoadAPI}
      />
    );
  } else {
    return null;
  }
};
