import { PublicClientApplication } from "@azure/msal-browser";
import CoreService from "@common/apis/core.service";
import { isSuccessResponse } from "@common/apis/util";
import { BannerText } from "@common/constants/common-setting";
import { FreshdeskWidgetCND } from "@common/helper/freshdesk/config";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { ISettings, ServiceName } from "@common/models/settings";
import { ThemeEventType } from "@common/pages/home/constant";
import {
  getSiteUserById,
  getSiteUserLOV,
} from "@common/pages/settings/security/site-users/_id/api";
import { useGlobalStore } from "@common/stores/global/store";
import Loading from "@components/loading/Loading";
import { ThemeType } from "@styles/constant";
import { EventEmitter } from "fbemitter";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppRoutes } from "./AppRoutes";
declare global {
  interface Window {
    HideFreshWidget: () => void;
    OpenFreshWidget: () => void;
    fwSettings: {
      widget_id: number;
    };
  }
}
export const eventEmitter = new EventEmitter();
export let msalInstance: PublicClientApplication;
const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { setSettings, dispose, setThemeActive } = useGlobalStore();

  useAddUniqueEventEmitter([
    {
      eventType: ThemeEventType.theme,
      listener: (themeName: string) => {
        localStorage.setItem("OOTheme", themeName);
        sessionStorage.removeItem("login");
        window.location.reload();
      },
    },
  ]);

  useEffect(() => {
    setIsLoading(true);
    CoreService.getSettings()
      .then((response: ISettings) => {
        setSettings(response);
        if (
          response.helperService &&
          response.helperService?.serviceName === ServiceName.Freshdesk
        ) {
          //Inital Feshdesk Widget Service
          window.fwSettings = {
            widget_id: parseInt(response.helperService.widgetId),
          };
          var loadScript = function (src: string) {
            var tag = document.createElement("script");
            tag.async = false;
            tag.src = src;
            var body = document.getElementsByTagName("body")[0];
            body.appendChild(tag);
          };
          loadScript(FreshdeskWidgetCND);
          window.HideFreshWidget();
        }
        BannerText();
        if (!msalInstance && response.azureAD?.azureADConfig) {
          msalInstance = new PublicClientApplication(
            response.azureAD.azureADConfig
          );
        }
      })
      .then(async () => {
        let chosenTheme = ThemeType.Default;
        if (
          !sessionStorage.getItem("login") &&
          localStorage.getItem("userData")
        ) {
          let themeDefaultAndActiveSetFromServer = undefined;
          const listNameThemeActive: string[] = [];
          /* ====  LIST THEME ACTIVE  ==== */
          const responseSiteUserLOV = await getSiteUserLOV();
          const themeLOV = responseSiteUserLOV.data?.Theme ?? [];
          const themeActive =
            themeLOV
              .filter((item) => {
                if (
                  item.Platform === "Central" &&
                  item.Sys_DBRowState === "Active"
                ) {
                  listNameThemeActive.push(item.Theme_DisplayName);
                  if (item.Theme_Name === "Default") {
                    themeDefaultAndActiveSetFromServer = ThemeType.Default =
                      item.Theme_DisplayName;
                  }
                }
                return (
                  item.Platform === "Central" &&
                  item.Sys_DBRowState === "Active"
                );
              })
              .sort((a, b) => a.Theme_LKP_ID - b.Theme_LKP_ID) ?? [];
          setThemeActive(themeActive);
          /* ====  / LIST THEME ACTIVE  ==== */

          /* ====  CURRENT THEME FROM SERVER  ==== */
          let currentThemeServer: string | null | undefined = undefined;
          const userDataStorage = localStorage.getItem("userData");
          const userData =
            userDataStorage !== null ? JSON.parse(userDataStorage) : null;
          if (userData?.userID) {
            /* ====  Get current theme  ==== */
            // TODO: this action takes only theme name so it affects performance
            const response = await getSiteUserById(userData?.userID ?? 0);
            if (isSuccessResponse(response) && response?.data) {
              currentThemeServer = response.data?.Contact_SiteUser?.Theme_Name;
            }
            /* ====  / Get current theme  ==== */
          }
          /* ====  / CURRENT THEME FROM SERVER  ==== */

          /* ====  THEME DEFAULT ACTIVE OR THEME FIRST ITEM IN ARRAY THEME ACTIVE  ==== */
          let themeDefault = undefined;
          if (themeDefaultAndActiveSetFromServer) {
            themeDefault = themeDefaultAndActiveSetFromServer;
          } else {
            themeDefault =
              themeActive[0]?.Theme_DisplayName ?? ThemeType.Default;
          }
          /* ====  / THEME DEFAULT ACTIVE OR THEME FIRST ITEM IN ARRAY THEME ACTIVE  ==== */

          if (currentThemeServer) {
            if (listNameThemeActive.includes(currentThemeServer)) {
              chosenTheme = currentThemeServer;
            } else {
              chosenTheme = themeDefault;
            }
          } else {
            chosenTheme = themeDefault;
          }
          localStorage.setItem("OOTheme", chosenTheme);
        } else {
          sessionStorage.removeItem("login");
          chosenTheme = localStorage.getItem("OOTheme") ?? ThemeType.Default;
        }

        if (chosenTheme === ThemeType.Ready) {
          import("./styles/themeReady").then(() => setIsLoading(false));
        } else if (chosenTheme === ThemeType.Dark) {
          import("./styles/themeDark").then(() => setIsLoading(false));
        } else if (chosenTheme === ThemeType.Indigo) {
          import("./styles/themeIndigo").then(() => setIsLoading(false));
        } else if (chosenTheme === ThemeType.Light) {
          import("./styles/themeLight").then(() => setIsLoading(false));
        } else {
          import("./styles/themeReady").then(() => setIsLoading(false));
        }
        /* ====  / Load theme  ==== */
        /* ====  / Handle load theme  ==== */
      });
    return () => {
      dispose();
      setIsLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading isLoading={isLoading} isFullScreen />
      ) : (
        <DndProvider backend={HTML5Backend}>
          <AppRoutes />
          <ToastContainer position="bottom-right" />
        </DndProvider>
      )}
    </>
  );
};
export default observer(App);
