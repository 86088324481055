import { eventEmitter } from "@/App";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { customLogger } from "@common/utils/logger";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useState } from "react";

interface IDeleteButtonProps {
  recordType?: RECORDTYPE;
  disabled?: boolean;
  visible?: boolean;
  refreshGridIds?: string[];
}

export const DeleteButton = observer(
  ({
    recordType,
    disabled,
    visible = true,
    refreshGridIds = [],
  }: IDeleteButtonProps) => {
    const {
      gridSelectedRows,
      setGridSelectedRows,
      gridSelectedIds,
      setGridSelectedIds,
    } = useCCProductListViewStore();
    const { pushNotification, clearNotifications } =
      useCCAppNotificationStore();
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
      useState<boolean>(false);
    const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
      []
    );
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const handleOnSubmit = async (data: IDeleteConfirmation) => {
      if (!recordType) return;
      setIsDeleting(true);
      const ids: number[] = gridSelectedIds ?? [];
      const response = await deleteRefUsageByIdAndRecordType(
        ids,
        recordType,
        data?.Reason
      );
      setIsDeleting(false);
      setNotifications([]);
      customLogger("Core delete, handle delete").info(response?.data);
      if (isSuccessResponse(response)) {
        setGridSelectedRows([]);
        setGridSelectedIds([]);
        clearNotifications();
        pushNotification({
          title: `${pluralize("Record", ids.length)} deleted successfully`,
          type: "success",
        });
        if (refreshGridIds?.length > 0) {
          eventEmitter.emit(CCGridEventType.RefreshOData, {
            gridIds: refreshGridIds,
          });
        } else {
          eventEmitter.emit(CCGridEventType.RefreshOData);
        }
        eventEmitter.emit(CCJournalEventType.RefreshData);
        setShowConfirmDeleteDialog(false);
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: `Delete ${pluralize("record", ids.length)} failed`,
            type: "error",
            description: response.data?.Errors,
          },
        ]);
      }
    };

    if (!visible) return null;

    return (
      <>
        <CCNavButton
          title="Delete"
          disabled={!gridSelectedRows?.length || disabled}
          isLoading={isDeleting}
          onClick={() => {
            setShowConfirmDeleteDialog(true);
          }}
        />
        {showConfirmDeleteDialog && (
          <ConfirmDelete
            onClose={() => setShowConfirmDeleteDialog(false)}
            handleSubmit={handleOnSubmit}
            isDeleting={isDeleting}
            header={"Confirm Deletion"}
            notifications={notifications}
          />
        )}
      </>
    );
  }
);
