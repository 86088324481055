import { searchStreetsConfig } from "@app/products/property/assessments/components/form-steps/new-assessment/components/street-search/config";
import { loadSearchCombobox } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/components/combobox-search-api/api";
import {
  ComboboxSearchMessage,
  searchConfig,
} from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/components/combobox-search-api/config";
import { isSuccessResponse } from "@common/apis/util";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
} from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import axios, { CancelTokenSource } from "axios";
import { isString } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

export interface IEventOnChangeComboBox extends ComboBoxChangeEvent {
  data?: any;
}
interface IComboboxSearchAPIProps extends Omit<ComboBoxProps, "onChange"> {
  visited?: boolean;
  onError?: (value: any) => void;
  textProduce?: (value: any) => string | undefined;
  isLoadingParent?: boolean;
  urlAPI: string;
  keySearch: string;
  onChange: (event: IEventOnChangeComboBox) => void;
}
export const ComboboxSearchAPI = <T extends {}>(
  props: IComboboxSearchAPIProps
) => {
  const {
    visited,
    validationMessage,
    onChange,
    className,
    value,
    onError,
    textProduce,
    isLoadingParent,
    itemRender,
    urlAPI,
    dataItemKey,
    keySearch,
    data,
    allowCustom = false,
    textField = "Name", //By default "Name"
    ...others
  } = props;
  const cancelRequest = useRef<CancelTokenSource>();
  const refTimeOut = useRef<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataSearch, setDataSearch] = useState<any>(data);

  //TODO: refactor later
  const comboBoxValue = useMemo(() => {
    if (dataItemKey && dataSearch?.length) {
      return dataSearch?.find((obj: any) => obj[dataItemKey] === value) ??
        (allowCustom && isString(value))
        ? {
            [textField]: value,
            [dataItemKey]: value,
          }
        : null;
    }
    if (dataItemKey && allowCustom && isString(value)) {
      return {
        [textField]: value,
        [dataItemKey]: value,
      };
    }
    return value;
  }, [textField, dataItemKey, dataSearch, value, allowCustom]);

  const handleSearch = (event: ComboBoxFilterChangeEvent) => {
    const searchText = event.filter.value;
    setDataSearch([]);
    if (searchText.length < searchConfig.minCharacters) return;
    if (refTimeOut.current) clearTimeout(refTimeOut.current);
    refTimeOut.current = setTimeout(async () => {
      cancelRequest.current?.cancel(ComboboxSearchMessage);
      cancelRequest.current = axios.CancelToken.source();
      setIsLoading(true);
      await loadSearchCombobox<T>(
        urlAPI,
        event.filter.value,
        keySearch,
        cancelRequest.current
      ).then((response) => {
        if (isSuccessResponse(response)) {
          setDataSearch(response?.data?.value as any);
          setIsLoading(false);
        } else if (response.error !== ComboboxSearchMessage) {
          if (onError) onError(response.error);
          setIsLoading(false);
        }
      });
    }, searchStreetsConfig.typeSpeed);
  };

  const handleOnChange = (event: ComboBoxChangeEvent) => {
    if (!onChange) return;
    const newValue = event.value;
    if (!newValue) return onChange({ ...event, value: null });
    return onChange({
      ...event,
      value: newValue,
      data: dataSearch,
    } as any);
  };

  useEffectOnce(() => {
    return () => {
      if (refTimeOut.current) clearTimeout(refTimeOut.current);
    };
  });

  return (
    <>
      <div className={`${className ?? ""}`}>
        <ComboBox
          {...others}
          allowCustom={allowCustom}
          textField={textField}
          filterable
          suggest
          data={dataSearch}
          itemRender={itemRender}
          loading={isLoading || isLoadingParent}
          onFilterChange={handleSearch}
          value={comboBoxValue}
          onChange={handleOnChange}
          popupSettings={{ className: "cc-picker-search-api" }}
        />
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
