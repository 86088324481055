import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";

import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { useDeferredDutyStore } from "@app/products/property/deferred-duty/[id]/store";
import {
  getInitialDataModifyDeferredDuty,
  postRaiseDeferredDutyInterest,
} from "@app/products/property/deferred-duty/components/action-bar/dialogs/raise-interest/api";
import { RaiseDeferredDutyInterestDetailsFormStep } from "@app/products/property/deferred-duty/components/action-bar/dialogs/raise-interest/form-elements/details/_index";
import { RaiseDeferredDutyInterestTaskScheduleFormStep } from "@app/products/property/deferred-duty/components/action-bar/dialogs/raise-interest/form-elements/task-schedule/_index";
import {
  DTO_Workflow_RaiseDeferredDutyInterest,
  EKeysOfStepsRaiseDeferredDutyInterest,
} from "@app/products/property/deferred-duty/components/action-bar/dialogs/raise-interest/model";
import { useRaiseDeferredDutyInterestDialogStore } from "@app/products/property/deferred-duty/components/action-bar/dialogs/raise-interest/store";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IRaiseInterestDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  suffixTitle?: string;
  isShowSearchType?: boolean;
  isReadOnly?: boolean;
  isToBeApprovalMode?: boolean;
  isShowReasonRejection?: boolean;
  isShowReason?: boolean;
  isShowCancelWorkflowButton?: boolean;
  statusBadge?: IBadgeDialog[];
  isSaveOnNextStep?: boolean;
  isIncompleteMode?: boolean;
  isFromActionList?: boolean;
  deferredDutyId?: number;
}

export const RaiseInterestDialog = observer(
  ({
    onClose,
    statusBadge,
    dataFromActionList,
    prefixTitle,
    suffixTitle,
    isReadOnly = false,
    isToBeApprovalMode = false,
    isSaveOnNextStep = false,
    isFromActionList = false,
    isShowCancelWorkflowButton = false,
    isIncompleteMode = false,
    deferredDutyId,
  }: IRaiseInterestDialogProps) => {
    // Store
    const { currentFormTitle } = useCommonProductStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { isLoadingApprove } = useRaiseDeferredDutyInterestDialogStore();
    const { clearSelectedItems } = useCCProductListViewStore();
    const { deferredDuty, reLoadDeferredDuty } = useDeferredDutyStore();

    // Hook
    const [
      raiseDeferredDutyInterestInitData,
      setRaiseDeferredDutyInterestInitData,
    ] = useState<DTO_Workflow_RaiseDeferredDutyInterest>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title =
        currentFormTitle(formId ?? 0) || "Raise Interest - On Deferred Duty";
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, workflowHeader]);

    const initialValues = useMemo(() => {
      let initDetails: any = {};
      let initTaskChedule: any = {};

      if (raiseDeferredDutyInterestInitData) {
        const workflowDetail =
          raiseDeferredDutyInterestInitData?.WorkflowDetail;
        //Details
        if (workflowDetail) {
          initDetails = {
            ...initDetails,
            DDAccountId: workflowDetail?.DDAccountId,
            PreviousInterestTo: workflowDetail.PreviousInterestTo,
            CalculateInterestTo: workflowDetail.CalculateInterestTo,
            MarketInterestRate: workflowDetail.MarketInterestRate,
            PremiumInterestRate: workflowDetail.PremiumInterestRate,
          };
          initTaskChedule = {
            ...initTaskChedule,
            TaskType: workflowDetail.TaskType,
            TaskName: workflowDetail.TaskName,
            EmailRecipients: workflowDetail.EmailRecipients,
            RunImmediately: workflowDetail.RunImmediately,
            RunAt: workflowDetail.RunAt ?? new Date(),
            Journal_Number: workflowDetail.Journal_Number,
          };
        }
      }
      return {
        [EKeysOfStepsRaiseDeferredDutyInterest.Details]: initDetails,
        [EKeysOfStepsRaiseDeferredDutyInterest.TaskSchedule]: initTaskChedule,
        [EKeysOfStepsRaiseDeferredDutyInterest.Comments]: {},
        [EKeysOfStepsRaiseDeferredDutyInterest.Documents]: {},
      };
    }, [raiseDeferredDutyInterestInitData]);

    /**
     * Handle process with modes
     */
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleConfirmRetainProcess(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
      }
    };

    const handleParkProcess = async (
      payload: DTO_Workflow_RaiseDeferredDutyInterest
    ) => {
      //set loading button and dialog
      setIsLoadingPark(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_RaiseDeferredDutyInterest> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            pushNotification({
              title:
                e?.Notification ??
                "Raise deferred duty interest was parked successfully.",
              type: "success",
            });
          },
          defaultFailedMessage:
            "Raise deferred duty interest could not be parked.",
          modeProcess: WorkflowProcessMode.Park,
        };

      const setLoading = () => setIsLoadingPark(false);

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };

    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postRaiseDeferredDutyInterest,
          dataCancel: data,
          defaultSuccessMessage:
            "Raise deferred duty interest was cancelled successfully.",
          defaultErrorMessage:
            "Raise deferred duty interest could not be cancelled.",
        });
      } else {
        onClose();
      }
    };

    const handleConfirmRetainProcess = async (
      payload: DTO_Workflow_RaiseDeferredDutyInterest
    ) => {
      //set loading button and dialog
      setIsLoadingClose(true);

      //props send to process workflow
      const retainProps: IProcessWorkflow<DTO_Workflow_RaiseDeferredDutyInterest> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            pushNotification({
              title:
                e?.Notification ??
                "Raise deferred duty interest was parked successfully.",
              type: "success",
            });
          },
          defaultFailedMessage:
            "Raise deferred duty interest could not be parked.",
          modeProcess: WorkflowProcessMode.Park,
        };

      const setLoading = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog(undefined);
      };

      //calling api process workflow
      await handleProcessWorkflow(retainProps, setLoading);
    };

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        //Store submit event
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_RaiseDeferredDutyInterest
    ) => {
      const dataProcessed = processData(payload);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage:
          "Raise Interest has been successfully sent to the job queue. You will be notified once the job has completed.",
      });
    };

    const handleFinishProcess = async (
      payload: DTO_Workflow_RaiseDeferredDutyInterest
    ) => {
      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_RaiseDeferredDutyInterest> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            if (deferredDuty) {
              reLoadDeferredDuty().then(() => {
                pushNotification({
                  title:
                    e?.Notification ??
                    "Raise deferred duty interest successfully.",
                  type: "success",
                });
              });
            } else {
              eventEmitter.emit(CCGridEventType.RefreshOData);
              clearSelectedItems();
              pushNotification({
                title:
                  e?.Notification ??
                  "Raise deferred duty interest successfully.",
                type: "success",
              });
            }
          },
          defaultFailedMessage: "Raise deferred duty interest failed.",
          modeProcess: WorkflowProcessMode.Finish,
        };

      const setLoading = () => {};
      //calling api process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };

    const handleSaveAndNext = async (
      payload: DTO_Workflow_RaiseDeferredDutyInterest,
      isCloseDialog: boolean = false
    ): Promise<boolean> => {
      //Calling process Save at next button
      const response = await postRaiseDeferredDutyInterest(
        WorkflowProcessMode.Save,
        payload
      );

      setIsLoadingOnNext(false);

      //set default notification
      const defaultSuccessMessage =
        "Raise deferred duty interest saved successfully.";
      const defaultFailedMessage =
        "Raise deferred duty interest process could not be saved.";

      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (isCloseDialog) {
          onClose();
          pushNotification({
            title: response?.data?.Notification ?? defaultSuccessMessage,
            type: "success",
          });
        }
        // check is the first saving
        if (isFirstSave) {
          setIsFirstSave(false);
          //set current workflowDraft Id
          setWorkflowDraftId(response?.data?.ID || 0);
          // set payload to send
          setWorkflowHeader({
            ...workflowHeader,
            WorkflowDraft: {
              ...workflowHeader.WorkflowDraft,
              Workflow_Draft_Id: response?.data?.ID || workflowDraftId || 0,
            },
          });
        }
        return true;
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
          });
        return false;
      }
    };

    /**
     * Block description:
     * - Process data each step
     * - Process submit data
     * handle set initial value to step
     */
    const processData = (data: any) => {
      const workflowDetail: any = { ...data.Detail, ...data.TaskSchedule };
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_RaiseDeferredDutyInterest>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;
      const response = await postRaiseDeferredDutyInterest(
        modeProcess,
        payload
      );
      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    const handleNextButton = async (data: any) => {
      setIsLoadingOnNext(true);
      return await handleSaveAndNext(processData(data));
    };

    /**
     * common function
     * handle get initial value
     */
    const getWorkflowData = async () => {
      const draftId = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      const response = await getInitialDataModifyDeferredDuty(
        deferredDutyId,
        draftId
      );
      if (isSuccessResponse(response)) {
        const workflowDataResponse = response;
        const workflowData = workflowDataResponse?.data;
        if (workflowData) {
          //set New InitData
          setRaiseDeferredDutyInterestInitData({
            WorkflowDetail: workflowData?.WorkflowDetail,
            WorkflowHeader: workflowData?.WorkflowHeader,
          });
          //set workflow header and workflow Draft Id
          if (workflowData?.WorkflowHeader) {
            setWorkflowHeader(workflowData.WorkflowHeader);
            setWorkflowDraftId(
              workflowData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id ??
                0
            );
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
        } else {
          const responseError = response as APIResponse<
            DTO_Workflow_RaiseDeferredDutyInterest | ResponsePacket
          >;
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed.",
            },
          });
        }
      } else {
        const responseError = response as APIResponse<
          DTO_Workflow_RaiseDeferredDutyInterest | ResponsePacket
        >;
        notificationFormStepRef?.current?.setLoadingFormStep(false);
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error:
              (responseError.data as ResponsePacket)?.Errors ??
              "Load workflow failed.",
          },
        });
      }
    };

    const steps: IStep[] = [
      {
        label: "Details",
        component: RaiseDeferredDutyInterestDetailsFormStep,
        visible: true,
        key: EKeysOfStepsRaiseDeferredDutyInterest.Details,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Task schedule",
        component: RaiseDeferredDutyInterestTaskScheduleFormStep,
        visible: true,
        key: EKeysOfStepsRaiseDeferredDutyInterest.TaskSchedule,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Comments",
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfStepsRaiseDeferredDutyInterest.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Journal,
        },
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        visible: true,
        key: EKeysOfStepsRaiseDeferredDutyInterest.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Journal_DD_Raise_Interest,
        },
      },
    ];

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        onSubmit={handleSubmit}
        listButtonId={listSubmitButton}
        initialValues={initialValues}
        initialSteps={steps}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            disabled={isLoadingApprove}
            titleHeader={titleHeader}
            onClose={() => handleCloseDialog(renderProps)}
            bodyElement={renderProps.children}
            badge={statusBadge}
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                {isShowParkButton(isFromActionList, isIncompleteMode) && (
                  <Button
                    iconClass={isLoadingPark ? "fas fa-spinner fa-spin" : ""}
                    className={"cc-dialog-button"}
                    id={EListSubmitButton.Park}
                    onClick={renderProps.submitButton.onClick}
                    disabled={renderProps.nextButton.disabled || isLoadingPark}
                  >
                    Park
                  </Button>
                )}
                {isShowCancelWorkflowButton && (
                  <Button
                    className={"cc-dialog-button"}
                    disabled={
                      isLoadingApprove || isLoadingOnNext || isLoadingPark
                    }
                    id={EListSubmitButton.Cancel}
                    onClick={renderProps.submitButton.onClick}
                  >
                    Cancel
                  </Button>
                )}
                {!renderProps.prevButton.disabled && (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                )}
                {isToBeApprovalMode || isReadOnly ? (
                  !renderProps.isLastStep && (
                    <Button
                      themeColor="primary"
                      id="cc-next-step-button"
                      disabled={
                        isLoadingOnNext || renderProps.nextButton.disabled
                      }
                      className={"cc-dialog-button"}
                      iconClass={
                        isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                      }
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingOnNext
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )
                ) : (
                  <Button
                    themeColor="primary"
                    id={renderProps.nextButton.idButton}
                    disabled={
                      isLoadingOnNext || renderProps.nextButton.disabled
                    }
                    iconClass={isLoadingOnNext ? "fas fa-spinner fa-spin" : ""}
                    className={"cc-dialog-button"}
                    onClick={renderProps.nextButton.onClick}
                  >
                    {isLoadingOnNext ? "Saving" : renderProps.nextButton.label}
                  </Button>
                )}
              </div>
            }
          />
        )}
      />
    );
  }
);
