import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { AnimalActionBarNavDropdown } from "@app/products/animals/components/action-bar/nav-dropdown/_index";
import { ANIMALS_POUND_REGISTER_LIST_VIEW_URL } from "@app/products/animals/pound-register/constant";
import { Svc_Animals_PoundRegisters } from "@app/products/animals/pound-register/model";
import { animalRoute } from "@app/products/animals/route";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { nameOfFactory } from "@common/utils/common";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { poundRegisterRoute } from "../route";
import { colAnimalsPoundRegisterByStatus } from "./config";

const nameOf = nameOfFactory<Svc_Animals_PoundRegisters>();
export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Animals_Form_PoundRegister,
    productType: PRODUCT_TYPE_NUMBER.Animals,
  });

  useCCListViewActionBar({
    title: animalRoute.name,
    leftComponents: [
      <AnimalActionBarNavDropdown category={poundRegisterRoute.path} />,
    ],
    centerComponents: [
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.Animals}
        dataSetFilter={MAILMERGEDATASET.Animals_Registration}
        recordType={RECORDTYPE.Animals_PoundRegister}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.Animals}
        recordType={RECORDTYPE.Animals_PoundRegister}
        keyField={nameOf("ID")}
      />,
      <DeleteButton
        recordType={RECORDTYPE.Animals_PoundRegister}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      // Update later when the "pound register" manage page is done
      // <ListViewBookmarkIcon
      //   linkUrl={ANIMALS_ROUTE}
      //   productType={PRODUCT_TYPE.Animals}
      //   recordType={RECORDTYPE.Animals_PoundRegister}
      //   detail={AnimalPoundRegisterByStatusBookmark.getBookmarkListViewDisplayName}
      //   displayName={AnimalPoundRegisterByStatusBookmark.getBookmarkListViewDetailRecord}
      //   listViewDetail={
      //     AnimalPoundRegisterByStatusBookmark.getBookmarkListViewDetail
      //   }
      //   listViewDisplayName={
      //     AnimalPoundRegisterByStatusBookmark.getBookmarkListViewDisplayName
      //   }
      // />,
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        columnFields={colAnimalsPoundRegisterByStatus}
        state={{
          group: [{ field: nameOf("Status"), dir: "asc" }],
          sort: [{ field: nameOf("RegisterNo"), dir: "desc" }],
        }}
        dataUrl={ANIMALS_POUND_REGISTER_LIST_VIEW_URL}
        primaryField={nameOf("ID")}
      />
    </LoadingPermissionWrapper>
  );
});
