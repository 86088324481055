import { DTO_PIC_Swine } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfSwine = nameOfFactory<DTO_PIC_Swine>();
export const colSwineBrandSummary: IColumnFields[] = [
  {
    field: nameOfSwine("Swine_Type_Name"),
    title: "Swine Type",
  },
  {
    field: nameOfSwine("Effective_From"),
    title: "From Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfSwine("Effective_To"),
    title: "To Date",
    format: DATE_FORMAT.DATE,
  },
];
