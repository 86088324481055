import { apiCoreValidationMapshare } from "@app/core/components/common/utils";
import { DELWPMapshareServiceSuggestions } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const searchAddress = async (
  address: string
): Promise<APIResponse<DELWPMapshareServiceSuggestions | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DELWPMapshareServiceSuggestions>(
      apiCoreValidationMapshare(),
      {
        params: {
          address,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
