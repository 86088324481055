import { DTO_WorkflowHeader } from "@app/products/property/model";

export enum eSearchAssessmentBy {
  AssessmentNumber = "Assessment_NumberX",
  AssessmentId = "Assessment_Id",
  ValuationNumber = "Valuation_Number",
  AssessmentReference = "Assessment_Reference",
}
export interface ISearchBy {
  Key: eSearchAssessmentBy;
  Value: string;
}

export interface ISearchAssessment {
  AssessmentNumber: string;
  AssessmentId: string;
  ValuationNumber: string;
  Address?: string;
  Ratepayer?: string;
}

export interface IAddAssessmentDataForm {
  Assessment?: VO_Assessment_Ratepayer;
  _option: {
    SearchBy: {
      Value: ISearchBy;
      Data: ISearchBy[];
    };
    Assessment: {
      Data: VO_Assessment_Ratepayer[];
      Loading: boolean;
    };
  };
}

export interface VO_Assessment_Ratepayer {
  Duplicate_Ratepayer_Id: number | null;
  Ratepayer_Postcode: string;
  Ratepayer_Name_Address: string;
  Ratepayer_Locality: string;
  Ratepayer_Address: string;
  Ratepayer_State: string;
  Ratepayer_Name: string;
  Ratepayer_Id: number | null;
  Valuation_Number: number | null;
  Assessment_NumberX: string;
  Assessment_Number: number | null;
  Assessment_Reference: string;
  Assessment_Id: number;
  Valuation_NumberX: string;
}

export interface DTO_WorkflowDetail_Supplementary_Associate {
  Component_Number: number;
  Component_Id?: number | null;
  Supplementary_Id: number;
  NewSupplementary?: boolean;
  SelectFromMyList: boolean | null;
  IsMultipleAllowed: boolean;
  Component_Ids: string;
}

export interface DTO_Workflow_Supplementary_Associate {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_Supplementary_Associate;
}

export enum ButtonNameSubmit {
  FINISH = "btn-finish",
  ADJUST = "btn-adjust",
}
