import {
  DTO_Entity_Concession_Card,
  EKeysOfNewContactSteps,
} from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { nameOfFactory } from "@common/utils/common";
import { isValidValueWithMask } from "@common/utils/formatting";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { colConcessionCardsHeld } from "./config";

const nameOfConcessionCard = nameOfFactory<DTO_Entity_Concession_Card>();
export const ConcessionCardsFormStep = (props: IFormStepElement) => {
  const newValidator = useCallback(
    (value: any, valueGetter: (name: string) => any) => {
      if (props?.options?.isReadOnly) return undefined;
      const wrongItem = value?.Concession_Cards.find(
        (concessionCard: DTO_Entity_Concession_Card) =>
          !isValidValueWithMask(
            concessionCard.Card_Number,
            concessionCard.Card_Number_InputMask
          )
      );
      if (wrongItem) return "Some card numbers are in the wrong format.";
      return undefined;
    },
    [props?.options]
  );
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={newValidator}
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = { isReadOnly: false },
  }: IFormStepElement) => {
    //get value
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const selectedConcessionCardHeld = getFieldValue(
      "_option.SelectedConcessionCardHeld"
    );
    const concessionCard = getFieldValue("Concession_Cards");
    if (options?.onChangeRef) {
      options.onChangeRef.current = { onChange, nameOf };
    }

    /**
     * handle selection change
     * @param dataItem
     */
    const handleGridSelectionChange = (
      dataItem: DTO_Entity_Concession_Card[]
    ) => {
      let newSelected = dataItem?.length ? { ...dataItem?.[0] } : undefined;
      onChange(nameOf(`_option.SelectedConcessionCardHeld`), {
        value: newSelected,
      });
    };

    /**
     * handle update information selection
     * @param field
     * @param value
     */
    const handleConcessionCardHeldInfoChange = (field: string, value: any) => {
      onChange(nameOf(`_option.SelectedConcessionCardHeld.${field}`), {
        value: value,
      });
      if (selectedConcessionCardHeld) {
        const newConcessionCardHeld = concessionCard?.map(
          (concessionCardHeld: any) => {
            if (concessionCardHeld.Id !== selectedConcessionCardHeld.Id)
              return concessionCardHeld;
            concessionCardHeld[field] = value;
            return concessionCardHeld;
          }
        );
        onChange(nameOf("Concession_Cards"), {
          value: newConcessionCardHeld,
        });
      }
    };

    return (
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel
                title="Concession cards held"
                errorMessage={errors[EKeysOfNewContactSteps.ConcessionCards]}
              />
              <CCGrid
                readOnly={options?.isReadOnly}
                data={concessionCard}
                selectedRows={
                  selectedConcessionCardHeld && !options?.isReadOnly
                    ? [selectedConcessionCardHeld]
                    : []
                }
                columnFields={colConcessionCardsHeld}
                primaryField={nameOfConcessionCard("Id")}
                selectableMode="single"
                onSelectionChange={(dataItem: DTO_Entity_Concession_Card[]) => {
                  !options?.isReadOnly && handleGridSelectionChange(dataItem);
                }}
                editableMode={!options?.isReadOnly ? "cell" : undefined}
                onDataRowChange={(_dataRow, fieldChange, valueChange) => {
                  handleConcessionCardHeldInfoChange(fieldChange, valueChange);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
