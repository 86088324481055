import { EListSubmitButton } from "@app/products/property/model";

export const INVALID_WORKFLOW_DRAFT_ID = -1; // In case WorkflowDraftId is zero, it is a new creation

export const listSubmitButton = [
  EListSubmitButton.Approve,
  EListSubmitButton.Finish,
  EListSubmitButton.Park,
  EListSubmitButton.Save,
  EListSubmitButton.ConfirmCloseYes,
  EListSubmitButton.ConfirmCloseNo,
  EListSubmitButton.Close,
  EListSubmitButton.Cancel,
  EListSubmitButton.SendBack,
  EListSubmitButton.Reallocate,
  EListSubmitButton.Reject,
  EListSubmitButton.SaveWorkflow,
  EListSubmitButton.MappingSpatialWorkflow,
  EListSubmitButton.FetchSpatialWorkflow,
];

export const PROPERTY_ASSESSMENT_ROUTE = "/property/assessments";
