export const LOCAL_LAWS_MANAGE_ROUTE = "/local-laws";

export enum LocalLawsRoute {
  LOCALLAWS_PERMITS_REGISTER = "/locallaws/permits/register",
  LOCALLAWS_PERMITS_BYTYPE = "/locallaws/permits/bytype",
  LOCALLAWS_PERMITS_BYSTATUS = "/locallaws/permits/bystatus",
  LOCALLAWS_PERMITS_BYLOCATION = "/locallaws/permits/bylocation",
  LOCALLAWS_PERMITS_BYOFFICER = "/locallaws/permits/byofficer",
  LOCALLAWS_PERMITS_BYAREA = "/locallaws/permits/byarea",
  LOCALLAWS_PERMITS_NEWAPPLICATIONS = "/locallaws/permits/newapplications",
  LOCALLAWS_PERMITS_LODGED = "/locallaws/permits/lodged",
  LOCALLAWS_PERMITS_REFUSED = "/locallaws/permits/refused",
  LOCALLAWS_PERMITS_EXPIRED = "/locallaws/permits/expired",
  LOCALLAWS_PERMITS_DEACTIVATED = "/locallaws/permits/deactivated",
  LOCALLAWS_PERMITS_INSURANCESTATUS = "/locallaws/permits/insurancestatus",
  LOCALLAWS_RENEWALS_ALLBYDUESTATUS = "/locallaws/renewals/allbyduestatus",
  LOCALLAWS_RENEWALS_REQUIRESRENEWALFEE = "/locallaws/renewals/requiresrenewalfee",
  LOCALLAWS_RENEWALS_REQUIRESRENEWALNOTICE = "/locallaws/renewals/requiresrenewalnotice",
  LOCALLAWS_RENEWALS_REQUIRESRENEWAL = "/locallaws/renewals/requiresrenewal",
  LOCALLAWS_INSPECTIONS_BYDATE = "/locallaws/inspections/bydate",
  LOCALLAWS_INSPECTIONS_BYTYPE = "/locallaws/inspections/bytype",
  LOCALLAWS_INSPECTIONS_BYOFFICER = "/locallaws/inspections/byofficer",
  LOCALLAWS_INSPECTIONS_BYMONTH = "/locallaws/inspections/bymonth",
  LOCALLAWS_COMPLAINTS_BYREFNO = "/locallaws/complaints/byrefno",
  LOCALLAWS_COMPLAINTS_BYCATEGORY = "/locallaws/complaints/bycategory",
  LOCALLAWS_COMPLAINTS_BYSTATUS = "/locallaws/complaints/bystatus",
  LOCALLAWS_COMPLIANCENOTICES_BYDATE = "/locallaws/compliancenotices/bydate",
  LOCALLAWS_COMPLIANCENOTICES_BYDUESTATUS = "/locallaws/compliancenotices/byduestatus",
  LOCALLAWS_CRMSAPPLICATIONS_DRAFT = "/locallaws/crmsapplications/draft",
  LOCALLAWS_FINANCE_OSFEES = "/locallaws/finance/osfees",
  LOCALLAWS_FINANCE_PAIDFEES = "/locallaws/finance/paidfees",
  LOCALLAWS_FINANCE_FEESALL = "/locallaws/finance/feesall",
  LOCALLAWS_FINANCE_NOTSENTTOFINANCE = "/locallaws/finance/notsenttofinance",
  LOCALLAWS_FINANCE_SENTTOFINANCE = "/locallaws/finance/senttofinance",
  LOCALLAWS_SYSTEMADMIN_PERMITTYPES = "/locallaws/systemadmin/permittypes",
  LOCALLAWS_SYSTEMADMIN_CONDITIONS = "/locallaws/systemadmin/conditions",
  LOCALLAWS_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/llpermits/register",
  LOCALLAWS_DETAIL = "/locallaws/manageregistration",
}
