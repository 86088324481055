import { ICCRoute } from "@common/constants/ICCRoute";
import { communicationRoute } from "./communication/route";
import { homepageRoute } from "./homepage/route";
import { lookupsRoute } from "./lookups/route";
import { securityRoute } from "./security/route";
import { systemAdminRoute } from "./system-admin/route";

export const settingsRoute: ICCRoute = {
  path: "settings",
  name: "settings",
  children: [
    lookupsRoute,
    communicationRoute,
    homepageRoute,
    securityRoute,
    systemAdminRoute,
  ],
};
