import { ExistedPropertyJournal } from "@app/products/property/journals/[id]/components/forms/existed/_index";
import { NewPropertyJournal } from "@app/products/property/journals/[id]/components/forms/new/_index";
import { useJournalStore } from "@app/products/property/journals/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const ManagePropertyJournal = observer(() => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const journalId = params.id;
  const { resetMenu } = useCCSubActionBarStore();
  const { loadJournal, resetStore } = useJournalStore();
  const location = useLocation();
  const state: any = location?.state;

  useEffect(() => {
    if (isNew) return;
    let notification = undefined;
    if (state) {
      notification = state?.notification;
      window.history.replaceState({}, "");
    }
    loadJournal(parseInt(journalId), notification);
    // eslint-disable-next-line
  }, [journalId, isNew, loadJournal]);

  useEffect(() => {
    return () => {
      resetStore();
      resetMenu();
    };
    // eslint-disable-next-line
  });

  if (isNew) {
    return <NewPropertyJournal />;
  }

  return <ExistedPropertyJournal />;
});

export default ManagePropertyJournal;
