import { useWorkflowGroupButtonsSpatialStore } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/store";
import { isSpatialGISMode } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/util";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
} from "@app/products/property/model";
import { Button } from "@progress/kendo-react-buttons";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useEffectOnce } from "react-use";

interface IWorkflowGroupButtonsSpatial {
  workflowHeader?: DTO_WorkflowHeader;
  actionSubmit: (event: any) => void;
}
export const WorkflowGroupButtonsSpatial = observer(
  ({ workflowHeader, actionSubmit }: IWorkflowGroupButtonsSpatial) => {
    const { buttonFetch, buttonMapping, resetData } =
      useWorkflowGroupButtonsSpatialStore();

    const isShowGroupsButton = useMemo(() => {
      return isSpatialGISMode(workflowHeader);
    }, [workflowHeader]);

    useEffectOnce(() => {
      return () => resetData();
    });

    return (
      <>
        {isShowGroupsButton && !isNil(workflowHeader) ? (
          <>
            <Button
              className={"cc-dialog-button"}
              iconClass={
                buttonMapping?.isLoading ? "fas fa-spinner fa-spin" : ""
              }
              disabled={buttonMapping?.isDisabled || buttonMapping?.isLoading}
              id={EListSubmitButton.MappingSpatialWorkflow}
              onClick={actionSubmit}
            >
              Mapping
            </Button>
            <Button
              iconClass={buttonFetch?.isLoading ? "fas fa-spinner fa-spin" : ""}
              className={"cc-dialog-button"}
              id={EListSubmitButton.FetchSpatialWorkflow}
              disabled={buttonFetch?.isDisabled || buttonFetch?.isLoading}
              onClick={actionSubmit}
            >
              Fetch data
            </Button>
          </>
        ) : null}
      </>
    );
  }
);
