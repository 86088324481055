import { ThemeType, scrollBarColorCRMSGuidanceTab } from "@styles/constant";

export const getColorScrollBar = () => {
  let theme = localStorage.getItem("OOTheme");
  let styleScrollBar;
  if (theme) {
    styleScrollBar = scrollBarColorCRMSGuidanceTab[theme];
  } else {
    styleScrollBar = scrollBarColorCRMSGuidanceTab[ThemeType.Default];
  }
  return styleScrollBar;
};
