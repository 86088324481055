import { DTO_CreateAssessment_Parcel } from "@app/products/property/assessments/components/form-steps/new-assessment/components/form-elements/associations-parcel/model";
import {
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

import { PercentageTextBoxCustomCell } from "@app/products/property/assessments/components/custom-cell/percentage-text-box-custom-cell/_index";
import { DTO_Title } from "@app/products/property/assessments/components/form-steps/new-assessment/components/form-elements/associations-parcel/model";
import { BOOLEAN_FORMAT } from "@common/constants/common-format";

const nameOf = nameOfFactory<DTO_CreateAssessment_Parcel>();
export const colAssociationParcel: IColumnFields[] = [
  {
    field: nameOf("ParcelReference"),
    title: "Parcel (S-S-B-U)",
  },
  {
    field: nameOf("PropertyName"),
    title: "Property Name",
  },
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("AssociationFactorPercentage"),
    title: "Factor %",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
    editable: true,
    editCell: PercentageTextBoxCustomCell,
    width: 150,
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOf("CensusDistrict"),
    title: "Census District",
  },
  {
    field: nameOf("ElectoralDivision"),
    title: "Electoral Division",
  },
  {
    field: nameOf("StateCodeElectoralDivision"),
    title: "State Electoral Division",
  },
  {
    field: nameOf("FederalElectoralDivision"),
    title: "Federal Electoral Division",
  },
  {
    field: nameOf("LandArea"),
    title: "Land Area m2",
  },
  {
    field: nameOf("Zones"),
    title: "Zoning",
  },
  {
    field: nameOf("LandUse"),
    title: "Land Uses",
  },
  {
    field: nameOf("OwnerName"),
    title: "Owners",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("LocationDescriptor"),
    title: "Location Descriptor",
  },
  {
    field: nameOf("MapNumber"),
    title: "Map Number",
  },
  {
    field: nameOf("MapReference"),
    title: "Map Reference",
  },
  {
    field: nameOf("LandCategory"),
    title: "Land Category",
  },
  {
    field: nameOf("Parcel_Id"),
    title: "Parcel Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

const nameOfTitle = nameOfFactory<DTO_Title>();
export const colFoliosAssessment: IColumnFields[] = [
  {
    field: nameOfTitle("ReferenceNumber"),
    title: "Reference Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfTitle("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOfTitle("Status"),
    title: "Status",
  },
  {
    field: nameOfTitle("Lot"),
    title: "Lot",
  },
  {
    field: nameOfTitle("AssociationFactorPercentage"),
    title: "Factor %",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
    editable: true,
    editCell: PercentageTextBoxCustomCell,
    width: 150,
  },
  {
    field: nameOfTitle("PlanType"),
    title: "Plan Type",
  },
  {
    field: nameOfTitle("PlanNumber"),
    title: "Plan Number",
  },
  {
    field: nameOfTitle("Section"),
    title: "Section",
  },
  {
    field: nameOfTitle("NameAddress"),
    title: "Name Address",
  },
  {
    field: nameOfTitle("Volume"),
    title: "Volume",
  },
  {
    field: nameOfTitle("Folio"),
    title: "Folio",
  },
  {
    field: nameOfTitle("County"),
    title: "County",
  },
  {
    field: nameOfTitle("Parish"),
    title: "Parish",
  },
  {
    field: nameOfTitle("DivisionDate"),
    title: "Division Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfTitle("Frontage"),
    title: "Frontage",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOfTitle("LandArea"),
    title: "Land Area (m2)",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfTitle("IsAreaSurveyed"),
    title: "Is Area Surveyed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfTitle("Coverage"),
    title: "Coverage",
  },
  {
    field: nameOfTitle("TitleId"),
    title: "Title Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
