import { booleanFilterEnum } from "@app/products/animals/view-crms-event/by-refno/config";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { EventView } from "@app/products/crms/all-events/by-refno/model";
import {
  iconColumnViewConfigAlert,
  iconColumnViewConfigCallReturn,
  iconColumnViewConfigHazard,
  iconColumnViewConfigUrgent,
} from "@app/products/crms/config";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<EventView>();

export const colAnimalsViewCrmsEventByStatus: IColumnFields[] = [
  {
    field: nameOf("RefNo"),
    title: "Reference Number",
    locked: true,
    linkTo: (dataItem: EventView) => {
      return `${CRMS_ROUTE}/` + dataItem.ID;
    },
  },
  ...iconColumnViewConfigAlert,
  ...iconColumnViewConfigUrgent,
  { field: nameOf("Description"), title: "Description" },
  {
    field: nameOf("DateTarget"),
    title: "Target",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Source"),
    title: "Source",
  },
  { field: nameOf("Category"), title: "Category" },
  ...iconColumnViewConfigHazard,
  { field: nameOf("Location"), title: "Location" },
  ...iconColumnViewConfigCallReturn,
  { field: nameOf("RequestedBy"), title: "Contact" },
  {
    field: nameOf("ContactDetails"),
    title: "Contact Number",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("DueStatus"),
    title: "Due Status",
  },
  {
    field: nameOf("DateRecorded"),
    title: "Recorded",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("RecordedBy"),
    title: "Recorded By",
  },
  {
    field: nameOf("OrgStructure"),
    title: "Org Structure",
  },
  {
    field: nameOf("ActionOfficer"),
    title: "Action Officer",
  },
  { field: nameOf("Coordinator"), title: "Coordinator" },
  {
    field: nameOf("DateWork"),
    title: "Work Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("FileNumber"),
    title: "File Number",
  },
  {
    field: nameOf("EnquiryRefNo"),
    title: "Assets Reference Number",
  },
  {
    field: nameOf("Event_ReferenceNoAdditional"),
    title: "Additional Reference Number",
  },
  {
    field: nameOf("OnBehalfOf"),
    title: "On Behalf Of",
  },
  {
    field: nameOf("Flag_Safety"),
    title: "Safety",
    filterByEnum: booleanFilterEnum,
  },
  {
    field: nameOf("Flag_VIP"),
    title: "VIP",
    filterByEnum: booleanFilterEnum,
  },
  {
    field: nameOf("Org2"),
    title: "Org 2",
    filterByEnum: booleanFilterEnum,
  },
];
