import { colAssociationsAssociatedName } from "@app/products/property/components/associations/components/associated-name/config";
import { DTO_AssociatedItem_AssociatedName } from "@app/products/property/components/associations/components/associated-name/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IAssociationsAssociatedNameProps {
  associationsAssociatedNameInfo:
    | DTO_AssociatedItem_AssociatedName[]
    | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_AssociatedName>();
export const AssociationsAssociatedName = ({
  associationsAssociatedNameInfo,
}: IAssociationsAssociatedNameProps) => {
  return (
    <CCGrid
      className="cc-associated-name-grid"
      data={associationsAssociatedNameInfo || []}
      columnFields={colAssociationsAssociatedName}
      primaryField={nameOf("Id")}
    />
  );
};
