import { DynamicGridFields } from "@app/products/animals/system-admin/settings/_id/components/dynamic-grid-fields/_index";
import { AnimalRenderSwitch } from "@app/products/animals/system-admin/settings/_id/components/switch/_index";
import { switchOptionsValueNumberString } from "@app/products/animals/system-admin/settings/_id/main/config";
import {
  ICommonFormProps,
  ISwitchCustomeFieldProps,
} from "@app/products/animals/system-admin/settings/_id/main/model";
import { useAnimalsSettingManageStore } from "@app/products/animals/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { isEditing } = useAnimalsSettingManageStore();

  const eSwitchFields: ISwitchCustomeFieldProps[] = [
    {
      eSettingsField:
        ECorporateSettingsField.Animals_Display_CertifiedAssistance,
    },
    {
      eSettingsField:
        ECorporateSettingsField.Animals_Mandatory_CertifiedAssistance,
    },
    {
      eSettingsField:
        ECorporateSettingsField.Animals_EnablePortal_CertifiedAssistance,
    },
    {
      eSettingsField:
        ECorporateSettingsField.Animals_RenewalLocked_CertifiedAssistance,
    },
  ];
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <AnimalRenderSwitch
          switchList={eSwitchFields}
          formRenderProps={formRenderProps}
          optionsValue={switchOptionsValueNumberString}
        />
      </div>

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <DynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
