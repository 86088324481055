import { isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useIsNew } from "@common/hooks/useIsNew";
import { getActionsTypes } from "@common/pages/actions/api";
import { colActionTypePicker } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/config";
import { IRespActionType } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/model";
import { productLogic } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/until";
import { nameOfFactory } from "@common/utils/common";
import { ICCGridProps } from "@components/cc-grid/_index";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<IRespActionType>();

export interface IActionTypePickerProps extends ICCInputPickerProps {
  recordType?: RECORDTYPE;
  onPickActionType: (value: IRespActionType) => void;
  selectableMode?: "single" | "multiple" | "none";
  grid?: ICCGridProps;
}
export const ActionTypePicker = ({
  recordType,
  selectableMode,
  textProduce,
  onChange,
  onError,
  onPickActionType,
  grid,
  ...others
}: IActionTypePickerProps) => {
  const isNew = useIsNew();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionTypeList, setActionTypeList] = useState<IRespActionType[]>();

  const handleOnChangeActionType = (event: ICCInputPickerChangeEvent) => {
    const value: IRespActionType = event.value;
    if (onPickActionType) onPickActionType(value);
  };

  useEffectOnce(() => {
    setIsLoading(true);
    const productType: PRODUCT_TYPE = productLogic(recordType);
    getActionsTypes(productType).then((response) => {
      if (isSuccessResponse(response)) {
        if (response?.data) {
          if (!isNew && productType === PRODUCT_TYPE.Core) {
            response.data.shift();
          }
          setActionTypeList(response.data);
        } else {
          const error = response?.error ?? "Keyword list load failed";
          if (onError) onError(error);
        }
      }
    });
    setIsLoading(false);
  });
  return (
    <CCInputPicker
      {...others}
      isLoading={isLoading}
      dialog={{
        titleHeader: "Pick Action Type",
        maxWidth: "60%",
      }}
      grid={{
        ...grid,
        columnFields: colActionTypePicker,
        primaryField: nameOf("ID"),
        selectableMode: selectableMode ?? "single",
        data: actionTypeList,
      }}
      onChange={handleOnChangeActionType}
      textProduce={textProduce}
    />
  );
};
