import { Kennel_Status } from "@app/products/animals/kennels/[id]/model";
import { useAnimalsKennelStore } from "@app/products/animals/kennels/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const KennelIssueRegistrationButton = observer(() => {
  const {
    animalsKennel,
    isWorkflowLoading,
    setIsShowKennelIssueRegistrationDialog,
  } = useAnimalsKennelStore();

  const isVisible = useMemo(() => {
    return animalsKennel?.Status_ENUM === Kennel_Status.NewRegistration;
  }, [animalsKennel]);

  return isVisible ? (
    <CCNavButton
      title={"Issue registration"}
      onClick={() => {
        setIsShowKennelIssueRegistrationDialog(true);
      }}
      disabled={isWorkflowLoading}
    />
  ) : null;
});
