import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { AmalgamateAssessmentCommonButton } from "@app/products/property/assessments/components/action-bar/buttons/amalgamate-assessment/_index";
import { RequestAmalgamateAssessmentObj } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/model";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const AmalgamateAssessmentManagePageButton = observer(() => {
  const { assessment } = useAssessmentStore();

  const requestAmalgamateObj: RequestAmalgamateAssessmentObj = useMemo(() => {
    return {
      Assessments: [
        {
          Assessment_Id: assessment?.AssessmentId ?? 0,
          StatusId: assessment?.Assessment?.StatusId ?? 0,
        },
      ],
      Assessment_Ids: isNil(assessment?.AssessmentId)
        ? []
        : [assessment?.AssessmentId ?? 0],
    };
  }, [assessment?.Assessment?.StatusId, assessment?.AssessmentId]);

  return (
    <AmalgamateAssessmentCommonButton
      requestAmalgamateObj={requestAmalgamateObj}
    />
  );
});
