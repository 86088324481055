import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import {
  DTO_ChangeOfOwnershipProcessed,
  DTO_Component_ChangeOfOwnership,
} from "@app/products/property/assessments/[id]/components/child-screens/transfer/model";
import {
  TypesActionsWorkflow,
  WorkflowApprovalStatus,
  WorkflowStatus,
} from "@app/products/property/components/action-bar/property-workflow/model";
import { ICCViewColumn, eVisibility } from "@app/products/property/model";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { IColumnFields, ICustomFormat } from "@components/cc-grid/model";
import { cloneDeep, isNull, isNumber } from "lodash";

export const isFieldVisible = (visibility?: eVisibility) => {
  if (!visibility) return false;
  return visibility === eVisibility.Visible;
};

export const processChangeOfOwnershipData = (
  rawData: DTO_Component_ChangeOfOwnership | undefined
): DTO_ChangeOfOwnershipProcessed[] | undefined => {
  if (rawData?.ChangeOfOwnerships) {
    rawData?.ChangeOfOwnerships.forEach((item: any) => {
      item["NewNameAndAddressWithId"] = item.NewNameAndAddress
        ? `${item.NewNameAndAddress} [${item.NewNameAndAddress_Id}]`
        : "";
      item["OldNameAndAddressWithId"] = item.OldNameAndAddress
        ? `${item.OldNameAndAddress} [${item.OldNameAndAddress}]`
        : "";
    });
    return rawData.ChangeOfOwnerships as DTO_ChangeOfOwnershipProcessed[];
  }
  return undefined;
};

export const isShowParkButton = (
  isFromActionList: boolean = false,
  isIncompleteMode: boolean = false
): boolean => {
  return isFromActionList ? isIncompleteMode : true;
};

export const processDynamicColumns = (
  gridColumns: IColumnFields[],
  viewConfig: ICCViewColumn[],
  dynamicFormat?: (
    viewConfig: ICCViewColumn,
    colConfig: IColumnFields
  ) => ICustomFormat | any
): IColumnFields[] => {
  const clonedViewConfig = cloneDeep(viewConfig);
  const processGridColumns = gridColumns
    .map((gridColumnsItem: any) => {
      const index = clonedViewConfig.findIndex(
        (viewColumn: ICCViewColumn) =>
          viewColumn.DataColumn === gridColumnsItem.field &&
          !viewColumn.IsHidden
      );
      if (index === -1) {
        return null;
      }

      let updatedCol = {
        ...gridColumnsItem,
        title: clonedViewConfig[index]?.ColumnName ?? gridColumnsItem.title,
        format: dynamicFormat
          ? dynamicFormat?.(clonedViewConfig[index], gridColumnsItem)
          : gridColumnsItem.format,
      };

      //Remove found column in visible view config
      clonedViewConfig.splice(index, 1);
      return updatedCol;
    })
    .filter((gridColumnsItem: IColumnFields) => !isNull(gridColumnsItem));

  return processGridColumns.length > 0 ? processGridColumns : gridColumns;
};

type StringKeyOf<T> = Extract<keyof T, string> | keyof T | string;

export const convertValueLOVToNumber = <T extends object>(
  lovData: T[],
  key?: StringKeyOf<T>
) => {
  // Check if key is a valid key of T
  const isValidKey = key ? lovData.length > 0 && key in lovData[0] : true;
  if (!isValidKey) {
    return [];
  }
  key = key || "Code";
  return (
    lovData?.reduce(function (res, cur) {
      const valueKeyNumber = parseInt(cur[key as keyof T] + "");
      if (cur[key as keyof T] && isNumber(valueKeyNumber)) {
        res.push({ ...cur, [key as keyof T]: valueKeyNumber as number });
      }
      return res;
    }, [] as any[]) ?? []
  );
};

export const getWorkflowStatus = ({
  Workflow_Status,
  Workflow_Approval_Status,
}: VO_Workflow_Draft) => {
  switch (Workflow_Status) {
    case WorkflowStatus.Saved:
    case WorkflowStatus.Parked:
      return TypesActionsWorkflow.Incomplete;
    case WorkflowStatus.Cancelled:
      return TypesActionsWorkflow.Cancelled;
    case WorkflowStatus.Completed:
      switch (Workflow_Approval_Status) {
        case WorkflowApprovalStatus.ToBeApproved:
        case WorkflowApprovalStatus.AwaitingGisApproval:
          return TypesActionsWorkflow.BeingApproved;
        case WorkflowApprovalStatus.Approved:
          return TypesActionsWorkflow.Approved;
        case WorkflowApprovalStatus.Rejected:
          return TypesActionsWorkflow.Rejected;
      }
  }
};

export const getContactAlertFromStringArray = (
  arr: (string | null)[],
  prefixTitle: string = "Contact alert: "
) => {
  const resultArr: IAppNotificationItem[] = [];
  arr.forEach((alert: string | null, index: number) => {
    if (alert) {
      resultArr.push({
        id: index.toString(),
        autoClose: false,
        title: `${prefixTitle}${alert}`,
        type: "warning",
        lock: true,
      } as IAppNotificationItem);
    }
  });
  return resultArr;
};

/**
 * Format valuations dynamically
 * @param item: row data or column config
 */
export const formatDynamicValuations = (item?: any): ICustomFormat => {
  if (!item) {
    return {
      defaultFormat: NUMBER_FORMAT.NUMBER,
      customFormat: NUMBER_FORMAT.NUMBER,
    };
  }
  // DecimalPlaces is a decimal field in backend DTO (API will return 1.0, 2.0, 3.0, etc.)
  // need to truncate the decimal part from UX
  const decimalPlaces = Math.trunc(item?.DecimalPlaces ?? 0);

  if (item?.IsCurrency) {
    return {
      defaultFormat: CURRENCY_FORMAT.CURRENCY1,
      customFormat: CURRENCY_FORMAT.CURRENCY1 + decimalPlaces,
    };
  }
  return {
    defaultFormat: NUMBER_FORMAT.NUMBER,
    customFormat: NUMBER_FORMAT.NUMBER + decimalPlaces,
  };
};
