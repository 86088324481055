import { useLookupTableStore } from "@app/products/crms/system-admin/lookup-tables/[id]/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export const CRMSSystemAdminLookupTablesDetailsTab = observer(() => {
  const { lookupTable, isLoadingDetail, loadLookupTableDetails } =
    useLookupTableStore();
  const { lastSelectedId } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const lookupTablesID = params.id || lastSelectedId;

  useEffect(() => {
    if (lookupTablesID) loadLookupTableDetails(lookupTablesID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupTablesID]);

  if (isLoadingDetail) return <Loading isLoading={isLoadingDetail} />;

  if (!lookupTable) return <NoData />;

  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Created date:"}
          value={formatDisplayValue(
            lookupTable?.Sys_CreatedDate,
            DATE_FORMAT.DATE
          )}
        />

        <ProductReferenceRow
          title={"Created by:"}
          value={lookupTable?.Sys_CreatedBy}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
