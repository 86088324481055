import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { DTO_Deferment_LOVs } from "@app/products/property/assessments/deferments/components/action-bar/dialogs/create-deferment/model";
import {
  getInitialDataModifyDeferment,
  postNewModifyDeferment,
} from "@app/products/property/assessments/deferments/components/action-bar/dialogs/modify-deferment/api";
import { ModifyDefermentDetailsFormStep } from "@app/products/property/assessments/deferments/components/action-bar/dialogs/modify-deferment/form-elements/details/_index";
import {
  DTO_WorkflowDetail_ModifyDeferment,
  DTO_Workflow_ModifyDeferment,
  EKeysOfStepsModifyDeferment,
  ModifyDefermentRequestObjFromSpatial,
} from "@app/products/property/assessments/deferments/components/action-bar/dialogs/modify-deferment/model";
import { useModifyDefermentDialogStore } from "@app/products/property/assessments/deferments/components/action-bar/dialogs/modify-deferment/store";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { INewProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IModifyDefermentDialogProps {
  onClose: () => void;
  isReadOnly?: boolean;
  isShowCancelWorkflowButton?: boolean;
  isIncompleteMode?: boolean;
  isFromActionList?: boolean;
  isSaveOnNextStep?: boolean;
  isToBeApprovalMode?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  defermentAssessmentId?: number;
  isRedirectManagePage?: boolean;
  statusBadge?: IBadgeDialog[];
}

export const ModifyDefermentDialog = observer(
  ({
    onClose,
    isReadOnly,
    isFromActionList = false,
    isShowCancelWorkflowButton = false,
    isIncompleteMode = false,
    isSaveOnNextStep = false,
    isToBeApprovalMode = false,
    prefixTitle,
    suffixTitle,
    dataFromActionList,
    defermentAssessmentId,
    isRedirectManagePage = true,
    statusBadge,
  }: IModifyDefermentDialogProps) => {
    const history = useHistory();
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);
    const { currentFormTitle } = useCommonProductStore();
    const { setModifyDefermentLOVs } = useModifyDefermentDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const [modifyDefermentInitData, setModifyDefermentInitData] =
      useState<DTO_Workflow_ModifyDeferment>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const { pushNotification } = useCCAppNotificationStore();
    const [isLoadingProcess, setIsLoadingProcess] = useState<
      WorkflowProcessMode | undefined
    >();
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);

    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "Modify Deferment";
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, workflowHeader]);

    //#region <Set up initial value>
    const initialValue = useMemo((): any => {
      let initDetails: any = {
        ...modifyDefermentInitData?.WorkflowDetail?.DefermentsDetails,
      };

      return {
        [EKeysOfStepsModifyDeferment.Details]: initDetails,
        [EKeysOfStepsModifyDeferment.Comments]: {},
        [EKeysOfStepsModifyDeferment.Documents]: {},
      };
    }, [modifyDefermentInitData]);
    //#endregion

    //#region <STEPS>
    const steps: IStep[] = [
      {
        label: "Details",
        component: ModifyDefermentDetailsFormStep,
        visible: true,
        key: EKeysOfStepsModifyDeferment.Details,
        options: {
          isReadOnly,
        },
        initialValues: initialValue[EKeysOfStepsModifyDeferment.Details],
      },
      {
        label: "Comments",
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfStepsModifyDeferment.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Assessment,
        },
        initialValues: initialValue[EKeysOfStepsModifyDeferment.Comments],
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        visible: true,
        key: EKeysOfStepsModifyDeferment.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Modify_Deferment,
        },
        initialValues: initialValue[EKeysOfStepsModifyDeferment.Documents],
      },
    ];
    //#endregion

    //#region <HANDLE SUBMIT>
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          handleConfirmFinish(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleParkProcess(
            processData(data),
            EListSubmitButton.ConfirmCloseYes
          );
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
      }
    };
    //#endregion

    //#region <FINISH>
    const handleConfirmFinish = (payload: DTO_Workflow_ModifyDeferment) => {
      const dataProcessed = processData(payload, WorkflowProcessMode.Finish);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage:
          "The modify deferment will be modified based on the information provided. Are you sure you want to submit?",
      });
    };

    const handleFinishProcess = async (
      payload: DTO_Workflow_ModifyDeferment
    ) => {
      //set loading button
      setIsLoadingProcess(WorkflowProcessMode.Finish);
      //props send to process workflow
      const finishProps: INewProcessWorkflow<DTO_Workflow_ModifyDeferment> = {
        payload: payload,
        actionSuccess: (e) => {
          const notificationContent: IAppNotificationItemAddProps = {
            title:
              e?.Notification ?? "Modify deferment was completed successfully",
            type: "success",
          };
          onClose();
          isRedirectManagePage &&
            history.push(
              `${PROPERTY_ASSESSMENT_ROUTE}/${modifyDefermentInitData?.WorkflowDetail?.DefermentsDetails?.Assessment_Id}`,
              {
                notification: notificationContent,
              }
            );
          !isRedirectManagePage && pushNotification(notificationContent);
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        defaultFailedMessage: "Modify deferment could not be modified.",
        modeProcess: WorkflowProcessMode.Finish,
      };
      //calling api process workflow
      await handleProcessWorkflow(finishProps);
    };
    //#endregion

    //#region <PARK>
    const handleParkProcess = async (
      payload: DTO_Workflow_ModifyDeferment,
      mode?: EListSubmitButton
    ) => {
      //set loading button and dialog
      setIsLoadingProcess(WorkflowProcessMode.Park);
      mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
      const actionCloseRetainDialog = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog();
      };
      //props send to process workflow
      const parkProps: INewProcessWorkflow<DTO_Workflow_ModifyDeferment> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ?? "Modify deferment was parked successfully.",
            type: "success",
          });
          isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        actionClose: () => {
          mode === EListSubmitButton.ConfirmCloseYes &&
            actionCloseRetainDialog();
        },
        defaultFailedMessage: "Modify deferment could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps);
    };
    //#endregion

    //#region <CANCEL>
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postNewModifyDeferment,
          dataCancel: data,
          defaultSuccessMessage: "Modify deferment was cancelled successfully",
          defaultErrorMessage: "Modify deferment could not be cancelled",
        });
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <CLOSE>
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        //Store submit event
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <NEXT and SAVE>
    const handleNextButton = async (data: DTO_Workflow_ModifyDeferment) => {
      const newData = { ...data };
      const processPayload = processData(newData);
      //send data to call api save
      return handleSaveAndNext(processPayload);
    };

    const handleSaveAndNext = async (
      payload: DTO_Workflow_ModifyDeferment
    ): Promise<boolean> => {
      //check condition use for Save button
      setIsLoadingProcess(WorkflowProcessMode.Save);
      //Calling process Save at next button
      const response = await postNewModifyDeferment(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingProcess(undefined);
      //set default notification
      const defaultSuccessMessage = "Modify deferment was saved successfully.";
      const defaultFailedMessage = "Modify deferment could not be saved.";
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (!isSaveOnNextStep) {
          onClose();
          pushNotification({
            title: response?.data?.Notification ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          // check is the first saving
          if (isFirstSave) {
            setIsFirstSave(false);
            //set current workflowDraft Id
            setWorkflowDraftId(response?.data?.ID ?? 0);
            // set payload to send
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID,
              },
            });
          }
        }
        return true;
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      return false;
    };
    //#endregion

    //#region <PROCESS DATA, PROCESS WORKFLOW>
    const processData: DTO_WorkflowDetail_ModifyDeferment | any = (
      data: any
    ) => {
      let workflowDetail = {
        DefermentsDetails: {
          ...modifyDefermentInitData?.WorkflowDetail?.DefermentsDetails,
          ...data?.Details,
        },
      };

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    const handleProcessWorkflow = async (
      props: INewProcessWorkflow<DTO_Workflow_ModifyDeferment>
    ) => {
      const {
        actionSuccess,
        defaultFailedMessage,
        setLoading,
        modeProcess,
        payload,
      } = props;
      const response = await postNewModifyDeferment(modeProcess, payload);
      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        } else {
          if (props?.actionFail) props?.actionFail(response);
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        if (props?.actionFail) props?.actionFail(response);
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      if (props?.actionClose) props.actionClose();
    };
    //#endregion

    //#region <GET DATA>
    const getWorkflowData = async () => {
      const draftId = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);

      const requestObjFromSpatial = {
        AssessmentDefermentId: defermentAssessmentId,
      } as ModifyDefermentRequestObjFromSpatial;

      const response = await getInitialDataModifyDeferment(
        draftId,
        requestObjFromSpatial
      );
      if (Array.isArray(response)) {
        const [lovsAssessment, workflowData] = response;
        if (
          isSuccessResponse(response[0]) &&
          isSuccessResponse(response[1]) &&
          lovsAssessment?.data &&
          workflowData?.data
        ) {
          //set Lovs Data Assessment
          setModifyDefermentLOVs(
            lovsAssessment?.data?.dtoCreate_Deferment_LOVs
          );
          if (workflowData?.data) {
            //set New InitData
            setModifyDefermentInitData({
              WorkflowDetail: workflowData?.data?.WorkflowDetail,
              WorkflowHeader: workflowData?.data?.WorkflowHeader,
            });
            //set workflow header and workflow Draft Id
            if (workflowData?.data?.WorkflowHeader) {
              setWorkflowHeader(workflowData?.data.WorkflowHeader);
              setWorkflowDraftId(
                workflowData?.data?.WorkflowHeader?.WorkflowDraft
                  ?.Workflow_Draft_Id ?? 0
              );
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
        } else {
          let responseError: APIResponse<
            DTO_Deferment_LOVs | DTO_Workflow_ModifyDeferment | ResponsePacket
          > = response[0];
          if (!isSuccessResponse(response[1])) {
            responseError = response[1];
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        notificationFormStepRef?.current?.setLoadingFormStep(false);
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error: "Load workflow failed",
          },
        });
      }
    };
    //#endregion

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        onSubmit={handleSubmit}
        listButtonId={listSubmitButton}
        initialValues={initialValue}
        initialSteps={steps}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => {
          return (
            <CCDialog
              maxWidth="65%"
              titleHeader={titleHeader}
              badge={statusBadge}
              disabled={isLoadingProcess !== undefined}
              onClose={() => handleCloseDialog(renderProps)}
              bodyElement={renderProps.children}
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Park
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled ||
                        isLoadingProcess === WorkflowProcessMode.Park
                      }
                    >
                      Park
                    </Button>
                  )}
                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      disabled={!isNil(isLoadingProcess)}
                      id={EListSubmitButton.Cancel}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}
                  {(isToBeApprovalMode || isReadOnly) &&
                  renderProps.isLastStep ? null : (
                    <Button
                      themeColor="primary"
                      id={
                        !renderProps.isLastStep &&
                        (isToBeApprovalMode || isReadOnly)
                          ? "cc-next-step-button"
                          : renderProps.nextButton.idButton
                      }
                      disabled={
                        isLoadingProcess === WorkflowProcessMode.Save ||
                        renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Save
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingProcess === WorkflowProcessMode.Save
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
