import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IColumnFields } from "@components/cc-grid/model";

export const PermitTableType = [{ field: "Name", title: "Name" }];

export const OfficerType: IColumnFields[] = [
  { field: "Name", title: "Name" },
  { field: "Position", title: "Position" },
  { field: "Email", title: "Email" },
  { field: "WorkPhone", title: "Phone" },
  { field: "Mobile", title: "Mobile" },
];

export const InsuranceCompanyData: IKeyValuePacket[] = [
  { Key: 1, Value: "ACE INSURANCE LIMITED" },
  { Key: 2, Value: "ACS INSURANCE SERVICES" },
  { Key: 3, Value: "Affinity Insurance Brokers" },
  { Key: 4, Value: "Allianz" },
  { Key: 5, Value: "ALLIANZ AUST INSURANCE LTD" },
  { Key: 6, Value: "Allianz Aust." },
  { Key: 7, Value: "ALLIANZ AUSTRALIA" },
  { Key: 8, Value: "ALLIANZ AUSTRALIA INSURANCE LTD" },
  { Key: 9, Value: "ALLIANZ INSURANCE LIMITED" },
];
