import {
  getContact,
  getContactLovs,
  postContact,
} from "@app/core/contacts/_id/api";
import { ContactFormElement } from "@app/core/contacts/_id/components/child-screens/general/components/form-element/_index";
import { partnerValidator } from "@app/core/contacts/_id/components/child-screens/general/components/form-element/util";
import {
  AddressBook,
  ContactClassification,
  ContactLovs,
  ContactMethodPreferred,
  Svc_Contact,
} from "@app/core/contacts/_id/model";
import { produceContactSaveTrigger } from "@app/core/contacts/_id/util";
import { formatABN } from "@app/core/contacts/components/dialogs/contact/util";
import { ContactFromNarDialog } from "@app/core/contacts/components/dialogs/contacts-from-nar/_index";
import {
  getAddressBook,
  postABNDetails,
  postPickContactFromNar,
} from "@app/core/contacts/components/dialogs/contacts-from-nar/api";
import { Svc_Contact_PickContactNAR_Request } from "@app/core/contacts/components/dialogs/contacts-from-nar/model";
import { Contact } from "@app/products/town-planning/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import {
  AddressBookSection,
  ContactExtension,
  NARSYSTEM,
} from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { customLogger } from "@common/utils/logger";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { cloneDeep, isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";

export interface IContactDialogProps {
  initialValue?: Contact | null;
  onClose: () => void;
  onSubmit: (contact: Contact | null) => void;
  excludePartnership?: boolean;
  contactClassificationFilters?: ContactClassification[];
}
export const ContactDialog = observer(
  ({
    initialValue,
    onClose,
    onSubmit,
    excludePartnership,
    contactClassificationFilters,
  }: IContactDialogProps) => {
    const { settings } = useCommonCoreStore();
    const enabledABNChecker = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableABNChecker]
    );
    const autoPopUpPickNARContact = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_NAR_AutoPopup]
    );
    const narSystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_NARSystem]
    );
    const [contact, setContact] = useState<Svc_Contact | undefined>(undefined);
    const [loadingStatus, setLoadingStatus] = useState<"get" | "save" | "none">(
      "none"
    );
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const [lovs, setLovs] = useState<ContactLovs | undefined>();
    const [isShowPickFromNARDialog, setIsShowPickFromNARDialog] =
      useState<boolean>(false);
    const [isFormModified, setIsFormModified] = useState<boolean>(false);
    const isNew = !initialValue?.Contact_ID;
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const handleLoadContact = async (id: number, isNew: boolean) => {
      setLoadingStatus("get");
      let newContact: any = undefined;
      let isEmptyNameAndNarId: boolean = false;
      let errorResponse = undefined;
      let newLovs = undefined;
      const lovResponse = await getContactLovs(isNew);
      customLogger("Core Contact, Add/Show contact, getContactLovs").info(
        lovResponse?.data
      );
      if (isSuccessResponse(lovResponse)) {
        newLovs = lovResponse.data;
      } else {
        errorResponse = {
          status: lovResponse.status,
          error: lovResponse.error,
        };
      }

      if (!isNew) {
        const response = await getContact(id);
        customLogger("Core Contact, Add/Show contact, getContact").info(
          lovResponse?.data
        );
        if (isSuccessResponse(response)) {
          newContact = response.data;
          if (
            isEmpty(response.data?.NARNumber) &&
            isEmpty(response.data?.Person_FirstName) &&
            isEmpty(response.data?.Person_LastName)
          ) {
            isEmptyNameAndNarId = true;
          }
        } else {
          errorResponse = {
            status: response.status,
            error: response.error,
          };
        }
      } else {
        newContact = {
          ContactClassification_ENUM: newLovs?.Classifications[0]
            ? ContactClassification[
                newLovs?.Classifications[0]
                  ?.Value as keyof typeof ContactClassification
              ]
            : ContactClassification.SystemInitialise,
          AddressBookSection_Int: AddressBookSection.Global,
          PreferredMethodOfContact_ENUM: ContactMethodPreferred.None,
        };
        isEmptyNameAndNarId = true;
      }

      setLovs(newLovs);
      setResponseLoadError(errorResponse);
      setContact(newContact);
      setLoadingStatus("none");
      if (autoPopUpPickNARContact && isEmptyNameAndNarId)
        setIsShowPickFromNARDialog(true);
      return newContact;
    };

    const handleSubmit = async (event: FormSubmitClickEvent) => {
      if (!event.isValid || (!isFormModified && !event.isModified)) return;

      setLoadingStatus("save");
      let values = event.values as any;
      values = produceContactSaveTrigger(values, contact);
      const response = await postContact(values);
      customLogger("Core Contact, save contact, postContact").info(
        response?.data
      );
      if (isSuccessResponse(response)) {
        if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
          onSubmit({ ...values, Contact_ID: response.data.ID });
        } else {
          notificationRef.current?.pushNotification({
            autoClose: false,
            type: "error",
            description: response.data?.Errors,
          });
        }
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: response.data?.Errors ?? "Contact update failed",
          type: "error",
        });
      }
      setLoadingStatus("none");
    };

    const handleGetNARContact = async (
      narContactRequest: Svc_Contact_PickContactNAR_Request
    ) => {
      if (!narContactRequest) return;
      setIsShowPickFromNARDialog(false);
      setLoadingStatus("get");
      const response = await postPickContactFromNar(narContactRequest);
      setLoadingStatus("none");
      if (isSuccessResponse(response) && response.data?.ReturnObj) {
        const contactNAR = cloneDeep(response.data.ReturnObj);
        const verificationQuest = contact?.Extension_VerificationQuestion ?? "";
        const verificationResponse =
          contact?.Extension_VerificationResponse ?? "";
        const prefMethod: ContactMethodPreferred | undefined =
          contact?.PreferredMethodOfContact_ENUM ?? ContactMethodPreferred.None;
        let addressBook: AddressBook | null = null;
        let abnValid = contact?.ABNValid ?? false;
        let strABN = contact?.ABN ?? "";

        if (contact?.AddressBook) {
          setLoadingStatus("get");
          const responseAddressBook = await getAddressBook(
            contact.AddressBook.ProductType_ENUM,
            contact.AddressBook.AddressBookSection_ENUM
          );
          setLoadingStatus("none");
          if (isSuccessResponse(responseAddressBook)) {
            addressBook = responseAddressBook.data;
          } else {
            notificationRef.current?.pushNotification({
              autoClose: false,
              title: "Get address book failed.",
              type: "error",
              description: responseAddressBook.error,
            });
          }
        }

        const extensionTypes = contact?.ExtensionTypes
          ? [
              ...cloneDeep(contact?.ExtensionTypes),
              ContactExtension.HasVerificationQuestion,
              ContactExtension.HasVerificationResponse,
            ]
          : [
              ContactExtension.HasVerificationQuestion,
              ContactExtension.HasVerificationResponse,
            ];
        if (enabledABNChecker) {
          if (contact?.ABN && !contact?.ABNValid) {
            setLoadingStatus("get");
            const responseABN = await postABNDetails({
              IsABNValidation: true,
              AuthenticationGuid: null,
              SearchString: contact?.ABN.trim(),
              IncludeHistoricalData: false,
            });
            setLoadingStatus("none");
            if (
              isSuccessResponse(responseABN) &&
              !responseABN.data?.ErrorMessage
            ) {
              strABN = formatABN(responseABN.data?.ABN ?? "");
              abnValid = true;
            } else {
              abnValid = false;
              notificationRef.current?.pushNotification({
                autoClose: false,
                title: "Get ABN details failed.",
                type: "error",
                description:
                  responseABN.data?.ErrorMessage ?? responseABN.error,
              });
            }
          }

          if (contact?.ACN && !contact?.ACNValid) {
            setLoadingStatus("get");
            const responseACN = await postABNDetails({
              IsABNValidation: false,
              AuthenticationGuid: null,
              SearchString: contact?.ACN.trim(),
              IncludeHistoricalData: false,
            });
            setLoadingStatus("none");
            if (
              isSuccessResponse(responseACN) &&
              !responseACN.data?.ErrorMessage
            ) {
              strABN = formatABN(responseACN.data?.ABN ?? "");
              abnValid = true;
            } else {
              abnValid = false;
              notificationRef.current?.pushNotification({
                autoClose: false,
                title: "Get ACN details failed.",
                type: "error",
                description:
                  responseACN.data?.ErrorMessage ?? responseACN.error,
              });
            }
          }
        }
        const newContact: Svc_Contact = contact?.AddressBook
          ? {
              ...contactNAR,
              Extension_VerificationQuestion: verificationQuest,
              Extension_VerificationResponse: verificationResponse,
              PreferredMethodOfContact_ENUM: prefMethod,
              AddressBook: addressBook ?? contact?.AddressBook,
              ExtensionTypes: extensionTypes,
              ABNValid: abnValid,
              ABN: strABN,
            }
          : {
              ...contactNAR,
              Extension_VerificationQuestion: verificationQuest,
              Extension_VerificationResponse: verificationResponse,
              PreferredMethodOfContact_ENUM: prefMethod,
              ExtensionTypes: extensionTypes,
              ABNValid: abnValid,
              ABN: strABN,
            };
        setIsFormModified(true);
        setContact(newContact);
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: "Pick contact from NAR failed.",
          type: "error",
          description: response.error ?? response.data?.Errors,
        });
      }
    };

    useEffect(() => {
      handleLoadContact(initialValue?.Contact_ID ?? 0, isNew);
      // eslint-disable-next-line
    }, [initialValue?.Contact_ID, isNew]);

    const formKey =
      JSON.stringify(contact) + JSON.stringify(lovs) + isNew.toString();
    return (
      <Form
        onSubmitClick={handleSubmit}
        initialValues={contact}
        key={formKey}
        validator={partnerValidator}
        render={(formRenderProps: FormRenderProps) => {
          const { valid, modified } = formRenderProps;
          const disableSaveButton = !valid || (!isFormModified && !modified);

          return (
            <CCDialog
              maxWidth="90%"
              maxHeight="90%"
              disabled={loadingStatus !== "none"}
              titleHeader={
                contact?.DisplayName && contact?.DisplayName.trim() !== ""
                  ? contact?.DisplayName
                  : "New Contact"
              }
              onClose={onClose}
              bodyElement={
                loadingStatus === "get" ? (
                  <Loading isLoading />
                ) : responseLoadError ? (
                  <CCLoadFailed
                    responseError={responseLoadError}
                    onReload={() => {
                      handleLoadContact(initialValue?.Contact_ID ?? 0, isNew);
                    }}
                  />
                ) : (
                  <>
                    <div className="cc-form">
                      <CCLocalNotification ref={notificationRef} />
                      <ContactFormElement
                        notificationRef={notificationRef}
                        formRenderProps={formRenderProps}
                        isNew={isNew}
                        lovs={lovs}
                        excludePartnership={excludePartnership}
                        contactClassificationFilters={
                          contactClassificationFilters
                        }
                      />
                    </div>
                    {isShowPickFromNARDialog && (
                      <ContactFromNarDialog
                        onCloseDialog={() => {
                          setIsShowPickFromNARDialog(false);
                        }}
                        onSubmitNARContact={handleGetNARContact}
                      />
                    )}
                  </>
                )
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  {narSystem !== NARSYSTEM.None && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={() => {
                        setIsShowPickFromNARDialog(true);
                      }}
                    >
                      Select from NAR
                    </Button>
                  )}
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    disabled={disableSaveButton}
                    themeColor="primary"
                    onClick={formRenderProps.onSubmit}
                    iconClass={
                      loadingStatus === "save" ? "fas fa-spinner fa-spin" : ""
                    }
                    className={"cc-dialog-button"}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
