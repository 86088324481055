import { Kennel_Status } from "@app/products/animals/kennels/[id]/model";
import { useAnimalsKennelStore } from "@app/products/animals/kennels/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const KennelDeregisterButton = observer(() => {
  const { animalsKennel, isWorkflowLoading, setIsShowKennelDeregisterDialog } =
    useAnimalsKennelStore();

  const isVisible = useMemo(() => {
    return animalsKennel?.Status_ENUM === Kennel_Status.Active;
  }, [animalsKennel]);

  return isVisible ? (
    <CCNavButton
      title={"Deregister"}
      onClick={() => {
        setIsShowKennelDeregisterDialog(true);
      }}
      disabled={isWorkflowLoading}
    />
  ) : null;
});
