import { colCouncilDetail } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/components/dialog/contact-manager/config";
import { ICouncilContact } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/components/dialog/contact-manager/model";
import { ContactClassification } from "@common/constants/enumerations";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { GridSearch } from "@components/cc-grid-search/_index";
import { State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useState } from "react";
import "./_index.scss";
interface IContactManagerDialogProps {
  isMultiple?: boolean;
  onClose: () => void;
  onSubmit: (data: ICouncilContact[]) => void;
  isSubmitLoading: boolean;
  classification?: ContactClassification;
  title?: string;
  dataURL?: string;
}

export const ContactManagerDialog = ({
  isMultiple = false,
  onClose,
  onSubmit,
  isSubmitLoading,
  classification = 6,
  title = "Contact Manager",
  dataURL = `odata/core/internal/contacts/GetContactsByClassification(classification=${classification})?$count=true&`,
}: IContactManagerDialogProps) => {
  const nameOf = nameOfFactory<ICouncilContact>();
  const [stateData, setStateData] = useState<State>({
    sort: [{ field: nameOf("DisplayName"), dir: "asc" }],
  });

  return (
    <Form
      onSubmitClick={(event: FormSubmitClickEvent) =>
        onSubmit(
          (event.values?.SelectedCouncilContacts ?? []) as ICouncilContact[]
        )
      }
      render={(formRenderProps: FormRenderProps) => {
        const selectedCouncilContacts =
          formRenderProps.valueGetter("SelectedCouncilContacts") ?? [];
        return (
          <div className="contact-manager">
            <CCDialog
              titleHeader={title}
              height={660}
              maxHeight="80%"
              maxWidth="55%"
              onClose={onClose}
              disabled={isSubmitLoading}
              bodyElement={
                <GridSearch
                  grid={{
                    primaryField: nameOf("Id"),
                  }}
                  search={{
                    hasSearchField: true,
                    filterCol: nameOf("DisplayName"),
                    sortCol: nameOf("DisplayName"),
                  }}
                  columnFields={colCouncilDetail}
                  dataUrl={dataURL}
                  selectableMode={isMultiple ? "multiple" : "single"}
                  selectedRows={selectedCouncilContacts}
                  handleSelectionChange={(dataItem: ICouncilContact[]) => {
                    if (dataItem) {
                      formRenderProps.onChange("SelectedCouncilContacts", {
                        value: [...dataItem],
                      });
                    }
                  }}
                  onDataStateChange={(state: State) => {
                    setStateData(state);
                  }}
                  state={stateData}
                />
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    themeColor="primary"
                    disabled={!selectedCouncilContacts.length}
                    className="cc-dialog-button"
                    iconClass={isSubmitLoading ? "fas fa-spinner fa-spin" : ""}
                    onClick={formRenderProps.onSubmit}
                  >
                    Select
                  </Button>
                </div>
              }
            />
          </div>
        );
      }}
    />
  );
};
