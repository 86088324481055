import { CORE_MAILMERGE_DOCUMENTS_ODATA } from "@app/core/components/common/constant";
import { apiCoreDocumentById } from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { isEmpty } from "lodash";

export const getMailMergeDocumentsTemplate = async (
  dataSetFilter: number,
  productType: PRODUCT_TYPE_NUMBER,
  documentIds?: number[]
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().get(
      CORE_MAILMERGE_DOCUMENTS_ODATA,
      {
        params: !isEmpty(documentIds)
          ? {
              key: dataSetFilter,
              productType: productType,
              documentids: documentIds?.toString(),
            }
          : {
              key: dataSetFilter,
              productType: productType,
            },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getMailMergeDocumentTemplate = async (
  mailMergeDataSetId: number,
  productType: PRODUCT_TYPE_NUMBER
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().get(
      apiCoreDocumentById(mailMergeDataSetId),
      {
        params: {
          ProductType: productType,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
