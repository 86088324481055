import { ValuationInputCell } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/valuations/component/custom-cell/valuation-input-cell/_index";
import { DTO_ValuationValues } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/valuations/model";
import { formatDynamicValuations } from "@app/products/property/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_ValuationValues>();
//All titles will be replaced by column name data from view configuration API
export const colAssessmentValuation: IColumnFields[] = [
  {
    field: nameOf("Valuation_Type"),
    title: "Valuation Type",
  },
  {
    field: nameOf("Previous_Valuation"),
    title: "Previous Valuation",
    format: formatDynamicValuations,
    filter: "numeric",
  },
  {
    field: nameOf("Current_Valuation"),
    title: "Supplementary Valuation",
    format: formatDynamicValuations,
    filter: "numeric",
    editable: true,
    editCell: ValuationInputCell,
  },
  {
    field: nameOf("Valuation_Difference"),
    title: "Difference",
    format: formatDynamicValuations,
    filter: "numeric",
  },
  {
    field: nameOf("Child_Valuation_1"),
    title: "Child Valuation 1",
    format: formatDynamicValuations,
    filter: "numeric",
  },
  {
    field: nameOf("Child_Valuation_2"),
    title: "Child Valuation 2",
    format: formatDynamicValuations,
    filter: "numeric",
  },
  {
    field: nameOf("Child_Valuation_3"),
    title: "Child Valuation 3",
    format: formatDynamicValuations,
    filter: "numeric",
  },
  {
    field: nameOf("Child_Valuation_4"),
    title: "Child Valuation 4",
    format: formatDynamicValuations,
    filter: "numeric",
  },
  {
    field: nameOf("Child_Valuation_5"),
    title: "Child Valuation 5",
    format: formatDynamicValuations,
    filter: "numeric",
  },
  {
    field: nameOf("Child_Valuation_6"),
    title: "Child Valuation 6",
    format: formatDynamicValuations,
    filter: "numeric",
  },
  {
    field: nameOf("Child_Valuation_7"),
    title: "Child Valuation 7",
    format: formatDynamicValuations,
    filter: "numeric",
  },
  {
    field: nameOf("Child_Valuation_8"),
    title: "Child Valuation 8",
    format: formatDynamicValuations,
    filter: "numeric",
  },
  {
    field: nameOf("Child_Valuation_9"),
    title: "Child Valuation 9",
    format: formatDynamicValuations,
    filter: "numeric",
  },
  {
    field: nameOf("Child_Valuation_10"),
    title: "Child Valuation 10",
    format: formatDynamicValuations,
    filter: "numeric",
  },
];
