import { Registration_Status } from "@app/products/animals/[id]/model";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const AnimalDeregisterButton = observer(() => {
  const { animalInfo, setIsShowWorkflowDeregister } = useAnimalStore();
  const [isShowConfirmDialog, setIsConfirmDialog] = useState<boolean>(false);

  const isVisible = useMemo(() => {
    return animalInfo?.Status_ENUM === Registration_Status.Active;
  }, [animalInfo]);

  return isVisible ? (
    <>
      <CCNavButton
        title="Deregister"
        onClick={() => setIsConfirmDialog(true)}
      />
      {isShowConfirmDialog && (
        <ConfirmDialog
          onClosePopup={() => setIsConfirmDialog(false)}
          title={"Confirm"}
          message={""}
          subMessage={
            "This will deregister the animal and remove any outstanding fees, do you want to continue?"
          }
          onConfirmYes={() => {
            setIsConfirmDialog(false);
            setIsShowWorkflowDeregister(true);
          }}
        />
      )}
    </>
  ) : null;
});
