import { AddAssessmentLookupDialog } from "@app/products/property/components/dialogs/add-assessment-lookup/_index";
import { fnt_Assessment_LookupResult } from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { AddTitleLookupDialog } from "@app/products/property/components/dialogs/add-title-lookup/_index";
import { fnt_Title_LookupResult } from "@app/products/property/components/dialogs/add-title-lookup/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  colAssessments,
  colTitles,
} from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/components/form-elements/associations/config";
import {
  DTO_ModifyParcel_Assessment,
  DTO_ModifyParcel_Title,
} from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/model";
import { processCombineData } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/util";
import { VO_Title } from "@app/products/property/titles/list/model";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

const nameOfVOTitleGrid = nameOfFactory<DTO_ModifyParcel_Title>();
const nameOfAssessmentsGrid = nameOfFactory<DTO_ModifyParcel_Assessment>();
export const AssociationsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = { isReadOnly: false },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const currentTitles: VO_Title[] = getFieldValue("AssociateTitles") ?? [];
    const listSelected: VO_Title[] = getFieldValue("TitlesSelected") ?? [];
    const [isShowConfirmDeletion, setIsShowConfirmDeletion] =
      useState<boolean>(false);
    const [showAddTitleDialog, setShowAddTitleDialog] = useState(false);
    const [showAddAssessmentDialog, setShowAddAssessmentDialog] =
      useState(false);
    const titleData: DTO_ModifyParcel_Title[] =
      getFieldValue("AssociateTitles") ?? [];
    const assessmentData: fnt_Assessment_LookupResult[] =
      getFieldValue("AssociateAssessments") ?? [];
    const [selectedTitles, setSelectedTitles] = useState<
      DTO_ModifyParcel_Title[]
    >([]);
    const folio = getFieldValue("AssociateTitles") ?? [];

    //Get labels
    const [
      titlesLabel,
      titleLowercaseLabel,
      assessmentsLabel,
      assessmentLowercaseLabel,
    ] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Titles,
      ECustomColNameProperty.TitleLowercase,
      ECustomColNameProperty.Assessments,
      ECustomColNameProperty.AssessmentLowercase,
    ]);

    const handleDelete = () => {
      const selectedIDs = listSelected.map((item: VO_Title) => item.Title_Id);
      const newTitles = currentTitles.filter(
        (record: VO_Title) => !selectedIDs.includes(record.Title_Id)
      );
      onChange(nameOf("AssociateTitles"), { value: newTitles });
      onChange(nameOf("TitlesSelected"), { value: [] });
    };

    const handleAddAssessment = async (data: fnt_Assessment_LookupResult[]) => {
      const newData = processCombineData(assessmentData, data, "Assessment_Id");
      onChange(nameOf("AssociateAssessments"), {
        value: newData,
      });
      setShowAddAssessmentDialog(false);
    };

    const handleAddItemFolio = async (data: fnt_Title_LookupResult[]) => {
      const newData = processCombineData(assessmentData, folio, "Title_Id");
      onChange(nameOf("AssociateAssessments"), {
        value: newData,
      });
    };

    return (
      <section className="cc-field-group">
        <div className="cc-field">
          <CCLabel title={titlesLabel} />
          <CCGrid
            data={titleData}
            columnFields={colTitles}
            primaryField={nameOfVOTitleGrid("Title_Id")}
            editableMode={"cell"}
            readOnly={options?.isReadOnly}
            selectableMode="multiple"
            selectedRows={listSelected}
            onSelectionChange={(dataItem: DTO_ModifyParcel_Title[]) =>
              setSelectedTitles(dataItem)
            }
            onDataChange={(dataItem: DTO_ModifyParcel_Title[]) => {
              onChange(nameOf("AssociateTitles"), { value: dataItem });
            }}
            toolbar={
              <div className="cc-grid-tools-bar">
                <Button
                  iconClass="fas fa-plus"
                  title={`Add ${titleLowercaseLabel}`}
                  disabled={options?.isReadOnly}
                  onClick={() => {
                    setShowAddTitleDialog(true);
                  }}
                />
                <Button
                  className="cc-edit-field-button"
                  iconClass="fa fa-minus"
                  title={`Remove ${titleLowercaseLabel}`}
                  disabled={!selectedTitles?.length || options?.isReadOnly}
                  onClick={() => {
                    setIsShowConfirmDeletion(true);
                  }}
                />
              </div>
            }
          />
        </div>
        <div className="cc-field">
          <CCLabel title={assessmentsLabel} />
          <CCGrid
            data={assessmentData}
            columnFields={colAssessments}
            primaryField={nameOfAssessmentsGrid("Id")}
            editableMode={"cell"}
            readOnly={options?.isReadOnly}
            selectableMode="multiple"
            selectedRows={listSelected}
            onDataChange={(dataItem: DTO_ModifyParcel_Assessment[]) => {
              onChange(nameOf("AssociateAssessments"), { value: dataItem });
            }}
            toolbar={
              <div className="cc-grid-tools-bar">
                <Button
                  iconClass="fas fa-plus"
                  title={`Add ${assessmentLowercaseLabel}`}
                  disabled={options?.isReadOnly}
                  onClick={() => {
                    setShowAddAssessmentDialog(true);
                  }}
                />
                <Button
                  className="cc-edit-field-button"
                  iconClass="fa fa-minus"
                  title={`Remove ${assessmentLowercaseLabel}`}
                  disabled={!listSelected?.length || options?.isReadOnly}
                  onClick={() => {
                    setIsShowConfirmDeletion(true);
                  }}
                />
              </div>
            }
          />
        </div>
        {showAddTitleDialog && (
          <AddTitleLookupDialog
            onClose={() => setShowAddTitleDialog(false)}
            handleAddTitle={(data: fnt_Title_LookupResult[]) => {
              setShowAddTitleDialog(false);
              handleAddItemFolio(data);
            }}
          />
        )}
        {showAddAssessmentDialog && (
          <AddAssessmentLookupDialog
            onClose={() => {
              setShowAddAssessmentDialog(false);
            }}
            handleAddAssessment={handleAddAssessment}
          />
        )}
        {isShowConfirmDeletion && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={"Are you sure you want to delete the selected item(s)?"}
            onClosePopup={() => {
              setIsShowConfirmDeletion(false);
            }}
            onConfirmYes={handleDelete}
          />
        )}
      </section>
    );
  }
);
