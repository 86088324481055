import { TypesActionsWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { WorkflowTypes } from "@app/products/property/model";
import { Label } from "@common/stores/products/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

interface INewAssessmentButtonProps {
  isInAdvanceSearch?: boolean;
}

export const NewAssessmentButton = observer(
  ({ isInAdvanceSearch = false }: INewAssessmentButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();

    //Get label
    const newAssessmentLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.New_Assessment
    );

    return (
      <CCNavButton
        title={newAssessmentLabel}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Create_Assessment,
              typeList: isInAdvanceSearch
                ? TypesActionsWorkflow.AdvanceSearch
                : undefined,
              data: {},
            },
          ]);
        }}
      />
    );
  }
);
