import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { AccountsInTheSchemeFormElement } from "@app/products/property/schemes/[id]/components/child-screens/accounts/_index";
import { GeneralFormElement } from "@app/products/property/schemes/[id]/components/child-screens/general/_index";
import { AddInterestRateButton } from "@app/products/property/schemes/[id]/components/forms/existed/components/action-bar/buttons/add-interest-rate/_index";
import { EditGLAccountButton } from "@app/products/property/schemes/[id]/components/forms/existed/components/action-bar/buttons/edit-gl-account/_index";
import { ImportSchemeAccountsButton } from "@app/products/property/schemes/[id]/components/forms/existed/components/action-bar/buttons/import-scheme-accounts/_index";
import { PROPERTY_SCHEMES_ROUTE } from "@app/products/property/schemes/[id]/constant";
import {
  ISchemesGL,
  ISchemesInterestRate,
} from "@app/products/property/schemes/[id]/model";
import { useSchemesStore } from "@app/products/property/schemes/[id]/store";
import { usePropertySchemeNewButtonStore } from "@app/products/property/schemes/components/action-bar/buttons/new-scheme/store";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { TYPE_NOTIFICATION } from "@components/cc-notification/comp-notification/_index";
import Loading from "@components/loading/Loading";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";

export const NewPropertyScheme = observer(() => {
  const history = useHistory();
  const { setNotification } = usePropertySchemeNewButtonStore();
  const [isUpdateSchemeAccount, setIsUpdateSchemeAccount] =
    useState<boolean>(false);
  const { schemes, setSchemes, isLoading } = useSchemesStore();
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const handleSubmit = async (data: any) => {
    setNotification([
      {
        isAutoClose: true,
        primaryContent: "New Scheme was added successfully",
        type: TYPE_NOTIFICATION.SUCCESS,
      },
    ]);
    history.push(`${PROPERTY_SCHEMES_ROUTE}/1`);
  };

  const getListPanelPar = (
    formRenderProps: FormRenderProps
  ): IPropertyPanelBar[] => {
    return [
      {
        title: "Accounts in Scheme",
        component: (
          <AccountsInTheSchemeFormElement
            formRenderProps={formRenderProps}
            isUpdateSchemeAccount={isUpdateSchemeAccount}
            setIsUpdateSchemeAccount={setIsUpdateSchemeAccount}
          />
        ),
      },
    ];
  };

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Scheme" />
      <FormTitle title="New Scheme" />
      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          >
            <ImportSchemeAccountsButton
              setIsUpdateSchemeAccount={setIsUpdateSchemeAccount}
            />
            <EditGLAccountButton
              onSave={(value: ISchemesGL[]) => {
                if (!schemes?.General) return;
                setSchemes({
                  ...schemes,
                  General: {
                    ...schemes.General,
                    InterestAndInstalmentDetailsGL: value,
                  },
                });
              }}
            />
          </CCNavButton>,
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
          <CCNavButton title={"Add"} type="sub"></CCNavButton>,
          <CCNavButton title={"View"} />,
          <CCNavButton title={"Print"} />,
          <CCNavButton title={"More Options"} type="more">
            <AddInterestRateButton
              initialData={schemes?.General?.SchemesInterestRate || []}
              onSave={(data: ISchemesInterestRate[]) => {
                if (!schemes?.General) return;
                setSchemes({
                  ...schemes,
                  General: {
                    ...schemes.General,
                    SchemesInterestRate: data,
                  },
                });
              }}
            />
          </CCNavButton>,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <div className="cc-manage-form-body">
            <div className="cc-form">
              <CCLocalNotification ref={notificationRef} />
              {schemes && (
                <React.Fragment>
                  <Form
                    key={JSON.stringify(schemes)}
                    onSubmit={handleSubmit}
                    initialValues={schemes}
                    render={(formRenderProps: FormRenderProps) => {
                      const validateForm = (
                        event: React.SyntheticEvent<any>
                      ) => {
                        if (formRenderProps.modified) {
                          formRenderProps.onSubmit(event);
                          return;
                        }

                        if (!formRenderProps.valid) return;

                        notificationRef.current?.pushNotification({
                          title: "The form is not modified",
                          type: "warning",
                        });
                      };
                      return (
                        <FormElement className="cc-property-scheme">
                          {/* Reference by a label in WastewaterActionBar.tsx */}
                          <input
                            hidden
                            type="submit"
                            id="btn-submit"
                            onClick={validateForm}
                          />
                          <CCGeneralPanel
                            component={
                              <GeneralFormElement
                                formRenderProps={formRenderProps}
                              />
                            }
                          />
                          <PropertyPanelBar
                            initialPanels={getListPanelPar(formRenderProps)}
                          />
                        </FormElement>
                      );
                    }}
                  />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
