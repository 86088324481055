import { EnumFilter } from "@progress/kendo-react-data-tools";
import { isNumber, isString } from "lodash";
import * as PropTypes from "prop-types";
import React from "react";
import { useEffectOnce } from "react-use";

export type dataFilterByEnum =
  | {
      text: string;
      value: any;
    }
  | undefined;

export const CCEnumFilter = (props: any) => {
  const { filterByEnum } = props;
  const data: dataFilterByEnum[] = [];
  const arrayValues = Object.values(filterByEnum).filter((value) =>
    isString(value)
  );
  const arrayKeys = Object.values(filterByEnum).filter((value) =>
    isNumber(value)
  );
  arrayValues.forEach((item, i) => {
    data.push({ text: item as string, value: arrayKeys?.[i] ?? item });
  });

  useEffectOnce(() => {
    props.onFilterChange.call(undefined, {
      nextFilter: { ...props.filter, operator: "eq" },
    });
  });

  return (
    <EnumFilter
      filter={props.filter}
      onFilterChange={props.onFilterChange}
      data={props.data || data}
      defaultItem={props.defaultItem}
      ariaLabel={props.ariaLabel}
    />
  );
};

CCEnumFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string, value: PropTypes.any })
  ),
  ariaLabel: PropTypes.string,
  defaultItem: PropTypes.any,
};
