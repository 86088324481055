import { colGroupLevySumary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/components/group-levy-sumary/config";
import { useChargeRunLevySumaryGroupStore } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/components/group-levy-sumary/store";
import { LevySummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/components/levy-sumary/_index";
import {
  DTO_LevySummary,
  eLevySummaryTypes,
} from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/model";
import { processDynamicColumns } from "@app/products/property/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

interface IGroupLevySumaryProps {
  groupTypeId: eLevySummaryTypes;
}

const nameOf = nameOfFactory<DTO_LevySummary>();
export const GroupLevySumary = observer(
  ({ groupTypeId }: IGroupLevySumaryProps) => {
    const {
      responseLoadError,
      chargeRunLevySumaryGroup,
      isLoading,
      viewConfiguration,
      loadChargeRunLevySumaryGroup,
      resetStore,
    } = useChargeRunLevySumaryGroupStore();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
      loadChargeRunLevySumaryGroup(+id, groupTypeId);
      return () => {
        resetStore();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, groupTypeId]);

    return isLoading ? (
      <Loading isLoading={isLoading} />
    ) : responseLoadError ? (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadChargeRunLevySumaryGroup(+id, groupTypeId);
        }}
      />
    ) : (
      <CCGrid
        data={chargeRunLevySumaryGroup ?? []}
        columnFields={processDynamicColumns(
          colGroupLevySumary,
          viewConfiguration
        )}
        primaryField={nameOf("GroupId")}
        detail={(dataItem) => (
          <LevySummary data={dataItem} groupTypeId={groupTypeId} />
        )}
        itemPerPage={10}
      />
    );
  }
);
