import { LOCAL_LAWS_MANAGE_ROUTE } from "@app/products/local-laws/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
export const colLocalLawsComplianceNoticesByDueStatus: IColumnFields[] = [
  {
    field: "DateServed",
    title: "Served",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "ReferenceNumber",
    title: "Number",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem) => ({
      pathname: `${LOCAL_LAWS_MANAGE_ROUTE}/` + dataItem.ID,
    }),
    locked: true,
  },
  { field: "ContactName", title: "Name" },
  { field: "Type", title: "Type" },
  { field: "Officer", title: "Officer" },
  {
    field: "DateDue",
    title: "Compliance Due",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "DateRevoked",
    title: "Complied / Revoked",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "InsuranceDueStatus",
    title: "Due Status",
  },
];
