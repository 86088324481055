import {
  renderColSimilarEventsGrid,
  renderSimilarEventOdataUrl,
} from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/recent-events-accordion/config";
import { CS_RecentRequestsFilter } from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { EventView } from "@app/products/crms/all-events/by-refno/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

const nameOf = nameOfFactory<EventView>();
const nameOfKeyValuePacket = nameOfFactory<IKeyValuePacket>();

export const SimilarEvents = observer(() => {
  const { settings } = useCommonCoreStore();
  const { uiControl, crmsEvent } = useCRMSEventStore();
  const [relatedEventFilterBy, setRelatedEventFilterBy] = useState(
    CS_RecentRequestsFilter.Location
  );
  const actionOfficerLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_ActionOfficerLabel]
  );
  const filterChangeHandler = (event: DropDownListChangeEvent) => {
    if (event.value) {
      setRelatedEventFilterBy(event.value.Key);
    }
  };

  return (
    <div>
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Sort/Filter by" />
            <CCDropDownList
              data={uiControl?.DdlRelatedEventsFilter?.Dataset ?? []}
              dataItemKey={nameOfKeyValuePacket("Key")}
              textField={nameOfKeyValuePacket("Value")}
              onChange={filterChangeHandler}
              value={uiControl?.DdlRelatedEventsFilter?.Dataset?.find(
                (item: IKeyValuePacket) => item.Key === relatedEventFilterBy
              )}
            />
          </div>
          {renderSimilarEventOdataUrl(crmsEvent, relatedEventFilterBy) ? (
            <CCGrid
              columnFields={renderColSimilarEventsGrid(actionOfficerLabel)}
              primaryField={nameOf("ID")}
              dataUrl={renderSimilarEventOdataUrl(
                crmsEvent,
                relatedEventFilterBy
              )}
              state={{ sort: [{ field: nameOf("ID"), dir: "desc" }] }}
            />
          ) : (
            <CCGrid
              columnFields={renderColSimilarEventsGrid(actionOfficerLabel)}
              primaryField={nameOf("ID")}
              data={[]}
              state={{ sort: [{ field: nameOf("ID"), dir: "desc" }] }}
            />
          )}
        </div>
      </div>
    </div>
  );
});
