import NewSettingManage from "@app/products/property/system-admin/settings/_id/components/forms/new/_index";
import { ISettingManagerForm } from "@app/products/property/system-admin/settings/model";
import { usePhoneOrTablet } from "@common/hooks/usePhoneOrTablet";
import { GlobalSettingHistoryTab } from "@common/pages/settings/system-admin/global-settings/_id/sidebar/history/_index";
import { useCCListViewActionBarStore } from "@components/cc-list-view-action-bar/store";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { useReferenceSideBarStore } from "@components/cc-reference-sidebar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SettingManageFormActionBar } from "./components/action-bar/_index";
import ExistedSettingManage from "./components/forms/existed/_index";

const SettingManage = observer((props: ISettingManagerForm) => {
  const actionBarStore = useCCListViewActionBarStore();
  const referenceSideBarStore = useReferenceSideBarStore();
  const isNew = props.isNew;
  const history = useHistory();
  const [isShowSlideBar, setIsShowSlideBar] = useState(true);
  usePhoneOrTablet(() => setIsShowSlideBar(false));
  actionBarStore.resetDefault();

  const handleShowSlideBar = () => {
    setIsShowSlideBar(!isShowSlideBar);
  };

  useEffect(() => {
    referenceSideBarStore.setReferenceComponents([
      {
        title: "History",
        component: <GlobalSettingHistoryTab />,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.state]);

  return (
    <>
      <SettingManageFormActionBar
        isNew={isNew}
        isShowSlideBar={isShowSlideBar}
        onClick={handleShowSlideBar}
      />

      <div className="cc-manage-container cc-container-setting-form">
        {isNew ? (
          <div className="cc-manage-left">
            <div className="cc-manage-form-body">
              <NewSettingManage {...props} />
            </div>
          </div>
        ) : (
          <>
            <div className="cc-manage-left">
              <div className="cc-manage-form-body">
                <ExistedSettingManage {...props} />
              </div>
            </div>
            {isShowSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
});

export default SettingManage;
