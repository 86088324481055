import { eventEmitter } from "@/App";
import { COMMENT_ACCORDION_GRID_ID } from "@app/core/comments/constant";
import {
  EWorkflow_Approval_Status_Name,
  VO_Workflow_Draft,
} from "@app/products/property/actions/model";
import { AssessmentTabDetailsEventType } from "@app/products/property/assessments/[id]/components/reference-sidebar/detail/config";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import {
  getInitialDataAssessment,
  postProcessAssociateAssessment,
} from "@app/products/property/assessments/components/form-steps/associate-assessment/api";
import { AssociationAssessmentFormStep } from "@app/products/property/assessments/components/form-steps/associate-assessment/components/form-elements/associations/_index";
import { DetailsFormStep } from "@app/products/property/assessments/components/form-steps/associate-assessment/components/form-elements/details/_index";
import {
  DTO_WorkflowDetail_AssociateAssessment,
  DTO_Workflow_AssociateAssessment,
  EKeysOfSteps,
  RequestObjFromSpatial,
  keysOfSendSteps,
} from "@app/products/property/assessments/components/form-steps/associate-assessment/model";
import { useAssociateAssessmentDialogStore } from "@app/products/property/assessments/components/form-steps/associate-assessment/store";

import { ASSESSMENT_ROUTE } from "@app/products/property/assessments/components/form-steps/modify-assessment/util";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import {
  Collection,
  DTO_Assessment_LOVs,
} from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CollectionFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/_index";
import { VO_CollectionType } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/model";
import { useAssessmentCollectionStepStore } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/store";
import { collectionUtilProcessing } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/util";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import {
  getSuffixTitle,
  getWorkflowInstructions,
  secondaryWorkflowUtilProcessing,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { PROPERTY_ACTIONS_APPROVED_LIST_ROUTE } from "@app/products/property/components/action-bar/property-workflow/constant";
import {
  IListDialog,
  IProcessWorkflow,
} from "@app/products/property/components/action-bar/property-workflow/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { eComponent } from "@app/products/property/components/associations/model";
import { useCollectionsStore } from "@app/products/property/components/child-screen/collections/store";
import { PropertyDocumentEventType } from "@app/products/property/components/child-screen/documents/constant";
import { officerUtilProcessing } from "@app/products/property/components/fields/officer-and-officer-region/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { Label } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { head, isEqual, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IAssociateAssessmentDialogProps {
  onClose: () => void;
  assessmentID: number;
  statusID: number;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  isReadOnly?: boolean;
  isShowReasonRejection?: boolean;
  isShowReason?: boolean;
  isShowCancelWorkflowButton?: boolean;
  statusBadge?: IBadgeDialog[];
  isSaveOnNextStep?: boolean;
  isIncompleteMode?: boolean;
  suffixTitle?: string;
  isFromActionList?: boolean;
  isRedirectManagePage?: boolean;
}

export const AssociateAssessmentDialog = observer(
  ({
    onClose,
    assessmentID,
    statusID,
    dataFromActionList,
    prefixTitle,
    isReadOnly = false,
    isShowReasonRejection = false,
    statusBadge,
    isSaveOnNextStep = false,
    suffixTitle,
    isFromActionList = false,
    isShowCancelWorkflowButton = false,
    isIncompleteMode = false,
    isRedirectManagePage = false,
  }: IAssociateAssessmentDialogProps) => {
    //Store
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { setIsLoadingOnDialog, setAssociateAssessmentLOVs } =
      useAssociateAssessmentDialogStore();
    const { setAssessmentCollectionStepLOVs } =
      useAssessmentCollectionStepStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { reLoadAssessment, assessment } = useAssessmentStore();
    const { setListDialog } = usePropertyWorkflowStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { reloadCollections } = useCollectionsStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { currentFormTitle } = useCommonProductStore();
    const history = useHistory();
    const params: { id: string } = useParams();
    const assessmentId: number = +params?.id || assessmentID;
    // Get status from props or from the summary api in manage page
    const statusId: number | undefined =
      assessment?.Assessment?.StatusId ?? statusID;

    //Site

    //State
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
    const [associateAssessmentInitData, setAssociateAssessmentInitData] =
      useState<DTO_Workflow_AssociateAssessment>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    const isToBeApprovalMode = isEqual(
      dataFromActionList?.Workflow_Approval_Status_Name,
      EWorkflow_Approval_Status_Name.To_Be_Approved
    );

    //Get labels
    const [assessmentLabel, associateAssessmentLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.Assessment,
        ECustomColNameProperty.Associate_Assessment,
      ]);

    const initialValue = useMemo(() => {
      const workflowDetail = associateAssessmentInitData?.WorkflowDetail;
      const workflowHeader = associateAssessmentInitData?.WorkflowHeader;

      const officerInitData = officerUtilProcessing.processDataInit(
        associateAssessmentInitData
      );
      // Init step details
      let initDetails: any = {
        ...workflowDetail?.Details,
        Instructions: getWorkflowInstructions(
          workflowHeader?.WorkflowApprovals ?? []
        ),
        ...officerInitData,
      };

      // Init step association
      let initAssociation: any = {
        ...workflowDetail?.Associations,
      };

      //---step Secondary Workflow---
      const initSecondaryWorkflow =
        secondaryWorkflowUtilProcessing.processDataInit(
          associateAssessmentInitData
        );

      return {
        [EKeysOfSteps.Details]: initDetails,
        [EKeysOfSteps.Associations]: initAssociation,
        [EKeysOfSteps.Collection]: collectionUtilProcessing.processDataInit<
          Collection[]
        >(associateAssessmentInitData),
        [EKeysOfSteps.Comments]: {},
        [EKeysOfSteps.Documents]: {},
        [EKeysOfSteps.SecondaryWorkflow]: initSecondaryWorkflow,
      };
    }, [associateAssessmentInitData]);

    const steps: IStep[] = [
      {
        label: "Details",
        initialValues: initialValue.Details,
        component: DetailsFormStep,
        visible: true,
        key: EKeysOfSteps.Details,
        isValid: false,
        options: {
          isReadOnly,
          isShowReasonRejection,
          isToBeApprovalMode,
          workflowDraftId,
        },
      },
      {
        label: "Related",
        initialValues: initialValue.Associations,
        component: AssociationAssessmentFormStep,
        visible: true,
        key: EKeysOfSteps.Associations,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Collections",
        initialValues: initialValue[EKeysOfSteps.Collection],
        component: CollectionFormStep,
        visible: true,
        key: EKeysOfSteps.Collection,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Comments",
        initialValues: initialValue.Comments,
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfSteps.Comments,
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Assessment,
        },
        customClassName: "cc-comment-step-fixed-height-grid",
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValue.Documents,
        visible: true,
        key: EKeysOfSteps.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          //Only use for modify workflow
          componentId: assessmentID,
          workflowType: WorkflowTypes.Associate_Assessment,
        },
      },
      {
        label: "Workflow",
        initialValues: initialValue.SecondaryWorkflow,
        component: WorkflowStepFormStep,
        visible: true,
        key: EKeysOfSteps.SecondaryWorkflow,
        options: {
          isReadOnly,
          isFromActionList,
          dataFromActionList,
        },
      },
    ];

    const handleSubmit = (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Approve:
          handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          sendSaveHolding(processData(data), true);
          break;
        case EListSubmitButton.SaveWorkflow:
          sendSaveHolding(processData(data), false, true);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          handleConfirmRetainProcess(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          handleParkProcess(processData(data));
          break;
      }
    };

    /**
     * Handle reject process
     */
    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessAssociateAssessment,
          dataReject: data,
          defaultSuccessMessage: `Associate ${assessmentLabel.toLowerCase()} application was rejected successfully`,
          defaultErrorMessage: `Associate ${assessmentLabel.toLowerCase()} reject failed`,
        });
      } else {
        onClose();
      }
    };

    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_AssociateAssessment
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessAssociateAssessment,
        dataSendBack: payload,
        defaultSuccessMessage: `Associate ${assessmentLabel.toLowerCase()} approval sent back successfully`,
        defaultErrorMessage: `Send associate ${assessmentLabel.toLowerCase()} back failed`,
      });
    };

    /**
     * handle confirm reallocate workflow process
     * @param payload
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_AssociateAssessment
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postProcessAssociateAssessment,
        dataReallocate: payload,
        defaultSuccessMessage: `Associate ${assessmentLabel.toLowerCase()} approved successfully`,
        defaultErrorMessage: `Approve associate ${assessmentLabel.toLowerCase()} failed`,
      });
    };

    const processData: DTO_Workflow_AssociateAssessment | any = (data: any) => {
      let workflowDetail: any = {};
      //Officer process data
      //field update: WD_Assessment_Group_Id
      const { WD_Assessment_Group_Id: assessmentGroupIdWD } =
        officerUtilProcessing.processData(data, EKeysOfSteps.Details);
      workflowHeader.WorkflowDraft.WD_Assessment_Group_Id = assessmentGroupIdWD;
      const sendSteps = pickBy(data, function (value, key) {
        if (keysOfSendSteps.includes(key as EKeysOfSteps)) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        let dataStep;
        if (key === EKeysOfSteps.Collection) {
          dataStep = [...(value ?? [])];
        } else {
          dataStep = { ...value };
        }
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }
        if (dataStep?.SelectedCollection?.length) {
          const listSelectedCollection: number[] =
            dataStep.SelectedCollection.map(
              (item: VO_CollectionType) => item.Collection_Id
            );
          workflowDetail.SelectedCollection = listSelectedCollection;
        }
        if (dataStep?.BuildingUnitNumber === "") {
          dataStep.BuildingUnitNumber = null;
        }
        if (key === EKeysOfSteps.SecondaryWorkflow) {
          secondaryWorkflowUtilProcessing.processData(
            value,
            workflowHeader,
            workflowDetail
          );
        } else {
          workflowDetail[key as keyof DTO_WorkflowDetail_AssociateAssessment] =
            dataStep;
        }
      }
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    /**
     * handle approve process
     * * @param payload
     */
    const handleApproveProcess = (
      payload: DTO_Workflow_AssociateAssessment
    ) => {
      //set loading button
      setIsLoadingApprove(true);

      //props send to process workflow
      const approveProps: IProcessWorkflow<DTO_Workflow_AssociateAssessment> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          history.push(PROPERTY_ACTIONS_APPROVED_LIST_ROUTE);
          pushNotification({
            title:
              e?.Notification ??
              `Associate ${assessmentLabel.toLowerCase()} approved successfully`,
            type: "success",
          });
        },
        defaultFailedMessage: `Approve associate ${assessmentLabel.toLowerCase()} failed`,
        modeProcess: WorkflowProcessMode.Approve,
      };

      const setLoading = () => {
        setIsLoadingApprove(false);
      };
      //calling api process workflow
      handleProcessWorkflow(approveProps, setLoading);
    };

    /**
     * handle park process
     * @param payload
     */
    const handleParkProcess = (payload: DTO_Workflow_AssociateAssessment) => {
      //set loading button and dialog
      setIsLoadingPark(true);
      setIsLoadingOnDialog(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_AssociateAssessment> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title: e?.Notification ?? `${assessmentLabel} parked successfully`,
            type: "success",
          });
        },
        defaultFailedMessage: `Park ${assessmentLabel.toLowerCase()} failed`,
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingPark(false);
        setIsLoadingOnDialog(false);
      };

      //calling api process workflow
      handleProcessWorkflow(parkProps, setLoading);
    };

    /**
     * handle finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_AssociateAssessment
    ) => {
      setDataForFinishDialog({
        finishCallback: async () =>
          await handleFinishProcess(
            processData(payload, WorkflowProcessMode.Finish)
          ),
        confirmMessage: `The ${assessmentLabel.toLowerCase()} will be associated based on the information provided. Are you sure you want to submit?`,
      });
    };

    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_AssociateAssessment>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;
      let secondaryWorkflowSelectedList: IListDialog[] = [];
      if (modeProcess === WorkflowProcessMode.Finish) {
        if (payload.WorkflowDetail?.SecondaryWorkflowSelectedList) {
          secondaryWorkflowSelectedList =
            payload.WorkflowDetail.SecondaryWorkflowSelectedList ?? [];
          delete payload.WorkflowDetail.SecondaryWorkflowSelectedList;
        }
      }
      const response = await postProcessAssociateAssessment(
        modeProcess,
        payload
      );

      if (isSuccessResponse(response)) {
        setLoading();
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
          //Show secondary workflow
          if (
            modeProcess === WorkflowProcessMode.Finish &&
            secondaryWorkflowSelectedList?.length
          ) {
            setListDialog(secondaryWorkflowSelectedList);
          }
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_AssociateAssessment
    ) => {
      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_AssociateAssessment> = {
        payload: payload,
        actionSuccess: async (e) => {
          onClose();
          eventEmitter.emit(CCGridEventType.RefreshOData);
          if (!isFromActionList) {
            //Refresh document and comment accordion after updated successfully
            eventEmitter.emit(PropertyDocumentEventType.RefreshData);
            eventEmitter.emit(CCGridEventType.RefreshOData, {
              gridIds: [COMMENT_ACCORDION_GRID_ID],
            });
            reloadCollections(eComponent.Assessment, assessmentID);
            await reLoadAssessment();
            if (
              history?.location?.pathname ===
              `${ASSESSMENT_ROUTE}/${assessmentId}`
            ) {
              eventEmitter.emit(
                AssessmentTabDetailsEventType.RefreshData,
                assessmentId
              );
            }
          }
          const notificationContent: IAppNotificationItemAddProps = {
            title:
              e?.Notification ??
              `Associate ${assessmentLabel.toLowerCase()} created successfully`,
            type: "success",
          };
          !isRedirectManagePage && pushNotification(notificationContent);
        },
        defaultFailedMessage: `Associate ${assessmentLabel.toLowerCase()} failed`,
        modeProcess: WorkflowProcessMode.Finish,
      };

      const setLoading = () => {};
      //calling api process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };

    const sendSaveHolding = async (
      payload: DTO_Workflow_AssociateAssessment,
      isCloseDialog: boolean = false,
      isRefreshWorkflowData: boolean = false
    ) => {
      const response = await postProcessAssociateAssessment(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingOnNext(false);
      const defaultSuccessMessage = `${assessmentLabel} saved successfully`;
      const defaultFailedMessage = `The associate ${assessmentLabel.toLowerCase()} process could not be saved`;
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (isCloseDialog) {
          onClose();
          pushNotification({
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        }
        if (isFirstSave) {
          setIsFirstSave(false);
          setWorkflowDraftId(response?.data?.ID ?? 0);
          //TODO: Don't need call after saving at create new
          //Update workflowHeader when creating new Workflow
          setWorkflowHeader({
            ...workflowHeader,
            WorkflowDraft: {
              ...workflowHeader.WorkflowDraft,
              Workflow_Draft_Id: response?.data?.ID,
            },
          });
          // get new data from draftId after first save
          if (officerUtilProcessing.canReloadWorkflowData(isFromActionList)) {
            setAssociateAssessmentInitData({
              WorkflowDetail: response.data?.ReturnData?.WorkflowDetail,
              WorkflowHeader: response.data?.ReturnData?.WorkflowHeader,
            });
          }
        }
        // TODO: Show notification after reloading the step -> enhance later
        if (isRefreshWorkflowData) {
          getWorkflowData().then(() => {
            notificationFormStepRef?.current
              ?.getNotificationFormStep()
              ?.current?.pushNotification({
                title: response?.data?.Notification ?? defaultSuccessMessage,
                type: "success",
              });
          });
        }
        return true;
      } else {
        const showNotification = () => {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title:
                (isRefreshWorkflowData
                  ? head(response?.data?.Errors)
                  : response.data?.ErrorMessage) ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        };
        if (isRefreshWorkflowData) {
          getWorkflowData().then(showNotification);
        } else {
          showNotification();
        }
        return false;
      }
    };

    const handleNextButton = (data: any) => {
      setIsLoadingOnNext(true);
      const newData = processData(data);
      return sendSaveHolding(newData);
    };

    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title =
        currentFormTitle(formId ?? 0) ??
        associateAssessmentLabel ??
        "Associate Holding";

      return getTitleWorkflow(
        title,
        prefixTitle,
        getSuffixTitle(
          suffixTitle,
          isToBeApprovalMode,
          workflowHeader?.WorkflowApprovals
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      workflowHeader,
      prefixTitle,
      suffixTitle,
      isToBeApprovalMode,
      workflowHeader?.WorkflowApprovals,
    ]);

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name &&
        dataFromActionList.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    /**
     * Handle cancel process
     * @param data
     */
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessAssociateAssessment,
          dataCancel: data,
          defaultSuccessMessage: `Associate ${assessmentLabel.toLowerCase()} was cancelled successfully.`,
          defaultErrorMessage: `Associate ${assessmentLabel.toLowerCase()} could not be cancelled.`,
        });
      } else {
        onClose();
      }
    };

    /**
     * handle confirm retain workflow process
     * @param payload
     */
    const handleConfirmRetainProcess = (
      payload: DTO_Workflow_AssociateAssessment
    ) => {
      //set loading button and dialog
      setIsLoadingClose(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_AssociateAssessment> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title: e?.Notification ?? `${assessmentLabel} parked successfully`,
            type: "success",
          });
        },
        defaultFailedMessage: `Park ${assessmentLabel.toLowerCase()} failed`,
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog(undefined);
      };

      //calling api process workflow
      handleProcessWorkflow(parkProps, setLoading);
    };

    const getWorkflowData = async () => {
      const id = dataFromActionList?.Workflow_Draft_Id;
      const requestObjPayload: RequestObjFromSpatial = {
        AssessmentId: assessmentId,
        StatusId: statusId,
      };
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      return await getInitialDataAssessment(
        WorkflowTypes.Associate_Assessment,
        requestObjPayload,
        id
      ).then((response) => {
        if (Array.isArray(response)) {
          const [lovsAssessment, workflowData] = response;
          if (
            isSuccessResponse(lovsAssessment) &&
            isSuccessResponse(workflowData) &&
            lovsAssessment?.data &&
            workflowData?.data
          ) {
            //set Lovs Data Assessment
            setAssociateAssessmentLOVs(
              lovsAssessment?.data?.dtoCreate_Assessment_LOVs
            );
            setAssessmentCollectionStepLOVs({
              Collection_Type:
                lovsAssessment?.data?.dtoCreate_Assessment_LOVs
                  ?.Collection_Type,
            });
            if (workflowData?.data) {
              //set Create Assessment InitData
              setAssociateAssessmentInitData({
                WorkflowDetail: workflowData.data?.WorkflowDetail,
                WorkflowHeader: workflowData.data?.WorkflowHeader,
              });
              if (workflowData.data?.WorkflowHeader) {
                setWorkflowHeader(workflowData.data?.WorkflowHeader);
                setWorkflowDraftId(
                  workflowData.data?.WorkflowHeader?.WorkflowDraft
                    ?.Workflow_Draft_Id ?? 0
                );
              }
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
          } else {
            let responseError: APIResponse<
              | DTO_Assessment_LOVs
              | DTO_Workflow_AssociateAssessment
              | ResponsePacket
            > = lovsAssessment;
            if (!isSuccessResponse(workflowData)) {
              responseError = workflowData;
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error:
                  (responseError.data as ResponsePacket)?.Errors ??
                  "Load workflow failed",
              },
            });
          }
        } else {
          const responseError = response as APIResponse;
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error: "Load workflow failed",
            },
          });
        }
      });
    };

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        listButtonId={listSubmitButton}
        onSubmit={handleSubmit}
        initialSteps={steps}
        initialValues={initialValue}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => {
          return (
            <CCDialog
              maxWidth="65%"
              disabled={isLoadingApprove}
              titleHeader={titleHeader}
              onClose={() => handleCloseDialog(renderProps)}
              badge={statusBadge}
              bodyElement={renderProps.children}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          isLoadingApprove || isLoadingOnNext || isLoadingPark
                        }
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}

                    {isToBeApprovalMode &&
                      workflowHeader?.OfficerCanApprove && (
                        <>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.SendBack}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Send Back
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reallocate}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reallocate
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Approve}
                            disabled={
                              isLoadingApprove ||
                              renderProps.nextButton.disabled
                            }
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                            iconClass={
                              isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                            }
                          >
                            Approve
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reject}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reject
                          </Button>
                        </>
                      )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                </>
              }
            />
          );
        }}
      />
    );
  }
);
