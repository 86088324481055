import { getContactDetail } from "@app/core/contacts/_id/components/reference-sidebar/detail/api";
import { ContactManager_Contact_TabDetail } from "@app/core/contacts/_id/components/reference-sidebar/detail/model";
import { useContactStore } from "@app/core/contacts/_id/store";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const ContactDetailTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { contact, reloadContact } = useContactStore();
  const [contactDetail, setContactDetail] = useState<
    ContactManager_Contact_TabDetail | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  useEffect(() => {
    const id = lastSelectedRow?.Id ?? contact?.Contact_Id;
    if (!id) return;
    setIsLoading(true);
    getContactDetail(id).then((response) => {
      setIsLoading(false);
      let newContact = undefined;
      let errorResponse = undefined;
      if (isSuccessResponse(response)) {
        newContact = response.data;
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
      setContactDetail(newContact);
      setResponseLoadError(errorResponse);
    });
  }, [contact, lastSelectedRow]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          reloadContact();
        }}
      />
    );
  if (!contactDetail) return null;
  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Contact ID:"}
              value={contactDetail?.Contact_Id.toString()}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            {contactDetail?.DisplayName && (
              <ProductReferenceRow
                title={"Display as (Name):"}
                value={contactDetail.DisplayName}
              />
            )}
            {contactDetail?.Classification && (
              <ProductReferenceRow
                title={"Contact type:"}
                value={contactDetail.Classification}
              />
            )}
            {contactDetail?.Mobile && (
              <ProductReferenceRow
                title={"Mobile:"}
                value={contactDetail.Mobile}
              />
            )}
            {contactDetail?.Email && (
              <ProductReferenceRow
                title={"Email:"}
                value={contactDetail.Email}
              />
            )}
            {contactDetail?.WorkPhone && (
              <ProductReferenceRow
                title={"Work Phone:"}
                value={contactDetail.WorkPhone}
              />
            )}
            {contactDetail?.HomePhone && (
              <ProductReferenceRow
                title={"Home Phone:"}
                value={contactDetail.HomePhone}
              />
            )}
            {contactDetail?.PreferredMethod && (
              <ProductReferenceRow
                title={"Preferred Method:"}
                value={contactDetail.PreferredMethod}
              />
            )}
            {contactDetail?.PreferredAddress && (
              <ProductReferenceRow
                title={"Preferred Address:"}
                value={contactDetail.PreferredAddress}
              />
            )}
            {contactDetail?.PostalAddress && (
              <ProductReferenceRow
                title={"Postal Address:"}
                value={contactDetail.PostalAddress}
              />
            )}
            {contactDetail?.AlternateAddress && (
              <ProductReferenceRow
                title={"Alternate Address:"}
                value={contactDetail.AlternateAddress}
              />
            )}
            {contactDetail?.VIPType && (
              <ProductReferenceRow
                title={"VIP Type:"}
                value={contactDetail.VIPType}
                warning
              />
            )}
            {contactDetail?.VIPRelationshipOwner && (
              <ProductReferenceRow
                title={"VIP Relationship Owner:"}
                value={contactDetail.VIPRelationshipOwner}
                warning
              />
            )}
            {contactDetail?.Alert && (
              <ProductReferenceRow badge value={contactDetail.Alert} danger />
            )}
            <ProductReferenceBlock flex>
              {contactDetail?.GoldenUser && (
                <ProductReferenceRow
                  badgeSmall
                  value={"Golden Contact:"}
                  warning
                />
              )}
            </ProductReferenceBlock>
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
