import {
  DTO_ChangeOfOwnership_LOVs,
  DTO_Workflow_ChangeOfOwnership,
} from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
export const mockNoticeOfSaleNewResponse: DTO_Workflow_ChangeOfOwnership = {
  WorkflowDetail: {
    COODetails: {
      Change_Of_Ownership_Id: 0,
      Type: 0,
      EffectiveDate: new Date(),
      RetainAssociatedNames: false,
      RetainRatePayerDetails: false,
      RetainLeaseDetails: false,
      ComponentInvoked: 0,
      ComponentInvoked_Id: 0,
    },
    PropertyDetails: {
      Titles: [
        {
          Legal_Description: "Lot 2 DP175805 (MOCK)",
          Owner: "Common Property - Body Corporate",
          Title_Id: 1818,
          Assessment_Id: 691,
          Detail: [
            {
              Family_Name: "Family",
              Given_Name: "David (MOCK)",
              Corporate_Miscellaneous_Name: "",
              Address: "65 Archer St, Archies Creek",
              Id: 1,
            },
          ],
        },
      ],
      Assessments: [
        {
          Assessment_Id: 691,
          Assessment_Number: "    678970",
          Property_Address: "65 Archer St, Archies Creek (MOCK)",
        },
      ],
      PICs: [
        {
          PIC_Id: 8,
          Property_Address: "36 Anzac St (MOCK)",
          PIC_Number: "8-1-0",
          Trading_Name: "P CAMILLERI",
        },
      ],
    },
    AssociatedNamesDetails: [
      {
        Entity_Id: 863,
        Entity_Name_Address_Id: 11929,
        Role_Name: "Non Resident Voter",
        Percentage: 100,
        Name: "DERIS, John",
        Address: "5 Wallace St\r\nGARFIELD, VIC   2110",
        isnew: false,
      },
    ],
    OwnerDetails: {},
    DuplicateRatePayerDetails: {},
    NewOwnerGridData: [
      {
        Entity_Id: 1,
        Entity_Name_Address_Id: 1,
        Role_Name: "LRS Owner",
        Percentage: 30,
        Name: "Tony, John (MOCK)",
        Address: "Mock address 1",
        isnew: false,
        Status: "Existing contact",
      },
      {
        Entity_Id: 2,
        Entity_Name_Address_Id: 2,
        Role_Name: "LRS Owner",
        Percentage: 30,
        Name: "Davis, John (MOCK)",
        Address: "Mock address 2",
        isnew: false,
        Status: "New contact",
      },
      {
        Entity_Id: 863,
        Entity_Name_Address_Id: 3,
        Role_Name: "LRS Owner",
        Percentage: 40,
        Name: "DERIS, John (MOCK)",
        Address: "Mock address 3",
        isnew: false,
        Status: "Potential duplicate contact",
      },
    ],
  } as any,
  WorkflowHeader: {
    WorkflowDraft: {
      Workflow_Draft_Id: 0,
      WD_Officer_User_Id: 305,
      WD_Form_Id: 11016,
      WD_Workflow_Status: 2,
      WD_Workflow_Approval_Status: 0,
      WD_Workflow_Type: 2,
    },
    AvailableSecondaryWorkflows: [],
    WorkflowApprovals: [
      {
        WorkflowStepName: "Address Validation1",
        WorkflowDetails: "Address Validation",
        AssessmentGroup: "Standard",
        WorkflowStepSequence: 2,
        SecurityGroup: "Assessment Maintenance",
        ApprovalStatusName: "",
        WDA_Create_DateTime: "2023-06-22T00:00:00+00:00",
        AssessmentGroupId: 0,
        WDA_Approval_Security_Group_Id: 8,
        ApprovalType: 0,
        FunctionalGroup: "Assessment Maintenance",
      },
      {
        WorkflowStepName: "Address Validation2",
        WorkflowDetails: "Address Validation",
        AssessmentGroup: "Standard",
        WorkflowStepSequence: 2,
        SecurityGroup: "Assessment Maintenance",
        ApprovalStatusName: "",
        WDA_Create_DateTime: "2023-06-22T00:00:00+00:00",
        AssessmentGroupId: 0,
        WDA_Approval_Security_Group_Id: 8,
        ApprovalType: 0,
        FunctionalGroup: "Assessment Maintenance",
      },
    ],
    WD_Require_Approval: true,
    Officer: "DERIN.DAVIS@OPENOFFICE.COM.AU",
    OfficerAssessmentGroups: [
      {
        Code: "20",
        Name: "Central Tablelands",
      },
      {
        Code: "21",
        Name: "Central West",
      },
      {
        Code: "27",
        Name: "Northern West",
      },
    ],
    WD_Require_SendBack: false,
    WD_Gis_Mapping_Launch_Url: "",
    Flags: {
      Requires_Approval: true,
      Can_Reject: true,
    },
  } as any,
};
export const mockLRSChangeOfOwnershipLOVResponse: DTO_ChangeOfOwnership_LOVs = {
  ChangeOfOwnershipTypes: [
    {
      Change_of_Ownership_Type: 1,
      COOT_Name: "Full Transfer of Lot Ownership",
      COOT_Clear_Rebates: true,
      COOT_Display_Rebates: true,
      COOT_Display_Percentage: true,
      COOT_Display_Owner_Roles: true,
      COOT_Display_Associated_Names: true,
      COOT_Display_RatePayer_Roles: true,
      COOT_Display_PIC_Roles: false,
      COOT_Display_Register_Roles: false,
      COOT_Display_Retain_AssociatedNames: true,
      COOT_Display_Retain_Occupier: true,
      COOT_Retain_Occupier: true,
      COOT_Retain_AssociatedNames: true,
    },
    {
      Change_of_Ownership_Type: 2,
      COOT_Name: "Partial Transfer of Lot Ownership",
      COOT_Clear_Rebates: false,
      COOT_Display_Rebates: true,
      COOT_Display_Percentage: true,
      COOT_Display_Owner_Roles: true,
      COOT_Display_Associated_Names: true,
      COOT_Display_RatePayer_Roles: true,
      COOT_Display_PIC_Roles: false,
      COOT_Display_Register_Roles: false,
      COOT_Display_Retain_AssociatedNames: false,
      COOT_Display_Retain_Occupier: false,
      COOT_Retain_Occupier: true,
      COOT_Retain_AssociatedNames: true,
    },
  ],
  NoticeGroups: [
    {
      Code: 1020,
      Name: "Pensioner Deferment",
    },
    {
      Code: 1021,
      Name: "S585 Postponement",
    },
    {
      Code: 1022,
      Name: "Heritage Valuation Applies",
    },
    {
      Code: 1030,
      Name: "Record for Information Purposes Only",
    },
    {
      Code: 1090,
      Name: "C/- Real Estate Agent - Misc",
    },
    {
      Code: 1091,
      Name: "Hunters Hill Council",
    },
    {
      Code: 1092,
      Name: "Pullouts",
    },
    {
      Code: 1093,
      Name: "NSW Land and Housing Corporation",
    },
    {
      Code: 1094,
      Name: "C/- Richardson & Wrench ",
    },
    {
      Code: 1096,
      Name: "Ausgrid",
    },
    {
      Code: 1097,
      Name: "C/- Ward Partners",
    },
    {
      Code: 1098,
      Name: "C/- Century 21 Prestige Properties, Rozelle",
    },
    {
      Code: 1099,
      Name: "C/- Ray White Gladesville",
    },
    {
      Code: 1100,
      Name: "C/- Ray White North Ryde",
    },
    {
      Code: 1102,
      Name: "C/- Warwick Williams Real Estate",
    },
    {
      Code: 1104,
      Name: "C/- John Ward Realty ",
    },
    {
      Code: 1105,
      Name: "Overseas - Airmail",
    },
    {
      Code: 1108,
      Name: "C/- Ray White Hunters Hill",
    },
    {
      Code: 1109,
      Name: "c/- Run Property",
    },
    {
      Code: 1114,
      Name: "C/- Defence Housing Authority Barton",
    },
    {
      Code: 1116,
      Name: "Sydney Water Corporation",
    },
    {
      Code: 1117,
      Name: "C/- Shenks Real Estate",
    },
    {
      Code: 1118,
      Name: "C/- Sarah Lorden Real Estate",
    },
    {
      Code: 1120,
      Name: "C/- Jackson & Rowe Pty Ltd",
    },
    {
      Code: 1122,
      Name: "C/- Elite Property Brokers",
    },
    {
      Code: 1123,
      Name: "C/- Charles & Stuart",
    },
    {
      Code: 1125,
      Name: "C/- Devine Real Estate Drummoyne",
    },
    {
      Code: 1126,
      Name: "161 Holdings Pty Limited",
    },
    {
      Code: 1127,
      Name: "C/- Hamilton & Co",
    },
    {
      Code: 1129,
      Name: "Moch Pty Ltd",
    },
    {
      Code: 1130,
      Name: "Pudo P/L & Senwood P/L",
    },
    {
      Code: 1132,
      Name: "Ciaglia 20 Augustine Street ",
    },
    {
      Code: 1133,
      Name: "C/- Ryde Real Estate",
    },
    {
      Code: 1135,
      Name: "Borec Homes Pty Ltd",
    },
    {
      Code: 1136,
      Name: "MLJ Enterprises",
    },
    {
      Code: 1137,
      Name: "East Bay Investments Pty Ltd",
    },
    {
      Code: 1139,
      Name: "C/- Shead Real Estate",
    },
    {
      Code: 1140,
      Name: "Fairfax",
    },
    {
      Code: 1141,
      Name: "Blizzard Winds Pty Ltd",
    },
    {
      Code: 1142,
      Name: "Xsite International Pty Limited",
    },
    {
      Code: 1144,
      Name: "C/- McGrath Lane Cove",
    },
    {
      Code: 1145,
      Name: "C/- REFA Australia Pty Ltd ",
    },
    {
      Code: 1146,
      Name: "B J Boland",
    },
    {
      Code: 1147,
      Name: "Mr A C & Mrs C J E Johnston",
    },
    {
      Code: 1149,
      Name: "C/- Century 21 Mirabella Leichardt",
    },
    {
      Code: 1150,
      Name: "C/- Morton Real Estate Crows Nest",
    },
    {
      Code: 1151,
      Name: "Iris Property Group Pty Ltd",
    },
    {
      Code: 1152,
      Name: "C/- Little Real Estate",
    },
    {
      Code: 1153,
      Name: "C/- Century 21 City Quarter",
    },
    {
      Code: 1154,
      Name: "C/- Cobden & Hayson Drummoyne",
    },
    {
      Code: 1156,
      Name: "C/- Belle Property Management, Lane Cove",
    },
    {
      Code: 1157,
      Name: "C/- CPS Property",
    },
    {
      Code: 1158,
      Name: "C/- Living Estate Agents",
    },
    {
      Code: 1159,
      Name: "C/- Ray White Drummoyne",
    },
    {
      Code: 1160,
      Name: "C/- iSquared Property",
    },
    {
      Code: 1161,
      Name: "Tanzanite Eight Pty Ltd",
    },
    {
      Code: 1162,
      Name: "C/- Bresic Whitney Estate Agents Hunters Hill",
    },
    {
      Code: 1163,
      Name: "Mr K K & Mrs N Dewan",
    },
    {
      Code: 1164,
      Name: "Chan No. 1 Investments Pty Ltd",
    },
    {
      Code: 1165,
      Name: "R & N Super Pty Ltd",
    },
    {
      Code: 1166,
      Name: "C/- Corporate & Executive Leasing",
    },
    {
      Code: 1167,
      Name: "Sir Moses Montefiore Jewish Home",
    },
    {
      Code: 1168,
      Name: "C/- Glass Property Consultants Pty Ltd",
    },
  ],
  Roles: [
    {
      Role_Id: 3,
      Role_Name: "Nominee",
      Role_Type_Id: 2,
      Role_Type_Name: "Associated Name",
      IsOwner: false,
      IsRatepayer: false,
      IsAssociatedName: true,
      IsPICRole: false,
      IsRegisterAccount: false,
    },
    {
      Role_Id: 4,
      Role_Name: "Lessee",
      Role_Type_Id: 3,
      Role_Type_Name: "Lessee",
      IsOwner: false,
      IsRatepayer: false,
      IsAssociatedName: true,
      IsPICRole: false,
      IsRegisterAccount: false,
    },
    {
      Role_Id: 5,
      Role_Name: "Life Tenant",
      Role_Type_Id: 2,
      Role_Type_Name: "Associated Name",
      IsOwner: false,
      IsRatepayer: false,
      IsAssociatedName: true,
      IsPICRole: false,
      IsRegisterAccount: false,
    },
    {
      Role_Id: 6,
      Role_Name: "Non Resident Owner",
      Role_Type_Id: 9,
      Role_Type_Name: "Owner",
      IsOwner: true,
      IsRatepayer: false,
      IsAssociatedName: false,
      IsPICRole: false,
      IsRegisterAccount: false,
    },
    {
      Role_Id: 7,
      Role_Name: "Non Resident Voter",
      Role_Type_Id: 2,
      Role_Type_Name: "Associated Name",
      IsOwner: false,
      IsRatepayer: false,
      IsAssociatedName: true,
      IsPICRole: false,
      IsRegisterAccount: false,
    },
    {
      Role_Id: 8,
      Role_Name: "Body Corporate",
      Role_Type_Id: 2,
      Role_Type_Name: "Associated Name",
      IsOwner: false,
      IsRatepayer: false,
      IsAssociatedName: true,
      IsPICRole: false,
      IsRegisterAccount: false,
    },
    {
      Role_Id: 10,
      Role_Name: "Account Name",
      Role_Type_Id: 4,
      Role_Type_Name: "Register Account Name",
      IsOwner: false,
      IsRatepayer: false,
      IsAssociatedName: false,
      IsPICRole: false,
      IsRegisterAccount: true,
    },
    {
      Role_Id: 12,
      Role_Name: "Occupier",
      Role_Type_Id: 8,
      Role_Type_Name: "Ratepayer",
      IsOwner: false,
      IsRatepayer: true,
      IsAssociatedName: false,
      IsPICRole: false,
      IsRegisterAccount: false,
    },
    {
      Role_Id: 13,
      Role_Name: "Owner",
      Role_Type_Id: 9,
      Role_Type_Name: "Owner",
      IsOwner: true,
      IsRatepayer: false,
      IsAssociatedName: false,
      IsPICRole: false,
      IsRegisterAccount: false,
    },
    {
      Role_Id: 14,
      Role_Name: "Property Agent",
      Role_Type_Id: 10,
      Role_Type_Name: "Property Agent",
      IsOwner: false,
      IsRatepayer: false,
      IsAssociatedName: true,
      IsPICRole: false,
      IsRegisterAccount: false,
    },
    {
      Role_Id: 103,
      Role_Name: "PIC Manager",
      Role_Type_Id: 2,
      Role_Type_Name: "Associated Name",
      IsOwner: false,
      IsRatepayer: false,
      IsAssociatedName: true,
      IsPICRole: false,
      IsRegisterAccount: false,
    },
    {
      Role_Id: 108,
      Role_Name: "Owner/Ratepayer",
      Role_Type_Id: 1,
      Role_Type_Name: "Owner/Ratepayer",
      IsOwner: true,
      IsRatepayer: true,
      IsAssociatedName: false,
      IsPICRole: false,
      IsRegisterAccount: false,
    },
    {
      Role_Id: 110,
      Role_Name: "Occupier",
      Role_Type_Id: 8,
      Role_Type_Name: "Ratepayer",
      IsOwner: false,
      IsRatepayer: true,
      IsAssociatedName: false,
      IsPICRole: false,
      IsRegisterAccount: false,
    },
    {
      Role_Id: 111,
      Role_Name: "Duplicate Occupier",
      Role_Type_Id: 11,
      Role_Type_Name: "Duplicate Ratepayer",
      IsOwner: false,
      IsRatepayer: true,
      IsAssociatedName: false,
      IsPICRole: false,
      IsRegisterAccount: false,
    },
    {
      Role_Id: 113,
      Role_Name: "Partner",
      Role_Type_Id: 13,
      Role_Type_Name: "Contact",
      IsOwner: false,
      IsRatepayer: false,
      IsAssociatedName: false,
      IsPICRole: false,
      IsRegisterAccount: false,
    },
    {
      Role_Id: 180,
      Role_Name: "PIC Manager",
      Role_Type_Id: 12,
      Role_Type_Name: "Pic Manager",
      IsOwner: false,
      IsRatepayer: false,
      IsAssociatedName: false,
      IsPICRole: true,
      IsRegisterAccount: false,
    },
  ],
};
