import {
  DTO_ChangeOfOwnership_LOVs,
  DTO_Role,
  RoleTypes,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ChangeOfOwnershipDialogStore {
  private _changeOfOwnershipLOVs?: DTO_ChangeOfOwnership_LOVs = undefined;
  private _ownerRoleNames: string[] = [];
  private _ratepayerRoleNames: string[] = [];
  private _duplicateRoleNames: string[] = [];
  private _associatedRoleNames: string[] = [];
  private _dynamicRoles?: DTO_Role[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get changeOfOwnershipLOVs() {
    return this._changeOfOwnershipLOVs;
  }
  setChangeOfOwnershipLOVs = (
    changeOfOwnershipLOVs?: DTO_ChangeOfOwnership_LOVs
  ) => {
    const ownerRoleNames =
      changeOfOwnershipLOVs?.Roles?.filter(
        (roleItem) => roleItem?.IsOwner || roleItem?.IsPICRole
      )?.map((roleItem) => roleItem?.Role_Name) ?? [];
    const ratepayerRoleNames =
      changeOfOwnershipLOVs?.Roles?.filter(
        (roleItem) => roleItem?.IsRatepayer
      )?.map((roleItem) => roleItem?.Role_Name) ?? [];
    const duplicateRoleNames =
      changeOfOwnershipLOVs?.Roles?.filter(
        (roleItem) => roleItem?.Role_Type_Id === RoleTypes.Duplicate_Owner
      )?.map((roleItem) => roleItem?.Role_Name) ?? [];
    const associatedRoleNames =
      changeOfOwnershipLOVs?.Roles?.filter(
        (roleItem) => roleItem?.IsAssociatedName
      )?.map((roleItem) => roleItem?.Role_Name) ?? [];
    runInAction(() => {
      this._changeOfOwnershipLOVs = changeOfOwnershipLOVs;
      this._dynamicRoles = changeOfOwnershipLOVs?.Roles;
      this._ownerRoleNames = ownerRoleNames;
      this._ratepayerRoleNames = ratepayerRoleNames;
      this._duplicateRoleNames = duplicateRoleNames;
      this._associatedRoleNames = associatedRoleNames;
    });
  };

  get ownerRoleNames() {
    return this._ownerRoleNames;
  }

  get ratepayerRoleNames() {
    return this._ratepayerRoleNames;
  }

  get duplicateRoleNames() {
    return this._duplicateRoleNames;
  }

  get associatedRoleNames() {
    return this._associatedRoleNames;
  }

  get dynamicRoles() {
    return this._dynamicRoles;
  }
  setDynamicRoles = (roles?: DTO_Role[]) => {
    runInAction(() => {
      this._dynamicRoles = roles;
    });
  };
}

const changeOfOwnershipDialogStoreContext = createContext(
  new ChangeOfOwnershipDialogStore()
);
export const useChangeOfOwnershipDialogStore = () => {
  return useContext(changeOfOwnershipDialogStoreContext);
};
