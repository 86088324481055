import { IRecipientsRewrite } from "@app/core/communication/dialogs/components/form-elememts/to/model";
import { find, result } from "lodash";

export const validatorToStep = (values: any) => {
  if (!values.UseSMS && !values.UsePreferred && !values.UseEmail) {
    return "Please choose options";
  }

  if (!values.SelectedToRows || values.SelectedToRows?.length <= 0) {
    return "Please select at least one type";
  }
  return undefined;
};

export const recipientsRewrite = (
  data: any,
  parentIdRecipients: number
): IRecipientsRewrite => {
  return {
    _WorkPhone: data._WorkPhone,
    _HomePhone: data._HomePhone,
    _Fax: data._Fax,
    _Mobile: data._Mobile,
    Parent_ID: parentIdRecipients,
    Contact_ID: data.Contact_ID,
    Contact_RID: data.Contact_ID,
    Contact_FullName: data.DisplayName,
    ContactRelationshipType_Name: "Other",
    Email: data.Email,
    PreferredMethod_ENUM: data.PreferredMethod_ENUM,
    SendCopyToMe: false,
    SendCopyToMeBCC: false,
    selected: true,
    expanded: false,
    SendToEmail: true,
    SendToSMS: false,
  };
};

export const recipientsAddRewrite = (
  data: any,
  parentIdRecipients: number,
  parentRecordType?: string
): IRecipientsRewrite => {
  return {
    _WorkPhone: data._WorkPhone,
    _HomePhone: data._HomePhone,
    _Fax: data._Fax,
    _Mobile: data.Mobile,
    Parent_ID: parentIdRecipients,
    Parent_RecordType: parentRecordType,
    Contact_ID: data.ID,
    Contact_RID: data.ID,
    Contact_FullName: data.DisplayName,
    ContactRelationshipType_Name: "Other",
    Email: data.Email,
    PreferredMethod_ENUM: data.PreferredMethod_ENUM,
    SendCopyToMe: false,
    SendCopyToMeBCC: false,
    selected: true,
    expanded: false,
    SendToEmail: true,
    SendToSMS: true,
  };
};

export const findSourceIdentifierForKey = (data: any, key: string) => {
  return result(
    find(data.SelectedRecords_Details[0]?._BubbleUps, {
      BubbleUpType_ENUM: "Parent",
    })?.SourceIdentifier,
    key
  );
};
