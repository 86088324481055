import { EventBatchClose } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/event-bactch-close/model";
import { CCPickEventClosure } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/pick-event-closure/_index";
import { CS_LookupTable } from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import "../close-event/index.scss";

export interface ICloseEventDialogProps {
  onClosePopup: () => void;
  onSubmit: (data: any) => void;
  eventBatchClose?: EventBatchClose;
  isLoading: boolean;
}

export const EventBatchCloseDialog = observer(
  ({
    onClosePopup,
    onSubmit,
    isLoading,
    eventBatchClose,
  }: ICloseEventDialogProps) => {
    const { settings } = useCommonCoreStore();
    const { notification, setNotification } = useCRMSEventStore();

    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const nameOf = nameOfFactory<EventBatchClose>();
    const [isShowPickEventClosure, setIsShowPickEventClosure] = useState(false);
    const isShowNotifyCustomer = getBoolValueSetting(
      settings[
        ECorporateSettingsField
          .CUSTOMERSERVICE_Event_SendNotificationInBatchClose
      ]
    );

    const handleSubmit = async (event: FormSubmitClickEvent) => {
      onSubmit(event.values);
    };

    useEffect(() => {
      if (notification) {
        notificationRef.current?.pushNotification(notification, () =>
          setNotification(undefined)
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification]);

    return (
      <Form
        initialValues={eventBatchClose}
        key={JSON.stringify(eventBatchClose)}
        onSubmitClick={handleSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit, modified, valid, onChange, valueGetter } =
            formRenderProps;
          return (
            <CCDialog
              maxWidth="30%"
              height="auto"
              titleHeader={"Event Batch Closure"}
              onClose={onClosePopup}
              bodyElement={
                <FormElement className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Date closed
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("DateClosed")}
                          component={CCDateTimePicker}
                          validator={requiredValidator}
                          format={DATETIME_FORMAT.DATETIME_CONTROL}
                          disabled={isLoading}
                        />
                      </div>
                      <div className="cc-field">
                        <div className="cc-label cc-justify-between cc-align-item">
                          <div className="cc-flex">
                            Closure comment
                            <CCTooltip type="validator" position="right" />
                          </div>
                          <Button
                            type="button"
                            iconClass="fas fa-plus"
                            onClick={() => {
                              setIsShowPickEventClosure(true);
                            }}
                            disabled={isLoading}
                            title="Insert a Standard Resolution"
                          />
                        </div>
                        <Field
                          name={nameOf("ClosureComment")}
                          component={CCTextArea}
                          placeholder="Closure detail"
                          validator={requiredValidator}
                          rows={3}
                          readOnly={isLoading}
                        />
                      </div>

                      {isShowNotifyCustomer && (
                        <div className="cc-field">
                          <label className="cc-label">
                            Notify customer of event closure (if applicable)
                            <CCTooltip
                              type="info"
                              content="If this option is ticked, an email will be sent to the customer notifying them that the event has been closed."
                            />
                          </label>
                          <Field
                            name={nameOf("FinalNotification")}
                            component={CCSwitch}
                            value={valueGetter(nameOf("FinalNotification"))}
                            disabled={isLoading}
                          />
                        </div>
                      )}
                    </div>
                  </section>

                  {isShowPickEventClosure && (
                    <CCPickEventClosure
                      isMultiple={false}
                      dataUrl={`/odata/crms/internal/lookuptable/GetCurrentLookupValues(lookupTableType=${CS_LookupTable.EventClosureComment})?$count=true&`}
                      onClose={() => setIsShowPickEventClosure(false)}
                      onSubmit={(data) => {
                        setIsShowPickEventClosure(false);
                        onChange(nameOf("ClosureComment"), {
                          value: data.Description,
                        });
                      }}
                    />
                  )}
                </FormElement>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    type="button"
                    className={"cc-dialog-button"}
                    onClick={() => {
                      onClosePopup();
                    }}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!modified || !valid || isLoading}
                    themeColor="primary"
                    onClick={onSubmit}
                    className={"cc-dialog-button"}
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
