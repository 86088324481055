import { ILocalLawsDetails } from "./model";

export const mockLocalLawsDetails: ILocalLawsDetails = {
  ID: 1,
  PermitNo: "C16622",
  Status: "Active",
  Lodged: "13 Jan 2021",
  EffectiveFrom: "13 Jan 2021",
  Expires: "13 Jan 2021",
};
