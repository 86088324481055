import { DTO_Modify_Parcel_LOVs } from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ModifyParcelDialogStore {
  private _modifyParcelLOVs?: DTO_Modify_Parcel_LOVs = undefined;
  private _isLoadingOnDialog: boolean = false;
  private _isLoadingOnDialogCancel: boolean = false;
  private _isLoadingSendBack: boolean = false;
  private _isLoadingAllocate: boolean = false;
  private _isLoadingReallocate: boolean = false;
  private _isOpenedUrlDialog: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get modifyParcelLOVs() {
    return this._modifyParcelLOVs;
  }
  setModifyParcelLOVs = (modifyParcelLOVs?: DTO_Modify_Parcel_LOVs) => {
    runInAction(() => {
      this._modifyParcelLOVs = modifyParcelLOVs;
    });
  };

  get isOpenedUrlDialog(): boolean {
    return this._isOpenedUrlDialog;
  }
  setIsOpenedUrlDialog = (_isOpenedUrlDialog: boolean) => {
    runInAction(() => {
      this._isOpenedUrlDialog = _isOpenedUrlDialog;
    });
  };

  get isLoadingOnDialogCancel(): boolean {
    return this._isLoadingOnDialogCancel;
  }
  setIsLoadingOnDialogCancel = (isLoadingOnDialogCancel: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialogCancel = isLoadingOnDialogCancel;
    });
  };
  get isLoadingOnDialog(): boolean {
    return this._isLoadingOnDialog;
  }
  setIsLoadingOnDialog = (isLoadingOnDialog: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialog = isLoadingOnDialog;
    });
  };

  get isLoadingSendBack(): boolean {
    return this._isLoadingSendBack;
  }
  setIsLoadingSendBack = (isLoadingSendBack: boolean) => {
    runInAction(() => {
      this._isLoadingSendBack = isLoadingSendBack;
    });
  };

  get isLoadingAllocate(): boolean {
    return this._isLoadingAllocate;
  }
  setIsLoadingAllocate = (isLoadingAllocate: boolean) => {
    runInAction(() => {
      this._isLoadingAllocate = isLoadingAllocate;
    });
  };

  get isLoadingReallocate(): boolean {
    return this._isLoadingReallocate;
  }
  setIsLoadingReallocate = (isLoadingReallocate: boolean) => {
    runInAction(() => {
      this._isLoadingReallocate = isLoadingReallocate;
    });
  };
}

const modifyParcelDialogStoreContext = createContext(
  new ModifyParcelDialogStore()
);
export const useModifyParcelDialogStore = () => {
  return useContext(modifyParcelDialogStoreContext);
};
