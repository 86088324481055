import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { Svc_ActionType } from "@common/models/actionType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { DeleteActionTypesButton } from "@common/pages/settings/lookups/action-types/components/action-bar/buttons/delete-action-types/_index";
import { NewActionTypesButton } from "@common/pages/settings/lookups/action-types/components/action-bar/buttons/new-action-types/_index";
import { colLookupsActionTypes } from "@common/pages/settings/lookups/action-types/config";
import { LOOKUPS_ACTION_TYPES_LIST_VIEW_URL } from "@common/pages/settings/lookups/action-types/constant";
import { LookupActionTypeBookmark } from "@common/pages/settings/lookups/action-types/util";
import { LookupActionTypesTabDetails } from "@common/pages/settings/lookups/action-types/_id/components/reference-sidebar/detail/_index";
import { LookupActionTypesHistoryTab } from "@common/pages/settings/lookups/action-types/_id/components/reference-sidebar/history/_index";
import { SETTINGS_LOOKUPS_ACTION_TYPES_ROUTE } from "@common/pages/settings/lookups/action-types/_id/constant";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<Svc_ActionType>();

export default observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  const { settings } = useCommonCoreStore();
  const isEnableTagColumn = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_ActionType_Tags]
  );
  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [
      <NewActionTypesButton disabled={!hasPermission} />,
      <DeleteActionTypesButton disabled={!hasPermission} />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={SETTINGS_LOOKUPS_ACTION_TYPES_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_ActionType}
        detail={LookupActionTypeBookmark.getBookmarkDetail}
        displayName={LookupActionTypeBookmark.getBookmarkDisplayName}
        listViewDetail={LookupActionTypeBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          LookupActionTypeBookmark.getBookmarkListViewDisplayName
        }
        isDisabledRecordListView={!hasPermission}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <LookupActionTypesTabDetails /> },
      {
        title: "History",
        component: <LookupActionTypesHistoryTab />,
      },
    ],
  });

  if (!isEnableTagColumn) {
    const colTag = colLookupsActionTypes.find((element) => {
      return element.field === nameOf("ActionTypeTags_CSV");
    });
    if (colTag) {
      colLookupsActionTypes.splice(colLookupsActionTypes.indexOf(colTag), 1);
    }
  }

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colLookupsActionTypes ?? []}
      primaryField={nameOf("ID")}
      dataUrl={LOOKUPS_ACTION_TYPES_LIST_VIEW_URL}
      state={{
        sort: [{ field: nameOf("Name"), dir: "asc" }],
      }}
      disabled={!hasPermission}
    />
  );
});
