import { assessmentGISValidator } from "@app/products/property/assessments/components/util";
import { GISReferenceDialog } from "@app/products/property/components/dialogs/gis-reference/_index";
import { colGISReference } from "@app/products/property/components/dialogs/gis-reference/config";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { useCreateTitleStore } from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/store";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";

const nameOfGIS = nameOfFactory<DTO_GIS>();
export const TitleDetailsFormStep = (props: IFormStepElement) => {
  const formValidator = useCallback((value) => {
    return assessmentGISValidator(
      value?.GIS_References ?? [],
      nameOfGIS("Effective_From"),
      nameOfGIS("Effective_To")
    );
  }, []);
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? formValidator : undefined}
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isLLS: false,
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { lovsCreateTitle } = useCreateTitleStore();

    // Variable for gis
    const gis: DTO_GIS[] = getFieldValue("GIS_References") ?? [];
    const selectedGIS = getFieldValue("_option.GISSelected") ?? [];

    // State
    const [isShowDialog, setIsShowDialog] = useState<boolean>();

    // Handle logic for GIS grid
    /**
     * handle add item for (GIS Reference)
     * @param data
     */
    const handleAddItem = async (data: DTO_GIS) => {
      //get previous data GIS
      const previousGIS = gis?.length ? [...gis] : [];
      //add more temporary ID for new record
      const newGIS = { ...data, Id: `new_${getUUID()}` };
      //merge previous data and new one
      const gisData = [...previousGIS, newGIS];
      onChange(nameOf("GIS_References"), {
        value: gisData,
      });
    };

    /**
     * handle delete item (GIS Reference)
     */
    const handleDeleteItem = () => {
      const gisId = selectedGIS?.[0]?.Id;
      //filter new list GIS without selected record
      const newGIS = gis.filter((item: DTO_GIS) => item.Id !== gisId);
      onChange(nameOf("GIS_References"), {
        value: newGIS,
      });
      onChange(nameOf("_option.GISSelected"), {
        value: [],
      });
    };

    const handleDataChangeGrid = async (
      dataRow: DTO_GIS,
      fieldChange: string
    ) => {
      //GIS Reference
      const gisId = dataRow?.Id;
      let newGIS = [...gis];
      // update value Effective_To and Effective_From
      newGIS = newGIS.map((item: DTO_GIS) => {
        if (
          item.Id === gisId &&
          (fieldChange === nameOfGIS("Effective_To") ||
            fieldChange === nameOfGIS("Effective_From"))
        ) {
          return {
            ...item,
            Effective_To: dataRow?.Effective_To ?? null,
            Effective_From: dataRow?.Effective_From ?? null,
          };
        }
        return item;
      });
      onChange(nameOf("GIS_References"), { value: newGIS });
    };

    //Get labels
    const [titleLabel, region, rlpBoard, titleLowercase] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.Title,
        ECustomColNameProperty.Sector,
        ECustomColNameProperty.Category,
        ECustomColNameProperty.TitleLowercase,
      ]);
    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title={`${titleLabel} reference`} />
            <Field
              name={nameOf("Reference_Number")}
              component={CCNumericTextBox}
              min={0}
              readOnly={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Lot" />
            <Field
              name={nameOf("Lot")}
              component={CCInput}
              readOnly={options?.isReadOnly}
              placeholder="Lot"
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Legal description" isMandatory />
            <Field
              name={nameOf("Legal_Description")}
              component={CCInput}
              readOnly={options?.isReadOnly}
              placeholder="Legal description"
              validator={!options?.isReadOnly ? requiredValidator : undefined}
            />
          </div>
          {options?.isLLS ? (
            <div className="cc-field">
              <CCLabel title="LGA" />
              <Field
                name={nameOf("LGA_Id")}
                component={CCSearchComboBox}
                data={convertValueLOVToNumber(lovsCreateTitle?.LGA ?? [])}
                textField="Name"
                dataItemKey="Code"
                disabled={options?.isReadOnly}
                isUseDefaultOnchange
              />
            </div>
          ) : null}
          <div className="cc-field">
            <CCLabel title="Plan type" />
            <Field
              name={nameOf("Plan_Type")}
              component={CCSearchComboBox}
              data={convertValueLOVToNumber(lovsCreateTitle?.Plan_Type ?? [])}
              textField="Name"
              dataItemKey="Code"
              disabled={options?.isReadOnly}
              isUseDefaultOnchange
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Plan number" />
            <Field
              name={nameOf("Plan_Number")}
              component={CCInput}
              readOnly={options?.isReadOnly}
              placeholder="Plan number"
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Land type" />
            <Field
              name={nameOf("Land_Type")}
              component={CCInput}
              readOnly={options?.isReadOnly}
              maxLength={100}
              placeholder="Land type"
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Township" />
            <Field
              name={nameOf("Township_Id")}
              component={CCSearchComboBox}
              data={convertValueLOVToNumber(lovsCreateTitle?.Township ?? [])}
              textField="Name"
              dataItemKey="Code"
              disabled={options?.isReadOnly}
              isUseDefaultOnchange
            />
          </div>
          <div className="cc-field">
            <CCLabel title="County" />
            <Field
              name={nameOf("County_Id")}
              component={CCSearchComboBox}
              data={convertValueLOVToNumber(lovsCreateTitle?.County ?? [])}
              textField="Name"
              dataItemKey="Code"
              disabled={options?.isReadOnly}
              isUseDefaultOnchange
            />
          </div>
          {options?.isLLS ? (
            <div className="cc-field">
              <CCLabel title="State" />
              <Field
                name={nameOf("State_Code")}
                component={CCSearchComboBox}
                data={lovsCreateTitle?.State ?? []}
                textField="Name"
                dataItemKey="Code"
                disabled={options?.isReadOnly}
                isUseDefaultOnchange
              />
            </div>
          ) : null}
          <div className="cc-field">
            <CCLabel title="Country" />
            <Field
              name={nameOf("Country_Id")}
              component={CCSearchComboBox}
              data={convertValueLOVToNumber(lovsCreateTitle?.Country ?? [])}
              textField="Name"
              dataItemKey="Code"
              disabled={options?.isReadOnly}
              isUseDefaultOnchange
            />
          </div>
          <div className="cc-field">
            <CCLabel title={region} />
            <Field
              name={nameOf("Region_Id")}
              component={CCSearchComboBox}
              data={convertValueLOVToNumber(lovsCreateTitle?.Region ?? [])}
              textField="Name"
              dataItemKey="Code"
              disabled={options?.isReadOnly}
              isUseDefaultOnchange
            />
          </div>
          {options?.isLLS ? (
            <div className="cc-field">
              <CCLabel title={rlpBoard} />
              <Field
                name={nameOf("DRC_Id")}
                component={CCSearchComboBox}
                data={convertValueLOVToNumber(
                  lovsCreateTitle?.Differential_Rate_Classification ?? []
                )}
                textField="Name"
                dataItemKey="Code"
                disabled={options?.isReadOnly}
                isUseDefaultOnchange
              />
            </div>
          ) : null}
          <div className="cc-field">
            <CCLabel title="Dealing number" />
            <Field
              name={nameOf("Dealing_Number")}
              component={CCNumericTextBox}
              readOnly={options?.isReadOnly}
              min={0}
              placeholder="Dealing number"
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Book number" />
            <Field
              name={nameOf("Book_Number")}
              component={CCInput}
              readOnly={options?.isReadOnly}
              placeholder="Book number"
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Section" />
            <Field
              name={nameOf("Section")}
              component={CCInput}
              readOnly={options?.isReadOnly}
              placeholder="Section"
              maxLength={12}
            />
          </div>
          <div className="cc-field">
            <CCLabel title={`${titleLabel} type`} />
            <Field
              name={nameOf("Title_Type_Id")}
              component={CCSearchComboBox}
              data={convertValueLOVToNumber(lovsCreateTitle?.Lot_Type ?? [])}
              textField="Name"
              dataItemKey="Code"
              disabled={options?.isReadOnly}
              isUseDefaultOnchange
            />
          </div>
          <div className="cc-field">
            <CCLabel title={`Is part ${titleLowercase}`} />
            <Field
              name={nameOf("Is_Part_Of_Lot")}
              checked={getFieldValue("Is_Part_Of_Lot")}
              component={CCSwitch}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Volume" />
            <Field
              name={nameOf("Volume")}
              component={CCInput}
              readOnly={options?.isReadOnly}
              placeholder="Volume"
              maxLength={20}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Folio" />
            <Field
              name={nameOf("Folio")}
              component={CCInput}
              readOnly={options?.isReadOnly}
              placeholder="Folio"
              maxLength={20}
            />
          </div>
          <div className="cc-field">
            <CCLabel title={`${titleLabel} system`} />
            <Field
              name={nameOf("Title_System")}
              component={CCInput}
              readOnly={options?.isReadOnly}
              placeholder={`${titleLabel} system`}
            />
          </div>
          <div className="cc-field">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <CCLabel title="Land area" />
                <Field
                  name={nameOf("Land_Area")}
                  component={CCNumericTextBox}
                  min={0}
                  readOnly={options?.isReadOnly}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Unit" />
                <Field
                  name={nameOf("Land_Area_Unit_Code")}
                  component={CCSearchComboBox}
                  data={lovsCreateTitle?.Area_Unit ?? []}
                  textField="Name"
                  dataItemKey="Code"
                  disabled={options?.isReadOnly}
                  isUseDefaultOnchange
                />
              </div>
            </div>
          </div>
          <div className="cc-field">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <CCLabel title="Excluded area" />
                <Field
                  name={nameOf("Excluded_Area")}
                  component={CCNumericTextBox}
                  min={0}
                  readOnly={options?.isReadOnly}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Unit" />
                <Field
                  name={nameOf("Excluded_Area_Unit_Code")}
                  component={CCSearchComboBox}
                  data={lovsCreateTitle?.Area_Unit ?? []}
                  textField="Name"
                  dataItemKey="Code"
                  disabled={options?.isReadOnly}
                  isUseDefaultOnchange
                />
              </div>
            </div>
          </div>
          <div className="cc-field">
            <CCLabel title={"Is area surveyed"} />
            <Field
              name={nameOf("Is_Area_Surveyed")}
              checked={getFieldValue("Is_Area_Surveyed")}
              component={CCSwitch}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Division date" />
            <Field
              name={nameOf("Division_Date")}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
              format={DATE_FORMAT.DATE_CONTROL}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Parish" />
            <Field
              name={nameOf("Parish_Id")}
              component={CCSearchComboBox}
              data={convertValueLOVToNumber(lovsCreateTitle?.Parish ?? [])}
              textField="Name"
              dataItemKey="Code"
              disabled={options?.isReadOnly}
              isUseDefaultOnchange
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Tenure" />
            <Field
              name={nameOf("Tenure_Id")}
              component={CCSearchComboBox}
              data={convertValueLOVToNumber(lovsCreateTitle?.Tenure ?? [])}
              textField="Name"
              dataItemKey="Code"
              disabled={options?.isReadOnly}
              isUseDefaultOnchange
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Frontage" />
            <Field
              name={nameOf("Frontage")}
              component={CCNumericTextBox}
              min={0}
              readOnly={options?.isReadOnly}
              format={DATE_FORMAT.DATE_CONTROL}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Coverage" />
            <Field
              name={nameOf("Coverage_Id")}
              component={CCSearchComboBox}
              data={convertValueLOVToNumber(lovsCreateTitle?.Coverage ?? [])}
              textField="Name"
              dataItemKey="Code"
              disabled={options?.isReadOnly}
              isUseDefaultOnchange
            />
          </div>
          <div className="cc-field">
            <CCLabel title="P number" />
            <Field
              name={nameOf("P_Number")}
              component={CCInput}
              readOnly={options?.isReadOnly}
              placeholder="Page number"
              maxLength={10}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Crown allotment" />
            <Field
              name={nameOf("Crown_Allotment")}
              component={CCInput}
              readOnly={options?.isReadOnly}
              placeholder="Crown allotment"
              maxLength={12}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Crown status" />
            <Field
              name={nameOf("Crown_Status")}
              component={CCInput}
              readOnly={options?.isReadOnly}
              placeholder="Crown status"
              maxLength={1}
            />
          </div>
          {!options?.isLLS && (
            <div className="cc-field">
              <CCLabel title="Block" />
              <Field
                name={nameOf("Title_Block")}
                component={CCInput}
                readOnly={options?.isReadOnly}
                placeholder="Block"
                maxLength={12}
              />
            </div>
          )}
          <div className="cc-field">
            <CCLabel title="Plan registration date" />
            <Field
              name={nameOf("Plan_Registration_Date")}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
              format={DATE_FORMAT.DATE_CONTROL}
            />
          </div>
          {/* Only show for state SA for now can hide */}
          {/* <div className="cc-field">
            <CCLabel title="Piece" />
            <Field
              name={nameOf("Title_Piece")}
              component={CCInput}
              readOnly={options?.isReadOnly}
              placeholder="Piece"
              maxLength={20}
            />
          </div> */}
          {!options?.isLLS && (
            <>
              <div className="cc-field">
                <CCLabel title="Portion" />
                <Field
                  name={nameOf("Title_Portion")}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                  placeholder="Portion"
                  maxLength={12}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Subdivision" />
                <Field
                  name={nameOf("Title_Subdivision")}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                  placeholder="Subdivision"
                  maxLength={20}
                />
              </div>
            </>
          )}
          <div className="cc-field">
            <CCLabel title="Further description" />
            <Field
              name={nameOf("Further_Description")}
              component={CCTextArea}
              readOnly={options?.isReadOnly}
              placeholder="Further description"
            />
          </div>
        </div>
        <div className="cc-field">
          <CCLabel title="GIS references" errorMessage={errors?.[nameOf("")]} />
          <CCGrid
            readOnly={options?.isReadOnly}
            toolbar={
              !options?.isReadOnly ? (
                <div className="cc-grid-tools-bar">
                  <Button
                    type="button"
                    iconClass="fas fa-plus"
                    title="Add"
                    onClick={(event) => {
                      event.preventDefault();
                      setIsShowDialog(true);
                    }}
                  />
                  <Button
                    type="button"
                    iconClass="fas fa-minus"
                    title="Remove"
                    onClick={(event) => {
                      event.preventDefault();
                      handleDeleteItem();
                    }}
                    disabled={selectedGIS?.length !== 1}
                  />
                </div>
              ) : null
            }
            onDataRowChange={(dataRow: DTO_GIS, fieldChange: string) => {
              handleDataChangeGrid(dataRow, fieldChange);
            }}
            editableMode={!options?.isReadOnly ? "cell" : undefined}
            className="cc-gis-reference-grid-data"
            data={gis ?? []}
            columnFields={colGISReference}
            primaryField={nameOfGIS("Id")}
            selectableMode="single"
            onSelectionChange={(dataItem: DTO_GIS[]) => {
              onChange(nameOf("_option.GISSelected"), {
                value: dataItem,
              });
            }}
            selectedRows={selectedGIS}
          />
        </div>
        {isShowDialog && (
          <GISReferenceDialog
            onClose={() => {
              setIsShowDialog(false);
            }}
            onSubmit={(data: DTO_GIS) => {
              handleAddItem(data);
              setIsShowDialog(false);
            }}
            gisReferenceData={gis ?? []}
            gisTypes={lovsCreateTitle?.GISType ?? []}
          />
        )}
      </section>
    );
  }
);
