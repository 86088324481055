import { ContactDetailFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/details/_index";
import { PostalAndPhysicalAddressFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/_index";
import { EAddressStepperMode } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/config";
import { EKeysOfUpdateEntitySteps } from "@app/products/property/contacts-central-names/list/components/dialogs/update-entity/model";
import { isShowParkButton } from "@app/products/property/util";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { AssociationsFormStep } from "../components/form-elements/associations/_index";
import { AttributesFormStep } from "../components/form-elements/attributes/_index";
import { ConcessionCardsFormStep } from "../components/form-elements/concession-cards/_index";
import { PersonFormStep } from "../components/form-elements/person/_index";
import { VotingFormStep } from "../components/form-elements/voting/_index";
import { defaultPerson } from "../new-contact/config";
import { defaultAssociations, defaultConcessionCards } from "./config";

interface IUpdateEntityDialogProps {
  onClose: () => void;
  onSubmit: (entity: any) => void;
  currentEntity?: any;
  title?: string;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
}

export const UpdateEntityDialog = observer(
  ({
    onClose,
    onSubmit,
    currentEntity,
    title = "Update Contact",
    isIncompleteMode = false,
    isFromActionList = false,
  }: IUpdateEntityDialogProps) => {
    const { gridSelectedRows } = useCCProductListViewStore();
    const { currentOrganisationMode } = useCommonProductStore();
    const isActro = currentOrganisationMode(OrganisationMode.ACTRO);
    const [dataUpdate, setDataUpdate] = useState<any[]>(gridSelectedRows || []);

    useEffectOnce(() => {
      const listSelected = gridSelectedRows;
      setDataUpdate(listSelected);
    });

    const getInitSteps = () => {
      let initStepsTemp = {};
      if (currentEntity) {
        initStepsTemp = {
          ...currentEntity,
        };
      } else if (dataUpdate && dataUpdate?.[0]) {
        initStepsTemp = {
          ...dataUpdate[0],
          Delivery: "Standard",
          Gender: dataUpdate[0]?.Gender === "Male" ? "M" : "F",
          Surname: dataUpdate[0]?.Name?.split(".")[0] ?? "",
          GivenNames: dataUpdate[0]?.Name?.split(".")?.[1].trim() ?? "",
        };
      }
      return initStepsTemp;
    };

    const steps: IStep[] = [
      {
        label: "Details",
        initialValues: {},
        component: ContactDetailFormStep,
        visible: true,
        key: EKeysOfUpdateEntitySteps.Details,
        options: {
          contactLOVs: undefined,
          isUpdate: true,
        },
      },
      {
        label: "Person",
        initialValues: { ...defaultPerson, ...getInitSteps() },
        component: PersonFormStep,
        visible: true,
        key: EKeysOfUpdateEntitySteps.Person,
        //TODO: Update contactLOVs in options when integrate API
        options: {
          contactLOVs: undefined,
        },
      },
      {
        label: "Postal Address",
        initialValues: {},
        component: PostalAndPhysicalAddressFormStep,
        visible: true,
        key: EKeysOfUpdateEntitySteps.PostalAddress,
        options: {
          contactLOVs: undefined,
          keyOfPersonStep: EKeysOfUpdateEntitySteps.Person,
          keyOfRelatedAddressStep: EKeysOfUpdateEntitySteps.PhysicalAddress,
          stepperMode: EAddressStepperMode.Postal,
        },
      },
      {
        label: "Physical Address",
        initialValues: {},
        component: PostalAndPhysicalAddressFormStep,
        visible: true,
        key: EKeysOfUpdateEntitySteps.PhysicalAddress,
        options: {
          contactLOVs: undefined,
          keyOfPersonStep: EKeysOfUpdateEntitySteps.Person,
          keyOfRelatedAddressStep: EKeysOfUpdateEntitySteps.PostalAddress,
          stepperMode: EAddressStepperMode.Physical,
        },
      },
      {
        label: "Attributes",
        initialValues: { ...getInitSteps() },
        component: AttributesFormStep,
        visible: true,
        key: EKeysOfUpdateEntitySteps.Attributes,
        //TODO: Update contactLOVs in options when integrate API
        options: {
          isActro,
          contactLOVs: undefined,
        },
      },
      {
        label: "Concession cards",
        initialValues: defaultConcessionCards,
        component: ConcessionCardsFormStep,
        visible: true,
        key: EKeysOfUpdateEntitySteps.ConcessionCards,
      },
      {
        label: "Related",
        initialValues: defaultAssociations,
        component: AssociationsFormStep,
        visible: true,
        key: EKeysOfUpdateEntitySteps.Associations,
      },
      {
        label: "Voting",
        initialValues: [],
        component: VotingFormStep,
        visible: !isActro,
        key: EKeysOfUpdateEntitySteps.Voting,
      },
    ];

    return (
      <CCFormStep
        onSubmit={onSubmit}
        initialSteps={steps}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            maxHeight="90%"
            titleHeader={title}
            onClose={onClose}
            bodyElement={renderProps.children}
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                {isShowParkButton(isFromActionList, isIncompleteMode) && (
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Park
                  </Button>
                )}
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Cancel
                </Button>
                {!renderProps.prevButton.disabled && (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                )}
                <Button
                  themeColor="primary"
                  id="cc-next-step-button"
                  disabled={renderProps.nextButton.disabled}
                  className={"cc-dialog-button"}
                  onClick={renderProps.nextButton.onClick}
                >
                  {renderProps.nextButton.label}
                </Button>
              </div>
            }
          />
        )}
      />
    );
  }
);
