import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { BatchUpdateEventLocationState } from "@app/products/crms/batch-update-event/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

export interface IBatchUpdateButton {
  isDisabled?: boolean;
  isVisible?: boolean;
}

export const BatchUpdateButton = observer(
  ({ isDisabled = false, isVisible = true }: IBatchUpdateButton) => {
    const history = useHistory();

    const { gridSelectedIds } = useCCProductListViewStore();

    if (!isVisible) return null;
    return (
      <CCNavButton
        title="Batch Update"
        disabled={isDisabled || gridSelectedIds.length === 0}
        onClick={() => {
          history.push(`${CRMS_ROUTE}/batch-update`, {
            gridSelectedIds: gridSelectedIds,
          } as BatchUpdateEventLocationState);
        }}
      />
    );
  }
);
