import { ILodgeApplicationDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/lodge-application/model";
import { ILodge } from "@app/products/town-planning/ppr/enquiries/_id/components/dialogs/lodge-application/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React from "react";
interface ILodgeApplicationFormProps {
  isLoading: boolean;
  onSubmit: (data: ILodge) => void;
  onClose: () => void;
}

const nameOf = nameOfFactory<ILodgeApplicationDialog>();

export const LodgeDialog = ({
  onSubmit,
  isLoading,
  onClose,
}: ILodgeApplicationFormProps) => {
  const handleSubmit = (event: FormSubmitClickEvent) => {
    onSubmit(event.values as ILodge);
  };

  return (
    <Form
      onSubmitClick={handleSubmit}
      initialValues={{ DateLodged: new Date() }}
      render={(formRenderProps: FormRenderProps) => {
        const { onSubmit, valid } = formRenderProps;
        return (
          <FormElement>
            <CCDialog
              maxWidth="20%"
              height={"auto"}
              titleHeader={"Enter the Date"}
              onClose={onClose}
              disabled={isLoading}
              bodyElement={
                <div className="cc-form">
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Date lodged <CCTooltip type="validator" />
                        </label>
                        <Field
                          name={nameOf("DateLodged")}
                          component={CCDatePicker}
                          format={DATE_FORMAT.DATE_CONTROL}
                          validator={requiredValidator}
                        />
                      </div>
                    </div>
                  </section>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    disabled={!valid}
                    onClick={onSubmit}
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
