import { amsRoute } from "@app/products/ams/route";
import { animalRoute } from "@app/products/animals/route";
import { buildingRoute } from "@app/products/building/route";
import { crmsRoute } from "@app/products/crms/route";
import { crsRoute } from "@app/products/crs/route";
import { cslRoute } from "@app/products/csl/route";
import { eventManagementRoute } from "@app/products/event-management/route";
import { hmRoute } from "@app/products/hm/route";
import { infringementsRoute } from "@app/products/infringements/route";
import { landManagementRoute } from "@app/products/land-management/route";
import { localLawsRoute } from "@app/products/local-laws/route";
import { parkingPermitsRoute } from "@app/products/parking-permits/route";
import { propertyRoute } from "@app/products/property/route";
import { townPlanningRoute } from "@app/products/town-planning/route";
import { wasteWaterRoute } from "@app/products/waste-water/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { AmsRouter } from "@routes/AmsRouter";
import { AnimalRouter } from "@routes/AnimalRouter";
import { BuildingRouter } from "@routes/BuildingRouter";
import { CrmsRouter } from "@routes/CrmsRouter";
import { CRSRouter } from "@routes/CRSRouter";
import { CSLRouter } from "@routes/CSLRouter";
import { EventManagementRouter } from "@routes/EventManagementRouter";
import { HMRouter } from "@routes/HMRouter";
import { InfringementsRouter } from "@routes/InfringementsRouter";
import { LandManagementRouter } from "@routes/LandManagementRouter";
import { LocalLawsRouter } from "@routes/LocalLawsRouter";
import { ParkingPermitsRouter } from "@routes/ParkingPermitsRouter";
import { IProductRouteProps } from "@routes/product-route/_index";
import { PropertyRouter } from "@routes/PropertyRouter";
import { TownPlanningRouter } from "@routes/TownPlanningRouter";
import { WasteWaterRouter } from "@routes/WastewaterRouter";

export const propsProductsRoute: IProductRouteProps[] = [
  {
    productType: PRODUCT_TYPE.DISRES,
    productPath: "/" + parkingPermitsRoute.path,
    componentRouter: ParkingPermitsRouter,
  },
  {
    productType: PRODUCT_TYPE.Animals,
    productPath: "/" + animalRoute.path,
    componentRouter: AnimalRouter,
  },
  {
    productType: PRODUCT_TYPE.EventManagementSystem,
    productPath: "/" + eventManagementRoute.path,
    componentRouter: EventManagementRouter,
  },
  {
    productType: PRODUCT_TYPE.CommunityAsset,
    productPath: "/" + amsRoute.path,
    componentRouter: AmsRouter,
  },
  {
    productType: PRODUCT_TYPE.TownPlanning,
    productPath: "/" + townPlanningRoute.path,
    componentRouter: TownPlanningRouter,
  },
  {
    productType: PRODUCT_TYPE.CommunityProperty,
    productPath: "/" + propertyRoute.path,
    componentRouter: PropertyRouter,
  },
  {
    productType: PRODUCT_TYPE.CustomerService,
    productPath: "/" + crmsRoute.path,
    componentRouter: CrmsRouter,
  },
  {
    productType: PRODUCT_TYPE.HealthManager,
    productPath: "/" + hmRoute.path,
    componentRouter: HMRouter,
  },
  {
    productType: PRODUCT_TYPE.Building,
    productPath: "/" + buildingRoute.path,
    componentRouter: BuildingRouter,
  },
  {
    productType: PRODUCT_TYPE.LLPermits,
    productPath: "/" + localLawsRoute.path,
    componentRouter: LocalLawsRouter,
  },
  {
    productType: PRODUCT_TYPE.WasteWater,
    productPath: "/" + wasteWaterRoute.path,
    componentRouter: WasteWaterRouter,
  },
  {
    productType: PRODUCT_TYPE.CRS,
    productPath: "/" + crsRoute.path,
    componentRouter: CRSRouter,
  },
  {
    productType: PRODUCT_TYPE.CSM,
    productPath: "/" + cslRoute.path,
    componentRouter: CSLRouter,
  },
  {
    productType: PRODUCT_TYPE.Infringements,
    productPath: "/" + infringementsRoute.path,
    componentRouter: InfringementsRouter,
  },
  {
    productType: PRODUCT_TYPE.LandManagement,
    productPath: "/" + landManagementRoute.path,
    componentRouter: LandManagementRouter,
  },
];
