import { ECustomColNameProperty } from "@app/products/property/config";
import { WorkflowTypes } from "@app/products/property/model";
import {
  getAssessmentLevyFlags,
  getAssessmentLevyLOVs,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/assessment-levies/dialogs/assessment-levy/api";
import { defaultAssessmentLevyFlags } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/assessment-levies/dialogs/assessment-levy/config";
import {
  DTO_AssessmentLevy_Flags,
  DTO_Levy_LOV,
  DTO_Supplementary_AssessmentLevy,
  DTO_Supplementary_AssessmentLevy_LOVs,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/assessment-levies/dialogs/assessment-levy/model";
import { handleValidateForm } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/assessment-levies/dialogs/assessment-levy/util";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCPercentInput } from "@components/cc-percent-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IFormAssessmentLevyDialogProps {
  initialValues?: DTO_Supplementary_AssessmentLevy;
  isNew: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  isSubmitting: boolean;
  notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>;
}
const nameOf = nameOfFactory<DTO_Supplementary_AssessmentLevy>();

export const FormAssessmentLevyDialog = observer(
  ({
    initialValues,
    isNew,
    onClose,
    onSubmit,
    isSubmitting,
    notificationRef,
  }: IFormAssessmentLevyDialogProps) => {
    //Use store
    const { assessmentId, supplementaryAssessmentId } =
      useSupplementaryRatesStore();

    //Get label
    const assessmentLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Assessment
    );

    //Use state
    const [formData, setFormData] = useState(initialValues);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAssessmentLevyFlags, setIsLoadingAssessmentLevyFlags] =
      useState(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const [assessmentLevyLOVs, setAssessmentLevyLOVs] =
      useState<DTO_Supplementary_AssessmentLevy_LOVs>();
    const [assessmentLevyFlags, setAssessmentLevyFlags] =
      useState<DTO_AssessmentLevy_Flags>(defaultAssessmentLevyFlags);

    //Destructure assessmentLevyFlags object
    const {
      Display_Category_Field: displayCategory,
      Display_LandRentCalcMethod_Field: displayLandRentCalcMethod,
      Display_Levy_Field: displayLevy,
      Display_MaximumsMinimums_Field: displayMaximumsMinimums,
      Display_PercentageofAmount_Field: displayPercentageOfAmount,
      Display_PercentageofLevy_Field: displayPercentageOfLevy,
      Display_PercentageofRatebyValn_Field: displayPercentageOfRatebyValn,
      Display_QuantityAmount_Field: displayQuantityAmountField,
      Display_Reason_Field: displayReason,
      Display_TobeActiveFromTo_Field: displayTobeActiveFromTo,
      Enable_Checkbox_ReplaceExistingLevy: enableReplaceExistingLevy,
      QuantityAmount_FieldLabel: quantityAmountLabel,
      QuantityAmount_IsRequired: quantityAmountIsRequired,
    } = assessmentLevyFlags;

    /**
     * Load assessment levy LOVs and flags to show/hide fields
     */
    const loadData = async () => {
      if (isNil(assessmentId)) return;
      setIsLoading(true);
      let errorResponse = undefined;
      const response = await getAssessmentLevyLOVs(
        assessmentId,
        WorkflowTypes.AssessmentAdjustment_Supplementary
      );
      if (isSuccessResponse(response) && response?.data) {
        if (isNew) {
          setAssessmentLevyLOVs(response.data.dtoAssessmentLevyLOVs);
        } else {
          //Get assessment levy flags
          const responseAssessmentLevyFlags = await getAssessmentLevyFlags(
            supplementaryAssessmentId,
            initialValues?.LevyId ?? 0
          );
          if (
            isSuccessResponse(response) &&
            responseAssessmentLevyFlags?.data
          ) {
            setAssessmentLevyFlags(responseAssessmentLevyFlags.data);
            setFormData({
              ...initialValues,
              ReplaceExistingLevy:
                responseAssessmentLevyFlags.data
                  .Check_Checkbox_ReplaceExistingLevy,
            } as DTO_Supplementary_AssessmentLevy);
            setAssessmentLevyLOVs(response.data.dtoAssessmentLevyLOVs);
          }
        }
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
      setResponseLoadError(errorResponse);
      setIsLoading(false);
    };

    useEffectOnce(() => {
      loadData();
    });

    return (
      <Form
        onSubmit={onSubmit}
        initialValues={isNew ? undefined : formData}
        key={JSON.stringify(formData)}
        validator={handleValidateForm}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onSubmit, onChange, modified, valid } =
            formRenderProps;

          const resetFields = () => {
            setAssessmentLevyFlags(defaultAssessmentLevyFlags);
            onChange(nameOf("ReplaceExistingLevy"), {
              value:
                defaultAssessmentLevyFlags.Check_Checkbox_ReplaceExistingLevy,
            });
            onChange(nameOf("Active_From_Date"), {
              value: defaultAssessmentLevyFlags.ActiveFromDefaultValue,
            });
          };

          const handleChangeCategory = (event: DropDownListChangeEvent) => {
            onChange(nameOf("LevyCategoryId"), {
              value: event.value?.Code,
            });

            //Reset fields when no levy is selected
            resetFields();
          };

          const handleChangeLevy = async (event: DropDownListChangeEvent) => {
            onChange(nameOf("LevyId"), {
              value: event.value?.Code,
            });

            //Reset fields when no levy is selected
            if (!event.value) {
              resetFields();
              return;
            }

            setIsLoadingAssessmentLevyFlags(true);
            let errorResponse = undefined;
            const response = await getAssessmentLevyFlags(
              supplementaryAssessmentId,
              event.value?.Code
            );
            setIsLoadingAssessmentLevyFlags(false);
            if (isSuccessResponse(response) && response?.data) {
              setAssessmentLevyFlags(response.data);
              onChange(nameOf("ReplaceExistingLevy"), {
                value: response.data.Check_Checkbox_ReplaceExistingLevy,
              });
              onChange(nameOf("Active_From_Date"), {
                value: response.data.ActiveFromDefaultValue,
              });
            } else {
              errorResponse = {
                status: response.status,
                error: response.error,
              };
            }
            setResponseLoadError(errorResponse);
          };
          return (
            <CCDialog
              maxWidth="50%"
              maxHeight="70%"
              isLoading={isLoading}
              titleHeader={`${isNew ? "New" : "Edit"} ${assessmentLabel} Levy`}
              onClose={onClose}
              bodyElement={
                <React.Fragment>
                  {responseLoadError ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={() => {
                        loadData();
                      }}
                    />
                  ) : (
                    <FormElement className="cc-form">
                      <section className="cc-field-group">
                        <CCLocalNotification ref={notificationRef} />
                        <div className="cc-form-cols-2">
                          {displayCategory && (
                            <div className="cc-field">
                              <CCLabel title="Category" isMandatory />
                              <Field
                                name={nameOf("LevyCategoryId")}
                                component={CCSearchComboBox}
                                validator={requiredValidator}
                                textField="Name"
                                dataItemKey="Code"
                                data={assessmentLevyLOVs?.LevyCategories ?? []}
                                value={getDropdownValue(
                                  "" + valueGetter(nameOf("LevyCategoryId")),
                                  assessmentLevyLOVs?.LevyCategories ?? [],
                                  "Code"
                                )}
                                onChange={handleChangeCategory}
                                disabled={!isNew}
                              />
                            </div>
                          )}
                          {displayLevy && (
                            <div className="cc-field">
                              <label className="cc-label">
                                Levy
                                <CCTooltip type="validator" position="right" />
                                {isLoadingAssessmentLevyFlags && (
                                  <i className="fas fa-spinner fa-spin ml-1" />
                                )}
                              </label>
                              <Field
                                name={nameOf("LevyId")}
                                component={CCSearchComboBox}
                                validator={requiredValidator}
                                textField="Name"
                                dataItemKey="Code"
                                data={
                                  assessmentLevyLOVs?.Levies?.filter(
                                    (levy: DTO_Levy_LOV) =>
                                      levy.Category ===
                                      +valueGetter(nameOf("LevyCategoryId"))
                                  ) ?? []
                                }
                                value={getDropdownValue(
                                  "" + valueGetter(nameOf("LevyId")),
                                  assessmentLevyLOVs?.Levies?.filter(
                                    (levy: DTO_Levy_LOV) =>
                                      levy.Category ===
                                      +valueGetter(nameOf("LevyCategoryId"))
                                  ) ?? [],
                                  "Code"
                                )}
                                onChange={handleChangeLevy}
                                disabled={!isNew}
                              />
                            </div>
                          )}
                          {displayReason && (
                            <div className="cc-field">
                              <CCLabel title="Change reason" />
                              <Field
                                name={nameOf("ChangeReasonId")}
                                component={CCSearchComboBox}
                                textField="Name"
                                dataItemKey="Code"
                                data={assessmentLevyLOVs?.ChangeReasons ?? []}
                                value={getDropdownValue(
                                  "" + valueGetter(nameOf("ChangeReasonId")),
                                  assessmentLevyLOVs?.ChangeReasons ?? [],
                                  "Code"
                                )}
                                onChange={(event: DropDownListChangeEvent) => {
                                  onChange(nameOf("ChangeReasonId"), {
                                    value: event.value?.Code,
                                  });
                                }}
                                disabled={!isNew}
                              />
                            </div>
                          )}
                          {displayMaximumsMinimums && (
                            <div className="cc-field">
                              <CCLabel title="Number of minimum/maximum" />
                              <Field
                                name={nameOf("NumberOfMinimums")}
                                component={CCNumericTextBox}
                                min={0}
                                max={100}
                                step={1}
                                disabled={!isNew}
                              />
                            </div>
                          )}
                          {displayQuantityAmountField && (
                            <div className="cc-field">
                              <CCLabel
                                title={quantityAmountLabel}
                                isMandatory={quantityAmountIsRequired}
                              />
                              <Field
                                name={nameOf("Quantity_Amount")}
                                component={CCNumericTextBox}
                                type="number"
                                min={0}
                                max={100}
                                step={0.1}
                                disabled={!isNew}
                              />
                            </div>
                          )}
                          {displayPercentageOfAmount && (
                            <div className="cc-field">
                              <CCLabel title="Percentage of amount" />
                              <Field
                                name={nameOf("BaseAdjustmentRate")}
                                min={0}
                                max={100}
                                step={0.01}
                                component={CCPercentInput}
                                disabled={!isNew}
                              />
                            </div>
                          )}
                          {displayPercentageOfRatebyValn && (
                            <div className="cc-field">
                              <CCLabel title="Percentage of rate by valuation" />
                              <Field
                                name={nameOf("RateByValueAdjustmentRate")}
                                min={0}
                                max={100}
                                step={0.01}
                                component={CCPercentInput}
                                disabled={!isNew}
                              />
                            </div>
                          )}
                          {displayPercentageOfLevy && (
                            <div className="cc-field">
                              <CCLabel title="Percentage of levy" />
                              <Field
                                name={nameOf("Percentage")}
                                min={0}
                                max={100}
                                step={0.01}
                                component={CCPercentInput}
                                disabled={!isNew}
                              />
                            </div>
                          )}
                          {displayTobeActiveFromTo && (
                            <>
                              <div className="cc-field">
                                <CCLabel title="To be active from" />
                                <Field
                                  name={nameOf("Active_From_Date")}
                                  component={CCDatePicker}
                                  format={DATE_FORMAT.DATE_CONTROL}
                                  max={
                                    valueGetter(nameOf("Active_To_Date"))
                                      ? new Date(
                                          valueGetter(nameOf("Active_To_Date"))
                                        )
                                      : undefined
                                  }
                                />
                              </div>
                              <div className="cc-field">
                                <CCLabel title="To be active to" />
                                <Field
                                  name={nameOf("Active_To_Date")}
                                  component={CCDatePicker}
                                  format={DATE_FORMAT.DATE_CONTROL}
                                  min={
                                    valueGetter(nameOf("Active_From_Date"))
                                      ? new Date(
                                          valueGetter(
                                            nameOf("Active_From_Date")
                                          )
                                        )
                                      : undefined
                                  }
                                />
                              </div>
                            </>
                          )}
                          {displayLandRentCalcMethod && (
                            <div className="cc-field cc-col-span-2">
                              <CCLabel title="Land rent calculation method" />
                              <Field
                                name={nameOf("LRCM_Method")}
                                component={CCSearchComboBox}
                                textField="Name"
                                dataItemKey="Code"
                                data={assessmentLevyLOVs?.LRCMS ?? []}
                                value={getDropdownValue(
                                  "" + valueGetter(nameOf("LRCM_Method")),
                                  assessmentLevyLOVs?.LRCMS ?? [],
                                  "Code"
                                )}
                                onChange={(event: DropDownListChangeEvent) => {
                                  onChange(nameOf("LRCM_Method"), {
                                    value: event.value?.Code,
                                  });
                                }}
                              />
                            </div>
                          )}
                          <div className="cc-field cc-col-span-2">
                            <Checkbox
                              label="This replaces an existing levy in the same category (previous levy will be made inactive)"
                              checked={valueGetter(
                                nameOf("ReplaceExistingLevy")
                              )}
                              onChange={(event: CheckboxChangeEvent) => {
                                onChange(nameOf("ReplaceExistingLevy"), {
                                  value: event.value,
                                });
                              }}
                              disabled={!enableReplaceExistingLevy || !isNew}
                            />
                          </div>
                          <div className="cc-field cc-col-span-2">
                            <Checkbox
                              label="No interest is to be raised on this levy"
                              checked={valueGetter(nameOf("NoInterestOnLevy"))}
                              onChange={(event: CheckboxChangeEvent) => {
                                onChange(nameOf("NoInterestOnLevy"), {
                                  value: event.value,
                                });
                              }}
                              disabled={!isNew}
                            />
                          </div>
                        </div>
                      </section>
                    </FormElement>
                  )}
                </React.Fragment>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    themeColor="primary"
                    className="cc-dialog-button"
                    disabled={!modified || !valid || isSubmitting}
                    iconClass={isSubmitting ? "fas fa-spinner fa-spin" : ""}
                    onClick={onSubmit}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
