import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { ContactRelationshipTypeDialog } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/communication/forms/communication-settings/components/contact-relationship-types-dialog/_index";
import { Svc_Contact_ContactRelationshipType } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/communication/forms/communication-settings/components/contact-relationship-types-dialog/model";
import { colContactType } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/communication/forms/communication-settings/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { NumericTextboxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/numeric-textbox/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { nameOfFactory } from "@common/utils/common";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCGrid } from "@components/cc-grid/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface ICommunicationSettingsProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<Svc_Contact_ContactRelationshipType>();
export default observer(({ formRenderProps }: ICommunicationSettingsProps) => {
  const { valueGetter, onChange } = formRenderProps;

  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const data = useDataSetting(configData, [
    "Global_Communication_EnableCommunicationQueue",
    "Global_Communication_ActionType",
    "Global_Communication_DefaultSenderEmail_KeywordID",
    "Global_Communication_DefaultInbox",
    "Global_Communication_AddFromCurrent",
    "Global_Communication_AddFromRecords",
    "Global_Communication_EnableAttachmentSizeRestriction",
    "Global_Communication_CommAttachmentSizeRestrictionSize",
    "CorporateSettings_CommunicationSaveAttachments",
    "CorporateSettings_CommunicationEnableEmailCopyByBCC",
    "Global_SendCommunication_UsingNewMethod",
    "Global_Communication_EnableDocCategory_DocumentTempate",
    "Global_Communication_EnableDocCategory_Attachment",
    "CorporateSettings_ExcludeContactRelationshipType",
  ]);

  //Local state
  const [showAddContactTypeDialog, setShowAddContactTypeDialog] =
    useState(false);
  const [
    contactRelationshipTypesSelected,
    setContactRelationshipTypesSelected,
  ] = useState<Svc_Contact_ContactRelationshipType[]>([]);
  const [isShowConfirmDeletion, setIsShowConfirmDeletion] =
    useState<boolean>(false);

  const contactTypesData: Svc_Contact_ContactRelationshipType[] =
    valueGetter(
      `option.ValueDisplayDetails_${CORPORATE_SETTINGS_FIELD.CorporateSettings_ExcludeContactRelationshipType}`
    ) ?? [];

  const processContactTypeData = (
    valueList: Svc_Contact_ContactRelationshipType[] | undefined
  ) => {
    let currentValue = "|";
    if (valueList) {
      valueList.forEach(
        (item: Svc_Contact_ContactRelationshipType) =>
          (currentValue += item.Id + "|")
      );
    }
    return currentValue;
  };

  const handleDeleteContactTypes = () => {
    const selectedIDs = contactRelationshipTypesSelected?.map(
      (contactRelationshipType: Svc_Contact_ContactRelationshipType) =>
        contactRelationshipType.Id
    );
    const newContactTypesData = contactTypesData?.filter(
      (contactRelationshipType: Svc_Contact_ContactRelationshipType) =>
        !selectedIDs.includes(contactRelationshipType.Id)
    );

    onChange(
      `option.ValueDisplayDetails_${CORPORATE_SETTINGS_FIELD.CorporateSettings_ExcludeContactRelationshipType}`,
      {
        value: newContactTypesData,
      }
    );

    onChange(
      `${CORPORATE_SETTINGS_FIELD.CorporateSettings_ExcludeContactRelationshipType}`,
      {
        value: processContactTypeData(newContactTypesData),
      }
    );
    setContactRelationshipTypesSelected([]);
  };

  const handleAddContactTypes = (
    data: Svc_Contact_ContactRelationshipType[]
  ) => {
    onChange(
      `option.ValueDisplayDetails_${CORPORATE_SETTINGS_FIELD.CorporateSettings_ExcludeContactRelationshipType}`,
      {
        value: data,
      }
    );

    onChange(
      `${CORPORATE_SETTINGS_FIELD.CorporateSettings_ExcludeContactRelationshipType}`,
      {
        value: processContactTypeData(data),
      }
    );
  };

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <SwitchGlobalSettings
            data={data.Global_Communication_EnableCommunicationQueue}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
        <div className="cc-form-cols-3">
          <CCSearchComboBoxGlobalSettings
            data={data.Global_Communication_ActionType}
            isEditing={isEditing}
          />
          <CCSearchComboBoxGlobalSettings
            data={data.Global_Communication_DefaultSenderEmail_KeywordID}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={data.Global_Communication_DefaultInbox}
            isEditing={isEditing}
          />
        </div>
        <div className="cc-form-cols-3">
          <SwitchGlobalSettings
            data={data.Global_Communication_AddFromCurrent}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchGlobalSettings
            data={data.Global_Communication_AddFromRecords}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
        <div className="cc-form-cols-3">
          <SwitchGlobalSettings
            data={data.Global_Communication_EnableAttachmentSizeRestriction}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          {valueGetter(
            data.Global_Communication_EnableAttachmentSizeRestriction
              ?.FieldName as string
          ) && (
            <NumericTextboxGlobalSettings
              data={data.Global_Communication_CommAttachmentSizeRestrictionSize}
              isEditing={isEditing}
              min={0}
            />
          )}
          <SwitchGlobalSettings
            data={data.CorporateSettings_CommunicationSaveAttachments}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
        <div className="cc-form-cols-3">
          <SwitchGlobalSettings
            data={data.CorporateSettings_CommunicationEnableEmailCopyByBCC}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchGlobalSettings
            data={data.Global_SendCommunication_UsingNewMethod}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchGlobalSettings
            data={data.Global_Communication_EnableDocCategory_DocumentTempate}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
        <div className="cc-form-cols-3">
          <SwitchGlobalSettings
            data={data.Global_Communication_EnableDocCategory_Attachment}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              {data.CorporateSettings_ExcludeContactRelationshipType?.Title ??
                ""}
              {data.CorporateSettings_ExcludeContactRelationshipType
                ?.HelpText && (
                <CCTooltip
                  type="custom"
                  content=" "
                  position="auto"
                  clickToOpen
                  customTemplate={
                    <>
                      {sanitizeHtml(
                        data.CorporateSettings_ExcludeContactRelationshipType
                          ?.HelpText
                      )}
                    </>
                  }
                >
                  <i className="fa fa-info-circle ml-1 text-accent" />
                </CCTooltip>
              )}
            </label>
            <div className="cc-custom-sub-panel-bar">
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <CCGrid
                    toolbar={
                      <div className="cc-grid-tools-bar">
                        <Button
                          type="button"
                          iconClass="fa fa-plus"
                          title="Add Contact Type"
                          onClick={() => {
                            setShowAddContactTypeDialog(true);
                          }}
                          disabled={!isEditing}
                        />
                        <Button
                          type="button"
                          iconClass="fas fa-minus"
                          title="Remove Contact Type"
                          disabled={
                            !(
                              contactRelationshipTypesSelected?.length > 0 &&
                              isEditing
                            )
                          }
                          onClick={() => {
                            setIsShowConfirmDeletion(true);
                          }}
                        />
                      </div>
                    }
                    columnFields={colContactType}
                    primaryField={nameOf("Id")}
                    data={contactTypesData ?? []}
                    selectableMode={isEditing ? "multiple" : "none"}
                    selectedRows={contactRelationshipTypesSelected}
                    onSelectionChange={(dataItem: any[]) => {
                      if (dataItem)
                        setContactRelationshipTypesSelected([...dataItem]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showAddContactTypeDialog && (
        <ContactRelationshipTypeDialog
          handleSubmit={handleAddContactTypes}
          onClose={() => {
            setShowAddContactTypeDialog(false);
          }}
        />
      )}
      {isShowConfirmDeletion && (
        <ConfirmDialog
          title="Confirm Deletion"
          subMessage="Are you sure you want to delete the selected item(s)?"
          onClosePopup={() => {
            setIsShowConfirmDeletion(false);
          }}
          onConfirmYes={handleDeleteContactTypes}
        />
      )}
    </>
  );
});
