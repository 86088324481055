import {
  loadChangeOfOwnerShipAssessment,
  loadRebateEntitlementsAssessment,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/property/api";
import {
  colAssessments,
  colTitles,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/property/config";
import {
  DTO_ChangeOfOwnership_Assessment,
  DTO_ChangeOfOwnership_Assessment_Rebates,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/property/model";
import {
  DTO_RebateEntitlementOwners,
  DTO_Rebates,
  DTO_Title,
  EKeysOfSteps,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { AddAssessmentLookupDialog } from "@app/products/property/components/dialogs/add-assessment-lookup/_index";
import { fnt_Assessment_LookupResult } from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { subDays } from "date-fns";
import React, { useState } from "react";

const nameOfAssessment = nameOfFactory<fnt_Assessment_LookupResult>();
const nameOfTitle = nameOfFactory<DTO_Title>();
export const PropertyFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  formRenderProps,
  localNotificationRef,
  setStepsVisible,
  nameOf,
  options = {
    isReadOnly: false,
  },
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const [confirmDialog, setConfirmDialog] = useState<
    | {
        title: string;
        message: string;
        subMessage: string;
        data: any;
      }
    | undefined
  >(undefined);
  const [showAddAssessmentDialog, setShowAddAssessmentDialog] = useState(false);
  const [isLoadingAddAssessment, setIsLoadingAddAssessment] = useState(false);

  const selectedTitle = getFieldValue("_option.TitleSelected");
  const currentTitles = getFieldValue("Titles") ?? [];
  const currentAssessments = getFieldValue("Assessments") ?? [];
  const currentRebateEntitlement = valueGetter(`${EKeysOfSteps.Rebates}`) ?? {};
  const selectedAssessment = getFieldValue("_option.AssessmentSelected");

  const handleAddAssessment = (
    propertyData: DTO_ChangeOfOwnership_Assessment
  ) => {
    if (propertyData?.Assessment) {
      let newAssessments = [...currentAssessments];
      if (
        newAssessments.find(
          (assessment: fnt_Assessment_LookupResult) =>
            assessment?.Assessment_Id === propertyData.Assessment?.Assessment_Id
        )
      ) {
        newAssessments = newAssessments.filter(
          (assessment: fnt_Assessment_LookupResult) =>
            assessment?.Assessment_Id !== propertyData.Assessment?.Assessment_Id
        );
        localNotificationRef?.current?.pushNotification({
          title:
            "Assessment `" +
            `${propertyData.Assessment?.Assessment_Id ?? ""} - ${
              propertyData.Assessment?.Property_Address ?? ""
            }` +
            "` has been selected",
          type: "success",
        });
      } else {
        newAssessments.unshift(propertyData?.Assessment);
        onChange(nameOf("Assessments"), { value: newAssessments });
        localNotificationRef?.current?.pushNotification({
          title: "Add assessment successfully",
          type: "success",
        });
      }
    }

    if (propertyData?.Title) {
      let newTitles = [...currentTitles];
      if (
        newTitles.find(
          (title: DTO_Title) => title.Title_Id === propertyData?.Title?.Title_Id
        )
      ) {
        newTitles = newTitles.filter(
          (title: DTO_Title) => title.Title_Id !== propertyData?.Title?.Title_Id
        );
      }
      newTitles.unshift(propertyData.Title);
      onChange(nameOf("Titles"), { value: newTitles });
    }
  };

  const handleAddRebate = (
    rebateData: DTO_ChangeOfOwnership_Assessment_Rebates
  ) => {
    if (
      !currentAssessments
        ?.map((item: any) => item?.Assessment_Id)
        ?.includes(rebateData?.AssessmentId) &&
      rebateData?.RebateEntitlements?.Rebates?.length
    ) {
      const rebateEntitlements = rebateData?.RebateEntitlements;
      const isClearRebate = valueGetter(
        `${EKeysOfSteps.Type}._option.Type`
      )?.COOT_Clear_Rebates;
      let newRebates = rebateEntitlements?.Rebates ?? [];
      if (isClearRebate) {
        newRebates = rebateEntitlements?.Rebates?.map(
          (rebateItem: DTO_Rebates) => {
            rebateItem.is_InActive = isClearRebate;
            if (rebateItem?.Effective_Date) {
              rebateItem.Loss_Of_Eligibility_Date = subDays(
                rebateItem.Effective_Date,
                1
              );
            } else {
              rebateItem.Loss_Of_Eligibility_Date = new Date();
            }
            return rebateItem;
          }
        );
      }

      onChange(`${EKeysOfSteps.Rebates}.Rebates`, {
        value: [...currentRebateEntitlement?.Rebates, ...newRebates],
      });
      onChange(`${EKeysOfSteps.Rebates}.Owners`, {
        value: [
          ...currentRebateEntitlement?.Owners,
          ...rebateEntitlements?.Owners,
        ],
      });

      setStepsVisible([
        {
          visible:
            valueGetter(`${EKeysOfSteps.Type}._option.Type`)
              ?.COOT_Display_Rebates &&
            currentRebateEntitlement?.Rebates?.length,
          key: EKeysOfSteps.Rebates,
          isClearData: false,
        },
      ]);
    }
  };

  const handleGridSelectionChange = (dataItem: any, field: string) => {
    let newSelected = dataItem ? dataItem[0] : undefined;
    onChange(nameOf(field), {
      value: newSelected,
    });
  };

  const handleRemoveTitle = (title: DTO_Title) => {
    if (title) {
      let newTitles = currentTitles.filter(
        (item: DTO_Title) => item.Title_Id !== title.Title_Id
      );
      if (newTitles[0]) {
        handleGridSelectionChange([newTitles[0]], "_option.TitleSelected");
      } else {
        handleGridSelectionChange([], "_option.TitleSelected");
      }
      onChange(nameOf("Titles"), { value: newTitles });
    }
  };

  const handleRemoveAssessment = (
    assessment: fnt_Assessment_LookupResult,
    isRemoveTitle: boolean
  ) => {
    if (assessment) {
      let newAssessments = currentAssessments.filter(
        (item: fnt_Assessment_LookupResult) =>
          item?.Assessment_Id !== assessment?.Assessment_Id
      );
      if (newAssessments[0]) {
        newAssessments[0].selected = true;
        handleGridSelectionChange(
          [newAssessments[0]],
          "_option.AssessmentSelected"
        );
      } else {
        handleGridSelectionChange([], "_option.AssessmentSelected");
      }
      if (isRemoveTitle) {
        let newTitles = currentTitles.filter(
          (item: DTO_Title) => item?.Assessment_Id !== assessment?.Assessment_Id
        );
        onChange(nameOf("Titles"), { value: newTitles });
        handleGridSelectionChange([], "_option.TitleSelected");
      }
      let newRebates =
        currentRebateEntitlement?.Rebates?.filter(
          (rebateItem: DTO_Rebates) =>
            rebateItem?.Assessment_Id !== assessment?.Assessment_Id
        ) ?? [];
      let newOwnersAssociated =
        currentRebateEntitlement?.Owners?.filter(
          (owners: DTO_RebateEntitlementOwners) =>
            owners?.Assessment_Id !== assessment?.Assessment_Id
        ) ?? [];
      setStepsVisible([
        {
          visible:
            valueGetter(`${EKeysOfSteps.Type}._option.Type`)
              ?.COOT_Display_Rebates && newRebates?.length,
          key: EKeysOfSteps.Rebates,
          isClearData: false,
        },
      ]);

      onChange(`${EKeysOfSteps.Rebates}.Rebates`, { value: newRebates });
      onChange(`${EKeysOfSteps.Rebates}.Owners`, {
        value: newOwnersAssociated,
      });
      onChange(nameOf("Assessments"), { value: newAssessments });
    }
  };

  const handleLookupAssessment = async (
    data: fnt_Assessment_LookupResult[]
  ) => {
    setIsLoadingAddAssessment(true);
    const [assessmentData, rebateData] = await Promise.all([
      loadChangeOfOwnerShipAssessment(data?.[0]?.Assessment_Id ?? 0),
      loadRebateEntitlementsAssessment(data?.[0]?.Assessment_Id ?? 0),
    ]);
    if (
      isSuccessResponse(assessmentData) &&
      isSuccessResponse(rebateData) &&
      assessmentData?.data &&
      rebateData?.data
    ) {
      handleAddAssessment(assessmentData?.data);
      handleAddRebate(rebateData?.data);
    } else {
      localNotificationRef?.current?.pushNotification({
        title: "Add assessment failed",
        type: "error",
        autoClose: false,
      });
    }
    setIsLoadingAddAssessment(false);
    setShowAddAssessmentDialog(false);
  };

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title=" Assessments of which change of ownership is to apply" />
            <CCGrid
              className="cc-assessment-update-lease-transfers"
              data={currentAssessments ?? []}
              selectedRows={selectedAssessment ? [selectedAssessment] : []}
              columnFields={colAssessments}
              primaryField={nameOfAssessment("Assessment_Id")}
              readOnly={options?.isReadOnly}
              selectableMode="single"
              isLimitDetailWidth
              onSelectionChange={(dataItem) => {
                handleGridSelectionChange(
                  dataItem,
                  "_option.AssessmentSelected"
                );
              }}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-plus"
                    title="Add Assessment"
                    onClick={() => {
                      setShowAddAssessmentDialog(true);
                    }}
                  />
                  <Button
                    iconClass="fas fa-minus"
                    title="Remove Assessment"
                    disabled={!selectedAssessment}
                    onClick={() => {
                      setConfirmDialog({
                        title: "Remove Titles?",
                        message: "",
                        subMessage:
                          "Do you wish to remove the corresponding titles for selected assessments from the change of ownership?",
                        data: selectedAssessment,
                      });
                    }}
                  />
                </div>
              }
            />
          </div>

          <div className="cc-field">
            <CCLabel title="Title for which change of ownership is to apply" />
            <CCGrid
              className="cc-title-update-lease-transfers"
              data={currentTitles}
              selectedRows={selectedTitle ? [selectedTitle] : undefined}
              columnFields={colTitles}
              readOnly={options?.isReadOnly}
              selectableMode="single"
              onSelectionChange={(dataItem: any) => {
                handleGridSelectionChange(dataItem, "_option.TitleSelected");
              }}
              primaryField={nameOfTitle("Title_Id")}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-minus"
                    title="Remove Title"
                    disabled={!selectedTitle}
                    onClick={() => {
                      handleRemoveTitle(selectedTitle);
                    }}
                  />
                </div>
              }
            />
          </div>

          <div className="cc-field">
            <CCLabel title="Primary land use" />
            <Field
              name={"Primarylanduse"}
              component={CCSearchComboBox}
              readOnly
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Secondary land use" />
            <Field
              name={"Secondarylanduse"}
              component={CCSearchComboBox}
              readOnly
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">LTO data</label>
            <div className="cc-custom-sub-panel-bar">
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <CCLabel title="Suburb" />
                      <Field
                        name={"Suburb"}
                        value="FLOR"
                        component={CCInput}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Section" />
                      <Field
                        name={"Section"}
                        value="145"
                        component={CCInput}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Block" />
                      <Field
                        name={"Block"}
                        value="00004"
                        component={CCInput}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="cc-field">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <CCLabel title="Unit" />
                      <Field
                        name={"Unit"}
                        value="136"
                        component={CCInput}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Volume" />
                      <Field
                        name={"Volume"}
                        value="1446"
                        component={CCInput}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Folio" />
                      <Field
                        name={"Folio"}
                        value="96"
                        component={CCInput}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {confirmDialog && (
        <ConfirmDialog
          onClosePopup={() => {
            setConfirmDialog(undefined);
          }}
          width="270px"
          height="auto"
          title={confirmDialog.title}
          message={confirmDialog.message}
          subMessage={confirmDialog.subMessage}
          onConfirmYes={() => {
            handleRemoveAssessment(confirmDialog.data, true);
            setConfirmDialog(undefined);
          }}
          onConfirmNo={() => {
            handleRemoveAssessment(confirmDialog.data, false);
            setConfirmDialog(undefined);
          }}
        />
      )}
      {showAddAssessmentDialog && (
        <AddAssessmentLookupDialog
          onClose={() => {
            setShowAddAssessmentDialog(false);
          }}
          handleAddAssessment={handleLookupAssessment}
          isLoadingFinish={isLoadingAddAssessment}
          //Update multiple when api updated
          selectableMode="single"
        />
      )}
    </>
  );
};
