import {
  batchUpdateEventHandler,
  getBatchUpdateEventLovs,
} from "@app/products/crms/batch-update-event/api";
import {
  EventBatchUpdate,
  EventBatchUpdateLOV,
  EventBatchUpdateRequest,
} from "@app/products/crms/batch-update-event/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class CRMSBatchUpdateEventStore {
  private _isLoading: boolean = false;
  private _eventBatchUpdate?: EventBatchUpdate = undefined;
  private _batchUpdateEventLovs?: EventBatchUpdateLOV = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _responseLoadError?: APIResponseError = undefined;
  private _isFormModified: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get batchUpdateEventLovs() {
    return this._batchUpdateEventLovs;
  }
  setBatchUpdateEventLovs = (batchUpdateEventLovs: EventBatchUpdateLOV) => {
    runInAction(() => {
      this._batchUpdateEventLovs = batchUpdateEventLovs;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isFormModified() {
    return this._isFormModified;
  }
  setIsFormModified = (isFormModified: boolean) => {
    runInAction(() => {
      this._isFormModified = isFormModified;
    });
  };

  get eventBatchUpdate() {
    return this._eventBatchUpdate;
  }
  setEventBatchUpdate = (eventBatchUpdate?: EventBatchUpdate) => {
    runInAction(() => {
      this._eventBatchUpdate = eventBatchUpdate;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._batchUpdateEventLovs = undefined;
      this._onSubmit = undefined;
      this._responseLoadError = undefined;
      this._isFormModified = false;
      this._eventBatchUpdate = undefined;
    });
  };

  loadBatchUpdateEventLovs = async () => {
    this.setIsLoading(true);
    const response = await getBatchUpdateEventLovs();
    this.setIsLoading(false);
    if (isSuccessResponse(response) && response?.data) {
      this.setBatchUpdateEventLovs(response?.data);
    } else {
      this.setResponseLoadError({
        status: response.status,
        error: response.error ?? "Load lovs failed.",
      });
    }
  };

  batchUpdateEventChangeHandler = async (
    eventBatchUpdate: EventBatchUpdateRequest,
    errorMsg: string
  ) => {
    this.setIsLoading(true);
    const response = await batchUpdateEventHandler(eventBatchUpdate);

    this.setIsLoading(false);
    if (isSuccessResponse(response)) {
      this.setIsFormModified(true);
      this.setEventBatchUpdate(response?.data?.ReturnObj);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: errorMsg,
        type: "error",
        description: response.data?.Errors || response.error,
      });
    }
  };
}

const crmsBatchUpdateEventStoreContext = createContext(
  new CRMSBatchUpdateEventStore()
);
export const useCRMSBatchUpdateEventStore = () => {
  return useContext(crmsBatchUpdateEventStoreContext);
};
