import { APIResponse } from "@common/apis/model";
import { IODataResponse } from "@common/models/odataResponse";

export enum eSearchTitleBy {
  ParcelReference = "Parcel_Reference",
  ParcelId = "Parcel_Id",
}

export enum eTitleSearchKey {
  ParcelReference = "pref",
  ParcelId = "pid",
}

export interface ISearchTitleBy {
  Key: eSearchTitleBy;
  Value: string;
}

export interface IDataSearchTitle {
  SearchBy: {
    Value: ISearchTitleBy;
    Data: ISearchTitleBy[];
  };
  SearchValue: {
    Data: VO_Assessment_Subdivision_Title[];
    Loading: boolean;
  };
}

export interface VO_Assessment_Subdivision_Title {
  Title_Legal_Description: string;
  Title_Lot: string;
  Title_Plan_Type: string;
  Title_Plan_Number: string;
  Title_Land_Area: number | null;
  Title_Type: string;
  Title_Owners_Names: string;
  Title_Id: number;
  Assessment_Id: number | null;
  Parcel_Id: number | null;
  Parcel_Reference: string;
}

export interface ILoadTitleData {
  searchKey: string;
  searchValue: string | number;
  successAction: (data: VO_Assessment_Subdivision_Title[]) => void;
  failedAction: (
    response: APIResponse<
      IODataResponse<VO_Assessment_Subdivision_Title> | undefined
    >
  ) => void;
  loadingAction?: () => void;
}
