import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ACTIONS_ROUTE } from "@common/pages/actions/[id]/constant";

export const getLinkPageNavigate = (recordType: RECORDTYPE) => {
  switch (recordType) {
    case RECORDTYPE.CORE_Action:
      return `${ACTIONS_ROUTE}`;
    case RECORDTYPE.TP_PPRApplication:
      return `${TOWN_PLANNING_PPR_ROUTE}`;
    default:
      return "";
  }
};

export const isHTML = (str: string) => {
  var htmlRegex = new RegExp(/<\/?[a-z][\s\S]*>/i);
  return htmlRegex.test(str);
};
