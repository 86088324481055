import { RebateSummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/components/rebate-summary/_index";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";
import { usePageType } from "@common/hooks/usePageType";
import { colLevySummary } from "@app/products/property/charge-and-notice-runs/charge-runs/components/detail/components/levies-summary/config";
import { DTO_LevySummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/model";

const nameOfLevySummaryGrid = nameOfFactory<DTO_LevySummary>();
export const LeviesSummary = (data: any) => {
  const { isManagePage } = usePageType();

  return (
    <div className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          {isManagePage && <label className="cc-label">Levy summary</label>}
          <CCGrid
            className="cc-charge-run-levies-summary-gird"
            dataUrl={`odata/property/internal/chargerunassessmentlevyregister/GetLevySummary(chargeRunId=${
              data?.dataItem?.Charge_Run_Id ?? data?.data?.Charge_Run_Id
            },assessmentId=${
              data?.dataItem?.Assessment_Id ?? data?.data?.Assessment_Id
            },faid=11)?$count=true&`}
            columnFields={colLevySummary}
            primaryField={nameOfLevySummaryGrid("GroupId")}
            detail={isManagePage ? RebateSummary : undefined}
          />
        </div>
      </div>
    </div>
  );
};
