import { LLPermitsMenu } from "@app/products/local-laws/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const systemAdminRoute: ICCRoute = {
  path: "system-admin",
  name: "System Admin",
  enum: LLPermitsMenu.Administration,
  children: [
    {
      path: "permit-types",
      name: "Permit Types",
      enum: LLPermitsMenu.PermitTypes,
      component: require("./permit-types/_index").default,
    },
    {
      path: "conditions",
      name: "Conditions",
      enum: LLPermitsMenu.Conditions,
      component: require("./conditions/_index").default,
    },
    {
      path: "settings",
      name: "System Settings",
      enum: LLPermitsMenu.Settings,
      component: require("./settings/_index").default,
    },
  ],
};
