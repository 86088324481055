import { getUUID } from "@common/utils/common";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { transformUrlFilterForContains } from "@components/cc-grid/components/grid-loader/util";
import { IColumnFields } from "@components/cc-grid/model";
import { getFilterType, getFirstRowInGroup } from "@components/cc-grid/util";
import { appSearchConfig } from "@components/layout/cc-header/components/app-search/config";
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  State,
  process,
  toODataString,
} from "@progress/kendo-data-query";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
import { ExportFormat, gridDefaultProps } from "../cc-grid/config";
import { ICCNotification } from "../cc-notification/components/cc-notification-item/model";
configure({ enforceActions: "always" }); // Strict mode: runInAction

export default class CCProductListViewStore {
  /* =========================== observable =================*/
  private _notifications: ICCNotification[] = [];
  private _isShowFilterBar: boolean = false;
  private _isShowSlideBar: boolean = false;
  private _gridSelectedIds: any[] = [];
  private _gridTotalSelected?: number;
  private _itemPerPage?: number;
  private _primaryField: string = "ID";
  private _requestUrl?: string;
  private _productListViewId: string = getUUID(); //gridId associated with the ProductListView.

  private _filterBarState?: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };
  private _disableRowField: string = gridDefaultProps.disableRowField;
  private _canSelectField: string = gridDefaultProps.canSelectField;
  private _gridData?: any[] = [];
  private _gridDataState: State = {
    take: this._itemPerPage,
    skip: 0,
    filter: { logic: "and", filters: [] },
    sort: [],
    group: [],
  };
  private _gridColumns: IColumnFields[] = [];
  private _gridSelectedRows: any = [];
  private _gridExportFormat: ExportFormat = ExportFormat.NONE;

  /* =========================== Actions =====================*/
  pushNotifications = (notifications: ICCNotification[]) => {
    runInAction(() => {
      this._notifications = notifications.concat(this._notifications);
    });
  };

  setNotifications = (notifications: ICCNotification[]) => {
    runInAction(() => {
      this._notifications = notifications;
    });
  };
  setIsShowFilterBar = (isShowFilterBar: boolean) => {
    runInAction(() => {
      this._isShowFilterBar = isShowFilterBar;
    });
  };

  setIsShowSlideBar = (isShowSlideBar: boolean) => {
    runInAction(() => {
      this._isShowSlideBar = isShowSlideBar;
      if (isShowSlideBar && !this.lastSelectedRow && this.gridData) {
        const processed = process(
          this.gridData.filter((item) =>
            item[this._disableRowField] ? item[this._canSelectField] : true
          ),
          {
            ...this.gridDataState,
            skip: 0,
          }
        ).data;

        const firstRow = getFirstRowInGroup(
          processed,
          this.gridDataState?.group
        );
        if (firstRow) {
          this._gridSelectedRows = [firstRow];
          this._gridSelectedIds = firstRow?.[this._primaryField]
            ? [firstRow[this._primaryField]]
            : [];
        }
      }
    });
  };
  setFilterBarState = (filter?: CompositeFilterDescriptor) => {
    runInAction(() => {
      this._filterBarState = filter;
      if (!this._isShowFilterBar) {
        this._isShowFilterBar = true;
      }
    });
  };
  setGridData = (gridData?: any[]) => {
    runInAction(() => {
      this._gridData = gridData || [];
    });
  };
  setCanSelectField = (canSelectField?: string) => {
    runInAction(() => {
      this._canSelectField = canSelectField || gridDefaultProps.canSelectField;
    });
  };
  setDisableRowField = (disableRowField?: string) => {
    runInAction(() => {
      this._disableRowField =
        disableRowField || gridDefaultProps.disableRowField;
    });
  };
  setGridDataState = (gridDataState: State | ((dataState: State) => State)) => {
    let newDataState: State = {
      take: this._itemPerPage,
      skip: 0,
      filter: { logic: "and", filters: [] },
      sort: [],
      group: [],
    };

    runInAction(() => {
      if (typeof gridDataState === "function") {
        newDataState = gridDataState({ ...this.gridDataState });
      } else {
        newDataState = { ...gridDataState };
      }
      this._gridDataState = newDataState;
      this._filterBarState = newDataState.filter;
    });
  };

  setGridColumns = (gridColumns: IColumnFields[]) => {
    runInAction(() => {
      this._gridColumns = gridColumns;
    });
  };
  setGridSelectedIds = (gridSelectedIds: any[]) => {
    runInAction(() => {
      this._gridSelectedIds = [...gridSelectedIds];
    });
  };
  setPrimaryField = (primaryField: string) => {
    runInAction(() => {
      this._primaryField = primaryField;
    });
  };
  setGridSelectedRows = (gridSelectedRows: any[]) => {
    runInAction(() => {
      this._gridSelectedRows = [...gridSelectedRows];
      if (gridSelectedRows.length === 0) {
        this._isShowSlideBar = false;
      }
    });
  };
  setGridTotalSelected = (gridTotalSelected: number) => {
    runInAction(() => {
      this._gridTotalSelected = gridTotalSelected;
    });
  };

  setGridExportFormat = (format: ExportFormat) => {
    runInAction(() => {
      this._gridExportFormat = format;
    });
  };
  resetStore = () => {
    runInAction(() => {
      this._notifications = [];
      this._isShowFilterBar = false;
      this._isShowSlideBar = false;

      this._filterBarState = {
        logic: "and",
        filters: [],
      };

      this._gridData = [];
      this._gridDataState = {
        take: this._itemPerPage,
        skip: 0,
        filter: { logic: "and", filters: [] },
        sort: [],
        group: [],
      };
      this._gridColumns = [];
      this._gridSelectedRows = [];
      this._gridSelectedIds = [];
      this._gridExportFormat = ExportFormat.NONE;
    });
  };

  setItemPerPage = (itemPerPage: number) => {
    runInAction(() => {
      this._itemPerPage = itemPerPage;
    });
  };

  clearSelectedItems = () => {
    runInAction(() => {
      this._gridSelectedRows = [];
      this._gridSelectedIds = [];
    });
  };

  /**
   * use on the setting page. Store request URL => Keep state
   */
  setRequestUrl = (url?: string) => {
    runInAction(() => {
      this._requestUrl = url;
    });
  };

  resetGridDataState = () => {
    runInAction(() => {
      this._gridDataState = {
        take: this._itemPerPage,
        skip: 0,
        filter: { logic: "and", filters: [] },
        sort: [],
        group: [],
      };
    });
  };

  setSearchGlobal = (searchOption: string | null) => {
    runInAction(() => {
      let newGridDataState = {};
      if (searchOption && searchOption.trim().length) {
        if (searchOption.length < appSearchConfig.minCharacters) return;
        // get filter query by combine logic or of all gridColumns
        let filterQuery: FilterDescriptor[] = [];
        let numberColumnFilted = 0;
        this.gridColumns.forEach((column) => {
          if (numberColumnFilted >= appSearchConfig.maximumColumnFilted) return;
          if (column.ignoreGlobalFilter) return;
          const columnFilterType = getFilterType(column);
          if (columnFilterType === "text" && !column?.filterByEnum) {
            filterQuery.push({
              field: column.field,
              operator: "contains",
              value: searchOption,
            });
            numberColumnFilted++;
          } else if (columnFilterType === "numeric") {
            !isNaN(Number(searchOption)) &&
              filterQuery.push({
                field: column.field,
                operator: "eq",
                value: parseFloat(searchOption),
              }) &&
              numberColumnFilted++;
          }
        });
        newGridDataState = {
          ...this._gridDataState,
          filter: {
            logic: "or",
            filters: filterQuery,
          },
        };
      } else {
        newGridDataState = {
          ...this._gridDataState,
          filter: {
            logic: "or",
            filters: [],
          },
        };
      }
      const newRequestURL = transformUrlFilterForContains(
        `${toODataString(newGridDataState, {
          utcDates: true,
        })}`
      );

      if (newRequestURL.length > appSearchConfig.maximumLenghtURL) {
        appNotificationStore.pushNotification({
          type: "error",
          title: "Search string length exceeded limit",
          autoClose: false,
        });
        this.setIsShowFilterBar(false);
      } else {
        this.setGridDataState(newGridDataState);
        if (searchOption && searchOption.trim().length) {
          appNotificationStore.clearErrorNotification();
          this.setIsShowFilterBar(true);
        } else {
          this.setIsShowFilterBar(false);
        }
      }
    });
  };
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  /* =========================== computed ===================*/
  get notifications(): ICCNotification[] {
    return toJS(this._notifications);
  }

  get isShowFilterBar(): boolean {
    return toJS(this._isShowFilterBar);
  }
  get isShowSlideBar(): boolean {
    return toJS(this._isShowSlideBar);
  }
  get filterBarState(): CompositeFilterDescriptor | undefined {
    return toJS(this._filterBarState);
  }
  get canSelectField(): string {
    return this._canSelectField;
  }
  get disableRowField(): string {
    return this._disableRowField;
  }
  get gridData(): any[] {
    return this._gridData ? toJS(this._gridData) : [];
  }
  get isEmptyData(): boolean {
    return !this._gridData || toJS(this._gridData).length === 0;
  }

  get gridDataState(): State {
    return toJS(this._gridDataState);
  }

  get primaryField(): string | undefined {
    return toJS(this._primaryField);
  }

  get gridColumns(): IColumnFields[] {
    return toJS(this._gridColumns);
  }
  get gridSelectedIds(): any[] {
    return toJS(this._gridSelectedIds) || [];
  }
  get gridSelectedRows(): any[] {
    return toJS(this._gridSelectedRows) || [];
  }
  get gridTotalSelected(): number {
    return toJS(this._gridTotalSelected) || 0;
  }
  get isEmptyGridSelectedRow(): boolean {
    return toJS(this._gridSelectedRows.length) === 0;
  }

  get lastSelectedRow(): any {
    return toJS(this._gridSelectedRows[this._gridSelectedRows.length - 1]);
  }

  get lastSelectedId(): any {
    return toJS(this._gridSelectedIds[this._gridSelectedIds.length - 1]);
  }

  get gridExportFormat(): ExportFormat {
    return toJS(this._gridExportFormat);
  }

  /**
   * use on the setting page. Store request URL => Keep state
   */
  get requestUrl(): string | undefined {
    return this._requestUrl;
  }

  /**
   * Gets the gridId associated with the ProductListView.
   * @returns {string} The gridId for the ProductListView.
   */
  get productListViewId(): string {
    return this._productListViewId;
  }
}

export const newCCProductListViewStore = new CCProductListViewStore();
export const ccProductListViewStoreContext = createContext(
  newCCProductListViewStore
);
export const useCCProductListViewStore = () => {
  const context = useContext(ccProductListViewStoreContext);
  if (context === undefined) {
    throw new Error(
      "useCCProductListViewStore in not within CCProductListViewStore"
    );
  }
  return context;
};
