import { LocalLaws } from "@app/products/local-laws/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { IResponseData } from "@common/models/service";
import { globalStoreInstance } from "@common/stores/global/store";
import { sleep } from "@common/utils/common";
import { formatDateByKendo } from "@common/utils/formatting";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { LocalLawsUrl, mockLocalLawsAll, mockLocalLawsByID } from "./mock";

export const getAllLocalLaws = () => {
  const locallaws: LocalLaws[] = JSON.parse(JSON.stringify(mockLocalLawsAll));

  locallaws.forEach((item: any) => {
    item.EffectiveFrom = formatDateByKendo(item.EffectiveFrom);
    item.EffectiveTo = formatDateByKendo(item.EffectiveTo);
  });
  return locallaws;
};

export const getLocalLawsById = async (id: string) => {
  // const response: IResponseData = await getAsync(
  //   LocalLawsUrl.GET_LOCALLAWS.replace('{id}', id)
  // );

  // const response: IResponseData = await getAsync(
  //   LocalLawsUrl.GET_LOCALLAWS.replace('{id}', id)
  // );

  return mockLocalLawsByID as any;
};

export const getLocalLaws = async () => {
  const response: IResponseData = await CoreAPIService.getClient().get(
    `${globalStoreInstance.allSetting.baseAPIUrl}${LocalLawsUrl.GET_LOCALLAWS}`
  );

  if (!response) {
    return undefined;
  }

  return response.data;
};

export const getLocalLawsLovs = async () => {
  // const response: IResponseData = await getAsync(LocalLawsUrl.GET_LOCALLAWS_LOVS);

  return mockLocalLawsByID as any;
};

export const postLocalLaws = async (requestBody: LocalLaws) => {
  sleep(2000);
  //TODO: DELETE IT WHEN WE HAVE API IMPLEMENTATION
  // const response: IResponseData = JSON.parse(
  //   JSON.stringify(singleLocalLawsJSON)
  // );

  // // const response = await postAsync(LocalLawsUrl.CREATE_LOCALLAWS, requestBody);
  // history.replace(window.location.pathname, {
  //   status: 'success',
  // });
  // return response.data;
};

export const putLocalLaws = async (requestBody: LocalLaws) => {
  //TODO: DELETE IT WHEN WE HAVE API IMPLEMENTATION
  // const response = await putAsync(
  //   LocalLawsUrl.EDIT_LOCALLAWS.replace(
  //     '{id}',
  //     requestBody.Registration_ID.toString()
  //   ),
  //   requestBody
  // );

  sleep(2000);
};

export const loadLocalLawsRecords = async (id: number, recordType: number) => {
  try {
    // const response = await getAsync(
    //   `/api/LocalLaws/Internal/relatedtotal/${id}/${recordType}`
    // );
    return mockLocalLawsAll as any;
  } catch (error) {
    appNotificationStore.pushNotification({
      title: "Local laws parameters could not be loaded.",
      type: "warning",
    });
  }
};
