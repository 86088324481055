import { requiredValidator } from "@common/utils/field-validators";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const DetailAmalgamatePICFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isLLS: false,
      isShowRegionDropDown: false,
      isToBeApprovalMode: false,
    },
  }: IFormStepElement) => {
    const { valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    return (
      <div className="cc-amalgamate-pic-details-step">
        <section className="cc-field-group">
          {options?.isToBeApprovalMode && (
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Workflow instructions" />
                <Field
                  name={nameOf("_option.Instructions")}
                  rows={3}
                  component={CCTextArea}
                  placeholder="Workflow instructions"
                  disabled
                />
              </div>
            </div>
          )}
        </section>
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <CCValueField
              label="Officer"
              value={getFieldValue("_option.Officer") ?? ""}
            />
            {options?.isLLS ? (
              <>
                {options?.isShowRegionDropDown ? (
                  <div className="cc-field">
                    <CCLabel title="Officer region" />
                    <Field
                      name={nameOf("_option.WD_Assessment_Group_Id")}
                      textField={"Name"}
                      dataItemKey={"Code"}
                      data={
                        getFieldValue("_option.OfficerAssessmentGroups") ?? []
                      }
                      component={CCDropDownList}
                      disabled={options?.isReadOnly}
                      isKeyValueDropdown
                    />
                  </div>
                ) : (
                  <CCValueField
                    label="Officer region"
                    value={
                      getFieldValue("_option.OfficerAssessmentGroups")?.[0]
                        ?.Name ?? ""
                    }
                  />
                )}
              </>
            ) : null}
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title="Reference" isMandatory />
              <Field
                name={nameOf("Reference")}
                placeholder="Reference"
                component={CCInput}
                readOnly={options?.isReadOnly}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Applicant name" isMandatory />
              <Field
                name={nameOf("ApplicantName")}
                placeholder="Applicant name"
                component={CCInput}
                readOnly={options?.isReadOnly}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Reason for change" isMandatory />
              <Field
                name={nameOf("ReasonId")}
                component={CCSearchComboBox}
                data={getFieldValue("_option.ReasonForCreation") ?? []}
                textField="Name"
                dataItemKey="Code"
                isUseDefaultOnchange
                disabled={options?.isReadOnly}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
);
