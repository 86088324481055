import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const RaiseSingleSchemeAccountInterestButton = observer(() => {
  const { currentOrganisationMode } = useCommonProductStore();
  const isActro = currentOrganisationMode(OrganisationMode.ACTRO);
  const isLLS = currentOrganisationMode(OrganisationMode.LLS);
  const isGeneral = currentOrganisationMode(OrganisationMode.General);

  if (isActro || isLLS || isGeneral) return null;
  return <CCNavButton title={"Raise single scheme account interest"} />;
});
