import { Journal } from "@app/core/journal/_index";
import { useNoticesStore } from "@app/core/notices/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const NoticHistoryTab = observer(() => {
  const { notices } = useNoticesStore();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const noticeID: number = params.id
    ? parseInt(params.id)
    : lastSelectedRow?.ID;
  return (
    <Journal
      id={noticeID}
      triggersReload={notices}
      recordType={RECORDTYPE.CORE_Notice}
    />
  );
});
