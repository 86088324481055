export const CRMS_SYSTEM_ADMIN_CATEGORY_URL =
  "odata/crms/internal/systemadmin/GetServiceStandardCategories?$count=true&";

export const CRMS_SYSTEM_ADMIN_LOOKUP_TABLES_URL =
  "odata/crms/internal/systemadmin/GetLookupTables?$count=true&";

export const CRMS_SYSTEM_ADMIN_ROSTER_URL =
  "odata/crms/internal/systemadmin/GetRosters?$count=true&";

export const CRMS_SYSTEM_ADMIN_LOCATION_REGISTER =
  "odata/crms/internal/systemadmin/GetLocationRegister?$count=true&";

export const CRMS_SYSTEM_ADMIN_CATEGORY_ROUTE = "/crms/system-admin/categories";

export const CRMS_SYSTEM_ADMIN_LOCATION_REGISTER_ROUTE =
  "/crms/system-admin/location-register";

export const CRMS_SYSTEM_ADMIN_ROSTER_ROUTE = "/crms/system-admin/rosters";

export const CRMS_SYSTEM_ADMIN_LOOKUP_TABLES_ROUTE =
  "/crms/system-admin/lookup-tables";
