import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { ContactUpdateTriggers } from "@common/models/contact";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import {
  DefaultActionsPage,
  NewSiteUser,
} from "@common/pages/settings/security/site-users/_id/model";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { isEqual, isNil } from "lodash";

export const getBookmarkListViewDisplayName = () => {
  return "Settings - Security";
};

export const getBookmarkListViewDetail = () => {
  return "Settings - Security - Site Users";
};

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `Settings - Security - Site Users ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([selectedRow.ID, selectedRow.DisplayName]);
};

export const getNamesAndValues = (e: any) => {
  return (
    Object.keys(e)
      .map((k) => e[k])
      .filter((v) => typeof v === "string") as string[]
  ).map((n) => ({ name: n, value: e[n] }));
};

export const convertProductListViewEnum = (productList?: any[]) => {
  if (!productList) return [];
  const newProductList = [{ Key: undefined, Value: "Default" }, ...productList];
  return newProductList.map((product: IKeyValuePacket) => {
    return {
      Key:
        getNamesAndValues(PRODUCT_TYPE_NUMBER).find(
          (productEnum) => productEnum.value === product.Key
        )?.name || undefined,
      Value: product.Value,
    };
  });
};

const getKeyDefaultAction = (product: IKeyValuePacket) => {
  switch (product.Key) {
    case DefaultActionsPage.AllActions:
      return "AllActions";
    case DefaultActionsPage.MyActions:
      return "MyActions";
    default:
      return undefined;
  }
};

export const convertDefaultActionPageEnum = (actionPageList?: any[]) => {
  if (!actionPageList) return [];
  const newActionPageList = [
    { Key: undefined, Value: "Default" },
    ...actionPageList,
  ];

  return newActionPageList.map((actionPage: IKeyValuePacket) => {
    return {
      Key: getKeyDefaultAction(actionPage),
      Value: actionPage.Value,
    };
  });
};

export const produceSiteUserSaveTrigger = (
  newSiteUser: NewSiteUser,
  oldSiteUser: NewSiteUser | undefined
): NewSiteUser => {
  if (!oldSiteUser) return newSiteUser;
  if (
    !isNil(oldSiteUser.Contact_SiteUser) &&
    !isNil(newSiteUser.Contact_SiteUser)
  ) {
    newSiteUser.Contact_SiteUser._SaveTriggers = [];

    if (!newSiteUser.Contact_SiteUser.PostalAddress) {
      newSiteUser.Contact_SiteUser._SaveTriggers.push(
        ContactUpdateTriggers.ClearSecondaryAddress
      );
    } else {
      if (
        !isEqual(
          oldSiteUser.Contact_SiteUser.PostalAddress,
          newSiteUser.Contact_SiteUser.PostalAddress
        )
      )
        newSiteUser.Contact_SiteUser._SaveTriggers.push(
          ContactUpdateTriggers.UpdateSecondaryAddress
        );
    }
  }
  return newSiteUser;
};
