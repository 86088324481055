import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { Collection } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { WorkflowGroupButtonsSpatial } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial";
import { IButtonSpatial } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/model";
import { useWorkflowGroupButtonsSpatialStore } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/store";
import {
  getNotificationSpatialWorkflow,
  isDisabledApproveButton,
  isHiddenRejectButton,
  isSpatialGISMode,
} from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/util";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { useAssessmentCollectionStepStore } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/store";
import { collectionUtilProcessing } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/util";
import { CollectionFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/_index";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import {
  getSuffixTitle,
  secondaryWorkflowUtilProcessing,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import {
  INewProcessWorkflow,
  WorkflowApprovalStatus,
} from "@app/products/property/components/action-bar/property-workflow/model";
import {
  getTitleWorkflow,
  isApprovalModeWorkflow,
} from "@app/products/property/components/action-bar/property-workflow/util";
import { DTO_Assessment } from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { DTO_Title } from "@app/products/property/components/dialogs/add-title-lookup/model";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { GISAccordionEventType } from "@app/products/property/components/gis-accordion/constant";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import {
  getInitialDataModifyPIC,
  getWorkflowModifyPIC,
  postModifyPIC,
} from "@app/products/property/pic/list/components/action-bar/form-steps/modify-pic/api";
import { AssociationsFormStep } from "@app/products/property/pic/list/components/action-bar/form-steps/modify-pic/components/form-element/associations/_index";
import { useNewPICDetailStepStoreContext } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/details/store";
import { NewPICDetailFormStep } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/details/_index";
import { useNewSwineBrandDialogStoreContext } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/pic-details/components/dialogs/swine-brands/store";
import { useNewPICDetailPICStepStoreContext } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/pic-details/store";
import { PicDetailsFormStep } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/pic-details/_index";
import {
  DTO_PIC_LOVs,
  DTO_PIC_Swine,
  ELoadingModeWorkflow,
} from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { PROPERTY_PICS_ROUTE } from "@app/products/property/pic/list/constant";
import { PICTabDetailsEventType } from "@app/products/property/pic/[id]/components/reference-sidebar/detail/config";
import { PROPERTY_PIC_ROUTE } from "@app/products/property/pic/[id]/constant";
import { usePicStore } from "@app/products/property/pic/[id]/store";
import {
  convertValueLOVToNumber,
  isShowParkButton,
} from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacket, ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { EBadgeDialogType, IBadgeDialog } from "@components/cc-dialog/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { IStep } from "@components/cc-form-step/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { cloneDeep, head, isNil, isNumber, pickBy } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import {
  DTO_Workflow_PIC_Modify,
  EKeysOfStepsModifyPIC,
  ELabelModifyPIC,
  keysOfStepsModifyPIC,
} from "./model";

interface IModifyPICDialog {
  onClose: () => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  isReadOnly?: boolean;
  statusBadge?: IBadgeDialog[];
  isShowCancelWorkflowButton?: boolean;
  picId?: number;
  statusId?: number;
}
export const ModifyPICDialog = (props: IModifyPICDialog) => {
  const {
    onClose,
    isSaveOnNextStep = false,
    isIncompleteMode = false,
    isFromActionList = false,
    prefixTitle,
    suffixTitle,
    dataFromActionList,
    isReadOnly = false,
    isShowCancelWorkflowButton = false,
    statusBadge,
    picId,
    statusId,
  } = props;
  //#region <Store>
  const { currentFormTitle } = useCommonProductStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { setDataForCancelDialog } = useConfirmCancelDialogStore();
  const { setDataForFinishDialog } = useConfirmFinishDialogStore();
  const { setDataForCloseDialog, setIsLoadingClose } =
    useConfirmCloseDialogStore();
  const { loadPic } = usePicStore();
  const { setLovDetail } = useNewPICDetailStepStoreContext();
  const { setLovPICDetail, lovPICDetail } =
    useNewPICDetailPICStepStoreContext();
  const { setAssessmentCollectionStepLOVs } =
    useAssessmentCollectionStepStore();
  const { setLov } = useNewSwineBrandDialogStoreContext();
  const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
  const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
  const { setDataForRejectDialog } = useConfirmRejectDialogStore();
  const { buttonMapping, buttonFetch, setButtonMapping, setButtonFetch } =
    useWorkflowGroupButtonsSpatialStore();
  //#endregion

  //#region <Local state>
  const history = useHistory();
  const formStepRef = useRef<ICCFormStepNotificationHandle | null>(null);
  const localNotificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [workflowInitData, setWorkflowInitData] =
    useState<DTO_Workflow_PIC_Modify>();
  const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
  const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
  const [isLoadingProcess, setIsLoadingProcess] = useState<
    WorkflowProcessMode | undefined | ELoadingModeWorkflow
  >();
  const [isLoadingStep, setIsLoadingStep] = useState<boolean>(false);
  const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
    WorkflowDraft: { Workflow_Draft_Id: 0 },
    AvailableSecondaryWorkflows: [],
    WorkflowApprovals: [],
  });
  const isToBeApprovalMode = useMemo(() => {
    return isApprovalModeWorkflow(
      workflowHeader?.WorkflowDraft?.WD_Workflow_Approval_Status
    );
  }, [workflowHeader]);
  //#endregion

  //#region <Title header>
  const titleHeader = useMemo(() => {
    const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
    const title = currentFormTitle(formId ?? 0) || "Modify PIC";

    return getTitleWorkflow(
      title,
      prefixTitle,
      getSuffixTitle(
        suffixTitle,
        isToBeApprovalMode,
        workflowHeader?.WorkflowApprovals
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefixTitle, suffixTitle, isToBeApprovalMode, workflowHeader]);
  //#region

  //#region <Initial value>
  const initialValue = useMemo((): any => {
    //step Association
    let initialDetail = {
      ...workflowInitData?.WorkflowDetail?.Details,
      WD_Assessment_Group_Id:
        workflowInitData?.WorkflowHeader?.WorkflowDraft
          ?.WD_Assessment_Group_Id ??
        workflowInitData?.WorkflowHeader?.OfficerAssessmentGroups?.[0]?.Code ??
        null,
      Officer: workflowInitData?.WorkflowHeader?.Officer,
      OfficerAssessmentGroups:
        workflowInitData?.WorkflowHeader?.OfficerAssessmentGroups,
    };

    let initialAssociation: any = {
      ...workflowInitData?.WorkflowDetail?.Association,
    };
    if (!isFromActionList) {
      //Don't auto populate
      initialAssociation.AssociationDate = undefined;
      initialAssociation.Reference = undefined;
      //Check %Area format and set default
      const newFolio =
        initialAssociation?.Folios?.map((item: DTO_Title) => {
          if (
            !(
              isNumber(item?.AssociatedPICAreaSet) &&
              item?.AssociatedPICAreaSet > 0 &&
              item?.AssociatedPICAreaSet <=
                100 - (item?.AssociatedPICAreaTotal ?? 0)
            )
          ) {
            return {
              ...item,
              AssociatedPICAreaSet: 100 - (item?.AssociatedPICAreaTotal ?? 0),
            };
          }
          return item;
        }) ?? [];
      initialAssociation.Folios = newFolio;
    }

    //step PIC detail
    const holdingList =
      workflowInitData?.WorkflowDetail?.Association?.Holdings ?? [];
    let primaryHolding;
    const primaryHoldingNumber =
      workflowInitData?.WorkflowDetail?.Association?.PrimaryHolding;
    if (primaryHoldingNumber) {
      primaryHolding = holdingList?.find(
        (item: DTO_Assessment) => item.Assessment_Id === primaryHoldingNumber
      );
    }
    const tradingName = holdingList?.find(
      (item: DTO_Assessment) =>
        item.Assessment_Id ===
        workflowInitData?.WorkflowDetail?.PICDetails?.TradingNameId
    )?.Ratepayer_Name;
    const gisData =
      workflowInitData?.WorkflowDetail?.PICDetails?.GisReferences?.map(
        (item: DTO_GIS, index: number) => {
          return {
            ...item,
            Id: `exist_${index + 1}`,
          };
        }
      ) ?? [];

    const swineData =
      workflowInitData?.WorkflowDetail?.PICDetails?.Swines?.map(
        (item: DTO_PIC_Swine, index: number) => {
          return {
            ...item,
            Id: `exist_${index + 1}`,
          };
        }
      ) ?? [];

    const picType =
      workflowInitData?.WorkflowDetail?.PICDetails?.PICTypeId === 0
        ? undefined
        : workflowInitData?.WorkflowDetail?.PICDetails?.PICTypeId;
    let initialPICDetail = {
      ...workflowInitData?.WorkflowDetail?.PICDetails,
      PICTypeId: picType,
      TradingNameId: primaryHolding?.Assessment_Id, // TradingNameId == Assessment ID
      _option: {
        PICAddress: primaryHolding?.Assess_Property_Address ?? "",
        RLPBoards: primaryHolding?.RLP_Board_Name ?? "",
      },
      GisReferences: gisData ?? [],
      Swines: swineData ?? [],
      TradingName:
        workflowInitData?.WorkflowDetail?.PICDetails?.TradingName ??
        tradingName,
    };

    //---step Secondary Workflow---
    const initialWorkflow =
      secondaryWorkflowUtilProcessing.processDataInit(workflowInitData);

    return {
      [EKeysOfStepsModifyPIC.PICID]:
        workflowInitData?.WorkflowDetail?.PIC_Id ?? picId ?? 0,
      [EKeysOfStepsModifyPIC.Detail]: initialDetail,
      [EKeysOfStepsModifyPIC.Association]: initialAssociation,
      [EKeysOfStepsModifyPIC.PICDetail]: initialPICDetail,
      [EKeysOfStepsModifyPIC.Collection]:
        collectionUtilProcessing.processDataInit<Collection[]>(
          workflowInitData
        ),
      [EKeysOfStepsModifyPIC.Document]: {},
      [EKeysOfStepsModifyPIC.Comment]: {},
      [EKeysOfStepsModifyPIC.Workflow]: initialWorkflow,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowInitData, isFromActionList, picId]);
  //#region

  //#region <Step>
  const steps: IStep[] = [
    {
      label: ELabelModifyPIC.Detail,
      component: NewPICDetailFormStep,
      visible: true,
      key: EKeysOfStepsModifyPIC.Detail,
      initialValues: initialValue[EKeysOfStepsModifyPIC.Detail],
      options: {
        isReadOnly,
        isDisabled: workflowDraftId,
        isFromModifyPIC: true,
      },
    },
    {
      label: ELabelModifyPIC.Related,
      component: AssociationsFormStep,
      visible: true,
      key: EKeysOfStepsModifyPIC.Association,
      initialValues: initialValue[EKeysOfStepsModifyPIC.Association],
      options: {
        isReadOnly,
        setIsLoadingStep,
      },
    },
    {
      label: ELabelModifyPIC.PICDetail,
      component: PicDetailsFormStep,
      visible: true,
      key: EKeysOfStepsModifyPIC.PICDetail,
      initialValues: initialValue[EKeysOfStepsModifyPIC.PICDetail],
      options: {
        isReadOnly,
        isFromModifyPIC: true,
      },
    },
    {
      label: ELabelModifyPIC.Collection,
      component: CollectionFormStep,
      visible: true,
      key: EKeysOfStepsModifyPIC.Collection,
      initialValues: initialValue[EKeysOfStepsModifyPIC.Collection],
      options: {
        isReadOnly,
      },
    },
    {
      label: ELabelModifyPIC.Comment,
      initialValues: initialValue[EKeysOfStepsModifyPIC.Comment],
      component: CommentsFormStep,
      visible: true,
      key: EKeysOfStepsModifyPIC.Comment,
      customClassName: "cc-comment-step-fixed-height-grid",
      options: {
        isReadOnly,
        workflowDraftId,
        recordType: RECORDTYPE.CommunityProperty_PIC,
      },
    },
    {
      label: ELabelModifyPIC.Document,
      initialValues: initialValue[EKeysOfStepsModifyPIC.Document],
      component: DocumentsFormStep,
      visible: true,
      key: EKeysOfStepsModifyPIC.Document,
      options: {
        isReadOnly,
        workflowDraftId,
        workflowType: WorkflowTypes.PIC_Modify,
      },
    },
    {
      label: "Workflow",
      initialValues: initialValue[EKeysOfStepsModifyPIC.Workflow],
      component: WorkflowStepFormStep,
      visible: true,
      key: EKeysOfStepsModifyPIC.Workflow,
      options: {
        isReadOnly,
        isFromActionList,
        dataFromActionList,
      },
    },
  ];
  //#endregion

  //#region <Handle submit>
  const handleSubmit = async (data: any, buttonId?: string) => {
    //reset notification before go next calling
    formStepRef?.current
      ?.getNotificationFormStep()
      ?.current?.resetNotifications();
    switch (buttonId) {
      case EListSubmitButton.Approve:
        handleApproveProcess(processData(data));
        break;
      case EListSubmitButton.SendBack:
        handleConfirmSendBackProcess(processData(data));
        break;
      case EListSubmitButton.Reallocate:
        handleConfirmReallocateProcess(processData(data));
        break;
      case EListSubmitButton.Reject:
        handleRejectButton(processData(data));
        break;
      case EListSubmitButton.Save:
        await handleSaveAndNext(processData(data));
        break;
      case EListSubmitButton.SaveWorkflow:
        await handleSaveAndNext(processData(data), undefined, true);
        break;
      case EListSubmitButton.Finish:
        handleConfirmFinish(data);
        break;
      case EListSubmitButton.Cancel:
      case EListSubmitButton.ConfirmCloseNo:
        handleCancelButton(processData(data));
        break;
      case EListSubmitButton.ConfirmCloseYes:
        await handleParkProcess(
          processData(data),
          EListSubmitButton.ConfirmCloseYes
        );
        break;
      case EListSubmitButton.Park:
      case EListSubmitButton.Close:
        await handleParkProcess(processData(data));
        break;
      case EListSubmitButton.MappingSpatialWorkflow:
        await handleMappingSpatialProcess(processData(data));
        break;
      case EListSubmitButton.FetchSpatialWorkflow:
        await handleFetchSpatialProcess(processData(data));
        break;
    }
  };
  //#endregion

  /**
   * Handle reject process
   */
  const handleRejectButton = (data: any) => {
    if (isFromActionList || !isFirstSave) {
      setDataForRejectDialog({
        rejectCallback: postModifyPIC,
        dataReject: data,
        defaultSuccessMessage:
          "Create modify PIC application was rejected successfully",
        defaultErrorMessage: "Create modify PIC reject failed",
      });
    } else {
      onClose();
    }
  };

  /**
   * handle confirm send back workflow process
   * @param payload
   */
  const handleConfirmSendBackProcess = (payload: DTO_Workflow_PIC_Modify) => {
    setDataForSendBackDialog({
      sendBackCallback: postModifyPIC,
      dataSendBack: payload,
      defaultSuccessMessage: "Modify PIC approval sent back successfully",
      defaultErrorMessage: "Send modify PIC back failed",
    });
  };

  /**
   * handle confirm reallocate workflow process
   * @param payload
   */
  const handleConfirmReallocateProcess = (payload: DTO_Workflow_PIC_Modify) => {
    setDataForReallocateDialog({
      reallocateCallback: postModifyPIC,
      dataReallocate: payload,
      defaultSuccessMessage: "Modify PIC approved successfully",
      defaultErrorMessage: "Approve modify PIC failed",
    });
  };

  //#region <Process data>
  const processData = (data: any): any => {
    let workflowDetail: any = {};
    const newData = cloneDeep(data);
    const sendSteps = pickBy(newData, (value, key) => {
      if (keysOfStepsModifyPIC.includes(key as EKeysOfStepsModifyPIC)) {
        return { [key]: value };
      }
    });
    for (const [key, value] of Object.entries(sendSteps)) {
      let dataStep;
      if (key === EKeysOfStepsModifyPIC.PICID) {
        dataStep = value;
      } else if (key === EKeysOfStepsModifyPIC.Collection) {
        dataStep = [...(value ?? [])];
      } else {
        dataStep = { ...value };
      }
      if (dataStep && dataStep?._option) {
        delete dataStep._option;
      }
      workflowDetail[key] = dataStep;
      if (key === EKeysOfStepsModifyPIC.Detail) {
        workflowHeader.WorkflowDraft.WD_Assessment_Group_Id =
          value?.WD_Assessment_Group_Id;
      }
      if (key === EKeysOfStepsModifyPIC.PICDetail) {
        const selectedPICTypeName = lovPICDetail?.PICTypes?.find(
          (item) =>
            item.Code === data?.[EKeysOfStepsModifyPIC.PICDetail]?.PICTypeId
        )?.Name;
        const selectedPICLandUseName = lovPICDetail?.PICLandUses?.find(
          (item) =>
            item.Code === data?.[EKeysOfStepsModifyPIC.PICDetail]?.PICLandUseId
        )?.Name;
        const selectedRLPBoardName =
          data?.[EKeysOfStepsModifyPIC.PICDetail]?._option?.RLPBoards ?? "";
        const selectedDistrictName = lovPICDetail?.Districts?.find(
          (item) =>
            item.Code === data?.[EKeysOfStepsModifyPIC.PICDetail]?.DistrictId
        )?.Name;
        let rpbBoardId;
        if (!isNil(data?.[EKeysOfStepsModifyPIC.Association]?.PrimaryHolding)) {
          rpbBoardId = data?.[
            EKeysOfStepsModifyPIC.Association
          ]?.Holdings?.find(
            (item: DTO_Assessment) =>
              item.Assessment_Id ===
              data?.[EKeysOfStepsModifyPIC.Association]?.PrimaryHolding
          )?.RLP_Board_Id;
        }
        workflowDetail = {
          ...workflowDetail,
          [EKeysOfStepsModifyPIC.PICDetail]: {
            ...workflowDetail?.[EKeysOfStepsModifyPIC.PICDetail],
            PICTypeName: selectedPICTypeName,
            PICLandUseName: selectedPICLandUseName,
            RLPBoardName: selectedRLPBoardName,
            DistrictName: selectedDistrictName,
            RLPBoardId: rpbBoardId,
          },
        };
      }
      if (key === EKeysOfStepsModifyPIC.Workflow) {
        secondaryWorkflowUtilProcessing.processData(
          value,
          workflowHeader,
          workflowDetail
        );
      }
    }
    return {
      WorkflowHeader: workflowHeader,
      WorkflowDetail: workflowDetail,
    };
  };
  //#endregion

  //#region <Handle process workflow>
  /**
   * common function
   * handle calling api with multiple process
   * @param props
   */
  const handleProcessWorkflow = async (
    props: INewProcessWorkflow<DTO_Workflow_PIC_Modify>
  ) => {
    const {
      actionSuccess,
      defaultFailedMessage,
      setLoading,
      modeProcess,
      payload,
    } = props;
    const response = await postModifyPIC(modeProcess, payload);
    setLoading();
    if (isSuccessResponse(response)) {
      if (response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        if (props?.actionFail) props?.actionFail(response);
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    } else {
      if (props?.actionFail) props?.actionFail(response);
      formStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response?.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
    if (props?.actionClose) props.actionClose();
  };
  //#endregion

  //#region <Handle next button>
  /**
   * @param data
   * @param step
   * @param keyStep
   * @returns
   */
  const handleNextButton = async (data: any, step: any, keyStep: string) => {
    const newData = { ...data };
    const processPayload = processData(newData);
    //send data to call api save
    return handleSaveAndNext(processPayload, keyStep);
  };
  //#endregion

  /**
   * Refresh workflow data
   * @param workflowDraftId
   */
  const refreshWorkflowData = async (workflowDraftId: number) => {
    const response = await getWorkflowModifyPIC(workflowDraftId);
    if (isSuccessResponse(response) && response?.data) {
      setWorkflowInitData({
        WorkflowDetail: response.data?.WorkflowDetail,
        WorkflowHeader: response.data?.WorkflowHeader,
      });
    } else {
      formStepRef?.current?.setLoadFailedFormStep({
        onReload: () => refreshWorkflowData(workflowDraftId),
        responseError: {
          status: response.status,
          error: response.error ?? "Load workflow failed",
        },
      });
    }
  };

  //#region <Handle save and next>
  const handleSaveAndNext = async (
    payload: DTO_Workflow_PIC_Modify,
    keyStep?: string,
    isRefreshWorkflowData: boolean = false
  ): Promise<boolean> => {
    //check condition use for Save button
    setIsLoadingProcess(WorkflowProcessMode.Save);
    //Calling process Save at next button
    const response = await postModifyPIC(WorkflowProcessMode.Save, payload);
    setIsLoadingProcess(undefined);
    //set default notification
    const defaultSuccessMessage = "Modify PIC was saved successfully.";
    const defaultFailedMessage = "Modify PIC could not be saved.";
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      if (!isSaveOnNextStep) {
        onClose();
        pushNotification({
          title:
            response?.data?.Notification ??
            response?.data?.SuccessMessage ??
            defaultSuccessMessage,
          type: "success",
        });
      } else {
        // check is the first saving
        if (isFirstSave) {
          setIsFirstSave(false);
          const workflowDraftId = response?.data?.ID ?? 0;
          //set current workflowDraft Id
          setWorkflowDraftId(workflowDraftId);
          // set payload to send
          setWorkflowHeader({
            ...workflowHeader,
            WorkflowDraft: {
              ...workflowHeader.WorkflowDraft,
              Workflow_Draft_Id: workflowDraftId,
            },
          });
          if (keyStep === EKeysOfStepsModifyPIC.Detail) {
            refreshWorkflowData(workflowDraftId);
          }
        }
      }
      // TODO: Show notification after reloading the step -> enhance later
      if (isRefreshWorkflowData) {
        getWorkflowData().then(() => {
          formStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response?.data?.Notification ?? defaultSuccessMessage,
              type: "success",
            });
        });
      }
      setIsLoadingProcess(undefined);
      return true;
    } else {
      const showNotification = () => {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title:
              (isRefreshWorkflowData
                ? head(response?.data?.Errors)
                : response.data?.ErrorMessage) ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      };
      if (isRefreshWorkflowData) {
        getWorkflowData().then(showNotification);
      } else {
        showNotification();
      }
      setIsLoadingProcess(undefined);
      return false;
    }
  };
  //#endregion

  //#region <Handle finish process>
  /**
   * @param payload
   */
  const handleFinishProcess = async (payload: DTO_Workflow_PIC_Modify) => {
    //set loading button
    setIsLoadingProcess(WorkflowProcessMode.Finish);
    //props send to process workflow
    const finishProps: INewProcessWorkflow<DTO_Workflow_PIC_Modify> = {
      payload: payload,
      actionSuccess: (e) => {
        const notificationContent: IAppNotificationItemAddProps = {
          title:
            e?.SuccessMessage ??
            e?.Notification ??
            "PIC was modified successfully.",
          type: "success",
        };
        if (
          workflowInitData?.WorkflowHeader?.WorkflowApprovals?.length === 0 &&
          !isNil(e?.Component_ID)
        ) {
          history.push(`${PROPERTY_PICS_ROUTE}/${e?.Component_ID}`, {
            notification: notificationContent,
          });
        } else {
          if (picId) {
            //PIC Manage page
            if (
              history?.location?.pathname === `${PROPERTY_PIC_ROUTE}/${picId}`
            ) {
              loadPic(picId, notificationContent);
              //Refresh GIS Reference accordion if the workflow is auto approved
              if (
                e?.WorkflowApprovalStatus === WorkflowApprovalStatus.Approved
              ) {
                eventEmitter.emit(GISAccordionEventType.reloadGISReferenceGrid);
              }
            } else {
              pushNotification(notificationContent);
            }
            //PIC Manage page and List view
            eventEmitter.emit(PICTabDetailsEventType.RefreshData, picId);
          }
        }
        onClose();
      },
      setLoading: () => {
        setIsLoadingProcess(undefined);
      },
      defaultFailedMessage: "PIC could not be created.",
      modeProcess: WorkflowProcessMode.Finish,
    };
    //calling api process workflow
    await handleProcessWorkflow(finishProps);
  };
  //#endregion

  //#region <Handle confirm finish>
  const handleConfirmFinish = (payload: DTO_Workflow_PIC_Modify) => {
    const dataProcessed = processData(payload);
    const finishCallback = function async() {
      return handleFinishProcess(dataProcessed);
    };
    setDataForFinishDialog({
      finishCallback,
      confirmMessage:
        "The PIC will be modified based on the information provided. Are you sure you want to submit?",
    });
  };
  //#endregion

  //#region <Handle park process>
  /**
   * @param payload
   * @param mode
   */
  const handleParkProcess = async (
    payload: DTO_Workflow_PIC_Modify,
    mode?: EListSubmitButton
  ) => {
    //set loading button and dialog
    setIsLoadingProcess(WorkflowProcessMode.Park);
    mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
    const actionCloseRetainDialog = () => {
      setIsLoadingClose(false);
      setDataForCloseDialog();
    };
    //props send to process workflow
    const parkProps: INewProcessWorkflow<DTO_Workflow_PIC_Modify> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        pushNotification({
          title:
            e?.Notification ??
            e?.SuccessMessage ??
            "Modify PIC was parked successfully.",
          type: "success",
        });
        isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
      },
      setLoading: () => {
        setIsLoadingProcess(undefined);
      },
      actionClose: () => {
        mode === EListSubmitButton.ConfirmCloseYes && actionCloseRetainDialog();
      },
      defaultFailedMessage: "Modify PIC could not be parked.",
      modeProcess: WorkflowProcessMode.Park,
    };
    //calling api process workflow
    await handleProcessWorkflow(parkProps);
  };
  //#endregion

  //#region <Handle Approve process>
  /**
   * @param payload
   * @param mode
   */
  const handleApproveProcess = async (payload: DTO_Workflow_PIC_Modify) => {
    //set loading button and dialog
    setIsLoadingProcess(WorkflowProcessMode.Approve);

    //props send to process workflow
    const parkProps: INewProcessWorkflow<DTO_Workflow_PIC_Modify> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        pushNotification({
          title:
            e?.Notification ??
            e?.SuccessMessage ??
            "Modify PIC was approved successfully.",
          type: "success",
        });
        isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
      },
      setLoading: () => {
        setIsLoadingProcess(undefined);
      },
      defaultFailedMessage: "Modify PIC could not be approved.",
      modeProcess: WorkflowProcessMode.Approve,
    };
    //calling api process workflow
    await handleProcessWorkflow(parkProps);
  };
  //#endregion

  //#region <handle cancel button>
  const handleCancelButton = (data: DTO_Workflow_PIC_Modify) => {
    if (isFromActionList || !isFirstSave) {
      setDataForCancelDialog({
        cancelAPI: postModifyPIC,
        dataCancel: data,
        defaultSuccessMessage: "Modify PIC was cancelled successfully",
        defaultErrorMessage: "Modify PIC could not be cancelled.",
      });
    } else {
      onClose();
    }
  };
  //#endregion

  //#region <Handle close dialog>
  /**
   * @param renderProps
   */
  const handleCloseDialog = (renderProps: ICCFormStepRender) => {
    if (!isFromActionList && !isFirstSave) {
      //Store submit event
      setDataForCloseDialog({
        closeCallback: renderProps.submitButton.onClick,
      });
    } else if (
      isIncompleteMode &&
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
    ) {
      onClose();
    } else if (
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Incomplete &&
      !isFirstSave
    ) {
      const newEvent = {
        currentTarget: { id: EListSubmitButton.Close },
      };
      renderProps.submitButton.onClick(newEvent);
    } else {
      onClose();
    }
  };
  //#endregion

  /**
   * handle mapping button at Spatial
   * Call 2 apis (Process, Get)
   * @param payload
   */
  const handleMappingSpatialProcess = async (
    payload: DTO_Workflow_PIC_Modify
  ) => {
    //set loading dialog
    setIsLoadingProcess(ELoadingModeWorkflow.ActionLoading);
    //set info mapping
    setButtonMapping((preState: IButtonSpatial) => ({
      ...preState,
      isLoading: true,
    }));
    let mappingInfo: any = {};
    //Calling api process
    const responsePost = await postModifyPIC(
      WorkflowProcessMode.Mapping,
      payload
    );
    if (isSuccessResponse(responsePost)) {
      const resSuccessPost =
        responsePost?.data as ResponseMessageWorkflow<DTO_Workflow_PIC_Modify>;
      if (resSuccessPost?.IsSuccess) {
        //open new tab spatial
        window.open(responsePost?.data?.Gis_Mapping_Launch_Url, "_blank");
        mappingInfo = {
          isLoading: false,
          loadSuccess: undefined,
          loadFail: undefined,
        };
        if (resSuccessPost?.Notification?.length) {
          mappingInfo.loadSuccess = {
            message: {
              messageTitle: resSuccessPost?.Notification,
            },
            type: EBadgeDialogType.SUCCESS,
          };
        }
        setWorkflowHeader(resSuccessPost?.ReturnData?.WorkflowHeader);
        setWorkflowInitData({
          WorkflowDetail: resSuccessPost?.ReturnData?.WorkflowDetail,
          WorkflowHeader: resSuccessPost?.ReturnData?.WorkflowHeader,
        });
      } else {
        mappingInfo = {
          isLoading: false,
          loadSuccess: undefined,
          loadFail: undefined,
        };
        if (resSuccessPost?.Notification?.length) {
          mappingInfo.loadFail = {
            message: {
              messageTitle: resSuccessPost?.Notification,
            },
            type: EBadgeDialogType.ERROR,
          };
        }
        if (resSuccessPost?.ErrorMessage?.length) {
          formStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: resSuccessPost?.Notification?.length
                ? resSuccessPost?.Notification
                : "Mapping data failed.",
              description: resSuccessPost?.ErrorMessage,
              type: "error",
              autoClose: false,
            });
        }
      }
    } else {
      //TODO: fix type for temporary
      const responseFail = responsePost?.data as any as IdentityPacket;
      mappingInfo = {
        isLoading: false,
        loadSuccess: undefined,
        loadFail: undefined,
      };
      if (responseFail?.Errors?.length) {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: "Mapping data failed.",
            description: head(responseFail?.Errors),
            type: "error",
            autoClose: false,
          });
      }
    }
    setButtonMapping((preState: IButtonSpatial) => ({
      ...preState,
      ...mappingInfo,
    }));
    setIsLoadingProcess(undefined);
  };

  const handleFetchSpatialProcess = async (
    payload: DTO_Workflow_PIC_Modify
  ) => {
    //set loading dialog
    setIsLoadingProcess(ELoadingModeWorkflow.ActionLoading);
    //set info fetch
    setButtonFetch((preState: IButtonSpatial) => ({
      ...preState,
      isLoading: true,
      loadFail: undefined,
      loadSuccess: undefined,
    }));
    let fetchInfo: any = {};
    const responsePost = await postModifyPIC(
      WorkflowProcessMode.Fetch,
      payload
    );
    if (isSuccessResponse(responsePost)) {
      const resSuccessPost =
        responsePost?.data as ResponseMessageWorkflow<DTO_Workflow_PIC_Modify>;
      if (resSuccessPost?.IsSuccess) {
        fetchInfo = {
          isLoading: false,
          loadSuccess: undefined,
          loadFail: undefined,
        };
        if (resSuccessPost?.Notification?.length) {
          fetchInfo.loadSuccess = {
            message: {
              messageTitle: resSuccessPost?.Notification,
            },
            type: EBadgeDialogType.SUCCESS,
          };
        }
        setWorkflowHeader(resSuccessPost?.ReturnData?.WorkflowHeader);
        setWorkflowInitData({
          WorkflowDetail: resSuccessPost?.ReturnData?.WorkflowDetail,
          WorkflowHeader: resSuccessPost?.ReturnData?.WorkflowHeader,
        });
      } else {
        fetchInfo = {
          isLoading: false,
          loadSuccess: undefined,
          loadFail: undefined,
        };
        if (resSuccessPost?.Notification?.length) {
          fetchInfo.loadFail = {
            message: {
              messageTitle: resSuccessPost?.Notification,
            },
            type: EBadgeDialogType.ERROR,
          };
        }
        if (resSuccessPost?.ErrorMessage?.length) {
          formStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: resSuccessPost?.Notification?.length
                ? resSuccessPost?.Notification
                : "Fetch data failed.",
              description: resSuccessPost?.ErrorMessage,
              type: "error",
              autoClose: false,
            });
        }
      }
    } else {
      //TODO: fix type for temporary
      const resFailPost = responsePost?.data as any as IdentityPacket;
      fetchInfo = {
        isLoading: false,
        loadSuccess: undefined,
        loadFail: undefined,
      };
      if (resFailPost?.Errors?.length) {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: "Fetch data failed.",
            description: head(resFailPost?.Errors),
            type: "error",
            autoClose: false,
          });
      }
    }
    setButtonFetch((preState: IButtonSpatial) => ({
      ...preState,
      ...fetchInfo,
    }));
    setIsLoadingProcess(undefined);
  };

  //#region <Get workflow data>
  /**
   * load initValue for FormStep
   * call once time
   */
  const getWorkflowData = async () => {
    const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
    formStepRef?.current?.setLoadingFormStep(true);
    const response = await getInitialDataModifyPIC(
      WorkflowTypes.PIC_Modify,
      workflowDraftId,
      picId,
      statusId
    );
    if (Array.isArray(response)) {
      const [lovsPIC, workflowData] = response;
      if (
        isSuccessResponse(lovsPIC) &&
        isSuccessResponse(workflowData) &&
        lovsPIC?.data &&
        workflowData?.data
      ) {
        const lovsPic = lovsPIC?.data;
        // set Lov Association step
        setLovDetail({
          PICStatus: convertValueLOVToNumber(lovsPic?.PICStatus ?? [], "Code"),
          ReasonForCreate:
            convertValueLOVToNumber(lovsPic?.ReasonForCreate ?? [], "Code") ??
            [],
        });
        //set Lov PIC detail step
        setLovPICDetail({
          PICTypes:
            convertValueLOVToNumber(lovsPic?.PICTypes ?? [], "Code") ?? [],
          PICLandUses:
            convertValueLOVToNumber(lovsPic?.PICLandUses ?? [], "Code") ?? [],
          Districts:
            convertValueLOVToNumber(lovsPic?.Districts ?? [], "Code") ?? [],
          GISType: lovsPic?.GISType ?? [],
        });

        setWorkflowInitData(workflowData?.data);
        if (workflowData.data?.WorkflowHeader) {
          //Get notification for Spatial
          const notificationSpatial = getNotificationSpatialWorkflow(
            workflowData.data?.WorkflowHeader
          );
          if (!isNil(notificationSpatial)) {
            if (notificationSpatial?.mapping) {
              setButtonMapping((preState: IButtonSpatial) => ({
                ...preState,
                ...notificationSpatial?.mapping,
              }));
            }
            if (notificationSpatial?.fetch) {
              setButtonFetch((preState: IButtonSpatial) => ({
                ...preState,
                ...notificationSpatial?.fetch,
              }));
            }
          }
          setWorkflowHeader(workflowData.data?.WorkflowHeader);
          setWorkflowDraftId(
            workflowData.data?.WorkflowHeader?.WorkflowDraft
              ?.Workflow_Draft_Id ?? 0
          );
        }
        //set Collection Type for Collection
        setAssessmentCollectionStepLOVs({
          Collection_Type: lovsPic?.CollectionTypes,
        });
        //set Swine Type
        setLov({
          SwineTypes:
            convertValueLOVToNumber(lovsPic?.SwineTypes ?? [], "Code") ?? [],
        });
        formStepRef?.current?.setLoadingFormStep(false);
      } else {
        let responseError: APIResponse<
          DTO_PIC_LOVs | DTO_Workflow_PIC_Modify | ResponsePacket
        > = response[0];
        if (!isSuccessResponse(response[1])) {
          responseError = response[1];
        }
        formStepRef?.current?.setLoadingFormStep(false);
        formStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error:
              (responseError.data as ResponsePacket)?.Errors ??
              "Load workflow failed",
          },
        });
      }
    } else {
      const responseError = response as APIResponse;
      formStepRef?.current?.setLoadingFormStep(false);
      formStepRef?.current?.setLoadFailedFormStep({
        onReload: () => getWorkflowData(),
        responseError: {
          status: responseError.status,
          error: "Load workflow failed",
        },
      });
    }
  };
  //#endregion

  //#region <Use Effect calling initial data>
  useEffectOnce(() => {
    getWorkflowData();
  });
  //#region

  const newBadge = useMemo(() => {
    if (!isNil(statusBadge)) return statusBadge;
    let newStatus: undefined | IBadgeDialog[];
    if (isSpatialGISMode(workflowHeader)) {
      newStatus = [];
      if (
        buttonMapping?.loadSuccess &&
        buttonMapping?.loadSuccess?.message?.messageTitle
      ) {
        newStatus.push({
          content:
            buttonMapping?.loadSuccess?.message?.messageTitle ??
            "Mapping in progress",
        });
      } else if (
        buttonMapping?.loadFail &&
        buttonMapping?.loadFail?.message?.messageTitle
      ) {
        newStatus.push({
          content:
            buttonMapping?.loadFail?.message?.messageTitle ??
            "Mapping in failed",
          type: EBadgeDialogType.ERROR,
        });
      }
      if (
        buttonFetch?.loadSuccess &&
        buttonFetch?.loadSuccess?.message?.messageTitle
      )
        newStatus.push({
          content:
            buttonFetch?.loadSuccess?.message?.messageTitle ??
            "Fetch data successful",
          type: EBadgeDialogType.SUCCESS,
        });
      else if (
        buttonFetch?.loadFail &&
        buttonFetch?.loadFail?.message?.messageTitle
      )
        newStatus.push({
          content:
            buttonFetch?.loadFail?.message?.messageTitle ?? "Fetch data failed",
          type: EBadgeDialogType.ERROR,
        });
    }
    return newStatus;
  }, [statusBadge, workflowHeader, buttonMapping, buttonFetch]);

  return (
    <>
      <CCFormStep
        ref={formStepRef}
        onSubmit={handleSubmit}
        localNotificationRef={localNotificationRef?.current ?? null}
        listButtonId={listSubmitButton}
        initialSteps={steps}
        initialValues={initialValue}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            titleHeader={titleHeader}
            onClose={() => handleCloseDialog(renderProps)}
            bodyElement={renderProps.children}
            badge={newBadge}
            disabled={isLoadingProcess !== undefined || isLoadingStep}
            footerElement={
              <>
                <div className={"cc-dialog-footer-actions-right"}>
                  {/* Park button */}
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Park
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled ||
                        isLoadingProcess === WorkflowProcessMode.Park
                      }
                    >
                      Park
                    </Button>
                  )}
                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      disabled={!isNil(isLoadingProcess)}
                      id={EListSubmitButton.Cancel}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}
                  {isToBeApprovalMode && workflowHeader?.OfficerCanApprove && (
                    <>
                      {/* Send Back button */}
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.SendBack}
                        disabled={
                          isLoadingProcess === WorkflowProcessMode.SendBack ||
                          renderProps.nextButton.disabled
                        }
                        className={"cc-dialog-button"}
                        iconClass={
                          isLoadingProcess === WorkflowProcessMode.SendBack
                            ? "fas fa-spinner fa-spin"
                            : ""
                        }
                        onClick={renderProps.submitButton.onClick}
                      >
                        Send Back
                      </Button>
                      {/* Reallocate button */}
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.Reallocate}
                        disabled={
                          isLoadingProcess === WorkflowProcessMode.Reallocate ||
                          renderProps.nextButton.disabled
                        }
                        className={"cc-dialog-button"}
                        iconClass={
                          isLoadingProcess === WorkflowProcessMode.Reallocate
                            ? "fas fa-spinner fa-spin"
                            : ""
                        }
                        onClick={renderProps.submitButton.onClick}
                      >
                        Reallocate
                      </Button>
                      {/* Approve button */}
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.Approve}
                        disabled={
                          isLoadingProcess === WorkflowProcessMode.Approve ||
                          renderProps.nextButton.disabled ||
                          isDisabledApproveButton(workflowHeader)
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.submitButton.onClick}
                        iconClass={
                          isLoadingProcess === WorkflowProcessMode.Approve
                            ? "fas fa-spinner fa-spin"
                            : ""
                        }
                      >
                        Approve
                      </Button>
                      {/* Reject button */}
                      {isHiddenRejectButton(workflowHeader) ? null : (
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Reject}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Reject
                        </Button>
                      )}
                    </>
                  )}
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}
                  {(isToBeApprovalMode || isReadOnly) &&
                  renderProps.isLastStep ? null : (
                    <Button
                      themeColor="primary"
                      id={
                        !renderProps.isLastStep &&
                        (isToBeApprovalMode || isReadOnly)
                          ? "cc-next-step-button"
                          : renderProps.nextButton.idButton
                      }
                      disabled={
                        isLoadingProcess === WorkflowProcessMode.Save ||
                        renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Save
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingProcess === WorkflowProcessMode.Save
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
                <div className="cc-dialog-footer-actions-left">
                  <WorkflowGroupButtonsSpatial
                    workflowHeader={workflowHeader}
                    actionSubmit={renderProps.nextButton.onClick}
                  />
                </div>
              </>
            }
          />
        )}
      />
    </>
  );
};
