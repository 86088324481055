import { loadViewConfiguresColumns } from "@app/products/property/api";
import { formatDynamicValuationsCol } from "@app/products/property/assessments/[id]/components/child-screens/valuations/util";
import { ViewConfiguration } from "@app/products/property/model";
import { VO_Supplementary_Valuation } from "@app/products/property/supplementary-rates/[id]/components/child-screens/valuations/model";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Checkbox } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { colValuation } from "./config";
import { ValuationsDetails } from "./details/_index";

const nameOf = nameOfFactory<VO_Supplementary_Valuation>();
export const SupplementaryRatesValuations = observer(() => {
  const { supplementaryRatesId } = useSupplementaryRatesStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [onlyShowChangedValuations, setOnlyShowChangedValuations] =
    useState<boolean>(false);

  const [
    colsSupplementaryValuationsConfig,
    setColsSupplementaryValuationsConfig,
  ] = useState<IColumnFields[]>([]);

  const loadSupplementaryValuationsViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Supplementary_Valuations_OData,
      colValuation,
      {
        dynamicFormat: formatDynamicValuationsCol,
      }
    );
    if (Array.isArray(response)) {
      setColsSupplementaryValuationsConfig(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadSupplementaryValuationsViewConfig();
    // eslint-disable-next-line
  }, [supplementaryRatesId]);

  return (
    <div className="cc-supplementary-rates-valuations">
      <div className="cc-grid-control-between">
        <div className="cc-grid-control-left">
          <Checkbox
            title="Only show valuations that have changed in supplementary"
            label="Only show valuations that have changed in supplementary"
            className="cc-checkbox"
            checked={onlyShowChangedValuations}
            onChange={() => {
              setOnlyShowChangedValuations(!onlyShowChangedValuations);
            }}
            disabled={isLoading}
          />
        </div>
        <div className="cc-grid-control-right"></div>
      </div>
      <CCLabel title="Current levies" />
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadSupplementaryValuationsViewConfig();
          }}
        />
      ) : (
        <CCGrid
          dataUrl={`odata/property/internal/supplementaryratesvaluationsodataregister/${supplementaryRatesId}?${
            onlyShowChangedValuations ? "changes=true&" : ""
          }$count=true&`}
          columnFields={colsSupplementaryValuationsConfig}
          primaryField={nameOf("RowId")}
          detail={ValuationsDetails}
        />
      )}
    </div>
  );
});
