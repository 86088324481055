import { VO_Supplementary_Levy_Calculation } from "@app/products/property/supplementary-rates/[id]/components/child-screens/charges/model";
import { getLevyCalculationGridData } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/levy-calculation/api";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleCurrency } from "@common/utils/formatting";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface IHeading {
  heading: string;
  total: string;
}

export const LevyCalculationFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const Heading = ({ heading, total }: IHeading) => {
  return (
    <div className="cc-levy-heading">
      <label className="cc-label">{heading}</label>
      <label className="cc-label" style={formatStyleCurrency(+total)}>
        ${total}
      </label>
    </div>
  );
};

const nameOfLevyCalculation =
  nameOfFactory<VO_Supplementary_Levy_Calculation>();
const FormStepElement = observer(
  ({
    isLoadingStep,
    setIsLoadingStep = () => {},
    loadFailedStep,
    setLoadFailedStep = () => {},
  }: IFormStepElement) => {
    //Use store
    const { supplementaryAssessmentId } = useSupplementaryRatesStore();

    const total = (data: VO_Supplementary_Levy_Calculation[]) => {
      return data.reduce(
        (acc: number, curr: VO_Supplementary_Levy_Calculation) => {
          return acc + (curr?.SLC_Old_X_Base_Amount ?? 0);
        },
        0
      );
    };

    //Use state
    const [oldChargesCol, setOldChargesCol] = useState<IColumnFields[]>([]);
    const [newChargesCol, setNewChargesCol] = useState<IColumnFields[]>([]);
    const [adjustmentsCol, setAdjustmentsCol] = useState<IColumnFields[]>([]);
    const [oldChargesData, setOldChargesData] = useState<
      VO_Supplementary_Levy_Calculation[]
    >([]);
    const [newChargesData, setNewChargesData] = useState<
      VO_Supplementary_Levy_Calculation[]
    >([]);
    const [adjustmentsData, setAdjustmentsData] = useState<
      VO_Supplementary_Levy_Calculation[]
    >([]);

    const loadLevyCalculation = async () => {
      setIsLoadingStep(true);
      const response = await getLevyCalculationGridData(
        supplementaryAssessmentId
      );
      setIsLoadingStep(false);
      if (Array.isArray(response)) {
        const [
          responseOldChargesViewConfig,
          responseNewChargesViewConfig,
          responseAdjustmentsViewConfig,
          responseOldChargesOData,
          responseNewChargesOData,
          responseAdjustmentsOData,
        ] = response;
        const dataOldChargesOData = responseOldChargesOData?.data?.value;
        const dataNewChargesOData = responseNewChargesOData?.data?.value;
        const dataAdjustmentsOData = responseAdjustmentsOData?.data?.value;
        if (
          Array.isArray(responseOldChargesViewConfig) &&
          Array.isArray(responseNewChargesViewConfig) &&
          Array.isArray(responseAdjustmentsViewConfig) &&
          isSuccessResponse(responseOldChargesOData) &&
          isSuccessResponse(responseNewChargesOData) &&
          isSuccessResponse(responseAdjustmentsOData) &&
          dataOldChargesOData &&
          dataNewChargesOData &&
          dataAdjustmentsOData
        ) {
          setOldChargesCol(responseOldChargesViewConfig);
          setNewChargesCol(responseNewChargesViewConfig);
          setAdjustmentsCol(responseAdjustmentsViewConfig);
          setOldChargesData(dataOldChargesOData);
          setNewChargesData(dataNewChargesOData);
          setAdjustmentsData(dataAdjustmentsOData);
        } else {
          let responseError = responseOldChargesViewConfig as APIResponseError;
          switch (true) {
            case !Array.isArray(responseNewChargesViewConfig):
              responseError = responseNewChargesViewConfig as APIResponseError;
              break;
            case !Array.isArray(responseAdjustmentsViewConfig):
              responseError = responseAdjustmentsViewConfig as APIResponseError;
              break;
            case !isSuccessResponse(responseOldChargesOData):
              responseError = responseOldChargesOData;
              break;
            case !isSuccessResponse(responseNewChargesOData):
              responseError = responseNewChargesOData;
              break;
            case !isSuccessResponse(responseAdjustmentsOData):
              responseError = responseAdjustmentsOData;
              break;
          }
          setLoadFailedStep({
            onReload: () => {
              loadLevyCalculation();
            },
            responseError: {
              status: responseError.status,
              error: responseError.error ?? "Load failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        setLoadFailedStep({
          onReload: () => {
            loadLevyCalculation();
          },
          responseError: {
            status: responseError.status,
            error: responseError.error ?? "Load failed",
          },
        });
      }
    };

    useEffectOnce(() => {
      loadLevyCalculation();
    });

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }

    if (loadFailedStep) {
      return (
        <CCLoadFailed
          onReload={loadFailedStep?.onReload}
          responseError={loadFailedStep?.responseError}
        />
      );
    }

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <Heading
              heading="Charges based on previous levies & valuations"
              total={"" + total(oldChargesData)}
            />
            <CCGrid
              data={oldChargesData}
              columnFields={oldChargesCol}
              primaryField={nameOfLevyCalculation("Supplementary_Levy_Calc_Id")}
            />
          </div>
          <div className="cc-field">
            <Heading
              heading="Charges based on new levies & valuations"
              total={"" + total(newChargesData)}
            />
            <CCGrid
              data={newChargesData}
              columnFields={newChargesCol}
              primaryField={nameOfLevyCalculation("Supplementary_Levy_Calc_Id")}
            />
          </div>
          <div className="cc-field">
            <Heading
              heading="Supplementary levy adjustments"
              total={"" + total(adjustmentsData)}
            />
            <CCGrid
              data={adjustmentsData}
              columnFields={adjustmentsCol}
              primaryField={nameOfLevyCalculation("Supplementary_Levy_Calc_Id")}
            />
          </div>
        </div>
      </section>
    );
  }
);
