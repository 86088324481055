import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { EKeysOfSteps } from "@app/products/property/components/action-bar/dialog/new-debt-recovery/model";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WhichJournalFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/_index";
import { JournalMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { useDeferredDutyTransactionStore } from "@app/products/property/deferred-duty/[id]/components/child-screens/transaction/store";
import {
  getInitialDataWriteOffBalance,
  postNewWriteOffBalance,
} from "@app/products/property/deferred-duty/components/action-bar/form-steps/write-off-balances/api";
import { AccountsFormStep } from "@app/products/property/deferred-duty/components/action-bar/form-steps/write-off-balances/components/form-elements/accounts/_index";
import { BalanceAdjustmentFormStep } from "@app/products/property/deferred-duty/components/action-bar/form-steps/write-off-balances/components/form-elements/balance-adjustment/_index";
import { WriteOffBalanceNewJournalFormStep } from "@app/products/property/deferred-duty/components/action-bar/form-steps/write-off-balances/components/form-elements/new-journal/_index";
import {
  DTO_DeferredDuty_Account,
  DTO_Workflow_JournalDDWriteOffBalance,
  WriteOffBalancesKeysOfSteps,
} from "@app/products/property/deferred-duty/components/action-bar/form-steps/write-off-balances/model";
import { useWriteOffBalanceDialogStore } from "@app/products/property/deferred-duty/components/action-bar/form-steps/write-off-balances/store";
import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";
import {
  DTO_Journal_LOVs,
  DTO_Workflow_JournalCreate,
} from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IWriteOffBalancesDialogProps {
  onClose: () => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  isReadOnly?: boolean;
  isToBeApprovalMode?: boolean;
  isShowCancelWorkflowButton?: boolean;
  accountId?: number;
  isRedirectManagePage?: boolean;
  statusBadge?: IBadgeDialog[];
}
export const WriteOffBalancesDialog = ({
  onClose,
  isSaveOnNextStep = false,
  isIncompleteMode = false,
  isFromActionList = false,
  suffixTitle,
  prefixTitle,
  dataFromActionList,
  isReadOnly = false,
  isToBeApprovalMode = false,
  isShowCancelWorkflowButton = false,
  accountId,
  isRedirectManagePage = true,
  statusBadge,
}: IWriteOffBalancesDialogProps) => {
  const history = useHistory();
  const notificationFormStepRef = useRef<ICCFormStepNotificationHandle | null>(
    null
  );
  const { currentFormTitle } = useCommonProductStore();
  const { setIsLoadingOnDialog, isLoadingApprove, setWriteOffBalanceLOVs } =
    useWriteOffBalanceDialogStore();
  const { setDataForCancelDialog } = useConfirmCancelDialogStore();
  const { setDataForCloseDialog, setIsLoadingClose } =
    useConfirmCloseDialogStore();
  const { setDataForFinishDialog } = useConfirmFinishDialogStore();
  const { clearSelectedItems } = useCCProductListViewStore();
  const [writeOffBalanceInitData, setWriteOffBalanceInitData] =
    useState<DTO_Workflow_JournalDDWriteOffBalance>();
  const { gridSelectedTransactionIds } = useDeferredDutyTransactionStore();
  const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
    WorkflowDraft: { Workflow_Draft_Id: 0 },
    AvailableSecondaryWorkflows: [],
    WorkflowApprovals: [],
  });
  const { pushNotification } = useCCAppNotificationStore();
  const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
  const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
  const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
  const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
  const [accounts, setAccounts] = useState<DTO_DeferredDuty_Account[]>([]);

  const titleHeader = useMemo(() => {
    const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
    const title =
      currentFormTitle(formId ?? 0) || "Write Off Out Standing Balances";

    return getTitleWorkflow(title, prefixTitle, suffixTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefixTitle, suffixTitle, workflowHeader]);

  //#region <Set up initial value>
  const initialValue = useMemo((): any => {
    const journalNumber =
      writeOffBalanceInitData?.WorkflowDetail?.JournalNumber;
    let initWhichJournal: any = {
      JournalMode: writeOffBalanceInitData?.WorkflowDetail?.JournalMode,
      JournalSelected:
        journalNumber && journalNumber !== 0
          ? [{ Journal_Number: journalNumber }]
          : [],
    };

    let initNewJournal: any = {
      ...writeOffBalanceInitData?.WorkflowDetail?.CreateJournal,
    };

    let initAccount: any = {
      ...writeOffBalanceInitData?.WorkflowDetail?.Accounts,
    };

    let initBalanceAdjustment: any = {
      ...writeOffBalanceInitData?.WorkflowDetail?.Transaction,
    };

    notificationFormStepRef?.current?.setStepsVisible([
      {
        key: WriteOffBalancesKeysOfSteps.NewJournal,
        visible:
          writeOffBalanceInitData?.WorkflowDetail.JournalMode ===
          JournalMode.CreateNew,
        isClearData: true,
      },
    ]);

    return {
      [WriteOffBalancesKeysOfSteps.WhichJournal]: initWhichJournal,
      [WriteOffBalancesKeysOfSteps.NewJournal]: initNewJournal,
      [WriteOffBalancesKeysOfSteps.Account]: initAccount,
      [WriteOffBalancesKeysOfSteps.BalanceAdjustment]: initBalanceAdjustment,
      [WriteOffBalancesKeysOfSteps.Comments]: {},
      [WriteOffBalancesKeysOfSteps.Documents]: {},
    };
  }, [writeOffBalanceInitData]);
  //#endregion

  //#region <STEPS>
  const steps: IStep[] = [
    {
      label: "Journal",
      component: WhichJournalFormStep,
      visible: true,
      key: WriteOffBalancesKeysOfSteps.WhichJournal,
      options: {
        showBringUpField: false,
        isReadOnly,
        newJournalKey: WriteOffBalancesKeysOfSteps.NewJournal,
      },
      initialValues: initialValue[WriteOffBalancesKeysOfSteps.WhichJournal],
    },
    {
      label: "New journal",
      component: WriteOffBalanceNewJournalFormStep,
      visible: true,
      key: WriteOffBalancesKeysOfSteps.NewJournal,
      options: {
        isReadOnly,
      },
      initialValues: initialValue[WriteOffBalancesKeysOfSteps.NewJournal],
    },
    {
      label: "Accounts",
      component: AccountsFormStep,
      key: WriteOffBalancesKeysOfSteps.Account,
      options: {
        isReadOnly,
        accounts,
      },
      visible: true,
      initialValues: initialValue[WriteOffBalancesKeysOfSteps.NewJournal],
    },
    {
      label: "Balance adjustment",
      component: BalanceAdjustmentFormStep,
      visible: true,
      options: {
        isReadOnly,
        accounts,
      },
      initialValues:
        initialValue[WriteOffBalancesKeysOfSteps.BalanceAdjustment],
      key: WriteOffBalancesKeysOfSteps.BalanceAdjustment,
    },
    {
      label: "Comments",
      component: CommentsFormStep,
      visible: true,
      key: EKeysOfSteps.Comments,
      customClassName: "cc-comment-step-fixed-height-grid",
      options: {
        isReadOnly,
        workflowDraftId,
        recordType: RECORDTYPE.CommunityProperty_Journal,
      },
      initialValues: initialValue[WriteOffBalancesKeysOfSteps.Comments],
    },
    {
      label: "Documents",
      component: DocumentsFormStep,
      visible: true,
      key: EKeysOfSteps.Documents,
      options: {
        isReadOnly,
        workflowDraftId,
        workflowType: WorkflowTypes.Journal_DD_Writeoff_Balance,
      },
      initialValues: initialValue[WriteOffBalancesKeysOfSteps.Documents],
    },
  ];
  //#endregion

  //#region <HANDLE SUBMIT>
  const handleSubmit = async (data: any, buttonId?: string) => {
    switch (buttonId) {
      case EListSubmitButton.Finish:
        handleConfirmFinishProcess(processData(data));
        break;
      case EListSubmitButton.Cancel:
      case EListSubmitButton.ConfirmCloseNo:
        handleCancelButton(processData(data));
        break;
      case EListSubmitButton.Park:
      case EListSubmitButton.ConfirmCloseYes:
      case EListSubmitButton.Close:
        await sendParkWriteOffBalance(processData(data));
        break;
      default:
        break;
    }
  };
  //#endregion

  //#region <FINISH>
  const handleConfirmFinishProcess = (
    payload: DTO_Workflow_JournalDDWriteOffBalance
  ) => {
    const finishCallback = function async() {
      return handleFinish(payload);
    };

    setDataForFinishDialog({
      finishCallback,
      confirmMessage:
        "The write off balance will be modified based on the information provided. Are you sure you want to submit?",
    });
  };

  const handleFinish = async (
    payload: DTO_Workflow_JournalDDWriteOffBalance
  ) => {
    const defaultSuccessMessage =
      "Write off balance was completed successfully";
    const defaultFailedMessage = "Write off balance could not be completed";

    const response = await postNewWriteOffBalance(
      WorkflowProcessMode.Finish,
      payload
    );
    if (isSuccessResponse(response)) {
      if (response?.data?.IsSuccess) {
        onClose();
        const successNotification: IAppNotificationItemAddProps = {
          title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
          type: "success",
        };
        !isRedirectManagePage && pushNotification(successNotification);
        isRedirectManagePage &&
          history.push(`${PROPERTY_JOURNALS_ROUTE}/${response?.data?.ID}`, {
            notification: successNotification,
          });
        isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
        clearSelectedItems();
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    } else {
      notificationFormStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response?.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
  };
  //#endregion

  //#region <PARK>
  const sendParkWriteOffBalance = async (
    payload: DTO_Workflow_JournalDDWriteOffBalance
  ) => {
    setIsLoadingPark(true);
    setIsLoadingOnDialog(true);
    setIsLoadingClose(true);
    const defaultSuccessMessage = "Write off balance was parked successfully";
    const defaultFailedMessage = "Write off balance could not be parked";

    const response = await postNewWriteOffBalance(
      WorkflowProcessMode.Park,
      payload
    );
    setIsLoadingClose(false);
    if (isSuccessResponse(response)) {
      if (response?.data?.IsSuccess) {
        onClose();
        pushNotification({
          title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
          type: "success",
        });
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    } else {
      notificationFormStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response?.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
    setIsLoadingOnDialog(false);
    setIsLoadingPark(false);
    setDataForCloseDialog(undefined);
  };
  //#endregion

  //#region <CANCEL>
  const handleCancelButton = (data: any) => {
    if (isFromActionList || !isFirstSave) {
      setDataForCancelDialog({
        cancelAPI: postNewWriteOffBalance,
        dataCancel: data,
        defaultSuccessMessage: "Write off balance was cancelled successfully",
        defaultErrorMessage: "Write off balance could not be cancelled",
      });
    } else {
      onClose();
    }
  };
  //#endregion

  //#region <CLOSE>
  const handleCloseDialog = (renderProps: ICCFormStepRender) => {
    if (!isFirstSave) {
      setDataForCloseDialog({
        closeCallback: renderProps.submitButton.onClick,
      });
    } else {
      onClose();
    }
  };
  //#endregion

  //#region <NEXT and SAVE>
  const handleNextButton = async (data: any) => {
    setIsLoadingOnNext(true);
    return await sendSaveWriteOffBalance(processData(data));
  };

  const sendSaveWriteOffBalance = async (
    payload: DTO_Workflow_JournalDDWriteOffBalance
  ) => {
    const response = await postNewWriteOffBalance(
      WorkflowProcessMode.Save,
      payload
    );
    setIsLoadingOnNext(false);
    const defaultFailedMessage =
      "Write off balance process could not be saved.";
    if (isSuccessResponse(response)) {
      if (response?.data?.IsSuccess) {
        if (isFirstSave && workflowDraftId === 0) {
          setIsFirstSave(false);
          setWorkflowDraftId(response?.data?.ID ?? 0);
          setWorkflowHeader({
            ...workflowHeader,
            WorkflowDraft: {
              ...workflowHeader.WorkflowDraft,
              Workflow_Draft_Id: response?.data?.ID ?? 0,
            },
          });
        }
        return true;
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        return false;
      }
    } else {
      notificationFormStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response?.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
      return false;
    }
  };
  //#endregion

  //#region <PROCESS DATA>
  const processData: DTO_Workflow_JournalCreate | any = (data: any) => {
    let workflowDetail: any = { ...writeOffBalanceInitData?.WorkflowDetail };
    workflowDetail = {
      JournalNumber:
        data?.WhichJournal?.JournalSelected[0]?.Journal_Number ?? 0,
      JournalMode: data?.WhichJournal?.JournalMode,
      CreateJournal: {
        ...writeOffBalanceInitData?.WorkflowDetail?.CreateJournal,
        Journal_Number: data?.CreateJournal?.Journal_Number ?? 0,
        Journal_Type: data?.CreateJournal?.Journal_Type,
        Jnl_Reference: data?.CreateJournal?.Jnl_Reference,
        Jnl_Description: data?.CreateJournal?.Jnl_Description,
        Jnl_Notes: data?.CreateJournal?.Jnl_Notes,
      },
      Transaction: {
        AccountId: data?.BalanceAdjustment?.AccountId ?? 0,
        TransactionTypeId: data?.BalanceAdjustment?.TransactionTypeId,
        TransactionDate: data?.BalanceAdjustment?.TransactionDate,
        Reference: data?.BalanceAdjustment?.Reference,
        Description: data?.BalanceAdjustment?.Description,
        Notes: data?.BalanceAdjustment?.Notes,
        DeferredDutyAmount: data?.BalanceAdjustment?.DeferredDutyAmount,
        InterestAmount: data?.BalanceAdjustment?.InterestAmount,
        OtherAmount: data?.BalanceAdjustment?.OtherAmount,
      },
      Accounts: writeOffBalanceInitData?.WorkflowDetail?.Accounts ?? [],
    };
    return {
      WorkflowHeader: workflowHeader,
      WorkflowDetail: workflowDetail,
    };
  };
  //#endregion

  //#region <GET DATA>
  const getWorkflowData = async () => {
    const draftId = dataFromActionList?.Workflow_Draft_Id;
    notificationFormStepRef?.current?.setLoadingFormStep(true);
    let transactionId: number = gridSelectedTransactionIds[0];

    const response = await getInitialDataWriteOffBalance(
      draftId,
      transactionId,
      accountId
    );
    if (Array.isArray(response)) {
      const [lovsAssessment, workflowData] = response;
      if (
        isSuccessResponse(response[0]) &&
        isSuccessResponse(response[1]) &&
        lovsAssessment?.data &&
        workflowData?.data
      ) {
        //set Lovs Data Assessment
        setWriteOffBalanceLOVs(lovsAssessment?.data);
        if (workflowData?.data) {
          //set Create Assessment InitData
          //set New InitData
          setWriteOffBalanceInitData({
            WorkflowDetail: workflowData?.data?.WorkflowDetail,
            WorkflowHeader: workflowData?.data?.WorkflowHeader,
          });
          setAccounts(workflowData?.data?.WorkflowDetail?.Accounts ?? []);
          //set workflow header and workflow Draft Id
          if (workflowData?.data?.WorkflowHeader) {
            setWorkflowHeader(workflowData?.data.WorkflowHeader);
            setWorkflowDraftId(
              workflowData?.data?.WorkflowHeader?.WorkflowDraft
                ?.Workflow_Draft_Id ?? 0
            );
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
        }
        notificationFormStepRef?.current?.setLoadingFormStep(false);
      } else {
        let responseError: APIResponse<
          | DTO_Journal_LOVs
          | DTO_Workflow_JournalDDWriteOffBalance
          | ResponsePacket
        > = response[0];
        if (!isSuccessResponse(response[1])) {
          responseError = response[1];
        }
        notificationFormStepRef?.current?.setLoadingFormStep(false);
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error:
              (responseError.data as ResponsePacket)?.Errors ??
              "Load workflow failed",
          },
        });
      }
    } else {
      const responseError = response as APIResponse;
      notificationFormStepRef?.current?.setLoadingFormStep(false);
      notificationFormStepRef?.current?.setLoadFailedFormStep({
        onReload: () => getWorkflowData(),
        responseError: {
          status: responseError.status,
          error: "Load workflow failed",
        },
      });
    }
  };
  //#endregion

  useEffectOnce(() => {
    getWorkflowData();
  });

  return (
    <>
      <CCFormStep
        ref={notificationFormStepRef}
        listButtonId={listSubmitButton}
        onSubmit={handleSubmit}
        initialValues={initialValue}
        initialSteps={steps}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            disabled={isLoadingApprove}
            titleHeader={titleHeader}
            badge={statusBadge}
            onClose={() => handleCloseDialog(renderProps)}
            bodyElement={renderProps.children}
            footerElement={
              <>
                <div className={"cc-dialog-footer-actions-right"}>
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={isLoadingPark ? "fas fa-spinner fa-spin" : ""}
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled || isLoadingPark
                      }
                    >
                      Park
                    </Button>
                  )}
                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      disabled={
                        isLoadingApprove || isLoadingOnNext || isLoadingPark
                      }
                      id={EListSubmitButton.Cancel}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}
                  {isToBeApprovalMode || isReadOnly ? (
                    !renderProps.isLastStep && (
                      <Button
                        themeColor="primary"
                        id="cc-next-step-button"
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        className={"cc-dialog-button"}
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )
                  ) : (
                    <Button
                      themeColor="primary"
                      id={renderProps.nextButton.idButton}
                      disabled={
                        isLoadingOnNext || renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingOnNext
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
              </>
            }
          />
        )}
      />
    </>
  );
};
