import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { WorkflowApprovalStatus } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { GISReferenceDialog } from "@app/products/property/components/dialogs/gis-reference/_index";
import { colGISReference } from "@app/products/property/components/dialogs/gis-reference/config";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { GISAccordionEventType } from "@app/products/property/components/gis-accordion/constant";
import { ECustomColNameProperty } from "@app/products/property/config";
import { WorkflowProcessMode } from "@app/products/property/model";
import {
  loadInitialDataTitleGISReference,
  postWorkflowTitleGISReference,
} from "@app/products/property/titles/[id]/components/dialogs/modify-gis-reference-title/api";
import { GIS_REFERENCE_DIALOG_MODE } from "@app/products/property/titles/[id]/components/dialogs/modify-gis-reference-title/config";
import {
  DTO_WorkflowDetail_ModifyGIS,
  DTO_Workflow_ModifyGIS,
} from "@app/products/property/titles/[id]/components/dialogs/modify-gis-reference-title/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DTO_LOV } from "@common/models/odataResponse";
import { Label } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCInput } from "@components/cc-input/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { differenceBy, isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IModifyGISReferenceTitleDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  dataFromActionList?: VO_Workflow_Draft;
  isShowCancelWorkflowButton?: boolean;
  statusBadge?: IBadgeDialog[];
  prefixTitle?: string;
  suffixTitle?: string;
  isIncompleteMode?: boolean;
  isFromActionList?: boolean;
  titleId: number | string;
}

const nameOfGISReference = nameOfFactory<DTO_GIS>();
const nameOf = nameOfFactory<DTO_WorkflowDetail_ModifyGIS>();

export const ModifyGISReferenceTitleDialog = observer(
  ({
    onClose,
    dataFromActionList,
    isShowCancelWorkflowButton = false,
    statusBadge,
    prefixTitle,
    suffixTitle,
    isIncompleteMode,
    isFromActionList,
    titleId,
  }: IModifyGISReferenceTitleDialogProps) => {
    //store
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const { currentFormTitle } = useCommonProductStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();

    //Get labels
    const titleLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Title
    );

    //state
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [submitting, setSubmitting] = useState<WorkflowProcessMode | null>(
      null
    );
    const [dialogMode, setDialogMode] = useState<GIS_REFERENCE_DIALOG_MODE>(
      GIS_REFERENCE_DIALOG_MODE.Hide
    );
    const [initialData, setInitialData] = useState<DTO_Workflow_ModifyGIS>();
    const [gisReferenceData, setGISReferenceData] = useState<DTO_GIS[]>([]);
    const [gisTypes, setGISTypes] = useState<DTO_LOV[]>([]);
    const [gisReferenceSelectedData, setGISReferenceSelectedData] = useState<
      DTO_GIS[]
    >([]);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();

    const columnFields = useMemo(() => {
      if (isFromActionList && !isIncompleteMode) {
        return colGISReference;
      }
      return colGISReference.map((col) => {
        if (col.field === nameOfGISReference("GISReference")) {
          col.handleOnClick = (dataItem: DTO_GIS) => {
            setGISReferenceSelectedData([dataItem]);
            setDialogMode(GIS_REFERENCE_DIALOG_MODE.Edit);
          };
        } else {
          col.handleOnClick = undefined;
        }
        return col;
      });
    }, [isFromActionList, isIncompleteMode]);

    const handleAddEditGISReferenceDialog = (data: DTO_GIS) => {
      let newGISReferences: DTO_GIS[] = [];
      if (dialogMode === GIS_REFERENCE_DIALOG_MODE.New) {
        newGISReferences = gisReferenceData
          ? [...gisReferenceData, data]
          : [data];
      } else {
        newGISReferences = gisReferenceData?.map((item) => {
          if (
            item?.GISReference === gisReferenceSelectedData?.[0]?.GISReference
          ) {
            const newItem = { ...item, ...data };
            if (!newItem) return item;
            setGISReferenceSelectedData([newItem]);
            return newItem;
          }
          return item;
        }) as DTO_GIS[];
      }
      setGISReferenceData(newGISReferences ?? []);
      setDialogMode(GIS_REFERENCE_DIALOG_MODE.Hide);
    };

    const handleRemoveGISReference = async () => {
      const newGISReferenceData = differenceBy(
        gisReferenceData,
        gisReferenceSelectedData,
        nameOfGISReference("GISReference")
      );
      setGISReferenceData(newGISReferenceData ?? []);
    };

    const handleCancelButton = () => {
      if (initialData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id) {
        setDataForCancelDialog({
          cancelAPI: postWorkflowTitleGISReference,
          dataCancel: initialData,
          defaultSuccessMessage:
            "Eit GIS reference was cancelled successfully.",
          defaultErrorMessage:
            "Edit GIS reference process could not be cancelled.",
        });
      } else {
        onClose();
      }
    };

    const processActionOnSubmit = (mode: WorkflowProcessMode) => {
      if (mode === WorkflowProcessMode.Finish) {
        const defaultMessageSuccess =
          "Edit GIS reference was saved successfully.";
        return {
          notification: {
            success: defaultMessageSuccess,
            error: "Edit GIS reference process could not be saved.",
          },
          action: (res: any) => {
            const resData = res?.data;
            if (!isFromActionList) {
              //Refresh GIS Reference accordion if the workflow is auto approved
              if (
                resData?.WorkflowApprovalStatus ===
                WorkflowApprovalStatus.Approved
              ) {
                eventEmitter.emit(GISAccordionEventType.reloadGISReferenceGrid);
              }
            } else {
              eventEmitter.emit(CCGridEventType.RefreshOData);
            }
            pushNotification({
              title:
                resData?.Notification ??
                resData?.SuccessMessage ??
                defaultMessageSuccess,
              type: "success",
            });
          },
        };
      } else if (mode === WorkflowProcessMode.Park) {
        return {
          notification: {
            success: "Eit GIS reference was parked successfully.",
            error: "Eit GIS reference could not be parked.",
          },
        };
      }
    };

    const handleSubmit = async (mode: WorkflowProcessMode) => {
      const req = {
        ...initialData,
        WorkflowDetail: {
          ...initialData?.WorkflowDetail,
          SelectGISReference: gisReferenceData,
        },
      } as DTO_Workflow_ModifyGIS;
      setSubmitting(mode);
      const res = await postWorkflowTitleGISReference(mode, req);
      setSubmitting(null);
      const actionOnSubmit = processActionOnSubmit(mode);
      if (isSuccessResponse(res)) {
        clearErrorNotification();
        if (res.data.IsSuccess) {
          onClose();
          if (actionOnSubmit?.action) {
            actionOnSubmit.action(res);
          } else {
            pushNotification({
              title:
                res?.data?.Notification ??
                actionOnSubmit?.notification?.success,
              type: "success",
            });
          }
        } else {
          notificationRef.current?.pushNotification({
            title:
              res?.data?.ErrorMessage ?? actionOnSubmit?.notification?.error,
            type: "error",
            autoClose: false,
          });
        }
      } else {
        notificationRef.current?.pushNotification({
          title: res?.data?.ErrorMessage ?? actionOnSubmit?.notification?.error,
          type: "error",
          autoClose: false,
        });
      }
    };

    const loadWorkflowData = async () => {
      setIsLoading(true);
      const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
      let errorResponse = undefined;
      const response = await loadInitialDataTitleGISReference(
        titleId,
        workflowDraftId
      );

      if (isSuccessResponse(response) && response?.data) {
        const workflowData = response.data;
        setInitialData({
          WorkflowDetail: workflowData?.WorkflowDetail,
          WorkflowHeader: workflowData?.WorkflowHeader,
        });
        setGISReferenceData(workflowData?.WorkflowDetail?.SelectGISReference);
        setGISTypes(workflowData?.WorkflowDetail?.GISTypes ?? []);
      } else {
        errorResponse = {
          status: response.status,
          error: response.error ?? "Initial data load failed.",
        };
      }
      setIsLoading(false);
      setResponseLoadError(errorResponse);
    };

    /**
     * process title dialog
     */
    const titleHeader = useMemo(() => {
      const formId = initialData?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) ?? `Modify ${titleLabel} GIS`;
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line
    }, [initialData, prefixTitle, suffixTitle]);

    useEffectOnce(() => {
      loadWorkflowData();
    });

    return (
      <Form
        render={() => {
          return (
            <CCDialog
              maxWidth={"40%"}
              height="auto"
              onClose={onClose}
              titleHeader={titleHeader}
              disabled={!isNil(submitting)}
              badge={statusBadge}
              bodyElement={
                isLoading ? (
                  <Loading isLoading={isLoading} />
                ) : responseLoadError ? (
                  <CCLoadFailed
                    responseError={responseLoadError}
                    onReload={() => {
                      loadWorkflowData();
                    }}
                  />
                ) : (
                  <FormElement>
                    <div className="cc-form">
                      <div className="cc-field-group">
                        <CCLocalNotification ref={notificationRef} />
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">
                              {titleLabel} reference
                            </label>
                            <Field
                              name={nameOf("TitleReference")}
                              placeholder={`${titleLabel} reference`}
                              component={CCInput}
                              value={
                                initialData?.WorkflowDetail?.TitleReference
                              }
                              readOnly
                            />
                          </div>
                          <CCGrid
                            toolbar={
                              isFromActionList && !isIncompleteMode ? null : (
                                <div className="cc-grid-tools-bar">
                                  <Button
                                    type="button"
                                    iconClass="fas fa-plus"
                                    title="Add"
                                    onClick={() =>
                                      setDialogMode(
                                        GIS_REFERENCE_DIALOG_MODE.New
                                      )
                                    }
                                  />
                                  <Button
                                    type="button"
                                    iconClass="fas fa-minus"
                                    title="Remove"
                                    onClick={handleRemoveGISReference}
                                    disabled={isEmpty(gisReferenceSelectedData)}
                                  />
                                </div>
                              )
                            }
                            columnFields={columnFields}
                            data={gisReferenceData ?? []}
                            primaryField={nameOfGISReference("GISReference")}
                            selectableMode={
                              isFromActionList && !isIncompleteMode
                                ? "none"
                                : "multiple"
                            }
                            onSelectionChange={(dataItem: DTO_GIS[]) => {
                              if (dataItem)
                                setGISReferenceSelectedData([...dataItem]);
                            }}
                            selectedRows={gisReferenceSelectedData}
                          />
                        </div>
                      </div>
                    </div>
                    {(dialogMode === GIS_REFERENCE_DIALOG_MODE.New ||
                      dialogMode === GIS_REFERENCE_DIALOG_MODE.Edit) && (
                      <GISReferenceDialog
                        onClose={() =>
                          setDialogMode(GIS_REFERENCE_DIALOG_MODE.Hide)
                        }
                        onSubmit={handleAddEditGISReferenceDialog}
                        initialGISReference={
                          dialogMode === GIS_REFERENCE_DIALOG_MODE.Edit &&
                          gisReferenceSelectedData[0]
                            ? ({
                                ...gisReferenceSelectedData[0],
                                GISTypeObj: {
                                  Code: gisReferenceSelectedData[0]?.GIS_Type_Id?.toString(),
                                  Name: gisReferenceSelectedData[0]?.GIS_Type,
                                },
                              } as DTO_GIS)
                            : undefined
                        }
                        gisReferenceData={gisReferenceData}
                        gisTypes={gisTypes}
                      />
                    )}
                  </FormElement>
                )
              }
              footerElement={
                !isFromActionList || isIncompleteMode ? (
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          !!responseLoadError || isLoading || !isNil(submitting)
                        }
                        onClick={handleCancelButton}
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      className={"cc-dialog-button"}
                      onClick={() => handleSubmit(WorkflowProcessMode.Park)}
                      disabled={
                        !!responseLoadError || isLoading || !isNil(submitting)
                      }
                      iconClass={
                        submitting === WorkflowProcessMode.Park
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                    >
                      Park
                    </Button>
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={() => handleSubmit(WorkflowProcessMode.Finish)}
                      disabled={
                        !!responseLoadError || isLoading || !isNil(submitting)
                      }
                      iconClass={
                        submitting === WorkflowProcessMode.Finish
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                    >
                      Finish
                    </Button>
                  </div>
                ) : undefined
              }
            />
          );
        }}
      />
    );
  }
);
