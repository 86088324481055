import { useNoticeRunAssessmentStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/assessment-selection-criteria/store";
import { useNoticeRunGroupStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/store";
import { useNoticeRunAttributeStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-run-attributes/store";
import { useNoticeRunNoticeTypeStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-type/store";
import { getLovNoticeRun } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/api";
import { EKeysOfStepsNewNoticeRun } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/model";
import { nameOfLov } from "@app/products/property/model";
import {
  convertValueLOVToNumber,
  isFieldVisible,
} from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const NoticeRunAttributesFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    localNotificationRef,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    //store
    const { dataSelectedNoticeType } = useNoticeRunNoticeTypeStepStore();
    const { attributeLOVs, setAttributeLOVs } =
      useNoticeRunAttributeStepStore();
    const { assessmentLOVs, setAssessmentLOVs } =
      useNoticeRunAssessmentStepStore();
    const { groupLOVs, setGroupLOVs } = useNoticeRunGroupStepStore();

    //Lovs data
    const chargeRunLov = useMemo(() => {
      return attributeLOVs?.ChargeRun ?? [];
    }, [attributeLOVs?.ChargeRun]);

    const ratingPeriodLov = useMemo(() => {
      return attributeLOVs?.RatingPeriods ?? [];
    }, [attributeLOVs?.RatingPeriods]);

    const financialGroupLov = useMemo(() => {
      return attributeLOVs?.FinancialGroups ?? [];
    }, [attributeLOVs?.FinancialGroups]);

    const isShowChargeRun = useMemo(() => {
      return isFieldVisible(dataSelectedNoticeType?.ChargeRunVisibility);
    }, [dataSelectedNoticeType?.ChargeRunVisibility]);

    //handle change charge run
    const handleChangeChargeRun = async (event: ComboBoxChangeEvent) => {
      onChange(nameOf("Charge_Run_Id"), { value: event?.value?.Code });
      const value: DTO_LOV_Number = event?.value;
      const paramLovs = isNil(value)
        ? undefined
        : {
            chargeRunId: value?.Code,
          };
      options?.setIsDisabledDialog(true);
      onChange(nameOf("_option.Loading_ChargeRun"), { value: true });
      const resLov = await getLovNoticeRun(paramLovs);
      if (isSuccessResponse(resLov) && resLov?.data) {
        setAttributeLOVs({
          ...attributeLOVs,
          FinancialGroups: convertValueLOVToNumber(
            resLov.data?.FinancialGroups ?? [],
            "Code"
          ),
          RatingPeriods: convertValueLOVToNumber(
            resLov.data?.RatingPeriods ?? [],
            "Code"
          ),
        });
        setGroupLOVs({
          ...groupLOVs,
          AssessmentGroups: convertValueLOVToNumber(
            resLov.data?.AssessmentGroups ?? [],
            "Code"
          ),
        });
        let ratingPeriod = resLov?.data?.RatingPeriodId;
        let financialGroup =
          resLov?.data?.FinancialGroups?.length === 1
            ? +resLov?.data?.FinancialGroups?.[0].Code
            : undefined;
        if (isNil(value)) {
          ratingPeriod = undefined;
          financialGroup = undefined;
        }
        onChange(nameOf("Rating_Period_Id"), {
          value: ratingPeriod,
        });
        onChange(nameOf("Financial_Group_Id"), {
          value: financialGroup,
        });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: "Load Lov failed.",
          type: "error",
          autoClose: false,
        });
      }
      options?.setIsDisabledDialog(false);
      onChange(nameOf("_option.Loading_ChargeRun"), { value: false });
    };

    //handle change financial group
    const handleChangeFinancialGroup = async (event: ComboBoxChangeEvent) => {
      onChange(nameOf("Financial_Group_Id"), { value: event?.value?.Code });
      const value: DTO_LOV_Number = event?.value;
      const paramLovs = isNil(value)
        ? undefined
        : {
            financialGroupId: value?.Code,
          };
      options?.setIsDisabledDialog(true);
      onChange(nameOf("_option.Loading_FinancialGroup"), { value: true });
      const resLov = await getLovNoticeRun(paramLovs);
      if (isSuccessResponse(resLov) && resLov?.data) {
        if (!isShowChargeRun) {
          setAttributeLOVs({
            ...attributeLOVs,
            RatingPeriods: convertValueLOVToNumber(
              resLov.data?.RatingPeriods ?? [],
              "Code"
            ),
          });
        }
        setAssessmentLOVs({
          ...assessmentLOVs,
          InstalmentPlans: convertValueLOVToNumber(
            resLov.data?.InstalmentPlans ?? [],
            "Code"
          ),
        });
        onChange(`${EKeysOfStepsNewNoticeRun.Attribute}.Instalment_Plan_Id`, {
          value: resLov?.data?.InstalmentPlanId,
        });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: "Load Lov failed.",
          type: "error",
          autoClose: false,
        });
      }
      options?.setIsDisabledDialog(false);
      onChange(nameOf("_option.Loading_FinancialGroup"), { value: false });
    };
    return (
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Issue date</label>
              <Field
                name={nameOf("IssueDate")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
                disabled={options?.isReadOnly}
              />
            </div>
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">
                  Set transaction date to issue date
                </label>
                <Field
                  name={nameOf("SetTransactionDateToIssueDate")}
                  checked={getFieldValue("SetTransactionDateToIssueDate")}
                  component={CCSwitch}
                  disabled={options?.isReadOnly}
                />
              </div>
            </div>
            {isShowChargeRun ? (
              <div className="cc-field">
                <label className="cc-label">
                  Charge run
                  <CCTooltip type="validator" position="right" />
                  {getFieldValue("_option.Loading_ChargeRun") ? (
                    <span>
                      <i className="fas fa-spinner fa-spin" />
                    </span>
                  ) : null}
                </label>
                <Field
                  name={nameOf("Charge_Run_Id")}
                  component={CCSearchComboBox}
                  data={chargeRunLov ?? []}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  onChange={handleChangeChargeRun}
                  disabled={options?.isReadOnly}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                />
              </div>
            ) : null}
            <div className="cc-field">
              <label className="cc-label">
                Financial group
                <CCTooltip type="validator" position="right" />
                {getFieldValue("_option.Loading_FinancialGroup") ? (
                  <span>
                    <i className="fas fa-spinner fa-spin" />
                  </span>
                ) : null}
              </label>
              <Field
                name={nameOf("Financial_Group_Id")}
                component={CCSearchComboBox}
                data={financialGroupLov ?? []}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                onChange={handleChangeFinancialGroup}
                disabled={options?.isReadOnly}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Rating period</label>
              <Field
                name={nameOf("Rating_Period_Id")}
                component={CCSearchComboBox}
                data={ratingPeriodLov ?? []}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                isUseDefaultOnchange
                disabled={options?.isReadOnly || isShowChargeRun}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Name
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                name={nameOf("Name")}
                component={CCInput}
                placeholder="Name"
                readOnly={options?.isReadOnly}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
