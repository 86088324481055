import { PERCENTAGE_FORMAT } from "@common/constants/common-format";
import { ICustomEditCell } from "@components/cc-grid/model";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import React from "react";
import "./_index.scss";

const MAX_PERCENTAGE = 100; // 100%

//auto reset Percentage need get maxPercentage can be allocated when it is null
const calValuePercentage = (value: number | null) => {
  return isNil(value) || value <= 0 || value > MAX_PERCENTAGE
    ? MAX_PERCENTAGE
    : value;
};
export const PercentageTextBoxCustomCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
}: ICustomEditCell) => {
  return (
    <div className="cc-percentage-text-box-custom-cell">
      <CCNumericTextBox
        className="cc-percentage-text-box-custom"
        value={calValuePercentage(value)}
        format={PERCENTAGE_FORMAT.PERCENTAGE_1}
        max={MAX_PERCENTAGE}
        onChange={(event: NumericTextBoxChangeEvent) => {
          if (onChange) {
            onChange({
              field: field,
              dataIndex: dataIndex || 0,
              dataItem,
              syntheticEvent: event.syntheticEvent,
              value: calValuePercentage(event.value),
            });
          }
        }}
      />
      {/* Tooltip provides max percentage user can pick */}
      <CCTooltip
        type="info"
        position="right"
        content={`Maximum area percentage is ${MAX_PERCENTAGE}`}
      />
    </div>
  );
};
