import { PROPERTY_JOURNALS_LIST } from "@app/products/property/journals/list/constant";
import { VO_JournalList } from "@app/products/property/journals/list/model";
import { PROPERTY_SUPPLEMENTARY_RATES_LIST } from "@app/products/property/supplementary-rates/list/all/constant";
import { VO_Supplementary } from "@app/products/property/supplementary-rates/list/model";
import { getProductTitle } from "@common/constants/product-control";
import { PRODUCT_TYPE } from "@common/constants/productType";
import {
  DTO_Dashboard_KPI,
  EPropertyKPI,
} from "@common/pages/home/components/property/model";
import { usePropertyDashboardStore } from "@common/pages/home/components/property/store";
import { nameOfFactory } from "@common/utils/common";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";
const nameOfSupplementary = nameOfFactory<VO_Supplementary>();
const nameOfJournal = nameOfFactory<VO_JournalList>();
export const PropertyActivity = observer(() => {
  const { propertyDashboard, isLoadingDashboardProperty } =
    usePropertyDashboardStore();
  const history = useHistory();
  return (
    <div className="cc-activity-group">
      <div className="cc-activity-group-title">
        {getProductTitle(PRODUCT_TYPE.CommunityProperty)}
      </div>
      <div className="cc-activity-group-container">
        {isLoadingDashboardProperty ? (
          <Loading isLoading />
        ) : (
          propertyDashboard?.KPIs?.length &&
          propertyDashboard.KPIs.map(
            (item: DTO_Dashboard_KPI, index: number) => {
              return (
                <div
                  className="cc-activity-item"
                  onClick={() => {
                    if (item.Id === EPropertyKPI.ActiveJournals) {
                      history.push(PROPERTY_JOURNALS_LIST, {
                        filter: [
                          {
                            field: nameOfJournal("Journal_Status_Name"),
                            value: "Cancelled",
                            operator: "neq",
                          },
                          {
                            field: nameOfJournal("Journal_Status_Name"),
                            value: "Authorised",
                            operator: "neq",
                          },
                        ],
                      });
                    } else if (item.Id === EPropertyKPI.ActiveSupplementaries) {
                      history.push(PROPERTY_SUPPLEMENTARY_RATES_LIST, {
                        filter: [
                          {
                            field: nameOfSupplementary("Supp_Is_Complete"),
                            value: true,
                            operator: "eq",
                          },
                        ],
                      });
                    } else if (
                      item.Id === EPropertyKPI.OutstandingOwnershipChanges
                    ) {
                    }
                  }}
                  key={index}
                >
                  <div className="cc-activity-name">{item.Name}</div>
                  <div className="cc-activity-number">{item.Value}</div>
                </div>
              );
            }
          )
        )}
      </div>
    </div>
  );
});
