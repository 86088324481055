import { usePPRApplicationDetailsStore } from "@app/products/town-planning/ppr/[id]/components/reference-sidebar/detail/store";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { toBoolean } from "@common/utils/common";
import {
  formatDateByKendo,
  formatDisplayValue,
} from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { differenceInDays } from "date-fns";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const PPRDetailTab = observer(() => {
  const {
    pprApplicationDetails,
    loadPPRApplicationDetails,
    isLoading,
    resetStore,
  } = usePPRApplicationDetailsStore();
  const { ppr } = usePPRStore();
  const { settings } = useCommonProductStore();
  const { lastSelectedId } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  let pprId = id ? +id : lastSelectedId;
  useEffectOnce(() => {
    return () => resetStore();
  });

  const displayControlLine = settings[
    ECorporateSettingsField.TP_DisplayControlLine
  ]?.Value
    ? toBoolean(settings[ECorporateSettingsField.TP_DisplayControlLine]?.Value)
    : undefined;

  const applicationBannerShowAppealDays = settings[
    ECorporateSettingsField.TP_ApplicationBannerShowAppealDays
  ]?.Value
    ? toBoolean(
        settings[ECorporateSettingsField.TP_ApplicationBannerShowAppealDays]
          ?.Value
      )
    : undefined;

  const isStatBasedOnValidation = settings[
    ECorporateSettingsField.TP_StatBasedOnValidation
  ]?.Value
    ? toBoolean(
        settings[ECorporateSettingsField.TP_StatBasedOnValidation]?.Value
      )
    : undefined;

  const ftdDays = settings[ECorporateSettingsField.TP_StatDaysFTDDays]?.Value
    ? settings[ECorporateSettingsField.TP_StatDaysFTDDays]?.Value
    : undefined;

  const targetDaysEnabled = settings[
    ECorporateSettingsField.TP_StatTargetDaysEnabled
  ]?.Value
    ? toBoolean(
        settings[ECorporateSettingsField.TP_StatTargetDaysEnabled]?.Value
      )
    : undefined;

  const getEOT = useMemo(() => {
    if (pprApplicationDetails?.Flag_EOT) {
      if (pprApplicationDetails?.HasExtensionGranted)
        return "Extension of Time Granted";
      return "Extension of Time Disallowed";
    }
    return null;
  }, [pprApplicationDetails]);

  const getSubStatus = useMemo(() => {
    let subStatus = "";
    if (displayControlLine) {
      if (
        pprApplicationDetails?.FIStatus_ControlPanel?.StatusOnly &&
        !isEmpty(pprApplicationDetails?.FIStatus_ControlPanel?.StatusOnly)
      ) {
        subStatus = `(FI) ${pprApplicationDetails?.FIStatus_ControlPanel?.StatusOnly}`;
      }
      if (
        pprApplicationDetails?.AppealStatus &&
        !isEmpty(pprApplicationDetails?.AppealStatus)
      ) {
        if (isEmpty(subStatus)) subStatus = pprApplicationDetails?.AppealStatus;
        else subStatus += `/${pprApplicationDetails?.AppealStatus}`;
      }
    }
    return subStatus;
  }, [displayControlLine, pprApplicationDetails]);

  const getStatus = useMemo(() => {
    let status = "";
    if (
      pprApplicationDetails?.Status_ENUM === Application_Status.PermitIssued
    ) {
      status = "DoT Responded";
    } else if (
      pprApplicationDetails?.Status_ENUM === Application_Status.ReadyForDecision
    ) {
      status = "To be Approved by Delegated approver";
    } else if (pprApplicationDetails?.Status_ENUM) {
      status = pprApplicationDetails.Status_Name;
    }
    if (pprApplicationDetails?.DueStatus)
      status += ` - ${pprApplicationDetails?.DueStatus}`;
    if (!isEmpty(getSubStatus)) status += ` (${getSubStatus})`;
    return status;
  }, [pprApplicationDetails, getSubStatus]);

  const getFTD = useMemo(() => {
    let FTDText = "";
    if (
      settings &&
      !pprApplicationDetails?.Decision_ENUM &&
      pprApplicationDetails?.StatDays.StatDays &&
      pprApplicationDetails?.StatDays?.AppealDays &&
      (pprApplicationDetails?.StatDays?.StatDays > ftdDays ||
        pprApplicationDetails?.StatDays.AppealDays > ftdDays)
    ) {
      FTDText = "Failure Possible";
    }
    if (pprApplicationDetails?.AssessmentPlanner_Flag_VicSmart) {
      if (isEmpty(FTDText)) FTDText = "VicSmart";
      else FTDText += " - VicSmart";
    }
    return FTDText;
  }, [ftdDays, pprApplicationDetails, settings]);

  const getDaysTargetDate = useMemo(() => {
    let daysTargetDate = "";
    if (targetDaysEnabled) {
      if (
        pprApplicationDetails?.StatDays.TargetDays &&
        pprApplicationDetails?.StatDays.TargetDays > 0
      )
        daysTargetDate = `${pprApplicationDetails?.StatDays.TargetDays} target days`;
      if (pprApplicationDetails?.StatDays.Flag_TargetDateExtended)
        daysTargetDate += `+ extended to ${formatDateByKendo(
          pprApplicationDetails?.StatDays.TargetDate,
          DATE_FORMAT.DATE
        )}`;
    }
    return daysTargetDate;
  }, [targetDaysEnabled, pprApplicationDetails]);

  const isVisibleClockStart = useMemo(() => {
    if (
      pprApplicationDetails?.StatDays &&
      pprApplicationDetails?.StatDays.StartDate &&
      isStatBasedOnValidation &&
      pprApplicationDetails?.Status_ENUM &&
      pprApplicationDetails?.Status_ENUM ===
        Application_Status.AssessedByPlanner
    ) {
      return false;
    }
    return true;
  }, [isStatBasedOnValidation, pprApplicationDetails]);

  const getClockDate = useMemo(() => {
    let clockDate = formatDateByKendo(
      pprApplicationDetails?.StatDays.StartDate,
      DATE_FORMAT.DATE
    );
    if (pprApplicationDetails?.StatDays.StartDateDescription)
      clockDate += ` (${pprApplicationDetails?.StatDays.StartDateDescription})`;
    return clockDate;
  }, [pprApplicationDetails]);

  const getRevisionDate = useMemo(() => {
    let revisionDate = "";
    if (pprApplicationDetails?.RevisionDate) {
      revisionDate = `${
        pprApplicationDetails?.RevisionReference
      } (${formatDateByKendo(
        pprApplicationDetails?.RevisionDate,
        DATE_FORMAT.DATE
      )} )`;
    }
    return revisionDate;
  }, [pprApplicationDetails]);

  const isVisibleTargetDays = useMemo(() => {
    if (
      targetDaysEnabled &&
      isStatBasedOnValidation &&
      pprApplicationDetails?.Status_ENUM &&
      pprApplicationDetails?.Status_ENUM ===
        Application_Status.AssessedByPlanner
    ) {
      return false;
    }
    return true;
  }, [isStatBasedOnValidation, pprApplicationDetails, targetDaysEnabled]);

  const getTargetDays = useMemo(() => {
    if (targetDaysEnabled) {
      if (pprApplicationDetails?.StatDays?.Flag_TargetDateExtended) {
        return `${formatDateByKendo(
          pprApplicationDetails?.StatDays.OriginalTargetDate,
          DATE_FORMAT.DATE
        )}`;
      } else if (pprApplicationDetails?.StatDays.TargetDate) {
        return `${formatDateByKendo(
          pprApplicationDetails?.StatDays.TargetDate,
          DATE_FORMAT.DATE
        )}`;
      }
    }
  }, [pprApplicationDetails, targetDaysEnabled]);

  const subDays = (valueDate: Date) => {
    const currentDate = new Date();
    valueDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    return Math.abs(differenceInDays(currentDate, valueDate));
  };

  const getParentLink = useMemo(() => {
    if (!pprApplicationDetails) return "";
    return (
      <Link
        className="cc-detail-tab-link"
        to={{
          pathname: `/town-planning/ppr/${pprApplicationDetails.ParentApplication_ID}`,
        }}
      >
        {pprApplicationDetails.ParentApplicationNumber}
      </Link>
    );
  }, [pprApplicationDetails]);

  useEffect(() => {
    if (pprId) {
      loadPPRApplicationDetails(pprId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pprId, ppr]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!pprApplicationDetails) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Application number:"}
              value={pprApplicationDetails?.ApplicationNumberDisplay}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            {getEOT && (
              <ProductReferenceRow title={"EOT:"} value={getEOT} success />
            )}
            <ProductReferenceRow title={"Status:"} value={getStatus} />
            {getFTD && <ProductReferenceRow title={"FTD:"} value={getFTD} />}
            <ProductReferenceRow
              title={"Responsible authority:"}
              value={pprApplicationDetails?.ResponsibleAuthority}
            />
            {pprApplicationDetails?.ParentApplicationNumber && (
              <ProductReferenceRow
                title={"Parent Record:"}
                value={getParentLink}
              />
            )}
            {pprApplicationDetails?.StatDays && (
              <ProductReferenceBlock flex>
                <ProductReferenceRow
                  badgeSmall
                  value={`${
                    pprApplicationDetails?.StatDays.StatDays || 0
                  } stat days `}
                  success
                />
                {applicationBannerShowAppealDays && (
                  <ProductReferenceRow
                    badgeSmall
                    value={`${
                      pprApplicationDetails?.StatDays.AppealDays || 0
                    } appeal days `}
                    success
                  />
                )}
                <ProductReferenceRow
                  badgeSmall
                  value={`${
                    pprApplicationDetails?.StatDays.GrossDays || 0
                  } gross days`}
                  success
                />
                {!isEmpty(getDaysTargetDate) && (
                  <ProductReferenceRow
                    badgeSmall
                    value={getDaysTargetDate}
                    success
                  />
                )}
                {pprApplicationDetails?.Referraltrigger_ResponseDue &&
                pprApplicationDetails?.LodgedDate ? (
                  new Date(
                    pprApplicationDetails?.Referraltrigger_ResponseDue
                  ) >= new Date() ? (
                    <ProductReferenceRow
                      badgeSmall
                      value={`${subDays(
                        new Date(
                          pprApplicationDetails?.Referraltrigger_ResponseDue
                        )
                      )} remaining days`}
                      success
                    />
                  ) : (
                    <ProductReferenceRow
                      badgeSmall
                      value={`${subDays(
                        new Date(
                          pprApplicationDetails?.Referraltrigger_ResponseDue
                        )
                      )} overdue days`}
                      success
                    />
                  )
                ) : null}
              </ProductReferenceBlock>
            )}
          </ProductReferenceBlock>
        </PanelBarItem>
        {/* Date information panel bar */}
        <PanelBarItem
          title={
            <ProductReferenceHeading title={"Date information:"} primary />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Created:"}
              value={formatDisplayValue(
                pprApplicationDetails?.Date_Created,
                DATE_FORMAT.DATE
              )}
            />
            {pprApplicationDetails?.LodgedDate && (
              <ProductReferenceRow
                title={"Lodged:"}
                value={formatDisplayValue(
                  pprApplicationDetails?.LodgedDate,
                  DATE_FORMAT.DATE
                )}
              />
            )}
            {isVisibleClockStart && (
              <ProductReferenceRow
                title={"Clock start:"}
                value={getClockDate}
              />
            )}

            {!isEmpty(getRevisionDate) && (
              <ProductReferenceRow
                title={"Revision:"}
                value={getRevisionDate}
              />
            )}

            {isVisibleTargetDays && !isEmpty(getTargetDays) && (
              <ProductReferenceRow title={"Target:"} value={getTargetDays} />
            )}
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
