import { getParcelSummaryById } from "@app/products/property/parcels/[id]/api";
import { DTO_Parcel_Summary } from "@app/products/property/parcels/[id]/model";
import { getContactAlertFromStringArray } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { ICCNotification } from "@components/cc-notification/components/cc-notification-item/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class ParcelsStore {
  private _isLoading: boolean = false;
  private _parcel?: DTO_Parcel_Summary = undefined;
  private _notifications: ICCNotification[] = [];
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }

  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get parcel() {
    return this._parcel;
  }

  setParcel = (parcel?: DTO_Parcel_Summary) => {
    runInAction(() => {
      this._parcel = parcel;
    });
  };

  get isLoading() {
    return this._isLoading;
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get notifications() {
    return this._notifications;
  }

  setNotifications = (notifications: ICCNotification[]) => {
    runInAction(() => {
      this._notifications = notifications;
    });
  };

  get parcelId() {
    return this._parcel?.ParcelId;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._parcel = undefined;
      this._isLoading = false;
      this._notifications = [];
    });
  };

  loadParcelSummary = async (parcelId: number) => {
    this.setIsLoading(true);
    const response = await getParcelSummaryById(parcelId);
    let newParcel = undefined;
    let errorResponse = undefined;
    if (isSuccessResponse(response)) {
      newParcel = response.data;
      if (newParcel?.Contact_Alerts && newParcel?.Contact_Alerts?.length > 0) {
        const newNotifications = getContactAlertFromStringArray(
          newParcel.Contact_Alerts
        );
        appNotificationStore.setNotifications(newNotifications);
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setParcel(newParcel);
    this.setIsLoading(false);
  };
}

const parcelsStoreContext = createContext(new ParcelsStore());
export const useParcelsStore = () => {
  return useContext(parcelsStoreContext);
};
