import { ECustomColNameProperty } from "@app/products/property/config";
import { Label } from "@common/stores/products/config";

export const validatorTitleGrid = (value: any): string | undefined => {
  const titleLabelLowercase = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.TitleLowercase
  );
  if (!value?.AssociateTitles?.length) {
    return `At least one ${titleLabelLowercase} is required`;
  }
  return undefined;
};
