import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { IPPREnquiryParentSection } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { CredentialType } from "@common/constants/enumerations";
import { APIResponseStatus } from "@common/constants/response-status";
import { convertClickOneVerEnum } from "@common/pages/profile/util";
import {
  getSiteUserById,
  getSiteUserLOV,
  putSaveSiteUser,
} from "@common/pages/settings/security/site-users/_id/api";
import { CCSiteUserDetailTab } from "@common/pages/settings/security/site-users/_id/components/reference-sidebar/detail/util";
import {
  NewSiteUser,
  SiteUserLovs,
} from "@common/pages/settings/security/site-users/_id/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class profileStore {
  private _siteUser?: NewSiteUser = undefined;
  private _siteUserLovs?: SiteUserLovs = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _parentSection?: IPPREnquiryParentSection = undefined;
  private _isInactive: boolean = false;
  private _isOnLeave?: boolean = false;
  private _onChange?: (
    name: string,
    options: {
      value: any;
    }
  ) => void;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get siteUser() {
    return toJS(this._siteUser);
  }
  setSiteUser = (siteUser?: any) => {
    runInAction(() => {
      this._siteUser = siteUser;
    });
  };

  get siteUserLovs() {
    return toJS(this._siteUserLovs);
  }
  setSiteUserLovs = (siteUserLovs?: SiteUserLovs) => {
    runInAction(() => {
      this._siteUserLovs = siteUserLovs;
    });
  };

  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: IPPREnquiryParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  get siteUserId() {
    return toJS(this.siteUser?.Contact_SiteUser?.Contact_ID);
  }

  get isInactive() {
    return this._isInactive;
  }

  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get isOnLeave() {
    return this._isOnLeave;
  }

  setIsOnLeave = (isOnLeave: boolean) => {
    runInAction(() => {
      this._isOnLeave = isOnLeave;
    });
  };

  get onChange() {
    return this._onChange;
  }
  setOnChange = (
    onChange: (
      name: string,
      options: {
        value: any;
      }
    ) => void
  ) => {
    runInAction(() => {
      this._onChange = onChange;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._siteUser = undefined;
      this._siteUserLovs = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._onSubmit = undefined;
      this._parentSection = undefined;
      this._isOnLeave = false;
    });
  };

  reloadSiteUser = async (): Promise<boolean> => {
    if (this.siteUserId) {
      return await this.loadSiteUser(this.siteUserId);
    }
    return false;
  };

  loadSiteUser = async (siteUserId?: number): Promise<boolean> => {
    let errorResponse = undefined;
    let newSiteUser = new NewSiteUser();
    if (newSiteUser.siteUserCredential)
      newSiteUser.siteUserCredential.CredentialType_ENUM =
        CredentialType.Exchange;
    this.setIsLoading(true);
    // Load LOVs data
    const lovResponse = await getSiteUserLOV();
    if (isSuccessResponse(lovResponse) && lovResponse.data) {
      lovResponse.data.ClickOnceVersion = convertClickOneVerEnum(
        lovResponse.data?.ClickOnceVersion
      );
      this.setSiteUserLovs(lovResponse.data);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    if (siteUserId) {
      const response = await getSiteUserById(siteUserId);
      if (isSuccessResponse(response) && response?.data) {
        newSiteUser = { ...newSiteUser, ...response.data };
        if (!newSiteUser._option) return false;
        newSiteUser._option.Contact_SiteUser.Sys_DBRowState =
          response?.data?.Contact_SiteUser?.Sys_DBRowState === "Active"
            ? true
            : false;
        this.setIsOnLeave(!!response.data?.Contact_SiteUser?.Flag_OnLeave);
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
      this.setSiteUser(newSiteUser);
    }
    this.setSiteUser(newSiteUser);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  saveSiteUser = async (siteUserInfo: NewSiteUser) => {
    siteUserInfo._option = undefined;
    this.setIsLoading(true);

    siteUserInfo.UserName = siteUserInfo.Contact_SiteUser?.MemberUserName;
    const response = await putSaveSiteUser(
      this.siteUserId as number,
      siteUserInfo
    );
    this.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      eventEmitter.emit(CCJournalEventType.RefreshData);
      eventEmitter.emit(CCSiteUserDetailTab.RefreshData);
      appNotificationStore.clearNotifications();
      if (this.siteUserId) await this.loadSiteUser(this.siteUserId);
      appNotificationStore.pushNotification({
        title: "Site user saved successfully",
        type: "success",
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Save site user failed",
        type: "error",
        description: response?.data?.Errors ?? response?.statusText,
      });
    }
  };
}

export const profileInstance = new profileStore();
const profileContext = createContext(profileInstance);
export const useProfileStore = () => {
  return useContext(profileContext);
};
