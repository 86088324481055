import { loadViewConfiguresColumns } from "@app/products/property/api";
import { VO_Charge_Run_Levy_Calculation } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levies-raised/model";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { colChargeRunLeviesRaised } from "./config";

export const ChargeRunLeviesRaised = () => {
  const { id } = useParams<{ id: string }>();
  const [colsConfigured, setColsConfigured] = useState<IColumnFields[]>([]);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const nameOf = nameOfFactory<VO_Charge_Run_Levy_Calculation>();

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Charge_Run_LevyRaised,
      colChargeRunLeviesRaised
    );
    if (Array.isArray(response)) {
      setColsConfigured(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };
  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );

  return (
    <CCGrid
      dataUrl={`/odata/property/internal/chargerunlevyraisedregister/${id}?$count=true&`}
      columnFields={colsConfigured}
      primaryField={nameOf("Levy_Id")}
      itemPerPage={10}
      groupDragMode="fixed"
      isLoading={isLoading}
      state={{ sort: [{ field: nameOf("Assess_NumberX"), dir: "asc" }] }}
    />
  );
};
