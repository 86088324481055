import { OfficerAndOfficerRegion } from "@app/products/property/components/fields/officer-and-officer-region/_index";
import { nameOfLov } from "@app/products/property/model";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { DTO_LOV } from "@common/models/odataResponse";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const ContactDetailFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isDisabled: false,
      isUpdate: false,
    },
  }: IFormStepElement) => {
    const contactLovs = useMemo(() => {
      const reason = convertValueLOVToNumber<DTO_LOV>(
        options?.contactLOVs?.Reason ?? [],
        nameOfLov("Code")
      );
      return {
        reason,
      };
    }, [options?.contactLOVs]);
    return (
      <>
        <OfficerAndOfficerRegion
          formRenderProps={formRenderProps}
          nameOf={nameOf}
          isDisabled={options?.isDisabled}
        />
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title="Applicant name" isMandatory />
              <Field
                name={nameOf("ApplicantName")}
                component={CCInput}
                readOnly={options?.isReadOnly}
                validator={options?.isReadOnly ? undefined : requiredValidator}
                placeholder="Applicant name"
              />
            </div>
            <div className="cc-field">
              <CCLabel
                title={`Reason for ${
                  options?.isUpdate ? "change" : "creation"
                }`}
                isMandatory
              />
              <Field
                name={nameOf("ReasonId")}
                component={CCSearchComboBox}
                data={contactLovs.reason}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                validator={options?.isReadOnly ? undefined : requiredValidator}
                disabled={options?.isReadOnly}
                isUseDefaultOnchange
              />
            </div>
          </div>
        </section>
      </>
    );
  }
);
