import { loadViewConfiguresColumns } from "@app/products/property/api";
import { getTitles } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/details/api";
import {
  colAssessmentParentTitles,
  getSearchTitleOptionRegex,
  getTitleSearchKey,
  searchTitleByData,
  searchTitleConfig,
} from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/details/config";
import {
  ISearchTitleBy,
  VO_Assessment_Subdivision_Title,
  eSearchTitleBy,
} from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/details/model";
import { validatorDetailSubdivide } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/details/util";
import { EKeysOfStepsSubdivideConsolidate } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/model";
import { useSubdivideConsolidateDialogStore } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/store";
import { calculateTotalLandArea } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import { ViewConfiguration, nameOfLov } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  DropDownListChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { isEmpty, isUndefined } from "lodash";
import { observer } from "mobx-react-lite";
import React, { ReactElement, useCallback, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

export const SubdivideConsolidateDetailsFormStep = (
  props: IFormStepElement
) => {
  const {
    options: { customTitleLabel = "lot" },
  } = props;
  const newValidatorDetailSubdivide = useCallback(
    (value: any) => {
      if (props?.options?.isReadOnly) return undefined;
      return validatorDetailSubdivide(value, customTitleLabel);
    },
    [customTitleLabel, props?.options?.isReadOnly]
  );
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={newValidatorDetailSubdivide}
    />
  );
};

const nameOfTitle = nameOfFactory<VO_Assessment_Subdivision_Title>();
const FormStepElement = observer(
  ({
    nameOf,
    options = { isReadOnly: false, customTitleLabel: "lot" },
    formRenderProps,
    isLoadingStep,
    setIsLoadingStep = () => {},
    loadFailedStep,
    setLoadFailedStep = () => {},
    localNotificationRef,
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const titleIDs: number[] = getFieldValue("Titles");
    const parentTitleList: VO_Assessment_Subdivision_Title[] =
      getFieldValue("_option.ParentTitleList") ?? [];
    //get current search value
    const searchByValue: ISearchTitleBy = getFieldValue(
      "_option.SearchBy.Value"
    );
    const selectedTitles: VO_Assessment_Subdivision_Title[] =
      getFieldValue("_option.SelectedTitles") ?? [];

    //Use ref
    const refTimeOut = useRef<NodeJS.Timeout | null>(null);

    //Use store
    const { subdivisionsConsolidationsLOVs, assessmentId } =
      useSubdivideConsolidateDialogStore();

    //Use state
    const [filterValue, setFilterValue] = useState<string>("");
    const [processedCol, setProcessedCol] = useState<IColumnFields[]>([]);

    //Get label
    const titleLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Title
    );

    /**
     * Load parent title data
     */
    const loadViewConfig = async () => {
      if (isUndefined(titleIDs)) return; // Avoid calling API before workflow LOVs and New
      setLoadFailedStep(undefined);
      setIsLoadingStep(true);
      const response = await loadViewConfiguresColumns(
        ViewConfiguration.Assessment_Subdivision_Parent,
        colAssessmentParentTitles
      );
      setIsLoadingStep(false);
      if (Array.isArray(response)) {
        setProcessedCol(response);
      } else {
        setLoadFailedStep({
          onReload: () => {
            loadViewConfig();
          },
          responseError: {
            status: response.status,
            error: response.error ?? "Load failed",
          },
        });
      }
    };

    /**
     * Reset search value
     */
    const resetSearchValue = () => {
      onChange(nameOf("SearchValue"), { value: "" });
      setFilterValue("");
    };

    /**
     * handle change title ID list
     * @param parenTitles
     */
    const handleChangeTitleIDsAndParcelIDs = (
      parenTitles: VO_Assessment_Subdivision_Title[]
    ) => {
      //Set title IDs
      const newTitleIDs = parenTitles.map(
        (title: VO_Assessment_Subdivision_Title) => title.Title_Id
      );
      onChange(nameOf("Titles"), {
        value: newTitleIDs,
      });

      //Set parcel IDs
      const newParcelIDs = parenTitles.map(
        (title: VO_Assessment_Subdivision_Title) => title.Parcel_Id
      );
      onChange(nameOf("Parcels"), {
        value: newParcelIDs,
      });
    };

    /**
     * handle change area of parent titles
     * @param parenTitles
     */
    const handleChangeTotalLandArea = (
      parenTitles: VO_Assessment_Subdivision_Title[]
    ) => {
      onChange(
        `${EKeysOfStepsSubdivideConsolidate.Progeny}.AreaOfParentTitles`,
        { value: calculateTotalLandArea(parenTitles, "Title_Land_Area") }
      );
    };

    //Use effect
    useEffectOnce(() => {
      loadViewConfig();
      return () => {
        resetSearchValue();
      };
    });

    /**
     * handle Search Title
     * @param event
     */
    const handleSearchTitle = (event: ComboBoxFilterChangeEvent) => {
      //get filter value
      const { filter } = event;
      const searchField = filter?.field as eSearchTitleBy;
      let filterValue: string = filter?.value;

      //reset lastIndex of regex
      getSearchTitleOptionRegex[searchField].lastIndex = 0;
      //flag check correct case
      const isCorrectFormat =
        getSearchTitleOptionRegex[searchField].test(filterValue);
      if (!isCorrectFormat) {
        //just only input number, replace words
        setFilterValue(filterValue.replace(/[^0-9]/gi, ""));
        return;
      }

      setFilterValue(filterValue);
      onChange(nameOf("_option.SearchValue.Data"), { value: [] });

      //clear previous timeout
      if (refTimeOut.current) clearTimeout(refTimeOut.current);
      refTimeOut.current = setTimeout(async function () {
        onChange(nameOf("_option.SearchValue.Loading"), { value: true });
        //check length with search corresponding with options
        if (
          filterValue?.length >= searchTitleConfig.numberLetterSearch &&
          event.filter.field
        ) {
          //call API
          const response = await getTitles(
            assessmentId ?? 0,
            getTitleSearchKey[searchField],
            filterValue
          );

          if (isSuccessResponse(response) && response?.data?.value) {
            onChange(nameOf("_option.SearchValue.Data"), {
              value: response?.data?.value,
            });
            onChange(nameOf("_option.SearchValue.Loading"), { value: false });
          } else {
            onChange(nameOf("_option.SearchValue.Data"), { value: [] });
            onChange(nameOf("_option.SearchValue.Loading"), { value: false });
          }
        } else {
          onChange(nameOf("_option.SearchValue.Data"), { value: [] });
          onChange(nameOf("_option.SearchValue.Loading"), { value: false });
        }
      }, searchTitleConfig.typeSpeed);
    };

    /**
     * Handle adding title
     * @param event
     */
    const handleAddTitle = (event: ComboBoxChangeEvent) => {
      let currentValue = event.value;
      //Prevent adding title if user clicks outside the combobox or current value is undefined
      if (event.nativeEvent instanceof FocusEvent || !currentValue) return;

      //Check duplicate
      const isDuplicate = parentTitleList.some(
        (title: VO_Assessment_Subdivision_Title) =>
          title.Title_Id === currentValue.Title_Id
      );
      if (isDuplicate) {
        localNotificationRef?.current?.pushNotification({
          title: "Selected title already exists.",
          type: "warning",
        });
        return;
      }

      const newParenTitleList = [...parentTitleList, currentValue];
      onChange(nameOf("_option.ParentTitleList"), {
        value: newParenTitleList,
      });

      handleChangeTitleIDsAndParcelIDs(newParenTitleList);
      handleChangeTotalLandArea(newParenTitleList);
      setFilterValue(
        currentValue?.[
          searchByValue?.Key ?? searchTitleByData[0].Key
        ]?.toString() ?? ""
      );
    };

    /**
     * Handle removing title
     */
    const handleRemoveTitle = () => {
      const selectedTitleIds = selectedTitles.map(
        (selectedTitle: VO_Assessment_Subdivision_Title) =>
          selectedTitle.Title_Id
      );
      const newParenTitleList = parentTitleList.filter(
        (parentTitle: VO_Assessment_Subdivision_Title) =>
          !selectedTitleIds.includes(parentTitle.Title_Id)
      );
      onChange(nameOf("_option.ParentTitleList"), {
        value: newParenTitleList,
      });
      handleChangeTitleIDsAndParcelIDs(newParenTitleList);
      handleChangeTotalLandArea(newParenTitleList);
      onChange(nameOf("_option.SelectedTitles"), { value: [] });
    };

    /**
     * process data item render in Combobox
     * @param li
     * @param itemProps
     * @returns
     */
    const itemRender = (
      li: ReactElement<HTMLLIElement>,
      itemProps: ListItemProps
    ) => {
      const { dataItem } = itemProps;
      const itemChildren = (
        <span>{`${
          searchByValue?.Key === eSearchTitleBy.ParcelReference
            ? dataItem?.Parcel_Reference ?? ""
            : dataItem?.Parcel_Id ?? ""
        } - ${dataItem?.Title_Id ?? ""} - ${
          dataItem?.Title_Legal_Description ?? ""
        } `}</span>
      );
      return React.cloneElement(li, li.props, itemChildren);
    };

    if (isLoadingStep) {
      return <Loading isLoading />;
    }

    if (loadFailedStep) {
      return (
        <CCLoadFailed
          onReload={loadFailedStep?.onReload}
          responseError={loadFailedStep?.responseError}
        />
      );
    }

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Status" isMandatory />
            <Field
              name={nameOf("Status_Id")}
              component={CCSearchComboBox}
              placeholder="Status"
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              data={
                subdivisionsConsolidationsLOVs?.SubdivisionsConsolidation_Status ??
                []
              }
              value={getDropdownValue(
                "" + getFieldValue("Status_Id"),
                subdivisionsConsolidationsLOVs?.SubdivisionsConsolidation_Status ??
                  [],
                "Code"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Status_Id"), {
                  value: event.target.value?.Code ?? null,
                });
              }}
              disabled={options?.isReadOnly}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Date</label>
            <Field
              name={nameOf("Date")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
        <section>
          <label className="cc-label">Search parcel</label>
          <div className="cc-custom-sub-panel-bar cc-estate">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">Search by</label>
                <Field
                  name={nameOf("_option.SearchBy.Value")}
                  component={CCDropDownList}
                  textField="Value"
                  data={getFieldValue("_option.SearchBy.Data")}
                  disabled={options?.isReadOnly}
                  onChange={(event: DropDownListChangeEvent) => {
                    onChange(nameOf("_option.SearchBy.Value"), {
                      value: event.target.value,
                    });
                    //reset data
                    resetSearchValue();
                  }}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Search value</label>
                <Field
                  name={nameOf("SearchValue")}
                  component={CCComboBox}
                  clearButton={false}
                  filterable
                  placeholder={`Type ${
                    searchByValue?.Value?.toLocaleLowerCase() ?? ""
                  }`}
                  textField={searchByValue?.Key ?? searchTitleByData[0].Key}
                  data={getFieldValue("_option.SearchValue.Data") ?? []}
                  loading={getFieldValue("_option.SearchValue.Loading")}
                  onFilterChange={handleSearchTitle}
                  itemRender={itemRender}
                  onChange={handleAddTitle}
                  filter={filterValue}
                  disabled={options?.isReadOnly}
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <CCLabel
            title={`Parent ${options?.customTitleLabel ?? "lot"}(s)`}
            isMandatory
          />
          {errors?.[nameOf("")] ? <Error>{errors[nameOf("")]}</Error> : null}
          <div className="cc-field">
            <CCGrid
              data={parentTitleList}
              columnFields={processedCol}
              primaryField={nameOfTitle("Title_Id")}
              selectableMode="multiple"
              selectedRows={selectedTitles}
              onSelectionChange={(
                dataItem: VO_Assessment_Subdivision_Title[]
              ) => {
                onChange(nameOf("_option.SelectedTitles"), { value: dataItem });
              }}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    type="button"
                    iconClass="fas fa-minus"
                    title={`Remove ${titleLabel}`}
                    disabled={isEmpty(selectedTitles) || options?.isReadOnly}
                    onClick={handleRemoveTitle}
                  />
                </div>
              }
              readOnly={options?.isReadOnly}
            />
          </div>
        </section>
      </section>
    );
  }
);
