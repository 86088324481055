import { DTO_Title_Detail } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfTitleDetail = nameOfFactory<DTO_Title_Detail>();
export const colTitleDetail: IColumnFields[] = [
  {
    field: nameOfTitleDetail("Family_Name"),
    title: "Family Name",
  },
  { field: nameOfTitleDetail("Given_Name"), title: "Given Name" },
  {
    field: nameOfTitleDetail("Corporate_Miscellaneous_Name"),
    title: "Corporate Miscellaneous Name",
  },
  {
    field: nameOfTitleDetail("Address"),
    title: "Address",
    isGrow: true,
  },
];
