import { DeleteButton } from "@app/core/delete/buttons/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { NewKennelButton } from "@app/products/animals/components/action-bar/buttons/new-kennels/_index";
import { AnimalActionBarNavDropdown } from "@app/products/animals/components/action-bar/nav-dropdown/_index";
import { AnimalsKennelContactsTab } from "@app/products/animals/kennels/[id]/component/reference-sidebar/contacts/_index";
import { AnimalsKennelDetailsTab } from "@app/products/animals/kennels/[id]/component/reference-sidebar/details/_index";
import { AnimalsKennelHistoryTab } from "@app/products/animals/kennels/[id]/component/reference-sidebar/history/_index";
import { AnimalsKennelMapsTab } from "@app/products/animals/kennels/[id]/component/reference-sidebar/map/_index";
import { ANIMALS_KENNEL_MANAGE_PAGE } from "@app/products/animals/kennels/[id]/constant";
import { AnimalsKennelBookmark } from "@app/products/animals/kennels/active/util";
import {
  AnimalKennelViewType,
  Svc_Animals_Kennel,
} from "@app/products/animals/kennels/model";
import {
  colAnimalsKennels,
  generateColAnimalsKennels,
} from "@app/products/animals/kennels/register/config";
import { AnimalKennelsRegisterBookmark } from "@app/products/animals/kennels/register/util";
import { kennelsRoute } from "@app/products/animals/kennels/route";
import { odataGetAnimalKennels } from "@app/products/animals/kennels/util";
import { animalRoute } from "@app/products/animals/route";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useCommonCoreStore } from "@common/stores/core/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<Svc_Animals_Kennel>();

export default observer(() => {
  const { settings } = useCommonCoreStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Animals_Form_Kennel,
    productType: PRODUCT_TYPE_NUMBER.Animals,
  });
  useCCListViewActionBar({
    title: animalRoute.name,
    leftComponents: [
      <AnimalActionBarNavDropdown category={kennelsRoute.path} />,
    ],
    centerComponents: [
      <NewKennelButton
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_NEW)}
      />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.Animals}
        dataSetFilter={MAILMERGEDATASET.Animals_Kennel}
        recordType={RECORDTYPE.Animals_Kennel}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      /**
       * Waiting for confirmation
       * Reason: on API, It does not have the setup function for itself.
       * It is used from the setup function of the Pound Register views
       **/
      // <CommunicationButtonListView
      //   productType={PRODUCT_TYPE_NUMBER.Animals}
      //   recordType={RECORDTYPE.Animals_Kennel}
      //   keyField={nameOf("ID")}
      // />,
      <DeleteButton
        recordType={RECORDTYPE.Animals_Kennel}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={ANIMALS_KENNEL_MANAGE_PAGE}
        productType={PRODUCT_TYPE.Animals}
        recordType={RECORDTYPE.Animals_Kennel}
        detail={AnimalsKennelBookmark.getsBookmarkDetail}
        displayName={AnimalsKennelBookmark.getBookmarkDisplayName}
        listViewDetail={AnimalKennelsRegisterBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          AnimalKennelsRegisterBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <AnimalsKennelDetailsTab />,
      },
      {
        title: "Map",
        component: <AnimalsKennelMapsTab />,
      },
      {
        title: "Contacts",
        component: <AnimalsKennelContactsTab />,
      },
      {
        title: "History",
        component: <AnimalsKennelHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        columnFields={generateColAnimalsKennels(colAnimalsKennels, settings)}
        dataUrl={odataGetAnimalKennels(AnimalKennelViewType.Registration)}
        primaryField={nameOf("ID")}
      />
    </LoadingPermissionWrapper>
  );
});
