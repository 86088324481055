// the DefaultValue is changed form friendly name to code name,
// so this mapping not in good practice anymore

import { customFieldsProperty } from "@app/products/property/config";
import { eProductType } from "@common/stores/products/enum";
import { ECustomColNameMap } from "@common/stores/products/model";
import { commonProductStore } from "@common/stores/products/store";

/**
 * A mapping of product types to their corresponding custom fields.
 */
export const customFields: { [key in eProductType]?: any } = {
  [eProductType.CommunityProperty]: customFieldsProperty,

  // Add other product type as needed
};

/**
 * A class that wraps the functionality of obtaining labels based on custom fields and product types.
 * @template T - The generic type representing the product type enumeration.
 */
class LabelWrapper<T extends eProductType> {
  /**
   * Creates an instance of LabelWrapper.
   * @param {T} productType - The product type.
   */
  constructor(private productType: T) {}

  /**
   * Get a label for a single custom field.
   * @param {ECustomColNameMap<T>} customFields - The custom field.
   * @returns {string} The label for the specified custom field.
   */
  getLabel(customFields: ECustomColNameMap<T>): string;

  /**
   * Get labels for an array of custom fields.
   * @param {ECustomColNameMap<T>[]} customFields - The array of custom fields.
   * @returns {string[]} An array of labels for the specified custom fields.
   */
  getLabel(customFields: ECustomColNameMap<T>[]): string[];

  /**
   * Implementation of the getLabel method.
   * Delegates the call to commonProductStore.getLabel.
   * @param {ECustomColNameMap<T> | ECustomColNameMap<T>[]} customField - The custom field or array of custom fields.
   * @returns {string | string[]} The label or array of labels for the specified custom field(s).
   */
  getLabel(customField: ECustomColNameMap<T> | ECustomColNameMap<T>[]) {
    return commonProductStore.getLabel(customField, this.productType);
  }
}

/**
 * Initializes a LabelWrapper instance for a specific product type.
 * @param {T} productType - The product type for which the LabelWrapper instance is created.
 * @returns {LabelWrapper<T>} An instance of LabelWrapper for the specified product type.
 */
const initLabelWrapper = <T extends eProductType>(productType: T) =>
  new LabelWrapper(productType);
/**
 * Represents a collection of LabelWrapper instances for different product types.
 */
export const Label = {
  [eProductType.CommunityProperty]: initLabelWrapper(
    eProductType.CommunityProperty
  ),

  //--- When applying to other product, remove the respective comment ---//

  // [eProductType.SystemInitialise]: initLabelWrapper(
  //   eProductType.SystemInitialise
  // ),
  // [eProductType.Core]: initLabelWrapper(eProductType.Core),
  // [eProductType.Contact]: initLabelWrapper(eProductType.Contact),
  // [eProductType.Action]: initLabelWrapper(eProductType.Action),
  // [eProductType.HealthManager]: initLabelWrapper(eProductType.HealthManager),
  // [eProductType.CRS]: initLabelWrapper(eProductType.CRS),
  // [eProductType.WasteWater]: initLabelWrapper(eProductType.WasteWater),
  // [eProductType.Reports]: initLabelWrapper(eProductType.Reports),
  // [eProductType.MailMerge]: initLabelWrapper(eProductType.MailMerge),
  // [eProductType.Dashboards]: initLabelWrapper(eProductType.Dashboards),
  // [eProductType.CustomerService]: initLabelWrapper(
  //   eProductType.CustomerService
  // ),
  // [eProductType.HomePage]: initLabelWrapper(eProductType.HomePage),
  // [eProductType.Complaints]: initLabelWrapper(eProductType.Complaints),
  // [eProductType.Notices]: initLabelWrapper(eProductType.Notices),
  // [eProductType.Streatrader_DONOTUSE]: initLabelWrapper(
  //   eProductType.Streatrader_DONOTUSE
  // ),
  // [eProductType.SRS_DONOTUSE]: initLabelWrapper(eProductType.SRS_DONOTUSE),
  // [eProductType.EPlanning]: initLabelWrapper(eProductType.EPlanning),
  // [eProductType.WasteWaterSPI]: initLabelWrapper(eProductType.WasteWaterSPI),
  // [eProductType.ELF]: initLabelWrapper(eProductType.ELF),
  // [eProductType.DevApps]: initLabelWrapper(eProductType.DevApps),
  // [eProductType.MaternalChildHealth]: initLabelWrapper(
  //   eProductType.MaternalChildHealth
  // ),
  // [eProductType.Building]: initLabelWrapper(eProductType.Building),
  // [eProductType.TownPlanning]: initLabelWrapper(eProductType.TownPlanning),
  // [eProductType.LLPermits]: initLabelWrapper(eProductType.LLPermits),
  // [eProductType.Animals]: initLabelWrapper(eProductType.Animals),
  // [eProductType.CRSPortal]: initLabelWrapper(eProductType.CRSPortal),
  // [eProductType.FSR]: initLabelWrapper(eProductType.FSR),
  // [eProductType.CSM]: initLabelWrapper(eProductType.CSM),
  // [eProductType.EHealth]: initLabelWrapper(eProductType.EHealth),
  // [eProductType.StreetProtection]: initLabelWrapper(
  //   eProductType.StreetProtection
  // ),
  // [eProductType.AppointmentManagementSystem]: initLabelWrapper(
  //   eProductType.AppointmentManagementSystem
  // ),
  // [eProductType.EventManagementSystem]: initLabelWrapper(
  //   eProductType.EventManagementSystem
  // ),
  // [eProductType.PortalCore]: initLabelWrapper(eProductType.PortalCore),
  // [eProductType.AppointmentBookingSystem]: initLabelWrapper(
  //   eProductType.AppointmentBookingSystem
  // ),
  // [eProductType.EventBookingSystem]: initLabelWrapper(
  //   eProductType.EventBookingSystem
  // ),
  // [eProductType.FoodAct]: initLabelWrapper(eProductType.FoodAct),
  // [eProductType.NSPR]: initLabelWrapper(eProductType.NSPR),
  // [eProductType.Radiation]: initLabelWrapper(eProductType.Radiation),
  // [eProductType.StandardsAndRegulation]: initLabelWrapper(
  //   eProductType.StandardsAndRegulation
  // ),
  // [eProductType.DWCMS]: initLabelWrapper(eProductType.DWCMS),
  // [eProductType.FleetBooking]: initLabelWrapper(eProductType.FleetBooking),
  // [eProductType.CommunityAsset]: initLabelWrapper(eProductType.CommunityAsset),
  // [eProductType.LandManagement]: initLabelWrapper(eProductType.LandManagement),
  // [eProductType.FakeProduct]: initLabelWrapper(eProductType.FakeProduct),
  // [eProductType.Agent]: initLabelWrapper(eProductType.Agent),
  // [eProductType.ThirdParty]: initLabelWrapper(eProductType.ThirdParty),
  // [eProductType.DISRES]: initLabelWrapper(eProductType.DISRES),
  // [eProductType.CM_DONOTUSE]: initLabelWrapper(eProductType.CM_DONOTUSE),
  // [eProductType.CC]: initLabelWrapper(eProductType.CC),
  // [eProductType.PAM]: initLabelWrapper(eProductType.PAM),
  // [eProductType.ITSupport]: initLabelWrapper(eProductType.ITSupport),
  // [eProductType.CRMS_HardRubbish]: initLabelWrapper(
  //   eProductType.CRMS_HardRubbish
  // ),
  // [eProductType.CRMS_PhoneMessages]: initLabelWrapper(
  //   eProductType.CRMS_PhoneMessages
  // ),
  // [eProductType.CRMS_Payroll]: initLabelWrapper(eProductType.CRMS_Payroll),
  // [eProductType.CRSCentreAccess]: initLabelWrapper(
  //   eProductType.CRSCentreAccess
  // ),
  // [eProductType.TrainingManager]: initLabelWrapper(
  //   eProductType.TrainingManager
  // ),
  // [eProductType.TelephoneActivity]: initLabelWrapper(
  //   eProductType.TelephoneActivity
  // ),
  // [eProductType.Asset]: initLabelWrapper(eProductType.Asset),
  // [eProductType.Property]: initLabelWrapper(eProductType.Property),
  // [eProductType.Payroll]: initLabelWrapper(eProductType.Payroll),
  // [eProductType.Receipting]: initLabelWrapper(eProductType.Receipting),
  // [eProductType.HumanResource]: initLabelWrapper(eProductType.HumanResource),
  // [eProductType.GIS]: initLabelWrapper(eProductType.GIS),
  // [eProductType.Infringement]: initLabelWrapper(eProductType.Infringement),
  // [eProductType.Project]: initLabelWrapper(eProductType.Project),
  // [eProductType.CouncilOnline]: initLabelWrapper(eProductType.CouncilOnline),
  // [eProductType.SSMS]: initLabelWrapper(eProductType.SSMS),
  // [eProductType.Records]: initLabelWrapper(eProductType.Records),
  // [eProductType.OtherSystems]: initLabelWrapper(eProductType.OtherSystems),
  // [eProductType.Hazard]: initLabelWrapper(eProductType.Hazard),
  // [eProductType.Cemeteries]: initLabelWrapper(eProductType.Cemeteries),
  // [eProductType.OpenOfficeCRM]: initLabelWrapper(eProductType.OpenOfficeCRM),
  // [eProductType.Infringements]: initLabelWrapper(eProductType.Infringements),
};
