import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { CCDialog } from "../cc-dialog/_index";
import "./ConfirmDialog.scss";

export interface IConfirmDialog {
  title: string;
  message?: string | JSX.Element;
  subMessage?: string | JSX.Element;
  height?: string | number;
  width?: string | number;
  maxHeight?: string | number;
  btnYesTitle?: string;
  btnNoTitle?: string;
  maxWidth?: string | number;
  isLoadingYes?: boolean;
  isLoadingNo?: boolean;
  btnCancelVisible?: boolean;
  onClosePopup: () => void;
  onConfirmYes?: (data?: any) => void;
  onAsyncConfirm?: (e?: any) => void;
  onConfirmNo?: () => void;
  btnYesId?: string;
  btnNoVisible?: boolean;
  unsetMinHeight?: boolean;
}

export const ConfirmDialog = ({
  message,
  subMessage,
  title,
  width,
  height,
  maxWidth,
  maxHeight,
  onClosePopup,
  onConfirmYes,
  onAsyncConfirm,
  onConfirmNo,
  isLoadingYes,
  isLoadingNo,
  btnYesTitle,
  btnNoTitle,
  btnCancelVisible = true,
  btnYesId,
  btnNoVisible = true,
  unsetMinHeight = true,
}: IConfirmDialog) => {
  return (
    <div className="cc-confirm-dialog">
      <CCDialog
        unsetMinHeight={unsetMinHeight}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        width={width}
        disabled={isLoadingYes || isLoadingNo}
        height={height}
        titleHeader={title}
        onClose={onClosePopup}
        bodyElement={
          <div className="cc-confirm-dialog-body">
            {message && <h5 className="cc-label">{message}</h5>}
            {subMessage && (
              <span className="cc-dialog-sub-message">{subMessage}</span>
            )}
          </div>
        }
        footerElement={
          <div className="cc-dialog-footer-actions-right">
            {onConfirmNo && btnCancelVisible ? (
              <Button
                className="cc-dialog-button"
                onClick={() => onClosePopup()}
              >
                Cancel
              </Button>
            ) : null}
            {btnNoVisible && (
              <Button
                className="cc-dialog-button"
                iconClass={isLoadingNo ? "fas fa-spinner fa-spin" : ""}
                onClick={() => (onConfirmNo ? onConfirmNo() : onClosePopup())}
              >
                {btnNoTitle ?? "No"}
              </Button>
            )}
            <Button
              className="cc-dialog-button"
              themeColor="primary"
              id={btnYesId}
              iconClass={isLoadingYes ? "fas fa-spinner fa-spin" : ""}
              onClick={(e) => {
                if (onAsyncConfirm) {
                  onAsyncConfirm(e);
                } else {
                  onConfirmYes && onConfirmYes();
                  onClosePopup();
                }
              }}
            >
              {btnYesTitle ?? "Yes"}
            </Button>
          </div>
        }
      />
    </div>
  );
};

ConfirmDialog.defaultProps = {
  width: "auto",
  height: "auto",
  maxWidth: "50%",
  maxHeight: "40%",
};
