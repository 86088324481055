import { OfficerPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/_index";
import { getSiteUser } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/api";
import { IOfficer } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { putCompleteAction } from "@common/pages/actions/components/action-bar/buttons/complete-action/api";
import { useActionCompleteButtonStore } from "@common/pages/actions/components/action-bar/buttons/complete-action/store";
import {
  IActionComplete,
  IActionCompleteSubmitData,
} from "@common/pages/actions/components/action-bar/dialog/complete-action/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
export interface IActionCompleteDialogProps {
  onClosePopup: () => void;
  onSubmit: () => void;
}

export const ActionCompleteDialog = observer(
  ({ onClosePopup, onSubmit }: IActionCompleteDialogProps) => {
    const {
      initialValues,
      initCompleteBy,
      initCompleteByID,
      responseLoadError,
      loadActionCompleteDetails,
      isLoadingOnDialog,
    } = useActionCompleteButtonStore();
    const { currentUserInfo } = useGlobalStore();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const { settings } = useCommonCoreStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [completeBy, setCompleteBy] = useState(initCompleteBy);
    const [completeByID, setCompleteByID] = useState<number | undefined>(
      initCompleteByID
    );
    const nameOf = nameOfFactory<IActionComplete>();
    const { id } = useParams<{ id: string }>();

    const isShowAlertRequestor = getBoolValueSetting(
      settings[
        ECorporateSettingsField
          .CorporateSettings_Action_EnableActionCompletionEmailAlert
      ]
    );

    const handleSubmit = async (event: FormSubmitClickEvent) => {
      setIsLoading(true);
      const data = event.values;
      if (completeByID) {
        const dataSubmit: IActionCompleteSubmitData = {
          DateCompleted: data.DateCompleted,
          Details: data.Details,
          CompletedBy_ID: completeByID,
          AlertRequestor: data.AlertRequestor,
        };
        const response = await putCompleteAction(+id, dataSubmit);
        if (isSuccessResponse(response)) {
          if (
            response.data?.ErrorStatus === IdentityPacketErrorStatus.Success
          ) {
            onSubmit();
          } else {
            notificationRef.current?.pushNotification({
              autoClose: false,
              title: "Action Complete failed",
              type: "error",
              description: response.data?.Errors,
            });
          }
        } else {
          notificationRef.current?.pushNotification({
            autoClose: false,
            title: "Action Complete failed",
            type: "error",
          });
        }
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: "Completed By failed",
          type: "error",
        });
      }

      setIsLoading(false);
    };

    return (
      <Form
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        onSubmitClick={handleSubmit}
        render={({
          onSubmit,
          modified,
          valid,
          onChange,
          valueGetter,
        }: FormRenderProps) => {
          const handleOnPickCompleteBy = (value: IOfficer) => {
            getSiteUser(value.ID).then((response) => {
              if (isSuccessResponse(response)) {
                if (response.data) {
                  setCompleteBy(
                    response.data.FirstName + " " + response.data.LastName
                  );
                  setCompleteByID(response.data.Contact_ID);
                  onChange(nameOf("CompletedBy"), {
                    value: value,
                  });
                }
              } else {
                notificationRef.current?.pushNotification({
                  title: "Get complete person failed",
                  type: "error",
                  autoClose: false,
                });
              }
            });
          };

          return (
            <CCDialog
              maxWidth="45%"
              height="auto"
              disabled={isLoading}
              titleHeader={"Completion Details"}
              onClose={onClosePopup}
              isLoading={isLoadingOnDialog}
              bodyElement={
                <>
                  {responseLoadError ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={() => {
                        loadActionCompleteDetails(currentUserInfo, true);
                      }}
                    />
                  ) : (
                    <FormElement className="cc-form">
                      <CCLocalNotification ref={notificationRef} />
                      <section className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">
                              Completion details
                              <CCTooltip type="validator" position="right" />
                            </label>
                            <Field
                              placeholder={"Completion details"}
                              name={nameOf("Details")}
                              rows={4}
                              component={CCTextArea}
                              validator={requiredValidator}
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-3">
                          <div className="cc-field">
                            <label className="cc-label">
                              Date
                              <CCTooltip type="validator" position="right" />
                            </label>
                            <Field
                              name={nameOf("DateCompleted")}
                              component={CCDateTimePicker}
                              format={DATETIME_FORMAT.DATETIME_CONTROL}
                              validator={requiredValidator}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              Complete by
                              <CCTooltip type="validator" position="right" />
                            </label>
                            <Field
                              name={nameOf("CompletedBy")}
                              value={completeBy}
                              component={OfficerPicker}
                              placeholder={"Complete by"}
                              onPickOfficer={handleOnPickCompleteBy}
                            />
                          </div>
                        </div>
                        {isShowAlertRequestor && (
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <label className="cc-label">
                                Alert requestor of completion?
                              </label>
                              <Field
                                name={nameOf("AlertRequestor")}
                                component={CCSwitch}
                                checked={valueGetter(nameOf("AlertRequestor"))}
                              />
                            </div>
                          </div>
                        )}
                      </section>
                    </FormElement>
                  )}
                </>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    className={"cc-dialog-button"}
                    onClick={() => {
                      onClosePopup();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!modified || !valid}
                    themeColor="primary"
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    onClick={onSubmit}
                    className={"cc-dialog-button"}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
