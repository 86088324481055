import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useCCSubActionBarStore } from "./store";
import { getUrlKey, removePinSubAction, setPinSubAction } from "./util";
import "./_index.scss";

export interface ICCSubActionBarProps {
  hasPinner?: boolean;
}

export const CCSubActionBar = observer(
  ({ hasPinner = true }: ICCSubActionBarProps) => {
    const { pathname } = useLocation();
    const urlKey = getUrlKey(pathname);
    const { isPin, children, setIsPin, menuName, isWorkflow } =
      useCCSubActionBarStore();

    useEffect(() => {
      if (!menuName) return;
      if (isPin) {
        if (isPin) setPinSubAction(urlKey, menuName);
      } else {
        removePinSubAction(urlKey);
      }
    }, [children, menuName, isPin, urlKey]);

    const renderElement = (
      element: ReactElement | ReactElement[] | undefined
    ) => {
      if (!element) return null;
      return Array.isArray(element)
        ? element.map((comp, key: number) => (
            <React.Fragment key={key}>{comp}</React.Fragment>
          ))
        : element;
    };
    return (
      <div className="cc-sub-action-bar-container">
        {children ? (
          <div
            className={`cc-sub-action-bar ${
              isWorkflow ? "cc-sub-action-bar-workflow" : ""
            }`}
          >
            <div className="cc-sub-action-bar-left">
              {renderElement(children)}
            </div>
            {hasPinner ? (
              <div className="cc-sub-action-bar-right">
                <CCNavButton
                  iconClass={"fal fa-thumbtack"}
                  title={"Pinter"}
                  isActive={isPin}
                  onClick={() => {
                    setIsPin(!isPin);
                  }}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
);
