import { odataCoreGetInvoiceItem } from "@app/core/components/common/utils";
import { getInvoiceItemColumnConfig } from "@app/core/invoice/invoice-item-accordion/api";
import { AddInvoiceItemButton } from "@app/core/invoice/invoice-item-accordion/components/button/add-invoice-item-button/_index";
import { DeleteInvoiceItemButton } from "@app/core/invoice/invoice-item-accordion/components/button/delete-invoice-item-button/_index";
import { colInvoiceItemConfig } from "@app/core/invoice/invoice-item-accordion/config";
import {
  EViewConfiguration,
  Fees,
} from "@app/core/invoice/invoice-item-accordion/model";
import { ICCViewColumn } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { FINANCEMETHOD } from "@common/constants/enumerations";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { totalColumnField } from "@common/utils/grid";
import { CCGrid } from "@components/cc-grid/_index";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IInvoiceItemListProps {
  id?: number | string;
  recordType: RECORDTYPE;
}

const nameOf = nameOfFactory<Fees>();
const InvoiceItems: React.FC<IInvoiceItemListProps> = ({ id, recordType }) => {
  const [gridSelectedRows, setGridSelectedRows] = useState<Fees[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [colInvoiceItem, setColInvoiceItem] = useState<IColumnFields[]>([]);
  const [responseLoadError, setResponseLoadError] =
    useState<APIResponseError>();

  const { settings } = useCommonCoreStore();

  const isEnableMultiLineInvoice = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
  );
  const isEnableUpToGlassFinance = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableUpToGlassFinance]
  );
  const financeMethod =
    getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_FinanceMethod]
    ) ?? FINANCEMETHOD.None;

  const loadInvoiceItemConfig = async () => {
    let isShowFooter = true;
    setIsLoading(true);
    const response = await getInvoiceItemColumnConfig(
      RECORDTYPE.CORE_Invoice,
      EViewConfiguration.TabTable_Fees
    );
    setIsLoading(false);
    if (isSuccessResponse(response) && response.data) {
      const newColInvoiceItem = processDynamicColumns(
        colInvoiceItemConfig,
        response.data?.ReturnObj?.ColumnDefinitions as ICCViewColumn[]
      );

      // show footer logic
      const listFieldHasFooter: (keyof Fees)[] = [
        nameOf("Amount"),
        nameOf("Owing"),
        nameOf("FinalAmount"),
        nameOf("SubsidyAmount"),
      ];
      let fieldsHasFooter = newColInvoiceItem.filter(
        (columnField: IColumnFields) =>
          listFieldHasFooter.includes(columnField.field as keyof Fees)
      );

      if (isEnableMultiLineInvoice) {
        if (recordType === RECORDTYPE.CORE_Invoice) isShowFooter = true;
      } else {
        if (
          recordType === RECORDTYPE.INFRINGEMENTS_InfringementTicket ||
          recordType === RECORDTYPE.CORE_Invoice
        ) {
          isShowFooter = true;
        } else {
          isShowFooter = !(
            isEnableUpToGlassFinance && financeMethod === FINANCEMETHOD.Direct
          );
        }
      }

      if (fieldsHasFooter.length > 0 && isShowFooter) {
        fieldsHasFooter.forEach((columnField: IColumnFields) => {
          columnField.calculateFooterCellValue = (
            props: GridFooterCellProps,
            gridData: any
          ) => totalColumnField(columnField.field, gridData);
          columnField.footerCell = <CurrencyCell />;
        });
      }

      setColInvoiceItem(newColInvoiceItem);
    } else {
      setResponseLoadError({
        status: response.status,
        error: response.data?.Errors ?? response.error,
      });
    }
  };

  // Load Invoice Item config at the first time
  useEffectOnce(() => {
    loadInvoiceItemConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => loadInvoiceItemConfig()}
      />
    );

  return (
    <CCGrid
      selectableMode="single"
      columnFields={colInvoiceItem}
      primaryField={nameOf("ID")}
      dataUrl={odataCoreGetInvoiceItem(id ?? 0, recordType)}
      state={{ sort: [{ field: nameOf("ID"), dir: "desc" }] }}
      selectedRows={gridSelectedRows}
      onSelectionChange={(dataItem: Fees[]) => {
        setGridSelectedRows([...dataItem]);
      }}
      isLoading={isLoading}
      toolbar={
        <div className="cc-grid-tools-bar">
          <AddInvoiceItemButton />
          <DeleteInvoiceItemButton
            recordType={RECORDTYPE.CORE_Fees}
            gridSelectedRows={gridSelectedRows}
            setGridSelectedRows={setGridSelectedRows}
          />
        </div>
      }
    />
  );
};

export default observer(InvoiceItems);
