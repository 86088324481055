import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { RescheduleMapObj } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/details-reschedule/model";
import { renderOptionRequestor } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/config";
import { WebRequest } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/web-request-accordion/_index";
import {
  CS_EventStatus,
  CS_RequestorType,
  Event,
  EventHandlerRequest,
  LookupTable,
  Svc_EventFormAction,
} from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { isFieldVisible } from "@app/products/crms/util";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { ACCESSRIGHTS } from "@common/constants/enumerations";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { Svc_ContactDetails } from "@common/pages/profile/components/input-picker/officer-picker/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { generateSharepointURL } from "@common/utils/sharepoint";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Field } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import "./_index.scss";

const nameOfRescheduleMapObj = nameOfFactory<RescheduleMapObj>();
const nameOfEvent = nameOfFactory<Event>();
const nameOfLookupTable = nameOfFactory<LookupTable>();

export interface IDetailsProps {
  eventFormObject: Event;
}

export const Details = observer(({ eventFormObject }: IDetailsProps) => {
  const {
    uiControl,
    crmsEvent,
    crmsEventLovs,
    eventChangeHandler,
    setIsExpandedAdditionalDetails,
    accessRights,
  } = useCRMSEventStore();

  const { settings } = useCommonCoreStore();

  const isDisable =
    crmsEvent?.EventStatus_ENUM === CS_EventStatus.Closed ||
    accessRights === ACCESSRIGHTS.CanRead;

  const listPanelBar: ITabProps[] = [
    {
      title: "Web request",
      component: <WebRequest />,
    },
  ];
  const actionOfficerLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_ActionOfficerLabel]
  );
  const coordinatorLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_CoordinatorLabel]
  );

  const handleReceivedByChange = (value: Svc_ContactDetails | null) => {
    let params: EventHandlerRequest = {
      EventFormAction: Svc_EventFormAction.Form_PickReceivedBy,
      Event: eventFormObject,
      EventArgs: {
        ReceivedBy_SiteUser_ID: value?.Id,
      },
      isFirstTimeLoad: false,
    };
    eventChangeHandler(params, "Change received by fail");
    setIsExpandedAdditionalDetails(true);
  };

  const handleClearParentFileNo = () => {
    const params: EventHandlerRequest = {
      EventFormAction: Svc_EventFormAction.Form_ClearParentFileNumber,
      Event: { ...eventFormObject, EventRecords_ParentFileNumber: "" },
      EventArgs: {},
      isFirstTimeLoad: false,
    };
    eventChangeHandler(params, "Clear parent file number fail");
    setIsExpandedAdditionalDetails(true);
  };

  return (
    <div>
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCValueField
              label={
                actionOfficerLabel
                  ? `${actionOfficerLabel}:`
                  : "Action officer:"
              }
              value={crmsEvent?.ActionOfficer_DisplayName ?? "Unassigned"}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label={coordinatorLabel ? `${coordinatorLabel}:` : "Coordinator:"}
              value={crmsEvent?.Coordinator_DisplayName ?? "Unassigned"}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Received on" />
            <Field
              name={`${nameOfRescheduleMapObj("Event")}.${nameOfEvent(
                "DateReceived"
              )}`}
              component={CCDateTimePicker}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              disabled={isDisable}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="First action date" />
            <Field
              name={`${nameOfRescheduleMapObj("Event")}.${nameOfEvent(
                "FirstActionDate"
              )}`}
              component={CCDateTimePicker}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              disabled={isDisable}
            />
          </div>

          <div className="cc-field">
            <CCLabel title="Received by" isMandatory />
            <Field
              name={`${nameOfRescheduleMapObj("Event")}.${nameOfEvent(
                "ReceivedBy_SiteUser_DisplayName"
              )}`}
              placeholder="Select received by"
              component={InputPickerSearch}
              onChange={handleReceivedByChange}
              validator={requiredValidator}
              options={renderOptionRequestor(CS_RequestorType.InternalPerson)}
              value={crmsEvent?.ReceivedBy_SiteUser_DisplayName}
              disabled={isDisable}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCValueField
              value={crmsEvent?.DateRecorded}
              format={DATETIME_FORMAT.DATETIME}
              label="Recorded on"
            />
          </div>
          <div className="cc-field">
            <CCValueField
              value={crmsEvent?.RecordedBy_SiteUser_DisplayName}
              label="Recorded by"
            />
          </div>
          <div className="cc-field">
            <CCValueField
              value={crmsEvent?.ServiceStandard?.OrgStructure}
              label="Org structure"
            />
          </div>
        </div>
      </div>

      {isFieldVisible(uiControl?.DivWorkflowRecords?.DisplayStatus) && (
        <>
          <hr className="cc-divider" />
          <div className="cc-field-group">
            <div className="cc-form-cols-3">
              {isFieldVisible(
                uiControl?.DivWorkflowRecordsClassification?.DisplayStatus
              ) && (
                <div className="cc-field">
                  <CCValueField
                    name={`${nameOfRescheduleMapObj("Event")}.${nameOfEvent(
                      "SSRecords_Classification"
                    )}`}
                    value={uiControl?.TxtWorkflowRecordsSSClassification.Value}
                    label="Records classification"
                  />
                </div>
              )}
              <div className="cc-field">
                <CCValueField
                  name={`${nameOfRescheduleMapObj("Event")}.${nameOfEvent(
                    "SSRecords_FileNumber"
                  )}`}
                  value={
                    uiControl?.TxtWorkflowRecordsSSFileNumber.Value ??
                    eventFormObject.ServiceStandard?.Records_CategoryCode
                  }
                  label={uiControl?.FlWorkflowRecordsSSFileNoLabel.Value}
                />
              </div>
              {isFieldVisible(
                uiControl?.DivRecordsFileNumber?.DisplayStatus
              ) && (
                <div className="cc-field">
                  <label className="cc-label">Event file number</label>
                  {crmsEvent?.EventRecords_FileNumber && (
                    <div className="cc-label-field cc-link-field">
                      <a
                        href={generateSharepointURL(
                          settings,
                          RECORDTYPE.CUSTOMERSERVICE_Event,
                          crmsEvent?.EventRecords_FileNumber
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cc-grid-link-text cc-label-field"
                      >
                        {crmsEvent?.EventRecords_FileNumber ?? ""}
                        <i className="fas fa-external-link-alt fa-xs cc-external-link-icon-sharepoint " />
                      </a>
                    </div>
                  )}
                </div>
              )}
              {isFieldVisible(
                uiControl?.DivRecordsInfoXpert?.DisplayStatus
              ) && (
                <div className="cc-field">
                  {isFieldVisible(uiControl?.TxtFileNo?.DisplayStatus) && (
                    <CCValueField
                      name={`${nameOfRescheduleMapObj("Event")}.${nameOfEvent(
                        "EventRecords_FileNumber"
                      )}`}
                      label="File number"
                      value={crmsEvent?.EventRecords_FileNumber}
                    />
                  )}
                  {isFieldVisible(uiControl?.AfileNumber?.DisplayStatus) && (
                    <>
                      <CCLabel title="File number" />
                      <a
                        href={`${uiControl?.AfileNumber.Value}${uiControl?.TxtFileNo.Value}`}
                      >
                        {uiControl?.TxtFileNo.Value}
                      </a>
                    </>
                  )}
                </div>
              )}

              {isFieldVisible(
                uiControl?.DivRecordsParentFileNumber?.DisplayStatus
              ) && (
                <div className="cc-field">
                  <CCValueField
                    name={`${nameOfRescheduleMapObj("Event")}.${nameOfEvent(
                      "EventRecords_ParentFileNumber"
                    )}`}
                    value={uiControl?.TxtWorkFlowRecordsParentFileNumber?.Value}
                    label="Parent file number"
                    tooltip={
                      <CCTooltip
                        type="custom"
                        content="Clear parent file number"
                        position="right"
                      >
                        <i
                          className="fas fa-times cc-clear-parent-file-no-button"
                          onClick={handleClearParentFileNo}
                        ></i>
                      </CCTooltip>
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {isFieldVisible(uiControl?.DivAcknowledgement?.DisplayStatus) && (
        <>
          <hr className="cc-divider" />
          <div className="cc-field-group">
            <div className="cc-form-cols-3">
              {isFieldVisible(
                uiControl?.LblAcknowledgementTarget?.DisplayStatus
              ) && (
                <div className="cc-field">
                  <CCValueField
                    value={uiControl?.LblAcknowledgementTarget?.Value}
                    label="Acknowledged target"
                    format={DATE_FORMAT.DATE}
                  />
                </div>
              )}
              <div className="cc-field">
                <CCLabel title="Acknowledged date" />
                <Field
                  name={`${nameOfRescheduleMapObj("Event")}.${nameOfEvent(
                    "DateAcknowledged"
                  )}`}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  disabled={isDisable}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Acknowledged how" />
                <Field
                  name={`${nameOfRescheduleMapObj("Event")}.${nameOfEvent(
                    "AcknowledgedBy_ID"
                  )}`}
                  component={CCSearchComboBox}
                  data={crmsEventLovs?.AcknowledgedHow ?? []}
                  textField={nameOfLookupTable("Description")}
                  dataItemKey={nameOfLookupTable(
                    "CustomerService_LookupTable_Id"
                  )}
                  placeholder="Select acknowledged how"
                  isUseDefaultOnchange
                  disabled={isDisable}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {isFieldVisible(uiControl?.DivReschedule?.DisplayStatus) && (
        <>
          <hr className="cc-divider" />
          <div className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Reschedule" />
                <Field
                  name={`${nameOfRescheduleMapObj("Event")}.${nameOfEvent(
                    "DateRescheduled"
                  )}`}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  disabled
                />
              </div>

              <div className="cc-col-span-2">
                <div className="cc-field">
                  <CCLabel title="Reason" />
                  <Field
                    name={`${nameOfRescheduleMapObj("Event")}.${nameOfEvent(
                      "RescheduledReason"
                    )}`}
                    placeholder="Rescheduled reason"
                    rows={2}
                    component={CCTextArea}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Web request accordion */}
      {isFieldVisible(uiControl?.DivWebServiceRequest?.DisplayStatus) && (
        <div className="cc-custom-sub-panel-bar">
          <TabTable
            recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
            initialPanels={listPanelBar}
          />
        </div>
      )}
    </div>
  );
});
