import { hiddenSomeInputsTypes } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/components/form-elements/detail/config";
import { EKeysOfSteps } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isLLS: false,
    },
  }: IFormStepElement) => {
    const { valueGetter } = formRenderProps;
    const changeOfOwnershipSelectedType = valueGetter(
      `${EKeysOfSteps.Type}.Type`
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field cc-col-span-2">
            <CCLabel title="Reference" />
            <Field
              name={nameOf("Reference")}
              component={CCInput}
              placeholder="Reference"
              readOnly={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Effective date" />
            <Field
              name={nameOf("EffectiveDate")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
            />
          </div>
          {!hiddenSomeInputsTypes.includes(changeOfOwnershipSelectedType) && (
            <>
              <div className="cc-field">
                <CCLabel title="Sale price" />
                <Field
                  name={nameOf("SalePrice")}
                  component={CCCurrencyInput}
                  disabled={options?.isReadOnly}
                  min={0}
                />
              </div>

              <div className="cc-field">
                <CCLabel title="Contract date" />
                <Field
                  name={nameOf("ContractDate")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDatePicker}
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Chattels" />
                <Field
                  name={nameOf("Chattels")}
                  component={CCCurrencyInput}
                  disabled={options?.isReadOnly}
                  min={0}
                />
              </div>
            </>
          )}
          <div className="cc-field cc-col-span-2">
            <CCLabel title="Comment" />
            <Field
              name={nameOf("Comment")}
              component={CCTextArea}
              rows={3}
              placeholder="Comment"
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>
      </section>
    );
  }
);
