import { eventEmitter } from "@/App";
import { RenderSend } from "@app/core/communication/dialogs/components/cells/send/_index";
import { IToStep } from "@app/core/communication/dialogs/components/form-elememts/to/model";
import { useToStepStore } from "@app/core/communication/dialogs/components/form-elememts/to/store";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IToStep>();
export const ConfigToStep = () => {
  const { preferred } = useToStepStore();
  const colCommunicationTo: IColumnFields[] = [
    {
      field: nameOf("Send"),
      title: "Send",
      cell: RenderSend,
      width: preferred.useSMS && preferred.useEmail ? "90px" : "70px",
    },
    {
      field: nameOf("Contact_FullName"),
      title: "Full Name",
      width: "300px",
      handleOnClick(dataRow) {
        eventEmitter.emit("editRecipientCommunication", dataRow);
      },
    },
    {
      field: nameOf("ContactRelationshipType_Name"),
      title: "Contact Relationship Type",
      width: "250px",
    },
    {
      field: nameOf("Email"),
      title: "Email",
      width: "250px",
    },
    {
      field: nameOf("_Mobile"),
      title: "Mobile",
      width: "200px",
    },
    {
      field: nameOf("PreferredMethod_ENUM"),
      title: "Preferred",
      width: "100px",
    },
  ];
  return colCommunicationTo;
};
