import { useCertificateStore } from "@app/products/property/certificates/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ExistedPropertyCertificate } from "./components/forms/existed/_index";
import { NewPropertyCertificate } from "./components/forms/new/_index";

function ManagePropertyCertificate() {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const certificateId = params.id;
  const { resetMenu } = useCCSubActionBarStore();
  const { resetStore, loadCertificate, isLoading } = useCertificateStore();
  const location = useLocation();
  const state: any = location?.state;

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
    };
  });

  useEffect(() => {
    if (isNew) return;
    let notification = undefined;
    if (state) {
      notification = state?.notification;
      window.history.replaceState({}, "");
    }
    loadCertificate(+certificateId, notification);
  }, [certificateId, isNew, loadCertificate, state]);

  if (isLoading) return <Loading isLoading isFullScreen />;

  if (isNew) {
    return <NewPropertyCertificate />;
  }
  return <ExistedPropertyCertificate />;
}

export default observer(ManagePropertyCertificate);
