import { InvoiceDetailsTab } from "@app/core/invoice/[id]/components/reference-sidebar/details/_index";
import { InvoiceHistoryTab } from "@app/core/invoice/[id]/components/reference-sidebar/history/_index";
import { INVOICE_MANAGE_ROUTE } from "@app/core/invoice/[id]/constant";
import { AnimalActionBarNavDropdown } from "@app/products/animals/components/action-bar/nav-dropdown/_index";
import { FinanceExportInvoice } from "@app/products/animals/finance/components/export-to-finance/_index";
import { generateAnimalsFinanceViewBySetting } from "@app/products/animals/finance/config";
import { getAnimalsFinanceListViewOdata } from "@app/products/animals/finance/constant";
import { AnimalFinanceInvoicesNotSentToFinanceBookmark } from "@app/products/animals/finance/invoices-not-sent-to-finance/util";
import {
  AnimalFinanceViewType,
  IAnimalsFinanceViewBySettingProps,
  Svc_Animals_Finance,
} from "@app/products/animals/finance/model";
import { animalRoute } from "@app/products/animals/route";
import { ProductType } from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCommonCoreStore } from "@common/stores/core/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";
import { financeRoute } from "../route";
import { colAnimalsFinanceInvoicesNotSentToFinance } from "./config";

const nameOf = nameOfFactory<Svc_Animals_Finance>();
export default observer(() => {
  const { settings } = useCommonCoreStore();

  useCCListViewActionBar({
    title: animalRoute.name,
    leftComponents: [
      <AnimalActionBarNavDropdown category={financeRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton title="Finance" type="sub">
        <FinanceExportInvoice productType={ProductType.Animals} isAllRecords />
        <FinanceExportInvoice productType={ProductType.Animals} />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={INVOICE_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.Animals}
        recordType={RECORDTYPE.CORE_Invoice}
        detail={
          AnimalFinanceInvoicesNotSentToFinanceBookmark.getBookmarkListViewDisplayName
        }
        displayName={
          AnimalFinanceInvoicesNotSentToFinanceBookmark.getBookmarkListViewDetailRecord
        }
        listViewDetail={
          AnimalFinanceInvoicesNotSentToFinanceBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          AnimalFinanceInvoicesNotSentToFinanceBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <InvoiceDetailsTab /> },
      {
        title: "History",
        component: <InvoiceHistoryTab />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={generateAnimalsFinanceViewBySetting({
        settings,
        defaultConfig: colAnimalsFinanceInvoicesNotSentToFinance,
        listType: AnimalFinanceViewType.InvoiceNotSentToFinance,
      } as IAnimalsFinanceViewBySettingProps)}
      dataUrl={getAnimalsFinanceListViewOdata(
        AnimalFinanceViewType.InvoiceNotSentToFinance
      )}
      primaryField={nameOf("ID")}
      state={{
        sort: [{ field: nameOf("InvoiceNumber"), dir: "desc" }],
      }}
    />
  );
});
