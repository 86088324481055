import { loadViewConfiguresColumns } from "@app/products/property/api";
import {
  colParcels,
  colUnaffectedParcels,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/components/form-elements/parcels-to-be-split/config";
import { DTO_SplitAssessment_Parcels } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/model";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { groupBy, last, sortBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const formValidator = (values: any) => {
  //Validate total factor
  const totalGroupByID = values.Parcels.reduce(
    (accumulator: any, current: DTO_SplitAssessment_Parcels) => {
      const { Parcel_Id, Factor } = current;
      const currentFactor = +(Factor ?? 0);
      if (!accumulator?.[Parcel_Id]) {
        accumulator[Parcel_Id] = currentFactor;
      } else {
        accumulator[Parcel_Id] += currentFactor;
      }
      return accumulator;
    },
    {}
  );

  const parcelIdsWithTotalFactorMoreThan100 = Object.keys(
    totalGroupByID
  )?.filter((key) => totalGroupByID[key] > 100);

  if (parcelIdsWithTotalFactorMoreThan100?.length > 0) {
    return "Total factor cannot be more than 100% for the same parcel ID";
  }

  // Validate duplicate parcel in a holding
  const groupedParcels = groupBy(
    values.Parcels,
    (parcel: DTO_SplitAssessment_Parcels) =>
      `${parcel.Parcel_Id}-${parcel.Provisional_Assessment_Number}`
  );
  const duplicateParcelIds = Object.values(groupedParcels)
    ?.filter((group) => group.length > 1)
    ?.map((group) => group[0].Parcel_Id);

  if (duplicateParcelIds?.length > 0) {
    return "Duplicate parcel ID for the same provisional holding number";
  }

  return "";
};

export const ParcelsToBeSplitFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? formValidator : undefined}
    />
  );
};

const nameOfParcel = nameOfFactory<DTO_SplitAssessment_Parcels>();
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    localNotificationRef,
    isLoadingStep,
    setIsLoadingStep = () => {},
    loadFailedStep,
    setLoadFailedStep = () => {},
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const reassignedParcelsData = getFieldValue("Parcels") ?? [];
    const unaffectedParcelsData = getFieldValue("ParcelsNotImpacted") ?? [];
    const selectedReassignedParcels =
      getFieldValue("_option.selectedReassignedParcels") ?? [];
    const selectedUnaffectedParcels =
      getFieldValue("_option.selectedUnaffectedParcels") ?? [];

    const [processedParcelCol, setProcessedParcelCol] =
      useState<IColumnFields[]>(colParcels);
    const [processedUnaffectedParcelCol, setProcessedUnaffectedParcelCol] =
      useState<IColumnFields[]>(colUnaffectedParcels);

    /**
     * Load view configuration
     */
    const loadViewConfig = async () => {
      setIsLoadingStep(true);
      const response = await Promise.all([
        loadViewConfiguresColumns(
          ViewConfiguration.SplitAssessment_Parcel,
          colParcels
        ),
        loadViewConfiguresColumns(
          ViewConfiguration.SplitAssessment_ParcelUnaffected,
          colUnaffectedParcels
        ),
      ]);
      setIsLoadingStep(false);
      if (Array.isArray(response)) {
        const [responseParcelsConfig, responseUnaffectedParcelsConfig] =
          response;
        if (
          Array.isArray(responseParcelsConfig) &&
          Array.isArray(responseUnaffectedParcelsConfig)
        ) {
          setProcessedParcelCol(responseParcelsConfig);
          setProcessedUnaffectedParcelCol(responseUnaffectedParcelsConfig);
        } else {
          let responseError = responseParcelsConfig as APIResponseError;
          if (!Array.isArray(responseUnaffectedParcelsConfig)) {
            responseError = responseUnaffectedParcelsConfig as APIResponseError;
          }
          setLoadFailedStep({
            onReload: () => {
              loadViewConfig();
            },
            responseError: {
              status: responseError.status,
              error: responseError.error ?? "Load failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        setLoadFailedStep({
          onReload: () => {
            loadViewConfig();
          },
          responseError: {
            status: responseError.status,
            error: responseError.error ?? "Load failed",
          },
        });
      }
    };

    useEffectOnce(() => {
      loadViewConfig();
    });

    /**
     * Handle clone parcel
     */
    const handleCloneParcels = () => {
      localNotificationRef?.current?.resetNotifications();

      // Clone "Parcels to be reassigned" array to a new aray
      const newParcelsData = [...reassignedParcelsData];

      //Grid allows only single selection => use the first element
      const selectedParcel = selectedReassignedParcels[0];

      // Get the list of parcel having the same ID with selected parcel
      // and sort by provisional assessment number ascending
      const parcelsWithTheSameSelectedID = sortBy(
        newParcelsData.filter(
          (parcelItem: DTO_SplitAssessment_Parcels) =>
            parcelItem.Parcel_Id === selectedParcel.Parcel_Id
        ),
        nameOfParcel("Provisional_Assessment_Number")
      );

      //Get index of selected parcel in original array
      const selectedIndex =
        newParcelsData.findIndex((parcelItem) => {
          return parcelItem?.UUID === selectedParcel?.UUID;
        }) + 1;

      //Set data for cloned item
      const clonedItem = {
        ...selectedParcel,
        UUID: getUUID(),
        Provisional_Assessment_Number:
          last(parcelsWithTheSameSelectedID).Provisional_Assessment_Number + 1,
      };

      //Insert cloned item to original array
      newParcelsData.splice(selectedIndex, 0, clonedItem);

      //Calculate factor percentage
      const totalNumberOfClonedParcels =
        parcelsWithTheSameSelectedID.length + 1;

      //Prevent user from cloning more than 100 items
      if (totalNumberOfClonedParcels > 100) {
        localNotificationRef?.current?.pushNotification({
          title: "Maximum clone count of 100 items has been reached.",
          type: "error",
          autoClose: false,
        });
        return;
      }

      const normalPercentage = Math.floor(+(100 / totalNumberOfClonedParcels));
      const lastPercentage =
        100 - normalPercentage * (totalNumberOfClonedParcels - 1);

      let countClonedParcel = 0;
      newParcelsData.forEach((parcelItem) => {
        if (parcelItem.Parcel_Id === selectedParcel?.Parcel_Id) {
          countClonedParcel++;
          parcelItem.Factor =
            countClonedParcel === totalNumberOfClonedParcels
              ? lastPercentage
              : normalPercentage;
        }
      });

      //Set new data for "Parcels to be reassigned" grid
      onChange(nameOf("Parcels"), {
        value: newParcelsData,
      });

      //Set new data for selected "Parcels to be reassigned" grid
      const newSelectedReassignedParcels = [
        ...(newParcelsData.filter(
          (item) => item.UUID === selectedParcel?.UUID
        ) ?? []),
      ];
      onChange(nameOf("_option.selectedReassignedParcels"), {
        value: newSelectedReassignedParcels,
      });
    };

    /**
     * Unaffect parcel
     */
    const handleUnaffectParcels = () => {
      localNotificationRef?.current?.resetNotifications();
      const newReassignedParcelsData = reassignedParcelsData?.filter(
        (item: any) => {
          return item?.UUID !== selectedReassignedParcels?.[0]?.UUID;
        }
      );
      onChange(nameOf("Parcels"), {
        value: newReassignedParcelsData,
      });
      onChange(nameOf("ParcelsNotImpacted"), {
        value: [...selectedReassignedParcels, ...unaffectedParcelsData],
      });
      onChange(nameOf("_option.selectedReassignedParcels"), {
        value: [],
      });
    };

    /**
     * Re-assign parcel
     */
    const handleReassignParcels = () => {
      localNotificationRef?.current?.resetNotifications();
      const newUnaffectedParcelsData = unaffectedParcelsData?.filter(
        (item: any) => {
          return item?.UUID !== selectedUnaffectedParcels?.[0]?.UUID;
        }
      );
      onChange(nameOf("ParcelsNotImpacted"), {
        value: newUnaffectedParcelsData,
      });
      onChange(nameOf("Parcels"), {
        value: [...selectedUnaffectedParcels, ...reassignedParcelsData],
      });
      onChange(nameOf("_option.selectedUnaffectedParcels"), {
        value: [],
      });
    };

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }
    if (loadFailedStep) {
      return (
        <CCLoadFailed
          onReload={loadFailedStep?.onReload}
          responseError={loadFailedStep?.responseError}
        />
      );
    }
    return (
      <>
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Parcels to be reassigned" />
              <CCGrid
                className="cc-split-assessment-parcels"
                data={reassignedParcelsData}
                columnFields={processedParcelCol}
                primaryField={nameOfParcel("UUID")}
                editableMode="cell"
                selectableMode="single"
                selectedRows={
                  selectedReassignedParcels
                    ? [...selectedReassignedParcels]
                    : undefined
                }
                onSelectionChange={(dataItem: any) => {
                  onChange(nameOf("_option.selectedReassignedParcels"), {
                    value: dataItem ? [...dataItem] : [],
                  });
                }}
                onDataChange={(dataItem: any[]) => {
                  onChange(nameOf("Parcels"), {
                    value: dataItem ?? [],
                  });
                }}
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      className="cc-edit-field-button"
                      iconClass="fas fa-arrow-alt-down"
                      title="Unaffected"
                      disabled={!selectedReassignedParcels?.length}
                      onClick={handleUnaffectParcels}
                    />
                    <Button
                      className="cc-edit-field-button"
                      iconClass="fad fa-clone"
                      title="Clone Parcel"
                      disabled={!selectedReassignedParcels?.length}
                      onClick={handleCloneParcels}
                    />
                  </div>
                }
                readOnly={options?.isReadOnly}
              />
              {errors?.[nameOf("")] ? (
                <Error>{errors[nameOf("")]}</Error>
              ) : null}
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Unaffected parcels" />
              <CCGrid
                className="cc-split-assessment-parcels"
                data={unaffectedParcelsData}
                columnFields={processedUnaffectedParcelCol}
                primaryField={nameOfParcel("UUID")}
                editableMode="cell"
                selectableMode="single"
                selectedRows={
                  selectedUnaffectedParcels
                    ? [...selectedUnaffectedParcels]
                    : undefined
                }
                onSelectionChange={(dataItem: any) => {
                  onChange(nameOf("_option.selectedUnaffectedParcels"), {
                    value: dataItem ? [...dataItem] : [],
                  });
                }}
                onDataChange={(dataItem: any[]) => {
                  onChange(nameOf("ParcelsNotImpacted"), {
                    value: dataItem ?? [],
                  });
                }}
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      className="cc-edit-field-button"
                      iconClass="fas fa-arrow-alt-up"
                      title="Reassign"
                      disabled={!selectedUnaffectedParcels?.length}
                      onClick={handleReassignParcels}
                    />
                  </div>
                }
                readOnly={options?.isReadOnly}
              />
            </div>
          </div>
        </section>
      </>
    );
  }
);
