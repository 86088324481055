import { DTO_Assessment_Levy } from "@app/products/property/assessments/[id]/components/child-screens/rating/model";
import {
  BOOLEAN_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Assessment_Levy>();
export const colRatingLevies: IColumnFields[] = [
  {
    field: nameOf("LevyCategory"),
    title: "Category",
  },
  {
    field: nameOf("LevyName"),
    title: "Levy",
  },
  {
    field: nameOf("LevyCode"),
    title: "Levy Code",
  },
  {
    field: nameOf("DifferentialCategory"),
    title: "Differential Category",
  },
  {
    field: nameOf("QuantityDescription"),
    title: "Quantity/Amount",
  },
  {
    field: nameOf("ActiveFromDate"),
    title: "Active From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ActiveToDate"),
    title: "Active To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("NoInterestOnLevy"),
    title: "Suppress Interest",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("ChangedBy"),
    title: "Changed By",
  },
  {
    field: nameOf("ChangedOn"),
    title: "Change On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Id"),
    title: "Levy ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("AssessmentLevyId"),
    title: "Assessment Levy ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
