import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { WorkflowTypes } from "@app/products/property/model";
import { Label } from "@common/stores/products/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const CreateTitleButton = observer(() => {
  const { setListDialog } = usePropertyWorkflowStore();
  const titleLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Title
  );
  return (
    <CCNavButton
      title={`Create ${titleLabel}`}
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.CreateTitle,
            data: {},
            props: {},
          },
        ]);
      }}
    />
  );
});
