import { AuthoriseJournalDialog } from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/authorise/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const AuthoriseButton = observer(() => {
  const [showAuthoriseJournal, setShowAuthoriseJournal] = useState(false);

  const [disabledAuthorise, setDisabledAuthorise] = useState(true);
  const { gridSelectedRows } = useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();

  const selectedItem: any = gridSelectedRows;
  const isAuthorized = selectedItem[0]?.Status !== "Authorised" ? true : false;

  useEffect(() => {
    if (selectedItem.length === 1 && isAuthorized) {
      setDisabledAuthorise(false);
    } else {
      setDisabledAuthorise(true);
    }
  }, [isAuthorized, selectedItem]);

  return (
    <>
      <CCNavButton
        title={"Authorise"}
        disabled={disabledAuthorise}
        onClick={() => {
          setShowAuthoriseJournal(true);
        }}
      />
      {showAuthoriseJournal && (
        <AuthoriseJournalDialog
          onClose={() => {
            setShowAuthoriseJournal(false);
          }}
          onSubmit={() => {
            setShowAuthoriseJournal(false);
            pushNotification({
              title: "Journals authorised successfully",
              type: "success",
            });
          }}
        />
      )}
    </>
  );
});
