import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { EventView } from "@app/products/crms/all-events/by-refno/model";
import {
  IGenerateEventsColumnsBySettingProps,
  iconColumnViewConfigAlert,
  iconColumnViewConfigCallReturn,
  iconColumnViewConfigUrgent,
} from "@app/products/crms/config";
import { DATE_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { AssetsSystem } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/assets/model";
import { getNumberValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";

const nameOf = nameOfFactory<EventView>();

export const colCSAdmin: IColumnFields[] = [
  {
    field: nameOf("RefNo"),
    title: "Reference Number",
    locked: true,
    linkTo: (dataItem: EventView) => {
      if (dataItem.IsHideInfo) {
        return undefined;
      }
      return `${CRMS_ROUTE}/${dataItem.ID}`;
    },
  },
  ...iconColumnViewConfigAlert,
  ...iconColumnViewConfigUrgent,
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("DateRecorded"),
    title: "Recorded",

    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DateTarget"),
    title: "Target",

    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Location"),
    title: "Location",
  },
  ...iconColumnViewConfigCallReturn,
  {
    field: nameOf("RequestedBy"),
    title: "Contact",
  },
  {
    field: nameOf("ActionOfficer"),
    title: "Action Officer",
  },
  {
    field: nameOf("Coordinator"),
    title: "Coordinator",
  },
  {
    field: nameOf("DueStatus"),
    title: "Due Status",
  },
  {
    field: nameOf("Category"),
    title: "Category",
  },
  {
    field: nameOf("ContactDetails"),
    title: "Contact Number",
  },
  {
    field: nameOf("OrgStructure"),
    title: "Org Structure",
  },
  {
    field: nameOf("FileNumber"),
    title: "File Number",
  },
  // Still to be reviewed - taskID: 3269
  // {
  //   field: nameOf("EnquiryRefNo"),
  //   title: "Assets Reference Number",
  // },
  {
    field: nameOf("Admin_Action"),
    title: "Admin Action",
  },
];

export const generateUnresolvedEventsCSAdminBySetting = ({
  settings,
  defaultConfig = [],
}: IGenerateEventsColumnsBySettingProps) => {
  if (!settings) return defaultConfig;

  const assetsSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_AssetsSystem]
  );

  let newConfig: IColumnFields[] = cloneDeep(defaultConfig);
  return newConfig.filter((col) => {
    switch (col.field) {
      case nameOf("EnquiryRefNo"):
        if (assetsSystem !== AssetsSystem.None) {
          return true;
        }
        return false;
      default:
        return true;
    }
  });
};
