import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const CreateAssessmentJournalButton = observer(() => {
  const { setListDialog } = usePropertyWorkflowStore();
  const { gridSelectedRows } = useCCProductListViewStore();

  return (
    <CCNavButton
      title="Create journal"
      disabled={gridSelectedRows.length < 1}
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Journal_Assessment_Add_Transaction,
            data: {},
          },
        ]);
      }}
    />
  );
});
