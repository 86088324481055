import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.AssessmentId ?? "";
  return `Property - Assessments - ${dynamicDisplayName}`;
};

//@TODO Don't have Property_Name_Address_Locality and Assessment_Number_Formatted data in grid mock data, recheck when integrated API
export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.Assessment_Number_Formatted,
    selectedRow.Property_Name_Address_Locality,
  ]);
};
