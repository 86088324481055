import { IData } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/model";
import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { cloneDeep, isNil, keyBy } from "lodash";

export const processDataForm = (data: SettingField[]): IData | undefined => {
  if (isNil(data)) return undefined;
  const cloneData = cloneDeep(data);
  let dataForm = null;
  const option: any = {};
  dataForm = cloneData.reduce((field: any, item: SettingField) => {
    const keyName = item["FieldName"];
    field[keyName] = item.Value;
    if (item.DataType === "Lookup" && item?.ValueDisplayDetails) {
      const customField = `ValueDisplayDetails_${keyName}`;
      option[customField] = item?.ValueDisplayDetails;
    }
    return field;
  }, {});
  if (isNil(dataForm)) return undefined;
  return { ...dataForm, option };
};

export const processConfigData = (data: SettingField[]) => {
  if (isNil(data)) return undefined;
  const cloneData = cloneDeep(data);
  return keyBy(cloneData, "FieldName");
};

export const convertObjectToArray = (data: IData) => {
  if (!data) return [];
  return Object.keys(data).map((key) => ({
    key,
    value: data[key],
  }));
};

export const onSubmitFormGlobalSettings = (e: any) => {
  const el: any = document.querySelector(
    ".cc-exist-global-setting-manage-form > form.k-form"
  );
  const reactHandlerKey = Object.keys(el).filter((item) =>
    item.includes("__reactEventHandlers")
  );
  const reactHandler = el[reactHandlerKey[0]];
  reactHandler.onSubmit(e);
};
