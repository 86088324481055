import { eventEmitter } from "@/App";
import { postSendCommunication } from "@app/core/communication/buttons-list-view/components/buttons/api";
import { CommunicationDialog } from "@app/core/communication/dialogs/_index";
import { ICommunication } from "@app/core/communication/dialogs/model";
import { DOCUMENTSTATUS } from "@app/core/documents/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { customLogger } from "@common/utils/logger";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface ICommunicationButton {
  productType?: PRODUCT_TYPE_NUMBER;
  recordId?: number;
  recordType?: RECORDTYPE;
  documentStatus?: DOCUMENTSTATUS;
  gridSelectedRows?: any[];
  keyField?: string;
  isDisabled?: boolean;
  isVisible?: boolean;
}

export const CommunicationButton = observer(
  ({
    productType,
    recordId,
    recordType,
    documentStatus,
    gridSelectedRows = [],
    keyField = "ID",
    isDisabled = false,
    isVisible = true,
  }: ICommunicationButton) => {
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();

    const [showCommunicationDialog, setShowCommunicationDialog] =
      useState<boolean>(false);
    const recordIDs: number[] | undefined = !isEmpty(gridSelectedRows)
      ? gridSelectedRows
          ?.map((item: any) => item?.[keyField])
          ?.filter((id) => id !== null)
      : recordId
      ? [recordId]
      : [];
    const handleCommunicationSubmit = (data: ICommunication) => {
      if (!recordType) return;
      postSendCommunication(data, recordType).then((response: any) => {
        customLogger("Core Communication, postSendCommunication").info(
          response?.data
        );
        if (isSuccessIdentityPacket(response)) {
          setShowCommunicationDialog(false);
          clearErrorNotification();
          pushNotification({
            type: "success",
            title: "Communication sent successfully",
          });
          eventEmitter.emit(CCGridEventType.RefreshOData);
          eventEmitter.emit(CCJournalEventType.RefreshData);
          eventEmitter.emit(TabTableEventType.RefreshData);
        } else {
          setShowCommunicationDialog(false);
          pushNotification({
            type: "error",
            title: "Error occurred",
            autoClose: false,
            description: response?.data?.Errors,
          });
        }
      });
    };

    if (!isVisible) return null;
    return (
      <>
        <CCNavButton
          title="Communication"
          disabled={recordIDs?.length === 0 || isDisabled}
          onClick={() => {
            setShowCommunicationDialog(true);
          }}
        />
        {showCommunicationDialog && (
          <CommunicationDialog
            onClose={() => setShowCommunicationDialog(false)}
            onSubmit={handleCommunicationSubmit}
            productType={productType}
            recordIDs={recordIDs}
            recordType={recordType}
            documentStatus={documentStatus}
          />
        )}
      </>
    );
  }
);
