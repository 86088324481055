import { ODATA_GET_CONTACTS } from "@app/core/contacts/components/dialogs/pick-contact/constant";
import { renderOptionRequestor } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/config";
import { CS_RequestorType } from "@app/products/crms/[id]/model";
import {
  BatchUpdateEventLocationState,
  EventBatchUpdate,
  EventBatchUpdateRequest,
  Svc_FormAction_EventBatchUpdate,
} from "@app/products/crms/batch-update-event/model";
import { useCRMSBatchUpdateEventStore } from "@app/products/crms/batch-update-event/store";
import { colCategory } from "@app/products/crms/components/dialogs/category-sub-category/config";
import {
  renderOptionActionOfficer,
  renderOptionPickServiceStandard,
} from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/form-element/config";
import { columnFieldsCustom } from "@app/products/crms/service-standards/util";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

export interface IBatchUpdateEventFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<EventBatchUpdate>();
const nameOfKeyValuePacket = nameOfFactory<IKeyValuePacket>();

export const BatchUpdateEventFormElement = observer(
  (props: IBatchUpdateEventFormElementProps) => {
    const { formRenderProps } = props;
    const { valueGetter, onChange } = formRenderProps;
    const { settings } = useCommonCoreStore();
    const {
      batchUpdateEventLovs,
      eventBatchUpdate,
      batchUpdateEventChangeHandler,
    } = useCRMSBatchUpdateEventStore();

    const location = useLocation();
    const locationState = location.state as BatchUpdateEventLocationState;
    const gridSelectedIds = locationState?.gridSelectedIds;

    const eventBatchUpdateObj = valueGetter("EventBatchUpdate");

    const isDisabled = useMemo(() => {
      return (
        !gridSelectedIds || (gridSelectedIds && gridSelectedIds.length < 1)
      );
    }, [gridSelectedIds]);

    const serviceStandardCategoryLevels = getNumberValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandardCategoryLevels
      ]
    );

    const subCategoryLevel3Label = getStringValueSetting(
      settings[
        ECorporateSettingsField
          .CUSTOMERSERVICE_ServiceStandardSubCategoryLevel3Caption
      ]
    );

    const subCategoryLevel4Label = getStringValueSetting(
      settings[
        ECorporateSettingsField
          .CUSTOMERSERVICE_ServiceStandardSubCategoryLevel4Caption
      ]
    );

    const handleChangeCoordinator = (data: any) => {
      onChange(`EventBatchUpdate.${nameOf("Coordinator_SiteUser_ID")}`, {
        value: data?.Id ?? null,
      });
      const params: EventBatchUpdateRequest = {
        EventBatchUpdateActions: Svc_FormAction_EventBatchUpdate.Coordinator,
        EventBatchUpdate: eventBatchUpdateObj,
        EventArgs: {},
      };
      batchUpdateEventChangeHandler(params, "Change coordinator failed.");
    };

    const handleChangeActionOfficer = (data: any) => {
      onChange(`EventBatchUpdate.${nameOf("ActionOfficer_SiteUser_ID")}`, {
        value: data?.Id ?? null,
      });
      const params: EventBatchUpdateRequest = {
        EventBatchUpdateActions: Svc_FormAction_EventBatchUpdate.ActionOfficer,
        EventBatchUpdate: eventBatchUpdateObj,
        EventArgs: {},
      };
      batchUpdateEventChangeHandler(params, "Change action officer failed.");
    };

    const handleChangeServiceStandard = (data: any) => {
      onChange(`EventBatchUpdate.${nameOf("ServiceStandard_ID")}`, {
        value: data?.ID ?? null,
      });

      const params: EventBatchUpdateRequest = {
        EventBatchUpdateActions:
          Svc_FormAction_EventBatchUpdate.ServiceStandard,
        EventBatchUpdate: eventBatchUpdateObj,
        EventArgs: {},
      };
      batchUpdateEventChangeHandler(params, "Change service standard failed.");
    };

    const handleChangeSource = (event: ComboBoxChangeEvent) => {
      const { value } = event;
      onChange(`EventBatchUpdate.${nameOf("Source_ID")}`, {
        value: value?.Key ?? "",
      });
      onChange(`EventBatchUpdate.${nameOf("Source_Display")}`, {
        value: value?.Value ?? "",
      });
    };

    const handleChangeType = (event: ComboBoxChangeEvent) => {
      const { value } = event;
      onChange(`EventBatchUpdate.${nameOf("Type_ID")}`, {
        value: value?.Key ?? "",
      });
      onChange(`EventBatchUpdate.${nameOf("Type_Display")}`, {
        value: value?.Value ?? "",
      });
    };

    return (
      <FormElement>
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update coordinator" />
              <Field
                name={`EventBatchUpdate.${nameOf("Update_Coordinator")}`}
                component={CCSwitch}
                checked={valueGetter(
                  `EventBatchUpdate.${nameOf("Update_Coordinator")}`
                )}
                disabled={isDisabled}
              />
            </div>
            {valueGetter(`EventBatchUpdate.${nameOf("Update_Coordinator")}`) ? (
              <div className="cc-field">
                <CCLabel title="Coordinator" isMandatory />
                <Field
                  name={`EventBatchUpdate.${nameOf("Coordinator_SiteUser_ID")}`}
                  nameDisplay="DisplayName"
                  placeholder="Select requestor"
                  component={InputPickerSearch}
                  onChange={handleChangeCoordinator}
                  validator={requiredValidator}
                  options={renderOptionRequestor(
                    CS_RequestorType.InternalPerson
                  )}
                  value={eventBatchUpdate?.Coordinator_DisplayName ?? ""}
                  disabled={isDisabled}
                />
              </div>
            ) : null}
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update action officer" />
              <Field
                name={`EventBatchUpdate.${nameOf("Update_ActionOfficer")}`}
                component={CCSwitch}
                checked={valueGetter(
                  `EventBatchUpdate.${nameOf("Update_ActionOfficer")}`
                )}
                disabled={isDisabled}
              />
            </div>
            {valueGetter(
              `EventBatchUpdate.${nameOf("Update_ActionOfficer")}`
            ) ? (
              <div className="cc-field">
                <CCLabel title="Action officer" isMandatory />
                <Field
                  name={`EventBatchUpdate.${nameOf(
                    "ActionOfficer_SiteUser_ID"
                  )}`}
                  nameDisplay="DisplayName"
                  placeholder="Select action officer"
                  component={InputPickerSearch}
                  onChange={handleChangeActionOfficer}
                  validator={requiredValidator}
                  options={renderOptionActionOfficer(
                    `${ODATA_GET_CONTACTS}(classificationFilters='2',hideCouncillors=true)`
                  )}
                  value={eventBatchUpdate?.ActionOfficer_DisplayName ?? ""}
                  disabled={isDisabled}
                />
              </div>
            ) : null}
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update service standard" />
              <Field
                name={`EventBatchUpdate.${nameOf("Update_ServiceStandard")}`}
                component={CCSwitch}
                checked={valueGetter(
                  `EventBatchUpdate.${nameOf("Update_ServiceStandard")}`
                )}
                disabled={isDisabled}
              />
            </div>
            {valueGetter(
              `EventBatchUpdate.${nameOf("Update_ServiceStandard")}`
            ) ? (
              <div className="cc-field">
                <CCLabel title="Service standard" isMandatory />
                <Field
                  name={`EventBatchUpdate.${nameOf("ServiceStandard_ID")}`}
                  nameDisplay="Name"
                  placeholder="Select service standard"
                  component={InputPickerSearch}
                  onChange={handleChangeServiceStandard}
                  options={renderOptionPickServiceStandard(
                    columnFieldsCustom(
                      serviceStandardCategoryLevels,
                      colCategory,
                      subCategoryLevel3Label,
                      subCategoryLevel4Label
                    ),
                    serviceStandardCategoryLevels
                  )}
                  value={eventBatchUpdate?.ServiceStandard_Description ?? ""}
                  validator={requiredValidator}
                  disabled={isDisabled}
                />
              </div>
            ) : null}
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update type" />
              <Field
                name={`EventBatchUpdate.${nameOf("Update_Type")}`}
                component={CCSwitch}
                checked={valueGetter(
                  `EventBatchUpdate.${nameOf("Update_Type")}`
                )}
                disabled={isDisabled}
              />
            </div>
            {valueGetter(`EventBatchUpdate.${nameOf("Update_Type")}`) ? (
              <div className="cc-field">
                <CCLabel title="Type" isMandatory />
                <Field
                  name={`EventBatchUpdate.${nameOf("Type_ID")}`}
                  component={CCSearchComboBox}
                  validator={requiredValidator}
                  data={batchUpdateEventLovs?.Type ?? []}
                  textField={nameOfKeyValuePacket("Value")}
                  dataItemKey={nameOfKeyValuePacket("Key")}
                  value={getDropdownValue(
                    valueGetter(`EventBatchUpdate.${nameOf("Type_ID")}`),
                    batchUpdateEventLovs?.Type,
                    "Key"
                  )}
                  onChange={handleChangeType}
                  disabled={isDisabled}
                />
              </div>
            ) : null}
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update source" />
              <Field
                name={`EventBatchUpdate.${nameOf("Update_Source")}`}
                component={CCSwitch}
                checked={valueGetter(
                  `EventBatchUpdate.${nameOf("Update_Source")}`
                )}
                disabled={isDisabled}
              />
            </div>
            {valueGetter(`EventBatchUpdate.${nameOf("Update_Source")}`) ? (
              <div className="cc-field">
                <CCLabel title="Source" isMandatory />
                <Field
                  name={`EventBatchUpdate.${nameOf("Source_ID")}`}
                  component={CCSearchComboBox}
                  validator={requiredValidator}
                  data={batchUpdateEventLovs?.Source ?? []}
                  textField={nameOfKeyValuePacket("Value")}
                  dataItemKey={nameOfKeyValuePacket("Key")}
                  value={getDropdownValue(
                    valueGetter(`EventBatchUpdate.${nameOf("Source_ID")}`),
                    batchUpdateEventLovs?.Source,
                    "Key"
                  )}
                  onChange={handleChangeSource}
                  disabled={isDisabled}
                />
              </div>
            ) : null}
          </div>
        </div>
      </FormElement>
    );
  }
);
