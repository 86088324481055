import { MAX_LENGTH_REASON } from "@app/core/delete/dialogs/config";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React from "react";

export interface IConfirmDelete {
  onClose: () => void;
  header?: string;
  message?: string;
  contentDelete?: string;
  handleSubmit: (data: IDeleteConfirmation) => void;
  isDeleting?: boolean;
  notifications?: IAppNotificationItem[];
}
const nameOf = nameOfFactory<IDeleteConfirmation>();
export const ConfirmDelete = ({
  onClose,
  header = "Delete Confirmation",
  message,
  contentDelete = "this item",
  handleSubmit,
  isDeleting = false,
  notifications,
}: IConfirmDelete) => {
  const handleSubmitDelete = (event: FormSubmitClickEvent) => {
    const values: IDeleteConfirmation = event.values as IDeleteConfirmation;
    handleSubmit(values);
  };

  return (
    <Form
      onSubmitClick={handleSubmitDelete}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter, valid, onSubmit } = formRenderProps;
        return (
          <FormElement>
            <CCDialog
              maxWidth="40%"
              height={"auto"}
              titleHeader={header}
              onClose={onClose}
              disabled={isDeleting}
              bodyElement={
                <>
                  <CCLocalNotification defaultNotifications={notifications} />
                  <h5 className="cc-label">
                    {message ??
                      `Are you sure you want to delete the ${contentDelete.toLowerCase()}(s)?`}
                  </h5>
                  <p className="cc-label">
                    If yes, enter the reason below (your comment will be visible
                    in the Journal):
                  </p>
                  <label className="cc-label">
                    Reason
                    <CCTooltip type="validator" position="right" />
                  </label>
                  <div className="cc-label-field">
                    <Field
                      name={nameOf("Reason")}
                      rows={5}
                      value={valueGetter("Reason")}
                      component={CCTextArea}
                      validator={requiredValidator}
                      maxLength={MAX_LENGTH_REASON}
                    />
                  </div>
                </>
              }
              footerElement={
                <React.Fragment>
                  <div className="cc-dialog-footer-actions-right">
                    <Button className="cc-dialog-button" onClick={onClose}>
                      No
                    </Button>
                    <Button
                      themeColor="primary"
                      disabled={!valid}
                      onClick={onSubmit}
                      className="cc-dialog-button"
                      iconClass={isDeleting ? "fas fa-spinner fa-spin" : ""}
                    >
                      Yes
                    </Button>
                  </div>
                </React.Fragment>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
