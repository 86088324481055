import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { useEntitledToRebateStore } from "@app/products/property/assessments/[id]/components/child-screens/rebates/components/entitled-to-rebate/store";
import { AssessmentRebatesEventType } from "@app/products/property/assessments/[id]/components/child-screens/rebates/constant";
import {
  getWorkflowUpdateLossOfEligibilityDate,
  postProcessUpdateLossOfEligibilityDate,
} from "@app/products/property/assessments/[id]/components/dialogs/rebates/api";
import {
  DTO_Workflow_Rebate_UpdateLossOfEligibilityDate,
  RebateLossOfEligibilityObj,
} from "@app/products/property/assessments/[id]/components/dialogs/rebates/model";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { useCommonProductStore } from "@common/stores/products/store";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IUpdateLossOfEligibilityDateDialogProps {
  isReadOnly?: boolean;
  onClose: () => void;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  suffixTitle?: string;
  statusBadge?: IBadgeDialog[];
}
export const UpdateLossOfEligibilityDateDialog = observer(
  ({
    isReadOnly = false,
    onClose,
    dataFromActionList,
    prefixTitle,
    suffixTitle,
    statusBadge,
  }: IUpdateLossOfEligibilityDateDialogProps) => {
    //#region HOOKS -------------------------------------------->
    //Use ref
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    //Use store
    const { currentFormTitle } = useCommonProductStore();
    const { assessmentId } = useAssessmentStore();
    const { selectedEntitledToRebateIDs } = useEntitledToRebateStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();

    //Use state
    const [isLoading, setIsLoading] = useState(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [initialData, setInitialData] =
      useState<RebateLossOfEligibilityObj>();

    //Use memo
    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title =
        currentFormTitle(formId ?? 0) || "Update Loss of Eligibility Date";
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, workflowHeader]);

    //Use effect
    /**
     * get workflow data
     */
    const getWorkflowData = async () => {
      setIsLoading(true);
      let errorResponse = undefined;

      const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
      //Get workflow data
      const response = await getWorkflowUpdateLossOfEligibilityDate(
        {
          Assessment_Id: assessmentId,
          Rebate_Ids: selectedEntitledToRebateIDs,
        },
        workflowDraftId
      );
      const workflowData = response?.data;
      if (isSuccessResponse(response) && workflowData) {
        setInitialData(workflowData.WorkflowDetail?.LossOfEligibility);
        //Set workflow header
        if (workflowData?.WorkflowHeader) {
          setWorkflowHeader(workflowData.WorkflowHeader);
        }
      } else {
        errorResponse = {
          status: response.status,
          error: response.error ?? "Load workflow failed",
        };
      }
      setIsLoading(false);
      setResponseLoadError(errorResponse);
    };

    useEffectOnce(() => {
      getWorkflowData();
    });
    //#endregion <--------------------------------------------

    //#region COMMON -------------------------------------------->
    /**
     * Handle submit to control all buttons in dialog
     * @param events
     * @param buttonId
     */
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
          handleCancelButton(data);
          break;
        default:
          break;
      }
    };

    /**
     * common function
     * handle process workflow
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_Rebate_UpdateLossOfEligibilityDate>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;
      const response = await postProcessUpdateLossOfEligibilityDate(
        modeProcess,
        payload
      );

      setLoading();
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        notificationRef?.current?.pushNotification({
          title: response?.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
      }
    };
    //#endregion <--------------------------------------------

    //#region CANCEL -------------------------------------------->
    /**
     * Handle Cancel button
     * @param data
     */
    const handleCancelButton = (data: RebateLossOfEligibilityObj) => {
      setDataForCancelDialog({
        cancelAPI: postProcessUpdateLossOfEligibilityDate,
        actionSuccess: () => {
          onClose();
        },
        dataCancel: {
          WorkflowHeader: workflowHeader,
          WorkflowDetail: {
            LossOfEligibility: data,
          },
        },
        defaultSuccessMessage:
          "Update loss of eligibility date was cancelled successfully.",
        defaultErrorMessage:
          "Update loss of eligibility date could not be cancelled.",
      });
    };
    //#endregion <--------------------------------------------

    //#region FINISH -------------------------------------------->
    /**
     * handle finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (
      payload: RebateLossOfEligibilityObj
    ) => {
      setDataForFinishDialog({
        finishCallback: async () =>
          await handleFinishProcess({
            WorkflowHeader: workflowHeader,
            WorkflowDetail: {
              LossOfEligibility: payload,
            },
          }),
        confirmMessage:
          "The loss of eligibility date will be updated based on the information provided. Are you sure you want to submit?",
      });
    };

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_Rebate_UpdateLossOfEligibilityDate
    ) => {
      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_Rebate_UpdateLossOfEligibilityDate> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            eventEmitter.emit(AssessmentRebatesEventType.reloadRebatesGrid);
            pushNotification({
              title:
                e?.SuccessMessage ??
                "Update loss of eligibility date was completed successfully.",
              type: "success",
            });
          },
          defaultFailedMessage:
            "Update loss of eligibility date could not be completed.",
          modeProcess: WorkflowProcessMode.Finish,
        };

      const setLoading = () => {};
      //calling API process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };
    //#endregion <--------------------------------------------

    return (
      <Form
        onSubmitClick={(event: FormSubmitClickEvent) =>
          handleSubmit(event.values, event.event?.currentTarget.id)
        }
        key={JSON.stringify(initialData)}
        initialValues={initialData}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit } = formRenderProps;
          return (
            <CCDialog
              badge={statusBadge}
              isLoading={isLoading}
              maxWidth="35%"
              height="auto"
              titleHeader={titleHeader}
              onClose={onClose}
              bodyElement={
                responseLoadError ? (
                  <CCLoadFailed
                    responseError={responseLoadError}
                    onReload={() => {
                      getWorkflowData();
                    }}
                  />
                ) : (
                  <div className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <FormElement className="cc-field-group">
                      <div className="cc-field">
                        <CCValueField
                          name="Rebate_Ids"
                          label="Rebate ID"
                          value={initialData?.Rebate_Ids?.join(", ")}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Loss of eligibility date
                        </label>
                        <Field
                          name="LossOfEligibilityDate"
                          format={DATE_FORMAT.DATE_CONTROL}
                          component={CCDatePicker}
                          disabled={isReadOnly}
                        />
                      </div>
                    </FormElement>
                  </div>
                )
              }
              footerElement={
                <>
                  {!isReadOnly && (
                    <div className={"cc-dialog-footer-actions-right"}>
                      <Button
                        id={EListSubmitButton.Cancel}
                        onClick={onSubmit}
                        className={"cc-dialog-button"}
                      >
                        Cancel
                      </Button>
                      <Button
                        id={EListSubmitButton.Finish}
                        themeColor="primary"
                        onClick={onSubmit}
                        className={"cc-dialog-button"}
                      >
                        Finish
                      </Button>
                    </div>
                  )}
                </>
              }
            />
          );
        }}
      />
    );
  }
);
