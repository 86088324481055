import { isSuccessResponse } from "@common/apis/util";
import { useGlobalStore } from "@common/stores/global/store";
import { CustomPagerPagesizeAll } from "@components/cc-grid/components/custom-pager-pagesize-all/_index";
import { ColumnMenuFilter } from "@components/cc-grid/components/grid-column-menus/column-menu-filter/_index";
import { GridDynamicCell } from "@components/cc-grid/components/grid-dynamic-cell/_index";
import { CCFooterCell } from "@components/cc-grid/components/grid-footer-cell/_index";
import GridHeaderCell from "@components/cc-grid/components/grid-header-cell/_index";
import { GridHeaderCollapsedStateTool } from "@components/cc-grid/components/grid-header-collapsed-state-tool/_index";
import { loadOData } from "@components/cc-grid/components/grid-loader/api";
import {
  MARGIN_LEFT_MESSAGE_NO_RECORD,
  WIDTH_TO_SHOW_LABEL_ITEM_PER_PAGE,
} from "@components/cc-grid/constant";
import {
  DataResult,
  GroupDescriptor,
  State,
  process,
  toODataString,
} from "@progress/kendo-data-query";
import {
  PageChangeEvent,
  Pager,
  TableSelectableMode,
  getGroupIds,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridColumnMenuProps,
  GridColumnMenuWrapper,
  GridColumnResizeEvent,
  GridDataStateChangeEvent,
  GridDetailRowProps,
  GridExpandChangeEvent,
  GridFooterCellProps,
  GridHeaderCellProps,
  GridItemChangeEvent,
  GridNoRecords,
  GridRowClickEvent,
  GridRowDoubleClickEvent,
  GridRowProps,
  GridSelectionChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import {
  cloneDeep,
  differenceWith,
  isEmpty,
  isEqual,
  isFunction,
  isNaN,
  isNumber,
} from "lodash";
import React, {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router";
import { useKey, useMeasure } from "react-use";
import "./_index.scss";
import {
  CCGridLoadFailed,
  CCGridLoader,
  GridLoadingPanel,
} from "./components/grid-loader/_index";
import { SelectionColumnMenu } from "./components/grid-selection-column-menu/_index";
import {
  CHANGE_PAGE_SPEED,
  ExportFormat,
  gridDefaultProps as defaultProps,
  gridConfig,
} from "./config";
import {
  IColumnFields,
  IEditOptions,
  ISelection,
  SelectTypeEnum,
} from "./model";
import {
  calculateWidthCol,
  formatDisplayValue,
  getFileExportName,
  getFilterColumnsName,
  getFilterType,
  getGroupedListWithAggregate,
  getSortedListAfterGroup,
  getSortedListAfterUnGroup,
  isGroupHeader,
  notYetAggregation,
  pagerMessagesMap,
  reSizeEmptyColumn,
  transformData,
} from "./util";

export interface IDataReceivedParams {
  result?: DataResult;
  state: State;
}
export interface ICCGridProps {
  data?: any[];
  errorComponent?: JSX.Element | null;
  state?: State;
  onDataStateChange?: (state: State) => void;

  dataUrl?: string;
  /* Convert data after received data from the server when using dataUrl*/
  onDataReceived?: (
    params: IDataReceivedParams
  ) => Promise<DataResult | undefined>;

  gridId?: string;
  isUseCancelRequest?: boolean;

  fileExportName?: string;
  exportFormat?: ExportFormat;
  onExported?: (exportFormat: ExportFormat) => void;

  columnFields: IColumnFields[];
  onColumnFieldsChange?: (column: IColumnFields[]) => void;

  primaryField: string;

  className?: string;
  style?: React.CSSProperties;

  itemPerPage?: number;
  pagerType?: "numeric" | "input";
  isAutoHiddenPager?: boolean;

  detail?: null | ComponentType<GridDetailRowProps>;
  isLimitDetailWidth?: boolean;

  disableRowField?: string;
  canSelectField?: string;
  canNotSelectField?: string;
  selectedField?: string;
  expandField?: string;
  groupField?: string;

  editField?: string;

  expandMode?: "single" | "multiple" | "none";
  selectableMode?: "single" | "multiple" | "none";
  selectedRows?: any[];

  onSelectionChange?: (dataItem: any[]) => void;

  editableMode?: "cell" | "row";
  showEditSelectedLine?: boolean;
  onDataChange?: (dataItem: any[]) => void;

  toolbar?: React.ReactNode;
  groupDragMode?: "auto" | "fixed";
  columnMenu?: null | ComponentType<GridColumnMenuProps>;
  headerSelectionCell?: ComponentType<GridHeaderCellProps>;

  initialEditOptions?: IEditOptions;
  onTotalSelectedRowChange?: (totalSelectedRows: number) => void;
  isLoading?: boolean;
  readOnly?: boolean;
  disabledGrid?: boolean;
  pageSizes?: any[];
  onDataRowChange?: (
    dataRow: any,
    fieldChange: string,
    valueChange?: any
  ) => void;
  onRowDoubleClick?: (dataItem: GridRowDoubleClickEvent) => void;
  onExpandChange?: (event: GridExpandChangeEvent) => void;
  hideExpanderButton?: (dataItem: any) => boolean;
}
export const CCGrid = ({
  errorComponent,
  data = defaultProps.data,
  state = defaultProps.state,
  onDataStateChange = defaultProps.onDataStateChange,
  dataUrl = defaultProps.dataUrl,
  gridId,
  isUseCancelRequest = true,
  fileExportName,
  exportFormat,
  onExported,
  columnFields = defaultProps.columnFields,
  onColumnFieldsChange = defaultProps.onColumnFieldsChange,
  primaryField = defaultProps.primaryField,
  className = defaultProps.className,
  style = defaultProps.style,
  itemPerPage = defaultProps.itemPerPage,
  pagerType = defaultProps.pagerType,
  isAutoHiddenPager = defaultProps.isAutoHiddenPager,
  detail = defaultProps.detail,
  isLimitDetailWidth = defaultProps.isLimitDetailWidth,
  disableRowField = defaultProps.disableRowField,
  selectedField = defaultProps.selectedField,
  canSelectField = defaultProps.canSelectField,
  canNotSelectField = defaultProps.canNotSelectField,
  expandField = defaultProps.expandField,
  groupField = defaultProps.groupField,
  editField = defaultProps.editField,
  expandMode = defaultProps.expandMode,
  selectableMode = defaultProps.selectableMode,
  selectedRows = defaultProps.selectedRows,
  onSelectionChange = defaultProps.onSelectionChange,
  editableMode = defaultProps.editableMode,
  showEditSelectedLine = true,
  onDataChange = defaultProps.onDataChange,
  toolbar = defaultProps.toolbar,
  groupDragMode = defaultProps.groupDragMode,
  columnMenu = defaultProps.columnMenu,
  initialEditOptions = defaultProps.initialEditOptions,
  onTotalSelectedRowChange,
  isLoading,
  readOnly,
  disabledGrid,
  pageSizes: pageSize = defaultProps.pageSizes,
  onDataRowChange,
  onRowDoubleClick,
  onExpandChange,
  onDataReceived = async (param) => param.result,
  hideExpanderButton,
}: ICCGridProps) => {
  const element = useRef<HTMLDivElement>();
  const [ref, { width }] = useMeasure<HTMLFormElement>();
  const exporter = useRef<any>(undefined);
  const changePageTimeout = useRef<NodeJS.Timeout | null>(null);

  const { allSetting } = useGlobalStore();
  const oDataUrl = allSetting.oDataUrl;

  const location = useLocation();

  const [gridData, setGridData] = useState<any[]>(disabledGrid ? [] : data);
  const [gridSelectedRows, setGridSelectedRows] = useState<any[]>([]);
  const [gridColumns, setGridColumns] = useState<IColumnFields[]>([]);
  const [calculatedGridColumns, setCalculatedGridColumns] =
    useState<IColumnFields[]>(columnFields);
  const [gridDataState, setGridDataState] = useState<State>(
    //itemPerPage is set to -1 if default selected option is "All"
    //In this case, the $take query option is removed in order to get all records
    {
      ...defaultProps.state,
      ...state,
      take: state.take === -1 ? undefined : state.take ?? itemPerPage,
    }
  );
  const [filterColumnsName, setFilterColumnsName] = useState<string[]>(
    () => getFilterColumnsName(state.filter) ?? []
  );
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);

  const [totalData, setTotalData] = useState<number>(0);
  const [expandedRowIds, setExpandedRowIds] = useState<any[]>([]);
  const [expandedGroupIds, setExpandedGroupIds] = useState<any[]>([]);
  const [editOptions, setEditOptions] = useState<IEditOptions>({
    fields: [],
    inEditRowIds: [],
  });

  const isShowLabelItemPerPage =
    element.current &&
    element.current?.offsetWidth > WIDTH_TO_SHOW_LABEL_ITEM_PER_PAGE;

  const [lastRowClickEvent, setLastRowClickEvent] = useState<
    GridRowClickEvent | undefined
  >(undefined);
  const [selection, setSelection] = useState<ISelection>({
    selectType: SelectTypeEnum.PAGE,
    selectedRowsIds: [],
    excludedRowsIds: [],
    ignoreSelectIds: [],
  });
  const [isUseCustomPagerPagesizeAll, setIsUseCustomPagerPagesizeAll] =
    useState<boolean>(itemPerPage === -1); //itemPerPage is set to -1 if default selected option is "All"

  useEffect(() => {
    if (initialEditOptions) {
      setEditOptions(initialEditOptions);
    }
  }, [initialEditOptions]);

  /*  =============== Grid Export ================= */

  useEffect(() => {
    if (exportFormat === ExportFormat.EXCEL) {
      getExport();
    }
    // eslint-disable-next-line
  }, [exportFormat]);

  const getExport = () => {
    const newGridDataState: State = {
      ...gridDataState,
      skip: 0,
      take: totalData,
    };
    if (dataUrl && selection.selectType === SelectTypeEnum.ALL) {
      const params = toODataString(newGridDataState, { utcDates: true });
      loadOData(`${oDataUrl}${dataUrl}${params}`).then((response) => {
        if (isSuccessResponse(response)) {
          const filterSelected = response.data.value.filter(
            (data: any) =>
              !selection.excludedRowsIds.includes(data[primaryField])
          );
          const progressData = process(filterSelected, newGridDataState).data;
          exporter.current.save({
            data: progressData,
            group: gridDataState.group,
          });
        } else {
          console.error("Export failed");
          exporter.current.save([]);
        }
      });
    } else {
      const progressData = process(gridSelectedRows, newGridDataState).data;
      exporter.current.save({
        data: progressData,
        group: newGridDataState.group,
      });
    }
  };
  /*  =============== Change Grid Data State Function ================= */
  const handleChangeGridDataState = useCallback(
    (state: State, isFromProp?: boolean) => {
      const { take, skip, filter, sort, group } = state;
      const newDataState = {
        take:
          take !== undefined
            ? take
            : gridDataState.take || itemPerPage || defaultProps.state?.take,
        skip:
          skip !== undefined
            ? skip
            : gridDataState.skip || defaultProps.state?.skip,
        filter:
          filter !== undefined
            ? filter
            : gridDataState.filter || defaultProps.state?.filter,
        sort:
          sort !== undefined
            ? sort
            : gridDataState.sort || defaultProps.state?.sort,
        group:
          group !== undefined
            ? group
            : gridDataState.group || defaultProps.state?.group,
      };

      //itemPerPage is set to -1 if default selected option is "All"
      //In this case, the $take query option is removed in order to get all records
      if (newDataState.take === -1) delete newDataState.take;

      const newGroupList: Array<GroupDescriptor> = newDataState?.group
        ? [...newDataState.group]
        : [];
      const oldGroupList = gridDataState.group ?? [];
      if (newGroupList && notYetAggregation(newGroupList)) {
        newDataState.group = getGroupedListWithAggregate(
          primaryField,
          newDataState.group ?? []
        );
      }
      //Handle Group
      if (newGroupList.length > oldGroupList.length) {
        newDataState.sort = getSortedListAfterGroup(
          newGroupList,
          newDataState.sort ?? []
        );
      }
      //Handle Un-Group
      else if (newGroupList.length < oldGroupList.length) {
        const unGroupedList = differenceWith(oldGroupList, newGroupList);
        newDataState.sort = getSortedListAfterUnGroup(
          unGroupedList,
          newDataState.sort ?? [],
          newDataState.group ?? []
        );
      }
      setGridDataState(newDataState);
      if (onDataStateChange && !isFromProp) onDataStateChange(newDataState);
    },
    [itemPerPage, onDataStateChange, gridDataState, primaryField]
  );

  /*  =============== Grid Data and Grid Data State Effect From Props================= */

  useEffect(() => {
    if (state && !isEqual(state, gridDataState)) {
      handleChangeGridDataState(state, true);
      setFilterColumnsName(getFilterColumnsName(state.filter));
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (!isEqual(data, gridData) && !disabledGrid) setGridData(data || []);

    // eslint-disable-next-line
  }, [data, disabledGrid]);

  useEffect(() => {
    if (disabledGrid) setGridData([]);
  }, [disabledGrid]);

  useEffect(() => {
    if (selectedRows && !isEqual(selectedRows, gridSelectedRows)) {
      setSelection({
        selectType: SelectTypeEnum.PAGE,
        excludedRowsIds: [],
        selectedRowsIds: selectedRows.map((data) => data[primaryField]),
        ignoreSelectIds: [],
      });
      setGridSelectedRows(selectedRows);
    }

    // eslint-disable-next-line
  }, [selectedRows]);

  /*  =============== Check has detail component================= */
  const hasDetail = useMemo(() => {
    return detail ? true : false;
  }, [detail]);

  const isGridParent = useCallback(() => {
    const classNameDetail = element.current?.offsetParent?.className ?? "";
    const parentElement =
      element.current?.parentElement?.closest(".cc-grid-parent") ?? "";
    return !/k-detail-cell\b/gi.test(classNameDetail) && !parentElement;
  }, [element]);

  const isGridDetail = useCallback(() => {
    return !isGridParent();
  }, [isGridParent]);

  const parentWidth = useCallback(() => {
    const parent =
      element?.current?.parentElement?.closest(".cc-grid")?.clientWidth;
    return parent ? parent - gridConfig.size.grid.paddingX : 0;
  }, [element]);

  /*  =============== Get Total of detail's expand control and checkbox control ================= */
  const offsetGridWidth = useMemo(
    () =>
      (hasDetail
        ? gridConfig.size.grid.detailPaddingX
        : gridConfig.size.grid.paddingX) +
      (selectableMode && selectableMode !== "none"
        ? gridConfig.size.components.checkBoxWidth
        : 0),
    [selectableMode, hasDetail]
  );
  /*  =============== Check Row Selected ================= */
  const gridDataShowNotGroup = process(gridData, {
    ...gridDataState,
    skip: dataUrl ? 0 : gridDataState.skip,
    group: undefined,
  });
  const countSelectedRows = useMemo(() => {
    if (selection.selectType === SelectTypeEnum.PAGE) {
      return selection.selectedRowsIds.length;
    }
    return totalData - selection.excludedRowsIds.length;
  }, [totalData, selection]);
  const getIsSelectedAllThisPage = useCallback(
    (selection: ISelection, gridDataShowNotGroup: DataResult) => {
      const processData = gridDataShowNotGroup.data.filter(
        (item) => !item[disableRowField] || !item[canNotSelectField]
      );

      if (!processData.length) return false;
      if (selection.selectType === SelectTypeEnum.PAGE) {
        if (!selection.selectedRowsIds.length) return false;
      } else {
        if (!selection.excludedRowsIds.length) return true;
      }

      for (let i = 0; i < processData.length; i++) {
        if (selection.selectType === SelectTypeEnum.PAGE) {
          if (!selection.selectedRowsIds.includes(processData[i][primaryField]))
            return false;
        } else {
          if (selection.excludedRowsIds.includes(processData[i][primaryField]))
            return false;
        }
      }
      return true;
    },
    [primaryField, disableRowField, canNotSelectField]
  );

  const renderDataShow = (
    gridData: any[],
    gridDataState: State,
    selection: ISelection,
    expandedRowIds: any[],
    expandedGroupIds: any[]
  ) => {
    if (!gridData)
      return {
        total: 0,
        data: [],
      };
    const processDataState = { ...gridDataState };
    if (dataUrl) processDataState.sort = undefined;
    //TODO: fix temporary bug filter OData
    if (dataUrl) processDataState.filter = { logic: "and", filters: [] };
    const processedDataShow = process(gridData, {
      ...processDataState,
      skip: dataUrl ? 0 : processDataState.skip,
    });
    return {
      total: processedDataShow.total,
      data: transformData(
        {
          selectedField,
          expandField,
          primaryField,
          groupField,
          editField,
        },
        processedDataShow.data,
        selection,
        expandedRowIds,
        expandedGroupIds,
        editOptions,
        processDataState.group
      ),
    };
  };
  const dataShow = renderDataShow(
    gridData,
    gridDataState,
    selection,
    expandedRowIds,
    expandedGroupIds
  );

  /* ====  Handle Expand Group views  ==== */
  const processWithGroups = (data: any[], dataState: State) => {
    const newDataState = process(data, dataState);
    setGroupIds({ data: newDataState.data, group: dataState.group });
    return newDataState;
  };

  const expandedGroupViews = useCallback(
    (status: boolean) => {
      const dataShowClone = cloneDeep(dataShow);
      const dataStateWithoutPaging = processWithGroups(dataShowClone.data, {
        group: gridDataState.group,
      });
      status
        ? setExpandedGroupIds(
            getGroupIds({ data: dataStateWithoutPaging.data })
          )
        : setExpandedGroupIds([]);
    },
    [dataShow, gridDataState.group]
  );
  /* ====  / Handle Expand Group views  ==== */

  const isAllSelectedThisPage = getIsSelectedAllThisPage(
    selection,
    gridDataShowNotGroup
  );
  const handleOnPageSizeChange = useCallback(
    (pageSize: number | string) => {
      const { skip, take } = gridDataState;
      if (pageSize === 0 && skip && take && skip > 0) {
        handleChangeGridDataState({ skip: skip - take });
      }
    },
    [gridDataState, handleChangeGridDataState]
  );

  useEffect(() => {
    if (dataShow) {
      if (!dataUrl) setTotalData(dataShow.total);
      handleOnPageSizeChange(dataShow?.data?.length);
    }
    // eslint-disable-next-line
  }, [dataShow, dataUrl]);

  useEffect(() => {
    if (!onTotalSelectedRowChange) return;
    onTotalSelectedRowChange(countSelectedRows);
  }, [countSelectedRows, onTotalSelectedRowChange]);

  /*  =============== Initial Columns ================= */
  const handleSetCalculatedGridColumns = useCallback(
    (columns: IColumnFields[], data: any) => {
      let newColumns = calculateWidthCol(columns, data, gridConfig);
      const emptyIndex = newColumns.length - 1;
      if (emptyIndex >= 0 && newColumns[emptyIndex].field !== "") {
        newColumns.push({
          field: "",
          title: "",
          width: 0,
        });
      }
      setCalculatedGridColumns(newColumns);
    },
    []
  );
  useEffect(() => {
    if (!isEqual(columnFields, gridColumns)) {
      if (!isInEditMode) {
        handleSetCalculatedGridColumns(columnFields, gridData || []);
      }
    }
    // eslint-disable-next-line
  }, [gridData, columnFields]);

  useEffect(() => {
    const gridToolbarElement =
      element.current?.querySelector(".k-grid-toolbar");
    const toolbarButton = gridToolbarElement?.querySelectorAll(".k-button");
    if (toolbarButton && toolbarButton.length > 0) {
      if (readOnly) {
        toolbarButton.forEach((button) => {
          button.classList.add("k-disabled");
        });
      } else {
        toolbarButton.forEach((button) => {
          button.classList.remove("k-disabled");
        });
      }
    }
  }, [readOnly]);

  /*  =============== Resize Columns Effect ================= */
  useEffect(() => {
    let newColumns = reSizeEmptyColumn(
      calculatedGridColumns,
      width,
      offsetGridWidth,
      gridDataState
    );
    newColumns = newColumns.map((columnField: IColumnFields) =>
      isFunction(columnField.dynamicStyle)
        ? {
            ...columnField,
            width: columnField.dynamicStyle?.(gridDataState).width,
          }
        : columnField
    );
    setGridColumns(newColumns);
    if (onColumnFieldsChange) onColumnFieldsChange(newColumns);
  }, [
    width,
    calculatedGridColumns,
    offsetGridWidth,
    onColumnFieldsChange,
    gridDataState,
  ]);

  /*  =============== Get Kendo Props ================= */
  const getGroupable = useMemo(() => {
    if (!groupDragMode) return undefined;
    if (groupDragMode === "auto") {
      return state?.group && state.group.length > 0 ? true : false;
    }
    return true;
  }, [groupDragMode, state]);

  const isShowPager = useMemo(() => {
    return !isAutoHiddenPager || !itemPerPage || totalData > itemPerPage;
  }, [totalData, isAutoHiddenPager, itemPerPage]);

  /*  =============== Callback functions ================= */

  const handleColumnResize = useCallback(
    (event: GridColumnResizeEvent) => {
      if (event.oldWidth <= gridConfig.size.columns.minResizable) return;
      const newColumns = [...gridColumns];

      const index = event.columns.findIndex(
        (column) => column.id === event.targetColumnId
      );
      newColumns[
        index - (selectableMode && selectableMode !== "none" ? 1 : 0)
      ].width = event.oldWidth;

      const newColumnsAfterResizeEmptyColumn = reSizeEmptyColumn(
        newColumns,
        width,
        offsetGridWidth,
        gridDataState
      );

      setGridColumns(newColumnsAfterResizeEmptyColumn);
      if (onColumnFieldsChange)
        onColumnFieldsChange(newColumnsAfterResizeEmptyColumn);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridColumns, onColumnFieldsChange, selectableMode]
  );

  const handlePageChange = useCallback(
    (event: PageChangeEvent) => {
      let { skip, take } = event;
      if (isNaN(take)) {
        take = totalData;
        setIsUseCustomPagerPagesizeAll(true);
      }
      if (event.nativeEvent?.type === "input") {
        if (changePageTimeout.current) clearTimeout(changePageTimeout.current);
        changePageTimeout.current = setTimeout(() => {
          handleChangeGridDataState({ skip, take });
        }, CHANGE_PAGE_SPEED);
        return;
      }
      handleChangeGridDataState({ skip, take });
    },
    [handleChangeGridDataState, totalData]
  );

  const handleDataStateChange = useCallback(
    (event: GridDataStateChangeEvent) => {
      const { dataState } = event;
      handleChangeGridDataState(dataState);
      setFilterColumnsName(getFilterColumnsName(dataState.filter));
    },
    [handleChangeGridDataState]
  );
  const handleSelectionChange = useCallback(
    (dataItems: any[], mode: "single" | "multiple") => {
      let newSelectedRowIds = [...selection.selectedRowsIds];
      let newExpandedRowIds = [...selection.excludedRowsIds];
      let newSelectionType = selection.selectType;
      let newGridSelectedRows = [...gridSelectedRows];
      dataItems.forEach((dataItem) => {
        const dataItemId = dataItem[primaryField];
        const checked = dataItem[selectedField];
        if (selection.selectType === SelectTypeEnum.ALL) {
          if (checked) {
            if (mode === "single") {
              newSelectedRowIds = [dataItemId];
              newSelectionType = SelectTypeEnum.PAGE;
            } else {
              newExpandedRowIds = newExpandedRowIds.filter(
                (id) => dataItemId !== id
              );
            }
          } else {
            newExpandedRowIds = Array.from(
              new Set([...newExpandedRowIds, dataItemId])
            );
          }
        } else {
          if (checked) {
            if (mode === "single") {
              newSelectedRowIds = [dataItemId];
            } else {
              newSelectedRowIds = Array.from(
                new Set([...newSelectedRowIds, dataItemId])
              );
            }
          } else {
            if (mode === "single") {
              newSelectedRowIds = [];
            } else {
              newSelectedRowIds = newSelectedRowIds.filter(
                (id) => dataItemId !== id
              );
            }
          }
        }

        if (checked) {
          if (mode === "single") {
            newGridSelectedRows = [dataItem];
          } else {
            newGridSelectedRows = newGridSelectedRows.filter(
              (item) => item[primaryField] !== dataItemId
            );
            newGridSelectedRows.push(dataItem);
          }
        } else {
          if (mode === "single") {
            newGridSelectedRows = [];
          } else {
            newGridSelectedRows = newGridSelectedRows.filter(
              (data) => dataItemId !== data[primaryField]
            );
          }
        }
      });

      setSelection({
        selectType: newSelectionType,
        selectedRowsIds: newSelectedRowIds,
        excludedRowsIds: newExpandedRowIds,
        ignoreSelectIds: selection.ignoreSelectIds,
      });
      setGridSelectedRows(newGridSelectedRows);
      if (onSelectionChange) onSelectionChange(newGridSelectedRows);

      //Show editMode all fields
      if (selectableMode !== "single" || !showEditSelectedLine) return;
      const canEditFields = gridColumns
        .filter((column) => column.editable)
        .map((column) => column.field);
      if (!canEditFields.length) return;
      if (newGridSelectedRows?.[0]?.[selectedField]) {
        const dataItemId = newGridSelectedRows[0][primaryField];
        setEditOptions((editOptions) => ({
          ...editOptions,
          fields: canEditFields,
          inEditRowIds: [dataItemId],
        }));
      } else {
        setEditOptions(
          initialEditOptions
            ? initialEditOptions
            : {
                fields: [],
                inEditRowIds: [],
              }
        );
      }
    },
    // eslint-disable-next-line
    [
      gridSelectedRows,
      onSelectionChange,
      primaryField,
      selection,
      selectedField,
    ]
  );

  const handleGridSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      const { dataItem } = event;
      const mode = selectableMode as TableSelectableMode;
      if (!dataItem || readOnly) return;
      const newDataItem = { ...dataItem };
      newDataItem[selectedField] = !newDataItem[selectedField];
      handleSelectionChange([newDataItem], mode);
      setLastRowClickEvent({ ...event, dataItem: newDataItem });
    },
    [handleSelectionChange, selectedField, selectableMode, readOnly]
  );

  const handleRowClick = useCallback(
    (event: GridRowClickEvent) => {
      const { dataItem, nativeEvent } = event;
      const { shiftKey, ctrlKey } = nativeEvent;
      if (!dataItem || readOnly || isGroupHeader(dataItem)) return;
      if (
        canSelectField !== defaultProps.canSelectField &&
        !dataItem[canSelectField] // prevent clicking if can't select
      ) {
        return;
      }

      if (!shiftKey) {
        const newDataItem = { ...dataItem };
        newDataItem[selectedField] = ctrlKey ? !dataItem[selectedField] : true;

        handleSelectionChange(
          [newDataItem],
          (isAllSelectedThisPage && !newDataItem[selectedField] && ctrlKey) ||
            (((lastRowClickEvent && lastRowClickEvent.nativeEvent.ctrlKey) ||
              !isAllSelectedThisPage) &&
              ctrlKey &&
              selectableMode === "multiple")
            ? "multiple"
            : "single"
        );
        setLastRowClickEvent({ ...event, dataItem: newDataItem });
      } else {
        const processData = gridDataShowNotGroup.data;
        let firstIndex =
          lastRowClickEvent?.dataItem &&
          lastRowClickEvent?.dataItem[selectedField]
            ? processData.findIndex(
                (item: any) =>
                  lastRowClickEvent?.dataItem[primaryField] ===
                  item[primaryField]
              )
            : 0;
        firstIndex = firstIndex !== -1 ? firstIndex : 0;
        let lastIndex = dataItem
          ? processData.findIndex(
              (item: any) => dataItem[primaryField] === item[primaryField]
            )
          : 0;
        lastIndex = lastIndex !== -1 ? lastIndex : 0;
        if (lastIndex < firstIndex) {
          firstIndex = firstIndex + lastIndex;
          lastIndex = firstIndex - lastIndex;
          firstIndex = firstIndex - lastIndex;
        }
        const newDataItems = processData
          .slice(firstIndex, lastIndex + 1)
          .map((item) => {
            if (!item[disableRowField] || !item[canNotSelectField])
              return { ...item, [selectedField]: true };
            return item;
          });
        handleSelectionChange(newDataItems, "multiple");
      }
    },
    [
      handleSelectionChange,
      selectedField,
      selectableMode,
      lastRowClickEvent,
      isAllSelectedThisPage,
      gridDataShowNotGroup,
      primaryField,
      disableRowField,
      canNotSelectField,
      readOnly,
      canSelectField,
    ]
  );

  const handleHeaderSelectionChange = React.useCallback(
    (checked: boolean, isSelectAllPage?: boolean) => {
      let newGridSelectedRows = [...gridSelectedRows];
      let newSelectedRowIds = [...selection.selectedRowsIds];
      let newExcludedRowIds = [...selection.excludedRowsIds];
      const processedData = process(gridData, {
        ...gridDataState,
        skip: dataUrl ? 0 : gridDataState.skip,
        group: undefined,
      }).data;

      let effectSelectIds: any[] = [];
      let ignoreSelectIds: any[] = [];
      processedData.forEach((item) => {
        // Check row disable and can't select
        if (
          item &&
          ((item[disableRowField] && !item[canSelectField]) ||
            item[canSelectField] === false ||
            item[canNotSelectField]) // Ignore field if can't select
        ) {
          ignoreSelectIds.push(item[primaryField]);
        } else {
          effectSelectIds.push(item[primaryField]);
        }
      });

      const ignoreSelectedRowIds = ignoreSelectIds.filter((id) =>
        selection.selectedRowsIds.includes(id)
      );

      const ignoreExcludedRowsIds = ignoreSelectIds.filter((id) =>
        selection.selectType === SelectTypeEnum.ALL
          ? selection.excludedRowsIds.includes(id)
          : !selection.selectedRowsIds.includes(id)
      );

      let effectSelectedDataRows: any[] = [];
      let ignoreSelectedDataRows: any[] = [];

      gridData.forEach((item) => {
        const itemId = item[primaryField];
        if (
          (item && item[disableRowField] && !item[canSelectField]) ||
          (item && item[canNotSelectField]) // Ignore field if can't select
        ) {
          if (
            selection.selectType === SelectTypeEnum.ALL
              ? !ignoreExcludedRowsIds.includes(itemId)
              : ignoreSelectedRowIds.includes(itemId)
          ) {
            ignoreSelectedDataRows.push(item);
            effectSelectedDataRows.push(item);
          }
        } else {
          effectSelectedDataRows.push(item);
        }
      });
      if (checked) {
        if (isSelectAllPage) {
          newExcludedRowIds = [...ignoreExcludedRowsIds];
          newSelectedRowIds = [...effectSelectIds, ...ignoreSelectedRowIds];
          newGridSelectedRows = [...effectSelectedDataRows];
        } else {
          newSelectedRowIds = Array.from(
            new Set([
              //...newSelectedRowIds, //hide this code to reset all selected items when selecting this page
              ...effectSelectIds,
              ...ignoreSelectedRowIds,
            ])
          );
          newGridSelectedRows = newGridSelectedRows.filter((item) =>
            newSelectedRowIds.includes(item[primaryField])
          );
          const currentSelectedRowIds = newGridSelectedRows.map(
            (row) => row[primaryField]
          );
          processedData.forEach((item) => {
            const itemId = item[primaryField];
            if (
              (effectSelectIds.includes(itemId) ||
                ignoreSelectedRowIds.includes(itemId)) &&
              !currentSelectedRowIds.includes(itemId)
            ) {
              newGridSelectedRows.push(item);
            }
          });
        }
        setSelection({
          selectType: isSelectAllPage
            ? SelectTypeEnum.ALL
            : SelectTypeEnum.PAGE,
          selectedRowsIds: newSelectedRowIds,
          excludedRowsIds: newExcludedRowIds,
          ignoreSelectIds: ignoreSelectIds,
        });
      } else {
        if (selection.selectType === SelectTypeEnum.ALL) {
          newSelectedRowIds = [...ignoreSelectedRowIds];
          newExcludedRowIds = [...ignoreExcludedRowsIds];
          newGridSelectedRows = [...ignoreSelectedDataRows];
        } else {
          newSelectedRowIds = newSelectedRowIds.filter(
            (id) => !effectSelectIds.includes(id)
          );
          newGridSelectedRows = newGridSelectedRows.filter(
            (item) => !effectSelectIds.includes(item[primaryField])
          );
        }
        setSelection({
          selectType: SelectTypeEnum.PAGE,
          selectedRowsIds: newSelectedRowIds,
          excludedRowsIds: newExcludedRowIds,
          ignoreSelectIds: ignoreSelectIds,
        });
      }

      setGridSelectedRows(newGridSelectedRows);
      if (onSelectionChange) onSelectionChange(newGridSelectedRows);
    },
    [
      dataUrl,
      gridData,
      gridDataState,
      primaryField,
      selection,
      gridSelectedRows,
      onSelectionChange,
      disableRowField,
      canNotSelectField,
      canSelectField,
    ]
  );

  const handleExpandChange = useCallback(
    (event: GridExpandChangeEvent) => {
      const { dataItem, dataIndex } = event;
      if (!dataItem) return;
      if (isFunction(onExpandChange)) onExpandChange(event);
      if (dataIndex !== -1) {
        //Selection change
        const newDataItem = { ...dataItem };

        // Expand data
        const dataItemId = dataItem[primaryField];
        const isExpanded = dataItem[expandField];
        newDataItem[selectedField] = !isExpanded;

        if (expandMode && expandMode === "multiple") {
          let newExpandedRowIds = [...expandedRowIds];
          newExpandedRowIds = newExpandedRowIds.filter(
            (id) => id !== dataItemId
          );
          if (newExpandedRowIds.length === expandedRowIds.length) {
            newExpandedRowIds.push(dataItemId);
          }
          setExpandedRowIds(newExpandedRowIds);
          handleSelectionChange([newDataItem], "multiple");
        } else {
          if (isExpanded) {
            setExpandedRowIds([]);
          } else {
            setExpandedRowIds([dataItemId]);
          }
          handleSelectionChange([newDataItem], "single");
        }
      } else {
        // Expand group
        const groupId = dataItem[groupField];
        let newExpandedGroupIds = [...expandedGroupIds];
        newExpandedGroupIds = newExpandedGroupIds.filter(
          (id) => id !== groupId
        );
        if (newExpandedGroupIds.length === expandedGroupIds.length) {
          newExpandedGroupIds.push(groupId);
        }
        setExpandedGroupIds(newExpandedGroupIds);
      }
    },
    [
      expandedGroupIds,
      expandedRowIds,
      expandField,
      primaryField,
      groupField,
      selectedField,
      handleSelectionChange,
      expandMode,
      onExpandChange,
    ]
  );

  const handleOnDataReceived = useCallback(
    async (params: IDataReceivedParams) => {
      const result = await onDataReceived(params);
      if (result) {
        setGridData(result.data);
        setTotalData(result.total);
        if (onDataChange) {
          onDataChange(result.data);
        }
        if (selection.selectType === SelectTypeEnum.ALL) {
          let newGridSelectedRows = [...gridSelectedRows];
          let newSelectedRowIds = [...selection.selectedRowsIds];
          result.data.forEach((item) => {
            const itemId = item[primaryField];
            if (
              !selection.excludedRowsIds.includes(itemId) &&
              !selection.selectedRowsIds.includes(itemId)
            ) {
              newGridSelectedRows.push(item);
              newSelectedRowIds.push(itemId);
            }
          });
          setGridSelectedRows(newGridSelectedRows);
          setSelection({ ...selection, selectedRowsIds: newSelectedRowIds });

          if (onSelectionChange) onSelectionChange(newGridSelectedRows);
        }
      } else {
        setGridData([]);
        setTotalData(0);
        console.error("Get data failed!");
      }
    },
    [
      onDataChange,
      onDataReceived,
      gridSelectedRows,
      primaryField,
      onSelectionChange,
      selection,
    ]
  );

  const handleToggleLockColumn = useCallback(
    (index: number) => {
      let newGridColum = [...gridColumns];
      newGridColum[index] = {
        ...newGridColum[index],
        locked: !newGridColum[index].locked,
      };
      handleSetCalculatedGridColumns(newGridColum, data);
    },
    [gridColumns, handleSetCalculatedGridColumns, data]
  );
  /* ==================== Start using for Editable Grid ==========================  */
  const handleEditRow = useCallback(
    (event: GridRowClickEvent) => {
      const { dataItem } = event;
      if (!dataItem) return;
      const dataItemId = dataItem[primaryField];
      setEditOptions((editOptions) => ({
        ...editOptions,
        inEditRowIds: [dataItemId],
      }));
      setSelection((selection) => ({
        ...selection,
        selectType: SelectTypeEnum.PAGE,
        selectedRowsIds: [dataItemId],
      }));
    },
    [primaryField]
  );
  const handleItemChange = useCallback(
    (event: GridItemChangeEvent) => {
      setIsInEditMode(true);
      const { dataItem, field, value } = event;

      const newGridData = gridData.map((item) =>
        item[primaryField] === dataItem[primaryField]
          ? { ...item, [field ?? ""]: value }
          : item
      );
      setGridData(newGridData);
      if (onDataChange) onDataChange(newGridData);
      if (onDataRowChange)
        onDataRowChange(
          { ...dataItem, [field ?? ""]: value },
          field ?? "",
          value
        );

      //Update expanded items
      if (hasDetail) {
        const dataItemId = dataItem[primaryField];
        let newExpandedRowIds = cloneDeep(expandedRowIds);
        newExpandedRowIds = newExpandedRowIds.filter((id) => id !== dataItemId);
        if (dataItem[expandField]) {
          newExpandedRowIds.push(dataItemId);
        }
        if (newExpandedRowIds.length !== expandedRowIds.length) {
          setExpandedRowIds(newExpandedRowIds);
        }
      }
    },
    // eslint-disable-next-line
    [gridData, onDataChange, onDataRowChange, primaryField, expandedRowIds]
  );

  const handleEditCell = useCallback(
    (dataItem: any, field: string) => {
      if (!dataItem || !editableMode || readOnly) return;
      const dataItemId = dataItem[primaryField];
      setEditOptions({ fields: [field], inEditRowIds: [dataItemId] });
    },
    [primaryField, editableMode, readOnly]
  );
  const handleOnPressEnter = useCallback(() => {
    setEditOptions({
      fields: [],
      inEditRowIds: [],
    });
    setIsInEditMode(false);
  }, []);
  useKey("Enter", handleOnPressEnter);

  /* ==================== End using for Editable Grid ==========================  */

  const selectedColumnMenuStatus = useMemo(() => {
    return countSelectedRows > 0 &&
      countSelectedRows + selection.ignoreSelectIds.length >= totalData
      ? "allPage"
      : isAllSelectedThisPage
      ? "thisPage"
      : undefined;
  }, [selection, countSelectedRows, totalData, isAllSelectedThisPage]);

  const customCellRender = (td: any, props: GridCellProps) => {
    const field: IColumnFields | undefined = gridColumns.find(
      (item: any) => item.field === props.dataItem.field
    );
    const formatField = field?.format;
    // Show/hide expander button
    if (
      hasDetail &&
      props.field === expandField &&
      isFunction(hideExpanderButton) &&
      hideExpanderButton(props.dataItem)
    ) {
      return <td></td>;
    }
    if (
      td &&
      td.props?.children &&
      td.props.children?.props?.children &&
      (formatField || props.rowType === "groupHeader")
    ) {
      let children = [...td.props.children.props.children];
      if (children?.[1] && formatField) {
        children[1] = formatDisplayValue(formatField, children[1]);
      }
      if (
        props.rowType === "groupHeader" &&
        props.dataItem?.aggregates?.[primaryField]?.count
      ) {
        children[1] = children?.[1]
          ? `${children[1]} (${props.dataItem.aggregates?.[primaryField]?.count})`
          : `(${props.dataItem.aggregates?.[primaryField]?.count})`;
      }
      return React.cloneElement(td, td.props, children);
    }
    return td;
  };
  const customRowRender = useCallback(
    (row: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
      let className = row.props.className;
      if (props.dataItem && props.dataItem[disableRowField]) {
        const canSelectRow = props.dataItem[canSelectField];
        className += canSelectRow
          ? " cc-disabled-row-can-select"
          : " cc-disabled-row";
      }
      if (props.dataItem[canSelectField] === false || readOnly) {
        className += " cc-row-can-not-select";
      }
      if (props.dataItem[canNotSelectField]) {
        className += " cc-disabled-row-can-not-select";
      }
      return React.cloneElement(row, {
        ...row.props,
        className,
      });
    },
    [disableRowField, canSelectField, readOnly, canNotSelectField]
  );

  useEffect(() => {
    return () => {
      if (changePageTimeout.current) {
        clearTimeout(changePageTimeout.current);
      }
    };
  }, []);

  const renderCell = useCallback(
    (cell: GridCellProps) => {
      return (
        <GridDynamicCell
          cell={cell}
          columnFields={gridColumns}
          primaryField={primaryField}
          editField={editField}
          editableMode={editableMode}
          onEnterEditCell={handleEditCell}
          disabledGrid={disabledGrid || false}
        />
      );
    },
    [
      gridColumns,
      primaryField,
      editableMode,
      editField,
      handleEditCell,
      disabledGrid,
    ]
  );

  const renderPager = () => {
    if (!isShowPager) return null;
    return !isUseCustomPagerPagesizeAll ? (
      <Pager
        messagesMap={(messageKey: string) =>
          pagerMessagesMap(
            messageKey,
            selectableMode && selectableMode !== "none"
              ? countSelectedRows
              : undefined,
            isShowLabelItemPerPage
          )
        }
        skip={gridDataState.skip || 0}
        take={gridDataState.take || 0}
        total={totalData}
        buttonCount={gridConfig.paging.buttonCount}
        info={gridConfig.paging.info}
        type={pagerType}
        previousNext={gridConfig.paging.previousNext}
        pageSizes={pageSize}
        onPageChange={handlePageChange}
      />
    ) : (
      <CustomPagerPagesizeAll
        total={totalData}
        skip={gridDataState.skip || 0}
        take={gridDataState.take || 0}
        pageSizes={pageSize}
        totalSelectedRows={countSelectedRows}
        setGridDataState={handleChangeGridDataState}
        setIsUseCustomPagerPagesizeAll={setIsUseCustomPagerPagesizeAll}
        isShowLabelItemPerPage
      />
    );
  };

  //#region Handle loading & load fail & no record position ==============================/
  const positionLoad = useCallback(() => {
    const gridParent =
      element.current?.parentElement?.closest(".cc-grid-parent")?.clientWidth;
    const gridParentWidth = gridParent
      ? gridParent - gridConfig.size.grid.detailPaddingX
      : "100%";

    const gridContent = element.current?.querySelector(".k-grid-content");
    const gridContentWidth =
      (gridContent?.clientWidth ?? 0) - gridConfig.size.grid.paddingX;

    const width =
      isNumber(gridParentWidth) && +gridParentWidth < gridContentWidth
        ? gridParentWidth
        : gridContentWidth;

    return {
      width,
      marginLeft: isGridDetail() ? MARGIN_LEFT_MESSAGE_NO_RECORD : "unset",
      gridContent,
      isGridDetail: isGridDetail(),
    };
  }, [isGridDetail]);
  //#endregion Handle loading & load fail & no record position ===========================/

  return (
    <div
      ref={(div: HTMLDivElement) => {
        element.current = div;
        return div;
      }}
      className={`cc-grid ${className ? className : ""} ${
        isGridParent() ? "cc-grid-parent" : ""
      }`}
      style={
        isGridDetail() && isLimitDetailWidth
          ? { width: parentWidth() - gridConfig.size.grid.detailPaddingX }
          : {}
      }
    >
      {!!gridDataState?.group?.length && (
        <GridHeaderCollapsedStateTool
          setExpandedGroupViews={expandedGroupViews}
        />
      )}
      <form
        ref={ref}
        className="cc-grid-container"
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <ExcelExport
          fileName={fileExportName || getFileExportName(location.pathname)}
          ref={exporter}
          onExportComplete={() => {
            if (onExported) onExported(ExportFormat.NONE);
          }}
          group={gridDataState.group}
        >
          {gridColumns.map((item, index) => {
            return (
              <ExcelExportColumn
                key={index}
                field={item.field}
                title={item.title}
                width={Number(item.width) || 150}
              />
            );
          })}
        </ExcelExport>
        <Grid
          resizable
          pageable
          sortable
          reorderable
          style={style}
          editField={editField}
          selectedField={selectedField}
          expandField={expandField}
          groupable={getGroupable}
          filterOperators={gridConfig.filterOperators}
          data={dataShow}
          {...gridDataState}
          onItemChange={handleItemChange}
          onColumnResize={handleColumnResize}
          onSelectionChange={handleGridSelectionChange}
          onExpandChange={handleExpandChange}
          onRowClick={
            editableMode && editableMode === "row"
              ? handleEditRow
              : handleRowClick
          }
          onDataStateChange={handleDataStateChange}
          //@TODO
          /*rowRender={editableMode ? customRowRender : undefined} */
          cellRender={customCellRender}
          rowRender={customRowRender}
          detail={detail ? detail : undefined}
          pager={() => renderPager()}
          onRowDoubleClick={onRowDoubleClick}
        >
          {toolbar ? <GridToolbar>{toolbar}</GridToolbar> : null}
          {selectableMode && selectableMode !== "none" && (
            <GridColumn
              groupable={false}
              resizable={false}
              locked
              editable={false}
              className="cc-checkbox-locked"
              headerClassName="cc-grid-selection-column-header"
              field={selectedField}
              width={gridConfig.size.components.checkBoxWidth}
              columnMenu={
                !selectableMode || !(selectableMode === "multiple")
                  ? undefined
                  : (props) => (
                      <SelectionColumnMenu
                        {...props}
                        selectedStatus={selectedColumnMenuStatus}
                        handleHeaderSelectionChange={
                          handleHeaderSelectionChange
                        }
                        isReadOnly={readOnly}
                      />
                    )
              }
              headerCell={
                selectableMode === "multiple"
                  ? (props) => (
                      <>
                        <Checkbox
                          checked={isAllSelectedThisPage}
                          disabled={readOnly}
                          onChange={(event: CheckboxChangeEvent) => {
                            if (readOnly) return;
                            handleHeaderSelectionChange(event.value);
                          }}
                        />
                        <GridColumnMenuWrapper
                          {...props.columnMenuWrapperProps}
                        />
                      </>
                    )
                  : () => null
              }
            />
          )}

          {gridColumns.map((column: IColumnFields, index: number) => {
            return (
              <GridColumn
                key={index}
                minResizableWidth={gridConfig.size.columns.minResizable}
                className={`${
                  column.locked ? "cc-background-color-locked" : ""
                } text-overflow-ellipsis`}
                field={column.field}
                locked={column.locked}
                title={column.title}
                filter={getFilterType(column)}
                width={column.width}
                //@TODO: Need to discuss and have solution to merge all editable flags
                editable={
                  (editableMode || (editableMode && editableMode === "row")) &&
                  column.editable &&
                  !readOnly
                    ? column.editable
                    : false
                }
                cell={column.cell ? column.cell : renderCell}
                //@TODO: Need to double check and remove editor property if we can
                editor={column.editor}
                headerClassName="cc-grid-content-column-header"
                headerCell={(props: GridHeaderCellProps) => (
                  <>
                    <GridHeaderCell
                      {...props}
                      showFilter={
                        (props.field &&
                          filterColumnsName &&
                          filterColumnsName.includes(props.field)) ||
                        false
                      }
                      iconHeader={column.iconHeaderCell}
                      id={column?.id ?? ""}
                    />
                    {!isEmpty(column.field) && (
                      <GridColumnMenuWrapper
                        {...props.columnMenuWrapperProps}
                      />
                    )}
                  </>
                )}
                columnMenu={
                  column.field === "" || column.filterable === false
                    ? undefined
                    : columnMenu
                    ? columnMenu
                    : (props: GridColumnMenuProps, e) => {
                        const ColumnMenu = column.columnMenu
                          ? column.columnMenu
                          : ColumnMenuFilter;
                        return (
                          <ColumnMenu
                            isLocked={column.locked}
                            columnMenuProps={props}
                            handelToggleLookColumn={() => {
                              handleToggleLockColumn(index);
                            }}
                            columnConfig={column}
                          />
                        );
                      }
                }
                footerCell={
                  column.footerCell
                    ? (props: GridFooterCellProps) => (
                        <CCFooterCell
                          footerCell={props}
                          columnField={column}
                          gridData={gridData}
                        />
                      )
                    : undefined
                }
              />
            );
          })}
          <GridNoRecords>
            <div
              className="cc-grid-no-records"
              style={{
                width: positionLoad().width,
                marginLeft: positionLoad().marginLeft,
              }}
            >
              No records available
            </div>
          </GridNoRecords>
        </Grid>
        {errorComponent ? (
          <CCGridLoadFailed
            errorComponent={errorComponent}
            positionLoad={positionLoad}
            setGridData={setGridData}
          />
        ) : null}
        {dataUrl && element.current ? (
          <CCGridLoader
            positionLoad={positionLoad}
            dataUrl={dataUrl}
            gridId={gridId}
            gridDataState={gridDataState}
            onDataReceived={handleOnDataReceived}
            isUseCancelRequest={isUseCancelRequest}
            isNotCallDataGrid={disabledGrid}
          />
        ) : isLoading && element.current ? (
          <GridLoadingPanel positionLoad={positionLoad} />
        ) : null}
      </form>
    </div>
  );
};
