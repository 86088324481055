import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import {
  getInitialReplaceCertificate,
  getWorkflowReplaceCertificate,
  postProcessReplaceCertificate,
} from "@app/products/property/certificates/[id]/components/form-steps/replace-certificate/api";
import { CertificateDetailFormStep } from "@app/products/property/certificates/[id]/components/form-steps/replace-certificate/components/form-elements/certificate-details/_index";
import { useCertificateDetailStepStore } from "@app/products/property/certificates/[id]/components/form-steps/replace-certificate/components/form-elements/certificate-details/store";
import { ReplaceCertificateDetailFormStep } from "@app/products/property/certificates/[id]/components/form-steps/replace-certificate/components/form-elements/details/_index";
import { useNewCertificateDetailStepStoreContext } from "@app/products/property/certificates/[id]/components/form-steps/replace-certificate/components/form-elements/details/store";
import {
  DTO_Certificate_LOVs,
  DTO_Workflow_CertificateReplace,
  ReplaceCertificateKeysOfSteps,
  keysOfSendSteps,
} from "@app/products/property/certificates/[id]/components/form-steps/replace-certificate/model";
import { PROPERTY_CERTIFICATE_ROUTE } from "@app/products/property/certificates/[id]/constant";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import {
  getTitleWorkflow,
  isApprovalModeWorkflow,
} from "@app/products/property/components/action-bar/property-workflow/util";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ResponsePacket } from "@common/models/identityPacket";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { head, isNil, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IReplaceCertificateDialog {
  onClose: () => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  isReadOnly?: boolean;
  isShowCancelWorkflowButton?: boolean;
  statusBadge?: IBadgeDialog[];
}
export const ReplaceCertificateDialog = observer(
  ({
    onClose,
    isSaveOnNextStep = false,
    isIncompleteMode = false,
    isFromActionList = false,
    prefixTitle,
    suffixTitle,
    dataFromActionList,
    isReadOnly = false,
    isShowCancelWorkflowButton = false,
    statusBadge,
  }: IReplaceCertificateDialog) => {
    //#region <Store>

    const { setCertificateDetailStepLOVs } = useCertificateDetailStepStore();
    const { pushNotification } = useCCAppNotificationStore();
    //set data for modes
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { currentOrganisationMode, currentFormTitle } =
      useCommonProductStore();
    const isLLS = currentOrganisationMode(OrganisationMode.LLS);
    const { clearSelectedItems } = useCCProductListViewStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
    const { setLovDetail } = useNewCertificateDetailStepStoreContext();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    //#endregion

    //#region <Local state>
    const formStepRef = useRef<ICCFormStepNotificationHandle | null>(null);
    const [workflowInitData, setWorkflowInitData] =
      useState<DTO_Workflow_CertificateReplace>();
    const [isLoadingInStep, setIsLoadingInStep] = useState<boolean>(false);
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [listIDs, setListIDs] = useState<number[]>([]);
    //#endregion

    const isApprovalMode = useMemo(() => {
      return isApprovalModeWorkflow(
        workflowHeader?.WorkflowDraft?.WD_Workflow_Approval_Status
      );
    }, [workflowHeader]);

    const certificateType =
      workflowInitData?.WorkflowDetail?.CertificateDetails?.CertificateType;

    //#region <Title header>
    //Use memo
    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) ?? "Replace Certificate";
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line
    }, [workflowHeader, prefixTitle, suffixTitle]);
    //#endregion

    //region <Initial value>
    const initialValue = useMemo((): any => {
      let initialDetail = {
        ...workflowInitData?.WorkflowDetail?.Details,
        ReasonId:
          workflowInitData?.WorkflowDetail?.Details?.ReasonId === 0
            ? undefined
            : workflowInitData?.WorkflowDetail?.Details?.ReasonId,
        WD_Assessment_Group_Id:
          workflowInitData?.WorkflowHeader?.WorkflowDraft
            ?.WD_Assessment_Group_Id ??
          workflowInitData?.WorkflowHeader?.OfficerAssessmentGroups?.[0]
            ?.Code ??
          null,
        Officer: workflowInitData?.WorkflowHeader?.Officer,
        OfficerAssessmentGroups:
          workflowInitData?.WorkflowHeader?.OfficerAssessmentGroups,
      };
      let initCertificateDetails: any = {
        CertificateType: certificateType === 0 ? undefined : certificateType,
        Assessments:
          workflowInitData?.WorkflowDetail?.CertificateDetails?.Assessments ??
          [],
        Parcels:
          workflowInitData?.WorkflowDetail?.CertificateDetails?.Parcels ?? [],
        AssociateTitles:
          workflowInitData?.WorkflowDetail?.CertificateDetails?.Titles ?? [],
        PIC_Ids:
          workflowInitData?.WorkflowDetail?.CertificateDetails?.SelectedPICs
            ?.PIC_Ids,
        PICs:
          workflowInitData?.WorkflowDetail?.CertificateDetails?.SelectedPICs
            ?.PICs ?? [],
      };

      return {
        [ReplaceCertificateKeysOfSteps.Details]: initialDetail,
        [ReplaceCertificateKeysOfSteps.CertificateDetails]:
          initCertificateDetails,
      };
      // eslint-disable-next-line
    }, [workflowInitData]);
    //#endregion

    const steps: IStep[] = [
      {
        label: "Details",
        component: ReplaceCertificateDetailFormStep,
        visible: true,
        key: ReplaceCertificateKeysOfSteps.Details,
        initialValues: initialValue[ReplaceCertificateKeysOfSteps.Details],
        options: {
          isDisabled: workflowDraftId,
          isDisabledStatus: true,
        },
      },
      {
        label: "Certificate details",
        initialValues:
          initialValue[ReplaceCertificateKeysOfSteps.CertificateDetails],
        component: CertificateDetailFormStep,
        visible: true,
        key: ReplaceCertificateKeysOfSteps.CertificateDetails,
        options: {
          isReadOnly,
          isLLS,
          listIDs,
          setIsLoadingInStep,
        },
      },
    ];

    //#region handle Cancel Button>
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessReplaceCertificate,
          dataCancel: data,
          defaultSuccessMessage:
            "Replace certificate was cancelled successfully.",
          defaultErrorMessage: "Replace certificate could not be cancelled.",
        });
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <Handle close dialog>
    /**
     * @param renderProps
     */
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        //Store submit event
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <Handle save and next>
    const handleSaveAndNext = async (
      payload: DTO_Workflow_CertificateReplace,
      isCloseDialog: boolean = false,
      isRefreshWorkflowData: boolean = false
    ): Promise<boolean> => {
      //Calling process Save at next button
      const response = await postProcessReplaceCertificate(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingOnNext(false);

      //set default notification
      const defaultSuccessMessage =
        "Replace certificate was saved successfully.";
      const defaultFailedMessage = "Replace certificate could not be saved.";
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (isCloseDialog) {
          onClose();
          pushNotification({
            title: response?.data?.Notification ?? defaultSuccessMessage,
            type: "success",
          });
        }
        // check is the first saving
        if (isFirstSave) {
          setIsFirstSave(false);
          //set current workflowDraft Id
          setWorkflowDraftId(response?.data?.ID || 0);
          // set payload to send
          setWorkflowHeader({
            ...workflowHeader,
            WorkflowDraft: {
              ...workflowHeader.WorkflowDraft,
              Workflow_Draft_Id: response?.data?.ID || workflowDraftId || 0,
            },
          });
          // get new data from draftId after first save
          refreshWorkflowData(response?.data?.ID || 0);
        }
        // TODO: Show notification after reloading the step -> enhance later
        if (isRefreshWorkflowData) {
          getWorkflowData().then(() => {
            formStepRef?.current
              ?.getNotificationFormStep()
              ?.current?.pushNotification({
                title: response?.data?.Notification ?? defaultSuccessMessage,
                type: "success",
              });
          });
        }
        return true;
      } else {
        const showNotification = () => {
          formStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title:
                (isRefreshWorkflowData
                  ? head(response?.data?.Errors)
                  : response.data?.ErrorMessage) ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        };
        if (isRefreshWorkflowData) {
          getWorkflowData().then(showNotification);
        } else {
          showNotification();
        }
        return false;
      }
    };
    //#endregion

    //#region <Handle next button>
    /**
     * @param data
     * @param step
     * @param keyStep
     * @returns
     */
    const handleNextButton = async (data: DTO_Workflow_CertificateReplace) => {
      setIsLoadingOnNext(true);
      const processPayload = processData(data);
      //send data to call api save
      return handleSaveAndNext(processPayload);
    };
    //#endregion

    //#region <Handle park process>
    /**
     * @param payload
     */
    const handleParkProcess = async (
      payload: DTO_Workflow_CertificateReplace
    ) => {
      setIsLoadingPark(true);
      const parkProps: IProcessWorkflow<DTO_Workflow_CertificateReplace> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              e?.SuccessMessage ??
              "Replace certificate was parked successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Replace certificate could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => setIsLoadingPark(false);

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };
    //#endregion

    //#region
    const handleSubmit = async (
      data: any,
      buttonId?: string,
      actions?: any
    ) => {
      switch (buttonId) {
        case EListSubmitButton.Save:
          const response = await handleSaveAndNext(processData(data));
          if (actions?.reloadForm && response) {
            refreshWorkflowData(workflowDraftId || 0);
          }
          break;
        case EListSubmitButton.Approve:
          await handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleConfirmRetainProcess(processData(data));
          break;
      }
    };
    //#endregion

    /**
     * handle confirm retain workflow process
     * @param payload
     */
    const handleConfirmRetainProcess = async (
      payload: DTO_Workflow_CertificateReplace
    ) => {
      //set loading button and dialog
      setIsLoadingClose(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_CertificateReplace> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              e?.SuccessMessages ??
              `Replace certificate was parked successfully.`,
            type: "success",
          });
        },
        defaultFailedMessage: `Replace certificate could not be parked.`,
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog(undefined);
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };

    /**
     * handle confirm reallocate workflow process
     * @param payload
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_CertificateReplace
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postProcessReplaceCertificate,
        dataReallocate: payload,
        defaultSuccessMessage:
          "Replace certificate was reallocated successfully.",
        defaultErrorMessage: "Replace certificate could not be reallocated.",
      });
    };

    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_CertificateReplace
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessReplaceCertificate,
        dataSendBack: payload,
        defaultSuccessMessage: `Certificate approval sent back successfully`,
        defaultErrorMessage: `Send certificate back failed`,
      });
    };

    /**
     * handle finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_CertificateReplace
    ) => {
      const dataProcessed = processData(payload, WorkflowProcessMode.Finish);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage: `The certificate will be replaced based on the information provided. Are you sure you want to submit?`,
      });
    };

    /**
     * Handle reject process
     */
    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessReplaceCertificate,
          dataReject: data,
          defaultSuccessMessage: `Replace certificate application was rejected successfully`,
          defaultErrorMessage: `Replace certificate reject failed`,
        });
      } else {
        onClose();
      }
    };

    /**
     * handle approve process
     */
    const handleApproveProcess = async (
      payload: DTO_Workflow_CertificateReplace
    ) => {
      //set loading button
      setIsLoadingApprove(true);

      //props send to process workflow
      const approveProps: IProcessWorkflow<DTO_Workflow_CertificateReplace> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          clearSelectedItems();
          eventEmitter.emit(CCGridEventType.RefreshOData);
          pushNotification({
            title:
              e?.Notification ?? `Replace certificate approved successfully`,
            type: "success",
          });
        },
        defaultFailedMessage: `Approve replace certificate failed`,
        modeProcess: WorkflowProcessMode.Approve,
      };

      const setLoading = () => {
        setIsLoadingApprove(false);
      };
      //calling api process workflow
      await handleProcessWorkflow(approveProps, setLoading);
    };

    const processData: DTO_Workflow_CertificateReplace | any = (data: any) => {
      let workflowDetail: any = {};
      // process workflow header to send the WD_Assessment_Group_Id/ Officer Region Id
      workflowHeader.WorkflowDraft.WD_Assessment_Group_Id =
        +data?.[ReplaceCertificateKeysOfSteps.Details]?.WD_Assessment_Group_Id;
      const sendSteps = pickBy(data, function (value, key) {
        if (keysOfSendSteps.includes(key as ReplaceCertificateKeysOfSteps)) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        const dataStep = { ...value };
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }
        workflowDetail[key] = dataStep;
        workflowDetail.CertificateId =
          workflowInitData?.WorkflowDetail?.CertificateId ?? id;
        if (key === ReplaceCertificateKeysOfSteps.CertificateDetails) {
          workflowDetail.CertificateDetails = {
            ...workflowDetail.CertificateDetails,
            SelectedPICs: {
              PIC_Ids: data?.CertificateDetails?.PIC_Ids ?? listIDs ?? [],
              Selected_Primary_PIC_Id:
                data?.CertificateDetails?.Selected_Primary_PIC_Id,
            },
          };
          if (isLLS) {
            workflowDetail.CertificateDetails.Titles =
              data?.CertificateDetails?.AssociateTitles ?? [];
          } else {
            workflowDetail.CertificateDetails.Parcels =
              data?.CertificateDetails?.Parcels ?? [];
          }
          delete workflowDetail.CertificateDetails.AssessmentSelected;
          delete workflowDetail.CertificateDetails.AssociateTitles;
          delete workflowDetail.CertificateDetails.PICs;
          delete workflowDetail.CertificateDetails.Selected_Primary_PIC_Id;
          delete workflowDetail.CertificateDetails.PIC_Ids;
        }
      }

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    //#region <Handle process workflow>
    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_CertificateReplace>,
      setLoading: () => void
    ) => {
      const { actionSuccess, defaultFailedMessage, modeProcess, payload } =
        props;
      const response = await postProcessReplaceCertificate(
        modeProcess,
        payload
      );
      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        } else {
          formStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };
    //#endregion

    //#region <Handle finish process>
    /**
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_CertificateReplace
    ) => {
      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_CertificateReplace> = {
        payload: payload,
        actionSuccess: (e) => {
          const notificationContent: IAppNotificationItemAddProps = {
            title:
              e?.SuccessMessage ??
              e?.Notification ??
              "Replace certificate created successfully",
            type: "success",
          };
          onClose();
          if (
            workflowInitData?.WorkflowHeader?.WorkflowApprovals?.length === 0 &&
            !isNil(e?.Component_ID)
          ) {
            history.push(`${PROPERTY_CERTIFICATE_ROUTE}/${e?.Component_ID}`, {
              notification: notificationContent,
            });
          } else {
            pushNotification(notificationContent);
          }
        },
        defaultFailedMessage: `Replace certificate failed`,
        modeProcess: WorkflowProcessMode.Finish,
      };

      const setLoading = () => {};
      //calling api process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };
    //#endregion

    /**
     * Refresh workflow data
     * @param workflowDraftId
     */
    const refreshWorkflowData = async (workflowDraftId: number) => {
      formStepRef?.current?.setLoadingFormStep(true);
      const getWorkflowResponse = await getWorkflowReplaceCertificate(
        workflowDraftId
      );
      formStepRef?.current?.setLoadingFormStep(false);
      if (isSuccessResponse(getWorkflowResponse) && getWorkflowResponse?.data) {
        setWorkflowInitData({
          WorkflowDetail: getWorkflowResponse.data?.WorkflowDetail,
          WorkflowHeader: getWorkflowResponse.data?.WorkflowHeader,
        });
        setListIDs(
          getWorkflowResponse.data?.WorkflowDetail?.CertificateDetails
            ?.SelectedPICs?.PIC_Ids ?? []
        );
      } else {
        formStepRef?.current?.setLoadFailedFormStep({
          onReload: () => refreshWorkflowData(workflowDraftId),
          responseError: {
            status: getWorkflowResponse.status,
            error: getWorkflowResponse.error ?? "Load workflow failed",
          },
        });
      }
    };

    //#region <Get workflow data>
    /**
     * load initValue for FormStep
     * call once time
     */
    const getWorkflowData = async () => {
      let workflowDraftId: number | undefined =
        dataFromActionList?.Workflow_Draft_Id ??
        workflowHeader?.WorkflowDraft?.Workflow_Draft_Id;
      formStepRef?.current?.setLoadingFormStep(true);
      const response = await getInitialReplaceCertificate(
        WorkflowTypes.Certificate_Replace,
        workflowDraftId,
        isFromActionList,
        isFromActionList ? undefined : +id
      );
      formStepRef?.current?.setLoadingFormStep(false);
      if (Array.isArray(response)) {
        const [lovsCertificate, workflowData] = response;
        if (
          isSuccessResponse(lovsCertificate) &&
          isSuccessResponse(workflowData) &&
          lovsCertificate?.data &&
          workflowData?.data
        ) {
          const lovPic = lovsCertificate?.data;
          //set Lov Detail step
          setLovDetail(lovPic?.ReasonForCreate ?? []);
          //set Lovs Data
          setCertificateDetailStepLOVs(lovPic?.CertificateType);
          setWorkflowInitData({
            WorkflowDetail: workflowData.data?.WorkflowDetail,
            WorkflowHeader: workflowData.data?.WorkflowHeader,
          });
          if (workflowData.data?.WorkflowHeader) {
            setWorkflowHeader(workflowData.data?.WorkflowHeader);
            setWorkflowDraftId(
              workflowData.data?.WorkflowHeader?.WorkflowDraft
                ?.Workflow_Draft_Id ?? 0
            );
          }
          setListIDs(
            workflowData?.data?.WorkflowDetail?.CertificateDetails?.SelectedPICs
              ?.PIC_Ids ?? []
          );
        } else {
          let responseError: APIResponse<
            | DTO_Certificate_LOVs
            | DTO_Workflow_CertificateReplace
            | ResponsePacket
          > = response[0];
          if (!isSuccessResponse(response[1])) {
            responseError = response[1];
          }
          formStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        formStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error: "Load workflow failed",
          },
        });
      }
    };
    //#endregion

    //#region <Use Effect calling initial data>
    useEffectOnce(() => {
      getWorkflowData();
    });
    //#endregion

    return (
      <CCFormStep
        ref={formStepRef}
        onSubmit={handleSubmit}
        initialSteps={steps}
        initialValues={initialValue}
        listButtonId={listSubmitButton}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            titleHeader={titleHeader}
            onClose={() => handleCloseDialog(renderProps)}
            bodyElement={renderProps.children}
            badge={statusBadge}
            disabled={isLoadingApprove || isLoadingInStep}
            footerElement={
              <>
                <div className={"cc-dialog-footer-actions-right"}>
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={isLoadingPark ? "fas fa-spinner fa-spin" : ""}
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled || isLoadingPark
                      }
                    >
                      Park
                    </Button>
                  )}
                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      disabled={
                        isLoadingApprove || isLoadingOnNext || isLoadingPark
                      }
                      id={EListSubmitButton.Cancel}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}

                  {isApprovalMode && workflowHeader?.OfficerCanApprove && (
                    <>
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.SendBack}
                        disabled={renderProps.nextButton.disabled}
                        className={"cc-dialog-button"}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Send Back
                      </Button>
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.Reallocate}
                        disabled={renderProps.nextButton.disabled}
                        className={"cc-dialog-button"}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Reallocate
                      </Button>
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.Approve}
                        disabled={
                          isLoadingApprove || renderProps.nextButton.disabled
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.submitButton.onClick}
                        iconClass={
                          isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                        }
                      >
                        Approve
                      </Button>
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.Reject}
                        disabled={renderProps.nextButton.disabled}
                        className={"cc-dialog-button"}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Reject
                      </Button>
                    </>
                  )}
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}
                  {isApprovalMode || isReadOnly ? (
                    !renderProps.isLastStep && (
                      <Button
                        themeColor="primary"
                        id="cc-next-step-button"
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        className={"cc-dialog-button"}
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )
                  ) : (
                    <Button
                      themeColor="primary"
                      id={renderProps.nextButton.idButton}
                      disabled={
                        isLoadingOnNext || renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingOnNext
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
              </>
            }
          />
        )}
      />
    );
  }
);
