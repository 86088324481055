import { getResponsesQuestions } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/responses/api";
import { CCCheckboxGroup } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/responses/components/checkbox-group/_index";
import {
  ChecklistAnswerType,
  DTO_ChecklistQuestion,
} from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  InputChangeEvent,
  SwitchChangeEvent,
  TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import {
  find,
  forEach,
  includes,
  isEmpty,
  isEqual,
  map,
  sortBy,
  split,
  toString,
} from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOfQuestion = nameOfFactory<DTO_ChecklistQuestion>();
export const QuestionnaireFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,

    options = {
      isReadOnly: false,
      isDisabled: false,
    },
    setIsLoadingStep,
    setLoadFailedStep,
    loadFailedStep,
    isLoadingStep,
  }: IFormStepElement) => {
    const { onChange, valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { isReadOnly } = options;

    // FieldValue
    const [questionData, setQuestionData] = useState<DTO_ChecklistQuestion[]>(
      getFieldValue("Questions") ?? []
    );
    const dynamicQuestionListID = getFieldValue("DynamicQuestionList_ID");

    const getQuestionData = async (id: number) => {
      setIsLoadingStep(true);
      const getWorkflowResponse = await getResponsesQuestions(id);
      setIsLoadingStep(false);
      let errorResponse = undefined;
      if (isSuccessResponse(getWorkflowResponse) && getWorkflowResponse?.data) {
        const sortQuestionData = sortBy(
          getWorkflowResponse?.data?.Questions ?? [],
          ["SortIndex"]
        );
        const answerData = getFieldValue("Answers");
        if (!isEmpty(answerData)) {
          const questionIncludeAnswerData = forEach(
            sortQuestionData,
            (question: DTO_ChecklistQuestion) => {
              const foundElement = find(answerData, {
                ChecklistQuestion_Id: question.ChecklistQuestion_ID,
              });
              if (foundElement) {
                question.AnswerResponse = foundElement.AnswerResponse;
              }
            }
          );
          setQuestionData(questionIncludeAnswerData);
        } else {
          setQuestionData(sortQuestionData);
        }
      } else {
        errorResponse = {
          onReload: () => {
            getQuestionData(id);
          },
          responseError: {
            status: APIResponseStatus.INTERNAL_SERVER_ERROR,
            error: "Server error",
          },
        };
      }
      setLoadFailedStep(errorResponse);
    };

    const handleOnChange = (id: number, value: string) => {
      const newQuestionData = map(questionData, (obj) => {
        if (obj.ChecklistQuestion_ID === id) {
          return { ...obj, AnswerResponse: value };
        }
        return obj;
      });
      onChange(nameOf("DynamicQuestionList_ID"), { value: options?.id });
      // TODO: compile two values
      setQuestionData(newQuestionData);
      onChange(nameOf("Questions"), { value: newQuestionData });
    };

    const renderAnswerField = (
      id: number,
      checklistAnswerType: number | string,
      answerField: string,
      index: number
    ) => {
      const answers = split(answerField, "|");
      const valueField =
        getFieldValue("Questions")?.[toString(index)]?.[
          nameOfQuestion("AnswerResponse")
        ] ?? getFieldValue("Answers")?.[index]?.AnswerResponse;

      switch (checklistAnswerType) {
        case ChecklistAnswerType.CheckBox:
          return (
            <Field
              name={toString(id)}
              value={valueField}
              data={answers.map((answer) => ({
                label: answer,
                value: includes(split(toString(valueField), "|"), answer),
              }))}
              component={CCCheckboxGroup}
              disabled={isReadOnly}
              onDataChange={(value: string) => handleOnChange(id, value)}
            />
          );
        case ChecklistAnswerType.RadioButton:
          return (
            <Field
              name={toString(id)}
              component={CCSwitch}
              disabled={isReadOnly}
              checked={isEqual(valueField, "Yes")}
              onChange={({ value }: SwitchChangeEvent) =>
                handleOnChange(id, value ? "Yes" : "No")
              }
            />
          );
        case ChecklistAnswerType.DatePicker:
          return (
            <Field
              name={toString(id)}
              component={CCDatePicker}
              disabled={isReadOnly}
              format={DATE_FORMAT.DATE_CONTROL}
              value={valueField}
              onChange={({ value }: DatePickerChangeEvent) => {
                onChange(toString(id), { value: value });
                handleOnChange(id, toString(value));
              }}
            />
          );
        case ChecklistAnswerType.Label:
          return (
            <Field
              name={toString(id)}
              component={CCInput}
              readOnly={isReadOnly}
              value={valueField}
              onChange={({ value }: InputChangeEvent) =>
                handleOnChange(id, value)
              }
            />
          );
        case ChecklistAnswerType.TextBox:
          return (
            <Field
              name={toString(id)}
              component={CCTextArea}
              readOnly={isReadOnly}
              rows={3}
              value={valueField}
              onChange={({ value }: TextAreaChangeEvent) =>
                handleOnChange(id, value)
              }
            />
          );
        default:
          return null;
      }
    };

    useEffectOnce(() => {
      if (
        !isEqual(dynamicQuestionListID, options.id) ||
        isEmpty(questionData)
      ) {
        getQuestionData(options?.id);
        onChange(nameOf("DynamicQuestionList_ID"), { value: options?.id });
      }
    });

    if (isLoadingStep) return <Loading isLoading={isLoadingStep} />;
    if (loadFailedStep) {
      return (
        <CCLoadFailed
          onReload={loadFailedStep?.onReload}
          responseError={loadFailedStep?.responseError}
        />
      );
    }

    return (
      <>
        <section className="cc-field-group">
          {map(
            questionData,
            (question: DTO_ChecklistQuestion, index: number) => {
              return (
                <div className="cc-field" key={question.ChecklistQuestion_ID}>
                  <CCLabel
                    title={question?.Question}
                    informationTooltip={question?.Hint}
                  />
                  {renderAnswerField(
                    question.ChecklistQuestion_ID,
                    question.ChecklistAnswerType,
                    question.AnswerField,
                    index
                  )}
                </div>
              );
            }
          )}
          <br />
        </section>
      </>
    );
  }
);
