import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { loadViewConfiguresColumns } from "@app/products/property/api";
import { CertificateDetailTab } from "@app/products/property/certificates/[id]/components/reference-sidebar/detail/_index";
import { CertificateMapTab } from "@app/products/property/certificates/[id]/components/reference-sidebar/map/_index";
import { NewCertificateButton } from "@app/products/property/certificates/components/action-bar/buttons/new-certificate/_index";
import { colList } from "@app/products/property/certificates/list/config";
import {
  CERTIFICATE_ROUTE,
  PROPERTY_CERTIFICATE_MAIN_MENU__LIST_VIEW_URL,
} from "@app/products/property/certificates/list/constant";
import { VO_Certificate } from "@app/products/property/certificates/list/model";
import { CertificateListBookmark } from "@app/products/property/certificates/list/util";
import { certificateRoute } from "@app/products/property/certificates/route";
import { CertificateBookmark } from "@app/products/property/certificates/util";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { APIResponseError } from "@common/apis/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import { eComponent } from "@app/products/property/components/associations/model";

const nameOf = nameOfFactory<VO_Certificate>();
export default observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const [colsConfigured, setColsConfigured] =
    useState<IColumnFields[]>(colList);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.OData_Certificate_MainMenu_List,
      colList
    );
    if (Array.isArray(response)) {
      setColsConfigured(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={certificateRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <NewCertificateButton />
      </CCNavButton>,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Certificate}
        recordType={RECORDTYPE.CommunityProperty_Certificate}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={CERTIFICATE_ROUTE}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Certificate}
        detail={CertificateBookmark.getBookmarkDetail}
        displayName={CertificateBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          CertificateListBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={CertificateListBookmark.getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <CertificateDetailTab />,
      },
      {
        title: "Map",
        component: <CertificateMapTab />,
      },
      {
        title: "Contacts",
        component: (
          <PropertyContactTab
            id={lastSelectedRow?.Certificate_Id}
            componentNumber={eComponent.Certificate}
          />
        ),
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Certificate}
          />
        ),
      },
    ],
  });

  if (isLoading) {
    return <Loading isLoading isFullScreen />;
  }

  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );
  }

  return (
    <CCProductListView
      columnFields={colsConfigured}
      primaryField={nameOf("Certificate_Id")}
      dataUrl={PROPERTY_CERTIFICATE_MAIN_MENU__LIST_VIEW_URL}
      state={{ sort: [{ field: nameOf("Cert_Number"), dir: "desc" }] }}
    />
  );
});
