import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const notYetInJournalRoute: ICCRoute = {
  path: "not-yet-in-journal",
  name: "Not yet in journal",
  enum: eMenuAction.Journals_NotYetInJournal,
  children: [
    {
      path: "assessment-transactions",
      name: "Assessment transactions",
      enum: eMenuAction.Journals_TransactionsNotYetInJournals_AssessmentTransactions,
      component: require("./assessment-transactions/index").default,
    },
    {
      path: "deferred-duty-transactions",
      name: "Deferred duty transactions",
      enum: eMenuAction.Journals_TransactionsNotYetInJournals_DeferredDutyTransactions,
      component: require("./deferred-duty-transactions/index").default,
    },
    {
      path: "cashlink-payments",
      name: "Cashlink Payments",
      enum: eMenuAction.Journals_TransactionsNotYetInJournals_CashlinkPayments,
      component: require("./cashlink-payments/index").default,
    },
  ],
};
