import { postAssociateCollections } from "@app/products/property/components/action-bar/button/collections/api";
import { AssociateCollection } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/component/dialog/associate-collection";
import { eComponent } from "@app/products/property/components/associations/model";
import { DTO_Collection } from "@app/products/property/components/child-screen/collections/model";
import { useCollectionsStore } from "@app/products/property/components/child-screen/collections/store";
import { Collection } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isArray } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

interface ICollectionsButton {
  id: number;
  propertyComponent: eComponent;
}
const convertCollectionDTO = (
  selectedCollections: DTO_Collection[] | undefined
) => {
  if (!selectedCollections) return [];
  const newSelectedCollections = selectedCollections.map(
    (collection: DTO_Collection) => {
      return {
        ...collection,
        Collection_Id: collection.CollectionId,
        Collection_Name: collection.CollectionName,
      } as DTO_Collection;
    }
  );
  return newSelectedCollections;
};

export const CollectionsButton = observer(
  ({ id, propertyComponent }: ICollectionsButton) => {
    const { currentOrganisationMode } = useCommonProductStore();
    const isActro = currentOrganisationMode(OrganisationMode.ACTRO);
    const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
    const [isLoadingCollectionButton, setIsLoadingCollectionButton] =
      useState<boolean>(false);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
    const { pushNotification } = useCCAppNotificationStore();
    const { collections, loadCollections, reloadCollections } =
      useCollectionsStore();

    const isVisible = useMemo(() => {
      return !(
        (isActro && propertyComponent === eComponent.Meter) ||
        (isActro && propertyComponent === eComponent.Scheme)
      );
    }, [isActro, propertyComponent]);

    const handleSubmit = async (data: any) => {
      setIsLoadingSave(true);
      const payload: number[] =
        isArray(data?.SelectedCollection) &&
        data?.SelectedCollection.map((item: DTO_Collection | Collection) => {
          return (
            (item as DTO_Collection)?.CollectionId ??
            (item as Collection)?.Collection_Id
          );
        });
      const response = await postAssociateCollections(
        propertyComponent,
        id,
        payload
      );
      if (isSuccessResponse(response)) {
        pushNotification({
          type: "success",
          title: "Collection associate successfully",
        });
      } else {
        pushNotification({
          type: "error",
          title: "Collection associate failed",
        });
      }
      setIsLoadingSave(false);
      setIsShowDialog(false);
      await loadCollections(propertyComponent, id);
    };

    const handleClickCollections = async () => {
      setIsLoadingCollectionButton(true);
      await reloadCollections(propertyComponent, id);
      setIsShowDialog(true);
      setIsLoadingCollectionButton(false);
    };

    return isVisible ? (
      <>
        <CCNavButton
          title="Collections"
          onClick={handleClickCollections}
          isLoading={isLoadingCollectionButton}
          disabled={isLoadingCollectionButton}
        />
        {isShowDialog && (
          <AssociateCollection
            onClose={() => setIsShowDialog(false)}
            onSubmit={handleSubmit}
            isLoadingParentForm={isLoadingCollectionButton}
            collections={convertCollectionDTO(collections)}
            isLoadingSave={isLoadingSave}
            isSaveAndClose={false}
          />
        )}
      </>
    ) : null;
  }
);
