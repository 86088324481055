import { useAnimalsSettingStore } from "@app/products/animals/system-admin/settings/_category/store";
import { DynamicGridFields } from "@app/products/animals/system-admin/settings/_id/components/dynamic-grid-fields/_index";
import { ICommonFormProps } from "@app/products/animals/system-admin/settings/_id/main/model";
import { useAnimalsSettingManageStore } from "@app/products/animals/system-admin/settings/_id/store";
import { ILookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/combo-box/_index";
import { DropdownSelectGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown-select/_index";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import {
  ComboBoxChangeEvent,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOfLookupItem = nameOfFactory<ILookupItem>();

export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { onChange, valueGetter } = formRenderProps;
  const { getConfigDataField } = useAnimalsSettingStore();
  const { isEditing } = useAnimalsSettingManageStore();
  const eKennelNewRegistrationFeeType =
    ECorporateSettingsField.Animals_KennelNewRegistrationFeeType;
  const eKennelRenewalFeeType =
    ECorporateSettingsField.Animals_KennelRenewalFeeType;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <DropdownSelectGlobalSettings
          data={getConfigDataField(eKennelNewRegistrationFeeType)}
          isEditing={isEditing}
          onChange={(e: DropDownListChangeEvent) => {
            onChange(`${eKennelNewRegistrationFeeType}`, {
              value: e.value?.Id ?? "",
            });
          }}
          value={getDropdownValue(
            valueGetter(`${eKennelNewRegistrationFeeType}`) ?? "",
            getConfigDataField(eKennelNewRegistrationFeeType)?.LookupItems ??
              [],
            nameOfLookupItem("Id")
          )}
        />

        <ComboBoxGlobalSettings
          data={getConfigDataField(eKennelRenewalFeeType)}
          isEditing={isEditing}
          onChange={(e: ComboBoxChangeEvent) => {
            onChange(`${eKennelRenewalFeeType}`, {
              value: e.value?.Id ?? null,
            });
          }}
          value={getDropdownValue(
            valueGetter(`${eKennelRenewalFeeType}`),
            getConfigDataField(eKennelRenewalFeeType)?.LookupItems ?? [],
            nameOfLookupItem("Id")
          )}
        />
      </div>

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <DynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
