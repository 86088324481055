import { getViewConfigurations } from "@app/products/property/api";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { ODATA_SUPPLEMENTARYS_RATE_VALUATION_BY_GROUP_DETAIL } from "@app/products/property/supplementary-rates/valuations-by-group/constant";
import { processDynamicColumns } from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colDetail } from "./config";

const DetailComponent = ({ dataItem }: GridDetailRowProps) => {
  const [listConfigurationDetail, setListConfigurationDetail] = useState<
    ICCViewColumn[]
  >([]);

  useEffectOnce(() => {
    getViewConfigurations(
      ViewConfiguration.OData_SupplementaryValuationAssessments
    ).then((response) => {
      if (isSuccessResponse(response) && response?.data) {
        setListConfigurationDetail(response.data.ColumnDefinitions?.Columns);
      }
    });
  });

  return (
    <div className="cc-rebates-list-detail">
      <CCGrid
        className="cc-associated-entities-grid"
        dataUrl={ODATA_SUPPLEMENTARYS_RATE_VALUATION_BY_GROUP_DETAIL}
        columnFields={processDynamicColumns(colDetail, listConfigurationDetail)}
        primaryField="AssessmentId"
        state={{
          filter: {
            logic: "and",
            filters: [
              {
                field: "Valuation_Group_Id",
                value: dataItem.Valuation_Group_Id,
                operator: "eq",
              },
            ],
          },
        }}
      />
    </div>
  );
};

export default DetailComponent;
