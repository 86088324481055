import {
  apiCoreGetHomepageLovs,
  apiCoreHomePageAddUser,
  apiCoreHomePageRemoveUser,
} from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { ResponsePacket } from "@common/models/identityPacket";
import {
  ProductListing,
  Svc_HomepagePickUserRequest,
} from "@common/pages/settings/homepage/model";

export const postHomePageAddUser = async (
  homepagePickUserRequest: Svc_HomepagePickUserRequest
): Promise<APIResponse<ResponsePacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<ResponsePacket>(
      apiCoreHomePageAddUser(),
      homepagePickUserRequest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postHomePageRemoveUser = async (
  homepagePickUserRequest: Svc_HomepagePickUserRequest
): Promise<APIResponse<ResponsePacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<ResponsePacket>(
      apiCoreHomePageRemoveUser(),
      homepagePickUserRequest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getHomepageLovs = async (): Promise<
  APIResponse<ProductListing[] | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<ProductListing[]>(
      apiCoreGetHomepageLovs()
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
