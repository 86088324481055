import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.Title_Id
    ? `- ${selectedRow.Title_Id}`
    : "";
  return `Property - Titles ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([selectedRow.Legal_Description]);
};
