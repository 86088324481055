import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { NewSupplementaryFormStep } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/_index";
import { useNewSupplementaryDialogStore } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/store";
import {
  isDisabledApproveButton,
  isHiddenRejectButton,
} from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/util";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { SupplementaryMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import { secondaryWorkflowUtilProcessing } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { INewProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import {
  getTitleWorkflow,
  isApprovalModeWorkflow,
} from "@app/products/property/components/action-bar/property-workflow/util";
import { DTO_PIC } from "@app/products/property/components/dialogs/add-pic-lookup/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { DeferredDutyJournalFormStep } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/_index";
import { useDeferredJournalStepStore } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/store";
import { AllocationToChargeBalancesAddTransactionFormStep } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/allocations-to-charge-balances/_index";
import { loadAssessmentChargeBalanceDetail } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/allocations-to-charge-balances/api";
import { eAssessmentAllocation } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/model";
import { NewChargeDetailsAddTransactionFormStep } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/new-charge-details/_index";
import {
  DTO_LOV_Levy,
  DTO_LOV_RatingPeriod,
  INewChargeDetailsTransactionStepsLOVs,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/new-charge-details/model";
import { useNewChargeDetailsAddTransactionStepStore } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/new-charge-details/store";
import { DTO_ChargeBalance } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/model";
import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";
import {
  getInitialDataJournal,
  postProcessCreateJournal,
} from "@app/products/property/journals/list/components/dialogs/create-journal/api";
import { useNewAssessmentJournalStepStore } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/assessment-journal/store";
import { NewJournalFormStep } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/_index";
import { eJournalType } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/model";
import { useNewJournalStepStore } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/store";
import { isHideBaseOnJournalType } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/util";
import {
  DTO_Journal_LOVs,
  DTO_Workflow_JournalCreate,
  JournalKeysOfSteps,
  TransactionAllocationMode,
  journalKeysOfSendSteps,
} from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { PICNewTransactionJournalFormStep } from "@app/products/property/pic/list/components/action-bar/form-steps/new-transaction/components/form-elements/pic/_index";
import { usePICNewTransactionJournalStepStore } from "@app/products/property/pic/list/components/action-bar/form-steps/new-transaction/components/form-elements/pic/store";
import {
  convertValueLOVToNumber,
  isShowParkButton,
} from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { Label } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { cloneDeep, isNil, pickBy, uniqBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { AssessmentJournalFormStep } from "./components/form-elements/assessment-journal/_index";

interface ICreateJournalDialogProps {
  onClose: () => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  isReadOnly?: boolean;
  isBeingApprovedMode?: boolean;
  isShowCancelWorkflowButton?: boolean;
  statusBadge?: IBadgeDialog[];
  journalTypeDefault?: number;
  isFromDeferredDuty?: boolean;
  accountId?: number;
}
export const CreateJournalDialog = observer(
  ({
    onClose,
    isSaveOnNextStep = false,
    isIncompleteMode = false,
    isFromActionList = false,
    suffixTitle,
    prefixTitle,
    dataFromActionList,
    isReadOnly = false,
    isShowCancelWorkflowButton = false,
    statusBadge,
    journalTypeDefault,
    accountId,
    isFromDeferredDuty = false,
  }: ICreateJournalDialogProps) => {
    const history = useHistory();
    const formStepRef = useRef<ICCFormStepNotificationHandle | null>(null);

    //#region <Store>
    const { setNewPICJournalStepLOVs } = usePICNewTransactionJournalStepStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDeferredJournalStepLOVs } = useDeferredJournalStepStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const [isLoadingProcess, setIsLoadingProcess] = useState<
      WorkflowProcessMode | undefined
    >();
    const { clearSelectedItems } = useCCProductListViewStore();
    const {
      setNewChargeDetailsStepLOVs,
      originNewChargeDetailsStepLOVs,
      setOriginNewChargeDetailsStepLOVs,
      dataLOvsGroupByFinancialGroup,
    } = useNewChargeDetailsAddTransactionStepStore();
    const { setNewJournalStepLOVs, newJournalStepLOVs } =
      useNewJournalStepStore();
    const { setNewAssessmentJournalStepLOVs, newAssessmentJournalStepLOVs } =
      useNewAssessmentJournalStepStore();
    const { setCreateSupplementaryLOVs } = useNewSupplementaryDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { currentFormTitle } = useCommonProductStore();
    //#endregion

    //region <State and variables>
    const [workflowInitData, setWorkflowInitData] =
      useState<DTO_Workflow_JournalCreate>();
    const [previousAssessmentID, setPreviousAssessmentID] = useState<number>();
    const [isLoadingInStep, setIsLoadingInStep] = useState<boolean>(false);
    const localNotificationRef = useRef<ICCLocalNotificationHandle | null>(
      null
    );

    //Get labels
    const [assessmentLabel, assessmentLowercaseLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.Assessment,
        ECustomColNameProperty.AssessmentLowercase,
      ]);

    //#endregion
    //#region <Title header>
    const titleHeader = useMemo(() => {
      const formId =
        workflowInitData?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id;
      const workflowName = currentFormTitle(formId ?? 0) ?? "Create Journal";
      return getTitleWorkflow(workflowName, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, workflowInitData]);

    //#endregion

    const workflowDraftId = useMemo(() => {
      return (
        workflowInitData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id ?? 0
      );
    }, [workflowInitData]);

    const isToBeApprovalMode = useMemo(() => {
      return isApprovalModeWorkflow(
        workflowInitData?.WorkflowHeader?.WorkflowDraft
          ?.WD_Workflow_Approval_Status
      );
    }, [workflowInitData]);

    const isFirstSave = useMemo(() => {
      const draftId =
        workflowInitData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id;
      return isNil(draftId) || draftId === 0;
    }, [workflowInitData]);

    const initialValue = useMemo((): any => {
      let initNewJournal: any = {
        ...workflowInitData?.WorkflowDetail?.CreateJournal,
        SupplementaryMode: workflowInitData?.WorkflowDetail?.SupplementaryMode,
        Journal_Type_Classification: newJournalStepLOVs?.JournalTypes
          ? newJournalStepLOVs.JournalTypes?.find(
              (journalType) =>
                journalType?.Code ===
                workflowInitData?.WorkflowDetail?.CreateJournal?.Journal_Type
            )?.Classifications ?? null
          : null,
      };
      const isDeferredDutyJournalType =
        initNewJournal?.Journal_Type ===
        (eJournalType["Deferred Duty Account Journal"] as number);
      const isPICJournalType =
        initNewJournal?.Journal_Type ===
        (eJournalType["PIC Journal"] as number);
      const isHideStepBaseOnJournalType = isHideBaseOnJournalType(
        initNewJournal?.Journal_Type
      );
      const supplementaryId =
        workflowInitData?.WorkflowDetail?.CreateJournal?.Supplementary_Id;
      if (supplementaryId) {
        initNewJournal._options = {
          SupplementarySelected: [
            {
              Supplementary_Id: supplementaryId,
            },
          ],
        };
      }

      let initNewSupplementary: any = {
        ...workflowInitData?.WorkflowDetail?.CreateSupplementary,
      };

      let initAssessmentTransaction: any = {
        ...workflowInitData?.WorkflowDetail?.AssessmentTransaction,
        Assessments: workflowInitData?.WorkflowDetail?.Assessments,
        Transaction_Type:
          workflowInitData?.WorkflowDetail?.AssessmentTransaction
            ?.Transaction_Type || undefined,
      };

      const ratingPeriod =
        workflowInitData?.WorkflowDetail?.NewCharge?.RatingPeriod;
      const levyId =
        workflowInitData?.WorkflowDetail?.NewCharge?.LevyId || undefined;
      let optionLevyId;
      let optionRatingPeriod;
      if (levyId) {
        optionLevyId = originNewChargeDetailsStepLOVs?.Levy?.find(
          (item) => item?.Code === levyId
        );
      }
      if (ratingPeriod) {
        optionRatingPeriod =
          originNewChargeDetailsStepLOVs?.RatingPeriods?.find(
            (item) => item?.Code === ratingPeriod
          );
      }
      let initNewChargeDetail: any = {
        ...workflowInitData?.WorkflowDetail?.NewCharge,
        ChargeDate:
          workflowInitData?.WorkflowDetail?.NewCharge?.ChargeDate ?? new Date(),
        Amount:
          workflowInitData?.WorkflowDetail?.NewCharge?.Amount ??
          workflowInitData?.WorkflowDetail?.AssessmentTransaction?.Amount,
        RatingPeriod: ratingPeriod,
        LevyId: levyId,
        _option: { LevyId: optionLevyId, RatingPeriod: optionRatingPeriod },
      };
      let initNewAccount: any = workflowInitData?.WorkflowDetail?.Accounts;
      let initNewAccountTransaction: any = {
        ...workflowInitData?.WorkflowDetail?.AccountTransaction,
        Accounts: workflowInitData?.WorkflowDetail?.Accounts ?? [],
        AccountsSelected: workflowInitData?.WorkflowDetail?.Accounts ?? [],
      };
      const manualChargeBalances =
        workflowInitData?.WorkflowDetail?.AllocationChargeBalance
          ?.ManualChargeBalances ?? [];
      let chargeBalancesIDs: number[] = [];
      if (manualChargeBalances?.length) {
        chargeBalancesIDs = manualChargeBalances.map(
          (item) => item.Charge_Balances_Id
        );
      }
      let initAllocationChargeBalances: any = {
        ManualChargeBalances:
          workflowInitData?.WorkflowDetail?.AllocationChargeBalance
            ?.ManualChargeBalances ?? [],
        ChargeBalance:
          workflowInitData?.WorkflowDetail?.AllocationChargeBalance
            ?.ChargeBalance ?? [],
        _option: { ChargeBalancesIDs: chargeBalancesIDs },
      };
      // step PIC
      let initPICTransaction: any = {
        ...workflowInitData?.WorkflowDetail?.PIC,
      };
      formStepRef?.current?.setStepsVisible([
        {
          key: JournalKeysOfSteps.NewSupplementary,
          visible:
            !isHideStepBaseOnJournalType &&
            workflowInitData?.WorkflowDetail.SupplementaryMode ===
              SupplementaryMode.CreateNew,
        },
        {
          key: JournalKeysOfSteps.AccountTransaction,
          visible: isDeferredDutyJournalType,
        },
        {
          key: JournalKeysOfSteps.PIC,
          visible: isPICJournalType,
        },
        {
          key: JournalKeysOfSteps.AssessmentTransaction,
          visible: !isHideStepBaseOnJournalType,
        },
        {
          key: JournalKeysOfSteps.AllocationChargeBalances,
          visible:
            !isHideStepBaseOnJournalType &&
            workflowInitData?.WorkflowDetail?.AssessmentTransaction
              ?.AllocationMode === TransactionAllocationMode.Manual,
        },
        {
          key: JournalKeysOfSteps.NewChargeDetail,
          visible:
            !isHideStepBaseOnJournalType &&
            workflowInitData?.WorkflowDetail?.AssessmentTransaction
              ?.AllocationMode === TransactionAllocationMode.NewCharge,
        },
      ]);
      //---step Secondary Workflow---
      const initialWorkflow =
        secondaryWorkflowUtilProcessing.processDataInit(workflowInitData);

      return {
        [JournalKeysOfSteps.NewJournal]: initNewJournal,
        [JournalKeysOfSteps.PIC]: initPICTransaction,
        [JournalKeysOfSteps.NewSupplementary]: initNewSupplementary,
        [JournalKeysOfSteps.AssessmentTransaction]: initAssessmentTransaction,
        [JournalKeysOfSteps.NewChargeDetail]: initNewChargeDetail,
        [JournalKeysOfSteps.Accounts]: initNewAccount,
        [JournalKeysOfSteps.AccountTransaction]: initNewAccountTransaction,
        [JournalKeysOfSteps.AllocationChargeBalances]:
          initAllocationChargeBalances,
        [JournalKeysOfSteps.Documents]: {},
        [JournalKeysOfSteps.Comments]: {},
        [JournalKeysOfSteps.Workflow]: initialWorkflow,
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflowInitData]);

    const steps: IStep[] = [
      {
        label: "New journal",
        component: NewJournalFormStep,
        visible: true,
        key: JournalKeysOfSteps.NewJournal,
        options: {
          showStatusField: false,
          isReadOnly,
          showWhichSupplementary: true,
          supplementaryStepKey: JournalKeysOfSteps.NewSupplementary,
          accountStepKey: JournalKeysOfSteps.AccountTransaction,
          isFromDeferredDuty,
        },
        initialValues: initialValue[JournalKeysOfSteps.NewJournal],
      },
      {
        label: "PIC",
        component: PICNewTransactionJournalFormStep,
        visible: false,
        key: JournalKeysOfSteps.PIC,
        options: {
          isReadOnly,
        },
        initialValues: initialValue[JournalKeysOfSteps.PIC],
      },
      {
        label: "Accounts",
        component: DeferredDutyJournalFormStep,
        visible: false,
        key: JournalKeysOfSteps.AccountTransaction,
        options: {
          isReadOnly,
          workflowDraftId,
        },
        initialValues: initialValue[JournalKeysOfSteps.AccountTransaction],
      },
      {
        label: "New supplementary",
        component: NewSupplementaryFormStep,
        visible: false,
        key: JournalKeysOfSteps.NewSupplementary,
        options: {
          isReadOnly,
        },
        initialValues: initialValue[JournalKeysOfSteps.NewSupplementary],
      },
      {
        label: `${assessmentLabel}s`,
        component: AssessmentJournalFormStep,
        visible: false,
        key: JournalKeysOfSteps.AssessmentTransaction,
        options: {
          isReadOnly,
          workflowDraftId,
          newJournalStepKey: JournalKeysOfSteps.NewJournal,
          assessmentLowercaseLabel,
          setIsLoadingInStep,
        },
        initialValues: initialValue[JournalKeysOfSteps.AssessmentTransaction],
      },
      {
        label: "New charge details",
        component: NewChargeDetailsAddTransactionFormStep,
        visible: false,
        key: JournalKeysOfSteps.NewChargeDetail,
        options: {
          isReadOnly,
          workflowDraftId,
        },
        initialValues: initialValue[JournalKeysOfSteps.NewChargeDetail],
      },
      {
        label: "Allocation to charge balances",
        component: AllocationToChargeBalancesAddTransactionFormStep,
        visible: false,
        key: JournalKeysOfSteps.AllocationChargeBalances,
        options: {
          isReadOnly,
        },
        initialValues:
          initialValue[JournalKeysOfSteps.AllocationChargeBalances],
      },
      {
        label: "Comments",
        component: CommentsFormStep,
        visible: true,
        key: JournalKeysOfSteps.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Journal,
        },
        initialValues: initialValue[JournalKeysOfSteps.Comments],
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        visible: true,
        key: JournalKeysOfSteps.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Journal_Create,
        },
        initialValues: initialValue[JournalKeysOfSteps.Documents],
      },
      {
        label: "Workflow",
        initialValues: initialValue[JournalKeysOfSteps.Workflow],
        component: WorkflowStepFormStep,
        visible: true,
        key: JournalKeysOfSteps.Workflow,
        options: {
          isReadOnly,
          isFromActionList,
          dataFromActionList,
        },
      },
    ];

    /**
     * handle submit to control all button in dialog
     * @param data
     * @param buttonId
     */
    const handleSubmit = async (data: any, buttonId?: string) => {
      //reset notification before go next calling
      formStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.resetNotifications();
      switch (buttonId) {
        case EListSubmitButton.Approve:
          handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.SaveWorkflow:
          await handleSaveAndNext(data);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.ConfirmCloseNo:
        case EListSubmitButton.Cancel:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleParkProcess(
            processData(data),
            EListSubmitButton.ConfirmCloseYes
          );
          break;
        default:
          break;
      }
    };

    //#region <Handle Approve process>
    /**
     * @param payload
     * @param mode
     */
    const handleApproveProcess = async (
      payload: DTO_Workflow_JournalCreate
    ) => {
      //set loading button and dialog
      setIsLoadingProcess(WorkflowProcessMode.Approve);

      //props send to process workflow
      const approveProps: INewProcessWorkflow<DTO_Workflow_JournalCreate> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              e?.SuccessMessage ??
              `Create journal was approved successfully.`,
            type: "success",
          });
          isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        defaultFailedMessage: "Create journal could not be approved.",
        modeProcess: WorkflowProcessMode.Approve,
      };
      //calling api process workflow
      await handleProcessWorkflow(approveProps);
    };
    //#endregion

    /**
     * Handle reject process
     */
    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessCreateJournal,
          dataReject: data,
          defaultSuccessMessage: `Create journal was rejected successfully.`,
          defaultErrorMessage: `Create journal could not be rejected.`,
        });
      } else {
        onClose();
      }
    };

    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_JournalCreate
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessCreateJournal,
        dataSendBack: payload,
        defaultSuccessMessage: `Create journal was sent back successfully.`,
        defaultErrorMessage: `Create journal could not be sent back.`,
      });
    };

    /**
     * handle confirm reallocate workflow process
     * @param payload
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_JournalCreate
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postProcessCreateJournal,
        dataReallocate: payload,
        defaultSuccessMessage: `Create journal was reallocated successfully.`,
        defaultErrorMessage: `Create journal could not be reallocated.`,
      });
    };

    /**
     * handle finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_JournalCreate
    ) => {
      const dataProcessed = processData(payload);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage:
          "The journal will be created based on the information provided. Are you sure you want to submit?",
      });
    };

    const processData: DTO_Workflow_JournalCreate | any = (data: any) => {
      let workflowDetail: any = {};
      let workflowHeader = {
        ...workflowInitData?.WorkflowHeader,
      } as DTO_WorkflowHeader;
      const sendSteps = pickBy(data, function (value, key) {
        if (journalKeysOfSendSteps.includes(key as JournalKeysOfSteps)) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        const dataStep = { ...value };
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }
        if (key === JournalKeysOfSteps.Workflow) {
          secondaryWorkflowUtilProcessing.processData(
            value,
            workflowHeader,
            workflowDetail
          );
        } else {
          workflowDetail[key] = dataStep;
        }
      }
      workflowDetail.JournalMode =
        workflowInitData?.WorkflowDetail?.JournalMode ?? 0;
      if (
        !isNil(data?.[JournalKeysOfSteps.AccountTransaction]?.AccountsSelected)
      ) {
        workflowDetail.Accounts =
          data?.[JournalKeysOfSteps.AccountTransaction]?.AccountsSelected ?? [];
        workflowDetail.AccountTransaction.AccountId =
          data?.[JournalKeysOfSteps.AccountTransaction]?.AccountsSelected?.[0]
            ?.AccountId ?? 0;
        delete workflowDetail[JournalKeysOfSteps.AccountTransaction]
          .AccountsSelected;
        delete workflowDetail[JournalKeysOfSteps.AccountTransaction].Accounts;
      }
      //process data Allocation
      const chargeBalanceList = cloneDeep(
        data?.[JournalKeysOfSteps.AllocationChargeBalances]?.ChargeBalance
      );
      let manualChargeBalance =
        data?.[JournalKeysOfSteps.AllocationChargeBalances]
          ?.ManualChargeBalances ?? [];
      const listChargeBalanceIdChanges =
        data?.[JournalKeysOfSteps.AllocationChargeBalances]?._option
          ?.ChargeBalancesIDs ?? [];
      if (listChargeBalanceIdChanges?.length) {
        const chargeBalanceListChange = chargeBalanceList.filter(
          (item: DTO_ChargeBalance) =>
            listChargeBalanceIdChanges.includes(item.Charge_Balances_Id)
        );
        manualChargeBalance = chargeBalanceListChange?.map(
          (chargeBalance: DTO_ChargeBalance) => {
            return {
              Charge_Balances_Id: chargeBalance?.Charge_Balances_Id,
              BalanceAmount: chargeBalance?.Adjusted_Amount ?? null,
              Interest: chargeBalance?.Adjusted_Interest ?? null,
            };
          }
        );
      }
      workflowDetail.AllocationChargeBalance = {
        ChargeBalance: chargeBalanceList,
        ManualChargeBalances: manualChargeBalance,
      };
      if (!isNil(data?.[JournalKeysOfSteps.NewJournal]?.SupplementaryMode)) {
        workflowDetail.SupplementaryMode =
          data[JournalKeysOfSteps.NewJournal].SupplementaryMode;
      }
      if (
        !isNil(data?.[JournalKeysOfSteps.AssessmentTransaction]?.AllocationMode)
      ) {
        workflowDetail.AssessmentTransaction.AllocationMode =
          data[JournalKeysOfSteps.AssessmentTransaction].AllocationMode;
      }
      if (
        !isNil(data?.[JournalKeysOfSteps.AssessmentTransaction]?.Assessments)
      ) {
        workflowDetail.Assessments =
          data[JournalKeysOfSteps.AssessmentTransaction].Assessments;
      } else {
        workflowDetail.Assessments = [];
      }
      //process data step PIC
      const listPICId = data?.[JournalKeysOfSteps.PIC]?.PICs?.map(
        (pic: DTO_PIC) => pic?.PIC_Id
      );
      workflowDetail.PIC.PIC_Ids = listPICId;
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };
    const processDataLovsNewChargeDetail = async (
      payload: DTO_Workflow_JournalCreate,
      data: any
    ) => {
      if (!originNewChargeDetailsStepLOVs) return;
      const dataOriginLovsNewChargeDetail: INewChargeDetailsTransactionStepsLOVs =
        {
          ...originNewChargeDetailsStepLOVs,
        };
      //allocation mode
      const allocationMode =
        payload?.WorkflowDetail?.AssessmentTransaction?.AllocationMode;
      if (allocationMode === TransactionAllocationMode.NewCharge) {
        //clone data lovs
        const newRatingPeriod = cloneDeep(
          dataLOvsGroupByFinancialGroup?.RatingPeriods
        );
        const newLevy = cloneDeep(dataLOvsGroupByFinancialGroup?.Levy);
        //transactionType
        const transactionType =
          payload?.WorkflowDetail?.AssessmentTransaction?.Transaction_Type;
        //financial group in transaction
        let financialGroups =
          data?.AssessmentTransaction?._option?.Transaction_Type
            ?.Financial_Groups;
        if (isNil(financialGroups)) {
          financialGroups =
            newAssessmentJournalStepLOVs?.TransactionTypes?.find(
              (item) => item?.Code === transactionType
            )?.Financial_Groups;
        }
        if (financialGroups?.length) {
          //filter data by financial group
          let ratingPeriodLovs: DTO_LOV_RatingPeriod[] = [];
          let levyLovs: DTO_LOV_Levy[] = [];
          financialGroups?.forEach((group: number) => {
            let ratingPeriodByGroup: DTO_LOV_RatingPeriod[] = [];
            if (newRatingPeriod) {
              ratingPeriodByGroup = newRatingPeriod[group] ?? [];
            }
            ratingPeriodLovs = [...ratingPeriodLovs, ...ratingPeriodByGroup];
            ratingPeriodLovs = uniqBy(ratingPeriodLovs, "Code");

            let levyByGroup: DTO_LOV_Levy[] = [];
            if (newLevy) {
              levyByGroup = newLevy[group] ?? [];
            }
            levyLovs = [...levyLovs, ...levyByGroup];
            levyLovs = uniqBy(levyLovs, "Code");
          });
          //set new lovs
          setNewChargeDetailsStepLOVs({
            ...dataOriginLovsNewChargeDetail,
            RatingPeriods: ratingPeriodLovs,
            Levy: levyLovs,
          });
        } else {
          //get current value
          const valueRatingPeriod =
            data?.[`${JournalKeysOfSteps.NewChargeDetail}`]?.RatingPeriod;
          const valueLevy =
            data?.[`${JournalKeysOfSteps.NewChargeDetail}`]?.LevyId;

          if (!isNil(valueRatingPeriod) && isNil(valueLevy)) {
            const financialGroup =
              data?.[`${JournalKeysOfSteps.NewChargeDetail}`]?._option
                ?.RatingPeriod?.Financial_Groups ?? [];
            if (financialGroup?.length) {
              let listLevy: DTO_LOV_Levy[] = [];
              financialGroups?.forEach((group: number) => {
                let levyByGroup: DTO_LOV_Levy[] = [];
                if (newLevy) {
                  levyByGroup = newLevy[group] ?? [];
                }
                listLevy = [...listLevy, ...levyByGroup];
                listLevy = uniqBy(listLevy, "Code");
              });
              setNewChargeDetailsStepLOVs({
                ...dataOriginLovsNewChargeDetail,
                Levy: listLevy,
              });
            }
          } else if (isNil(valueRatingPeriod) && !isNil(valueLevy)) {
            const financialGroupId =
              data?.[`${JournalKeysOfSteps.NewChargeDetail}`]?._option?.Levy
                ?.Financial_Group_Id;
            if (financialGroupId) {
              let listRatingPeriod: DTO_LOV_RatingPeriod[] = [];
              financialGroups?.forEach((group: number) => {
                let ratingByGroup: DTO_LOV_RatingPeriod[] = [];
                if (newRatingPeriod) {
                  ratingByGroup = newRatingPeriod[group] ?? [];
                }
                listRatingPeriod = [...listRatingPeriod, ...ratingByGroup];
                listRatingPeriod = uniqBy(listRatingPeriod, "Code");
              });
              setNewChargeDetailsStepLOVs({
                ...dataOriginLovsNewChargeDetail,
                RatingPeriods: listRatingPeriod,
              });
            }
          } else {
            setNewChargeDetailsStepLOVs(dataOriginLovsNewChargeDetail);
          }
        }
      } else if (allocationMode === eAssessmentAllocation.CHARGES_BALANCES) {
        const newAssessmentId =
          data?.AssessmentTransaction?.Assessments?.[0]?.Assessment_Id;
        if (previousAssessmentID !== newAssessmentId)
          setPreviousAssessmentID(newAssessmentId);
        const chargeBalanceData =
          data?.AllocationChargeBalance?.ChargeBalance ?? [];
        //check condition to load api list charge balance

        if (
          previousAssessmentID !== newAssessmentId ||
          chargeBalanceData?.length === 0
        ) {
          await loadAssessmentChargeBalanceDetail(newAssessmentId).then(
            (response) => {
              if (isSuccessResponse(response) && response.data) {
                const chargeBalanceItems = response.data?.ChargeBalances
                  ? response.data?.ChargeBalances
                  : [];

                payload.WorkflowDetail.AllocationChargeBalance.ChargeBalance =
                  chargeBalanceItems;
              } else {
                localNotificationRef?.current?.pushNotification({
                  title: `${assessmentLabel} charge balance load failed`,
                  type: "error",
                });
              }
            }
          );
        }
      }
    };
    //#region <Handle save and next>
    const handleSaveAndNext = async (data: any): Promise<boolean> => {
      const payload = processData(data);
      //check condition use for Save button
      setIsLoadingProcess(WorkflowProcessMode.Save);
      //Calling process Save at next button
      await processDataLovsNewChargeDetail(payload, data);
      const response = await postProcessCreateJournal(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingProcess(undefined);
      //set default notification
      const defaultFailedMessage = "Create journal could not be saved.";
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        setWorkflowInitData(response?.data?.ReturnData);
        return true;
      } else {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      return false;
    };
    //#endregion

    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessCreateJournal,
          dataCancel: data,
          defaultSuccessMessage: "Create journal was cancelled successfully.",
          defaultErrorMessage: "Create journal could not be cancelled.",
        });
      } else {
        onClose();
      }
    };

    const getWorkflowData = async () => {
      const id = dataFromActionList?.Workflow_Draft_Id;
      formStepRef?.current?.setLoadingFormStep(true);
      const response = await getInitialDataJournal(
        id,
        journalTypeDefault,
        accountId
      );
      if (Array.isArray(response)) {
        const [lovsJournal, workflowData] = response;
        if (
          isSuccessResponse(lovsJournal) &&
          isSuccessResponse(workflowData) &&
          lovsJournal?.data &&
          workflowData?.data
        ) {
          //set Lovs Data Assessment
          let createSupplementaryLOVs: any = {
            SupplementaryType: lovsJournal?.data?.SupplementaryType ?? [],
            InstallmentType: lovsJournal?.data?.InstalmentPlans ?? [],
            AssessmentType: lovsJournal?.data?.AssessmentType ?? [],
            ValuationTypeTobeUsed:
              lovsJournal?.data?.ValuationTypeTobeUsed ?? [],
          };
          const newChargeDetail = {
            RatingPeriods: convertValueLOVToNumber(
              lovsJournal?.data?.RatingPeriods ?? [],
              "Code"
            ),
            InstalmentPlans: convertValueLOVToNumber(
              lovsJournal?.data?.InstalmentPlans ?? [],
              "Code"
            ),
            Levy: convertValueLOVToNumber(
              lovsJournal?.data?.Levy ?? [],
              "Code"
            ),
          };
          setDeferredJournalStepLOVs({
            TransactionTypes: convertValueLOVToNumber(
              lovsJournal?.data?.TransactionTypes ?? []
            ),
            PaymentAllocations: convertValueLOVToNumber(
              lovsJournal?.data?.PaymentAllocations ?? []
            ),
            IsRebateTypeTransaction:
              lovsJournal?.data?.IsRebateTypeTransaction ?? false,
            AssessRebateEntitlements: convertValueLOVToNumber(
              lovsJournal?.data?.AssessRebateEntitlements ?? [],
              "Code"
            ),
            DDTransactionTypes: convertValueLOVToNumber(
              lovsJournal?.data?.DDTransactionTypes ?? []
            ),
          });
          setOriginNewChargeDetailsStepLOVs(newChargeDetail);
          setNewChargeDetailsStepLOVs(newChargeDetail);
          setNewJournalStepLOVs({
            JournalTypes: convertValueLOVToNumber(
              lovsJournal?.data?.JournalTypes ?? [],
              "Code"
            ),
            Officer: convertValueLOVToNumber(
              lovsJournal?.data?.Officer ?? [],
              "Code"
            ),
          });
          setNewAssessmentJournalStepLOVs({
            TransactionTypes: convertValueLOVToNumber(
              lovsJournal?.data?.TransactionTypes ?? [],
              "Code"
            ),
            PaymentAllocations: convertValueLOVToNumber(
              lovsJournal?.data?.PaymentAllocations ?? [],
              "Code"
            ),
            IsRebateTypeTransaction:
              lovsJournal?.data?.IsRebateTypeTransaction ?? false,
            AssessRebateEntitlements: convertValueLOVToNumber(
              lovsJournal?.data?.AssessRebateEntitlements ?? [],
              "Code"
            ),
            DDTransactionTypes: convertValueLOVToNumber(
              lovsJournal?.data?.DDTransactionTypes ?? []
            ),
          });
          setCreateSupplementaryLOVs(createSupplementaryLOVs);
          //LOV for PIC step
          setNewPICJournalStepLOVs({
            PICTransactionTypes: convertValueLOVToNumber(
              lovsJournal?.data?.PICTransactionTypes ?? [],
              "Code"
            ),
          });
          setWorkflowInitData(workflowData?.data);
          setPreviousAssessmentID(
            workflowData?.data?.WorkflowDetail?.Assessments?.[0]?.Assessment_Id
          );
          formStepRef?.current?.setLoadingFormStep(false);
        } else {
          let responseError: APIResponse<
            DTO_Journal_LOVs | DTO_Workflow_JournalCreate | ResponsePacket
          > = response[0];
          if (!isSuccessResponse(response[1])) {
            responseError = response[1];
          }
          formStepRef?.current?.setLoadingFormStep(false);
          formStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        formStepRef?.current?.setLoadingFormStep(false);
        formStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error: "Load workflow failed",
          },
        });
      }
    };

    const handleNextButton = async (data: DTO_Workflow_JournalCreate) => {
      const newData = { ...data };
      return handleSaveAndNext(newData);
    };

    useEffectOnce(() => {
      getWorkflowData();
    });

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        //Store submit event
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    //#region <Handle process workflow>
    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: INewProcessWorkflow<DTO_Workflow_JournalCreate>
    ) => {
      const {
        actionSuccess,
        defaultFailedMessage,
        setLoading,
        modeProcess,
        payload,
      } = props;
      const response = await postProcessCreateJournal(modeProcess, payload);
      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        } else {
          if (props?.actionFail) props?.actionFail(response);
          formStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        if (props?.actionFail) props?.actionFail(response);
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      if (props?.actionClose) props.actionClose();
    };
    //#endregion

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (payload: DTO_Workflow_JournalCreate) => {
      //props send to process workflow
      const finishProps: INewProcessWorkflow<DTO_Workflow_JournalCreate> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          if (isFromActionList) {
            clearSelectedItems();
            eventEmitter.emit(CCGridEventType.RefreshOData);
          }
          const notificationContent: IAppNotificationItemAddProps = {
            title:
              e?.Notification ??
              e?.SuccessMessage ??
              "New journal was created successfully.",
            type: "success",
          };
          if (!isNil(e?.Component_ID)) {
            history.push(`${PROPERTY_JOURNALS_ROUTE}/${e?.Component_ID}`, {
              notification: notificationContent,
            });
          } else {
            pushNotification(notificationContent);
          }
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        defaultFailedMessage: "Create journal could not be finished.",
        modeProcess: WorkflowProcessMode.Finish,
      };

      //calling api process workflow
      await handleProcessWorkflow(finishProps);
    };

    /**
     * handle park process
     * @param payload
     */
    const handleParkProcess = async (
      payload: DTO_Workflow_JournalCreate,
      mode?: EListSubmitButton
    ) => {
      //set loading button and dialog
      setIsLoadingProcess(WorkflowProcessMode.Park);
      mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
      const actionCloseRetainDialog = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog();
      };

      //props send to process workflow
      const parkProps: INewProcessWorkflow<DTO_Workflow_JournalCreate> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              e?.SuccessMessage ??
              "Create journal was parked successfully.",
            type: "success",
          });
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        actionClose: () => {
          mode === EListSubmitButton.ConfirmCloseYes &&
            actionCloseRetainDialog();
        },
        defaultFailedMessage: "Create journal could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };
      //calling api process workflow
      await handleProcessWorkflow(parkProps);
    };

    return (
      <CCFormStep
        ref={formStepRef}
        listButtonId={listSubmitButton}
        onSubmit={handleSubmit}
        localNotificationRef={localNotificationRef?.current ?? null}
        initialValues={initialValue}
        initialSteps={steps}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            titleHeader={titleHeader}
            onClose={() => handleCloseDialog(renderProps)}
            bodyElement={renderProps.children}
            badge={statusBadge}
            disabled={isLoadingProcess !== undefined || isLoadingInStep}
            footerElement={
              <>
                <div className={"cc-dialog-footer-actions-right"}>
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Park
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled ||
                        isLoadingProcess === WorkflowProcessMode.Park
                      }
                    >
                      Park
                    </Button>
                  )}
                  {/* Cancel button */}
                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      disabled={!isNil(isLoadingProcess)}
                      id={EListSubmitButton.Cancel}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}
                  {isToBeApprovalMode &&
                    workflowInitData?.WorkflowHeader?.OfficerCanApprove && (
                      <>
                        {/* Send Back button */}
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.SendBack}
                          disabled={
                            isLoadingProcess === WorkflowProcessMode.SendBack ||
                            renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingProcess === WorkflowProcessMode.SendBack
                              ? "fas fa-spinner fa-spin"
                              : ""
                          }
                          onClick={renderProps.submitButton.onClick}
                        >
                          Send Back
                        </Button>
                        {/* Reallocate button */}
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Reallocate}
                          disabled={
                            isLoadingProcess ===
                              WorkflowProcessMode.Reallocate ||
                            renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingProcess === WorkflowProcessMode.Reallocate
                              ? "fas fa-spinner fa-spin"
                              : ""
                          }
                          onClick={renderProps.submitButton.onClick}
                        >
                          Reallocate
                        </Button>
                        {/* Approve button */}
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Approve}
                          disabled={
                            isLoadingProcess === WorkflowProcessMode.Approve ||
                            renderProps.nextButton.disabled ||
                            isDisabledApproveButton(
                              workflowInitData?.WorkflowHeader
                            )
                          }
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                          iconClass={
                            isLoadingProcess === WorkflowProcessMode.Approve
                              ? "fas fa-spinner fa-spin"
                              : ""
                          }
                        >
                          Approve
                        </Button>
                        {/* Reject button */}
                        {isHiddenRejectButton(
                          workflowInitData?.WorkflowHeader
                        ) ? null : (
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reject}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reject
                          </Button>
                        )}
                      </>
                    )}
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}
                  {(isToBeApprovalMode || isReadOnly) &&
                  renderProps.isLastStep ? null : (
                    <Button
                      themeColor="primary"
                      id={
                        !renderProps.isLastStep &&
                        (isToBeApprovalMode || isReadOnly)
                          ? "cc-next-step-button"
                          : renderProps.nextButton.idButton
                      }
                      disabled={
                        isLoadingProcess === WorkflowProcessMode.Save ||
                        renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Save
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingProcess === WorkflowProcessMode.Save
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
              </>
            }
          />
        )}
      />
    );
  }
);
