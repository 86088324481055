const trimSpace = (string: string) => {
  return string.replace(/\s+/g, " ");
};

const handleMergeTagSpan = (html: string) => {
  let regexRuleTagSpanOfKendoList = [];
  let match;
  const regexGetAllTagSpan = /(<span[^>]*?>|<span>)([\s\S]*?)(<\/span>)/g;
  while ((match = regexGetAllTagSpan.exec(html))) {
    const tagOpen = match[1];
    if (tagOpen) {
      const regexRuleTagSpanOfKendoString = `(${tagOpen})([^]*?)(<\\/span>)(\\s+?((<\\w+(\\s[^>]*?|)>\\s+(${tagOpen})([^<>]*?)<\\/span>\\s+<\\/\\w+>)\\s+)+((${tagOpen})([^<>]*?)(<\\/span>)|))+`;
      regexRuleTagSpanOfKendoList.push(regexRuleTagSpanOfKendoString);
    }
  }

  /* ====  Remove regex duplicate  ==== */
  regexRuleTagSpanOfKendoList = regexRuleTagSpanOfKendoList.filter(
    (value, index, regexRuleTagSpanOfKendoList) =>
      regexRuleTagSpanOfKendoList.indexOf(value) === index
  );
  /* ====  / Remove regex duplicate  ==== */

  regexRuleTagSpanOfKendoList.forEach((regexItem) => {
    html = html.replace(new RegExp(regexItem, "g"), (match, group1) => {
      if (match) {
        let contentRemovedTagSpan = match.replace(
          /(<span(?:[^>]*?|)>)([^]*?)(<\/span>)/g,
          "$2"
        );
        contentRemovedTagSpan = trimSpace(contentRemovedTagSpan);

        contentRemovedTagSpan = contentRemovedTagSpan
          .replace(/(<\w+[^<>]*?>)\s/g, "$1")
          .replace(/\s(<\/\w+>)/g, "$1");

        const newContent = `${group1}${contentRemovedTagSpan}</span>`;
        return newContent;
      } else {
        return match;
      }
    });
  });
  return html;
};

// const handleRemoveTagP = (html: string) => { // TODO: test all case before remove it.
//   const regexClearTagP =
//     /((<p?\s+)(?:[^<>]*?|)(?:\s+|)(class="[^<>]*?")[^<>]*?(?:\s+|)(?:[^<>]*?|)(>)|<p>)([\s\S]*?)(<\/p>)/g;
//   return html.replace(regexClearTagP, (match, p1, p2, p3, p4, p5) => {
//     if (p3 === `class="tagP"`) {
//       return match.replace(/class="tagP"/g, "");
//     } else if (p3?.includes("tagP")) {
//       return match.replace(/(\s|)tagP/g, "");
//     } else {
//       return p5;
//     }
//   });
// };

const formatHTMLString = (inputString: string, indentSize = 2) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(inputString, "text/html");
  const indentChar = " ".repeat(indentSize);
  let output = "";

  function getAttributes(node: any) {
    let attributes = [];
    for (let attr of node.attributes) {
      attributes.push(`${attr.name}="${attr.value}"`);
    }
    return attributes.join(" ");
  }

  function recurse(node: any, level: number) {
    const isTagSpan = node.tagName?.toLowerCase() === "span";
    let indentBefore = isTagSpan ? "" : indentChar.repeat(Math.max(0, level));
    let indentAfter = isTagSpan
      ? ""
      : indentChar.repeat(Math.max(0, level - 1));
    let textNode;
    const lineBreak = isTagSpan ? "" : "\n";
    if (node.matches && node.matches("br,hr,input")) {
      if (
        node.parentNode &&
        node.parentNode.tagName?.toLowerCase() === "span"
      ) {
        output += `<${node.tagName?.toLowerCase()} />`;
      } else {
        output += `${indentBefore}<${node.tagName?.toLowerCase()} />`;
      }
    } else if (node.nodeType === Node.TEXT_NODE) {
      textNode = node.nodeValue; // Do not trim the text node
      if (textNode) {
        output += `${textNode}`;
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      let attributes = getAttributes(node);
      output += `${lineBreak}${indentBefore}<${node.tagName?.toLowerCase()} ${attributes}>`;
      for (let i = 0; i < node.childNodes.length; i++) {
        recurse(node.childNodes[i], level + 1);
      }
      output += `</${node.tagName?.toLowerCase()}>${lineBreak}${indentAfter}`;
    }
  }

  // Only iterate over the children of the <body> tag
  for (let i = 0; i < doc.body.childNodes.length; i++) {
    recurse(doc.body.childNodes[i], 0);
  }

  // Remove empty lines
  output = output
    .split("\n")
    .filter((line) => line?.trim() !== "")
    .join("\n");

  return output.replace(/(<\w+)(\s)(>)/g, "$1$3");
};

export const encryptionHtmlForEditor = (html: string) => {
  const regexGetIdMain =
    /(<\w+?\s+)(?:[^<>]*?|)(?:\s+|)id="DATA_SKIP_KENDO-([^<>"']*?)"[^<>]*?(?:\s+|)(?:[^<>]*?|)(>)(<\/div>)/gi;
  regexGetIdMain.lastIndex = 0;

  if (regexGetIdMain.test(html)) {
    return html;
  } else {
    const fragment = document.createRange().createContextualFragment(html);
    regexGetIdMain.lastIndex = 0;

    /* ====  Check Tag P  ==== */
    let pTags = fragment.querySelectorAll("p");
    let regexCheckTagP = new RegExp("^encryption-.*");
    pTags.forEach((p) => {
      // if (p.innerHTML === "") { // TODO: test all case before remove it.
      //   p.remove();
      // }
      if (!p.className || !regexCheckTagP.test(p.className)) {
        p.classList.add("tagP");
      }
    });
    /* ====  / Check Tag P  ==== */

    /* ====  Data HTML Origination  ==== */
    const regexSplitMainHtml =
      /([\s\S\n]*?<\/head>|)([\s\S]*?<body[^>]*?>|)([\s\S]*?(<\/(?:body|html)>[\s\S]*)|[\s\S]*)/gi;
    const matchMain = regexSplitMainHtml.exec(html);

    const htmlDataProps = {
      docType: matchMain?.[1],
      bodyStart: matchMain?.[2],
      footer: matchMain?.[4],
    };

    const htmlData = `<div id="DATA_SKIP_KENDO-${btoa(
      JSON.stringify(htmlDataProps)
    )}"></div>\n`;
    /* ====  / Data HTML Origination  ==== */

    const regexGetCssInStyleTag = /([\w.#]+)\s*\{\s*([^}]+)\s*\}/g;
    let match;
    while ((match = regexGetCssInStyleTag.exec(html))) {
      let selector = match[1];
      let rules = match[2];
      let elements = fragment.querySelectorAll(selector);

      /* ====  Add style to tag html ==== */
      for (let i = 0; i < elements.length; i++) {
        let inlineStyles = elements[i].getAttribute("style") || "";
        /* ====  Check ";"  ==== */
        if (inlineStyles?.trim().replace(/(.+)(.$)/g, "$2") !== ";") {
          inlineStyles = inlineStyles + ";";
        }
        /* ====  / Check ";"  ==== */
        inlineStyles += rules;

        /* ====  Delete duplicate css inline ==== */
        inlineStyles = inlineStyles
          .split(";")
          .filter((item) => item?.trim() !== "")
          .map((item) => item?.trim())
          .reverse()
          .filter((v, i, s) => s.indexOf(v) === i)
          .join(";");
        /* ====  / Delete duplicate css inline ==== */

        /* ====  Get all attr of selector  ==== */
        const attrList = elements[i].getAttributeNames().map((attrName) => {
          const attrValue = elements[i]?.getAttribute(attrName);
          return `${attrName}="${attrValue}"`;
        }, {});
        const attrListString = attrList.join(" ");
        /* ====  / Get all attr of selector  ==== */
        let optionData = {
          cssInStyleTag: rules,
          allAttributeInHtml: attrListString,
        };

        const data = btoa(JSON.stringify(optionData));

        if (inlineStyles) {
          elements[i].setAttribute("style", inlineStyles);
        }
        elements[i].setAttribute("class", `encryption-${data}`);
      }
      /* ====  / Add style to tag html ==== */
    }

    const serializer = new XMLSerializer();
    const stringIncludeCssInStyleTagAddToHTML =
      htmlData +
      serializer
        .serializeToString(fragment)
        .replace(/>\s+</g, "><")
        .replaceAll(` xmlns="http://www.w3.org/1999/xhtml"`, "")
        ?.trim();

    /* ====  Check other attribute  ==== */
    const result = stringIncludeCssInStyleTagAddToHTML.replace(
      /(<\w+?\s+)((?![^>]*(?:(?:\bclass|\bstyle)=|(?:\bid="DATA_SKIP_KENDO)))[^>/]*)(>)/gi,
      (match, group1, group2, group3) => {
        let optionData = {
          allAttributeInHtml: group2,
        };
        const data = btoa(JSON.stringify(optionData));
        return group1 + `class="encryption-${data}"` + group3;
      }
    );
    /* ====  / Check other attribute  ==== */
    return result;
  }
};

const convertPTagEmptyToBr = (htmlString: string) => {
  const newHtmlString = htmlString?.replace(
    /(<p><\/p>)|(<p>(?:(?:(?!<br\b)[^\w\d=*()^%$#@!~`"'+-/?><,.]*?)<br(?:[^/>]*?)\/>)+?[^\w\d=*()^%$#@!~`"'+-/?><,.]*?<\/p>)/gim,
    (match, group1, group2) => {
      if (group1) return "<br/>";
      if (group2) {
        const arrMatch = group2.match(/<br(?:[^/>]*?)\/>/gm);
        let result = "";
        arrMatch.forEach(() => {
          result += "<br/>";
        });
        return result;
      }
      return match;
    }
  );
  return newHtmlString;
};

export const decryptionHtmlForEditor = (html: string) => {
  const regexGetIdMain =
    /(<\w+?\s+)(?:[^<>]*?|)(?:\s+|)id="DATA_SKIP_KENDO-([^<>"']*?)"[^<>]*?(?:\s+|)(?:[^<>]*?|)(>)(<\/div>)/gi;

  const regexGetClass =
    /(<\w+?\s+)(?:[^<>]*?|)(?:\s+|)class="encryption-([^<>]*?)"[^<>]*?(?:\s+|)(?:[^<>]*?|)(>)/gi;

  regexGetIdMain.lastIndex = 0;
  if (!regexGetIdMain.test(html)) {
    return convertPTagEmptyToBr(html);
  } else {
    regexGetIdMain.lastIndex = 0;
    const contentHtml = html.replace(regexGetClass, (match) => {
      return match.replace(
        /(<\w+?\s+)(?:[^<>]*?|)(?:\s+|)class="encryption-([^<>"']*?)"[^<>]*?(?:\s+|)(?:[^<>]*?|)(>)/gi,
        (matchItem, p1, p2, p3) => {
          const contentStyleList =
            /(style=["'])([^"']*?)(["'])/gi.exec(matchItem) || [];
          let contentStyle = contentStyleList[2];
          const data = JSON.parse(atob(p2));

          let newAttribute = data.allAttributeInHtml?.trim();

          /* ====  Get Css In Style Tag  ==== */
          let cssInStyleTag = data?.cssInStyleTag;
          if (cssInStyleTag) {
            /* ====  Delete css if it existed in class ==== */
            const cssInStyleTagList = cssInStyleTag
              .split(";")
              .filter((item: string) => item?.trim() !== "")
              .map((item: string) => item?.trim());
            cssInStyleTagList.forEach((item: string) => {
              contentStyle = contentStyle.replaceAll(
                new RegExp(`${item}(;|)`, "g"),
                ""
              );
            });
            /* ====  / Delete css if it existed in class ==== */
          }

          /* ====  / Get Css In Style Tag  ==== */
          contentStyle = contentStyle?.trim();
          if (contentStyle) {
            if (!/style=["'][^"']*?["']/gi.test(newAttribute)) {
              newAttribute =
                data.allAttributeInHtml?.trim() + ` style="${contentStyle}"`;
            } else {
              newAttribute = newAttribute.replace(
                /(style=["'])([^"']*?)(["'])/g,
                `$1${contentStyle}$3`
              );
            }
          }
          return `${p1?.trim()} ${newAttribute}${p3?.trim()}`;
        }
      );
    });

    const contentHtmlObject = regexGetIdMain.exec(contentHtml);
    regexGetIdMain.lastIndex = 0;
    let contentHtmlRemoveIdData = contentHtml.replace(regexGetIdMain, "");

    // contentHtmlRemoveIdData = handleRemoveTagP(contentHtmlRemoveIdData); // TODO: test all case before remove it.

    const contentEditor = formatHTMLString(contentHtmlRemoveIdData);

    const contentAllFile = contentHtmlObject?.[2];

    const contentAllFileObj: {
      docType: string;
      bodyStart: string;
      footer: string;
    } = contentAllFile && JSON.parse(atob(contentAllFile));

    const docType = contentAllFileObj?.docType ?? "";
    const bodyStart = contentAllFileObj?.bodyStart ?? "";
    const footer = contentAllFileObj?.footer ?? "";

    let result = docType + bodyStart + "\n" + contentEditor + "\n" + footer;

    result = handleMergeTagSpan(result);
    return convertPTagEmptyToBr(result);
  }
};
