import { eventEmitter } from "@/App";
import { history } from "@/AppRoutes";
import {
  getFindDuplicateContact,
  postMergeContacts,
  postSetupMergeContacts,
} from "@app/core/contacts/merge-contact/api";
import {
  ContactAction,
  MergeContactsLovs,
  Svc_MergeContacts,
} from "@app/core/contacts/merge-contact/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { Contact } from "@common/models/contact";
import { IdentityPacketErrorStatusNumber } from "@common/models/sysEnumerations";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { newCCProductListViewStore } from "@components/cc-product-list-view/store";
import { cloneDeep } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class MergeContactStore {
  private _isLoading: boolean = false;
  private _isLoadingFindDuplicateContact: boolean = false;
  private _isLoadingMergeContacts: boolean = false;
  private _isLoadingMergeAndEditContacts: boolean = false;
  private _isShowMergeDialog: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _notification?: IAppNotificationItemAddProps = undefined;
  private _mergeContactsLovs?: MergeContactsLovs = undefined;
  private _contacts?: Contact[] = undefined;
  private _masterContact?: Contact = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isLoadingMergeContacts() {
    return this._isLoadingMergeContacts;
  }
  setIsLoadingMergeContacts = (isLoadingMergeContacts: boolean) => {
    runInAction(() => {
      this._isLoadingMergeContacts = isLoadingMergeContacts;
    });
  };

  get isLoadingMergeAndEditContacts() {
    return this._isLoadingMergeAndEditContacts;
  }
  setIsLoadingMergeAndEditContacts = (
    isLoadingMergeAndEditContacts: boolean
  ) => {
    runInAction(() => {
      this._isLoadingMergeAndEditContacts = isLoadingMergeAndEditContacts;
    });
  };

  get isLoadingFindDuplicateContact() {
    return this._isLoadingFindDuplicateContact;
  }
  setIsLoadingFindDuplicateContact = (
    isLoadingFindDuplicateContact: boolean
  ) => {
    runInAction(() => {
      this._isLoadingFindDuplicateContact = isLoadingFindDuplicateContact;
    });
  };

  get notification() {
    return this._notification;
  }
  setNotification = (
    notification: IAppNotificationItemAddProps | undefined
  ) => {
    runInAction(() => {
      this._notification = notification;
    });
  };

  get mergeContactsLovs() {
    return this._mergeContactsLovs;
  }
  setMergeContactsLovs = (mergeContactsLovs: MergeContactsLovs) => {
    runInAction(() => {
      this._mergeContactsLovs = mergeContactsLovs;
    });
  };

  get contacts() {
    return this._contacts;
  }
  setContacts = (contacts: Contact[]) => {
    runInAction(() => {
      this._contacts = contacts;
    });
  };

  get masterContact() {
    return this._masterContact;
  }
  setMasterContact = (contact: Contact) => {
    runInAction(() => {
      this._masterContact = contact;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get isShowMergeDialog() {
    return this._isShowMergeDialog;
  }
  setIsShowMergeDialog = (isShowMergeDialog: boolean) => {
    runInAction(() => {
      this._isShowMergeDialog = isShowMergeDialog;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._notification = undefined;
      this._contacts = undefined;
      this._mergeContactsLovs = undefined;
      this._masterContact = undefined;
      this._isLoadingFindDuplicateContact = false;
      this._isLoadingMergeContacts = false;
      this._isLoadingMergeAndEditContacts = false;
      this._isShowMergeDialog = false;
      this._onSubmit = undefined;
    });
  };

  findDuplicateContact = async () => {
    this.setIsLoadingFindDuplicateContact(true);
    const response = await getFindDuplicateContact();
    this.setIsLoadingFindDuplicateContact(false);

    if (!isSuccessIdentityPacket(response)) {
      appNotificationStore.pushNotification({
        autoClose: false,
        type: "error",
        description: response?.data?.Errors ?? response.error,
      });
    }
  };

  setupMergeContacts = async (contactIds: number[]) => {
    this.setIsLoading(true);
    const response = await postSetupMergeContacts(contactIds);
    this.setIsLoading(false);

    if (
      isSuccessResponse(response) &&
      response?.data?.ErrorStatus !==
        IdentityPacketErrorStatusNumber.HasErrors &&
      response?.data?.ReturnObj?.Contacts &&
      response?.data?.ReturnObj?.MergeContactsLovs
    ) {
      let contactsList = response.data.ReturnObj.Contacts;
      contactsList = contactsList.map((contact: Contact) => ({
        ...contact,
        _action: ContactAction.Merge,
      }));
      const unMasterContacts = cloneDeep(contactsList);
      unMasterContacts.shift();
      this.setMasterContact(contactsList[0]);
      this.setContacts(unMasterContacts);
      this.setMergeContactsLovs(response.data.ReturnObj.MergeContactsLovs);
    } else {
      this.setResponseLoadError({
        status: response.status,
        error: response.data?.Errors ?? response.error,
      });
    }
  };

  mergeContacts = async (
    mergeContacts: Svc_MergeContacts,
    isMergeAndEdit: boolean
  ) => {
    isMergeAndEdit
      ? this.setIsLoadingMergeAndEditContacts(true)
      : this.setIsLoadingMergeContacts(true);
    const response = await postMergeContacts(mergeContacts);
    isMergeAndEdit
      ? this.setIsLoadingMergeAndEditContacts(false)
      : this.setIsLoadingMergeContacts(false);

    if (
      isSuccessResponse(response) &&
      response?.data?.ErrorStatus !==
        IdentityPacketErrorStatusNumber.HasErrors &&
      response?.data?.Errors?.length === 0
    ) {
      this.setIsShowMergeDialog(false);
      if (isMergeAndEdit) {
        history.push(`/contact/${mergeContacts.MasterContactID}`);
      } else {
        appNotificationStore.pushNotification({
          type: "info",
          autoClose: false,
          description: response.data.Notifications ?? "merge contact success",
        });
      }
      newCCProductListViewStore.clearSelectedItems();
      eventEmitter.emit(CCGridEventType.RefreshOData);
    } else {
      this.setNotification({
        autoClose: false,
        type: "error",
        description: response?.data?.Errors ?? response.error,
      });
    }
  };
}

const MergeContactStoreContext = createContext(new MergeContactStore());
export const useMergeContactStore = () => {
  return useContext(MergeContactStoreContext);
};
