export class DateFromDateTo {
  Title_Text?: string;
  DateLabelFrom_Text?: string;
  DateLabelFrom_To?: string;
  DateFrom?: Date = undefined;
  DateTo?: Date = undefined;
  ShowNotification?: boolean;
  SendNotification?: boolean;
  NotifyText?: string;
  Flag_DateFromIsMandatory: boolean;
  Flag_DateToIsMandatory: boolean;
  Flag_ShowDateToDefaultValue: boolean;

  constructor() {
    this.Title_Text = "Enter the dates";
    this.DateLabelFrom_Text = "Date from";
    this.DateLabelFrom_To = "Date to";

    this.SendNotification = false;
    this.ShowNotification = false;
    this.NotifyText = "";
    this.Flag_DateFromIsMandatory = true;
    this.Flag_DateToIsMandatory = true;
    this.Flag_ShowDateToDefaultValue = true;
  }
}
