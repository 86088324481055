import { VO_Supplementary_Levy_Calculation } from "@app/products/property/supplementary-rates/[id]/components/child-screens/charges/model";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOf = nameOfFactory<VO_Supplementary_Levy_Calculation>();
export const colSupplementaryCharges: IColumnFields[] = [
  {
    field: nameOf("Assessment_Number_Formatted"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Levy_Category"),
    title: "Levy Category",
  },

  {
    field: nameOf("Levy_Name"),
    title: "Levy Name",
  },
  {
    field: nameOf("Levy_Code"),
    title: "Levy Code",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Rating_Period"),
    title: "Rating Period",
  },
  {
    field: nameOf("SLC_Diff_Base_Amount"),
    title: "Base Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("SLC_Diff_Base_Amount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("SLC_Diff_RateByValue_Amount"),
    title: "Rate by Valn Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("SLC_Diff_RateByValue_Amount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("SLC_Diff_Gross_Amount"),
    title: "Gross Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("SLC_Diff_Gross_Amount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("SLC_Diff_Nett_Amount"),
    title: "Nett Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("SLC_Diff_Nett_Amount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("SLC_Supp_Nett_Amount"),
    title: "Supplementary Nett Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("SLC_Supp_Nett_Amount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("SLC_Supp_Rebate_Amount"),
    title: "Supplementary Rebate Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("SLC_Supp_Rebate_Amount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Instalment_Plan"),
    title: "Instalment Plan",
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Balance", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
