import { VO_Journal_Transaction_NotIn_Journal } from "@app/products/property/journals/not-yet-in-journal/assessment-transactions/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Journal_Transaction_NotIn_Journal>();
export const colListJournalAssessmentTransaction: IColumnFields[] = [
  {
    field: nameOf("Assess_Number"),
    title: "Assessment Number",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("Trn_DateTime"),
    title: "Transaction Date/Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Trn_Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("TT_Name"),
    title: "Transaction Type",
  },
  {
    field: nameOf("Trn_Reference"),
    title: "Reference",
  },
  {
    field: nameOf("Trn_Description"),
    title: "Description",
  },
  {
    field: nameOf("Trn_Notes"),
    title: "Notes",
  },
  {
    field: nameOf("Property_Name_Address_Locality"),
    title: "Property Address",
  },
  {
    field: nameOf("Ratepayer_Name"),
    title: "Ratepayer Name",
  },
  {
    field: nameOf("Trn_UserId"),
    title: "User ID",
  },
  {
    field: nameOf("Trn_Is_Proposed"),
    title: "Proposed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Trn_Is_Cancelled"),
    title: "Cancelled",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Transaction_Id"),
    title: "Transaction ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
