import { DateCell } from "@app/products/property/assessments/components/custom-cell/date-cell";
import { NumberInputCell } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/custom-cell/number-input-cell/_index";
import {
  DTO_ModifyParcel_Assessment,
  DTO_ModifyParcel_Title,
} from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfTitle = nameOfFactory<DTO_ModifyParcel_Title>();
const nameOfAssessments = nameOfFactory<DTO_ModifyParcel_Assessment>();
export const colTitles: IColumnFields[] = [
  {
    field: nameOfTitle("Legal_Description"),
    title: "Legal Description",
  },
  {
    field: nameOfTitle("Factor"),
    title: "Factor",
    format: NUMBER_FORMAT.NUMBER2,
    editable: true,
    editCell: NumberInputCell,
  },
  {
    field: nameOfTitle("EffectiveFrom"),
    title: "Effective From",
    format: DATE_FORMAT.DATE,
    editable: true,
    editCell: DateCell,
  },
  {
    field: nameOfTitle("EffectiveTo"),
    title: "Effective To",
    format: DATE_FORMAT.DATE,
    editable: true,
    editCell: DateCell,
  },
  {
    field: nameOfTitle("Owners"),
    title: "Owners",
  },
  {
    field: nameOfTitle("Title_Id"),
    title: "Title ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colAssessments: IColumnFields[] = [
  {
    field: nameOfAssessments("Reference"),
    title: "Assessment Number",
  },
  {
    field: nameOfAssessments("PropertyName_Address_Locality"),
    title: "Property Address",
  },
  {
    field: nameOfAssessments("Factor"),
    title: "Factor",
    format: NUMBER_FORMAT.NUMBER2,
    editable: true,
    editCell: NumberInputCell,
  },
  {
    field: nameOfAssessments("EffectiveFrom"),
    title: "Effective From",
    format: DATE_FORMAT.DATE,
    editable: true,
    editCell: DateCell,
  },
  {
    field: nameOfAssessments("EffectiveTo"),
    title: "Effective To",
    format: DATE_FORMAT.DATE,
    editable: true,
    editCell: DateCell,
  },
  {
    field: nameOfAssessments("RatePayerName"),
    title: "Ratepayer",
  },
  {
    // TODO: when have API field: nameOfAssessments("Unit_Entitlement"),
    field: "Unit_Entitlement",
    title: "Unit Entitlement",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    // TODO: when have API field: nameOfAssessments("Unit_Entitlement_Percentage"),
    field: "Unit_Entitlement_Percentage",
    title: "Unit Entitlement Percentage",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    // TODO: when have API field: nameOfAssessments("Unit_Type"),
    field: "Unit_Type",
    title: "Unit Type",
  },
  {
    // TODO: when have API field: nameOfAssessments("Unit_Type_Percentage"),
    field: "Unit_Type_Percentage",
    title: "Unit Type Percentage",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessments("Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
