export enum eVisibility {
  Collapsed = "Collapsed",
  Hidden = "Hidden",
  Visible = "Visible",
}

export interface DTO_Assessment_Names {
  UtilityAgentVisibility: eVisibility;
  DuplicateRatepayer: DTO_NameAndAddress;
  Ratepayer: DTO_NameAndAddress;
  Entities: DTO_AssessmentEntity[];
  Lease: DTO_AssessmentLease;
  DuplicateRatepayerVisibility: eVisibility;
  OwnersGroupBoxVisibility: eVisibility;
  LeaseConditionsVisibility: eVisibility;
  LeasePeriodVisibility: eVisibility;
  LeaseTypeVisibility: eVisibility;
  LeaseMailboxVisibility: eVisibility;
  LeaseReferenceVisibility: eVisibility;
  LeaseVisibility: eVisibility;
  NameAndAddressGroupName: string;
  IncludePastNames: boolean;
  AssessmentId: number;
  Owners: DTO_NameAndAddress;
  UtilityAgent: DTO_NameAndAddress;
}
export interface DTO_AssessmentEntity {
  Role: string;
  Name: string;
  Gender: string;
  Address_Formatted: string;
  OwnershipPercentage: number | null;
  IsActive: boolean;
  ChangeOfOwnership_Id: number | null;
  ChangeOfOwnership_Reference: string;
  ChangeOfOwnership_EffectiveDate: Date | null;
  Id: number;
  Contact_Name_Address_Id: number;
  AssociatedEntities: DTO_AssociatedEntity[];
}

export interface DTO_AssociatedEntity {
  Role: string;
  Name: string;
  Gender: string;
  Address_Formatted: string;
  IsActive: boolean;
  ChangeOfOwnership_Id: number | null;
  ChangeOfOwnership_Reference: string;
  ChangeOfOwnership_EffectiveDateeDate: Date | null;
  Id: number;
  AssociatedTo_Id: number;
  Contact_Name_Address_Id: number;
}
export interface DTO_Entity {
  Id: number;
  Gender: string;
}

export interface DTO_NameAndAddress {
  NameAndAddress: string;
  NoticeGroup?: string;
  NameAddress_Id: number;
}

export interface DTO_EntityNameAndAddress {
  Id: number;
  Name: string;
  Address_Formatted: string;
  Gender: string;
}

export interface DTO_ChangeOfOwnership {
  Id: number;
  Reference: string;
  EffectiveDate: string | null;
}

export interface DTO_AssessmentLease {
  Reference: string;
  LeaseType: string;
  LeaseDate: string | null;
  Conditions: string;
  MailboxEmailAddress: string;
}
