import { DTO_New_Owner } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import {
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { PercentageCell } from "@components/cc-grid/components/grid-cells/percentage/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

export const fieldName = {
  Names: "Contacts",
  NameSelected: "_option.NameSelected",
  OwnerSelected: "_option.OnwerSelected",
  SearchName: "SearchName",
  Role: "Role_Name",
  Percentage: "Percentage",
  Name: "Name",
  PrimaryKey: "Entity_Id",
  EntityUsedForOwnerDialog: "_option.EntityIdUseForOnwerDialog",
  AttentionOf: "Attention_Of",
  CareOf: "Care_Of",
  Address: "Address",
  Locality: "Locality",
  State: "State",
  Country: "Country",
  Postcode: "Postcode",
  DPID: "DPID",
  NoticeId: "Notice_Id",
  FormattedNameAddress: "Formatted_Name_Address",
  isNeedToResetFormatted: "_option.IsNeedToResetFormatted",
  isPreventPopulateNames: "_option.IsPreventPopulateNames",
  InitAssociatedNamesDetails: "InitAssociatedNamesDetails",
  InitRatePayers: "InitRatePayers",
};

const nameOfNewOwner = nameOfFactory<DTO_New_Owner>();
export const newOwnerCols: IColumnFields[] = [
  { field: nameOfNewOwner("Name"), title: "Name" },
  {
    field: nameOfNewOwner("Role_Name"),
    title: "Role",
  },
  {
    field: nameOfNewOwner("Percentage"),
    title: "Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
    calculateFooterCellValue: (_props: GridFooterCellProps, gridData: any) =>
      totalColumnField(fieldName.Percentage, gridData),
    footerCell: <PercentageCell className="text-success" />,
  },
  { field: nameOfNewOwner("Address"), title: "Address" },
  {
    field: nameOfNewOwner("Entity_Name_Address_Id"),
    title: "Contact Name/Address ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
