import {
  RequestObjFromSpatial,
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { DTO_Parcel_LOVs } from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/model";
import { DTO_Workflow_CreateParcel } from "@app/products/property/parcels/components/form-steps/create-parcel/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postProcessCreateParcel = async (
  workflowType: WorkflowProcessMode,
  parcelData: DTO_Workflow_CreateParcel
): Promise<APIResponse<ResponseMessageWorkflow<DTO_Workflow_CreateParcel>>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/int/workflow/parcel/process/${workflowType}`,
      parcelData
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataParcel =
  | [APIResponse<DTO_Parcel_LOVs>, APIResponse<DTO_Workflow_CreateParcel>]
  | APIResponse<DTO_Parcel_LOVs | DTO_Workflow_CreateParcel>
  | undefined;
export const getInitialDataParcel = async (
  workflowType: WorkflowTypes,
  workflowDraftId: number | undefined
): Promise<IGetInitialDataParcel> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Parcel_LOVs>(
        `/api/property/internal/parcel/0/lovs?workflowType=${workflowType}` //Default 0 using for all Parcel
      ),
      !isNil(workflowDraftId)
        ? getWorkflowCreateParcel(workflowDraftId)
        : getNewWorkflowCreateParcel(),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowCreateParcel = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_CreateParcel>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/int/workflow/parcel/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowCreateParcel = async (
  requestObjSpatial: RequestObjFromSpatial | object = {}
): Promise<APIResponse<DTO_Workflow_CreateParcel>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/int/workflow/parcel/new`,
      requestObjSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
