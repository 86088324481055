import {
  mockLocalLawsAll,
  mockLocalLawsByID,
} from "@app/products/local-laws/mock";
import { ILocalLaws } from "@app/products/local-laws/model";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";

export const getLocalLawsById = async (
  id: number
): Promise<APIResponse<ILocalLaws | undefined>> => {
  await sleep(1000);
  const recordRegister = mockLocalLawsAll.find((item) => item.ID === id);

  if (recordRegister) {
    return {
      data: mockLocalLawsByID as ILocalLaws,
      status: APIResponseStatus.SUCCESS,
      statusText: "Ok",
      headers: {},
      config: {},
    };
  } else {
    return {
      data: undefined,
      status: APIResponseStatus.NOT_FOUND,
      statusText: "Not Found",
      headers: {},
      config: {},
    };
  }
};
