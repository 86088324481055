import { PPRApplication } from "@app/products/town-planning/ppr/permit-referrals/all/register/model";
import { handlePermitReferralsAllLinkTo } from "@app/products/town-planning/ppr/permit-referrals/all/util";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<PPRApplication>();

export const colPPRAllApplicationsRegister: IColumnFields[] = [
  {
    field: nameOf("ApplicationNumber"),
    title: "Number",
    locked: true,
    linkTo: handlePermitReferralsAllLinkTo,
  },
  {
    field: nameOf("LodgedDate"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("RefTrigger"),
    title: "Referral Trigger",
  },
  {
    field: nameOf("SiteAddress"),
    title: "Site Address",
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("ResponsibleAuthority"),
    title: "Responsible Authority",
  },
  {
    field: nameOf("Planner"),
    title: "Planner",
  },
  {
    field: nameOf("TeamLeader"),
    title: "Delegated Approver",
  },
  {
    field: nameOf("Status_Name"),
    title: "Status",
  },
  {
    field: nameOf("StatusWithSubStatus"),
    title: "Status With Sub-status",
  },
  {
    field: nameOf("DotOffice"),
    title: "DoT Region",
  },
  {
    field: nameOf("ResponseDue"),
    title: "Response Due",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("StatDays"),
    title: "Stat Days",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Rating"),
    title: "Rating",
  },
  {
    field: nameOf("CouncilReferenceNo"),
    title: "Council Reference Number",
  },
  {
    field: nameOf("Estate"),
    title: "Estate",
  },
  {
    field: nameOf("Applicant"),
    title: "Applicant",
  },
  {
    field: nameOf("ApplicantAddress"),
    title: "Applicant Address",
  },
  {
    field: nameOf("ApplicantContact"),
    title: "Applicant Contact",
    ignoreGlobalFilter: true,
  },
  {
    field: nameOf("Postcode"),
    title: "Postcode",
  },
  {
    field: nameOf("AppealDays"),
    title: "Appeal Days",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("ReasonForPermit"),
    title: "Reason for Permit",
  },
  {
    field: nameOf("ApplicationType"),
    title: "Application Type",
  },
  {
    field: nameOf("ApplicationCategories"),
    title: "Category",
  },
  {
    field: nameOf("Decision"),
    title: "Decision",
  },
  {
    field: nameOf("DecisionDate"),
    title: "Decision Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DecisionType"),
    title: "Type",
  },
  {
    field: nameOf("FinalDecision"),
    title: "Final Decision",
  },
  {
    field: nameOf("FinalDecisionDate"),
    title: "Final Decision Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("FinalDecisionType"),
    title: "Type",
  },
  {
    field: nameOf("AssessmentNo"),
    title: "Assess Number",
  },
  {
    field: nameOf("FileNo"),
    title: "File Number",
  },
  {
    field: nameOf("RIN"),
    title: "Rin",
  },
  {
    field: nameOf("Ward"),
    title: "Ward",
  },
  {
    field: nameOf("CostOfWorks"),
    title: "Cost of Works",
    format: CURRENCY_FORMAT.CURRENCY2,
  },
  {
    field: nameOf("SpearRef"),
    title: "Spear Reference",
  },
];
