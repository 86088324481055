import { AnimalsMenu } from "@app/products/animals/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const poundRegisterRoute: ICCRoute = {
  path: "pound-register",
  name: "Pound Register",
  enum: AnimalsMenu.PoundRegister,
  children: [
    {
      path: "by-number",
      name: "By Number",
      enum: AnimalsMenu.PoundRegisterByNumber,
      component: require("./by-number/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      enum: AnimalsMenu.PoundRegisterByStatus,
      component: require("./by-status/_index").default,
    },
  ],
};
