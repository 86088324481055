import { AddCommentPropertyButton } from "@app/core/comments/components/buttons/add-comment/components/add-comment-property/_index";
import { PropertyComments } from "@app/core/comments/components/property-comments/_index";
import { OutputToFileButton } from "@app/products/property/charge-and-notice-runs/notice-runs/components/buttons/output-to-file/_index";
import { NoticeRunsAssessments } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/child-screens/assessments/_index";
import { NoticeRunForm } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/child-screens/general/_index";
import { NoticeRunsNoticeGroups } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/child-screens/notice-groups/_index";
import { NoticeRunsNotices } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/child-screens/notices/_index";
import { NoticeRunInfoDetailTab } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/reference-sidebar/detail/_index";
import { useNoticeRunsStore } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/store";
import { eDocumentTypeCategory } from "@app/products/property/components/action-bar/button/add-attachment/model";
import { AddAttachmentPropertyButton } from "@app/products/property/components/action-bar/button/add-attachment/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyDocuments } from "@app/products/property/components/child-screen/documents/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ECustomColNameProperty } from "@app/products/property/config";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { Label } from "@common/stores/products/config";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedNoticeRun = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const { noticeRuns, isLoading, loadNoticeRuns, responseLoadError } =
    useNoticeRunsStore();

  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  //Get label
  const assessmentsLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessments
  );

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        noticeRuns?.NoticeRunId,
        noticeRuns?.NoticeName,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Property - Charge And Notice Runs - Notice Runs  - ${id}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
      Record_ID: +id ?? 0,
    },
  ];

  const getFormTitle = useMemo(() => {
    return `Notice Run ${noticeRuns?.NoticeRunId}: ${noticeRuns?.NoticeName}`;
  }, [noticeRuns]);

  const listPanelBar: IPropertyPanelBar[] = [
    {
      title: "Notices",
      component: <NoticeRunsNotices />,
    },
    {
      title: "Comments",
      component: (
        <PropertyComments
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Notice_Run_Assessment}
        />
      ),
    },
    {
      title: assessmentsLabel,
      component: <NoticeRunsAssessments />,
    },
    {
      title: "Notice Groups",
      component: <NoticeRunsNoticeGroups />,
    },
    {
      title: "Documents",
      component: (
        <PropertyDocuments
          componentId={parseInt(id)}
          componentNumber={eComponent.Notice_Run}
        />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Notice Run" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadNoticeRuns(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={getFormTitle} />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <OutputToFileButton />
              </CCNavButton>,
              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <AddAttachmentPropertyButton
                  id={parseInt(id)}
                  recordType={
                    RECORDTYPE.CommunityProperty_Notice_Run_Assessment
                  }
                  documentCategoryTypeId={
                    eDocumentTypeCategory.Notice_Run_Document
                  }
                />
                <AddCommentPropertyButton
                  id={parseInt(id)}
                  recordType={
                    RECORDTYPE.CommunityProperty_Notice_Run_Assessment
                  }
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {noticeRuns && <CCGeneralPanel component={<NoticeRunForm />} />}
                <PropertyPanelBar initialPanels={listPanelBar} />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <NoticeRunInfoDetailTab /> },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={
                            RECORDTYPE.CommunityProperty_Notice_Run_Assessment
                          }
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
