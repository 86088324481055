import {
  ComboboxSearchAPI,
  IEventOnChangeComboBox,
} from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/components/combobox-search-api/_index";
import { nameOfLov } from "@app/products/property/model";
import { mockDataDropDown } from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/components/dialogs/new-address/model";
import { mockData } from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/components/form-elements/addresses/mock";
import { DTO_ModifyParcel_Address } from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { indexOf, isEmpty } from "lodash";
import React, { useRef } from "react";

interface INewAddressDialogProps {
  onClose: () => void;
  isLoadingFinish?: boolean;
  dataItem?: any;
  handleAddAddress: (data: any) => void;
}

const nameOf = nameOfFactory<DTO_ModifyParcel_Address>();
export const NewAddressDialog = ({
  onClose,
  isLoadingFinish = false,
  dataItem,
  handleAddAddress,
}: INewAddressDialogProps) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  return (
    <Form
      initialValues={dataItem}
      onSubmitClick={({ values }: FormSubmitClickEvent) =>
        handleAddAddress(values)
      }
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter, onChange } = formRenderProps;
        const getFieldValue = (name: keyof DTO_ModifyParcel_Address) =>
          valueGetter(nameOf(name));
        const address = getDropdownValue(
          valueGetter("dropdown"),
          mockDataDropDown,
          "Code"
        )?.Name;
        const fullAddress =
          address &&
          `${address.slice(0, indexOf(address, ","))} Street${address.slice(
            indexOf(address, ",")
          )}`;

        return (
          <CCDialog
            titleHeader={isEmpty(dataItem) ? "New Address" : "Edit Address"}
            maxWidth="50%"
            height="auto"
            onClose={onClose}
            bodyElement={
              <FormElement className="cc-form">
                <section className="cc-field-group">
                  <CCLocalNotification ref={notificationRef} />
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Address type</label>
                      <Field
                        name={nameOf("AddressType")}
                        component={CCSearchComboBox}
                        data={mockData}
                        isUseDefaultOnchange
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Is the primary address</label>
                      <Field
                        name={nameOf("IsAddressPrimary")}
                        component={CCSwitch}
                        checked={getFieldValue("IsAddressPrimary")}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Address ID</label>
                      <Field name={nameOf("Id")} component={CCInput} readOnly />
                    </div>
                  </div>
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">Address</label>
                      <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">Property name</label>
                          <Field
                            name={nameOf("PropertyName")}
                            component={CCInput}
                          />
                        </div>
                        <div className="cc-form-cols-2">
                          <div className="cc-field">
                            <label className="cc-label">
                              Building unit number(s)
                            </label>
                            <div className="cc-custom-sub-panel-bar cc-form-cols-3">
                              <div className="cc-field">
                                <label className="cc-label">&nbsp;</label>
                                <Field
                                  name={nameOf("BuildingUnitNumber")}
                                  textField={nameOfLov("Name")}
                                  dataItemKey={nameOfLov("Code")}
                                  isUseDefaultOnchange
                                  component={CCSearchComboBox}
                                  data={[]}
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">&nbsp;</label>
                                <Field
                                  name={nameOf("UnitNumberFrom")}
                                  component={CCNumericTextBox}
                                  min={0}
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">to</label>
                                <Field
                                  name={nameOf("UnitNumberTo")}
                                  component={CCNumericTextBox}
                                  min={getFieldValue("UnitNumberFrom")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              Floor/Level number(s)
                            </label>
                            <div className="cc-custom-sub-panel-bar cc-form-cols-3">
                              <div className="cc-field">
                                <label className="cc-label">&nbsp;</label>
                                <Field
                                  name={"FloorLevelNumber"}
                                  textField={nameOfLov("Name")}
                                  dataItemKey={nameOfLov("Code")}
                                  isUseDefaultOnchange
                                  component={CCSearchComboBox}
                                  data={[]}
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">&nbsp;</label>
                                <Field
                                  name={nameOf("FloorNumberFrom")}
                                  component={CCNumericTextBox}
                                  min={0}
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">to</label>
                                <Field
                                  name={nameOf("FloorNumberTo")}
                                  component={CCNumericTextBox}
                                  min={getFieldValue("FloorNumberFrom")}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Building name</label>
                          <Field
                            name={nameOf("BuildingName")}
                            component={CCInput}
                          />
                        </div>
                        <label className="cc-label">
                          House number(s), street and locality
                        </label>
                        <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                          <div className="cc-field">
                            <Field
                              name={"RuralNumbering"}
                              component={Checkbox}
                              label={"Rural numbering"}
                              onChange={(event: CheckboxChangeEvent) => {
                                onChange("RuralNumbering", {
                                  value: event.value,
                                });
                                !event.value &&
                                  onChange("RuralNumberOutside", {
                                    value: event.value,
                                  });
                              }}
                            />
                          </div>
                          {!!valueGetter("RuralNumbering") && (
                            <div className="cc-form-cols-3">
                              <div className="cc-field">
                                <Field
                                  name={"RuralNumberOutside"}
                                  component={Checkbox}
                                  label={"Rural number is outside the property"}
                                />
                              </div>
                              {valueGetter("RuralNumberOutside") && (
                                <div className="cc-field cc-col-span-2">
                                  <Field
                                    name="Input"
                                    component={CCInput}
                                    value="3 kms from Start of Road (Outside the Property)"
                                  />
                                </div>
                              )}
                            </div>
                          )}
                          <div
                            className={
                              valueGetter("RuralNumberOutside")
                                ? "cc-form-cols-2"
                                : "cc-form-cols-3"
                            }
                          >
                            <div className="cc-field">
                              <label className="cc-label">&nbsp;</label>
                              <Field
                                name={nameOf("HouseFrom")}
                                component={CCNumericTextBox}
                                min={0}
                              />
                            </div>
                            {!valueGetter("RuralNumberOutside") && (
                              <div className="cc-field">
                                <label className="cc-label">to</label>
                                <Field
                                  name={nameOf("HouseTo")}
                                  component={CCNumericTextBox}
                                  min={getFieldValue("HouseFrom")}
                                />
                              </div>
                            )}
                            <div className="cc-field">
                              <label className="cc-label">&nbsp;</label>
                              <Field
                                name="dropdown"
                                textField={nameOfLov("Name")}
                                dataItemKey={nameOfLov("Code")}
                                isUseDefaultOnchange
                                component={CCSearchComboBox}
                                data={mockDataDropDown}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="cc-field">
                          <Field
                            name="input"
                            component={CCInput}
                            value={`${valueGetter("HouseFrom") ?? ""} ${
                              fullAddress ?? ""
                            }`}
                            readOnly
                          />
                        </div>
                        <div className="cc-form-cols-2">
                          <div className="cc-field">
                            <label className="cc-label">State</label>
                            <Field
                              name={"State"}
                              textField="Name"
                              dataItemKey="Code"
                              data={[]}
                              component={CCSearchComboBox}
                              value={getDropdownValue(
                                valueGetter("State"),
                                [],
                                "Code"
                              )}
                              onChange={(event: ComboBoxChangeEvent) => {
                                onChange("State", {
                                  value: event?.value?.Code ?? null,
                                });
                              }}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Country</label>
                            <Field
                              name={"Country"}
                              textField="Name"
                              dataItemKey="Code"
                              data={[]}
                              component={CCSearchComboBox}
                              value={getDropdownValue(
                                valueGetter("Country"),
                                [],
                                "Code"
                              )}
                              onChange={(event: ComboBoxChangeEvent) => {
                                onChange("Country", {
                                  value: event.value?.Code ?? null,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field cc-col-span-2">
                      <label className="cc-label">Location descriptor</label>
                      <Field
                        name={nameOf("LocationDescriptor")}
                        textField="LocationDescriptor"
                        dataItemKey="LocationDescriptor"
                        urlAPI=""
                        component={(event) => ComboboxSearchAPI<any>(event)}
                        keySearch={"LocationDescriptor"}
                        data={[]}
                        onChange={(event: IEventOnChangeComboBox) => {
                          formRenderProps.onChange("LocationDescriptor", {
                            value: event?.value?.LocationDescriptor,
                          });
                        }}
                        allowCustom
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Side of street</label>
                      <Field
                        name={nameOf("SideOfStreet")}
                        textField={nameOfLov("Name")}
                        dataItemKey={nameOfLov("Code")}
                        isUseDefaultOnchange
                        component={CCSearchComboBox}
                        data={[]}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">Map number</label>
                      <Field
                        name={nameOf("MapNumber")}
                        component={CCSearchComboBox}
                        data={[]}
                        allowCustom
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Map reference</label>
                      <Field
                        name={nameOf("MapReference")}
                        component={CCSearchComboBox}
                        data={[]}
                        allowCustom
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Postal delivery ID</label>
                      <Field name={nameOf("Address_PFI")} component={CCInput} />
                    </div>
                  </div>
                  <br />
                </section>
              </FormElement>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button
                  className="cc-dialog-button"
                  iconClass={isLoadingFinish ? "fas fa-spinner fa-spin" : ""}
                  themeColor="primary"
                  onClick={formRenderProps.onSubmit}
                  type="submit"
                  disabled={!formRenderProps.valid || !formRenderProps.modified}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
