export const CORE_MAILMERGE_DOCUMENTS_ODATA =
  "odata/core/internal/mailmergedocuments";
export const CORE_GET_VIEW_SITEUSER_ODATA =
  "odata/core/internal/contacts/GetViewSiteUser?$count=true&";
export const CORE_INSPECTION_TYPES_ODATA =
  "odata/core/internal/inspectiontypes?$count=true&";
export const CORE_STATEROADS_ODATA =
  "odata/core/internal/stateroads?$count=true&";
export const CORE_GET_ACTIONS_ODATA = "odata/core/internal/actions/getactions";
export const CORE_GET_ACTION_TYPES_ODATA =
  "odata/core/internal/actions/GetActionTypes";
export const CORE_GET_ORGUNIT_ODATA = "odata/core/internal/orgunit/GetOrgUnit";
export const CORE_GET_VIEW_SECURITY_TEMPLATES_ODATA =
  "odata/core/internal/securitytemplates/GetViewSecurityTemplates";
export const CORE_TRIM_RECORDTYPE_ODATA =
  "odata/core/internal/trimrecordtype/corporateadmin()?$count=true&";
export const CORE_GET_TRIM_CONTAINERS_ODATA =
  "odata/core/internal/corporateadmin/GetTrimContainers";
export const CORE_OFFICERS_ODATA = "odata/core/internal/officers?$count=true&";
export const CORE_GET_LOCAL_GOVERNMENTS_ODATA =
  "odata/core/internal/localgovernment/GetLocalGovernments?$count=true&";
export const CORE_GET_CORPORATE_AUTHOR_ODATA =
  "/odata/core/internal/corporateauthorisations/GetCorporateAuthorisations";
export const CORE_GET_HOMEPAGE_VIEWS_ODATA =
  "/odata/core/internal/homepageViews/GetHomepageViews";
export const CORE_GET_CONTACT_LIST_NAR_WITH_DETAIL_SEARCH =
  "/odata/core/internal/contacts/GetContactListNARWithDetailSearch";
export const CORE_GET_CONTACT_LIST_NAR_WITH_DEFAULT_SEARCH =
  "/odata/core/internal/contacts/GetContactListNAR";
export const CORE_GET_HOME_PAGE_SHORTCUTS =
  "odata/core/internal/homepageshortcuts/GetHomepageShortcuts?$count=true&";
export const CORE_GET_USER_BY_SHORTCUTS =
  "odata/core/internal/homepageuser/GetHomepageUserByShortcuts?$count=true&";
export const CORE_GET_HOMEPAGE_USER_BY_VIEWS_ODATA =
  "/odata/core/internal/homepageuser/GetHomepageUserByView";
