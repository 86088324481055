import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import SchemeAccountAttributesUDA from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/attributes-uda/_index";
import { Balances } from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/balances/_index";
import { SchemesFinancialSummary } from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/financial-summary/_index";
import { SchemeAccountForm } from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/general/_index";
import { SchemesAccountInstalments } from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/instalments/_index";
import SchemeAccountMemos from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/memos/_index";
import SchemeAccountNotices from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/notices/_index";
import { SchemeAccountTransactions } from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/transactions/_index";
import { SchemeAccountDetailTab } from "@app/products/property/schemes/[id]/account/[id]/components/reference-sidebar/detail/_index";
import { useSchemesAccountStore } from "@app/products/property/schemes/[id]/account/[id]/store";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedPropertySchemeAccount = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { id } = useParams<{ id: string }>();
  const { pushNotification } = useCCAppNotificationStore();
  const {
    isLoading,
    schemesAccount,
    schemesAccountId,
    responseLoadError,
    loadSchemesAccount,
  } = useSchemesAccountStore();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        schemesAccount?.AccountNumber,
        schemesAccount?.NameAndAddress,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Property - Schemes - Account - ${schemesAccountId}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
      Record_ID: schemesAccountId,
    },
  ];

  const listPanelBar: IPropertyPanelBar[] = [
    {
      title: "Attributes",
      component: <SchemeAccountAttributesUDA />,
    },
    {
      title: "Memos",
      component: <SchemeAccountMemos />,
    },
    { title: "Notices", component: <SchemeAccountNotices /> },
    {
      title: "Instalments",
      component: <SchemesAccountInstalments />,
    },
    {
      title: "Balances",
      component: <Balances />,
    },
    {
      title: "Financial Summary",
      component: <SchemesFinancialSummary />,
    },
    {
      title: "Transactions",
      component: <SchemeAccountTransactions />,
    },
  ];

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <SchemeAccountDetailTab /> },
    ],
  });

  useEffect(() => {
    if (schemesAccount && schemesAccount?.AlertMessage) {
      pushNotification({
        title: schemesAccount?.AlertMessage,
        type: "warning",
        autoClose: false,
      });
    }
  }, [schemesAccount, pushNotification]);

  const getTitle = (): string => {
    const accountNumber = schemesAccount?.AccountNumber;
    const propertyAddress = schemesAccount?.PropertyAddress;

    if (accountNumber && propertyAddress) {
      return `${accountNumber}, ${propertyAddress}`;
    }

    return accountNumber || propertyAddress || "N/A";
  };

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Scheme Account" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadSchemesAccount(+id)}
        />
      ) : (
        <>
          <FormTitle title={getTitle()} badge={schemesAccount?.AccountStatus} />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Calculate instalment"} />
                <CCNavButton title={"Modify scheme account"} />
              </CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,

              <CCNavButton title={"Add"} />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {schemesAccount && (
                  <CCGeneralPanel component={<SchemeAccountForm />} />
                )}
                <PropertyPanelBar initialPanels={listPanelBar} />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
