import { DTO_Assessment } from "@app/products/property/assessments/components/form-steps/associate-assessment/model";
import { DateCustomCell } from "@app/products/property/components/grid/components/cc-date-range-limit/_index";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields, ICustomEditCell } from "@components/cc-grid/model";
import React from "react";

const nameOfAssociate = nameOfFactory<DTO_Assessment>();

const propsDateCustomCell = {
  listFieldName: {
    fromField: nameOfAssociate("ActiveFrom"),
    toField: nameOfAssociate("ActiveTo"),
  },
};

export const colAssessmentAssociate: IColumnFields[] = [
  {
    field: nameOfAssociate("AssessmentNumber"),
    title: "Assessment Number",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOfAssociate("Ratepayer_Name"),
    title: "Ratepayer",
  },
  {
    field: nameOfAssociate("IsPrimaryAssessment"),
    title: "Is Primary Assessment",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfAssociate("ActiveFrom"),
    title: "Active From",
    format: DATE_FORMAT.DATE,
    editCell: (props: ICustomEditCell) => (
      <DateCustomCell {...props} extraConditions={propsDateCustomCell} />
    ),
    editable: true,
    width: 200,
  },
  {
    field: nameOfAssociate("ActiveTo"),
    title: "Active To",
    format: DATE_FORMAT.DATE,
    editCell: (props: ICustomEditCell) => (
      <DateCustomCell {...props} extraConditions={propsDateCustomCell} />
    ),
    editable: true,
    width: 200,
  },
  {
    field: nameOfAssociate("Assess_Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOfAssociate("Legal_Description"),
    title: "Legal Description",
  },
  {
    field: nameOfAssociate("Assessment_Group_Name"),
    title: "Assessment Group",
  },
  {
    field: nameOfAssociate("DifferentialCategory"),
    title: "Differential Category",
  },
  {
    field: nameOfAssociate("Assessment_Id"),
    title: "Assessment Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colUnaffectedAssessment: IColumnFields[] = [
  {
    field: nameOfAssociate("AssessmentNumber"),
    title: "Assessment Number",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOfAssociate("Ratepayer_Name"),
    title: "Ratepayer",
  },
  {
    field: nameOfAssociate("Assess_Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOfAssociate("Legal_Description"),
    title: "Legal Description",
  },
  {
    field: nameOfAssociate("Assessment_Group_Name"),
    title: "Assessment Group",
  },
  {
    field: nameOfAssociate("DifferentialCategory"),
    title: "Differential Category",
  },
  {
    field: nameOfAssociate("Assessment_Id"),
    title: "Assessment Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
