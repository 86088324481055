import { eVisibility } from "@app/products/property/model";
import { DTO_LOV, DTO_LOV_Number } from "@common/models/odataResponse";
import { eComponent } from "@app/products/property/components/associations/model";

export interface DTO_Certificate_Summary {
  CertificateId: number;
  Certificate: DTO_Certificate;
  AreaOfResponsibility: DTO_AreaOfResponsibility[];
  AdverseAffectionsVisibility: eVisibility;
}

export interface DTO_Certificate {
  Cert_Required_By_DateTime: Date | null;
  Cert_Receipt_Number: number | null;
  Cert_Receipt_Amount: number;
  Cert_Receipt_Item_Id: number | null;
  Elapsed_Days: number | null;
  RatingPeriodId: number | null;
  Cert_Is_Urgent: boolean;
  Expected_Completion_Days: number | null;
  Certificate_Status_Name: string;
  Certificate_Request_Source_Id: number | null;
  AssessmentId: number | null;
  Assess_Number: string;
  Assess_Reference: string;
  Assess_Property_Addresses: string;
  Parcel_Id: number | null;
  Parcel_Property_Addresses: string;
  Certificate_Type_Component: eComponent;
  Proposed_Settlement_Date: Date | null;
  PIC_Id: number | null;
  Holding_Property_Addresses: string;
  Certificate_Status_Id: number | null;
  Cert_Issued_DateTime: Date | null;
  Cert_Issued_By: string;
  Cert_Balance_Owing: number | null;
  CertificateId: number;
  Cert_Number: number | null;
  Certificate_Type: number;
  Certificate_Type_Name: string;
  Cert_Originated_DateTime: Date;
  Cert_Applicant_Id: number | null;
  Cert_Applicant_Name: string;
  Cert_Applicant_Reference: string;
  Cert_Originated_By: string;
  Cert_Applicant_Locality: string;
  Cert_Applicant_Locality_Id: number | null;
  Cert_Applicant_Postcode: string;
  Cert_Applicant_Country_Id: number | null;
  Cert_Applicant_Country_Name: string;
  Cert_Applicant_Fax: string;
  Cert_Applicant_Address: string;
  Cert_Applicant_Email_Address: string;
  Cert_Applicant_SAP_Number: string;
  Cert_Description: string;
  Cert_Comment: string;
  Cert_Applicant_State: string;

  // Extend property use in UX
  Expected_Completion?: Date | null;
}

export interface DTO_AreaOfResponsibility {
  AreaOfResponsibility_Id: number;
  Name: string;
  Completion_DateTime: Date | null;
  Completion_UserId: string;
  Comments: string;
  Elapsed_Days: number | null;
  Completed: boolean;
}

export interface DTO_LOV_UDAL {
  Code: string;
  Name: string;
  UDAL_Text: string;
  UDA_Attribute_Id: number;
}
export interface DTO_Certificate_LOVs {
  CertificateType: DTO_LOV_Number[];
  RequestSource: DTO_LOV_Number[];
  UDAL: DTO_LOV_UDAL[];
  RatingPeriods: DTO_LOV[];
  Applicants: DTO_LOV[];
  States: DTO_LOV[];
  Countries: DTO_LOV[];
  Localities: DTO_LOV[];
  UDAs: DTO_LOV[];
  ReasonForCreate: DTO_LOV[];
  Officers: DTO_LOV[];
  MailMergeDocumentTypes: DTO_LOV[];
}

export enum CertificateStatus {
  BeingCompiled = 0,
  InError = 1,
  DataCaptureComplete = 2,
  CertificateIssued = 3,
  BeingEditedAfterCompletion = 4,
  Cancelled = 9,
}
