import { getFromDate } from "@app/products/ams/api/helpers/date-helper";
import { DTO_Title } from "@app/products/property/assessments/components/form-steps/modify-assessment/components/form-elements/associations-parcel/model";
import { DTO_Address } from "@app/products/property/assessments/components/form-steps/modify-assessment/model";
import { isBefore } from "date-fns";
import { isNil } from "lodash";

export const checkNumberOnlyValidator = (value: any) => {
  if (isNil(value) || value === "") return;
  const message = "This field only accepts numerical values";
  const regexOnlyNumber = /^\d+$/;

  if (!regexOnlyNumber.test(value)) return message;
  return;
};

export const validatorPhysicalAddress = (
  value: any,
  isLLS: boolean
): string | undefined => {
  if (!value?.length) {
    return `At least one address must be set as primary${
      isLLS ? "and property name is required" : ""
    }`;
  }
  const isContainPrimary = value?.some((item: DTO_Address) => {
    if (isLLS) return item?.IsAddressPrimary && item?.PropertyName;
    return item?.IsAddressPrimary;
  });
  if (!isContainPrimary) {
    return `At least one address must be set as primary${
      isLLS ? "and property name is required" : ""
    }`;
  }
  return undefined;
};

export const lotsValidator = (value: any): string | undefined => {
  if (value?.AssociateTitles?.length) {
    let isError = false;
    isError = value?.AssociateTitles?.some((item: DTO_Title) => {
      return (
        !isNil(item?.Active_From) &&
        !isNil(item?.Active_To) &&
        isBefore(getFromDate(item.Active_To), getFromDate(item.Active_From))
      );
    });
    if (isError) {
      return `"Active To" must be greater than or equal to "Active From"`;
    }
    return undefined;
  }
};

export const ASSESSMENT_ROUTE = "/property/assessments";
