import {
  FormAction,
  FormIdentifier,
  ViewActionButton,
} from "@app/products/crms/model";
import { SettingsMap } from "@app/products/property/system-admin/settings/model";
import { convertSettings } from "@app/products/property/system-admin/settings/util";
import { getActionButtonPermission } from "@common/apis/core-common-apis";
import { APIResponseError } from "@common/apis/model";
import { getSettings } from "@common/apis/setting";
import { isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { hasPermissionButton } from "@common/hocs/permissions/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class CommonCoreStore {
  private _settings: SettingsMap = {}; //Product settings
  private _isLoadingSetting?: boolean = undefined; //Products settings loading
  private _responseLoadSettingError?: APIResponseError = undefined;
  private _isLoadingButtonPermission: boolean = false; // Action Button Permission loading
  private _securityViewActionButton?: ViewActionButton[] = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get settings() {
    return toJS(this._settings);
  }
  setSettings = (settings: SettingsMap) => {
    runInAction(() => {
      this._settings = settings;
    });
  };

  get isLoadingSetting() {
    return this._isLoadingSetting;
  }
  setIsLoadingSetting = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoadingSetting = isLoading;
    });
  };

  get isLoadingButtonPermission() {
    return this._isLoadingButtonPermission;
  }
  setIsLoadingButtonPermission = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoadingButtonPermission = isLoading;
    });
  };

  get securityViewActionButton() {
    return this._securityViewActionButton;
  }
  setSecurityViewActionButton = (
    securityViewActionButton?: ViewActionButton[]
  ) => {
    runInAction(() => {
      this._securityViewActionButton = securityViewActionButton;
    });
  };

  get responseLoadSettingError() {
    return toJS(this._responseLoadSettingError);
  }
  setResponseLoadSettingError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadSettingError = responseLoadError;
    });
  };

  get siteBaseUrl() {
    let url = "";
    if (!isNil(this.settings)) {
      url =
        this.settings?.[ECorporateSettingsField.CorporateSettings_SiteBaseURL]
          ?.Value;
    }
    return toJS(url);
  }

  get systemLabel() {
    let label = "";
    const dataLabel =
      this.settings?.[ECorporateSettingsField.CorporateSettings_SystemLabel];
    if (dataLabel?.IsVisible && !isNil(dataLabel?.Value)) {
      label = dataLabel?.Value;
    }
    return toJS(label);
  }

  get fileSize() {
    const dataSize =
      this.settings?.[
        ECorporateSettingsField.Global_AttachmentSizeRestrictionSize
      ];
    const values = ["", "0", "NULL"];
    if (
      !dataSize?.IsVisible ||
      isNil(dataSize?.Value) ||
      values.includes(dataSize?.Value as string)
    ) {
      return 26214400;
    }
    return toJS(+dataSize?.Value);
  }

  get headerBarLogo() {
    let logo;
    const dataLogo =
      this.settings?.[ECorporateSettingsField.CorporateSettings_HeaderBarLogo];
    if (dataLogo?.IsVisible && !isNil(dataLogo?.Value)) {
      logo = dataLogo?.Value;
    }
    return toJS(logo);
  }

  get homePageLogo1() {
    let logo;
    const dataLogo =
      this.settings?.[ECorporateSettingsField.CorporateSettings_HomePageLogo];
    if (dataLogo?.IsVisible && !isNil(dataLogo?.Value)) {
      logo = dataLogo?.Value;
    }
    return toJS(logo);
  }

  get homePageLogo2() {
    let logo;
    const dataLogo =
      this.settings?.[ECorporateSettingsField.CorporateSettings_HomePageLogo2];
    if (dataLogo?.IsVisible && !isNil(dataLogo?.Value)) {
      logo = dataLogo?.Value;
    }
    return toJS(logo);
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._settings = {};
      this._isLoadingSetting = undefined;
      this._responseLoadSettingError = undefined;
    });
  };

  resetPermissionStore = () => {
    runInAction(() => {
      this._isLoadingButtonPermission = false;
      this._securityViewActionButton = undefined;
    });
  };

  loadSettings = async (settingEnums: ECorporateSettingsField[]) => {
    this.setIsLoadingSetting(true);
    const response = await getSettings(settingEnums);
    if (isSuccessResponse(response)) {
      this.setSettings(convertSettings(response.data ?? []));
      this.setResponseLoadSettingError(undefined);
    } else {
      this.setSettings({});
      this.setResponseLoadSettingError({
        status: response.status,
        error: response.error,
      });
    }
    this.setIsLoadingSetting(false);
  };

  loadActionButtonPermission = async (
    formIdentifier: FormIdentifier,
    productType: PRODUCT_TYPE_NUMBER
  ) => {
    this.setIsLoadingButtonPermission(true);
    let viewActionButton: ViewActionButton[] | undefined;
    const response = await getActionButtonPermission(
      formIdentifier,
      productType
    );
    this.setIsLoadingButtonPermission(false);
    if (isSuccessResponse(response) && response?.data) {
      this.setSecurityViewActionButton(response.data);
      viewActionButton = response.data;
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Can not load action button permission.",
        type: "error",
        description: response.error,
      });
    }
    return viewActionButton;
  };

  isVisibleActionButton = (formAction?: FormAction) => {
    return hasPermissionButton(this.securityViewActionButton, formAction);
  };
}
export const commonCoreStore = new CommonCoreStore();
const commonCoreStoreContext = createContext(commonCoreStore);
export const useCommonCoreStore = () => {
  return useContext(commonCoreStoreContext);
};
