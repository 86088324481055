import { fnt_Parcel_w_Association_FactorResult } from "@app/products/property/assessments/[id]/components/child-screens/parcels/model";
import { PROPERTY_PARCEL_ROUTE } from "@app/products/property/parcels/[id]/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { isNil } from "lodash";

const nameOf = nameOfFactory<fnt_Parcel_w_Association_FactorResult>();
export const colParcels: IColumnFields[] = [
  {
    field: nameOf("Parcel_Id"),
    title: "Parcels ID",
    linkTo: (dataItem: fnt_Parcel_w_Association_FactorResult) => {
      if (!isNil(dataItem?.Parcel_Id)) {
        return `${PROPERTY_PARCEL_ROUTE}/${dataItem[nameOf("Parcel_Id")]}`;
      }
      return undefined;
    },
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Parcel_Reference"),
    title: "Parcels Reference",
  },
  {
    field: nameOf("Addr_Property_Address"),
    title: "Address",
  },
  {
    field: nameOf("Locality_Name"),
    title: "Locality",
  },
  {
    field: nameOf("Parcel_Legal_Description"),
    title: "Legal Description",
  },
  {
    field: nameOf("Parcel_Land_AreaX"),
    title: "Land Area",
  },
  {
    field: nameOf("Parcel_Owners_NamesX"),
    title: "Owners",
  },
  {
    field: nameOf("Parcel_Land_UsesX"),
    title: "Land Uses",
  },
  {
    field: nameOf("Parcel_ZonesX"),
    title: "Zone",
  },
  {
    field: nameOf("Electoral_Division_Name"),
    title: "Electoral Division",
  },
  {
    field: nameOf("Parcel_Status_Name"),
    title: "Status",
  },
];
