import { IconCell } from "@app/products/crms/config";
import { BOOLEAN_FORMAT } from "@common/constants/common-format";
import { DYNAMIC_LISTS_ROUTE } from "@common/pages/settings/lookups/dynamic-lists/constant";
import { Svc_DynamicQuestionView } from "@common/pages/settings/lookups/dynamic-lists/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOf = nameOfFactory<Svc_DynamicQuestionView>();

const StatusIcon = ({
  dataItem,
  propName,
}: {
  dataItem: Svc_DynamicQuestionView;
  propName: keyof Svc_DynamicQuestionView;
}) => {
  return dataItem?.[propName] ? (
    <i
      className="far fa-check"
      style={{
        color: "var(--success)",
      }}
    />
  ) : (
    <i
      className="far fa-times"
      style={{
        color: "var(--error)",
      }}
    />
  );
};

export const dynamicListGrigColumn: IColumnFields[] = [
  {
    field: nameOf("Question"),
    title: "Question List Name",
    linkTo: (dataItem: Svc_DynamicQuestionView) => {
      return `${DYNAMIC_LISTS_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: nameOf("ChecklistName"),
    title: "Type",
  },
  {
    field: nameOf("Isactive"),
    title: "Status",
    format: BOOLEAN_FORMAT.BOOLEAN,
    cell: (props: GridCellProps) => {
      return (
        <IconCell props={props}>
          <StatusIcon dataItem={props.dataItem} propName={nameOf("Isactive")} />
        </IconCell>
      );
    },
  },
  // TODO: API not return this column so implement it later.
  {
    field: "SortIndex",
    title: "Sort",
  },
];
