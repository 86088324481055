import { DTO_Name_Address } from "@app/products/property/contacts-central-names/[id]/components/child-screens/associated-contacts/model";
import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { DTO_TitleEntity } from "@app/products/property/titles/[id]/components/child-screens/title-contacts/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<DTO_TitleEntity>();

export const colTitleContacts: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    linkTo: (dataItem: DTO_Name_Address) =>
      `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${dataItem?.Id}`,
  },
  {
    field: nameOf("Role"),
    title: "Role",
  },
  {
    field: nameOf("Address_Formatted"),
    title: "Address",
  },
  {
    field: nameOf("Gender"),
    title: "Gender",
  },
  {
    field: nameOf("IsActive"),
    title: "Is Active",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("ChangeOfOwnership_Reference"),
    title: "Change Reference",
  },
  {
    field: nameOf("ChangeOfOwnership_EffectiveDate"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ChangeOfOwnership_Id"),
    title: "Change of Ownership ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Contact_Name_Address_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
