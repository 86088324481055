import { newOwnerCols } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/components/form-elements/names/config";
import {
  DTO_Entity_Details,
  EKeysOfSteps,
} from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOfNames = nameOfFactory<DTO_Entity_Details>();

export const NamesFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(({ formRenderProps }: IFormStepElement) => {
  const { valueGetter } = formRenderProps;
  const newOwnerGridData = valueGetter(EKeysOfSteps.NewOwnerGridData) || [];

  return (
    <div className="cc-field">
      <CCLabel title="New owners" />
      <CCGrid
        data={newOwnerGridData}
        columnFields={newOwnerCols}
        primaryField={nameOfNames("Entity_Id")}
      />
    </div>
  );
});
