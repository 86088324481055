import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.Id ? `- ${selectedRow.Id}` : "";
  return `Property - Assessments ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.Id,
    selectedRow.Property_Address,
    selectedRow.Property_Locality,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return "Property - Assessments";
};

export const getBookmarkListViewDetail = () => {
  return "Property - Assessments - Master Properties";
};
