import { colSupplementary } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/config";
import {
  GET_EXISTING_SUPPLEMENTARY_RATES_LIST,
  GET_EXISTING_SUPPLEMENTARY_RATES_LIST_CREATED_BY_ME,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/constant";
import {
  SupplementaryMode,
  VO_Supplementary,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import {
  dataJournalStatus,
  dataWhichSupplementary,
} from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/config";
import { eJournalType } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/model";
import { useNewJournalStepStore } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/store";
import { isHideBaseOnJournalType } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/util";
import {
  JournalKeysOfSteps,
  TransactionAllocationMode,
} from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { JournalStatus } from "@app/products/property/journals/model";
import { DTO_LOV } from "@common/models/odataResponse";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  RadioGroup,
  RadioGroupChangeEvent,
} from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import "./_index.scss";

const nameOfVOSupplementary = nameOfFactory<VO_Supplementary>();

const nameOfLovs = nameOfFactory<DTO_LOV>();
const formValidator = (values: any) => {
  if (
    checkSelectExistSupp(values?.SupplementaryMode) &&
    !values?._options?.SupplementarySelected?.length
  ) {
    return "Must select one supplementary.";
  }
  return;
};

const checkSelectExistSupp = (suppMode?: SupplementaryMode) => {
  if (!suppMode) return false;
  return (
    suppMode === SupplementaryMode.SelectFromList ||
    suppMode === SupplementaryMode.SelectFromMyList
  );
};

export const NewJournalFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? formValidator : undefined}
    />
  );
};

const FormStepElement = observer(
  ({
    setStepsVisible,
    resetAllStepsAfter,
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      showStatusField: true,
      showWhichSupplementary: false,
      dataJournalStatus: false,
      supplementaryStepKey: JournalKeysOfSteps.NewSupplementary,
      isDisableJournalType: false,
      isMandatoryJournalType: false,
      officerNameField: "Authorising_Officer_Id",
      accountStepKey: JournalKeysOfSteps.AccountTransaction,
      isFromDeferredDuty: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const { newJournalStepLOVs } = useNewJournalStepStore();
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const officerIdFieldName = nameOf(
      options.officerNameField ?? "Authorising_Officer_Id"
    );
    const isShowExistingSupp = checkSelectExistSupp(
      getFieldValue("SupplementaryMode")
    );
    const transactionAllocationMode = valueGetter(
      valueGetter(`${JournalKeysOfSteps.AssessmentTransaction}?.AllocationMode`)
    );
    const journalType = getFieldValue("Journal_Type");

    const isHiddenConditionJournalType = useMemo(() => {
      return isHideBaseOnJournalType(journalType);
    }, [journalType]);

    const handleSupplementaryModeChange = (e: RadioGroupChangeEvent) => {
      let newSteps = resetAllStepsAfter();
      onChange(nameOf("SupplementaryMode"), {
        value: e.value,
      });
      //Reset supplementary selected
      onChange(nameOf("_options.SupplementarySelected"), {
        value: null,
      });
      onChange(nameOf("Supplementary_Id"), {
        value: null,
      });

      if (setStepsVisible) {
        setStepsVisible(
          [
            {
              key: options?.supplementaryStepKey,
              visible: e.value === SupplementaryMode.CreateNew,
              isClearData: true,
            },
          ],
          newSteps
        );
      }
    };

    const handleJournalTypeChange = (event: ComboBoxChangeEvent) => {
      let newSteps = resetAllStepsAfter();
      onChange(nameOf("Journal_Type"), {
        value: event.value?.Code ?? null,
      });
      onChange(nameOf("Journal_Type_Classification"), {
        value: event.value?.Classifications,
      });
      onChange(nameOf("SupplementaryMode"), {
        value: SupplementaryMode.None,
      });
      const isDeferredDutyJournalType =
        event.value?.Code ===
        (eJournalType["Deferred Duty Account Journal"] as number);
      const isPICJournalType =
        event.value?.Code === (eJournalType["PIC Journal"] as number);
      const isHiddenStep = isHideBaseOnJournalType(event.value?.Code);
      if (setStepsVisible) {
        setStepsVisible(
          [
            {
              key: JournalKeysOfSteps.AssessmentTransaction,
              visible: !isHiddenStep,
              isClearData: true,
            },
            {
              key: JournalKeysOfSteps.PIC,
              visible: isPICJournalType,
              isClearData: true,
            },
            {
              key: options.accountStepKey,
              visible: isDeferredDutyJournalType,
              isClearData: true,
            },
            {
              key: JournalKeysOfSteps.NewChargeDetail,
              visible: !isHiddenStep,
              isClearData: true,
            },
            {
              key: JournalKeysOfSteps.AllocationChargeBalances,
              visible:
                !isHiddenStep &&
                transactionAllocationMode === TransactionAllocationMode.Manual,
              isClearData: true,
            },
            {
              key: JournalKeysOfSteps.NewChargeDetail,
              visible:
                !isHiddenStep &&
                transactionAllocationMode ===
                  TransactionAllocationMode.NewCharge,
              isClearData: true,
            },
            {
              key: JournalKeysOfSteps.NewSupplementary,
              visible: false,
              isClearData: true,
            },
          ],
          newSteps
        );
      }
    };

    return (
      <section className="cc-field-group">
        {!options?.isAdjustChargeBalances && (
          <div className="cc-field">
            <label className="cc-label">
              Journal type
              {options?.isMandatoryJournalType && (
                <CCTooltip type="validator" position="right" />
              )}
            </label>
            <Field
              name={nameOf("Journal_Type")}
              component={CCSearchComboBox}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              textField={nameOfLovs("Name")}
              dataItemKey={nameOfLovs("Code")}
              data={newJournalStepLOVs?.JournalTypes ?? []}
              disabled={
                options?.isReadOnly ||
                options?.isDisableJournalType ||
                options?.isFromDeferredDuty
              }
              value={getDropdownValue(
                getFieldValue("Journal_Type"),
                newJournalStepLOVs?.JournalTypes ?? [],
                "Code"
              )}
              onChange={handleJournalTypeChange}
            />
          </div>
        )}
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Reference</label>
            <Field
              name={nameOf("Jnl_Reference")}
              component={CCInput}
              readOnly={options?.isReadOnly}
              placeholder="Reference"
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Description</label>
            <Field
              name={nameOf("Jnl_Description")}
              placeholder="Description"
              readOnly={options?.isReadOnly}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Narration</label>
            <Field
              name={nameOf("Jnl_Notes")}
              placeholder="Narration"
              rows={4}
              readOnly={options?.isReadOnly}
              component={CCTextArea}
            />
          </div>
          {((options?.showWhichSupplementary &&
            !isHiddenConditionJournalType) ||
            options?.isAdjustChargeBalances) && (
            <>
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <label className="cc-label">Supplementary</label>
                  <Field
                    name={nameOf("SupplementaryMode")}
                    data={dataWhichSupplementary}
                    component={RadioGroup}
                    value={getFieldValue("SupplementaryMode")}
                    onChange={handleSupplementaryModeChange}
                    disabled={options?.isReadOnly}
                  />
                </div>
              </div>
              <div className="cc-form-cols-1">
                {isShowExistingSupp ? (
                  <div className="cc-field cc-supplementary-error">
                    {errors?.[nameOf("")] ? (
                      <Error>{errors[nameOf("")]}</Error>
                    ) : null}
                    <CCGrid
                      dataUrl={
                        getFieldValue("SupplementaryMode") ===
                        SupplementaryMode.SelectFromList
                          ? GET_EXISTING_SUPPLEMENTARY_RATES_LIST
                          : GET_EXISTING_SUPPLEMENTARY_RATES_LIST_CREATED_BY_ME
                      }
                      selectedRows={getFieldValue(
                        "_options.SupplementarySelected"
                      )}
                      primaryField={nameOfVOSupplementary("Supplementary_Id")}
                      columnFields={colSupplementary}
                      selectableMode={"single"}
                      readOnly={options?.isReadOnly}
                      onSelectionChange={(dataItem: any[]) => {
                        onChange(nameOf("_options.SupplementarySelected"), {
                          value: dataItem,
                        });
                        onChange(nameOf("Supplementary_Id"), {
                          value: dataItem?.[0]?.Supplementary_Id ?? null,
                        });
                      }}
                      state={{
                        sort: [
                          {
                            field: nameOfVOSupplementary("Supplementary_Id"),
                            dir: "desc",
                          },
                        ],
                      }}
                    />
                  </div>
                ) : null}
                {options?.showStatusField ? (
                  <div className="cc-field">
                    <CCLabel title="Status for journal" isMandatory />
                    <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
                      <Field
                        name={nameOf("Journal_Status")}
                        data={dataJournalStatus}
                        component={RadioGroup}
                        disabled={options?.isReadOnly}
                        validator={
                          !options?.isReadOnly ? requiredValidator : undefined
                        }
                      />
                      {JournalStatus.Authorised ===
                      getFieldValue("Journal_Status") ? (
                        <div className="cc-field">
                          <label className="cc-label">Authorised by</label>
                          <Field
                            name={officerIdFieldName}
                            textField="Name"
                            dataItemKey="Code"
                            data={newJournalStepLOVs?.Officer ?? []}
                            component={CCSearchComboBox}
                            isUseDefaultOnchange
                            disabled={options?.isReadOnly}
                          />
                        </div>
                      ) : null}
                      <br />
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          )}
        </div>
      </section>
    );
  }
);
