import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { postProcessNewBulkLevyUpdate } from "@app/products/property/assessments/list/components/form-steps/bulk-levy-update/api";
import { LevyDetailsFormStep } from "@app/products/property/assessments/list/components/form-steps/bulk-levy-update/components/form-elements/levy-details/_index";
import {
  EKeysOfStepsBulkLevyUpdate,
  keysOfStepsBulkLevyUpdate,
} from "@app/products/property/assessments/list/components/form-steps/bulk-levy-update/model";
import { NewSupplementaryFormStep } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/_index";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WhichSupplementaryFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/_index";
import { SupplementaryMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import { INewProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { PROPERTY_SUPPLEMENTARY_RATES_ROUTE } from "@app/products/property/supplementary-rates/[id]/constant";
import { isShowParkButton } from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCommonProductStore } from "@common/stores/products/store";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { isNil, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IBulkLevyUpdateDialog {
  onClose: () => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
  isToBeApprovalMode?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  isReadOnly?: boolean;
  isShowCancelWorkflowButton?: boolean;
  statusBadge?: IBadgeDialog[];
  isRedirectManagePage?: boolean;
}
export const BulkLevyUpdateDialog = observer(
  ({
    onClose,
    isSaveOnNextStep = false,
    isIncompleteMode = false,
    isFromActionList = false,
    isToBeApprovalMode = false,
    suffixTitle,
    prefixTitle,
    dataFromActionList,
    isReadOnly = false,
    statusBadge,
    isShowCancelWorkflowButton = false,
    isRedirectManagePage = true,
  }: IBulkLevyUpdateDialog) => {
    //#region <Store>
    //TODO: Waiting api
    // const { setCreateSupplementaryLOVs } = useNewSupplementaryDialogStore();
    const { currentFormTitle } = useCommonProductStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    //#endregion

    //region <State>
    const history = useHistory();
    const localNotificationRef = useRef<ICCLocalNotificationHandle | null>(
      null
    );
    //TODO: waiting api
    const [workflowInitData, setWorkflowInitData] = useState<any>(false);
    const [isLoadingProcess, setIsLoadingProcess] = useState<
      WorkflowProcessMode | undefined
    >();
    const formStepRef = useRef<ICCFormStepNotificationHandle | null>(null);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    //#endregion

    //#region <Title header>
    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "Bulk Levy Update";
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suffixTitle, prefixTitle, workflowHeader]);
    //#endregion

    //#region <Initial value>
    const initialValue = useMemo((): any => {
      //TODO: waiting api
      formStepRef?.current?.setStepsVisible([
        {
          key: EKeysOfStepsBulkLevyUpdate.NewSupplementary,
          visible: workflowInitData, //TODO: waiting api
          isClearData: true,
        },
      ]);
      const initSupplementary: any = {
        _options: {
          WhichSupplementary: SupplementaryMode.CreateNew,
          //TODO: waiting api
          // : SelectFromMyList
          // ? SupplementaryMode.SelectFromMyList
          // : SupplementaryMode.SelectFromList,
        },
        Supplementary_Id: 0,
        NewSupplementary: workflowInitData ?? false,
        SelectFromMyList: false,
        SupplementarySelected: [],
      };
      return {
        [EKeysOfStepsBulkLevyUpdate.LevyDetails]: {},
        [EKeysOfStepsBulkLevyUpdate.Supplementary]: initSupplementary,
        [EKeysOfStepsBulkLevyUpdate.NewSupplementary]: {},
        [EKeysOfStepsBulkLevyUpdate.Documents]: {},
        [EKeysOfStepsBulkLevyUpdate.Comments]: {},
      };
    }, [workflowInitData]);
    //#endregion

    //#region <Steps>
    const steps: IStep[] = [
      {
        label: "Levy Details",
        component: LevyDetailsFormStep,
        visible: true,
        key: EKeysOfStepsBulkLevyUpdate.LevyDetails,
        options: {
          isReadOnly,
        },
        initialValues: initialValue[EKeysOfStepsBulkLevyUpdate.LevyDetails],
      },
      {
        label: "Supplementary",
        component: WhichSupplementaryFormStep,
        initialValues: initialValue[EKeysOfStepsBulkLevyUpdate.Supplementary],
        visible: true,
        options: {
          isReadOnly,
        },
        key: EKeysOfStepsBulkLevyUpdate.Supplementary,
      },
      {
        label: "New supplementary",
        component: NewSupplementaryFormStep,
        visible: false, //TODO: waiting api
        key: EKeysOfStepsBulkLevyUpdate.NewSupplementary,
        options: {
          isReadOnly,
        },
        initialValues:
          initialValue[EKeysOfStepsBulkLevyUpdate.NewSupplementary],
      },
      {
        label: "Comments",
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfStepsBulkLevyUpdate.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Assessment_Levy_Id,
        },
        initialValues: initialValue[EKeysOfStepsBulkLevyUpdate.Comments],
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        visible: true,
        key: EKeysOfStepsBulkLevyUpdate.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Default, //TODO: waiting API
        },
        initialValues: initialValue[EKeysOfStepsBulkLevyUpdate.Documents],
      },
    ];
    //endregion

    //#region <handleSubmit>
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          handleConfirmFinish(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleParkProcess(
            processData(data),
            EListSubmitButton.ConfirmCloseYes
          );
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
      }
    };
    //#endregion

    //#region <Process data>
    const processData: any = (data: any) => {
      let workflowDetail: any = {};
      const newData = { ...data };
      const sendSteps = pickBy(newData, (value, key) => {
        if (
          keysOfStepsBulkLevyUpdate.includes(key as EKeysOfStepsBulkLevyUpdate)
        ) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        const dataStep = { ...value };
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }
        workflowDetail[key] = dataStep;
      }

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };
    //#endregion

    //#region <Handle process workflow>
    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (props: INewProcessWorkflow<any>) => {
      const {
        actionSuccess,
        defaultFailedMessage,
        setLoading,
        modeProcess,
        payload,
      } = props;
      const response = await postProcessNewBulkLevyUpdate(modeProcess, payload);
      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        } else {
          if (props?.actionFail) props?.actionFail(response);
          formStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        if (props?.actionFail) props?.actionFail(response);
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      if (props?.actionClose) props.actionClose();
    };
    //#endregion

    //#region <Handle park process>
    /**
     * @param payload
     * @param mode
     */
    const handleParkProcess = async (
      payload: any,
      mode?: EListSubmitButton
    ) => {
      //set loading button and dialog
      setIsLoadingProcess(WorkflowProcessMode.Park);
      mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
      const actionCloseRetainDialog = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog();
      };
      //props send to process workflow
      const parkProps: INewProcessWorkflow<any> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.SuccessMessage ??
              e?.Notification ??
              "Bulk levy update was parked successfully.",
            type: "success",
          });
          isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        actionClose: () => {
          mode === EListSubmitButton.ConfirmCloseYes &&
            actionCloseRetainDialog();
        },
        defaultFailedMessage: "Bulk levy update could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps);
    };
    //#endregion

    //#region <Handle cancel button>
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessNewBulkLevyUpdate,
          dataCancel: data,
          defaultSuccessMessage: "Bulk levy update was cancelled successfully.",
          defaultErrorMessage: "Bulk levy update could not be cancelled.",
        });
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <Handle next button>
    /**
     * @param data
     * @param step
     * @param keyStep
     * @returns
     */
    const handleNextButton = async (data: any) => {
      const newData = { ...data };
      const processPayload = processData(newData);
      //send data to call api save
      return handleSaveAndNext(processPayload, newData);
    };
    //#endregion

    //#region <Handle save and next>
    const handleSaveAndNext = async (
      payload: any,
      data: any
    ): Promise<boolean> => {
      //check condition use for Save button
      setIsLoadingProcess(WorkflowProcessMode.Save);
      //Calling process Save at next button
      const response = await postProcessNewBulkLevyUpdate(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingProcess(undefined);
      //set default notification
      const defaultSuccessMessage = "Bulk levy update was saved successfully.";
      const defaultFailedMessage = "Bulk levy update could not be saved.";
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (!isSaveOnNextStep) {
          onClose();
          pushNotification({
            title:
              response?.data?.SuccessMessage ??
              response?.data?.Notification ??
              defaultSuccessMessage,
            type: "success",
          });
        } else {
          // check is the first saving
          if (isFirstSave) {
            setIsFirstSave(false);
            //set current workflowDraft Id
            setWorkflowDraftId(response?.data?.ID ?? 0);
            // set payload to send
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID,
              },
            });
          }
        }
        return true;
      } else {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      return false;
    };
    //#endregion

    //#region <Handle finish process>
    /**
     * @param payload
     */
    const handleFinishProcess = async (payload: any) => {
      //set loading button
      setIsLoadingProcess(WorkflowProcessMode.Finish);
      //props send to process workflow
      const finishProps: INewProcessWorkflow<any> = {
        payload: payload,
        actionSuccess: (e) => {
          const notificationContent: IAppNotificationItemAddProps = {
            title:
              e?.SuccessMessage ??
              e?.Notification ??
              "Bulk levy was updated successfully.",
            type: "success",
          };
          onClose();
          isRedirectManagePage &&
            history.push(`${PROPERTY_SUPPLEMENTARY_RATES_ROUTE}/${e?.ID}`, {
              notification: notificationContent,
            });
          !isRedirectManagePage && pushNotification(notificationContent);
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        defaultFailedMessage: "Bulk levy could not be updated.",
        modeProcess: WorkflowProcessMode.Finish,
      };
      //calling api process workflow
      await handleProcessWorkflow(finishProps);
    };
    //#endregion

    //#region <Handle confirm finish>
    const handleConfirmFinish = (payload: any) => {
      const dataProcessed = processData(payload, WorkflowProcessMode.Finish);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage:
          "The bulk levy will be updated based on the information provided. Are you sure you want to submit?",
      });
    };
    //#endregion

    //#region <Handle close dialog>
    /**
     * @param renderProps
     */
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        //Store submit event
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <Get workflow data>
    /**
     * load initValue for FormStep
     * call once time
     */
    const getWorkflowData = async () => {
      //TODO: waiting api
      // const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
      formStepRef?.current?.setLoadingFormStep(true);
      // const response = await getInitialDataBulkLevyUpdate(
      //   workflowDraftId,
      //   isFromActionList,
      //   isFromActionList ? undefined : id ? [+id] : gridSelectedIds
      // );
      formStepRef?.current?.setLoadingFormStep(false);
      setWorkflowInitData(true);
      // if (Array.isArray(response)) {
      //   const [lovsJournal, workflowData] = response;
      //   if (
      //     isSuccessResponse(lovsJournal) &&
      //     isSuccessResponse(workflowData) &&
      //     lovsJournal?.data &&
      //     workflowData?.data
      //   ) {
      //     //set Lovs Data Assessment
      //     let createSupplementaryLOVs: any = {
      //       SupplementaryType: lovsJournal?.data?.SupplementaryType ?? [],
      //       InstallmentType: lovsJournal?.data?.InstalmentPlans,
      //       AssessmentType: lovsJournal?.data?.AssessmentType,
      //       ValuationTypeTobeUsed: lovsJournal?.data?.ValuationTypeTobeUsed,
      //     };
      //     setCreateSupplementaryLOVs(createSupplementaryLOVs);
      //     // setWorkflowInitData(workflowData?.data);
      //     if (workflowData.data?.WorkflowHeader) {
      //       setWorkflowHeader(workflowData.data?.WorkflowHeader);
      //       setWorkflowDraftId(
      //         workflowData.data?.WorkflowHeader?.WorkflowDraft
      //           ?.Workflow_Draft_Id ?? 0
      //       );
      //     }
      //   } else {
      //     let responseError: APIResponse<
      //       | any
      //     > = response[0];
      //     if (!isSuccessResponse(response[1])) {
      //       responseError = response[1];
      //     }
      //     formStepRef?.current?.setLoadFailedFormStep({
      //       onReload: () => getWorkflowData(),
      //       responseError: {
      //         status: responseError.status,
      //         error: "Load workflow failed",
      //       },
      //     });
      //   }
      // } else {
      //   const responseError = response as APIResponse;
      //   formStepRef?.current?.setLoadFailedFormStep({
      //     onReload: () => getWorkflowData(),
      //     responseError: {
      //       status: responseError.status,
      //       error: "Load workflow failed",
      //     },
      //   });
      // }
    };
    //#endregion

    //#region <Use Effect calling initial data>
    useEffectOnce(() => {
      getWorkflowData();
    });
    //#endregion
    return (
      <CCFormStep
        ref={formStepRef}
        onSubmit={handleSubmit}
        listButtonId={listSubmitButton}
        initialSteps={steps}
        initialValues={initialValue}
        localNotificationRef={localNotificationRef?.current ?? null}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            titleHeader={titleHeader}
            onClose={() => handleCloseDialog(renderProps)}
            badge={statusBadge}
            bodyElement={renderProps.children}
            disabled={isLoadingProcess !== undefined}
            footerElement={
              <>
                <div className={"cc-dialog-footer-actions-right"}>
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Park
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled ||
                        isLoadingProcess === WorkflowProcessMode.Park
                      }
                    >
                      Park
                    </Button>
                  )}
                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      disabled={!isNil(isLoadingProcess)}
                      id={EListSubmitButton.Cancel}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}
                  {(isToBeApprovalMode || isReadOnly) &&
                  renderProps.isLastStep ? null : (
                    <Button
                      themeColor="primary"
                      id={
                        !renderProps.isLastStep &&
                        (isToBeApprovalMode || isReadOnly)
                          ? "cc-next-step-button"
                          : renderProps.nextButton.idButton
                      }
                      disabled={
                        isLoadingProcess === WorkflowProcessMode.Save ||
                        renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Save
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingProcess === WorkflowProcessMode.Save
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
              </>
            }
          />
        )}
      />
    );
  }
);
