import { ASSESSMENT_MASTER_PROPERTIES_ROUTE } from "@app/products/property/assessments/master-properties/[id]/constant";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { VO_Master_Property } from "./model";

const nameOf = nameOfFactory<VO_Master_Property>();

//All titles will be replaced by column name data from view configuration API
export const colMasterProperties: IColumnFields[] = [
  {
    field: nameOf("Property_Assessment_Number"),
    title: "Assessment Number",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) =>
      `${ASSESSMENT_MASTER_PROPERTIES_ROUTE}/${dataItem.Id}`,
  },
  {
    //TODO: use new field after viewconfig api updated
    //field: nameOf("Property_Name_Address_Locality"),
    field: nameOf("Property_Address"),
    title: "Address",
    width: 200,
  },
  {
    field: nameOf("Property_Name"),
    title: "Name",
    width: 200,
  },
  {
    field: nameOf("Property_Locality"),
    title: "Locality",
    width: 150,
  },
  {
    field: nameOf("Property_Description"),
    title: "Description",
    width: 350,
  },
  {
    field: nameOf("No_of_Assessments"),
    title: "Number of Assessments",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Valuation_1"),
    title: "Valuation 1",
    width: 170,
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_2"),
    title: "Valuation 2",
    width: 200,
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_3"),
    title: "Valuation 3",
    width: 170,
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_4"),
    title: "Valuation 4",
    width: 170,
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_Issue_Date"),
    title: "Issue Date",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Valuation_Date"),
    title: "Date of Valuation",
    width: 200,
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Comment"),
    title: "Comment",
    width: 350,
  },
  {
    field: nameOf("Supplementary_Id"),
    title: "Supplementary ID",
    width: 170,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Supplementary_Name"),
    title: "Supplementary Name",
    width: 350,
  },
  {
    field: nameOf("Supplementary_Date"),
    title: "Supplementary Date",
    width: 200,
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("MP_Effective_Date"),
    title: "Effective Date",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Property_Type"),
    title: "Type",
    width: 150,
  },
  {
    field: nameOf("Id"),
    title: "Master Property ID",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
