import { checkIsValidFile } from "@app/core/attachment/components/dialogs/util";
import { ImportNOSFileRestrictions } from "@app/products/property/changes-of-ownership/notice-of-sales/components/dialogs/import-notice-of-sale/components/form-elements/import-file/config";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCTooltip } from "@components/cc-tooltip/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const fileValidator = (data: any) => {
  if (!checkIsValidFile(data)) {
    return "File size too large.";
  }
  return requiredValidator(data);
};

export const ImportFileFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({ formRenderProps, nameOf }: IFormStepElement) => {
    const { onChange } = formRenderProps;

    const { settings } = useCommonCoreStore();
    const isEnableAttachmentSizeRestriction = getBoolValueSetting(
      settings[ECorporateSettingsField.Global_EnableAttachmentSizeRestriction]
    );
    const attachmentSizeRestrictionSize = getNumberValueSetting(
      settings[ECorporateSettingsField.Global_AttachmentSizeRestrictionSize]
    );

    const maxFileSize = useMemo(() => {
      if (isEnableAttachmentSizeRestriction && attachmentSizeRestrictionSize) {
        return attachmentSizeRestrictionSize;
      }
      return ImportNOSFileRestrictions.DEFAULT_MAX_FILE_SIZE;
    }, [attachmentSizeRestrictionSize, isEnableAttachmentSizeRestriction]);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Upload file
              <CCTooltip type="validator" position="right" />
              <CCTooltip
                type="custom"
                position="auto"
                content=" "
                customTemplate={
                  <div>
                    &#8226; Allowed file type(s): .xml
                    <br />
                    &#8226; Maximum upload file size:{" "}
                    {maxFileSize / (1024 * 1024)} MB
                  </div>
                }
              >
                <i className="fa fa-info-circle ml-1 text-accent" />
              </CCTooltip>
            </label>
            <Field
              name={nameOf("File")}
              component={CCUploadFile}
              batch={false}
              multiple={false}
              autoUpload={false}
              accept={ImportNOSFileRestrictions.ACCEPT_FORMAT.join(",")}
              withCredentials={false}
              restrictions={{
                allowedExtensions: ImportNOSFileRestrictions.ACCEPT_FORMAT,
                maxFileSize: maxFileSize,
              }}
              onAdd={(event: UploadOnAddEvent) => {
                onChange(nameOf("File"), {
                  value: event.newState,
                });
              }}
              onRemove={(event: UploadOnRemoveEvent) => {
                onChange(nameOf("File"), {
                  value: event.newState,
                });
              }}
              showActionButtons={false}
              validator={fileValidator}
            />
          </div>
        </div>
      </section>
    );
  }
);
