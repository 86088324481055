import { useRaiseInterestWorkflowStore } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-interest/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { nameOfLov } from "@app/products/property/model";
import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { getDropdownValue } from "@common/utils/common";
import { validatorDateValueWithMinMax } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCMaskedTextBox } from "@components/cc-masked-textbox/_index";
import { CCNote } from "@components/cc-note/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";

export const RaiseInterestFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
export const FormStepElement = observer(
  ({
    nameOf,
    formRenderProps,
    options = {
      isShowAssessmentInfo: false,
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { currentOrganisationMode } = useCommonProductStore();
    const { isUseAssessRefAsPrimary } = useRaiseInterestWorkflowStore();

    //Get labels
    const [assessmentLabel, assessmentIDLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.Assessment,
        ECustomColNameProperty.AssessmentID,
      ]);

    /**
     * Task D-5702
     * only show 'Sth Australia' fields for organisation mode "General"
     */
    const isGeneral = useMemo(() => {
      return currentOrganisationMode(OrganisationMode.General) ?? false;
    }, [currentOrganisationMode]);
    const { valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const handleValidateFromDate = useCallback(
      (value: Date) => {
        return validatorDateValueWithMinMax(
          value,
          null,
          valueGetter(nameOf("InterestToDate"))
        );
      },
      [valueGetter, nameOf]
    );

    const handleValidateToDate = useCallback(
      (value: Date) => {
        return validatorDateValueWithMinMax(
          value,
          valueGetter(nameOf("InterestFromDate")),
          null
        );
      },
      [valueGetter, nameOf]
    );

    return (
      <>
        {/* Task D-5702: If created from the Assessment management page additional field is required.
        Assessment Reference:   Auto populate the current assessment
        (Note: Assessment reference is displayed because the Options setting is set to Primary Reference, otherwise it show Assessment ID) */}
        {(options?.isShowAssessmentInfo || getFieldValue("AssessmentId")) && (
          <section className="cc-field-group">
            <div className="cc-form-cols-2">
              {isUseAssessRefAsPrimary ? (
                <div className="cc-field">
                  <label className="cc-label">
                    {assessmentLabel} reference
                  </label>
                  <Field
                    name={nameOf("AssessmentReference")}
                    component={CCMaskedTextBox}
                    mask="000-000-0000-000"
                    readonly={options?.isReadOnly}
                  />
                </div>
              ) : (
                <div className="cc-field">
                  <label className="cc-label">{assessmentIDLabel}</label>
                  <Field
                    name={nameOf("AssessmentId")}
                    component={CCInput}
                    readOnly
                  />
                </div>
              )}
            </div>
            <br />
          </section>
        )}
        <section className="cc-field-group">
          <label className="cc-label">Raise interest for the period</label>
          <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">From (optional)</label>
                <Field
                  name={nameOf("InterestFromDate")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  max={
                    getFieldValue("InterestToDate")
                      ? new Date(getFieldValue("InterestToDate"))
                      : undefined
                  }
                  disabled={options?.isReadOnly}
                  validator={
                    !options?.isReadOnly ? handleValidateFromDate : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">To date</label>
                <Field
                  name={nameOf("InterestToDate")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  min={
                    getFieldValue("InterestFromDate")
                      ? new Date(getFieldValue("InterestFromDate"))
                      : undefined
                  }
                  disabled={options?.isReadOnly}
                  validator={
                    !options?.isReadOnly ? handleValidateToDate : undefined
                  }
                />
              </div>
            </div>
          </div>
        </section>
        <br />
        <section className="cc-field-group">
          <label className="cc-label">Interest transaction</label>
          <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">Date</label>
                <Field
                  name={nameOf("TransactionDate")}
                  component={CCDateTimePicker}
                  format={DATETIME_FORMAT.DATETIME_CONTROL}
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Type</label>
                <Field
                  name={nameOf("TransactionType")}
                  component={CCSearchComboBox}
                  data={options?.lovsJournalData?.TransactionTypes ?? []}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  disabled={options?.isReadOnly}
                  value={getDropdownValue(
                    getFieldValue("TransactionType")?.toString(),
                    options?.lovsJournalData?.TransactionTypes ?? [],
                    nameOfLov("Code")
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    formRenderProps.onChange(nameOf("TransactionType"), {
                      value: event.target.value?.Code ?? null,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <br />
        {isGeneral && (
          <section className="cc-field-group">
            <label className="cc-label">Sth australia instalment penalty</label>
            <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">
                    Instalment plan on which to raise penalty
                  </label>
                  <Field
                    name={nameOf("InstalmentPlan")}
                    component={CCSearchComboBox}
                    data={options?.lovsJournalData?.InstalmentPlans}
                    textField="Value"
                    dataItemKey="Key"
                    isUseDefaultOnchange
                    disabled
                  />
                </div>
              </div>
            </div>
            <br />
          </section>
        )}

        <CCNote
          message={`
        Raising Interest will create a journal with the interest for each ${assessmentLabel}. The Interest To Date on each Charge Instalment where interest is raised will be set to the 'To Date'
          `}
        />
      </>
    );
  }
);
