import { WorkflowTypes } from "@app/products/property/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { useJournalStore } from "@app/products/property/journals/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

interface IAuthoriseJournalButtonProps {
  disabled?: boolean;
}

export const AuthoriseJournalButton = observer(
  ({ disabled = false }: IAuthoriseJournalButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();
    const { reLoadJournal } = useJournalStore();
    return (
      <CCNavButton
        title="Authorise"
        disabled={disabled}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Journal_Authorise,
              data: {},
              props: {
                reloadJournal: reLoadJournal,
              },
            },
          ]);
        }}
      />
    );
  }
);
