import { VO_Supplementary } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import {
  DTO_Request_Base,
  DTO_WorkflowDetail_Base,
  DTO_WorkflowHeader,
} from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfSteps {
  Supplementary = "Supplementary",
  CreateSupplementary = "CreateSupplementary",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfSendSteps = [
  EKeysOfSteps.Supplementary,
  EKeysOfSteps.CreateSupplementary,
];

export interface RequestAddSupplementaryObj extends DTO_Request_Base {
  Component_Number: number;
  Component_Id: number;
}

export interface DTO_Workflow_Supplementary_Associate {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_Supplementary_Associate;
}

export interface DTO_WorkflowDetail_Supplementary_Associate
  extends DTO_WorkflowDetail_Base {
  Component_Number: number;
  Component_Id: number;
  Supplementary_Id: number;
  NewSupplementary: boolean;
  SelectFromMyList: boolean | null;
  IsMultipleAllowed: boolean;
  Component_Ids: string;
  CreateSupplementary: DTO_Supplementary_Create;
}

export interface DTO_Supplementary_Create {
  SupplementaryId: number | null;
  SupplementaryType: number;
  InstalmentPlanId: number | null;
  Name: string;
  Reference: string;
  Date: Date;
  Notes: string;
  IsComplete: boolean | null;
  CreatedOn: Date | null;
  CreatedBy: string;
  VM2000_Id: number | null;
  TransferRemainingBalance: boolean;
  BalanceTransferMethodId: number;
  BalanceTransferValuationType: number | null;
  UseExistingInstalmentPlan: boolean;
}

export interface DTO_Create_Supplementary_LOVs_Response {
  dtoCreate_Supplementary_LOVs: DTO_Create_Supplementary_LOVs;
}

export interface DTO_Create_Supplementary_LOVs {
  SupplementaryType?: DTO_LOV_SupplementaryType[];
  InstallmentType?: DTO_LOV[];
  AssessmentType?: DTO_LOV[];
  ValuationTypeTobeUsed?: DTO_LOV[];
  Workflow_Memo_Category?: DTO_LOV[];
}

export interface DTO_LOV_SupplementaryType {
  Code: string;
  Name: string;
  IsInstalmentPlanHidden: boolean;
}

export interface IAddToSuplementaryFormStep {
  [EKeysOfSteps.Supplementary]: IWhichSupplementary;
  [EKeysOfSteps.CreateSupplementary]: ICreateSupplementary;
  [EKeysOfSteps.Comments]: any;
  [EKeysOfSteps.Documents]: any;
}

export interface IWhichSupplementary {
  SupplementarySelected?: VO_Supplementary[];
  NewSupplementary: VO_Supplementary;
  SelectFromMyList: VO_Supplementary;
  _options?: any;
}

export interface ICreateSupplementary {
  SupplementaryId?: number | null;
  SupplementaryType?: number;
  InstalmentPlanId?: number | null;
  Name?: string;
  Reference?: string;
  Date: Date;
  Notes?: string;
  IsComplete?: boolean | null;
  CreatedOn?: Date | null;
  CreatedBy?: string;
  VM2000_Id?: number | null;
  TransferRemainingBalance: boolean;
  BalanceTransferMethodId?: number;
  BalanceTransferValuationType?: number | null;
  UseExistingInstalmentPlan?: boolean;
  _options?: any;
}

export interface IWorkflowSupplementaryAssociate {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_Supplementary_Associate;
  Component_Number?: number;
  Component_Id?: number;
}
