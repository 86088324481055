import { RefNumberUsage } from "@common/input-pickers/debtor-number/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<RefNumberUsage>();
export const colPickDebtorNumber: IColumnFields[] = [
  {
    field: nameOf("Sys_DebtorNumber"),
    title: "Debtor number",
  },
  {
    field: nameOf("Description"),
    title: "Name",
  },
  {
    field: nameOf("RecordType_ENUM"),
    title: "Record type",
  },
];
