import { loadAssessmentSummaryById } from "@app/products/property/assessments/[id]/api";
import {
  DTO_Assessment_Alert,
  DTO_Assessment_Summary,
} from "@app/products/property/assessments/[id]/model";
import { getContactAlertFromStringArray } from "@app/products/property/util";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class AssessmentStore {
  private _assessment?: DTO_Assessment_Summary = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get assessment() {
    return this._assessment;
  }
  setAssessment = (assessment?: DTO_Assessment_Summary) => {
    runInAction(() => {
      this._assessment = assessment;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get assessmentId() {
    return this.assessment?.Assessment?.Id;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._assessment = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };
  loadAssessment = async (
    assessmentId: number,
    notification?: IAppNotificationItemAddProps
  ) => {
    this.setIsLoading(true);
    const response = await loadAssessmentSummaryById(assessmentId);
    let newAssessment = undefined;
    let errorResponse = undefined;
    if (Array.isArray(response)) {
      const [assessmentSummary, optionSettings] = response;
      if (isSuccessResponse(response[0]) && isSuccessResponse(response[1])) {
        newAssessment = {
          ...assessmentSummary?.data,
          Settings: optionSettings?.data,
        };
      } else {
        errorResponse = {
          status: response[0].status,
          error: response[0].error,
        };
        if (!isSuccessResponse(response[1])) {
          errorResponse = {
            status: response[1].status,
            error: response[1].error,
          };
        }
      }

      this.setAssessment(newAssessment);
      const assessmentAlertMessages =
        newAssessment?.AlertMessages?.map((alert: DTO_Assessment_Alert) => {
          return alert.Message;
        }) ?? [];
      const contactAlertMessages =
        newAssessment?.Assessment?.Contact_Alerts?.map((alert: string) => {
          return `Contact alert: ${alert}`;
        }) ?? [];
      const assessmentFullAlerts = [
        ...assessmentAlertMessages,
        ...contactAlertMessages,
      ];
      if (assessmentFullAlerts?.length > 0) {
        const newNotifications = getContactAlertFromStringArray(
          assessmentFullAlerts,
          ""
        );
        appNotificationStore.setNotifications(newNotifications);
      }
    } else {
      errorResponse = response as APIResponse;
      errorResponse = {
        status: errorResponse?.status,
        error: errorResponse?.error ?? "Load failed",
      };
    }
    if (notification) {
      appNotificationStore.pushNotification(notification);
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };
  reLoadAssessment = async () => {
    if (this.assessmentId) await this.loadAssessment(this.assessmentId);
  };
}
export const assessmentStore = new AssessmentStore();
const assessmentStoreContext = createContext(new AssessmentStore());
export const useAssessmentStore = () => {
  return useContext(assessmentStoreContext);
};
