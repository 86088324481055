import { DTO_Journal } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/new-transaction/model";
import { DTO_WorkflowHeader, eVisibility } from "@app/products/property/model";
import { DTO_Supplementary_Create } from "@app/products/property/supplementary-rates/components/form-elements/new-supplementary/model";
import { DTO_LOV_Number } from "@common/models/odataResponse";

export enum EKeysOfStepsNewNoticeRun {
  NoticeType = "NoticeType",
  Attribute = "Attributes",
  Instalment = "InstalmentPlans",
  Journal = "Journals",
  NewJournal = "CreateJournal",
  Supplementary = "Supplementary",
  NewSupplementary = "CreateSupplementary",
  Assessment = "Assessment",
  Group = "Groups",
  Collection = "Collections",
  Execution = "Execution",
  Option = "Options",
  Comment = "Comments",
}

export enum ELabelNewNoticeRun {
  NoticeType = "Notice type",
  Attribute = "Attributes",
  Instalment = "Instalments",
  Journal = "Journal",
  NewJournal = "New journal",
  Supplementary = "Supplementaries",
  NewSupplementary = "New supplementary",
  Assessment = "Assessment",
  Group = "Groups",
  Collection = "Collections",
  Execution = "Execution",
  Option = "Options",
  Comment = "Comments",
}

export const keysOfStepsNewNoticeRun = [
  EKeysOfStepsNewNoticeRun.NewJournal,
  EKeysOfStepsNewNoticeRun.Attribute,
  EKeysOfStepsNewNoticeRun.Option,
  EKeysOfStepsNewNoticeRun.Collection,
  EKeysOfStepsNewNoticeRun.NewSupplementary,
  EKeysOfStepsNewNoticeRun.Group,
  EKeysOfStepsNewNoticeRun.Assessment,
  EKeysOfStepsNewNoticeRun.Instalment,
  EKeysOfStepsNewNoticeRun.Execution,
];

export interface DTO_Workflow_NoticeRunCreate {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_NoticeRunCreate;
}

export interface DTO_WorkflowDetail_NoticeRunCreate {
  SupplementaryMode: number;
  JournalMode: number;
  CreateJournal: DTO_Journal;
  ComponentNumber: number;
  Options: DTO_Process_NoticeRun_Options;
  Collections: DTO_Process_NoticeRun_Collections;
  CreateSupplementary: DTO_Supplementary_Create;
  Groups: DTO_Process_NoticeRun_Groups;
  Supplementaries: number[];
  Journals: number[];
  Assessment: DTO_Process_NoticeRun_Assessment;
  Attributes: DTO_Process_NoticeRun_Attributes;
  Notice_Type_Id: number;
  Notice_Run_Id: number | null;
  InstalmentPlans: DTO_Process_NoticeRun_InstalmentPlans;
  Execution: DTO_Process_NoticeRun_Execution;
}

export interface DTO_Process_NoticeRun_Attributes {
  Rating_Period_Id: number | null;
  Charge_Run_Id: number | null;
  Financial_Group_Id: number | null;
  SetTransactionDateToIssueDate: boolean;
  IssueDate: Date;
  Name: string;
}

export interface DTO_Process_NoticeRun_Assessment {
  MinimumNoticeAmount: number | null;
  Instalment_Plan_Id: number | null;
  NoNoticeIssuedAfter: Date | null;
  NoChangeOfOwnershipAfter: Date | null;
  NoActiveArrangement: boolean;
  NoInterestExemption: boolean;
  ExcludeInstalments: boolean;
  DirectDebitSchemesToBeExcluded: number[];
  NoOutstandingChargeInDiscountPeriod: boolean;
  EntitledToRebates: boolean;
  NotEntitledToRebates: boolean;
}

export interface DTO_Process_NoticeRun_Groups {
  IncludeUnGroupedNotices: boolean;
  AssessmentGroups: number[];
  AssessmentStatuses: number[];
  AssessmentTypes: number[];
  NoticeGroups: number[];
}

export interface DTO_Process_NoticeRun_Collections {
  CollectionsToExclude: number[];
  CollectionsToInclude: number[];
}

export interface DTO_Process_NoticeRun_Options {
  UseUtilityAgent: boolean;
  IncludeBalances: boolean;
  IncludeIfNotInChargeRun: boolean;
  NoticeOrdering_Id: number | null;
  FinancialAreas: number[];
  ReportTemplate: string;
}

export interface DTO_Process_NoticeRun_InstalmentPlans {
  SpecificRange: boolean;
  DueFrom: Date | null;
  DueTo: Date | null;
  InstalmentPlans: number[];
  OnlyAssessmentsPayingInFull: boolean;
}

export interface DTO_Process_NoticeRun_Execution {
  EmailRecipients: string;
  RunImmediately: boolean;
  RunAt: Date | null;
}
export interface RequestNoticeRunCreateObj {
  ComponentNumber: number;
}

export interface DTO_Process_NoticeRun_Attributes {
  Rating_Period_Id: number | null;
  Charge_Run_Id: number | null;
  Financial_Group_Id: number | null;
  SetTransactionDateToIssueDate: boolean;
  IssueDate: Date;
  Name: string;
}

export interface DTO_Process_NoticeRun_Assessment {
  MinimumNoticeAmount: number | null;
  Instalment_Plan_Id: number | null;
  OnlyAssessmentsPayingInFull: boolean;
  NoNoticeIssuedAfter: Date | null;
  NoChangeOfOwnershipAfter: Date | null;
  NoActiveArrangement: boolean;
  NoInterestExemption: boolean;
  ExcludeInstalments: boolean;
  DirectDebitSchemesToBeExcluded: number[];
  NoOutstandingChargeInDiscountPeriod: boolean;
  EntitledToRebates: boolean;
}

export interface DTO_Process_NoticeRun_Groups {
  IncludeUnGroupedNotices: boolean;
  AssessmentGroups: number[];
  AssessmentStatuses: number[];
  AssessmentTypes: number[];
  NoticeGroups: number[];
}

export interface DTO_Process_NoticeRun_Collections {
  CollectionsToExclude: number[];
  CollectionsToInclude: number[];
}

export interface DTO_Process_NoticeRun_Options {
  UseUtilityAgent: boolean;
  IncludeBalances: boolean;
  IncludeIfNotInChargeRun: boolean;
  NoticeOrdering_Id: number | null;
  FinancialAreas: number[];
  ReportTemplate: string;
}

export interface DTO_Process_NoticeRun_InstalmentPlans {
  SpecificRange: boolean;
  DueFrom: Date | null;
  DueTo: Date | null;
  InstalmentPlans: number[];
}
export interface DTO_NoticeRun_LOVs {
  Collections?: DTO_LOV_Number[];
  AssessmentStatuses?: DTO_LOV_Number[];
  DirectDebitSchemes?: DTO_LOV_Number[];
  NoticeOrderings?: DTO_LOV_NoticeOrdering[];
  FinancialGroups?: DTO_LOV_Number[];
  FinancialAreas?: DTO_LOV_Number[];
  SupplementaryTypes?: DTO_LOV_Number[];
  AssessmentTypes?: DTO_LOV_Number[];
  RatingPeriodId?: number | null;
  InstalmentPlans?: DTO_LOV_Number[];
  ChargeRun?: DTO_LOV_Number[];
  RatingPeriods?: DTO_LOV_Number[];
  AssessmentGroups?: DTO_LOV_Number[];
  NoticeGroups?: DTO_LOV_Number[];
  NoticeTypes?: DTO_LOV_NoticeType[];
  InstalmentPlanId?: number | null;
  JournalTypes?: DTO_LOV_Number[];
  AssessmentType?: DTO_LOV_Number[];
  ValuationTypeTobeUsed?: DTO_LOV_Number[];
}
export interface IListConfigStepCreateNoticeRun {
  keyNameStep: EKeysOfStepsNewNoticeRun;
  flagVisibilityStep: EFlagVisibilityStepCreateNoticeRun;
}

export enum EFlagVisibilityStepCreateNoticeRun {
  Attribute = "TabAttributeVisibility",
  Assessment = "TabAssessmentVisibility",
  Collection = "TabCollectionVisibility",
  Groups = "TabGroupsVisibility",
  Options = "TabOptionsVisibility",
  Journal = "TabJournalVisibility",
  Supplementary = "TabSupplementaryVisibility",
  Instalment = "TabInstalmentsVisibility",
}
export interface DTO_LOV_NoticeType {
  Code: number;
  Name: string;
  ComponentNumber: number;
  TabAttributeVisibility: eVisibility;
  TabAssessmentVisibility: eVisibility;
  TabGroupsVisibility: eVisibility;
  TabCollectionVisibility: eVisibility;
  TabOptionsVisibility: eVisibility;
  TabJournalVisibility: eVisibility;
  TabSupplementaryVisibility: eVisibility;
  TabInstalmentsVisibility: eVisibility;
  ChargeRunVisibility: eVisibility;
  AssessmentExclusionsVisibility: eVisibility;
  SetTransactionDateToIssueDateVisibility: eVisibility;
}

export interface DTO_LOV_NoticeOrdering {
  Code: number;
  Name: string;
  ComponentNumber: number;
}

export interface IFilterLOVsNoticeRun {
  chargeRunId?: number;
  financialGroupId?: number;
}
