import { nameOfLov } from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { getDropdownValue } from "@common/utils/common";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export interface IOfficerAndOfficerRegion {
  formRenderProps: FormRenderProps;
  nameOf: (name?: string) => string;
  isDisabled: boolean;
}

export const OfficerAndOfficerRegion = observer(
  ({ formRenderProps, nameOf, isDisabled }: IOfficerAndOfficerRegion) => {
    const { valueGetter, onChange } = formRenderProps;
    const { currentOrganisationMode } = useCommonProductStore();
    const isLLS = currentOrganisationMode(OrganisationMode.LLS);

    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const officerAssessmentGroupsValue: DTO_LOV[] =
      getFieldValue("OfficerAssessmentGroups") ?? [];

    return (
      <>
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <CCValueField label="Officer" value={getFieldValue("Officer")} />
            {isLLS ? (
              officerAssessmentGroupsValue.length > 1 ? (
                <div className="cc-field">
                  <label className="cc-label">Officer region</label>
                  <Field
                    name={nameOf("WD_Assessment_Group_Id")}
                    component={CCDropDownList}
                    data={officerAssessmentGroupsValue}
                    textField="Name"
                    dataItemKey="Code"
                    disabled={isDisabled}
                    value={getDropdownValue(
                      "" + getFieldValue("WD_Assessment_Group_Id"),
                      officerAssessmentGroupsValue,
                      nameOfLov("Code")
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      onChange(nameOf("WD_Assessment_Group_Id"), {
                        value: event.value?.Code ?? null,
                      });
                    }}
                  />
                </div>
              ) : (
                <CCValueField
                  label="Officer region"
                  value={officerAssessmentGroupsValue[0]?.Name ?? ""}
                />
              )
            ) : null}
          </div>
        </section>
        <hr className="cc-divider" />
      </>
    );
  }
);
