import { ContactAction } from "@app/core/contacts/merge-contact/model";
import { useMergeContactStore } from "@app/core/contacts/merge-contact/store";
import { Contact } from "@common/models/contact";
import { nameOfFactory } from "@common/utils/common";
import { CCLabel } from "@components/cc-label/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import {
  RadioGroup,
  RadioGroupChangeEvent,
} from "@progress/kendo-react-inputs";
import { cloneDeep, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export interface IUnMasterContactFormProps {
  contact: Contact;
}
const nameOf = nameOfFactory<Contact>();

const actions = [
  { label: "Merge", value: ContactAction.Merge },
  { label: "Delete", value: ContactAction.Delete },
  { label: "No action", value: ContactAction.NoAction },
];

export const UnMasterContactForm = observer(
  ({ contact }: IUnMasterContactFormProps) => {
    const {
      masterContact,
      mergeContactsLovs,
      setContacts,
      contacts,
      isLoadingMergeContacts,
      isLoadingFindDuplicateContact,
    } = useMergeContactStore();

    const contactType = mergeContactsLovs?.ContactType?.filter(
      (contactTypeItem) =>
        contactTypeItem.Key === contact.ContactClassification_ENUM
    );

    const contactIndex = useMemo(
      () =>
        contacts?.findIndex(
          (contactItem: Contact) =>
            contactItem.Contact_ID === contact.Contact_ID
        ),
      [contact, contacts]
    );

    const actionChangeHandler = (event: RadioGroupChangeEvent) => {
      if (contacts && !isNil(contactIndex) && contactIndex > -1) {
        const newContacts = cloneDeep(contacts);
        newContacts[contactIndex] = {
          ...contact,
          _action: event.value,
        } as Contact;
        setContacts(newContacts);
      }
    };

    const contactValueField = (name: keyof Contact, label: string) => {
      return (
        <div className="cc-field">
          <CCLabel title={label} />
          <CCValueField
            className={
              contact[name] === masterContact?.[name] || !contact[name]
                ? ""
                : "cc-mark"
            }
            value={contact[name]}
          />
        </div>
      );
    };

    return (
      <div className="cc-collapse-body">
        <div className="cc-form">
          <div className="cc-field-group">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <RadioGroup
                  data={actions}
                  defaultValue={actions[0].value}
                  layout="horizontal"
                  className="cc-radio-group"
                  disabled={
                    isLoadingFindDuplicateContact || isLoadingMergeContacts
                  }
                  onChange={actionChangeHandler}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Display name" />
                <CCValueField
                  className={
                    !contact.DisplayName ||
                    contact.DisplayName === masterContact?.DisplayName
                      ? ""
                      : "cc-mark"
                  }
                  value={`${contact.DisplayName ?? ""} (${contact.Contact_ID})`}
                />
              </div>
              {contactValueField(nameOf("AddressBook_ID"), "Address book")}
              <div className="cc-field">
                <CCLabel title="Type" />
                <CCValueField
                  className={
                    !contact.ContactClassification_ENUM ||
                    contact.ContactClassification_ENUM ===
                      masterContact?.ContactClassification_ENUM
                      ? ""
                      : "cc-mark"
                  }
                  value={contactType?.[0]?.Value}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Postal address" />
                <CCValueField
                  className={
                    !contact.PostalAddress?.Address_ID ||
                    contact.PostalAddress?.Address_ID ===
                      masterContact?.PostalAddress?.Address_ID
                      ? ""
                      : "cc-mark"
                  }
                  value={contact.PostalAddress?.Formatted_SingleLine}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Street address" />
                <CCValueField
                  className={
                    !contact.PreferredAddress?.Address_ID ||
                    contact.PreferredAddress?.Address_ID ===
                      masterContact?.PreferredAddress?.Address_ID
                      ? ""
                      : "cc-mark"
                  }
                  value={contact.PreferredAddress?.Formatted_SingleLine}
                />
              </div>
              {contactValueField(nameOf("_WorkPhone"), "Work phone")}
              {contactValueField(nameOf("_HomePhone"), "Home phone")}
              {contactValueField(nameOf("_Mobile"), "Mobile")}
              {contactValueField(nameOf("Email"), "Email")}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
