import {
  loadCategoryById,
  saveCategory,
} from "@app/products/crms/system-admin/categories/[id]/api";
import { ServiceStandardCategory } from "@app/products/crms/system-admin/categories/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

import { eventEmitter } from "@/App";
import { history } from "@/AppRoutes";
import { DBRowState } from "@app/products/crms/[id]/model";
import { CRMS_SYSTEM_ADMIN_CATEGORY_ROUTE } from "@app/products/crms/system-admin/categories/constant";
import { CCGridEventType } from "@components/cc-grid/constant";

configure({ enforceActions: "always" });

class CRMSSystemAdminCategoryStore {
  private _crmsCategory?: ServiceStandardCategory = undefined;
  private _responseLoadError?: APIResponseError = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _isLoading: boolean = false;
  private _isDisabled: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get crmsCategory() {
    return this._crmsCategory;
  }
  setCrmsCategory = (crmsCategory?: ServiceStandardCategory) => {
    runInAction(() => {
      this._crmsCategory = crmsCategory;
    });
  };

  get crmsCategoryId() {
    return this._crmsCategory?.ServiceStandardCategory_Id;
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isDisabled() {
    return this._isDisabled;
  }
  setIsDisabled = (isDisabled: boolean) => {
    runInAction(() => {
      this._isDisabled = isDisabled;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._onSubmit = undefined;
      this._crmsCategory = undefined;
      this._isDisabled = false;
    });
  };

  loadCategory = async (categoryId: number, isNew: boolean = false) => {
    this.setIsLoading(true);
    if (isNew) {
      const response = await loadCategoryById(0);
      this.setIsLoading(false);

      if (isSuccessResponse(response) && response.data) {
        this.setCrmsCategory(response.data);
      } else {
        this.setResponseLoadError({
          status: response.status,
          error: response.error,
        });
      }
    } else {
      const response = await loadCategoryById(categoryId);
      this.setIsLoading(false);

      if (isSuccessResponse(response)) {
        this.setCrmsCategory(response.data);
        if (response.data?.Sys_DBRowState === DBRowState.Inactive) {
          this.setIsDisabled(true);
          appNotificationStore.pushNotification({
            autoClose: false,
            title:
              "Important Note: You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
            type: "error",
          });
        }
      } else {
        this.setResponseLoadError({
          status: response.status,
          error: response.error,
        });
      }
    }
  };

  reLoadCRMSCategory = async (isNew: boolean) => {
    if (this.crmsCategoryId) {
      await this.loadCategory(this.crmsCategoryId, isNew);
    }
  };

  submitAction = async (
    crmsCategory: ServiceStandardCategory,
    isNew: boolean = false,
    action: ActionSubmitActions
  ) => {
    if (action === ActionSubmitActions.Save) {
      appNotificationStore.clearNotifications();
      this.setIsLoading(true);
      const response = await saveCategory(crmsCategory);
      this.setIsLoading(false);

      if (isSuccessResponse(response)) {
        if (isNew) {
          history.replace(
            CRMS_SYSTEM_ADMIN_CATEGORY_ROUTE +
              "/" +
              response.data?.ID.toString(),
            {
              notification: [
                {
                  title: "Service standard category successfully saved.",
                  type: "success",
                },
              ],
            }
          );
        } else {
          eventEmitter.emit(CCGridEventType.RefreshOData);
          this.reLoadCRMSCategory(isNew);

          appNotificationStore.pushNotification({
            title: "Service standard category successfully saved.",
            type: "success",
          });
        }
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Service standard category could not be saved.",
          type: "error",
        });
      }
    }
  };
}

const crmsSystemAdminCategoryContext = createContext(
  new CRMSSystemAdminCategoryStore()
);
export const useCRMSSytemAdminCategoryStore = () => {
  return useContext(crmsSystemAdminCategoryContext);
};
