import { handleValidateLotNumbers } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/progeny/dialogs/add-lots/util";
import {
  DTO_Address,
  DTO_SubdivisionsProgeny_LOVs,
} from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/progeny/model";
import { DTO_Progeny } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/model";
import { nameOfLov } from "@app/products/property/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";
import "./_index.scss";

const nameOf = nameOfFactory<DTO_Progeny>();
interface IAddLotsDialog {
  onClose: () => void;
  onSubmit: (event: DTO_Progeny & DTO_Address) => void;
  progenyLOVs: DTO_SubdivisionsProgeny_LOVs | undefined;
}

export const AddLotsDialog = ({
  onClose,
  onSubmit,
  progenyLOVs,
}: IAddLotsDialog) => {
  return (
    <Form
      onSubmit={(event) => onSubmit(event as DTO_Progeny & DTO_Address)}
      initialValues={{ Lot_From: 1 }}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter, onChange } = formRenderProps;
        const getFieldValue = (name: keyof DTO_Progeny) =>
          valueGetter(nameOf(name));

        return (
          <FormElement>
            <CCDialog
              maxHeight="65%"
              maxWidth="40%"
              titleHeader="Add Lots"
              onClose={onClose}
              bodyElement={
                <div className="cc-form cc-add-lots">
                  <section className="cc-field-group">
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <CCLabel
                          title="Plan type"
                          isMandatory={getFieldValue("Plan_Number")}
                        />
                        <Field
                          name={nameOf("Plan_Type_Name")}
                          component={CCSearchComboBox}
                          placeholder="Plan type"
                          data={progenyLOVs?.Plan_Type ?? []}
                          textField={nameOfLov("Name")}
                          dataItemKey={nameOfLov("Code")}
                          value={getDropdownValue(
                            getFieldValue("Plan_Type_Name"),
                            progenyLOVs?.Plan_Type ?? [],
                            nameOfLov("Name")
                          )}
                          onChange={(event: ComboBoxChangeEvent) => {
                            onChange("Plan_Type_Name", {
                              value: event.value?.Name ?? "",
                            });
                          }}
                          validator={
                            getFieldValue("Plan_Number")
                              ? requiredValidator
                              : undefined
                          }
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Plan number</label>
                        <Field
                          name={nameOf("Plan_Number")}
                          component={CCInput}
                          placeholder="Plan number"
                          format={NUMBER_FORMAT.NUMBER2}
                        />
                      </div>
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <CCLabel title="Lot numbers" isMandatory />
                          <Field
                            name={nameOf("Lot_From")}
                            component={CCNumericTextBox}
                            min={1}
                            validator={requiredValidator}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">to</label>
                          <Field
                            name={nameOf("Lot_To")}
                            component={CCNumericTextBox}
                            min={1}
                            validator={handleValidateLotNumbers}
                          />
                        </div>
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Owners</label>
                        <Field
                          name={nameOf("Owners")}
                          component={CCSearchComboBox}
                          textField={nameOfLov("Name")}
                          dataItemKey={nameOfLov("Code")}
                          placeholder="Owners"
                          data={progenyLOVs?.Owners ?? []}
                          value={getDropdownValue(
                            getFieldValue("Owners"),
                            progenyLOVs?.Owners ?? [],
                            nameOfLov("Name")
                          )}
                          onChange={(event: ComboBoxChangeEvent) => {
                            onChange("Owners", {
                              value: event.value?.Name ?? "",
                            });
                          }}
                        />
                      </div>
                    </div>
                    <section>
                      <label className="cc-label">Address</label>
                      <div className="cc-custom-sub-panel-bar">
                        <section className="cc-field-group">
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">
                                Building unit type
                              </label>
                              <Field
                                name={nameOf("Building_Unit_Name")}
                                placeholder="Building unit type"
                                component={CCSearchComboBox}
                                textField={nameOfLov("Name")}
                                dataItemKey={nameOfLov("Code")}
                                data={progenyLOVs?.StreetType}
                                value={getDropdownValue(
                                  valueGetter(nameOf("Building_Unit_Name")),
                                  progenyLOVs?.StreetType,
                                  nameOfLov("Name")
                                )}
                                onChange={(event: ComboBoxChangeEvent) => {
                                  onChange(nameOf("Building_Unit_Name"), {
                                    value: event.value?.Name ?? "",
                                  });
                                }}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">
                                Unit/Flat number
                              </label>
                              <Field
                                name={nameOf("Building_Unit_Number")}
                                component={CCNumericTextBox}
                                placeholder="Unit/Flat number"
                                format={NUMBER_FORMAT.NUMBER2}
                                min={0}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">House number</label>
                              <Field
                                name={nameOf("House_Number")}
                                component={CCNumericTextBox}
                                placeholder="House number"
                                format={NUMBER_FORMAT.NUMBER2}
                                min={0}
                              />
                            </div>
                            <div className="cc-field">
                              <CCLabel title="Street" isMandatory />
                              <Field
                                name={nameOf("Street_And_Locality")}
                                placeholder="Street"
                                component={CCSearchComboBox}
                                data={progenyLOVs?.StreetLocality ?? []}
                                textField={nameOfLov("Name")}
                                dataItemKey={nameOfLov("Code")}
                                value={getDropdownValue(
                                  valueGetter(nameOf("Street_And_Locality")),
                                  progenyLOVs?.StreetLocality ?? [],
                                  nameOfLov("Name")
                                )}
                                onChange={(event: ComboBoxChangeEvent) => {
                                  onChange(nameOf("Street_And_Locality"), {
                                    value: event.value?.Name ?? "",
                                  });
                                  onChange(nameOf("Street_Locality_Id"), {
                                    value: event.value?.Code ?? "",
                                  });
                                }}
                                validator={requiredValidator}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">Side of street</label>
                              <Field
                                name={nameOf("Side_Of_Street")}
                                placeholder="Side of street"
                                component={CCSearchComboBox}
                                textField={nameOfLov("Name")}
                                dataItemKey={nameOfLov("Code")}
                                data={progenyLOVs?.StreetSide ?? []}
                                value={getDropdownValue(
                                  getFieldValue("Side_Of_Street"),
                                  progenyLOVs?.StreetSide ?? [],
                                  nameOfLov("Name")
                                )}
                                onChange={(event: ComboBoxChangeEvent) => {
                                  onChange(nameOf("Side_Of_Street"), {
                                    value: event.value?.Name ?? "",
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </section>
                      </div>
                    </section>
                    <section>
                      <label className="cc-label">Estate</label>
                      <div className="cc-custom-sub-panel-bar cc-estate">
                        <section className="cc-field-group">
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">Estate</label>
                              <Field
                                name={nameOf("Estate")}
                                placeholder="Estate"
                                component={CCSearchComboBox}
                                data={progenyLOVs?.Estates ?? []}
                                textField={nameOfLov("Name")}
                                dataItemKey={nameOfLov("Code")}
                                value={getDropdownValue(
                                  getFieldValue("Estate"),
                                  progenyLOVs?.Estates ?? [],
                                  nameOfLov("Name")
                                )}
                                onChange={(event: ComboBoxChangeEvent) => {
                                  onChange("Estate", {
                                    value: event.value?.Name ?? "",
                                  });
                                }}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">Stage number</label>
                              <Field
                                name={nameOf("Estate_Stage_Number")}
                                component={CCInput}
                                placeholder="Stage number"
                              />
                            </div>
                          </div>
                        </section>
                      </div>
                    </section>
                  </section>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className={"cc-dialog-button"}
                    disabled={!formRenderProps.valid}
                    themeColor="primary"
                    onClick={formRenderProps.onSubmit}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
