import { OfficerAndOfficerRegion } from "@app/products/property/components/fields/officer-and-officer-region/_index";
import { useReactivePICDetailStepStoreContext } from "@app/products/property/pic/list/components/action-bar/form-steps/reactivate-pic/components/form-element/details/store";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const ReactivatePICDetailFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isDisabled: false,
    },
  }: IFormStepElement) => {
    //store
    const { lovDetail } = useReactivePICDetailStepStoreContext();
    const { valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    return (
      <>
        <OfficerAndOfficerRegion
          formRenderProps={formRenderProps}
          nameOf={nameOf}
          isDisabled={options?.isDisabled}
        />
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">
                Applicant name
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("ApplicantName")}
                component={CCInput}
                readOnly={options?.isReadOnly}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                placeholder="Applicant name"
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Reason for creation
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("ReasonId")}
                component={CCSearchComboBox}
                data={lovDetail?.ReasonForCreate ?? []}
                textField="Name"
                dataItemKey="Code"
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                disabled={options?.isReadOnly}
                isUseDefaultOnchange
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Reference
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("Reference")}
                readOnly={options?.isReadOnly}
                component={CCInput}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                placeholder="Reference"
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Status"
                value={
                  getDropdownValue(
                    getFieldValue("PICStatus"),
                    lovDetail?.PICStatus ?? [],
                    "Code"
                  )?.Name ?? ""
                }
              />
            </div>
          </div>
        </section>
      </>
    );
  }
);
