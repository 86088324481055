import { IDocument } from "@app/core/new-mail-merge/dialogs/model";
import {
  getMailMergeDocumentsTemplate,
  getMailMergeDocumentTemplate,
} from "@app/core/new-mail-merge/form-steps/template/api";
import { RECORDSSYSTEM } from "@app/products/town-planning/ppr/[id]/model";
import {
  colDocumentsTemplate,
  nameOfDocumentsTemplate,
} from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/ppr/planning-permit-referrals/components/dialogs/select-documents-template/config";
import {
  IDocumentsTemplate,
  ISelectDocumentsTemplateDialog,
} from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/ppr/planning-permit-referrals/components/dialogs/select-documents-template/model";
import { getKeywords } from "@common/apis/coreKeyword";
import { isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import _, { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useEffectOnce } from "react-use";
import "./_index.scss";

const pdfjsWorker = require("pdfjs-dist/build/pdf.worker.entry");
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
interface ISelectDocumentsTemplateProps {
  isMultiple?: boolean;
  dataSetFilters: number;
  productType: PRODUCT_TYPE_NUMBER;
  onClose: () => void;
  onSubmit: (data: ISelectDocumentsTemplateDialog) => void;
}

export const SelectDocumentsTemplateDialog = ({
  isMultiple = false,
  dataSetFilters,
  productType,
  onClose,
  onSubmit,
}: ISelectDocumentsTemplateProps) => {
  const { settings } = useCommonCoreStore();
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [numPages, setNumPages] = useState<number>(0);
  const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false);
  const [docPreviewData, setDocPreviewData] = useState<string | null>(null);
  const [categoriesData, setCategoriesData] = useState<CoreKeyword[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<CoreKeyword>();
  const [documentsTemplateData, setDocumentsTemplateData] = useState<
    IDocumentsTemplate[]
  >([]);
  const [selectedDocumentsTemplate, setSelectedDocumentsTemplate] = useState<
    IDocumentsTemplate[]
  >([]);
  const nameOfCoreKeyword = nameOfFactory<CoreKeyword>();

  const recordsSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
  );
  const isRequiredCategory =
    recordsSystem && recordsSystem === RECORDSSYSTEM.infoXpert ? true : false;
  const selectedDocumentIds: number[] =
    selectedDocumentsTemplate?.map((item) => item?.MailMergeDocument_ID) ?? [];

  const handleOnSubmit = () => {
    onSubmit({
      Category: selectedCategory,
      DocumentsTemplateSelected: selectedDocumentsTemplate?.map((item) => {
        return {
          Name: item?.Name,
          Document_ID: item?.MailMergeDocument_ID,
          FileEntry: {
            FileName: item?.FileName,
          },
        } as IDocument;
      }),
    });
  };

  const loadInitialValues = async () => {
    setIsLoading(true);
    const [resCategory, resTemplate] = await Promise.all([
      getKeywords(
        KEYWORD_TYPE.Core_DocumentCategory,
        productType ?? PRODUCT_TYPE_NUMBER.Core
      ),
      getMailMergeDocumentsTemplate(dataSetFilters, productType),
    ]);
    setIsLoading(false);
    if (isSuccessResponse(resCategory) && isSuccessResponse(resTemplate)) {
      if (resCategory?.data && resTemplate?.data) {
        setCategoriesData(resCategory.data);
        setDocumentsTemplateData(resTemplate.data?.value);
      }
    } else {
      notificationRef.current?.pushNotification({
        title: `Initial data load failed`,
        type: "error",
      });
    }
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const showAllPage = () => {
    const pages = Array.from({ length: numPages }, (_, index) => index + 1).map(
      (page) => <Page className="cc-pdf-pages" pageNumber={page} />
    );
    return pages;
  };

  useEffectOnce(() => {
    loadInitialValues();
  });

  useEffect(() => {
    setDocPreviewData(null);

    if (!isEmpty(selectedDocumentIds) && productType) {
      setShowPreview(true);
      setIsLoadingPreview(true);
      const lastTemplateId = _.last(selectedDocumentIds);
      if (lastTemplateId) {
        getMailMergeDocumentTemplate(lastTemplateId, productType).then(
          (response) => {
            setIsLoadingPreview(false);
            if (isSuccessResponse(response)) {
              if (response?.data) setDocPreviewData(response?.data);
            } else {
              notificationRef.current?.pushNotification({
                title: `Document template load failed`,
                type: "warning",
              });
            }
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocumentIds?.length]);

  return (
    <CCDialog
      titleHeader={"Documents Template"}
      height={"75%"}
      maxWidth={"70%"}
      onClose={onClose}
      bodyElement={
        isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <div className="cc-select-documents-container">
            <CCLocalNotification ref={notificationRef} />
            <div className="cc-field-group">
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <label className="cc-label">
                    Category
                    {isRequiredCategory && (
                      <CCTooltip type="validator" position="right" />
                    )}
                  </label>
                  <CCSearchComboBox
                    className="cc-search-combobox"
                    data={categoriesData ?? []}
                    textField={nameOfCoreKeyword("Name")}
                    dataItemKey={nameOfCoreKeyword("Keyword_ID")}
                    onChange={(event: ComboBoxChangeEvent) => {
                      setSelectedCategory(event.target.value);
                    }}
                  />
                  <CCGrid
                    className="cc-grid-container"
                    onSelectionChange={(dataItems: IDocumentsTemplate[]) => {
                      if (dataItems)
                        setSelectedDocumentsTemplate([...dataItems]);
                    }}
                    selectedRows={selectedDocumentsTemplate ?? []}
                    columnFields={colDocumentsTemplate}
                    primaryField={nameOfDocumentsTemplate(
                      "MailMergeDocument_ID"
                    )}
                    data={documentsTemplateData ?? []}
                    selectableMode={isMultiple ? "multiple" : "single"}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Preview</label>
                  <Loading isLoading={isLoadingPreview} />
                  {showPreview && !isLoadingPreview && (
                    <>
                      {docPreviewData &&
                        !isEmpty(selectedDocumentsTemplate) && (
                          <Document
                            className="cc-preview"
                            file={`data:application/pdf;base64,${docPreviewData}`}
                            renderMode="svg"
                            loading={""}
                            onLoadSuccess={onDocumentLoadSuccess}
                          >
                            {numPages && showAllPage()}
                          </Document>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      }
      footerElement={
        <div className="cc-dialog-footer-actions-right">
          <Button className="cc-dialog-button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            themeColor="primary"
            disabled={!selectedDocumentsTemplate?.length}
            className={"cc-dialog-button"}
            type={"submit"}
            onClick={handleOnSubmit}
          >
            Select
          </Button>
        </div>
      }
    />
  );
};
