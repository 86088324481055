import { DTO_Contact } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { Collection } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { DTO_Assessment } from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { DTO_Title } from "@app/products/property/components/dialogs/add-title-lookup/model";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import {
  DTO_Request_Base,
  DTO_WorkflowHeader,
} from "@app/products/property/model";
import { DTO_PIC_Swine } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";

export enum EKeysOfStepsReactivatePIC {
  PICID = "PIC_Id",
  Association = "Association",
  PICDetail = "PICDetails",
  Detail = "Details",
  Contact = "Contact",
  Collection = "SelectedCollection",
  Comment = "Comments",
  Document = "Documents",
  Workflow = "Workflow",
}

export enum ELabelReactivatePIC {
  Related = "Related",
  PICDetail = "PIC details",
  Detail = "Details",
  Collection = "Collections",
  Comment = "Comments",
  Document = "Documents",
  Workflow = "Workflow",
}

export enum EPICStatus {
  Inactive = "Inactive",
}

export const keysOfStepsReactivatePIC = [
  EKeysOfStepsReactivatePIC.PICID,
  EKeysOfStepsReactivatePIC.Association,
  EKeysOfStepsReactivatePIC.PICDetail,
  EKeysOfStepsReactivatePIC.Contact,
  EKeysOfStepsReactivatePIC.Detail,
  EKeysOfStepsReactivatePIC.Collection,
];

export interface DTO_Workflow_PIC_Reactivate {
  WorkflowDetail: DTO_WorkflowDetail_PIC_Reactivate;
  WorkflowHeader: DTO_WorkflowHeader;
}

export interface DTO_WorkflowDetail_PIC_Reactivate {
  PIC_Id: number | null;
  Details: DTO_PIC_Detail;
  Association: DTO_PIC_ReactivateAssociation;
  PICDetails: DTO_PIC_ReactivateDetails;
  SelectedCollection: Collection[];
  Contact: DTO_Contact;
  // TODO: Recheck when have API
  SelectedSecondaryWorkflow: number[];
}

export interface DTO_PIC_Detail {
  ApplicantName: string;
  ReasonId: number;
  Reference: string;
  PICStatus: number;
}
export interface DTO_PIC_ReactivateAssociation {
  AssociationDate: Date;
  PrimaryHolding?: number;
  Holdings: DTO_Assessment[];
  Folios: DTO_Title[];
}

export interface DTO_PIC_ReactivateDetails {
  Postcode: string;
  StateCode: string;
  PropertyLocality: string;
  PropertyAddress: string;
  PropertyName: string;
  PrimaryHolding: string;
  GisReferences: DTO_GIS[];
  TradingNameId: number | null;
  DistrictId: number | null;
  RLPBoardId: number | null;
  AgentLicenseNumber: string;
  PICLandUseId: number | null;
  PICTypeId: number;
  PICNumber: string;
  Occupier: string;
  Swines: DTO_PIC_Swine[];
}

export interface RequestPICReactivateObj extends DTO_Request_Base {
  PIC_Id: number;
}
