import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { Svc_BubbleupIdentifier } from "@common/pages/actions/[id]/components/reference-sidebar/details/model";
import { useActionDetailTabStore } from "@common/pages/actions/[id]/components/reference-sidebar/details/store";
import { formatDisplayValue } from "@common/utils/formatting";
import { getPageLinkByRecordType } from "@common/utils/getPageLinkByRecordType";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const getBubbleupValue = (bubbleUpObj: Svc_BubbleupIdentifier) => {
  return (
    <Link
      className="cc-detail-tab-link"
      to={{
        pathname: getPageLinkByRecordType(
          bubbleUpObj.RecordType_ENUM,
          bubbleUpObj.RecordID
        ),
      }}
    >
      {bubbleUpObj.RecordNumber}
    </Link>
  );
};

export const ActionsDetailTab = observer(() => {
  const {
    loadActionDetailTab,
    actionDetailTab,
    actionBubbleUpParent,
    actionBubbleUpAncestor,
    isLoading,
    responseLoadError,
  } = useActionDetailTabStore();
  const productListViewStore = useCCProductListViewStore();
  const { lastSelectedRow } = productListViewStore;
  const params: { id: string } = useParams();
  const actionsId = params.id ? parseInt(params.id) : lastSelectedRow?.ID;

  useEffect(() => {
    if (actionsId) {
      loadActionDetailTab(actionsId);
    }
    //eslint-disable-next-line
  }, [actionsId]);
  if (isLoading) return <Loading isLoading={isLoading} />;

  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          actionsId && loadActionDetailTab(actionsId);
        }}
      />
    );
  }

  if (!actionDetailTab) return <NoData />;
  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title="Reference number:"
        value={formatDisplayValue(actionDetailTab.ID, NUMBER_FORMAT.NUMBER2)}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow
          title="Status:"
          value={actionDetailTab.Status}
          success
        />
        <ProductReferenceRow
          title="Recorded by:"
          value={actionDetailTab.CreatedBy}
        />
        <ProductReferenceRow
          title="Recorded on:"
          value={formatDisplayValue(
            actionDetailTab.CreatedDate,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title="Due on:"
          value={formatDisplayValue(actionDetailTab.DueDate, DATE_FORMAT.DATE)}
        />
        <ProductReferenceRow
          title="Action by:"
          value={actionDetailTab.Officer}
        />
        <ProductReferenceRow title="Type:" value={actionDetailTab.Type} />
        {actionDetailTab.ParentDescription && (
          <ProductReferenceRow
            title="Parent description:"
            value={actionDetailTab.ParentDescription}
          />
        )}
        {actionBubbleUpAncestor && (
          <ProductReferenceRow
            title={actionBubbleUpAncestor.RecordTypeName}
            value={getBubbleupValue(actionBubbleUpAncestor)}
          />
        )}
        {actionBubbleUpParent && (
          <ProductReferenceRow
            title={actionBubbleUpParent.RecordTypeName}
            value={getBubbleupValue(actionBubbleUpParent)}
          />
        )}
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
