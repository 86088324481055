import {
  getAllOdata,
  getCallBackDataByID,
  getSearchByURL,
  getSearchOdata,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/api";
import { getUrlSearchInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/config";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { useDebounce } from "@common/hooks/useDebounce";
import { ILocalGovernment } from "@common/pages/settings/lookups/local-government/model";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog, ICCDialogProps } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { isHTML } from "@components/cc-input-picker/util";
import { CCInput } from "@components/cc-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ListItemProps,
  MultiSelect,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";
import { InputChangeEvent, InputProps } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import {
  concat,
  filter,
  first,
  includes,
  isArray,
  isEmpty,
  isEqualWith,
  isFunction,
  isNil,
  isNull,
  isObject,
  isString,
  isUndefined,
  map,
  some,
  uniq,
  uniqWith,
} from "lodash";
import React, {
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

export interface IInputPickerSearch
  extends Omit<InputProps, "validationMessage" | "value" | "onChange"> {
  options: IOptionInputPicker;
  validationMessage?: string | null;
  visited?: boolean;
  value: any;
  onChange?: (event: ILocalGovernment) => void;
  onInputChange?: (event: InputChangeEvent) => void;
  onError?: (value: any) => void;
  dialog?: Omit<
    ICCDialogProps,
    "bodyElement" | "onClose" | "footerElement" | "width" | "height"
  >;
  customDialog?: (
    value: any,
    onClose: () => void,
    onSubmit: (value: any) => void
  ) => ReactElement;
  onOpenDialogClick?: MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  showClearButton?: boolean;
  allowCustom?: boolean;
  nameDisplay?: string | Function;
  nameDisplayMerge?: string | ((value: any) => string);
  apiUrlCallBackByID?: string;
  onClickCancel?: () => void;
  customGetData?: (searchText: string) => Promise<APIResponse<any>>;
  localSearchOptions?: ILocalSearchOptions;
  isCRMS?: boolean;
}

export interface ILocalSearchOptions {
  enableLocalSearch?: boolean;
  searchingPropKey?: string;
}

export const InputPickerSearch = (props: IInputPickerSearch) => {
  const {
    options,
    validationMessage,
    visited,
    className,
    value,
    onChange,
    disabled,
    customDialog,
    onOpenDialogClick,
    showClearButton,
    placeholder,
    allowCustom,
    nameDisplay,
    nameDisplayMerge,
    apiUrlCallBackByID,
    onError,
    customGetData,
    localSearchOptions = {},
    isCRMS = false,
    ...others
  } = props;

  const { enableLocalSearch, searchingPropKey } = localSearchOptions;
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  const urlOData = useMemo(() => {
    return options.grid.dataUrl
      ? `${options.grid.dataUrl}?$count=true&`
      : undefined;
  }, [options.grid.dataUrl]);

  const [ODataSearchURL, setUrlODataSearch] = useState("");

  const [searchKey, setSearchKey] = useState("");
  const [typeAheadSearchResults, setTypeAheadSearchResults] = useState<any[]>(
    []
  );
  const [isLoadedOriginalData, setIsLoadedOriginalData] =
    useState<boolean>(false);
  const [gridData, setGridData] = useState<any[]>([]);
  const [gridDataOData, setGridDataOData] = useState<any[]>([]);
  const [gridOriginalData, setGridOriginalData] = useState<any[]>([]);

  const localTypeAheadSearchResults = gridData?.slice(
    0,
    options.grid.itemPerPage
  );
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearch = useDebounce(searchKey, 500);

  const [showDialog, setShowDialog] = useState(false);
  const [gridSelectedRows, setGridSelectedRows] = useState<any[]>([]);
  //#region handle multi-select mode
  const multiselectableMode = options.grid.selectableMode === "multiple";
  const [multipleOriginalData, setMultipleOriginalData] = useState<any[]>([]);
  const [multipleSearchData, setMultipleSearchData] = useState<any[]>([]);
  const originalOData = useRef<any[]>([]);
  let isUsingOriginalOData = true;

  if (multiselectableMode) {
    originalOData.current = [];
    originalOData.current = uniqWith(
      concat(originalOData.current, multipleSearchData, value),
      isEqualWith
    );
    isUsingOriginalOData =
      !isUndefined(first(originalOData.current)) &&
      isObject(value) &&
      some(Object.keys(first(originalOData.current)), (key) =>
        includes(map(options?.grid?.columnFields, "title"), key)
      );
  }
  //#endregion

  const inputValue = useMemo(() => {
    if (isUndefined(value) || isNull(value)) return "";
    if (isObject(value) && nameDisplay) {
      if (isString(nameDisplay)) {
        const valueDisplay =
          value?.[nameDisplay as keyof typeof value] ??
          value?.[options.boxSearch?.colSearch as keyof typeof value];
        return valueDisplay;
      }
      if (isFunction(nameDisplay)) {
        return nameDisplay(value);
      }
    }
    if (nameDisplayMerge && isArray(value)) {
      if (typeof nameDisplayMerge === "function") {
        return nameDisplayMerge(value);
      } else {
        let nameDisplay: Array<string> = [];
        value?.forEach((item) => {
          if (
            !isNil(item?.[nameDisplayMerge]) &&
            item[nameDisplayMerge]?.length > 0
          ) {
            nameDisplay.push(item?.[nameDisplayMerge]?.toString()?.trim());
          }
        });
        return multiselectableMode ? nameDisplay : nameDisplay?.join(", ");
      }
    }
    return value ?? "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, nameDisplayMerge, nameDisplay]);

  useEffect(() => {
    if (isNil(inputValue) || isEmpty(inputValue)) {
      setGridSelectedRows([]);
    }
  }, [inputValue]);

  const [multipleInputValues, setMultipleInputValues] = useState<string[]>(
    isArray(inputValue) ? inputValue : []
  );

  const handleSubmitCustomDialog = useCallback(
    (value: any) => {
      setShowDialog(false);
      if (onChange) onChange(value);
    },
    [onChange]
  );

  const handleOnClickToChangeData = useCallback(
    (value: any) => {
      setShowDialog(false);
      isString(nameDisplayMerge) &&
        setMultipleInputValues(uniq(map(value, nameDisplayMerge)));
      const apiUrlCallBackByID = options.grid.apiUrlCallBackByID;
      if (apiUrlCallBackByID && value) {
        const id = value[options.grid.primaryField as keyof typeof value];
        if (id) {
          setIsSearching(true);
          getCallBackDataByID(id, apiUrlCallBackByID).then((response) => {
            setIsSearching(false);
            if (isSuccessResponse(response)) {
              if (response.data) {
                if (onChange) onChange(response.data);
              }
            } else {
              if (onError) onError(response.error);
            }
          });
        } else {
          if (onChange) onChange(value);
        }
      } else {
        if (onChange) onChange(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, onError]
  );

  const handleOnLoadGridDataFromAPI = async (searchText: string) => {
    if (customGetData) {
      const response = await customGetData(searchText);
      if (isSuccessResponse(response) && response?.data) {
        setGridData(response.data);
        if (!isLoadedOriginalData) {
          setGridOriginalData(response.data);
          setIsLoadedOriginalData(true);
        }
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: "Load data failed",
          type: "error",
          description: response.data?.Errors,
        });
      }
    }
  };

  const handleOnLocalSearch = async (searchText: string) => {
    if (!isLoadedOriginalData) {
      await handleOnLoadGridDataFromAPI("");
    }
    setGridData(() => {
      if (searchText !== "" && searchingPropKey) {
        return gridOriginalData.filter((x) =>
          x?.[searchingPropKey].toLowerCase().includes(searchText.toLowerCase())
        );
      }
      return gridOriginalData;
    });
  };

  const handleOnClickOpenDialog = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (onOpenDialogClick) return onOpenDialogClick(event);
    setSearchKey("");
    setShowDialog(true);
    isString(nameDisplayMerge) &&
      setGridSelectedRows(
        filter(
          isUsingOriginalOData ? originalOData.current : multipleOriginalData,
          (item: any) => includes(multipleInputValues, item?.[nameDisplayMerge])
        )
      );

    if (customGetData) {
      setIsSearching(true);
      await handleOnLoadGridDataFromAPI("");
      setIsSearching(false);
    }
  };

  const getDataODataGrid = useCallback((urlOData) => {
    getAllOdata(urlOData).then((response) => {
      if (isSuccessResponse(response)) {
        setGridDataOData(response.data.value);
      }
    });
  }, []);

  useEffectOnce(() => {
    if (options.flagRedirectGridPageNoApiCall && urlOData) {
      getDataODataGrid(urlOData);
    }
  });

  const handleMultipleOriginalData = async () => {
    if (options.grid.dataUrl) {
      const response = await getSearchOdata(
        debouncedSearch,
        options.boxSearch.colSearch,
        options.grid.dataUrl,
        options.grid.itemPerPage
      );
      setMultipleOriginalData(response?.data.value || []);
    }
  };

  useEffect(() => {
    (async () => {
      if (enableLocalSearch) {
        setIsSearching(true);
        await handleOnLocalSearch(debouncedSearch);
        setIsSearching(false);
      } else if (debouncedSearch) {
        setIsSearching(true);
        if (options.grid.dataUrl) {
          const response = await getSearchOdata(
            debouncedSearch,
            options.boxSearch.colSearch,
            options.grid.dataUrl,
            options.grid.itemPerPage
          );

          const responseData = response?.data?.value;
          let newTypeAheadSearchResults = [];
          let newMultipleSearchData = [];
          if (isArray(responseData)) {
            newMultipleSearchData = responseData;
            if (isString(nameDisplayMerge) && multiselectableMode) {
              newTypeAheadSearchResults = map(responseData, nameDisplayMerge);
            } else {
              newTypeAheadSearchResults = responseData;
            }
          }

          setTypeAheadSearchResults(newTypeAheadSearchResults);
          setMultipleSearchData(newMultipleSearchData);
          setUrlODataSearch(
            getUrlSearchInputPicker(
              searchKey,
              options.boxSearch.colSearch,
              options.grid.dataUrl
            )
          );
        } else if (options.grid.dataUrlSearch) {
          const response = await getSearchByURL(
            options.grid.dataUrlSearch,
            debouncedSearch
          );
          setTypeAheadSearchResults(
            (isString(nameDisplayMerge) && multiselectableMode
              ? map(response?.data.value, nameDisplayMerge)
              : response?.data.value) || []
          );
        } else if (customGetData) {
          await handleOnLoadGridDataFromAPI(debouncedSearch);
        }
        setIsSearching(false);
      } else {
        handleMultipleOriginalData();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  useEffect(() => {
    if (
      options.flagRedirectGridPageNoApiCall &&
      urlOData &&
      isEmpty(searchKey)
    ) {
      getDataODataGrid(urlOData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  const renderItems = (
    li: ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const { dataItem } = itemProps;
    const itemChildren = (
      <div className="cc-search-item">
        {options.boxSearch.colShowComboboxSearch.map((col: IColumnFields) => (
          <div key={col.field}>{sanitizeHtml(dataItem[col.field] ?? "")}</div>
        ))}
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const handleSearch = (
    event: ComboBoxFilterChangeEvent | MultiSelectFilterChangeEvent
  ) => {
    const searchText = event.filter.value;
    setSearchKey(searchText);
  };

  return (
    <>
      <div
        className={`${className ?? ""} cc-input-picker-new ${
          !others.valid ? "cc-input-picker-invalid" : ""
        }`}
      >
        {isHTML(inputValue) ? (
          <div className="cc-input-picker-html k-textbox">
            {sanitizeHtml(inputValue)}
          </div>
        ) : multiselectableMode && !isCRMS ? (
          <MultiSelect
            loading={isSearching}
            data={
              enableLocalSearch
                ? localTypeAheadSearchResults
                : typeAheadSearchResults
            }
            value={multipleInputValues}
            onChange={(event: MultiSelectChangeEvent) => {
              const value = event?.value ?? null;
              setTypeAheadSearchResults([]);
              handleOnClickToChangeData(
                value &&
                  (multiselectableMode || nameDisplayMerge) &&
                  isString(nameDisplayMerge) &&
                  filter(
                    isUsingOriginalOData
                      ? originalOData.current
                      : multipleOriginalData,
                    (item) => includes(value, item?.[nameDisplayMerge])
                  )
              );
            }}
            placeholder={placeholder}
            onFilterChange={(event: MultiSelectFilterChangeEvent) => {
              handleSearch(event);
            }}
            allowCustom={allowCustom}
            disabled={disabled}
            required={!others.valid}
            filterable
          />
        ) : (
          <ComboBox
            allowCustom={allowCustom}
            placeholder={placeholder}
            disabled={disabled}
            required={!others.valid}
            filterable
            suggest
            data={
              enableLocalSearch
                ? localTypeAheadSearchResults
                : typeAheadSearchResults
            }
            loading={isSearching}
            onFilterChange={handleSearch}
            itemRender={renderItems}
            value={inputValue}
            onChange={(e: ComboBoxChangeEvent) => {
              e.target.value
                ? handleOnClickToChangeData(
                    multiselectableMode || nameDisplayMerge
                      ? [e.target.value]
                      : e.target.value
                  )
                : handleOnClickToChangeData(null);
            }}
            popupSettings={{ className: "cc-officer-picker-search" }}
            clearButton={showClearButton}
          />
        )}
        <Button
          disabled={disabled}
          className="cc-input-picker-button"
          iconClass="fa fa-ellipsis-h"
          onClick={handleOnClickOpenDialog}
        />
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
      {showDialog &&
        (customDialog ? (
          customDialog(
            value,
            () => setShowDialog(false),
            handleSubmitCustomDialog
          )
        ) : (
          <CCDialog
            dialogWrapperClassName="cc-officer-picker-search"
            {...options.dialog}
            maxWidth={options.dialog.maxWidth ?? "60%"}
            maxHeight={options.dialog.maxHeight ?? "80%"}
            height={options.dialog.height ?? 660}
            onClose={() => setShowDialog(false)}
            bodyElement={
              <div className="cc-search-result">
                <CCLocalNotification ref={notificationRef} />
                <div className="cc-search-result-title">
                  <label className="cc-label">Search</label>
                  <Field
                    name={"searchKey"}
                    component={CCInput}
                    placeholder="Search"
                    onChange={(e: InputChangeEvent) => {
                      setSearchKey(e.value);
                    }}
                  />
                </div>
                <div className="cc-search-result-body">
                  <CCGrid
                    {...options.grid}
                    /* For using odata */
                    dataUrl={
                      searchKey
                        ? ODataSearchURL
                        : !options.flagRedirectGridPageNoApiCall
                        ? urlOData
                        : undefined
                    }
                    /** Need to pass gridData & onLoadData For using api, otherwise needless*/
                    data={
                      options.flagRedirectGridPageNoApiCall
                        ? gridDataOData ?? []
                        : gridData ?? []
                    }
                    isLoading={isSearching}
                    onSelectionChange={(dataItems) => {
                      setGridSelectedRows(dataItems);
                    }}
                    selectedRows={gridSelectedRows}
                  />
                </div>
              </div>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button
                  className="cc-dialog-button"
                  onClick={() => setShowDialog(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  disabled={gridSelectedRows.length === 0}
                  onClick={() => {
                    handleOnClickToChangeData(
                      multiselectableMode || nameDisplayMerge
                        ? gridSelectedRows
                        : gridSelectedRows[0]
                    );
                  }}
                >
                  Select
                </Button>
              </div>
            }
          />
        ))}
    </>
  );
};
