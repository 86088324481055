import { history } from "@/AppRoutes";
import { DBRowState, LookupTable } from "@app/products/crms/[id]/model";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import {
  getLookupTableById,
  getLookupTableLovs,
  postEventHandler,
  postSaveLookupTable,
} from "@app/products/crms/system-admin/lookup-tables/[id]/api";
import { LOOKUP_TABLES_ROUTE } from "@app/products/crms/system-admin/lookup-tables/[id]/constant";
import {
  LookuptableHandlerRequest,
  LookuptableUIControl,
  Svc_FormAction_Lookuptable,
} from "@app/products/crms/system-admin/lookup-tables/[id]/model";
import { IPSARApplicationParentSection } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class LookupTableStore {
  private _lookupTable?: LookupTable = undefined;
  private _lookupTableLovs?: KeyValuePacket[] = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _isFormModified?: boolean = false;
  private _notification?: IAppNotificationItemAddProps = undefined;
  private _uiControl?: LookuptableUIControl = undefined;
  private _parentSection?: IPSARApplicationParentSection = undefined;
  private _isLoadingDetail?: boolean = false;
  private _isInactive?: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get lookupTable() {
    return toJS(this._lookupTable);
  }
  setLookupTable = (lookupTable?: LookupTable) => {
    runInAction(() => {
      this._lookupTable = lookupTable;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get lookupTableLovs() {
    return toJS(this._lookupTableLovs);
  }
  setLookupTableLovs = (lookupTableLovs?: KeyValuePacket[]) => {
    runInAction(() => {
      this._lookupTableLovs = lookupTableLovs;
    });
  };

  get isFormModified() {
    return toJS(this._isFormModified);
  }
  setIsFormModified = (isFormModified?: boolean) => {
    runInAction(() => {
      this._isFormModified = isFormModified;
    });
  };

  get notification() {
    return this._notification;
  }
  setNotification = (notification?: IAppNotificationItemAddProps) => {
    runInAction(() => {
      this._notification = notification;
    });
  };

  get uiControl() {
    return this._uiControl;
  }
  setUiControl = (uiControl?: LookuptableUIControl) => {
    runInAction(() => {
      this._uiControl = uiControl;
    });
  };

  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: IPSARApplicationParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  get isLoadingDetail() {
    return this._isLoadingDetail;
  }
  setIsLoadingDetail = (isLoadingDetail: boolean) => {
    runInAction(() => {
      this._isLoadingDetail = isLoadingDetail;
    });
  };

  get isInactive() {
    return this._isInactive;
  }
  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._lookupTable = undefined;
      this._lookupTableLovs = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._isFormModified = undefined;
      this._notification = undefined;
      this._onSubmit = undefined;
      this._uiControl = undefined;
      this._parentSection = undefined;
      this._isLoadingDetail = false;
      this._isInactive = false;
    });
  };

  loadLookupTable = async (lookupTableId: number, isNew: boolean) => {
    let errorResponse = undefined;
    let lookupTable = undefined;
    let lookupTableLovs = undefined;
    let uiControl = undefined;

    if (isNew) {
      this.setIsLoading(true);
      const [response, responseLovs] = await Promise.all([
        getLookupTableById(0),
        getLookupTableLovs(),
      ]);
      if (
        isSuccessResponse(response) &&
        response?.data &&
        isSuccessResponse(responseLovs) &&
        responseLovs?.data
      ) {
        const params: LookuptableHandlerRequest = {
          LookuptableFormAction: Svc_FormAction_Lookuptable.SystemInitialise,
          Lookuptable: response?.data,
          EventArgs: {},
          IsFirstTimeLoad: true,
        };
        const responseEventHandler = await postEventHandler(params);
        if (
          isSuccessResponse(responseEventHandler) &&
          responseEventHandler?.data?.ReturnObj
        ) {
          lookupTable = responseEventHandler?.data?.ReturnObj?.Lookuptable;
          lookupTableLovs = responseLovs?.data;
          uiControl = responseEventHandler?.data?.ReturnObj?.UIControl;
        } else {
          errorResponse = {
            status: responseEventHandler?.status,
            error:
              responseEventHandler?.data?.Errors ?? responseEventHandler?.error,
          };
        }
      } else {
        errorResponse = {
          status: response?.status ?? responseLovs?.status,
          error: response?.error ?? responseLovs?.error,
        };
      }
    } else {
      this.setIsLoading(true);
      const [response, responseLovs] = await Promise.all([
        getLookupTableById(lookupTableId),
        getLookupTableLovs(),
      ]);
      if (
        isSuccessResponse(response) &&
        response?.data &&
        isSuccessResponse(responseLovs) &&
        responseLovs?.data
      ) {
        const params: LookuptableHandlerRequest = {
          LookuptableFormAction: Svc_FormAction_Lookuptable.SystemInitialise,
          Lookuptable: response?.data,
          EventArgs: {},
          IsFirstTimeLoad: true,
        };
        const responseEventHandler = await postEventHandler(params);
        if (
          isSuccessResponse(responseEventHandler) &&
          responseEventHandler?.data?.ReturnObj
        ) {
          lookupTable = responseEventHandler?.data?.ReturnObj?.Lookuptable;
          lookupTableLovs = responseLovs?.data;
          uiControl = responseEventHandler?.data?.ReturnObj?.UIControl;

          // push notification
          if (this.parentSection?.notification) {
            this.parentSection?.notification.forEach(
              (notification: IAppNotificationItemAddProps) => {
                appNotificationStore.pushNotification(notification);
              }
            );
            this.setParentSection({ ...this._parentSection, notification: [] });
          }

          if (this.notification) {
            appNotificationStore.pushNotification(this.notification);
            this.setNotification(undefined);
          }

          // show alert when lookup table is inactive
          if (lookupTable?.Sys_DBRowState === DBRowState.Inactive) {
            appNotificationStore.pushNotification({
              title:
                "Important Note: You are viewing a deleted record.  You are not allowed to perform any Workflow functions or make changes to this record.",
              type: "warning",
              autoClose: false,
            });
            this.setIsInactive(true);
          }
        } else {
          errorResponse = {
            status: responseEventHandler?.status,
            error:
              responseEventHandler?.data?.Errors ?? responseEventHandler?.error,
          };
        }
      } else {
        errorResponse = {
          status: response?.status ?? responseLovs?.status,
          error: response?.error ?? responseLovs?.error,
        };
      }
    }

    this.setUiControl(uiControl);
    this.setLookupTable(lookupTable);
    this.setLookupTableLovs(lookupTableLovs?.LookupTableTypes);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  submitAction = async (
    lookupTable: LookupTable,
    action: ActionSubmitActions,
    isNew: boolean
  ) => {
    if (action === ActionSubmitActions.Save) {
      appNotificationStore.clearNotifications();
      this.setIsLoading(true);
      let newLookupTable = undefined;
      const response = await postSaveLookupTable(lookupTable);
      this.setIsLoading(false);

      if (isSuccessResponse(response)) {
        newLookupTable = response.data;
        if (this.isFormModified) this.setIsFormModified(false);
        if (isNew) {
          history.replace(
            `/crms/${LOOKUP_TABLES_ROUTE}/` + newLookupTable?.ID.toString(),
            {
              notification: [
                {
                  title: "Record successfully saved.",
                  type: "success",
                },
              ],
            }
          );
        } else {
          this.loadLookupTable(newLookupTable?.ID, isNew);
          this.setNotification({
            title: "Record successfully saved.",
            type: "success",
          });
        }
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Event could not be saved.",
          type: "error",
          description: response.data?.Errors ?? response.error,
        });
      }
    }
  };

  lookupTableChangeHandler = async (
    params: LookuptableHandlerRequest,
    errorMsg: string
  ) => {
    let result = false;
    this.setIsLoading(true);
    const response = await postEventHandler(params);
    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setIsFormModified(true);
      this.setLookupTable(response.data.ReturnObj.Lookuptable);
      this.setUiControl(response.data.ReturnObj.UIControl);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: errorMsg,
        type: "error",
        description: response.data?.Errors || response.error,
      });
      result = false;
    }
    this.setIsLoading(false);
    return result;
  };

  loadLookupTableDetails = async (lookupTableId: number) => {
    this.setIsLoadingDetail(true);
    const response = await getLookupTableById(lookupTableId);
    this.setIsLoadingDetail(false);
    if (isSuccessResponse(response) && response.data) {
      this.setLookupTable(response.data);
    } else {
      this.setResponseLoadError({
        status: response.status,
        error: response.error,
      });
    }
  };
}

const LookupTableStoreContext = createContext(new LookupTableStore());
export const useLookupTableStore = () => {
  return useContext(LookupTableStoreContext);
};
