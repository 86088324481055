import { useAnimalStore } from "@app/products/animals/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const AnimalUpdateRegistrationButton = observer(() => {
  const { setIsShowUpdateRegistrationDialog } = useAnimalStore();

  return (
    <CCNavButton
      title="Update registration"
      onClick={() => setIsShowUpdateRegistrationDialog(true)}
    />
  );
});
