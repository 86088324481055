import { ProductType } from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const postFinanceInvoiceExport = async (
  kennelIds: number[],
  isAllRecords: boolean,
  productType: ProductType
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `api/core/internal/finance/finance-invoice-export?productType=${productType}&isAllRecords=${isAllRecords}`,
      kennelIds
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
