import { CRMS_SERVICE_STANDARDS_ROUTE } from "@app/products/crms/service-standards/[id]/constant";
import { StateSvcStandardUpdate } from "@app/products/crms/service-standards/batch-update/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

export interface IServiceStandardBatchUpdateProps {
  isDisabled?: boolean;
  isVisible?: boolean;
}

export const SvcStandardBatchUpdateButton = observer(
  ({
    isDisabled = false,
    isVisible = true,
  }: IServiceStandardBatchUpdateProps) => {
    const history = useHistory();
    const { gridSelectedIds } = useCCProductListViewStore();

    if (!isVisible) return null;
    return (
      <CCNavButton
        title="Batch Update"
        disabled={isDisabled || gridSelectedIds.length < 1}
        onClick={() => {
          history.push(`${CRMS_SERVICE_STANDARDS_ROUTE}/batch-update`, {
            gridSelectedIds: gridSelectedIds,
          } as StateSvcStandardUpdate);
        }}
      />
    );
  }
);
