import { IWriteOffSmallBalances } from "@app/products/property/assessments/components/dialogs/write-off-small-balances/model";
import _ from "lodash";

export const changeGridData = (
  data: IWriteOffSmallBalances[],
  lastSelectedRow: IWriteOffSmallBalances | undefined,
  value: string | number,
  setData: (value: React.SetStateAction<IWriteOffSmallBalances[]>) => void
) => {
  const index = _.findIndex(data, {
    Variable_Id: lastSelectedRow?.Variable_Id,
  });

  if (index !== -1 && lastSelectedRow) {
    const updatedRow: IWriteOffSmallBalances = {
      ...lastSelectedRow,
      Value: value,
    };

    return setData([
      ...data.slice(0, index),
      updatedRow,
      ...data.slice(index + 1),
    ]);
  }

  return setData(data);
};
