import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  formatDisplayValue,
  formatStyleAlignRight,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import {
  IWriteOffSmallBalances,
  eSqlDataType,
} from "@app/products/property/assessments/components/dialogs/write-off-small-balances/model";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";

const nameOf = nameOfFactory<IWriteOffSmallBalances>();
export const colWriteOffSmallBalances: IColumnFields[] = [
  {
    field: nameOf("Prompt"),
    title: "Prompt",
  },
  {
    field: nameOf("Value"),
    title: "Value",
    cell: (props: GridCellProps) => {
      const { dataItem } = props;
      return (
        <TextCell
          value={
            dataItem?.SQL_Data_Type === eSqlDataType.MONEY
              ? formatDisplayValue(dataItem?.Value, CURRENCY_FORMAT.CURRENCY1)
              : dataItem?.Value
          }
          style={
            dataItem?.SQL_Data_Type !== eSqlDataType.VARCHAR
              ? formatStyleAlignRight
              : undefined
          }
        />
      );
    },
  },
  { field: nameOf("Variable_Name"), title: "Variable Name" },
  {
    field: nameOf("SQL_Data_Type"),
    title: "SQL Data Type",
  },
  {
    field: nameOf("Variable_Id"),
    title: "Variable ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
