import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { IPropsWorkflow } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/add-transaction/model";
import { useJournalStore } from "@app/products/property/journals/[id]/store";
import { eJournalType } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/model";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IAddTransactionButtonProps {
  disabled?: boolean;
}

export const AddTransactionButton = observer(
  ({ disabled = false }: IAddTransactionButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();
    const { journal } = useJournalStore();

    const propsWorkflow = useMemo(() => {
      if (isNil(journal?.Journal_Type)) return undefined;
      let propsDialog: IPropsWorkflow = {
        typeOfDialog: undefined,
        props: undefined,
      };
      switch (journal?.Journal_Type) {
        case eJournalType["Assessment Journal"]:
          propsDialog = {
            typeOfDialog: WorkflowTypes.Journal_Add_Transaction,
          };
          break;
        case eJournalType["Deferred Duty Account Journal"]:
          propsDialog = {
            typeOfDialog: WorkflowTypes.Journal_Add_DDTransaction,
          };
          break;
        case eJournalType["PIC Journal"]:
          propsDialog = {
            typeOfDialog: WorkflowTypes.Journal_PIC_AddTransaction,
            props: { journalNumber: journal?.Journal_Number },
          };
          break;
      }
      return propsDialog;
    }, [journal]);

    return (
      <CCNavButton
        title="Add"
        disabled={disabled}
        onClick={() => {
          setListDialog([
            {
              type: propsWorkflow?.typeOfDialog,
              data: {},
              props: isNil(propsWorkflow?.props)
                ? {}
                : { ...propsWorkflow?.props },
            },
          ]);
        }}
      />
    );
  }
);
