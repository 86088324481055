import { LookupTable } from "@app/products/crms/[id]/model";
import { LookupTablesFormElement } from "@app/products/crms/system-admin/lookup-tables/[id]/components/forms/components/child-screens/general/components/form-element/_index";
import { useLookupTableStore } from "@app/products/crms/system-admin/lookup-tables/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import { default as React, useEffect, useRef } from "react";

export const LookupTablesForm = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();

  const { setOnSubmit, lookupTable, submitAction } = useLookupTableStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();
  const isNew = useIsNew();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { values, isModified, isValid } = event;
    const actionSubmit = event.event?.currentTarget.name as ActionSubmitActions;
    if (!(actionSubmit in ActionSubmitActions) || !isValid) return;

    if (!isModified && [ActionSubmitActions.Save].includes(actionSubmit))
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });

    if ([ActionSubmitActions.Save].includes(actionSubmit)) {
      submitAction(values as LookupTable, actionSubmit, isNew);
    }
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={lookupTable}
        key={JSON.stringify(lookupTable)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <LookupTablesFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
