import {
  Application,
  PPRApplicationIdentityPacket,
  Region,
} from "@app/products/town-planning/ppr/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const putValidateAndSave = async (
  id: number,
  saveApplication: boolean,
  application: Application
): Promise<APIResponse<PPRApplicationIdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<PPRApplicationIdentityPacket>(
      `/api/tp/ppr/internal/application/${id}/validatesave?saveApplication=${saveApplication}`,
      application
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getPPRSummaryById = async (
  pprId: number,
  isPSAR: boolean = false
): Promise<APIResponse<Application | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Application>(
      `/api/tp/Internal/tpapplication/${pprId}/?isPSAR=${isPSAR}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postPPR = async (
  requestBody: Application
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post<Application>(
      `/api/tp/ppr/internal/application/pprapplication`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postPSAR = async (
  requestBody: Application
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post<Application>(
      `/api/tp/ppr/internal/application/psarapplication`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

//@ TODO: Handle error when unify the API respone structure with onshore team
export const getRegionByPostcodeAndSuburb = async (
  postcode: string,
  suburb: string
): Promise<APIResponse<Region | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Region>(
      `/api/core/internal/regionbypostcode`,
      { params: { postcode: postcode, suburb: suburb } }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAmendPPR = async (
  requestBody: Application
): Promise<APIResponse<any | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<Application>(
      `api/tp/ppr/internal/amendpermit/application/create`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putAmendPPR = async (
  requestBody: Application
): Promise<APIResponse<any | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<Application>(
      `api/tp/ppr/internal/amendpermit/application`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const getPlanToComplyPPR = async (
  requestBody: Application
): Promise<APIResponse<any | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<Application>(
      `api/tp/ppr/internal/planstocomply/application/create`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
