import { colContactAssociationAssessmentRebateEntitlement } from "@app/products/property/components/associations/components/assessment-rebate-entitlement/contact-assessment-rebate/config";
import { DTO_AssociatedItem_AssessmentRebateEntitlement } from "@app/products/property/components/associations/components/assessment-rebate-entitlement/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IContactAssessmentRebateEntitlementProps {
  data: DTO_AssociatedItem_AssessmentRebateEntitlement[];
}

const nameOf = nameOfFactory<DTO_AssociatedItem_AssessmentRebateEntitlement>();
export const ContactAssociationAssessmentRebateEntitlement = ({
  data,
}: IContactAssessmentRebateEntitlementProps) => {
  return (
    <CCGrid
      data={data}
      columnFields={colContactAssociationAssessmentRebateEntitlement}
      primaryField={nameOf("Id")}
    />
  );
};
