import { DTO_DeferredDuty_Account_Detail } from "@app/products/property/deferred-duty/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { nameOfFactory } from "@common/utils/common";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCPercentInput } from "@components/cc-percent-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IDeferredDutySummaryProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<DTO_DeferredDuty_Account_Detail>();
export const DeferredDutyGeneralFormElement = observer(
  ({ formRenderProps }: IDeferredDutySummaryProps) => {
    const { currentOrganisationMode } = useCommonProductStore();
    const isActro = currentOrganisationMode(OrganisationMode.ACTRO);
    return (
      <FormElement className="cc-deferred-duty-form-body">
        <section className="cc-field-group">
          <div className="cc-field">
            <label className="cc-label">Property</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Assessment</label>
                  <Field
                    readOnly
                    name={nameOf("AssessmentReference")}
                    placeholder={"Assessment"}
                    component={CCInput}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Assessment ID</label>
                  <Field
                    readOnly
                    name={nameOf("AssessmentId")}
                    placeholder={"Assessment ID"}
                    component={CCInput}
                  />
                </div>
              </div>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">Property address</label>
                  <Field
                    readOnly
                    name={nameOf("PropertyAddress")}
                    placeholder={"Property address"}
                    component={CCTextArea}
                    rows={3}
                  />
                </div>
              </div>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">Name and address</label>
                  <Field
                    readOnly
                    name={nameOf("NameAndAddress")}
                    placeholder={"Name and address"}
                    component={CCTextArea}
                    rows={3}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Description</label>
                  <Field
                    readOnly
                    name={nameOf("Description")}
                    placeholder={"Description"}
                    component={CCTextArea}
                    rows={3}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <section className="cc-field-group">
          <div className="cc-field">
            <label className="cc-label">Account</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Account</label>
                  <Field
                    readOnly
                    name={nameOf("AccountNumber")}
                    placeholder={"Account"}
                    component={CCInput}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Check digit</label>
                  <Field
                    readOnly
                    name={nameOf("checkDigit")}
                    placeholder={"Check digit"}
                    component={CCInput}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Status</label>
                  <Field
                    readOnly
                    name={nameOf("Status")}
                    placeholder={"Status"}
                    component={CCInput}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <section className="cc-field-group">
          <div className="cc-field">
            <label className="cc-label">Balances</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Duty balance</label>
                  <Field
                    disabled
                    name={nameOf("DutyBalance")}
                    component={CCCurrencyInput}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Interest balance</label>
                  <Field
                    disabled
                    name={nameOf("InterestBalance")}
                    component={CCCurrencyInput}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Other balance</label>
                  <Field
                    disabled
                    name={nameOf("OtherBalance")}
                    component={CCCurrencyInput}
                  />
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Total outstanding</label>
                  <Field
                    disabled
                    name={nameOf("TotalOutstanding")}
                    component={CCCurrencyInput}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <section className="cc-field-group">
          <div className="cc-field">
            <label className="cc-label">Duty</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Original duty assessed</label>
                  <Field
                    disabled
                    name={nameOf("OriginalDutyAssessed")}
                    component={CCCurrencyInput}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Duty deferred</label>
                  <Field
                    disabled
                    name={nameOf("DutyDeferred")}
                    component={CCCurrencyInput}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Execution date</label>
                  <Field
                    disabled
                    name={nameOf("ExecutionDate")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                  />
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Deferment date</label>
                  <Field
                    disabled
                    name={nameOf("DefermentDate")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Payment commencement</label>
                  <Field
                    disabled
                    name={nameOf("PaymentCommencement")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Payment completion</label>
                  <Field
                    disabled
                    name={nameOf("PaymentCompletion")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <section className="cc-field-group">
          <div className="cc-field">
            <label className="cc-label">TRS information</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Client ID</label>
                  <Field
                    readOnly
                    name={nameOf("ClientId")}
                    placeholder={"Client ID"}
                    component={CCInput}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">TRS assessment ID</label>
                  <Field
                    readOnly
                    name={nameOf("TRSAsessmentId")}
                    placeholder={"Assessment ID"}
                    component={CCInput}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Lodgement number</label>
                  <Field
                    readOnly
                    name={nameOf("LodgementNumber")}
                    placeholder={"Lodgement number"}
                    component={CCInput}
                  />
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Revenue type</label>
                  <Field
                    readOnly
                    name={nameOf("RevenueType")}
                    placeholder={"Revenue type"}
                    component={CCInput}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <section className="cc-field-group">
          <div className="cc-field">
            <label className="cc-label">Interest</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">
                    Interest calculated to date
                  </label>
                  <Field
                    disabled
                    name={nameOf("InterestCalculatedToDate")}
                    placeholder={"Interest calculated to date"}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Interest rate</label>
                  <Field
                    disabled
                    name={nameOf("InterestRate")}
                    placeholder={"Interest rate"}
                    component={CCPercentInput}
                  />
                </div>
                <div className="cc-field">
                  <div className="cc-field">
                    <label className="cc-label">
                      Minimum amount for interest
                    </label>
                    <Field
                      disabled
                      name={nameOf("MinimumAmountOfInterestRate")}
                      component={CCCurrencyInput}
                    />
                  </div>
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Premium rate</label>
                  <Field
                    name={nameOf("PremiumRate")}
                    placeholder={"Premium rate"}
                    component={CCPercentInput}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        {!isActro && (
          <>
            <section className="cc-field-group">
              <div className="cc-field">
                <label className="cc-label">Instalment details</label>
                <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Instalment frequency</label>
                      <Field
                        readOnly
                        name={nameOf("InstallmentFrequency")}
                        placeholder={"Instalment frequency"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Number of instalment</label>
                      <Field
                        readOnly
                        name={nameOf("NoOfInstallments")}
                        placeholder={"Number of instalment"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">
                        Current instalment number
                      </label>
                      <Field
                        readOnly
                        name={nameOf("CurrentInstallmentNumber")}
                        placeholder={"Current instalment number"}
                        component={CCInput}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Agreed instalments</label>
                      <Field
                        disabled
                        name={nameOf("AgreedInstallment")}
                        component={CCCurrencyInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Current instalment</label>
                      <Field
                        disabled
                        name={nameOf("CurrentInstallment")}
                        component={CCCurrencyInput}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Next instalment date</label>
                      <Field
                        disabled
                        name={nameOf("NextInstallmentDate")}
                        component={CCDatePicker}
                        format={DATE_FORMAT.DATE_CONTROL}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">Description</label>
                  <Field
                    readOnly
                    name={nameOf("Description")}
                    placeholder={"Description"}
                    component={CCTextArea}
                    rows={3}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Comment</label>
                  <Field
                    readOnly
                    name={nameOf("Comment")}
                    placeholder={"Comment"}
                    component={CCTextArea}
                    rows={3}
                  />
                </div>
              </div>
            </section>
            <br />
          </>
        )}
      </FormElement>
    );
  }
);
