import { PPREventType } from "@app/products/town-planning/ppr/constant";
import { ExistedPPR } from "@app/products/town-planning/ppr/[id]/components/forms/existed/_index";
import { NewPPR } from "@app/products/town-planning/ppr/[id]/components/forms/new/_index";
import { IPPRApplicationParentSection } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManagePPR = () => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { resetStore, loadPPR, setParentSection } = usePPRStore();
  const { resetMenu } = useCCSubActionBarStore();

  useEffect(() => {
    if (location.state) {
      setParentSection(location.state as IPPRApplicationParentSection);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  useAddUniqueEventEmitter([
    {
      eventType: PPREventType.RefreshApplication,
      listener: () => {
        loadPPR(parseInt(id), isNew);
      },
    },
  ]);

  useEffect(() => {
    loadPPR(parseInt(id), isNew);
  }, [id, isNew, loadPPR]);

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  if (isNew) {
    return <NewPPR />;
  }
  return <ExistedPPR />;
};

export default observer(ManagePPR);
