import { localLawsFormValidator } from "@app/products/local-laws/util";
import { LocalLawsFormElement } from "@app/products/local-laws/[id]/components/child-screens/general/components/form-element/_index";
import { useLocalLawsStore } from "@app/products/local-laws/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export const LocalLawsForm = () => {
  const { localLaws, createLocalLaw, updateLocalLaw } = useLocalLawsStore();
  const isNew = useIsNew();
  const handleSubmit = (data: any) => {
    if (isNew) {
      createLocalLaw(data);
    } else {
      updateLocalLaw(data);
    }
  };
  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={localLaws}
        validator={localLawsFormValidator}
        render={(formRenderProps: FormRenderProps) => (
          <LocalLawsFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
};
