import { decryptionHtmlForEditor } from "@app/core/communication/dialogs/components/form-elememts/message-template/components/cc-edit-html-tool/util";
import { getColorScrollBar } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/guidance/util";
import DOMPurify from "dompurify";
import React from "react";

interface IGuidanceContentIframeProps {
  content: string;
}
export const GuidanceContentIframe = ({
  content,
}: IGuidanceContentIframeProps) => {
  const sanitizeGuidanceContent = DOMPurify.sanitize(
    decryptionHtmlForEditor(content ?? ""),
    {
      FORCE_BODY: true,
      FORBID_TAGS: ["script"],
    }
  );
  const scrollBarColor = getColorScrollBar();
  return (
    <iframe
      className="cc-right-side-bar-guidance"
      srcDoc={
        `<style>
          html {
            word-wrap: break-word !important;
          }
          ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }
          ::-webkit-scrollbar-track {
            background: ${scrollBarColor.trackColor};
          }
          ::-webkit-scrollbar-thumb {
            border-radius: 9999px;
            background: ${scrollBarColor.thumbColor};
          }
          ::-webkit-scrollbar-thumb:hover {
              background: ${scrollBarColor.thumbHoverColor};
              border-radius: 9999px;
          }
        </style>` + sanitizeGuidanceContent
      }
      title="Iframe"
    ></iframe>
  );
};
