import { INotificationSpatialWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/model";
import { WorkflowApprovalStatus } from "@app/products/property/components/action-bar/property-workflow/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";
import { isNil } from "lodash";

export const isDisabledApproveButton = (workflowHeader: DTO_WorkflowHeader) => {
  return (
    (isSpatialGISMode(workflowHeader) &&
      isNil(workflowHeader?.WorkflowDraft?.WD_GIS_Fetched_Date)) ??
    false
  );
};

export const isHiddenRejectButton = (workflowHeader: DTO_WorkflowHeader) => {
  return (
    (isSpatialGISMode(workflowHeader) &&
      !isNil(workflowHeader?.WorkflowDraft?.WD_GIS_Mapping_Invoked_Date) &&
      !workflowHeader?.Flags?.Can_Reject) ??
    false
  );
};

export const getNotificationSpatialWorkflow = (
  workflowHeader: DTO_WorkflowHeader
): undefined | INotificationSpatialWorkflow => {
  const workflow_Draft = workflowHeader?.WorkflowDraft;
  let resultNotification: undefined | INotificationSpatialWorkflow = undefined;
  if (isSpatialGISMode(workflowHeader)) {
    resultNotification = {};
    if (
      !isNil(workflow_Draft?.WD_Mapping_Status) &&
      !isNil(workflow_Draft?.WD_Mapping_Status_Message) &&
      workflow_Draft?.WD_Mapping_Status_Message?.length
    ) {
      if (workflow_Draft?.WD_Mapping_Status) {
        resultNotification.mapping = {
          loadSuccess: {
            message: {
              messageTitle:
                workflow_Draft?.WD_Mapping_Status_Message ??
                "Mapping in progress",
            },
          },
          loadFail: undefined,
        };
      } else {
        resultNotification.mapping = {
          loadSuccess: undefined,
          loadFail: {
            message: {
              messageTitle:
                workflow_Draft?.WD_Mapping_Status_Message ??
                "Mapping in failed",
            },
          },
        };
      }
    }
    if (
      !isNil(workflow_Draft?.WD_Fetch_Status) &&
      !isNil(workflow_Draft?.WD_Fetch_Status_Message) &&
      workflow_Draft?.WD_Fetch_Status_Message?.length
    ) {
      if (workflow_Draft?.WD_Fetch_Status) {
        resultNotification.fetch = {
          loadSuccess: {
            message: {
              messageTitle:
                workflow_Draft?.WD_Fetch_Status_Message ??
                "Fetch data successful",
            },
          },
          loadFail: undefined,
        };
      } else {
        resultNotification.fetch = {
          loadSuccess: undefined,
          loadFail: {
            message: {
              messageTitle:
                workflow_Draft?.WD_Fetch_Status_Message ?? "Fetch data failed",
            },
          },
        };
      }
    }
  }
  return resultNotification;
};

export const isSpatialGISMode = (workflowHeader?: DTO_WorkflowHeader) => {
  if (isNil(workflowHeader)) return false;
  return (
    workflowHeader?.WorkflowDraft?.WD_Workflow_Approval_Status ===
    WorkflowApprovalStatus.AwaitingGisApproval
  );
};
