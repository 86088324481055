import { EventBatchCloseDialog } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/event-bactch-close/_index";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { EventView } from "@app/products/crms/all-events/by-refno/model";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export interface ICloseEventsButton {
  isDisabled?: boolean;
  isVisible?: boolean;
}

export const CloseEventsButton = observer(
  ({ isDisabled = false, isVisible = true }: ICloseEventsButton) => {
    const { gridSelectedRows } = useCCProductListViewStore();

    const {
      isLoadingBatchCloseEvent,
      batchCloseEventHandler,
      isShowBatchCloseEventsDialog,
      eventBatchClose,
      setIsShowBatchCloseEventsDialog,
      setEventBatchClose,
    } = useCRMSEventStore();

    const getSelectedEventIds = (data: EventView[]) => {
      return data.map((item: EventView) => item.ID);
    };

    if (!isVisible) return null;

    return (
      <>
        <CCNavButton
          title={"Close Event(s)"}
          onClick={() => {
            setEventBatchClose({
              DateClosed: new Date(),
              ClosureComment: "",
              FinalNotification: false,
              Flag_HardRubbish: false,
            });
            setIsShowBatchCloseEventsDialog(true);
          }}
          name={ActionSubmitActions.CloseEvent}
          disabled={
            isLoadingBatchCloseEvent ||
            gridSelectedRows.length === 0 ||
            isDisabled
          }
        />

        {isShowBatchCloseEventsDialog && (
          <EventBatchCloseDialog
            eventBatchClose={eventBatchClose}
            onClosePopup={() => {
              setIsShowBatchCloseEventsDialog(false);
              setEventBatchClose(undefined);
            }}
            onSubmit={(data: any) => {
              batchCloseEventHandler({
                EventBatchClose: data,
                SelectedEvent: getSelectedEventIds(gridSelectedRows),
              });
            }}
            isLoading={isLoadingBatchCloseEvent}
          />
        )}
      </>
    );
  }
);
