import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";

export const mockLocalLawsContacts: ICommonContactItemRender[] = [
  {
    ID: "3173054",
    Type: "Permit Holder",
    DisplayName: "Greyson Turcotte",
    Email: "Macy.Lind37@hotmail.com",
    Mobile: "303.861.8356 x5924",
    WorkPhone: "(147) 255-6449 x9951",
    PreferredMethod_ENUM: "0",
  },
];
