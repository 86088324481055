import { APIResponse } from "@common/apis/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { DTO_DynamicQuestionnaire } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/model";

export const getResponsesQuestions = async (
  id: number
): Promise<APIResponse<DTO_DynamicQuestionnaire>> => {
  try {
    const response = await CoreAPIService.getClient().get(
      `/api/property/int/questionnaire/${id}/questions`
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
