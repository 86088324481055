import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useMeasure } from "react-use";
import "./_index.scss";
import { IReferenceComponents, useReferenceSideBarStore } from "./store";

interface IReferenceSideBar {
  components?: IReferenceComponents[];
}
export const ReferenceSideBar = observer(
  ({ components }: IReferenceSideBar) => {
    const { referenceComponents } = useReferenceSideBarStore();
    const [refReferencesSideBar, { width }] = useMeasure();
    const [selectedTab, setSelectedTab] = useState(0);
    const [isVisibleNextBtn, setIsVisibleNextBtn] = useState(false);
    const [isVisiblePrevBtn, setIsVisiblePrevBtn] = useState(false);

    const tabstripRef = useRef<any | null>(null);
    const nextBtnRef = useRef<HTMLSpanElement | null>(null);
    const prevBtnRef = useRef<HTMLSpanElement | null>(null);
    const previousClientWidth = useRef<number | null>(null);
    const scrollSpace = useRef(0);
    const scrollAmount = useRef(0);

    useEffect(() => {
      if (tabstripRef.current) {
        let headerTab = document.querySelector(".reference-tabstrip ul");
        if (headerTab) {
          scrollSpace.current = headerTab.scrollWidth - headerTab.clientWidth;
          previousClientWidth.current = headerTab.clientWidth;
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabstripRef.current]);

    const handleTabLeftRightArrowChange = () => {
      let tabstrip = tabstripRef.current;

      if (tabstrip) {
        let headerTab = document.querySelector(".reference-tabstrip ul");

        if (
          previousClientWidth.current &&
          headerTab &&
          !(previousClientWidth.current - headerTab.clientWidth === 0)
        ) {
          if (previousClientWidth.current - headerTab.clientWidth > 0) {
            scrollSpace.current +=
              previousClientWidth.current - headerTab.clientWidth;
          } else {
            if (
              scrollSpace.current >
              headerTab.clientWidth - previousClientWidth.current
            ) {
              scrollSpace.current -=
                headerTab.clientWidth - previousClientWidth.current;
            }
          }
          previousClientWidth.current = width;
        }
        if (headerTab) {
          if (headerTab.clientWidth === headerTab.scrollWidth) {
            setIsVisibleNextBtn(false);
            setIsVisiblePrevBtn(false);
          } else if (scrollSpace.current === 0) {
            setIsVisibleNextBtn(false);
            setIsVisiblePrevBtn(true);
          } else if (scrollAmount.current === 0) {
            setIsVisibleNextBtn(true);
            setIsVisiblePrevBtn(false);
          } else {
            setIsVisibleNextBtn(true);
            setIsVisiblePrevBtn(true);
          }
        }
      }
    };

    const handlePrevBtnClick = () => {
      let headerTab = document.querySelector(".reference-tabstrip ul");
      if (headerTab) {
        if (scrollAmount.current < headerTab.clientWidth) {
          scrollSpace.current += scrollAmount.current;
          scrollAmount.current = 0;
        } else {
          scrollAmount.current -= headerTab.clientWidth;
          scrollSpace.current += headerTab.clientWidth;
        }
        headerTab.scroll({
          top: 0,
          left: scrollAmount.current,
          behavior: "smooth",
        });

        if (scrollAmount.current === 0) {
          setIsVisiblePrevBtn(false);
        }

        if (scrollSpace.current > 0) {
          setIsVisibleNextBtn(true);
        }
      }
    };

    const handleNextBtnClick = () => {
      let headerTab = document.querySelector(".reference-tabstrip ul");
      if (headerTab) {
        if (scrollSpace.current < headerTab.clientWidth) {
          scrollAmount.current += scrollSpace.current;
          scrollSpace.current = 0;
        } else {
          scrollAmount.current += headerTab.clientWidth;
          scrollSpace.current -= headerTab.clientWidth;
        }
        headerTab.scroll({
          top: 0,
          left: scrollAmount.current,
          behavior: "smooth",
        });

        if (scrollAmount.current > 0) {
          setIsVisiblePrevBtn(true);
        }

        if (scrollSpace.current === 0) {
          setIsVisibleNextBtn(false);
        }
      }
    };

    useEffect(() => {
      handleTabLeftRightArrowChange();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    const renderComponents = components ?? referenceComponents;

    return (
      <div ref={refReferencesSideBar} className="cc-reference-sidebar">
        {isVisibleNextBtn && (
          <span
            ref={nextBtnRef}
            className="cc-tabstrip-button cc-tabstrip-next"
            onClick={handleNextBtnClick}
          >
            <i className="fas fa-caret-right"></i>
          </span>
        )}
        {isVisiblePrevBtn && (
          <span
            ref={prevBtnRef}
            className="cc-tabstrip-button cc-tabstrip-prev"
            onClick={handlePrevBtnClick}
          >
            <i className="fas fa-caret-left"></i>
          </span>
        )}
        <TabStrip
          ref={tabstripRef}
          className="reference-tabstrip"
          selected={selectedTab}
          onSelect={(e) => {
            setSelectedTab(e.selected);
          }}
        >
          {renderComponents?.map(
            (item: IReferenceComponents, index: number) => {
              if (item?.isVisible === false) return null;
              return (
                <TabStripTab title={item.title} key={index}>
                  {item.component}
                </TabStripTab>
              );
            }
          )}
        </TabStrip>
      </div>
    );
  }
);
