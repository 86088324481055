import { UITownPlanning } from "@app/products/town-planning/model";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putPSARApproveReportAndResponse } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/approve-report-and-response/api";
import {
  IProponentDecision,
  PSAR_PlannerDecision_Number,
} from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/approve-decision/model";
import { PSARApproveDecisionDialog } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/approve-decision/_index";

import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";

import { isSuccessIdentityPacket } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const PSARApproveReportAndResponseButton = observer(() => {
  const { ppr, onSubmit, pprId, setPPRWithLoading } = usePSARStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.ApproveReport.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const isVisible = useMemo(() => {
    return ppr?.Status_ENUM === Application_Status.TeamLeaderDecisionPending;
  }, [ppr]);

  const handleOnSubmit = async (
    decision: PSAR_PlannerDecision_Number,
    id?: number,
    decisionDate?: Date
  ) => {
    if (!id) return;
    setIsLoading(true);
    const validatedDecisionDate = decisionDate ? decisionDate : new Date();
    const response = await putPSARApproveReportAndResponse(
      id,
      decision,
      validatedDecisionDate
    );

    if (isSuccessIdentityPacket(response) && response.data?.Application) {
      setIsShowDialog(false);
      appNotificationStore.clearNotifications();
      setPPRWithLoading(response.data.Application);
      appNotificationStore.pushNotification({
        type: "success",
        title: "Application approved.",
      });
      if (
        response.data?.Notifications &&
        response.data?.Notifications.length > 0
      ) {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Approve application",
          type: "warning",
          description: response.data?.Notifications,
        });
      }
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: "The approve application workflow could not be completed.",
          description: response.data?.Errors ?? response.statusText,
          type: "error",
        },
      ]);
    }
    setIsLoading(false);
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Approve application"
        onClick={onSubmit}
        name={PSARSubmitActions.ApproveReportAndResponse}
      />

      {isShowDialog && (
        <PSARApproveDecisionDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={(value: IProponentDecision) => {
            handleOnSubmit(
              value?.Decision as PSAR_PlannerDecision_Number,
              pprId,
              value?.DecisionDate
            );
          }}
          isSubmitLoading={isLoading}
          notifications={notifications}
        />
      )}
    </>
  ) : null;
});
