import { useAnimalsSettingStore } from "@app/products/animals/system-admin/settings/_category/store";
import { DynamicGridFields } from "@app/products/animals/system-admin/settings/_id/components/dynamic-grid-fields/_index";
import { ICommonFormProps } from "@app/products/animals/system-admin/settings/_id/main/model";
import { useAnimalsSettingManageStore } from "@app/products/animals/system-admin/settings/_id/store";
import { ILookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { DropdownSelectGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown-select/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOfLookupItem = nameOfFactory<ILookupItem>();
export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { onChange, valueGetter } = formRenderProps;
  const { getConfigDataField } = useAnimalsSettingStore();
  const { isEditing } = useAnimalsSettingManageStore();

  const eSharePointKennelsStorageMethod =
    ECorporateSettingsField.SharePoint_Kennels_StorageMethod;

  const eInputFiels = [
    ECorporateSettingsField.SharePoint_Kennels_ParentContainerFormat,
    ECorporateSettingsField.SharePoint_Kennels_TitleFormat,
    ECorporateSettingsField.SharePoint_Kennels_DataSetCustomField,
  ];

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <DropdownSelectGlobalSettings
          data={getConfigDataField(eSharePointKennelsStorageMethod)}
          isEditing={isEditing}
          onChange={(e: DropDownListChangeEvent) => {
            onChange(`${eSharePointKennelsStorageMethod}`, {
              value: e.value?.Id ?? null,
            });
          }}
          value={getDropdownValue(
            valueGetter(`${eSharePointKennelsStorageMethod}`),
            getConfigDataField(eSharePointKennelsStorageMethod)?.LookupItems ??
              [],
            nameOfLookupItem("Id")
          )}
        />
        <SwitchGlobalSettings
          data={getConfigDataField(
            ECorporateSettingsField.SharePoint_Kennels_UseSiteCollectionUrl
          )}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <InputGlobalSettings
          data={getConfigDataField(
            ECorporateSettingsField.SharePoint_Kennels_SiteCollectionUrl
          )}
          isEditing={isEditing}
        />

        {eInputFiels.map((eField: ECorporateSettingsField) => (
          <InputGlobalSettings
            data={getConfigDataField(eField)}
            isEditing={isEditing}
          />
        ))}
      </div>

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <DynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
