import { history } from "@/AppRoutes";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { CRMS_SYSTEM_ADMIN_ROSTER_ROUTE } from "@app/products/crms/system-admin/constant";
import {
  getRosterById,
  getRosterTypes,
  postRosterEventHandler,
  postSaveRoster,
} from "@app/products/crms/system-admin/rosters/[id]/api";
import {
  Roster,
  RosterHandlerRequest,
} from "@app/products/crms/system-admin/rosters/[id]/model";
import { IPSARApplicationParentSection } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class RosterStore {
  private _roster?: Roster = undefined;
  private _rosterTypes?: KeyValuePacket[] = undefined;
  private _notification?: IAppNotificationItemAddProps = undefined;
  private _isFormModified: boolean = false;
  private _isLoading: boolean = false;
  private _isLoadingDetails: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _parentSection?: IPSARApplicationParentSection = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get roster() {
    return toJS(this._roster);
  }
  setRoster = (roster?: Roster) => {
    runInAction(() => {
      this._roster = roster;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get rosterTypes() {
    return this._rosterTypes;
  }
  setRosterTypes = (rosterTypes?: IKeyValuePacket[]) => {
    runInAction(() => {
      this._rosterTypes = rosterTypes;
    });
  };

  get notification() {
    return this._notification;
  }
  setNotification = (notification?: IAppNotificationItemAddProps) => {
    runInAction(() => {
      this._notification = notification;
    });
  };

  get isFormModified() {
    return this._isFormModified;
  }
  setIsFormModified = (isFormModified: boolean) => {
    runInAction(() => {
      this._isFormModified = isFormModified;
    });
  };

  get isLoadingDetails() {
    return this._isLoadingDetails;
  }
  setIsLoadingDetails = (isLoadingDetails: boolean) => {
    runInAction(() => {
      this._isLoadingDetails = isLoadingDetails;
    });
  };

  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: IPSARApplicationParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._roster = undefined;
      this._isLoading = false;
      this._isLoadingDetails = false;
      this._responseLoadError = undefined;
      this._onSubmit = undefined;
      this._isFormModified = false;
      this._notification = undefined;
      this._rosterTypes = undefined;
      this._parentSection = undefined;
    });
  };

  loadRoster = async (rosterId: number, isNew: boolean) => {
    let errorResponse = undefined;
    let roster = undefined;
    let rosterTypes = undefined;
    this.setIsLoading(true);
    this.setIsLoadingDetails(true);
    if (isNew) {
      const [response, responseRosterTypes] = await Promise.all([
        getRosterById(0),
        getRosterTypes(),
      ]);
      if (
        isSuccessResponse(response) &&
        response?.data &&
        isSuccessResponse(responseRosterTypes) &&
        responseRosterTypes?.data
      ) {
        roster = response.data;
        rosterTypes = responseRosterTypes.data;
      } else {
        errorResponse = {
          status: response.status ?? responseRosterTypes.status,
          error: response.error ?? responseRosterTypes.error,
        };
      }
    } else {
      const [response, responseRosterTypes] = await Promise.all([
        getRosterById(rosterId),
        getRosterTypes(),
      ]);
      if (
        isSuccessResponse(response) &&
        response?.data &&
        isSuccessResponse(responseRosterTypes) &&
        responseRosterTypes?.data
      ) {
        roster = response.data;
        rosterTypes = responseRosterTypes.data;
      } else {
        errorResponse = {
          status: response.status ?? responseRosterTypes.status,
          error: response.error ?? responseRosterTypes.error,
        };
      }
      // Push notification
      if (this.parentSection?.notification) {
        this.parentSection?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
        this.setParentSection({ ...this._parentSection, notification: [] });
      }
      if (this.notification) {
        appNotificationStore.pushNotification(this.notification);
        this.setNotification(undefined);
      }
    }
    this.setRoster(roster);
    this.setRosterTypes(rosterTypes);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    this.setIsLoadingDetails(false);
  };

  loadRosterDetails = async (rosterId: number) => {
    this.setIsLoadingDetails(true);
    const response = await getRosterById(rosterId);
    this.setIsLoadingDetails(false);
    if (isSuccessResponse(response) && response?.data) {
      this.setRoster(response.data);
    } else {
      this.setResponseLoadError({
        status: response.status,
        error: response.error,
      });
    }
  };

  submitAction = async (
    roster: Roster,
    isNew: boolean = false,
    action: ActionSubmitActions
  ) => {
    if (action === ActionSubmitActions.Save) {
      this.setIsLoading(true);
      const response = await postSaveRoster(roster);
      this.setIsLoading(false);
      if (isSuccessResponse(response)) {
        const newRosterId = response.data?.ID;
        if (this.isFormModified) this.setIsFormModified(false);

        if (isNew) {
          history.replace(
            `${CRMS_SYSTEM_ADMIN_ROSTER_ROUTE}/${response?.data?.ID}`,
            {
              notification: [
                {
                  title: "Record successfully saved.",
                  type: "success",
                },
              ],
            }
          );
        } else {
          this.loadRoster(newRosterId, isNew);
          this.setNotification({
            title: "Record successfully saved.",
            type: "success",
          });
        }
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Roster could not be saved.",
          type: "error",
          description: response.data?.Errors,
        });
      }
    }
  };

  rosterChangeHandler = async (
    rosterRequest: RosterHandlerRequest,
    errorMsg: string
  ) => {
    this.setIsLoading(true);
    const response = await postRosterEventHandler(rosterRequest);

    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setIsFormModified(true);
      this.setRoster(response.data?.ReturnObj?.Roster);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: errorMsg,
        type: "error",
        description: response.error,
      });
    }
    this.setIsLoading(false);
  };
}

const RosterStoreContext = createContext(new RosterStore());
export const useRosterStore = () => {
  return useContext(RosterStoreContext);
};
