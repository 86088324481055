import { CRMSGridSearch } from "@app/products/crms/components/dialogs/crms-category-grid/_index";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { GridSearch } from "@components/cc-grid-search/_index";
import { ICCGridProps } from "@components/cc-grid/_index";
import { State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import React, { useEffect, useRef, useState } from "react";
import "./_index.scss";

interface IGridSelectionDialogProps extends Omit<ICCGridProps, "primaryField"> {
  titleHeader: string;
  height?: string | number;
  width?: string | number;
  onSubmit?: (
    e: any[],
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => void;
  setShowDialog: (isShow: boolean) => void;
  onCloseDialog: () => void;
  flag_btnClearSelection?: boolean;
  maxWidth?: string | number;
  maxHeight?: string | number;
  isLoading?: boolean;
  isCloseDialogAfterSubmit?: boolean;
  primaryField?: string;
  itemPerPage?: number;
  hasSearchField?: boolean;
  sortCol?: string;
  filterCol?: string;
  crmsGrid?: boolean;
}

export const GridSelectionDialog = ({
  titleHeader,
  height = 660,
  width,
  columnFields,
  onSubmit,
  dataUrl,
  data,
  selectableMode = "single",
  setShowDialog,
  onCloseDialog,
  flag_btnClearSelection,
  state,
  isLoading,
  primaryField,
  isCloseDialogAfterSubmit,
  selectedRows,
  itemPerPage,
  hasSearchField = false,
  sortCol,
  filterCol,
  crmsGrid = false,
  ...others
}: IGridSelectionDialogProps) => {
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [selectedItemCount, setSelectedItemCount] = useState<number>(0);
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [stateData, setStateData] = useState<State | undefined>(
    state ?? undefined
  );

  const handleSelectionChange = (dataItem: any) => {
    setSelectedData(dataItem);
  };

  useEffect(() => {
    setStateData(state);
  }, [state]);

  return (
    <div className="cc-grid-selection-dialog">
      <CCDialog
        height={height}
        maxHeight="80%"
        titleHeader={titleHeader}
        onClose={onCloseDialog}
        {...others}
        bodyElement={
          <>
            <CCLocalNotification ref={notificationRef} />
            {!crmsGrid ? (
              <GridSearch
                grid={{
                  primaryField: primaryField,
                  itemPerPage: itemPerPage,
                }}
                search={{
                  hasSearchField: hasSearchField,
                  filterCol: filterCol,
                  sortCol: sortCol ? sortCol : filterCol,
                }}
                state={stateData}
                columnFields={columnFields}
                dataUrl={dataUrl}
                data={data}
                selectableMode={selectableMode}
                selectedRows={selectedRows}
                setSelectedItemCount={setSelectedItemCount}
                handleSelectionChange={handleSelectionChange}
                onDataStateChange={(state: State) => {
                  setStateData(state);
                }}
              />
            ) : (
              <CRMSGridSearch
                grid={{
                  primaryField: primaryField,
                  itemPerPage: itemPerPage,
                }}
                search={{
                  hasSearchField: hasSearchField,
                  filterCol: filterCol,
                  sortCol: sortCol ? sortCol : filterCol,
                }}
                state={stateData}
                columnFields={columnFields}
                dataUrl={dataUrl}
                data={data}
                selectableMode={selectableMode}
                selectedRows={selectedRows}
                setSelectedItemCount={setSelectedItemCount}
                handleSelectionChange={handleSelectionChange}
                onDataStateChange={(state: State) => {
                  setStateData(state);
                }}
              />
            )}
          </>
        }
        footerElement={
          <>
            <div className="cc-dialog-footer-actions-right">
              <Button className={"cc-dialog-button"} onClick={onCloseDialog}>
                Cancel
              </Button>
              {flag_btnClearSelection && (
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  onClick={() => {
                    setShowDialog(false);
                  }}
                >
                  Clear Selection
                </Button>
              )}
              <Button
                className="cc-dialog-button"
                themeColor="primary"
                iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                disabled={
                  (selectedData?.length ?? selectedItemCount) === 0 || isLoading
                }
                onClick={() => {
                  isCloseDialogAfterSubmit && setShowDialog(false);
                  onSubmit && onSubmit(selectedData, notificationRef);
                }}
              >
                Select
              </Button>
            </div>
          </>
        }
      />
    </div>
  );
};
