import { AddCommentPropertyButton } from "@app/core/comments/components/buttons/add-comment/components/add-comment-property/_index";
import { PropertyComments } from "@app/core/comments/components/property-comments/_index";
import { DeleteChargeRunButton } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/buttons/delete/_index";
import { UpdateChargeRun } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/buttons/update/_index";
import { ChargeRunRebateAssessments } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/_index";
import { PropertyChargeAndNoticeRunsForm } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/general/_index";
import { ChargeRunLeviesRaised } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levies-raised/_index";
import { ChargeRunRebateLevySummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/_index";
import { ChargeRunRebateSummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/rebate-summary/_index";
import { ChargeRunRebatesApplied } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/rebates-applied/_index";
import { ChargeAndNoticeRunsDetailTab } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/reference-sidebar/detail/_index";
import { eChargeRunType } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/model";
import { useChargeRunsStore } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/store";
import { eDocumentTypeCategory } from "@app/products/property/components/action-bar/button/add-attachment/model";
import { AddAttachmentPropertyButton } from "@app/products/property/components/action-bar/button/add-attachment/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyActions } from "@app/products/property/components/child-screen/actions";
import { PropertyDocuments } from "@app/products/property/components/child-screen/documents/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ECustomColNameProperty } from "@app/products/property/config";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { Label } from "@common/stores/products/config";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { eAccordionType } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedPropertyChargeAndNoticeRuns = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const {
    chargeRun,
    chargeRunsId,
    isLoading,
    responseLoadError,
    loadChargeRuns,
  } = useChargeRunsStore();
  const { currentUserInfo } = useGlobalStore();
  const managePageUrl = window.location.pathname;

  //Get label
  const assessmentsLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessments
  );

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        chargeRunsId,
        chargeRun?.ChargeName,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Charge runs - ${chargeRunsId}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Property,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Charge_Run,
      Record_ID: chargeRunsId ?? 0,
    },
  ];

  const listPanelBar: IPropertyPanelBar[] = [
    {
      title: "Actions and Workflows",
      component: (
        <PropertyActions
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Charge_Run}
          componentNumber={eComponent.Charge_Run}
        />
      ),
      accordionType: eAccordionType.Actions_And_Workflows,
    },
    {
      title: assessmentsLabel,
      component: <ChargeRunRebateAssessments />,
    },
    {
      title: "Levy Summary",
      component: <ChargeRunRebateLevySummary />,
      isVisible: !isEqual(chargeRun?.ChargeRunType, eChargeRunType.PIC),
    },
    {
      title: "Rebate Summary",
      component: <ChargeRunRebateSummary />,
    },
    {
      title: "Comments",
      component: (
        <PropertyComments
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Charge_Run}
        />
      ),
    },
    {
      title: "Levies Raised",
      component: <ChargeRunLeviesRaised />,
    },
    {
      title: "Rebates Applied",
      component: <ChargeRunRebatesApplied />,
    },
    {
      title: "Documents",
      component: (
        <PropertyDocuments
          componentNumber={eComponent.Charge_Run}
          componentId={parseInt(id)}
        />
      ),
    },
  ];

  const getFormTitle = () => {
    return `Charge Run ${chargeRunsId}${
      chargeRun?.ChargeName ? `: ${chargeRun?.ChargeName}` : ""
    }`;
  };

  const isUpdated = chargeRun && chargeRun?.UpdatedBy ? true : false;

  const tagInTitle = isUpdated ? "Updated" : "Proposed";

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Charge Run" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadChargeRuns(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={getFormTitle()} badge={tagInTitle} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <DeleteChargeRunButton disabled={isUpdated} />
                <UpdateChargeRun
                  disabled={isUpdated}
                  isPicChargeRun={chargeRun?.CRT_Is_Pic ?? false}
                />
              </CCNavButton>,

              <CCNavButton title={"Add"} type="sub">
                <CCNavButton title={"Add item"} />
                <AddAttachmentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Charge_Run}
                  documentCategoryTypeId={
                    eDocumentTypeCategory.Charge_Run_Document
                  }
                />
                <AddCommentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Charge_Run}
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {chargeRun && (
                  <CCGeneralPanel
                    component={<PropertyChargeAndNoticeRunsForm />}
                  />
                )}
                <PropertyPanelBar
                  initialPanels={listPanelBar}
                  componentId={parseInt(id)}
                  componentNumber={eComponent.Charge_Run}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <ChargeAndNoticeRunsDetailTab />,
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={RECORDTYPE.CommunityProperty_Charge_Run}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
