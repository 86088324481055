import { APIResponseError } from "@common/apis/model";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { handleUpdateFormValid } from "@components/cc-form-step/util";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import {
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import {
  StepProps,
  Stepper,
  StepperChangeEvent,
} from "@progress/kendo-react-layout";
import { cloneDeep, isEqual } from "lodash";
import React, {
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import "./_index.scss";
import { IFormValid, IStep, IStepState } from "./model";

export interface ICCFormStep {
  onSubmit: (stepData: any, buttonId?: string, actions?: any) => void;
  onValueChange?: (stepData: any) => any;
  saveOnNextStep?: (
    stepData: any,
    filterSteps: any,
    keyStep: string
  ) => Promise<boolean>;
  onPrevStep?: (stepData: any, filterSteps: any, keyStep: string) => void;
  initialSteps: IStep[];
  renderForm: (renderProps: ICCFormStepRender) => any;
  initialValues?: any;
  listButtonId?: string[];
  localNotificationRef?: ICCLocalNotificationHandle | null;
}
export interface ICCFormStepRender {
  children: React.ReactElement;
  prevButton: ICCFormStepActionButton;
  nextButton: ICCFormStepActionButton;
  submitButton: ICCFormStepActionSingleSubmitButton;
  resetAllStepsAfter: (key?: string | undefined) => any;
  moveToStep: (key?: string | undefined) => void;
  isLastStep: boolean;
  isLoadingGetter: (type: "allStep" | "currentStep") => boolean;
  loadFailedGetter: (
    type: "allStep" | "currentStep"
  ) => ICCFormStepLoadFailed | undefined;
}

export interface ICCFormStepActionButton {
  disabled: boolean;
  onClick: (event: any) => void;
  label: string;
  idButton?: string;
}
export interface ICCFormStepActionSingleSubmitButton {
  disabled?: boolean;
  onClick: (event: any) => void;
  label?: string;
}
export interface ICCFormStepLoadFailed {
  onReload?: () => void;
  responseError?: APIResponseError;
}
export interface ICCFormStepNotificationHandle {
  setLoadingFormStep: (isLoading: boolean) => void;
  setLoadFailedFormStep: (loadFailed: ICCFormStepLoadFailed) => void;
  getNotificationFormStep: () => RefObject<ICCLocalNotificationHandle | null>;
  setStepsVisible: (stepsState: IStepState[], currentSteps?: IStep[]) => void;
}

export const getInitialFormValues = (steps: IStep[]) => {
  const values: any = {};
  steps.forEach((step: IStep) => {
    values[step.key] = { ...step.initialValues };
  });
  return values;
};
const listButtonSubmitId = ["cc-next-step-button", "cc-finish-step-button"];

export const CCFormStep = forwardRef<
  ICCFormStepNotificationHandle,
  ICCFormStep
>(
  (
    {
      onSubmit,
      renderForm,
      initialSteps,
      onValueChange,
      initialValues,
      listButtonId = [],
      saveOnNextStep,
      onPrevStep,
      localNotificationRef,
    }: ICCFormStep,
    ref
  ) => {
    //Step index in steps
    const [step, setStep] = useState<number>(0);

    //Steps array
    const [steps, setSteps] = useState<IStep[]>(initialSteps);

    //Current form valid for current step
    const [formValid, setFormValid] = useState<IFormValid>({
      isValid: undefined,
      stepIndex: -1,
    });

    // Global form values for all steps
    const [currentFormValue, setCurrentFormValues] = useState(
      initialValues ?? getInitialFormValues(initialSteps)
    );

    //Component ref
    const onSubmitRef = useRef<any>();
    const onChangeRef = useRef<any>();
    const childRef = useRef<
      | {
          getConfirm: (buttonId?: any) => Promise<boolean>;
        }
      | undefined
    >(undefined);

    //Filter steps = visible
    const filterSteps = steps.filter((step) => step.visible);
    const lastStepIndex = filterSteps.length - 1;
    const isLastStep = lastStepIndex === step;

    //Set is Loading FormStep
    const [isLoading, setIsLoading] = useState<boolean>(false);
    //Set APIResponseError and Reload function
    const [loadFailed, setLoadFailed] = useState<
      ICCFormStepLoadFailed | undefined
    >();
    //Set Local Notification
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(
      localNotificationRef as ICCLocalNotificationHandle
    );

    //Get step by step index
    const getStep = useCallback(
      (stepIndex) => {
        return filterSteps[stepIndex];
      },
      [filterSteps]
    );

    // Current step

    const currentStep = getStep(step);
    const currentStepIndex = steps.findIndex(
      (step) => step.visible && step.key === currentStep.key
    );

    useImperativeHandle(ref, () => ({
      setLoadingFormStep(isLoading: boolean) {
        setLoadFailed(undefined); //reset load failed when we load/reload formStep
        return setIsLoading(isLoading);
      },
      setLoadFailedFormStep(loadFailed: ICCFormStepLoadFailed) {
        setIsLoading(false); //stop loading when we have error
        return setLoadFailed(loadFailed);
      },
      getNotificationFormStep() {
        return notificationRef;
      },
      setStepsVisible(stepsState: IStepState[], currentSteps?: IStep[]) {
        return handleStepsVisible(stepsState, currentSteps);
      },
    }));

    const handleOnStepPrev = useCallback(
      async (event: any, currentFormValue: any) => {
        event.preventDefault();
        const nextStepIndex: number = Math.max(step - 1, 0);
        const keyStep = filterSteps[nextStepIndex].key;
        if (onPrevStep) {
          onPrevStep(currentFormValue, steps, keyStep);
        }
        setStep(nextStepIndex);
      },
      [step, filterSteps, onPrevStep, steps]
    );

    const handleOnStepChange = (e: StepperChangeEvent) => {
      const nextStepIndex =
        getStep(e.value).isValid !== undefined ? e.value : step;
      setStep(nextStepIndex);
    };

    // Set form Valid
    useEffect(() => {
      setSteps((steps) => {
        const newSteps = steps.map((step: IStep, index) => {
          if (index === formValid.stepIndex) {
            return {
              ...step,
              isValid: formValid.isValid,
            };
          }
          if (!formValid.isValid && index > formValid.stepIndex)
            return { ...step, isValid: undefined };
          return step;
        });
        return newSteps;
      });
    }, [formValid.stepIndex, formValid.isValid]);

    const handleSaveOnNextStep = useCallback(
      async (values: any, stepIndex: number) => {
        if (saveOnNextStep) {
          const keyStep = filterSteps[stepIndex - 1].key;
          if (keyStep && values?.[keyStep]) {
            // check key step must be belong to initialStep
            // else, the step won't go next
            await saveOnNextStep(values, filterSteps, keyStep).then((data) => {
              if (data) {
                setStep(stepIndex);
              }
            });
          } else if (!values?.[keyStep]) {
            console.warn(
              "The value of current step is undefined, please declare initial value for this step."
            );
          }
        } else {
          setStep(stepIndex);
        }
      },
      [filterSteps, saveOnNextStep]
    );

    const handleOnStepNext = useCallback(
      (event: FormSubmitClickEvent) => {
        // Check button id
        const currentIdButton: string = event.event?.currentTarget.id;
        // Extra task after submit
        const actions = event.event?.currentTarget?.actions;
        if (listButtonSubmitId.includes(currentIdButton)) {
          const nextStepIndex = Math.min(step + 1, lastStepIndex);
          // Check confirm dialog in the child
          if (childRef.current) {
            childRef.current
              .getConfirm(currentIdButton)
              .then((value: boolean) => {
                if (value) {
                  handleSaveOnNextStep(event.values, nextStepIndex);
                  if (isLastStep) {
                    onSubmit(event.values, currentIdButton, actions);
                  }
                }
              });
            return;
          }

          handleSaveOnNextStep(event.values, nextStepIndex);
          if (isLastStep) {
            onSubmit(event.values, currentIdButton, actions);
          }
        }
      },
      [step, lastStepIndex, handleSaveOnNextStep, isLastStep, onSubmit]
    );

    const handleSingleSubmitButton = useCallback(
      (event: FormSubmitClickEvent) => {
        const buttonIdSingle = event.event?.currentTarget.id;
        // Extra task after submit
        const actions = event.event?.currentTarget?.actions;
        // Check confirm dialog in the child
        if (childRef.current) {
          childRef.current.getConfirm().then(() => {
            onSubmit(event.values, buttonIdSingle, actions);
          });
          return;
        }
        onSubmit(event.values, buttonIdSingle, actions);
      },
      [onSubmit]
    );

    const handleSubmitForm = async (event: FormSubmitClickEvent) => {
      if (typeof subActionRef.current !== "function") {
        const buttonId = event.event?.currentTarget.id;
        if (listButtonId.includes(buttonId)) {
          handleSingleSubmitButton(event);
        } else {
          handleOnStepNext(event);
        }
        return;
      }

      //Clone submit event and store currentTarget to avoid losing currentTarget in case there is sub action
      const clonedSubmitEvent = {
        ...event,
        event: {
          ...event.event,
          currentTarget: event.event?.currentTarget,
        },
      } as FormSubmitClickEvent;

      //Execute the sub action before executing submit action
      await subActionRef.current();
      const buttonId = clonedSubmitEvent.event?.currentTarget.id;
      if (listButtonId.includes(buttonId)) {
        handleSingleSubmitButton(clonedSubmitEvent);
      } else {
        handleOnStepNext(clonedSubmitEvent);
      }
    };

    //Action that is executed before moving to other step or submit
    const subActionRef = useRef<any>(null);
    const setSubActionRef = (action?: () => void) => {
      subActionRef.current = action;
    };

    const handleMoveStep = async (
      mainAction: (event?: FormSubmitClickEvent) => void
    ) => {
      //If there is no sub action, main action is executed immediately
      if (typeof subActionRef.current !== "function") {
        mainAction();
        return;
      }

      //If there is no sub action, main action will be executed after sub action is completed
      await subActionRef.current();
      mainAction();
    };

    const setIsLoadingStep = useCallback(
      (isLoading: boolean, currentSteps?: IStep[]) => {
        setSteps((pre: IStep[]) => {
          const progressSteps = currentSteps || pre;
          const newStep = progressSteps.map((step: IStep) => {
            if (step.key === currentStep.key) {
              return {
                ...step,
                loadFailedStep: undefined,
                isLoadingStep: isLoading,
              };
            }
            return step;
          });
          return newStep;
        });
      },
      [currentStep.key]
    );

    const setLoadFailedStep = useCallback(
      (loadFailed?: ICCFormStepLoadFailed, currentSteps?: IStep[]) => {
        setSteps((pre: IStep[]) => {
          const progressSteps = currentSteps || pre;
          const newStep = progressSteps.map((step: IStep) => {
            if (step.key === currentStep.key) {
              return {
                ...step,
                isValid: formValid.isValid,
                isLoadingStep: false,
                loadFailedStep: loadFailed,
              };
            }
            return step;
          });
          return newStep;
        });
      },
      [currentStep.key, formValid.isValid]
    );

    const handleStepsVisible = useCallback(
      (stepState: IStepState[], currentSteps?: IStep[]) => {
        let newSteps = currentSteps ?? steps;
        let newCurrentStepIndex = currentStepIndex;
        newSteps = newSteps.map((step: IStep, index: number) => {
          const foundStep: IStepState | undefined = stepState.find(
            (stepP: IStepState) => stepP.key === step.key
          );
          if (foundStep) {
            if (foundStep.isClearData) {
              onChangeRef.current(step.key, {
                value:
                  initialSteps[index].initialValues ??
                  initialValues?.[step.key] ??
                  {},
              });
            }
            return {
              ...step,
              visible: foundStep.visible,
              label: foundStep.label ? foundStep.label : step.label,
              isValid: foundStep.isClearData ? undefined : step?.isValid,
            } as IStep;
          }
          return step;
        });

        const cloneNewStep: any = cloneDeep(newSteps);
        const cloneStepSecond = cloneDeep(newSteps);
        const listStepVisible = cloneStepSecond.filter((item) => item.visible);
        cloneNewStep.forEach((item: any) => {
          const currItem = listStepVisible.findIndex(
            (step) => item.key === step.key
          );
          if (currItem !== -1) {
            item.newIndex = currItem;
          }
        });

        const oldIndexStep = getNearestStepVisible(
          currentStepIndex,
          cloneNewStep
        );
        if (oldIndexStep === 0) {
          newCurrentStepIndex = 0;
        } else {
          newCurrentStepIndex = cloneNewStep?.[oldIndexStep]?.newIndex;
        }

        //Update current step index
        setStep(newCurrentStepIndex);
        //Update steps
        setSteps(newSteps);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [steps, currentStepIndex, initialSteps, initialValues]
    );

    const getNearestStepVisible = (
      currentIndexFullStep: number,
      steps: any
    ): number => {
      if (currentIndexFullStep === 0) return 0;
      if (steps[currentIndexFullStep]?.visible) return currentIndexFullStep;
      currentIndexFullStep--;
      return getNearestStepVisible(currentIndexFullStep, steps);
    };

    const handleResetAllStepsAfter = useCallback(
      (key?: string) => {
        // if have key step => reset all steps after step with provided key, otherwise reset all after current step
        const stepIndex = !key
          ? currentStepIndex
          : steps.findIndex((step) => step.key === key);
        if (stepIndex === -1) return;

        const newSteps: any = steps.map((step: IStep, index: number) => {
          if (index > stepIndex) {
            onChangeRef.current(step.key, {
              value: initialSteps[index].initialValues,
            });
            return {
              ...step,
              isValid: initialSteps[index].isValid,
            };
          }
          return step;
        });
        setSteps(newSteps);
        return newSteps;
      },
      [steps, initialSteps, currentStepIndex]
    );

    const moveToStep = (key?: string) => {
      if (!key) {
        const stepIndex = filterSteps.findIndex((step) => step.key === key);
        if (stepIndex !== -1) {
          setStep(stepIndex);
        }
      }
      setStep(0);
    };

    const getNameOf = useCallback(
      (name?: string) => {
        if (name) {
          return `${currentStep.key}.${name}`;
        }
        return currentStep.key;
      },
      [currentStep.key]
    );

    const setRealtimeFormValues = useCallback(
      (values) => {
        // Check loop render
        setCurrentFormValues(values);
        if (onValueChange) onValueChange(values);
        return undefined;
      },
      [onValueChange]
    );

    const renderStepperItems = (steps: IStep[]) => {
      return steps.map((item: IStep, index: number) => {
        const newItem: StepProps = {
          index: index,
          label: item.label,
          isValid: item.isValid,
          disabled: item.disabled,
          children: null,
          id: `${item?.id}-${index}`,
        };
        return newItem;
      });
    };

    const handleAllDataChange = (initialValues: any) => {
      if (!initialValues) return;
      for (const key in initialValues) {
        onChangeRef.current(key, {
          value: initialValues[key],
        });
      }
    };

    useEffect(() => {
      //Only reload the first time when initialValue was updated
      if (initialValues && !isEqual(initialValues, currentFormValue)) {
        setCurrentFormValues(initialValues);
        //change data of each steppers based on new initial values
        handleAllDataChange(initialValues);
      }
      // eslint-disable-next-line
    }, [initialValues]);

    const FormElementComp = initialSteps[currentStepIndex].component;

    const isLoadingGetter = (type: "allStep" | "currentStep") => {
      if (type === "allStep") return isLoading;
      return currentStep?.isLoadingStep ?? false;
    };

    const loadFailedGetter = (type: "allStep" | "currentStep") => {
      if (type === "allStep") return loadFailed;
      return currentStep?.loadFailedStep;
    };

    const { customClassName, isLoadingStep, loadFailedStep } = currentStep;

    const bindingClassName = useMemo(() => {
      let classNames = "";
      if (customClassName) {
        classNames = customClassName;
      }
      if (isLoadingStep) {
        classNames = `${classNames} cc-form-loading`;
      }
      if (loadFailedStep) {
        classNames = `${classNames} cc-form-load-failed`;
      }
      return classNames;
      // eslint-disable-next-line
    }, [customClassName, isLoadingStep, loadFailedStep]);

    //Step modified
    const [modified, setModified] = useState<boolean>(false);
    return renderForm({
      children: (
        <Form
          //Trigger form re-render
          key={currentStepIndex}
          initialValues={currentFormValue}
          onSubmitClick={handleSubmitForm}
          // @TODO: Fix Warning
          validator={setRealtimeFormValues}
          render={(formRenderProps: FormRenderProps) => {
            //Realtime getting form valid
            // @TODO: Fix Warning
            handleUpdateFormValid(
              formValid,
              setFormValid,
              formRenderProps,
              currentStepIndex
            );
            onChangeRef.current = formRenderProps.onChange;
            onSubmitRef.current = formRenderProps.onSubmit;
            setModified(formRenderProps.modified);
            return (
              <div className="cc-form-step">
                <div className="cc-stepper">
                  <Stepper
                    items={renderStepperItems(filterSteps)}
                    value={step}
                    orientation="vertical"
                    onChange={(event: StepperChangeEvent) =>
                      handleMoveStep(() => {
                        handleOnStepChange(event);
                      })
                    }
                    successIcon="k-i-check k-icon"
                  />
                </div>
                <div className="cc-steps-content">
                  {isLoading ? (
                    <Loading isLoading={isLoading} />
                  ) : loadFailed ? (
                    <CCLoadFailed
                      responseError={loadFailed?.responseError}
                      onReload={loadFailed?.onReload}
                      subTitle={loadFailed?.responseError?.error}
                    />
                  ) : (
                    <>
                      <div className="cc-steps-title">{currentStep.label}</div>
                      {!isLoadingStep && !loadFailedStep && (
                        <div className="cc-local-notification-form-step">
                          <CCLocalNotification ref={notificationRef} />
                        </div>
                      )}
                      <FormElement className={`cc-form ${bindingClassName}`}>
                        <FormElementComp
                          formRenderProps={formRenderProps}
                          setStepsVisible={handleStepsVisible}
                          resetAllStepsAfter={handleResetAllStepsAfter}
                          nameOf={getNameOf}
                          ref={
                            typeof FormElementComp === "object"
                              ? childRef
                              : undefined
                          }
                          options={initialSteps[currentStepIndex].options}
                          localNotificationRef={notificationRef}
                          isLoadingStep={isLoadingStep ?? false}
                          setIsLoadingStep={(isLoading: boolean) =>
                            setIsLoadingStep(isLoading, undefined)
                          }
                          loadFailedStep={loadFailedStep}
                          setLoadFailedStep={setLoadFailedStep}
                          isModified={modified}
                          setModified={setModified}
                          setSubAction={(action) => {
                            setSubActionRef(action);
                          }}
                          currentStepKeyName={getNameOf()}
                        />
                      </FormElement>
                    </>
                  )}
                </div>
              </div>
            );
          }}
        />
      ),
      prevButton: {
        disabled: step === 0,
        onClick: (event) =>
          handleMoveStep(() => {
            handleOnStepPrev(event, currentFormValue);
          }),
        label: "Previous",
      },
      nextButton: {
        disabled:
          isLoading ||
          isLoadingStep ||
          !!loadFailedGetter("allStep") ||
          !!loadFailedGetter("currentStep") ||
          currentStep?.isValid === false
            ? true
            : false,
        label: isLastStep ? "Finish" : "Next",
        idButton: isLastStep ? "cc-finish-step-button" : "cc-next-step-button",
        onClick: onSubmitRef.current,
      },
      submitButton: {
        onClick: onSubmitRef.current,
      },
      resetAllStepsAfter: handleResetAllStepsAfter,
      moveToStep: moveToStep,
      isLastStep,
      isLoadingGetter,
      loadFailedGetter,
    });
  }
);
