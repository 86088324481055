import { DTO_Title_Detail } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { observer } from "mobx-react-lite";
import React from "react";
import { colTitleDetail } from "./config";
const nameOfTitleDetail = nameOfFactory<DTO_Title_Detail>();
export const TitleDetail = observer((props: GridDetailRowProps) => {
  const dataItem = props.dataItem;
  return (
    <CCGrid
      className="cc-title-detail-grid"
      data={dataItem?.Detail || []}
      columnFields={colTitleDetail}
      primaryField={nameOfTitleDetail("Id")}
      isLimitDetailWidth
    />
  );
});
