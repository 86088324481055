import { LOCAL_LAWS_MANAGE_ROUTE } from "@app/products/local-laws/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
export const colLocalLawsFinanceFeesAll: IColumnFields[] = [
  {
    field: "ReferenceNumber",
    title: "Number",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem) => ({
      pathname: `${LOCAL_LAWS_MANAGE_ROUTE}/` + dataItem.ID,
    }),
    locked: true,
  },
  { field: "ContactName", title: "Name" },
  {
    field: "CreatedDate",
    title: "Recorded",
    format: DATE_FORMAT.DATE,
  },
  { field: "FeeType", title: "Type" },
  {
    field: "AmountIssued",
    title: "Issued",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "AmountOutstanding",
    title: "Outstanding",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: "CostCode", title: "Cost Code" },
  {
    field: "InvoiceNumber",
    title: "Invoice Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "DebtorNumber",
    title: "Debtor Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
