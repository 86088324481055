import { DTO_Assessment_TabDetails } from "@app/products/property/assessments/[id]/components/reference-sidebar/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const getAssessmentDetailsTabById = async (
  assessmentId: number
): Promise<DTO_Assessment_TabDetails | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Assessment_TabDetails>(
        `/api/property/internal/assessment/${assessmentId}/tabdetails`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
