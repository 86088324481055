import { CORE_GET_HOMEPAGE_VIEWS_ODATA } from "@app/core/components/common/constant";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { AddUsersButton } from "@common/pages/settings/homepage/homepage-shortcuts/components/add-user-button/_index";
import { RemoveUsersButton } from "@common/pages/settings/homepage/homepage-shortcuts/components/remove-user-button/_index";
import { HomepageViewsHistoryTab } from "@common/pages/settings/homepage/homepage-views/[id]/components/reference-sidebar/history/_index";
import { colHomepageViews } from "@common/pages/settings/homepage/homepage-views/config";
import { HOME_PAGE_VIEW_URL } from "@common/pages/settings/homepage/homepage-views/constant";
import { Svc_HomepageViews } from "@common/pages/settings/homepage/homepage-views/model";
import {
  HomepageViewsBookmark,
  HomepageViewsDetailsBookmark,
} from "@common/pages/settings/homepage/homepage-views/util";
import { HomepageType } from "@common/pages/settings/homepage/model";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<Svc_HomepageViews>();

export default () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    const responseCheckGlobalSettings = await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );

    if (responseCheckGlobalSettings) {
      await checkPermissionForCurrentMenu(
        FormActionCheckPermission.HOMEPAGE_MENU,
        1
      );
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton title="Tools" type="more">
        <AddUsersButton homepageType={HomepageType.HomepageView} />
        <RemoveUsersButton homepageType={HomepageType.HomepageView} />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={HOME_PAGE_VIEW_URL}
        productType={PRODUCT_TYPE.HomePage}
        recordType={RECORDTYPE.Core_HomePageView}
        detail={HomepageViewsDetailsBookmark.getBookmarkDetail}
        displayName={HomepageViewsDetailsBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          HomepageViewsBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={HomepageViewsBookmark.getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "History",
        component: <HomepageViewsHistoryTab />,
      },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colHomepageViews}
      primaryField={nameOf("ID")}
      dataUrl={`${CORE_GET_HOMEPAGE_VIEWS_ODATA}?$count=true&`}
      state={{
        sort: [
          {
            field: nameOf("ID"),
            dir: "desc",
          },
        ],
      }}
    />
  );
};
