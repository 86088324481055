import { IWriteOffSmallBalances } from "@app/products/property/assessments/components/dialogs/write-off-small-balances/model";
import { DTO_LOV } from "@common/models/odataResponse";

export const mockData: IWriteOffSmallBalances[] = [
  {
    Prompt: "Enter Assessment Ids seperated by commas",
    Variable_Name: "@Assessment_Ids",
    SQL_Data_Type: "varchar(100)",
    Variable_Id: 2,
  },
  {
    Prompt: "Enter Collection Name",
    Variable_Name: "@Collection_Name",
    SQL_Data_Type: "varchar(100)",
    Variable_Id: 3,
  },
  {
    Prompt: "Minimum Writeoff Amount",
    Value: 0.01,
    Variable_Name: "@Min_Writeoff_Amount",
    SQL_Data_Type: "Money",
    Variable_Id: 4,
  },
  {
    Prompt: "Maximum Writeoff Amount",
    Value: 1.0,
    Variable_Name: "@Max_Writeoff_Amount",
    SQL_Data_Type: "Money",
    Variable_Id: 5,
  },
  {
    Prompt: "Include deferred amounts",
    Value: 0,
    Variable_Name: "@Write_off_Deferred",
    SQL_Data_Type: "BIT",
    Variable_Id: 6,
  },
  {
    Prompt: "Include in existing Journal Number",
    Variable_Name: "@Journal_Number",
    SQL_Data_Type: "Int",
    Variable_Id: 7,
  },
];

export const defaultSelectedRows: IWriteOffSmallBalances[] = [
  {
    Prompt: "Minimum Writeoff Amount",
    Value: 0.01,
    Variable_Name: "@Min_Writeoff_Amount",
    SQL_Data_Type: "Money",
    Variable_Id: 4,
  },
  {
    Prompt: "Maximum Writeoff Amount",
    Value: 1.0,
    Variable_Name: "@Max_Writeoff_Amount",
    SQL_Data_Type: "Money",
    Variable_Id: 5,
  },
  {
    Prompt: "Include deferred amounts",
    Value: 0,
    Variable_Name: "@Write_off_Deferred",
    SQL_Data_Type: "BIT",
    Variable_Id: 6,
  },
];

export const mockDropDownData: DTO_LOV[] = [
  {
    Code: "1",
    Name: "Equals",
  },
  {
    Code: "2",
    Name: "Contains",
  },
  {
    Code: "3",
    Name: "Starts with",
  },
  {
    Code: "4",
    Name: "Ends with",
  },
];
