import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { VO_Contact } from "@app/products/property/contacts-central-names/list/model";
import {
  BOOLEAN_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Contact>();
export const propertyContactCols: IColumnFields[] = [
  {
    field: nameOf("ENA_Name"),
    title: "Name",
    locked: true,
    linkTo: (dataItem: VO_Contact) =>
      `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${dataItem.Entity_Id}`,
  },
  {
    field: nameOf("ENA_Address"),
    title: "Address",
  },
  {
    field: nameOf("Entity_Occupation"),
    title: "Occupation",
  },
  {
    field: nameOf("Entity_Gender"),
    title: "Gender",
  },
  {
    field: nameOf("Entity_Type_Name"),
    title: "Type",
  },
  {
    field: nameOf("Is_Active"),
    title: "Is Active",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Is_Obsolete"),
    title: "Is Obsolete",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Entity_Change_DateTime"),
    title: "Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Entity_Change_UserId"),
    title: "Changed By",
  },
  {
    field: nameOf("Change_of_Ownership_Id"),
    title: "Change of Ownership ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Entity_Id"),
    title: "Contact ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
