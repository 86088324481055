import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { DTO_Charge } from "@app/products/property/assessments/[id]/components/child-screens/charges/model";
import {
  getInitialDataAdjustChargeBalances,
  postProcessWorkflowAdjustChargeBalances,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/api";
import { ChargeBalanceAdjustmentsFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/components/form-elements/charge-balance-adjustments/_index";
import { ChargesFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/components/form-elements/charges/_index";
import { TransactionFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/components/form-elements/transaction/_index";
import {
  DTO_Workflow_JournalAssessmentAdjustChargeBalances,
  EKeysOfStepsAdjustChargeBalances,
  keysOfSendStepsAdjustChargeBalances,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/model";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { NewSupplementaryFormStep } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/_index";
import { useNewSupplementaryDialogStore } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/store";
import { OfficerReallocateDialog } from "@app/products/property/assessments/master-properties/components/form-steps/add-new-master-property/dialog/officer-reallocate/_index";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { SupplementaryMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";
import { NewJournalFormStep } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/_index";
import { useNewJournalStepStore } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/store";
import {
  getWorkflowOverwrittenAtAuthorisingOfficer,
  omitOfficerBasedOnStatus,
} from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/util";
import { DTO_LOV_Transaction } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import {
  convertValueLOVToNumber,
  isShowParkButton,
} from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import { pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IAdjustChargeBalancesDialog {
  onClose: () => void;
  handleReallocate?: (data: any) => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
  isReadOnly?: boolean;
  isToBeApprovalMode?: boolean;
  isBeingApprovedMode?: boolean;
  isShowCancelWorkflowButton?: boolean;
  chargeData: DTO_Charge[];
  isSaveOnNextStep?: boolean;
  dataFromActionList?: VO_Workflow_Draft;
  newSpatial: DTO_Workflow_JournalAssessmentAdjustChargeBalances;
  statusBadge?: IBadgeDialog[];
  isRedirectManagePage?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
}

export const AdjustChargeBalancesDialog = observer(
  ({
    onClose,
    handleReallocate,
    isIncompleteMode = false,
    isFromActionList = false,
    isReadOnly = false,
    isToBeApprovalMode = false,
    isBeingApprovedMode = false,
    isShowCancelWorkflowButton = false,
    chargeData,
    isSaveOnNextStep = false,
    dataFromActionList,
    newSpatial,
    statusBadge,
    isRedirectManagePage = true,
    suffixTitle,
    prefixTitle,
  }: IAdjustChargeBalancesDialog) => {
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);
    const { currentFormTitle } = useCommonProductStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { assessmentId } = useAssessmentStore();
    const { setCreateSupplementaryLOVs } = useNewSupplementaryDialogStore();
    const { setNewJournalStepLOVs } = useNewJournalStepStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const history = useHistory();
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isShowOfficerReallocate, setIsShowOfficerReallocate] =
      useState<boolean>(false);
    const [workflowInitDataFromNew, setWorkflowInitDataFromNew] =
      useState<DTO_Workflow_JournalAssessmentAdjustChargeBalances>(newSpatial);
    const [transactionTypes, setTransactionTypes] = useState<
      DTO_LOV_Transaction[]
    >([]);
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });

    const initialValue = useMemo(() => {
      const initJournal: any = {
        ...workflowInitDataFromNew?.WorkflowDetail?.CreateJournal,
        SupplementaryMode:
          workflowInitDataFromNew?.WorkflowDetail?.SupplementaryMode,
      };
      const supplementaryId =
        workflowInitDataFromNew?.WorkflowDetail?.CreateJournal
          ?.Supplementary_Id;
      if (supplementaryId) {
        initJournal._options = {
          SupplementarySelected: [
            {
              Supplementary_Id: supplementaryId,
            },
          ],
        };
      }
      const initSupplementary: any = {
        ...workflowInitDataFromNew?.WorkflowDetail?.CreateSupplementary,
      };
      const initCharges: any =
        workflowInitDataFromNew?.WorkflowDetail?.AssessmentCharges ?? [];
      const initChargeBalanceAdjustments: any =
        workflowInitDataFromNew?.WorkflowDetail?.AssessmentCharges ?? [];

      const transactionDetails =
        workflowInitDataFromNew?.WorkflowDetail?.TransactionDetails;
      const initTransaction: any = {
        ...transactionDetails,
        TransactionDescription:
          transactionDetails?.TransactionDescription ?? "",
      };
      notificationFormStepRef?.current?.setStepsVisible([
        {
          key: EKeysOfStepsAdjustChargeBalances.CreateSupplementary,
          visible:
            workflowInitDataFromNew?.WorkflowDetail.SupplementaryMode ===
            SupplementaryMode.CreateNew,
          isClearData: false,
        },
      ]);

      return {
        [EKeysOfStepsAdjustChargeBalances.CreateJournal]: initJournal,
        [EKeysOfStepsAdjustChargeBalances.CreateSupplementary]:
          initSupplementary,
        [EKeysOfStepsAdjustChargeBalances.AssessmentCharges]: initCharges,
        [EKeysOfStepsAdjustChargeBalances.ChargeBalanceAdjustments]:
          initChargeBalanceAdjustments,
        [EKeysOfStepsAdjustChargeBalances.TransactionDetails]: initTransaction,
        [EKeysOfStepsAdjustChargeBalances.Comments]: {},
        [EKeysOfStepsAdjustChargeBalances.Documents]: {},
      };
    }, [workflowInitDataFromNew]);

    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title =
        currentFormTitle(formId ?? 0) || "Charge Balance Adjustments";

      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, workflowHeader]);

    const steps: IStep[] = [
      {
        label: "Journal",
        initialValues: initialValue.CreateJournal,
        component: NewJournalFormStep,
        visible: true,
        key: EKeysOfStepsAdjustChargeBalances.CreateJournal,
        options: {
          isAdjustChargeBalances: true,
          supplementaryStepKey:
            EKeysOfStepsAdjustChargeBalances.CreateSupplementary,
          isReadOnly,
          showStatusField: true,
          officerNameField: "Officer_Id",
        },
      },
      {
        label: "New supplementary",
        initialValues: initialValue.CreateSupplementary,
        component: NewSupplementaryFormStep,
        visible: false,
        key: EKeysOfStepsAdjustChargeBalances.CreateSupplementary,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Charges",
        initialValues: initialValue.AssessmentCharges,
        component: ChargesFormStep,
        visible: true,
        key: EKeysOfStepsAdjustChargeBalances.AssessmentCharges,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Charge Balance Adjustments",
        initialValues: initialValue.ChargeBalanceAdjustments,
        component: ChargeBalanceAdjustmentsFormStep,
        visible: true,
        key: EKeysOfStepsAdjustChargeBalances.ChargeBalanceAdjustments,
      },
      {
        label: "Transaction",
        initialValues: initialValue.TransactionDetails,
        component: TransactionFormStep,
        visible: true,
        key: EKeysOfStepsAdjustChargeBalances.TransactionDetails,
        options: {
          isReadOnly,
          transactionTypes: transactionTypes,
        },
      },
      {
        label: "Comments",
        initialValues: initialValue.Comments,
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfStepsAdjustChargeBalances.Comments,
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Journal,
        },
        customClassName: "cc-comment-step-fixed-height-grid",
      },
      {
        label: "Documents",
        initialValues: initialValue.Documents,
        component: DocumentsFormStep,
        visible: true,
        key: EKeysOfStepsAdjustChargeBalances.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Journal_Assessment_Adjust_ChargeBalances,
        },
      },
    ];

    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Approve:
          await handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          await handleSaveAndNext(processData(data), true);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          await handleCancelButton(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleConfirmRetainProcess(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
      }
    };

    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_JournalAssessmentAdjustChargeBalances
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessWorkflowAdjustChargeBalances,
        dataSendBack: payload,
        defaultSuccessMessage:
          "Charge balance adjustments approval sent back successfully",
        defaultErrorMessage: "Send charge balance adjustments back failed",
      });
    };

    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessWorkflowAdjustChargeBalances,
          dataReject: data,
          defaultSuccessMessage:
            "Charge balance adjustments application was rejected successfully",
          defaultErrorMessage: "Charge balance adjustments reject failed",
        });
      } else {
        onClose();
      }
    };

    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_JournalAssessmentAdjustChargeBalances
    ) => {
      const dataProcessed = processData(payload);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage:
          "The charge balance adjustments will be created based on the information provided. Are you sure you want to submit?",
      });
    };

    const handleConfirmRetainProcess = async (
      payload: DTO_Workflow_JournalAssessmentAdjustChargeBalances
    ) => {
      setIsLoadingClose(true);

      const parkProps: IProcessWorkflow<DTO_Workflow_JournalAssessmentAdjustChargeBalances> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            pushNotification({
              title:
                e?.SuccessMessage ??
                "Charge Balance Adjustments parked successfully",
              description: e?.Notification,
              type: "success",
            });
          },
          defaultFailedMessage: "Park Charge Balance Adjustments failed",
          modeProcess: WorkflowProcessMode.Park,
        };

      const setLoading = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog(undefined);
      };

      await handleProcessWorkflow(parkProps, setLoading);
    };

    const handleParkProcess = async (
      payload: DTO_Workflow_JournalAssessmentAdjustChargeBalances
    ) => {
      setIsLoadingPark(true);

      const parkProps: IProcessWorkflow<DTO_Workflow_JournalAssessmentAdjustChargeBalances> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            pushNotification({
              title:
                e?.Notification ??
                "Charge Balance Adjustments parked successfully",
              type: "success",
            });
          },
          defaultFailedMessage: "Park Charge Balance Adjustments failed",
          modeProcess: WorkflowProcessMode.Park,
        };

      const setLoading = () => {
        setIsLoadingPark(false);
      };

      await handleProcessWorkflow(parkProps, setLoading);
    };

    const handleApproveProcess = async (
      payload: DTO_Workflow_JournalAssessmentAdjustChargeBalances
    ) => {
      setIsLoadingApprove(true);

      const approveProps: IProcessWorkflow<DTO_Workflow_JournalAssessmentAdjustChargeBalances> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            pushNotification({
              title:
                e?.Notification ??
                "Charge Balance Adjustments approved successfully",
              type: "success",
            });
          },
          defaultFailedMessage: "Approve Charge Balance Adjustments failed",
          modeProcess: WorkflowProcessMode.Approve,
        };

      const setLoading = () => {
        setIsLoadingApprove(false);
      };
      await handleProcessWorkflow(approveProps, setLoading);
    };

    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_JournalAssessmentAdjustChargeBalances>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;

      const response = await postProcessWorkflowAdjustChargeBalances(
        modeProcess,
        payload
      );
      if (isSuccessResponse(response)) {
        setLoading();
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    const handleFinishProcess = async (
      payload: DTO_Workflow_JournalAssessmentAdjustChargeBalances
    ) => {
      const finishProps: IProcessWorkflow<DTO_Workflow_JournalAssessmentAdjustChargeBalances> =
        {
          payload: payload,
          actionSuccess: (e) => {
            const notificationContent: IAppNotificationItemAddProps = {
              title:
                e?.SuccessMessage ??
                e?.Notification ??
                "Charge Balance Adjustments was finished successfully",
              type: "success",
            };
            onClose();
            !isRedirectManagePage && pushNotification(notificationContent);
            isRedirectManagePage &&
              history.push(`${PROPERTY_JOURNALS_ROUTE}/${e?.Component_ID}`, {
                notification: notificationContent,
              });
          },
          defaultFailedMessage: "Finish Charge Balance Adjustments failed",
          modeProcess: WorkflowProcessMode.Finish,
        };

      const setLoading = () => {};
      await handleProcessWorkflow(finishProps, setLoading);
    };

    const handleCloseDialog = async (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name &&
        dataFromActionList.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    const handleCancelButton = async (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessWorkflowAdjustChargeBalances,
          dataCancel: data,
          defaultSuccessMessage:
            "Charge Balance Adjustments was cancelled successfully.",
          defaultErrorMessage:
            "Charge Balance Adjustments could not be cancelled.",
        });
      } else {
        onClose();
      }
    };

    const handleNextButton = async (data: any) => {
      setIsLoadingOnNext(true);
      const newData = { ...data };
      const processPayload = processData(newData);
      return handleSaveAndNext(processPayload);
    };

    const processData = (data: any) => {
      let workflowDetail: any = {
        ...workflowInitDataFromNew?.WorkflowDetail,
        Assessment_Id:
          workflowInitDataFromNew?.WorkflowDetail?.Assessment_Id || 0,
        JournalMode: workflowInitDataFromNew?.WorkflowDetail?.JournalMode || 0,
        SupplementaryMode: data?.CreateJournal?.SupplementaryMode || 0,
      };
      const sendSteps = pickBy(data, function (value, key) {
        if (
          keysOfSendStepsAdjustChargeBalances.includes(
            key as EKeysOfStepsAdjustChargeBalances
          )
        ) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        if (key === EKeysOfStepsAdjustChargeBalances.AssessmentCharges) {
          const dataStep = value;
          workflowDetail[key] = dataStep;
        } else {
          const dataStep = { ...value };
          if (
            key === EKeysOfStepsAdjustChargeBalances.CreateJournal &&
            dataStep?.Supplementary_Id &&
            dataStep.Supplementary_Id?.length === 1
          ) {
            dataStep.Supplementary_Id =
              dataStep?.Supplementary_Id[0]?.Supplementary_Id;
          }
          workflowDetail[key as keyof EKeysOfStepsAdjustChargeBalances] =
            dataStep;
        }
      }
      omitOfficerBasedOnStatus(workflowDetail);

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    const handleSaveAndNext = async (
      payload: DTO_Workflow_JournalAssessmentAdjustChargeBalances,
      isCloseDialog: boolean = false
    ): Promise<boolean> => {
      isCloseDialog && setIsLoadingSave(true);

      const response = await postProcessWorkflowAdjustChargeBalances(
        WorkflowProcessMode.Save,
        payload
      );

      setIsLoadingOnNext(false);

      const defaultSuccessMessage =
        "Charge Balance Adjustments saved successfully";
      const defaultFailedMessage =
        "The Charge Balance Adjustments process could not be saved";

      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (isCloseDialog) {
          setIsLoadingSave(false);
          onClose();
          pushNotification({
            title:
              response?.data?.Notification ??
              response?.data?.SuccessMessage ??
              defaultSuccessMessage,
            description: response?.data?.Notification,
            type: "success",
          });
        }
        if (isFirstSave && workflowDraftId === 0) {
          setIsFirstSave(false);
          setWorkflowDraftId(response?.data?.ID ?? 0);
          setWorkflowHeader({
            ...workflowHeader,
            WorkflowDraft: {
              ...workflowHeader.WorkflowDraft,
              Workflow_Draft_Id: response?.data?.ID ?? 0,
            },
          });
        }
        return true;
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        return false;
      }
    };

    const getWorkflowData = async () => {
      const chargeSelectedIds = chargeData?.map(
        (charge: DTO_Charge) => charge.Id
      );
      const id = dataFromActionList?.Workflow_Draft_Id ?? assessmentId;
      if (!id) return;
      notificationFormStepRef?.current?.setLoadingFormStep(true);

      const willBeNewWorkflow = !isFromActionList;
      getInitialDataAdjustChargeBalances(
        id,
        isFromActionList,
        chargeSelectedIds
      ).then((response: any) => {
        if (Array.isArray(response)) {
          const [lovsAssessment, workflowData] = response;
          if (
            isSuccessResponse(response[0]) &&
            isSuccessResponse(response[1]) &&
            lovsAssessment?.data &&
            workflowData?.data
          ) {
            let createSupplementaryLOVs: any = {
              SupplementaryType: lovsAssessment?.data?.SupplementaryType ?? [],
              InstallmentType: lovsAssessment?.data?.InstalmentPlans ?? [],
              AssessmentType: lovsAssessment?.data?.AssessmentType ?? [],
              ValuationTypeTobeUsed:
                lovsAssessment?.data?.ValuationTypeTobeUsed ?? [],
            };
            setCreateSupplementaryLOVs(createSupplementaryLOVs);
            setTransactionTypes(lovsAssessment?.data?.TransactionTypes ?? []);
            setWorkflowInitDataFromNew(
              willBeNewWorkflow
                ? getWorkflowOverwrittenAtAuthorisingOfficer(workflowData?.data)
                : workflowData?.data
            );
            setNewJournalStepLOVs({
              JournalTypes: convertValueLOVToNumber(
                lovsAssessment?.data?.JournalTypes ?? []
              ),
              Officer: convertValueLOVToNumber(
                lovsAssessment?.data?.Officer ?? []
              ),
            });
            if (workflowData?.data?.WorkflowHeader) {
              setWorkflowHeader(workflowData?.data?.WorkflowHeader);
              setWorkflowDraftId(
                workflowData?.data?.WorkflowHeader?.WorkflowDraft
                  ?.Workflow_Draft_Id ?? 0
              );
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
          } else {
            let responseError: APIResponse<ResponsePacket> = response[0];
            if (!isSuccessResponse(response[1])) responseError = response[1];
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error:
                  (responseError.data as ResponsePacket)?.Errors ??
                  "Load workflow failed",
              },
            });
          }
        } else {
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: response?.status ?? 400,
              error: "Load workflow failed",
            },
          });
        }
      });
    };

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <>
        <CCFormStep
          ref={notificationFormStepRef}
          onSubmit={handleSubmit}
          listButtonId={listSubmitButton}
          initialSteps={steps}
          initialValues={initialValue}
          saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
          renderForm={(renderProps: ICCFormStepRender) => (
            <CCDialog
              maxWidth="70%"
              disabled={isLoadingApprove || isLoadingOnNext}
              titleHeader={titleHeader}
              onClose={() => handleCloseDialog(renderProps)}
              bodyElement={renderProps.children}
              badge={statusBadge}
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={isLoadingPark ? "fas fa-spinner fa-spin" : ""}
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled || isLoadingPark
                      }
                    >
                      Park
                    </Button>
                  )}

                  {!isReadOnly &&
                    isToBeApprovalMode &&
                    !isBeingApprovedMode && (
                      <Button
                        iconClass={
                          isLoadingSave ? "fas fa-spinner fa-spin" : ""
                        }
                        disabled={
                          renderProps.nextButton.disabled || isLoadingSave
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Save}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Save
                      </Button>
                    )}

                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      disabled={
                        isLoadingApprove ||
                        isLoadingOnNext ||
                        isLoadingPark ||
                        isLoadingSave
                      }
                      id={EListSubmitButton.Cancel}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}

                  {isToBeApprovalMode && !isBeingApprovedMode && (
                    <>
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.SendBack}
                        disabled={renderProps.nextButton.disabled}
                        className={"cc-dialog-button"}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Send Back
                      </Button>
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.Reallocate}
                        disabled={renderProps.nextButton.disabled}
                        className={"cc-dialog-button"}
                        onClick={() => setIsShowOfficerReallocate(true)}
                      >
                        Reallocate
                      </Button>
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.Approve}
                        disabled={
                          isLoadingApprove || renderProps.nextButton.disabled
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.submitButton.onClick}
                        iconClass={
                          isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                        }
                      >
                        Approve
                      </Button>
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.Reject}
                        disabled={renderProps.nextButton.disabled}
                        className={"cc-dialog-button"}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Reject
                      </Button>
                    </>
                  )}

                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}

                  {isToBeApprovalMode || isReadOnly ? (
                    !renderProps.isLastStep && (
                      <Button
                        themeColor="primary"
                        id="cc-next-step-button"
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        className={"cc-dialog-button"}
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )
                  ) : (
                    <Button
                      themeColor="primary"
                      id={renderProps.nextButton.idButton}
                      disabled={
                        isLoadingOnNext || renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingOnNext
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
              }
            />
          )}
        />
        {isShowOfficerReallocate && (
          <OfficerReallocateDialog
            onClose={() => {
              setIsShowOfficerReallocate(false);
            }}
            onSubmit={(data) => {
              if (handleReallocate) {
                handleReallocate(data);
              }
            }}
          />
        )}
      </>
    );
  }
);
