import { DTO_Entity } from "@app/products/property/components/fields/search-name/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Entity>();

export const colDuplicateContact: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    locked: true,
  },
  {
    field: nameOf("Formatted_Name_Address"),
    title: "Address Formatted",
  },
  {
    field: nameOf("Mobile"),
    title: "Mobile Number",
  },
  {
    field: nameOf("Email"),
    title: "Email Address",
  },
  {
    field: nameOf("Gender"),
    title: "Gender",
  },
  {
    field: nameOf("Entity_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
