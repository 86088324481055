import { ImportButton } from "@app/core/import/buttons/_index";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { KeywordLite } from "@common/models/keyword";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { DeleteKeywordButton } from "@common/pages/settings/lookups/keywords/components/action-bar/buttons/delete-keywords/_index";
import { MarkToPublishKeywordButton } from "@common/pages/settings/lookups/keywords/components/action-bar/buttons/mark-to-publish/_index";
import { NewKeywordButton } from "@common/pages/settings/lookups/keywords/components/action-bar/buttons/new-keywords/_index";
import { colLookupsKeywords } from "@common/pages/settings/lookups/keywords/config";
import { LOOKUPS_KEYWORDS_LIST_VIEW_URL } from "@common/pages/settings/lookups/keywords/constant";
import { LookupKeywordBookmark } from "@common/pages/settings/lookups/keywords/util";
import { LookupKeywordsTabDetails } from "@common/pages/settings/lookups/keywords/_id/components/reference-sidebar/detail/_index";
import { LookupKeywordsHistoryTab } from "@common/pages/settings/lookups/keywords/_id/components/reference-sidebar/history/_index";
import { SETTINGS_LOOKUPS_KEYWORDS_ROUTE } from "@common/pages/settings/lookups/keywords/_id/constant";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<KeywordLite>();

export default () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [
      <NewKeywordButton disabled={!hasPermission} />,
      <DeleteKeywordButton disabled={!hasPermission} />,
      <MarkToPublishKeywordButton disabled={!hasPermission} />,
      <ImportButton
        apiURL="api/core/internal/keyword/import"
        buttonTitle="Import Keywords"
        description={importDescriptionContent}
        disabled={!hasPermission}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={SETTINGS_LOOKUPS_KEYWORDS_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_Keyword}
        detail={LookupKeywordBookmark.getBookmarkDetail}
        displayName={LookupKeywordBookmark.getBookmarkDisplayName}
        listViewDetail={LookupKeywordBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          LookupKeywordBookmark.getBookmarkListViewDisplayName
        }
        isDisabledRecordListView={!hasPermission}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <LookupKeywordsTabDetails /> },
      {
        title: "History",
        component: <LookupKeywordsHistoryTab />,
      },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colLookupsKeywords}
      primaryField={nameOf("Keyword_ID")}
      dataUrl={LOOKUPS_KEYWORDS_LIST_VIEW_URL}
      state={{
        sort: [
          { field: nameOf("KeywordType_Name"), dir: "asc" },
          { field: nameOf("Sys_SortOrderIndex"), dir: "asc" },
          { field: nameOf("Name"), dir: "asc" },
        ],
      }}
      disabled={!hasPermission}
    />
  );
};

const importDescriptionContent = (
  <>
    <div>Instructions for import : Keywords</div>
    <ul className="cc-note-keywords-import">
      <li>
        The import file must be a Comma Separated File(.xls) with the following.
      </li>
      <li>
        ID, Name, Description, KeywordType_Name, KeywordType_ID, Code, Sort
        Index, Is Active, Product, ProductType, Enumerated ID, Enumerated Type,
        Is System Record, Is Prod Admin Record, Is Hidden Record, Target Days,
        Parent KWD, FSR Code, FSR Name, System Filters, SystemFilter_Names, URL,
        Full Description, Restricted XML, IsPublishToWeb
      </li>
      <li>The first row must contain the column headers.</li>
      <li>'Save' button will import all rows in the file.</li>
    </ul>
  </>
);
