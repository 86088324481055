import { CRMS_SERVICE_STANDARDS_ROUTE } from "@app/products/crms/service-standards/[id]/constant";
import { ServiceStandardView } from "@app/products/crms/service-standards/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<ServiceStandardView>();

export const colByName: IColumnFields[] = [
  {
    field: nameOf("Category"),
    title: "Category",
    locked: true,
    linkTo: (dataItem) => `${CRMS_SERVICE_STANDARDS_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("SubCategory"),
    title: "Sub Category",
  },
  {
    field: nameOf("SubCategoryLevel3"),
    title: "Sub Category Level 3",
  },
  {
    field: nameOf("SubCategoryLevel4"),
    title: "Sub Category Level 4",
  },
  { field: nameOf("Name"), title: "Name" },
  {
    field: nameOf("OrgStructure"),
    title: "Org Structure",
  },
  {
    field: nameOf("ActionOfficer"),
    title: "Action Officer",
  },
  {
    field: nameOf("Coordinator"),
    title: "Coordinator",
  },
  {
    field: nameOf("FileNumber"),
    title: "File Number",
  },
  {
    field: nameOf("Classification"),
    title: "Classification",
  },
  {
    field: nameOf("CategoryCode"),
    title: "Category Code",
  },
  {
    field: nameOf("HasAsset"),
    title: "Has Asset",
  },
  {
    field: nameOf("DisplayAsCRMSChoice"),
    title: "CRMS Choice",
  },
  {
    field: nameOf("DisplayAsInternetChoice"),
    title: "Internet Choice",
  },
  {
    field: nameOf("Flag_MobileChoice"),
    title: "Mobile Choice",
  },
  // Dont receive any requirement from Onshore for this field
  // {
  //   field: nameOf("Flag_ITSupport"),
  //   title: "IT Support",
  // },
  {
    field: nameOf("Flag_Payroll"),
    title: "Payroll",
  },
  {
    field: nameOf("Flag_System"),
    title: "System",
  },
  {
    field: nameOf("Flag_ExternalUser"),
    title: "External Action Officer",
  },
  {
    field: nameOf("ServiceStandardType_Name"),
    title: "Type",
  },
  {
    field: nameOf("IsAnimal"),
    title: "Animal",
  },
  {
    field: nameOf("IsHealth"),
    title: "Health",
  },
  {
    field: nameOf("HasWasteWater"),
    title: "Waste Water",
  },
];
