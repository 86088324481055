import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import React from "react";

interface ICommentPropertyButtonProps {
  id?: number | string;
  recordType: RECORDTYPE;
  isDisabled?: boolean;
}

export const AddCommentPropertyButton = (
  props: ICommentPropertyButtonProps
) => {
  return <AddCommentButton {...props} isUseDropDownForTitle />;
};
