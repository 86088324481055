import { useCertificateDetailsTabStore } from "@app/products/property/certificates/[id]/components/reference-sidebar/detail/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { eComponent } from "@app/products/property/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

export const CertificateDetailTab = observer(() => {
  const {
    isLoading,
    certificateDetailsTabData,
    responseLoadError,
    loadCertificateDetailsTabById,
    resetStore,
  } = useCertificateDetailsTabStore();
  const { lastSelectedId } = useCCProductListViewStore();

  //Get labels
  const [
    assessmentIDLabel,
    assessmentLabel,
    titleIDLabel,
    legalDescriptionLabel,
  ] = Label.CommunityProperty.getLabel([
    ECustomColNameProperty.AssessmentID,
    ECustomColNameProperty.Assessment,
    ECustomColNameProperty.Title_ID,
    ECustomColNameProperty.Legal_Description,
  ]);

  const { id } = useParams<{ id: string }>();
  const certificateId: string | number = useMemo(() => {
    return id ?? lastSelectedId;
  }, [lastSelectedId, id]);

  useEffect(() => {
    certificateId && loadCertificateDetailsTabById(+certificateId);
    return () => resetStore();
  }, [certificateId, loadCertificateDetailsTabById, resetStore]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          certificateId && loadCertificateDetailsTabById(+certificateId);
        }}
      />
    );
  }
  if (!certificateDetailsTabData) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Certificate:"}
              value={
                certificateDetailsTabData?.CertificateId &&
                certificateDetailsTabData?.Certificate_Type_Name
                  ? certificateDetailsTabData.CertificateId +
                    " - " +
                    certificateDetailsTabData.Certificate_Type_Name
                  : ""
              }
              primary
            />
          }
        >
          <ProductReferenceBlock>
            {isEqual(
              certificateDetailsTabData?.Certificate_Type_Component,
              eComponent.PIC
            ) && (
              <>
                <ProductReferenceRow
                  title={"PIC ID:"}
                  value={certificateDetailsTabData?.PIC_Id}
                />
                <ProductReferenceRow
                  title={"Holding address:"}
                  value={certificateDetailsTabData?.Holding_Property_Addresses}
                />
              </>
            )}
            {isEqual(
              certificateDetailsTabData?.Certificate_Type_Component,
              eComponent.Assessment
            ) && (
              <>
                <ProductReferenceRow
                  title={assessmentIDLabel + ":"}
                  value={certificateDetailsTabData?.AssessmentId}
                />
                <ProductReferenceRow
                  title={assessmentLabel + " address:"}
                  value={certificateDetailsTabData?.Assess_Property_Addresses}
                />
              </>
            )}
            {isEqual(
              certificateDetailsTabData?.Certificate_Type_Component,
              eComponent.Parcel
            ) && (
              <>
                <ProductReferenceRow
                  title={titleIDLabel + ":"}
                  value={certificateDetailsTabData?.Parcel_Id}
                />
                <ProductReferenceRow
                  title={legalDescriptionLabel + ":"}
                  value={certificateDetailsTabData?.Parcel_Property_Addresses}
                />
              </>
            )}
            <ProductReferenceRow
              title={"Date originated:"}
              value={formatDisplayValue(
                certificateDetailsTabData?.Cert_Originated_DateTime,
                DATETIME_FORMAT.DATETIME
              )}
            />
            <ProductReferenceRow
              title={"Status:"}
              value={certificateDetailsTabData?.Certificate_Status_Name}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
