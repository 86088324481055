import { KBPage } from "@app/products/crms/knowledge-base/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket } from "@common/models/identityPacket";

export const loadGuidanceByEventId = async (
  eventID: number
): Promise<APIResponse<IIdentityPacket<KBPage | undefined>>> => {
  try {
    return await CoreAPIService.getClient().get<IIdentityPacket<KBPage>>(
      `api/crms/internal/knowledge-base/get-kb-by-event/${eventID}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const loadGuidanceById = async (
  id: number
): Promise<APIResponse<KBPage>> => {
  try {
    return await CoreAPIService.getClient().get<KBPage>(
      `api/crms/internal/knowledge-base/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
