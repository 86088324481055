import { Attachment, KeyValuePair } from "@app/core/documents/model";
import { ProductType } from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { ChecklistQuestion } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/model";
import {
  BubbleUpIdentifier,
  SiteUser,
} from "@app/products/waste-water/[id]/model";
import {
  AFSAADJUSTMENT,
  AFSARESULT,
  ASSESSMENTADJUSTMENT,
  ASSESSMENTCONSEQUENCE,
  ASSESSMENTEVIDENCE,
  ASSESSMENTLIKELIHOOD,
  ASSESSMENTRESULT,
  ASSESSMENTRISK,
  ChecklistType,
  ContactRelationExtension,
  ContactRelationshipType,
  ContactRelationshipUpdateTriggers,
  DEDUCTIONTYPE,
  DefaultAffectSchedule,
  EnforcementOptions,
  InspectionClassification,
  InterviewClassification,
  NC_RISK,
  NC_STATUS,
  TobaccoVisitType,
} from "@common/constants/enumerations";
import { FSR_INSPECTIONTYPE } from "@common/constants/fsrCode";
import { RECORDTYPE } from "@common/constants/recordtype";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { Contact } from "@common/models/contact";
import { Svc_InspectionType } from "@common/models/inspectionType";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { Keyword, Keyword_Tags } from "@common/models/keyword";
import { Action } from "@common/pages/actions/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export interface InspectionTypeFee {
  CoreLinkId: number | null;
  Index: number;
  InspectionTypeFeeType_ID: number | null;
  InspectionTypeFeeType_Name: string;
  InspectionTypeFee_Amount: number | null;
  InheritInspectionTypeFeeType: boolean | null;
}
export interface DynamicQuestionList_Lightweight {
  DynamicQuestionList_ID: number;
  DynamicQuestionList_Name: string;
  SortIndex: number;
  ChecklistType_ENUM: ChecklistType;
  ChecklistType_Name: string;
}

export interface InspectionType extends DTOBaseClass_Standard {
  InspectionType_ID: number;
  Name: string;
  Description: string;
  Group: string;
  InspectionClassification_ENUM: InspectionClassification;
  InspectionClassification_Name: string;
  FSRCode_ENUM: FSR_INSPECTIONTYPE | null;
  FSRCode_Name: string;
  DeductionType_ENUM: DEDUCTIONTYPE | null;
  DeductionType_Name: string;
  DefaultAffectSchedule_ENUM: DefaultAffectSchedule | null;
  DefaultAffectSchedule_Name: string;
  Flag_AllowNCItems: boolean;
  Flag_InheritNCItems: boolean | null;
  Flag_SimpleInspection: boolean | null;
  Flag_IsAssessment: boolean | null;
  Flag_IsScoring: boolean | null;
  Flag_IsAllowFollowUp: boolean | null;
  Flag_IsAllowFSS: boolean | null;
  Flag_AssessmentRatingOverwrite: boolean | null;
  FeeType_ID: number | null;
  FeeAmount: number | null;
  SystemFilter_ENUMS: string;
  SystemFilter_Names: string;
  InspectionReport_Document_IDs: string;
  Selected_Documents: string;
  InspectionReport_Send_ActionType_ID: number | null;
  InspectionReport_NotSend_ActionType_ID: number | null;
  Flag_IsNumberofUnits: boolean | null;
  Flag_AdministrativeFee: boolean | null;
  Flag_AutoAction: boolean | null;
  ActionTypeIds: number[];
  TechOneActionTypeId: number | null;
  Flag_SearchWarrant: boolean | null;
  Flag_Seizure: boolean | null;
  Flag_IssueCategory: boolean | null;
  Flag_IncludeWorkflow: boolean | null;
  Flag_Location: boolean | null;
  Flag_TCRAssessment: boolean | null;
  Flag_RelatedNotice: boolean | null;
  Flag_RelatedIncident: boolean | null;
  Flag_RegistrationCertificateDisplayed: boolean | null;
  SaveTriggers: InspectionTypeUpdateTriggers[];
  Code: number | null;
  UnitsLabel: string;
  InspectionTypeFees: InspectionTypeFee[];
  FeeType2_ID: number | null;
  FeeAmount2: number | null;
  Flag_IsNumberofUnits2: boolean | null;
  UnitsLabel2: string;
  ApplicationAssessment: DynamicQuestionList_Lightweight[];
  Flag_InheritFeeType: boolean | null;
  Flag_InheritFeeType2: boolean | null;
}

export enum InspectionTypeUpdateTriggers {
  CreateActionTypes,
  CreateTechOneActionType,
}
export interface AFSAResultComment extends DTOBaseClass_Standard {
  AFSAResultComment_ID: number;
  Comment: string;
}

export enum AFSAUpdateTriggers {
  UpdateAFSA,
  UpdateQ1_Comment,
  UpdateQ2_Comment,
  UpdateQ3_Comment,
  UpdateQ4_Comment,
  UpdateQ5_Comment,
  UpdateQ6_Comment,
  UpdateQ7_Comment,
  UpdateQ8_Comment,
  UpdateQ9_Comment,
  UpdateQ10_Comment,
  UpdateQ11_Comment,
  UpdateQ12_Comment,
  UpdateQ13_Comment,
  UpdateQ14_Comment,
  UpdateQ15_Comment,
  UpdateQ16_Comment,
  UpdateQ17_Comment,
  UpdateQ18_Comment,
  UpdateQ19_Comment,
  UpdateQ20_Comment,
  UpdateQ21_Comment,
  UpdateQ22_Comment,
  UpdateQ23_Comment,
  UpdateQ24_Comment,
  UpdateQ25_Comment,
  UpdateQ26_Comment,
  UpdateQ27_Comment,
  UpdateQ28_Comment,
  UpdateQ29_Comment,
  UpdateQ30_Comment,
  UpdateQ31_Comment,
  UpdateQ32_Comment,
  UpdateQ33_Comment,
  UpdateQ34_Comment,
  UpdateQ35_Comment,
}

export interface AFSAResult extends DTOBaseClass_Standard {
  AFSAResult_ID: number;
  Q1_Result_ENUM: AFSARESULT;
  Q1_Result_Name: string;
  Q2_Result_ENUM: AFSARESULT;
  Q2_Result_Name: string;
  Q3_Result_ENUM: AFSARESULT;
  Q3_Result_Name: string;
  Q4_Result_ENUM: AFSARESULT;
  Q4_Result_Name: string;
  Q5_Result_ENUM: AFSARESULT;
  Q5_Result_Name: string;
  Q6_Result_ENUM: AFSARESULT;
  Q6_Result_Name: string;
  Q7_Result_ENUM: AFSARESULT;
  Q7_Result_Name: string;
  Q8_Result_ENUM: AFSARESULT;
  Q8_Result_Name: string;
  Q9_Result_ENUM: AFSARESULT;
  Q9_Result_Name: string;
  Q10_Result_ENUM: AFSARESULT;
  Q10_Result_Name: string;
  Q11_Result_ENUM: AFSARESULT;
  Q11_Result_Name: string;
  Q12_Result_ENUM: AFSARESULT;
  Q12_Result_Name: string;
  Q13_Result_ENUM: AFSARESULT;
  Q13_Result_Name: string;
  Q14_Result_ENUM: AFSARESULT;
  Q14_Result_Name: string;
  Q15_Result_ENUM: AFSARESULT;
  Q15_Result_Name: string;
  Q16_Result_ENUM: AFSARESULT;
  Q16_Result_Name: string;
  Q17_Result_ENUM: AFSARESULT;
  Q17_Result_Name: string;
  Q18_Result_ENUM: AFSARESULT;
  Q18_Result_Name: string;
  Q19_Result_ENUM: AFSARESULT;
  Q19_Result_Name: string;
  Q20_Result_ENUM: AFSARESULT;
  Q20_Result_Name: string;
  Q21_Result_ENUM: AFSARESULT;
  Q21_Result_Name: string;
  Q22_Result_ENUM: AFSARESULT;
  Q22_Result_Name: string;
  Q23_Result_ENUM: AFSARESULT;
  Q23_Result_Name: string;
  Q24_Result_ENUM: AFSARESULT;
  Q24_Result_Name: string;
  Q25_Result_ENUM: AFSARESULT;
  Q25_Result_Name: string;
  Q26_Result_ENUM: AFSARESULT;
  Q26_Result_Name: string;
  Q27_Result_ENUM: AFSARESULT;
  Q27_Result_Name: string;
  Q28_Result_ENUM: AFSARESULT;
  Q28_Result_Name: string;
  Q29_Result_ENUM: AFSARESULT;
  Q29_Result_Name: string;
  Q30_Result_ENUM: AFSARESULT;
  Q30_Result_Name: string;
  Q31_Result_ENUM: AFSARESULT;
  Q31_Result_Name: string;
  Q32_Result_ENUM: AFSARESULT;
  Q32_Result_Name: string;
  Q33_Result_ENUM: AFSARESULT;
  Q33_Result_Name: string;
  Q34_Result_ENUM: AFSARESULT;
  Q34_Result_Name: string;
  Q35_Result_ENUM: AFSARESULT;
  Q35_Result_Name: string;
  Adjustment_ENUM: AFSAADJUSTMENT;
  Adjustment_Name: string;
  Adjustment_Frequency: number;
  Q1_Comment: AFSAResultComment;
  Q1_Comment_ID: number | null;
  Q2_Comment: AFSAResultComment;
  Q2_Comment_ID: number | null;
  Q3_Comment: AFSAResultComment;
  Q3_Comment_ID: number | null;
  Q4_Comment: AFSAResultComment;
  Q4_Comment_ID: number | null;
  Q5_Comment: AFSAResultComment;
  Q5_Comment_ID: number | null;
  Q6_Comment: AFSAResultComment;
  Q6_Comment_ID: number | null;
  Q7_Comment: AFSAResultComment;
  Q7_Comment_ID: number | null;
  Q8_Comment: AFSAResultComment;
  Q8_Comment_ID: number | null;
  Q9_Comment: AFSAResultComment;
  Q9_Comment_ID: number | null;
  Q10_Comment: AFSAResultComment;
  Q10_Comment_ID: number | null;
  Q11_Comment: AFSAResultComment;
  Q11_Comment_ID: number | null;
  Q12_Comment: AFSAResultComment;
  Q12_Comment_ID: number | null;
  Q13_Comment: AFSAResultComment;
  Q13_Comment_ID: number | null;
  Q14_Comment: AFSAResultComment;
  Q14_Comment_ID: number | null;
  Q15_Comment: AFSAResultComment;
  Q15_Comment_ID: number | null;
  Q16_Comment: AFSAResultComment;
  Q16_Comment_ID: number | null;
  Q17_Comment: AFSAResultComment;
  Q17_Comment_ID: number | null;
  Q18_Comment: AFSAResultComment;
  Q18_Comment_ID: number | null;
  Q19_Comment: AFSAResultComment;
  Q19_Comment_ID: number | null;
  Q20_Comment: AFSAResultComment;
  Q20_Comment_ID: number | null;
  Q21_Comment: AFSAResultComment;
  Q21_Comment_ID: number | null;
  Q22_Comment: AFSAResultComment;
  Q22_Comment_ID: number | null;
  Q23_Comment: AFSAResultComment;
  Q23_Comment_ID: number | null;
  Q24_Comment: AFSAResultComment;
  Q24_Comment_ID: number | null;
  Q25_Comment: AFSAResultComment;
  Q25_Comment_ID: number | null;
  Q26_Comment: AFSAResultComment;
  Q26_Comment_ID: number | null;
  Q27_Comment: AFSAResultComment;
  Q27_Comment_ID: number | null;
  Q28_Comment: AFSAResultComment;
  Q28_Comment_ID: number | null;
  Q29_Comment: AFSAResultComment;
  Q29_Comment_ID: number | null;
  Q30_Comment: AFSAResultComment;
  Q30_Comment_ID: number | null;
  Q31_Comment: AFSAResultComment;
  Q31_Comment_ID: number | null;
  Q32_Comment: AFSAResultComment;
  Q32_Comment_ID: number | null;
  Q33_Comment: AFSAResultComment;
  Q33_Comment_ID: number | null;
  Q34_Comment: AFSAResultComment;
  Q34_Comment_ID: number | null;
  Q35_Comment: AFSAResultComment;
  Q35_Comment_ID: number | null;
  Q50_Comment: AFSAResultComment;
  Q50_Comment_ID: number | null;
  Q51_Comment: AFSAResultComment;
  Q51_Comment_ID: number | null;
  Q52_Comment: AFSAResultComment;
  Q52_Comment_ID: number | null;
  Q53_Comment: AFSAResultComment;
  Q53_Comment_ID: number | null;
  Q54_Comment: AFSAResultComment;
  Q54_Comment_ID: number | null;
  Q1100_Comment: AFSAResultComment;
  Q1100_Comment_ID: number | null;
  Q1200_Comment: AFSAResultComment;
  Q1200_Comment_ID: number | null;
  Q1300_Comment: AFSAResultComment;
  Q1300_Comment_ID: number | null;
  Q2100_Comment: AFSAResultComment;
  Q2100_Comment_ID: number | null;
  Q2200_Comment: AFSAResultComment;
  Q2200_Comment_ID: number | null;
  Q3100_Comment: AFSAResultComment;
  Q3100_Comment_ID: number | null;
  Q4100_Comment: AFSAResultComment;
  Q4100_Comment_ID: number | null;
  Q4200_Comment: AFSAResultComment;
  Q4200_Comment_ID: number | null;
  Q4300_Comment: AFSAResultComment;
  Q4300_Comment_ID: number | null;
  Q4400_Comment: AFSAResultComment;
  Q4400_Comment_ID: number | null;
  Q5100_Comment: AFSAResultComment;
  Q5100_Comment_ID: number | null;
  Q6100_Comment: AFSAResultComment;
  Q6100_Comment_ID: number | null;
  Q7100_Comment: AFSAResultComment;
  Q7100_Comment_ID: number | null;
  Q7200_Comment: AFSAResultComment;
  Q7200_Comment_ID: number | null;
  Q7300_Comment: AFSAResultComment;
  Q7300_Comment_ID: number | null;
  Q8100_Comment: AFSAResultComment;
  Q8100_Comment_ID: number | null;
  Q9100_Comment: AFSAResultComment;
  Q9100_Comment_ID: number | null;
  Q10100_Comment: AFSAResultComment;
  Q10100_Comment_ID: number | null;
  Q10200_Comment: AFSAResultComment;
  Q10200_Comment_ID: number | null;
  Q11100_Comment: AFSAResultComment;
  Q11100_Comment_ID: number | null;
  Q12100_Comment: AFSAResultComment;
  Q12100_Comment_ID: number | null;
  Q13100_Comment: AFSAResultComment;
  Q13100_Comment_ID: number | null;
  Q13200_Comment: AFSAResultComment;
  Q13200_Comment_ID: number | null;
  Q13300_Comment: AFSAResultComment;
  Q13300_Comment_ID: number | null;
  Q14100_Comment: AFSAResultComment;
  Q14100_Comment_ID: number | null;
  Q15100_Comment: AFSAResultComment;
  Q15100_Comment_ID: number | null;
  Q15200_Comment: AFSAResultComment;
  Q15200_Comment_ID: number | null;
  Q15300_Comment: AFSAResultComment;
  Q15300_Comment_ID: number | null;
  Q15400_Comment: AFSAResultComment;
  Q15400_Comment_ID: number | null;
  Q16100_Comment: AFSAResultComment;
  Q16100_Comment_ID: number | null;
  Q16200_Comment: AFSAResultComment;
  Q16200_Comment_ID: number | null;
  Q17100_Comment: AFSAResultComment;
  Q17100_Comment_ID: number | null;
  Q17200_Comment: AFSAResultComment;
  Q17200_Comment_ID: number | null;
  Q18100_Comment: AFSAResultComment;
  Q18100_Comment_ID: number | null;
  Q18200_Comment: AFSAResultComment;
  Q18200_Comment_ID: number | null;
  Q19100_Comment: AFSAResultComment;
  Q19100_Comment_ID: number | null;
  Q20100_Comment: AFSAResultComment;
  Q20100_Comment_ID: number | null;
  Q20200_Comment: AFSAResultComment;
  Q20200_Comment_ID: number | null;
  Q20300_Comment: AFSAResultComment;
  Q20300_Comment_ID: number | null;
  Q21100_Comment: AFSAResultComment;
  Q21100_Comment_ID: number | null;
  Q22100_Comment: AFSAResultComment;
  Q22100_Comment_ID: number | null;
  Q22200_Comment: AFSAResultComment;
  Q22200_Comment_ID: number | null;
  Q22300_Comment: AFSAResultComment;
  Q22300_Comment_ID: number | null;
  Q23100_Comment: AFSAResultComment;
  Q23100_Comment_ID: number | null;
  Q23200_Comment: AFSAResultComment;
  Q23200_Comment_ID: number | null;
  Q23300_Comment: AFSAResultComment;
  Q23300_Comment_ID: number | null;
  Q24100_Comment: AFSAResultComment;
  Q24100_Comment_ID: number | null;
  Q25100_Comment: AFSAResultComment;
  Q25100_Comment_ID: number | null;
  Q25200_Comment: AFSAResultComment;
  Q25200_Comment_ID: number | null;
  Q26100_Comment: AFSAResultComment;
  Q26100_Comment_ID: number | null;
  Q27100_Comment: AFSAResultComment;
  Q27100_Comment_ID: number | null;
  Q27200_Comment: AFSAResultComment;
  Q27200_Comment_ID: number | null;
  Q28100_Comment: AFSAResultComment;
  Q28100_Comment_ID: number | null;
  Q28200_Comment: AFSAResultComment;
  Q28200_Comment_ID: number | null;
  Q29100_Comment: AFSAResultComment;
  Q29100_Comment_ID: number | null;
  Q29200_Comment: AFSAResultComment;
  Q29200_Comment_ID: number | null;
  Q29300_Comment: AFSAResultComment;
  Q29300_Comment_ID: number | null;
  Q30100_Comment: AFSAResultComment;
  Q30100_Comment_ID: number | null;
  Q31100_Comment: AFSAResultComment;
  Q31100_Comment_ID: number | null;
  Q31200_Comment: AFSAResultComment;
  Q31200_Comment_ID: number | null;
  Q32100_Comment: AFSAResultComment;
  Q32100_Comment_ID: number | null;
  Q32200_Comment: AFSAResultComment;
  Q32200_Comment_ID: number | null;
  Q32300_Comment: AFSAResultComment;
  Q32300_Comment_ID: number | null;
  Q33100_Comment: AFSAResultComment;
  Q33100_Comment_ID: number | null;
  Q33200_Comment: AFSAResultComment;
  Q33200_Comment_ID: number | null;
  Q34100_Comment: AFSAResultComment;
  Q34100_Comment_ID: number | null;
  Q34200_Comment: AFSAResultComment;
  Q34200_Comment_ID: number | null;
  Q35100_Comment: AFSAResultComment;
  Q35100_Comment_ID: number | null;
  Q35200_Comment: AFSAResultComment;
  Q35200_Comment_ID: number | null;
  Q51100_Comment: AFSAResultComment;
  Q51100_Comment_ID: number | null;
  Q52100_Comment: AFSAResultComment;
  Q52100_Comment_ID: number | null;
  Q52200_Comment: AFSAResultComment;
  Q52200_Comment_ID: number | null;
  Q52300_Comment: AFSAResultComment;
  Q52300_Comment_ID: number | null;
  Q52400_Comment: AFSAResultComment;
  Q52400_Comment_ID: number | null;
  SaveTriggers: AFSAUpdateTriggers[];
}

export interface ChecklistQuestionWithSubQuestion extends ChecklistQuestion {
  SubQuestions: ChecklistQuestion[];
}

export interface DynamicAssessment extends DTOBaseClass_Standard {
  DynamicAssessment_ID: number;
  SourceId: number;
  SourceType: string;
  DynamicQuestionList_ID: number;
  DynamicQuestionList_Name: string;
  SortIndex: number;
  ProductType_ENUM: ProductType;
  ProductType_Name: string;
  ChecklistType_ENUM: ChecklistType;
  ChecklistType_Name: string;
  Questionnaire: ChecklistQuestionWithSubQuestion[];
}

export enum INSPECTIONDEDUCTIONRESULT {
  NONE = 0,
  JUSTIFIED = 1,
  NOTJUSTIFIED = 2,
}
export interface RecordSourceIdentifier {
  _RecordSource_ID: number;
  _RecordSourceType_ENUM: RECORDTYPE;
  _RecordSourceType_Name: string;
  _RecordSource_ColumnName: string;
  _RecordSource_StringID: string;
  _RecordSource_FileNumber: string;
  _RecordSource_Description: string;
}

export enum NCItemResultUpdateTriggers {
  UpdateEPAContravention,
}

export interface NCItemResult extends DTOBaseClass_Standard {
  NCItemResult_ID: number;
  ItemNumber: number;
  Category_1: string;
  Category_2: string;
  Category_3: string;
  Category_4: string;
  Category_5: string;
  Category_6: string;
  ObservationSummary: string;
  ComplianceInstruction: string;
  AFSAQuestion_ID: number | null;
  FoodSafetyRatingQuestion_ID: number | null;
  Legacy_LID: number | null;
  Location_KWD: number;
  Location: Keyword;
  Risk_ENUM: NC_RISK;
  Risk_Name: string;
  Status_ENUM: NC_STATUS;
  Status_Name: string;
  FoodActSection: string;
  FSANZClause: string;
  ActionPlan: boolean;
  ComplianceDateDue: Date | null;
  RecordSourceParent: RecordSourceIdentifier;
  ActionPlan_ID: number | null;
  Flag_EPAContravention: boolean;
  AFSAResult: AFSAResult;
  NoticeType_ID: number | null;
  NoticeType_Name: string;
  NoticeCategory_KWD: number | null;
  NoticeCategory: Keyword;
  ChipNo: string;
  TagNo: string;
  EnforcementNo: string;
  OwnerName: string;
  OwnerAddress: string;
  Flag_SitePhotoVideo: boolean | null;
  AddToComplianceInstruction: boolean | null;
  Dosage: string;
  PestSpeciesType_ID: number | null;
  Species_ID: number | null;
  Species_Name: string;
  SaveTriggers: NCItemResultUpdateTriggers[];
}

export interface AssessmentResultXml {
  Height: number | null;
  Depth: number | null;
  Length: number | null;
  Distance: number | null;
  Measure: number | null;
  Calculation: number | null;
  Note: string;
}

export enum BubbleUpType {
  SystemInitialise = 0,
  Parent = 1,
  Ancestor = 2,
  Association = 3,
  Shared = 4,
}

export interface Svc_BubbleupIdentifier {
  RecordID: number;
  RecordType_ENUM: RECORDTYPE;
  RecordTypeName: string;
  RecordNumber: string;
  BubbleupType_ENUM: BubbleUpType;
  LinkManagePage?: string;
}

export interface AssessmentResult extends DTOBaseClass_Standard {
  AssessmentResult_ID: number;
  Inspection_ID: number;
  Question_ID: number;
  Comment: string;
  Flag_CorrectedOnsite: boolean | null;
  AdjustedScore: number | null;
  Legacy_LID: number | null;
  Result_ID: ASSESSMENTRESULT;
  Result_Name: string;
  Result_ID_For_Dialog: ASSESSMENTRESULT | null;
  Evidence_ID: ASSESSMENTEVIDENCE;
  Evidence_Name: string;
  FoodSafetyControl_CSV: string;
  Likelihood_ENUM: ASSESSMENTLIKELIHOOD;
  Likelihood_Name: string;
  Consequence_ENUM: ASSESSMENTCONSEQUENCE;
  Consequence_Name: string;
  Risk_ENUM: ASSESSMENTRISK;
  Risk_Name: string;
  BlobStore_ID: number | null;
  Image_Count: number | null;
  HasImages: boolean;
  AssessmentResultXml: AssessmentResultXml;
  BubbleUps: BubbleUpIdentifier[];
}

export interface AssessmentSummary {
  Inspection_ID: number;
  SummaryQuestion: string;
  SummaryResult: string;
}

export interface TemperatureRecorded extends DTOBaseClass_Standard {
  TemperatureRecorded_ID: number;
  Appliance_KWD: number;
  Inspection_ID: number;
  Temperature: number;
  Description: string;
}

export interface InterviewQuestion extends DTOBaseClass_Standard {
  Question_ID: number;
  InterviewType_ID: number;
  Question: string;
  IsDelete: boolean;
}

export enum InterviewTypeUpdateTriggers {
  UpdateInterviewQuestion,
}

export interface InterviewType extends DTOBaseClass_Standard {
  InterviewType_ID: number;
  Name: string;
  Description: string;
  InterviewClassification_ENUM: InterviewClassification | null;
  InterviewClassification_Name: string;
  SystemFilter_ENUMS: string;
  SystemFilter_Names: string;
  SortCode: number | null;
  Questions: InterviewQuestion[];
  SaveTriggers: InterviewTypeUpdateTriggers[];
}

export interface InterviewDetails {
  Question: string;
  Answer: string;
}

export interface Interview extends DTOBaseClass_Standard {
  Interview_ID: number;
  Interviewer: SiteUser;
  Interviewer_ID: number;
  Interviewee: Contact_Relation;
  Interviewee_RID: number | null;
  Description: string;
  InterviewType: InterviewType;
  InterviewType_ID: number;
  Outcome_KWD: number | null;
  Outcome_Name: string;
  DateScheduled: Date | null;
  DateCompleted: Date | null;
  Offline_Recording: Attachment;
  Offline_Signature: string;
  BubbleUps: BubbleUpIdentifier[];
  Details: InterviewDetails[];
}

export enum InspectionUpdateTriggers {
  UpdateAFSA,
  UpdatePersonContacted,
  UpdateTobaccoBuyer,
  FromOfflineModule,
  SkipFeeGeneration,
  CreateOfflineActions,
  CreateAutoActions,
  UpdateParentFromOfflineModule,
  InspectionExport_Close,
  Transfer_Inspection,
  Ancestor_InspectionFrequency_Update,
  Update_IssueCategory,
  Update_PrivateInspector,
  Dynamic_QuestionListAssessment,
}

export interface InspectionAdditionalField {
  Enum: number;
  FriendlyName: string;
  Value: string;
  HasChange: boolean;
}

export interface Contact_Relation extends DTOBaseClass_Standard {
  RID: number;
  ContactRelationshipType_ENUM: ContactRelationshipType;
  ContactRelationshipType_Name: string;
  Contact: Contact;
  DisplayName: string;
  BubbleUps: BubbleUpIdentifier[];
  _ExtensionType: ContactRelationExtension[];
  ExtensionType: ContactRelationExtension[];
  Extension: Contact_RelationExtension;
  PersonRole_KWD: number | null;
  PersonRole: Keyword;
  RelationshipComment: string;
  _KeywordTags: Keyword_Tags[];
  KeywordTags: Keyword_Tags[];
  SaveTriggers: ContactRelationshipUpdateTriggers[];
}

export interface Contact_RelationExtension {}

export interface Inspection extends DTOBaseClass_Standard {
  Inspection_ID: number;
  Legacy_LID: number | null;
  InspectionType?: InspectionType;
  InspectionType_ID: number;
  InspectionType_Name: string;
  Description: string;
  DateInspected: Date | null;
  TimeStarted: Date | null;
  TimeCompleted: Date | null;
  ReferenceNo: string;
  ActionOfficer: SiteUser;
  ActionOfficer_ID: number;
  SecondaryOfficer: SiteUser;
  SecondaryOfficer_ID: number | null;
  PrivateInspector_RID: number | null;
  PrivateInspector: Contact_Relation;
  Reason: Keyword;
  Reason_KWD: number | null;
  Compliance: Keyword;
  Compliance_KWD: number | null;
  AuditorNumber: string;
  TCR_Category: string;
  AffectSchedule: boolean | null;
  SFOD: boolean | null;
  SignoffOfficer: SiteUser;
  SignoffOfficer_ID: number | null;
  DateSignOff: Date | null;
  FollowUpRequired: boolean | null;
  OverwriteAssessmentRating: boolean | null;
  DateFollowUpRequired: Date | null;
  FollowUpAction_ID: number | null;
  PersonContacted: Contact_Relation;
  PersonContacted_WorkPhone: string;
  PersonContacted_Mobile: string;
  PersonContacted_RID: number | null;
  TobaccoBuyer: Contact_Relation;
  TobaccoBuyer_RID: number | null;
  PersonPosition: Keyword;
  PersonPosition_KWD: number | null;
  AFSAResults_ID: number | null;
  AFSAResults: AFSAResult;
  HasSearchWarrant: boolean | null;
  SearchWarrant_ID: number | null;
  SearchWarrantDescription: string;
  HasSeizure: boolean | null;
  Seizure_ID: number | null;
  SeizureDescription: string;
  Location: Keyword;
  Location_KWD: number | null;
  DateCompleted: Date | null;
  CompletedBy_ID: number | null;
  CompletedBy_Name: string;
  IssueCategory_Keyword: Keyword[];
  IssueCategories: number[];
  IssueCategories_CSV: string;
  SiteAddress: string;
  RegistrationCertificateDisplayed: number | null;
  Tobacco_VisitType_ENUM: TobaccoVisitType | null;
  Tobacco_VisitType_Name: string;
  Tobacco_Competence: number | null;
  Tobacco_TPResult: string;
  Tobacco_DateTPPrevResult: Date | null;
  Tobacco_Action: Keyword;
  Tobacco_Action_KWD: number | null;
  Tobacco_DateActionSent: Date | null;
  Tobacco_Offences_Keyword: KeyValuePacket[];
  Tobacco_Types_Keyword: KeyValuePacket[];
  RelatedNotice_ID: number | null;
  RelatedAction_ID: number | null;
  RelatedInspection_ID: number | null;
  RelatedIncident_ID: number | null;
  IssuedBy_Council_ID: number | null;
  FoodSafeScore: number | null;
  InfectionControlScore: number | null;
  ScoreChoices: string;
  AssessmentScore: number | null;
  AssessmentRating: number | null;
  AssessmentRatingDisplay: string;
  AssessmentDeductionScore: number | null;
  AssessmentComplianceScore: number | null;
  AssessmentComplianceScorePercent: number | null;
  AssessmentComplianceScoreDisplay: string;
  AssessmentCompliance: Keyword;
  AssessmentCompliance_KWD: number | null;
  QuestionList: DynamicAssessment[];
  Deductions_ENUM: INSPECTIONDEDUCTIONRESULT | null;
  Deductions_Name: string;
  Offline_PersonContacted: string;
  Offline_PersonContactedMobile: string;
  Offline_SelectedDocument_IDs: number[];
  Offline_TemplatesUpdated: boolean | null;
  Offline_RaiseFees: boolean | null;
  Offline_NCItemResults: NCItemResult[];
  Offline_AssessmentResults: AssessmentResult[];
  Offline_AssessmentSummary: AssessmentSummary[];
  Offline_TemperatureRecords: TemperatureRecorded[];
  Offline_ActionList: Action[];
  Offline_Interviews: Interview[];
  Offline_Contacts: Contact_Relation[];
  Flag_SendInspectionReportViaEmail: boolean | null;
  Offline_EmailAddress: string;
  Offline_ProprietorSignature: string;
  Offline_OfficerSignature: string;
  Offline_Photos: Attachment[];
  Offline_QuesionPhotos: Attachment[];
  BubbleUps: BubbleUpIdentifier[];
  _BubbleUps: BubbleUpIdentifier[];
  Adjustment_ENUM: ASSESSMENTADJUSTMENT | null;
  Adjustment_Name: string;
  Adjustment_Frequency: number | null;
  MedicalPractitionerName: string;
  MedicalPractitionerProcedureDetails: string;
  MedicalPractitionerAHPRANo: string;
  AssessmentAddedPCBAItems: string;
  NumberOfUnits: number | null;
  NumberOfUnits2: number | null;
  FSS: string;
  FSSCertificateDate: Date | null;
  FSSCertificateNumber: string;
  AssessmentResults: AssessmentResult[];
  NumberOfDogsOffLead: number | null;
  ParentDebtorNumber: string;
  SaveTriggers: InspectionUpdateTriggers[];
  _SaveTriggers: InspectionUpdateTriggers[];
  ProductType_ENUM: ProductType;
  ProductType_Name: string;
  Risk: Keyword;
  Risk_KWD: number | null;
  Pool_IsWorksCarriedOut: boolean | null;
  Pool_WorksCarriedOut_Desc: string;
  Flag_PrivateInspector: boolean | null;
  AutoActionTypeIds: KeyValuePair<number, boolean>[];
  AdditionalDetails: InspectionAdditionalField[];
  Transfer_ParentRecordSource: RecordSourceIdentifier;
  OutstandingItems: string;
  RequiredDocuments: string;
  HighRiskFoods: string;
  HRFComment: string;
  Enforcement_SelectedRegistration_ID: number | null;
  Enforcement_SelectedOption: EnforcementOptions | null;
}

export interface IInspectionLovs {
  Reason: IKeyValuePacket[];
  Positions: IKeyValuePacket[];
  Deductions: IKeyValuePacket[];
  Locations: IKeyValuePacket[];
  CbxTCR: IKeyValuePacket[];
  RelatedInspection: IKeyValuePacket[];
  RelatedActions: IKeyValuePacket[];
  RelatedNotice: IKeyValuePacket[];
  RelatedIncident: IKeyValuePacket[];
  Compliance: IKeyValuePacket[];
  Complied: number;
  InspectionTypeDetail: InspectionType;
}

export interface IParentInspectionsSection {
  id: number;
  recordType: RECORDTYPE;
  bubbleUps?: BubbleUpIdentifier[];
  data?: Inspection;
  notification?: IAppNotificationItemAddProps[];
  inspectionsType?: Svc_InspectionType;
}

export interface Officers {
  ID: number;
  Name: string;
  Role: string;
  WorkPhone: string;
  HomePhone: string;
  Mobile: string;
  Email: string;
  Position: string;
}
