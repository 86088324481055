import {
  IDataStateURLToNavigatePage,
  IPermissionsWrapperProps,
} from "@common/hocs/permissions/model";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { CCNotFoundPage } from "@components/cc-not-found-page/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useLocation } from "react-router-dom";

/**
 * Higher-order component that applies a permission wrapper to the provided component,
 * utilizing permission data retrieved from a hook.
 *
 * @param props - Props containing information required for permission checks and component rendering.
 * @returns A wrapped component with applied permission logic.
 */
export const renderWithPermissionsWrapper = (
  props: IPermissionsWrapperProps
) => {
  return observer(() => {
    const { useNewPermissionDataCreator, ...restProps } = props;

    // Retrieve permission data from the designated hook
    const { formIdentifier, productType, formAction } =
      useNewPermissionDataCreator
        ? useNewPermissionDataCreator(props)
        : usePermissionDataCreator(props);

    // Get the checkPermission function from the hook
    const { checkPermissions, isLoadingPermission } = useCheckPermissions({
      formIdentifier: formIdentifier,
      productType: productType,
    });

    // Check permission based on the provided form action
    const hasPermission = checkPermissions(formAction);

    // Display a loading indicator if permission data is still loading
    if (isLoadingPermission) {
      return <Loading isLoading isFullScreen />;
    }

    // Render the component based on the permission status
    const PermissionWrapper = createPermissionsWrapper(hasPermission);
    return <PermissionWrapper {...restProps} />;
  });
};

const usePermissionDataCreator = (props: IPermissionsWrapperProps) => {
  // Retrieve the current location
  const location = useLocation();
  // Extract relevant data from the location state (if available)
  const dataStateUrl = location.state as IDataStateURLToNavigatePage;
  return {
    formIdentifier: dataStateUrl?.permissionDataState?.formIdentifier,
    productType: props.permissions.productType,
    formAction: dataStateUrl?.permissionDataState?.formAction,
  };
};

export const createPermissionsWrapper = (
  hasPermission: boolean
): ((props: IPermissionsWrapperProps) => JSX.Element) => {
  return (props: IPermissionsWrapperProps) => {
    const {
      component: Component,
      placeholder = <CCNotFoundPage />,
      ...restProps
    } = props;
    if (!hasPermission) return placeholder;
    return <Component {...restProps} />;
  };
};
