import ChangeInstalmentDialog from "@app/products/property/assessments/components/dialogs/change-instalments/_index";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ChangeInstalmentButton = observer(() => {
  const { currentOrganisationMode } = useCommonProductStore();
  const isLLS = currentOrganisationMode(OrganisationMode.LLS);
  const [showChangeInstalmentDialog, setShowChangeInstalmentDialog] =
    useState(false);

  return (
    <>
      <CCNavButton
        title="Change Instalment"
        onClick={() => setShowChangeInstalmentDialog(true)}
        invisible={isLLS}
      />

      {showChangeInstalmentDialog && (
        <ChangeInstalmentDialog
          onClose={() => {
            setShowChangeInstalmentDialog(false);
          }}
        />
      )}
    </>
  );
});
