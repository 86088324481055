import { getCertificateSummaryById } from "@app/products/property/certificates/[id]/api";
import {
  DTO_AreaOfResponsibility,
  DTO_Certificate,
  DTO_Certificate_LOVs,
  DTO_Certificate_Summary,
} from "@app/products/property/certificates/[id]/model";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { addDays } from "date-fns";
import { isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class CertificateStore {
  private _certificate?: DTO_Certificate = undefined;
  private _summary?: DTO_Certificate_Summary = undefined;
  private _areaOfResponsibility: DTO_AreaOfResponsibility[] = [];
  private _certificateLov?: DTO_Certificate_LOVs = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get certificate() {
    return toJS(this._certificate);
  }
  setCertificate = (certificate?: DTO_Certificate) => {
    runInAction(() => {
      this._certificate = certificate;
    });
  };

  get certificateLov() {
    return toJS(this._certificateLov);
  }
  setCertificateLov = (certificateLov: DTO_Certificate_LOVs) => {
    runInAction(() => {
      this._certificateLov = certificateLov;
    });
  };

  get areaOfResponsibility() {
    return toJS(this._areaOfResponsibility);
  }
  setAreaOfResponsibility = (data: DTO_AreaOfResponsibility[]) => {
    runInAction(() => {
      this._areaOfResponsibility = data;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  get certificateId() {
    return this.certificate?.CertificateId;
  }

  get summary() {
    return toJS(this._summary);
  }
  setSummary = (summary?: DTO_Certificate_Summary) => {
    runInAction(() => {
      this._summary = summary;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._certificate = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._summary = undefined;
    });
  };

  loadCertificate = async (
    certificateId: number,
    notification?: IAppNotificationItemAddProps
  ) => {
    this.setIsLoading(true);
    const response = await getCertificateSummaryById(certificateId);
    let errorResponse = undefined;
    if (Array.isArray(response)) {
      const [lovsCertificate, certificate] = response;
      if (
        isSuccessResponse(lovsCertificate) &&
        isSuccessResponse(certificate) &&
        lovsCertificate?.data &&
        certificate?.data
      ) {
        let expectedCompletion =
          certificate?.data?.Certificate?.Cert_Originated_DateTime;
        if (!isNil(expectedCompletion)) {
          expectedCompletion = addDays(
            certificate?.data?.Certificate?.Cert_Originated_DateTime,
            certificate?.data?.Certificate?.Expected_Completion_Days ?? 0
          );
        }
        this.setSummary(certificate?.data);
        this.setCertificate({
          ...certificate?.data?.Certificate,
          Expected_Completion: expectedCompletion,
        });
        this.setAreaOfResponsibility(
          certificate?.data?.AreaOfResponsibility ?? []
        );
        this.setCertificateLov({
          ...lovsCertificate?.data,
          CertificateType: convertValueLOVToNumber(
            lovsCertificate?.data?.CertificateType ?? [],
            "Code"
          ),
          RequestSource: convertValueLOVToNumber(
            lovsCertificate?.data?.RequestSource ?? [],
            "Code"
          ),
        });
      } else {
        let responseError: APIResponse<
          DTO_Certificate_LOVs | DTO_Certificate_Summary
        > = response[0];
        if (!isSuccessResponse(response[1])) {
          responseError = response[1];
        }
        errorResponse = {
          status: responseError.status,
          error: responseError.error,
        };
      }
    } else {
      const res = response as APIResponse;
      errorResponse = {
        status: res.status,
        error: res.error ?? "Load data failed",
      };
    }
    this.setResponseLoadError(errorResponse);
    if (notification) {
      appNotificationStore.pushNotification(notification);
    }
    this.setIsLoading(false);
  };

  reLoadCertificate = async (notification?: IAppNotificationItemAddProps) => {
    if (this.certificateId)
      await this.loadCertificate(this.certificateId, notification);
  };
}

const certificateStoreContext = createContext(new CertificateStore());
export const useCertificateStore = () => {
  return useContext(certificateStoreContext);
};
