import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { EventView } from "@app/products/crms/all-events/by-refno/model";
import {
  iconColumnViewConfigAlert,
  iconColumnViewConfigCallReturn,
  iconColumnViewConfigUrgent,
} from "@app/products/crms/config";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<EventView>();

export const colVIP: IColumnFields[] = [
  {
    field: nameOf("RefNo"),
    title: "Reference Number",
    locked: true,
    linkTo: (dataItem: EventView) => {
      if (dataItem.IsHideInfo) {
        return undefined;
      }
      return `${CRMS_ROUTE}/${dataItem.ID}`;
    },
  },
  ...iconColumnViewConfigAlert,
  ...iconColumnViewConfigUrgent,
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("DateTarget"),
    title: "Target",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Location"),
    title: "Location",
  },
  ...iconColumnViewConfigCallReturn,
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("DateRecorded"),
    title: "Recorded",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("RecordedBy"),
    title: "Recorded By",
  },
  {
    field: nameOf("OnBehalfOf_DisplayName"),
    title: "Onbehalf Of",
  },
  {
    field: nameOf("OnBehalfOf_Email"),
    title: "Email",
  },
  {
    field: nameOf("OnBehalfOf_WorkPhone"),
    title: "Work Phone",
  },
  {
    field: nameOf("VIP"),
    title: "VIP",
  },
];
