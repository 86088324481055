import { VO_NoticeOfSale_Service_Notice_Address } from "@app/products/property/changes-of-ownership/notice-of-sales/components/detail/components/service-of-notice/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_NoticeOfSale_Service_Notice_Address>();
export const serviceOfNoticeColumns: IColumnFields[] = [
  {
    field: nameOf("Contact_Name"),
    title: "Contact Name",
  },
  {
    field: nameOf("Property_Name"),
    title: "Property name",
  },
  {
    field: nameOf("Unit_Number"),
    title: "Unit Number",
  },
  {
    field: nameOf("Unit_Suffix"),
    title: "Unit Suffix",
  },
  {
    field: nameOf("Address_Line1"),
    title: "Address Line 1",
  },
  {
    field: nameOf("Address_Line2"),
    title: "Address Line 2",
  },
  {
    field: nameOf("Address_Line3"),
    title: "Address Line 3",
  },
  {
    field: nameOf("Address_Line4"),
    title: "Address Line 4",
  },
  {
    field: nameOf("House_Number1"),
    title: "House Number 1",
  },
  {
    field: nameOf("House_Prefix1"),
    title: "House Prefix 1",
  },
  {
    field: nameOf("House_Suffix1"),
    title: "House Suffix 1",
  },
  {
    field: nameOf("House_Number2"),
    title: "House Number 2",
  },
  {
    field: nameOf("House_Prefix2"),
    title: "House Prefix 2",
  },
  {
    field: nameOf("House_Suffix2"),
    title: "House Suffix 2",
  },
  {
    field: nameOf("Floor_Number"),
    title: "Floor Number",
  },
  {
    field: nameOf("Floor_Type"),
    title: "Floor Type",
  },
  {
    field: nameOf("Floor_Suffix"),
    title: "Floor Suffix",
  },
  {
    field: nameOf("Location_Description"),
    title: "Location Descriptor",
  },
  {
    field: nameOf("Street_Name"),
    title: "Street Name",
  },
  {
    field: nameOf("Street_Type"),
    title: "Street Type",
  },
  {
    field: nameOf("Street_Suffix"),
    title: "Street Suffix",
  },
  {
    field: nameOf("Suburb"),
    title: "Suburb",
  },
  {
    field: nameOf("Postcode"),
    title: "Postcode",
  },
  {
    field: nameOf("State"),
    title: "State",
  },
  {
    field: nameOf("Country"),
    title: "Country",
  },
];
