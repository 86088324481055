import { DTO_Modify_Parcel_LOVs } from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class CreateParcelWorkflowStore {
  private _createParcelLOVs?: DTO_Modify_Parcel_LOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get createParcelLOVs() {
    return this._createParcelLOVs;
  }
  setCreateParcelLOVs = (createParcelLOVs?: DTO_Modify_Parcel_LOVs) => {
    runInAction(() => {
      this._createParcelLOVs = createParcelLOVs;
    });
  };
}

const createParcelWorkflowStoreContext = createContext(
  new CreateParcelWorkflowStore()
);
export const useCreateParcelStore = () => {
  return useContext(createParcelWorkflowStoreContext);
};
