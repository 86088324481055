import { colContactAssociationAssessment } from "@app/products/property/components/associations/components/assessment/contact-assessment/config";
import { DTO_AssociatedItem_Assessment } from "@app/products/property/components/associations/components/assessment/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IContactAssessmentProps {
  data: DTO_AssociatedItem_Assessment[];
}

const nameOf = nameOfFactory<DTO_AssociatedItem_Assessment>();
export const ContactAssociationAssessment = observer(
  ({ data }: IContactAssessmentProps) => {
    //Get labels
    const [
      assessmentNumberLabel,
      assessmentReferenceLabel,
      assessmentGroupLabel,
    ] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.AssessmentNumber,
      ECustomColNameProperty.Assessment_Reference,
      ECustomColNameProperty.AssessmentGroup,
    ]);

    const newColumnData = useMemo(() => {
      return colContactAssociationAssessment.map((column: IColumnFields) => {
        switch (column.field) {
          case nameOf("AssessmentNumber"):
            return {
              ...column,
              title: assessmentNumberLabel ?? column.title,
              id: `cc-grid-cell-${ECustomColNameProperty.AssessmentNumber}`,
            };
          case nameOf("AssessmentReference"):
            return {
              ...column,
              title: assessmentReferenceLabel ?? column.title,
              id: `cc-grid-cell-${ECustomColNameProperty.Assessment_Reference}`,
            };
          case nameOf("AssessmentGroup"):
            return {
              ...column,
              title: assessmentGroupLabel ?? column.title,
              id: `cc-grid-cell-${ECustomColNameProperty.AssessmentGroup}`,
            };
          default:
            return column;
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <CCGrid
        data={data}
        columnFields={newColumnData}
        primaryField={nameOf("Id")}
      />
    );
  }
);
