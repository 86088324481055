import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { NewLocalLawsButton } from "@app/products/local-laws/components/action-bar/buttons/new-local-laws/_index";
import { LocalLawsActionBarNavDropdown } from "@app/products/local-laws/components/action-bar/nav-dropdown/_index";
import { mockLocalLawsAll } from "@app/products/local-laws/mock";
import { localLawsRoute } from "@app/products/local-laws/route";
import { LocalLawsSystemAdminPermitTypesBookmark } from "@app/products/local-laws/system-admin/permit-types/util";
import { systemAdminRoute } from "@app/products/local-laws/system-admin/route";
import { LocalLawsContactsTab } from "@app/products/local-laws/[id]/components/reference-sidebar/contacts/_index";
import { LocalLawsDetailsTab } from "@app/products/local-laws/[id]/components/reference-sidebar/details/_index";
import { LocalLawsHistoryTab } from "@app/products/local-laws/[id]/components/reference-sidebar/history/_index";
import { LOCAL_LAWS_MANAGE_ROUTE } from "@app/products/local-laws/[id]/constant";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { colLocalLawsSystemAdminPermitTypes } from "./config";

export default () => {
  useCCListViewActionBar({
    title: localLawsRoute.name,
    leftComponents: [
      <LocalLawsActionBarNavDropdown category={systemAdminRoute.path} />,
    ],
    centerComponents: [
      <NewLocalLawsButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.LLPermits}
        dataSetFilter={MAILMERGEDATASET.LLP_Permit}
        recordType={RECORDTYPE.LLP_Permit}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.LLPermits}
        recordType={RECORDTYPE.LLP_Permit}
        keyField={"ID"}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={LOCAL_LAWS_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.LLPermits}
        //TODO don't have exactly record type, temporary use LLP_Permit
        recordType={RECORDTYPE.LLP_Permit}
        listViewDetail={
          LocalLawsSystemAdminPermitTypesBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          LocalLawsSystemAdminPermitTypesBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <LocalLawsDetailsTab />,
      },
      { title: "Contacts", component: <LocalLawsContactsTab /> },
      { title: "History", component: <LocalLawsHistoryTab /> },
    ],
  });

  return (
    <CCProductListView
      data={mockLocalLawsAll}
      columnFields={colLocalLawsSystemAdminPermitTypes}
      primaryField={"ID"}
    />
  );
};
