import {
  DTO_TitleLookupObj,
  DTO_Title_Lookup,
  fnt_Title_LookupResult,
} from "@app/products/property/components/dialogs/add-title-lookup/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IODataResponse } from "@common/models/odataResponse";
import { CancelTokenSource } from "axios";

export const getSearchTitleLookup = async (
  searchData: DTO_TitleLookupObj,
  requestController?: CancelTokenSource
): Promise<APIResponse<DTO_Title_Lookup>> => {
  try {
    return await CoreAPIService.getClient().post<DTO_Title_Lookup>(
      `api/property/int/title/lookup`,
      searchData,
      {
        cancelToken: requestController?.token,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error:
        requestController?.token.reason?.message ??
        errorResponse?.data?.Message,
    };
  }
};

export const titleLookupOData = async (
  searchData: DTO_TitleLookupObj,
  requestController?: CancelTokenSource
): Promise<APIResponse<IODataResponse<fnt_Title_LookupResult> | undefined>> => {
  const { LookupKey, LookupValue, Statuses } = searchData;
  try {
    return await CoreAPIService.getClient().get(
      `odata/property/internal/titlelookupregister?lookupKey=${LookupKey}&lookupValue=${LookupValue}${
        Statuses.length > 0 ? `&status=${Statuses.toString()}` : "0" // default task 12866
      }&$count=true`,
      {
        cancelToken: requestController?.token,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error:
        requestController?.token.reason?.message ??
        errorResponse?.data?.Message,
    };
  }
};
