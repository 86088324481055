export enum PRODUCT_TYPE {
  SystemInitialise = "SystemInitialise",
  Core = "Core",
  Contact = "Contact",
  Action = "Action",
  HealthManager = "HealthManager",
  CRS = "CRS",
  WasteWater = "WasteWater",
  Reports = "Reports",
  MailMerge = "MailMerge",
  Dashboards = "Dashboards",
  CustomerService = "CustomerService",
  HomePage = "HomePage",
  Complaints = "Complaints",
  Notices = "Notices",
  Streatrader_DONOTUSE = "Streatrader_DONOTUSE",
  SRS_DONOTUSE = "SRS_DONOTUSE",
  EPlanning = "EPlanning",
  WasteWaterSPI = "WasteWaterSPI",
  ELF = "ELF",
  DevApps = "DevApps",
  MaternalChildHealth = "MaternalChildHealth",
  Building = "Building",
  TownPlanning = "TownPlanning",
  LLPermits = "LLPermits",
  Animals = "Animals",
  CRSPortal = "CRSPortal",
  FSR = "FSR",
  CSM = "CSM",
  EHealth = "EHealth",
  StreetProtection = "StreetProtection",
  AppointmentManagementSystem = "AppointmentManagementSystem",
  EventManagementSystem = "EventManagementSystem",
  PortalCore = "PortalCore",
  AppointmentBookingSystem = "AppointmentBookingSystem",
  EventBookingSystem = "EventBookingSystem",
  FoodAct = "FoodAct",
  NSPR = "NSPR",
  Radiation = "Radiation",
  StandardsAndRegulation = "StandardsAndRegulation",
  DWCMS = "DWCMS",
  FleetBooking = "FleetBooking",
  CommunityAsset = "CommunityAsset",
  CommunityProperty = "CommunityProperty",
  LandManagement = "LandManagement",
  FakeProduct = "FakeProduct",
  Agent = "Agent",
  ThirdParty = "ThirdParty",
  DISRES = "DISRES",
  CM_DONOTUSE = "CM_DONOTUSE",
  CC = "CC",
  PAM = "PAM",
  ITSupport = "ITSupport",
  CRMS_HardRubbish = "CRMS_HardRubbish",
  CRMS_PhoneMessages = "CRMS_PhoneMessages",
  CRMS_Payroll = "CRMS_Payroll",
  CRSCentreAccess = "CRSCentreAccess",
  TrainingManager = "TrainingManager",
  TelephoneActivity = "TelephoneActivity",
  Asset = "Asset",
  Property = "Property",
  Payroll = "Payroll",
  Receipting = "Receipting",
  HumanResource = "HumanResource",
  GIS = "GIS",
  Infringement = "Infringement",
  Project = "Project",
  CouncilOnline = "CouncilOnline",
  SSMS = "SSMS",
  Records = "Records",
  OtherSystems = "OtherSystems",
  Hazard = "Hazard",
  Cemeteries = "Cemeteries",
  OpenOfficeCRM = "OpenOfficeCRM",
  Infringements = "Infringements",
}

export enum PRODUCT_TYPE_NUMBER {
  SystemInitialise = 0,
  Core = 1,
  Contact = 2,
  Action = 3,
  HealthManager = 4,
  CRS = 5,
  WasteWater = 6,
  Reports = 7,
  MailMerge = 8,
  Dashboards = 9,
  CustomerService = 10,
  HomePage = 11,
  Complaints = 12,
  Notices = 13,
  Streatrader_DONOTUSE = 14,
  SRS_DONOTUSE = 15,
  EPlanning = 16,
  WasteWaterSPI = 17,
  ELF = 18,
  DevApps = 19,
  MaternalChildHealth = 20,
  Building = 21,
  TownPlanning = 22,
  LLPermits = 23,
  Animals = 24,
  CRSPortal = 25,
  FSR = 26,
  CSM = 27,
  EHealth = 28,
  StreetProtection = 29,
  AppointmentManagementSystem = 30,
  EventManagementSystem = 31,
  PortalCore = 32,
  AppointmentBookingSystem = 33,
  EventBookingSystem = 34,
  FoodAct = 35,
  NSPR = 36,
  Radiation = 37,
  StandardsAndRegulation = 38,
  DWCMS = 39,
  FleetBooking = 40,
  CommunityAsset = 41,
  CommunityProperty = 42,
  LandManagement = 43,
  FakeProduct = 98,
  Agent = 99,
  ThirdParty = 100,
  DISRES = 250,
  CM_DONOTUSE = 251,
  CC = 252,
  PAM = 253,
  ITSupport = 254,
  CRMS_HardRubbish = 255,
  CRMS_PhoneMessages = 256,
  CRMS_Payroll = 257,
  CRSCentreAccess = 258,
  TrainingManager = 259,
  TelephoneActivity = 260,
  Asset = 261,
  Property = 262,
  Payroll = 263,
  Receipting = 264,
  HumanResource = 265,
  GIS = 266,
  Infringement = 267,
  Project = 268,
  CouncilOnline = 269,
  SSMS = 270,
  Records = 271,
  OtherSystems = 272,
  Hazard = 273,
  Cemeteries = 274,
  OpenOfficeCRM = 275,
  Infringements = 276,
}
