import {
  ADDRESS_VALIDATION_FORM_STEP,
  AddressValidationStep,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/_index";
import { AddressValidationType } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/model";
import {
  ADDRESS_FORM_STEP,
  AddressStep,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address/_index";
import {
  DETAIL_FORM_STEP,
  DetailStep,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/detail/_index";
import {
  MAP_FORM_STEP,
  MapStep,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/map/_index";
import {
  PEOPLE_FORM_STEP,
  PeopleStep,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/people/_index";
import {
  SUMMARY_FORM_STEP,
  SummaryStep,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/summary/_index";
import {
  checkEmptySimpleAddressForm,
  checkModifySimpleAddressForm,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/util";
import { Address_BuildAddress } from "@app/products/town-planning/ppr/[id]/model";
import { Address } from "@app/products/waste-water/[id]/model";
import { AddressClassification } from "@common/input-pickers/address/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import {
  getDisplayBuildingUnitNumber,
  getDisplayFloorNumber,
  getDisplayHouseNumber,
  getDisplayStreetName,
} from "@common/utils/formatting";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import { isEmpty, isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef, useState } from "react";

interface IPropertyDetailDialogProps {
  onClose: () => void;
  onSubmit: (data: Address_BuildAddress) => void;
  isLoading: boolean;
  notification?: IAppNotificationItemAddProps;
  initialData?: any;
}

export const ComplexPropertyDetailDialog = observer(
  ({
    onClose,
    onSubmit,
    isLoading,
    notification,
    initialData,
  }: IPropertyDetailDialogProps) => {
    const { settings } = useCommonProductStore();
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);
    const [originalFormData, setOriginalFormData] = useState<Address>();
    const addressValidationSetting = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_AddressValidation]
    );

    const isValidateAddress = useMemo(() => {
      if (
        addressValidationSetting &&
        addressValidationSetting === AddressValidationType.DELWPMapShare
      ) {
        return true;
      } else {
        return false;
      }
    }, [addressValidationSetting]);

    const steps: IStep[] = [
      {
        label: "Address",
        component: AddressStep,
        visible: true,
        key: ADDRESS_FORM_STEP,
      },
      {
        label: "Address validation",
        component: AddressValidationStep,
        visible: isValidateAddress,
        key: ADDRESS_VALIDATION_FORM_STEP,
        options: {
          setOriginalFormData,
        },
      },
      {
        label: "Map",
        component: MapStep,
        visible: true,
        key: MAP_FORM_STEP,
      },
      {
        label: "Detail",
        component: DetailStep,
        visible: true,
        key: DETAIL_FORM_STEP,
      },
      {
        label: "People",
        component: PeopleStep,
        visible: true,
        key: PEOPLE_FORM_STEP,
      },
      {
        label: "Summary",
        component: SummaryStep,
        visible: true,
        key: SUMMARY_FORM_STEP,
      },
    ];

    const handleOnSubmit = (value: any) => {
      if (!value.Address) {
        onClose();
        return;
      }
      delete value._option;
      delete value[ADDRESS_VALIDATION_FORM_STEP];
      delete value[MAP_FORM_STEP];
      delete value[DETAIL_FORM_STEP];
      delete value[PEOPLE_FORM_STEP];
      delete value[SUMMARY_FORM_STEP];

      if (checkEmptySimpleAddressForm(value.Address)) {
        value.Address.VMAS_Verified = undefined;
      }
      if (value.Address.VMAS_Verified) {
        if (!isEqual(originalFormData, value.Address)) {
          value.Address.VMAS_Verified = false;
        }
      } else {
        if (checkModifySimpleAddressForm(value.Address)) {
          value.Address.VMAS_Verified = false;
        }
      }

      const buildAddress: Address_BuildAddress = { ...value };
      buildAddress.Address.AddressClassification_ENUM =
        AddressClassification.Property;
      buildAddress.bAddressChanged = true;
      buildAddress.Address.UnitNo =
        (!isEmpty(
          getDisplayFloorNumber(buildAddress?.Address?.PropertyAssessment)
        )
          ? `${getDisplayFloorNumber(
              buildAddress?.Address?.PropertyAssessment
            )} `
          : "") +
        getDisplayBuildingUnitNumber(buildAddress?.Address?.PropertyAssessment);
      buildAddress.Address.StreetNo = getDisplayHouseNumber(
        buildAddress?.Address?.PropertyAssessment
      );
      buildAddress.Address.StreetName = getDisplayStreetName(
        buildAddress?.Address?.PropertyAssessment
      );
      onSubmit(buildAddress as Address_BuildAddress);
    };

    useEffect(() => {
      if (!notification) {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.resetNotifications();
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification(notification);
      }
    }, [notification]);

    useEffect(() => {
      notificationFormStepRef?.current?.setLoadingFormStep(isLoading);
    }, [isLoading]);

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        onSubmit={handleOnSubmit}
        initialSteps={steps}
        initialValues={initialData}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            titleHeader={"Property Details"}
            onClose={onClose}
            bodyElement={renderProps.children}
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
                <Button
                  themeColor="primary"
                  id="cc-next-step-button"
                  disabled={renderProps.nextButton.disabled}
                  className={"cc-dialog-button"}
                  onClick={renderProps.nextButton.onClick}
                >
                  {renderProps.nextButton.label}
                </Button>
              </div>
            }
          />
        )}
      />
    );
  }
);
