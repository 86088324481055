import { DTO_ModifyParcel_Address } from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/model";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_ModifyParcel_Address>();
export const colParcelAddresses: IColumnFields[] = [
  {
    field: nameOf("AddressType"),
    title: "Address Type",
  },
  {
    field: nameOf("IsAddressPrimary"),
    title: "Is Primary?",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("PropertyName"),
    title: "Property Name",
  },
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("LocationDescriptor"),
    title: "Location Description",
  },
  {
    field: nameOf("MapNumber"),
    title: "Map Number",
  },
  {
    field: nameOf("MapReference"),
    title: "Map Reference",
  },
  {
    field: nameOf("Id"),
    title: "Address ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Address_PFI"),
    title: "Address PFI",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
