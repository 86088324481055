import { getRLPBoardLOV } from "@app/products/property/assessments/components/form-steps/new-assessment/api";
import { checkNumberOnlyValidator } from "@app/products/property/assessments/components/form-steps/new-assessment/util";
import { useReactivateAssessmentDialogStore } from "@app/products/property/assessments/components/form-steps/reactivate-assessment/store";
import { assessmentGISValidator } from "@app/products/property/assessments/components/util";
import { GISReferenceDialog } from "@app/products/property/components/dialogs/gis-reference/_index";
import { colGISReference } from "@app/products/property/components/dialogs/gis-reference/config";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { WorkflowTypes, nameOfLov } from "@app/products/property/model";
import {
  convertValueLOVToNumber,
  isFieldVisible,
} from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import {
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { DTO_LOV } from "@common/models/odataResponse";
import { Label } from "@common/stores/products/config";
import { getDropdownValue, getUUID, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { isNil, upperFirst } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";

const nameOfGIS = nameOfFactory<DTO_GIS>();
export const AssessmentDetailsFormStep = (props: IFormStepElement) => {
  const formValidator = useCallback((value) => {
    return assessmentGISValidator(
      value?.GisReferences ?? [],
      nameOfGIS("Effective_From"),
      nameOfGIS("Effective_To")
    );
  }, []);
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? formValidator : undefined}
    />
  );
};

const requiredAndNumberOnlyValidator = (value: any) => {
  return requiredValidator(value) || checkNumberOnlyValidator(value);
};

const FormStepElement = observer(
  ({
    nameOf,
    options = {
      isReadOnly: false,
      isActro: false,
      isLLS: false,
    },
    isLoadingStep,
    setIsLoadingStep = () => {},
    loadFailedStep,
    setLoadFailedStep = () => {},
    formRenderProps,
  }: IFormStepElement) => {
    //@Store
    const { reactivateAssessmentLOVs } = useReactivateAssessmentDialogStore();
    const [rlpBoard, setRlpBoard] = useState<DTO_LOV[]>();
    const [showDialog, setShowDialog] = useState<boolean>();
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const gis = getFieldValue("GisReferences") ?? [];
    const selectedGIS = getFieldValue("_option.GISSelected") ?? [];

    const assessmentStatusLOV = convertValueLOVToNumber<DTO_LOV>(
      reactivateAssessmentLOVs?.Assessment_Status ?? [],
      "Code"
    );
    const assessmentTypeLOV = convertValueLOVToNumber<DTO_LOV>(
      reactivateAssessmentLOVs?.Assessment_Type ?? [],
      "Code"
    );
    const assessmentSuburbLOV = convertValueLOVToNumber<DTO_LOV>(
      reactivateAssessmentLOVs?.LocalityName_Id ?? [],
      "Code"
    );
    const assessmentGroupLOV = convertValueLOVToNumber<DTO_LOV>(
      reactivateAssessmentLOVs?.Assessment_Group ?? [],
      "Code"
    );

    const assessmentLgaLOV = convertValueLOVToNumber<DTO_LOV>(
      reactivateAssessmentLOVs?.Assessment_LGA ?? [],
      "Code"
    );

    const assessmentGroupId = getFieldValue("Sector");

    //Get labels
    const [assessmentNumberLabel, sectorLabel, categoryLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.AssessmentNumber,
        ECustomColNameProperty.Sector,
        ECustomColNameProperty.Category,
      ]);

    /**
     * handle add item for (GIS Reference)
     * @param data
     */
    const handleAddItem = async (data: DTO_GIS) => {
      //get previous data GIS
      const previousGIS = gis?.length ? [...gis] : [];
      //add more temporary ID for new record
      const newGIS = { ...data, Id: `new_${getUUID()}` };
      //merge previous data and new one
      const gisData = [...previousGIS, newGIS];
      onChange(nameOf("GisReferences"), {
        value: gisData,
      });
    };

    /**
     * handle delete item (GIS Reference)
     */
    const handleDeleteItem = () => {
      const gisId = selectedGIS?.[0]?.Id;
      //filter new list GIS without selected record
      const newGIS = gis.filter((item: DTO_GIS) => item.Id !== gisId);
      onChange(nameOf("GisReferences"), {
        value: newGIS,
      });
      onChange(nameOf("_option.GISSelected"), {
        value: [],
      });
    };

    const handleDataChangeGrid = async (
      dataRow: DTO_GIS,
      fieldChange: string
    ) => {
      //GIS Reference
      const gisId = dataRow?.Id;
      let newGIS = [...gis];
      // update value Effective_To and Effective_From
      newGIS = newGIS.map((item: DTO_GIS) => {
        if (
          item.Id === gisId &&
          (fieldChange === nameOfGIS("Effective_To") ||
            fieldChange === nameOfGIS("Effective_From"))
        ) {
          return {
            ...item,
            Effective_To: dataRow[nameOfGIS("Effective_To")] ?? null,
            Effective_From: dataRow[nameOfGIS("Effective_From")] ?? null,
          };
        }
        return item;
      });
      onChange(nameOf("GisReferences"), { value: newGIS });
    };

    const getRLPBoard = async (assessmentGroupId: string) => {
      if (isNil(assessmentGroupId)) {
        setRlpBoard([]);
      } else {
        setIsLoadingStep(true);
        const response = await getRLPBoardLOV(
          +assessmentGroupId,
          WorkflowTypes.Reactivate_Assessment
        );
        setIsLoadingStep(false);
        if (isSuccessResponse(response) && response?.data) {
          const assessmentRLP =
            convertValueLOVToNumber<DTO_LOV>(
              response.data?.dtoCreate_Assessment_LOVs
                ?.Differential_Rate_Classification ?? [],
              "Code"
            ) ?? [];
          setRlpBoard(assessmentRLP);
        } else {
          setLoadFailedStep({
            onReload: () => {
              getRLPBoardLOV(
                +assessmentGroupId,
                WorkflowTypes.Reactivate_Assessment
              );
            },
            responseError: {
              status: response.status,
              error: response.error ?? "Load failed",
            },
          });
        }
      }
    };

    useEffect(() => {
      if (!isNil(assessmentGroupId)) {
        getRLPBoard(assessmentGroupId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assessmentGroupId]);

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }

    if (loadFailedStep) {
      return (
        <CCLoadFailed
          onReload={loadFailedStep?.onReload}
          responseError={loadFailedStep?.responseError}
        />
      );
    }

    return (
      <section className="cc-field-group">
        {!options?.isActro && (
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel
                title={upperFirst(assessmentNumberLabel.toLocaleLowerCase())}
              />
              <Field
                name={nameOf("AssessmentNumber")}
                placeholder={upperFirst(
                  assessmentNumberLabel.toLocaleLowerCase()
                )}
                component={CCNumericTextBox}
                min={0}
                format={NUMBER_FORMAT.NUMBER2}
                readOnly
              />
            </div>
          </div>
        )}

        <>
          <div className="cc-field-group">
            {!options?.isLLS && <label className="cc-label">Reference</label>}
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                {options?.isActro && (
                  <>
                    <div className="cc-field">
                      <label className="cc-label">
                        Suburb
                        <CCTooltip type="validator" position="right" />
                      </label>
                      <Field
                        name={nameOf("Suburb")}
                        component={CCSearchComboBox}
                        data={assessmentSuburbLOV ?? []}
                        textField={nameOfLov("Name")}
                        dataItemKey={nameOfLov("Code")}
                        disabled={options?.isReadOnly}
                        validator={
                          !options?.isReadOnly ? requiredValidator : undefined
                        }
                        value={getDropdownValue(
                          +valueGetter(nameOf("Suburb")),
                          assessmentSuburbLOV,
                          "Code"
                        )}
                        onChange={(event: ComboBoxChangeEvent) => {
                          onChange(nameOf("Suburb"), {
                            value: event.target.value?.Code ?? null,
                          });
                        }}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">
                        Section
                        <CCTooltip type="validator" position="right" />
                      </label>
                      <Field
                        name={nameOf("Section")}
                        placeholder={"Section"}
                        component={CCInput}
                        maxLength={3}
                        validator={
                          !options?.isReadOnly
                            ? requiredAndNumberOnlyValidator
                            : undefined
                        }
                        readOnly={options?.isReadOnly}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">
                        Block
                        <CCTooltip type="validator" position="right" />
                      </label>
                      <Field
                        name={nameOf("Block")}
                        placeholder={"Block"}
                        component={CCInput}
                        maxLength={4}
                        validator={
                          !options?.isReadOnly
                            ? requiredAndNumberOnlyValidator
                            : undefined
                        }
                        readOnly={options?.isReadOnly}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Unit</label>
                      <Field
                        name={nameOf("Unit")}
                        placeholder={"Unit"}
                        component={CCInput}
                        maxLength={3}
                        validator={
                          !options?.isReadOnly
                            ? checkNumberOnlyValidator
                            : undefined
                        }
                        readOnly={options?.isReadOnly}
                      />
                    </div>
                    {getFieldValue("Unit") ? (
                      <div className="cc-field">
                        <label className="cc-label">Entitlement</label>
                        <Field
                          name={nameOf("UnitEntitlement")}
                          placeholder={"Entitlement"}
                          component={CCInput}
                          maxLength={3}
                          validator={
                            !options?.isReadOnly
                              ? checkNumberOnlyValidator
                              : undefined
                          }
                          readOnly={options?.isReadOnly}
                        />
                      </div>
                    ) : null}
                  </>
                )}
                {!options?.isLLS && (
                  <div className="cc-field">
                    <label className="cc-label">Is common property</label>
                    <Field
                      name={nameOf("IsCommonProperty")}
                      checked={getFieldValue("IsCommonProperty")}
                      component={CCSwitch}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {options?.isActro && getFieldValue("Unit") ? (
            <div className="cc-field-group">
              <label className="cc-label">Plan registration</label>
              <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">Number</label>
                    <Field
                      name={nameOf("PlanRegistrationNumber")}
                      placeholder={"Number"}
                      component={CCInput}
                      validator={
                        !options?.isReadOnly
                          ? checkNumberOnlyValidator
                          : undefined
                      }
                      readOnly={options?.isReadOnly}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Date</label>
                    <Field
                      name={nameOf("PlanRegistrationDate")}
                      component={CCDatePicker}
                      format={DATE_FORMAT.DATE_CONTROL}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Status</label>
            <Field
              name={nameOf("Status")}
              component={CCSearchComboBox}
              disabled={true}
              data={assessmentStatusLOV ?? []}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              value={getDropdownValue(
                valueGetter(nameOf("Status")),
                assessmentStatusLOV,
                "Code"
              )}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Type
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("Type")}
              component={CCSearchComboBox}
              disabled={options?.isReadOnly}
              data={assessmentTypeLOV ?? []}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              isUseDefaultOnchange
            />
          </div>
          {!options?.isLLS && (
            <div className="cc-field">
              <CCLabel title="Valuation number" />
              <Field
                name={nameOf("ValuationNumber")}
                placeholder={"Valuation number"}
                component={CCNumericTextBox}
                format={NUMBER_FORMAT.NUMBER2}
                disabled={options?.isReadOnly}
              />
            </div>
          )}
        </div>

        {options?.isActro || options?.isLLS ? (
          <>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title={sectorLabel} />
                <Field
                  name={nameOf("Sector")}
                  component={CCSearchComboBox}
                  disabled={options?.isReadOnly}
                  data={assessmentGroupLOV ?? []}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  onChange={(event: ComboBoxChangeEvent) => {
                    onChange(nameOf("Sector"), {
                      value: event.value?.Code,
                    });
                    getRLPBoard(event.value?.Code);
                    onChange(nameOf("Region_Name"), {
                      value: event.value?.Name,
                    });
                    onChange(nameOf("Differential_Rate_Classification_Id"), {
                      value: "",
                    });
                    onChange(nameOf("DifferentialCategoryName"), {
                      value: "",
                    });
                  }}
                />
              </div>
              {/* @TODO: Integrate API */}
              <div className="cc-field">
                <CCLabel title={categoryLabel} />
                <Field
                  name={nameOf("Differential_Rate_Classification_Id")}
                  component={CCSearchComboBox}
                  disabled={options?.isReadOnly}
                  data={rlpBoard ?? []}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  onChange={(event: ComboBoxChangeEvent) => {
                    onChange(nameOf("Differential_Rate_Classification_Id"), {
                      value: event.value?.Code,
                    });
                    onChange(nameOf("DifferentialCategoryName"), {
                      value: event.value?.Name,
                    });
                  }}
                />
              </div>
              {options?.isLLS && (
                <div className="cc-field">
                  <CCLabel title="LGA" />
                  <Field
                    name={nameOf("LGA_Id")}
                    component={CCSearchComboBox}
                    disabled={options?.isReadOnly}
                    data={assessmentLgaLOV ?? []}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    isUseDefaultOnchange
                  />
                </div>
              )}
              <div className="cc-field">
                <CCLabel
                  title="Primary land use"
                  isMandatory={options?.isLLS ?? false}
                />
                <Field
                  name={nameOf("PrimaryLandUseId")}
                  component={CCSearchComboBox}
                  disabled={options?.isReadOnly}
                  data={
                    reactivateAssessmentLOVs?.Assessment_Primary_Land_Use ?? []
                  }
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  validator={
                    options?.isLLS && !options?.isReadOnly
                      ? requiredValidator
                      : undefined
                  }
                  value={getDropdownValue(
                    "" + valueGetter(nameOf("PrimaryLandUseId")),
                    reactivateAssessmentLOVs?.Assessment_Primary_Land_Use ?? [],
                    nameOfLov("Code")
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    onChange(nameOf("PrimaryLandUseId"), {
                      value: event.value?.Code,
                    });
                  }}
                />
              </div>
              {options?.isLLS && (
                <div className="cc-field">
                  <CCLabel title="Secondary land use" />
                  <Field
                    name={nameOf("SecondaryLandUseId")}
                    component={CCSearchComboBox}
                    disabled={options?.isReadOnly}
                    data={
                      reactivateAssessmentLOVs?.Assessment_Secondary_Land_Use ??
                      []
                    }
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    value={getDropdownValue(
                      "" + valueGetter(nameOf("SecondaryLandUseId")),
                      reactivateAssessmentLOVs?.Assessment_Secondary_Land_Use ??
                        [],
                      nameOfLov("Code")
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      onChange(nameOf("SecondaryLandUseId"), {
                        value: event.value?.Code,
                      });
                    }}
                  />
                </div>
              )}
            </div>
            <div className="cc-form-cols-3">
              {options?.isLLS && (
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <CCLabel title="Actual land area" />
                    <Field
                      name={nameOf("ActualLandArea")}
                      placeholder={"Actual area"}
                      component={CCNumericTextBox}
                      readOnly={options?.isReadOnly}
                      min={0}
                      format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Unit" />
                    <Field
                      name={nameOf("ActualLandAreaUnitCode")}
                      disabled={options?.isReadOnly}
                      component={CCSearchComboBox}
                      data={reactivateAssessmentLOVs?.LandArea_Unit ?? []}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      isUseDefaultOnchange
                    />
                  </div>
                </div>
              )}
              {!options?.isActro && !options?.isLLS && (
                <div className="cc-col-span-2">
                  <div className="cc-field">
                    <CCLabel title="Improvements" />
                    <Field
                      name={nameOf("Improvements")}
                      placeholder={"Improvements"}
                      component={CCInput}
                      readOnly={options?.isReadOnly}
                    />
                  </div>
                </div>
              )}
              {isFieldVisible(getFieldValue("SAPHoldingNumberVisibility")) && (
                <div className="cc-field">
                  <CCLabel title="SAP ID" />
                  <Field
                    name={nameOf("SAPHoldingNumber")}
                    placeholder={"SAP ID"}
                    component={CCInput}
                    readOnly={options?.isReadOnly}
                  />
                </div>
              )}
            </div>
            {options?.isActro && (
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Folio</label>
                  <Field
                    name={nameOf("Folio")}
                    placeholder={"Folio"}
                    component={CCInput}
                    readOnly={options?.isReadOnly}
                    maxLength={20}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">
                    Lease commencement date
                    <CCTooltip type="validator" position="right" />
                  </label>
                  <Field
                    name={nameOf("CreationDate")}
                    component={CCDatePicker}
                    disabled={options?.isReadOnly}
                    format={DATE_FORMAT.DATE_CONTROL}
                    validator={
                      !options?.isReadOnly ? requiredValidator : undefined
                    }
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Volume</label>
                  <Field
                    name={nameOf("Volume")}
                    placeholder={"Volume"}
                    component={CCInput}
                    readOnly={options?.isReadOnly}
                    maxLength={20}
                  />
                </div>
              </div>
            )}
            {isFieldVisible(getFieldValue("GISReferenceGridVisibility")) && (
              <div className="cc-field">
                <label className="cc-label">GIS references</label>
                <CCGrid
                  readOnly={options?.isReadOnly}
                  toolbar={
                    !options?.isReadOnly ? (
                      <div className="cc-grid-tools-bar">
                        <Button
                          type="button"
                          iconClass="fas fa-plus"
                          title="Add"
                          onClick={(event: any) => {
                            event.preventDefault();
                            setShowDialog(true);
                          }}
                        />
                        <Button
                          type="button"
                          iconClass="fas fa-minus"
                          title="Remove"
                          onClick={(event: any) => {
                            event.preventDefault();
                            handleDeleteItem();
                          }}
                          disabled={selectedGIS?.length !== 1}
                        />
                      </div>
                    ) : null
                  }
                  onDataRowChange={(dataRow: DTO_GIS, fieldChange: string) => {
                    handleDataChangeGrid(dataRow, fieldChange);
                  }}
                  editableMode={!options?.isReadOnly ? "cell" : undefined}
                  className="cc-gis-reference-grid-data"
                  data={gis ?? []}
                  columnFields={colGISReference}
                  primaryField={nameOfGIS("Id")}
                  selectableMode="single"
                  onSelectionChange={(dataItem: DTO_GIS[]) => {
                    onChange(nameOf("_option.GISSelected"), {
                      value: dataItem,
                    });
                  }}
                  selectedRows={selectedGIS}
                />
                {errors?.[nameOf("")] ? (
                  <Error>{errors[nameOf("")]}</Error>
                ) : null}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Sector</label>
                <Field
                  name={nameOf("Sector")}
                  component={CCSearchComboBox}
                  disabled={options?.isReadOnly}
                  data={assessmentGroupLOV ?? []}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  isUseDefaultOnchange
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Rateable land area m2</label>
                <Field
                  name={nameOf("RateableLandArea")}
                  placeholder={"Rateable land area m2"}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Spatial land area m2</label>
                <Field
                  name={nameOf("SpatialLandArea")}
                  placeholder={"Spatial land area m2"}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  Calculated from title land area m2
                </label>
                <Field
                  name={nameOf("CalculatedFromTitleLandArea")}
                  placeholder={"Calculated from title land area m2"}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Differential rating</label>
                <Field
                  name={nameOf("DifferentialRating")}
                  placeholder={"Differential rating"}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Instalment plan type</label>
                <Field
                  name={nameOf("InstalmentPlanTypeId")}
                  placeholder={"Instalment plan type"}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                />
              </div>
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Primary land use</label>
                <Field
                  name={nameOf("PrimaryLandUse")}
                  component={CCSearchComboBox}
                  disabled={options?.isReadOnly}
                  data={
                    reactivateAssessmentLOVs?.Assessment_Primary_Land_Use ?? []
                  }
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  isUseDefaultOnchange
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Secondary land use</label>
                <Field
                  name={nameOf("SecondaryLandUse")}
                  component={CCSearchComboBox}
                  disabled={options?.isReadOnly}
                  data={
                    reactivateAssessmentLOVs?.Assessment_Secondary_Land_Use ??
                    []
                  }
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  isUseDefaultOnchange
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">LGA</label>
                <Field
                  name={nameOf("LGA")}
                  component={CCSearchComboBox}
                  disabled={options?.isReadOnly}
                  data={reactivateAssessmentLOVs?.Assessment_LGA ?? []}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  isUseDefaultOnchange
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Actual area</label>
                <Field
                  name={nameOf("ActualLandArea")}
                  placeholder={"Actual area"}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Single farm enterprise flag</label>
                <Field
                  name={nameOf("SingleFarmEnterpriseFlag")}
                  placeholder={"Single farm enterprise flag"}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Commonwealth or crown land</label>
                <Field
                  name={nameOf("CommonwealthOrCrownLand")}
                  placeholder={"Commonwealth or crown land"}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Improvements</label>
                <Field
                  name={nameOf("Improvements")}
                  placeholder={"Improvements"}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                />
              </div>
            </div>
          </>
        )}
        <br />
        {showDialog && (
          <GISReferenceDialog
            onClose={() => {
              setShowDialog(false);
            }}
            onSubmit={(data: DTO_GIS) => {
              handleAddItem(data);
              setShowDialog(false);
            }}
            gisReferenceData={gis ?? []}
            gisTypes={reactivateAssessmentLOVs?.GISType ?? []}
          />
        )}
      </section>
    );
  }
);
