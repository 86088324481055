import { getViewConfigurations } from "@app/products/property/api";
import { assessmentLookupOData } from "@app/products/property/components/dialogs/add-assessment-lookup/api";
import {
  assessmentSearchByList,
  colAssessmentLookup,
  eOptionSearchAssessmentLookup,
} from "@app/products/property/components/dialogs/add-assessment-lookup/config";
import {
  IFilterSearch,
  fnt_Assessment_LookupResult,
} from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { isNumberModeAssessmentSearch } from "@app/products/property/components/dialogs/add-assessment-lookup/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import { ComboboxSearchMessage } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/components/combobox-search-api/config";

import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { useDebounce } from "@common/hooks/useDebounce";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import axios, { CancelTokenSource } from "axios";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IAddAssessmentLookupDialogProps {
  onClose: () => void;
  handleAddAssessment: (data: fnt_Assessment_LookupResult[]) => void;
  isLoadingFinish?: boolean;
  statusParamSearch?: number[];
  selectableMode?: "single" | "multiple" | "none";
}
const nameOf = nameOfFactory<fnt_Assessment_LookupResult>();
export const AddAssessmentLookupDialog = observer(
  ({
    onClose,
    handleAddAssessment,
    isLoadingFinish = false,
    statusParamSearch = [0, 8], //Fixed now (0: Active, 8: Non-Rateable)
    selectableMode = "multiple",
  }: IAddAssessmentLookupDialogProps) => {
    //Get labels
    const [
      assessmentLabel,
      legalDescriptionLabel,
      assessmentIDLabel,
      assessmentNumberLabel,
      assessmentReferenceLabel,
      titleIDLabel,
      assessmentLookupLabel,
    ] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Assessment,
      ECustomColNameProperty.Legal_Description,
      ECustomColNameProperty.AssessmentID,
      ECustomColNameProperty.AssessmentNumber,
      ECustomColNameProperty.Assessment_Reference,
      ECustomColNameProperty.Title_ID,
      ECustomColNameProperty.Assessment_Lookup,
    ]);

    const cancelRequest = useRef<CancelTokenSource>();
    const [filter, setFilter] = useState<IFilterSearch>({
      searchValue: "",
      searchBy: assessmentSearchByList[0],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >(undefined);
    const [processedColumns, setProcessedColumns] =
      useState<IColumnFields[]>(colAssessmentLookup);
    const [isSearching, setIsSearching] = useState(false);
    const [searchResult, setSearchResult] = useState<
      fnt_Assessment_LookupResult[]
    >([]);
    const [selectedAssessment, setSelectedAssessment] = useState<
      fnt_Assessment_LookupResult[]
    >([]);
    const [
      processedAssessmentSearchByList,
      setProcessedAssessmentSearchByList,
    ] = useState<DTO_LOV_Number[]>(assessmentSearchByList);

    const debouncedSearch = useDebounce(filter?.searchValue ?? "", 500);
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    useEffect(() => {
      (async () => {
        if (
          !isNil(debouncedSearch) &&
          filter &&
          filter?.searchValue?.length >= 1 &&
          !isNil(filter?.searchBy?.Code)
        ) {
          notificationRef.current?.resetNotifications();
          setIsSearching(true);
          cancelRequest.current?.cancel(ComboboxSearchMessage);
          cancelRequest.current = axios.CancelToken.source();
          const response = await assessmentLookupOData(
            {
              LookupKey: filter?.searchBy.Code,
              LookupValue: debouncedSearch,
              Statuses: statusParamSearch,
            },
            cancelRequest.current
          );
          if (isSuccessResponse(response) && response.data) {
            setSearchResult(response.data.value ?? []);
            setIsSearching(false);
          } else if (response.error !== ComboboxSearchMessage) {
            notificationRef.current?.setNotifications([
              {
                title:
                  response.error ?? `${assessmentLabel} lookup search failed`,
                type: "error",
                autoClose: false,
                id: "1",
              },
            ]);
            setIsSearching(false);
          }
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearch, filter?.searchBy?.Code]);

    const processAssessmentSearchByList = () => {
      const newParcelSearchByList = assessmentSearchByList.map(
        (searchByItem: DTO_LOV_Number) => {
          switch (searchByItem.Code) {
            case eOptionSearchAssessmentLookup.LegalDescription:
              return {
                ...searchByItem,
                Name: legalDescriptionLabel ?? searchByItem.Name,
              };
            case eOptionSearchAssessmentLookup.AssessmentId:
              return {
                ...searchByItem,
                Name: assessmentIDLabel ?? searchByItem.Name,
              };
            case eOptionSearchAssessmentLookup.AssessmentNumber:
              return {
                ...searchByItem,
                Name: assessmentNumberLabel ?? searchByItem.Name,
              };
            case eOptionSearchAssessmentLookup.AssessmentReference:
              return {
                ...searchByItem,
                Name: assessmentReferenceLabel ?? searchByItem.Name,
              };
            case eOptionSearchAssessmentLookup.TitleId:
              return {
                ...searchByItem,
                Name: titleIDLabel ?? searchByItem.Name,
              };
            default:
              return searchByItem;
          }
        }
      );
      setProcessedAssessmentSearchByList(newParcelSearchByList);
    };

    const loadConfigurationView = () => {
      setIsLoading(true);
      getViewConfigurations(ViewConfiguration.AssessmentSearch).then(
        (response) => {
          if (isSuccessResponse(response)) {
            const viewConfig: ICCViewColumn[] | undefined =
              response?.data?.ColumnDefinitions?.Columns;
            if (!viewConfig || viewConfig?.length === 0) {
              setProcessedColumns([]);
              setIsLoading(false);
              return;
            }
            setProcessedColumns(
              processDynamicColumns(colAssessmentLookup, viewConfig)
            );
          } else {
            setResponseLoadError({
              status: response.status,
              error: response.error ?? "Load failed",
            });
          }
          setIsLoading(false);
        }
      );
    };

    useEffectOnce(() => {
      processAssessmentSearchByList();
      loadConfigurationView();
    });

    return (
      <Form
        onSubmitClick={() => handleAddAssessment(selectedAssessment)}
        initialValues={{
          AssessmentSearchBy: assessmentSearchByList[0],
        }}
        render={(formRenderProps: FormRenderProps) => (
          <CCDialog
            titleHeader={assessmentLookupLabel}
            maxWidth="50%"
            height="auto"
            onClose={onClose}
            disabled={isLoading || isLoadingFinish}
            bodyElement={
              <FormElement className="cc-form">
                <section className="cc-field-group">
                  <CCLocalNotification ref={notificationRef} />
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Search by" />
                      <Field
                        name={"AssessmentSearchBy"}
                        placeholder={"Search by"}
                        component={CCDropDownList}
                        data={processedAssessmentSearchByList}
                        textField={"Name"}
                        dataItemKey={"Code"}
                        onChange={(event: DropDownListChangeEvent) => {
                          setFilter((pre: IFilterSearch) => {
                            return {
                              searchValue: "",
                              searchBy: event?.value,
                            };
                          });
                          formRenderProps.onChange("AssessmentSearchBy", {
                            value: event.value,
                          });
                          formRenderProps.onChange("AssessmentSearchValue", {
                            value: "",
                          });
                        }}
                      />
                    </div>
                    <div className="cc-field cc-col-span-2">
                      <label className="cc-label">
                        Search value
                        <CCTooltip
                          type="info"
                          content={`${assessmentIDLabel} and ${assessmentNumberLabel} are numbers`}
                          position="right"
                        />
                      </label>
                      <Field
                        name={"AssessmentSearchValue"}
                        placeholder={"Search value"}
                        component={CCInput}
                        type={
                          isNumberModeAssessmentSearch(filter?.searchBy?.Code)
                            ? "number"
                            : "string"
                        }
                        onChange={(event: InputChangeEvent) => {
                          setFilter((pre: IFilterSearch) => {
                            return { ...pre, searchValue: event?.value };
                          });
                          formRenderProps.onChange("AssessmentSearchValue", {
                            value: event.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-1">
                    {isLoading ? (
                      <Loading isLoading />
                    ) : responseLoadError ? (
                      <CCLoadFailed
                        responseError={responseLoadError}
                        onReload={() => {
                          loadConfigurationView();
                        }}
                      />
                    ) : (
                      <div className="cc-field">
                        <CCGrid
                          columnFields={processedColumns ?? []}
                          primaryField={nameOf("Assessment_Id")}
                          data={searchResult ?? []}
                          isLoading={isSearching}
                          selectableMode={selectableMode}
                          onSelectionChange={(
                            dataItem: fnt_Assessment_LookupResult[]
                          ) => {
                            setSelectedAssessment(dataItem);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </section>
              </FormElement>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button
                  className="cc-dialog-button"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  iconClass={isLoadingFinish ? "fas fa-spinner fa-spin" : ""}
                  themeColor="primary"
                  onClick={formRenderProps.onSubmit}
                  type="submit"
                  disabled={
                    !formRenderProps.valid ||
                    !formRenderProps.modified ||
                    selectedAssessment?.length === 0
                  }
                >
                  OK
                </Button>
              </div>
            }
          />
        )}
      />
    );
  }
);
