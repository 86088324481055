import { useToStepStore } from "@app/core/communication/dialogs/components/form-elememts/to/store";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";

export const RenderSend = (props: GridCellProps) => {
  const { preferred } = useToStepStore();
  const initIconAndError = {
    selected: false,
    sendIcon: "fal fa-envelope",
    error: !props.dataItem.Email,
    sendIcon2: "fal fa-comment-dots",
    error2: !props.dataItem._Mobile,
  };
  //render icon and error base on selected preferred into Send cell
  const checkIcon = () => {
    return props.dataItem.sendIcon ?? initIconAndError.sendIcon;
  };
  const checkError = () => {
    return props.dataItem.error ?? initIconAndError.error;
  };
  const checkError2 = () => {
    return props.dataItem.error2 ?? initIconAndError.error2;
  };
  const checkIconWhenSelectedBoth = () => {
    return props.dataItem.sendIcon2 ?? initIconAndError.sendIcon2;
  };
  const returnIcon = () => {
    return (
      checkIcon() + (checkError() ? " cc-text-danger" : " cc-text-success")
    );
  };
  return (
    <td role="gridcell">
      <div className="d-flex justify-content-center">
        {!(preferred.useEmail && preferred.useSMS) && (
          <i className={returnIcon()}></i>
        )}

        {preferred.useEmail && preferred.useSMS && (
          <>
            <i
              className={
                checkIcon() +
                (checkError() && checkError2()
                  ? " cc-text-danger"
                  : checkError()
                  ? " cc-text-warning"
                  : " cc-text-success")
              }
            ></i>
            <i
              className={
                "ml-2 " +
                checkIconWhenSelectedBoth() +
                (checkError() && checkError2()
                  ? " cc-text-danger"
                  : checkError2()
                  ? " cc-text-warning"
                  : " cc-text-success")
              }
            ></i>
          </>
        )}
      </div>
    </td>
  );
};
