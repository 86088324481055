import { DTO_LOV } from "@common/models/odataResponse";

export const searchNameConfig = {
  typeSpeed: 300,
  minCharacters: 3,
};

export const ID_FOR_ITEM_NOT_FOUND = -1;
export const listSearchOptions: DTO_LOV[] = [
  { Code: "do_Keyword_Search", Name: "Keyword" },
  { Code: "do_Soundex_Search", Name: "Soundex" },
  { Code: "only_Active", Name: "Only Active Entities" },
  { Code: "include_Superseded", Name: "Include Superseded" },
  { Code: "include_Obsolete", Name: "Include Obsolete" },
];
