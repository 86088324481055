import { SettingsMap } from "@app/products/property/system-admin/settings/model";
import { IColumnFields } from "@components/cc-grid/model";

export interface Svc_Animals_Finance {
  ID: number;
  AmountIssued: number | null;
  AmountOutstanding: number | null;
  ContactName: string;
  ReferenceNumber: string;
  FeeType: string;
  CreatedDate: Date | null;
  DebtorNumber: string;
  InvoiceNumber: string;
  CostCode: string;
  SecurePayReference: string;
  AmountPaid: number | null;
  ReceiptNumber: string;
  ReceiptDate: Date | null;
  ReceiptedBy: string;
  Method: string;
  Type: string;
  SentToFinanceDate: Date | null;
  SentToFinanceBatch: number | null;
  TagNo: string;
  MicrochipNo: string;
  AnimalOwner: string;
  RegistrationNumber: string;
  Dangerous: boolean | null;
  Menacing: boolean | null;
  SiteAddress: string;
  Locality: string;
  Breed: string;
  AnimalName: string;
  PropertyOwner: string;
  AssessmentNo: string;
  StatusDueStatus: string;
  AmountOS: number | null;
  DebtorNo: string;
  NARID: string;
  CONTACTID: string;
  FileNumber: string;
  Gender: string;
  AppBalance: number | null;
  ExportToFinanceDate: Date | null;
  InvoiceAmount: number | null;
  InvoiceDescription: string;
  InvoiceDate: Date | null;
  InvoicePostingDate: Date | null;
  InvoiceDueDate: Date | null;
  InvoiceActiveDate: Date | null;
  PaidInFull: boolean | null;
  InvoiceBalance: number | null;
  FeeCount: number | null;
  RecordType: number;
}

export enum AnimalFinanceViewType {
  OSFee,
  FeesPaid,
  FeesNotSentToFinance,
  FeesSentToFinance,
  FeesAll,
  DebtorSummaryAll,
  DebtorSummaryOutstanding,
  InvoiceNotSentToFinance,
  InvoiceSentToFinance,
}

export interface IAnimalsFinanceViewBySettingProps {
  listType?: AnimalFinanceViewType;
  defaultConfig: IColumnFields[];
  settings?: SettingsMap;
}
