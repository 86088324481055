import { odataCoreGetInspection } from "@app/core/components/common/utils";
import { colInspections } from "@app/core/inspections/inspections-accordion/config";
import { IInspection } from "@app/core/inspections/inspections-accordion/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IInspectionsProps {
  id?: number | string;
  recordType: RECORDTYPE;
}

const nameOf = nameOfFactory<IInspection>();
const Inspections: React.FC<IInspectionsProps> = ({ id, recordType }) => {
  return (
    <CCGrid
      columnFields={colInspections}
      primaryField={nameOf("ID")}
      dataUrl={
        id === undefined ? undefined : odataCoreGetInspection(id, recordType)
      }
      state={{ sort: [{ field: nameOf("ID"), dir: "desc" }] }}
    />
  );
};

export default Inspections;
