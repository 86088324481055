import { VO_Compliance_Document } from "@app/products/property/assessments/compliance/actions-and-letters/all-documents/all/model";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: VO_Compliance_Document) => {
  const dynamicDisplayName = selectedRow.Compliance_Id
    ? `- ${selectedRow.Compliance_Id}`
    : "";
  return `Property - Assessments ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: VO_Compliance_Document) => {
  return getDisplayTextWithDashes([
    selectedRow.Compliance_Id,
    selectedRow.NA_Name,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return "Property - Assessments";
};

export const getBookmarkListViewDetail = () => {
  return "Property - Assessments - Compliance - Actions & Letters - All Documents - All";
};
