import {
  IAttachment,
  IWebLink,
} from "@app/core/attachment/components/buttons/add-attachment/model";
import { AddAttachmentDialog } from "@app/core/attachment/components/dialogs/_index";
import {
  IAddAttachmentDialog,
  eUploadMode,
} from "@app/core/attachment/components/dialogs/model";
import { convertToFileStreamEntry } from "@app/core/attachment/components/dialogs/util";
import {
  getDocumentById,
  saveDocument,
  saveDocumentAsWebLink,
} from "@app/core/documents/api";
import { Svc_Attachment } from "@app/core/documents/model";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { checkMaxNoOfAnimals } from "@app/products/animals/[id]/api";
import {
  Registration,
  RegistrationUpdateTriggers,
  Registration_Status,
  Svc_Registration,
} from "@app/products/animals/[id]/model";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import { convertDateStringFormatDDMMYYYYToDate } from "@app/products/animals/[id]/util";
import "@app/products/animals/components/forms/components/form-element/_index.scss";
import { AnimalOwnerSection } from "@app/products/animals/components/forms/components/form-element/components/animal-owner-section/_index";
import { AnimalPhotoSection } from "@app/products/animals/components/forms/components/form-element/components/photo/_index";
import { RelatedAnimals } from "@app/products/animals/components/forms/components/form-element/components/related-animals/_index";
import { AnimalsRegistrationWorkflowElements } from "@app/products/animals/components/forms/components/form-element/components/workflow-elements/_index";
import {
  calculateAnimalClassificationName,
  getValueFromSetting,
} from "@app/products/animals/components/forms/components/form-element/util";
import { AnimalSettingValue } from "@app/products/animals/model";
import { getAnimalTypeByID } from "@app/products/animals/system-admin/animal-types/[id]/api";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { RECORDTYPE } from "@common/constants/recordtype";
import { APIResponseStatus } from "@common/constants/response-status";
import { useIsNew } from "@common/hooks/useIsNew";
import { DebtorNumberPicker } from "@common/input-pickers/debtor-number/_index";
import { RefNumberUsage } from "@common/input-pickers/debtor-number/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import {
  requiredValidator,
  validatorDateValueWithMinMax,
} from "@common/utils/field-validators";
import { capitalizeFirstLetter, formatOSFees } from "@common/utils/formatting";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { ICCInputPickerChangeEvent } from "@components/cc-input-picker/_index";
import { isHTML } from "@components/cc-input-picker/util";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { addYears } from "date-fns";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

export interface IAnimalFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOfDebtorNumber = nameOfFactory<RefNumberUsage>();
const nameOf = nameOfFactory<Registration>();
const nameOfMapObj = nameOfFactory<Svc_Registration>();
const getNameOf = (name: keyof Registration) =>
  `${nameOfMapObj("Registration")}.${nameOf(name)}`;

const AnimalFormElement = observer(
  ({ formRenderProps }: IAnimalFormElementProps) => {
    const { onChange, valueGetter } = formRenderProps;
    const isNew = useIsNew();
    const { settings } = useCommonCoreStore();
    const { pushNotification, clearNotifications } =
      useCCAppNotificationStore();
    const {
      animalInfo,
      animalLOVs,
      animalId,
      OSFee,
      totalRelatedAnimalRecords,
      restrictedBreedListID,
      isReadOnlyRestrictedBreed,
      animalBreedLOVs,
      isSuperAdmin,
      pidNo,
      setIsReadOnlyRestrictedBreed,
      setIsLoading,
      populateBreeds,
    } = useAnimalStore();
    const [getSettingValue, loadSettingValue] = useState<AnimalSettingValue>();
    const [isOpenPopupPhoto, setOpenPopupPhoto] = useState<boolean>(false);
    const [isLoadingUploadPhoto, setIsLoadingUploadPhoto] =
      useState<boolean>(false);
    const [isShowDogExemption, setIsShowDogExemption] =
      useState<boolean>(false);

    const listPanelBar: ITabProps[] = !isNew
      ? [
          {
            title: "Tag history",
            badge: (animalInfo?.TagHistoryCount ?? 0).toString(),
            component: (
              <CCValueField
                label="Tag history"
                value={
                  !animalInfo?.TagHistory
                    ? "No tag history found"
                    : isHTML(animalInfo.TagHistory)
                    ? sanitizeHtml(animalInfo.TagHistory)
                    : animalInfo.TagHistory
                }
              />
            ),
          },
          {
            title: "Related animals",
            badge: totalRelatedAnimalRecords.toString(),
            component: <RelatedAnimals id={animalId} pidNO={pidNo} />,
          },
        ]
      : [];

    const handleSaveAttachmentPhoto = async (data: IAddAttachmentDialog) => {
      setIsLoadingUploadPhoto(true);
      switch (data?.UploadMode) {
        case eUploadMode.UPLOAD_FILE:
          if (!isNil(data?.File) && isNil(data?.File[0].validationErrors)) {
            const fileEntry = await convertToFileStreamEntry(data?.File[0]);
            const attachments = {
              FileEntry: fileEntry,
              Title: data.Title ?? data?.File[0]?.name,
              Attachment_ID: 0,
              Category_KWD: data?.Category?.Keyword_ID ?? null,
              FileNumber: "",
              TRIMUri: "",
              AddToRecords: false,
            } as IAttachment;

            const requestBody = {
              ParentID: animalId,
              ParentRecordType: RECORDTYPE.Animals_Registration,
              AttachmentList: [attachments],
              AddDateTimeToFileName: null,
            } as Svc_Attachment;
            const response = await saveDocument(requestBody);
            if (isSuccessIdentityPacket(response)) {
              const responseDocument = await getDocumentById(
                response.data?.ID,
                true
              );
              if (isSuccessResponse(responseDocument)) {
                onChange(getNameOf("PhotoAttachment"), {
                  value: responseDocument.data,
                });
                onChange(getNameOf("PhotoAttachment_ID"), {
                  value: response.data?.ID,
                });
                clearNotifications();
                pushNotification({
                  title: "Photo uploaded successfully",
                  type: "success",
                });
              } else {
                pushNotification({
                  autoClose: false,
                  title: "Upload photo failed",
                  type: "error",
                });
              }
            } else {
              if (response?.status === APIResponseStatus.EXCEEDED_SIZE_LIMIT) {
                pushNotification({
                  autoClose: false,
                  title: "Exceeded the specified size limit",
                  type: "error",
                });
              } else {
                pushNotification({
                  autoClose: false,
                  title: "Upload photo failed.",
                  description: response.data?.Errors ?? response.statusText,
                  type: "error",
                });
              }
            }
            setOpenPopupPhoto(false);
            setIsLoadingUploadPhoto(false);
          }
          break;
        case eUploadMode.UPLOAD_WEB_LINK:
          if (!animalId) return;
          const requestBody = {
            WebLinkURI: data.WebLink,
            Tag: data?.Tag ?? "",
            WeblinkCategory_KWD: data?.WebLinkCategory?.Keyword_ID ?? null,
          } as IWebLink;
          const response = await saveDocumentAsWebLink(
            animalId,
            RECORDTYPE.CORE_Contact,
            requestBody
          );
          setIsLoadingUploadPhoto(false);
          if (isSuccessIdentityPacket(response)) {
            clearNotifications();
            const responseDocument = await getDocumentById(
              response.data?.ID,
              false
            );
            if (isSuccessResponse(responseDocument)) {
              onChange(getNameOf("PhotoAttachment"), {
                value: responseDocument.data,
              });
              onChange(getNameOf("PhotoAttachment_ID"), {
                value: response.data?.ID,
              });
              pushNotification({
                title: "Photo uploaded successfully.",
                type: "success",
              });
            } else {
              pushNotification({
                autoClose: false,
                title: "Upload photo failed.",
                type: "error",
              });
            }
          } else {
            pushNotification({
              autoClose: false,
              title: "Upload photo failed.",
              type: "error",
              description: response?.data?.Errors ?? response?.statusText,
            });
          }
          setOpenPopupPhoto(false);
          break;
      }
    };

    const checkMaxAnimals = async () => {
      if (
        valueGetter(nameOfMapObj("Registration")) &&
        valueGetter(getNameOf("Address")) &&
        valueGetter(getNameOf("AnimalType_ID"))
      ) {
        const response = await checkMaxNoOfAnimals(
          valueGetter(nameOfMapObj("Registration"))
        );
        if (isSuccessResponse(response)) {
          if (!isNil(response.data) && response.data !== "") {
            pushNotification({
              autoClose: false,
              title: response.data,
              type: "warning",
            });

            if (
              getSettingValue?.animalsEnableDOGExemption !== "1" &&
              getSettingValue?.animalsEnableDOGExemption !== "2"
            ) {
              if (!valueGetter(getNameOf("Flag_DogExemption"))) {
                onChange(getNameOf("Flag_DogExemption"), {
                  value: false,
                });
              }
              setIsShowDogExemption(true);
            } else {
              setIsShowDogExemption(false);
            }
          }
        } else {
          pushNotification({
            autoClose: false,
            title: "Check max animals failed.",
            type: "error",
          });
        }
      }
    };

    const changeExpiryDate = async (animalTypeID: number) => {
      if (animalTypeID) {
        const defaultRegistrationExpiryDate: Date | null =
          convertDateStringFormatDDMMYYYYToDate(
            getSettingValue?.animalsRegistrationExpiryDate
          );

        const expiryDateOptions =
          getSettingValue?.animalsRegistrationExpiryDateOption;
        const registrationExpiryCalcEffectiveDate: Date | null =
          convertDateStringFormatDDMMYYYYToDate(
            getSettingValue?.registrationExpiryCalcEffectiveDate
          );

        const response = await getAnimalTypeByID(animalTypeID);
        if (isSuccessResponse(response) && response?.data) {
          const newAnimalType = response.data;
          onChange(getNameOf("AnimalType"), {
            value: response?.data,
          });
          if (
            !isNil(registrationExpiryCalcEffectiveDate) &&
            new Date() < registrationExpiryCalcEffectiveDate
          ) {
            if (!isNil(defaultRegistrationExpiryDate)) {
              if (
                !isNil(expiryDateOptions) &&
                expiryDateOptions !== 3 &&
                newAnimalType !== null &&
                !isNil(newAnimalType?.RenewalPeriod_ID) &&
                newAnimalType.RenewalPeriod_ID > 1
              )
                //case of 3 year dog registration
                onChange(getNameOf("Date_EffectiveTo"), {
                  value: addYears(
                    defaultRegistrationExpiryDate,
                    newAnimalType.RenewalPeriod_ID - 1
                  ),
                });
              else
                onChange(getNameOf("Date_EffectiveTo"), {
                  value: defaultRegistrationExpiryDate,
                }); //for 1 year registration
            } else {
              if (
                newAnimalType != null &&
                !isNil(newAnimalType?.RenewalPeriod_ID)
              ) {
                //Adding Number of years to update the expiry date
                if (valueGetter(getNameOf("Date_EffectiveTo")))
                  onChange(getNameOf("Date_EffectiveTo"), {
                    value: addYears(
                      valueGetter(getNameOf("Date_EffectiveTo")),
                      newAnimalType.RenewalPeriod_ID
                    ),
                  });
                else
                  onChange(getNameOf("Date_EffectiveTo"), {
                    value: addYears(new Date(), newAnimalType.RenewalPeriod_ID),
                  });
              }
            }
          } else if (
            newAnimalType != null &&
            !isNil(newAnimalType?.RenewalPeriod_ID)
          ) {
            //Adding Number of years to update the expiry date
            if (valueGetter(getNameOf("Date_EffectiveTo"))) {
              onChange(getNameOf("Date_EffectiveTo"), {
                value: addYears(
                  valueGetter(getNameOf("Date_EffectiveTo")),
                  newAnimalType.RenewalPeriod_ID
                ),
              });
            } else if (
              !isNil(defaultRegistrationExpiryDate) &&
              !isNil(expiryDateOptions) &&
              expiryDateOptions !== 3
            ) {
              onChange(getNameOf("Date_EffectiveTo"), {
                value: addYears(
                  defaultRegistrationExpiryDate,
                  newAnimalType.RenewalPeriod_ID
                ),
              });
            } else
              onChange(getNameOf("Date_EffectiveTo"), {
                value: defaultRegistrationExpiryDate,
              });
          }
        } else {
          pushNotification({
            autoClose: false,
            title: "Reload animal type failed.",
            type: "error",
          });
        }
      }
    };

    const handleAnimalTypeChange = async (event: ComboBoxChangeEvent) => {
      if (event.target.value?.Key) {
        setIsLoading(true);
        onChange(getNameOf("AnimalType_ID"), {
          value: event.target.value.Key,
        });

        const saveTriggers = valueGetter(getNameOf("SaveTriggers")) ?? [];
        if (
          !saveTriggers.includes(RegistrationUpdateTriggers.UpdateAnimalType)
        ) {
          saveTriggers.push(RegistrationUpdateTriggers.UpdateAnimalType);
          onChange(getNameOf("SaveTriggers"), { value: saveTriggers });
        }

        await checkMaxAnimals();
        await changeExpiryDate(event.target.value.Key);
        populateBreeds(
          valueGetter(getNameOf("AnimalType_ID")),
          valueGetter(getNameOf("AnimalType"))
        );
        setIsLoading(false);
      } else {
        onChange(getNameOf("AnimalType_ID"), {
          value: null,
        });
      }
    };

    const handleBreedChange = (event: ComboBoxChangeEvent) => {
      var breedKeywordId = event.value?.Key;
      onChange(getNameOf("Breed_KWD"), {
        value: breedKeywordId,
      });
      markAsRestrictedBreed(breedKeywordId);
    };

    const markAsRestrictedBreed = (breedId: number) => {
      if (!isNil(breedId)) {
        if (restrictedBreedListID && restrictedBreedListID.length > 0) {
          if (restrictedBreedListID.some((idNumber) => idNumber === breedId)) {
            onChange(getNameOf("Flag_RestrictedBreed"), {
              value: true,
            });
            setIsReadOnlyRestrictedBreed(true);
          } else {
            onChange(getNameOf("Flag_RestrictedBreed"), {
              value: false,
            });
            setIsReadOnlyRestrictedBreed(false);
          }
        }
      }
    };

    const maxDateValidator = useCallback(
      (value: Date) =>
        validatorDateValueWithMinMax(value, null, new Date()) ||
        requiredValidator(value),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [valueGetter(getNameOf("Date_DOB"))]
    );

    //Debtor picker
    const documentDebtorNumbers: RefNumberUsage[] = valueGetter(
      `option.DebtorNumber`
    ) ?? [
      {
        Record_Id: null,
        Sys_DebtorNumber: valueGetter(getNameOf("DebtorNumber")),
      },
    ];

    const handleOnChangeDebtorNumberValue = (value: RefNumberUsage) => {
      onChange(getNameOf("DebtorNumber"), {
        value: value?.Sys_DebtorNumber ?? "",
      });
      onChange(`option.DebtorNumber`, {
        value: [value],
      });
    };

    useEffectOnce(() => {
      loadSettingValue(getValueFromSetting(settings));
    });

    return (
      <>
        <FormElement className="cc-animal-form-element">
          <AnimalsRegistrationWorkflowElements
            formRenderProps={formRenderProps}
          />
          <AnimalOwnerSection formRenderProps={formRenderProps} />
          <hr className="cc-divider" />
          {/* SECTION 1 */}
          <section className="cc-field-group">
            {/* ROW 1 */}
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Animal type" isMandatory />
                <Field
                  name={getNameOf("AnimalType_ID")}
                  dataItemKey="Key"
                  textField="Value"
                  data={animalLOVs?.AnimalTypes}
                  value={getDropdownValue(
                    valueGetter(getNameOf("AnimalType_ID")),
                    animalLOVs?.AnimalTypes
                  )}
                  onChange={handleAnimalTypeChange}
                  component={CCSearchComboBox}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel
                  title={
                    capitalizeFirstLetter(getSettingValue?.animalsLabelBreed) ??
                    "Breed"
                  }
                  isMandatory={getSettingValue?.animalsMandatoryBreed}
                />
                <Field
                  name={getNameOf("Breed_KWD")}
                  component={CCSearchComboBox}
                  data={animalBreedLOVs}
                  validator={
                    getSettingValue?.animalsMandatoryBreed
                      ? requiredValidator
                      : undefined
                  }
                  textField="Value"
                  dataItemKey="Key"
                  value={getDropdownValue(
                    valueGetter(getNameOf("Breed_KWD")),
                    animalBreedLOVs,
                    "Key"
                  )}
                  onChange={handleBreedChange}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Cross breed" />
                <Field
                  name={getNameOf("CrossBreed_KWD")}
                  dataItemKey="Key"
                  textField="Value"
                  data={animalBreedLOVs}
                  isUseDefaultOnchange
                  component={CCSearchComboBox}
                />
              </div>
            </div>
            {/* ROW 2 */}
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel
                  title="Gender"
                  isMandatory={getSettingValue?.animalsMandatoryGender}
                />
                <Field
                  name={getNameOf("Gender_ENUM")}
                  dataItemKey="Key"
                  textField="Value"
                  data={animalLOVs?.Animals_Gender}
                  isUseDefaultOnchange
                  component={CCSearchComboBox}
                  validator={
                    getSettingValue?.animalsMandatoryGender
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>

              <div className="cc-field">
                <CCLabel
                  title="Animal name"
                  isMandatory={getSettingValue?.animalsMandatoryAnimalName}
                />
                <Field
                  name={getNameOf("AnimalName")}
                  component={CCInput}
                  placeholder={"Animal name"}
                  validator={
                    getSettingValue?.animalsMandatoryAnimalName
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>

              <div className="cc-field">
                <CCLabel
                  title={
                    capitalizeFirstLetter(
                      getSettingValue?.animalsLabelColour
                    ) ?? "Colour"
                  }
                  isMandatory={getSettingValue?.animalsMandatoryColour}
                />
                <Field
                  name={getNameOf("Colour_KWD")}
                  dataItemKey="Key"
                  textField="Value"
                  data={animalLOVs?.Animals_Colour}
                  isUseDefaultOnchange
                  component={CCSearchComboBox}
                  validator={
                    getSettingValue?.animalsMandatoryColour
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>
            </div>

            {/* ROW 3 */}
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel
                  title={
                    capitalizeFirstLetter(getSettingValue?.animalsLabelDOB) ??
                    "Date of birth"
                  }
                  isMandatory
                />
                <Field
                  name={getNameOf("Date_DOB")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  validator={maxDateValidator}
                  component={CCDatePicker}
                  max={new Date()}
                />
              </div>

              {!isNew && (
                <CCValueField
                  label="Age"
                  value={valueGetter(getNameOf("Age"))}
                />
              )}
              {getSettingValue?.animalsDisplaySecondaryColour && (
                <div className="cc-field">
                  <CCLabel
                    title="Secondary colour"
                    isMandatory={
                      getSettingValue?.animalsMandatorySecondaryColour
                    }
                  />
                  <Field
                    name={getNameOf("SecondaryColour_KWD")}
                    dataItemKey="Key"
                    textField="Value"
                    data={animalLOVs?.Animals_Colour}
                    isUseDefaultOnchange
                    component={CCSearchComboBox}
                    validator={
                      getSettingValue?.animalsMandatorySecondaryColour
                        ? requiredValidator
                        : undefined
                    }
                  />
                </div>
              )}
            </div>

            <div className="cc-form-cols-3">
              <CCValueField
                label="Animal classification"
                value={calculateAnimalClassificationName(
                  animalInfo,
                  getSettingValue
                )}
              />
            </div>
          </section>
          <hr className="cc-divider" />
          {/* SECTION 2 */}
          <section className="cc-field-group">
            {/* ROW 1 */}
            <div className="cc-form-cols-3">
              <div className="animal-form-flex-col">
                <div className="cc-field">
                  <CCLabel title="Microchip?" isMandatory />
                  <Field
                    name={getNameOf("Flag_Microchipped")}
                    defaultChecked={valueGetter(getNameOf("Flag_Microchipped"))}
                    component={CCSwitch}
                  />
                </div>
                {valueGetter(getNameOf("Flag_Microchipped")) ? (
                  <div className="cc-field">
                    <CCLabel title="Microchip number" isMandatory />
                    <Field
                      name={getNameOf("MicrochipNumber")}
                      component={CCInput}
                      validator={requiredValidator}
                      placeholder={"Microchip number"}
                    />
                  </div>
                ) : (
                  <div className="cc-field">
                    <CCLabel title="Proof of exemption" isMandatory />
                    <Field
                      name={getNameOf("MicrochipProof_KWD")}
                      textField="Value"
                      dataItemKey="Key"
                      data={animalLOVs?.Animals_MicrochipProofExemption}
                      isUseDefaultOnchange
                      component={CCSearchComboBox}
                      validator={requiredValidator}
                    />
                  </div>
                )}
              </div>
              <div className="animal-form-flex-col">
                <div className="cc-field">
                  <CCLabel title="Pensioner?" isMandatory />
                  <Field
                    name={getNameOf("Flag_Pensioner")}
                    defaultChecked={valueGetter(getNameOf("Flag_Pensioner"))}
                    component={CCSwitch}
                  />
                </div>
                {valueGetter(getNameOf("Flag_Pensioner")) && (
                  <div className="cc-field">
                    <CCLabel title="Pension number" isMandatory />
                    <Field
                      name={getNameOf("PensionNumber")}
                      component={CCInput}
                      validator={requiredValidator}
                      placeholder={"Pension number"}
                    />
                  </div>
                )}
                {getSettingValue?.animalsEnablePensionerExpiry && (
                  <div className="cc-field">
                    <CCLabel title="Expiry" />
                    <Field
                      name={getNameOf("Date_PensionerExpiry")}
                      format={DATE_FORMAT.DATE_CONTROL}
                      component={CCDatePicker}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* ROW 2 */}
            <div className="cc-form-cols-3">
              {getSettingValue?.animalsDisplayHealthCareCard && (
                <div className="cc-field">
                  <CCLabel
                    title="Health care card?"
                    isMandatory={
                      getSettingValue?.animalsMandatoryHealthCareCard
                    }
                  />
                  <Field
                    name={getNameOf("Flag_HealthCareCard")}
                    defaultChecked={valueGetter(
                      getNameOf("Flag_HealthCareCard")
                    )}
                    component={CCSwitch}
                    validator={
                      getSettingValue?.animalsMandatoryHealthCareCard
                        ? requiredValidator
                        : undefined
                    }
                  />
                </div>
              )}
            </div>
          </section>
          <hr className="cc-divider" />
          {/* SECTION 3 */}
          <section className="cc-field-group">
            {/* ROW 1 */}
            <div className="cc-form-cols-3">
              <div className="animal-form-flex-col">
                <div className="cc-field">
                  <CCLabel title="Sterilised?" isMandatory />
                  <Field
                    name={getNameOf("Flag_Sterilised")}
                    defaultChecked={valueGetter(getNameOf("Flag_Sterilised"))}
                    component={CCSwitch}
                  />
                </div>
                {valueGetter(getNameOf("Flag_Sterilised")) && (
                  <div className="cc-field">
                    <CCLabel
                      title="Type of proof"
                      isMandatory={
                        getSettingValue?.animalsMandatorySterlisedTypeOfProof
                      }
                    />
                    <Field
                      name={getNameOf("SterlisedProof_KWD")}
                      textField="Value"
                      dataItemKey="Key"
                      data={animalLOVs?.Animals_ProofOfSterilisation}
                      isUseDefaultOnchange
                      component={CCSearchComboBox}
                      validator={
                        getSettingValue?.animalsMandatorySterlisedTypeOfProof
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                )}
              </div>
              {getSettingValue?.animalsDisplayClubMember && (
                <div className="animal-form-flex-col">
                  <div className="cc-field">
                    <CCLabel
                      title={
                        capitalizeFirstLetter(
                          getSettingValue?.animalsLabelClubMember
                        ) ?? "Club member?"
                      }
                      isMandatory={getSettingValue?.animalsMandatoryClubMember}
                    />
                    <Field
                      name={getNameOf("Flag_ClubMember")}
                      defaultChecked={valueGetter(getNameOf("Flag_ClubMember"))}
                      component={CCSwitch}
                      validator={
                        getSettingValue?.animalsMandatoryClubMember
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                  {valueGetter(getNameOf("Flag_ClubMember")) && (
                    <div className="cc-field">
                      <CCLabel title="Member number" isMandatory />
                      <Field
                        name={getNameOf("ClubNumber")}
                        component={CCInput}
                        validator={requiredValidator}
                        placeholder={"Member number"}
                      />
                    </div>
                  )}
                </div>
              )}
              {getSettingValue?.animalsDisplayObedienceTrained && (
                <div className="animal-form-flex-col">
                  {valueGetter("AnimalType.Name") &&
                    valueGetter("AnimalType.Name")
                      ?.toLowerCase()
                      ?.includes("dog") && (
                      <div className="cc-field">
                        <CCLabel
                          title="Obedience?"
                          isMandatory={
                            getSettingValue?.animalsMandatoryObedienceTrained
                          }
                        />
                        <Field
                          name={getNameOf("Flag_Obedience")}
                          defaultChecked={valueGetter(
                            getNameOf("Flag_Obedience")
                          )}
                          component={CCSwitch}
                          validator={
                            getSettingValue?.animalsMandatoryObedienceTrained
                              ? requiredValidator
                              : undefined
                          }
                        />
                      </div>
                    )}
                  {valueGetter(getNameOf("Flag_Obedience")) && (
                    <div className="cc-field">
                      <CCLabel
                        title="Type of proof"
                        isMandatory={
                          getSettingValue?.animalsMandatoryObedienceTypeOfProof
                        }
                      />
                      <Field
                        name={getNameOf("ObedienceProof_KWD")}
                        textField="Value"
                        dataItemKey="Key"
                        data={animalLOVs?.Animals_ProofOfObedience}
                        isUseDefaultOnchange
                        component={CCSearchComboBox}
                        validator={
                          getSettingValue?.animalsMandatoryObedienceTypeOfProof
                            ? requiredValidator
                            : undefined
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* ROW 2 */}
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel
                  title={
                    capitalizeFirstLetter(
                      getSettingValue?.animalsLabelDangerous
                    ) ?? "Dangerous?"
                  }
                  isMandatory={getSettingValue?.animalsMandatoryDangerous}
                />
                <Field
                  name={getNameOf("Flag_Dangerous")}
                  defaultChecked={valueGetter(getNameOf("Flag_Dangerous"))}
                  component={CCSwitch}
                  validator={
                    getSettingValue?.animalsMandatoryDangerous
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>

              {getSettingValue?.animalsDisplayMenacing && (
                <div className="cc-field">
                  <CCLabel
                    title={
                      capitalizeFirstLetter(
                        getSettingValue?.animalsLabelMenacing
                      ) ?? "Menacing?"
                    }
                    isMandatory={getSettingValue?.animalsMandatoryMenacing}
                  />
                  <Field
                    name={getNameOf("Flag_Menacing")}
                    defaultChecked={valueGetter(getNameOf("Flag_Menacing"))}
                    component={CCSwitch}
                    validator={
                      getSettingValue?.animalsMandatoryMenacing
                        ? requiredValidator
                        : undefined
                    }
                  />
                </div>
              )}
              {getSettingValue?.animalsDisplayOrganisation && (
                <div className="cc-field">
                  <CCLabel
                    title={
                      capitalizeFirstLetter(
                        getSettingValue?.animalsLabelOrganisation
                      ) ?? "Organisation?"
                    }
                    isMandatory={getSettingValue?.animalsMandatoryOrganisation}
                  />
                  <Field
                    name={getNameOf("Flag_Organisation")}
                    defaultChecked={valueGetter(getNameOf("Flag_Organisation"))}
                    component={CCSwitch}
                    validator={
                      getSettingValue?.animalsMandatoryOrganisation
                        ? requiredValidator
                        : undefined
                    }
                  />
                </div>
              )}
            </div>
          </section>
          <hr className="cc-divider" />
          {/* SECTION 4 */}
          <section className="cc-field-group">
            {/* ROW 1 */}
            <div className="cc-form-cols-3">
              <div className="animal-form-flex-col">
                <div className="cc-field">
                  <CCLabel
                    title={
                      capitalizeFirstLetter(
                        getSettingValue?.animalsLabelGuideDogAssistance
                      ) ?? "Guide/Assistance dog?"
                    }
                    isMandatory={
                      getSettingValue?.animalsMandatoryGuideDogAssistance
                    }
                  />
                  <Field
                    name={getNameOf("Flag_GuideDog")}
                    defaultChecked={valueGetter(getNameOf("Flag_GuideDog"))}
                    component={CCSwitch}
                    validator={
                      getSettingValue?.animalsMandatoryGuideDogAssistance
                        ? requiredValidator
                        : undefined
                    }
                  />
                </div>
                {valueGetter(getNameOf("Flag_GuideDog")) && (
                  <div className="cc-field">
                    <CCLabel
                      title="Type of proof"
                      isMandatory={
                        getSettingValue?.animalsMandatoryGuideDogTypeOfProof
                      }
                    />
                    <Field
                      name={getNameOf("GuideOrAssistanceDogProof_KWD")}
                      textField="Value"
                      dataItemKey="Key"
                      data={animalLOVs?.Animals_ProofOfSterilisation}
                      isUseDefaultOnchange
                      component={CCSearchComboBox}
                      validator={
                        getSettingValue?.animalsMandatoryGuideDogTypeOfProof
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                )}
              </div>
              <div className="animal-form-flex-col">
                <div className="cc-field">
                  <CCLabel
                    title={
                      capitalizeFirstLetter(
                        getSettingValue?.animalsLabelWorking
                      ) ?? "Working?"
                    }
                    isMandatory={getSettingValue?.animalsMandatoryWorking}
                  />
                  <Field
                    name={getNameOf("Flag_Farm")}
                    defaultChecked={valueGetter(getNameOf("Flag_Farm"))}
                    component={CCSwitch}
                    validator={
                      getSettingValue?.animalsMandatoryWorking
                        ? requiredValidator
                        : undefined
                    }
                  />
                </div>
                {valueGetter(getNameOf("Flag_Farm")) && (
                  <div className="cc-field">
                    <CCLabel
                      title="Type of proof"
                      isMandatory={
                        getSettingValue?.animalsMandatoryWorkingTypeOfProof
                      }
                    />
                    <Field
                      name={getNameOf("WorkingProof_KWD")}
                      textField="Value"
                      dataItemKey="Key"
                      data={animalLOVs?.Animals_ProofOfSterilisation}
                      isUseDefaultOnchange
                      component={CCSearchComboBox}
                      validator={
                        getSettingValue?.animalsMandatoryWorkingTypeOfProof
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                )}
              </div>

              {getSettingValue?.animalsDisplayCustomsDog && (
                <div className="animal-form-flex-col">
                  <div className="cc-field">
                    <CCLabel
                      title="Customs dog?"
                      isMandatory={getSettingValue?.animalsMandatoryCustomsDog}
                    />
                    <Field
                      name={getNameOf("Flag_Customs")}
                      defaultChecked={valueGetter(getNameOf("Flag_Customs"))}
                      component={CCSwitch}
                      validator={
                        getSettingValue?.animalsMandatoryCustomsDog
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                  {valueGetter(getNameOf("Flag_Customs")) && (
                    <div className="cc-field">
                      <CCLabel
                        title="Type of proof"
                        isMandatory={
                          getSettingValue?.animalsMandatoryCustomsDogTypeOfProof
                        }
                      />
                      <Field
                        name={getNameOf("CustomsDogProof_KWD")}
                        textField="Value"
                        dataItemKey="Key"
                        data={animalLOVs?.Animals_ProofOfSterilisation}
                        isUseDefaultOnchange
                        component={CCSearchComboBox}
                        validator={
                          getSettingValue?.animalsMandatoryCustomsDogTypeOfProof
                            ? requiredValidator
                            : undefined
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* ROW 2 */}
            <div className="cc-form-cols-3">
              {getSettingValue?.animalsMandatoryPurebreed && (
                <div className="animal-form-flex-col">
                  <div className="cc-field">
                    <CCLabel
                      title={
                        capitalizeFirstLetter(
                          getSettingValue?.animalsLabelPureBreed
                        ) ?? "Pure breed?"
                      }
                      isMandatory={getSettingValue?.animalsMandatoryPurebreed}
                    />
                    <Field
                      name={getNameOf("IsPureBreed")}
                      defaultChecked={valueGetter(getNameOf("IsPureBreed"))}
                      component={CCSwitch}
                      validator={
                        getSettingValue?.animalsMandatoryPurebreed
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                  {valueGetter(getNameOf("IsPureBreed")) && (
                    <div className="cc-field">
                      <CCLabel
                        title="Type of proof"
                        isMandatory={
                          getSettingValue?.animalsMandatoryPureBreedTypeOfProof
                        }
                      />
                      <Field
                        name={getNameOf("PureBreedProof_KWD")}
                        textField="Value"
                        dataItemKey="Key"
                        data={animalLOVs?.Animals_ProofOfSterilisation}
                        isUseDefaultOnchange
                        component={CCSearchComboBox}
                        validator={
                          getSettingValue?.animalsMandatoryPureBreedTypeOfProof
                            ? requiredValidator
                            : undefined
                        }
                      />
                    </div>
                  )}
                </div>
              )}
              {getSettingValue?.animalsDisplayGuardDog && (
                <div className="animal-form-flex-col">
                  <div className="cc-field">
                    <CCLabel
                      title="Guard dog?"
                      isMandatory={getSettingValue?.animalsMandatoryGuardDog}
                    />
                    <Field
                      name={getNameOf("IsGuardDog")}
                      defaultChecked={valueGetter(getNameOf("IsGuardDog"))}
                      component={CCSwitch}
                      validator={
                        getSettingValue?.animalsMandatoryGuardDog
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                  {valueGetter(getNameOf("IsGuardDog")) && (
                    <div className="cc-field">
                      <CCLabel
                        title="Type of proof"
                        isMandatory={
                          getSettingValue?.animalsMandatoryGuardDogTypeOfProof
                        }
                      />
                      <Field
                        name={getNameOf("GuardDogProof_KWD")}
                        textField="Value"
                        dataItemKey="Key"
                        data={animalLOVs?.Animals_ProofOfSterilisation}
                        isUseDefaultOnchange
                        component={CCSearchComboBox}
                        validator={
                          getSettingValue?.animalsMandatoryGuardDogTypeOfProof
                            ? requiredValidator
                            : undefined
                        }
                      />
                    </div>
                  )}
                </div>
              )}
              {getSettingValue?.animalsDisplayDogHomeAdoption && (
                <div className="animal-form-flex-col">
                  <div className="cc-field">
                    <CCLabel
                      title="Dog home adoption?"
                      isMandatory={
                        getSettingValue?.animalsMandatoryDogHomeAdoption
                      }
                    />
                    <Field
                      name={getNameOf("IsDogHomeAdoption")}
                      defaultChecked={valueGetter(
                        getNameOf("IsDogHomeAdoption")
                      )}
                      component={CCSwitch}
                      validator={
                        getSettingValue?.animalsMandatoryDogHomeAdoption
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                  {valueGetter(getNameOf("IsDogHomeAdoption")) && (
                    <div className="cc-field">
                      <CCLabel
                        title="Type of proof"
                        isMandatory={
                          getSettingValue?.animalsMandatoryDogHomeAdoptionTypeOfProof
                        }
                      />
                      <Field
                        name={getNameOf("DogHomeAdoptionProof_KWD")}
                        textField="Value"
                        dataItemKey="Key"
                        data={animalLOVs?.Animals_ProofOfSterilisation}
                        isUseDefaultOnchange
                        component={CCSearchComboBox}
                        validator={
                          getSettingValue?.animalsMandatoryDogHomeAdoptionTypeOfProof
                            ? requiredValidator
                            : undefined
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* ROW 3 */}
            <div className="cc-form-cols-3">
              {getSettingValue?.animalsDisplayHuntingdog && (
                <div className="animal-form-flex-col">
                  <div className="cc-field">
                    <CCLabel
                      title={
                        capitalizeFirstLetter(
                          getSettingValue?.animalsLabelHuntingDog
                        ) ?? "Hunting dog?"
                      }
                      isMandatory={getSettingValue?.animalsMandatoryHuntingdog}
                    />
                    <Field
                      name={getNameOf("IsHuntingDog")}
                      defaultChecked={valueGetter(getNameOf("IsHuntingDog"))}
                      component={CCSwitch}
                      validator={
                        getSettingValue?.animalsMandatoryHuntingdog
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                  {valueGetter(getNameOf("IsHuntingDog")) && (
                    <div className="cc-field">
                      <CCLabel
                        title="Type of proof"
                        isMandatory={
                          getSettingValue?.animalsMandatoryHuntingDogTypeOfProof
                        }
                      />
                      <Field
                        name={getNameOf("HuntingDogProof_KWD")}
                        textField="Value"
                        dataItemKey="Key"
                        data={animalLOVs?.Animals_ProofOfSterilisation}
                        isUseDefaultOnchange
                        component={CCSearchComboBox}
                        validator={
                          getSettingValue?.animalsMandatoryHuntingDogTypeOfProof
                            ? requiredValidator
                            : undefined
                        }
                      />
                    </div>
                  )}
                </div>
              )}
              {getSettingValue?.animalsDisplayGreyhound && (
                <div className="animal-form-flex-col">
                  <div className="cc-field">
                    <CCLabel
                      title={
                        capitalizeFirstLetter(
                          getSettingValue?.animalsLabelGreyhound
                        ) ?? "Greyhound?"
                      }
                      isMandatory={getSettingValue?.animalsMandatoryGreyhound}
                    />
                    <Field
                      name={getNameOf("IsGreyhound")}
                      defaultChecked={valueGetter(getNameOf("IsGreyhound"))}
                      component={CCSwitch}
                      validator={
                        getSettingValue?.animalsMandatoryGreyhound
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                  {valueGetter(getNameOf("IsGreyhound")) && (
                    <div className="cc-field">
                      <CCLabel
                        title="Type of proof"
                        isMandatory={
                          getSettingValue?.animalsMandatoryGreyhoundTypeOfProof
                        }
                      />
                      <Field
                        name={getNameOf("GreyhoundProof_KWD")}
                        textField="Value"
                        dataItemKey="Key"
                        data={animalLOVs?.Animals_ProofOfSterilisation}
                        isUseDefaultOnchange
                        component={CCSearchComboBox}
                        validator={
                          getSettingValue?.animalsMandatoryGreyhoundTypeOfProof
                            ? requiredValidator
                            : undefined
                        }
                      />
                    </div>
                  )}
                </div>
              )}
              {getSettingValue?.animalsDisplayCertifiedAssistance && (
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <CCLabel
                      title="Certified assistance?"
                      isMandatory={
                        getSettingValue?.animalsMandatoryCertifiedAssistance
                      }
                    />
                    <Field
                      name={getNameOf("Flag_CertifiedAssistance")}
                      defaultChecked={valueGetter(
                        getNameOf("Flag_CertifiedAssistance")
                      )}
                      component={CCSwitch}
                      validator={
                        getSettingValue?.animalsMandatoryCertifiedAssistance
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                </div>
              )}
              {getSettingValue?.animalsDisplayRestrictedBreed && (
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <CCLabel title="Restricted breed?" />
                    <Field
                      name={getNameOf("Flag_RestrictedBreed")}
                      defaultChecked={valueGetter(
                        getNameOf("Flag_RestrictedBreed")
                      )}
                      component={CCSwitch}
                      disabled={isReadOnlyRestrictedBreed}
                    />
                  </div>
                </div>
              )}
            </div>
          </section>
          <hr className="cc-divider" />

          {/* SECTION 5 */}
          <section className="cc-field-group">
            {/* ROW 1 */}
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel
                  title="Tag number"
                  isMandatory={getSettingValue?.animalsAutoIssue}
                />
                <Field
                  name={getNameOf("TagNumber")}
                  component={CCInput}
                  validator={
                    getSettingValue?.animalsAutoIssue
                      ? requiredValidator
                      : undefined
                  }
                  placeholder={"Tag number"}
                />
              </div>
              {!isNew && (
                <div className="cc-field">
                  <CCLabel title="Tag colour" />
                  <Field
                    name={getNameOf("TagColour_KWD")}
                    textField="Value"
                    dataItemKey="Key"
                    data={animalLOVs?.Animals_TagColour}
                    isUseDefaultOnchange
                    component={CCSearchComboBox}
                  />
                </div>
              )}
            </div>

            {/* ROW 2 */}
            <div className="cc-form-cols-3">
              {valueGetter(getNameOf("ReferenceNumber")) && (
                <div className="cc-field">
                  <CCLabel title="Permit number" isMandatory />
                  <Field
                    name={getNameOf("ReferenceNumber")}
                    component={CCInput}
                    validator={requiredValidator}
                    placeholder={"Permit number"}
                  />
                </div>
              )}
              {isNil(valueGetter(getNameOf("ReferenceNumber"))) &&
                isShowDogExemption && (
                  <div className="cc-field">
                    <CCLabel title="Dog Exemption?" />
                    <Field
                      name={getNameOf("Flag_DogExemption")}
                      defaultChecked={valueGetter(
                        getNameOf("Flag_DogExemption")
                      )}
                      component={CCSwitch}
                    />
                  </div>
                )}
            </div>
            {/* ROW 3 */}
            {valueGetter(getNameOf("Flag_DogExemption")) &&
              !getSettingValue?.animalsEnableDOGExemption && (
                <>
                  <div className="cc-form-cols-3 ">
                    <div className="cc-field">
                      <CCLabel title="Inspection date" />
                      <Field
                        name={getNameOf("Inspection_Date")}
                        format={DATE_FORMAT.DATE_CONTROL}
                        component={CCDatePicker}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Assessment date" />
                      <Field
                        name={getNameOf("Assessment_Date")}
                        format={DATE_FORMAT.DATE_CONTROL}
                        component={CCDatePicker}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Approved exemption?" />
                      <Field
                        name={getNameOf("Flag_ExemptionApproved")}
                        defaultChecked={valueGetter(
                          getNameOf("Flag_ExemptionApproved")
                        )}
                        component={CCSwitch}
                      />
                    </div>
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Approved date" />
                      <Field
                        name={getNameOf("Approved_Date")}
                        format={DATE_FORMAT.DATE_CONTROL}
                        component={CCDatePicker}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Exemption number" />
                      <Field
                        name={getNameOf("ExemptionNo")}
                        component={CCInput}
                        placeholder="Exemption number"
                      />
                    </div>
                  </div>
                </>
              )}

            {/* ROW 4 */}
            {!isNew && valueGetter(getNameOf("Date_EffectiveTo")) && (
              <div className="cc-form-cols-3">
                <CCValueField
                  label="Registered"
                  value={
                    valueGetter(getNameOf("Date_EffectiveFrom"))
                      ? new Date(valueGetter(getNameOf("Date_EffectiveFrom")))
                      : undefined
                  }
                  format={DATE_FORMAT.DATE}
                />
                <CCValueField
                  label="Renewal due"
                  value={
                    valueGetter(getNameOf("Status_ENUM")) ===
                    Registration_Status.Active
                      ? new Date(valueGetter(getNameOf("Date_EffectiveTo")))
                      : undefined
                  }
                  format={DATE_FORMAT.DATE}
                />
                <div className="cc-field">
                  <CCLabel title="File number" />
                  <Field
                    name={getNameOf("FileNumber")}
                    component={CCInput}
                    placeholder={"File number"}
                  />
                </div>
              </div>
            )}

            {/* ROW 5 */}
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel
                  title={
                    capitalizeFirstLetter(
                      getSettingValue?.corporateSettingsDebtorNumberLabel
                    ) ?? "Debtor number"
                  }
                  isMandatory={
                    getSettingValue?.corporateSettingsDebtorNumberMandatory
                  }
                />
                {getSettingValue?.corporateSettingsEnableDebtorUserSelection ? (
                  <Field
                    name={getNameOf("DebtorNumber")}
                    value={documentDebtorNumbers[0]}
                    placeholder={"Debtor number"}
                    component={DebtorNumberPicker}
                    disabled={
                      !isSuperAdmin &&
                      getSettingValue?.corporateSettingsFinanceAllocateDebtorNumber
                    }
                    onlyDisableInput
                    isSharePointDocumentLibraries
                    selectableMode="single"
                    onPickHandle={handleOnChangeDebtorNumberValue}
                    onInputChange={(event: ICCInputPickerChangeEvent) => {
                      onChange(getNameOf("DebtorNumber"), {
                        value: event?.value ?? "",
                      });
                      onChange(`option.DebtorNumber`, {
                        value: [
                          {
                            Record_Id: null,
                            Sys_DebtorNumber: event.value,
                          },
                        ],
                      });
                    }}
                    validator={
                      getSettingValue?.corporateSettingsDebtorNumberMandatory
                        ? requiredValidator
                        : undefined
                    }
                    contactId={parseInt(
                      valueGetter(`${getNameOf("Contact")}.Contact.Contact_ID`)
                    )}
                    recordId={animalId}
                    recordType={RECORDTYPE.Animals_Registration}
                    textField={nameOfDebtorNumber("Sys_DebtorNumber")}
                    textProduce={(data: RefNumberUsage) =>
                      data.Sys_DebtorNumber
                    }
                  />
                ) : (
                  <Field
                    name={getNameOf("DebtorNumber")}
                    component={CCInput}
                    placeholder={"Debtor number"}
                    disabled={
                      !isSuperAdmin &&
                      getSettingValue?.corporateSettingsFinanceAllocateDebtorNumber
                    }
                    validator={
                      getSettingValue?.corporateSettingsDebtorNumberMandatory
                        ? requiredValidator
                        : undefined
                    }
                  />
                )}
              </div>

              <CCValueField
                label="OS fees"
                value={OSFee}
                style={formatOSFees(OSFee)}
                format={CURRENCY_FORMAT.CURRENCY1}
              />
            </div>
          </section>

          <hr className="cc-divider" />
          <AnimalPhotoSection
            formRenderProps={formRenderProps}
            setOpenPopupPhoto={setOpenPopupPhoto}
          />

          {/* Additional Details */}
          {!isNew && (
            <>
              <br />
              <div className="cc-custom-sub-panel-bar">
                <TabTable
                  recordType={RECORDTYPE.Animals_Registration}
                  initialPanels={listPanelBar}
                />
              </div>
            </>
          )}
        </FormElement>

        {isOpenPopupPhoto && (
          <AddAttachmentDialog
            onClose={() => setOpenPopupPhoto(false)}
            onSubmit={handleSaveAttachmentPhoto}
            isLoadingButton={isLoadingUploadPhoto}
            isAllowMultiple={false}
            allowedFileExtensions={"jpg|png|jpeg|bmp|gif"}
            isDisableWebLink
          />
        )}
      </>
    );
  }
);

export default AnimalFormElement;
