import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfAssessmentAdjustmentsSteps {
  General = "General",
  AssessmentLevies = "Levies",
  Valuations = "Valuations",
  RatingPeriods = "RatingPeriod",
  LevyCalculation = "LevyCalculation",
  BalanceTransfer = "BalanceTransfer",
}

export const assessmentAdjustmentsKeysOfSendSteps = [
  EKeysOfAssessmentAdjustmentsSteps.General,
  EKeysOfAssessmentAdjustmentsSteps.AssessmentLevies,
  EKeysOfAssessmentAdjustmentsSteps.Valuations,
  EKeysOfAssessmentAdjustmentsSteps.RatingPeriods,
];

export interface IInitialData {
  showAssessmentLeviesStepper: boolean;
  showValuationsStepper: boolean;
  showRatingPeriodsStepper: boolean;
  showLevyCalculationStepper: boolean;
  workflowData: DTO_Workflow_AssessmentAdjustment;
}

export interface DTO_Supplementary_AssessmentAdjustment_LOVs {
  SubMarketGroup: DTO_LOV[];
  LandUse: DTO_LOV[];
  InstallmentPlan: DTO_LOV[];
  ApplicationForConversationAgreementPercentageVisibility: boolean;
  RestartAveragingVisibility: boolean;
  LandTaxPercentageVisibility: boolean;
  LRCM: DTO_LOV[];
  SubMarketGroupVisibility: boolean;
  CanRaiseCharges: boolean;
  AllowLevyChanges: boolean;
  AllowValuationChanges: boolean;
  SupplementaryId: number;
  AssessmentId: number;
  IsActiveSupplementaryType: boolean;
  ValuationCalculation: DTO_LOV[];
  SuppAssessRatingPeriod: Supp_Assess_Rating_Period[];
}

export interface RequestAdjustmentAssessmentObj {
  CalculateAdjustmentsRatingPeriod: boolean;
  ValuationComment: string;
  SubMarketGroup_Id: number | null;
  ValuationDate: Date | null;
  ValuationIssueDate: Date | null;
  ValuationEffectiveDate: Date | null;
  Valuation: string;
  SecondaryLandUse_Id: number;
  ValuationforPreviousCalculation_Id: number | null;
  PrimaryLandUse_Id: number;
  EffectiveDate: Date | null;
  Reason: string;
  Owners: string;
  Address: string;
  AssessmentNumber: string;
  Name_Address_Id: number;
  SupplementaryId: number;
  AssessmentId: number;
  InstalmentPlan_Id: number;
  ValuationforNewCalculation_Id: number | null;
}

export interface DTO_Workflow_AssessmentAdjustment {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_AssessmentAdjustment;
}

export interface DTO_WorkflowDetail_AssessmentAdjustment {
  AssessmentId: number;
  SupplementaryId: number;
  SupplementaryAssessmentId: number;
  AllowLevyRecalculations: boolean;
  General: DTO_General;
  Levies: DTO_Levies;
  RatingPeriod: DTO_RatingPeriod;
}

export interface DTO_General {
  AssessmentId: number;
  AssessmentNumber: string;
  AddressId: number;
  Address: string;
  Owners: string;
  Reason: string;
  EffectiveDate: Date | null;
  InstalmentPlanId: number | null;
}

export interface DTO_Levies {
  PrimaryLandUseId: number | null;
  SecondaryLandUseId: number | null;
}

export interface DTO_Supplementary {
  Id: number | null;
  Name: string;
  IdAndName: string;
  Date: Date | null;
  Reference: string;
  Notes: string;
}

export interface DTO_RatingPeriod {
  ValuationforPreviousCalculationId: number | null;
  ValuationforNewCalculationId: number | null;
  CalculateAdjustment: boolean;
}

export enum SUPPLEMENTARY_TYPES {
  ASSOCIATE_ASSESSMENTS = "Subdivisions,Amalgamations and general changes",
}

export interface Supp_Assess_Rating_Period {
  Supplementary_Assessment_Id: number;
  Rating_Period_Id: number;
  New_Valuation_Id: number | null;
  Old_Valuation_Id: number | null;
  SARP_Calculate_Adjustments: boolean;
}
