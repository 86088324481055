import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { AnimalActionBarNavDropdown } from "@app/products/animals/components/action-bar/nav-dropdown/_index";

import { DeleteButton } from "@app/core/delete/buttons/_index";
import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { NewAnimalButton } from "@app/products/animals/components/action-bar/buttons/new-animal/_index";
import { generateAnimalsFinanceViewBySetting } from "@app/products/animals/finance/config";
import { getAnimalsFinanceListViewOdata } from "@app/products/animals/finance/constant";
import {
  AnimalFinanceViewType,
  IAnimalsFinanceViewBySettingProps,
  Svc_Animals_Finance,
} from "@app/products/animals/finance/model";
import { AnimalOSDebtorSummaryBookmark } from "@app/products/animals/finance/os-debtor-summary/util";
import { animalRoute } from "@app/products/animals/route";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useCommonCoreStore } from "@common/stores/core/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { financeRoute } from "../route";
import { colAnimalsFinanceOSDebtorSummary } from "./config";

const nameOf = nameOfFactory<Svc_Animals_Finance>();
export default observer(() => {
  const { settings } = useCommonCoreStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Animals_Form_Registration,
    productType: PRODUCT_TYPE_NUMBER.Animals,
  });

  useCCListViewActionBar({
    title: animalRoute.name,
    leftComponents: [
      <AnimalActionBarNavDropdown category={financeRoute.path} />,
    ],
    centerComponents: [
      <NewAnimalButton
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_NEW)}
      />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.Animals}
        dataSetFilter={MAILMERGEDATASET.Animals_Registration}
        recordType={RECORDTYPE.Animals_Registration}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.Animals}
        recordType={RECORDTYPE.Animals_Registration}
        keyField={nameOf("ID")}
      />,
      <DeleteButton
        recordType={RECORDTYPE.Animals_Registration}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ListViewBookmarkIcon
        linkUrl={ANIMALS_ROUTE}
        productType={PRODUCT_TYPE.Animals}
        recordType={RECORDTYPE.Animals_Registration}
        detail={AnimalOSDebtorSummaryBookmark.getBookmarkListViewDisplayName}
        displayName={
          AnimalOSDebtorSummaryBookmark.getBookmarkListViewDetailRecord
        }
        listViewDetail={AnimalOSDebtorSummaryBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          AnimalOSDebtorSummaryBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        columnFields={generateAnimalsFinanceViewBySetting({
          settings,
          defaultConfig: colAnimalsFinanceOSDebtorSummary,
          listType: AnimalFinanceViewType.DebtorSummaryOutstanding,
        } as IAnimalsFinanceViewBySettingProps)}
        dataUrl={getAnimalsFinanceListViewOdata(
          AnimalFinanceViewType.DebtorSummaryOutstanding
        )}
        primaryField={nameOf("ID")}
      />
    </LoadingPermissionWrapper>
  );
});
