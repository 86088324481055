import { CCGrid, ICCGridProps } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import { Field } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import "./_index.scss";

interface IGridSearch extends Omit<ICCGridProps, "primaryField"> {
  grid: {
    primaryField?: string;
    itemPerPage?: number;
    isAutoHiddenPager?: boolean;
  };
  search: {
    hasSearchField?: boolean;
    filterCol?: string;
    sortCol?: string;
  };
  setSelectedItemCount?: (data: any) => void;
  handleSelectionChange: (data: any) => void;
}

export const CRMSGridSearch = ({
  grid,
  search = {
    hasSearchField: false,
    filterCol: undefined,
    sortCol: undefined,
  },
  columnFields,
  dataUrl,
  data,
  selectableMode = "single",
  state,
  selectedRows,
  setSelectedItemCount,
  handleSelectionChange,
}: IGridSearch) => {
  const filters: (CompositeFilterDescriptor | FilterDescriptor)[] | undefined =
    state?.filter?.filters ?? [];
  const [searchKey, setSearchKey] = useState("");
  const [stateGrid, setStateGrid] = useState<any>(
    search.sortCol
      ? searchKey.trim() !== ""
        ? {
            ...state,
            sort: [{ field: search.sortCol, dir: "asc" }],
            filter: {
              logic: "and",
              filters: [
                {
                  field: search.filterCol,
                  operator: "contains",
                  value: searchKey,
                },
                ...filters,
              ],
            },
          }
        : {
            ...state,
            sort: [{ field: search.sortCol, dir: "asc" }],
            filter: {
              logic: "or",
              filters: [...filters],
            },
          }
      : { state }
  );

  useEffect(() => {
    const newFiltersUpdate = state?.filter?.filters ?? [];
    setStateGrid(
      search.sortCol
        ? searchKey.trim() !== ""
          ? {
              ...state,
              sort: [{ field: search.sortCol, dir: "asc" }],
              filter: {
                logic: "and",
                filters: [
                  {
                    field: search.filterCol,
                    operator: "contains",
                    value: searchKey,
                  },
                  ...newFiltersUpdate,
                ],
              },
            }
          : {
              ...state,
              sort: [{ field: search.sortCol, dir: "asc" }],
              filter: {
                logic: "and",
                filters: [...newFiltersUpdate],
              },
            }
        : { state }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className="cc-grid-search">
      {search.hasSearchField ? (
        <div className="cc-grid-search-input">
          <label className="cc-label">Search</label>
          <Field
            name={"searchKey"}
            component={CCInput}
            placeholder="Search"
            onChange={(e: InputChangeEvent) => {
              setSearchKey(e.value);
              setStateGrid({
                ...stateGrid,
                ...(search.sortCol
                  ? e.value.trim() !== ""
                    ? {
                        ...stateGrid,
                        filter: {
                          logic: "and",
                          filters: [
                            {
                              field: search.filterCol,
                              operator: "contains",
                              value: e.value,
                            },
                            ...filters,
                          ],
                        },
                      }
                    : {
                        ...stateGrid,
                        filter: {
                          logic: "or",
                          filters: [...filters],
                        },
                      }
                  : stateGrid),
              });
            }}
          />
        </div>
      ) : null}
      <span
        id="grid-header-collapsed-state-tool"
        className="cc-expand-collapse-button-group"
      />
      <div className="cc-grid-search-list">
        <CCGrid
          columnFields={columnFields}
          selectableMode={selectableMode}
          data={data}
          dataUrl={dataUrl}
          primaryField={grid.primaryField || "ID"}
          onSelectionChange={handleSelectionChange}
          onTotalSelectedRowChange={setSelectedItemCount}
          onDataStateChange={(state) => setStateGrid(state)}
          state={stateGrid}
          isAutoHiddenPager={grid.isAutoHiddenPager ?? false}
          selectedRows={selectedRows}
          itemPerPage={grid.itemPerPage ?? 10}
        />
      </div>
    </div>
  );
};
