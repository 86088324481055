import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const AddRebateButton = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { assessment } = useAssessmentStore();
  const { setListDialog } = usePropertyWorkflowStore();
  return (
    <CCNavButton
      title="Add rebate"
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.New_Rebate,
            data: {},
            props: {
              assessmentID: +id,
              statusID: assessment?.Assessment?.StatusId ?? null,
            },
          },
        ]);
      }}
    />
  );
});
