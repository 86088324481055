import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import {
  getInitialDataDebtRecovery,
  postNewDebtRecovery,
} from "@app/products/property/components/action-bar/dialog/new-debt-recovery/api";
import { DetailsFormStep } from "@app/products/property/components/action-bar/dialog/new-debt-recovery/components/form-elements/detail/_index";
import { ConfirmCloseButton } from "@app/products/property/components/action-bar/dialog/new-debt-recovery/dialog/confirm-close-button/_index";
import {
  DTO_DebtRecovery_LOVs,
  DTO_Workflow_CreateDebtRecovery,
  EKeysOfSteps,
  keysOfSendSteps,
} from "@app/products/property/components/action-bar/dialog/new-debt-recovery/model";
import { useNewDebtRecoveryDialogStore } from "@app/products/property/components/action-bar/dialog/new-debt-recovery/store";
import { abandonWorkflow } from "@app/products/property/components/action-bar/property-workflow/api";

import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface INewDebtRecoveryDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  assessmentId: number;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  suffixTitle?: string;
  isShowSearchType?: boolean;
  isReadOnly?: boolean;
  isToBeApprovalMode?: boolean;
  isShowReasonRejection?: boolean;
  isShowReason?: boolean;
  isShowCancelWorkflowButton?: boolean;
  statusBadge?: IBadgeDialog[];
  isSaveOnNextStep?: boolean;
  isIncompleteMode?: boolean;
  isFromActionList?: boolean;
}

export const NewDebtRecoveryDialog = observer(
  ({
    onClose,
    statusBadge,
    assessmentId,
    dataFromActionList,
    prefixTitle,
    isReadOnly = false,
    isToBeApprovalMode = false,
    isSaveOnNextStep = false,
    isFromActionList = false,
    isShowCancelWorkflowButton = false,
    isIncompleteMode = false,
    suffixTitle,
  }: INewDebtRecoveryDialogProps) => {
    const { currentFormTitle } = useCommonProductStore();
    const { pushNotification } = useCCAppNotificationStore();
    const {
      setIsLoadingOnDialog,
      setIsLoadingOnDialogCancel,
      setDebtRecoveryLOVs,
    } = useNewDebtRecoveryDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const [isShowCloseDialog, setIsShowCloseDialog] = useState<boolean>(false);
    const [finishConfirmDialogData, setFinishConfirmDialogData] =
      useState<any>();
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingFinish, setIsLoadingFinish] = useState<boolean>(false);
    const [newDebtRecoveryInitData, setNewDebtRecoveryInitData] =
      useState<DTO_Workflow_CreateDebtRecovery>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);

    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "New debt recovery";
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, workflowHeader]);

    const initialValues = useMemo(() => {
      let initDetails: any = {};
      if (newDebtRecoveryInitData) {
        const workflowDetail = newDebtRecoveryInitData?.WorkflowDetail;
        //Details
        if (workflowDetail?.DebtRecovery) {
          initDetails = {
            ...initDetails,
            ...workflowDetail?.DebtRecovery,
          };
        }
      }
      return {
        [EKeysOfSteps.Details]: initDetails,
        [EKeysOfSteps.Comments]: {},
        [EKeysOfSteps.Documents]: {},
      };
    }, [newDebtRecoveryInitData]);

    const steps: IStep[] = [
      {
        label: "Details",
        initialValues: initialValues.DebtRecovery,
        component: DetailsFormStep,
        visible: true,
        key: EKeysOfSteps.Details,
        options: {
          isReadOnly,
          workflowDraftId,
        },
      },
      {
        label: "Comments",
        initialValues: initialValues.Comments,
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfSteps.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Debt_Recovery,
        },
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValues.Documents,
        visible: true,
        key: EKeysOfSteps.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Create_DebtRecovery,
        },
      },
    ];

    /**
     * Process data before sending to API
     * @param data
     */
    const processData: DTO_Workflow_CreateDebtRecovery | any = (data: any) => {
      let workflowDetail: any = {};
      const sendSteps = pickBy(data, function (value, key) {
        if (keysOfSendSteps.includes(key as EKeysOfSteps)) {
          return { [key]: value };
        }
      });

      if (sendSteps) {
        for (const [key, value] of Object.entries(sendSteps)) {
          if (value && value._option) {
            delete value._option;
          }
          const dataStep = { ...value };
          workflowDetail[key as keyof DTO_Workflow_CreateDebtRecovery] =
            dataStep;
        }
      }
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          setFinishConfirmDialogData(data);
          break;
        case EListSubmitButton.Cancel:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.ConfirmCloseYes:
        case EListSubmitButton.Close:
          await sendParkDebtRecovery(processData(data));
          break;
      }
    };

    const sendSaveDebtRecovery = async (
      payload: DTO_Workflow_CreateDebtRecovery,
      isCloseDialog: boolean = false
    ) => {
      const response = await postNewDebtRecovery(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingOnNext(false);
      const defaultSuccessMessage = "Debt recovery saved successfully";
      const defaultFailedMessage =
        "The create new debt recovery process could not be saved";
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          if (isCloseDialog) {
            onClose();
            pushNotification({
              title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
              type: "success",
            });
          }
          if (isFirstSave) {
            setIsFirstSave(false);
            setWorkflowDraftId(response?.data?.ID ?? 0);
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID ?? 0,
              },
            });
          }
          return true;
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
          return false;
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        return false;
      }
    };

    const sendApproveDebtRecovery = async (
      payload: DTO_Workflow_CreateDebtRecovery
    ) => {
      const defaultSuccessMessage = "New debt recovery created successfully";
      const defaultFailedMessage = "Create debt recovery failed";

      const response = await postNewDebtRecovery(
        WorkflowProcessMode.Approve,
        payload
      );
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          onClose();
          pushNotification({
            title: response.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response?.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    const sendParkDebtRecovery = async (
      payload: DTO_Workflow_CreateDebtRecovery
    ) => {
      setIsLoadingPark(true);
      setIsLoadingOnDialog(true);
      const defaultSuccessMessage = "Debt recovery parked successfully";
      const defaultFailedMessage = "Park debt recovery failed";
      const response = await postNewDebtRecovery(
        WorkflowProcessMode.Park,
        payload
      );
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          onClose();
          pushNotification({
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response?.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      setIsLoadingOnDialog(false);
      setIsLoadingPark(false);
    };

    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          //@TODO implement again after api update
          cancelAPI: postNewDebtRecovery,
          dataCancel: data,
          defaultSuccessMessage:
            "The new debt recovery was cancelled successfully.",
          defaultErrorMessage: "New debt recovery could not be cancelled.",
        });
      } else {
        onClose();
      }
    };

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setIsShowCloseDialog(true);
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name &&
        dataFromActionList.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    const handleConfirmNoRetain = async () => {
      setIsLoadingOnDialogCancel(true);
      await abandonWorkflow().then(() => {
        setIsLoadingOnDialogCancel(false);
        onClose();
      });
    };

    const handleNextButton = async (data: any) => {
      setIsLoadingOnNext(true);
      return await sendSaveDebtRecovery(processData(data));
    };

    const handleFinish = async (data: any) => {
      setIsLoadingFinish(true);
      //Auto approve after finish
      await sendApproveDebtRecovery(processData(data));
    };

    const getWorkflowData = () => {
      const draftId = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);

      getInitialDataDebtRecovery(assessmentId, draftId).then((response) => {
        if (Array.isArray(response)) {
          const [lovsDebtRecoveryResponse, workflowDataResponse] = response;
          const lovsDebtRecoveryData = lovsDebtRecoveryResponse?.data;
          const workflowData = workflowDataResponse?.data;
          if (
            isSuccessResponse(response[0]) &&
            isSuccessResponse(response[1]) &&
            lovsDebtRecoveryData &&
            workflowData
          ) {
            //set Lovs Data DebtRecovery
            setDebtRecoveryLOVs(lovsDebtRecoveryData);
            //set New DebtRecovery InitData
            setNewDebtRecoveryInitData({
              WorkflowDetail: workflowData?.WorkflowDetail,
              WorkflowHeader: workflowData?.WorkflowHeader,
            });
            //set workflow header and workflow Draft Id
            if (workflowData?.WorkflowHeader) {
              setWorkflowHeader(workflowData.WorkflowHeader);
              setWorkflowDraftId(
                workflowData?.WorkflowHeader?.WorkflowDraft
                  ?.Workflow_Draft_Id ?? 0
              );
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
          } else {
            let responseError: APIResponse<
              | DTO_DebtRecovery_LOVs
              | DTO_Workflow_CreateDebtRecovery
              | ResponsePacket
            > = response[0];
            if (!isSuccessResponse(response[1])) {
              responseError = response[1];
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error:
                  (responseError.data as ResponsePacket)?.Errors ??
                  "Load workflow failed",
              },
            });
          }
        } else {
          const responseError = response as APIResponse;
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error: "Load workflow failed",
            },
          });
        }
      });
    };

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <>
        <CCFormStep
          ref={notificationFormStepRef}
          onSubmit={handleSubmit}
          listButtonId={listSubmitButton}
          initialValues={initialValues}
          initialSteps={steps}
          saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
          renderForm={(renderProps: ICCFormStepRender) => (
            <CCDialog
              maxWidth="60%"
              titleHeader={titleHeader}
              onClose={() => handleCloseDialog(renderProps)}
              bodyElement={renderProps.children}
              badge={statusBadge}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          isLoadingPark || isLoadingFinish || isLoadingOnNext
                        }
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                  {isShowCloseDialog && (
                    <ConfirmCloseButton
                      onClose={() => setIsShowCloseDialog(false)}
                      onConfirmYes={renderProps.submitButton.onClick}
                      onConfirmNo={handleConfirmNoRetain}
                    />
                  )}
                </>
              }
            />
          )}
        />
        {finishConfirmDialogData && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={
              "The debt recovery will be created based on the information provided. Are you sure you want to submit?"
            }
            isLoadingYes={isLoadingFinish}
            onClosePopup={() => setFinishConfirmDialogData(undefined)}
            onAsyncConfirm={() => {
              return handleFinish(finishConfirmDialogData);
            }}
          />
        )}
      </>
    );
  }
);
