import { IAction } from "@app/core/actions/model";
import { getUrlAction } from "@app/products/town-planning/ppr/actions/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IAction>();
export const colAction: IColumnFields[] = [
  {
    field: nameOf("Date"),
    title: "Date",
    format: DATE_FORMAT.LONG_DATE,
    locked: true,
    linkTo: getUrlAction,
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("ActionOfficer"),
    title: "Action Officer",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
];

export const actionSettingEnums = [
  ECorporateSettingsField.CorporateSettings_ActionType_Tags,
  ECorporateSettingsField.CorporateSettings_Action_ShowDetailsForMeetings,
  ECorporateSettingsField.CorporateSettings_Action_EnableActionCompletionEmailAlert,
];
