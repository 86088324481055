import {
  DTO_Assessment,
  DTO_Title,
} from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfTitle = nameOfFactory<DTO_Title>();
const nameOfAssessment = nameOfFactory<DTO_Assessment>();

export const colTitles: IColumnFields[] = [
  {
    field: nameOfTitle("Legal_Description"),
    title: "Legal Description",
  },
  { field: nameOfTitle("Owner"), title: "Owner" },
  {
    field: nameOfTitle("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOfTitle("Title_Id"),
    title: "Title ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colAssessments: IColumnFields[] = [
  {
    field: nameOfAssessment("Assessment_Number"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: nameOfAssessment("Property_Address"), title: "Property Address" },
  {
    field: nameOfAssessment("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfAssessment("Occupier_Name_Address"),
    title: "Occupier Name and Address",
    isGrow: true,
  },
];
