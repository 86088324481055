import { VO_Compliance_W_Assessment } from "@app/products/property/assessments/compliance/[id]/model";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (
  selectedRow: VO_Compliance_W_Assessment
) => {
  const dynamicDisplayName = selectedRow.Compliance_Id
    ? `- ${selectedRow.Compliance_Id}`
    : "";
  return `Property - Assessments ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: VO_Compliance_W_Assessment) => {
  return getDisplayTextWithDashes([
    selectedRow.Compliance_Id,
    selectedRow.Name,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return "Property - Assessments";
};

export const getBookmarkListViewDetail = () => {
  return "Property - Assessments - Compliance - All";
};
