import AssessmentReAllocationDialog from "@app/products/property/assessments/components/dialogs/re-allocation/_index";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ReAllocationOfCreditsButton = observer(() => {
  const { currentOrganisationMode } = useCommonProductStore();
  const isLLS = currentOrganisationMode(OrganisationMode.LLS);
  const [showReAllocationDialog, setShowReAllocationDialog] = useState(false);

  return (
    <>
      <CCNavButton
        title="Reallocation Credits"
        onClick={() => setShowReAllocationDialog(true)}
        invisible={isLLS}
      />

      {showReAllocationDialog && (
        <AssessmentReAllocationDialog
          onClosePopup={() => {
            setShowReAllocationDialog(false);
          }}
          onConfirmYes={() => {
            setShowReAllocationDialog(false);
          }}
        />
      )}
    </>
  );
});
