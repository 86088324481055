import { DTO_AssessmentLevy_Flags } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/assessment-levies/dialogs/assessment-levy/model";

export const defaultAssessmentLevyFlags: DTO_AssessmentLevy_Flags = {
  ActiveFromDefaultValue: null,
  Check_Checkbox_ReplaceExistingLevy: false,
  Display_Category_Field: true,
  Display_LandRentCalcMethod_Field: true,
  Display_Levy_Field: true,
  Display_MaximumsMinimums_Field: true,
  Display_PercentageofAmount_Field: true,
  Display_PercentageofLevy_Field: true,
  Display_PercentageofRatebyValn_Field: true,
  Display_QuantityAmount_Field: true,
  Display_Reason_Field: true,
  Display_TobeActiveFromTo_Field: true,
  Enable_Checkbox_ReplaceExistingLevy: true,
  QuantityAmount_FieldLabel: "Quantity/Amount",
  QuantityAmount_IsRequired: false,
};
