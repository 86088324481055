import { getUUID } from "@common/utils/common";

export const CCGridEventType = {
  RefreshOData: getUUID(),
};

// [Grid] Pager: Use to show text "items/page" in the pager-info
export const WIDTH_TO_SHOW_LABEL_ITEM_PER_PAGE = 570;

export const MARGIN_LEFT_MESSAGE_NO_RECORD = "-15px";

export enum EGroupHeaderProps {
  AGGREGATES = "aggregates",
  GROUP_ID = "groupId",
}
