import { UserCredentials } from "@common/pages/settings/security/site-users/_id/components/dialogs/credentials/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredStringValidator } from "@common/utils/field-validators";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { isEmpty } from "lodash";
import React from "react";

export interface IAddCommentDialogProps {
  onClose: () => void;
  onSubmit: (dataItem: UserCredentials) => void;
  isDomainMandatory?: boolean;
  initialValue?: UserCredentials;
}

export const CredentialsDialog = ({
  onClose,
  onSubmit,
  isDomainMandatory = false,
  initialValue,
}: IAddCommentDialogProps) => {
  const nameOf = nameOfFactory<UserCredentials>();
  const handleOnSubmitClick = async (event: FormSubmitClickEvent) => {
    onSubmit(event.values as UserCredentials);
  };

  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      initialValues={{
        ...initialValue,
        _option: {
          Password:
            initialValue?.Password &&
            initialValue?.Password.replace(/[\s\S]/g, "●"),
        },
      }}
      key={JSON.stringify(initialValue)}
      render={(formRenderProps: FormRenderProps) => {
        const { onChange, valueGetter } = formRenderProps;
        const password = valueGetter("Password");
        return (
          <CCDialog
            maxWidth="35%"
            height="auto"
            titleHeader={
              initialValue?.Header && isEmpty(initialValue?.Header)
                ? initialValue?.Header
                : "User Credentials"
            }
            onClose={onClose}
            bodyElement={
              <FormElement className="cc-form cc-form-credentials">
                <section className="cc-field-group">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <CCLabel title="Domain" isMandatory={isDomainMandatory} />
                      <Field
                        name={nameOf("Domain")}
                        placeholder={"Domain"}
                        component={CCInput}
                        validator={
                          isDomainMandatory
                            ? requiredStringValidator
                            : undefined
                        }
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Username" isMandatory />
                      <Field
                        name={nameOf("UserName")}
                        placeholder={"Username"}
                        component={CCInput}
                        validator={requiredStringValidator}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Password" isMandatory />
                      <Field
                        className="cc-password-field"
                        placeholder={"Password"}
                        name="_option.Password"
                        rows={4}
                        component={CCInput}
                        type="text"
                        validator={requiredStringValidator}
                        onChange={(event: InputChangeEvent) => {
                          if (!event.value) {
                            onChange(".Password", {
                              value: undefined,
                            });
                            onChange("_option.Password", {
                              value: undefined,
                            });
                            return;
                          }
                          onChange("_option.Password", {
                            value: event.value.replace(/[\s\S]/g, "●"),
                          });

                          const lastCharacter = event.value.substring(
                            event.value.length - 1
                          );
                          if (event.value && lastCharacter !== "●") {
                            onChange("Password", {
                              value: password + lastCharacter,
                            });
                          } else {
                            onChange("Password", {
                              value: password.substring(0, password.length - 1),
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </section>
              </FormElement>
            }
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Close
                </Button>
                <Button
                  disabled={!formRenderProps.valid}
                  themeColor="primary"
                  onClick={formRenderProps.onSubmit}
                  className={"cc-dialog-button"}
                >
                  Save
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
