import { BusinessessNumberTypes } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/people/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInputPhone } from "@components/cc-input-phone/_index";
import { useIsEnableValidatePhoneNumber } from "@components/cc-input-phone/hook/useIsEnableValidatePhoneNumber";
import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { validatePhoneNumber } from "@components/cc-input-phone/util";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const PEOPLE_FORM_STEP = "People";
export const PeopleStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({ formRenderProps, nameOf, setStepsVisible }: IFormStepElement) => {
    const { onChange } = formRenderProps;
    const { settings } = useCommonProductStore();
    const isVisibleABN = useRef(false);
    const isVisibleACN = useRef(false);
    const isVisibleARBN = useRef(false);
    const isEnableValidatePhoneNumber = useIsEnableValidatePhoneNumber();

    const ownerBusinessNumbersKeyValue =
      settings[
        ECorporateSettingsField
          .CorporateSettings_PropertyDialog_OwnerBusinessNumbers
      ]?.KeyValueBlob ?? undefined;

    const ownerBusinessNumbers = getBoolValueSetting(
      settings[
        ECorporateSettingsField
          .CorporateSettings_PropertyDialog_OwnerBusinessNumbers
      ]
    );

    useEffect(() => {
      if (ownerBusinessNumbersKeyValue) {
        ownerBusinessNumbersKeyValue.split(",").forEach((item) => {
          if (
            item.toLowerCase() ===
            BusinessessNumberTypes.ABN.toString().toLowerCase()
          )
            isVisibleABN.current = true;
          if (
            item.toLowerCase() ===
            BusinessessNumberTypes.ACN.toString().toLowerCase()
          )
            isVisibleACN.current = true;
          if (
            item.toLowerCase() ===
            BusinessessNumberTypes.ARBN.toString().toLowerCase()
          )
            isVisibleARBN.current = true;
        });
      }
    }, [ownerBusinessNumbersKeyValue]);

    return (
      <>
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Owners</label>
              <Field
                name={"Address.PropertyAssessment.Owners_Name"}
                component={CCInput}
                placeholder={"Owners"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Attention of</label>
              <Field
                name={"Address.PropertyAssessment.Owners_Attention_Of"}
                component={CCInput}
                placeholder={"Attention of"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Care of</label>
              <Field
                name={"Address.PropertyAssessment.Owners_Care_Of"}
                component={CCInput}
                placeholder={"Care of"}
              />
            </div>
            {ownerBusinessNumbers ?? (
              <div className="cc-field">
                <label className="cc-label">Street number</label>
                <Field
                  name={"Address.PropertyAssessment.Owners_StreetNumber"}
                  component={CCInput}
                  placeholder={"Street number"}
                />
              </div>
            )}
            <div className="cc-field">
              <label className="cc-label">Address</label>
              <Field
                name={"Address.PropertyAssessment.Owners_Address"}
                component={CCInput}
                placeholder={"Address"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Locality</label>
              <Field
                name={"Address.PropertyAssessment.Owners_Locality"}
                component={CCInput}
                placeholder={"Locality"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">State</label>
              <Field
                name={"Address.PropertyAssessment.Owners_State"}
                component={CCInput}
                placeholder={"State"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Postcode</label>
              <Field
                name={"Address.PropertyAssessment.Owners_Postcode"}
                component={CCInput}
                placeholder={"Postcode"}
              />
            </div>
            {isVisibleABN && (
              <div className="cc-field">
                <label className="cc-label">Country</label>
                <Field
                  name={"Address.PropertyAssessment.Owners_Country"}
                  component={CCInput}
                  placeholder={"Country"}
                />
              </div>
            )}
            <div className="cc-field">
              <label className="cc-label">Phone</label>
              <Field
                name={"Address.PropertyAssessment.Owners_Phone"}
                placeholder={"Phone"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Private}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Mobile</label>
              <Field
                name={"Address.PropertyAssessment.Owners_Mobile"}
                placeholder={"Mobile"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Mobile}
              />
            </div>
            {isVisibleACN && (
              <div className="cc-field">
                <label className="cc-label">ACN</label>
                <Field
                  name={"Address.PropertyAssessment.Owners_ACN"}
                  component={CCInput}
                  placeholder={"ACN"}
                />
              </div>
            )}
            <div className="cc-field">
              <Field
                label="Email"
                name={"Address.PropertyAssessment.Owners_Email"}
                placeholder={"Email"}
                component={CCInputEmail}
                validator={validateEmail}
                onChangeForm={onChange}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Fax</label>
              <Field
                name={"Address.PropertyAssessment.Owners_Fax"}
                placeholder={"Fax"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Fax}
              />
            </div>
            {isVisibleARBN && (
              <div className="cc-field">
                <label className="cc-label">ARBN</label>
                <Field
                  name={"Address.PropertyAssessment.Owners_ARBN"}
                  component={CCInput}
                  placeholder={"ARBN"}
                />
              </div>
            )}
            <div className="cc-field">
              <label className="cc-label">Entity ID</label>
              <Field
                name={"Address.PropertyAssessment.Owners_Entity_ID"}
                component={CCNumericTextBox}
                placeholder={"Entity ID"}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Ratepayers</label>
              <Field
                name={"Address.PropertyAssessment.RatePayers_Name"}
                component={CCInput}
                placeholder={"Ratepayers"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Attention of</label>
              <Field
                name={"Address.PropertyAssessment.RatePayers_Attention_Of"}
                component={CCInput}
                placeholder={"Attention of"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Care of</label>
              <Field
                name={"Address.PropertyAssessment.RatePayers_Care_Of"}
                component={CCInput}
                placeholder={"Care of"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Address</label>
              <Field
                name={"Address.PropertyAssessment.RatePayers_Address"}
                component={CCInput}
                placeholder={"Address"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Locality</label>
              <Field
                name={"Address.PropertyAssessment.RatePayers_Locality"}
                component={CCInput}
                placeholder={"Locality"}
              />
            </div>

            <div className="cc-field ">
              <label className="cc-label">State</label>
              <Field
                name={"Address.PropertyAssessment.RatePayers_State"}
                component={CCInput}
                placeholder={"State"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Postcode</label>
              <Field
                name={"Address.PropertyAssessment.RatePayers_Postcode"}
                component={CCInput}
                placeholder={"Postcode"}
              />
            </div>
            <div className="cc-field ">
              <label className="cc-label">Country</label>
              <Field
                name={"Address.PropertyAssessment.RatePayers_Country"}
                component={CCInput}
                placeholder={"Country"}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Phone</label>
              <Field
                name={"Address.PropertyAssessment.RatePayers_Phone"}
                placeholder={"Phone"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Private}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Mobile</label>
              <Field
                name={"Address.PropertyAssessment.RatePayers_Mobile"}
                placeholder={"Mobile"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Mobile}
              />
            </div>
            <div className="cc-field">
              <Field
                label="Email"
                name={"Address.PropertyAssessment.RatePayers_Email"}
                placeholder={"Email"}
                component={CCInputEmail}
                validator={validateEmail}
                onChangeForm={onChange}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Fax</label>
              <Field
                name={"Address.PropertyAssessment.RatePayers_Fax"}
                placeholder={"Fax"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Entity ID</label>
              <Field
                name={"Address.PropertyAssessment.RatePayers_Entity_ID"}
                component={CCNumericTextBox}
                placeholder={"Entity ID"}
              />
            </div>
          </div>
        </section>
      </>
    );
  }
);
