import { useSettingManageFormActionBarStore } from "@app/products/property/system-admin/settings/_id/components/action-bar/store";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const SettingManageFormActionBar = observer((props: any) => {
  const { isLoadingSubmitNew } = useSettingManageFormActionBarStore();
  return props.isNew ? (
    <CCManagePageActionBar
      leftActions={[
        <CCNavButton
          title={"Save"}
          htmlFor="btn-submit"
          disabled={isLoadingSubmitNew}
          isLoading={isLoadingSubmitNew}
        />,
      ]}
    />
  ) : (
    <CCManagePageActionBar
      rightActions={[
        <CCNavButton
          title={"Slide Bar"}
          iconClass="fal fa-info-circle"
          isActive={props.isShowSlideBar}
          onClick={() => {
            props.onClick();
          }}
        />,
      ]}
    />
  );
});
