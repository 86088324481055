import { KeyValuePair } from "@app/core/documents/model";
import {
  CS_CaseDetailType,
  CS_Confidentiality,
  CS_OnBehalfOfType,
  CS_ServiceStandardType,
  ElementControl,
  ServiceStandardUpdateTriggers,
  SpecialInterestRequestorOrgManagerLevel,
} from "@app/products/crms/[id]/model";
import { CS_MobileIcon } from "@app/products/crms/service-standards/[id]/model";
import { AccessControl } from "@common/models/accessControl";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { SiteUser } from "@common/pages/settings/security/site-users/_id/model";

export interface ServiceStandardBatchUpdateMapObj {
  SvcStandardBatchUpdate: ServiceStandardBatchUpdate;
}

export interface ServiceStandardBatchUpdate {
  SelectedRows: number[];
  Update_DisplayInBulletinBoard: boolean;
  DisplayInBulletinBoard: boolean | null;
  Update_CaseDetailsForm: boolean;
  CaseDetailsForm: CS_CaseDetailType | null;
  Update_CaseDetailsButtonLabel: boolean;
  CaseDetailsButtonLabel: string;
  Update_MobileIconForm: boolean;
  MobileIconForm: CS_MobileIcon | null;
  Update_MobileChoice: boolean;
  MobileChoice: boolean | null;
  Update_OrgStructure: boolean;
  Org1_Id: number | null;
  Org2_Id: number | null;
  Org3_Id: number | null;
  Org4_Id: number | null;
  Org5_Id: number | null;
  Update_Coordinator: boolean;
  Coordinator_SiteUser_ID: number | null;
  Update_ActionOfficer: boolean;
  ActionOfficer_SiteUser_ID: number | null;
  Update_SendToCoordinator: boolean;
  SendToCoordinator: boolean | null;
  Update_SendToActionOfficer: boolean;
  SendToActionOfficer: boolean | null;
  Update_AutomaticAllocation: boolean;
  AutomaticAllocation: boolean | null;
  Update_AllowAnonymous: boolean;
  AllowAnonymous: boolean | null;
  Update_ServiceStandardType: boolean;
  ServiceStandardType: CS_ServiceStandardType | null;
  Update_Confidentiality: boolean;
  Confidentiality: CS_Confidentiality | null;
  Update_AllowRecategorise: boolean;
  AllowRecategorise: boolean | null;
  Update_AssetIsMandatory: boolean;
  AssetIsMandatory: boolean | null;
  Update_QuickClose: boolean;
  QuickClose: boolean | null;
  Update_OnBehalfOf: boolean;
  OnBehalfOf: CS_OnBehalfOfType | null;
  Update_IssueLocationSystemDefaults: boolean;
  IssueLocationSystemDefaults: boolean | null;
  Update_IssueLocationTypes: boolean;
  IssueLocationTypes: string;
  Update_Records_Classification: boolean;
  Records_Classification: string;
  Update_Records_FileNumber: boolean;
  Records_FileNumber: string;
  Update_AdditionalRefNo: boolean;
  AdditionalRefNo: boolean | null;
  Update_AllowReallocateOfficer: boolean;
  AllowReallocateOfficer: boolean | null;
  Update_AssessmentNoIsMandatory: boolean;
  AssessmentNoIsMandatory: boolean | null;
  Update_PromptForLocation: boolean;
  PromptForLocation: boolean | null;
  Update_SetMeAsRequestor: boolean;
  SetMeAsRequestor: boolean | null;
  Update_AddAttachment: boolean;
  AddAttachment: boolean | null;
  Update_ShowMap: boolean;
  ShowMap: boolean | null;
  Update_LockEvent: boolean;
  LockEvent: boolean | null;
  Update_HasAlerts: boolean;
  HasAlerts: boolean | null;
  Update_EventOverdueAlerts: boolean;
  EventOverdueAlerts: boolean | null;
  Update_CustomerEvents: boolean;
  CustomerEvents: boolean | null;
  Update_KnowledgeBase: boolean;
  KnowledgeBase: boolean | null;
  Update_SimpleForm: boolean;
  SimpleForm: boolean | null;
  Update_Inspections: boolean;
  Inspections: boolean | null;
  Update_InternetChoice: boolean;
  InternetChoice: boolean | null;
  Update_System: boolean;
  System: boolean | null;
  Update_AutoActions: boolean;
  AutoActions: boolean | null;
  Update_ControlledSubstance: boolean;
  ControlledSubstance: boolean | null;
  Update_WasteWater: boolean;
  WasteWater: boolean | null;
  Update_AfterHoursAfterHoursSendEmail: boolean;
  AfterHoursAfterHoursSendEmail: boolean | null;
  Update_IssueLocationTypesUseSystemDefaults: boolean;
  IssueLocationTypesUseSystemDefaults: boolean | null;
  Update_SendEmailToRequestorOnSendForAction: boolean;
  SendEmailToRequestorOnSendForAction: boolean | null;
  Update_SendEmailToRequestorOnReSendForAction: boolean;
  SendEmailToRequestorOnReSendForAction: boolean | null;
  Update_SendSMSToRequestorOnSendForAction: boolean;
  SendSMSToRequestorOnSendForAction: boolean | null;
  Update_SendSMSToRequestorOnReSendForAction: boolean;
  SendSMSToRequestorOnReSendForAction: boolean | null;
  Update_SendEmailToOnBehalfOfOnSendForAction: boolean;
  SendEmailToOnBehalfOfOnSendForAction: boolean | null;
  Update_SendEmailToOnBehalfOfOnReSendForAction: boolean;
  SendEmailToOnBehalfOfOnReSendForAction: boolean | null;
  Update_HasSpecialInterest: boolean;
  HasSpecialInterest: boolean | null;
  Update_SpecialInterestEmailRequestorOrgManager: boolean;
  SpecialInterestEmailRequestorOrgManager: boolean | null;
  Update_SpecialInterestEmailRequestorOrgManager_OrgLevel: boolean;
  SpecialInterestEmailRequestorOrgManager_OrgLevel: SpecialInterestRequestorOrgManagerLevel | null;
  Update_SpecialInterestEmailWhenCreated: boolean;
  SpecialInterestEmailWhenCreated: boolean | null;
  Update_SpecialInterestEmailWhenRecategorised: boolean;
  SpecialInterestEmailWhenRecategorised: boolean | null;
  Update_SpecialInterestEmailWhenRescheduled: boolean;
  SpecialInterestEmailWhenRescheduled: boolean | null;
  Update_SpecialInterestEmailWhenOverdue: boolean;
  SpecialInterestEmailWhenOverdue: boolean | null;
  Update_SpecialInterestEmailWhenClosed: boolean;
  SpecialInterestEmailWhenClosed: boolean | null;
  Update_SpecialInterestEmailWhenSentForAction: boolean;
  SpecialInterestEmailWhenSentForAction: boolean | null;
  Update_SpecialInterestEntities: boolean;
  SpecialInterest: AccessControl[];
  Update_SpecialInterestManualEntries: boolean;
  SpecialInterestManualEntries: string;
  Update_TargetDays: boolean;
  TargetDays: boolean | null;
  Update_TargetDays_Or_Hours: boolean;
  TargetDays_Or_Hours: boolean | null;
  Update_UseAcknowledgement: boolean;
  UseAcknowledgement: boolean | null;
  Update_MayBeRescheduled: boolean;
  MayBeRescheduled: boolean | null;
  Update_NotifyCustomerIfRescheduled: boolean;
  NotifyCustomerIfRescheduled: boolean | null;
  Update_FinalNotification: boolean;
  FinalNotification: boolean | null;
  Update_IncludeWeekendsInTargetCalculation: boolean;
  IncludeWeekendsInTargetCalculation: boolean | null;
  Update_ActualCompletionTarget: boolean;
  ActualCompletionTarget: number | null;
  Update_UrgentCompletionTarget: boolean;
  UrgentCompletionTarget: number | null;
  Update_AcknowledgementTarget: boolean;
  AcknowledgementTarget: number | null;
  Update_UrgentAcknowledgementTarget: boolean;
  UrgentAcknowledgementTarget: number | null;
  Update_RescheduleMaxDays: boolean;
  RescheduleMaxDays: number | null;
  Update_EnableInternalClosureComment: boolean;
  EnableInternalClosureComment: boolean | null;
  Update_Readers: boolean;
  Readers: AccessControl[];
  Update_Writers: boolean;
  Writers: AccessControl[];
  Update_EventReaders: boolean;
  EventReaders: AccessControl[];
  Update_EventWriters: boolean;
  EventWriters: AccessControl[];
  Alerts_EnableEscalationAlerts: boolean | null;
  Alerts_Escalation1_Officer_SiteUser_ID: number | null;
  Alerts_Escalation1_Officer: SiteUser;
  Alerts_Escalation1_Days: number | null;
  Alerts_Escalation2_Officer_SiteUser_ID: number | null;
  Alerts_Escalation2_Officer: SiteUser;
  Alerts_Escalation2_Days: number | null;
  Alerts_EnableCouncillorAlerts: boolean | null;
  Alerts_Councillor_RequestSent: boolean | null;
  Alerts_Councillor_RequestClosed: boolean | null;
  Alerts_Councillor_Manager1: boolean | null;
  Alerts_Councillor_Manager2: boolean | null;
  Alerts_Councillor_Manager3: boolean | null;
  Alerts_Councillor_Manager4: boolean | null;
  Alerts_Councillor_Manager5: boolean | null;
  Alerts_Councillor_Other: boolean | null;
  Alerts_Councillor_Other_Emails: string;
  Update_DisplayAsCRMSChoice: boolean;
  DisplayAsCRMSChoice: boolean | null;
  Update_DisplayAsInternetChoice: boolean;
  DisplayAsInternetChoice: boolean | null;
  Update_Asset: boolean;
  Asset: boolean | null;
  Update_ITSupport: boolean;
  ITSupport: boolean | null;
  Flag_HardRubbish: boolean | null;
  Update_Flag_HardRubbish: boolean;
  Update_Animal: boolean;
  Animal: boolean | null;
  Update_Kennel: boolean;
  Kennel: boolean | null;
  Update_Health: boolean;
  Health: boolean | null;
  Update_LocalLaws: boolean;
  LocalLaws: boolean | null;
  Update_TownPlanning: boolean;
  TownPlanning: boolean | null;
  Update_Building: boolean;
  Building: boolean | null;
  Update_ParkingPermit: boolean;
  ParkingPermit: boolean | null;
  Update_Notices: boolean;
  Notices: boolean | null;
  Update_EnableBinsLookup: boolean;
  EnableBinsLookup: boolean | null;
  Update_Payroll: boolean;
  Payroll: boolean | null;
  Update_CollectionDate: boolean;
  CollectionDate: boolean | null;
  Update_EnableSchoolPatrols: boolean;
  EnableSchoolPatrols: boolean | null;
  Update_EnableNuisanceAct: boolean;
  EnableNuisanceAct: boolean | null;
  Update_EnableAnimalsLookUp: boolean;
  EnableAnimalsLookUp: boolean | null;
  Update_Flag_HardRubbishPicker: boolean;
  Flag_HardRubbishPicker: boolean;
  Update_Flag_HardRubbishCollectionItemLookup: boolean;
  Flag_HardRubbishCollectionItemLookup: boolean;
  Update_HardRubbish_CollectionItemUserDefinedValue: boolean;
  HardRubbish_CollectionItemUserDefinedValue: string;
  Update_Flag_HardRubbishAllocationManual: boolean;
  Flag_HardRubbishAllocationManual: boolean;
  Update_HardRubbish_AllocationBusinessDays: boolean;
  HardRubbish_AllocationBusinessDays: number;
  Update_HardRubbish_CollectionsPerYear: boolean;
  HardRubbish_CollectionsPerYear: number;
  Update_Flag_HardRubbishScheduleEventOnSent: boolean;
  Flag_HardRubbishScheduleEventOnSent: boolean;
  Update_HardRubbish_RestrictionsPerMonth: boolean;
  HardRubbish_RestrictionsPerMonth: number;
  Update_HardRubbish_RestrictionsAlert: boolean;
  HardRubbish_RestrictionsAlert: string;
  Update_HardRubbish_MessageItemsOptions: boolean;
  HardRubbish_MessageItemsOptions: string;
  Update_HardRubbish_MessagePrompt: boolean;
  HardRubbish_MessagePrompt: string;
  Update_Records_CategoryCode: boolean;
  Records_CategoryCode: string;
  SaveTriggers: ServiceStandardUpdateTriggers[];
}

export interface StateSvcStandardUpdate {
  gridSelectedIds: number[];
}

export interface ServiceStandardBatchUpdateLovs {
  ServiceStandardType: IKeyValuePacket[];
  Confidentiality: IKeyValuePacket[];
  OnBehalfOf: IKeyValuePacket[];
  IssueLocationTypes: KeyValuePair<number, string>[];
  CaseDetailType: IKeyValuePacket[];
  SpecialInterestEmailRequestorOrgLevel: IKeyValuePacket[];
  MobileIcons: IKeyValuePacket[];
}

export interface ServiceStandardBatchUpdateHandlerRequest {
  ServiceStandardBatchUpdateFormAction: Svc_FormAction_ServiceStandardBatchUpdate;
  ServiceStandardBatchUpdate: ServiceStandardBatchUpdate;
  EventArgs: any;
  IsFirstTimeLoad: boolean;
}

export interface ServiceStandardBatchUpdateHandlerResponse {
  ServiceStandardBatchUpdate: ServiceStandardBatchUpdate;
  UIControl: ServiceStandardBatchUpdateUIControl;
}

export interface ServiceStandardBatchUpdateUIControl {
  DdlIssueLocationTypes: ElementControl;
  FlSendToActionOfficer: ElementControl;
  LblOrgStructure: ElementControl;
  LblCoordinator: ElementControl;
  LblActionOfficer: ElementControl;
  DivRecords: ElementControl;
  DivWasteWater: ElementControl;
  DivControlledSubstance: ElementControl;
  DivWWMS_CSM: ElementControl;
  FlDisplayAsCRMSChoice: ElementControl;
}

export enum Svc_FormAction_ServiceStandardBatchUpdate {
  SystemInitialise,
  PickActionOfficer,
  PickCoordinator,
  PickOrgStructure,
  PickSpecialInterest,
  PickSecurityReader,
  PickSecurityWriter,
  PickSecurityEventReader,
  PickSecurityEventWriter,
  PickEscalationOfficer1,
  PickEscalationOfficer2,
}

export enum eConfirmDeleteDialogName {
  StdReaders = "StdReaders",
  StdWriters = " StdWriters",
  EventReaders = "EventReaders",
  EventWriters = "EventWriters",
}

export interface IConfirmDeleteDialogName {
  dialogType: eConfirmDeleteDialogName;
  isSelectedMuiltiple: boolean;
}
