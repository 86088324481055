import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import {
  formatBooleanValue,
  formatDateByKendo,
  formatIconValue,
  formatNumberByKendo,
} from "@common/utils/formatting";
import { EGroupHeaderProps } from "@components/cc-grid/constant";
import {
  IColumnFields,
  IEditOptions,
  IGridConfig,
  ISelection,
  SelectTypeEnum,
} from "@components/cc-grid/model";
import {
  AggregateDescriptor,
  CompositeFilterDescriptor,
  FilterDescriptor,
  GroupDescriptor,
  SortDescriptor,
  State,
  isCompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import calculateSize from "calculate-size";
import { isNil, unionBy } from "lodash";

export const getFileExportName = (pathName: string): string => {
  let fileName = pathName.replaceAll("/", "_");
  if (fileName[0] === "_") fileName = fileName.slice(1);
  return fileName + ".xlsx";
};
export const pagerMessagesMap = (
  messageKey: string,
  countSelectedRows?: number,
  isShowLabelItemPerPage?: boolean
) => {
  switch (messageKey) {
    case "pager.itemsPerPage":
      return {
        defaultMessage:
          `${isShowLabelItemPerPage ? "items/page" : ""}` +
          (countSelectedRows !== undefined
            ? ` ㅤ ${countSelectedRows} ${
                countSelectedRows > 1 ? "items" : "item"
              } selected`
            : ``),
        messageKey: messageKey,
      };
    case "pager.info":
      return {
        defaultMessage: "{0} - {1} of {2} items",
        messageKey: messageKey,
      };
    case "pager.page":
      return {
        defaultMessage: "page",
        messageKey: messageKey,
      };
    case "pager.of":
      return {
        defaultMessage: "of",
        messageKey: messageKey,
      };
    case "pager.totalPages":
      return {
        defaultMessage: "{0}",
        messageKey: messageKey,
      };
    default:
      return {
        defaultMessage: "",
        messageKey: messageKey,
      };
  }
};
interface IMoveGridRow {
  selectedItem: any;
  gridData: any[];
  direction: "up" | "down";
  primaryField: string;
}
export const moveGridRow = ({
  selectedItem,
  gridData = [],
  direction,
  primaryField,
}: IMoveGridRow): any[] => {
  const selectedIndex = gridData.findIndex(
    (data) => data[primaryField] === selectedItem[primaryField]
  );
  if (selectedIndex === -1) return gridData;
  if (
    (direction === "up" && selectedIndex === 0) ||
    (direction === "down" && selectedIndex === gridData.length - 1)
  )
    return gridData;

  let newGridData = [...gridData];
  const newIndex = direction === "up" ? selectedIndex - 1 : selectedIndex + 1;

  newGridData.splice(newIndex, 0, newGridData.splice(selectedIndex, 1)[0]);
  return newGridData;
};

export const getFilterColumnsName = (
  filterState: FilterDescriptor | CompositeFilterDescriptor | undefined
): string[] => {
  if (!filterState) return [];
  if (!isCompositeFilterDescriptor(filterState) && filterState.field) {
    if (typeof filterState.field === "string") return [filterState.field];
  }
  if (isCompositeFilterDescriptor(filterState) && filterState.filters) {
    let columnsName: string[] = [];
    filterState.filters.forEach(
      (filter: FilterDescriptor | CompositeFilterDescriptor) => {
        let childColumnsName = getFilterColumnsName(filter);
        columnsName = [...columnsName, ...childColumnsName];
      }
    );
    return Array.from(new Set(columnsName));
  }
  return [];
};

export const calculateWidthCol = (
  cols: Array<IColumnFields>,
  values: Array<any>,
  config: IGridConfig
): Array<IColumnFields> => {
  let newColumns: Array<IColumnFields> = cols.map((col: IColumnFields) => {
    let dataMaxLength: number = 0;
    let colFormat: any = col.format;

    if (col.width) {
      return col;
    }

    if (col.format && Object.values(DATE_FORMAT).includes(colFormat))
      dataMaxLength = config.size.components.dateCellWidth;
    if (col.format && Object.values(DATETIME_FORMAT).includes(colFormat))
      dataMaxLength = config.size.components.dateTimeCellWidth;

    if (dataMaxLength === 0) {
      values.forEach((item) => {
        if (item[col.field]) {
          let colDataWidth = calculateSize(
            item[col.field],
            config.fontSetting
          ).width;
          if (dataMaxLength < colDataWidth) dataMaxLength = colDataWidth;
        }
      });
    }
    let headerWidth: number =
      calculateSize(col.title, config.fontSetting).width +
      config.size.components.filterButtonWidth;
    let dataWidth: number = dataMaxLength + config.size.columns.paddingX;
    const colMinWidth = col?.minWidth ?? 0;

    let colWidth = Math.min(
      Math.max(
        headerWidth,
        config.size.columns.minWidth,
        dataWidth,
        colMinWidth
      ),
      config.size.columns.maxWidth
    );

    return { ...col, width: colWidth };
  });

  return newColumns;
};

export const getTotalWidthCols = (
  cols: Array<IColumnFields>,
  gridDataState?: State
) => {
  let totalWidth: number = 0;
  cols.forEach((col) => {
    if (col && col.field !== "" && col.width) {
      if (col.dynamicStyle && gridDataState) {
        const dynamicStyleWidth = col.dynamicStyle?.(gridDataState)?.width;
        const standardWidth = parseInt(col.width + "");
        totalWidth +=
          standardWidth < dynamicStyleWidth ? standardWidth : dynamicStyleWidth;
      } else {
        totalWidth += parseInt(col.width + "");
      }
    }
  });
  return totalWidth;
};

export const reSizeEmptyColumn = (
  columnFields: IColumnFields[],
  gridWidth: number,
  offsetGridWidth: number,
  gridDataState?: State
) => {
  if (columnFields.length) {
    let newGridColField = [...columnFields];
    let totalWidthCols = getTotalWidthCols(newGridColField, gridDataState);

    let emptyColWidth = gridWidth - (totalWidthCols + offsetGridWidth);

    const growColIndex = columnFields.findIndex((col) => col.isGrow);
    if (growColIndex !== -1) {
      const temp = parseInt(newGridColField[growColIndex].width + "");
      newGridColField[growColIndex].width = temp + emptyColWidth;
      return newGridColField;
    }
    const emptyColIndex = newGridColField.findIndex((col) => col.field === "");
    if (
      emptyColIndex !== -1 &&
      emptyColWidth !== columnFields[emptyColIndex].width
    ) {
      emptyColWidth = emptyColWidth < 0 ? 0 : emptyColWidth;
      newGridColField[emptyColIndex].width = emptyColWidth;
      return newGridColField;
    }
    return newGridColField;
  }
  return columnFields;
};

export const getFilterType = (item: IColumnFields) => {
  if (item.filter) return item.filter;
  switch (item.format) {
    case DATE_FORMAT.DATE_CONTROL:
    case DATE_FORMAT.DATE:
    case DATE_FORMAT.MONTH_YEAR:
    case DATETIME_FORMAT.DATETIME_CONTROL:
    case DATETIME_FORMAT.DATETIME:
    case DATE_FORMAT.LONG_DATE:
    case DATETIME_FORMAT.LONG_DATETIME:
    case DATE_FORMAT.DAY_MONTH:
      return "date";

    case NUMBER_FORMAT.NUMBER1:
    case NUMBER_FORMAT.NUMBER2:
    case DECIMAL_FORMAT.DECIMAL1:
    case CURRENCY_FORMAT.CURRENCY2:
    case CURRENCY_FORMAT.CURRENCY1:
    case PERCENTAGE_FORMAT.PERCENTAGE:
    case ICON_FORMAT.ICON_CALL_RETURN:
    case ICON_FORMAT.ICON_HAZARD:
      return "numeric";

    case BOOLEAN_FORMAT.BOOLEAN:
    case ICON_FORMAT.ICON_BOOLEAN:
    case ICON_FORMAT.ICON_BOOLEAN_WITHOUT_CROSS:
    case ICON_FORMAT.ICON_URGENT:
      return "boolean";

    default:
      return "text";
  }
};

interface ITransformDataConfig {
  selectedField: string;
  expandField: string;
  primaryField: string;
  groupField: string;
  editField: string;
}

export const transformData: any = (
  config: ITransformDataConfig,
  dataShow: any[],
  selection: ISelection,
  expandedRowIds: any[],
  expandedGroupIds: any[],
  editOptions: IEditOptions,
  groupState?: Array<GroupDescriptor>,
  parentGroupId?: string
) => {
  const { selectedField, expandField, primaryField, groupField, editField } =
    config;
  if (!groupState || groupState.length === 0) {
    return dataShow.map((data) => {
      const dataId = data[primaryField];
      return {
        ...data,
        [selectedField]:
          selection.selectType === SelectTypeEnum.PAGE
            ? selection.selectedRowsIds.includes(dataId)
            : !selection.excludedRowsIds.includes(dataId),
        [expandField]: expandedRowIds.includes(dataId),
        [editField]: editOptions.inEditRowIds.includes(dataId)
          ? editOptions.fields
          : undefined,
      };
    });
  }
  let newGroupState = [...groupState];
  newGroupState.shift();
  return dataShow.map((data, index) => {
    const currentGroupId = parentGroupId
      ? parentGroupId + data.field + index
      : data.field + index;
    return {
      ...data,
      [groupField]: currentGroupId,
      [expandField]: expandedGroupIds.includes(currentGroupId),
      items: transformData(
        config,
        data.items || [],
        selection,
        expandedRowIds,
        expandedGroupIds,
        editOptions,
        newGroupState,
        currentGroupId
      ),
    };
  });
};

export const formatDisplayValue = (format: any, value: any) => {
  if (
    Object.values(DATE_FORMAT).includes(format) ||
    Object.values(DATETIME_FORMAT).includes(format)
  ) {
    return formatDateByKendo(value, format);
  }

  if (Object.values(NUMBER_FORMAT).includes(format)) {
    return formatNumberByKendo(value, format);
  }

  if (Object.values(CURRENCY_FORMAT).includes(format)) {
    return formatNumberByKendo(value, format);
  }

  if (Object.values(PERCENTAGE_FORMAT).includes(format)) {
    return formatNumberByKendo(value, format);
  }

  if (Object.values(ICON_FORMAT).includes(format)) {
    return formatIconValue(value, format);
  }

  if (Object.values(BOOLEAN_FORMAT).includes(format)) {
    return formatBooleanValue(value, format);
  }
  return value;
};

export const getFirstRowInGroup = (
  dataShow: any,
  groupState?: Array<GroupDescriptor>
): any => {
  if (!groupState || groupState.length === 0) {
    return dataShow?.[0] || dataShow?.items?.[0];
  }
  let newGroupState = [...groupState];
  newGroupState.shift();
  if (!dataShow?.[0] && !dataShow?.items?.[0]) return undefined;
  return getFirstRowInGroup(
    dataShow?.[0] || dataShow?.items?.[0],
    newGroupState
  );
};

export const notYetAggregation = (groupList: GroupDescriptor[]) => {
  return groupList?.some(
    (groupItem: GroupDescriptor) => !groupItem?.aggregates
  );
};

export const getGroupedListWithAggregate = (
  primaryField: string,
  groupList: GroupDescriptor[]
) => {
  const newGroupList = [...groupList];
  const aggregates: AggregateDescriptor[] = [
    { field: primaryField, aggregate: "count" },
  ];
  newGroupList.forEach(
    (group: GroupDescriptor) => (group.aggregates = aggregates)
  );
  return newGroupList;
};

export const getSortedListAfterGroup = (
  groupedList: GroupDescriptor[],
  sortList: SortDescriptor[]
) => {
  const sortListFromGroup = groupedList.map((groupItem: GroupDescriptor) => {
    return {
      field: groupItem.field,
      dir: "asc",
    } as SortDescriptor;
  });
  //Concat two sort list and remove duplicate item by field
  const newSortList = unionBy(sortListFromGroup, sortList, "field");
  return newSortList;
};

export const getSortedListAfterUnGroup = (
  unGroupedList: GroupDescriptor[],
  sortList: SortDescriptor[],
  groupList: GroupDescriptor[]
) => {
  let newSortList = [...sortList];
  unGroupedList.forEach((unGroupedItem: GroupDescriptor) => {
    const anotherUnGroupItem = groupList.find(
      (groupItem: GroupDescriptor) => groupItem.field === unGroupedItem.field
    );
    //Remove ungrouped item in sort list if we don't have another ungrouped item in group list
    if (isNil(anotherUnGroupItem)) {
      newSortList = newSortList.filter(
        (item: SortDescriptor) => item.field !== unGroupedItem.field
      );
    }
  });
  return newSortList;
};

export const isGroupHeader = (dataItem: any) => {
  return (
    dataItem?.hasOwnProperty(EGroupHeaderProps.AGGREGATES) &&
    !isNil(dataItem?.[EGroupHeaderProps.GROUP_ID])
  );
};
