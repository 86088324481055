import { Address } from "@app/products/waste-water/[id]/model";
import { AddressBookSection } from "@common/constants/enumerations";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import {
  DBRowState,
  DTOBaseClass_Standard,
} from "@common/models/baseClassStandard";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export enum ContactSubmitActions {
  Save = "Save",
  New = "New",
}

export interface IContactParentSection {
  notification: IAppNotificationItemAddProps[];
}

export interface ContactLovs {
  AddressBooks: IKeyValuePacket[];
  Classifications: IKeyValuePacket[];
  PreferredMethods: IKeyValuePacket[];
  PersonSalutations: IKeyValuePacket[];
  IncBodyTypes: IKeyValuePacket[];
  VIPTypes: IKeyValuePacket[];
  GenderPronouns: IKeyValuePacket[];
  Aboriginal: IKeyValuePacket[];
  HasCommunityGroup: IKeyValuePacket[];
  CommunityGroup: IKeyValuePacket[];
}

export interface Svc_Contact extends DTOBaseClass_Standard {
  Contact_Id: number;
  ContactClassification_ENUM: ContactClassification;
  ContactClassification: string;
  ContactClassification_Int: number;
  DisplayName: string;
  Person_FirstName: string;
  Person_LastName: string;
  Person_MiddleName: string;
  Person_Salutation: string;
  Person_GenderPronoun: string;
  Company_TradingName: string;
  Company_ContactPerson: string;
  IncorporatedBody_Name: string;
  IncorporatedBodyType_KWD: number | null;
  ContactAlert: string;
  Contact_Alerts: string[];
  ContactComments: string;
  Qualifications: string;
  Custom_Tags: string;
  Partnership_Name: string;
  Partnership1_Rid: number | null;
  Partnership1_Contact_Id: number | null;
  Partner1_Name: string;
  Partnership2_Rid: number | null;
  Partnership2_Contact_Id: number | null;
  Partner2_Name: string;
  Partnership3_Rid: number | null;
  Partnership3_Contact_Id: number | null;
  Partner3_Name: string;
  Partnership4_Rid: number | null;
  Partnership4_Contact_Id: number | null;
  Partner4_Name: string;
  WorkPhone: string;
  HomePhone: string;
  Mobile: string;
  Fax: string;
  Email: string;
  Website: string;
  PreferredMethodOfContact_ENUM: ContactMethodPreferred;
  PreferredMethodOfContact: string;
  PreferredMethodOfContact_Int: number;
  Flag_SendSMSAlerts: boolean;
  ABN: string;
  ABNValid: boolean;
  ACNValid: boolean;
  ACN: string;
  NARNumber: string;
  NARUpdated: boolean | null;
  VIP: boolean | null;
  VIPType_ID: number | null;
  VIPType: string;
  VIPRelationshipOwner_ID: number | null;
  VIPRelationshipOwner: string;
  PreferredAddress: Address;
  PostalAddress: Address;
  AlternateAddress: Address;
  Queued_Sys_DBRowState: DBRowState | null;
  AddressBook: AddressBook;
  AddressBookFlag_ENUM: Contact_AddressBookFlag;
  AddressBookFlag_Int: number;
  AddressBookSection_ENUM: AddressBookSection | null;
  AddressBookSection_Int: number | null;
  ExtensionTypes: ContactExtension[];
  IsAdministrativeAccount: boolean | null;
  GoldenRecord: boolean;
  Extension_JobTitle: string;
  Extension_Organisation: string;
  Extension_DateOfBirth: string | null;
  Extension_VerificationQuestion: string;
  Extension_VerificationResponse: string;
  Extension_FSSCertificate: string;
  Extension_FSSCertificateExpiryDate: string | null;
  Extension_FSSCertificateNo: string;
  Extension_PlumberLicNo: string;
  Extension_Attention: string;
  Extension_ShowAttention: boolean | null;
  Extension_Language_ID: number | null;
  Extension_Language: string;
  Extension_SecondLanguage: string;
  Extension_DisabilityType_KWD: number | null;
  Extension_DisabilityDetails: string;
  Extension_TTY: string;
  Extension_Prisoner: boolean | null;
  Extension_CRNNumber: string;
  Extension_AboriginalStatus_KWD: number | null;
  Extension_CM_Deceased: boolean | null;
  Extension_CM_DatePassedAway: string | null;
  Extension_Disability_Categories: number[];
  Extension_Disability_SubCategories: number[];
  Extension_LanguageAtHome: boolean | null;
  Extension_LanguageAtHome_KWD: number | null;
  Extension_Officer_Ticket_Counter: number | null;
  SaveTriggers: ContactUpdateTriggers[];
  Aboriginal?: number;
  HasCommunityGroup?: number;
  Community_Group_Ids: number[];
}

export enum ContactClassification {
  SystemInitialise = 0,
  Contact = 1,
  SiteUser = 2,
  Person = 3,
  Company = 4,
  Partnership = 5,
  IncorporatedBody = 6,
  PortalUser = 7,
  PrivateInspector = 8,
  CRS_Parent = 10,
  CRS_Child = 11,
  ProprietorBus_Individual = 15,
  ProprietorBus_Company = 16,
  ProprietorBus_Partnership = 17,
  ProprietorComm_NotForProfit = 18,
  ProprietorComm_IndividualForCharity = 19,
  ProprietorComm_BodyForCharity = 20,
  VCATBoardRepresentative = 21,
}

export enum Contact_AddressBookFlag {
  GenericContact = 0,
  GenericCopy = 1,
  AddressBookCopy = 2,
  AddressBookReference = 3,
}

export enum ContactExtension {
  SystemInitialise = 0,
  HasJobTitle = 1,
  HasOrganisation = 2,
  HasVerificationQuestion = 4,
  HasVerificationResponse = 8,
  HasDateOfBirth = 16,
  HasPortalUserDetails = 32,
  HasLicenceNo = 64,
  HasFSSCertificate = 128,
  HasDebtorNo = 256,
  HasCMPlaceOfBirth = 512,
  HasCMContactable = 1024,
  HasCMDeceased = 2048,
  HasCMLastKnownAddress = 4096,
  HasCMDatePassedAway = 8192,
  HasGender = 16384,
  HasContactAttention = 32768,
  HasAgeAtDeath = 65536,
  HasLanguage = 131072,
  HasSecondLanguage = 262144,
  HasDWCMSAdditionalDetails = 524288,
}
export enum ContactMethodPreferred {
  None = 0,
  WorkPhone = 1,
  HomePhone = 2,
  Email = 3,
  Fax = 4,
  Mobile = 5,
  Post = 6,
  EmailAndPost = 7,
  TTY = 8,
}

export enum ContactUpdateTriggers {
  UpdatePrimaryAddress = "UpdatePrimaryAddress",
  ClearPrimaryAddress = "ClearPrimaryAddress",
  UpdateSecondaryAddress = "UpdateSecondaryAddress",
  ClearSecondaryAddress = "ClearSecondaryAddress",
  UpdateOtherAddress = "UpdateOtherAddress",
  ClearOtherAddress = "ClearOtherAddress",
  UpdateGroups = "UpdateGroups",
  LoginProviders = "LoginProviders",
  Products = "Products",
  SecurityTemplates = "SecurityTemplates",
  UpdateContactAddressTypeFirst = "UpdateContactAddressTypeFirst",
  UpdateContactAddressTypeSecond = "UpdateContactAddressTypeSecond",
  UpdateContactAddressTypeOther = "UpdateContactAddressTypeOther",
  UpdatePartner1 = "UpdatePartner1",
  UpdatePartner2 = "UpdatePartner2",
  UpdatePartner3 = "UpdatePartner3",
  UpdatePartner4 = "UpdatePartner4",
  RemovePartner1 = "RemovePartner1",
  RemovePartner2 = "RemovePartner2",
  RemovePartner3 = "RemovePartner3",
  RemovePartner4 = "RemovePartner4",
  RearrangePartner = "RearrangePartner",
  UpdateCMLastKnownAddress = "UpdateCMLastKnownAddress",
  ClearCMLastKnownAddress = "ClearCMLastKnownAddress",
  UpdateVIPRelatioshipOwner = "UpdateVIPRelatioshipOwner",
  SwitchPrimaryAddress = "SwitchPrimaryAddress",
  SwitchSecondaryAddress = "SwitchSecondaryAddress",
  SwitchOtherAddress = "SwitchOtherAddress",
  UpdateCommunityGroup = "UpdateCommunityGroup",
}

export interface AddressBook {
  Sys_EnterpriseKey: string;
  Sys_TransactionDate: Date;
  Sys_TimeStamp: string;
  AddressBook_ID: number;
  AddressBook_Name: string;
  AddressBookSection_ENUM: AddressBookSection;
  AddressBookSection_Name: string;
  ProductType_ENUM: PRODUCT_TYPE_NUMBER;
  ProductType_Name: string;
  IsSystemRecord: boolean;
  ExtensionType: ContactExtension[];
}
