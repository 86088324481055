import { ImportValuationsDialog } from "@app/products/property/supplementary-rates/import-valuations/components/action-bar/dialogs/import-valuations/_index";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ImportValuationsButton = observer(() => {
  const [isShowImportValuationsDialog, setIsShowImportValuationsDialog] =
    useState<boolean>(false);
  const { currentOrganisationMode } = useCommonProductStore();
  const isLLS = currentOrganisationMode(OrganisationMode.LLS);

  return !isLLS ? (
    <>
      <CCNavButton
        title="Import valuations"
        onClick={() => setIsShowImportValuationsDialog(true)}
      />
      {isShowImportValuationsDialog && (
        <ImportValuationsDialog
          onClose={() => {
            setIsShowImportValuationsDialog(false);
          }}
        />
      )}
    </>
  ) : null;
});
