import { IOfficer } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { OfficerPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/_index";
import { useIsNew } from "@common/hooks/useIsNew";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import {
  OrgStructureLevel,
  OrgUnit,
  OrgUnitUpdateTriggers,
  SecurityOrgStructureSubmitActions,
} from "@common/pages/settings/security/org-structure/_id/model";
import { useOrgStructureStore } from "@common/pages/settings/security/org-structure/_id/store";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import "@styles/index.scss";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
interface ISecurityOrgStructureFormElementProps {
  formRenderProps: FormRenderProps;
  handleOnSubmit: any; // (event: FormSubmitClickEvent) => string | undefined;
}

const nameOf = nameOfFactory<OrgUnit>();

export const SecurityOrgStructureFormElement = observer(
  ({
    formRenderProps,
    handleOnSubmit,
  }: ISecurityOrgStructureFormElementProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const isNew = useIsNew();
    const { settings } = useCommonCoreStore();
    const { loadLevel, level1, level2, level3, level4, level5, onSubmit } =
      useOrgStructureStore();
    const { pushNotification } = useCCAppNotificationStore();

    const [showLevel2, setShowLevel2] = useState(false);
    const [showLevel3, setShowLevel3] = useState(false);
    const [showLevel4, setShowLevel4] = useState(false);
    const [showLevel5, setShowLevel5] = useState(false);

    const orgLevel =
      getNumberValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_OrgStructureLevels]
      ) ?? 5;

    const level1Title =
      getStringValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_LabelOrg1]
      ) ?? "Level 1";
    const level2Title =
      getStringValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_LabelOrg2]
      ) ?? "Level 2";
    const level3Title =
      getStringValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_LabelOrg3]
      ) ?? "Level 3";
    const level4Title =
      getStringValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_LabelOrg4]
      ) ?? "Level 4";
    const level5Title =
      getStringValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_LabelOrg5]
      ) ?? "Level 5";

    const setShowLevel = (level: OrgStructureLevel, show: boolean) => {
      switch (level) {
        case OrgStructureLevel.Level2:
          setShowLevel2(show);
          break;
        case OrgStructureLevel.Level3:
          setShowLevel3(show);
          break;
        case OrgStructureLevel.Level4:
          setShowLevel4(show);
          break;
        case OrgStructureLevel.Level5:
          setShowLevel5(show);
          break;
      }
    };

    const handleOnchangeLevel = (event: ComboBoxChangeEvent, level: number) => {
      onChange(`Level${level}_OrgUnitID`, {
        value: event.target.value?.Key ?? null,
      });
      onChange(nameOf("Parent_Index"), {
        value: event.target.value?.Key ?? null,
      });
    };

    const handleOnClickSelect = (level: number) => {
      if (isNil(valueGetter(`Level${level}_OrgUnitID`))) {
        pushNotification({
          type: "warning",
          title: "Pick Manager Errors",
          description: `Org Unit for level ${level} must be filled`,
        });
      } else {
        loadLevel(level + 1, valueGetter(`Level${level}_OrgUnitID`));
        setShowLevel(level + 1, true);
        onChange(nameOf("Parent_Index"), {
          value: valueGetter(`Level${level}_OrgUnitID`),
        });
      }
    };

    const handleOnChangeManager = (manager: IOfficer | null) => {
      onChange(nameOf("Manager_Name"), {
        value: manager?.Name,
      });
      onChange(nameOf("Manager_ID"), {
        value: manager?.ID,
      });
    };

    const handleOnChangeUsers = (users: IOfficer[] | null) => {
      const a = users?.map((item) => {
        return { Key: item?.ID, Value: item?.Name ?? "" } ?? [];
      });
      onChange(nameOf("Users"), {
        value: a,
      });
      onChange(nameOf("_SaveTriggers"), {
        value: [OrgUnitUpdateTriggers.UpdateUsers],
      });
    };

    return (
      <FormElement className="cc-keyword-form-elements">
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Org unit name" isMandatory={true}></CCLabel>
              <Field
                name={nameOf("OrgUnit_Name")}
                component={CCInput}
                placeholder={"Org Unit Name"}
                validator={requiredValidator}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            {!isNew && !valueGetter(nameOf("AdhocGroup")) && (
              <div className="cc-field">
                <label className="cc-label">Current hierarchy</label>

                <label className="cc-label">
                  {valueGetter(nameOf("Hierarchy"))}
                </label>
              </div>
            )}
            <div className="cc-field">
              <label className="cc-label">Manager</label>
              <Field
                name={nameOf("Manager_Name")}
                component={OfficerPicker}
                title={"Contact Manager"}
                placeholder={"Manager"}
                showClearButton
                onPickOfficer={handleOnChangeManager}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Phone</label>
              <Field
                name={nameOf("Phone")}
                component={CCInput}
                placeholder={"Phone"}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Users</label>
              <Field
                name={nameOf("Users")}
                component={OfficerPicker}
                title={"Contact Manager"}
                placeholder={"Users"}
                showClearButton
                selectableMode={"multiple"}
                textProduce={(data: IKeyValuePacket[]) => {
                  return data
                    .reduce((pre, cur) => pre + ", " + cur.Value, "")
                    .slice(1)
                    .trim();
                }}
                onPickOfficer={handleOnChangeUsers}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          {isNew && level1 && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">{level1Title}</label>
                <Field
                  name={nameOf("Level1_OrgUnitID")}
                  dataItemKey={"Key"}
                  textField={"Value"}
                  component={CCSearchComboBox}
                  data={level1 ?? []}
                  disabled={showLevel2}
                  value={getDropdownValue(
                    valueGetter(nameOf("Level1_OrgUnitID")) ?? "",
                    level1
                  )}
                  onChange={(event: ComboBoxChangeEvent) =>
                    handleOnchangeLevel(event, OrgStructureLevel.Level1)
                  }
                />
              </div>
              <div className="cc-form-cols-3 cc-custom-grid-cols-1-2-2">
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Button
                    className={"cc-dialog-button"}
                    type={"button"}
                    disabled={
                      orgLevel <= OrgStructureLevel.Level1 ? true : showLevel2
                    }
                    onClick={() =>
                      handleOnClickSelect(OrgStructureLevel.Level1)
                    }
                  >
                    Select
                  </Button>
                </div>
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Button
                    className={"cc-dialog-button"}
                    type={"button"}
                    name={SecurityOrgStructureSubmitActions.AddThisOrgUnit}
                    disabled={showLevel2}
                    onClick={onSubmit}
                  >
                    Add to this org unit
                  </Button>
                </div>
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Button
                    className={"cc-dialog-button"}
                    type={"button"}
                    disabled={true}
                  >
                    Go back one level
                  </Button>
                </div>
              </div>
            </div>
          )}
          {showLevel2 && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">{level2Title}</label>
                <Field
                  name={nameOf("Level2_OrgUnitID")}
                  dataItemKey={"Key"}
                  textField={"Value"}
                  component={CCSearchComboBox}
                  data={level2 ?? []}
                  value={getDropdownValue(
                    valueGetter(nameOf("Level2_OrgUnitID")) ?? "",
                    level2
                  )}
                  onChange={(event: ComboBoxChangeEvent) =>
                    handleOnchangeLevel(event, OrgStructureLevel.Level2)
                  }
                />
              </div>
              <div className="cc-form-cols-3 cc-custom-grid-cols-1-2-2">
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Button
                    className={"cc-dialog-button"}
                    type={"button"}
                    disabled={
                      orgLevel <= OrgStructureLevel.Level2 ? true : showLevel3
                    }
                    onClick={() =>
                      handleOnClickSelect(OrgStructureLevel.Level2)
                    }
                  >
                    Select
                  </Button>
                </div>
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Button
                    className={"cc-dialog-button"}
                    type={"button"}
                    name={SecurityOrgStructureSubmitActions.AddThisOrgUnit}
                    disabled={showLevel3}
                    onClick={onSubmit}
                  >
                    Add to this org unit
                  </Button>
                </div>
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Button
                    className={"cc-dialog-button"}
                    type={"button"}
                    disabled={showLevel3}
                    onClick={() =>
                      setShowLevel(OrgStructureLevel.Level2, false)
                    }
                  >
                    Go back one level
                  </Button>
                </div>
              </div>
            </div>
          )}
          {showLevel3 && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">{level3Title}</label>
                <Field
                  name={nameOf("Level3_OrgUnitID")}
                  dataItemKey={"Key"}
                  textField={"Value"}
                  component={CCSearchComboBox}
                  data={level3 ?? []}
                  value={getDropdownValue(
                    valueGetter(nameOf("Level3_OrgUnitID")) ?? "",
                    level3
                  )}
                  onChange={(event: ComboBoxChangeEvent) =>
                    handleOnchangeLevel(event, OrgStructureLevel.Level3)
                  }
                />
              </div>
              <div className="cc-form-cols-3 cc-custom-grid-cols-1-2-2">
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Button
                    className={"cc-dialog-button"}
                    type={"button"}
                    disabled={
                      orgLevel <= OrgStructureLevel.Level3 ? true : showLevel4
                    }
                    onClick={() =>
                      handleOnClickSelect(OrgStructureLevel.Level3)
                    }
                  >
                    Select
                  </Button>
                </div>
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Button
                    className={"cc-dialog-button"}
                    type={"button"}
                    name={SecurityOrgStructureSubmitActions.AddThisOrgUnit}
                    disabled={showLevel4}
                    onClick={onSubmit}
                  >
                    Add to this org unit
                  </Button>
                </div>
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Button
                    className={"cc-dialog-button"}
                    type={"button"}
                    disabled={showLevel4}
                    onClick={() =>
                      setShowLevel(OrgStructureLevel.Level3, false)
                    }
                  >
                    Go back one level
                  </Button>
                </div>
              </div>
            </div>
          )}
          {showLevel4 && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">{level4Title}</label>
                <Field
                  name={nameOf("Level4_OrgUnitID")}
                  dataItemKey={"Key"}
                  textField={"Value"}
                  component={CCSearchComboBox}
                  data={level4 ?? []}
                  value={getDropdownValue(
                    valueGetter(nameOf("Level4_OrgUnitID")) ?? "",
                    level4
                  )}
                  onChange={(event: ComboBoxChangeEvent) =>
                    handleOnchangeLevel(event, OrgStructureLevel.Level4)
                  }
                />
              </div>
              <div className="cc-form-cols-3 cc-custom-grid-cols-1-2-2">
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Button
                    className={"cc-dialog-button"}
                    type={"button"}
                    disabled={
                      orgLevel <= OrgStructureLevel.Level4 ? true : showLevel5
                    }
                    onClick={() =>
                      handleOnClickSelect(OrgStructureLevel.Level4)
                    }
                  >
                    Select
                  </Button>
                </div>
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Button
                    className={"cc-dialog-button"}
                    type={"button"}
                    name={SecurityOrgStructureSubmitActions.AddThisOrgUnit}
                    disabled={showLevel5}
                    onClick={onSubmit}
                  >
                    Add to this org unit
                  </Button>
                </div>
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Button
                    className={"cc-dialog-button"}
                    type={"button"}
                    disabled={showLevel5}
                    onClick={() =>
                      setShowLevel(OrgStructureLevel.Level4, false)
                    }
                  >
                    Go back one level
                  </Button>
                </div>
              </div>
            </div>
          )}
          {showLevel5 && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">{level5Title}</label>
                <Field
                  name={nameOf("Level5_OrgUnitID")}
                  dataItemKey={"Key"}
                  textField={"Value"}
                  component={CCSearchComboBox}
                  data={level5 ?? []}
                  value={getDropdownValue(
                    valueGetter(nameOf("Level5_OrgUnitID")) ?? "",
                    level5
                  )}
                  onChange={(event: ComboBoxChangeEvent) =>
                    handleOnchangeLevel(event, OrgStructureLevel.Level5)
                  }
                />
              </div>
              <div className="cc-form-cols-3 cc-custom-grid-cols-1-2-2">
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Button
                    className={"cc-dialog-button"}
                    type={"button"}
                    disabled={
                      orgLevel <= OrgStructureLevel.Level5 ? true : showLevel2
                    }
                    onClick={() =>
                      handleOnClickSelect(OrgStructureLevel.Level5)
                    }
                  >
                    Select
                  </Button>
                </div>
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Button
                    className={"cc-dialog-button"}
                    type={"button"}
                    name={SecurityOrgStructureSubmitActions.AddThisOrgUnit}
                    onClick={onSubmit}
                  >
                    Add to this org unit
                  </Button>
                </div>
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Button
                    className={"cc-dialog-button"}
                    type={"button"}
                    onClick={() =>
                      setShowLevel(OrgStructureLevel.Level5, false)
                    }
                  >
                    Go back one level
                  </Button>
                </div>
              </div>
            </div>
          )}
        </section>
      </FormElement>
    );
  }
);
