import { useCRMSPhoneMessageStore } from "@app/products/crms/phone-messages/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";

export const NewMessageButton = () => {
  const { setIsShowNewPhoneMsgDialog, loadCRMSPhoneMessage } =
    useCRMSPhoneMessageStore();

  return (
    <React.Fragment>
      <CCNavButton
        title="New Message"
        onClick={() => {
          loadCRMSPhoneMessage(0, true);
          setIsShowNewPhoneMsgDialog(true);
        }}
      />
    </React.Fragment>
  );
};
