export interface IWriteOffSmallBalances {
  Prompt: string;
  Value?: string | number | null;
  Variable_Name: string;
  SQL_Data_Type: string;
  Variable_Id: number;
}

export enum eSqlDataType {
  VARCHAR = "varchar(100)",
  MONEY = "Money",
  BIT = "BIT",
  INT = "Int",
}
