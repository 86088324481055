import {
  searchContacts,
  searchSiteUser,
} from "@app/core/communication/dialogs/components/form-elememts/to/components/add-recipient/api";
import { AddRecipientByFillInfo } from "@app/core/communication/dialogs/components/form-elememts/to/components/add-recipient/components/add-recipient-by-fill-info/_index";
import {
  colRecipientContact,
  colRecipientResult,
  colRecipientSiteUser,
  configGrid as configGridStyle,
  dataSearchByRecipientCommunication,
  optionsSearchField,
} from "@app/core/communication/dialogs/components/form-elememts/to/components/add-recipient/config";
import {
  searchByType,
  searchColumnType,
} from "@app/core/communication/dialogs/components/form-elememts/to/components/add-recipient/model";
import { CORE_GET_VIEW_SITEUSER_ODATA } from "@app/core/components/common/constant";

import { CONTACT_ACTIVE_ODATA } from "@app/core/contacts/active/_filter/constant";
import { useDebounce } from "@common/hooks/useDebounce";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCInput } from "@components/cc-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, Form, FormRenderProps } from "@progress/kendo-react-form";
import {
  InputChangeEvent,
  RadioGroup,
  RadioGroupChangeEvent,
} from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

interface IAddRecipientProps {
  setIsShowDialogAddRecipient: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddRecipient:
    | ((
        values: {
          [name: string]: any;
        },
        event?: React.SyntheticEvent<any, Event> | undefined
      ) => void)
    | undefined;
}

export const AddRecipient = observer(
  ({ setIsShowDialogAddRecipient, handleAddRecipient }: IAddRecipientProps) => {
    const [columnFields, setColumnFields] =
      useState<IColumnFields[]>(colRecipientSiteUser);
    const [dataUrl, setDataUrl] = useState(CORE_GET_VIEW_SITEUSER_ODATA);
    const [primaryField, setPrimaryField] = useState("ID");

    const [searchBy, setSearchBy] = useState(1);
    const [searchColumn, setSearchColumn] = useState(1);

    const [recipientData, setRecipientData] = useState<any>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [searchKey, setSearchKey] = useState("");
    const [resultsSearch, setResultsSearch] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const debouncedSearch = useDebounce(searchKey, 500);
    const [
      isShowDialogAddRecipientByFillInfo,
      setIsShowDialogAddRecipientByFillInfo,
    ] = useState(false);
    const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
      []
    );

    useEffect(() => {
      (async () => {
        // Site User
        if (searchBy === searchByType.SiteUser) {
          setColumnFields(colRecipientSiteUser);
          setPrimaryField("ID");
          if (!debouncedSearch) {
            // Load All List
            setDataUrl(CORE_GET_VIEW_SITEUSER_ODATA);
          } else {
            setIsSearching(true);
            // Search All
            if (searchColumn === searchColumnType.All) {
              const response = await searchSiteUser(
                debouncedSearch,
                searchColumn
              );
              setResultsSearch(response.data.value || []);
            }
            // Search Name only
            if (searchColumn === searchColumnType.NameOnly) {
              const response = await searchSiteUser(
                debouncedSearch,
                searchColumn
              );
              setResultsSearch(response.data.value || []);
            }
            setIsSearching(false);
          }
        }

        // Contact
        if (searchBy === searchByType.Contact) {
          setColumnFields(colRecipientContact);
          setPrimaryField("Id");
          if (!debouncedSearch) {
            // Load All List
            setDataUrl(CONTACT_ACTIVE_ODATA);
          } else {
            setIsSearching(true);
            // Search All
            if (searchColumn === searchColumnType.All) {
              const response = await searchContacts(
                debouncedSearch,
                searchColumn
              );
              setResultsSearch(response.data.value || []);
            }
            // Search Name only
            if (searchColumn === searchColumnType.NameOnly) {
              const response = await searchContacts(
                debouncedSearch,
                searchColumn
              );
              setResultsSearch(response.data.value || []);
            }
            setIsSearching(false);
          }
        }
      })();
    }, [debouncedSearch, searchBy, searchColumn]);

    useEffect(() => {
      setResultsSearch([]);
    }, [debouncedSearch]);

    return (
      <Form
        onSubmit={handleAddRecipient}
        render={({ onSubmit, onChange, valueGetter }: FormRenderProps) => {
          // Add data grid1 to grid2
          const handleFillData = (data: any[]) => {
            const dataFill = data[0];
            const dynamicId =
              searchBy === searchByType.SiteUser ? dataFill?.ID : dataFill?.Id;
            const objRecipient = {
              ID: dynamicId,
              DisplayName: dataFill?.DisplayName,
              Email: dataFill?.Email,
              Mobile: dataFill?.Mobile,
            };
            const recipient = valueGetter("recipientResult") || [];
            if (recipient.length) {
              const recipientDuplicate = recipient.find(
                (v: any) => v.ID === dynamicId
              );
              if (recipientDuplicate) {
                setNotifications([
                  {
                    id: "1",
                    title: `Duplicate recipients: ${recipientDuplicate.DisplayName}`,
                    type: "warning",
                  },
                ]);
              } else {
                setRecipientData([...recipient, objRecipient]);
                onChange("recipientResult", {
                  value: [...recipient, objRecipient],
                });
              }
            } else {
              setRecipientData([objRecipient]);
              onChange("recipientResult", {
                value: [objRecipient],
              });
            }
          };

          // Add data user fill to grid2
          const handleAddRecipientByFillInfo = (data: any) => {
            const recipientDataAdd = Object.assign(data, {
              ID:
                "0" +
                Math.floor(
                  Math.random() * 999999999 + new Date().getUTCMilliseconds()
                ),
            });
            const recipientsNew = [...recipientData, recipientDataAdd];
            setRecipientData(recipientsNew);
            onChange("recipientResult", {
              value: recipientsNew,
            });
            setIsShowDialogAddRecipientByFillInfo(false);
          };

          // Remove recipient grid2
          const handleRemoveRecipient = () => {
            const recipient = valueGetter("recipientResult") || [];
            let idsSelected = selectedRows.map((v: any) => {
              return v.ID;
            });

            const recipientNew = recipient.filter(
              (item: any) => !idsSelected.includes(item.ID)
            );
            setRecipientData([...recipientNew]);
            onChange("recipientResult", {
              value: recipientNew,
            });
            setSelectedRows([]);
          };

          const handleSelectionChangeRecipient = (e: any[]) => {
            setSelectedRows(e);
          };

          return (
            <>
              <CCDialog
                height="auto"
                width="45%"
                titleHeader={"Add Recipient"}
                onClose={() => setIsShowDialogAddRecipient(false)}
                bodyElement={
                  <div className="cc-form">
                    <div className="cc-field-group cc-add-recipient-dialog">
                      <div className="cc-form-cols-3">
                        <div className="cc-field">
                          <label className="cc-label">Search</label>
                          <Field
                            name={"searchKey"}
                            component={CCInput}
                            placeholder="Search"
                            onChange={(e: InputChangeEvent) => {
                              setSearchKey(e.value);
                            }}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Search by</label>
                          <Field
                            name={"searchBy"}
                            component={CCDropDownList}
                            data={dataSearchByRecipientCommunication}
                            textField="Value"
                            dataItemKey="Key"
                            defaultValue={{
                              Key: 1,
                              Value: "Site User",
                            }}
                            onChange={(e: DropDownListChangeEvent) =>
                              setSearchBy(e.value.Key)
                            }
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Search columns</label>
                          <Field
                            name={"searchColumn"}
                            component={RadioGroup}
                            data={optionsSearchField}
                            layout="horizontal"
                            defaultValue={1}
                            onChange={(e: RadioGroupChangeEvent) =>
                              setSearchColumn(e.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-1">
                        {searchKey ? (
                          <CCGrid
                            columnFields={columnFields}
                            primaryField={primaryField}
                            onSelectionChange={handleFillData}
                            data={resultsSearch}
                            isLoading={isSearching}
                            style={configGridStyle}
                            isAutoHiddenPager={false}
                          />
                        ) : (
                          <CCGrid
                            columnFields={columnFields}
                            primaryField={primaryField}
                            onSelectionChange={handleFillData}
                            dataUrl={dataUrl}
                            style={configGridStyle}
                            isAutoHiddenPager={false}
                          />
                        )}
                      </div>
                      <CCLocalNotification
                        defaultNotifications={notifications}
                      />
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Selected recipients
                          </label>
                          <CCGrid
                            style={configGridStyle}
                            isAutoHiddenPager={false}
                            toolbar={
                              <div className="cc-grid-tools-bar">
                                <Button
                                  iconClass="fas fa-plus"
                                  type="button"
                                  title="Add Recipient"
                                  onClick={() =>
                                    setIsShowDialogAddRecipientByFillInfo(true)
                                  }
                                />
                                <Button
                                  type="button"
                                  iconClass="fas fa-minus"
                                  title="Remove Recipient"
                                  disabled={!selectedRows || !selectedRows[0]}
                                  onClick={handleRemoveRecipient}
                                />
                              </div>
                            }
                            columnFields={colRecipientResult}
                            primaryField={"ID"}
                            selectableMode="multiple"
                            data={recipientData || []}
                            selectedRows={selectedRows}
                            onSelectionChange={handleSelectionChangeRecipient}
                          />
                        </div>
                      </div>

                      <br />
                    </div>
                  </div>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button
                      className={"cc-dialog-button"}
                      onClick={() => setIsShowDialogAddRecipient(false)}
                    >
                      Cancel
                    </Button>

                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={onSubmit}
                      disabled={!recipientData.length}
                    >
                      OK
                    </Button>
                  </div>
                }
              />
              {isShowDialogAddRecipientByFillInfo && (
                <AddRecipientByFillInfo
                  onClose={setIsShowDialogAddRecipientByFillInfo}
                  onAddRecipientByFillInfo={handleAddRecipientByFillInfo}
                />
              )}
            </>
          );
        }}
      />
    );
  }
);
