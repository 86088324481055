import {
  getAsseticVersion,
  setupPickEventAddress,
} from "@app/products/crms/[id]/api";
import { AddressPickerSearch } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/address-picker-search/_index";
import { AssetPickerSearch } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/asset-picker-search/_index";
import { renderOptionIssueLocation } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/config";
import {
  LocationRegisterView,
  PremisesView,
} from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/model";
import {
  Asset,
  CS_IssueLocationType,
  ElementDisplayStatus,
  Event,
  EventHandlerRequest,
  EventMapObj,
  EventUpdateTriggers,
  Svc_EventFormAction,
} from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { PropertyDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/_index";
import { isSuccessResponse } from "@common/apis/util";
import { Address_BuildAddress } from "@common/input-pickers/address/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { AssetsSystem } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/assets/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { getFullAddressSingleLine } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { isHTML } from "@components/cc-input-picker/util";
import { CCLabel } from "@components/cc-label/_index";
import Loading from "@components/loading/Loading";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useRef, useState } from "react";

export const LOCATION_STEP = "LocationStep";

const nameOfEventMapObj = nameOfFactory<EventMapObj>();
const nameOfEvent = nameOfFactory<Event>();

export const LocationStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({ formRenderProps, localNotificationRef }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const {
      uiControl,
      crmsEventLovs,
      simpleEventChangeHandler,
      isLoading,
      setIsLoading,
    } = useCRMSEventStore();
    const { pushNotification, clearNotifications } =
      useCCAppNotificationStore();
    const prevAddressLocationDisplayValue = useRef("");
    const [initialAssetData, setInitialAssetData] = useState<Asset | null>();

    const { settings } = useCommonCoreStore();
    const assetsSystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_AssetsSystem]
    );

    const eventFormObj = valueGetter(nameOfEventMapObj("Event"));
    const locationType = valueGetter(
      `${nameOfEventMapObj("Event")}.${nameOfEvent(
        "IssueLocation"
      )}.LocationType_ENUM`
    );
    const locationAddress = valueGetter(
      `${nameOfEventMapObj("Event")}.${nameOfEvent(
        "IssueLocation"
      )}.LocationAddress`
    );

    const asset = valueGetter(
      `${nameOfEventMapObj("Event")}.${nameOfEvent("Asset")}`
    );

    const [isLoadingDialog, setIsLoadingDialog] = useState(false);

    const isShowPropertyAddress = useMemo(() => {
      return (
        locationType === CS_IssueLocationType.PropertyAddress ||
        locationType === CS_IssueLocationType.SameAsReporter
      );
    }, [locationType]);

    const isShowLocationPremises = useMemo(() => {
      return (
        locationType === CS_IssueLocationType.HealthPremises ||
        locationType === CS_IssueLocationType.TobaccoPremises
      );
    }, [locationType]);

    const isShowLocationRegister = useMemo(() => {
      return (
        locationType === CS_IssueLocationType.Reserve ||
        locationType === CS_IssueLocationType.CommunityProperty
      );
    }, [locationType]);

    const isShowLocationNonProperty = useMemo(() => {
      return locationType === CS_IssueLocationType.NonProperty;
    }, [locationType]);

    const isShowLocationStreet = useMemo(() => {
      return locationType === CS_IssueLocationType.Street;
    }, [locationType]);

    const isShowLocationAsset = useMemo(() => {
      return locationType === CS_IssueLocationType.Assets;
    }, [locationType]);

    const displaySiteAddress = useMemo(() => {
      if (locationAddress) {
        return getFullAddressSingleLine(locationAddress);
      } else return "";
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationAddress]);

    const [initialBuildAddress, setInitialBuildAddress] =
      useState<Address_BuildAddress>();

    const handleLocationTypeChange = (event: ComboBoxChangeEvent) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_LocationTypeChange,
        Event: eventFormObj,
        EventArgs: {
          LocationType_ID: event.target?.value?.CustomerService_LookupTable_Id
            ? event.target?.value?.CustomerService_LookupTable_Id
            : null,
        },
      };

      simpleEventChangeHandler(
        params,
        "Change location type fail.",
        localNotificationRef
      );
    };

    const handleAddressLocationChange = (
      buildAddress: Address_BuildAddress | null
    ) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_PickLocationAddress,
        Event: eventFormObj,
        EventArgs: buildAddress ?? {},
        isFirstTimeLoad: false,
      };

      simpleEventChangeHandler(
        params,
        "Change address location fail.",
        localNotificationRef
      );
    };

    const handleOpenDialog = async () => {
      if (!eventFormObj) return;
      setIsLoadingDialog(true);
      clearNotifications();
      const response = await setupPickEventAddress(eventFormObj);
      setIsLoadingDialog(false);

      if (isSuccessResponse(response) && response.data) {
        setInitialBuildAddress(response.data.ReturnObj);
      } else {
        pushNotification({
          autoClose: false,
          title: "Setup property address fail.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    };

    const updateSaveTriggers = (triggers: EventUpdateTriggers) => {
      let saveTriggers: EventUpdateTriggers[] =
        valueGetter(
          `${nameOfEventMapObj("Event")}.${nameOfEvent("SaveTriggers")}`
        ) ?? [];

      if (!Array.isArray(saveTriggers)) saveTriggers = [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange(
          `${nameOfEventMapObj("Event")}.${nameOfEvent("SaveTriggers")}`,
          {
            value: saveTriggers,
          }
        );
      }
    };

    const handleSelectLocationPremises = (location: PremisesView | null) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_PickPremises,
        Event: eventFormObj,
        EventArgs: {
          Premises_ID: location?.ID,
        },
      };

      simpleEventChangeHandler(
        params,
        "Change location fail.",
        localNotificationRef
      );
    };

    const handleSelectLocationRegister = (
      location: LocationRegisterView | null
    ) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_PickLocationRegister,
        Event: eventFormObj,
        EventArgs: {
          Register_ID: location?.ID,
        },
      };

      simpleEventChangeHandler(
        params,
        "Change location fail.",
        localNotificationRef
      );
    };

    const handleOpenAssetDialog = async () => {
      if (
        asset?.SiteCode &&
        (assetsSystem === AssetsSystem.AssetMaster ||
          assetsSystem === AssetsSystem.Custom)
      ) {
        setIsLoading(true);
        const response = await getAsseticVersion(asset.SiteCode);
        setIsLoading(false);
        if (isSuccessResponse(response)) {
          setInitialAssetData(response?.data ?? null);
        }
      } else {
        setInitialAssetData(asset);
      }
    };

    const handleChangeAsset = (data: Asset | null) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_AssetDetails,
        Event: eventFormObj,
        EventArgs: data ?? {},
        isFirstTimeLoad: false,
      };
      simpleEventChangeHandler(params, "Change location failed.");
    };

    const assetRequiredValidator = useCallback(
      (value: Asset) => {
        if (
          (!!value?.LocationDescription || !!value?.SiteCode) &&
          eventFormObj?.IssueLocation?.LocationAddress
        )
          return "";

        return (
          requiredValidator(value?.SiteCode) ||
          requiredValidator(eventFormObj?.IssueLocation?.LocationAddress)
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [asset]
    );

    if (isLoading) {
      return <Loading isLoading={isLoading} />;
    }

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Location type" isMandatory />

            <Field
              name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                "LocationType_ID"
              )}`}
              placeholder="Select location type"
              textField="Description"
              dataItemKey="CustomerService_LookupTable_Id"
              component={CCDropDownList}
              validator={requiredValidator}
              data={
                uiControl?.RblLocationType.Dataset ??
                crmsEventLovs?.LocationType
              }
              value={getDropdownValue(
                valueGetter(
                  `${nameOfEventMapObj("Event")}.${nameOfEvent(
                    "LocationType_ID"
                  )}`
                ),
                crmsEventLovs?.LocationType ?? [],
                "CustomerService_LookupTable_Id"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                handleLocationTypeChange(event);
              }}
            />
          </div>
        </div>

        <div className="cc-form-cols-2">
          {uiControl?.DivLocation.DisplayStatus ===
            ElementDisplayStatus.Visible && (
            <>
              {isShowPropertyAddress && (
                <div className="cc-field">
                  <CCLabel title="Property address" isMandatory />
                  <Field
                    uniqueKey="CRMSAddressLocationPicker"
                    name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "IssueLocation"
                    )}.LocationAddress`}
                    component={PropertyDetail}
                    initialData={initialBuildAddress}
                    placeholder={"Select property address"}
                    formRenderProps={formRenderProps}
                    validator={requiredValidator}
                    onSubmit={handleAddressLocationChange}
                    isSearchPropertyAddresses={true}
                    onError={(error: any) => {
                      pushNotification({
                        type: "error",
                        title: "Select location fail",
                        description: error,
                        autoClose: false,
                      });
                    }}
                    value={uiControl?.LblLocation?.Value || ""}
                    onButtonClick={handleOpenDialog}
                    onChangeEventHandler={handleAddressLocationChange}
                    updateSaveTriggers={() => {
                      updateSaveTriggers(
                        EventUpdateTriggers.UpdateIssueLocation
                      );
                    }}
                    removeDisplayValue={() => {
                      if (uiControl) {
                        prevAddressLocationDisplayValue.current =
                          uiControl.LblLocation?.Value;
                        uiControl.LblLocation.Value = displaySiteAddress;
                      }
                    }}
                    restoreDisplayValue={() => {
                      if (
                        uiControl &&
                        isHTML(prevAddressLocationDisplayValue.current) &&
                        valueGetter(
                          `${nameOfEventMapObj("Event")}.${nameOfEvent(
                            "IssueLocation"
                          )}`
                        )
                      ) {
                        uiControl.LblLocation.Value =
                          prevAddressLocationDisplayValue.current;
                      }
                    }}
                    isLoadingDialog={isLoadingDialog}
                  />
                </div>
              )}

              {isShowLocationPremises && (
                <div className="cc-field">
                  <CCLabel title="Location" isMandatory />
                  <Field
                    name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "IssueLocation"
                    )}.LocationAddress`}
                    nameDisplay="TradingName"
                    placeholder="Select location"
                    component={InputPickerSearch}
                    onChange={handleSelectLocationPremises}
                    validator={requiredValidator}
                    options={renderOptionIssueLocation(locationType)}
                    value={uiControl?.LblLocation?.Value || ""}
                  />
                </div>
              )}

              {isShowLocationRegister && (
                <div className="cc-field">
                  <CCLabel title="Location" isMandatory />
                  <Field
                    name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "IssueLocation"
                    )}.LocationAddress`}
                    nameDisplay="Name"
                    placeholder="Select location"
                    component={InputPickerSearch}
                    onChange={handleSelectLocationRegister}
                    validator={requiredValidator}
                    options={renderOptionIssueLocation(locationType)}
                    value={uiControl?.LblLocation?.Value || ""}
                  />
                </div>
              )}

              {isShowLocationNonProperty && (
                <div className="cc-field">
                  <CCLabel title="Location" isMandatory />
                  <Field
                    name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "IssueLocation"
                    )}.LocationAddress`}
                    component={AddressPickerSearch}
                    isCRMS={true}
                    formRenderProps={formRenderProps}
                    initialData={initialBuildAddress}
                    placeholder="Select location"
                    onButtonClick={handleOpenDialog}
                    onChangeEventHandler={handleAddressLocationChange}
                    onSubmit={handleAddressLocationChange}
                    validator={requiredValidator}
                    isLoadingDialog={isLoadingDialog}
                    value={uiControl?.LblLocation?.Value || ""}
                    onError={(error: any) => {
                      pushNotification({
                        type: "error",
                        title: "Select location fail",
                        description: error,
                        autoClose: false,
                      });
                    }}
                  />
                </div>
              )}

              {isShowLocationStreet && (
                <div className="cc-field">
                  <CCLabel title="Location" isMandatory />
                  <Field
                    name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "IssueLocation"
                    )}.LocationAddress`}
                    component={AddressPickerSearch}
                    isHaveStreetLookup
                    isCRMS={true}
                    formRenderProps={formRenderProps}
                    initialData={initialBuildAddress}
                    placeholder="Select location"
                    onButtonClick={handleOpenDialog}
                    onChangeEventHandler={handleAddressLocationChange}
                    onSubmit={handleAddressLocationChange}
                    validator={requiredValidator}
                    value={uiControl?.LblLocation?.Value || ""}
                    onError={(error: any) => {
                      pushNotification({
                        type: "error",
                        title: "Select location fail",
                        description: error,
                        autoClose: false,
                      });
                    }}
                    isLoadingDialog={isLoadingDialog}
                  />
                </div>
              )}

              {isShowLocationAsset && (
                <div className="cc-field">
                  <CCLabel title="Location" isMandatory />
                  <Field
                    name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "Asset"
                    )}`}
                    component={AssetPickerSearch}
                    formRenderProps={formRenderProps}
                    value={uiControl?.LblLocation?.Value || ""}
                    placeholder="Select location"
                    onChangeEventHandler={handleChangeAsset}
                    validator={assetRequiredValidator}
                    onButtonClick={handleOpenAssetDialog}
                    initialData={initialAssetData}
                    initialDataSubmit={asset}
                    onError={(error: any) => {
                      if (error) {
                        pushNotification({
                          type: "error",
                          title: "Select location fail",
                          description: error,
                          autoClose: false,
                        });
                      }
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </section>
    );
  }
);
