import {
  ERebateEntitlementsOption,
  dataRebateEntitlements,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/assessment-selection-criteria/config";
import { useNoticeRunAssessmentStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/assessment-selection-criteria/store";
import { useNoticeRunNoticeTypeStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-type/store";
import { eNoticeType } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/config";
import { ECustomColNameProperty } from "@app/products/property/config";
import { nameOfLov } from "@app/products/property/model";
import { isFieldVisible } from "@app/products/property/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { RadioGroupChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const MINIMUM_AMOUNT = 0.01;
const MAXIMUM_AMOUNT = 1000;

const validatorMinimumNoticeAmount = (values: any) => {
  if (!values) {
    return "Minimum notice amount is invalid";
  }

  if (values < 0) {
    return "The value for this field is not negative number";
  }

  if (values < MINIMUM_AMOUNT || values > MAXIMUM_AMOUNT) {
    return `Minimum notice amount must be between ${MINIMUM_AMOUNT} and ${MAXIMUM_AMOUNT}`;
  }
  return;
};

export const AssessmentSelectionCriteriaFormStep = (
  props: IFormStepElement
) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = { isReadOnly: false },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { dataSelectedNoticeType } = useNoticeRunNoticeTypeStepStore();
    const { assessmentLOVs } = useNoticeRunAssessmentStepStore();
    const instalmentPlanLov = useMemo(() => {
      return assessmentLOVs?.InstalmentPlans ?? [];
    }, [assessmentLOVs?.InstalmentPlans]);

    const directDebitSchemesLov = useMemo(() => {
      return assessmentLOVs?.DirectDebitSchemes ?? [];
    }, [assessmentLOVs?.DirectDebitSchemes]);

    const isShowInstalment = useMemo(() => {
      //Currently this field base on only one notice type Instalment Notices
      return !(dataSelectedNoticeType?.Code === eNoticeType.InstalmentNotices);
    }, [dataSelectedNoticeType?.Code]);

    const handleRebateEntitlements = (event: RadioGroupChangeEvent) => {
      onChange(nameOf("_option.RebateEntitlements"), { value: event?.value });
      if (event?.value === ERebateEntitlementsOption.NoSelection) {
        onChange(nameOf("EntitledToRebates"), { value: false });
        onChange(nameOf("NotEntitledToRebates"), { value: false });
      } else if (event?.value === ERebateEntitlementsOption.IsEntitled) {
        onChange(nameOf("EntitledToRebates"), { value: true });
        onChange(nameOf("NotEntitledToRebates"), { value: false });
      } else if (event?.value === ERebateEntitlementsOption.IsNotEntitled) {
        onChange(nameOf("EntitledToRebates"), { value: false });
        onChange(nameOf("NotEntitledToRebates"), { value: true });
      }
    };

    const isAssessmentExclusion = useMemo(() => {
      return isFieldVisible(
        dataSelectedNoticeType?.AssessmentExclusionsVisibility
      );
    }, [dataSelectedNoticeType?.AssessmentExclusionsVisibility]);

    //Get label
    const assessmentLabelLowercase = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.AssessmentLowercase
    );

    return (
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">
                Minimum notice amount
                <CCTooltip type="validator" />
              </label>
              <Field
                name={nameOf("MinimumNoticeAmount")}
                component={CCCurrencyInput}
                min={0.01}
                max={1000}
                disabled={options?.isReadOnly}
                validator={
                  !options?.isReadOnly
                    ? validatorMinimumNoticeAmount
                    : undefined
                }
              />
            </div>
            {isShowInstalment && (
              //this field alway disabled
              <div className="cc-field">
                <label className="cc-label">
                  Charge has instalment plan
                  <CCTooltip
                    content={`Only ${assessmentLabelLowercase}s with an outstanding charge with one of the selected instalment plans are to be included`}
                  />
                </label>
                <Field
                  name={nameOf("Instalment_Plan_Id")}
                  component={CCSearchComboBox}
                  data={instalmentPlanLov ?? []}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  isUseDefaultOnchange
                  disabled
                />
              </div>
            )}
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">
                  {`Only ${assessmentLabelLowercase}s paying in full`}
                </label>
                <Field
                  name={nameOf("OnlyAssessmentsPayingInFull")}
                  checked={getFieldValue("OnlyAssessmentsPayingInFull")}
                  component={CCSwitch}
                  disabled={options?.isReadOnly}
                />
              </div>
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Exclude direct debit
                <CCTooltip
                  content={`Exclude ${assessmentLabelLowercase}s having one of the selected direct debit schemes`}
                />
              </label>
              <Field
                name={nameOf("DirectDebitSchemesToBeExcluded")}
                component={CCMultiSelectDropdown}
                data={directDebitSchemesLov ?? []}
                disabled={options?.isReadOnly}
              />
            </div>
            <div className="cc-form-cols-2">
              {isAssessmentExclusion ? (
                <>
                  <div className="cc-field">
                    <label className="cc-label">
                      Number change of ownership after
                      <CCTooltip
                        content={`Exclude ${assessmentLabelLowercase}s where there has been a change of ownership after nominated date`}
                      />
                    </label>
                    <Field
                      name={nameOf("NoChangeOfOwnershipAfter")}
                      format={DATETIME_FORMAT.DATETIME_CONTROL}
                      component={CCDateTimePicker}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      No notice issued after
                      <CCTooltip
                        content={`Exclude ${assessmentLabelLowercase}s where a notice has been issued after nominated date`}
                      />
                    </label>
                    <Field
                      name={nameOf("NoNoticeIssuedAfter")}
                      format={DATETIME_FORMAT.DATETIME_CONTROL}
                      component={CCDateTimePicker}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Rebate entitlements
                      <CCTooltip
                        content={`One can selected ${assessmentLabelLowercase}s based on whether or not the ${assessmentLabelLowercase} has an entitlement to rebates ie. pensioner`}
                      />
                    </label>
                    <Field
                      name={nameOf("_option.RebateEntitlements")}
                      component={CCRadioGroup}
                      data={dataRebateEntitlements}
                      onChange={handleRebateEntitlements}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      No active arrangement
                      <CCTooltip
                        content={`Exclude ${assessmentLabelLowercase}s with an active arrangement`}
                      />
                    </label>
                    <Field
                      name={nameOf("NoActiveArrangement")}
                      checked={getFieldValue("NoActiveArrangement")}
                      component={CCSwitch}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                  <div className="cc-field">
                    <div className="cc-field">
                      <label className="cc-label">
                        No interest exemption
                        <CCTooltip
                          content={`Exclude ${assessmentLabelLowercase}s with an interest exception`}
                        />
                      </label>
                      <Field
                        name={nameOf("NoInterestExemption")}
                        checked={getFieldValue("NoInterestExemption")}
                        component={CCSwitch}
                        disabled={options?.isReadOnly}
                      />
                    </div>
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      With a current charge on instalments
                    </label>
                    <Field
                      //TODO: waiting DTO
                      name={nameOf("WithACurrentChargeOnInstalments")}
                      checked={getFieldValue("WithACurrentChargeOnInstalments")}
                      component={CCSwitch}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                </>
              ) : null}
              <div className="cc-field">
                <label className="cc-label">
                  No outstanding charge in discount period
                </label>
                <Field
                  name={nameOf("NoOutstandingChargeInDiscountPeriod")}
                  checked={getFieldValue("NoOutstandingChargeInDiscountPeriod")}
                  component={CCSwitch}
                  disabled={options?.isReadOnly}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
