import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";
import { listRoute } from "./list/route";

export const supplementaryRatesRoute: ICCRoute = {
  path: "supplementary-rates",
  name: "Supplementary Rates",
  enum: eMenuAction.Supplementaries_Folder,
  children: [
    listRoute,
    {
      path: "valuations-by-group",
      name: "Valuations by Group",
      enum: eMenuAction.Supplementaries_Valuations_by_Group,
      component: require("./valuations-by-group/_index").default,
    },
    {
      path: "import-valuations",
      name: "Import valuations",
      enum: eMenuAction.Supplementaries_Import_VM2000_General_Valuations,
      component: require("./import-valuations/_index").default,
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
