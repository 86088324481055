import { fieldName } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/config";
import { nameOfFactory } from "@common/utils/common";
import { includes, isArray, map } from "lodash";
import { DTO_Entity_Details } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";

const nameOfNames = nameOfFactory<DTO_Entity_Details>();

export const validatorFullPercentages = (values: any) => {
  if (!values || getTotalPercentages(values) !== 100)
    return "Please complete form.";
  return undefined;
};

export const validatorArrayIsNotEmpty = (values: any) => {
  if (values?.length) return undefined;
  return "Please complete form.";
};

export const validatorRoleIsRequired = (values: any) => {
  return includes(map(values, nameOfNames("Role_Name")), undefined)
    ? "A contact must be assigned a role."
    : "";
};

const getTotalPercentages = (values: any) => {
  if (!isArray(values)) return;
  return parseFloat(
    values
      .reduce(
        (total: number, name: any) =>
          name[fieldName.Percentage]
            ? total + name[fieldName.Percentage]
            : total,

        0
      )
      .toFixed(4)
  );
};
