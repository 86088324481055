import { NewAddressDialog } from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/components/dialogs/new-address/_index";
import { colParcelAddresses } from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/components/form-elements/addresses/config";
import { DTO_ModifyParcel_Address } from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/model";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { GridRowDoubleClickEvent } from "@progress/kendo-react-grid";
import { differenceWith, extend, find, map, unionBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IRenderAddressDialog {
  isShow: boolean;
  dataItem: any;
}

const nameOfParcelAddress = nameOfFactory<DTO_ModifyParcel_Address>();
export const AddressesFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = { isReadOnly: false },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const [isShowConfirmDeletion, setIsShowConfirmDeletion] =
      useState<boolean>(false);
    const parcelAddressData: DTO_ModifyParcel_Address[] =
      getFieldValue("Address");
    const [renderAddressDialog, setRenderAddressDialog] =
      useState<IRenderAddressDialog>({
        isShow: false,
        dataItem: {},
      });
    const [selectedRows, setSelectedRows] = useState<
      DTO_ModifyParcel_Address[]
    >([]);

    const handleDelete = () => {
      onChange(nameOf("Address"), {
        value: differenceWith(
          parcelAddressData,
          selectedRows,
          (parcelAddress, selectedRow) => parcelAddress?.Id === selectedRow?.Id
        ),
      });
    };

    const handleAddAddress = (dataItem: DTO_ModifyParcel_Address) => {
      const data = find(parcelAddressData, { Id: dataItem?.Id })
        ? map(parcelAddressData, (item) =>
            extend(
              item,
              find(
                [
                  {
                    ...dataItem,
                  },
                ],
                { Id: item?.Id }
              )
            )
          )
        : unionBy(
            parcelAddressData,
            [
              {
                ...dataItem,
                Id: dataItem?.Id ?? parcelAddressData.length,
                IsAddressPrimary: dataItem?.IsAddressPrimary ?? false,
              },
            ],
            "Id"
          );
      onChange(nameOf("Address"), { value: data });
      setRenderAddressDialog({ isShow: false, dataItem: {} });
    };

    return (
      <section className="cc-field-group">
        <div className="cc-field">
          <label className="cc-label">Parcel addresses</label>
          <CCGrid
            data={parcelAddressData}
            columnFields={colParcelAddresses}
            primaryField={nameOfParcelAddress("Id")}
            readOnly={options?.isReadOnly}
            selectableMode="multiple"
            onSelectionChange={(dataItem: DTO_ModifyParcel_Address[]) =>
              setSelectedRows(dataItem)
            }
            toolbar={
              <div className="cc-grid-tools-bar">
                <Button
                  iconClass="fas fa-plus"
                  title="New Address"
                  disabled={options?.isReadOnly}
                  onClick={() => {
                    setRenderAddressDialog({
                      isShow: true,
                      dataItem: {},
                    });
                  }}
                />
                <Button
                  iconClass="fa fa-minus"
                  title="Remove Address"
                  disabled={!selectedRows?.length || options?.isReadOnly}
                  onClick={() => {
                    setIsShowConfirmDeletion(true);
                  }}
                />
              </div>
            }
            onRowDoubleClick={({ dataItem }: GridRowDoubleClickEvent) => {
              setRenderAddressDialog({ isShow: true, dataItem: dataItem });
            }}
          />
        </div>
        {renderAddressDialog.isShow && (
          <NewAddressDialog
            onClose={() =>
              setRenderAddressDialog({ dataItem: {}, isShow: false })
            }
            handleAddAddress={handleAddAddress}
            dataItem={renderAddressDialog?.dataItem}
          />
        )}
        {isShowConfirmDeletion && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={"Are you sure you want to delete the selected item(s)?"}
            onClosePopup={() => {
              setIsShowConfirmDeletion(false);
            }}
            onConfirmYes={handleDelete}
          />
        )}
      </section>
    );
  }
);
