import { DTO_AdverseAffection } from "@app/products/property/certificates/[id]/components/child-screens/adverse-affections/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOfAdverseAffection = nameOfFactory<DTO_AdverseAffection>();
export const colAdverseAffection: IColumnFields[] = [
  {
    field: nameOfAdverseAffection("LGA"),
    title: "LGA",
  },
  {
    field: nameOfAdverseAffection("Parish"),
    title: "Parish",
  },
  {
    field: nameOfAdverseAffection("County"),
    title: "County",
  },
  {
    field: nameOfAdverseAffection("Locality"),
    title: "Locality",
  },
  {
    field: nameOfAdverseAffection("HouseNumber"),
    title: "House Number",
  },
  {
    field: nameOfAdverseAffection("LandDescription"),
    title: "Land Description",
  },
  {
    field: nameOfAdverseAffection("NewSubdivision"),
    title: "New Subdivision",
  },
  {
    field: nameOfAdverseAffection("PurchaserNameAddress"),
    title: "Purchaser Name Address",
  },
  {
    field: nameOfAdverseAffection("ProposedSettlementDate"),
    title: "Proposed Settlement Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAdverseAffection("AssessmentId"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOfAdverseAffection("TitleId"),
    title: "Title ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
