import { AmountsOwingDetail } from "@app/products/property/contacts-central-names/[id]/components/child-screens/general/components/amounts-owing/components/form/_index";
import { EntitiesFurtherDetails } from "@app/products/property/contacts-central-names/[id]/components/child-screens/general/components/further-details/_index";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import { observer } from "mobx-react-lite";
import React from "react";
import { FormGeneral } from "./components/form/_index";

export const GeneralContactForm = observer(() => {
  const { currentOrganisationMode } = useCommonProductStore();
  const isLLS = currentOrganisationMode(OrganisationMode.LLS);
  let listPanelBar: IListPanelBar[] = [
    {
      title: `Further Details`,
      component: <EntitiesFurtherDetails />,
    },
    {
      title: `Amounts Owing`,
      component: <AmountsOwingDetail />,
      isVisible: !isLLS,
    },
  ];

  return (
    <div className="cc-property-contact">
      <div className="cc-custom-sub-panel-bar">
        <FormGeneral />
        <CustomPanelBar listPanelBar={listPanelBar} sort={false} />
      </div>
    </div>
  );
});
