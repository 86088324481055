import { IAlert } from "@app/core/contacts/components/accordions/alerts/model";
import {
  BOOLEAN_FORMAT,
  DATETIME_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IAlert>();
export const colAlertAccordion: IColumnFields[] = [
  {
    field: nameOf("Notes"),
    title: "Notes",
  },
  {
    field: nameOf("Category"),
    title: "Category",
  },
  {
    field: nameOf("Active"),
    title: "Active",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Sys_CreatedDate"),
    title: "Recorded On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Sys_ModifiedDate"),
    title: "Modified On",
    format: DATETIME_FORMAT.DATETIME,
  },
];
