/* eslint-disable react-hooks/exhaustive-deps */
import { getViewConfigurations } from "@app/products/property/api";
import { AddAssessmentLookupDialog } from "@app/products/property/components/dialogs/add-assessment-lookup/_index";
import { getSearchAssessmentLookup } from "@app/products/property/components/dialogs/add-assessment-lookup/api";
import {
  colAssessmentLookup,
  eOptionSearchAssessmentLookup,
} from "@app/products/property/components/dialogs/add-assessment-lookup/config";
import {
  DTO_Assessment,
  DTO_Assessment_LinkedTitle,
  fnt_Assessment_LookupResult,
} from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { AddTitleLookupDialog } from "@app/products/property/components/dialogs/add-title-lookup/_index";
import { getSearchTitleLookup } from "@app/products/property/components/dialogs/add-title-lookup/api";
import { eOptionSearchTitleLookup } from "@app/products/property/components/dialogs/add-title-lookup/config";
import {
  DTO_Title,
  DTO_Title_LinkedAssessment,
  fnt_Title_LookupResult,
} from "@app/products/property/components/dialogs/add-title-lookup/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { ViewConfiguration } from "@app/products/property/model";
import {
  EDialogStepAssociation,
  EPICGridType,
} from "@app/products/property/pic/list/components/action-bar/form-steps/modify-pic/components/form-element/associations/model";
import { EMessageDelete } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/model";
import {
  combineValueWithDash,
  processCombineData,
  validatorAssociation,
} from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/util";
import { colFoliosToBeLinked } from "@app/products/property/pic/list/components/action-bar/form-steps/reactivate-pic/components/form-element/associations/config";

import "@app/products/property/pic/list/components/action-bar/form-steps/reactivate-pic/components/form-element/pic-details/_index.scss";
import { EKeysOfStepsReactivatePIC } from "@app/products/property/pic/list/components/action-bar/form-steps/reactivate-pic/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { intersection } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";
/*
  Title - Lot - Folio are all the same, just the names are different
  * Title: the name used in ACTRO
  * Folio: the old name used in LLS and don't used now
  * Lot: the name used in LLS
*/
export const AssociationsFormStep = (props: IFormStepElement) => {
  const { options } = props;
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={options?.isReadOnly ? undefined : validatorAssociation}
    />
  );
};

const nameOfFolio = nameOfFactory<DTO_Title>();
const nameOfHolding = nameOfFactory<DTO_Assessment>();
const FormStepElement = observer(
  ({
    formRenderProps,
    localNotificationRef,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    //store
    const { valueGetter, onChange, errors } = formRenderProps;

    //Get labels
    const [
      assessmentLowercaseLabel,
      titleLowercaseLabel,
      titlesLabel,
      titleLabel,
      assessmentLabel,
      assessmentsLabel,
    ] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.AssessmentLowercase,
      ECustomColNameProperty.TitleLowercase,
      ECustomColNameProperty.Titles,
      ECustomColNameProperty.Title,
      ECustomColNameProperty.Assessment,
      ECustomColNameProperty.Assessments,
    ]);

    //state
    const [isShowDialog, setIsShowDialog] = useState<
      EDialogStepAssociation | undefined
    >();
    const [columnsFolio, setProcessedFolio] =
      useState<IColumnFields[]>(colFoliosToBeLinked);
    const [columnsHolding, setColumnsHolding] =
      useState<IColumnFields[]>(colAssessmentLookup);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >(undefined);

    //get value fields
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const selectedFolio = getFieldValue("_option.FolioSelected") ?? [];
    const selectedHolding = getFieldValue("_option.HoldingSelected") ?? [];
    const folio = getFieldValue("Folios") ?? [];
    const holding = getFieldValue("Holdings") ?? [];
    const loadingMode = getFieldValue("_option.Loading");
    const primaryHolding = getFieldValue("PrimaryHolding");
    const listFolioIds = useMemo(() => {
      return folio?.map((item: DTO_Title) => item?.TitleId) ?? [];
    }, [folio]);
    const listHoldingIds = useMemo(() => {
      return (
        holding?.map(
          (item: fnt_Assessment_LookupResult) => item?.Assessment_Id
        ) ?? []
      );
    }, [holding]);

    /**
     * Load view configuration
     * Holding (Assessment)
     * Folio (Title)
     */
    const loadViewConfiguration = async () => {
      onChange(nameOf("_option.Loading"), {
        value: EPICGridType.ViewConfiguration,
      });
      options?.setIsLoadingStep(true);
      const response = await Promise.all([
        getViewConfigurations(ViewConfiguration.AssessmentSearch),
        getViewConfigurations(ViewConfiguration.TitleSearch),
      ]);
      onChange(nameOf("_option.Loading"), { value: undefined });
      options?.setIsLoadingStep(false);
      const [viewAssessment, viewTitle] = response;
      let errorResponse = undefined;
      if (
        Array.isArray(response) &&
        viewAssessment?.data &&
        viewTitle?.data &&
        !viewAssessment?.data?.hasOwnProperty("MessageType") &&
        !viewTitle?.data?.hasOwnProperty("MessageType")
      ) {
        if (viewAssessment?.data) {
          setColumnsHolding(
            processDynamicColumns(
              columnsHolding,
              viewAssessment?.data?.ColumnDefinitions?.Columns
            )
          );
        }
        if (viewTitle?.data) {
          setProcessedFolio(
            processDynamicColumns(
              columnsFolio,
              viewTitle?.data?.ColumnDefinitions?.Columns
            )
          );
        }
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Load view configuration failed",
        };
      }
      setResponseLoadError(errorResponse);
    };

    /**
     * get initial view configure
     */
    useEffectOnce(() => {
      (async () => {
        await loadViewConfiguration();
      })();
    });

    //#region HANDLE ADD ITEM --->
    /**
     * handle add Folio
     * @param data
     * @param previousFolio
     * @param previousHolding
     */
    const handleAddFolios = async (
      data: fnt_Title_LookupResult[],
      previousFolio: DTO_Title[],
      previousHolding: DTO_Assessment[]
    ) => {
      if (data.length === 0) return;
      const titleLookupValue = data
        .map((item: fnt_Title_LookupResult) => item?.Title_Id)
        .toString();
      options?.setIsLoadingStep(true);
      onChange(nameOf("_option.Loading"), {
        value: EPICGridType.Folio,
      });
      const responseTitleLookup = await getSearchTitleLookup({
        LookupKey: eOptionSearchTitleLookup.TitleId,
        LookupValue: titleLookupValue,
        Statuses: [0], //default task 12866
      });
      if (isSuccessResponse(responseTitleLookup) && responseTitleLookup?.data) {
        const resTitles = responseTitleLookup?.data?.Titles ?? [];
        if (resTitles.length) {
          const newTitles = resTitles.map((title: DTO_Title) => {
            //calculate maximum Area Percentage can be allocated
            const maxPercentage = 100 - (title?.AssociatedPICAreaTotal ?? 0);
            return {
              ...title,
              AssociatedPICAreaSet: maxPercentage,
            };
          });
          //merge previous data and new one
          const folioData = processCombineData(
            previousFolio,
            newTitles,
            nameOfFolio("TitleId")
          );
          onChange(nameOf("Folios"), {
            value: folioData,
          });
        }
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load ${titleLowercaseLabel}s failed`,
          type: "error",
          autoClose: false,
        });
      }
      //Calling api get holding by TitleID (get association of folio)
      onChange(nameOf("_option.Loading"), {
        value: EPICGridType.Holding,
      });
      const response = await getSearchAssessmentLookup({
        LookupKey: eOptionSearchAssessmentLookup.TitleId, // 8 - Title Id
        LookupValue: titleLookupValue,
        Statuses: [0],
      });
      options?.setIsLoadingStep(false);
      if (isSuccessResponse(response) && response?.data) {
        const resAssessment = response?.data?.Assessments ?? [];
        if (resAssessment?.length) {
          resAssessment?.forEach((item: DTO_Assessment) => {
            item.Is_Primary_Assessment = item?.Is_Primary_Assessment ?? false;
          });
          onChange(nameOf("Holdings"), {
            //merge previous data and new one
            value: processCombineData(
              previousHolding,
              resAssessment,
              nameOfHolding("Assessment_Id")
            ),
          });
        }
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load ${assessmentLowercaseLabel}s failed`,
          type: "error",
          autoClose: false,
        });
      }
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
    };

    /**
     * handle add Holding
     * @param data
     * @param previousFolio
     * @param previousHolding
     */
    const handleAddHoldings = async (
      data: fnt_Assessment_LookupResult[],
      previousFolio: DTO_Title[],
      previousHolding: DTO_Assessment[]
    ) => {
      if (data.length === 0) return;
      const holdingLookupValue = data
        .map((item: fnt_Assessment_LookupResult) => item?.Assessment_Id)
        .toString();
      options?.setIsLoadingStep(true);

      //#region SET DATA FOR HOLDINGS GRID --->
      //Call API to get full data of selected holding
      onChange(nameOf("_option.Loading"), {
        value: EPICGridType.Holding,
      });
      const responseAssessmentLookup = await getSearchAssessmentLookup({
        LookupKey: eOptionSearchAssessmentLookup.AssessmentId, // 4 - Assessment Id
        LookupValue: holdingLookupValue,
        Statuses: [0, 8], //Fixed now (0: Active, 8: Non-Rateable)
      });
      if (
        isSuccessResponse(responseAssessmentLookup) &&
        responseAssessmentLookup?.data
      ) {
        const resAssessment = responseAssessmentLookup?.data?.Assessments ?? [];
        //Set new assessment with full data (has Is_Primary_Assessment flag)
        resAssessment?.forEach((item: DTO_Assessment) => {
          item.Is_Primary_Assessment = item?.Is_Primary_Assessment ?? false;
        });
        onChange(nameOf("Holdings"), {
          value: processCombineData(
            previousHolding,
            resAssessment,
            nameOfHolding("Assessment_Id")
          ),
        });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load ${assessmentLowercaseLabel}s failed`,
          type: "error",
          autoClose: false,
        });
      }
      //#endregion SET DATA FOR HOLDINGS GRID <---

      //#region SET DATA FOR LOTS GRID --->
      //Call API get folio by AssessmentID (get association of holding)
      onChange(nameOf("_option.Loading"), {
        value: EPICGridType.Folio,
      });
      const responseTitleLookup = await getSearchTitleLookup({
        LookupKey: eOptionSearchTitleLookup.AssessmentId, // 6 - Assessment Id
        LookupValue: holdingLookupValue,
        Statuses: [0],
      });
      if (isSuccessResponse(responseTitleLookup) && responseTitleLookup?.data) {
        const resTitle = responseTitleLookup?.data?.Titles ?? [];
        const newFolio =
          resTitle?.map((item) => {
            //calculate Area Percentage maximum can be allocated
            const maxPercentage = 100 - (item?.AssociatedPICAreaTotal ?? 0);
            return {
              ...item,
              AssociatedPICAreaSet: maxPercentage,
            };
          }) ?? [];
        if (resTitle?.length) {
          onChange(nameOf("Folios"), {
            //merge previous data and new one
            value: processCombineData(
              previousFolio,
              newFolio,
              nameOfFolio("TitleId")
            ),
          });
        }
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load ${titleLowercaseLabel}s failed`,
          type: "error",
          autoClose: false,
        });
      }
      //#endregion SET DATA FOR LOTS GRID <---

      options?.setIsLoadingStep(false);
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
    };

    /**
     * handle add item (Holding and Folio)
     * @param data
     * @param field
     */
    const handleAddItems = async (
      data: any[],
      field: EDialogStepAssociation
    ) => {
      //reset notification
      localNotificationRef?.current?.resetNotifications();
      //get previous Folio and Holding
      const previousFolio = folio?.length ? [...folio] : [];
      const previousHolding = holding?.length ? [...holding] : [];
      switch (field) {
        case EDialogStepAssociation.Folio:
          handleAddFolios(data, previousFolio, previousHolding);
          break;
        case EDialogStepAssociation.Holding:
          handleAddHoldings(data, previousFolio, previousHolding);
          break;
      }
    };
    //#endregion HANDLE ADD ITEM <---

    //#region HANDLE DELETE ITEM --->
    /**
     * handle delete Folio
     */
    const handleDeleteFolio = () => {
      //get selected holding
      const aFolio: DTO_Title = selectedFolio?.[0];
      const folioId = aFolio?.TitleId;
      //list existed holding in Holding grid
      let existedHoldingCanDelete: number[] = [];
      let newFolio = folio;
      //check linkedAssessment with folio
      const linkedAssessments: DTO_Title_LinkedAssessment[] =
        aFolio?.LinkedAssessments ?? [];
      if (linkedAssessments?.length) {
        linkedAssessments?.forEach((item: DTO_Title_LinkedAssessment) => {
          //check with existed holding in Holding grid
          if (listHoldingIds.includes(item.Assessment_Id)) {
            if (item?.LinkedTitles?.length === 1) {
              existedHoldingCanDelete.push(item.Assessment_Id);
            } else {
              const theSameIds = intersection(item.LinkedTitles, listFolioIds);
              //check association with Folio
              if (theSameIds?.length <= 1) {
                existedHoldingCanDelete.push(item.Assessment_Id);
              }
            }
          }
        });
      }
      //delete current folio selected
      newFolio = folio.filter((item: DTO_Title) => item.TitleId !== folioId);
      onChange(nameOf("_option.FolioSelected"), {
        value: [],
      });
      if (existedHoldingCanDelete?.length) {
        const newHolding = holding.filter(
          (item: fnt_Assessment_LookupResult) =>
            item.Assessment_Id &&
            !existedHoldingCanDelete.includes(item.Assessment_Id)
        );
        //reset empty selected list
        if (
          existedHoldingCanDelete.includes(selectedHolding?.[0]?.Assessment_Id)
        ) {
          onChange(nameOf("_option.HoldingSelected"), {
            value: [],
          });
        }
        //set undefined for primary holding
        if (existedHoldingCanDelete.includes(primaryHolding)) {
          onChange(nameOf("PrimaryHolding"), { value: undefined });
        }
        onChange(nameOf("Holdings"), {
          value: newHolding,
        });
      }
      onChange(nameOf("Folios"), {
        value: newFolio,
      });
    };

    /**
     * handle delete Holding
     */
    const handleDeleteHolding = async () => {
      //get selected holding
      const aHolding = selectedHolding?.[0];
      const holdingId = aHolding?.Assessment_Id;
      //list existed folio in Folio grid
      let existedFolioCanDelete: number[] = [];
      let newHolding = holding;
      //check linkedTitle with holding
      const linkedTitles: DTO_Assessment_LinkedTitle[] =
        aHolding?.LinkedTitles ?? [];
      if (linkedTitles?.length) {
        linkedTitles?.forEach((item: DTO_Assessment_LinkedTitle) => {
          //check with existed folio in Folio grid
          if (listFolioIds.includes(item.Title_Id)) {
            if (item?.LinkedAssessments?.length === 1) {
              existedFolioCanDelete.push(item.Title_Id);
            } else {
              const theSameIds = intersection(
                item.LinkedAssessments,
                listHoldingIds
              );
              //check association with Holding
              if (theSameIds?.length <= 1) {
                existedFolioCanDelete.push(item.Title_Id);
              }
            }
          }
        });
      }
      //delete current holding selected
      newHolding = holding.filter(
        (item: fnt_Assessment_LookupResult) => item.Assessment_Id !== holdingId
      );
      //set undefined for primary holding
      if (holdingId === primaryHolding) {
        onChange(nameOf("PrimaryHolding"), { value: undefined });
      }
      //reset empty selected list
      onChange(nameOf("_option.HoldingSelected"), {
        value: [],
      });
      //remove Folio
      if (existedFolioCanDelete?.length) {
        const newFolio = folio.filter(
          (item: DTO_Title) => !existedFolioCanDelete.includes(item.TitleId)
        );
        if (existedFolioCanDelete.includes(selectedFolio?.[0]?.TitleId)) {
          onChange(nameOf("_option.FolioSelected"), {
            value: [],
          });
        }
        onChange(nameOf("Folios"), {
          value: newFolio,
        });
      }
      onChange(nameOf("Holdings"), {
        value: newHolding,
      });
    };

    /**
     * handle delete item
     * @param field
     */
    const handleDeleteItem = async (field: EDialogStepAssociation) => {
      switch (field) {
        case EDialogStepAssociation.Folio:
          handleDeleteFolio();
          break;
        case EDialogStepAssociation.Holding:
          handleDeleteHolding();
          break;
      }
    };
    //#endregion HANDLE DELETE ITEM <---

    /**
     * disable button primary when having one primary holding
     */
    const isDisabledPrimaryHolding = useMemo(() => {
      const selectHolding: DTO_Assessment = selectedHolding?.[0];
      return selectHolding?.Assessment_Id === primaryHolding;
    }, [primaryHolding, selectedHolding]);

    /**
     * handle set primary holding
     */
    const handleSetPrimaryHolding = async () => {
      const selectHolding: DTO_Assessment = selectedHolding?.[0];
      const holdingId = selectHolding?.Assessment_Id;
      if (holdingId !== primaryHolding) {
        onChange(nameOf("PrimaryHolding"), { value: holdingId });
        //set only one holding (selected) and reset False for left holding
        const newHolding = holding?.map((item: DTO_Assessment) => {
          return {
            ...item,
            Is_Primary_Assessment: item?.Assessment_Id === holdingId,
          };
        });
        //update new list Holdings
        onChange(nameOf("Holdings"), {
          value: newHolding,
        });
        //set some values of Primary Holding using in next step (PIC detail)
        onChange(`${EKeysOfStepsReactivatePIC.PICDetail}.PrimaryHolding`, {
          value: combineValueWithDash(
            [
              selectHolding?.Assessment_Id + "" ?? "",
              selectHolding?.Assess_Property_Address ?? "",
            ],
            " - "
          ),
        });
        onChange(`${EKeysOfStepsReactivatePIC.PICDetail}.Occupier`, {
          value: selectHolding?.Ratepayer_Name ?? "",
        });
        onChange(`${EKeysOfStepsReactivatePIC.PICDetail}._option.PICAddress`, {
          value: selectHolding?.Assess_Property_Address ?? "",
        });
        onChange(`${EKeysOfStepsReactivatePIC.PICDetail}.TradingNameId`, {
          value: holdingId,
        });
        onChange(`${EKeysOfStepsReactivatePIC.PICDetail}._option.RLPBoards`, {
          value: selectHolding?.RLP_Board_Name ?? "",
        });
      }
    };

    /**
     * handle data change Folio in grid
     * Using for edit inline
     * Field change: AssociatedPICAreaSet (Area Percentage)
     * @param dataRow
     * @param fieldName
     */
    const handleDataChangeFolio = async (
      dataRow: DTO_Title,
      fieldName: string
    ) => {
      const titleId = dataRow.TitleId;
      let newFolio = [...folio];
      newFolio = folio.map((item: DTO_Title) => {
        if (item.TitleId === titleId && fieldName === "AssociatedPICAreaSet") {
          return {
            ...item,
            AssociatedPICAreaSet: dataRow[fieldName] ?? null,
          };
        }
        return item;
      });
      onChange(nameOf("Folios"), { value: newFolio });
    };

    return (
      <>
        <section className="cc-field-group cc-pic-detail">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">
                Association date
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("AssociationDate")}
                disabled={options?.isReadOnly}
                component={CCDatePicker}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
          </div>
          {loadingMode === EPICGridType.ViewConfiguration ? (
            <Loading isLoading />
          ) : responseLoadError ? (
            <CCLoadFailed
              responseError={responseLoadError}
              onReload={() => {
                loadViewConfiguration();
              }}
            />
          ) : (
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  {titlesLabel} to be linked
                  <CCTooltip type="validator" position="right" />
                  <CCTooltip
                    type="info"
                    position="right"
                    content="% Area is mandatory"
                  />
                  {getFieldValue("_option.Loading") === EPICGridType.Folio ? (
                    <span className="cc-icon-loading-free">
                      <i className="fas fa-spinner fa-spin" />
                    </span>
                  ) : null}
                  {errors?.[nameOf("")] ? (
                    errors[nameOf("")] === "ValidateBothGrid" ? (
                      <Error>
                        You must add both a {titleLabel} and {assessmentLabel}{" "}
                        to proceed.
                      </Error>
                    ) : null
                  ) : null}
                </label>
                <CCGrid
                  data={folio ?? []}
                  columnFields={columnsFolio}
                  selectableMode="single"
                  primaryField={nameOfFolio("TitleId")}
                  selectedRows={selectedFolio}
                  editableMode={!options?.isReadOnly ? "cell" : undefined}
                  onSelectionChange={(dataItems) => {
                    onChange(nameOf("_option.FolioSelected"), {
                      value: dataItems,
                    });
                  }}
                  onDataRowChange={handleDataChangeFolio}
                  readOnly={options?.isReadOnly}
                  toolbar={
                    !options?.isReadOnly ? (
                      <div className="cc-grid-tools-bar">
                        <Button
                          iconClass="fas fa-plus"
                          title={`Add ${titleLowercaseLabel}`}
                          onClick={() => {
                            setIsShowDialog(EDialogStepAssociation.Folio);
                          }}
                        />
                        <Button
                          iconClass="fas fa-minus"
                          title={`Remove ${titleLowercaseLabel}`}
                          onClick={() =>
                            setIsShowDialog(EDialogStepAssociation.DeleteFolio)
                          }
                          disabled={selectedFolio.length !== 1}
                        />
                      </div>
                    ) : null
                  }
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  {assessmentsLabel}{" "}
                  <CCTooltip type="validator" position="right" />
                  {getFieldValue("_option.Loading") === EPICGridType.Holding ? (
                    <span className="cc-icon-loading-free">
                      <i className="fas fa-spinner fa-spin" />
                    </span>
                  ) : null}
                  {errors?.[nameOf("")] ? (
                    errors[nameOf("")] === "PrimaryHolding" ? (
                      <Error>
                        A {assessmentLowercaseLabel} association is mandatory
                        and the primary needs to be set.
                      </Error>
                    ) : null
                  ) : null}
                </label>
                <CCGrid
                  data={holding ?? []}
                  columnFields={columnsHolding}
                  selectableMode="single"
                  primaryField={nameOfHolding("Assessment_Id")}
                  selectedRows={selectedHolding}
                  editableMode={!options?.isReadOnly ? "cell" : undefined}
                  onSelectionChange={(dataItems) => {
                    onChange(nameOf("_option.HoldingSelected"), {
                      value: dataItems,
                    });
                  }}
                  readOnly={options?.isReadOnly}
                  toolbar={
                    !options?.isReadOnly ? (
                      <div className="cc-grid-tools-bar">
                        <Button
                          themeColor="primary"
                          title="Set as primary"
                          disabled={
                            selectedHolding.length !== 1 ||
                            isDisabledPrimaryHolding
                          }
                          onClick={handleSetPrimaryHolding}
                        >
                          Set as primary
                        </Button>
                        <Button
                          iconClass="fas fa-plus"
                          title={`Add ${assessmentLowercaseLabel}`}
                          onClick={() =>
                            setIsShowDialog(EDialogStepAssociation.Holding)
                          }
                        />
                        <Button
                          iconClass="fas fa-minus"
                          title={`Remove ${assessmentLowercaseLabel}`}
                          onClick={() => {
                            setIsShowDialog(
                              EDialogStepAssociation.DeleteHolding
                            );
                          }}
                          disabled={selectedHolding.length !== 1}
                        />
                      </div>
                    ) : null
                  }
                />
              </div>
            </div>
          )}
        </section>
        {isShowDialog === EDialogStepAssociation.Folio && (
          <AddTitleLookupDialog
            onClose={() => setIsShowDialog(undefined)}
            handleAddTitle={(data: fnt_Title_LookupResult[]) => {
              setIsShowDialog(undefined);
              handleAddItems(data, EDialogStepAssociation.Folio);
            }}
          />
        )}
        {isShowDialog === EDialogStepAssociation.Holding && (
          <AddAssessmentLookupDialog
            onClose={() => setIsShowDialog(undefined)}
            handleAddAssessment={(data: fnt_Assessment_LookupResult[]) => {
              setIsShowDialog(undefined);
              handleAddItems(data, EDialogStepAssociation.Holding);
            }}
          />
        )}
        {(isShowDialog === EDialogStepAssociation.DeleteHolding ||
          isShowDialog === EDialogStepAssociation.DeleteFolio) && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={
              isShowDialog === EDialogStepAssociation.DeleteHolding
                ? EMessageDelete.Holding
                : EMessageDelete.Folio
            }
            onClosePopup={() => setIsShowDialog(undefined)}
            onConfirmYes={() =>
              handleDeleteItem(
                isShowDialog === EDialogStepAssociation.DeleteHolding
                  ? EDialogStepAssociation.Holding
                  : EDialogStepAssociation.Folio
              )
            }
          />
        )}
      </>
    );
  }
);
