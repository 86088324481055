import {
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields, ICustomEditCell } from "@components/cc-grid/model";

import { FactorCell } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/components/form-elements/parcels-to-be-split/components/factor-cell/_index";
import {
  DTO_ModifyAssessment_Parcel,
  DTO_Title,
} from "@app/products/property/assessments/components/form-steps/modify-assessment/components/form-elements/associations-parcel/model";
import { DateCustomCell } from "@app/products/property/components/grid/components/cc-date-range-limit/_index";
import { BOOLEAN_FORMAT } from "@common/constants/common-format";
import React from "react";

const nameOf = nameOfFactory<DTO_ModifyAssessment_Parcel>();
export const colAssociationParcel: IColumnFields[] = [
  {
    field: nameOf("ParcelReference"),
    title: "Parcel (S-S-B-U)",
  },
  {
    field: nameOf("PropertyName"),
    title: "Property Name",
  },
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("AssociationFactorPercentage"),
    title: "Factor %",
    minWidth: 120,
    editable: true,
    format: PERCENTAGE_FORMAT.PERCENTAGE_NOT_DECIMAL,
    editCell: FactorCell,
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOf("CensusDistrict"),
    title: "Census District",
  },
  {
    field: nameOf("ElectoralDivision"),
    title: "Electoral Division",
  },
  {
    field: nameOf("StateCodeElectoralDivision"),
    title: "State Electoral Division",
  },
  {
    field: nameOf("FederalElectoralDivision"),
    title: "Federal Electoral Division",
  },
  {
    field: nameOf("LandArea"),
    title: "Land Area m2",
  },
  {
    field: nameOf("Zones"),
    title: "Zoning",
  },
  {
    field: nameOf("LandUse"),
    title: "Land Uses",
  },
  {
    field: nameOf("OwnerName"),
    title: "Owners",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("LocationDescriptor"),
    title: "Location Descriptor",
  },
  {
    field: nameOf("MapNumber"),
    title: "Map Number",
  },
  {
    field: nameOf("MapReference"),
    title: "Map Reference",
  },
  {
    field: nameOf("LandCategory"),
    title: "Land Category",
  },
  {
    field: nameOf("Parcel_Id"),
    title: "Parcel Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

const nameOfTitle = nameOfFactory<DTO_Title>();
const propsDateCustomCell = {
  listFieldName: {
    fromField: nameOfTitle("Active_From"),
    toField: nameOfTitle("Active_To"),
  },
};
export const colFoliosAssessment: IColumnFields[] = [
  {
    field: nameOfTitle("ReferenceNumber"),
    title: "Reference Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfTitle("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOfTitle("Status"),
    title: "Status",
  },
  {
    field: nameOfTitle("Active_From"),
    title: "Active From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfTitle("Active_To"),
    title: "Active To",
    format: DATE_FORMAT.DATE,
    editCell: (props: ICustomEditCell) => (
      <DateCustomCell {...props} extraConditions={propsDateCustomCell} />
    ),
    editable: true,
    width: 200,
  },
  {
    field: nameOfTitle("Lot"),
    title: "Lot",
  },
  {
    field: nameOfTitle("AssociationFactorPercentage"),
    title: "Factor",
    minWidth: 120,
    editable: true,
    format: PERCENTAGE_FORMAT.PERCENTAGE_NOT_DECIMAL,
    editCell: FactorCell,
  },
  {
    field: nameOfTitle("PlanType"),
    title: "Plan Type",
  },
  {
    field: nameOfTitle("PlanNumber"),
    title: "Plan Number",
  },
  {
    field: nameOfTitle("Section"),
    title: "Section",
  },
  {
    field: nameOfTitle("NameAddress"),
    title: "Name Address",
  },
  {
    field: nameOfTitle("Volume"),
    title: "Volume",
  },
  {
    field: nameOfTitle("Folio"),
    title: "Folio",
  },
  {
    field: nameOfTitle("County"),
    title: "County",
  },
  {
    field: nameOfTitle("Parish"),
    title: "Parish",
  },
  {
    field: nameOfTitle("DivisionDate"),
    title: "Division Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfTitle("Frontage"),
    title: "Frontage",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOfTitle("LandArea"),
    title: "Land Area (m2)",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfTitle("IsAreaSurveyed"),
    title: "Is Area Surveyed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfTitle("Coverage"),
    title: "Coverage",
  },
  {
    field: nameOfTitle("TitleId"),
    title: "Title Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
