import { useAddRelatedButtonStore } from "@app/core/related/related-button/store";
import { AddRelatedDialog } from "@app/core/related/related-dialog/_index";
import { RelatedRecord } from "@app/core/related/related-dialog/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";

interface IAddRelatedButton {
  id?: number;
  recordType: RECORDTYPE;
}

export const AddRelatedButton = observer(
  ({ id, recordType }: IAddRelatedButton) => {
    const { isLoading, handleAddRelated } = useAddRelatedButtonStore();
    const [isShowDialog, setIsShowDialog] = useState(false);
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const handleAddRelatedEvent = async (data: RelatedRecord[]) => {
      const result = await handleAddRelated(data);
      if (!result?.isSuccess) {
        if (result?.notification)
          notificationRef.current?.pushNotification(result?.notification);
      } else {
        setIsShowDialog(false);
      }
    };
    return (
      <>
        <CCNavButton
          title="Related"
          onClick={() => {
            setIsShowDialog(true);
          }}
        />
        {isShowDialog && (
          <AddRelatedDialog
            onClose={() => {
              setIsShowDialog(false);
            }}
            onSubmit={handleAddRelatedEvent}
            sourceRecordType={recordType}
            sourceID={id}
            isLoading={isLoading}
            notificationRef={notificationRef}
          />
        )}
      </>
    );
  }
);
