import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import {
  DTO_Parcel_LOVs,
  DTO_Workflow_ModifyParcel,
  RequestModifyParcelObjFromSpatial,
} from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type IGetInitialDataParcel =
  | [APIResponse<DTO_Parcel_LOVs>, APIResponse<DTO_Workflow_ModifyParcel>]
  | APIResponse<DTO_Parcel_LOVs | DTO_Workflow_ModifyParcel>
  | undefined;
export const getInitialDataParcel = async (
  workflowType: WorkflowTypes,
  id: number,
  isFromActionList: boolean = false,
  requestObjSpatial: RequestModifyParcelObjFromSpatial | object = {
    ParcelId: id,
  }
): Promise<IGetInitialDataParcel> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Parcel_LOVs>(
        `/api/property/internal/parcel/0/lovs?workflowType=${workflowType}` //Default 0 using for all Parcel
      ),
      isFromActionList
        ? getWorkflowParcel(id)
        : getNewWorkflowParcel(requestObjSpatial),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowParcel = async (
  requestObjSpatial: RequestModifyParcelObjFromSpatial | object = {}
): Promise<APIResponse<DTO_Workflow_ModifyParcel>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/modifyparcel/new`,
      requestObjSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowParcel = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_ModifyParcel>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/modifyparcel/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessWorkflowParcel = async (
  modeProcess: WorkflowProcessMode,
  dataParcel: DTO_Workflow_ModifyParcel
): Promise<APIResponse<ResponseMessageWorkflow<DTO_Workflow_ModifyParcel>>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/modifyparcel/process/${modeProcess}`,
      dataParcel
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
