import { getViewConfigurations } from "@app/products/property/api";
import { eComponent } from "@app/products/property/components/associations/model";
import { DTO_Documents } from "@app/products/property/components/child-screen/documents/model";
import {
  ICCViewConfiguration,
  ResponseMessage,
  ViewConfiguration,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const deactivateAssessmentDocument = async (
  id: number
): Promise<APIResponse<ResponseMessage | any>> => {
  try {
    return await CoreAPIService.getClient().put(
      `/api/property/internal/attachment/deactivate/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const downloadAssessmentDocument = async (
  id: number
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/attachment/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetPropertyDocument =
  | [APIResponse<DTO_Documents>, APIResponse<ICCViewConfiguration | undefined>]
  | APIResponse<DTO_Documents | ICCViewConfiguration>;

export const getPropertyDocuments = async (
  componentNumber: eComponent,
  componentId: number
): Promise<IGetPropertyDocument> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Documents>(
        `api/property/int/documentattachments/${componentNumber}/${componentId}`
      ),
      getViewConfigurations(ViewConfiguration.Workflow_Document),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
