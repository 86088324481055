import { eComponent } from "@app/products/property/components/associations/model";
import {
  colJournalItems,
  colJournalsPicTransactions,
} from "@app/products/property/journals/[id]/components/child-screens/items/config";
import {
  DTO_PIC_Transaction,
  DTO_Transaction,
  VO_Transaction,
} from "@app/products/property/journals/[id]/components/child-screens/items/model";
import { ViewConfiguration } from "@app/products/property/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Transaction>();
const nameOfJournalsPICTransaction = nameOfFactory<DTO_PIC_Transaction>();

interface IItemsInfo {
  viewConfiguration: ViewConfiguration;
  col: IColumnFields[];
  primaryField:
    | keyof VO_Transaction
    | keyof DTO_Transaction
    | keyof DTO_PIC_Transaction;
  nameForTransactionId: string;
  nameForJournalNumber: string;
}

export const getItemsInfoBaseOnComponentNumber = (
  componentNumber: number | undefined
): IItemsInfo => {
  switch (componentNumber) {
    case eComponent.Assessment:
      return {
        viewConfiguration: ViewConfiguration.Journal_Transactions,
        col: colJournalItems,
        primaryField: nameOf("Transaction_Id"),
        nameForTransactionId: "Transaction_Id",
        nameForJournalNumber: "Journal_Number"
      };
    case eComponent.PIC:
      return {
        viewConfiguration: ViewConfiguration.Journal_Items_Type_PIC,
        col: colJournalsPicTransactions,
        primaryField: nameOfJournalsPICTransaction("PICTransactionId"),
        nameForTransactionId: "PICTransactionId",
        nameForJournalNumber: "JournalNumber"
      };
    default:
      return {
        viewConfiguration: ViewConfiguration.Journal_Transactions,
        col: colJournalItems,
        primaryField: nameOf("Transaction_Id"),
        nameForTransactionId: "Transaction_Id",
        nameForJournalNumber: "Journal_Number"
      };
  }
};

export const journalItemOdata = (journalId: string | number) => {
  return `odata/property/internal/transactionsregister/${journalId}?&cancelled=false&view=0&year=0&$count=true&`;
};
