import { DTO_Address } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { DTO_LOV } from "@common/models/odataResponse";
import { getDropdownValue } from "@common/utils/common";

export const validatorNewPhysicalAddress = (value: any): string | undefined => {
  if (value?.length) {
    let isContainPrimary;
    const isPrimaryAddress = value?.find(
      (item: DTO_Address) => item?.IsAddressPrimary
    );
    if (!isPrimaryAddress) {
      return undefined;
    } else {
      isContainPrimary = value?.some((item: DTO_Address) => {
        return isPrimaryAddress?.RowId === item?.RowId && item?.PropertyName;
      });
    }
    if (!isContainPrimary) {
      return "At least one address must be set as primary and property name is required";
    }
  }
  return undefined;
};

// Handle for API, API just return the Id
// we need to get the AddressTypeName from the Id to display for the physical address grid
// the API will fix later, we need to temporary fix
export const getAddressTypeName = (fieldValue: string, lovs: DTO_LOV[]) => {
  return getDropdownValue(fieldValue ?? null, lovs ?? [], "Code")?.Name || "";
};
