import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

interface IRaiseDeferredDutyInterestButtonProps {
  title?: string;
}
export const RaiseDeferredDutyInterestButton = observer(
  ({ title = "Raise interest" }: IRaiseDeferredDutyInterestButtonProps) => {
    const { gridSelectedRows, isEmptyData } = useCCProductListViewStore();
    const { setListDialog } = usePropertyWorkflowStore();
    const { id } = useParams<{ id: string }>();
    const { currentOrganisationMode } = useCommonProductStore();
    const isGeneral = currentOrganisationMode(OrganisationMode.General);
    const isDisabled = useMemo(() => {
      return (
        (!isEmptyData && gridSelectedRows.length > 1 && !id) ||
        (!id && isEmptyData)
      );
    }, [gridSelectedRows.length, id, isEmptyData]);

    return (
      <>
        {/* Hide for General - Task: 14698 */}
        {isGeneral ? null : (
          <CCNavButton
            title={title}
            onClick={() => {
              setListDialog([
                {
                  type: WorkflowTypes.Journal_DD_Raise_Interest,
                  data: {},
                },
              ]);
            }}
            disabled={isDisabled}
          />
        )}
      </>
    );
  }
);
