import { useParcelsStore } from "@app/products/property/parcels/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import ExistedPropertyParcel from "./components/forms/existed/_index";
import { NewPropertyParcel } from "./components/forms/new/_index";

const ManagePropertyParcel = observer(() => {
  const isNew = useIsNew();
  const history = useHistory();
  const params: { id: string } = useParams();
  const parcelId = params.id;
  const { resetMenu } = useCCSubActionBarStore();
  const { loadParcelSummary, resetStore } = useParcelsStore();

  useEffect(() => {
    if (isNew) return;
    loadParcelSummary(parseInt(parcelId));
  }, [parcelId, history, isNew, loadParcelSummary]);

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
    };
  });

  if (isNew) {
    return <NewPropertyParcel />;
  }

  return <ExistedPropertyParcel />;
});

export default ManagePropertyParcel;
