import { Contact } from "@app/products/waste-water/[id]/model";
import React from "react";

export const renderContact = (contact: Contact) => {
  if (!contact) return "";
  return (
    <>
      {contact._HomePhone && (
        <a href={`tel:${contact.HomePhone}`} className="cc-contact">
          {contact._HomePhone}(H)
        </a>
      )}
      {contact._WorkPhone && (
        <a href={`tel:${contact.WorkPhone}`} className="cc-contact">
          {contact._WorkPhone}(W)
        </a>
      )}
      {contact._Mobile && (
        <a href={`tel:${contact.Mobile}`} className="cc-contact">
          {contact._Mobile}(M)
        </a>
      )}
      {contact.Email && (
        <a href={`mailto:${contact.Email}`} className="cc-contact">
          {contact.Email?.trim()}
        </a>
      )}
    </>
  );
};
