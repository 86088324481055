import { DTO_ConcessionCard } from "@app/products/property/assessments/[id]/components/child-screens/rebates/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<DTO_ConcessionCard>();
export const colConcessionCards: IColumnFields[] = [
  { field: nameOf("CardType"), title: "Card Type" },
  { field: nameOf("CardNumber"), title: "Card Number" },
  { field: nameOf("CardHolder"), title: "Card Holder" },
  { field: nameOf("Gender"), title: "Gender" },
  {
    field: nameOf("DateOfBirth"),
    title: "Date of Birth",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DatePresented"),
    title: "Date Presented",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ConfirmationDate"),
    title: "Confirmation Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("RebatesEffectiveFrom"),
    title: "Rebates Effective From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("AssessmentRebateEntitlementsAsString"),
    title: "Active Assessment Rebate Entitlements",
  },
  {
    field: nameOf("IsActive"),
    title: "Is Active",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("ConcessionCardId"),
    title: "Concession Card ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Id"),
    title: "Entity ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
