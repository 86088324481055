import {
  Registration,
  Svc_Registration,
} from "@app/products/animals/[id]/model";
import ImageDefault from "@assets/img/image-default.svg";
import { useIsNew } from "@common/hooks/useIsNew";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import "./_index.scss";

interface IAnimalParentSectionProps {
  formRenderProps: FormRenderProps;
  setOpenPopupPhoto(data: boolean): void;
}

const nameOf = nameOfFactory<Registration>();
const nameOfLocationRegisterMapObj = nameOfFactory<Svc_Registration>();
const getNameOf = (name: keyof Registration) =>
  `${nameOfLocationRegisterMapObj("Registration")}.${nameOf(name)}`;

export const AnimalPhotoSection = observer(
  (props: IAnimalParentSectionProps) => {
    const { valueGetter, onChange } = props.formRenderProps;
    const { pushNotification } = useCCAppNotificationStore();
    const isNew = useIsNew();

    const photoAttachment = valueGetter(getNameOf("PhotoAttachment"));

    const clickAddAttachment = () => {
      props.setOpenPopupPhoto(isNew ? false : true);
      if (isNew) {
        pushNotification({
          autoClose: false,
          type: "info",
          title: "Please save before attaching a photo.",
        });
      }
    };

    const handleDeleteImage = () => {
      onChange(getNameOf("PhotoAttachment"), {
        value: "",
      });
      onChange(getNameOf("PhotoAttachment_ID"), {
        value: "",
      });
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Photo</label>
            <div className="cc-image-upload">
              <div className="cc-image-upload-control">
                {photoAttachment?.FileEntry?.FileAttachment ? (
                  <i
                    className="fa fa-times fa-2x"
                    onClick={handleDeleteImage}
                    title="Remove"
                  />
                ) : (
                  <i
                    className="fa fa-upload fa-2x"
                    onClick={clickAddAttachment}
                    title="Upload"
                  />
                )}
              </div>
              <img
                src={
                  photoAttachment?.FileEntry?.FileAttachment
                    ? !photoAttachment?.FileEntry?.FileAttachment.toString().includes(
                        "blob:"
                      )
                      ? `data:${photoAttachment?.FileEntry?.ContentType};base64,` +
                        photoAttachment.FileEntry?.FileAttachment
                      : photoAttachment.FileEntry?.FileAttachment
                    : ImageDefault
                }
                className="cc-image-review"
                alt="Attachment Photos"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
);
