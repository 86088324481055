import { IExecutionData } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/execution/model";

export const dataRebateEntitlements: Array<IExecutionData> = [
  {
    value: "1",
    label: "No selection on Rebate Entitlement presence",
  },
  {
    value: "2",
    label: "Is Entitled to Rebates",
  },
  {
    value: "3",
    label: "Is Not Entitled to Rebates",
  },
];

export enum ERebateEntitlementsOption {
  NoSelection = "1",
  IsEntitled = "2",
  IsNotEntitled = "3",
}
