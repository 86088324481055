import {
  IAttachment,
  IWebLink,
} from "@app/core/attachment/components/buttons/add-attachment/model";
import { AddAttachmentDialog } from "@app/core/attachment/components/dialogs/_index";
import {
  IAddAttachmentDialog,
  eUploadMode,
} from "@app/core/attachment/components/dialogs/model";
import { convertToFileStreamEntry } from "@app/core/attachment/components/dialogs/util";
import { defaultBuildAddress } from "@app/core/contacts/_id/components/child-screens/general/components/form-element/configs";
import {
  getDocumentById,
  saveDocument,
  saveDocumentAsWebLink,
} from "@app/core/documents/api";
import { Svc_Attachment } from "@app/core/documents/model";
import AvatarDefault from "@assets/img/AvatarDefault.svg";
import SignatureDefault from "@assets/img/SignatureDefault.svg";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { RECORDSSYSTEM } from "@common/constants/enumerations";
import { RECORDTYPE } from "@common/constants/recordtype";
import { APIResponseStatus } from "@common/constants/response-status";
import { useIsNew } from "@common/hooks/useIsNew";
import { AddressPicker } from "@common/input-pickers/address/_index";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { getVerifyCredential } from "@common/pages/profile/api";
import { ContactPicker } from "@common/pages/profile/components/input-picker/officer-picker/_index";
import { Svc_ContactDetails } from "@common/pages/profile/components/input-picker/officer-picker/model";
import { VerifyCredentialResult } from "@common/pages/profile/model";
import { useProfileStore } from "@common/pages/profile/store";
import { getSiteUserById } from "@common/pages/settings/security/site-users/_id/api";
import { UserCredentials } from "@common/pages/settings/security/site-users/_id/components/dialogs/credentials/model";
import { CredentialsPicker } from "@common/pages/settings/security/site-users/_id/components/input-picker/exchange-username/_index";
import { NewSiteUser } from "@common/pages/settings/security/site-users/_id/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  formatDateByKendo,
  getFullAddressHTML,
} from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmailRequired } from "@components/cc-input-email/util";
import { CCInputPhone } from "@components/cc-input-phone/_index";
import { useIsEnableValidatePhoneNumber } from "@components/cc-input-phone/hook/useIsEnableValidatePhoneNumber";
import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { validatePhoneNumber } from "@components/cc-input-phone/util";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  InputChangeEvent,
  SwitchChangeEvent,
} from "@progress/kendo-react-inputs";
import { isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./_index.scss";
export interface IProfileFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOfContactPick = nameOfFactory<Svc_ContactDetails>();

const ProfileFormElement = ({ formRenderProps }: IProfileFormElementProps) => {
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const { settings } = useCommonCoreStore();
  const { onChange } = formRenderProps;
  const { setIsOnLeave } = useProfileStore();
  const isNew = useIsNew();
  const getValue = formRenderProps.valueGetter;
  const [isOpenPopupPhoto, setOpenPopupPhoto] = useState(false);
  const [isOpenPopupSignature, setOpenPopupSignature] = useState(false);
  const [isLoadingUploadPhoto, setIsLoadingUploadPhoto] = useState(false);
  const [isLoadingSignature, setIsLoadingSignature] = useState(false);
  const isEnableValidatePhoneNumber = useIsEnableValidatePhoneNumber();
  const [checkingVerifyCredential, setCheckingVerifyCredential] =
    useState<boolean>(false);
  const [showVerifyCredential, setShowVerifyCredential] =
    useState<boolean>(false);
  const [verifyCredential, setVerifyCredential] = useState<boolean>(false);

  const credentialUserName = getValue("siteUserCredential.Username");
  const credentialPassword = getValue("siteUserCredential.Password");
  const contactUserName = getValue("Contact_SiteUser.DocMgmt_Username");
  const contactPassword = getValue("Contact_SiteUser.DocMgmt_Password");
  const displayName = getValue("Contact_SiteUser.DisplayName");
  const userEmail = getValue("Contact_SiteUser.Email");
  const leaveStartDate = getValue("Contact_SiteUser.OnLeave_StartDate");
  const leaveEndDate = getValue("Contact_SiteUser.OnLeave_EndDate");
  const isOnLeaveField = getValue("Contact_SiteUser.Flag_OnLeave");
  const onLeaveEndDate = getValue("Contact_SiteUser.OnLeave_EndDate");
  const actingOfficer = getValue("Contact_SiteUser.SiteUser_ActingOfficer");
  const recordsSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
  );

  const handleOnChangeFirstName = (e: InputChangeEvent) => {
    onChange("Contact_SiteUser.FirstName", { value: e.value });
    onChange("Contact_SiteUser.DisplayName", {
      value: e.value + " " + (getValue("Contact_SiteUser.LastName") ?? ""),
    });
  };
  const handleOnChangeLastName = (e: InputChangeEvent) => {
    onChange("Contact_SiteUser.LastName", { value: e.value });
    onChange("Contact_SiteUser.DisplayName", {
      value: (getValue("Contact_SiteUser.FirstName") ?? "") + " " + e.value,
    });
  };

  const userName = getValue("UserName");
  const nameOf = nameOfFactory<NewSiteUser>();
  const { siteUserLovs, siteUserId } = useProfileStore();
  const photoAttachment = getValue("Contact_SiteUser.Photo_Attachment");
  const signAttachment = getValue("Contact_SiteUser.Sign_Attachment");
  const userNameDisplayValue = useMemo(() => {
    return !isNil(userName)
      ? userName
      : getValue("Contact_SiteUser.MemberUserName");
  }, [getValue, userName]);

  useEffect(() => {
    clearNotifications("info");
    let onLeaveNotification = "";
    if (isOnLeaveField) {
      if (!onLeaveEndDate) {
        onLeaveNotification = `${displayName} is on leave`;
        if (!isNil(actingOfficer)) {
          onLeaveNotification += `. All requests normally allocated to ${displayName} will be forwarded to ${actingOfficer?.DisplayName} for action`;
        }
      } else {
        onLeaveNotification = `${displayName} is on leave until ${formatDateByKendo(
          onLeaveEndDate
        )}`;
        if (!isNil(actingOfficer)) {
          onLeaveNotification += `. All requests normally allocated to ${displayName} will be forwarded to ${actingOfficer?.DisplayName} for action`;
        }
      }
    }
    if (!isEmpty(onLeaveNotification)) {
      pushNotification({
        autoClose: false,
        type: "info",
        title: onLeaveNotification,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actingOfficer, isOnLeaveField, onLeaveEndDate]);

  const handleSaveAttachmentPhoto = async (data: IAddAttachmentDialog) => {
    setIsLoadingUploadPhoto(true);
    switch (data?.UploadMode) {
      case eUploadMode.UPLOAD_FILE:
        if (!isNil(data?.File) && isNil(data?.File[0].validationErrors)) {
          const fileEntry = await convertToFileStreamEntry(data?.File[0]);
          const attachments = {
            FileEntry: fileEntry,
            Title: data.Title ?? data?.File[0]?.name,
            Attachment_ID: 0,
            Category_KWD: data?.Category?.Keyword_ID ?? null,
            FileNumber: "",
            TRIMUri: "",
          } as IAttachment;

          const requestBody = {
            ParentID: getValue("Contact_SiteUser.Contact_ID"),
            ParentRecordType: RECORDTYPE.CORE_Contact,
            AttachmentList: [attachments],
            AddDateTimeToFileName: null,
          } as Svc_Attachment;
          const response = await saveDocument(requestBody);
          setIsLoadingUploadPhoto(false);
          if (isSuccessIdentityPacket(response)) {
            const responseDocument = await getDocumentById(
              response.data?.ID,
              false
            );
            if (isSuccessResponse(responseDocument)) {
              onChange("Contact_SiteUser.Photo_Attachment", {
                value: responseDocument.data,
              });
              onChange("Contact_SiteUser.Photo_Attachment_ID", {
                value: response.data?.ID,
              });
              clearNotifications();
              pushNotification({
                title: "Photo uploaded successfully",
                type: "success",
              });
            } else {
              pushNotification({
                autoClose: false,
                title: "Upload photo failed",
                type: "error",
              });
            }
          } else {
            if (response?.status === APIResponseStatus.EXCEEDED_SIZE_LIMIT) {
              pushNotification({
                autoClose: false,
                title: "Exceeded the specified size limit",
                type: "error",
              });
            } else {
              pushNotification({
                autoClose: false,
                title: "Upload photo failed",
                description: response.data?.Errors ?? response.statusText,
                type: "error",
              });
            }
          }
          setOpenPopupPhoto(false);
        }
        break;
      case eUploadMode.UPLOAD_WEB_LINK:
        if (!siteUserId) return;
        const requestBody = {
          WebLinkURI: data.WebLink,
          Tag: data?.Tag ?? "",
          WeblinkCategory_KWD: data?.WebLinkCategory?.Keyword_ID ?? null,
        } as IWebLink;
        const response = await saveDocumentAsWebLink(
          siteUserId,
          RECORDTYPE.CORE_Contact,
          requestBody
        );
        setIsLoadingUploadPhoto(false);
        if (isSuccessIdentityPacket(response)) {
          clearNotifications();
          const responseDocument = await getDocumentById(
            response.data?.ID,
            false
          );
          if (isSuccessResponse(responseDocument)) {
            onChange("Contact_SiteUser.Photo_Attachment", {
              value: responseDocument.data,
            });
            onChange("Contact_SiteUser.Photo_Attachment_ID", {
              value: response.data?.ID,
            });
            pushNotification({
              title: "Photo uploaded successfully",
              type: "success",
            });
          } else {
            pushNotification({
              autoClose: false,
              title: "Upload photo failed",
              type: "error",
            });
          }
        } else {
          pushNotification({
            autoClose: false,
            title: "Upload photo failed",
            type: "error",
            description: response?.data?.Errors ?? response?.statusText,
          });
        }
        setOpenPopupPhoto(false);
        break;
    }
  };

  const handleSaveSignature = async (data: IAddAttachmentDialog) => {
    setIsLoadingSignature(true);
    switch (data?.UploadMode) {
      case eUploadMode.UPLOAD_FILE:
        if (!isNil(data?.File) && isNil(data?.File[0].validationErrors)) {
          const fileEntry = await convertToFileStreamEntry(data?.File[0]);
          const attachments = {
            FileEntry: fileEntry,
            Title: data.Title ?? data?.File[0]?.name,
            Attachment_ID: 0,
            Category_KWD: data?.Category?.Keyword_ID ?? null,
            FileNumber: "",
            TRIMUri: "",
          } as IAttachment;

          const requestBody = {
            ParentID: getValue("Contact_SiteUser.Contact_ID"),
            ParentRecordType: RECORDTYPE.CORE_Contact,
            AttachmentList: [attachments],
            AddDateTimeToFileName: null,
          } as Svc_Attachment;
          const response = await saveDocument(requestBody);
          setIsLoadingSignature(false);
          if (isSuccessIdentityPacket(response)) {
            const responseDocument = await getDocumentById(
              response.data?.ID,
              false
            );
            if (isSuccessResponse(responseDocument)) {
              onChange("Contact_SiteUser.Sign_Attachment", {
                value: responseDocument.data,
              });
              onChange("Contact_SiteUser.Sign_Attachment_ID", {
                value: response.data?.ID,
              });
              clearNotifications();
              pushNotification({
                title: "Signature uploaded successfully",
                type: "success",
              });
            } else {
              pushNotification({
                autoClose: false,
                title: "Upload signature failed",
                type: "error",
              });
            }
          } else {
            if (response?.status === APIResponseStatus.EXCEEDED_SIZE_LIMIT) {
              pushNotification({
                autoClose: false,
                title: "Exceeded the specified size limit",
                type: "error",
              });
            } else {
              pushNotification({
                autoClose: false,
                title: "Upload signature failed",
                description: response.data?.Errors ?? response.statusText,
                type: "error",
              });
            }
          }
          setOpenPopupSignature(false);
        }
        break;
      case eUploadMode.UPLOAD_WEB_LINK:
        if (!siteUserId) return;
        const requestBody = {
          WebLinkURI: data.WebLink,
          Tag: data?.Tag ?? "",
          WeblinkCategory_KWD: data?.WebLinkCategory?.Keyword_ID ?? null,
        } as IWebLink;
        const response = await saveDocumentAsWebLink(
          siteUserId,
          RECORDTYPE.CORE_Contact,
          requestBody
        );
        setIsLoadingSignature(false);
        if (isSuccessIdentityPacket(response)) {
          clearNotifications();
          const responseDocument = await getDocumentById(
            response.data?.ID,
            false
          );
          if (isSuccessResponse(responseDocument)) {
            onChange("Contact_SiteUser.Sign_Attachment", {
              value: responseDocument.data,
            });
            onChange("Contact_SiteUser.Sign_Attachment_ID", {
              value: response.data?.ID,
            });
            clearNotifications();
            pushNotification({
              title: "Signature uploaded successfully",
              type: "success",
            });
          } else {
            pushNotification({
              autoClose: false,
              title: "Upload signature failed",
              type: "error",
            });
          }
        } else {
          pushNotification({
            autoClose: false,
            title: "Upload signature failed",
            type: "error",
            description: response?.data?.Errors ?? response?.statusText,
          });
        }
        setOpenPopupSignature(false);
        break;
    }
  };

  const handleOnPickOfficer = async (value: Svc_ContactDetails | null) => {
    if (!value) return;
    onChange("Contact_SiteUser.SiteUser_ActingOfficer_ID", {
      value: value.Id,
    });
    const response = await getSiteUserById(value.Id);
    if (isSuccessResponse(response) && response?.data) {
      onChange("Contact_SiteUser.SiteUser_ActingOfficer", {
        value: response.data.Contact_SiteUser,
      });
    } else {
      pushNotification({
        autoClose: false,
        title: "Pick replacement officer failed",
        type: "error",
      });
    }
  };

  const initialExchangeUserValues = useMemo(() => {
    const retVal = new UserCredentials();
    if (credentialUserName) {
      const domainUserName: string[] = credentialUserName.split("\\");
      if (domainUserName.length > 1) {
        retVal.Domain = domainUserName[0];
        retVal.UserName = domainUserName[1];
      } else {
        retVal.UserName = credentialUserName;
      }
    }
    return retVal;
  }, [credentialUserName]);

  const isShowClickOne = useMemo(() => {
    return recordsSystem === RECORDSSYSTEM.TRIMContext;
  }, [recordsSystem]);
  const isShowRecordsCredential = useMemo(() => {
    return (
      recordsSystem === RECORDSSYSTEM.TRIMContext ||
      recordsSystem === RECORDSSYSTEM.infoXpert
    );
  }, [recordsSystem]);

  const handleExchangeUserChange = (data: any) => {
    const newUserInfo: UserCredentials = data.value;
    if (newUserInfo) {
      if (isEmpty(newUserInfo.Domain)) {
        onChange("siteUserCredential.Username", {
          value: newUserInfo.UserName,
        });
      } else {
        onChange("siteUserCredential.Username", {
          value: `${newUserInfo.Domain}\\${newUserInfo.UserName}`,
        });
      }
      onChange("siteUserCredential.Password", {
        value: newUserInfo.Password,
      });
    } else {
      onChange("siteUserCredential.Username", {
        value: "",
      });
      onChange("siteUserCredential.Password", { value: "" });
    }
  };

  const initialRecordUserValues = useMemo(() => {
    const retVal = new UserCredentials();
    if (contactUserName) {
      const domainUserName: string[] = contactUserName.split("\\");
      if (domainUserName.length > 1) {
        retVal.Domain = domainUserName[0];
        retVal.UserName = domainUserName[1];
      } else {
        retVal.UserName = contactUserName;
      }
    }
    retVal.Title = "Records Credentials";
    return retVal;
  }, [contactUserName]);

  const handleRecordsUserChange = (data: any) => {
    const newUserInfo: UserCredentials = data.value;
    if (newUserInfo) {
      if (isEmpty(newUserInfo.Domain)) {
        onChange("Contact_SiteUser.DocMgmt_Username", {
          value: newUserInfo.UserName,
        });
      } else {
        onChange("Contact_SiteUser.DocMgmt_Username", {
          value: `${newUserInfo.Domain}\\${newUserInfo.UserName}`,
        });
      }
      onChange("Contact_SiteUser.DocMgmt_Password", {
        value: newUserInfo.Password,
      });
    } else {
      onChange("Contact_SiteUser.DocMgmt_Username", {
        value: "",
      });
      onChange("Contact_SiteUser.DocMgmt_Password", { value: "" });
    }
  };

  const clickAddAttachment = (mode: boolean = false) => {
    if (isNew) {
      let infoMessage = "Please save the site user before attaching a photo";
      if (mode) {
        infoMessage = "Please save the site user before attaching a signature";
      }
      pushNotification({
        type: "info",
        title: infoMessage,
      });
    }
  };
  const checkAttachmentField = (field: string) => {
    return getValue(field)?.FileEntry?.FileAttachment;
  };

  const requiredStringValidator = useCallback(
    (value: any) => {
      return userNameDisplayValue && !isEmpty(userNameDisplayValue.trim())
        ? ""
        : "This field is required.";
    },
    [userNameDisplayValue]
  );

  const onClickCheckCredential = async () => {
    if (!siteUserId || !userEmail) return;
    setCheckingVerifyCredential(true);
    const response = await getVerifyCredential(siteUserId, userEmail);
    if (
      isSuccessResponse(response) &&
      response?.data?.Status === VerifyCredentialResult.Successful
    ) {
      setVerifyCredential(true);
      pushNotification({
        title: response?.data?.NotificationMessage || "Verification Successful",
        type: "success",
      });
    } else {
      setVerifyCredential(false);
      pushNotification({
        autoClose: false,
        title: response?.data?.NotificationMessage || "Verification Failed",
        type: "error",
      });
    }
    setShowVerifyCredential(true);
    setCheckingVerifyCredential(false);
  };

  return (
    <div className="cc-profile-form-element">
      <FormElement>
        {isOnLeaveField && (
          <>
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Replacement officer" />
                  <Field
                    name={"Contact_SiteUser.SiteUser_ActingOfficer"}
                    placeholder={"Replacement officer"}
                    component={ContactPicker}
                    onPickOfficer={handleOnPickOfficer}
                    textField={nameOfContactPick("DisplayName")}
                  />
                </div>
                <div className="cc-field cc-col-span-2">
                  <CCLabel title="On leave" />
                  <Field
                    name={"Contact_SiteUser.Flag_OnLeave"}
                    component={CCSwitch}
                    checked={isOnLeaveField}
                    onChange={(event: SwitchChangeEvent) => {
                      onChange("Contact_SiteUser.Flag_OnLeave", {
                        value: event.value,
                      });
                      setIsOnLeave(event.value);
                    }}
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Leave start date" isMandatory />
                  <Field
                    name={"Contact_SiteUser.OnLeave_StartDate"}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                    validator={requiredValidator}
                    max={leaveEndDate ? new Date(leaveEndDate) : undefined}
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Leave end date" isMandatory />
                  <Field
                    name={"Contact_SiteUser.OnLeave_EndDate"}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                    validator={requiredValidator}
                    min={leaveStartDate ? new Date(leaveStartDate) : undefined}
                  />
                </div>
              </div>
            </section>
            <hr className="cc-divider" />
          </>
        )}

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Login name
                <CCTooltip
                  type="validator"
                  content={formRenderProps.errors.loginName}
                />
              </label>
              <Field
                name={nameOf("UserName")}
                component={CCInput}
                placeholder={"Login name"}
                validator={requiredStringValidator}
                defaultValue={userNameDisplayValue}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Change password</label>

              <Field
                name={"Contact_SiteUser.bResetPasswordOnFirstLogon"}
                checked={getValue(
                  "Contact_SiteUser.bResetPasswordOnFirstLogon"
                )}
                onChange={(event: SwitchChangeEvent) => {
                  onChange("Contact_SiteUser.bResetPasswordOnFirstLogon", {
                    value: event.value,
                  });
                  onChange("bResetPasswordOnFirstLogon", {
                    value: event.value,
                  });
                }}
                component={CCSwitch}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Salutation</label>
              <Field
                name={"Contact_SiteUser.Salutation"}
                textField="Value"
                dataItemKey="Key"
                data={siteUserLovs?.Salutation ?? []}
                component={CCSearchComboBox}
                value={getDropdownValue(
                  +(getValue("Contact_SiteUser.Salutation") ?? "0"),
                  siteUserLovs?.Salutation ?? [],
                  "Key"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  formRenderProps.onChange("Contact_SiteUser.Salutation", {
                    value: event.value?.Key ?? null,
                  });
                }}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                First name
                <CCTooltip
                  type="validator"
                  content={formRenderProps.errors.firstName}
                />
              </label>
              <Field
                name={"Contact_SiteUser.FirstName"}
                component={CCInput}
                placeholder={"First name"}
                validator={requiredValidator}
                onChange={handleOnChangeFirstName}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">
                Last name
                <CCTooltip
                  type="validator"
                  content={formRenderProps.errors.lastName}
                />
              </label>
              <Field
                name={"Contact_SiteUser.LastName"}
                component={CCInput}
                placeholder={"Last name"}
                validator={requiredValidator}
                onChange={handleOnChangeLastName}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">
                Display name
                <CCTooltip
                  type="validator"
                  content={formRenderProps.errors.displayName}
                />
              </label>

              <Field
                name="Contact_SiteUser.DisplayName"
                component={CCInput}
                placeholder={"Display name"}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Identity card number</label>

              <Field
                name="Contact_SiteUser.IDCardNumber"
                component={CCInput}
                placeholder={"Identity card number"}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <Field
                label="Email"
                tooltip={
                  <CCTooltip
                    type="validator"
                    content={formRenderProps.errors.email}
                  />
                }
                name="Contact_SiteUser.Email"
                placeholder={"Email"}
                component={CCInputEmail}
                validator={validateEmailRequired}
                onChangeForm={onChange}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Work phone</label>
              <Field
                name="Contact_SiteUser.WorkPhone"
                placeholder={"Work phone"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={formRenderProps.onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.WorkPhone}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Mobile</label>
              <Field
                name="Contact_SiteUser.Mobile"
                placeholder={"Mobile"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={formRenderProps.onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Mobile}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Home phone</label>
              <Field
                name="Contact_SiteUser.HomePhone"
                placeholder={"Home phone"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={formRenderProps.onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.HomePhone}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Fax</label>
              <Field
                name="Contact_SiteUser.Fax"
                placeholder={"Fax"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Fax}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Postal address</label>
              <Field
                name="Contact_SiteUser.PostalAddressName"
                component={AddressPicker}
                buildAddress={defaultBuildAddress}
                placeholder="Postal address"
                value={getValue("Contact_SiteUser.PostalAddress")}
                onChangeDataSearch={(data: any) => {
                  formRenderProps.onChange(
                    "Contact_SiteUser.PostalAddressName",
                    {
                      value: getFullAddressHTML(data),
                    }
                  );
                  formRenderProps.onChange("Contact_SiteUser.PostalAddress", {
                    value: data,
                  });
                }}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Location</label>

              <Field
                name="Contact_SiteUser.LocationName"
                component={CCInput}
                placeholder={"Location"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Position</label>

              <Field
                name="Contact_SiteUser.PositionName"
                component={CCInput}
                placeholder={"Position"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Position number</label>

              <Field
                name="Contact_SiteUser.PositionNumber"
                component={CCInput}
                placeholder={"Position number"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Theme</label>

              <Field
                name={"Contact_SiteUser.Theme_LKP"}
                textField="Theme_DisplayName"
                dataItemKey="Theme_LKP_ID"
                data={siteUserLovs?.Theme || []}
                component={CCSearchComboBox}
                isUseDefaultOnchange
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Photo</label>
              <div className="cc-image-upload">
                <div className="cc-image-upload-control">
                  {checkAttachmentField("Contact_SiteUser.Photo_Attachment") ? (
                    <i
                      className="fa fa-times fa-2x"
                      onClick={() => {
                        formRenderProps.onChange(
                          "Contact_SiteUser.Photo_Attachment",
                          {
                            value: "",
                          }
                        );
                        formRenderProps.onChange(
                          "Contact_SiteUser.Photo_Attachment_ID",
                          {
                            value: "",
                          }
                        );
                      }}
                      title="Remove"
                    />
                  ) : (
                    <i
                      className="fa fa-upload fa-2x"
                      onClick={() => {
                        setOpenPopupPhoto(isNew ? false : true);
                        clickAddAttachment();
                      }}
                      title="Upload"
                    />
                  )}
                </div>
                <img
                  src={
                    checkAttachmentField("Contact_SiteUser.Photo_Attachment")
                      ? !photoAttachment?.FileEntry?.FileAttachment.toString().includes(
                          "blob:"
                        )
                        ? `data:${photoAttachment?.FileEntry?.ContentType};base64,` +
                          photoAttachment.FileEntry?.FileAttachment
                        : photoAttachment.FileEntry?.FileAttachment
                      : AvatarDefault
                  }
                  className="cc-image-review"
                  alt="Attachment Photos"
                />
              </div>
            </div>
            <div className="cc-field">
              <label className="cc-label">Signature</label>
              <div className="cc-image-upload">
                <div className="cc-image-upload-control">
                  {checkAttachmentField("Contact_SiteUser.Sign_Attachment") ? (
                    <i
                      className="fa fa-times fa-2x"
                      onClick={() => {
                        formRenderProps.onChange(
                          "Contact_SiteUser.Sign_Attachment",
                          {
                            value: "",
                          }
                        );
                        formRenderProps.onChange(
                          "Contact_SiteUser.Sign_Attachment_ID",
                          {
                            value: "",
                          }
                        );
                      }}
                      title="Remove"
                    />
                  ) : (
                    <i
                      className="fa fa-upload fa-2x"
                      onClick={() => {
                        setOpenPopupSignature(isNew ? false : true);
                        clickAddAttachment(true);
                      }}
                      title="Upload"
                    />
                  )}
                </div>
                <img
                  src={
                    checkAttachmentField("Contact_SiteUser.Sign_Attachment")
                      ? !signAttachment?.FileEntry?.FileAttachment.toString().includes(
                          "blob:"
                        )
                        ? `data:${signAttachment?.FileEntry?.ContentType};base64,` +
                          signAttachment.FileEntry?.FileAttachment
                        : signAttachment.FileEntry?.FileAttachment
                      : SignatureDefault
                  }
                  className="cc-image-review"
                  alt="Attachment Signature"
                />
              </div>
            </div>
          </div>

          {/*end info*/}
        </section>

        {isShowClickOne && (
          <>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              <div className="cc-field">
                <CCLabel
                  title="ClickOnce - Version"
                  // eslint-disable-next-line no-octal-escape
                  informationTooltip="After switching the ClickOnce version, please delete the previous installation from your machine - C:\Users\{username}\AppData\Local\Apps\2.0"
                />

                <Field
                  name={"Contact_SiteUser.ClickOnceVersion"}
                  textField="Value"
                  dataItemKey="Key"
                  data={siteUserLovs?.ClickOnceVersion || []}
                  component={CCSearchComboBox}
                  isUseDefaultOnchange
                />
              </div>
              <div className="cc-field">
                <CCLabel
                  title="Client binaries load path (for TRIM .NET only)"
                  informationTooltip="Leave empty to use the default Client Binaries Load Path from the System configuration"
                />
                <Field
                  name={"Contact_SiteUser.TRIMClientBinariesLoadPath"}
                  component={CCInput}
                />
              </div>
            </section>
          </>
        )}

        {isShowRecordsCredential && (
          <>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Exchange username</label>
                  <Field
                    name="siteUserCredential.Username"
                    component={CredentialsPicker}
                    placeholder="Exchange username"
                    onChange={handleExchangeUserChange}
                    initialValue={initialExchangeUserValues}
                    showClearButton
                  />
                </div>
                <CCValueField
                  label="Exchange password"
                  value={!isEmpty(credentialPassword) ? "**********" : ""}
                />
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Records username</label>
                  <Field
                    name="Contact_SiteUser.DocMgmt_Username"
                    component={CredentialsPicker}
                    placeholder="Records username"
                    onChange={handleRecordsUserChange}
                    initialValue={initialRecordUserValues}
                    showClearButton
                  />
                </div>
                <CCValueField
                  label="Records password"
                  value={!isEmpty(contactPassword) ? "**********" : ""}
                />
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <div>
                    <Button type="button" onClick={onClickCheckCredential}>
                      {checkingVerifyCredential ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : null}
                      {!checkingVerifyCredential && showVerifyCredential && (
                        <>
                          {verifyCredential ? (
                            <i className="fa fa-check" />
                          ) : (
                            <i className="fa fa-times" />
                          )}
                        </>
                      )}{" "}
                      Check Credential
                    </Button>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </FormElement>

      {isOpenPopupPhoto && !isNew && (
        <AddAttachmentDialog
          onClose={() => setOpenPopupPhoto(false)}
          onSubmit={handleSaveAttachmentPhoto}
          isLoadingButton={isLoadingUploadPhoto}
          isAllowMultiple={false}
          allowedFileExtensions={"jpg|png|jpeg|bmp|gif"}
          isDisableWebLink
        />
      )}

      {isOpenPopupSignature && !isNew && (
        <AddAttachmentDialog
          onClose={() => setOpenPopupSignature(false)}
          onSubmit={handleSaveSignature}
          isLoadingButton={isLoadingSignature}
          isAllowMultiple={false}
          allowedFileExtensions={"jpg|png|jpeg|bmp|gif"}
          isDisableWebLink
        />
      )}
    </div>
  );
};

export default observer(ProfileFormElement);
