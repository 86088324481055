import { IAttachment } from "@app/core/attachment/components/buttons/add-attachment/model";
import { convertToFileStreamEntry } from "@app/core/attachment/components/dialogs/util";
import { EventMapObj } from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";

import {
  ATTACHMENT_STEP,
  AttachmentStep,
} from "@app/products/crms/components/dialogs/new-simple-event/components/attachment/_index";
import {
  DETAIL_STEP,
  DetailStep,
} from "@app/products/crms/components/dialogs/new-simple-event/components/details/_index";
import {
  LOCATION_STEP,
  LocationStep,
} from "@app/products/crms/components/dialogs/new-simple-event/components/location/_index";

import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { Button } from "@progress/kendo-react-buttons";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef } from "react";

export interface INewSimpleEventProps {
  onClose: () => void;
}

export const NewSimpleEventDialog = observer((props: INewSimpleEventProps) => {
  const { onClose } = props;
  const {
    loadCRMSSimpleEvent,
    crmsEvent,
    saveSimpleEvent,
    isLoading,
    isLoadingSubmitSimpleEvent,
    responseLoadError,
  } = useCRMSEventStore();
  const notificationFormStepRef = useRef<ICCFormStepNotificationHandle | null>(
    null
  );

  const initialValues: EventMapObj = useMemo(() => {
    return { Event: crmsEvent } as EventMapObj;
  }, [crmsEvent]);

  const steps: IStep[] = [
    {
      label: "Details",
      component: DetailStep,
      visible: true,
      key: DETAIL_STEP,
    },
    {
      label: "Location",
      component: LocationStep,
      visible: true,
      key: LOCATION_STEP,
    },
    {
      label: "Attachment",
      component: AttachmentStep,
      visible: true,
      key: ATTACHMENT_STEP,
    },
  ];

  const handleSubmit = async (data: any) => {
    const attachmentsValidated = data.Event.Attachment?.filter((item: any) =>
      isNil(item.validationErrors)
    );

    const attachmentsConverted = await Promise.all(
      attachmentsValidated?.map(async (item: any) => {
        return {
          Attachment_ID: 0,
          Category_KWD: null,
          FileEntry: await convertToFileStreamEntry(item),
          TRIMUri: "",
          Title: data.Title ?? item?.name,
        } as IAttachment;
      })
    );

    const event = { ...data.Event, Attachment: attachmentsConverted };
    saveSimpleEvent(
      event,
      notificationFormStepRef.current?.getNotificationFormStep()
    );
  };

  return (
    <CCFormStep
      ref={notificationFormStepRef}
      onSubmit={handleSubmit}
      initialSteps={steps}
      initialValues={initialValues}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="60%"
          maxHeight="70%"
          titleHeader={"New Event"}
          onClose={onClose}
          bodyElement={
            responseLoadError ? (
              <CCLoadFailed
                responseError={responseLoadError}
                onReload={loadCRMSSimpleEvent}
              />
            ) : (
              renderProps.children
            )
          }
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              <Button
                className={"cc-dialog-button"}
                onClick={onClose}
                disabled={isLoading || isLoadingSubmitSimpleEvent}
              >
                Cancel
              </Button>
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                  disabled={isLoading || isLoadingSubmitSimpleEvent}
                >
                  Previous
                </Button>
              )}
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                iconClass={
                  isLoadingSubmitSimpleEvent ? "fas fa-spinner fa-spin" : ""
                }
                disabled={
                  renderProps.nextButton.disabled ||
                  isLoading ||
                  isLoadingSubmitSimpleEvent
                }
                className={"cc-dialog-button"}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.isLastStep
                  ? "Submit event"
                  : renderProps.nextButton.label}
              </Button>
            </div>
          }
        />
      )}
    />
  );
});
