import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

interface INewDebtRecoveryButtonProps {
  isHidden?: boolean;
}

export const NewDebtRecoveryButton = observer(
  ({ isHidden = false }: INewDebtRecoveryButtonProps) => {
    const { gridSelectedRows, isEmptyData } = useCCProductListViewStore();
    const { setListDialog } = usePropertyWorkflowStore();
    const { id } = useParams<{ id: string }>();

    if (isHidden) return null;

    const isDisabled = useMemo(() => {
      return (
        (!isEmptyData && gridSelectedRows.length !== 1 && !id) ||
        (!id && isEmptyData)
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridSelectedRows.length, id, isEmptyData]);

    return (
      <CCNavButton
        title="New debt recovery"
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Create_DebtRecovery,
              data: {},
            },
          ]);
        }}
        disabled={isDisabled}
      />
    );
  }
);
