import { feeSetupRoute } from "@app/products/local-laws/fee-setup/route";
import { llsPermitsRoute } from "@app/products/local-laws/lls-permits/route";
import { llsTendersRoute } from "@app/products/local-laws/tenders/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";
import { complaintsRoute } from "./complaints/route";
import { complianceNoticesRoute } from "./compliance-notices/route";
import { crmsApplicationsRoute } from "./crms-applications/route";
import { financeRoute } from "./finance/route";
import { inspectionsRoute } from "./inspections/route";
import { permitsRoute } from "./permits/route";
import { renewalsRoute } from "./renewals/route";
import { systemAdminRoute } from "./system-admin/route";

export const localLawsRoute: ICCRoute = {
  path: "local-laws",
  name: "Local Laws",
  enum: eProductType.LLPermits,
  children: [
    permitsRoute,
    renewalsRoute,
    inspectionsRoute,
    complaintsRoute,
    complianceNoticesRoute,
    crmsApplicationsRoute,
    financeRoute,
    systemAdminRoute,
    llsPermitsRoute,
    llsTendersRoute,
    feeSetupRoute,
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
