import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { getViewConfigurations } from "@app/products/property/api";
import { ChangeOfOwnerShipButton } from "@app/products/property/assessments/components/action-bar/buttons/change-of-ownership/_index";
import { NewAssessmentButton } from "@app/products/property/assessments/components/action-bar/buttons/new-assessment/_index";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ECustomColNameProperty } from "@app/products/property/config";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { CreatePICButton } from "@app/products/property/pic/list/components/action-bar/buttons/new-pic/_index";
import { propertyRoute } from "@app/products/property/route";
import { TitlesAssociationsTab } from "@app/products/property/titles/[id]/components/reference-sidebar/associations/_index";
import { TitleDetailTab } from "@app/products/property/titles/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_TITLES_ROUTE } from "@app/products/property/titles/[id]/constant";
import { ModifyTitleButton } from "@app/products/property/titles/list/components/action-bar/buttons/modify-title/_index";
import { CreateTitleButton } from "@app/products/property/titles/list/components/action-bar/buttons/new-title/_index";
import { colList } from "@app/products/property/titles/list/config";
import { GET_VIEW_PROPERTY_TITLES_LIST } from "@app/products/property/titles/list/constant";
import { VO_Title } from "@app/products/property/titles/list/model";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/products/property/titles/list/util";
import { titlesRoute } from "@app/products/property/titles/route";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
} from "@app/products/property/titles/util";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { Label } from "@common/stores/products/config";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_Title>();
export default observer(() => {
  const { currentOrganisationMode } = useCommonProductStore();
  const { gridSelectedIds } = useCCProductListViewStore();
  const isLLS = currentOrganisationMode(OrganisationMode.LLS);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedColumns, setProcessedColumns] =
    useState<IColumnFields[]>(colList);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const titleLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Title
  );

  const loadViewConfiguration = () => {
    setIsLoading(true);
    getViewConfigurations(ViewConfiguration.Titles_List).then((response) => {
      if (isSuccessResponse(response)) {
        const viewConfig: ICCViewColumn[] | undefined =
          response?.data?.ColumnDefinitions?.Columns;
        if (!viewConfig || viewConfig?.length === 0) {
          setProcessedColumns([]);
          setIsLoading(false);
          return;
        }
        setProcessedColumns(processDynamicColumns(colList, viewConfig));
      } else {
        setResponseLoadError({
          status: response.status,
          error: response.error ?? "Load failed",
        });
      }
      setIsLoading(false);
    });
  };

  useCCListViewActionBar({
    title: propertyRoute.name,
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <CCNavButton title={titleLabel} type="dropdown">
          <CreateTitleButton />
          <ModifyTitleButton />
        </CCNavButton>
        <NewAssessmentButton />
        <ChangeOfOwnerShipButton componentNumber={eComponent.Title} />
        {isLLS ? (
          <CCNavButton title={"PIC"} type="dropdown">
            <CreatePICButton
              titleIds={gridSelectedIds}
              isDisabled={gridSelectedIds?.length < 1}
            />
          </CCNavButton>
        ) : (
          <></>
        )}
      </CCNavButton>,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Title}
        recordType={RECORDTYPE.CommunityProperty_Title}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Title}
        keyField={"Title_Id"}
      />,
    ],
    leftComponents: [
      <PropertyActionBarNavDropdown category={titlesRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_TITLES_ROUTE}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <TitleDetailTab /> },
      {
        title: "Map",
        //TODO: Update after api add location field
        component: <MapTab />,
      },
      {
        title: "Contacts",
        component: <PropertyContactTab componentNumber={eComponent.Title} />,
      },
      {
        title: "Related",
        component: <TitlesAssociationsTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab recordType={RECORDTYPE.CommunityProperty_Title} />
        ),
      },
    ],
  });

  useEffectOnce(() => {
    loadViewConfiguration();
  });

  if (isLoading) return <Loading isLoading isFullScreen />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfiguration();
        }}
      />
    );

  return (
    <CCProductListView
      dataUrl={GET_VIEW_PROPERTY_TITLES_LIST}
      columnFields={processedColumns}
      primaryField={nameOf("Title_Id")}
    />
  );
});
