import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { DTO_LOV } from "@common/models/odataResponse";

export interface IWebLink {
  WebLink_ID?: number;
  WebLinkURI?: string;
  Tag?: string;
  WeblinkCategory_KWD?: number | null;
  BubbleUps?: BubbleUpIdentifier[];
}

export interface Svc_Attachment {
  ComponentID: number | null;
  RecordType: RECORDTYPE | null;
  DocumentType: number | null;
  Workflow_Draft_Id: number | null;
  AttachmentList: Attachment[];
}

export interface Attachment {
  Attachment_ID: number;
  Title: string;
  FileEntry: FileStreamEntry;
}

export interface FileStreamEntry {
  FileName: string;
  ContentType: string;
  FileSize: number;
  FileAttachment: string;
}

export enum eDocumentTypeCategory {
  Unknown = 0,
  Assessment_Document = 1,
  Parcel_Document = 2,
  Title_Document = 3,
  Certificate_Document = 4,
  Master_Property_Document = 5,
  Debt_Recovery_Document = 6,
  Deferred_Duty_Document = 7,
  Notice_Run_Document = 8,
  Supplementary_Rates_Document = 9,
  Journal_Document = 10,
  Communication_Template_Document = 11,
  Contact_Document = 12,
  Charge_Run_Document = 13,
  Workflow_Document = 14,
  PIC_Document = 15,
}

export interface DTO_DocumentType_LOVs {
  Document_Type: DTO_LOV[];
}
