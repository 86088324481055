import { useAnimalsSettingStore } from "@app/products/animals/system-admin/settings/_category/store";
import { DynamicGridFields } from "@app/products/animals/system-admin/settings/_id/components/dynamic-grid-fields/_index";
import { AnimalRenderSwitch } from "@app/products/animals/system-admin/settings/_id/components/switch/_index";
import {
  switchOptionsValueBooleanString,
  switchOptionsValueNumberString,
} from "@app/products/animals/system-admin/settings/_id/main/config";
import { ICommonFormProps } from "@app/products/animals/system-admin/settings/_id/main/model";
import { useAnimalsSettingManageStore } from "@app/products/animals/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { NumericTextboxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/numeric-textbox/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { getConfigDataField } = useAnimalsSettingStore();
  const { isEditing } = useAnimalsSettingManageStore();
  const { valueGetter } = formRenderProps;

  const eEmailDueInspectionPlanners =
    ECorporateSettingsField.Animals_EmailDueInspectionPlanners;
  const eEnableInspectionPlanner =
    ECorporateSettingsField.Animals_EnableInspectionPlanner;

  const dataActualDueDaysInspectionPlanner = getConfigDataField(
    ECorporateSettingsField.Animals_ActualDueDaysInspectionPlanner
  );

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <AnimalRenderSwitch
          switchList={[
            {
              eSettingsField: eEnableInspectionPlanner,
            },
          ]}
          formRenderProps={formRenderProps}
          optionsValue={switchOptionsValueNumberString}
        />

        {valueGetter(`${eEnableInspectionPlanner}`) ===
        switchOptionsValueNumberString.TRUE ? (
          <>
            <AnimalRenderSwitch
              switchList={[
                {
                  eSettingsField: eEmailDueInspectionPlanners,
                },
              ]}
              formRenderProps={formRenderProps}
              optionsValue={switchOptionsValueBooleanString}
            />

            {valueGetter(`${eEmailDueInspectionPlanners}`) ===
            switchOptionsValueBooleanString.TRUE ? (
              <NumericTextboxGlobalSettings
                data={dataActualDueDaysInspectionPlanner}
                isEditing={isEditing}
                suffixCustom="Days"
              />
            ) : null}
          </>
        ) : null}

        <AnimalRenderSwitch
          switchList={[
            {
              eSettingsField:
                ECorporateSettingsField.Animals_EnableCalendarSubscription,
            },
          ]}
          formRenderProps={formRenderProps}
          optionsValue={switchOptionsValueNumberString}
        />
      </div>

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <DynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
