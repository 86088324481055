import {
  EnterpriseRoleFunction,
  LoginAuthenticationType,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { CredentialType, ProductRole } from "@common/constants/enumerations";
import { PRODUCT_TYPE } from "@common/constants/productType";
import {
  DBRowState,
  DTOBaseClass_Standard,
} from "@common/models/baseClassStandard";
import { Contact } from "@common/models/contact";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { CorporateAuth } from "@common/pages/settings/security/corporate-authorisations/_id/model";

export class NewSiteUser {
  AuthenticationType?: LoginAuthenticationType;
  UserName?: string;
  UserPassword?: string;
  PasswordQuestion?: string;
  PasswordAnswer?: string;
  bResetPasswordOnFirstLogon: boolean = false;
  EnterpriseKey_Set?: string;
  EnterpriseRole_Set?: EnterpriseRoleFunction;
  Contact_SiteUser?: SiteUser;
  siteUserCredential?: SiteUserCredential;
  IsUserLockedOut?: boolean;
  _option?: {
    Contact_SiteUser: {
      Sys_DBRowState: boolean;
    };
  };
  constructor() {
    this.EnterpriseKey_Set = "00000000-0000-0000-0000-000000000000";
    this.EnterpriseRole_Set = EnterpriseRoleFunction.ProductUser;
    this.siteUserCredential = new SiteUserCredential();
    this._option = {
      Contact_SiteUser: { Sys_DBRowState: false },
    };
  }
}

export class SiteUserCredential {
  Credential_Id: number = 0;
  SiteUser_Id: number = 0;
  CredentialType_ENUM?: CredentialType;
  Sys_CreatedDate?: string;
  Username?: string;
  Password?: string;
  Sys_TimeStamp?: string;
}

export class ContactSiteUser {
  _ProductRole: any[] = [];
  _LoginProviders: any[] = [];
  _Products: any[] = [];
  _CorporateAuths: CorporateAuth[] = [];
  _SecurityTemplates?: SecurityTemplate[]; // = [];
  _Special_SecurityTemplates?: SecurityTemplate[]; // = [];
  _OrgUnits: any[] = [];
  _SecurityOrgUnits: any[] = [];
  _HomePageFunctions?: HomePageFunction[]; // = [];
  _CorpAuth_HomePageFunctions: any[] = [];
  _HomePageViews?: HomePageView[]; // = [];
  _CorpAuth_HomePageViews: any[] = [];
  Sys_TimeStamp?: string;
  _WorkPhone?: string;
  _HomePhone?: string;
  _Fax?: string;
  _Mobile?: string;
  _ExtensionType: string[] = [];
  _SaveTriggers: any[] = [];
  MemberUserName?: string;
  MemberGUID?: string;
  EnterpriseRole_ENUM?: string;
  Salutation?: string;
  Staff_ID?: string;
  MiddleName?: string = " ";
  LocationName?: string;
  PositionName?: string;
  PositionNumber?: string;
  DefaultActionPage_ENUM?: string;
  Manager?: string;
  Flag_Councillor?: boolean = false;
  Flag_ExternalUser?: boolean = false;
  Ward?: string;
  Flag_CRMS_StandardForm?: boolean = false;
  Flag_CRMS_Escalation_ActionOfficer_OutstandingNotifications?: boolean = false;
  ClickOnceVersion?: string;
  bResetPasswordOnFirstLogon?: boolean;
  EnterpriseKey_Set?: string;
  EnterpriseRole_Set?: string;
  DocMgmt_Username?: string;
  DocMgmt_Password?: string;
  Contact_ID?: number = 0;
  DisplayName?: string;
  FirstName?: string;
  LastName?: string;
  ContactClassification_ENUM?: string;
  PreferredMethod_ENUM?: string;
  Flag_SendSMSAlerts?: boolean;
  Email?: string;
  ContactRoles_Count?: number;
  AddressBook?: AddressBook; // = new AddressBook();
  AddressBookFlag_ENUM?: string;
  CopiedFrom_Contact_ID?: number;
  SynchDate?: string;
  Extension?: Extension; // = new Extension();
  IsContactCopied?: boolean;
  ABNValid?: boolean;
  ACNValid?: boolean;
  Sys_EnterpriseKey?: string;
  Sys_CreatedDate?: string;
  Sys_CreatedBy?: string;
  Sys_CreatedBy_ID?: number;
  Sys_DBRowState?: string;
  Sys_TransactionDate?: string;
  Sys_ModifiedDate?: string;
  Sys_ModifiedBy_ID?: number;
  Sys_StatClockStatus_ENUM?: string;
  Flag_OnLeave: boolean = false;
  VIP?: boolean;
  Flag_Duplicate?: boolean;
  IDCardNumber?: string;
  Photo_Attachment_ID?: number;
  Sign_Attachment_ID?: number;
  Photo_Attachment?: Attachment;
  Sign_Attachment?: Attachment;
  UserName?: string;
}

export class Extension {}

export class AddressBook {
  Sys_TimeStamp?: string;
  _ExtensionType: any[] = [];
  Sys_EnterpriseKey?: string;
  Sys_TransactionDate?: string;
  AddressBook_ID?: number;
  AddressBook_Name?: string;
  AddressBookSection_ENUM?: string;
  ProductType_ENUM?: string;
  IsSystemRecord?: boolean;
}

export class HomePageView {
  Sys_TimeStamp?: string;
  View_Id?: number;
  ProductType_ENUM?: string;
  ViewHeading?: string;
  SortIndex?: number = 0;
  SQLCommandText?: string;
  FormIdentifier_ENUM?: string;
  FormAction_ENUM?: string;
  Sys_EnterpriseKey?: string;
  Sys_CreatedDate?: string;
  Sys_CreatedBy?: string;
  Sys_CreatedBy_ID?: number = 0;
  Sys_DBRowState?: string;
  Sys_TransactionDate?: string;
  Sys_ModifiedDate?: string;
  Sys_ModifiedBy_ID?: number = 0;
  Sys_StatClockStatus_ENUM?: string;
}

export class HomePageFunction {
  Sys_TimeStamp?: string;
  Function_Id?: number = 0;
  ProductType_ENUM?: string;
  FunctionHeading?: string;
  FunctionDescription?: string;
  ImageURL?: string;
  NavigateToForm?: boolean = false;
  NavigationURL?: string;
  FormIdentifier_ENUM?: string;
  FormAction_ENUM?: string;
  SortIndex: number = 0;
  Sys_EnterpriseKey?: string;
  Sys_CreatedDate?: string;
  Sys_CreatedBy?: string;
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState?: string;
  Sys_TransactionDate?: string;
  Sys_ModifiedDate?: string;
  Sys_ModifiedBy_ID?: number = 0;
  Sys_StatClockStatus_ENUM?: string;
  OpenInDialog?: boolean = false;
  NavigationForm_ENUM?: string;
}

export class SecurityTemplate {
  Sys_TimeStamp?: string;
  SecurityTemplate_ID: number = 0;
  Sys_EnterpriseKey?: string;
  Sys_DBRowState?: string;
  Sys_TransactionDate?: string;
  TemplateName?: string;
  TemplateDescription?: string;
  ProductType_ENUM?: string;
  IsSystemRecord?: boolean = false;
  DeniedActions: any[] = [];
}

export class Attachment {
  FileEntry?: FileEntry;
  Tag?: string;
  Title?: string;
}

export class FileEntry {
  FileName?: any;
  ContentType?: string;
  FileSize?: number;
  FileAttachment?: string | ArrayBuffer | null;
}

export class ChangePassword {
  userName?: string;
  password?: string;
}

export interface SiteUserRegister {
  ID: string;
  LoginName: string;
  AssignPassword: string;
  ChangePassword: string;
  Salutaion: string;
  FirstName: string;
  LastName: string;
  DisplayName: string;
  StaffNumber: string;
  Email: string;
  Phone: string;
  Mobile: string;
  HomePhone: string;
  Fax: string;
  Location: string;
  Position: string;
  PositionNumber: string;
  Manager: string;
  IndentityCardNumber: string;
}

export enum SiteUserSubmitActions {
  New = "New",
  Save = "Save",
}
export interface SiteUserLovs {
  Salutation: IKeyValuePacket[];
  Product: IKeyValuePacket[];
  DefaultPage: any[];
  DefaultActionPages: any[];
  DefaultClickOnceVersion: IKeyValuePacket[];
  HealthArea: IKeyValuePacket[];
  VIPTypes: IKeyValuePacket[];
  UserType: IKeyValuePacket[];
  PermitArea: IKeyValuePacket[];
  Theme: OOSys_Theme_LKP[];
  ClickOnceVersion: any[];
}

export interface OOSys_Theme_LKP {
  Sys_ModifiedBy: string;
  Sys_ModifiedBy_ID: number;
  Sys_ModifiedDate: Date;
  Sys_DBRowState: DBRowState;
  Sys_TransactionDate: Date;
  Theme_LKP_ID: number;
  Theme_Name: string;
  Theme_DisplayName: string;
  Platform: string;
}

export interface SiteUser extends Contact {
  MemberUserName: string;
  MemberGUID: string;
  EnterpriseRole_ENUM: EnterpriseRoleFunction;
  Salutation: string;
  MiddleName: string;
  Staff_ID: string;
  LocationName: string;
  PositionName: string;
  PositionNumber: string;
  Manager: string;
  ManagerUserName: string;
  Department: string;
  HomePage_ENUM: PRODUCT_TYPE | null;
  HomePage_Name: string;
  DefaultActionPage_ENUM: DefaultActionsPage | null;
  DefaultActionPage_Name: string;
  Org1: OrgStructure;
  Org2: OrgStructure;
  Org3: OrgStructure;
  PersonGroups: IKeyValuePacket[];
  Flag_Councillor: boolean | null;
  Flag_ExternalUser: boolean | null;
  Ward: string;
  Flag_CRMS_StandardForm: boolean | null;
  Flag_CRMS_Escalation_ActionOfficer_OutstandingNotifications: boolean | null;
  CRS_Centre_Access_Centre_ID: number | null;
  HealthArea_KWD: number | null;
  HealthArea_Name: string;
  PermitArea_KWD: number | null;
  PermitArea_Name: string;
  ClickOnceVersion: ClickOnceVersion | null;
  TRIMClientBinariesLoadPath: string;
  SSMSOrganisation_ENUM: SSMS_Organisation | null;
  SSMSOrganisation_Name: string;
  IsOpenOfficeAdmin: boolean | null;
  ProductRole: ProductRole[];
  SSMS_TeamLeaderID: number | null;
  SSMS_CouncilLeaderID: number | null;
  SSMSVICRoads_RegionID: number | null;
  SSMSCouncil_LGAID: number | null;
  LoginProviders: LoginAuthenticationProvider[];
  Products: PRODUCT_TYPE[];
  CorporateAuths: CorporateAuth[];
  SecurityTemplates: SecurityTemplate[];
  Special_SecurityTemplates: SecurityTemplate[];
  OrgUnits: IKeyValuePacket[];
  SecurityOrgUnits: IKeyValuePacket[];
  HomePageFunctions: HomePageFunction[];
  CorpAuth_HomePageFunctions: HomePageFunction[];
  HomePageViews: HomePageView[];
  CorpAuth_HomePageViews: HomePageView[];
  Special_HomePageFunctions: HomePageFunction[];
  Special_HomePageViews: HomePageView[];
  FullName_Short: string;
  FullName_Salutation: string;
  FullName: string;
  Theme_LKP: number | null;
  Theme_Name: string;
  ACO: boolean | null;
  UserName: string;
  UserPassword: string;
  PasswordQuestion: string;
  PasswordAnswer: string;
  bResetPasswordOnFirstLogon: boolean;
  EnterpriseKey_Set: string;
  EnterpriseRole_Set: EnterpriseRoleFunction;
  DocMgmt_Username: string;
  DocMgmt_Password: string;
  SiteUser_ActingOfficer_ID: number | null;
  SiteUser_ActingOfficer: SiteUser;
  Flag_OnLeave: boolean | null;
  OnLeave_StartDate: Date | null;
  OnLeave_EndDate: Date | null;
  //Add new field to show text on input picker
  PostalAddressName: string;
}

export enum DefaultActionsPage {
  SystemInitialise = 0,
  AllActions = 1,
  MyActions = 2,
}
export interface OrgStructure extends DTOBaseClass_Standard {
  ID: number;
  Sys_IsLatest: number;
  ModifiedDate: Date;
  ModifiedBy: string;
  Name: string;
  Level: number;
  ParentOrgStructure_ID: number | null;
  ParentOrgStructure_Name: string;
  Manager_ID: number | null;
  Manager_Name: string;
}

export enum ClickOnceVersion {
  InheritCorporateSettings = 0,
  SixtyFourBit = 64,
  ThirtyTwoBit = 32,
}

export enum SSMS_Organisation {
  VICRoads = 1,
  Council = 2,
  Installer = 3,
}
export interface LoginAuthenticationProvider {
  ID: number;
  EnterpriseKey: string;
  CreatedDate: Date;
  CreatedBy: string;
  CreatedBy_LID: number;
  AuthenticationType: LoginAuthenticationType;
  AuthenticationKey1: string;
  AuthenticationKey2: string;
}

export enum SITE_USER_DELETE_ITEMS {
  CORP_AUTHORISATIONS = 1,
  SECURITY_TEMPLATES = 2,
  ORG_STRUCTURE = 3,
  AD_HOC_GROUPS = 4,
  HOMEPAGE_SHORTCUTS = 5,
  HOMEPAGE_VIEWS = 6,
}
