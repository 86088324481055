import { VO_NoticeOfSale_Title } from "@app/products/property/changes-of-ownership/notice-of-sales/components/detail/components/lot-details/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_NoticeOfSale_Title>();
export const lotDetailsColumns: IColumnFields[] = [
  {
    field: nameOf("Formatted_Title_Reference"),
    title: "Formatted Title Reference",
  },
  {
    field: nameOf("Lot_Number"),
    title: "Lot Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Section_Number"),
    title: "Section Number",
  },
  {
    field: nameOf("Plan_Type"),
    title: "Plan Type",
  },
  {
    field: nameOf("Plan_Number"),
    title: "Plan Number",
  },
  {
    field: nameOf("Folio_Suffix"),
    title: "Folio Suffix",
  },
  {
    field: nameOf("Volume_Number"),
    title: "Volume Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Folio_Number"),
    title: "Folio Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("VF_Suffix"),
    title: "VF Suffix",
  },
  {
    field: nameOf("Auto_Consolidation_Flag"),
    title: "Auto Consolidate Flag",
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("LGA"),
    title: "LGA",
  },
];
