import { MailMerge_SelectMergeDocument } from "@app/core/new-mail-merge/dialogs/model";
import {
  getMailMergeDocumentsTemplate,
  getMailMergeDocumentTemplate,
} from "@app/core/new-mail-merge/form-steps/template/api";
import { colMailMergeDocumentGrid } from "@app/core/new-mail-merge/form-steps/template/config";
import { IMailMergeDocumentsTemplate } from "@app/core/new-mail-merge/form-steps/template/model";
import { RECORDSSYSTEM } from "@app/products/town-planning/ppr/[id]/model";
import { getKeywords } from "@common/apis/coreKeyword";
import { isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { APIResponseStatus } from "@common/constants/response-status";
import { CoreKeyword } from "@common/models/coreKeyword";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { customLogger } from "@common/utils/logger";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Field, FieldArray } from "@progress/kendo-react-form";
import _, { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { useEffectOnce } from "react-use";
import "./_index.scss";
export const MAIL_MERGE_TEMPLATE_FORM_STEP = "Template";
const pdfjsWorker = require('pdfjs-dist/build/pdf.worker.entry');
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const TemplateFormStep = observer((props: IFormStepElement) => {
  return <FieldArray name={""} {...props} component={FormStepElement} />;
});
const nameOf = nameOfFactory<MailMerge_SelectMergeDocument>();
const nameOfCoreKeyword = nameOfFactory<CoreKeyword>();
const nameOfMailMergeDocumentsTemplate =
  nameOfFactory<IMailMergeDocumentsTemplate>();

const FormStepElement = observer(
  ({
    options,
    formRenderProps,
    isLoadingStep,
    setIsLoadingStep = () => {},
    loadFailedStep,
    setLoadFailedStep = () => {},
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const { settings } = useCommonCoreStore();

    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false);
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const [numPages, setNumPages] = useState<number>(0);

    const recordsSystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
    );

    const isRequiredCategory =
      recordsSystem && recordsSystem === RECORDSSYSTEM.infoXpert ? true : false;
    const selectedDocumentIds: number[] =
      valueGetter(nameOf("SelectedDocumentIDs")) ?? [];

    const loadMailMergeDocumentsTemplate = async () => {
      setIsLoadingStep(true);
      const [resCategory, resTemplate] = await Promise.all([
        getKeywords(
          KEYWORD_TYPE.Core_DocumentCategory,
          options.productType ?? PRODUCT_TYPE_NUMBER.Core
        ),
        getMailMergeDocumentsTemplate(
          valueGetter(nameOf("Dataset_Filters")),
          options.productType,
          valueGetter(nameOf("DocumentIDs"))
        ),
      ]);
      customLogger("Core Mail Merge, getKeywords - Core_DocumentCategory").info(
        resCategory?.data
      );
      customLogger("Core Mail Merge, getMailMergeDocumentsTemplate").info(
        resTemplate?.data
      );
      setIsLoadingStep(false);
      if (isSuccessResponse(resCategory) && isSuccessResponse(resTemplate)) {
        if (resCategory.data && resTemplate.data) {
          onChange("_options.Categories", {
            value: resCategory.data,
          });
          onChange("_options.MailMergeDocumentsTemplate", {
            value: resTemplate.data.value,
          });
        }
      } else {
        setLoadFailedStep({
          onReload: () => {
            loadMailMergeDocumentsTemplate();
          },
          responseError: {
            status: APIResponseStatus.INTERNAL_SERVER_ERROR,
            error: "Server error",
          },
        });
      }
    };

    const handleOnSelectionChange = (
      dataItem: IMailMergeDocumentsTemplate[]
    ) => {
      onChange("_options.DocPreviewData", { value: null });
      const selectedMailMergeDocumentsTemplateIds: number[] =
        dataItem?.map(
          (item: IMailMergeDocumentsTemplate) => item?.MailMergeDocument_ID
        ) ?? [];

      onChange("_options.SelectedMailMergeDocumentsTemplate", {
        value: dataItem,
      });
      onChange(nameOf("SelectedDocumentID"), {
        value: selectedMailMergeDocumentsTemplateIds[0] ?? null,
      });
      onChange(nameOf("SelectedDocumentIDs"), {
        value: selectedMailMergeDocumentsTemplateIds ?? null,
      });
    };

    const onDocumentLoadSuccess = ({ numPages }: any) => {
      setNumPages(numPages);
    };

    const showAllPage = () => {
      const pages = Array.from(
        { length: numPages },
        (_, index) => index + 1
      ).map((page) => <Page className="cc-pdf-pages" pageNumber={page} />);
      return pages;
    };

    useEffectOnce(() => {
      loadMailMergeDocumentsTemplate();
    });

    useEffect(() => {
      onChange("_options.DocPreviewData", { value: null });
      if (!isEmpty(selectedDocumentIds) && options?.productType) {
        setShowPreview(true);
        setIsLoadingPreview(true);
        const lastTemplateId = _.last(selectedDocumentIds);
        if (lastTemplateId) {
          getMailMergeDocumentTemplate(
            lastTemplateId,
            options?.productType
          ).then((response) => {
            customLogger(
              "Core Mail Merge, Mail merge template, getMailMergeDocumentTemplate"
            ).info(response?.data);
            setIsLoadingPreview(false);
            if (isSuccessResponse(response)) {
              if (response?.data)
                onChange("_option.DocPreviewData", {
                  value: response?.data,
                });
            } else {
              notificationRef.current?.pushNotification({
                title: `Document template load failed`,
                type: "warning",
              });
            }
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDocumentIds]);

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }
    if (loadFailedStep) {
      return (
        <CCLoadFailed
          onReload={loadFailedStep?.onReload}
          responseError={loadFailedStep?.responseError}
        />
      );
    }

    return (
      <div className="cc-mail-merge-selection-container">
        <CCLocalNotification ref={notificationRef} />
        <div className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">
                Category
                {isRequiredCategory && (
                  <CCTooltip type="validator" position="right" />
                )}
              </label>
              <Field
                className="cc-dropdown"
                name={nameOf("Category_KWD")}
                data={valueGetter("_options.Categories") ?? []}
                textField={nameOfCoreKeyword("Name")}
                dataItemKey={nameOfCoreKeyword("Keyword_ID")}
                isUseDefaultOnchange
                component={CCSearchComboBox}
              />
              <Field
                className="cc-grid-container"
                name={nameOf("SelectedDocumentIDs")}
                onSelectionChange={handleOnSelectionChange}
                selectedRows={
                  valueGetter("_options.SelectedMailMergeDocumentsTemplate") ??
                  []
                }
                columnFields={colMailMergeDocumentGrid}
                primaryField={nameOfMailMergeDocumentsTemplate(
                  "MailMergeDocument_ID"
                )}
                data={valueGetter("_options.MailMergeDocumentsTemplate") ?? []}
                selectableMode={options.isMultiple ? "multiple" : "single"}
                component={CCGrid}
                validator={requiredValidator}
                itemPerPage={10}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Preview</label>
              <Loading isLoading={isLoadingPreview} />
              {showPreview && !isLoadingPreview && (
                <>
                  {valueGetter("_option.DocPreviewData") &&
                    !isEmpty(selectedDocumentIds) && (
                      <Document
                        className="cc-preview"
                        file={`data:application/pdf;base64,${valueGetter(
                          "_option.DocPreviewData"
                        )}`}
                        renderMode="svg"
                        loading={""}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        {numPages && showAllPage()}
                      </Document>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
