import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import {
  getInitialDataAddDeferredDutyTransaction,
  postProcessAddDeferredDutyTransaction,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/api";
import { DeferredDutyJournalFormStep } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/_index";
import { useDeferredJournalStepStore } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/store";
import { useNewChargeDetailsStepStore } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-charge-details/store";
import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import {
  DTO_WorkflowHeader,
  DTO_Workflow_Base,
  EListSubmitButton,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import {
  convertValueLOVToNumber,
  isShowParkButton,
} from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { isNil, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import {
  DTO_Workflow_JournalAddDDTransaction,
  EKeysOfStepsAddDDTransaction,
  keysOfStepsAddDDTransaction,
} from "./model";

interface IAddDeferredDutyAccountTransactionDialog {
  onClose: () => void;
  onSubmit: (data: any) => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  isReadOnly?: boolean;
  isToBeApprovalMode?: boolean;
  isBeingApprovedMode?: boolean;
  isShowCancelWorkflowButton?: boolean;
  statusBadge?: IBadgeDialog[];
}
export const AddDeferredDutyAccountTransactionDialog = observer(
  ({
    onClose,
    isSaveOnNextStep = false,
    isIncompleteMode = false,
    isFromActionList = false,
    prefixTitle,
    suffixTitle,
    dataFromActionList,
    isReadOnly = false,
    isToBeApprovalMode = false,
    isBeingApprovedMode = false,
    isShowCancelWorkflowButton = false,
    statusBadge,
  }: IAddDeferredDutyAccountTransactionDialog) => {
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);
    const { id } = useParams<{ id: string }>();
    const { currentFormTitle } = useCommonProductStore();
    const { setNewChargeDetailsStepLOVs } = useNewChargeDetailsStepStore();
    const { setDeferredJournalStepLOVs } = useDeferredJournalStepStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const [workflowInitDataFromNew, setWorkflowInitDataFromNew] =
      useState<DTO_Workflow_JournalAddDDTransaction>();
    const [finishConfirmDialogData, setFinishConfirmDialogData] =
      useState<any>();
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });

    const [isLoadingFinish, setIsLoadingFinish] = useState<boolean>(false);

    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "New Transaction";

      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, workflowHeader]);

    const initialValue = useMemo((): any => {
      let initDeferredDutyTransaction: any = {
        ...workflowInitDataFromNew?.WorkflowDetail?.Transaction,
        Accounts: workflowInitDataFromNew?.WorkflowDetail?.Accounts ?? [],
        AccountsSelected:
          workflowInitDataFromNew?.WorkflowDetail?.Accounts ?? [],
      };

      return {
        [EKeysOfStepsAddDDTransaction.DeferredDutyJournal]:
          initDeferredDutyTransaction,
        [EKeysOfStepsAddDDTransaction.Comments]: {},
        [EKeysOfStepsAddDDTransaction.Documents]: {},
      };
    }, [workflowInitDataFromNew]);

    const steps: IStep[] = [
      {
        label: "Deferred Duty",
        component: DeferredDutyJournalFormStep,
        visible: true,
        key: EKeysOfStepsAddDDTransaction.DeferredDutyJournal,
        options: {
          isReadOnly,
          workflowDraftId,
        },
        initialValues:
          initialValue[EKeysOfStepsAddDDTransaction.DeferredDutyJournal],
      },
      {
        label: "Comments",
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfStepsAddDDTransaction.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
        },
        initialValues: initialValue[EKeysOfStepsAddDDTransaction.Comments],
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        visible: true,
        key: EKeysOfStepsAddDDTransaction.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Journal_DD_Transfer_Transaction,
        },
        initialValues: initialValue[EKeysOfStepsAddDDTransaction.Documents],
      },
    ];

    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          setFinishConfirmDialogData(data);
          break;
        case EListSubmitButton.Cancel:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.ConfirmCloseYes:
        case EListSubmitButton.Close:
          await sendParkAddTransaction(processData(data));
          break;
      }
    };

    const processData: DTO_Workflow_JournalAddDDTransaction | any = (
      data: any
    ) => {
      let workflowDetail: any = {};
      const sendSteps = pickBy(data, (value, key) => {
        if (
          keysOfStepsAddDDTransaction.includes(
            key as EKeysOfStepsAddDDTransaction
          )
        ) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        const dataStep = { ...value };
        if (dataStep?._option) {
          delete dataStep._option;
        }
        workflowDetail[key] = dataStep;
      }
      delete workflowDetail[EKeysOfStepsAddDDTransaction.DeferredDutyJournal]
        .Accounts;
      workflowDetail.JournalNumber =
        workflowInitDataFromNew?.WorkflowDetail.JournalNumber ?? 0;
      if (
        !isNil(
          data?.[EKeysOfStepsAddDDTransaction.DeferredDutyJournal]
            ?.AccountsSelected
        )
      ) {
        workflowDetail.Accounts =
          data[
            EKeysOfStepsAddDDTransaction.DeferredDutyJournal
          ].AccountsSelected;
        workflowDetail.Transaction.AccountId =
          data[EKeysOfStepsAddDDTransaction.DeferredDutyJournal]
            .AccountsSelected?.[0]?.AccountId ?? 0;
        delete workflowDetail[EKeysOfStepsAddDDTransaction.DeferredDutyJournal]
          .AccountsSelected;
      }

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    const sendSaveTransaction = async (
      payload: DTO_Workflow_JournalAddDDTransaction,
      isCloseDialog: boolean = false
    ) => {
      isCloseDialog && setIsLoadingSave(true);
      const response = await postProcessAddDeferredDutyTransaction(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingOnNext(false);
      const defaultSuccessMessage = "Transaction saved successfully";
      const defaultFailedMessage =
        "The create new transaction process could not be saved";
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          if (isCloseDialog) {
            setIsLoadingSave(false);
            onClose();
            pushNotification({
              title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
              type: "success",
            });
          }
          if (isFirstSave) {
            setIsFirstSave(false);
            setWorkflowDraftId(response?.data?.ID ?? 0);
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID ?? 0,
              },
            });
          }
          return true;
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
          return false;
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        return false;
      }
    };

    const sendParkAddTransaction = async (
      payload: DTO_Workflow_JournalAddDDTransaction
    ) => {
      setIsLoadingPark(true);
      const defaultSuccessMessage = "Transaction parked successfully";
      const defaultFailedMessage = "Park transaction failed";
      const response = await postProcessAddDeferredDutyTransaction(
        WorkflowProcessMode.Park,
        payload
      );
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          onClose();
          pushNotification({
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response?.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      setIsLoadingPark(false);
    };

    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessAddDeferredDutyTransaction,
          dataCancel: data,
          defaultSuccessMessage: "The new journal was cancelled successfully",
          defaultErrorMessage: "New journal cancel failed",
        });
      } else {
        onClose();
      }
    };

    const handleNextButton = async (data: any) => {
      setIsLoadingOnNext(true);
      let workflowData: any = processData(data);
      return sendSaveTransaction({
        ...workflowData,
      });
    };

    const sendFinishWorkflow = async (
      payload: DTO_Workflow_JournalAddDDTransaction
    ) => {
      const defaultSuccessMessage =
        "Assessment transaction created successfully";
      const defaultFailedMessage = "Create new assessment transaction failed";

      const response = await postProcessAddDeferredDutyTransaction(
        WorkflowProcessMode.Finish,
        payload
      );
      setIsLoadingFinish(false);

      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          onClose();
          eventEmitter.emit(CCGridEventType.RefreshOData);
          pushNotification({
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
        setFinishConfirmDialogData(undefined);
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        setFinishConfirmDialogData(undefined);
      }
    };

    const handleConfirmDelete = async () => {
      setIsLoadingFinish(true);
      await sendFinishWorkflow(
        processData(finishConfirmDialogData, WorkflowProcessMode.Finish)
      );
    };

    const getWorkflowData = async () => {
      const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      const response = await getInitialDataAddDeferredDutyTransaction(
        workflowDraftId,
        +id
      );
      if (Array.isArray(response)) {
        const [lovsJournal, workflowData] = response;
        if (
          isSuccessResponse(lovsJournal) &&
          isSuccessResponse(workflowData) &&
          lovsJournal?.data &&
          workflowData?.data
        ) {
          //set Lovs Data Assessment
          setNewChargeDetailsStepLOVs({
            RatingPeriods: convertValueLOVToNumber(
              lovsJournal?.data?.RatingPeriods ?? [],
              "Code"
            ),
            InstalmentPlans: convertValueLOVToNumber(
              lovsJournal?.data?.InstalmentPlans ?? [],
              "Code"
            ),
            Levy: convertValueLOVToNumber(
              lovsJournal?.data?.Levy ?? [],
              "Code"
            ),
          });
          setDeferredJournalStepLOVs({
            TransactionTypes: convertValueLOVToNumber(
              lovsJournal?.data?.DDTransactionTypes ?? [],
              "Code"
            ),
            PaymentAllocations: convertValueLOVToNumber(
              lovsJournal?.data?.PaymentAllocations ?? [],
              "Code"
            ),
            IsRebateTypeTransaction:
              lovsJournal?.data?.IsRebateTypeTransaction ?? false,
            AssessRebateEntitlements: convertValueLOVToNumber(
              lovsJournal?.data?.AssessRebateEntitlements ?? [],
              "Code"
            ),
            DDTransactionTypes: convertValueLOVToNumber(
              lovsJournal?.data?.DDTransactionTypes ?? []
            ),
          });
          setWorkflowInitDataFromNew(workflowData?.data);

          if (workflowData.data?.WorkflowHeader) {
            setWorkflowHeader(workflowData.data?.WorkflowHeader);
            setWorkflowDraftId(
              workflowData.data?.WorkflowHeader?.WorkflowDraft
                ?.Workflow_Draft_Id ?? 0
            );
          }

          notificationFormStepRef?.current?.setLoadingFormStep(false);
        } else {
          let responseError: APIResponse<
            DTO_Journal_LOVs | DTO_Workflow_Base | ResponsePacket
          > = response[0];
          if (!isSuccessResponse(response[1])) {
            responseError = response[1];
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        notificationFormStepRef?.current?.setLoadingFormStep(false);
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error: "Load workflow failed",
          },
        });
      }
    };

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <>
        <CCFormStep
          ref={notificationFormStepRef}
          onSubmit={handleSubmit}
          listButtonId={listSubmitButton}
          initialSteps={steps}
          initialValues={initialValue}
          saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
          renderForm={(renderProps: ICCFormStepRender) => (
            <CCDialog
              maxWidth="60%"
              titleHeader={titleHeader}
              onClose={onClose}
              bodyElement={renderProps.children}
              badge={statusBadge}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}
                    {!isReadOnly &&
                      isToBeApprovalMode &&
                      !isBeingApprovedMode && (
                        <Button
                          iconClass={
                            isLoadingSave ? "fas fa-spinner fa-spin" : ""
                          }
                          disabled={
                            renderProps.nextButton.disabled || isLoadingSave
                          }
                          className={"cc-dialog-button"}
                          id={EListSubmitButton.Save}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Save
                        </Button>
                      )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          isLoadingOnNext ||
                          isLoadingPark ||
                          isLoadingSave ||
                          isLoadingFinish
                        }
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}
                    {!renderProps.prevButton.disabled ? (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    ) : null}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                </>
              }
            />
          )}
        />
        {finishConfirmDialogData && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={
              "The new transaction will be created based on the information provided. Are you sure you want to submit?"
            }
            isLoadingYes={isLoadingFinish}
            onClosePopup={() => setFinishConfirmDialogData(undefined)}
            onAsyncConfirm={handleConfirmDelete}
          />
        )}
      </>
    );
  }
);
