import { history } from "@/AppRoutes";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { APIResponseStatus } from "@common/constants/response-status";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { SECURITY_TEMPLATE_ROUTE } from "@common/pages/settings/security/security-templates/constant";
import {
  ISecurityTemplateNotificationSection,
  ProductListing,
  SecurityAction,
  SecurityTemplate,
  SecurityTemplateSubmitActions,
  SecurityTemplate_Ext,
} from "@common/pages/settings/security/security-templates/model";
import {
  getProductListing,
  getSecurityActions,
  getSecurityTemplateById,
  postSecurityTemplate,
} from "@common/pages/settings/security/security-templates/_id/api";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class SecurityTemplateStore {
  private _securityTemplate?: SecurityTemplate = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _notificationSection?: ISecurityTemplateNotificationSection =
    undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _productListing?: ProductListing[] = [];
  private _securityActionListing: SecurityAction[] = [];
  private _allowAndDenyListing: SecurityAction[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get notificationSection() {
    return this._notificationSection;
  }
  setNotificationSection = (
    notificationSection: ISecurityTemplateNotificationSection
  ) => {
    runInAction(() => {
      this._notificationSection = notificationSection;
    });
  };

  get securityTemplate() {
    return toJS(this._securityTemplate);
  }
  setSecurityTemplate = (securityTemplate?: any) => {
    runInAction(() => {
      this._securityTemplate = securityTemplate;
    });
  };

  get productListing() {
    return toJS(this._productListing);
  }
  setProductListing = (productListing?: ProductListing[]) => {
    runInAction(() => {
      this._productListing = productListing;
    });
  };

  get securityActionListing() {
    return toJS(this._securityActionListing);
  }
  setSecurityActionListing = (securityActionListing: SecurityAction[]) => {
    runInAction(() => {
      this._securityActionListing = securityActionListing;
    });
  };

  get allowAndDenyListing() {
    return toJS(this._allowAndDenyListing);
  }
  setAllowAndDenyListing = (allowAndDenyListing: SecurityAction[]) => {
    runInAction(() => {
      this._allowAndDenyListing = allowAndDenyListing;
    });
  };

  get hasItemAllow() {
    return this.allowAndDenyListing.some((item) => item.bAllowAction);
  }

  get hasItemDeny() {
    return this.allowAndDenyListing.some((item) => !item.bAllowAction);
  }

  resetStore = () => {
    runInAction(() => {
      this._securityTemplate = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  get securityTemplateId() {
    return toJS(this.securityTemplate?.SecurityTemplate_ID);
  }

  reloadSecurityTemplate = async (): Promise<boolean> => {
    if (this.securityTemplateId)
      return await this.loadSecurityTemplate(this.securityTemplateId);
    return false;
  };

  loadSecurityAction = async (
    securityTemplate_ID: number,
    productType_ENUM: PRODUCT_TYPE
  ) => {
    const idsGetSecurityActions: number[] = [securityTemplate_ID];
    const response = await getSecurityActions(
      productType_ENUM,
      idsGetSecurityActions
    );
    if (isSuccessResponse(response)) {
      if (response.data) {
        this.setSecurityActionListing(response.data);
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: response?.error ?? "Security actions load failed",
        type: "warning",
      });
    }
  };

  loadSecurityTemplate = async (
    securityTemplateID: number,
    isNew?: boolean
  ): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    if (this.notificationSection?.notification) {
      this.notificationSection?.notification.forEach(
        (notification: IAppNotificationItemAddProps) => {
          appNotificationStore.pushNotification(notification);
        }
      );
      this.setNotificationSection({
        ...this._notificationSection,
        notification: [],
      });
    }

    if (isNew) {
      const resProductListing = await getProductListing();
      if (isSuccessResponse(resProductListing)) {
        if (resProductListing?.data)
          resProductListing.data.sort((pre, cur) =>
            pre.ProductType_Name.localeCompare(cur.ProductType_Name)
          );
        this.setProductListing(resProductListing?.data);
        this.setSecurityTemplate({
          _options: {
            Product: resProductListing?.data?.[0],
          },
        } as SecurityTemplate_Ext);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: resProductListing?.error ?? "Product listing load failed",
          type: "warning",
        });
      }
    } else {
      let newSecurityTemplate: SecurityTemplate | undefined;
      const response = await getSecurityTemplateById(securityTemplateID);
      if (isSuccessResponse(response) && response.data) {
        newSecurityTemplate = response.data;
        this.loadSecurityAction(
          response.data.SecurityTemplate_ID,
          response.data.ProductType_ENUM
        );
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
      this.setSecurityTemplate(newSecurityTemplate);
    }

    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  saveSecurityTemplate = async (
    securityTemplateInfo: SecurityTemplate,
    action: SecurityTemplateSubmitActions
  ) => {
    this.setIsLoading(true);
    const response = await postSecurityTemplate(securityTemplateInfo);
    if (isSuccessResponse(response)) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        await this.runActions(action, response);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Save record failed",
          type: "error",
          description: response.data?.Errors ?? response?.statusText,
        });
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: response?.error ?? response?.statusText,
        type: "error",
      });
    }
  };

  runActions = async (
    action: SecurityTemplateSubmitActions,
    response?: APIResponse
  ) => {
    switch (action) {
      case SecurityTemplateSubmitActions.Save:
        await this.reloadSecurityTemplate();
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Security template saved successfully",
          type: "success",
        });
        break;
      case SecurityTemplateSubmitActions.New:
        appNotificationStore.clearErrorNotification();
        history.replace(`${SECURITY_TEMPLATE_ROUTE}/${response?.data?.ID}`, {
          notification: [
            { title: "Security template saved successfully", type: "success" },
          ],
        });
        break;
      default:
        break;
    }
    this.setIsLoading(false);
  };
}

export const securityTemplateStore = new SecurityTemplateStore();
const SecurityTemplateStoreContext = createContext(securityTemplateStore);
export const useSecurityTemplateStore = () => {
  return useContext(SecurityTemplateStoreContext);
};
