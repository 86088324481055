import { IJournal } from "@app/core/journal/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";

export const getJournal = async (
  id: number | string | undefined,
  recordType: RECORDTYPE,
  skip: number,
  take: number
): Promise<APIResponse<IJournal[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<IJournal[] | undefined>(
      `/api/core/Internal/journal/${id}/${recordType}/${skip}/${take}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
