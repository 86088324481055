import { colNames } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/names/config";
import { namesValidator } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/names/util";
import { loadNameDetail } from "@app/products/property/components/fields/search-name/api";
import { DTO_Entity } from "@app/products/property/components/fields/search-name/model";
import {
  handleSearchNameProcess,
  ISearchNameProcessParams,
} from "@app/products/property/components/fields/search-name/util";
import { SearchNameCombobox } from "@app/products/property/components/fields/search-name/_index";
import { DTO_Workflow_CreateContact } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { DTO_Entity_Associated_Entity } from "@app/products/property/contacts-central-names/list/model";
import { ResponseMessage } from "@app/products/property/model";
import { DTO_Entity_Details } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/contacts/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { ContactLookahead_JSON } from "@common/models/contact";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React, { useState } from "react";
import "./_index.scss";

const nameOfNames = nameOfFactory<DTO_Entity_Associated_Entity>();

export const NamesFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? namesValidator : undefined}
    />
  );
};

const FormStepElement = ({
  nameOf,
  formRenderProps,
  localNotificationRef,
  options = {
    isReadOnly: false,
    contactLOVs: undefined,
  },
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const onChangeFieldValue = (name: string, value: any) =>
    onChange(nameOf(name), { value: value });
  const namesGridData: DTO_Entity_Details[] =
    getFieldValue("Associated_Entities") ?? [];
  const selectedName = getFieldValue("_option.SelectedName");
  const rolesLOV = options?.contactLOVs?.Roles ?? [];

  const [isGridLoading, setIsGridLoading] = useState<boolean>(false);

  //Check duplicate
  const checkExistedEntityNameAndAddress = (
    entityNameAndAddressId: number | null
  ) => {
    return (
      namesGridData &&
      namesGridData?.some(
        (contactItem: DTO_Entity_Associated_Entity) =>
          contactItem?.Entity_Name_Address_Id === entityNameAndAddressId
      )
    );
  };

  const handleLoadNameDetail = async (value: any) => {
    const newContact: DTO_Entity_Details = {
      Entity_Id: value?.Entity_Id,
      Entity_Name_Address_Id: value?.Entity_Name_Address_Id,
      Role_Name: rolesLOV?.[0]?.Name ?? "",
      Name: value?.Name,
      Address: value?.Address,
    } as DTO_Entity_Details;
    let newNames: DTO_Entity_Details[] = [];
    newNames = [...namesGridData, newContact];
    onChangeFieldValue("Associated_Entities", newNames);
  };

  const handleAddName = async (value: any) => {
    const entityId = value?.Entity_Id;
    let existingProcess = undefined;
    if (
      entityId &&
      !checkExistedEntityNameAndAddress(value?.Entity_Name_Address_Id)
    ) {
      existingProcess = () => {
        localNotificationRef?.current?.pushNotification({
          title: `${value?.DisplayName} already exists`,
          type: "warning",
        });
      };
    }
    const dataProcess: ISearchNameProcessParams = {
      data: value,
      existingProcess,
      handleLoadNameDetail: async (data: any) => {
        if (data?.Entity_Name_Address_Id) {
          if (checkExistedEntityNameAndAddress(data?.Entity_Name_Address_Id)) {
            localNotificationRef?.current?.pushNotification({
              title: `${data?.Name ?? value?.DisplayName} already exists`,
              type: "warning",
            });
            return;
          }
          return await handleLoadNameDetail({ ...data });
        }
      },
      isCheckEntityId: !!value?.Entity_Name_Address_Id,
      notificationAction: {
        canNotAddName: (response) => {
          localNotificationRef?.current?.pushNotification({
            title: response?.error ?? "Name could not be added",
            type: "error",
            autoClose: false,
          });
        },
      },
      setLoading: (isLoading: boolean) => {
        setIsGridLoading(isLoading);
      },
    };

    await handleSearchNameProcess(dataProcess);
  };

  const handleGridSelectionChange = (
    field: string,
    dataItem: DTO_Entity_Associated_Entity[]
  ) => {
    let newSelected = dataItem ? dataItem[0] : undefined;
    onChangeFieldValue(field, newSelected);
  };

  const handleRemoveName = (name: DTO_Entity_Associated_Entity) => {
    if (name) {
      const newNames = namesGridData.filter(
        (item: DTO_Entity_Associated_Entity) =>
          item.Entity_Name_Address_Id !== name.Entity_Name_Address_Id
      );
      onChangeFieldValue("Associated_Entities", newNames);
      handleGridSelectionChange("_option.SelectedName", []);
    }
  };

  const handleSubmitNewContactDialog = async (
    _payload: DTO_Workflow_CreateContact,
    responseMessage: ResponseMessage
  ) => {
    const entityId = responseMessage?.Component_ID;
    if (responseMessage?.IsSuccess && entityId) {
      setIsGridLoading(true);
      const nameDetailResponse = await loadNameDetail(entityId);
      setIsGridLoading(false);
      if (isSuccessResponse(nameDetailResponse)) {
        const nameDetail = nameDetailResponse?.data?.Entity_Name_Address;
        const newContact = {
          ...nameDetail,
          Entity_Id: entityId,
          Address: nameDetail.Full_Address,
        };
        handleAddName(newContact as DTO_Entity);
      } else {
        localNotificationRef?.current?.pushNotification({
          title: responseMessage?.ErrorMessage ?? "Name could not be added",
          type: "error",
          autoClose: false,
        });
      }
    } else {
      localNotificationRef?.current?.pushNotification({
        title: responseMessage?.ErrorMessage ?? "Name could not be added",
        type: "error",
        autoClose: false,
      });
    }
  };

  return (
    <>
      <section className="cc-field-group cc-contact-associations-step">
        <div className="cc-field">
          <label className="cc-label">Proposed settlement date</label>
          <Field
            name={nameOf("ProposedSettlementDate")}
            component={CCDatePicker}
            format={DATE_FORMAT.DATE_CONTROL}
            disabled={options?.isReadOnly}
          />
        </div>
        <div className="cc-form-cols-2">
          <SearchNameCombobox
            name={nameOf("SearchName")}
            onSelectionChange={(values: ContactLookahead_JSON) => {
              handleAddName(values);
            }}
            disabled={options?.isReadOnly}
            selectedSearchOptions={getFieldValue("SearchOptions")}
            onError={(error: any) => {
              localNotificationRef?.current?.pushNotification({
                type: "error",
                title: error ?? "Search name error",
                autoClose: false,
              });
            }}
            onSubmitNewContactDialog={handleSubmitNewContactDialog}
          />
          {/* [13524] Contact - Workflow dialog contact lookup:
            -  Search options – hide for all (not just LLS) */}
          {/* <div className="cc-field">
            <label className="cc-label">Search options</label>
            <Field
              name={nameOf("SearchOptions")}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              data={listSearchOptions}
              component={CCMultiSelectDropdown}
              disabled={options?.isReadOnly}
            />
          </div> */}
        </div>

        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Names
              <CCTooltip type="validator" position="right" />
            </label>
            <CCGrid
              isLoading={isGridLoading}
              data={namesGridData}
              selectedRows={selectedName ? [selectedName] : undefined}
              columnFields={colNames}
              readOnly={options?.isReadOnly}
              selectableMode="single"
              onSelectionChange={(dataItem: DTO_Entity_Associated_Entity[]) => {
                handleGridSelectionChange("_option.SelectedName", dataItem);
              }}
              primaryField={nameOfNames("Entity_Name_Address_Id")}
              editableMode="cell"
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-minus"
                    title="Remove Entity from list"
                    disabled={!selectedName}
                    onClick={() => {
                      handleRemoveName(selectedName);
                    }}
                  />
                </div>
              }
            />
          </div>
        </div>
      </section>
    </>
  );
};
