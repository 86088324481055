import {
  SupplementaryMode,
  VO_Supplementary,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Supplementary>();

export const colSupplementary: IColumnFields[] = [
  { field: nameOf("Supplementary_Id"), title: "Number" },
  { field: nameOf("Supp_Name"), title: "Name" },
  { field: nameOf("ST_Name"), title: "Type" },
];

export const dataWhichSupplementary = [
  { label: "New supplementary", value: SupplementaryMode.CreateNew },
  {
    label: "Existing supplementary created by me",
    value: SupplementaryMode.SelectFromMyList,
  },
  {
    label: "Existing supplementary",
    value: SupplementaryMode.SelectFromList,
  },
];

export const getSupplementaryOdata = (
  isMyList: boolean,
  isCompletedSupplementary: boolean
) => {
  return `/odata/property/internal/supplementaryratesregister?mylist=${isMyList}&$filter=Supp_Is_Complete eq ${isCompletedSupplementary}&$count=true&`;
};
