import { dataCalculateCompletionTargetInDaysOrHours } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/form-element/config";
import {
  ServiceStandard,
  ServiceStandardHandlerRequest,
  ServiceStandardMapObj,
  Svc_FormAction,
} from "@app/products/crms/service-standards/[id]/model";
import { useCRMSServiceStandardStore } from "@app/products/crms/service-standards/[id]/store";
import { ACCESSRIGHTS } from "@common/constants/enumerations";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import {
  RadioGroupChangeEvent,
  SwitchChangeEvent,
} from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<ServiceStandard>();
const nameOfServiceStandardMapObj = nameOfFactory<ServiceStandardMapObj>();

export interface TargetDaysProps {
  formRenderProps: FormRenderProps;
}

export const TargetDays = observer(({ formRenderProps }: TargetDaysProps) => {
  const { valueGetter, onChange } = formRenderProps;

  const { serviceStandardChangeHandler, setIsExpandedTargetDays, accessRight } =
    useCRMSServiceStandardStore();

  const isTargetsAllowRescheduling = valueGetter(
    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
      "Targets_AllowRescheduling"
    )}`
  );

  const isDisabled = accessRight === ACCESSRIGHTS.CanRead;

  const serviceStandardFormObj = valueGetter(
    nameOfServiceStandardMapObj("ServiceStandard")
  );

  const handleChangeRescheduled = (event: SwitchChangeEvent) => {
    const params: ServiceStandardHandlerRequest = {
      ServiceStandardFormAction: Svc_FormAction.ChangeTargetMayBeRescheduled,
      ServiceStandard: serviceStandardFormObj,
      EventArgs: { Targets_AllowRescheduling: event.value },
      IsFirstTimeLoad: false,
    };

    onChange(
      `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
        "Targets_AllowRescheduling"
      )}`,
      { value: event.value }
    );
    serviceStandardChangeHandler(params, "Change may be rescheduled fail");
    setIsExpandedTargetDays(true);
  };

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel title="Calculate completion target in days/hours" />
          <Field
            disabled={isDisabled}
            name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "TargetInDays"
            )}`}
            component={CCRadioGroup}
            layout={"horizontal"}
            data={dataCalculateCompletionTargetInDaysOrHours}
            onChange={(e: RadioGroupChangeEvent) => {
              onChange(
                `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                  "TargetInDays"
                )}`,
                {
                  value: e.value,
                }
              );
            }}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Completion" isMandatory />
          <Field
            disabled={isDisabled}
            name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "Targets_Standard_Actual_Completion"
            )}`}
            component={CCNumericTextBox}
            placeholder={"Completion"}
            validator={requiredValidator}
            min={0}
          />
        </div>

        <div className="cc-field">
          <CCLabel title="Urgent completion" isMandatory />
          <Field
            disabled={isDisabled}
            name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "Targets_Urgent_Actual_Completion"
            )}`}
            component={CCNumericTextBox}
            placeholder={"Urgent completion"}
            validator={requiredValidator}
            min={0}
          />
        </div>
      </div>
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel title="Use acknowledgement" />
          <Field
            disabled={isDisabled}
            name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "Targets_Acknowledgement"
            )}`}
            checked={
              valueGetter(
                `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                  "Targets_Acknowledgement"
                )}`
              ) ?? false
            }
            component={CCSwitch}
          />
        </div>

        {valueGetter(
          `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
            "Targets_Acknowledgement"
          )}`
        ) && (
          <>
            <div className="cc-field">
              <CCLabel title="Acknowledgement" isMandatory />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("Targets_Standard_Acknowledgement")}`}
                component={CCNumericTextBox}
                placeholder={"Acknowledgement"}
                validator={requiredValidator}
                min={0}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Urgent acknowledgement" isMandatory />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("Targets_Urgent_Acknowledgement")}`}
                component={CCNumericTextBox}
                validator={requiredValidator}
                placeholder={"Urgent acknowledgement"}
                min={0}
              />
            </div>
          </>
        )}
      </div>
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel title="May be rescheduled" />
          <Field
            disabled={isDisabled}
            name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "Targets_AllowRescheduling"
            )}`}
            checked={
              valueGetter(
                `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                  "Targets_AllowRescheduling"
                )}`
              ) ?? false
            }
            component={CCSwitch}
            onChange={handleChangeRescheduled}
          />
        </div>
        {isTargetsAllowRescheduling && (
          <>
            <div className="cc-field">
              <CCLabel title="Maximum number of day to complete" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("Targets_Max_Completion_Days")}`}
                component={CCNumericTextBox}
                placeholder={"Maximum number of day to complete"}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Notify customer if rescheduled" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("Targets_RescheduledNotifyCustomer")}`}
                checked={
                  valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "Targets_RescheduledNotifyCustomer"
                    )}`
                  ) ?? false
                }
                component={CCSwitch}
              />
            </div>
          </>
        )}
      </div>

      <div className="cc-form-cols-3">
        <div className="cc-field">
          {isTargetsAllowRescheduling ? (
            <>
              <CCLabel title="Allow reschedule - urgent" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("Targets_AllowReschedulingOnUrgent")}`}
                checked={
                  valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "Targets_AllowReschedulingOnUrgent"
                    )}`
                  ) ?? false
                }
                component={CCSwitch}
              />
            </>
          ) : (
            <CCValueField label="Allow reschedule - urgent" value={"No"} />
          )}
        </div>
      </div>
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel title="Final notification" />
          <Field
            disabled={isDisabled}
            name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "Targets_FinalNotification"
            )}`}
            checked={
              valueGetter(
                `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                  "Targets_FinalNotification"
                )}`
              ) ?? false
            }
            component={CCSwitch}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Include weekends/public holidays in target calculation" />
          <Field
            disabled={isDisabled}
            name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "Targets_IncludeWeekendInCalculation"
            )}`}
            checked={
              valueGetter(
                `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                  "Targets_IncludeWeekendInCalculation"
                )}`
              ) ?? false
            }
            component={CCSwitch}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Enable internal closure comment" />
          {valueGetter(
            `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "Targets_FinalNotification"
            )}`
          ) ? (
            <Field
              disabled={isDisabled}
              name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "Enable_InternalClosureComments"
              )}`}
              checked={
                valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "Enable_InternalClosureComments"
                  )}`
                ) ?? true
              }
              component={CCSwitch}
            />
          ) : (
            <CCValueField value={"Yes"} />
          )}
        </div>
      </div>
    </section>
  );
});
