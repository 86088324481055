import { DTO_Assessment } from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

/**
 *  1 - Address
 *  2 - Name
 *  3 - Legal Description
 *  4 - Assessment Id
 *  5 - Assessment Number
 *  6 - Assessment (S-S-B-U)
 *  7 - Parcel Id
 *  8 - Title Id
 */
export enum eOptionSearchAssessmentLookup {
  Address = 1,
  Name = 2,
  LegalDescription = 3,
  AssessmentId = 4,
  AssessmentNumber = 5,
  AssessmentReference = 6,
  ParcelId = 7, //only use in background processes, not show in Title Lookup Search By dropdown
  TitleId = 8,
}

export const assessmentSearchByList: DTO_LOV_Number[] = [
  {
    Code: eOptionSearchAssessmentLookup.Address,
    Name: "Address",
  },
  {
    Code: eOptionSearchAssessmentLookup.Name,
    Name: "Name",
  },
  {
    Code: eOptionSearchAssessmentLookup.LegalDescription,
    Name: "Legal Description",
  },
  {
    Code: eOptionSearchAssessmentLookup.AssessmentId,
    Name: "Assessment ID",
  },
  {
    Code: eOptionSearchAssessmentLookup.AssessmentNumber,
    Name: "Assessment Number",
  },
  {
    Code: eOptionSearchAssessmentLookup.AssessmentReference,
    Name: "Assessment (S-S-B-U)",
  },
  {
    Code: eOptionSearchAssessmentLookup.TitleId,
    Name: "Title ID",
  },
];

const nameOf = nameOfFactory<DTO_Assessment>();
export const listConfigNumberTypeAssessment = [4, 5, 7, 8];
export const colAssessmentLookup: IColumnFields[] = [
  {
    field: nameOf("Assess_Number"),
    title: "Assessment Number",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("Ratepayer_Name"),
    title: "Ratepayer",
  },
  {
    field: nameOf("Is_Primary_Assessment"),
    title: "Is Primary Assessment",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Assessment_Status_Name"),
    title: "Status",
  },
  {
    field: nameOf("Active_From"),
    title: "Active from",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Active_To"),
    title: "Active to",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Assess_Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Assess_Reference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("Assess_Legal_Description"),
    title: "Legal Description",
  },
  {
    field: nameOf("Unit_Entitlement"),
    title: "Unit Entitlement",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("Unit_Entitlement_Percentage"),
    title: "Unit Entitlement Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE_1,
  },
  {
    field: nameOf("Unit_Type"),
    title: "Unit Type",
  },
  {
    field: nameOf("Unit_Type_Percentage"),
    title: "Unit Type Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE_1,
  },
  {
    field: nameOf("Assess_Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Assess_Balance_Outstanding"),
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Assess_Being_Postponed"),
    title: "Being Postponed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Assessment_Group_Name"),
    title: "Group",
  },
  {
    field: nameOf("Valuation_Group_Name"),
    title: "Valuation Group",
  },
  {
    field: nameOf("Assessment_Type_Name"),
    title: "Type",
  },
  {
    field: nameOf("Primary_Assess_Land_Use_Name"),
    title: "Land Use",
  },
  {
    field: nameOf("Valuation_1"),
    title: "Valuation 1",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_2"),
    title: "Valuation 2",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_3"),
    title: "Valuation 3",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_4"),
    title: "Valuation 4",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
