import { IExecutionData } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/execution/model";

export const instalmentPlanData: Array<IExecutionData> = [
  { label: "No selection on instalment plans", value: "1" },
  {
    label: "Select instalment plans",
    value: "2",
  },
];

export enum EInstalmentPlanOption {
  NoSelection = "1",
  SelectInstalment = "2",
}
