import { eventEmitter } from "@/App";
import { COMMENT_ACCORDION_GRID_ID } from "@app/core/comments/constant";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { OfficerReallocateDialog } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/officer-reallocate/_index";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import {
  getInitialDataSubdivisionsConsolidations,
  postProcessSubdivisionsConsolidations,
} from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/api";
import { SubdivideConsolidateDetailsFormStep } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/details/_index";
import { defaultSearchTitle } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/details/config";
import { VO_Assessment_Subdivision_Title } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/details/model";
import { SubdivideConsolidateProgenyFormStep } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/progeny/_index";
import { SupplementaryFormStep } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/supplementary/_index";
import {
  DTO_SubdivisionsConsolidations,
  DTO_SubdivisionsConsolidations_LOVs_Response,
  DTO_Workflow_SubdivisionsConsolidations,
  EKeysOfStepsSubdivideConsolidate,
  keysOfSendStepsSubdivideConsolidate,
} from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/model";
import { useSubdivideConsolidateDialogStore } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/store";
import { NewSupplementaryFormStep } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/_index";
import { useNewSupplementaryDialogStore } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/store";
import { postReject } from "@app/products/property/components/action-bar/property-workflow/api";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { SupplementaryMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { PropertyDocumentEventType } from "@app/products/property/components/child-screen/documents/constant";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { IODataResponse } from "@common/models/odataResponse";
import { Label } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface ICallbackNextStep {
  callBack: (isNext: boolean) => void;
  data?: any;
}
interface INewAssessmentDialogProps {
  onClose: () => void;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  isReadOnly?: boolean;
  isToBeApprovalMode?: boolean;
  isBeingApprovedMode?: boolean;
  isShowReason?: boolean;
  isShowCancelWorkflowButton?: boolean;
  handleReallocate?: (data: any) => void;
  statusBadge?: IBadgeDialog[];
  isIncompleteMode: boolean;
  suffixTitle?: string;
  isFromActionList?: boolean;
  isSaveOnNextStep?: boolean;
  assessmentId: number;
  statusId: number;
  associationsRef: React.MutableRefObject<any>;
}

export const SubdivideConsolidateDialog = observer(
  ({
    onClose,
    dataFromActionList,
    handleReallocate,
    prefixTitle,
    isReadOnly = false,
    isToBeApprovalMode = false,
    isBeingApprovedMode = false,
    statusBadge,
    suffixTitle,
    isFromActionList = false,
    isShowCancelWorkflowButton = false,
    isIncompleteMode = false,
    isSaveOnNextStep = false,
    assessmentId,
    statusId,
    associationsRef,
  }: INewAssessmentDialogProps) => {
    //#region HOOKS -------------------------------------------->
    //Use ref
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    //Use store
    const { pushNotification } = useCCAppNotificationStore();
    const { setSubdivisionsConsolidationsLOVs, setAssessmentId } =
      useSubdivideConsolidateDialogStore();
    const { setCreateSupplementaryLOVs } = useNewSupplementaryDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { currentFormTitle } = useCommonProductStore();

    //Use state
    const [isShowOfficerReallocate, setIsShowOfficerReallocate] =
      useState<boolean>(false);
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
    const [callbackNextStep, setCallbackNextStep] =
      useState<ICallbackNextStep>();
    const [initialData, setInitialData] =
      useState<DTO_Workflow_SubdivisionsConsolidations>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);

    //Get label
    const titleLowercaseLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.TitleLowercase
    );

    //Use memo
    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title =
        currentFormTitle(formId ?? 0) ?? "Subdivisions/Consolidations";
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line
    }, [workflowHeader, prefixTitle, suffixTitle]);

    const initialValue = useMemo(() => {
      let initDetails: any = {
        Date: new Date(),
      };
      let initProgeny: any = {
        AreaOfParentTitles: 0,
        AreaOfProgenyTitles: 0,
      };
      let initSupplementary: any = {
        SupplementaryMode: SupplementaryMode.None,
      };
      let initCreateSupplementary: any = {};

      if (initialData) {
        const workflowDetail = initialData?.WorkflowDetail;
        //Details
        if (workflowDetail?.Details) {
          initDetails = {
            ...initDetails,
            ...workflowDetail?.Details,
            _option: {
              ...workflowDetail?.Details?._option,
              ...defaultSearchTitle,
            },
          };
        }

        //Progeny
        if (workflowDetail?.Progeny) {
          initProgeny = {
            ...initProgeny,
            ...workflowDetail?.Progeny,
          };
        }

        //Supplementary
        const supplementaryId = workflowDetail?.Supplementary_Id;
        initSupplementary = {
          SupplementaryMode:
            workflowDetail?.SupplementaryMode ?? SupplementaryMode.None,
          Supplementary_Id: workflowDetail?.Supplementary_Id,
          _option: {
            SupplementarySelected:
              supplementaryId && supplementaryId !== 0
                ? [{ Supplementary_Id: supplementaryId }]
                : [],
          },
        };

        //Create supplementary
        if (workflowDetail?.NewSupplementary) {
          initCreateSupplementary = {
            ...initCreateSupplementary,
            ...workflowDetail?.NewSupplementary,
          };
        }
      }

      notificationFormStepRef?.current?.setStepsVisible([
        {
          key: EKeysOfStepsSubdivideConsolidate.NewSupplementary,
          visible:
            initialData?.WorkflowDetail.SupplementaryMode ===
            SupplementaryMode.CreateNew,
          isClearData: false,
        },
      ]);

      return {
        [EKeysOfStepsSubdivideConsolidate.Details]: initDetails,
        [EKeysOfStepsSubdivideConsolidate.Progeny]: initProgeny,
        [EKeysOfStepsSubdivideConsolidate.Supplementary]: initSupplementary,
        [EKeysOfStepsSubdivideConsolidate.NewSupplementary]:
          initCreateSupplementary,
        [EKeysOfStepsSubdivideConsolidate.Comments]: {},
        [EKeysOfStepsSubdivideConsolidate.Documents]: {},
      };
    }, [initialData]);

    //Use effect
    /**
     * get workflow data
     */
    const getWorkflowData = async () => {
      const id = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      const payload: DTO_SubdivisionsConsolidations = {
        Assessment_Id: assessmentId ?? 0,
        StatusId: statusId,
      };
      const response = await getInitialDataSubdivisionsConsolidations(
        payload,
        id
      );
      if (Array.isArray(response)) {
        const [lovsResponse, workflowDataResponse, titleODataResponse] =
          response;
        const lovsData = lovsResponse?.data;
        const workflowData = workflowDataResponse?.data;
        const titleOData = titleODataResponse?.data?.value;
        if (
          isSuccessResponse(lovsResponse) &&
          isSuccessResponse(workflowDataResponse) &&
          isSuccessResponse(titleODataResponse) &&
          lovsData &&
          workflowData &&
          titleOData
        ) {
          //Set workflow LOVs
          setSubdivisionsConsolidationsLOVs(
            lovsData.dtoSubdivisionsConsolidation_LOVs
          );
          //Set create supplementary LOVs
          const createSupplementaryLOVs = {
            SupplementaryType:
              lovsData.dtoSubdivisionsConsolidation_LOVs.Supplementary_Type ??
              [],
            InstallmentType:
              lovsData.dtoSubdivisionsConsolidation_LOVs.Installment_Type ?? [],
            AssessmentType:
              lovsData.dtoSubdivisionsConsolidation_LOVs.Assessment_Type ?? [],
            ValuationTypeTobeUsed:
              lovsData.dtoSubdivisionsConsolidation_LOVs
                .ValuationTypeTobeUsed ?? [],
          };
          setCreateSupplementaryLOVs(createSupplementaryLOVs);
          //Set workflow initial data
          setInitialData({
            WorkflowHeader: workflowData.WorkflowHeader,
            WorkflowDetail: {
              ...workflowData.WorkflowDetail,
              Details: {
                ...workflowData.WorkflowDetail.Details,
                _option: {
                  ParentTitleList: titleOData,
                },
              },
            },
          });
          //Set assessment ID
          setAssessmentId(workflowData.WorkflowDetail?.Assessment_Id);
          //Set workflow header and workflow draft ID
          if (workflowData?.WorkflowHeader) {
            setWorkflowHeader(workflowData.WorkflowHeader);
            setWorkflowDraftId(
              workflowData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id ??
                0
            );
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
        } else {
          let responseError: APIResponse<
            | DTO_SubdivisionsConsolidations_LOVs_Response
            | DTO_Workflow_SubdivisionsConsolidations
            | IODataResponse<VO_Assessment_Subdivision_Title>
            | ResponsePacket
            | undefined
          > = lovsResponse;

          if (!isSuccessResponse(workflowDataResponse)) {
            responseError = workflowDataResponse;
          } else if (!isSuccessResponse(titleODataResponse)) {
            responseError = titleODataResponse;
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        notificationFormStepRef?.current?.setLoadingFormStep(false);
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error: "Load workflow failed",
          },
        });
      }
    };

    useEffectOnce(() => {
      getWorkflowData();
    });
    //#endregion <--------------------------------------------

    //#region COMMON FUNCTIONS -------------------------------------------->
    /**
     * Handle submit to control all buttons in dialog
     * @param events
     * @param buttonId
     */
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Approve:
          await handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          await sendSaveWorkflow(processData(data), true);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          handleConfirmRetainProcess(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
        default:
          break;
      }
    };

    /**
     * Process data before sending to API
     * @param data
     */
    const processData = (data: any) => {
      let workflowDetail: any = {};
      const sendSteps = pickBy(data, function (value, key) {
        if (
          keysOfSendStepsSubdivideConsolidate.includes(
            key as EKeysOfStepsSubdivideConsolidate
          )
        ) {
          return { [key]: value };
        }
      });
      workflowDetail.Assessment_Id = initialData?.WorkflowDetail?.Assessment_Id;

      if (sendSteps) {
        for (const [key, value] of Object.entries(sendSteps)) {
          const dataStep = { ...value };
          if (dataStep && dataStep?._option) {
            delete dataStep._option;
          }
          if (key === EKeysOfStepsSubdivideConsolidate.Supplementary) {
            workflowDetail = { ...workflowDetail, ...dataStep };
          } else {
            workflowDetail[key] = dataStep;
          }
        }
      }
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    /**
     * common function
     * handle calling API with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_SubdivisionsConsolidations>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;
      const response = await postProcessSubdivisionsConsolidations(
        modeProcess,
        payload
      );
      setLoading();
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };
    //#endregion <--------------------------------------------

    //#region SAVE and NEXT -------------------------------------------->
    /**
     * Handle Next button
     * @param data
     * @param filterSteps
     * @param keyStep
     */
    const handleNextButton = async (
      data: any,
      _filterSteps: any,
      keyStep: string
    ) => {
      if (
        keyStep === EKeysOfStepsSubdivideConsolidate.Progeny &&
        data.Progeny?.AreaOfParentTitles !== data.Progeny?.AreaOfProgenyTitles
      ) {
        return await new Promise<boolean>((resolve) => {
          setCallbackNextStep({
            callBack: (isNext: boolean) => {
              resolve(isNext);
            },
            data: data,
          });
        });
      }

      setIsLoadingOnNext(true);
      return await sendSaveWorkflow(processData(data));
    };

    /**
     * Send save workflow data (call API with 'Save' mode)
     * @param payload
     * @param isCloseDialog
     */
    const sendSaveWorkflow = async (
      payload: DTO_Workflow_SubdivisionsConsolidations,
      isCloseDialog: boolean = false
    ) => {
      isCloseDialog && setIsLoadingSave(true);
      const response = await postProcessSubdivisionsConsolidations(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingOnNext(false);
      const defaultSuccessMessage =
        "Subdivisions and consolidations was saved successfully.";
      const defaultFailedMessage =
        "Subdivisions and consolidations could not be saved";
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (isCloseDialog) {
          setIsLoadingSave(false);
          onClose();
          pushNotification({
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        }
        if (isFirstSave) {
          setIsFirstSave(false);
          setWorkflowDraftId(response?.data?.ID ?? 0);
          setWorkflowHeader({
            ...workflowHeader,
            WorkflowDraft: {
              ...workflowHeader.WorkflowDraft,
              Workflow_Draft_Id: response?.data?.ID ?? 0,
            },
          });
        }
        return true;
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        return false;
      }
    };

    const handleConfirmContinue = async () => {
      if (!callbackNextStep) return;
      const data = processData(callbackNextStep.data);
      //Close confirmation dialog
      setCallbackNextStep(undefined);
      //Handle Next button
      setIsLoadingOnNext(true);
      await sendSaveWorkflow(data);
      //Move to next step
      callbackNextStep.callBack(true);
    };
    //#endregion <--------------------------------------------

    //#region PARK -------------------------------------------->
    /**
     * Handle park process
     * @param payload
     */
    const handleParkProcess = async (
      payload: DTO_Workflow_SubdivisionsConsolidations
    ) => {
      //set loading button and dialog
      setIsLoadingPark(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_SubdivisionsConsolidations> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            pushNotification({
              title:
                e?.Notification ??
                "Subdivisions and consolidations was parked successfully.",
              type: "success",
            });
          },
          defaultFailedMessage:
            "Subdivisions and consolidations could not be parked.",
          modeProcess: WorkflowProcessMode.Park,
        };

      const setLoading = () => {
        setIsLoadingPark(false);
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };
    //#endregion <--------------------------------------------

    //#region CANCEL -------------------------------------------->
    /**
     * Handle Cancel button
     * @param data
     */
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessSubdivisionsConsolidations,
          dataCancel: data,
          defaultSuccessMessage:
            "Subdivisions and consolidations was cancelled successfully.",
          defaultErrorMessage:
            "Subdivisions and consolidations could not be cancelled.",
        });
      } else {
        onClose();
      }
    };
    //#endregion <--------------------------------------------

    //#region RETAIN -------------------------------------------->
    /**
     * Handle close dialog
     * @param renderProps
     */
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name &&
        dataFromActionList.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    /**
     * handle confirm retain workflow process
     * @param payload
     */
    const handleConfirmRetainProcess = async (
      payload: DTO_Workflow_SubdivisionsConsolidations
    ) => {
      //set loading button and dialog
      setIsLoadingClose(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_SubdivisionsConsolidations> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            pushNotification({
              title:
                e?.Notification ??
                "Subdivisions and consolidations was parked successfully.",
              type: "success",
            });
          },
          defaultFailedMessage:
            "Subdivisions and consolidations could not be parked.",
          modeProcess: WorkflowProcessMode.Park,
        };

      const setLoading = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog(undefined);
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };
    //#endregion <--------------------------------------------

    //#region FINISH -------------------------------------------->
    /**
     * handle confirm finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_SubdivisionsConsolidations
    ) => {
      setDataForFinishDialog({
        finishCallback: async () =>
          await handleFinishProcess(processData(payload)),
        confirmMessage:
          "The subdivisions and consolidations will be created based on the information provided. Are you sure you want to submit?",
      });
    };

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_SubdivisionsConsolidations
    ) => {
      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_SubdivisionsConsolidations> =
        {
          payload: payload,
          actionSuccess: (e) => {
            eventEmitter.emit(CCGridEventType.RefreshOData);
            if (!isFromActionList) {
              associationsRef.current && associationsRef.current.reloadData();
              //Refresh document and comment accordion after updated successfully
              eventEmitter.emit(PropertyDocumentEventType.RefreshData);
              eventEmitter.emit(CCGridEventType.RefreshOData, {
                gridIds: [COMMENT_ACCORDION_GRID_ID],
              });
            }
            onClose();
            pushNotification({
              title:
                e?.Notification ??
                "Subdivisions and consolidations was completed successfully.",
              type: "success",
            });
          },
          defaultFailedMessage:
            "Subdivisions and consolidations could not be completed.",
          modeProcess: WorkflowProcessMode.Finish,
        };

      const setLoading = () => {};
      //calling API process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };
    //#endregion <--------------------------------------------

    //#region APPROVE -------------------------------------------->
    /**
     * handle approve process
     * * @param payload
     */
    const handleApproveProcess = async (
      payload: DTO_Workflow_SubdivisionsConsolidations
    ) => {
      //set loading button
      setIsLoadingApprove(true);

      //props send to process workflow
      const approveProps: IProcessWorkflow<DTO_Workflow_SubdivisionsConsolidations> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            pushNotification({
              title:
                e?.Notification ??
                "Subdivisions and consolidations was approved successfully.",
              type: "success",
            });
          },
          defaultFailedMessage:
            "Subdivisions and consolidations could not be approved.",
          modeProcess: WorkflowProcessMode.Approve,
        };

      const setLoading = () => {
        setIsLoadingApprove(false);
      };
      //calling api process workflow
      await handleProcessWorkflow(approveProps, setLoading);
    };
    //#endregion <--------------------------------------------

    //#region REJECT -------------------------------------------->
    /**
     * Handle reject process
     * @param data
     */
    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postReject,
          dataReject: data,
          defaultSuccessMessage:
            "Subdivisions and consolidations was rejected successfully.",
          defaultErrorMessage:
            "Subdivisions and consolidations could not be rejected.",
        });
      } else {
        onClose();
      }
    };
    //#endregion <--------------------------------------------

    //#region SEND BACK -------------------------------------------->
    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_SubdivisionsConsolidations
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessSubdivisionsConsolidations,
        dataSendBack: payload,
        defaultSuccessMessage:
          "Subdivisions and consolidations was sent back successfully.",
        defaultErrorMessage:
          "Subdivisions and consolidations could not be sent back.",
      });
    };
    //#endregion <--------------------------------------------

    const steps: IStep[] = [
      {
        label: "Details",
        initialValues: initialValue.Details,
        component: SubdivideConsolidateDetailsFormStep,
        visible: true,
        key: EKeysOfStepsSubdivideConsolidate.Details,
        options: {
          isReadOnly,
          customTitleLabel: titleLowercaseLabel,
        },
      },
      {
        label: "Progeny",
        initialValues: initialValue.Progeny,
        component: SubdivideConsolidateProgenyFormStep,
        visible: true,
        key: EKeysOfStepsSubdivideConsolidate.Progeny,
        options: {
          isReadOnly,
          customTitleLabel: titleLowercaseLabel,
        },
      },
      {
        label: "Supplementary",
        component: SupplementaryFormStep,
        initialValues: initialValue.Supplementary,
        visible: true,
        options: {
          isReadOnly,
          supplementaryStepKey:
            EKeysOfStepsSubdivideConsolidate.NewSupplementary,
        },
        key: EKeysOfStepsSubdivideConsolidate.Supplementary,
      },
      {
        label: "New supplementary",
        initialValues: initialValue.NewSupplementary,
        component: NewSupplementaryFormStep,
        visible: false,
        key: EKeysOfStepsSubdivideConsolidate.NewSupplementary,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Comments",
        initialValues: initialValue.Comments,
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfStepsSubdivideConsolidate.Comments,
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Assessment,
        },
        customClassName: "cc-comment-step-fixed-height-grid",
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValue.Documents,
        visible: true,
        key: EKeysOfStepsSubdivideConsolidate.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Subdivisions_Consolidations,
        },
      },
    ];

    return (
      <>
        <CCFormStep
          ref={notificationFormStepRef}
          listButtonId={listSubmitButton}
          onSubmit={handleSubmit}
          initialSteps={steps}
          initialValues={initialValue}
          saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
          renderForm={(renderProps: ICCFormStepRender) => {
            return (
              <CCDialog
                maxWidth="65%"
                disabled={isLoadingApprove}
                titleHeader={titleHeader}
                onClose={() => handleCloseDialog(renderProps)}
                badge={statusBadge}
                bodyElement={renderProps.children}
                footerElement={
                  <>
                    <div className={"cc-dialog-footer-actions-right"}>
                      {!isReadOnly &&
                        isShowParkButton(
                          isFromActionList,
                          isIncompleteMode
                        ) && (
                          <Button
                            iconClass={
                              isLoadingPark ? "fas fa-spinner fa-spin" : ""
                            }
                            className={"cc-dialog-button"}
                            id={EListSubmitButton.Park}
                            onClick={renderProps.submitButton.onClick}
                            disabled={
                              renderProps.nextButton.disabled || isLoadingPark
                            }
                          >
                            Park
                          </Button>
                        )}
                      {!isReadOnly &&
                        isToBeApprovalMode &&
                        !isBeingApprovedMode && (
                          <Button
                            iconClass={
                              isLoadingSave ? "fas fa-spinner fa-spin" : ""
                            }
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            id={EListSubmitButton.Save}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Save
                          </Button>
                        )}
                      {isShowCancelWorkflowButton && !isReadOnly && (
                        <Button
                          className={"cc-dialog-button"}
                          themeColor="primary"
                          id={EListSubmitButton.Cancel}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Cancel
                        </Button>
                      )}
                      {isToBeApprovalMode && !isBeingApprovedMode && (
                        <>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.SendBack}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Send Back
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reallocate}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={() => setIsShowOfficerReallocate(true)}
                          >
                            Reallocate
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Approve}
                            disabled={
                              isLoadingApprove ||
                              renderProps.nextButton.disabled
                            }
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                            iconClass={
                              isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                            }
                          >
                            Approve
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reject}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reject
                          </Button>
                        </>
                      )}
                      {!renderProps.prevButton.disabled && (
                        <Button
                          className={"cc-dialog-button"}
                          themeColor="primary"
                          onClick={renderProps.prevButton.onClick}
                        >
                          Previous
                        </Button>
                      )}
                      {isToBeApprovalMode || isReadOnly ? (
                        !renderProps.isLastStep && (
                          <Button
                            themeColor="primary"
                            id="cc-next-step-button"
                            disabled={
                              isLoadingOnNext || renderProps.nextButton.disabled
                            }
                            className={"cc-dialog-button"}
                            iconClass={
                              isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                            }
                            onClick={renderProps.nextButton.onClick}
                          >
                            {isLoadingOnNext
                              ? "Saving"
                              : renderProps.nextButton.label}
                          </Button>
                        )
                      ) : (
                        <Button
                          themeColor="primary"
                          id={renderProps.nextButton.idButton}
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          className={"cc-dialog-button"}
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )}
                    </div>
                  </>
                }
              />
            );
          }}
        />
        {isShowOfficerReallocate && (
          <OfficerReallocateDialog
            onClose={() => {
              setIsShowOfficerReallocate(false);
            }}
            onSubmit={(data) => {
              if (handleReallocate) {
                handleReallocate(data);
              }
            }}
          />
        )}
        {callbackNextStep && (
          <ConfirmDialog
            title="Difference in Land Area"
            subMessage={
              <>
                The total Land area of the parent titles does not match that of
                the child parcels.
                <br />
                Do you wish to continue?
              </>
            }
            onClosePopup={() => setCallbackNextStep(undefined)}
            onAsyncConfirm={handleConfirmContinue}
            onConfirmNo={() => {
              callbackNextStep.callBack(false);
              setCallbackNextStep(undefined);
            }}
            btnCancelVisible={false}
          />
        )}
      </>
    );
  }
);
