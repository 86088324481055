import {
  loadGuidanceByEventId,
  loadGuidanceById,
} from "@app/products/crms/[id]/components/forms/components/reference-sidebar/guidance/api";
import { GuidanceContentIframe } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/guidance/components/guidance-content-iframe/_index";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { KBPage } from "@app/products/crms/knowledge-base/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import "./_index.scss";

interface CRMSGuidanceTabProps {
  isManagePage?: boolean;
}

export const CRMSGuidanceTab = observer(
  ({ isManagePage = false }: CRMSGuidanceTabProps) => {
    const { crmsEvent } = useCRMSEventStore();
    const { lastSelectedId } = useCCProductListViewStore();
    const [isLoading, setIsLoading] = useState(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const [knowledgeBase, setKnowledgeBase] = useState<KBPage>();

    const loadKnowledgeBaseByEventID = async (eventID: number) => {
      let errorResponse = undefined;
      setIsLoading(true);
      const response = await loadGuidanceByEventId(eventID);
      setIsLoading(false);
      if (isSuccessResponse(response) && response?.data) {
        setKnowledgeBase(response.data?.ReturnObj ?? undefined);
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
      setResponseLoadError(errorResponse);
    };

    const loadKnowledgeBaseByID = async (bulletinBoardID: number) => {
      let errorResponse = undefined;
      setIsLoading(true);
      const response = await loadGuidanceById(bulletinBoardID);
      setIsLoading(false);
      if (isSuccessResponse(response) && response?.data) {
        setKnowledgeBase(response.data);
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
      setResponseLoadError(errorResponse);
    };

    useEffect(() => {
      if (isManagePage) {
        if (crmsEvent?.BulletinBoardID)
          loadKnowledgeBaseByID(+crmsEvent.BulletinBoardID);
      } else {
        if (lastSelectedId) {
          loadKnowledgeBaseByEventID(lastSelectedId);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastSelectedId, crmsEvent?.BulletinBoardID]);

    if (isLoading) return <Loading isLoading={isLoading} />;

    if (responseLoadError) {
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            if (lastSelectedId) {
              loadKnowledgeBaseByEventID(lastSelectedId);
            }
          }}
        />
      );
    }

    if (!knowledgeBase) return <NoData />;

    return <GuidanceContentIframe content={knowledgeBase.Content_HTML} />;
  }
);
