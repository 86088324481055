import Loading from "@components/loading/Loading";
import React, { ReactElement } from "react";

interface ILoadingPermissionWrapperProps {
  isLoadingPermission: boolean;
  children: ReactElement;
}

export const LoadingPermissionWrapper = ({
  isLoadingPermission,
  children,
}: ILoadingPermissionWrapperProps) => {
  return (
    <>
      <Loading isFullScreen isLoading={isLoadingPermission} />
      {children}
    </>
  );
};
