import { NewArrangementDialog } from "@app/products/property/assessments/arrangements/components/dialogs/new-arrangements/_index";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const NewArrangementButton = observer(() => {
  const [showNewArrangementDialog, setShowNewArrangementDialog] =
    useState(false);
  const { gridSelectedRows } = useCCProductListViewStore();
  const { currentOrganisationMode } = useCommonProductStore();

  /**
   * Task D-5702
   * Hide "New arrangement" for ACTRO
   */
  const isHidden = useMemo(() => {
    return currentOrganisationMode(OrganisationMode.ACTRO);
  }, [currentOrganisationMode]);

  return (
    <>
      {isHidden ? undefined : (
        <CCNavButton
          title="New arrangement"
          disabled={gridSelectedRows.length > 1}
          onClick={() => setShowNewArrangementDialog(true)}
        />
      )}
      {showNewArrangementDialog && (
        <NewArrangementDialog
          assessmentInfo={gridSelectedRows[0]}
          onClose={() => setShowNewArrangementDialog(false)}
        />
      )}
    </>
  );
});
