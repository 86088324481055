import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { useNewTransactionDialogStoreContext } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/new-transaction/store";
import {
  getInitialRaiseCharges,
  postProcessRaiseCharges,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-charges/api";
import { AssessmentsFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-charges/components/form-elements/assessments/_index";
import { ChargeFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-charges/components/form-elements/charge/_index";
import {
  IChargesStepsLOVs,
  LOVProcessedRatingPeriod,
  LOVTransactionType,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-charges/components/form-elements/charge/model";
import { useChargeStepStore } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-charges/components/form-elements/charge/store";
import {
  DTO_Workflow_JournalAssessmentRaiseCharge,
  RaiseChargesKeysOfSteps,
  raiseChargesKeysOfSendSteps,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-charges/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { NewSupplementaryFormStep } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/_index";
import { useNewSupplementaryDialogStore } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/store";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { WhichJournalFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/_index";
import { JournalMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/model";
import { SupplementaryMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import { NewJournalFormStep } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/_index";
import { eJournalType } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/model";
import { useNewJournalStepStore } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/store";
import {
  getWorkflowOverwrittenAtAuthorisingOfficer,
  omitOfficerBasedOnStatus,
} from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/util";
import {
  DTO_Journal_LOVs,
  DTO_LOV_JournalConverted,
  DTO_LOV_RatingPeriod,
  DTO_LOV_RatingPeriodConverted,
  DTO_LOV_Transaction,
  DTO_LOV_TransactionConverted,
} from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { JournalStatus } from "@app/products/property/journals/model";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
} from "@app/products/property/model";
import {
  convertValueLOVToNumber,
  isShowParkButton,
} from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ResponsePacket } from "@common/models/identityPacket";
import { Label } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import { isArray, isNil, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IRaiseChargesDialog {
  onClose: () => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  isReadOnly?: boolean;
  isToBeApprovalMode?: boolean;
  isBeingApprovedMode?: boolean;
  isShowCancelWorkflowButton?: boolean;
  isRedirectManagePage?: boolean;
  statusBadge?: IBadgeDialog[];
}
export const RaiseChargesDialog = observer(
  ({
    onClose,
    isSaveOnNextStep = false,
    isIncompleteMode = false,
    isFromActionList = false,
    suffixTitle,
    prefixTitle,
    dataFromActionList,
    isReadOnly = false,
    isToBeApprovalMode = false,
    isBeingApprovedMode = false,
    isShowCancelWorkflowButton = false,
    statusBadge,
  }: IRaiseChargesDialog) => {
    const formStepRef = useRef<ICCFormStepNotificationHandle | null>(null);
    const { id } = useParams<{ id: string }>();
    const { setNewJournalStepLOVs } = useNewJournalStepStore();
    const { setChargesStepLOVs } = useChargeStepStore();
    const { setCreateSupplementaryLOVs } = useNewSupplementaryDialogStore();

    const { pushNotification } = useCCAppNotificationStore();
    const { setIsLoadingOnDialog } = useNewTransactionDialogStoreContext();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { currentFormTitle } = useCommonProductStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();

    const [workflowInitDataFromNew, setWorkflowInitDataFromNew] =
      useState<DTO_Workflow_JournalAssessmentRaiseCharge>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);

    //Get label
    const assessmentLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Assessment
    );

    const dialogName = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title =
        currentFormTitle(formId ?? 0) ||
        `Raise Charge Against Selected ${assessmentLabel}`;
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, workflowHeader]);

    const initialValue = useMemo((): any => {
      const journalNumber =
        workflowInitDataFromNew?.WorkflowDetail?.Journal_Number;
      let initWhichJournal: any = {
        JournalMode: workflowInitDataFromNew?.WorkflowDetail?.JournalMode,
        JournalSelected: journalNumber
          ? [{ Journal_Number: journalNumber }]
          : [],
      };
      let initCreateJournal: any = {
        ...workflowInitDataFromNew?.WorkflowDetail?.CreateJournal,
        SupplementaryMode:
          workflowInitDataFromNew?.WorkflowDetail?.SupplementaryMode,
      };
      const supplementaryId =
        workflowInitDataFromNew?.WorkflowDetail?.CreateJournal
          ?.Supplementary_Id;
      if (supplementaryId) {
        initCreateJournal._options = {
          SupplementarySelected: [
            {
              Supplementary_Id: supplementaryId,
            },
          ],
        };
      }
      let initAssessments: any = {
        Assessments: workflowInitDataFromNew?.WorkflowDetail?.Assessments ?? [],
      };
      let initNewCharge: any = {
        ChargeNettFrom: new Date(),
        ChargeDate: new Date(),
        TransactionDate: new Date(),
        ...workflowInitDataFromNew?.WorkflowDetail?.NewCharge,
      };

      formStepRef?.current?.setStepsVisible([
        {
          key: RaiseChargesKeysOfSteps.CreateJournal,
          visible:
            workflowInitDataFromNew?.WorkflowDetail.JournalMode ===
            JournalMode.CreateNew,
          isClearData: true,
        },
        {
          key: RaiseChargesKeysOfSteps.CreateSupplementary,
          visible:
            workflowInitDataFromNew?.WorkflowDetail.SupplementaryMode ===
            SupplementaryMode.CreateNew,
          isClearData: true,
        },
      ]);

      return {
        [RaiseChargesKeysOfSteps.WhichJournal]: initWhichJournal,
        [RaiseChargesKeysOfSteps.CreateJournal]: initCreateJournal,
        [RaiseChargesKeysOfSteps.Assessments]: initAssessments,
        [RaiseChargesKeysOfSteps.NewCharge]: initNewCharge,
        [RaiseChargesKeysOfSteps.Documents]: {},
        [RaiseChargesKeysOfSteps.Comments]: {},
      };
    }, [workflowInitDataFromNew]);

    const steps: IStep[] = [
      {
        label: "Journal",
        component: WhichJournalFormStep,
        visible: true,
        key: RaiseChargesKeysOfSteps.WhichJournal,
        options: {
          //hidden showBringUpField for now
          showBringUpField: false,
          isReadOnly,
          newJournalKey: RaiseChargesKeysOfSteps.CreateJournal,
          newSupplementary: RaiseChargesKeysOfSteps.CreateSupplementary,
          journalTypeNumber:
            workflowInitDataFromNew?.WorkflowDetail?.CreateJournal
              ?.Journal_Type ??
            eJournalType["Raise Charges"] ??
            1,
          journalStatus: JournalStatus.Incomplete,
        },
        initialValues: initialValue[RaiseChargesKeysOfSteps.WhichJournal],
      },
      {
        label: "New journal",
        component: NewJournalFormStep,
        visible: false,
        key: RaiseChargesKeysOfSteps.CreateJournal,
        options: {
          showStatusField: true,
          isReadOnly,
          showWhichSupplementary: true,
          supplementaryStepKey: RaiseChargesKeysOfSteps.CreateSupplementary,
          isDisableJournalType: true,
          officerNameField: "Officer_Id",
        },
        initialValues: initialValue[RaiseChargesKeysOfSteps.CreateJournal],
      },
      {
        label: "New supplementary",
        component: NewSupplementaryFormStep,
        visible: false,
        options: {
          isReadOnly,
          workflowDraftId,
        },
        initialValues:
          initialValue[RaiseChargesKeysOfSteps.CreateSupplementary],
        key: RaiseChargesKeysOfSteps.CreateSupplementary,
      },
      {
        label: assessmentLabel,
        initialValues: initialValue[RaiseChargesKeysOfSteps.Assessments],
        component: AssessmentsFormStep,
        visible: true,
        options: {
          isReadOnly,
          workflowDraftId,
        },
        key: RaiseChargesKeysOfSteps.Assessments,
      },
      {
        label: "Charge",
        initialValues: initialValue[RaiseChargesKeysOfSteps.NewCharge],
        component: ChargeFormStep,
        visible: true,
        options: {
          isReadOnly,
          workflowDraftId,
        },
        key: RaiseChargesKeysOfSteps.NewCharge,
      },
    ];

    const sendSaveNextSteps = async (
      payload: DTO_Workflow_JournalAssessmentRaiseCharge,
      isCloseDialog: boolean = false
    ) => {
      isCloseDialog && setIsLoadingSave(true);
      const response = await postProcessRaiseCharges(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingOnNext(false);
      const defaultSuccessMessage = "Transaction was saved successfully";
      const defaultFailedMessage =
        "The raise charges could not be cancelled saved";
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          if (isCloseDialog) {
            setIsLoadingSave(false);
            onClose();
            pushNotification({
              title:
                response?.data?.Notification ??
                response?.data?.SuccessMessage ??
                defaultSuccessMessage,
              type: "success",
            });
          }
          if (isFirstSave) {
            setIsFirstSave(false);
            setWorkflowDraftId(response?.data?.ID ?? 0);
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID ?? 0,
              },
            });
          }
          return true;
        } else {
          formStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
          return false;
        }
      } else {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        return false;
      }
    };

    const sendParkAddTransaction = async (
      payload: DTO_Workflow_JournalAssessmentRaiseCharge
    ) => {
      setIsLoadingPark(true);
      setIsLoadingOnDialog(true);
      setIsLoadingClose(true);
      const defaultSuccessMessage = `${dialogName} was parked successfully.`;
      const defaultFailedMessage = `${dialogName} could not be parked.`;
      const response = await postProcessRaiseCharges(
        WorkflowProcessMode.Park,
        payload
      );
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          onClose();
          pushNotification({
            title:
              response?.data?.Notification ??
              response?.data?.SuccessMessage ??
              defaultSuccessMessage,
            type: "success",
          });
        } else {
          formStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response?.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      setIsLoadingOnDialog(false);
      setIsLoadingPark(false);
      setIsLoadingClose(false);
      setDataForCloseDialog(undefined);
    };

    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessRaiseCharges,
          dataCancel: data,
          defaultSuccessMessage: `${dialogName} was cancelled successfully.`,
          defaultErrorMessage: `${dialogName} could not be cancelled.`,
        });
      } else {
        onClose();
      }
    };

    const handleNextButton = async (data: any) => {
      setIsLoadingOnNext(true);
      return sendSaveNextSteps(processData(data));
    };

    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.ConfirmCloseYes:
        case EListSubmitButton.Close:
          await sendParkAddTransaction(processData(data));
          break;
      }
    };
    /**
     * handle finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_JournalAssessmentRaiseCharge
    ) => {
      const dataProcessed = processData(payload, WorkflowProcessMode.Finish);
      const finishCallback = function async() {
        return sendFinishHolding(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage:
          "The charges will be raised based on the information provided. Are you sure you want to submit?",
      });
    };

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    const processData: DTO_Workflow_JournalAssessmentRaiseCharge | any = (
      data: any
    ) => {
      let workflowDetail: any = {};
      const sendSteps = pickBy(data, (value, key) => {
        if (
          raiseChargesKeysOfSendSteps.includes(key as RaiseChargesKeysOfSteps)
        ) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        const dataStep = { ...value };
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }
        workflowDetail[key] = dataStep;
      }
      if (!isNil(data?.[RaiseChargesKeysOfSteps.WhichJournal]?.JournalMode)) {
        workflowDetail.JournalMode =
          data?.[RaiseChargesKeysOfSteps.WhichJournal]?.JournalMode;
      }

      if (
        !isNil(data?.[RaiseChargesKeysOfSteps.WhichJournal]?.JournalSelected)
      ) {
        workflowDetail.Journal_Number =
          data?.[
            RaiseChargesKeysOfSteps.WhichJournal
          ]?.JournalSelected?.[0]?.Journal_Number;
      }

      if (isArray(data?.[RaiseChargesKeysOfSteps.Assessments]?.Assessments)) {
        workflowDetail.Assessments =
          data?.[RaiseChargesKeysOfSteps.Assessments]?.Assessments;
      }

      if (
        !isNil(data?.[RaiseChargesKeysOfSteps.CreateJournal]?.SupplementaryMode)
      ) {
        workflowDetail.SupplementaryMode =
          data[RaiseChargesKeysOfSteps.CreateJournal].SupplementaryMode;
        delete workflowDetail?.[RaiseChargesKeysOfSteps.CreateJournal]
          .SupplementaryMode;
      }

      if (
        data?.[RaiseChargesKeysOfSteps.WhichJournal]?.JournalMode ===
        JournalMode.CreateNew
      ) {
        omitOfficerBasedOnStatus(workflowDetail);
      }

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    const sendFinishHolding = async (
      payload: DTO_Workflow_JournalAssessmentRaiseCharge
    ) => {
      const defaultSuccessMessage = `${dialogName} was completed successfully.`;
      const defaultFailedMessage = `${dialogName} could not be completed.`;

      const response = await postProcessRaiseCharges(
        WorkflowProcessMode.Finish,
        payload
      );

      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          const notificationContent: IAppNotificationItemAddProps = {
            title:
              response?.data.Notification ??
              response?.data.SuccessMessage ??
              defaultSuccessMessage,
            type: "success",
          };
          onClose();
          // as per concluded in work-item 13342, keep user at the current page in all instances
          // just push notification, no redirect for now
          pushNotification(notificationContent);
        } else {
          formStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    const getWorkflowData = async () => {
      const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
      const willBeNewWorkflow = isNil(workflowDraftId);
      formStepRef?.current?.setLoadingFormStep(true);
      const response = await getInitialRaiseCharges(workflowDraftId, +id);
      if (Array.isArray(response)) {
        const [lovsJournal, workflowData] = response;
        if (
          isSuccessResponse(lovsJournal) &&
          isSuccessResponse(workflowData) &&
          lovsJournal?.data &&
          workflowData?.data
        ) {
          // process data to filter base on Financial (in Levy)
          const processedRatingPeriod: LOVProcessedRatingPeriod = {};
          const processedTransactionType: LOVTransactionType = {};
          const processedTransactionTypeOnlyCharge: LOVTransactionType = {};
          // Process Rating Period to a hashmap object
          (
            convertValueLOVToNumber<DTO_LOV_RatingPeriod>(
              lovsJournal?.data?.RatingPeriods ?? []
            ) as DTO_LOV_RatingPeriodConverted[]
          ).forEach((item) => {
            if (!item.Financial_Groups) {
              return;
            }
            item.Financial_Groups.forEach((group) => {
              if (!processedRatingPeriod[group]) {
                processedRatingPeriod[group] = [];
              }
              processedRatingPeriod[group].push(item);
            });
          });
          // Process Transaction Types and OnlyCharge to 2 hashmap objects
          (
            convertValueLOVToNumber<DTO_LOV_Transaction>(
              lovsJournal?.data?.TransactionTypes ?? []
            ) as DTO_LOV_TransactionConverted[]
          ).forEach((item) => {
            if (item.Financial_Groups.length <= 0) {
              return;
            }
            if (
              (
                lovsJournal?.data?.ChargeTransactionClassifications ?? []
              ).includes(+item.Classifications)
            ) {
              item.Financial_Groups.forEach((group) => {
                if (!processedTransactionTypeOnlyCharge[group]) {
                  processedTransactionTypeOnlyCharge[group] = [];
                  processedTransactionType[group] = [];
                }
                processedTransactionTypeOnlyCharge[group].push(item);
                processedTransactionType[group].push(item);
              });
            } else {
              item.Financial_Groups.forEach((group) => {
                if (!processedTransactionType[group]) {
                  processedTransactionType[group] = [];
                }
                processedTransactionType[group].push(item);
              });
            }
          });
          //set Lovs Data Assessment
          setNewJournalStepLOVs({
            JournalTypes: convertValueLOVToNumber(
              lovsJournal?.data?.JournalTypes ?? []
            ) as DTO_LOV_JournalConverted[],
            Officer: convertValueLOVToNumber(lovsJournal?.data?.Officer ?? []),
          });

          setChargesStepLOVs({
            Levy: convertValueLOVToNumber(lovsJournal?.data?.Levy ?? []),
            RatingPeriods: processedRatingPeriod,
            TransactionTypes: processedTransactionType,
            TransactionTypesOnlyCharge: processedTransactionTypeOnlyCharge,
          } as IChargesStepsLOVs);
          let createSupplementaryLOVs: any = {
            SupplementaryType: lovsJournal?.data?.SupplementaryType ?? [],
            InstallmentType: lovsJournal?.data?.InstalmentPlans ?? [],
            AssessmentType: lovsJournal?.data?.AssessmentType ?? [],
            ValuationTypeTobeUsed:
              lovsJournal?.data?.ValuationTypeTobeUsed ?? [],
          };
          setCreateSupplementaryLOVs(createSupplementaryLOVs);
          setWorkflowInitDataFromNew(
            willBeNewWorkflow
              ? getWorkflowOverwrittenAtAuthorisingOfficer(workflowData?.data)
              : workflowData?.data
          );

          if (workflowData.data?.WorkflowHeader) {
            setWorkflowHeader(workflowData.data?.WorkflowHeader);
            setWorkflowDraftId(
              workflowData.data?.WorkflowHeader?.WorkflowDraft
                ?.Workflow_Draft_Id ?? 0
            );
          }

          formStepRef?.current?.setLoadingFormStep(false);
        } else {
          let responseError: APIResponse<
            | DTO_Journal_LOVs
            | DTO_Workflow_JournalAssessmentRaiseCharge
            | ResponsePacket
          > = response[0];
          if (!isSuccessResponse(response[1])) {
            responseError = response[1];
          }
          formStepRef?.current?.setLoadingFormStep(false);
          formStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        formStepRef?.current?.setLoadingFormStep(false);
        formStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error: "Load workflow failed",
          },
        });
      }
    };

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <>
        <CCFormStep
          ref={formStepRef}
          onSubmit={handleSubmit}
          initialSteps={steps}
          initialValues={initialValue}
          listButtonId={listSubmitButton}
          saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
          renderForm={(renderProps: ICCFormStepRender) => (
            <CCDialog
              maxWidth="60%"
              titleHeader={dialogName}
              badge={statusBadge}
              onClose={() => handleCloseDialog(renderProps)}
              bodyElement={renderProps.children}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}
                    {!isReadOnly &&
                      isToBeApprovalMode &&
                      !isBeingApprovedMode && (
                        <Button
                          iconClass={
                            isLoadingSave ? "fas fa-spinner fa-spin" : ""
                          }
                          disabled={
                            renderProps.nextButton.disabled || isLoadingSave
                          }
                          className={"cc-dialog-button"}
                          id={EListSubmitButton.Save}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Save
                        </Button>
                      )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          isLoadingOnNext || isLoadingPark || isLoadingSave
                        }
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                </>
              }
            />
          )}
        />
      </>
    );
  }
);
