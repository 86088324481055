export const validatorMessageTemplateStep = (values: any) => {
  if (!values.SubjectContent) {
    return "Please enter subject content";
  } else {
    let removeHtmlTag = values.SubjectContent.replace("<p>", "");
    removeHtmlTag = removeHtmlTag.replace("</p>", "");
    let checkContent = removeHtmlTag.replace(/&nbsp;+/g, "").trim();
    if (checkContent === "") {
      return "Please enter subject content";
    }
  }
  return undefined;
};
export const convertRgbaToRgb = (value?: string) => {
  if(!value) return ""
  return value.replace(
    // eslint-disable-next-line no-useless-escape
    /rgba\((\d+), (\d+), (\d+), [\d\.]+\)/,
    "rgb($1, $2, $3)"
  );
};
