// enum mapped with field_name of config
export enum ECustomColNameProperty {
  //VIC
  Assessment_Property_Address = "Assess_Property_Address",
  Assess_Differential = "Assess_differential",
  Assess_Legal_Description = "Assess_legal_description",
  AssessmentNumberX = "AssessmentNumberX",
  AssessmentReference = "AssessmentReference",
  Valuation_Number = "{Valuation_Number}",
  CBA_Check_Digit = "CBA Check Digit",
  AssessmentID = "{Assessment_ID}",
  AssessmentGroup = "{Assessment_Group}",
  AssessmentGroups = "{Assessment_Groups}",
  Assessment = "{Assessment}",
  Assessments = "{Assessments}",
  NumberOfAssessments = "{Number of Assessments}",

  AssessmentNumbers = "{Assessment_Numbers}",
  AssessmentNumber = "{Assessment_Number}",

  // Actro
  AssessNumber = "Assess_Number",
  AssessReference = "Assess_Reference",
  Assessment_Reference = "{Assessment_Reference}",
  Assessment_Lookup = "{Assessment_Lookup}",
  ParcelReference = "{Parcel_Reference}",
  TitleReferenceNumber = "Title_Reference_Number",
  MapNumber = "Map Number",
  CBACheckDigit = "CBA_Check_Digit",
  BPayCheckDigit = "BPay_Check_Digit",
  AustPostCheckDigit = "AustPost_Check_Digit",
  ValnIssueDate = "Valn_Issue_Date",
  ValRestartAveraging = "Val_Restart_Averaging",

  // LLS
  Ratepayer = "{Ratepayer}",
  Ratepayers = "{Ratepayers}",
  Ratepayer_notice_group = "{Ratepayer_notice_group}",
  Sector = "{Sector}",
  Category = "{Category}",
  Title = "{Title}",
  Titles = "{Titles}",
  Title_ID = "{Title_ID}",
  Title_Number = "{Title_Number}",
  Title_Type = "{Title_Type}",
  Title_System = "{Title_System}",
  Title_Status = "{Title_Status}",
  Addresses_For_Assessment = "{Addresses_For_Assessment}",
  Legal_Description = "{Legal_Description}",
  Modify_Assessment = "{Modify_Assessment}",
  Split_Assessment = "{Split_Assessment}",
  New_Assessment = "{New_Assessment}",
  From_Assessment_Number = "{From_Assessment_Number}",
  From_Assessment = "{From_Assessment}",
  Transfer_To_Another_Assessment = "{Transfer_to_another_assessment}",
  Assessment_Detail = "{Assessment_Detail}",

  DateOfValuation = "{DateOfValuation}",
  Valuation = "{Valuation}",
  AssessmentType = "{AssessmentType}",
  Provisional_Holding_Number = "{Provisional_Holding_Number}",
  Reactivate_Assessment = "{Reactivate_Assessment}",
  Create_Assessment = "{Create_Assessment}",
  Title_Lookup = "{Title_Lookup}",
  Associate_Assessment = "{Associate_Assessment}",

  //Lower case
  AssessmentLowercase = "{Assessment_lowercase}",
  TitleLowercase = "{Title_lowercase}",
}

// the DefaultValue is changed from friendly name to code name,
// so this mapping not in good practice anymore
export const customFieldsProperty = {
  [ECustomColNameProperty.Assessment_Property_Address]: {
    FieldName: [ECustomColNameProperty.Assessment_Property_Address],
    DefaultValue: ECustomColNameProperty.Assessment_Property_Address,
  },
  [ECustomColNameProperty.Assess_Differential]: {
    FieldName: [ECustomColNameProperty.Assess_Differential],
    DefaultValue: ECustomColNameProperty.Assess_Differential,
  },
  [ECustomColNameProperty.Assess_Legal_Description]: {
    FieldName: [ECustomColNameProperty.Assess_Legal_Description],
    DefaultValue: ECustomColNameProperty.Assess_Legal_Description,
  },
  [ECustomColNameProperty.AssessmentNumberX]: {
    FieldName: [
      ECustomColNameProperty.AssessmentNumberX,
      ECustomColNameProperty.AssessNumber,
    ],
    DefaultValue: ECustomColNameProperty.AssessmentNumberX,
  },
  [ECustomColNameProperty.AssessmentReference]: {
    FieldName: [
      ECustomColNameProperty.AssessmentReference,
      ECustomColNameProperty.AssessReference,
    ],
    DefaultValue: ECustomColNameProperty.AssessmentReference,
  },
  [ECustomColNameProperty.Valuation_Number]: {
    FieldName: [ECustomColNameProperty.Valuation_Number],
    DefaultValue: ECustomColNameProperty.Valuation_Number,
  },
  [ECustomColNameProperty.CBA_Check_Digit]: {
    FieldName: [ECustomColNameProperty.CBA_Check_Digit],
    DefaultValue: ECustomColNameProperty.CBA_Check_Digit,
  },
  [ECustomColNameProperty.Assessment]: {
    FieldName: [ECustomColNameProperty.Assessment],
    DefaultValue: ECustomColNameProperty.Assessment,
  },
  [ECustomColNameProperty.Assessments]: {
    FieldName: [ECustomColNameProperty.Assessments],
    DefaultValue: ECustomColNameProperty.Assessments,
  },
  [ECustomColNameProperty.AssessmentID]: {
    FieldName: [ECustomColNameProperty.AssessmentID],
    DefaultValue: ECustomColNameProperty.AssessmentID,
  },
  [ECustomColNameProperty.AssessmentGroup]: {
    FieldName: [ECustomColNameProperty.AssessmentGroup],
    DefaultValue: ECustomColNameProperty.AssessmentGroup,
  },
  [ECustomColNameProperty.AssessmentGroups]: {
    FieldName: [ECustomColNameProperty.AssessmentGroups],
    DefaultValue: ECustomColNameProperty.AssessmentGroups,
  },
  [ECustomColNameProperty.AssessmentNumbers]: {
    FieldName: [ECustomColNameProperty.AssessmentNumbers],
    DefaultValue: ECustomColNameProperty.AssessmentNumbers,
  },
  [ECustomColNameProperty.AssessmentNumber]: {
    FieldName: [ECustomColNameProperty.AssessmentNumber],
    DefaultValue: ECustomColNameProperty.AssessmentNumber,
  },
  // Actro
  [ECustomColNameProperty.ParcelReference]: {
    FieldName: [ECustomColNameProperty.ParcelReference],
    DefaultValue: ECustomColNameProperty.ParcelReference,
  },
  [ECustomColNameProperty.TitleReferenceNumber]: {
    FieldName: [ECustomColNameProperty.TitleReferenceNumber],
    DefaultValue: ECustomColNameProperty.TitleReferenceNumber,
  },
  [ECustomColNameProperty.MapNumber]: {
    FieldName: [ECustomColNameProperty.MapNumber],
    DefaultValue: ECustomColNameProperty.MapNumber,
  },
  [ECustomColNameProperty.CBACheckDigit]: {
    FieldName: [ECustomColNameProperty.CBACheckDigit],
    DefaultValue: ECustomColNameProperty.CBACheckDigit,
  },
  [ECustomColNameProperty.BPayCheckDigit]: {
    FieldName: [ECustomColNameProperty.BPayCheckDigit],
    DefaultValue: ECustomColNameProperty.BPayCheckDigit,
  },
  [ECustomColNameProperty.AustPostCheckDigit]: {
    FieldName: [ECustomColNameProperty.AustPostCheckDigit],
    DefaultValue: ECustomColNameProperty.AustPostCheckDigit,
  },
  [ECustomColNameProperty.ValnIssueDate]: {
    FieldName: [ECustomColNameProperty.ValnIssueDate],
    DefaultValue: ECustomColNameProperty.ValnIssueDate,
  },
  [ECustomColNameProperty.ValRestartAveraging]: {
    FieldName: [ECustomColNameProperty.ValRestartAveraging],
    DefaultValue: ECustomColNameProperty.ValRestartAveraging,
  },
  [ECustomColNameProperty.Ratepayer]: {
    FieldName: [ECustomColNameProperty.Ratepayer],
    DefaultValue: ECustomColNameProperty.Ratepayer,
  },
  [ECustomColNameProperty.Ratepayers]: {
    FieldName: [ECustomColNameProperty.Ratepayers],
    DefaultValue: ECustomColNameProperty.Ratepayers,
  },
  [ECustomColNameProperty.Ratepayer_notice_group]: {
    FieldName: [ECustomColNameProperty.Ratepayer_notice_group],
    DefaultValue: ECustomColNameProperty.Ratepayer_notice_group,
  },
  [ECustomColNameProperty.Sector]: {
    FieldName: [ECustomColNameProperty.Sector],
    DefaultValue: ECustomColNameProperty.Sector,
  },
  [ECustomColNameProperty.Category]: {
    FieldName: [ECustomColNameProperty.Category],
    DefaultValue: ECustomColNameProperty.Category,
  },
  [ECustomColNameProperty.Title]: {
    FieldName: [ECustomColNameProperty.Title],
    DefaultValue: ECustomColNameProperty.Title,
  },
  [ECustomColNameProperty.Titles]: {
    FieldName: [ECustomColNameProperty.Titles],
    DefaultValue: ECustomColNameProperty.Titles,
  },
  [ECustomColNameProperty.Title_ID]: {
    FieldName: [ECustomColNameProperty.Title_ID],
    DefaultValue: ECustomColNameProperty.Title_ID,
  },
  [ECustomColNameProperty.Assessment_Reference]: {
    FieldName: [ECustomColNameProperty.Assessment_Reference],
    DefaultValue: ECustomColNameProperty.Assessment_Reference,
  },
  [ECustomColNameProperty.Addresses_For_Assessment]: {
    FieldName: [ECustomColNameProperty.Addresses_For_Assessment],
    DefaultValue: ECustomColNameProperty.Addresses_For_Assessment,
  },
  [ECustomColNameProperty.Legal_Description]: {
    FieldName: [ECustomColNameProperty.Legal_Description],
    DefaultValue: ECustomColNameProperty.Legal_Description,
  },
  [ECustomColNameProperty.Modify_Assessment]: {
    FieldName: [ECustomColNameProperty.Modify_Assessment],
    DefaultValue: ECustomColNameProperty.Modify_Assessment,
  },
  [ECustomColNameProperty.Split_Assessment]: {
    FieldName: [ECustomColNameProperty.Split_Assessment],
    DefaultValue: ECustomColNameProperty.Split_Assessment,
  },
  [ECustomColNameProperty.New_Assessment]: {
    FieldName: [ECustomColNameProperty.New_Assessment],
    DefaultValue: ECustomColNameProperty.New_Assessment,
  },
  [ECustomColNameProperty.From_Assessment_Number]: {
    FieldName: [ECustomColNameProperty.From_Assessment_Number],
    DefaultValue: ECustomColNameProperty.From_Assessment_Number,
  },
  [ECustomColNameProperty.From_Assessment]: {
    FieldName: [ECustomColNameProperty.From_Assessment],
    DefaultValue: ECustomColNameProperty.From_Assessment,
  },
  [ECustomColNameProperty.Transfer_To_Another_Assessment]: {
    FieldName: [ECustomColNameProperty.Transfer_To_Another_Assessment],
    DefaultValue: ECustomColNameProperty.Transfer_To_Another_Assessment,
  },
  [ECustomColNameProperty.Assessment_Detail]: {
    FieldName: [ECustomColNameProperty.Assessment_Detail],
    DefaultValue: ECustomColNameProperty.Assessment_Detail,
  },
  [ECustomColNameProperty.Assessment_Lookup]: {
    FieldName: [ECustomColNameProperty.Assessment_Lookup],
    DefaultValue: ECustomColNameProperty.Assessment_Lookup,
  },
  [ECustomColNameProperty.AssessmentType]: {
    FieldName: [ECustomColNameProperty.AssessmentType],
    DefaultValue: ECustomColNameProperty.AssessmentType,
  },
  [ECustomColNameProperty.DateOfValuation]: {
    FieldName: [ECustomColNameProperty.DateOfValuation],
    DefaultValue: ECustomColNameProperty.DateOfValuation,
  },
  [ECustomColNameProperty.Valuation]: {
    FieldName: [ECustomColNameProperty.Valuation],
    DefaultValue: ECustomColNameProperty.Valuation,
  },
  [ECustomColNameProperty.Provisional_Holding_Number]: {
    FieldName: [ECustomColNameProperty.Provisional_Holding_Number],
    DefaultValue: ECustomColNameProperty.Provisional_Holding_Number,
  },
  [ECustomColNameProperty.Reactivate_Assessment]: {
    FieldName: [ECustomColNameProperty.Reactivate_Assessment],
    DefaultValue: ECustomColNameProperty.Reactivate_Assessment,
  },
  [ECustomColNameProperty.Create_Assessment]: {
    FieldName: [ECustomColNameProperty.Create_Assessment],
    DefaultValue: ECustomColNameProperty.Create_Assessment,
  },
  [ECustomColNameProperty.Title_Lookup]: {
    FieldName: [ECustomColNameProperty.Title_Lookup],
    DefaultValue: ECustomColNameProperty.Title_Lookup,
  },
  [ECustomColNameProperty.Associate_Assessment]: {
    FieldName: [ECustomColNameProperty.Associate_Assessment],
    DefaultValue: ECustomColNameProperty.Associate_Assessment,
  },
  [ECustomColNameProperty.Title_Number]: {
    FieldName: [ECustomColNameProperty.Title_Number],
    DefaultValue: ECustomColNameProperty.Title_Number,
  },
  [ECustomColNameProperty.Title_Type]: {
    FieldName: [ECustomColNameProperty.Title_Type],
    DefaultValue: ECustomColNameProperty.Title_Type,
  },
  [ECustomColNameProperty.Title_System]: {
    FieldName: [ECustomColNameProperty.Title_System],
    DefaultValue: ECustomColNameProperty.Title_System,
  },
  [ECustomColNameProperty.Title_Status]: {
    FieldName: [ECustomColNameProperty.Title_Status],
    DefaultValue: ECustomColNameProperty.Title_Status,
  },
  [ECustomColNameProperty.AssessmentLowercase]: {
    FieldName: [ECustomColNameProperty.AssessmentLowercase],
    DefaultValue: ECustomColNameProperty.AssessmentLowercase,
  },
  [ECustomColNameProperty.TitleLowercase]: {
    FieldName: [ECustomColNameProperty.TitleLowercase],
    DefaultValue: ECustomColNameProperty.TitleLowercase,
  },
  [ECustomColNameProperty.NumberOfAssessments]: {
    FieldName: [ECustomColNameProperty.NumberOfAssessments],
    DefaultValue: ECustomColNameProperty.NumberOfAssessments,
  },
};
