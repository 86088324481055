import { EKeysOfSteps } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/model";
import {
  colSupplementary,
  dataWhichSupplementary,
  getSupplementaryOdata,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/config";
import {
  SupplementaryMode,
  VO_Supplementary,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import {
  checkIsMyExistingSupp,
  checkIsNewSupp,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/util";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  RadioGroup,
  RadioGroupChangeEvent,
} from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";
const formValidator = (values: any) => {
  if (
    !values ||
    (!checkIsNewSupp(values?._options?.WhichSupplementary) &&
      values.SupplementarySelected &&
      values.SupplementarySelected.length !== 1)
  ) {
    return "Must select one supplementary";
  }
  return undefined;
};

const nameOfVOSupplementary = nameOfFactory<VO_Supplementary>();
export const WhichSupplementaryFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? formValidator : undefined}
    />
  );
};

const FormStepElement = observer(
  ({
    setStepsVisible,
    options = {},
    formRenderProps,
    nameOf,
    resetAllStepsAfter,
  }: IFormStepElement) => {
    const {
      isReadOnly = false,
      supplementaryOptions = dataWhichSupplementary,
      isFilteringCompletedSupp = false,
    } = options;
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const supplementaryMode = getFieldValue("_options.WhichSupplementary");

    const handleChangeStep = (whichSupplementary: SupplementaryMode) => {
      const newSupplementaryValue: boolean =
        whichSupplementary === SupplementaryMode.CreateNew;
      const selectFromMyListValue: boolean =
        whichSupplementary === SupplementaryMode.SelectFromMyList;

      if (isReadOnly) return;
      onChange(nameOf("SupplementarySelected"), {
        value: [],
      });
      onChange(nameOf("_options.WhichSupplementary"), {
        value: whichSupplementary,
      });
      onChange(nameOf("NewSupplementary"), {
        value: newSupplementaryValue,
      });
      onChange(nameOf("SelectFromMyList"), {
        value: selectFromMyListValue,
      });
      const newSteps = resetAllStepsAfter();
      setStepsVisible(
        [
          {
            key: EKeysOfSteps.CreateSupplementary,
            visible: newSupplementaryValue,
            isClearData: true,
          },
        ],
        newSteps
      );
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Select an option below</label>
            <Field
              name={nameOf("_options.WhichSupplementary")}
              data={supplementaryOptions}
              component={RadioGroup}
              onChange={(e: RadioGroupChangeEvent) => {
                handleChangeStep(e.value);
              }}
              disabled={isReadOnly}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          {!checkIsNewSupp(supplementaryMode) ? (
            <div className="cc-field">
              <CCLabel
                title="Supplementary"
                isMandatory
                errorMessage={errors?.[nameOf("")]}
              />
              <CCGrid
                dataUrl={getSupplementaryOdata(
                  checkIsMyExistingSupp(supplementaryMode),
                  isFilteringCompletedSupp
                )}
                selectedRows={getFieldValue("SupplementarySelected")}
                primaryField={nameOfVOSupplementary("Supplementary_Id")}
                columnFields={colSupplementary}
                readOnly={isReadOnly}
                selectableMode="single"
                onSelectionChange={(dataItem: VO_Supplementary[]) => {
                  onChange(nameOf("SupplementarySelected"), {
                    value: [...dataItem],
                  });
                  if (dataItem?.[0]?.Supplementary_Id) {
                    onChange(nameOf("Supplementary_Id"), {
                      value: dataItem[0].Supplementary_Id,
                    });
                  }
                }}
                state={{
                  sort: [
                    {
                      field: nameOfVOSupplementary("Supplementary_Id"),
                      dir: "desc",
                    },
                  ],
                }}
              />
            </div>
          ) : null}
        </div>
      </section>
    );
  }
);
