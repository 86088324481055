import { IJournalProps, Journal } from "@app/core/journal/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

interface IPropertyHistoryProps extends Omit<IJournalProps, "id"> {
  /**
   * This field is used to retrieve an ID when the ID you want to use difference from primaryField in CCProductListview.
   * If not provided, it will default to obtaining the primaryField in CCProductListview.
   * Rule: the ID in listview and manage page must be the same
   */
  primaryField?: string;
}

export const PropertyHistoryTab = observer((props: IPropertyHistoryProps) => {
  const { lastSelectedId, lastSelectedRow } = useCCProductListViewStore();
  const { primaryField } = props;
  const params: { id: string } = useParams();
  const manangePageId = params.id;
  const id: string | number =
    manangePageId ||
    (primaryField ? lastSelectedRow?.[primaryField] : lastSelectedId) ||
    0;
  return <Journal id={id} {...props} />;
});
