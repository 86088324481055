export interface DTO_AssociatedItem_Assessment {
  Id: number;
  IdString: string;
  Association: string;
  AssessmentNumber: string;
  AssessmentReference: string;
  PropertyName_Address_Locality: string;
  RatepayerName: string;
  LegalDescription: string;
  UnitEntitlement: number | null;
  UnitEntitlementPercentage: number | null;
  LevyUnitType: string;
  UnitTypeEntitlementPercent: number | null;
  ValuationValues: DTO_Valuation_Value[];
  MasterProperty: string;
  Balance: number | null;
  InterestBalance: number | null;
  BalanceOutstanding: number | null;
  DeferredBalance: number | null;
  DeferredInterest: number | null;
  PendingDiscount: number | null;
  BeingPostponed: boolean;
  PostponedBalance: number | null;
  PostponedInterestBalance: number | null;
  AssessmentGroup: string;
  ValuationGroup: string;
  Type: string;
  Status: string;
  ValuationNumber: string;
  Improvements: string;
  LandUses: string;
  FireLevyLandStatus: string;
  AlertMessage: string;
  BalanceOwing: number | null;
}
export interface DTO_Valuation_Value {
  Name: string;
  FieldName: string;
  Value: number;
}

export const PropertyUnion = "FieldName";
