import {
  Attachment,
  AttachmentUpdateTriggers,
} from "@app/core/documents/model";
import { IUpdateAttachment } from "@app/core/view/components/dialogs/view-documents/components/dialogs/update-attachment/model";
import { CoreKeyword } from "@common/models/coreKeyword";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React from "react";

interface IUpdateAttachmentDialogProps {
  isLoading?: boolean;
  isUpdating?: boolean;
  notifications?: IAppNotificationItem[];
  attachmentInfo: IUpdateAttachment | undefined;
  onSubmit: (data: IUpdateAttachment) => void;
  onClose: () => void;
}
export const UpdateAttachmentDialog = ({
  isLoading = false,
  isUpdating = false,
  notifications,
  attachmentInfo,
  onClose,
  onSubmit,
}: IUpdateAttachmentDialogProps) => {
  const nameOf = nameOfFactory<IUpdateAttachment>();
  const nameOfCoreKeyword = nameOfFactory<CoreKeyword>();

  const handleOnSubmitClick = async (event: FormSubmitClickEvent) => {
    const data = event.values as IUpdateAttachment;
    onSubmit({
      ...attachmentInfo,
      _options: {
        Attachment: {
          ...attachmentInfo?._options?.Attachment,
          Title: data?.NewTitle,
          Category_KWD: data?.Category_KWD,
          SaveTriggers: [AttachmentUpdateTriggers?.UpdateTitle],
        } as Attachment,
      },
    });
  };

  return (
    <Form
      initialValues={attachmentInfo}
      key={JSON.stringify(attachmentInfo)}
      onSubmitClick={handleOnSubmitClick}
      render={({ valueGetter, onChange, onSubmit, valid }: FormRenderProps) => {
        return (
          <CCDialog
            titleHeader={"Update Attachment Details"}
            height={"auto"}
            maxWidth={"25%"}
            onClose={onClose}
            disabled={isUpdating}
            bodyElement={
              isLoading ? (
                <Loading isLoading={isLoading} />
              ) : (
                <FormElement className="cc-form">
                  <CCLocalNotification defaultNotifications={notifications} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Current title <CCTooltip type="validator" />
                        </label>
                        <Field
                          name={nameOf("CurrentTitle")}
                          component={CCInput}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          New title <CCTooltip type="validator" />
                        </label>
                        <Field
                          name={nameOf("NewTitle")}
                          component={CCInput}
                          validator={requiredValidator}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Category</label>
                        <Field
                          className="cc-dropdown"
                          name={nameOf("Category_KWD")}
                          data={valueGetter("_options.Categories") ?? []}
                          textField={nameOfCoreKeyword("Name")}
                          dataItemKey={nameOfCoreKeyword("Keyword_ID")}
                          component={CCSearchComboBox}
                        />
                      </div>
                    </div>
                  </section>
                </FormElement>
              )
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  onClick={onSubmit}
                  disabled={!valid}
                  iconClass={isUpdating ? "fas fa-spinner fa-spin" : ""}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
