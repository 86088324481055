import { ECustomColNameProperty } from "@app/products/property/config";
import { eDBRowState, eProductType } from "@common/stores/products/enum";

export interface CCPrivilege {
  Id: number;
  Name: string;
}
export interface CCPrivileges {
  Privileges: CCPrivilege[];
}

//Server Interface
export interface ProductListingDTO {
  Sys_ModifiedBy: string;
  Sys_ModifiedBy_ID: number;
  Sys_ModifiedDate: string;
  Sys_DBRowState: eDBRowState;
  Sys_EnterpriseKey: string;
  Sys_TimeStamp: string;
  Sys_TransactionDate: string;
  ClientListing_ID: number;
  ProductType_ENUM: eProductType;
  CustomProductName: string;
  ProductType_Name: string;
  Theme_LKP: number;
  LicenceKey: string;
  Menu_Width: number | null;
  Logo: string;
  FileSize: number;
  Filename: string;
  ContentType: string;
  Menu_Sort_Order: number | null;
  Product_Label: string;
  Flag_CommunityCentral: boolean | null;
  Product_Acronym: string;
  Menu?: CCMenuDTO;
  LabelNames: CCLabelNamesDTO;
  FormConfig: CCFormConfigDTO;
  Organisations: CCOrganisations;
  ProductType_Int: number;
  WorkflowTypes: CCWorkflowTypes;
  Privileges: CCPrivileges;
}

export interface CCWorkflowTypes {
  WorkflowTypes: CCWorkflowType[];
}

export interface CCWorkflowType {
  WorkflowType_ENUM: string;
  WorkflowType_Name: string;
  WorkflowType_Int: number;
}

export interface CCOrganisations {
  Organisation: CCOrganisation;
}

export interface CCOrganisation {
  Id: number;
  Name: string;
  State: string;
  Address: string;
  ABN: string;
  OrganisationMode: OrganisationMode;
}

export enum OrganisationMode {
  General = "General",
  LLS = "LLS",
  ACTRO = "ACTRO",
}

export interface CCFormConfigDTO {
  Forms: CCFormDTO[];
  ParentForms: CCParentFormDTO[];
  ParentFormTabs: CCParentFormTabDTO[];
}

export interface CCFormDTO {
  Form_Id: number;
  Standard_Form_No: number;
  Form_Name: string;
  Form_Title: string;
}

export interface CCParentFormDTO {
  Parent_Form_Id: number;
  Component_Number: number;
  Name: string;
  Title: string;
}

export interface CCParentFormTabDTO {
  Parent_Form_Id: number;
  Form_Id: number;
  Sequence: number;
  Tab_Label: string;
}

export interface CCLabelNamesDTO {
  LabelNames: CCLabelNameDTO[];
}

export interface CCLabelNameDTO {
  ColumnName: string;
  LabelName: string;
}

export interface CCMenuDTO {
  Nodes: CCMenuNodeDTO[];
}

export interface CCMenuNodeDTO {
  MenuItem_Id: number;
  ParentMenuItem_Id?: number | null;
  Name: string;
  ToolTip?: string;
  MenuActionEnumeration_Id: number | null;
  NavigateUrl?: string;
  IsCustomisation?: boolean | null;
  UserView_Id?: number | null;
  ChildNodes?: CCMenuNodeDTO[];
  IsUnnavigated?: boolean; // Open NavigateUrl in the same tab or new tab
}

//Client interface
export interface ICCMenuItem {
  name: string;
  path: string;
  navigationPath?: string;
  tooltip?: string;
  children?: ICCMenuItem[];
  isUnnavigated?: boolean; // Open navigationPath in the same tab or new tab
}

export interface IFlatCCMenuItem extends Omit<ICCMenuItem, "children"> {}

/**
 * The mapping of product types to their respective custom field configurations.
 */
export type CustomFieldConfigAllProducts = {
  [key in eProductType]: CustomFieldConfig;
};

/**
 * The mapping of properties in the ECustomColName enum to their respective custom field configurations
 */
export type CustomFieldConfig = {
  [key in keyof ECustomColName]?: CustomFieldAttribute<ECustomColName[key]>;
};

/**
 * Defines attributes for a custom field, including names, default values, and optional custom values.
 */
export interface CustomFieldAttribute<T> {
  FieldName: T[];
  DefaultValue: string;
  CustomValue?: string;
}

/**
 * A type that maps a product type to its respective ECustomColName enum.
 * It ensures type safety by limiting the product type to valid keys of ECustomColName.
 */
export type ECustomColNameMap<T extends eProductType> =
  T extends keyof ECustomColName ? ECustomColName[T] : never;

/**
 * A mapping of product types to their respective ECustomColName enum.
 */
type ECustomColName = {
  [eProductType.CommunityProperty]: ECustomColNameProperty;

  // Add other product types as needed
};
