import { DeleteButton } from "@app/core/delete/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { AnimalActionBarNavDropdown } from "@app/products/animals/components/action-bar/nav-dropdown/_index";
import { KennelIssueRenewalFeesSubButton } from "@app/products/animals/kennels-renewals/components/button/issue-renewal-fees/_index";
import { Svc_Animals_Kennel } from "@app/products/animals/kennels-renewals/model";
import { AnimalKennelsRenewalsRequiresRenewalsFeesByBookmark } from "@app/products/animals/kennels-renewals/requires-renewal-fees/util";
import { kennelsRenewalsRoute } from "@app/products/animals/kennels-renewals/route";
import { getAnimalsKennelRenewalUrl } from "@app/products/animals/kennels-renewals/util";
import { ANIMALS_KENNEL_MANAGE_PAGE } from "@app/products/animals/kennels/[id]/constant";
import { animalRoute } from "@app/products/animals/route";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { colAnimalsKennelsRenewals } from "../config";

const nameOf = nameOfFactory<Svc_Animals_Kennel>();

export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Animals_Form_Kennel,
    productType: PRODUCT_TYPE_NUMBER.Animals,
  });

  useCCListViewActionBar({
    title: animalRoute.name,
    leftComponents: [
      <AnimalActionBarNavDropdown category={kennelsRenewalsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton title="Issue renewal fees" type="sub">
        <KennelIssueRenewalFeesSubButton isAllRecords />
        <KennelIssueRenewalFeesSubButton />
      </CCNavButton>,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.Animals}
        dataSetFilter={MAILMERGEDATASET.Animals_Kennel}
        recordType={RECORDTYPE.Animals_Kennel}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      /**
       * Waiting for confirmation on task #12716
       * Reason: on API, It does not have the setup function for itself.
       * It is used from the setup function of the Pound Register views
       **/
      // <CommunicationButtonListView
      //   productType={PRODUCT_TYPE_NUMBER.Animals}
      //   recordType={RECORDTYPE.Animals_Kennel}
      //   keyField={nameOf("ID")}
      // />,
      <DeleteButton
        recordType={RECORDTYPE.Animals_Kennel}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ListViewBookmarkIcon
        linkUrl={ANIMALS_KENNEL_MANAGE_PAGE}
        productType={PRODUCT_TYPE.Animals}
        recordType={RECORDTYPE.Animals_Kennel}
        detail={
          AnimalKennelsRenewalsRequiresRenewalsFeesByBookmark.getBookmarkListViewDisplayName
        }
        displayName={
          AnimalKennelsRenewalsRequiresRenewalsFeesByBookmark.getBookmarkListViewDetailRecord
        }
        listViewDetail={
          AnimalKennelsRenewalsRequiresRenewalsFeesByBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          AnimalKennelsRenewalsRequiresRenewalsFeesByBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        columnFields={colAnimalsKennelsRenewals}
        dataUrl={getAnimalsKennelRenewalUrl(false, false)}
        primaryField={nameOf("ID")}
      />
    </LoadingPermissionWrapper>
  );
});
