import { useIsNew } from "@common/hooks/useIsNew";
import {
  TEMPLATE_DESCRIPTION_MAX_LENGTH,
  TEMPLATE_NAME_MAX_LENGTH,
} from "@common/pages/settings/security/security-templates/_id/child-screens/general/components/form-element/config";
import { SecurityActionAllowButton } from "@common/pages/settings/security/security-templates/_id/components/buttons/allow-actions/_index";
import { SecurityActionDenyButton } from "@common/pages/settings/security/security-templates/_id/components/buttons/deny-actions/_index";
import { colSecurityActionGrid } from "@common/pages/settings/security/security-templates/_id/config";
import { useSecurityTemplateStore } from "@common/pages/settings/security/security-templates/_id/store";
import {
  SecurityAction,
  SecurityTemplate,
} from "@common/pages/settings/security/security-templates/model";
import { ProductListingDTO } from "@common/stores/products/model";

import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface ISecurityTmpFormElemenProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<SecurityTemplate>();
const nameOfGrid = nameOfFactory<SecurityAction>();
const nameOfProductListing = nameOfFactory<ProductListingDTO>();

export const SecurityTmpFormElement = observer(
  ({ formRenderProps }: ISecurityTmpFormElemenProps) => {
    const { valueGetter } = formRenderProps;
    const {
      productListing,
      securityActionListing,
      allowAndDenyListing,
      setAllowAndDenyListing,
    } = useSecurityTemplateStore();
    const isNew = useIsNew();

    return (
      <FormElement>
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              {isNew ? (
                <>
                  <label className="cc-label">Product</label>
                  <Field
                    name={"_options.Product"}
                    textField={nameOfProductListing("ProductType_Name")}
                    dataItemKey={nameOfProductListing("ProductType_ENUM")}
                    component={CCSearchComboBox}
                    data={productListing ?? []}
                  />
                </>
              ) : (
                <CCValueField
                  label="Product"
                  value={valueGetter("ProductType_Name")}
                />
              )}
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Name
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("TemplateName")}
                value={valueGetter("TemplateName")}
                component={CCInput}
                validator={requiredValidator}
                maxLength={TEMPLATE_NAME_MAX_LENGTH}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Description
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("TemplateDescription")}
                value={valueGetter("TemplateDescription")}
                component={CCTextArea}
                validator={requiredValidator}
                rows={3}
                maxLength={TEMPLATE_DESCRIPTION_MAX_LENGTH}
              />
            </div>
            {!isNew ? (
              <div>
                <CCGrid
                  toolbar={
                    <div className="cc-grid-tools-bar">
                      <SecurityActionAllowButton isGridToolBar />
                      <SecurityActionDenyButton isGridToolBar />
                    </div>
                  }
                  itemPerPage={10}
                  selectableMode="multiple"
                  primaryField={nameOfGrid("SecurityKey")}
                  selectedRows={allowAndDenyListing}
                  onSelectionChange={(dataItem: SecurityAction[]) => {
                    if (dataItem) setAllowAndDenyListing([...dataItem]);
                  }}
                  columnFields={colSecurityActionGrid}
                  data={securityActionListing ?? []}
                />
              </div>
            ) : null}
          </div>
        </div>
      </FormElement>
    );
  }
);
