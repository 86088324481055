import { history } from "@/AppRoutes";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { putSendToActionOfficer } from "@common/pages/actions/components/action-bar/buttons/send-to-action-officer/api";
import { Action, IParentActionSection } from "@common/pages/actions/model";
import { actionStore } from "@common/pages/actions/[id]/store";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

class SendToActionOfficerButtonStore {
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }
  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };

  sendToActionOfficer = async (
    action?: Action,
    parent?: IParentActionSection
  ) => {
    if (action === undefined) return;
    let respId = null;
    let respRecordType = null;
    this.setIsLoading(true);
    if (!parent) {
      respRecordType = RECORDTYPE.CORE_Register;
    } else {
      const { id: parentId, recordType } = parent;
      respId = parentId;
      respRecordType = recordType;
    }
    const response = await putSendToActionOfficer(
      action,
      respId,
      respRecordType
    );
    if (isSuccessResponse(response)) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        actionStore.reLoadAction().then(() => {
          appNotificationStore.clearErrorNotification();
          appNotificationStore.pushNotification({
            onClose: () => {
              history.goBack();
            },
            type: "success",
            title: "Record saved successfully",
          });
        });
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Record could not be saved",
          type: "error",
          description: response.data?.Errors,
        });
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: response?.error,
        type: "error",
      });
    }
    this.setIsLoading(false);
  };
}
export const sendToActionOfficerButtonStore =
  new SendToActionOfficerButtonStore();
const sendToActionOfficerButtonContext = createContext(
  sendToActionOfficerButtonStore
);
export const useSendToActionOfficerButtonStore = () => {
  return useContext(sendToActionOfficerButtonContext);
};
