import { ECustomColNameProperty } from "@app/products/property/config";
import { nameOfLov } from "@app/products/property/model";
import { useModifyParcelDialogStore } from "@app/products/property/parcels/[id]/components/forms/existed/components/form-step/edit-parcel/store";
import {
  convertValueLOVToNumber,
  isFieldVisible,
} from "@app/products/property/util";
import { DTO_LOV } from "@common/models/odataResponse";
import { Label } from "@common/stores/products/config";
import { requiredValidator } from "@common/utils/field-validators";
import { formatAssessmentNumberInMasked } from "@common/utils/formatting";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCMaskedTextBox } from "@components/cc-masked-textbox/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { MaskedTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = { isReadOnly: false },
  }: IFormStepElement) => {
    //using store
    const { modifyParcelLOVs } = useModifyParcelDialogStore();
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const isShowSwimmingPool: boolean = isFieldVisible(
      valueGetter("SwimmingPoolFlagVisibility")
    );
    const isShowLandCategory: boolean = isFieldVisible(
      valueGetter("LandCategoryVisibility")
    );
    const isShowElectoralDivisionLocal: boolean = isFieldVisible(
      valueGetter("ElectoralDivisionLocalVisibility")
    );
    const isShowElectoralDivisionStateCode: boolean = isFieldVisible(
      valueGetter("ElectoralDivisionStateCodeVisibility")
    );
    const isShowElectoralDivisionFederal: boolean = isFieldVisible(
      valueGetter("ElectoralDivisionFederalVisibility")
    );
    const isShowPropertyCategories: boolean = isFieldVisible(
      valueGetter("PropertyCategoriesVisibility")
    );
    const isShowClassifications: boolean = isFieldVisible(
      valueGetter("ClassificationsVisibility")
    );
    const isShowDistricts: boolean = isFieldVisible(
      valueGetter("DistrictsVisibility")
    );
    const isShowEstate: boolean = isFieldVisible(
      valueGetter("EstateVisibility")
    );
    const isShowElectoralDivision: boolean =
      isShowElectoralDivisionLocal ||
      isShowElectoralDivisionStateCode ||
      isShowElectoralDivisionFederal;
    const isShowFireServices: boolean =
      isShowPropertyCategories || isShowClassifications || isShowDistricts;

    //process Lovs data of dropdown
    const statusLOV = convertValueLOVToNumber<DTO_LOV>(
      modifyParcelLOVs?.Parcel_Status ?? [],
      "Code"
    );
    const estateLOV = convertValueLOVToNumber<DTO_LOV>(
      modifyParcelLOVs?.Estate ?? [],
      "Code"
    );
    const localGovtLOV = convertValueLOVToNumber<DTO_LOV>(
      modifyParcelLOVs?.Council ?? [],
      "Code"
    );
    const stateLOV = convertValueLOVToNumber<DTO_LOV>(
      modifyParcelLOVs?.State_Electoral_Division ?? [],
      "Code"
    );
    const federalLOV = convertValueLOVToNumber<DTO_LOV>(
      modifyParcelLOVs?.Federal_Electoral_Division ?? [],
      "Code"
    );
    const propertyCategoryLOV = convertValueLOVToNumber<DTO_LOV>(
      modifyParcelLOVs?.Fire_Property_Category ?? [],
      "Code"
    );
    const classificationLOV = convertValueLOVToNumber<DTO_LOV>(
      modifyParcelLOVs?.Fire_Service_Classification ?? [],
      "Code"
    );
    const districtLOV = convertValueLOVToNumber<DTO_LOV>(
      modifyParcelLOVs?.Fire_Service_District ?? [],
      "Code"
    );
    const reasonForChange = convertValueLOVToNumber<DTO_LOV>(
      modifyParcelLOVs?.ReasonForChange ?? [],
      "Code"
    );

    //Get labels
    const [parcelReferenceLabel, legalDescriptionLabel, titleLowerCase] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.ParcelReference,
        ECustomColNameProperty.Legal_Description,
        ECustomColNameProperty.TitleLowercase
      ]);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Applicant name</label>
            <Field
              name={nameOf("ApplicantName")}
              placeholder={"Applicant name"}
              component={CCInput}
              readOnly={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Reason for change" isMandatory />
            <Field
              name={nameOf("ReasonForChange")}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={options?.isReadOnly}
              data={reasonForChange}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
            />
          </div>
        </div>
        <div className="cc-field-group">
          <label className="cc-label">Classification</label>
          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">{parcelReferenceLabel}</label>
                <Field
                  name={nameOf("Reference")}
                  readOnly={options?.isReadOnly}
                  component={CCMaskedTextBox}
                  mask="000-000-0000-000"
                  onChange={(event: MaskedTextBoxChangeEvent) => {
                    const rawDataOnlyNumber =
                      event.target.value?.replace(/\D/g, "") ?? "";
                    const newValue =
                      formatAssessmentNumberInMasked(rawDataOnlyNumber);
                    if (getFieldValue("Reference") !== newValue) {
                      onChange(nameOf("Reference"), {
                        value: newValue,
                      });
                    }
                  }}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Status</label>
                <Field
                  name={nameOf("Status")}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  isUseDefaultOnchange
                  component={CCSearchComboBox}
                  data={statusLOV}
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Land area</label>
                <Field
                  name={nameOf("LandArea")}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cc-field-group">
          <label className="cc-label">{legalDescriptionLabel}</label>
          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                {`Automatically update legal description from ${titleLowerCase} legal
                  descriptions`}
                </label>
                <Field
                  name={nameOf("AutoUpdateLegalDescription")}
                  checked={getFieldValue("AutoUpdateLegalDescription")}
                  component={CCSwitch}
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <Field
                  name={nameOf("LegalDescription")}
                  placeholder={"Legal description"}
                  component={CCTextArea}
                  readOnly={
                    getFieldValue("AutoUpdateLegalDescription") ||
                    options?.isReadOnly
                  }
                  rows={4}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={isShowEstate ? "cc-form-cols-3" : "cc-form-cols-2"}>
          {isShowEstate && (
            <div className="cc-field">
              <label className="cc-label">Estate</label>
              <Field
                name={nameOf("Estate")}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                isUseDefaultOnchange
                component={CCSearchComboBox}
                data={estateLOV}
                disabled={options?.isReadOnly}
              />
            </div>
          )}
          <div className="cc-field">
            <label className="cc-label">Stage</label>
            <Field
              name={nameOf("EstateStage")}
              placeholder={"Stage"}
              component={CCInput}
              readOnly={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Map number</label>
            <Field
              name={nameOf("MapNumber")}
              placeholder={"Map number"}
              component={CCInput}
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>
        <div
          className={
            isShowSwimmingPool && isShowLandCategory
              ? "cc-form-cols-3"
              : "cc-form-cols-2"
          }
        >
          <div className="cc-field">
            <label className="cc-label">Census district</label>
            <Field
              name={nameOf("CensusDistrict")}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              data={[]}
              disabled={options?.isReadOnly}
            />
          </div>
          {isShowLandCategory && (
            <div className="cc-field">
              <label className="cc-label">Land category</label>
              <Field
                name={nameOf("LandCategory")}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                isUseDefaultOnchange
                component={CCSearchComboBox}
                data={[]}
                disabled={options?.isReadOnly}
              />
            </div>
          )}
          {isShowSwimmingPool && (
            <div className="cc-field">
              <label className="cc-label">Has a swimming pool</label>
              <Field
                name={nameOf("HasSwimmingPool")}
                checked={getFieldValue("HasASwimmingPool")}
                component={CCSwitch}
                disabled={options?.isReadOnly}
              />
            </div>
          )}
        </div>
        {isShowElectoralDivision && (
          <div className="cc-field-group">
            <label className="cc-label">Electoral division</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                {isShowElectoralDivisionLocal && (
                  <div className="cc-field">
                    <label className="cc-label">Local</label>
                    <Field
                      name={nameOf("LocalElectoralDivision")}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      isUseDefaultOnchange
                      component={CCSearchComboBox}
                      data={localGovtLOV}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                )}
                {isShowElectoralDivisionStateCode && (
                  <div className="cc-field">
                    <label className="cc-label">State code</label>
                    <Field
                      name={nameOf("StateElectoralDivision")}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      isUseDefaultOnchange
                      component={CCSearchComboBox}
                      data={stateLOV}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                )}
                {isShowElectoralDivisionFederal && (
                  <div className="cc-field">
                    <label className="cc-label">Federal</label>
                    <Field
                      name={nameOf("FederalElectoralDivision")}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      isUseDefaultOnchange
                      component={CCSearchComboBox}
                      data={federalLOV}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {isShowFireServices && (
          <div className="cc-field-group">
            <label className="cc-label">Fire services</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                {isShowPropertyCategories && (
                  <div className="cc-field">
                    <label className="cc-label">Property category</label>
                    <Field
                      name={nameOf("Fire_Property_Category")}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      isUseDefaultOnchange
                      component={CCSearchComboBox}
                      disabled={options?.isReadOnly}
                      data={propertyCategoryLOV}
                    />
                  </div>
                )}
                {isShowClassifications && (
                  <div className="cc-field">
                    <label className="cc-label">Classification</label>
                    <Field
                      name={nameOf("Fire_Service_Classification")}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      isUseDefaultOnchange
                      component={CCSearchComboBox}
                      data={classificationLOV}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                )}
                {isShowDistricts && (
                  <div className="cc-field">
                    <label className="cc-label">District</label>
                    <Field
                      name={nameOf("Fire_Service_District")}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      isUseDefaultOnchange
                      component={CCSearchComboBox}
                      data={districtLOV}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
);
