import { history } from "@/AppRoutes";
import { postLocalLaws, putLocalLaws } from "@app/products/local-laws/api";
import { ILocalLaws, LocalLaws } from "@app/products/local-laws/model";
import { getLocalLawsById } from "@app/products/local-laws/[id]/api";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class LocalLawsStore {
  private _localLaws?: ILocalLaws = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get localLaws() {
    return toJS(this._localLaws);
  }
  setLocalLaws = (localLaws?: ILocalLaws) => {
    runInAction(() => {
      this._localLaws = localLaws;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get localLawsId() {
    return toJS(this.localLaws?.ID);
  }

  //Action

  resetStore = () => {
    runInAction(() => {
      this._localLaws = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadLocalLaws = async (localLawsId: number) => {
    this.setIsLoading(true);
    const response = await getLocalLawsById(localLawsId);
    let errorResponse = undefined;
    let newLocalLaws = undefined;
    if (isSuccessResponse(response)) {
      newLocalLaws = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setLocalLaws(newLocalLaws);
    this.setIsLoading(false);
  };
  createLocalLaw = async (localLaw: LocalLaws) => {
    this.setIsLoading(true);
    await postLocalLaws(localLaw);
    appNotificationStore.pushNotification({
      title: "Local law created successfully",
      type: "success",
      onClose: () => {
        history.push(`/locallaws/manageregistration/${localLaw?.id || 1}`);
      },
    });
    this.setIsLoading(false);
  };
  updateLocalLaw = async (localLaw: LocalLaws) => {
    this.setIsLoading(true);
    await putLocalLaws(localLaw);
    appNotificationStore.pushNotification({
      title: "Local law updated successfully",
      type: "success",
    });
    this.setIsLoading(false);
  };
}

const localLawsStoreContext = createContext(new LocalLawsStore());
export const useLocalLawsStore = () => {
  return useContext(localLawsStoreContext);
};
