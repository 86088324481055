import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.AssessmentId
    ? `- ${selectedRow.AssessmentId}`
    : "";
  return `Property - Assessments ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.Assessment,
    selectedRow.PropertyAddress,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return "Property - Assessments";
};

export const getBookmarkListViewDetail = () => {
  return "Property - Assessments - Compliance - Land Tax Exemptions";
};
