import { eComponent } from "@app/products/property/components/associations/model";
import { JournalItemsDetail } from "@app/products/property/journals/[id]/components/child-screens/items/components/details/_index";
import { ITEMS_ACCORDION_GRID_ID } from "@app/products/property/journals/[id]/components/child-screens/items/constant";
import { useJournalTransactionStore } from "@app/products/property/journals/[id]/components/child-screens/items/store";
import {
  getItemsInfoBaseOnComponentNumber,
  journalItemOdata,
} from "@app/products/property/journals/[id]/components/child-screens/items/util";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { State } from "@progress/kendo-data-query";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { isEqual, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface IJournalItemsProps {
  componentNumber: number | undefined;
}

export const JournalItems = observer(
  ({ componentNumber }: IJournalItemsProps) => {
    const {
      columnFields,
      isLoading,
      isLoadingGrid,
      responseLoadErrorGrid,
      responseLoadErrorViewConfig,
      itemsData,
      selectedTransaction,
      loadItemsConfigurationColumns,
      resetStore,
      setSelectedTransaction,
      loadJournalItemsData,
    } = useJournalTransactionStore();
    const { id } = useParams<{ id: string }>();
    const { primaryField, nameForTransactionId, nameForJournalNumber } =
      getItemsInfoBaseOnComponentNumber(componentNumber);

    const renderComponent = () => {
      let data: any[] | undefined = undefined;
      let state: State | undefined = {
        sort: [
          {
            field: primaryField,
            dir: "asc",
          },
        ],
      };
      let dataUrl: string | undefined = undefined;
      let detail: React.ComponentType<GridDetailRowProps> | null | undefined = (
        props: GridDetailRowProps
      ) => {
        return React.cloneElement(
          <JournalItemsDetail
            {...props}
            nameForTransactionId={nameForTransactionId}
            nameForJournalNumber={nameForJournalNumber}
          />
        );
      };

      switch (componentNumber) {
        case eComponent.PIC:
          data = itemsData?.PIC_Transactions;
          state = undefined;
          detail = undefined;
          break;
        case eComponent.Assessment:
          dataUrl = journalItemOdata(id);
          break;
        default:
          dataUrl = journalItemOdata(id);
          break;
      }

      return (
        <CCGrid
          gridId={ITEMS_ACCORDION_GRID_ID}
          data={data}
          dataUrl={dataUrl}
          state={state}
          columnFields={columnFields}
          selectableMode="single"
          onSelectionChange={(dataItem: any[]) =>
            setSelectedTransaction(dataItem?.[0])
          }
          selectedRows={isNil(selectedTransaction) ? [] : [selectedTransaction]}
          primaryField={primaryField}
          detail={detail}
        />
      );
    };

    // If loading data with odata, then we need to pass the journal number to the loadJournalItemsData
    // because we need to redefine the logic to calculate the total amount.
    const totalAmountConfigId = () : string | undefined  => {
      if (isEqual(componentNumber, eComponent.Assessment)) {
        return id;
      }
      return undefined;
    }

    useEffectOnce(() => {
      loadItemsConfigurationColumns(componentNumber, totalAmountConfigId());
      (isEqual(componentNumber, eComponent.PIC))
        && loadJournalItemsData(id);
      return () => resetStore();
    });

    if (isLoading || isLoadingGrid) return <Loading isLoading />;
    if (responseLoadErrorGrid || responseLoadErrorViewConfig)
      return (
        <CCLoadFailed
          responseError={responseLoadErrorGrid}
          onReload={() => {
            responseLoadErrorViewConfig &&
              loadItemsConfigurationColumns(componentNumber, totalAmountConfigId());
              responseLoadErrorGrid && loadJournalItemsData(id);
          }}
        />
      );

    return (
      <div className="cc-property-supplementary-transactions">
        {renderComponent()}
      </div>
    );
  }
);
