import { eventEmitter } from "@/App";
import { history } from "@/AppRoutes";
import { FURTHER_INFO_ROUTE } from "@app/core/further-info/[id]/constant";
import { IParentFISection } from "@app/core/further-info/[id]/model";
import { UITownPlanning } from "@app/products/town-planning/model";
import {
  getPPRSummaryById,
  postPPR,
  putAmendPPR,
  putValidateAndSave,
} from "@app/products/town-planning/ppr/[id]/api";
import { putPlansToComply } from "@app/products/town-planning/ppr/[id]/components/buttons/plans-to-comply/api";
import { plansToComplyButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/plans-to-comply/store";
import { allocateToOfficerStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/allocate-to-officer/store";
import { amendApplicationButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/amend-application/store";
import { appealCompleteButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/appeal-complete-application/store";
import { appealWithdrawnButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/appeal-withdrawn/store";
import { fiNotRequiredButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/fi-not-required/store";
import { lodgeApplicationStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/lodge-application/store";
import { noAppealButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/no-appeal/store";
import { officerAssessmentButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/officer-assessment/store";
import { sendReptAndRespLetterForApprovalButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/send-rept-and-resp-letter-for-approval/store";
import { withdrawApplicationStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/withdraw-application/store";
import { pprApplicationFrom } from "@app/products/town-planning/ppr/[id]/config";
import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import {
  Application,
  IPPRApplicationParentSection,
  PPRSubmitActions,
  pprSubmitWithValidateActions,
} from "@app/products/town-planning/ppr/[id]/model";
import {
  convertApplicationToPPRForm,
  convertRegisterEnquiryToPPRForm,
  processDataParentSentToAppeal,
} from "@app/products/town-planning/ppr/[id]/util";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { APIResponseStatus } from "@common/constants/response-status";
import { DBRowState } from "@common/models/baseClassStandard";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { APPEAL_ROUTE } from "@common/pages/appeal/_id/constant";
import { IParentAppealSection } from "@common/pages/appeal/_id/model";
import { commonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class PPRStore {
  private _ppr?: Application = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _parentSection?: IPPRApplicationParentSection = undefined;
  private _isInactive: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isInactive() {
    return this._isInactive;
  }
  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get ppr() {
    return toJS(this._ppr);
  }
  setPPR = (ppr?: any) => {
    runInAction(() => {
      this._ppr = ppr;
    });
  };

  setPPRWithLoading = (ppr?: Application) => {
    runInAction(() => {
      this._isLoading = true;
      this._ppr = convertApplicationToPPRForm(ppr);
      this._isLoading = false;
    });
  };

  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: IPPRApplicationParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  get pprId() {
    return toJS(this.ppr?.Application_ID);
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._ppr = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._onSubmit = undefined;
      this._parentSection = undefined;
      this._isInactive = false;
    });
  };

  //Return isReloadSuccess
  reLoadPPR = async (): Promise<boolean> => {
    if (this.pprId) {
      return await this.loadPPR(this.pprId);
    }
    return false;
  };

  loadPPR = async (pprId: number, isNew?: boolean): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    if (isNew) {
      if (
        this._parentSection?.data &&
        (this?._parentSection?.isAmendPermit ||
          this._parentSection?.isPlanToComply)
      ) {
        this.setPPR(this._parentSection?.data);
      } else if (this.parentSection?.registerData) {
        this.setPPR(
          convertRegisterEnquiryToPPRForm(
            toJS(this.parentSection?.registerData)
          )
        );
      } else {
        this.setPPR(pprApplicationFrom);
      }
    } else {
      const response = await getPPRSummaryById(pprId);
      let newPPR = undefined;
      if (isSuccessResponse(response)) {
        newPPR = convertApplicationToPPRForm(response.data);
        if (response.data?.Sys_DBRowState === DBRowState.Inactive) {
          this.setIsInactive(true);
          appNotificationStore.clearNotifications();
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Important note",
            type: "info",
            description:
              "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
          });
        }
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
      this.setPPR(newPPR);
      if (this.parentSection?.notification) {
        this.parentSection?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
        this.setParentSection({ ...this._parentSection, notification: [] });
      }
    }

    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  // TODO: Need to handle API response again, just mockup
  savePPR = async (
    pprInfo: Application,
    action: PPRSubmitActions,
    isNew?: boolean
  ) => {
    this.setIsLoading(true);
    if (action === PPRSubmitActions.CreateAndLodgeApplication) {
      lodgeApplicationStore.setNewApplication(pprInfo);
      lodgeApplicationStore.setIsShowDialog(true);
      this.setIsLoading(false);
      return;
    }
    if (pprSubmitWithValidateActions.includes(action)) {
      await this.validateSave(action, pprInfo);
    } else if (
      isNew &&
      this?._parentSection?.isAmendPermit &&
      this._parentSection?.data
    ) {
      const response = await putAmendPPR(pprInfo);
      this.setIsLoading(false);

      if (isSuccessIdentityPacket(response)) {
        //Reload After save
        if (this.pprId) await this.loadPPR(this.pprId);
        this.runActions(action, response);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Amend permit application could not be saved.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    } else if (
      isNew &&
      this?._parentSection?.isPlanToComply &&
      this._parentSection?.data
    ) {
      const response = await putPlansToComply(
        pprInfo,
        plansToComplyButtonStore.isPPRWorkflow
      );
      this.setIsLoading(false);

      if (isSuccessIdentityPacket(response)) {
        //Reload After save
        if (this.pprId) await this.loadPPR(this.pprId);
        this.runActions(action, response);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Plan to comply application could not be saved.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    } else {
      const response = await postPPR(pprInfo);
      this.setIsLoading(false);

      if (isSuccessIdentityPacket(response)) {
        //Reload After save
        if (this.pprId) await this.loadPPR(this.pprId);
        this.runActions(action, response);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Application could not be saved.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    }
  };

  handleValidateSave = async (
    id: number,
    saveApplication: boolean,
    application: Application,
    workflow: UITownPlanning
  ) => {
    let isSuccess = false;
    const response = await putValidateAndSave(id, saveApplication, application);
    if (isSuccessIdentityPacket(response)) {
      PPRStoreInstance.setPPR(response.data?.Application);
      appNotificationStore.clearErrorNotification();
      eventEmitter.emit(workflow.toString());
    } else {
      if (response.data?.Application) {
        PPRStoreInstance.setPPR(response.data.Application);
      }
      appNotificationStore.pushNotification({
        autoClose: false,
        description: response.data?.Errors ?? response.statusText,
        type: "error",
      });
    }
    this.setIsLoading(false);
    return isSuccess;
  };

  runActions = (action: PPRSubmitActions, response?: APIResponse) => {
    switch (action) {
      case PPRSubmitActions.Save:
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Application saved successfully",
          type: "success",
        });
        break;
      case PPRSubmitActions.New:
        appNotificationStore.clearErrorNotification();
        history.replace(`${TOWN_PLANNING_PPR_ROUTE}/${response?.data?.ID}`, {
          notification: [
            { title: "Application saved successfully", type: "success" },
          ],
        });
        break;
      case PPRSubmitActions.OfficerAssessment:
        officerAssessmentButtonStore.setIsShowDialog(true);
        break;
      case PPRSubmitActions.LodgeApplication:
        lodgeApplicationStore.setIsShowDialog(true);
        break;
      case PPRSubmitActions.WithdrawnApplication:
        withdrawApplicationStore.setIsShowDialog(true);
        break;
      case PPRSubmitActions.FiNotRequired:
        if (this.pprId) fiNotRequiredButtonStore.fiNotRequired(this.pprId);
        break;
      case PPRSubmitActions.RequestFI:
        if (this.ppr) {
          history.push(`${FURTHER_INFO_ROUTE}/new`, {
            parent: {
              id: this.ppr.Application_ID,
              recordType: RECORDTYPE.TP_PPRApplication,
              data: {
                DateRequested: new Date(),
                Officer_ID: this.ppr?.Planner_ID,
                Officer: { DisplayName: this.ppr?.Planner },
                NumberOfDaysGiven: getNumberValueSetting(
                  commonCoreStore?.settings[
                    ECorporateSettingsField.TP_StatDaysFIDays
                  ]
                ),
              },
              parentAction: PPRSubmitActions.RequestFI,
            } as IParentFISection,
          });
        }
        break;
      case PPRSubmitActions.AllocateToOfficer:
        allocateToOfficerStore.setIsShowDialog(true);
        break;
      case PPRSubmitActions.AppealComplete:
        appealCompleteButtonStore.setIsShowDialog(true);
        break;
      case PPRSubmitActions.AmendApplication:
        amendApplicationButtonStore.setIsShowDialog(true);
        break;
      case PPRSubmitActions.SendReptAndRespLetterForApproval:
        if (this.pprId) {
          sendReptAndRespLetterForApprovalButtonStore.handleSendReptAndRespLetterForApprovalValidate(
            this.pprId
          );
        }
        break;
      case PPRSubmitActions.Appealed:
        if (this.ppr) {
          history.push(`${APPEAL_ROUTE}/new`, {
            parent: {
              id: this.ppr.Application_ID,
              recordType: RECORDTYPE.TP_PPRApplication,
              data: processDataParentSentToAppeal(this.ppr),
              parentAction: PPRSubmitActions.Appealed,
            } as IParentAppealSection,
          });
        }
        break;
      case PPRSubmitActions.AppealWithdrawn:
        if (this.pprId) {
          appealWithdrawnButtonStore.handleSubmitAppealWithdrawn(this.pprId);
        }
        break;
      case PPRSubmitActions.NoAppeal:
        noAppealButtonStore.setIsShowDialog(true);
        break;
      default:
        break;
    }
  };

  validateSave = async (action: PPRSubmitActions, pprInfo?: Application) => {
    switch (action) {
      case PPRSubmitActions.ApproveReportAndResponse:
        if (this.pprId && pprInfo) {
          await this.handleValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.ApproveReport
          );
        }
        break;
      case PPRSubmitActions.ReportAndResponseNotApproved:
        if (this.pprId && pprInfo) {
          await this.handleValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.ResponseReportLetterNotApproved
          );
        }
        break;
      case PPRSubmitActions.SendForTLApproval:
        if (this.pprId && pprInfo) {
          await this.handleValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.PlansToComplySendForApprovalNotes
          );
        }
        break;
      case PPRSubmitActions.ResendForTLApproval:
        if (this.pprId && pprInfo) {
          await this.handleValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.PPRSendForApproval
          );
        }
        break;
      case PPRSubmitActions.SendLetterToRAandApplicant:
        if (this.pprId && pprInfo) {
          await this.handleValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.SendLetterToRAAndApplicant
          );
        }
        break;
      default:
        break;
    }
  };
}

export const PPRStoreInstance = new PPRStore();
const PPRStoreContext = createContext(PPRStoreInstance);
export const usePPRStore = () => {
  return useContext(PPRStoreContext);
};
