import { FurtherInfoDetailTab } from "@app/core/further-info/[id]/components/reference-sidebar/detail/_index";
import { FurtherInfoHistoryTab } from "@app/core/further-info/[id]/components/reference-sidebar/history/_index";
import { NoticeDetailTab } from "@app/core/notices/[id]/components/reference-sidebar/details/_index";
import { NoticHistoryTab } from "@app/core/notices/[id]/components/reference-sidebar/history/_index";
import { FormIdentifier } from "@app/products/crms/model";
import { ActionsContactsTab } from "@common/pages/actions/[id]/components/reference-sidebar/contacts/_index";
import { ActionsDetailTab } from "@common/pages/actions/[id]/components/reference-sidebar/details/_index";
import { ActionsHistoryTab } from "@common/pages/actions/[id]/components/reference-sidebar/history/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { NoData } from "@components/no-data/NoData";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

export const ActionsDetailTabSwitch = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();

  if (isNil(lastSelectedRow?.FormIdentifier_ENUM)) return <NoData />;
  const renderContent = React.useCallback(() => {
    switch (lastSelectedRow?.FormIdentifier_ENUM) {
      case FormIdentifier.Core_Form_Action:
        return <ActionsDetailTab />;
      case FormIdentifier.Core_Form_Notice:
        return <NoticeDetailTab />;
      case FormIdentifier.Core_Form_ActionPlan:
      case FormIdentifier.Core_Form_VCATReview:
      case FormIdentifier.Core_Form_Advertising:
      case FormIdentifier.Core_Form_Prosecution:
      case FormIdentifier.Core_Form_Complaint:
        return <NoData />;
      case FormIdentifier.Core_Form_FurtherInformationRequest:
        return <FurtherInfoDetailTab />;
      default:
        return <NoData />;
    }
  }, [lastSelectedRow]);

  return <>{renderContent()}</>;
});

export const ActionsContactsTabSwitch = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  if (isNil(lastSelectedRow?.FormIdentifier_ENUM)) return <NoData />;

  const renderContent = React.useCallback(() => {
    switch (lastSelectedRow?.FormIdentifier_ENUM) {
      case FormIdentifier.Core_Form_Action:
        return <ActionsContactsTab />;
      case FormIdentifier.Core_Form_Notice:
      case FormIdentifier.Core_Form_ActionPlan:
      case FormIdentifier.Core_Form_VCATReview:
      case FormIdentifier.Core_Form_Advertising:
      case FormIdentifier.Core_Form_Prosecution:
      case FormIdentifier.Core_Form_Complaint:
      case FormIdentifier.Core_Form_FurtherInformationRequest:
        return <NoData />;
      default:
        return <NoData />;
    }
  }, [lastSelectedRow]);

  return <>{renderContent()}</>;
});

export const ActionsHistoryTabSwitch = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  if (isNil(lastSelectedRow?.FormIdentifier_ENUM)) return <ActionsHistoryTab />;
  const renderContent = React.useCallback(() => {
    switch (lastSelectedRow?.FormIdentifier_ENUM) {
      case FormIdentifier.Core_Form_Action:
        return <ActionsHistoryTab />;
      case FormIdentifier.Core_Form_Notice:
        return <NoticHistoryTab />;
      case FormIdentifier.Core_Form_ActionPlan:
      case FormIdentifier.Core_Form_VCATReview:
      case FormIdentifier.Core_Form_Advertising:
      case FormIdentifier.Core_Form_Prosecution:
      case FormIdentifier.Core_Form_Complaint:
        return <NoData />;
      case FormIdentifier.Core_Form_FurtherInformationRequest:
        return <FurtherInfoHistoryTab />;
      default:
        return <NoData />;
    }
  }, [lastSelectedRow]);

  return <>{renderContent()}</>;
});
