import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { getKeywords } from "@common/apis/coreKeyword";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { CoreKeyword } from "@common/models/coreKeyword";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { isEmpty } from "lodash";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IProvideReasonDialogProps {
  onClose: () => void;
  onSubmit: (
    value: ProvideReason,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => void;
  isLoading: boolean;
  setIsLoading?: any;
  initialData?: ProvideReason;
}

const nameOf = nameOfFactory<ProvideReason>();
const nameOfCoreKeyword = nameOfFactory<CoreKeyword>();

export const ReasonDialog = ({
  onClose,
  onSubmit,
  isLoading,
  setIsLoading,
  initialData,
}: IProvideReasonDialogProps) => {
  const [keyWordReasonList, setKeyWordReasonList] = useState<
    CoreKeyword[] | undefined
  >();
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    onSubmit(event.values as ProvideReason, notificationRef);
  };

  useEffectOnce(() => {
    if (
      initialData?.Flag_ShowKWD &&
      initialData.KWDType &&
      initialData.ProdType &&
      setIsLoading
    ) {
      setIsLoading(true);
      getKeywords(initialData.KWDType, initialData.ProdType).then(
        (response) => {
          if (isSuccessResponse(response)) {
            if (response?.data) setKeyWordReasonList(response?.data);
          } else {
            notificationRef.current?.pushNotification({
              title: `Reason keyword categories load failed`,
              type: "warning",
            });
          }
        }
      );
      setIsLoading(false);
    }
  });

  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      initialValues={initialData}
      render={(formRenderProps: FormRenderProps) => {
        return (
          <FormElement>
            <CCDialog
              height="auto"
              maxWidth="30%"
              titleHeader={
                !isEmpty(initialData?.Title_Text) && initialData?.Title_Text
                  ? initialData?.Title_Text
                  : "Provide Reason"
              }
              onClose={onClose}
              disabled={isLoading}
              bodyElement={
                <div className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      {initialData?.Flag_ShowKWD && (
                        <div className="cc-field">
                          <CCLabel
                            title={
                              !isEmpty(initialData?.KWDLabel_Text) &&
                              initialData?.KWDLabel_Text
                                ? initialData?.KWDLabel_Text
                                : "Keyword Reason"
                            }
                            isMandatory={initialData?.Flag_KWDIsMandatory}
                          />
                          <Field
                            name={nameOf("Reason_KWD")}
                            component={CCSearchComboBox}
                            validator={
                              initialData?.Flag_KWDIsMandatory
                                ? requiredValidator
                                : undefined
                            }
                            isUseDefaultOnchange
                            data={keyWordReasonList || []}
                            textField={nameOfCoreKeyword("Name")}
                            dataItemKey={nameOfCoreKeyword("Keyword_ID")}
                          />
                        </div>
                      )}
                      {initialData?.Flag_ShowReasonTextBox && (
                        <div className="cc-field">
                          <CCLabel
                            title={
                              !isEmpty(initialData?.ReasonLabel_Text) &&
                              initialData?.ReasonLabel_Text
                                ? initialData?.ReasonLabel_Text
                                : "Reason"
                            }
                            isMandatory={initialData?.Flag_TextIsMandatory}
                          />
                          <Field
                            name={nameOf("Reason")}
                            component={CCTextArea}
                            rows={4}
                            placeholder={
                              !isEmpty(initialData?.ReasonLabel_Text) &&
                              initialData?.ReasonLabel_Text
                                ? initialData?.ReasonLabel_Text
                                : "Reason"
                            }
                            validator={
                              initialData?.Flag_TextIsMandatory
                                ? requiredValidator
                                : undefined
                            }
                          />
                        </div>
                      )}
                      {initialData?.Flag_ShowDate && (
                        <div className="cc-field">
                          <CCLabel
                            title={
                              !isEmpty(initialData?.DateLabel_Text) &&
                              initialData?.DateLabel_Text
                                ? initialData?.DateLabel_Text
                                : "Date Action Performed"
                            }
                            isMandatory={initialData?.Flag_DateIsMandatory}
                          />
                          <Field
                            name={nameOf("DateOfAction")}
                            format={DATE_FORMAT.DATE_CONTROL}
                            component={CCDatePicker}
                            validator={
                              initialData?.Flag_DateIsMandatory
                                ? requiredValidator
                                : undefined
                            }
                          />
                        </div>
                      )}
                    </div>
                  </section>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    disabled={!formRenderProps.valid}
                    onClick={formRenderProps.onSubmit}
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
