import { AnimalTypeSubmitActions } from "@app/products/animals/[id]/model";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import { postRegenerateFee } from "@app/products/animals/components/action-bar/buttons/regenerate-fee/api";
import { isSuccessResponse } from "@common/apis/util";
import { usePageType } from "@common/hooks/usePageType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IRegenerateFeeButtonProps {
  title?: string;
}
export const RegenerateFeeButton = observer(
  ({ title = "Regenerate Fee" }: IRegenerateFeeButtonProps) => {
    //#region Store
    const { settings } = useCommonCoreStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { onSubmit, isLoadingRegenerateFee } = useAnimalStore();
    const { gridSelectedIds, clearSelectedItems } = useCCProductListViewStore();
    //#endregion

    const [isLoading, setIsLoading] = useState(false);
    const { isProductListView } = usePageType();
    const enableRegenerateFee = getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_EnableRegenerateFee]
    );
    if (!enableRegenerateFee) return null;

    const handleOnClick = async () => {
      if (!isProductListView || isEmpty(gridSelectedIds)) return;
      setIsLoading(true);
      const response = await postRegenerateFee(gridSelectedIds, true);
      setIsLoading(false);
      if (isSuccessResponse(response)) {
        pushNotification({
          title: "Regenerate fee successfully.",
          type: "success",
        });
        clearSelectedItems();
      } else {
        pushNotification({
          autoClose: false,
          description: response.data?.Errors ?? response?.error,
          type: "error",
        });
      }
    };
    return (
      <>
        {(isLoading || isLoadingRegenerateFee) && (
          <Loading isLoading isFullScreen />
        )}
        <CCNavButton
          title={title}
          disabled={isProductListView && isEmpty(gridSelectedIds)}
          onClick={isProductListView ? handleOnClick : onSubmit}
          name={AnimalTypeSubmitActions.RegenerateFee}
        />
      </>
    );
  }
);
