import { validatorPhysicalAddress } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/components/form-elements/physical-address-grid/util";
import { NewAddressDialog } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/dialog/new-address";
import {
  DTO_Address,
  EKeysOfSteps,
} from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/model";
import { useAmalgamateAssessmentStore } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/store";
import { colPhysicalAddress } from "@app/products/property/assessments/components/form-steps/modify-assessment/components/form-elements/physical-address-grid/config";
import { IEditAddAddressLOVs } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/models";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";

export const PhysicalAddressGridFormStep = (props: IFormStepElement) => {
  const newValidator = useCallback(
    (values: any) => {
      return validatorPhysicalAddress(values, props?.options?.isLLS);
    },
    [props?.options?.isLLS]
  );
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? newValidator : undefined}
    />
  );
};

const nameOfAddress = nameOfFactory<DTO_Address>();

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = { isReadOnly: false, isLLS: false },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const [isShowAddDialog, setIsShowAddDialog] = useState<boolean>();
    const [updateDialogData, setUpdateDialogData] = useState<DTO_Address>();
    const address = getFieldValue("Addresses") ?? [];
    const addressSelected = getFieldValue("AddressSelected") ?? [];
    // update later
    const initNewAddressDialog = {
      Country: valueGetter(`${EKeysOfSteps.AssessmentDetail}.Country`),
      State: valueGetter(`${EKeysOfSteps.AssessmentDetail}.State`),
    };

    const { lovsAmalgamateAssessment } = useAmalgamateAssessmentStore();

    const initEditAddAddressLOVs: IEditAddAddressLOVs = useMemo(() => {
      return {
        Address_Side_of_Street:
          lovsAmalgamateAssessment?.Address_Side_of_Street,
        Address_State: lovsAmalgamateAssessment?.Address_State,
        Address_FloorType: lovsAmalgamateAssessment?.Address_FloorType,
        Address_UnitType: lovsAmalgamateAssessment?.Address_UnitType,
        Address_Type: lovsAmalgamateAssessment?.Address_Type,
        Country: lovsAmalgamateAssessment?.Country,
      };
    }, [lovsAmalgamateAssessment]);

    /**
     * handle add item
     * @param data
     */
    const handleAddItem = (data: DTO_Address) => {
      const newItemId = getUUID();
      const newData = {
        ...data,
        RowId: newItemId,
        AddressPFI: 0,
      };
      const previousAddress = address?.length
        ? [...address, newData]
        : [newData];
      // If new data have IsAddressPrimary=true => reset all of IsAddressPrimary
      //  Only one IsAddressPrimary = true
      if (data?.IsAddressPrimary && previousAddress?.length > 0) {
        handleSetPrimaryKey(newItemId, previousAddress, false);
        return;
      }

      onChange(nameOf("Addresses"), {
        value: previousAddress,
      });
    };

    /**
     * handle add item
     * @param data
     */
    const handleUpdateItem = (data: DTO_Address) => {
      const newAddress =
        address?.map((item: DTO_Address) => {
          return item?.RowId === data?.RowId ? { ...item, ...data } : item;
        }) ?? [];

      if (data?.IsAddressPrimary && newAddress?.length > 0) {
        handleSetPrimaryKey(data?.RowId, newAddress, false);
        return;
      }
      onChange(nameOf("Addresses"), {
        value: newAddress,
      });
    };

    /**
     * handle delete item
     */
    const handleDeleteItem = () => {
      const addressId = addressSelected?.[0]?.RowId;
      const newAddress = address.filter(
        (item: DTO_Address) => addressId !== item.RowId
      );
      onChange(nameOf("Addresses"), {
        value: newAddress,
      });
      onChange(nameOf("AddressSelected"), {
        value: [],
      });
    };

    /**
     * handle set Primary key
     * @param id
     * @param addressList
     * @param isResetSelected
     */
    const handleSetPrimaryKey = (
      id: number | string,
      addressList: DTO_Address[],
      isResetSelected: boolean = true
    ) => {
      if (!id || !addressList.length) return;
      const newAddress = addressList.map((item: DTO_Address) => ({
        ...item,
        IsAddressPrimary: id === item.RowId,
      }));

      onChange(nameOf("Addresses"), {
        value: newAddress ?? [],
      });
      if (isResetSelected) {
        onChange(nameOf("AddressSelected"), {
          value: [],
        });
      }
    };

    const renderNamesColumn = (cols: IColumnFields[]) => {
      // Do nothing while in readOnly mode
      if (options?.isReadOnly) return cols;
      return cols.map((col: IColumnFields) => {
        // Add handle OnClick action for field PropertyAddress
        if (col.field === nameOfAddress("PropertyAddress")) {
          col.handleOnClick = (dataItem: DTO_Address) => {
            setUpdateDialogData(dataItem);
          };
        }
        return col;
      });
    };

    const handleDataChangeGrid = (dataRow: any, fieldChange: string) => {
      const addressId = dataRow?.RowId;
      let newAddress = [...address];
      // update value PropertyName
      newAddress = newAddress.map((item: DTO_Address) => {
        if (
          item.RowId === addressId &&
          fieldChange === nameOfAddress("PropertyName")
        ) {
          return {
            ...item,
            PropertyName: dataRow[nameOfAddress("PropertyName")] ?? null,
          };
        }
        return item;
      });
      onChange(nameOf("Addresses"), { value: newAddress });
    };

    return (
      <section className="cc-field-group">
        <label className="cc-label">
          Physical address
          <CCTooltip type="validator" position="right" />
          <CCTooltip
            type="custom"
            position="auto"
            content=" "
            customTemplate={
              <div>
                • Please add at least one address
                <br />• At least one address must be set as primary and property
                name is required
              </div>
            }
          >
            <i className="fa fa-info-circle ml-1 text-accent" />
          </CCTooltip>
          {errors?.[nameOf("")] ? <Error>{errors[nameOf("")]}</Error> : null}
        </label>
        <div className="cc-form-cols-1">
          <CCGrid
            toolbar={
              <div className="cc-grid-tools-bar">
                <Button
                  type="button"
                  disabled={
                    options?.isReadOnly ||
                    address.length <= 0 ||
                    (addressSelected?.length === 1 &&
                      addressSelected?.[0]?.IsAddressPrimary) ||
                    !addressSelected?.length
                  }
                  themeColor="primary"
                  onClick={() =>
                    handleSetPrimaryKey(addressSelected?.[0]?.RowId, address)
                  }
                >
                  Set as primary
                </Button>
                <Button
                  iconClass="fas fa-plus"
                  type="button"
                  onClick={() => {
                    setIsShowAddDialog(true);
                  }}
                  disabled={options?.isReadOnly}
                />
                <Button
                  type="button"
                  iconClass="fas fa-minus"
                  disabled={
                    options?.isReadOnly ||
                    address.length <= 0 ||
                    !addressSelected?.length
                  }
                  onClick={() => {
                    handleDeleteItem();
                  }}
                />
              </div>
            }
            className="cc-address-for-parcel-grid"
            columnFields={renderNamesColumn(colPhysicalAddress)}
            data={address ?? []}
            primaryField={nameOfAddress("RowId")}
            readOnly={options?.isReadOnly}
            selectableMode="single"
            selectedRows={addressSelected ?? []}
            onSelectionChange={(dataItem: DTO_Address[]) => {
              onChange(nameOf("AddressSelected"), {
                value: dataItem ?? [],
              });
            }}
            editableMode={!options?.isReadOnly ? "cell" : undefined}
            onDataRowChange={(dataRow: any, fieldChange: any) => {
              handleDataChangeGrid(dataRow, fieldChange);
            }}
          />
          {isShowAddDialog ? (
            <NewAddressDialog
              onClose={() => setIsShowAddDialog(false)}
              handleAddAddress={(data: DTO_Address) => {
                handleAddItem(data);
                setIsShowAddDialog(false);
              }}
              initialValue={initNewAddressDialog}
              assessmentLOVs={initEditAddAddressLOVs}
            />
          ) : null}
          {updateDialogData ? (
            <NewAddressDialog
              onClose={() => setUpdateDialogData(undefined)}
              handleUpdateAddress={(data: DTO_Address) => {
                handleUpdateItem(data);
                setUpdateDialogData(undefined);
              }}
              dataItem={updateDialogData}
              assessmentLOVs={initEditAddAddressLOVs}
            />
          ) : null}
        </div>
      </section>
    );
  }
);
