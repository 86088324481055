import { DTO_Title } from "@app/products/property/components/dialogs/add-title-lookup/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Title>();

//TODO: Add nameOf and mapping when Yasitha update DB
export const colPicFolios: IColumnFields[] = [
  {
    field: nameOf("ReferenceNumber"),
    title: "Reference Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: "Type",
    title: "Type",
  },
  {
    field: nameOf("Lot"),
    title: "Lot",
  },
  {
    field: nameOf("PlanType"),
    title: "Plan Type",
  },
  {
    field: nameOf("PlanNumber"),
    title: "Plan Number",
  },
  {
    field: nameOf("Volume"),
    title: "Volume",
  },
  {
    field: nameOf("Folio"),
    title: "Folio",
  },
  {
    field: nameOf("County"),
    title: "County",
  },
  {
    field: nameOf("Parish"),
    title: "Parish",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("IsAreaSurveyed"),
    title: "Is Area Surveyed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Coverage"),
    title: "Coverage",
  },
  {
    field: "OwnersName",
    title: "Name",
  },
  {
    field: "NameAddress",
    title: "Address",
  },
  {
    field: "OwnersLocality",
    title: "Locality",
  },
  {
    field: "Association",
    title: "Association",
  },
  {
    field: "Tenure",
    title: "Tenure",
  },
  {
    field: "CrownAllotment",
    title: "Crown Allotment",
  },
  {
    field: nameOf("Section"),
    title: "Section",
  },
  {
    field: "Block",
    title: "Block",
  },
  {
    field: "Portion",
    title: "Portion",
  },
  {
    field: "Subdivision",
    title: "Subdivision",
  },
  {
    field: "CrownStatus",
    title: "Crown Status",
  },
  {
    field: "FurtherDescription",
    title: "Further Description",
  },
  {
    field: "Page",
    title: "Page",
  },
  {
    field: "Township",
    title: "Township",
  },
  {
    field: nameOf("DivisionDate"),
    title: "Division Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Frontage"),
    title: "Frontage",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("LandArea"),
    title: "Land Area",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: "ExcludedAreaAsString",
    title: "Excluded Area",
  },
  {
    field: nameOf("GISReference"),
    title: "GIS Reference",
  },
  {
    field: "State",
    title: "State",
  },
  {
    field: "Country",
    title: "Country",
  },
  {
    field: "LGA",
    title: "LGA",
  },
  {
    field: "Assessment_Group",
    title: "Assessment Group",
  },
  {
    field: "Differential_Rate__Category",
    title: "Differential Rate Category",
  },
  {
    field: nameOf("AssociatedPICArea"),
    title: "Association Factor",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("TitleId"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
