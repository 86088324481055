import { useAnimalsSettingStore } from "@app/products/animals/system-admin/settings/_category/store";
import { DynamicGridFields } from "@app/products/animals/system-admin/settings/_id/components/dynamic-grid-fields/_index";
import { saveRestrictedBreed } from "@app/products/animals/system-admin/settings/_id/main/api";
import { ICommonFormProps } from "@app/products/animals/system-admin/settings/_id/main/model";
import { PickRestrictedBreedDialog } from "@app/products/animals/system-admin/settings/_id/main/settings/dog-settings/forms/restricted-breed/components/pick-restricted-breed-dialog/_index";
import {
  colRestrictedBreedAnimalsSettings,
  defaultRestrictedBreedAnimalSettingsStyleGrid,
} from "@app/products/animals/system-admin/settings/_id/main/settings/dog-settings/forms/restricted-breed/config";
import { ELoadingMode } from "@app/products/animals/system-admin/settings/_id/main/settings/dog-settings/forms/restricted-breed/model";
import { useAnimalsSettingManageStore } from "@app/products/animals/system-admin/settings/_id/store";
import { isSuccessResponse } from "@common/apis/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { Keyword } from "@common/models/keyword";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { NoData } from "@components/no-data/NoData";
import { Button } from "@progress/kendo-react-buttons";
import { uniqBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

const nameOf = nameOfFactory<Keyword>();

export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { isEditing } = useAnimalsSettingManageStore();
  const { getConfigDataField } = useAnimalsSettingStore();
  const { valueGetter, onChange } = formRenderProps;
  const { pushNotification } = useCCAppNotificationStore();

  const [listSelected, setListSelected] = useState<Keyword[]>([]);
  const [isShowDialogAdd, setIsShowDialogAdd] = useState<boolean>(false);
  const [isShowConfirmDeleteDialog, setIsShowConfirmDeleteDialog] =
    useState<boolean>(false);
  const [loadingMode, setLoadingMode] = useState<ELoadingMode | undefined>();
  const restrictedBreedData = getConfigDataField(
    ECorporateSettingsField.Animals_RestrictedBreeds
  );
  const restrictedBreedsDisplayValue =
    valueGetter(
      `option.ValueDisplayDetails_${ECorporateSettingsField.Animals_RestrictedBreeds}`
    ) ?? [];

  const handleDelete = async () => {
    if (listSelected.length > 0) {
      const dataClone = [...restrictedBreedsDisplayValue];
      const listSelectedIds = listSelected.map(
        (item: Keyword) => item.Keyword_ID
      );
      const dataAfterRemove = dataClone.filter(
        (item: Keyword) => !listSelectedIds.includes(item.Keyword_ID)
      );
      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.Animals_RestrictedBreeds}`,
        { value: dataAfterRemove }
      );
      handleSubmitData(true);
    }
    setListSelected([]);
    setIsShowConfirmDeleteDialog(false);
  };

  const handleAdd = (values: Keyword[]) => {
    if (values?.length > 0) {
      const dataClone = restrictedBreedsDisplayValue;
      const mergeList = [...dataClone, ...values];
      const uniqueMergeList = uniqBy(mergeList, nameOf("Keyword_ID"));

      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.Animals_RestrictedBreeds}`,
        { value: uniqueMergeList }
      );
      handleSubmitData(false);
    }
    setIsShowDialogAdd(false);
  };

  const handleSubmitData = async (isDelete: boolean) => {
    setLoadingMode(isDelete ? ELoadingMode.DELETE : ELoadingMode.ADD);
    const dataSubmit = valueGetter(
      `option.ValueDisplayDetails_${ECorporateSettingsField.Animals_RestrictedBreeds}`
    );
    const ids = dataSubmit.map((item: Keyword) => item.Keyword_ID);
    const response = await saveRestrictedBreed(ids, isDelete);

    if (isSuccessResponse(response)) {
      pushNotification({
        title: `Restricted breed was ${
          isDelete ? "removed" : "added"
        } successfully.`,
        type: "success",
      });
    } else {
      pushNotification({
        title: `Restricted breed was ${isDelete ? "removed" : "added"} failed.`,
        description: response.error ?? response.data?.Errors,
        autoClose: false,
        type: "error",
      });
    }
    setLoadingMode(undefined);
  };

  if (!restrictedBreedData) return <NoData vertical />;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title={restrictedBreedData?.Title ?? ""} />
          {!isEditing ? (
            <CCGrid
              columnFields={colRestrictedBreedAnimalsSettings}
              primaryField={nameOf("Keyword_ID")}
              data={restrictedBreedsDisplayValue}
              itemPerPage={50}
              isAutoHiddenPager={false}
              style={defaultRestrictedBreedAnimalSettingsStyleGrid}
            />
          ) : (
            <CCGrid
              columnFields={colRestrictedBreedAnimalsSettings}
              primaryField={nameOf("Keyword_ID")}
              data={restrictedBreedsDisplayValue}
              selectableMode={"multiple"}
              selectedRows={listSelected}
              style={defaultRestrictedBreedAnimalSettingsStyleGrid}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass={
                      loadingMode === ELoadingMode.ADD
                        ? "fas fa-spinner fa-spin"
                        : "fas fa-plus"
                    }
                    type="button"
                    title="Add dog breed"
                    onClick={() => setIsShowDialogAdd(true)}
                    disabled={!isEditing || loadingMode === ELoadingMode.ADD}
                  />
                  <Button
                    type="button"
                    iconClass={
                      loadingMode === ELoadingMode.DELETE
                        ? "fas fa-spinner fa-spin"
                        : "fas fa-minus"
                    }
                    title="Remove"
                    disabled={
                      !isEditing ||
                      !listSelected.length ||
                      loadingMode === ELoadingMode.DELETE
                    }
                    onClick={() => setIsShowConfirmDeleteDialog(true)}
                  />
                </div>
              }
              onSelectionChange={(dataItem: Keyword[]) => {
                let listSelected: Keyword[] = [];
                if (dataItem) {
                  listSelected = dataItem;
                }
                setListSelected(listSelected);
              }}
              itemPerPage={50}
              isAutoHiddenPager={false}
            />
          )}
        </div>
        {isShowDialogAdd && (
          <PickRestrictedBreedDialog
            onClose={() => setIsShowDialogAdd(false)}
            handleSubmit={handleAdd}
          />
        )}
        {isShowConfirmDeleteDialog && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={`Are you sure you want to delete this ${
              listSelected.length > 1 ? "items" : "item"
            }?`}
            onClosePopup={() => {
              setIsShowConfirmDeleteDialog(false);
            }}
            onConfirmYes={handleDelete}
          />
        )}
      </div>

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <DynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
