import {
  MAX_LENGTH_LEASE_ADVICE_NUMBER,
  MAX_LENGTH_REFERENCE,
} from "@app/products/property/assessments/components/form-steps/modify-assessment/components/form-elements/select-titles/util";
import { useModifyAssessmentDialogStore } from "@app/products/property/assessments/components/form-steps/modify-assessment/store";
import { checkNumberOnlyValidator } from "@app/products/property/assessments/components/form-steps/modify-assessment/util";
import { OfficerAndOfficerRegion } from "@app/products/property/components/fields/officer-and-officer-region/_index";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { DTO_LOV } from "@common/models/odataResponse";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const SelectTitlesFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const validationReqAndNumberOnly = (value: any) => {
  return requiredValidator(value) || checkNumberOnlyValidator(value);
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isToBeApprovalMode: false,
      isActro: false,
      workflowDraftId: 0,
    },
  }: IFormStepElement) => {
    const { valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { modifyAssessmentLOVs } = useModifyAssessmentDialogStore();
    const leaseTypeLOV = convertValueLOVToNumber<DTO_LOV>(
      modifyAssessmentLOVs?.ACT_Lease_Advice_Type ?? [],
      "Code"
    );

    return (
      <>
        <section className="cc-field-group">
          {options?.isToBeApprovalMode && (
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Workflow instructions" />
                <Field
                  name={nameOf("Instructions")}
                  rows={3}
                  component={CCTextArea}
                  placeholder="Workflow instructions"
                  readOnly
                />
              </div>
            </div>
          )}
          <div className="cc-field">
            <OfficerAndOfficerRegion
              formRenderProps={formRenderProps}
              nameOf={nameOf}
              isDisabled={options?.isReadOnly || options?.workflowDraftId}
            />
          </div>
        </section>

        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title="Applicant name" isMandatory />
              <Field
                name={nameOf("ApplicantName")}
                placeholder={"Applicant name"}
                component={CCInput}
                readOnly={options?.isReadOnly}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Reason for change" isMandatory />
              <Field
                name={nameOf("ReasonForCreation")}
                component={CCSearchComboBox}
                disabled={options?.isReadOnly}
                data={modifyAssessmentLOVs?.ReasonForCreation ?? []}
                textField="Name"
                dataItemKey="Code"
                isUseDefaultOnchange
                validator={!options?.isReadOnly ? requiredValidator : undefined}
              />
            </div>
            <div className="cc-field">
              <CCLabel
                title={options?.isActro ? "Lease advice number" : "Reference"}
                isMandatory
              />
              <Field
                name={nameOf(
                  options?.isActro ? "LeaseAdviceNumber" : "Reference"
                )}
                placeholder={
                  options?.isActro ? "Lease advice number" : "Reference"
                }
                component={CCInput}
                maxLength={
                  options?.isActro
                    ? MAX_LENGTH_LEASE_ADVICE_NUMBER
                    : MAX_LENGTH_REFERENCE
                }
                validator={
                  !options?.isReadOnly
                    ? options?.isActro
                      ? validationReqAndNumberOnly
                      : requiredValidator
                    : undefined
                }
                readOnly={options?.isReadOnly}
              />
            </div>
          </div>
          {options?.isActro && (
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <CCLabel title="Lease type" isMandatory />
                <Field
                  name={nameOf("LeaseType")}
                  component={CCSearchComboBox}
                  data={leaseTypeLOV ?? []}
                  textField={"Name"}
                  dataItemKey={"Code"}
                  isUseDefaultOnchange
                  disabled={options?.isReadOnly}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Last lease number"
                  value={getFieldValue("LastLeaseNumber")}
                  format={NUMBER_FORMAT.NUMBER2}
                />
              </div>
            </div>
          )}
        </section>
      </>
    );
  }
);
