import { getContactGeneral } from "@app/products/property/contacts-central-names/[id]/api";
import { DTO_Attributes } from "@app/products/property/contacts-central-names/[id]/components/child-screens/general/components/further-details/model";
import {
  DTO_Entity_Summary,
  DTO_LOVS_Summary,
} from "@app/products/property/contacts-central-names/[id]/model";
import {
  convertValueLOVToNumber,
  getContactAlertFromStringArray,
} from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" }); // Strict mode: runInAction

class ContactsCentralNamesStore {
  private _contact?: DTO_Entity_Summary = undefined;
  private _attribute?: DTO_Attributes = undefined;
  private _lovs?: DTO_LOVS_Summary = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  // TODO: will change _selectedAssessment and _selectedTitle to computed - mock data for temporary
  private _selectedAssessment?: any[] = undefined;
  private _selectedTitle?: any[] = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get contact() {
    return toJS(this._contact);
  }
  setContact = (contact?: DTO_Entity_Summary) => {
    runInAction(() => {
      this._contact = contact;
    });
  };

  get attribute() {
    return toJS(this._attribute);
  }
  setAttribute = (attribute?: DTO_Attributes) => {
    runInAction(() => {
      this._attribute = attribute;
    });
  };

  get lovs() {
    return toJS(this._lovs);
  }
  setLovs = (lovs?: DTO_LOVS_Summary) => {
    runInAction(() => {
      this._lovs = lovs;
    });
  };

  get selectedAssessment() {
    return toJS(this._selectedAssessment);
  }
  setSelectedSingleAssessment = (values: any) => {
    runInAction(() => {
      this._selectedAssessment = values;
    });
  };

  get selectedTitle() {
    return toJS(this._selectedTitle);
  }
  setSelectedTitle = (values: any) => {
    runInAction(() => {
      this._selectedTitle = values;
    });
  };

  get contactId() {
    return this._contact?.ContactId;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._contact = undefined;
      this._attribute = undefined;
      this._lovs = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._selectedAssessment = undefined;
      this._selectedTitle = undefined;
    });
  };

  loadContact = async (
    contactId: number,
    notification?: IAppNotificationItemAddProps
  ) => {
    this.setIsLoading(true);
    const response = await getContactGeneral(contactId);
    let errorResponse = undefined;
    let newContact: any;
    let newAttribute: any;
    let newLovs: any;

    if (
      Array.isArray(response) &&
      response?.[0]?.data &&
      response?.[1]?.data &&
      response?.[2]?.data
    ) {
      const summary = response?.[0]?.data;
      const attribute = response?.[1]?.data;
      const lovs = response?.[2]?.data;

      newContact = { ...summary, ContactId: contactId };
      newAttribute = { ...attribute?.Attributes };
      if (attribute?.Attributes?.AlsoKnownAs?.length) {
        newAttribute.AlsoKnownAs =
          attribute?.Attributes?.AlsoKnownAs.join("\n");
      } else {
        newAttribute.AlsoKnownAs = "";
      }
      newLovs = {
        Category:
          convertValueLOVToNumber(
            lovs?.dtoEntity_LOVs?.Category ?? [],
            "Code"
          ) ?? [],
        CommunityGroup: convertValueLOVToNumber(
          lovs?.dtoEntity_LOVs?.CommunityGroup ?? []
        ),
      };
      if (
        newContact?.Entity?.Contact_Alerts &&
        newContact?.Entity?.Contact_Alerts?.length > 0
      ) {
        const newNotifications = getContactAlertFromStringArray(
          newContact.Entity.Contact_Alerts
        );
        appNotificationStore.setNotifications(newNotifications);
      }
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Load contact general failed",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setContact(newContact);
    this.setAttribute(newAttribute);
    this.setLovs(newLovs);
    this.setIsLoading(false);
    if (notification) {
      appNotificationStore.pushNotification(notification);
    }
  };

  reloadPage = (
    contactId: number,
    notification?: IAppNotificationItemAddProps
  ) => {
    if (contactId) {
      this.loadContact(contactId, notification);
    }
  };
}

export const contactsCentralNamesStore = new ContactsCentralNamesStore();
const contactsCentralNamesStoreContext = createContext(
  new ContactsCentralNamesStore()
);
export const useContactsCentralNamesStore = () => {
  return useContext(contactsCentralNamesStoreContext);
};
