import { Contact_Relation } from "@app/core/inspections/[id]/model";
import { ElementControl } from "@app/products/crms/[id]/model";
import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { DateFromDateTo } from "@common/dialog/date-to-from/model";
import { Address } from "@common/input-pickers/address/model";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";

export interface AnimalsKennel extends DTOBaseClass_Standard {
  Kennel_ID: number;
  KennelNo: string;
  Address: Address;
  Address_ID: number;
  Owner?: Contact_Relation;
  Owner_RID: number | null;
  AdditonalHolder?: Contact_Relation;
  AdditonalHolder_RID: number | null;
  Status_ENUM: Kennel_Status;
  Status_Name: string;
  Description: string;
  Date_Lodged: Date | null;
  Date_EffectiveFrom: Date | null;
  Date_EffectiveTo: Date | null;
  Date_Deactivation: Date | null;
  DeactivateReason_KWD: number | null;
  DeactivateReason_Name: string;
  IsRenewalNoticeSent: boolean | null;
  IsRenewalFeeCreated: boolean | null;
  SaveTriggers: KennelUpdateTriggers[];
  _SaveTriggers?: KennelUpdateTriggers[];
  BubbleUp: BubbleUpIdentifier[];
  isReIssue?: boolean;
}

export interface Svc_AnimalKennel {
  Kennel: AnimalsKennel;
  OSFee?: number;
}

export interface AnimalsKennelReturnObject {
  Kennel: AnimalsKennel;
  OSFee: number;
  UIControl: KennelUIControl;
}

export enum Kennel_Status {
  Unspecified = 0,
  NewRegistration = 1,
  Active = 2,
  Inactive = 3,
}

export enum KennelUpdateTriggers {
  UpdateOwner,
  UpdateAddress,
  CreateNewRegistrationFee,
  CreateRenewalRegistrationFee,
  UpdateAdditionalHolder,
}

export interface KennelFormHandlerRequest {
  KennelFormAction: Svc_FormAction_Kennel;
  Kennel: AnimalsKennel;
  KennelArgs: any;
  IsFirstTimeLoad: boolean;
}

export interface KennelHandlerResponse {
  Kennel: AnimalsKennel;
  UIControl: KennelUIControl;
}

export enum Svc_FormAction_Kennel {
  SystemInitialise,
  Form_PickAddress,
  Form_PickContact,
  Form_PickAdditionalContact,
  Form_PickOSFee,
  Form_KennelSameAsPropertyOwner,
}

export interface KennelUIControl {
  LitContact: ElementControl;
  LitAddress: ElementControl;
  LitAddressDisplay: ElementControl;
  LitContactUnFormat: ElementControl;
  LitAddressUnFormat: ElementControl;
  LitOsFee: ElementControl;
  LitContactHomePhone: ElementControl;
  LitContactWorkPhone: ElementControl;
  LitContactMobilePhone: ElementControl;
  LitContactEmail: ElementControl;
  LitContactAddress: ElementControl;
  LitAdditionalContact: ElementControl;
  LitAdditionalContactHomePhone: ElementControl;
  LitAdditionalContactWorkPhone: ElementControl;
  LitAdditionalContactMobilePhone: ElementControl;
  LitAdditionalContactEmail: ElementControl;
  LitAdditionalContactAddress: ElementControl;
  TxtDebtorNo: ElementControl;
  TxtFileNo: ElementControl;
}

export interface Svc_Kennel {
  Kennel: AnimalsKennel;
  OSFee: number | null;
}

export interface InitGetKennelData {
  Address?: Address;
  LitContactUnFormat?: ElementControl;
  LitAddressUnFormat?: ElementControl;
}

export interface Svc_Kennel_Workflow_DateFromDateTo {
  Kennel: AnimalsKennel;
  DateFromDateTo: DateFromDateTo;
  isReIssue?: boolean;
}

export interface Svc_Kennel_Workflow_ProvideReason {
  Kennel: AnimalsKennel;
  ProvideReason: ProvideReason;
}
