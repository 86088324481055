import { isEmpty, isNil } from "lodash";

export const handleValidateEmail = (
  value: string,
  isTextArea: boolean | undefined,
  readOnly: boolean | undefined,
  disabled: boolean | undefined
) => {
  const emailRegex =
    /^([^@.!#$%&'"*+/=?^`{|}~](?:(?!\.@|\.{2,})[0-9a-zA-Z_\-.])*?)(@)([^@^-](?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{1,}$|(?:\[|)(?:\d{1,3}\.){3}\d{1,3}(?:\]|)$)/g;

  const emailList = value
    ?.replace(/^\n+|\n+$/gm, "")
    ?.replace(/\n+|;/g, ",")
    ?.split(",")
    ?.map((v) => v.trim());

  if (isTextArea && emailList?.length > 1) {
    if (readOnly || disabled) return null;
    let error = undefined;
    //#region Check duplicate ========/
    const duplicateItem = emailList.find((v, i, s) => s.indexOf(v) !== i);
    if (duplicateItem) {
      error = `$emailDuplicate_${duplicateItem}`;
    }
    //#endregion Check duplicate =====/
    emailList?.forEach((email) => {
      emailRegex.lastIndex = 0;
      if ((!emailRegex.test(email) && !isEmpty(email)) || isEmpty(email)) {
        error = `$emailInvalid_${email}`;
      }
    });
    return error;
  } else {
    if (readOnly || disabled) return null;
    if (!emailRegex.test(value) && !isEmpty(value))
      return `emailInvalid${value}`;
  }
};

const validateEmailFunction = (value: string, required?: boolean) => {
  // Return empty string if value is nil and not required
  if (isNil(value) && !required) {
    return "";
  }
  // Return required message if value is nil or empty and required
  if (required && (isEmpty(value) || isNil(value))) {
    return "Email field is required.";
  }
  // Check if the value includes "emailInvalid"
  if (value.includes("emailInvalid")) {
    return "Email is not valid format.";
  }
  // Check for duplicate email marker
  const duplicateRegex = /\$emailDuplicate_/g;
  if (duplicateRegex.test(value)) {
    const email = value.replace(duplicateRegex, "");
    return `Email is duplicate: ${email}`;
  }
  // Check for invalid email marker
  const invalidRegex = /\$emailInvalid_/g;
  if (invalidRegex.test(value)) {
    const email = value.replace(invalidRegex, "");
    return email
      ? `Email is not valid format: ${email}`
      : "Email is not valid format.";
  }
  // If none of the conditions are met, return an empty string
  return "";
};

export const validateEmail = (value: string) => validateEmailFunction(value);

export const validateEmailRequired = (value: string) =>
  validateEmailFunction(value, true);

export const handleFormatEmail = (
  value: string,
  isTextArea: boolean | undefined,
  readOnly: boolean | undefined,
  disabled: boolean | undefined
) => {
  let result = value;
  const hasError = handleValidateEmail(value, isTextArea, readOnly, disabled);

  if (hasError) result = hasError;
  return result;
};
