import { APIResponseError } from "@common/apis/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class AssociateAssessmentStore {
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };
}

const associateAssessmentStoreContext = createContext(
  new AssociateAssessmentStore()
);

export const useAssociateAssessmentStore = () => {
  return useContext(associateAssessmentStoreContext);
};
