export enum eChangeOfOwnershipType {
  Full_Transfer_of_Ownership = 1,
  Partial_Transfer_of_Ownership = 2,
  Change_of_Name_Address = 3,
  Change_of_Associated_Name_Address = 4,
  Change_of_Lease = 5,
  Electoral_Change = 6,
  Entity_Name_and_Address_Change = 7,
  Register_Account_Name_and_Address_Change = 8,
  Add_Mailbox = 9,
  Edit_Mailbox = 10,
  Remove_Mailbox = 11,
  Change_of_Street_Type = 12,
  Add_Tribe_Account = 13,
  PIC_Name_and_Address_Change = 14,
  Full_Transfer_of_Occupier = 15,
  Partial_Transfer_of_Occupier = 16,
}
