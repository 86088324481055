import { postAccordionsCount } from "@app/products/property/components/panel-bar/api";
import { PropertyPanelBarEventType } from "@app/products/property/components/panel-bar/util";
import { isSuccessResponse } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { nameOfFactory, sortByField } from "@common/utils/common";
import { customLogger } from "@common/utils/logger";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ICCPanelProps } from "@components/cc-panel/_index";
import {
  CCPanelsBar,
  ICCPanelsBarProps,
} from "@components/cc-panels-bar/_index";
import {
  DictionaryAccordionType,
  IListPanelBar,
  eAccordionType,
} from "@components/custom-panelbar/model";
import { isNil } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

export interface IPropertyPanelBar extends ICCPanelProps {
  accordionType?: eAccordionType;
}
export interface IPropertyPanelBarPops
  extends Omit<ICCPanelsBarProps, "initialPanels"> {
  initialPanels: IPropertyPanelBar[];
  componentNumber?: number;
  componentId?: number;
  sort?: boolean;
}

const nameOf = nameOfFactory<IListPanelBar>();
export const PropertyPanelBar = ({
  initialPanels,
  componentId,
  componentNumber,
  sort = true,
  onChangeExpandedState,
  ...others
}: IPropertyPanelBarPops) => {
  const { pushNotification } = useCCAppNotificationStore();
  const [totals, setTotals] = useState<DictionaryAccordionType>({});

  const listAccordionType = useMemo(() => {
    return initialPanels
      .map((panel: IPropertyPanelBar) => panel.accordionType)
      ?.filter((item: eAccordionType | undefined) => {
        return item !== undefined;
      }) as eAccordionType[];
  }, [initialPanels]);

  const getAccordionsQuantity = useCallback(
    async (
      componentNumber: number,
      componentId: number,
      listAccordionType: eAccordionType[]
    ) => {
      const response = await postAccordionsCount(
        componentNumber,
        componentId,
        listAccordionType
      );
      customLogger("Core Property accordion, postAccordionsCount").info(
        response?.data
      );
      if (isSuccessResponse(response) && response?.data) {
        setTotals(response?.data?.Accordion_Count);
      } else {
        pushNotification({
          type: "warning",
          title: "Get accordions count failed",
        });
        setTotals({});
      }
    },
    // eslint-disable-next-line
    []
  );

  const newListPanelBar = useMemo(() => {
    const newList = initialPanels?.map((panel: IPropertyPanelBar) => {
      if (panel.accordionType !== undefined) {
        const count = totals[eAccordionType[panel.accordionType]];
        return {
          ...panel,
          badge: count !== undefined ? count.toString() : undefined,
        } as ICCPanelProps;
      }
      return panel as ICCPanelProps;
    });
    //List panel bar sorted
    return sort ? sortByField(newList, nameOf("title")) : newList;
  }, [totals, initialPanels, sort]);

  const handleOnExpandedChange = useCallback(
    (expandedState: boolean[], currentIndex?: number) => {
      if (onChangeExpandedState)
        onChangeExpandedState(expandedState, currentIndex);
      if (isNil(componentNumber) || isNil(componentId)) return;
      if (
        !isNil(currentIndex) &&
        expandedState?.[currentIndex] &&
        !isNil(newListPanelBar?.[currentIndex]?.accordionType)
      ) {
        getAccordionsQuantity(componentNumber, componentId, listAccordionType);
      }
    },
    [
      componentNumber,
      componentId,
      listAccordionType,
      newListPanelBar,
      getAccordionsQuantity,
      onChangeExpandedState,
    ]
  );

  useAddUniqueEventEmitter([
    {
      eventType: PropertyPanelBarEventType.RefreshData,
      listener: () => {
        if (isNil(componentNumber) || isNil(componentId)) return;
        getAccordionsQuantity(componentNumber, componentId, listAccordionType);
      },
    },
  ]);

  useEffectOnce(() => {
    if (isNil(componentNumber) || isNil(componentId)) return;
    (async () =>
      getAccordionsQuantity(componentNumber, componentId, listAccordionType))();
  });

  return (
    <CCPanelsBar
      {...others}
      initialPanels={newListPanelBar}
      onChangeExpandedState={handleOnExpandedChange}
    />
  );
};
