import { ICCViewColumn } from "@app/products/property/model";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields, ICustomFormat } from "@components/cc-grid/model";
import { isNil } from "lodash";

export const formatDynamicValuationsCol = (
  item: ICCViewColumn,
  colConfig: IColumnFields
): ICustomFormat | any => {
  // DecimalPlaces is a decimal field in backend DTO (API will return 1.0, 2.0, 3.0, etc.)
  // need to truncate the decimal part from UX
  const decimalPlaces = Math.trunc(item?.DecimalPlaces ?? 0);
  if (isNil(item?.IsCurrency)) return colConfig.format;

  if (item?.IsCurrency) {
    return {
      defaultFormat: CURRENCY_FORMAT.CURRENCY1,
      customFormat: CURRENCY_FORMAT.CURRENCY1 + decimalPlaces,
    };
  }
  return {
    defaultFormat: NUMBER_FORMAT.NUMBER,
    customFormat: NUMBER_FORMAT.NUMBER + decimalPlaces,
  };
};
