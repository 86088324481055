import { DTO_ConcessionCard } from "@app/products/property/assessments/[id]/components/child-screens/rebates/model";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";
import ConcessionCardsDetails from "./components/detail/_index";
import { colConcessionCards } from "./config";
export interface IRebatesConcessionCards {
  dataRebatesConcessionCards: DTO_ConcessionCard[];
}
const RebatesConcessionCards = ({
  dataRebatesConcessionCards,
}: IRebatesConcessionCards) => {
  return (
    <div className="cc-field">
      <label className="cc-label">Concession cards</label>
      <CCGrid
        className="cc-rebates-concession-grid"
        data={dataRebatesConcessionCards}
        columnFields={colConcessionCards}
        primaryField="Id"
        detail={ConcessionCardsDetails}
      />
    </div>
  );
};

export default RebatesConcessionCards;
