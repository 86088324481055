import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { useHistory } from "react-router-dom";
import "./_index.scss";
import { isArray, map } from "lodash";

interface IFormNavigationProps {
  title?: string;
  id?: string;
  badge?: number | string | (string | undefined)[];
}

// Common Title template
export const FormNavigation = ({ title, id, badge }: IFormNavigationProps) => {
  const history = useHistory();

  const renderBadge = () => {
    if (!badge) return "";
    if (isArray(badge) && badge.length) {
      return (
        <div className="cc-list-badge">
          {map(
            badge,
            (item: string | undefined) =>
              item && (
                <span key={item} className="cc-standard-badge">
                  {item}
                </span>
              )
          )}
        </div>
      );
    }
    return <span className="cc-standard-badge">{badge}</span>;
  };

  return (
    <div className="cc-common-form-navigation">
      <Button
        className="cc-rounded-back-button"
        onClick={() => {
          history.goBack();
        }}
        iconClass="fal fa-arrow-left"
      />

      <h5 className="cc-form-title" id={id ?? ""}>
        {title?.toLocaleUpperCase()}
      </h5>
      {renderBadge()}
    </div>
  );
};
