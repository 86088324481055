import { eventEmitter } from "@/App";
import { COMMENT_ACCORDION_GRID_ID } from "@app/core/comments/constant";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import {
  getInitialDataModifyCompliance,
  postProcessModifyCompliance,
  postRejectModifyCompliance,
} from "@app/products/property/assessments/compliance/[id]/components/forms/existed/components/form-steps/modify-compliance/api";
import { ComplianceFormStep } from "@app/products/property/assessments/compliance/[id]/components/forms/existed/components/form-steps/modify-compliance/components/form-elements/compliance/_index";
import { DetailsFormStep } from "@app/products/property/assessments/compliance/[id]/components/forms/existed/components/form-steps/modify-compliance/components/form-elements/details/_index";
import {
  DTO_ModifyCompliance_LOVs_Response,
  DTO_Workflow_ModifyCompliance,
  EKeysOfSteps,
  modifyComplianceKeysOfSendSteps,
} from "@app/products/property/assessments/compliance/[id]/components/forms/existed/components/form-steps/modify-compliance/model";
import { useModifyComplianceStore } from "@app/products/property/assessments/compliance/[id]/components/forms/existed/components/form-steps/modify-compliance/store";
import { useComplianceStore } from "@app/products/property/assessments/compliance/[id]/store";
import { OfficerReallocateDialog } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/officer-reallocate/_index";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { PROPERTY_ACTIONS_APPROVED_LIST_ROUTE } from "@app/products/property/components/action-bar/property-workflow/constant";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { PropertyDocumentEventType } from "@app/products/property/components/child-screen/documents/constant";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { VO_Officer } from "@common/models/officer";
import { useCommonProductStore } from "@common/stores/products/store";
import { getOfficerSearch } from "@common/stores/responsible-officer/api";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IModifyComplianceDialogProps {
  onClose: () => void;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  suffixTitle?: string;
  isReadOnly?: boolean;
  isToBeApprovalMode?: boolean;
  isBeingApprovedMode?: boolean;
  isShowCancelWorkflowButton?: boolean;
  handleReallocate?: (data: any) => void;
  isIncompleteMode?: boolean;
  isSaveOnNextStep?: boolean;
  isFromActionList?: boolean;
  statusBadge?: IBadgeDialog[];
  complianceId: number;
}

export const ModifyComplianceDialog = observer(
  ({
    onClose,
    dataFromActionList,
    prefixTitle,
    suffixTitle,
    isReadOnly = false,
    isToBeApprovalMode = false,
    isBeingApprovedMode = false,
    isFromActionList = false,
    isShowCancelWorkflowButton = false,
    handleReallocate,
    isIncompleteMode = false,
    isSaveOnNextStep = false,
    statusBadge,
    complianceId,
  }: IModifyComplianceDialogProps) => {
    //#region HOOKS -------------------------------------------->
    //Use history
    const history = useHistory();

    //Use ref
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    //Use store
    const { currentFormTitle } = useCommonProductStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setModifyComplianceLOVs, setComplianceId } =
      useModifyComplianceStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { reLoadCompliance } = useComplianceStore();

    //Use state
    const [isShowOfficerReallocate, setIsShowOfficerReallocate] =
      useState<boolean>(false);
    const [modifyComplianceInitData, setModifyComplianceInitData] =
      useState<DTO_Workflow_ModifyCompliance>();
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);

    //Use memo
    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "Modify Compliance";
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, workflowHeader]);

    const initialValues = useMemo(() => {
      let initCompliance = {};
      let initDetails = {};

      if (modifyComplianceInitData) {
        const workflowDetail = modifyComplianceInitData?.WorkflowDetail;
        //Compliance
        if (workflowDetail?.SelectGeneral) {
          initCompliance = {
            ...initCompliance,
            ...workflowDetail?.SelectGeneral,
          };
        }

        //Details
        if (workflowDetail?.ComplianceDetail) {
          initDetails = {
            ...initDetails,
            ...workflowDetail?.ComplianceDetail,
          };
        }
      }
      return {
        [EKeysOfSteps.Compliance]: initCompliance,
        [EKeysOfSteps.Details]: initDetails,
        [EKeysOfSteps.Comments]: {},
        [EKeysOfSteps.Documents]: {},
      };
    }, [modifyComplianceInitData]);

    //Use effect
    /**
     * get officer
     * @param officerId
     * @returns
     */
    const getOfficer = async (officerId: number | null) => {
      if (!officerId) return undefined;
      const filterParam: CompositeFilterDescriptor = {
        logic: "and",
        filters: [
          {
            logic: "and",
            filters: [
              {
                field: "Id",
                operator: "eq",
                value: officerId,
              },
            ],
          },
        ],
      };

      const response = await getOfficerSearch(filterParam);
      if (isSuccessResponse(response) && response?.data?.value) {
        return response.data.value[0];
      }
      return undefined;
    };

    /**
     * get workflow data
     */
    const getWorkflowData = async () => {
      const id = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      const response = await getInitialDataModifyCompliance(
        WorkflowTypes.Modify_Compliance,
        complianceId,
        id
      );
      if (Array.isArray(response)) {
        const [modifyComplianceLOVsResponse, workflowDataResponse] = response;
        const modifyComplianceLOVsData = modifyComplianceLOVsResponse?.data;
        const workflowData = workflowDataResponse?.data;
        if (
          isSuccessResponse(response[0]) &&
          isSuccessResponse(response[1]) &&
          modifyComplianceLOVsData &&
          workflowData
        ) {
          //Get officer
          const officer: VO_Officer | undefined = await getOfficer(
            workflowData.WorkflowDetail?.ComplianceDetail?.Officer_Id
          );
          //Set modify compliance LOVs data
          setModifyComplianceLOVs(
            modifyComplianceLOVsData.dtoModifyComplianceLOVs
          );
          //Set Modify Compliance initial data
          if (officer) {
            workflowData.WorkflowDetail.ComplianceDetail.ResponsibleOfficer =
              officer;
          }
          setModifyComplianceInitData({
            WorkflowDetail: workflowData.WorkflowDetail,
            WorkflowHeader: workflowData.WorkflowHeader,
          });
          //Set Modify Compliance ID
          setComplianceId(workflowData.WorkflowDetail?.ComplianceId);
          //Set workflow header and workflow draft ID
          if (workflowData?.WorkflowHeader) {
            setWorkflowHeader(workflowData.WorkflowHeader);
            setWorkflowDraftId(
              workflowData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id ??
                0
            );
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
        } else {
          let responseError: APIResponse<
            | DTO_ModifyCompliance_LOVs_Response
            | DTO_Workflow_ModifyCompliance
            | ResponsePacket
          > = response[0];
          if (!isSuccessResponse(response[1])) {
            responseError = response[1];
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        notificationFormStepRef?.current?.setLoadingFormStep(false);
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error: "Load workflow failed",
          },
        });
      }
    };

    useEffectOnce(() => {
      getWorkflowData();
    });
    //#endregion <--------------------------------------------

    //#region COMMON FUNCTIONS -------------------------------------------->
    /**
     * Handle submit to control all buttons in dialog
     * @param events
     * @param buttonId
     */
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Approve:
          await handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          await sendSaveCompliance(processData(data), true);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          handleConfirmRetainProcess(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
        default:
          break;
      }
    };

    /**
     * Process data before sending to API
     * @param data
     */
    const processData = (data: any) => {
      let workflowDetail: any = {};
      const sendSteps = pickBy(data, function (value, key) {
        if (modifyComplianceKeysOfSendSteps.includes(key as EKeysOfSteps)) {
          return { [key]: value };
        }
      });
      workflowDetail.ComplianceId =
        modifyComplianceInitData?.WorkflowDetail?.ComplianceId;

      if (sendSteps) {
        for (const [key, value] of Object.entries(sendSteps)) {
          workflowDetail[key as keyof DTO_Workflow_ModifyCompliance] = {
            ...value,
          };
        }
      }
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    /**
     * common function
     * handle calling API with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_ModifyCompliance>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;
      const response = await postProcessModifyCompliance(modeProcess, payload);

      setLoading();
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };
    //#endregion <--------------------------------------------

    //#region SAVE and NEXT -------------------------------------------->
    /**
     * Handle Next button
     * @param data
     */
    const handleNextButton = async (data: any) => {
      setIsLoadingOnNext(true);
      return await sendSaveCompliance(processData(data));
    };

    /**
     * Send save compliance (call API with 'Save' mode)
     * @param payload
     * @param isCloseDialog
     */
    const sendSaveCompliance = async (
      payload: DTO_Workflow_ModifyCompliance,
      isCloseDialog: boolean = false
    ) => {
      isCloseDialog && setIsLoadingSave(true);
      const response = await postProcessModifyCompliance(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingOnNext(false);
      const defaultSuccessMessage = "Compliance was saved successfully";
      const defaultFailedMessage =
        "The modify compliance process could not be saved";
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          if (isCloseDialog) {
            setIsLoadingSave(false);
            onClose();
            pushNotification({
              title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
              type: "success",
            });
          }
          if (isFirstSave) {
            setIsFirstSave(false);
            setWorkflowDraftId(response?.data?.ID ?? 0);
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID ?? 0,
              },
            });
          }
          return true;
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
          return false;
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        return false;
      }
    };
    //#endregion <--------------------------------------------

    //#region PARK -------------------------------------------->
    /**
     * Handle park process
     * @param payload
     */
    const handleParkProcess = async (
      payload: DTO_Workflow_ModifyCompliance
    ) => {
      //set loading button and dialog
      setIsLoadingPark(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_ModifyCompliance> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title: e?.Notification ?? "Compliance parked successfully",
            type: "success",
          });
        },
        defaultFailedMessage: "Park compliance failed",
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingPark(false);
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };
    //#endregion <--------------------------------------------

    //#region CANCEL -------------------------------------------->
    /**
     * Handle Cancel button
     * @param data
     */
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessModifyCompliance,
          dataCancel: data,
          defaultSuccessMessage: "Compliance was cancelled successfully",
          defaultErrorMessage: "Cancel compliance failed",
        });
      } else {
        onClose();
      }
    };
    //#endregion <--------------------------------------------

    //#region RETAIN -------------------------------------------->
    /**
     * Handle close dialog
     * @param renderProps
     */
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name &&
        dataFromActionList.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    /**
     * handle confirm retain workflow process
     * @param payload
     */
    const handleConfirmRetainProcess = async (
      payload: DTO_Workflow_ModifyCompliance
    ) => {
      //set loading button and dialog
      setIsLoadingClose(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_ModifyCompliance> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title: e?.Notification ?? "Compliance was parked successfully",
            type: "success",
          });
        },
        defaultFailedMessage: "Park compliance failed",
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog(undefined);
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };
    //#endregion <--------------------------------------------

    //#region FINISH -------------------------------------------->
    /**
     * handle finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_ModifyCompliance
    ) => {
      setDataForFinishDialog({
        finishCallback: async () =>
          await handleFinishProcess(processData(payload)),
        confirmMessage:
          "The compliance will be modified based on the information provided. Are you sure you want to submit?",
      });
    };

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_ModifyCompliance
    ) => {
      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_ModifyCompliance> = {
        payload: payload,
        actionSuccess: (e) => {
          eventEmitter.emit(CCGridEventType.RefreshOData);

          if (!isFromActionList) {
            //Reload compliance summary
            reLoadCompliance();
            //Refresh document and comment accordion after updated successfully
            eventEmitter.emit(PropertyDocumentEventType.RefreshData);
            eventEmitter.emit(CCGridEventType.RefreshOData, {
              gridIds: [COMMENT_ACCORDION_GRID_ID],
            });
          }

          onClose();
          pushNotification({
            title: e?.Notification ?? "Compliance was modified successfully",
            type: "success",
          });
        },
        defaultFailedMessage: "Modify compliance failed",
        modeProcess: WorkflowProcessMode.Finish,
      };

      const setLoading = () => {};
      //calling API process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };
    //#endregion <--------------------------------------------

    //#region APPROVE -------------------------------------------->
    /**
     * handle approve process
     * * @param payload
     */
    const handleApproveProcess = async (
      payload: DTO_Workflow_ModifyCompliance
    ) => {
      //set loading button
      setIsLoadingApprove(true);

      //props send to process workflow
      const approveProps: IProcessWorkflow<DTO_Workflow_ModifyCompliance> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          history.push(PROPERTY_ACTIONS_APPROVED_LIST_ROUTE);
          pushNotification({
            title: e?.Notification ?? "Compliance was approved successfully",
            type: "success",
          });
        },
        defaultFailedMessage: "Approve compliance failed",
        modeProcess: WorkflowProcessMode.Approve,
      };

      const setLoading = () => {
        setIsLoadingApprove(false);
      };
      //calling api process workflow
      await handleProcessWorkflow(approveProps, setLoading);
    };
    //#endregion <--------------------------------------------

    //#region REJECT -------------------------------------------->
    /**
     * Handle reject process
     * @param data
     */
    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postRejectModifyCompliance,
          dataReject: data,
          defaultSuccessMessage: "Modify compliance was rejected successfully.",
          defaultErrorMessage: "Modify compliance could not be rejected.",
        });
      } else {
        onClose();
      }
    };
    //#endregion <--------------------------------------------

    //#region SEND BACK -------------------------------------------->
    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_ModifyCompliance
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessModifyCompliance,
        dataSendBack: payload,
        defaultSuccessMessage: "Compliance approval sent back successfully",
        defaultErrorMessage: "Send compliance back failed",
      });
    };
    //#endregion <--------------------------------------------
    const steps: IStep[] = [
      {
        label: "Compliance",
        initialValues: initialValues.SelectGeneral,
        component: ComplianceFormStep,
        visible: true,
        key: EKeysOfSteps.Compliance,
        options: {
          isReadOnly,
          workflowDraftId,
        },
      },
      {
        label: "Details",
        initialValues: initialValues.ComplianceDetail,
        component: DetailsFormStep,
        visible: true,
        key: EKeysOfSteps.Details,
        options: {
          isReadOnly,
          workflowDraftId,
        },
      },
      {
        label: "Comments",
        initialValues: initialValues.Comments,
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfSteps.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Compliance,
        },
      },
      {
        label: "Documents",
        initialValues: initialValues.Documents,
        component: DocumentsFormStep,
        visible: true,
        key: EKeysOfSteps.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          componentId: complianceId,
          workflowType: WorkflowTypes.Modify_Compliance,
        },
      },
    ];

    return (
      <>
        <CCFormStep
          ref={notificationFormStepRef}
          onSubmit={handleSubmit}
          listButtonId={listSubmitButton}
          initialValues={initialValues}
          initialSteps={steps}
          saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
          renderForm={(renderProps: ICCFormStepRender) => {
            return (
              <CCDialog
                maxWidth="65%"
                disabled={isLoadingApprove}
                titleHeader={titleHeader}
                onClose={() => handleCloseDialog(renderProps)}
                bodyElement={renderProps.children}
                badge={statusBadge}
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}
                    {!isReadOnly &&
                      isToBeApprovalMode &&
                      !isBeingApprovedMode && (
                        <Button
                          iconClass={
                            isLoadingSave ? "fas fa-spinner fa-spin" : ""
                          }
                          disabled={
                            renderProps.nextButton.disabled || isLoadingSave
                          }
                          className={"cc-dialog-button"}
                          id={EListSubmitButton.Save}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Save
                        </Button>
                      )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          isLoadingApprove ||
                          isLoadingOnNext ||
                          isLoadingPark ||
                          isLoadingSave
                        }
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}
                    {isToBeApprovalMode && !isBeingApprovedMode && (
                      <>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.SendBack}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Send Back
                        </Button>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Reallocate}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={() => setIsShowOfficerReallocate(true)}
                        >
                          Reallocate
                        </Button>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Approve}
                          disabled={
                            isLoadingApprove || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                          iconClass={
                            isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                          }
                        >
                          Approve
                        </Button>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Reject}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Reject
                        </Button>
                      </>
                    )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                }
              />
            );
          }}
        />
        {isShowOfficerReallocate && (
          <OfficerReallocateDialog
            onClose={() => {
              setIsShowOfficerReallocate(false);
            }}
            onSubmit={(data) => {
              if (handleReallocate) {
                handleReallocate(data);
              }
            }}
          />
        )}
      </>
    );
  }
);
