import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { getViewConfigurations } from "@app/products/property/api";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { PROPERTY_SUPPLEMENTARY_RATES_ROUTE } from "@app/products/property/supplementary-rates/[id]/constant";
import { NewSupplementaryButton } from "@app/products/property/supplementary-rates/components/action-bar/buttons/new-supplementary/_index";
import { ImportValuationsButton } from "@app/products/property/supplementary-rates/import-valuations/components/action-bar/buttons/import-valuations/_index";
import { supplementaryRatesRoute } from "@app/products/property/supplementary-rates/route";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDisplayName,
} from "@app/products/property/supplementary-rates/util";
import DetailComponent from "@app/products/property/supplementary-rates/valuations-by-group/components/detail/_index";
import { colValuationsByGroup } from "@app/products/property/supplementary-rates/valuations-by-group/config";
import { ODATA_SUPPLEMENTARYS_RATE_VALUATION_BY_GROUP } from "@app/products/property/supplementary-rates/valuations-by-group/constant";
import { VO_Valuations_By_Group } from "@app/products/property/supplementary-rates/valuations-by-group/model";
import { processDynamicColumns } from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { NoData } from "@components/no-data/NoData";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { getBookmarkListViewDetail } from "./util";

const nameOf = nameOfFactory<VO_Valuations_By_Group>();

export default () => {
  const [listConfiguration, setListConfiguration] = useState<ICCViewColumn[]>(
    []
  );

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={supplementaryRatesRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <NewSupplementaryButton />
        <ImportValuationsButton />
      </CCNavButton>,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Supplementary}
        recordType={RECORDTYPE.CommunityProperty_Supplementary}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_SUPPLEMENTARY_RATES_ROUTE}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        //TODO - Wrong mock right sidebar detail tab, set empty temporary
        component: <NoData />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Supplementary}
          />
        ),
      },
    ],
  });

  useEffectOnce(() => {
    getViewConfigurations(ViewConfiguration.OData_SupplementaryValuations).then(
      (response) => {
        if (isSuccessResponse(response) && response?.data) {
          setListConfiguration(response.data.ColumnDefinitions?.Columns);
        }
      }
    );
  });

  return (
    <CCProductListView
      dataUrl={ODATA_SUPPLEMENTARYS_RATE_VALUATION_BY_GROUP}
      state={{ group: [{ field: nameOf("RatingPeriod_Name"), dir: "asc" }] }}
      columnFields={processDynamicColumns(
        colValuationsByGroup,
        listConfiguration
      )}
      primaryField={nameOf("Valuation_Group_Id")}
      detail={DetailComponent}
    />
  );
};
