import { SAP } from "@app/products/property/contacts-central-names/list/components/dialogs/update-contact/model";
import { DTO_Entity_Associated_Entity } from "@app/products/property/contacts-central-names/list/model";
import {
  DTO_WorkflowDetail_Base,
  DTO_WorkflowHeader,
  DTO_Workflow_Base,
} from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfNewContactSteps {
  Details = "Details",
  Person = "EntityDetails",
  PostalAddress = "PostalAddress",
  PhysicalAddress = "PhysicalAddress",
  Attributes = "Attributes",
  Associations = "Associated_Entities",
  ConcessionCards = "Concession_Cards",
  Voting = "VotingDetails",
  Comments = "Comments",
  Documents = "Documents",
  SecondaryWorkflow = "SecondaryWorkflow",
}

export const keysOfSendNewContactSteps = [
  EKeysOfNewContactSteps.Details,
  EKeysOfNewContactSteps.Person,
  EKeysOfNewContactSteps.PostalAddress,
  EKeysOfNewContactSteps.PhysicalAddress,
  EKeysOfNewContactSteps.Attributes,
  EKeysOfNewContactSteps.Associations,
  EKeysOfNewContactSteps.ConcessionCards,
  //TODO: Glenorchy (General) - Temporarily hidden, implement in the future
  // EKeysOfNewContactSteps.Voting,
];

export interface DTO_Entity_LOVs {
  dtoEntity_LOVs: DTO_Create_Entity_LOVs;
}

export interface DTO_Create_Entity_LOVs {
  Country: DTO_LOV[];
  PostalDeliveryType: DTO_LOV[];
  State: DTO_LOV[];
  Locality: DTO_LOV[];
  StreetType: DTO_LOV[];
  StreetName: DTO_LOV[];
  Gender: DTO_LOV[];
  FloorType: DTO_LOV[];
  PropertyName: DTO_LOV[];
  JobDescription: DTO_LOV[];
  Category: DTO_LOV[];
  Distinction: DTO_LOV[];
  PersonTitle: DTO_LOV[];
  Type: DTO_LOV[];
  BuildingUnit: DTO_LOV[];
  ConcessionCards: DTO_ConcessionCard[];
  Reason: DTO_LOV[];
  Cultural_Identity: DTO_LOV[];
  Roles: DTO_LOV[];
  PreferredMethod: DTO_LOV[];
  Aboriginal: DTO_LOV[];
  HasCommunityGroup: DTO_LOV[];
  CommunityGroup: DTO_LOV[];
}

export interface DTO_ConcessionCard {
  Id: number;
  Name: string;
  Card_Number: string;
  Card_Number_InputMask: string;
}
export interface IRadioGroupData {
  label: string;
  value: any;
}

export enum MODE_CONTACT {
  ORGANISATION = "Organisation",
  PERSON = "Person",
  UNKNOWN = "Unknown",
}

export interface RequestObjFromSpatial {
  Entity_Id: number | null;
}

export interface DTO_Workflow_CreateContact {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_CreateEntity;
}

export interface DTO_Workflow_CreateEntity extends DTO_Workflow_Base {
  WorkflowDetail: DTO_WorkflowDetail_CreateEntity;
}

export interface DTO_WorkflowDetail_CreateEntity
  extends DTO_WorkflowDetail_Base {
  Details: DTO_Detail;
  EntityDetails: DTO_Entity_Detail;
  PostalAddress: DTO_Entity_PostalAddress;
  PhysicalAddress: DTO_Entity_PhysicalAddress;
  Attributes: DTO_Entity_Attributes;
  Concession_Cards: DTO_Entity_Concession_Card[];
  Associated_Entities: DTO_Entity_Associated_Entity[];
}

export interface DTO_Detail {
  ApplicantName: string;
  ReasonId: number;
}

export interface DTO_Entity_Detail {
  Id: number;
  Change_Of_Ownership_Id?: number;
  Type: number;
  Title: string;
  GivenName: string;
  Surname: string;
  Distinction: string;
  Category_Id?: number;
  Organisation_Name: string;
  ACN: string;
  ABN: string;
  AttentionOf: string;
  CareOf: string;
  Job_Description: string;
  Organisation: string;
}

export interface DTO_Entity_PostalAddress {
  Delivery: string;
  AddressFormatted: string;
  Property_Name: string;
  Unit_Type: string;
  Unit_Number: string;
  Floor_Type: string;
  Floor_Number: string;
  House_Number: string;
  Street_Name: string;
  Street_Type: string;
  Additional_Address: string;
  Locality: string;
  State: string;
  Post_Code: string;
  Country: string;
  Physical_Address: string;
  Postal_Delivery_Type: string;
  Postal_Delivery_Number: string;
  Address: string;
}

export interface DTO_Entity_PhysicalAddress extends DTO_Entity_PostalAddress {
  Same_As_Postal: boolean;
}

export interface DTO_Entity_Attributes {
  DateOfBirth?: Date;
  DateOfDeath?: Date;
  Gender?: string;
  Occupation: string;
  PhoneNumber_Private: string;
  PhoneNumber_Business: string;
  PhoneNumber_Mobile: string;
  PhoneNumber_Fax: string;
  ElectoralCommission_PersonNumber?: number;
  ElectoralCommission_UpdateDate?: Date;
  Is_Silent_Enrolment: boolean;
  ElectoralCommission_VoterRegistrationDate?: Date;
  AlsoKnownAsNames: string[];
  Email_Address: string;
  Notes: string;
  Applicant_Number?: number;
  Applicant_Reference: string;
  SAP_Numbers: SAP[];
  Cultural_Identity_Ids: number[];
  Preferred_Name: string;
  Is_Obselete: boolean;
  Preferred_Method?: number | null;
  Aboriginal?: number;
  HasCommunityGroup?: number;
  Community_Group_Ids: number[];
}

export interface DTO_Entity_Concession_Card {
  Id: number;
  Card_Number: string;
  Is_Active: boolean;
  Presented_Date: Date | null;
  Confirmation_Date: Date | null;
  Name?: string;
  Card_Number_InputMask?: string;
}

export interface DTO_Entity_Voting {
  Nominated_Properties: DTO_Entity_NominatedProperty[];
}

export interface DTO_Entity_NominatedProperty {
  Assessment_Id: number;
  Assessment_Number: string;
  Property_Address: string;
  Role: string;
  Effective_Date: Date;
  Finish_Date: Date;
  Nominated_Id: number;
}
