import { Registration_Status } from "@app/products/animals/[id]/model";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const AnimalReactivateButton = observer(() => {
  const { animalInfo, setIsShowWorkflowReactivate } = useAnimalStore();

  const isVisible = useMemo(() => {
    return (
      animalInfo?.Status_ENUM === Registration_Status.Deceased ||
      animalInfo?.Status_ENUM === Registration_Status.Inactive
    );
  }, [animalInfo]);

  return isVisible ? (
    <CCNavButton
      title="Reactivate"
      onClick={() => setIsShowWorkflowReactivate(true)}
    />
  ) : null;
});
