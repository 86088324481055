import { UITownPlanning } from "@app/products/town-planning/model";
import { IRejectApplicationDialogData } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reject-application/model";
import { RejectApplicationDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reject-application/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putPSARRejectReportResponseLetter } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/report-and-response-not-approved/api";
import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const PSARReportAndResponseNotApprovedButton = observer(() => {
  const { ppr, onSubmit, pprId, setPPRWithLoading } = usePSARStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.ResponseReportLetterNotApproved.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const isVisible = useMemo(() => {
    return ppr?.Status_ENUM === Application_Status.TeamLeaderDecisionPending;
  }, [ppr]);

  const handleOnSubmit = async (comment?: string) => {
    // TODO: Have API issue
    if (!pprId) return;
    setIsLoading(true);
    const response = await putPSARRejectReportResponseLetter(pprId, comment);

    if (isSuccessIdentityPacket(response) && response.data?.Application) {
      setIsShowDialog(false);
      setPPRWithLoading(response.data.Application);
      appNotificationStore.clearNotifications();
      appNotificationStore.pushNotification({
        type: "success",
        title: "Application rejected.",
      });
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: "The Reject Application workflow could not be completed.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        },
      ]);
    }

    setIsLoading(false);
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Reject application"
        onClick={onSubmit}
        name={PSARSubmitActions.ReportAndResponseNotApproved}
      />

      {isShowDialog && (
        <RejectApplicationDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={(value: IRejectApplicationDialogData) => {
            handleOnSubmit(value?.Comment);
          }}
          isSubmitLoading={isLoading}
          notifications={notifications}
        />
      )}
    </>
  ) : null;
});
