import {
  EWorkflow_Approval_Status_Name,
  VO_Workflow_Draft,
} from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { useAssessmentTransferStore } from "@app/products/property/assessments/[id]/components/child-screens/transfer/store";
import {
  getChangeOfOwnershipLovs,
  getInitialDataChangeOfOwnership,
  getWorkflowChangeOfOwnership,
  postProcessChangeOfOwnership,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/api";
import { DetailsFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/detail/_index";
import { LeaseFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/lease/_index";
import { fieldName } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/config";
import { NamesFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/_index";
import { PICFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/pic/_index";
import { PropertyFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/property/_index";
import { RebatesFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/rebates/_index";
import { eChangeOfOwnershipType } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/type/model";
import { TypeFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/type/_index";
import {
  DTO_ChangeOfOwnership_LOVs,
  DTO_Entity_Details,
  DTO_RebateEntitlementOwners,
  DTO_Rebates,
  DTO_Title,
  DTO_Workflow_ChangeOfOwnership,
  EKeysOfSteps,
  keysOfSendSteps,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { useChangeOfOwnershipDialogStore } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/store";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import {
  getSuffixTitle,
  secondaryWorkflowUtilProcessing,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { eComponent } from "@app/products/property/components/associations/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { VotingFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/voting/_index";
import { useContactTransferStore } from "@app/products/property/contacts-central-names/[id]/components/child-screens/transfer/store";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import {
  convertValueLOVToNumber,
  isShowParkButton,
} from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { Label } from "@common/stores/products/config";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { getDropdownValue } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { IBadgeDialog } from "@components/cc-dialog/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { IStep } from "@components/cc-form-step/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { Button } from "@progress/kendo-react-buttons";
import { head, isEqual, pickBy, unionBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
interface IChangeOfOwnershipDialogProps {
  assessmentId: number;
  contactId: number;
  titleId: number;
  picId: number;
  dataFromActionList?: VO_Workflow_Draft;
  isReadOnly?: boolean;
  isShowCancelWorkflowButton?: boolean;
  statusBadge?: IBadgeDialog[];
  isSaveOnNextStep?: boolean;
  isIncompleteMode?: boolean;
  isFromActionList?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  componentNumber: eComponent;
  onReject?: (data: any) => void;
  onClose: () => void;
  onSubmit: (data: any) => void;
}

export const ChangeOfOwnershipDialog = observer(
  (props: IChangeOfOwnershipDialogProps) => {
    const {
      assessmentId,
      contactId,
      titleId,
      picId,
      prefixTitle,
      suffixTitle,
      onClose,
      dataFromActionList,
      isReadOnly = false,
      statusBadge,
      isSaveOnNextStep = false,
      isFromActionList = false,
      isShowCancelWorkflowButton = false,
      isIncompleteMode = false,
      componentNumber,
    } = props;

    //#region HOOKS -------------------------------------------->
    const { currentOrganisationMode, currentFormTitle } =
      useCommonProductStore();
    const isActro = currentOrganisationMode(OrganisationMode.ACTRO);
    const isLLS = currentOrganisationMode(OrganisationMode.LLS);
    const { reLoadTransfer } = useAssessmentTransferStore();
    const { reLoadContactTransfer } = useContactTransferStore();
    const { changeOfOwnershipLOVs, setChangeOfOwnershipLOVs } =
      useChangeOfOwnershipDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
    const { pushNotification } = useCCAppNotificationStore();

    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);
    const [changeOfOwnershipInitData, setChangeOfOwnershipInitData] =
      useState<DTO_Workflow_ChangeOfOwnership>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });

    const defaultTitle = useMemo(() => {
      const formId =
        changeOfOwnershipInitData?.WorkflowHeader.WorkflowDraft.WD_Form_Id;
      return currentFormTitle(formId ?? 0) || "Change of Ownership";
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeOfOwnershipInitData]);

    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [selectedTypeId, setSelectedTypeId] = useState<number>();
    const [titleHeader, setTitleHeader] = useState<string>(defaultTitle);
    const isToBeApprovalMode = isEqual(
      dataFromActionList?.Workflow_Approval_Status_Name,
      EWorkflow_Approval_Status_Name.To_Be_Approved
    );
    //#endregion <--------------------------------------------

    //Get labels
    const [assessmentLowercaseLabel, titleLowercaseLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.AssessmentLowercase,
        ECustomColNameProperty.TitleLowercase,
      ]);

    //#region INITIALVALUE -------------------------------------------->
    const initialValues = useMemo(() => {
      let initType: any = { _option: { Type: undefined } };
      let initDetails: any = {};
      let initPICDetail: any = {};
      let initProperty: any = { _option: { AssessmentSelected: undefined } };
      let initNames: {
        Contacts: DTO_Entity_Details[];
        Name: string;
      } = {
        Contacts: [],
        Name: "",
      };
      const emptyRatePayer: {
        Contacts: DTO_Entity_Details[];
        Name: string;
      } = {
        Contacts: [],
        Name: "",
      };
      let initRatePayer = { ...emptyRatePayer };
      let initDuplicateRatePayer: {
        Contacts: DTO_Entity_Details[];
        Name: string;
      } = {
        Contacts: [],
        Name: "",
      };

      let initRebates: {
        Rebates: DTO_Rebates[];
        Owners: DTO_RebateEntitlementOwners[];
      } = {
        Rebates: [],
        Owners: [],
      };
      let initDocuments: {
        Document_Type_Id: number[];
      } = {
        Document_Type_Id: [],
      };
      let initAssociatedNamesDetails: DTO_Entity_Details[] = [];
      //---step Secondary Workflow---
      let initialWorkflow: any = {};
      let initNamesGridData: DTO_Entity_Details[] = [];

      if (changeOfOwnershipInitData) {
        const workflowDetail = changeOfOwnershipInitData?.WorkflowDetail;
        const typeSelectedRow =
          changeOfOwnershipLOVs?.ChangeOfOwnershipTypes?.find(
            (typeItem) =>
              typeItem?.Change_of_Ownership_Type ===
              workflowDetail?.COODetails?.Type
          );
        initPICDetail = { ...workflowDetail?.PICDetails };
        initType = {
          ...initType,
          ...workflowDetail?.COODetails,
          _option: {
            Type: typeSelectedRow,
          },
        };

        initAssociatedNamesDetails = [
          ...initAssociatedNamesDetails,
          ...(workflowDetail?.AssociatedNamesDetails ?? []),
        ];

        initRatePayer = {
          ...initRatePayer,
          ...workflowDetail?.RatePayerDetails,
        };

        initDetails = {
          ...initDetails,
          ...workflowDetail?.COODetails,
          ComponentInvoked:
            workflowDetail?.COODetails?.ComponentInvoked || componentNumber,
          ComponentInvoked_Id:
            workflowDetail?.COODetails?.ComponentInvoked_Id ||
            assessmentId ||
            titleId ||
            contactId ||
            picId ||
            0,
          _option: {
            [fieldName.InitAssociatedNamesDetails]: initAssociatedNamesDetails,
            [fieldName.InitRatePayers]: initRatePayer.Contacts,
          },
        };

        initNames = {
          ...initNames,
          ...workflowDetail?.OwnerDetails,
        };
        //Add primary field from UX
        const titleData =
          workflowDetail?.PropertyDetails?.Titles?.map(
            (item: DTO_Title, index: number) => {
              return {
                ...item,
                Id: `exist_${index + 1}`,
              };
            }
          ) ?? [];
        initProperty = {
          ...initProperty,
          ...workflowDetail?.PropertyDetails,
          Titles: titleData,
        };
        initDuplicateRatePayer = {
          ...initDuplicateRatePayer,
          ...workflowDetail?.DuplicateRatePayerDetails,
        };

        initRebates = {
          ...initRebates,
          ...workflowDetail?.RebateEntitlements,
        };

        initDocuments = {
          ...initDocuments,
          ...workflowDetail?.Documents,
        };
        initNamesGridData = [
          ...initNames.Contacts,
          ...(initDetails?.RetainRatePayerDetails
            ? initRatePayer.Contacts
            : []),
          ...initDuplicateRatePayer.Contacts,
          ...(initDetails?.RetainAssociatedNames
            ? initAssociatedNamesDetails
            : []),
        ];
        initNamesGridData = unionBy(initNamesGridData, fieldName.PrimaryKey);

        initialWorkflow = secondaryWorkflowUtilProcessing.processDataInit(
          changeOfOwnershipInitData
        );

        notificationFormStepRef?.current?.setStepsVisible([
          {
            visible:
              initType?._option?.Type?.COOT_Display_Rebates &&
              initRebates?.Owners?.length !== 0,
            key: EKeysOfSteps.Rebates,
            isClearData: false,
          },
          {
            visible:
              initType?._option?.Type?.Change_of_Ownership_Type !==
              eChangeOfOwnershipType.PIC_Name_and_Address_Change,
            key: EKeysOfSteps.Property,
            isClearData: true,
          },
          {
            visible:
              initType?._option?.Type?.Change_of_Ownership_Type ===
              eChangeOfOwnershipType.PIC_Name_and_Address_Change,
            key: EKeysOfSteps.PICDetails,
            isClearData: true,
          },
        ]);
      }

      return {
        [EKeysOfSteps.Type]: initType,
        [EKeysOfSteps.Details]: initDetails,
        [EKeysOfSteps.PICDetails]: initPICDetail,
        [EKeysOfSteps.Property]: initProperty,
        [EKeysOfSteps.Names]: initNames,
        [EKeysOfSteps.RatePayer]: initDetails?.RetainRatePayerDetails
          ? initRatePayer
          : emptyRatePayer,
        [EKeysOfSteps.DuplicateRatePayer]: initDuplicateRatePayer,
        [EKeysOfSteps.Rebates]: initRebates,
        [EKeysOfSteps.Voting]: [],
        [EKeysOfSteps.Comments]: {},
        [EKeysOfSteps.Documents]: initDocuments,
        [EKeysOfSteps.SecondaryWorkflow]: initialWorkflow,
        [EKeysOfSteps.AssociatedNamesDetails]:
          initDetails?.RetainAssociatedNames ||
          initDetails?.Type ===
            eChangeOfOwnershipType.Change_of_Associated_Name_Address
            ? initAssociatedNamesDetails
            : [],
        [EKeysOfSteps.NamesGridData]: initNamesGridData,
        Lease: {}, //TODO: Implement in the future outside ACTRO/LLS => fix for next button
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeOfOwnershipInitData, changeOfOwnershipLOVs, assessmentId]);

    //#endregion <--------------------------------------------
    const steps: IStep[] = [
      {
        label: "Type",
        initialValues: initialValues[EKeysOfSteps.Type],
        component: TypeFormStep,
        visible: true,
        key: EKeysOfSteps.Type,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Details",
        initialValues: initialValues[EKeysOfSteps.Details],
        component: DetailsFormStep,
        visible: true,
        key: EKeysOfSteps.Details,
        options: {
          isReadOnly,
          isLLS,
        },
      },
      {
        label: isLLS ? "Related" : "Property",
        initialValues: initialValues[EKeysOfSteps.Property],
        component: PropertyFormStep,
        visible: true,
        key: EKeysOfSteps.Property,
        options: {
          isReadOnly,
          assessmentLabel: assessmentLowercaseLabel,
          titleLabel: titleLowercaseLabel,
        },
      },
      {
        label: isLLS ? "Related" : "Property",
        initialValues: initialValues[EKeysOfSteps.PICDetails],
        component: PICFormStep,
        visible: false,
        key: EKeysOfSteps.PICDetails,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Names",
        initialValues: initialValues[EKeysOfSteps.NamesGridData],
        component: NamesFormStep,
        visible: true,
        key: EKeysOfSteps.NamesGridData,
        options: {
          isReadOnly,
          assessmentId,
          contactId,
        },
      },
      //This step don't show in UX, but handle data in Names step
      //Need to define this step to reset data when change type
      {
        label: "Rate Payer",
        initialValues: initialValues[EKeysOfSteps.RatePayer],
        component: NamesFormStep,
        visible: false,
        key: EKeysOfSteps.RatePayer,
        options: {
          isReadOnly,
        },
      },
      //This step don't show in UX, but handle data in Names step
      //Need to define this step to reset data when change type
      {
        label: "Duplicate Rate Payer",
        initialValues: initialValues[EKeysOfSteps.DuplicateRatePayer],
        component: NamesFormStep,
        visible: false,
        key: EKeysOfSteps.DuplicateRatePayer,
        options: {
          isReadOnly,
        },
      },
      //This step don't show in UX, but handle data in Names step
      //Need to define this step to reset data when change type
      {
        label: "Associated Names",
        initialValues: initialValues[EKeysOfSteps.AssociatedNamesDetails],
        component: NamesFormStep,
        visible: false,
        key: EKeysOfSteps.AssociatedNamesDetails,
        options: {
          isReadOnly,
        },
      },
      //This step don't show in UX, but handle data in Names step
      //Need to define this step to reset data when change type
      {
        label: "Owner Names",
        initialValues: initialValues[EKeysOfSteps.Names],
        component: NamesFormStep,
        visible: false,
        key: EKeysOfSteps.Names,
        options: {
          isReadOnly,
          assessmentId,
          contactId,
        },
      },
      //TODO: Implement in the future outside ACTRO
      {
        label: "Lease",
        initialValues: {},
        component: LeaseFormStep,
        visible: !isActro && !isLLS,
        key: "Lease",
        options: {
          isReadOnly,
        },
      },
      {
        label: "Rebates",
        initialValues: initialValues[EKeysOfSteps.Rebates],
        component: RebatesFormStep,
        visible: false,
        key: EKeysOfSteps.Rebates,
        options: {
          isReadOnly,
        },
      },
      //TODO: Implement in the future outside ACTRO
      {
        label: "Voting",
        initialValues: initialValues[EKeysOfSteps.Voting],
        component: VotingFormStep,
        visible: !isActro && !isLLS,
        key: EKeysOfSteps.Voting,
      },
      {
        label: "Comments",
        initialValues: initialValues[EKeysOfSteps.Comments],
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfSteps.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Change_of_Ownership,
        },
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValues[EKeysOfSteps.Documents],
        visible: true,
        key: EKeysOfSteps.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Change_Of_Ownership,
          subType: selectedTypeId,
          isFromActionList,
        },
      },
      {
        label: "Workflow",
        initialValues: initialValues[EKeysOfSteps.SecondaryWorkflow],
        component: WorkflowStepFormStep,
        visible: true,
        key: EKeysOfSteps.SecondaryWorkflow,
        options: {
          isReadOnly,
          isFromActionList,
          dataFromActionList,
        },
      },
    ];

    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Approve:
          await handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          await handleSaveAndNext(processData(data), true);
          break;
        case EListSubmitButton.SaveWorkflow:
          await handleSaveAndNext(processData(data), false, true);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleConfirmRetainProcess(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
      }
    };

    const fullTitleHeader = useMemo(() => {
      return getTitleWorkflow(
        titleHeader,
        prefixTitle,
        getSuffixTitle(
          suffixTitle,
          isToBeApprovalMode,
          workflowHeader?.WorkflowApprovals
        )
      );
    }, [
      titleHeader,
      prefixTitle,
      suffixTitle,
      isToBeApprovalMode,
      workflowHeader,
    ]);

    const handleNextButton = async (data: any) => {
      setIsLoadingOnNext(true);
      return await handleSaveAndNext(processData(data));
    };

    /**
     * Handle save
     * @param payload
     * @param isCloseDialog
     * @returns Promise<boolean>
     */
    const handleSaveAndNext = async (
      payload: DTO_Workflow_ChangeOfOwnership,
      isCloseDialog: boolean = false,
      isRefreshWorkflowData: boolean = false
    ): Promise<boolean> => {
      //Calling process Save at next button
      const response = await postProcessChangeOfOwnership(
        WorkflowProcessMode.Save,
        payload
      );

      setIsLoadingOnNext(false);

      //set default notification
      const defaultSuccessMessage =
        "Change of ownership was saved successfully.";
      const defaultFailedMessage = "Change of ownership could not be saved.";

      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (isCloseDialog) {
          onClose();
          pushNotification({
            title:
              response?.data?.Notification ??
              response?.data?.SuccessMessage ??
              defaultSuccessMessage,
            type: "success",
          });
        }
        // check is the first saving
        if (isFirstSave) {
          setIsFirstSave(false);
          //set current workflowDraft Id
          setWorkflowDraftId(response?.data?.ID || 0);
          // set payload to send
          setWorkflowHeader({
            ...workflowHeader,
            WorkflowDraft: {
              ...workflowHeader.WorkflowDraft,
              Workflow_Draft_Id: response?.data?.ID || workflowDraftId || 0,
            },
          });
        }
        // TODO: Show notification after reloading the step -> enhance later
        if (isRefreshWorkflowData) {
          getWorkflowData().then(() => {
            notificationFormStepRef?.current
              ?.getNotificationFormStep()
              ?.current?.pushNotification({
                title: response?.data?.Notification ?? defaultSuccessMessage,
                type: "success",
              });
          });
        }
        return true;
      } else {
        const showNotification = () => {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title:
                (isRefreshWorkflowData
                  ? head(response?.data?.Errors)
                  : response.data?.ErrorMessage) ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        };
        if (isRefreshWorkflowData) {
          getWorkflowData().then(showNotification);
        } else {
          showNotification();
        }
        return false;
      }
    };

    /**
     * Handle cancel process
     */
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessChangeOfOwnership,
          dataCancel: data,
          defaultSuccessMessage:
            "Change of ownership was cancelled successfully.",
          defaultErrorMessage: "Change of ownership could not be cancelled.",
        });
      } else {
        onClose();
      }
    };

    /**
     * Handle reject process
     */
    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessChangeOfOwnership,
          dataReject: data,
          defaultSuccessMessage:
            "Change of ownership was rejected successfully.",
          defaultErrorMessage: "Change of ownership could not be rejected.",
        });
      } else {
        onClose();
      }
    };

    /**
     * handle confirm retain workflow process
     * @param payload
     */
    const handleConfirmRetainProcess = async (
      payload: DTO_Workflow_ChangeOfOwnership
    ) => {
      //set loading button and dialog
      setIsLoadingClose(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_ChangeOfOwnership> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ?? "Change of ownership was parked successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Change of ownership could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog(undefined);
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };

    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_ChangeOfOwnership
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessChangeOfOwnership,
        dataSendBack: payload,
        defaultSuccessMessage:
          "Change of ownership was sent back successfully.",
        defaultErrorMessage: "Change of ownership could not be sent back.",
      });
    };

    /**
     * handle confirm reallocate workflow process
     * @param payload
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_ChangeOfOwnership
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postProcessChangeOfOwnership,
        dataReallocate: payload,
        defaultSuccessMessage: "Change of ownership approved successfully.",
        defaultErrorMessage: "Approve change of ownership failed.",
      });
    };

    /**
     * handle park process
     * @param payload
     */
    const handleParkProcess = async (
      payload: DTO_Workflow_ChangeOfOwnership
    ) => {
      //set loading button and dialog
      setIsLoadingPark(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_ChangeOfOwnership> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ?? "Change of ownership was parked successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Change of ownership could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => setIsLoadingPark(false);

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };

    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_ChangeOfOwnership>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;
      const response = await postProcessChangeOfOwnership(modeProcess, payload);

      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    /**
     * handle approve process
     */
    const handleApproveProcess = async (
      payload: DTO_Workflow_ChangeOfOwnership
    ) => {
      //set loading button
      setIsLoadingApprove(true);

      //props send to process workflow
      const approveProps: IProcessWorkflow<DTO_Workflow_ChangeOfOwnership> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              "Change of ownership was approved successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Change of ownership could not be approved.",
        modeProcess: WorkflowProcessMode.Approve,
      };

      const setLoading = () => {
        setIsLoadingApprove(false);
      };
      //calling api process workflow
      await handleProcessWorkflow(approveProps, setLoading);
    };

    /**
     * handle close workflow
     */
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        //Store submit event
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    const getWorkflowData = async () => {
      const id = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      //Get data when open workflow in workflow list (click item in workflow list)
      //Call new api first and use ComponentInvoked to call lovs api
      if (isFromActionList && id) {
        getWorkflowChangeOfOwnership(id).then((workflowDataResponse) => {
          if (
            isSuccessResponse(workflowDataResponse) &&
            workflowDataResponse?.data
          ) {
            const workflowData = workflowDataResponse.data;
            //set Modify ChangeOfOwnership InitData
            setChangeOfOwnershipInitData({
              WorkflowDetail: workflowData?.WorkflowDetail,
              WorkflowHeader: workflowData?.WorkflowHeader,
            });
            //set workflow header and workflow Draft Id
            if (workflowData?.WorkflowHeader) {
              setWorkflowHeader(workflowData.WorkflowHeader);
              setWorkflowDraftId(
                workflowData?.WorkflowHeader?.WorkflowDraft
                  ?.Workflow_Draft_Id ?? 0
              );
            }
            const componentNumberWillBeCalled =
              workflowData?.WorkflowDetail?.COODetails?.ComponentInvoked ?? 0;
            getChangeOfOwnershipLovs(componentNumberWillBeCalled).then(
              (lovsRespone) => {
                if (isSuccessResponse(lovsRespone) && lovsRespone?.data) {
                  const lovsChangeOfOwnershipData = lovsRespone.data;
                  //set Lovs Data ChangeOfOwnership
                  setChangeOfOwnershipLOVs({
                    ...lovsChangeOfOwnershipData,
                    NoticeGroups:
                      convertValueLOVToNumber(
                        lovsChangeOfOwnershipData?.NoticeGroups ?? [],
                        "Code"
                      ) ?? [],
                  });
                  notificationFormStepRef?.current?.setLoadingFormStep(false);
                } else {
                  notificationFormStepRef?.current?.setLoadingFormStep(false);
                  notificationFormStepRef?.current?.setLoadFailedFormStep({
                    onReload: () => getWorkflowData(),
                    responseError: {
                      status: workflowDataResponse.status,
                      error: workflowDataResponse.error,
                    },
                  });
                }
              }
            );
          } else {
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: workflowDataResponse.status,
                error: workflowDataResponse.error,
              },
            });
          }
        });
      }

      //Get data when open workflow outside workflow list (click button)
      //Call new api and lovs api at the same time
      else {
        return await getInitialDataChangeOfOwnership(
          assessmentId,
          contactId,
          titleId,
          picId,
          componentNumber
        ).then((response) => {
          if (Array.isArray(response)) {
            const [lovsChangeOfOwnershipResponse, workflowDataResponse] =
              response;
            const lovsChangeOfOwnershipData =
              lovsChangeOfOwnershipResponse?.data;
            const workflowData = workflowDataResponse?.data;
            if (
              isSuccessResponse(lovsChangeOfOwnershipResponse) &&
              isSuccessResponse(workflowDataResponse) &&
              lovsChangeOfOwnershipData &&
              workflowData
            ) {
              //set Lovs Data ChangeOfOwnership
              setChangeOfOwnershipLOVs({
                ...lovsChangeOfOwnershipData,
                NoticeGroups:
                  convertValueLOVToNumber(
                    lovsChangeOfOwnershipData?.NoticeGroups ?? [],
                    "Code"
                  ) ?? [],
              });
              //set Modify ChangeOfOwnership InitData
              setChangeOfOwnershipInitData({
                WorkflowDetail: workflowData?.WorkflowDetail,
                WorkflowHeader: workflowData?.WorkflowHeader,
              });
              //set workflow header and workflow Draft Id
              if (workflowData?.WorkflowHeader) {
                setWorkflowHeader(workflowData.WorkflowHeader);
                setWorkflowDraftId(
                  workflowData?.WorkflowHeader?.WorkflowDraft
                    ?.Workflow_Draft_Id ?? 0
                );
              }
              notificationFormStepRef?.current?.setLoadingFormStep(false);
            } else {
              let responseError: APIResponse<
                | DTO_ChangeOfOwnership_LOVs
                | DTO_Workflow_ChangeOfOwnership
                | ResponsePacket
              > = lovsChangeOfOwnershipResponse;
              if (!isSuccessResponse(workflowDataResponse)) {
                responseError = workflowDataResponse;
              }
              notificationFormStepRef?.current?.setLoadingFormStep(false);
              notificationFormStepRef?.current?.setLoadFailedFormStep({
                onReload: () => getWorkflowData(),
                responseError: {
                  status: responseError.status,
                  error:
                    (responseError.data as ResponsePacket)?.Errors ??
                    "Load workflow failed",
                },
              });
            }
          } else {
            const responseError = response as APIResponse;
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error: "Load workflow failed",
              },
            });
          }
        });
      }
    };

    /**
     * handle finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_ChangeOfOwnership
    ) => {
      const dataProcessed = processData(payload, WorkflowProcessMode.Finish);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage:
          "The change of ownership will be created based on the information provided. Are you sure you want to submit?",
      });
    };

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_ChangeOfOwnership
    ) => {
      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_ChangeOfOwnership> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              "Change of ownership was created successfully.",
            type: "success",
          });
          if (assessmentId) {
            reLoadTransfer(assessmentId);
          } else if (contactId) {
            reLoadContactTransfer(contactId);
          }
        },
        defaultFailedMessage: "Change of ownership could not be created.",
        modeProcess: WorkflowProcessMode.Finish,
      };

      const setLoading = () => {};
      //calling api process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };

    const processData: DTO_Workflow_ChangeOfOwnership | any = (data: any) => {
      let workflowDetail: any = {};
      data[EKeysOfSteps.Details].Type = data[EKeysOfSteps.Type].Type;
      const sendSteps = pickBy(data, function (value, key) {
        if (keysOfSendSteps.includes(key as EKeysOfSteps)) {
          return { [key]: value };
        }
      });

      for (const [key, value] of Object.entries(sendSteps)) {
        let dataStep = Array.isArray(value) ? [...value] : { ...value };
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }
        //Clear Id - field created by UX
        if (key === EKeysOfSteps.Property) {
          const titleDataCleaned =
            dataStep?.Titles?.map((titleItem: DTO_Title) => {
              const newTitleItem = { ...titleItem };
              delete newTitleItem.Id;
              return newTitleItem;
            }) ?? [];
          dataStep = {
            ...dataStep,
            Titles: titleDataCleaned,
          };
        }
        if (key === EKeysOfSteps.SecondaryWorkflow) {
          secondaryWorkflowUtilProcessing.processData(
            value,
            workflowHeader,
            workflowDetail
          );
        }
        workflowDetail[key as keyof DTO_Workflow_ChangeOfOwnership] = dataStep;
      }
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        listButtonId={listSubmitButton}
        onSubmit={handleSubmit}
        onValueChange={(values) => {
          if (values[EKeysOfSteps.Type]?.Type !== selectedTypeId) {
            setTitleHeader(
              getDropdownValue(
                values[EKeysOfSteps.Type]?.Type,
                changeOfOwnershipLOVs?.ChangeOfOwnershipTypes ?? [],
                "Change_of_Ownership_Type"
              )?.COOT_Name || defaultTitle
            );
            setSelectedTypeId(values[EKeysOfSteps.Type]?.Type);
          }
        }}
        initialValues={initialValues}
        initialSteps={steps}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            titleHeader={fullTitleHeader}
            badge={statusBadge}
            onClose={() => handleCloseDialog(renderProps)}
            bodyElement={renderProps.children}
            footerElement={
              <>
                <div className={"cc-dialog-footer-actions-right"}>
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={isLoadingPark ? "fas fa-spinner fa-spin" : ""}
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled || isLoadingPark
                      }
                    >
                      Park
                    </Button>
                  )}
                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      disabled={
                        isLoadingApprove || isLoadingOnNext || isLoadingPark
                      }
                      id={EListSubmitButton.Cancel}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}

                  {isToBeApprovalMode && workflowHeader?.OfficerCanApprove && (
                    <>
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.SendBack}
                        disabled={renderProps.nextButton.disabled}
                        className={"cc-dialog-button"}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Send Back
                      </Button>
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.Reallocate}
                        disabled={renderProps.nextButton.disabled}
                        className={"cc-dialog-button"}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Reallocate
                      </Button>
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.Approve}
                        disabled={
                          isLoadingApprove || renderProps.nextButton.disabled
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.submitButton.onClick}
                        iconClass={
                          isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                        }
                      >
                        Approve
                      </Button>
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.Reject}
                        disabled={renderProps.nextButton.disabled}
                        className={"cc-dialog-button"}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Reject
                      </Button>
                    </>
                  )}
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}
                  {isToBeApprovalMode || isReadOnly ? (
                    !renderProps.isLastStep && (
                      <Button
                        themeColor="primary"
                        id="cc-next-step-button"
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        className={"cc-dialog-button"}
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )
                  ) : (
                    <Button
                      themeColor="primary"
                      id={renderProps.nextButton.idButton}
                      disabled={
                        isLoadingOnNext || renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingOnNext
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
              </>
            }
          />
        )}
      />
    );
  }
);
