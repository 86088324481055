import { ExistedPropertyAssessment } from "@app/products/property/assessments/[id]/components/forms/existed/_index";
import { NewPropertyAssessment } from "@app/products/property/assessments/[id]/components/forms/new/_index";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManagePropertyAssessment = observer(() => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { resetMenu } = useCCSubActionBarStore();
  const { loadAssessment, resetStore } = useAssessmentStore();
  const location = useLocation();
  const state: any = location?.state;

  useEffect(() => {
    if (isNew) return;
    let notification = undefined;
    if (state) {
      notification = state?.notification;
      window.history.replaceState({}, "");
    }
    loadAssessment(+id, notification);
    // eslint-disable-next-line
  }, [id, isNew, loadAssessment]);

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
    };
  });

  if (isNew) {
    return <NewPropertyAssessment />;
  }
  return <ExistedPropertyAssessment />;
});

export default ManagePropertyAssessment;
