import { getViewConfigurations } from "@app/products/property/api";
import { DTO_Certificate_AdverseAffections } from "@app/products/property/certificates/[id]/components/child-screens/adverse-affections/model";
import {
  ICCViewConfiguration,
  ViewConfiguration,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type IGetCertificateAdverseAffection =
  | [
      APIResponse<ICCViewConfiguration | undefined>,
      APIResponse<DTO_Certificate_AdverseAffections>
    ]
  | APIResponse<ICCViewConfiguration | DTO_Certificate_AdverseAffections>
  | undefined;

export const getCertificateAdverseAffection = async (
  certificateId: number
): Promise<IGetCertificateAdverseAffection> => {
  try {
    return await Promise.all([
      getViewConfigurations(ViewConfiguration.Certificate_AdverseAffection),
      CoreAPIService.getClient().get<DTO_Certificate_AdverseAffections>(
        `/api/property/int/certificate/${certificateId}/adverseaffections`
      ),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
