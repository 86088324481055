import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { postProcessModifyAreaOfResponsibility } from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/api";
import { StepDetailFormStep } from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/components/element-steps/_step-details/_index";
import { EConfigNameFieldsModifyAreaOfResponsibility } from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/components/element-steps/_step-details/config";
import {
  DTO_Certificate_UDAL,
  DTO_Workflow_AreaOfResponsibilityModify,
} from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/model";
import { useModifyAreaOfResponsibilityStore } from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/store";
import { PROPERTY_CERTIFICATE_ROUTE } from "@app/products/property/certificates/[id]/constant";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { INewProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import {
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { isNil } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ModifyAreaOfResponsibilityKeysOfSteps } from "./config";

export interface IModifyAreaOfResponsibilityDialog {
  onClose: () => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
  isToBeApprovalMode?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  isReadOnly?: boolean;
  isShowCancelWorkflowButton?: boolean;
  statusBadge?: IBadgeDialog[];
  isRedirectManagePage?: boolean;
  aorId?: number;
  actionAfterFinish?: (
    notificationContent?: IAppNotificationItemAddProps
  ) => void;
}
export const ModifyAreaOfResponsibilityDialog = ({
  onClose,
  isSaveOnNextStep = false,
  isIncompleteMode = false,
  isFromActionList = false,
  prefixTitle,
  suffixTitle,
  dataFromActionList,
  isReadOnly = false,
  isShowCancelWorkflowButton = false,
  statusBadge,
  isRedirectManagePage = true,
  actionAfterFinish,
  isToBeApprovalMode = false,
}: IModifyAreaOfResponsibilityDialog) => {
  //#region <Store>
  const { pushNotification } = useCCAppNotificationStore();
  const {
    dialogInfo,
    workflowInitData,
    setWorkflowDraftId,
    workflowDraftId,
    setWorkflowHeader,
    workflowHeader,
    resetStore,
  } = useModifyAreaOfResponsibilityStore();
  //set data for modes
  const { setDataForCancelDialog } = useConfirmCancelDialogStore();
  const { setDataForFinishDialog } = useConfirmFinishDialogStore();
  const { setDataForCloseDialog, setIsLoadingClose } =
    useConfirmCloseDialogStore();
  //#endregion

  //#region <Local state>
  const formStepRef = useRef<ICCFormStepNotificationHandle | null>(null);
  const history = useHistory();
  const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
  const [isLoadingProcess, setIsLoadingProcess] = useState<
    WorkflowProcessMode | undefined
  >();
  //#endregion

  useEffectOnce(() => {
    return () => resetStore();
  });
  //#region <Title header>
  const titleHeader = useMemo(() => {
    const title = `Area of Responsibility Update - ${dialogInfo?.titleDialog}`;
    return getTitleWorkflow(title, prefixTitle, suffixTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefixTitle, suffixTitle, dialogInfo?.titleDialog]);
  //#endregion

  //region <Initial value>
  const initialValue = useMemo((): any => {
    const dataUDAL: DTO_Certificate_UDAL | undefined =
      workflowInitData?.WorkflowDetail?.AreaOfResponsibility?.UDAL?.[0];
    const fieldUDA: any = {};
    fieldUDA[`${EConfigNameFieldsModifyAreaOfResponsibility.OptionField}`] =
      dataUDAL?.UDA_Code;
    fieldUDA[`${EConfigNameFieldsModifyAreaOfResponsibility.TextField}`] =
      dataUDAL?.UDA_Text;
    let initStepDetails: any = {
      ...workflowInitData?.WorkflowDetail?.AreaOfResponsibility,
      _option: { ...fieldUDA },
    };
    return {
      [ModifyAreaOfResponsibilityKeysOfSteps.AreaOfResponsibility]:
        initStepDetails,
      [ModifyAreaOfResponsibilityKeysOfSteps.Documents]: {},
      [ModifyAreaOfResponsibilityKeysOfSteps.Comments]: {},
    };
  }, [workflowInitData]);
  //#endregion

  const steps: IStep[] = [
    {
      label: `${dialogInfo?.titleDialog} details`,
      initialValues:
        initialValue[
          ModifyAreaOfResponsibilityKeysOfSteps.AreaOfResponsibility
        ],
      component: StepDetailFormStep,
      visible: true,
      key: ModifyAreaOfResponsibilityKeysOfSteps.AreaOfResponsibility,
      options: {
        isReadOnly,
        udaId: dialogInfo?.udaId,
      },
    },
    {
      label: "Comments",
      component: CommentsFormStep,
      visible: true,
      key: ModifyAreaOfResponsibilityKeysOfSteps.Comments,
      customClassName: "cc-comment-step-fixed-height-grid",
      options: {
        isReadOnly,
        workflowDraftId,
        recordType: RECORDTYPE.CommunityProperty_Certificate,
      },
      initialValues:
        initialValue[ModifyAreaOfResponsibilityKeysOfSteps.Comments],
    },
    {
      label: "Documents",
      component: DocumentsFormStep,
      visible: true,
      key: ModifyAreaOfResponsibilityKeysOfSteps.Documents,
      options: {
        isReadOnly,
        workflowDraftId,
        workflowType: WorkflowTypes.AreaOfResponsibility_Modify,
      },
      initialValues:
        initialValue[ModifyAreaOfResponsibilityKeysOfSteps.Documents],
    },
  ];

  //#region handle Cancel Button>
  const handleCancelButton = (data: any) => {
    if (isFromActionList || !isFirstSave) {
      setDataForCancelDialog({
        cancelAPI: postProcessModifyAreaOfResponsibility,
        dataCancel: data,
        defaultSuccessMessage: `Area of responsibility was cancelled successfully.`,
        defaultErrorMessage: `Area of responsibility could not be cancelled.`,
      });
    } else {
      onClose();
    }
  };
  //#endregion

  //#region <Handle close dialog>
  /**
   * @param renderProps
   */
  const handleCloseDialog = (renderProps: ICCFormStepRender) => {
    if (!isFromActionList && !isFirstSave) {
      //Store submit event
      setDataForCloseDialog({
        closeCallback: renderProps.submitButton.onClick,
      });
    } else if (
      isIncompleteMode &&
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
    ) {
      onClose();
    } else if (
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Incomplete &&
      !isFirstSave
    ) {
      const newEvent = {
        currentTarget: { id: EListSubmitButton.Close },
      };
      renderProps.submitButton.onClick(newEvent);
    } else {
      onClose();
    }
  };
  //#endregion

  //#region <Handle save and next>
  const handleSaveAndNext = async (
    payload: DTO_Workflow_AreaOfResponsibilityModify
  ): Promise<boolean> => {
    //check condition use for Save button
    setIsLoadingProcess(WorkflowProcessMode.Save);
    //Calling process Save at next button
    const response = await postProcessModifyAreaOfResponsibility(
      WorkflowProcessMode.Save,
      payload
    );
    setIsLoadingProcess(undefined);
    //set default notification
    const defaultSuccessMessage = `Area of responsibility was saved successfully.`;
    const defaultFailedMessage = `Area of responsibility could not be saved.`;
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      if (!isSaveOnNextStep) {
        onClose();
        pushNotification({
          title:
            response?.data?.Notification ??
            response?.data?.SuccessMessage ??
            defaultSuccessMessage,
          type: "success",
        });
      } else {
        // check is the first saving
        if (isFirstSave) {
          setIsFirstSave(false);
          //set current workflowDraft Id
          setWorkflowDraftId(response?.data?.ID ?? 0);
          // set payload to send
          setWorkflowHeader({
            ...workflowHeader,
            WorkflowDraft: {
              ...workflowHeader.WorkflowDraft,
              Workflow_Draft_Id: response?.data?.ID,
            },
          });
        }
      }
      return true;
    } else {
      formStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
    return false;
  };
  //#endregion

  //#region <Handle next button>
  /**
   * @param data
   * @param step
   * @param keyStep
   * @returns
   */
  const handleNextButton = async (
    data: DTO_Workflow_AreaOfResponsibilityModify
  ) => {
    const newData = { ...data };
    const processPayload = processData(newData);
    //send data to call api save
    return handleSaveAndNext(processPayload);
  };
  //#endregion

  //#region <Handle park process>
  /**
   * @param payload
   * @param mode
   */
  const handleParkProcess = async (
    payload: DTO_Workflow_AreaOfResponsibilityModify,
    mode?: EListSubmitButton
  ) => {
    //set loading button and dialog
    setIsLoadingProcess(WorkflowProcessMode.Park);
    mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
    const actionCloseRetainDialog = () => {
      setIsLoadingClose(false);
      setDataForCloseDialog();
    };
    //props send to process workflow
    const parkProps: INewProcessWorkflow<DTO_Workflow_AreaOfResponsibilityModify> =
      {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              e?.SuccessMessage ??
              `Area of responsibility was parked successfully.`,
            type: "success",
          });
          isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        actionClose: () => {
          mode === EListSubmitButton.ConfirmCloseYes &&
            actionCloseRetainDialog();
        },
        defaultFailedMessage: `Area of responsibility could not be parked.`,
        modeProcess: WorkflowProcessMode.Park,
      };

    //calling api process workflow
    await handleProcessWorkflow(parkProps);
  };
  //#endregion

  //#region
  const handleSubmit = async (data: any, buttonId?: string) => {
    switch (buttonId) {
      case EListSubmitButton.Finish:
        handleConfirmFinish(data);
        break;
      case EListSubmitButton.Cancel:
      case EListSubmitButton.ConfirmCloseNo:
        handleCancelButton(processData(data));
        break;
      case EListSubmitButton.ConfirmCloseYes:
        await handleParkProcess(
          processData(data),
          EListSubmitButton.ConfirmCloseYes
        );
        break;
      case EListSubmitButton.Park:
      case EListSubmitButton.Close:
        await handleParkProcess(processData(data));
        break;
    }
  };
  //#endregion

  const processData: DTO_Workflow_AreaOfResponsibilityModify | any = (
    data: any
  ) => {
    let workflowDetail: any = {
      CertificateId: workflowInitData?.WorkflowDetail?.CertificateId,
    };
    const newData = { ...data };
    const stepDetail =
      newData[`${ModifyAreaOfResponsibilityKeysOfSteps.AreaOfResponsibility}`];
    const udal =
      workflowInitData?.WorkflowDetail?.AreaOfResponsibility?.UDAL?.[0];
    if (udal) {
      const option = stepDetail._option;
      udal.UDA_Code =
        option[`${EConfigNameFieldsModifyAreaOfResponsibility.OptionField}`];
      udal.UDA_Text =
        option[`${EConfigNameFieldsModifyAreaOfResponsibility.TextField}`];
      udal.UDA_Completed = stepDetail?.Completed;
      udal.UDA_Comment = stepDetail?.AOR_Comments;
    }

    const areaOfResponsibility = {
      UDA_Attribute_Id:
        workflowInitData?.WorkflowDetail?.AreaOfResponsibility
          ?.UDA_Attribute_Id,
      AOR_Id: workflowInitData?.WorkflowDetail?.AreaOfResponsibility?.AOR_Id,
      AOR_Name:
        workflowInitData?.WorkflowDetail?.AreaOfResponsibility?.AOR_Name,
      Completed: stepDetail?.Completed,
      Completion_DateTime: stepDetail?.Completion_DateTime,
      Completion_UserId: stepDetail?.Completion_UserId,
      AOR_Comments: stepDetail?.AOR_Comments,
      UDAL: [udal],
    };

    workflowDetail[
      `${ModifyAreaOfResponsibilityKeysOfSteps.AreaOfResponsibility}`
    ] = areaOfResponsibility;

    return {
      WorkflowHeader: workflowHeader,
      WorkflowDetail: workflowDetail,
    };
  };

  //#region <Handle process workflow>
  /**
   * common function
   * handle calling api with multiple process
   * @param props
   */
  const handleProcessWorkflow = async (
    props: INewProcessWorkflow<DTO_Workflow_AreaOfResponsibilityModify>
  ) => {
    const {
      actionSuccess,
      defaultFailedMessage,
      setLoading,
      modeProcess,
      payload,
    } = props;
    const response = await postProcessModifyAreaOfResponsibility(
      modeProcess,
      payload
    );
    setLoading();
    if (isSuccessResponse(response)) {
      if (response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        if (props?.actionFail) props?.actionFail(response);
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    } else {
      if (props?.actionFail) props?.actionFail(response);
      formStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response?.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
    if (props?.actionClose) props.actionClose();
  };
  //#endregion

  //#region <Handle finish process>
  /**
   * @param payload
   */
  const handleFinishProcess = async (
    payload: DTO_Workflow_AreaOfResponsibilityModify
  ) => {
    //set loading button
    setIsLoadingProcess(WorkflowProcessMode.Finish);
    //props send to process workflow
    const finishProps: INewProcessWorkflow<DTO_Workflow_AreaOfResponsibilityModify> =
      {
        payload: payload,
        actionSuccess: (e) => {
          const notificationContent: IAppNotificationItemAddProps = {
            title:
              e?.Notification ??
              e?.SuccessMessage ??
              `Area of responsibility was updated successfully.`,
            type: "success",
          };
          onClose();
          if (isRedirectManagePage) {
            history.push(`${PROPERTY_CERTIFICATE_ROUTE}/${e?.ID}`, {
              notification: notificationContent,
            });
          } else {
            if (actionAfterFinish) {
              actionAfterFinish(notificationContent);
            }
          }
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        defaultFailedMessage: `Area of responsibility could not be updated.`,
        modeProcess: WorkflowProcessMode.Finish,
      };
    //calling api process workflow
    await handleProcessWorkflow(finishProps);
  };
  //#endregion

  //#region <Handle confirm finish>
  const handleConfirmFinish = (
    payload: DTO_Workflow_AreaOfResponsibilityModify
  ) => {
    const dataProcessed = processData(payload, WorkflowProcessMode.Finish);
    const finishCallback = function async() {
      return handleFinishProcess(dataProcessed);
    };
    setDataForFinishDialog({
      finishCallback,
      confirmMessage: `The area of responsibility will be updated based on the information provided. Are you sure you want to submit?`,
    });
  };
  //#endregion

  return (
    <CCFormStep
      ref={formStepRef}
      onSubmit={handleSubmit}
      initialSteps={steps}
      initialValues={initialValue}
      listButtonId={listSubmitButton}
      saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="60%"
          titleHeader={titleHeader}
          onClose={() => handleCloseDialog(renderProps)}
          bodyElement={renderProps.children}
          badge={statusBadge}
          disabled={isLoadingProcess !== undefined}
          footerElement={
            <>
              <div className={"cc-dialog-footer-actions-right"}>
                {isShowParkButton(isFromActionList, isIncompleteMode) && (
                  <Button
                    iconClass={
                      isLoadingProcess === WorkflowProcessMode.Park
                        ? "fas fa-spinner fa-spin"
                        : ""
                    }
                    className={"cc-dialog-button"}
                    id={EListSubmitButton.Park}
                    onClick={renderProps.submitButton.onClick}
                    disabled={
                      renderProps.nextButton.disabled ||
                      isLoadingProcess === WorkflowProcessMode.Park
                    }
                  >
                    Park
                  </Button>
                )}
                {isShowCancelWorkflowButton && (
                  <Button
                    className={"cc-dialog-button"}
                    disabled={!isNil(isLoadingProcess)}
                    id={EListSubmitButton.Cancel}
                    onClick={renderProps.submitButton.onClick}
                  >
                    Cancel
                  </Button>
                )}
                {!renderProps.prevButton.disabled && (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                )}
                <Button
                  themeColor="primary"
                  id={
                    !renderProps.isLastStep &&
                    (isToBeApprovalMode || isReadOnly)
                      ? "cc-next-step-button"
                      : renderProps.nextButton.idButton
                  }
                  disabled={
                    isLoadingProcess === WorkflowProcessMode.Save ||
                    renderProps.nextButton.disabled
                  }
                  iconClass={
                    isLoadingProcess === WorkflowProcessMode.Save
                      ? "fas fa-spinner fa-spin"
                      : ""
                  }
                  className={"cc-dialog-button"}
                  onClick={renderProps.nextButton.onClick}
                >
                  {isLoadingProcess === WorkflowProcessMode.Save
                    ? "Saving"
                    : renderProps.nextButton.label}
                </Button>
              </div>
            </>
          }
        />
      )}
    />
  );
};
