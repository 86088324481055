import { loadViewConfiguresColumns } from "@app/products/property/api";
import {
  colAssociatedEntities,
  colAssociatedLots,
  colAssociatedParcels,
} from "@app/products/property/assessments/[id]/components/child-screens/rebates/components/entitled-to-rebate/components/detail/config";
import {
  DTO_AssessmentRebateEntitlementEntity,
  DTO_Assessment_Rebates,
} from "@app/products/property/assessments/[id]/components/child-screens/rebates/model";
import {
  DTO_Parcel,
  DTO_Title,
} from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { Label } from "@common/stores/products/config";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { loadAssessmentRebateEntitiesDetail } from "./api";

const nameOfTitle = nameOfFactory<DTO_Title>();
const nameOfParcel = nameOfFactory<DTO_Parcel>();
const nameOfEntity = nameOfFactory<DTO_AssessmentRebateEntitlementEntity>();
export const EntitledToRebateDetails = observer((props: GridDetailRowProps) => {
  const dataItem = props.dataItem;

  const { currentOrganisationMode } = useCommonProductStore();
  const isLLS = currentOrganisationMode(OrganisationMode.LLS);
  const isActro = currentOrganisationMode(OrganisationMode.ACTRO);

  const [isLoading, setIsLoading] = useState(true);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [processedParcelColumns, setProcessedParcelColumns] =
    useState<IColumnFields[]>(colAssociatedParcels);
  const [processedLotColumns, setProcessedLotColumns] =
    useState<IColumnFields[]>(colAssociatedLots);
  const [assessmentRebateData, setAssessmentRebateData] =
    useState<DTO_Assessment_Rebates>();

  // Get label
  const titleLowercaseLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.TitleLowercase
  );

  const loadData = async () => {
    setIsLoading(true);
    //Load detail data
    const response = await loadAssessmentRebateEntitiesDetail(
      dataItem.assessmentId,
      dataItem.Id
    );

    //Load Parcel view config
    if (isActro) {
      const responseParcelsConfig = await loadViewConfiguresColumns(
        ViewConfiguration.ParcelSearch,
        colAssociatedParcels
      );
      if (Array.isArray(responseParcelsConfig)) {
        setProcessedParcelColumns(responseParcelsConfig);
      } else {
        setResponseLoadError(responseParcelsConfig);
      }
    }

    //Load Lot view config
    if (isLLS) {
      const responseLotsConfig = await loadViewConfiguresColumns(
        ViewConfiguration.TitleSearch,
        colAssociatedLots
      );
      if (Array.isArray(responseLotsConfig)) {
        setProcessedLotColumns(responseLotsConfig);
      } else {
        setResponseLoadError(responseLotsConfig);
      }
    }

    setIsLoading(false);
    setAssessmentRebateData(response);
  };
  useEffectOnce(() => {
    loadData();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadData();
        }}
      />
    );

  return (
    <section className="cc-field-group">
      {isLLS && (
        <div className="cc-field">
          <CCLabel title={`Associated ${titleLowercaseLabel}s`} />
          <CCGrid
            data={assessmentRebateData?.AssociatedLots ?? []}
            primaryField={nameOfTitle("TitleId")}
            columnFields={processedLotColumns}
          />
        </div>
      )}

      {isActro && (
        <div className="cc-field">
          <CCLabel title="Associated parcels" />
          <CCGrid
            data={assessmentRebateData?.AssociatedParcels ?? []}
            primaryField={nameOfParcel("Parcel_Id")}
            columnFields={processedParcelColumns}
          />
        </div>
      )}

      <div className="cc-field">
        <CCLabel title="Associated contacts" />
        <CCGrid
          data={assessmentRebateData?.AssociatedEntities}
          columnFields={colAssociatedEntities}
          primaryField={nameOfEntity("Id")}
        />
      </div>
    </section>
  );
});
