import { colContact } from "@app/core/contacts/components/dialogs/pick-contact/config";
import { ODATA_GET_CONTACTS } from "@app/core/contacts/components/dialogs/pick-contact/constant";
import { Svc_ContactDetails } from "@common/pages/profile/components/input-picker/officer-picker/model";
import { nameOfFactory } from "@common/utils/common";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { GridSelectionDialog } from "@components/dialog/dialog-grid-selection/_index";
import { Form } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export interface IPickContactDialogProps {
  setShowDialog: (isShow: boolean) => void;
  onCloseDialog: () => void;
  onSubmit?: (
    e: any[],
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => void;
  oDataURLParams?: string;
}

export const PickContactDialog = observer(
  ({
    setShowDialog,
    onCloseDialog,
    onSubmit,
    oDataURLParams,
  }: IPickContactDialogProps) => {
    const nameOf = nameOfFactory<Svc_ContactDetails>();
    return (
      <Form
        render={() => (
          <GridSelectionDialog
            maxWidth={"60%"}
            maxHeight={"80%"}
            height={660}
            titleHeader={"Pick Contact"}
            onCloseDialog={() => onCloseDialog()}
            dataUrl={`${ODATA_GET_CONTACTS}(${
              oDataURLParams ?? ""
            })?$count=true&`}
            columnFields={colContact}
            setShowDialog={setShowDialog}
            hasSearchField
            groupDragMode="auto"
            onSubmit={onSubmit}
            crmsGrid
            primaryField={nameOf("Id")}
            filterCol={nameOf("DisplayName")}
          />
        )}
      />
    );
  }
);
