import { getMultipleSelectValue } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/util";
import {
  EInstalmentPlanOption,
  instalmentPlanData,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/instalments/config";
import { useNoticeRunStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/store";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { RadioGroupChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const NoticeRunInstalmentsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = { isReadOnly: false },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    //store
    const { noticeRunOriginLOVs } = useNoticeRunStore();
    const instalmentPlanLov = useMemo(() => {
      return noticeRunOriginLOVs?.InstalmentPlans ?? [];
    }, [noticeRunOriginLOVs?.InstalmentPlans]);

    const handleSelectionByInstalmentPlan = (event: RadioGroupChangeEvent) => {
      if (event?.value === EInstalmentPlanOption.NoSelection) {
        onChange(nameOf("InstalmentPlans"), { value: [] });
      }
      onChange(nameOf("_option.SelectionByInstalmentPlan"), {
        value: event?.value,
      });
      onChange(nameOf("SpecificRange"), {
        value:
          event?.value === EInstalmentPlanOption.NoSelection ? false : true,
      });
    };
    const valueSelectionByInstalmentPlan =
      getFieldValue("_option.SelectionByInstalmentPlan") ??
      EInstalmentPlanOption.NoSelection;

    const isDisabledInstalmentPlan = useMemo(() => {
      return (
        valueSelectionByInstalmentPlan === EInstalmentPlanOption.NoSelection
      );
    }, [valueSelectionByInstalmentPlan]);

    return (
      <div className="cc-form">
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Selection by instalment plan</label>
              <Field
                name={nameOf("_option.SelectionByInstalmentPlan")}
                component={CCRadioGroup}
                data={instalmentPlanData}
                disabled={options?.isReadOnly}
                onChange={handleSelectionByInstalmentPlan}
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            {/* TODO: Hidden temporary and get back later */}
            {/* <div className="cc-field">
            <label className="cc-label">Select instalment plan due</label>
            <Field
              name={nameOf("InstalmentPlanDue")}
              component={CCDatePicker}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">to</label>
            <Field name={nameOf("InstalmentPlanTo")} component={CCDatePicker} />
          </div> */}
          </div>
          <div className="cc-field">
            <label className="cc-label">Instalment plan</label>
            <Field
              name={nameOf("InstalmentPlans")}
              component={CCMultiSelectDropdown}
              data={instalmentPlanLov ?? []}
              onChange={(event: MultiSelectChangeEvent) => {
                let value = event?.value;
                if (value?.length) {
                  value = value.map((item: DTO_LOV_Number) => item.Code);
                }
                onChange(nameOf("InstalmentPlans"), {
                  value: value,
                });
              }}
              value={getMultipleSelectValue(
                getFieldValue("InstalmentPlans"),
                instalmentPlanLov ?? [],
                "Code"
              )}
              textField="Name"
              dataItemKey="Code"
              disabled={options?.isReadOnly || isDisabledInstalmentPlan}
            />
          </div>
        </section>
      </div>
    );
  }
);
