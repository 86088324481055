import { getViewConfigurations } from "@app/products/property/api";
import { getJournalItems, getJournalTotalAmount } from "@app/products/property/journals/[id]/components/child-screens/items/api";
import { colJournalItems } from "@app/products/property/journals/[id]/components/child-screens/items/config";
import { getItemsInfoBaseOnComponentNumber } from "@app/products/property/journals/[id]/components/child-screens/items/util";
import { DTO_Transaction_Grouping } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { IColumnFields } from "@components/cc-grid/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
configure({ enforceActions: "always" });

class JournalItemsStore {
  private _isLoading: boolean = false;
  private _isLoadingGrid: boolean = false;
  private _responseLoadErrorGrid?: APIResponseError = undefined;
  private _responseLoadErrorViewConfig?: APIResponseError = undefined;
  private _showCancelledTransactions: boolean = false;
  private _columnFields: IColumnFields[] = colJournalItems;
  private _selectedTransaction?: any = undefined;
  private _itemsData?: DTO_Transaction_Grouping = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadErrorGrid() {
    return toJS(this._responseLoadErrorGrid);
  }
  setResponseLoadErrorGrid = (responseLoadErrorGrid?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadErrorGrid = responseLoadErrorGrid;
    });
  };

  get responseLoadErrorViewConfig() {
    return toJS(this._responseLoadErrorViewConfig);
  }
  setResponseLoadErrorViewConfig = (
    responseLoadErrorViewConfig?: APIResponseError
  ) => {
    runInAction(() => {
      this._responseLoadErrorViewConfig = responseLoadErrorViewConfig;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isLoadingGrid() {
    return this._isLoadingGrid;
  }
  setIsLoadingGrid = (isLoadingGrid: boolean) => {
    runInAction(() => {
      this._isLoadingGrid = isLoadingGrid;
    });
  };

  get showCancelledTransactions() {
    return this._showCancelledTransactions;
  }
  setShowCancelledTransactions = (showCancelledTransactions: boolean) => {
    runInAction(() => {
      this._showCancelledTransactions = showCancelledTransactions;
    });
  };

  get columnFields() {
    return this._columnFields;
  }
  setColumnFields = (columnFields: IColumnFields[]) => {
    runInAction(() => {
      this._columnFields = columnFields;
    });
  };

  get selectedTransaction() {
    return toJS(this._selectedTransaction);
  }
  setSelectedTransaction = (selectedTransaction?: any) => {
    runInAction(() => {
      this._selectedTransaction = selectedTransaction;
    });
  };

  get itemsData() {
    return toJS(this._itemsData);
  }
  setItemsData = (itemData?: DTO_Transaction_Grouping) => {
    runInAction(() => {
      this._itemsData = itemData;
    });
  };

  // Change the total amount calculation for the grid when loading data with OData
  // In this case we get the total amount from the server, calculated for all transactions, 
  // and set it to the footer cell
  changeTotalAmountCalculation = (amount: number, col: IColumnFields[]): IColumnFields[] => {
    const foundIndex = col.findIndex(c => !!c.calculateFooterCellValue);
    if (foundIndex === -1) return;
    col[foundIndex].calculateFooterCellValue = (props: GridFooterCellProps, gridData: any) => amount;      
  }

  loadItemsConfigurationColumns = async (
    componentNumber?: number | undefined,
    journalNumber?: string | undefined
  ) => {
    let errorResponse = undefined;
    this.setIsLoadingGrid(true);
    const { viewConfiguration, col } =
    getItemsInfoBaseOnComponentNumber(componentNumber);
    const response = await getViewConfigurations(viewConfiguration);
    if (journalNumber) {
      const amount = await getJournalTotalAmount(journalNumber);
      this.changeTotalAmountCalculation(amount?.data.Amount, col);
    }
    if (isSuccessResponse(response) && response?.data) {
      this.setColumnFields(
        processDynamicColumns(col, response.data.ColumnDefinitions?.Columns)
      );
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadErrorViewConfig(errorResponse);
    this.setIsLoadingGrid(false);
  };

  loadJournalItemsData = async (id: string) => {
    this.setIsLoading(true);
    let errorResponse = undefined;
    const response = await getJournalItems(id);
    if (isSuccessResponse(response) && response?.data) {
      this.setItemsData(response.data?.TransactionGrouping);
    } else {
      errorResponse = {
        status: response?.status,
        error: response?.error ?? "Server error",
      };
    }
    this.setResponseLoadErrorGrid(errorResponse);
    this.setIsLoading(false);
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._isLoadingGrid = false;
      this._responseLoadErrorGrid = undefined;
      this._showCancelledTransactions = false;
      this._columnFields = [];
      this._selectedTransaction = undefined;
      this._itemsData = undefined;
    });
  };
}

const journalTransactionStoreStoreContext = createContext(
  new JournalItemsStore()
);
export const useJournalTransactionStore = () => {
  return useContext(journalTransactionStoreStoreContext);
};
