import {
  FormAction,
  FormIdentifier,
  ViewActionButton,
} from "@app/products/crms/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { hasPermissionButton } from "@common/hocs/permissions/util";
import { useCommonCoreStore } from "@common/stores/core/store";
import { isArray, isNil } from "lodash";
import { useCallback, useState } from "react";
import { useEffectOnce } from "react-use";

export interface IPermissionsProps {
  formIdentifier: FormIdentifier;
  productType: PRODUCT_TYPE_NUMBER;
}

export interface ICheckPermissionResult {
  checkPermissions: (formAction?: FormAction | FormAction[]) => boolean;
  isLoadingPermission: boolean;
  resetPermissions: () => void;
}

export type TCheckPermissions = (
  props: IPermissionsProps
) => ICheckPermissionResult;

export const useCheckPermissions: TCheckPermissions = (
  props: IPermissionsProps
) => {
  //State
  const [isLoadingPermission, setIsLoadingPermission] =
    useState<boolean>(false);
  const [viewActionButton, setViewActionButton] = useState<
    ViewActionButton[] | undefined
  >();
  //Store Permission
  const { loadActionButtonPermission, resetPermissionStore } =
    useCommonCoreStore();

  //Load permission
  useEffectOnce(() => {
    const fetchPermission = async () => {
      setIsLoadingPermission(true);
      const response = await loadActionButtonPermission(
        props.formIdentifier,
        props.productType
      );
      setIsLoadingPermission(false);
      setViewActionButton(response);
    };
    if (!isNil(props?.formIdentifier) && !isNil(props?.productType)) {
      fetchPermission();
    }
    return () => {
      resetPermissionStore();
    };
    // eslint-disable-next-line
  });

  //Check permission function
  const checkPermissions = useCallback(
    (formActions?: FormAction | FormAction[]) => {
      let hasPermission = true;
      if (!isArray(formActions)) {
        hasPermission = hasPermissionButton(viewActionButton, formActions);
      } else {
        formActions.forEach((formAction) => {
          hasPermission =
            hasPermission && hasPermissionButton(viewActionButton, formAction);
        });
      }
      return hasPermission;
    },
    // eslint-disable-next-line
    [viewActionButton]
  );

  //Reset permission data
  const resetPermission = useCallback(
    () => {
      setViewActionButton(undefined);
      resetPermissionStore();
    },
    // eslint-disable-next-line
    [resetPermissionStore]
  );

  return {
    checkPermissions: checkPermissions,
    isLoadingPermission: isLoadingPermission,
    resetPermissions: resetPermission,
  };
};
