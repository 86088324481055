import {
  DTO_Request_Base,
  DTO_WorkflowHeader,
} from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfStepsSplitAssessment {
  General = "Details",
  AssessmentToBeSplit = "SplitAssessment",
  ParcelsToBeSplit = "SplitParcel",

  //@TODO: Waiting confirmation
  ItemsToClone = "ItemsToClone",

  Collection = "Collection",
  Comments = "Comments",
  Documents = "Documents",
  Workflow = "Workflow",
}

export const keysOfSendStepsSplitAssessment = [
  EKeysOfStepsSplitAssessment.General,
  EKeysOfStepsSplitAssessment.AssessmentToBeSplit,
  EKeysOfStepsSplitAssessment.ParcelsToBeSplit,
  EKeysOfStepsSplitAssessment.ItemsToClone,
];

export interface DTO_SplitAssessment extends DTO_Request_Base {
  Assessment_Id: number;
}
export interface DTO_Workflow_SplitAssessment {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_SplitAssessment;
}

export interface DTO_WorkflowDetail_SplitAssessment {
  Assessment_Id: number;
  Supplementary_Id: number;
  IsNewSupplementary: boolean | null;
  Details: DTO_SplitAssessment_Details;
  SplitAssessment: DTO_SplitAssessment_Assessment;
  SplitParcel: DTO_SplitParcel;
  LOVs: DTO_SplitAssessment_LOVs;

  SelectedSecondaryWorkflow: number[];
  SelectedCollection: number[];
}

export interface DTO_SplitParcel {
  Parcels: DTO_SplitAssessment_Parcels[];
  ParcelsNotImpacted: DTO_SplitAssessment_Parcels[];
}

export interface DTO_SplitAssessment_Details {
  Reason_Id: number;
  Reason: string;
  Applicant: string;
  Reference: string;
  Association_Date: Date | null;
}

export interface DTO_SplitAssessment_Assessment {
  Assessment: DTO_Assessment;
}

export interface DTO_Assessment {
  Assessment_Id: number;
  Assessment_Number: string;
  Assessment_Number_Decimal: number | null;
  Assessment_Valuation: string;
  Assessment_Valuation_Decimal: number | null;
  Assessment_Reference: string;
  Property_Address: string;
  Assessment_Group: string;
  Ratepayer: string;
}

export interface DTO_SplitAssessment_Parcels {
  UUID: string;
  Existing_Assessment_Id: number;
  Provisional_Assessment_Valuation_NumberX: string;
  Provisional_Assessment_Valuation_Number: number | null;
  Provisional_Assessment_Reference: string;
  Existing_Assessment_NumberX: string;
  Existing_Assessment_Number: number | null;
  Owner: string;
  Description: string;
  Section: string;
  Plan: string;
  Lot: string;
  Factor: number | null;
  New_Assessment_Id: number;
  Provisional_Assessment_Number: number;
  Parcel_Id: number;
  Supplementary_Id: number | null;
}

export interface DTO_SplitAssessment_LOVs {
  Change_Reason: DTO_LOV[];
  Memo_Category: DTO_LOV[];
}
