import { IKeyValuePacket } from "@common/models/keyValuePacket";

export enum EKeysOfStepsParcel {
  SelectDetails = "SelectDetails",
  Associations = "Associations",
  Address = "Address",
  SelectLandUses = "SelectLandUses",
  SelectZones = "SelectZones",
  SelectConstraints = "SelectConstraints",
  Comments = "Comments",
  Documents = "Documents",
  SecondaryWorkflow = "SecondaryWorkflow",
}

export const keysOfSendStepsParcel = [
  EKeysOfStepsParcel.SelectDetails,
  EKeysOfStepsParcel.Associations,
  EKeysOfStepsParcel.Address,
  EKeysOfStepsParcel.SelectLandUses,
  EKeysOfStepsParcel.SelectZones,
  EKeysOfStepsParcel.SelectConstraints,
];

export const mockReasonParcelLOV: IKeyValuePacket[] = [
  {
    Key: 1,
    Value: "Invalid information",
  },
  {
    Key: 2,
    Value: "Conflict with another parcel",
  },
  {
    Key: 3,
    Value: "Other",
  },
];
