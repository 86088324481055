import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { eComponent } from "@app/products/property/components/associations/model";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

interface IChangeOfOwnerShipButtonProps {
  title?: string;
  componentNumber: eComponent;
  disabled?: boolean;
}
export const ChangeOfOwnerShipButton = observer(
  ({ title, componentNumber, disabled }: IChangeOfOwnerShipButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();
    const { id } = useParams<{ id: string }>();
    const { gridSelectedIds, gridSelectedRows, isEmptyData } =
      useCCProductListViewStore();
    const isFromContact = componentNumber === eComponent.Entity;
    const isFromAssessment = componentNumber === eComponent.Assessment;
    const isFromTitle = componentNumber === eComponent.Title;
    const isFromChangeOfOwnership =
      componentNumber === eComponent.Change_of_Ownership;
    const isFromPIC = componentNumber === eComponent.PIC;

    const isDisabled = useMemo(() => {
      return (
        (!isEmptyData && gridSelectedRows.length > 1 && !id) ||
        (!id && isEmptyData)
      );
    }, [gridSelectedRows.length, id, isEmptyData]);

    return (
      <CCNavButton
        title={title ?? "Ownership"}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Change_Of_Ownership,
              data: {},
              props: {
                assessmentId: isFromAssessment
                  ? id ?? gridSelectedRows?.[0]?.Assessment_Id ?? null
                  : null,
                contactId: isFromContact ? id ?? null : null,
                titleId: isFromTitle ? id ?? gridSelectedIds[0] ?? null : null,
                picId: isFromPIC ? id ?? gridSelectedIds[0] ?? null : null,
                componentNumber: componentNumber ?? eComponent.Assessment,
              },
            },
          ]);
        }}
        disabled={disabled ?? (isDisabled && !isFromChangeOfOwnership)}
      />
    );
  }
);
