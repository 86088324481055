import {
  CS_CaseDetailType,
  CS_Confidentiality,
  CS_OnBehalfOfType,
  CS_ServiceStandardType,
  ElementControl,
  EventLookupTableLNK,
  FSR_COMPLAINTTYPE,
  Records_FileNoType,
  ServiceStandardActionTypeLink,
  ServiceStandardUpdateTriggers,
  SpecialInterestRequestorOrgManagerLevel,
} from "@app/products/crms/[id]/model";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { SiteUser } from "@app/products/waste-water/[id]/model";
import {
  ACCESSCONTROLRECORDTYPE,
  RosterType,
} from "@common/constants/enumerations";
import { AccessControl } from "@common/models/accessControl";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";

export interface ICRMSServiceStandardSummary {
  Id?: number;
  Category?: string;
  SubCategory?: string;
  Precis?: string;
  Description?: string;
  OrgStructure?: string;
  DistributedWithin?: string;
  CoordinatorPerson?: string;
  AutomaticAllocation?: boolean;
  ActionPersonAllocation?: ActionPersonAllocation;
  ActionPerson?: string;
  RosterType?: string;
  SendToCoordinatorPerson?: boolean;
  SendToActionPerson?: boolean;
  AllowAnonymous?: boolean;
  ServiceStandardType?: eServiceStandardType;
  PHMSDetails?: boolean;
  AdditionalRefNo?: boolean;
  AllowReCategories?: boolean;
  AllowReallocateOfficer?: boolean;
  HasNotificationsOrAlerts?: boolean;
  HasTargetDays?: boolean;
  HasSpecialInterest?: boolean;
  AllowQuickClose?: boolean;
  AssessmentNoIsMandatory?: boolean;
  RequestorAddressIsMandatory?: boolean;
  OnBehalfOfForInternalRequests?: string;
  PromptForLocation?: boolean;
  SetMeAsRequestor?: boolean;
  AddAttachment?: boolean;
  ShowMap?: boolean;
  LockEventOnSendForAction?: boolean;
  EnableComplainee?: boolean;
  ComplaineeLabel?: string;
  EmailRequestorOnSchedule?: boolean;
  CRMS?: boolean;
  KnowledgeBase?: boolean;
  System?: boolean;
  WWMS?: boolean;
  ControlledSubstance?: boolean;
  Animal?: boolean;
  ITSupport?: boolean;
  Health?: boolean;
  LocalLaws?: boolean;
  TownPlanning?: boolean;
  Building?: boolean;
  InternetChoice?: boolean;
  EMS?: boolean;
  ParkingPermits?: boolean;
  Infringements?: boolean;
  SimpleForm?: boolean;
  Inspections?: boolean;
  EnableAnimalsLookup?: boolean;
  Notices?: boolean;
  AutoActions?: boolean;
  EnableBinsLookup?: boolean;
  Assets?: boolean;
  HardRubbish?: boolean;
  Payroll?: boolean;
  CollectionDate?: boolean;
  EnableSchoolPatrols?: boolean;
  EnableNuisanceAct?: boolean;
  AfterHoursSendEmail?: boolean;
  UseSystemDefault?: boolean;
  NonLocation?: boolean;
  CommunityProperty?: boolean;
  NonProperty?: boolean;
  Reserve?: boolean;
  Location?: boolean;
  CouncilProperty?: boolean;
  Poisons?: boolean;
  Tobacco?: boolean;
  SameAsRequestor?: boolean;
  USHealth?: boolean;
  RadiationPlace?: boolean;
  //Asset Details
  SendToRAMM?: boolean;
  AssetIsMandatory?: boolean;
  WorkIdentifier?: string;
  ContractId?: number;
  FaultCode?: string;
  AssetType?: number;
  Priority?: number;

  //Case Details
  CaseDetailsForm?: string;
  ButtonLabel?: string;

  //Communication Settings
  SendToCoordinator?: string;
  SendToActionOfficer?: string;
  SendEmailToRequestorOnSendForAction?: boolean;
  SendEmailToRequestorOnResendForAction?: boolean;
  SendSmsToRequestorOnSendForAction?: boolean;
  SendSmsToRequestorOnReSendForAction?: boolean;
  SendEmailToOnBehalfOfOnSendForAction?: boolean;
  SendEmailToOnBehalfOfOnReSendForAction?: boolean;
  SendSMSToRequestorOnClosureForAction?: string;

  //Customer Service Validation & Resolution Responses
  CSValidation?: string;
  TextCSValidation?: string;
  SpecialInstructions?: string;
  RequestorOnPhoneResponse?: string;
  RequestorInPersonResponse?: string;

  //Health Manager
  MobileChoice?: boolean;
  MobileIcon?: string;

  //Mobile Details
  FsrCode?: string;
  ShowHealthFieldsOnEventClosure?: boolean;

  //Security
  Confidentiality?: string;
  IsConfidentiality?: boolean;

  //Special Interest
  ManualEntries?: string;
  EmailOrgManager?: boolean;
  OrgLevel?: string;
  Created?: boolean;
  ReCategorised?: boolean;
  Rescheduled?: boolean;
  Overdue?: boolean;
  Closed?: boolean;
  SentForAction?: boolean;

  //Target Days
  CalculateCompletionTargetInDaysOrHours: eCalculateCompletionTargetInDaysOrHours;
  Completion?: number;
  UrgentCompletion?: number;
  UseAcknowledgement?: boolean;
  Acknowledgement?: string;
  UrgentAcknowledgement?: string;
  MayBeRescheduled?: boolean;
  MaxNoOfDayToComplete?: number;
  NotifyCustomerIfRescheduled?: boolean;
  FinalNotification?: boolean;
  IncludeWeekendsOrPublicHolidaysInTargetCalculation?: boolean;
  EnableInternalClosureComment?: boolean;
}
export enum eServiceStandardType {
  REQUEST = "Request",
  INCIDENT = "Incident",
}
export enum ActionPersonAllocation {
  ROSTER = 0,
  STAFF = 1,
}
export enum eCalculateCompletionTargetInDaysOrHours {
  DAYS = "Days",
  HOURS = "Hours",
}
export interface ICRMSServiceStandardRequest
  extends ICRMSServiceStandardSummary {}
export interface ICRMSServiceStandardRecordsState {
  StandardDocuments?: number;
}

export enum ServiceStandardCategoryUpdateTriggers {
  SaveServiceStandardCategory,
}

export enum ServiceStandardSubCategoryUpdateTriggers {
  SaveServiceStandardSubCategory,
}

export interface ServiceStandardCategory extends DTOBaseClass_Standard {
  ServiceStandardCategory_Id: number;
  Name: string;
  SaveTriggers: ServiceStandardCategoryUpdateTriggers[];
}

export interface ServiceStandardSubCategory extends DTOBaseClass_Standard {
  ServiceStandardSubCategory_Id: number;
  ServiceStandardCategory_Id: number;
  Name: string;
  SaveTriggers: ServiceStandardSubCategoryUpdateTriggers[];
}

export enum CS_MobileIcon {
  Default = 0,
  Aged_Disability = 1,
  Animals = 2,
  Assets = 3,
  Bookings = 4,
  Bridges = 5,
  Building = 6,
  Bus_Shelters = 7,
  Child_Care = 8,
  Community = 9,
  Correspondence = 10,
  Drainage = 11,
  Footpaths = 12,
  Graffiti = 13,
  Health = 14,
  Lighting = 15,
  Local_Laws = 16,
  Nature_Strips = 17,
  Parking = 18,
  Parks_Reserves = 19,
  Roads = 20,
  Rubbish = 21,
  Signs = 22,
  Syringes = 23,
  Toilets = 24,
  Trees = 25,
  Vehicle = 26,
  Works = 27,
  Youth = 28,
  Other = 29,
  Traffic = 30,
  BusShelters = 31,
}

export interface ServiceStandard extends DTOBaseClass_Standard {
  ServiceStandard_Id: number;
  ServiceStandardMapper_Id: number;
  ServiceStandardCategory_Id: number;
  Category: ServiceStandardCategory;
  NewCategoryName: string;
  ServiceStandardSubCategory_Id: number;
  SubCategory: ServiceStandardSubCategory;
  NewSubCategoryName: string;
  SubCategoryLevel3: string;
  SubCategoryLevel4: string;
  CategoryCode: string;
  NewSubCategoryLevel3: string;
  NewSubCategoryLevel4: string;
  FullName: string;
  Name: string;
  Description: string;
  ServiceStandardType_ENUM: CS_ServiceStandardType;
  ServiceStandardType_Name: string;
  Alerts_EnableEscalationAlerts: boolean | null;
  Alerts_Escalation1_Officer_SiteUser_ID: number | null;
  Alerts_Escalation1_Officer: SiteUser;
  Alerts_Escalation1_Days: number | null;
  Alerts_Escalation2_Officer_SiteUser_ID: number | null;
  Alerts_Escalation2_Officer: SiteUser;
  Alerts_Escalation2_Days: number | null;
  Alerts_EnableCouncillorAlerts: boolean | null;
  Alerts_Councillor_RequestSent: boolean | null;
  Alerts_Councillor_RequestClosed: boolean | null;
  Alerts_Councillor_Manager1: boolean | null;
  Alerts_Councillor_Manager2: boolean | null;
  Alerts_Councillor_Manager3: boolean | null;
  Alerts_Councillor_Manager4: boolean | null;
  Alerts_Councillor_Manager5: boolean | null;
  Alerts_Councillor_Other: boolean | null;
  Alerts_Councillor_Other_Emails: string;
  Org1_Id: number | null;
  Org2_Id: number | null;
  Org3_Id: number | null;
  Org4_Id: number | null;
  Org5_Id: number | null;
  DistributedWithin: number | null;
  AutomaticAllocation: boolean | null;
  AllowAnonymous: boolean | null;
  AllowRecategorise: boolean | null;
  AllowReallocateOfficer: boolean | null;
  IsComplaineeEnabled: boolean | null;
  ComplaineeLabel: string;
  EmailRequestorOnSchedule: boolean | null;
  SendToCoordinator: boolean | null;
  Coordinator_SiteUser_ID: number | null;
  Coordinator: SiteUser;
  SendToActionOfficer: boolean | null;
  ActionOfficer_SiteUser_ID: number | null;
  ActionOfficer: SiteUser;
  Flag_ActionOfficer_StaffMember: boolean;
  RosterType_ENUM: RosterType | null;
  RosterType_Name: string;
  Confidentiality_ENUM: CS_Confidentiality;
  Confidentiality_Name: string;
  IssueLocationTypes: EventLookupTableLNK[];
  IssueLocationTypes_UseDefaults: boolean | null;
  ResolutionResponses_UseDefaults: boolean | null;
  CustomerServiceValidation: string;
  ResolutionResponses: EventLookupTableLNK[];
  Response1: string;
  Response2: string;
  Response3: string;
  EnableQuickClose: boolean | null;
  DisplayInBulletinBoard: boolean | null;
  DisplayAsInternetChoice: boolean | null;
  Flag_MobileChoice: boolean | null;
  Flag_InformationOnly: boolean | null;
  Flag_WebHouseNumberMandatory: boolean | null;
  Flag_WebEmailMandatory: boolean | null;
  Flag_WebHomePhoneMandatory: boolean | null;
  Flag_WebWorkPhoneMandatory: boolean | null;
  Flag_WebLookupCouncilLocation: boolean | null;
  Flag_WebEnableUploadControl: boolean | null;
  Flag_WebShowCouncillor: boolean | null;
  CategoryWebInstructions: string;
  CategoryInternetSearchKey: string;
  CommunityEngagementSearchKey: number | null;
  CategoryInternetFriendlyName: string;
  InternetPageTitle: string;
  CategorySortIndex: number;
  CategoryDescriptionTextOption1: string;
  CategoryDescriptionTextOption2: string;
  CategoryDescriptionCheckboxes: string;
  CategoryUploadControlAdditionalText: string;
  InternetPageSubTitle: string;
  InternetTags: string;
  InternetParentServiceStandard_Id: number | null;
  DisplayAsCRMSChoice: boolean | null;
  SpecialInterest_SiteUser_IDs: number[];
  SpecialInterest_Email_ManualEntries: string;
  Flag_SpecialInterest_Email_RequestorOrgManager: boolean | null;
  SpecialInterest_Email_RequestorOrgManagerLevel_ENUM: SpecialInterestRequestorOrgManagerLevel | null;
  SpecialInterest_Email_RequestorOrgManagerLevel_Name: string;
  SpecialInterest_Email_WhenCreated: boolean | null;
  SpecialInterest_Email_WhenClosed: boolean | null;
  SpecialInterest_Email_WhenOverdue: boolean | null;
  SpecialInterest_Email_WhenRecategorised: boolean | null;
  SpecialInterest_Email_WhenRescheduled: boolean | null;
  SpecialInterest_EmailIncludeDescriptionInSubject: boolean | null;
  SpecialInterest_Email_WhenSentForAction: boolean | null;
  EnableTargetDays: boolean | null;
  TargetInDays: boolean | null;
  Targets_Acknowledgement: boolean | null;
  Targets_Standard_Acknowledgement: number | null;
  Targets_Standard_Actual_Completion: number | null;
  Targets_Standard_Admin_Completion: number | null;
  Targets_Urgent_Acknowledgement: number | null;
  Targets_Urgent_Actual_Completion: number | null;
  Targets_Urgent_Admin_Completion: number | null;
  Targets_Max_Completion_Days: number | null;
  Targets_IncludeWeekendInCalculation: boolean | null;
  Targets_AllowRescheduling: boolean | null;
  Targets_AllowReschedulingOnUrgent: boolean | null;
  Targets_RescheduledNotifyCustomer: boolean | null;
  Targets_FinalNotification: boolean | null;
  Enable_InternalClosureComments: boolean | null;
  CaseDetails_Form_ENUM: CS_CaseDetailType | null;
  CaseDetails_Name: string;
  CaseDetails_Label: string;
  SendToActionSendEmailToRequestor: boolean | null;
  ReSendToActionSendEmailToRequestor: boolean | null;
  SendToActionSendSMSToRequestor: boolean | null;
  ReSendToActionSendSMSToRequestor: boolean | null;
  ClosureSendSMSToRequestor: boolean | null;
  Actions_Categorised_AllowActions: boolean | null;
  Actions_Normal_CreateAutomatically: boolean | null;
  Flags_ReturnCall: boolean | null;
  Flags_InsuranceClaim: boolean | null;
  Flags_Proactive: boolean | null;
  Records_Classification: string;
  Records_FileNumber: string;
  Records_FileNoType_ENUM: Records_FileNoType | null;
  Records_FileNoType_Name: string;
  Records_CategoryCode: string;
  HasSpecialInterest: boolean | null;
  HasCaseDetails: boolean | null;
  HasAlerts: boolean | null;
  Flag_Inspection: boolean | null;
  IsNotice: boolean | null;
  IsEnableBinsLookUp: boolean | null;
  IsAnimalsLookUpEnabled: boolean | null;
  HasAsset: boolean | null;
  Flag_AssetIsMandatory: boolean | null;
  Flag_AssetSendToAssetSystem: boolean | null;
  Flag_AssetSendToAssetSystemOnClosure: boolean | null;
  AssetServiceCode: string;
  AssetSubjectCode: string;
  AssetCategoryCode: string;
  AssetJobPriority: string;
  WorkIdentifier_KWD: number | null;
  WorkIdentifier_Name: string;
  DefaultEventType_ID: number | null;
  Tier_ID: number | null;
  EnableTierLookup: boolean | null;
  EnableInformationOrProcedureDisplay: boolean | null;
  EnableCustomerContacted: boolean | null;
  HasWasteWater: boolean | null;
  HasControlledSubstance: boolean | null;
  SpecialInterest: AccessControl[];
  Readers: AccessControl[];
  Writers: AccessControl[];
  EventReaders: AccessControl[];
  EventWriters: AccessControl[];
  ServiceDirection_DocumentTemplate_ID: number | null;
  Flag_ITSupport: boolean | null;
  OnBehalfOfType_ENUM: CS_OnBehalfOfType | null;
  OnBehalfOfType_Name: string;
  SendToActionSendEmailToOnBehalfOf: boolean | null;
  ReSendToActionSendEmailToOnBehalfOf: boolean | null;
  Flag_ReferenceNumberAdditional: boolean | null;
  ReferenceNumberAdditionalLabel: string;
  Flag_AssessmentNo: boolean | null;
  Flag_PropertySearch: boolean | null;
  Flag_HardRubbish: boolean | null;
  Flag_HardRubbishPicker: boolean | null;
  Flag_HardRubbishCollectionItemLookup: boolean | null;
  Flag_HardRubbishMessagePrompt: boolean | null;
  Flag_RequestorAddressMandatory: boolean | null;
  HardRubbish_CollectionItemLookupTableID: number | null;
  HardRubbish_CollectionItemLookupTableName: string;
  HardRubbish_CollectionItemUserDefinedValue: string;
  HardRubbish_CollectionItemName: string;
  Flag_HardRubbishAllocationManual: boolean | null;
  HardRubbish_AllocationBusinessDays: number | null;
  HardRubbish_CollectionsPerYear: number | null;
  Flag_HardRubbishScheduleEventOnSent: boolean | null;
  HardRubbish_RestrictionsPerMonth: number | null;
  HardRubbish_RestrictionsAlert: string;
  HardRubbish_MessagePrompt: string;
  HardRubbish_MessageItemsOptions: string;
  Flag_AfterHoursSendEmail: boolean | null;
  AfterHoursEmailAddress: string;
  Flag_IncidentTypeLookup: boolean | null;
  FSRCode_ENUM: FSR_COMPLAINTTYPE | null;
  FSRCode_Name: string;
  Flag_EventClosureShowHealthFields: boolean | null;
  Flag_ExternalUser: boolean | null;
  Flag_SimpleForm: boolean | null;
  Flag_Payroll: boolean | null;
  isCollectionDate: boolean | null;
  Flag_System: boolean | null;
  Flag_MeAsRequestor: boolean | null;
  Flag_AddAttachment: boolean | null;
  Flag_ShowMap: boolean | null;
  Flag_LockEvent: boolean | null;
  EnableSchoolPatrols: boolean | null;
  EnableNuisanceAct: boolean | null;
  MobileIconDetails_Form_ENUM: CS_MobileIcon | null;
  MobileIconDetails_Name: string;
  NeedAutoPopupInstructions: boolean | null;
  IsAvailableOnlyToOwners: boolean | null;
  ConfirmationContent: string;
  IssueLocationLabel: string;
  ConfidentialEnableAddAttachment: boolean | null;
  IsAnimal: boolean | null;
  IsKennels: boolean | null;
  IsHealth: boolean | null;
  IsLocalLaws: boolean | null;
  IsTownPlanning: boolean | null;
  IsBuilding: boolean | null;
  Flag_WorkflowCreateActions: boolean | null;
  WorkflowActions: ServiceStandardActionTypeLink[];
  LocalLawPermitType_ID: number | null;
  IsInfringements: boolean | null;
  TPApplicationCategory_ID: number | null;
  IsEMS: boolean | null;
  AutoCreateEMSEvent: boolean | null;
  IsParkingPermit: boolean | null;
  ParkingPermitType_ID: number | null;
  IsLandManagement: boolean | null;
  SaveTriggers: ServiceStandardUpdateTriggers[];
  _SaveTriggers: ServiceStandardUpdateTriggers[];
}

export interface ServiceStandardLOVs {
  Products: KeyValuePacket[];
  Category: KeyValuePacket[];
  EventType: KeyValuePacket[];
  Tier: KeyValuePacket[];
  RosterType: KeyValuePacket[];
  Confidentiality: KeyValuePacket[];
  CommunityEngagementSearchKey: KeyValuePacket[];
  RecordsFileNoType: KeyValuePacket[];
  OnBehalfOf: KeyValuePacket[];
  CaseDetails: KeyValuePacket[];
  MobileIcon: KeyValuePacket[];
  ServiceStandardType: KeyValuePacket[];
  SpecialInterest_Email_OrgManager_OrgLevel: KeyValuePacket[];
  WorkIdentifier: KeyValuePacket[];
  IssueLocationTypes: EventLookupTableLNKWithName[];
  ResolutionResponses: EventLookupTableLNKWithName[];
  DisplayinCRMS: string;
  IsHealthManagerEnabled: boolean;
  fieldHasWasteWaterText: string;
}

export enum Svc_FormAction {
  SystemInitialise,
  PickOrgStructure,
  ChangeCategory,
  ChangeSubCategory,
  ChangeSubCategoryL3,
  ChangeSubCategoryL4,
  CreateCategory,
  CreateSubCategory,
  CreateSubCategoryL3,
  CreateSubCategoryL4,
  ChangeServiceStandardType,
  PickCoordinator,
  PickActionOfficer,
  PickEscalationOfficer1,
  PickEscalationOfficer2,
  PickSpecialInterest,
  PickReader,
  PickWriter,
  PickEventReader,
  PickEventWriter,
  PickAutoActions,
  PickStandardDocument,
  PickServiceDirection,
  PickRecordsTRIMDetails,
  PickRecordsinfoXpertDetails,
  PickParentServiceStandardforTRIBE,
  CreateSearchKey,
  ChangeTargetMayBeRescheduled,
}

export interface ServiceStandardHandlerRequest {
  ServiceStandardFormAction: Svc_FormAction;
  ServiceStandard: ServiceStandard;
  EventArgs: any;
  IsFirstTimeLoad: boolean;
}

export interface ServiceStandardHandlerResponse {
  ServiceStandard: ServiceStandard;
  UIControl: ServiceStandardUIControl;
}

export interface ServiceStandardMapObj {
  ServiceStandard: ServiceStandard;
}

export interface ServiceStandardUIControl {
  FlDisplayAsCRMSChoice: ElementControl;
  DivSubCategoryLevel3: ElementControl;
  DivSubCategoryLevel4: ElementControl;
  FlSubCategoryLevel3: ElementControl;
  FlSubCategoryLevel4: ElementControl;
  LbFieldHasWasteWater: ElementControl;
  DivResponses: ElementControl;
  LbtClearInfoXpertPrimaryFolder: ElementControl;
  DivRecordsTRIM: ElementControl;
  DivRecordsContentPlus: ElementControl;
  DivRecordsObjective: ElementControl;
  DivRecordsInfoXpert: ElementControl;
  DivWasteWater: ElementControl;
  DivControlledSubstance: ElementControl;
  DivAnimal: ElementControl;
  DivKennel: ElementControl;
  DivWWMS_CSM: ElementControl;
  DivITSupport: ElementControl;
  DivHardRubbishFlag: ElementControl;
  DivHealth: ElementControl;
  TrMobileIcon: ElementControl;
  TrSystemInterface: ElementControl;
  TrAdditionalInterface: ElementControl;
  DivComplaintHandling: ElementControl;
  DdlDistributedWithin: ElementControl;
  DivDistributedWithin: ElementControl;
  DdlCategory: ElementControl;
  DdlSubCategory: ElementControl;
  DdlSubCategoryLevel3: ElementControl;
  DdlSubCategoryLevel4: ElementControl;
  DivTargetAcknowledgement: ElementControl;
  DivTargetRescheduled: ElementControl;
  RblEnableInternalClosureComment: ElementControl;
  LblOrgStructure: ElementControl;
  LblActionOfficer: ElementControl;
  LblCoordinator: ElementControl;
  FlResponse1Caption: ElementControl;
  FlResponse2Caption: ElementControl;
  FlResponse3Caption: ElementControl;
  LblParentKeyServiceStandard: ElementControl;
  DdlCommunityEngagementSearchKey: ElementControl;
  DdlConfidentiality: ElementControl;
  DdlRosterType: ElementControl;
  AccessRights: ElementControl;
  DivGeneralAssetDetails: ElementControl;
  DivAssetAddtionalDetails: ElementControl;
  DivAssetJobPriority: ElementControl;
  DivAssetSendToExternalSystemOnClosure: ElementControl;
  FlAssetSendToAssetSystem: ElementControl;
  FlAssetCategoryCode: ElementControl;
  FlAssetServiceCode: ElementControl;
  FlAssetSubjectCode: ElementControl;
  FlAssetSendToAssetSystemOnClosure: ElementControl;
  RblSendToAssetSystem: ElementControl;
}

export interface EventLookupTableLNKWithName extends EventLookupTableLNK {
  Name: string;
}

export interface PickOrgAndSiteUser {
  SiteUsers: number[];
  Hierarchies: number[];
}

export enum AdvancedFilterOperations {
  Equals = 0,
  Greater = 1,
  GreaterOrEquals = 2,
  Lesser = 3,
  LesserOrEquals = 4,
  NotEquals = 5,
  Contains = 6,
  Between = 7,
  RangeLessThanEnd = 8,
  StartsWith = 9,
}

export interface AdvancedFilter {
  MustExist: boolean;
  FilterField: string;
  FilterOp: AdvancedFilterOperations;
  Match1: any;
  Match2: any;
  Nullable: boolean;
}

export interface RadGrid_FilterAndSort {
  Filters: AdvancedFilter[];
  SortByOrder: string;
  PageIndex: number;
  PageSize: number;
}

export interface PickListPacket {
  SelectedRows: number[];
  fas: RadGrid_FilterAndSort;
  Init_FilterAndSort: boolean;
}

export interface ServiceStandardPickListPacket extends PickListPacket {
  ServiceStandardTypeFilter: CS_ServiceStandardType | null;
  AllowClear: boolean;
  Flag_CRMSChoice: boolean | null;
  Flag_ITSupport: boolean | null;
  Flag_Payroll: boolean | null;
  IsAnimal: boolean | null;
  IsKennels: boolean | null;
  Flag_System: boolean | null;
  Flag_ExternalUser: boolean | null;
  Flag_HardRubbish: boolean | null;
  Flag_HardRubbishPicker: boolean;
  Flag_DeactivatedOrgStructure: boolean | null;
  ACRecordType_ENUM: ACCESSCONTROLRECORDTYPE | null;
  BulletinBoard_Id: number | null;
  Flag_InternetChoice: boolean | null;
  Flag_InformationOnly: boolean | null;
  IsHealth: boolean | null;
  HasWasteWater: boolean | null;
  IsLocalLaws: boolean | null;
  IsTownPlanning: boolean | null;
  IsBuilding: boolean | null;
  IsDisabilityWorker: boolean | null;
  IsParkingPermit: boolean | null;
  IsInfringement: boolean | null;
  HasControlledSubstances: boolean | null;
  IsLandMangement: boolean | null;
}

export interface NewServiceStandardCloneParams {
  id?: number;
  isClone: boolean;
}

export interface NewServiceStandardFromSubCategoryParams {
  ServiceStandardSubCategory_Id?: number;
  ServiceStandardCategory_Id?: number;
}

export interface StandardDocuments {
  ID: number;
  DisplayRank: number;
  Title: string;
  FileName: string;
  FileNumber: string;
  ContentType: string;
  RecordedBy: string;
  RecordedDate: Date;
  Sys_CreatedDate: Date;
  DeactivationDate: Date;
  IsBubbleUp: boolean;
  IsHidden: boolean;
  Tag: string;
  ParentDesc: string;
  ServiceDirection: boolean | null;
  DocumentID: number;
}
