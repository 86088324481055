import { getCertificateAdverseAffection } from "@app/products/property/certificates/[id]/components/child-screens/adverse-affections/api";
import { colAdverseAffection } from "@app/products/property/certificates/[id]/components/child-screens/adverse-affections/config";
import { DTO_AdverseAffection } from "@app/products/property/certificates/[id]/components/child-screens/adverse-affections/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { IColumnFields } from "@components/cc-grid/model";
import { isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class CertificateAdverseAffectionsStore {
  private _isLoading: boolean = false;
  private _data: DTO_AdverseAffection[] = [];
  private _colAdverseAffectionProcess: IColumnFields[] = colAdverseAffection;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get colAdverseAffectionProcess() {
    return this._colAdverseAffectionProcess;
  }
  setColAdverseAffectionProcess = (columnFields: IColumnFields[]) => {
    runInAction(() => {
      this._colAdverseAffectionProcess = columnFields;
    });
  };

  get data() {
    return toJS(this._data);
  }
  setData = (data: DTO_AdverseAffection[]) => {
    runInAction(() => {
      this._data = data ?? [];
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  loadViewsAndData = async (certificateId?: number) => {
    if (isNil(certificateId)) return;
    this.setIsLoading(true);
    let errorResponse = undefined;
    const response = await getCertificateAdverseAffection(certificateId);

    if (
      Array.isArray(response) &&
      response?.[0]?.data &&
      response?.[1]?.data &&
      !response?.[0]?.data?.hasOwnProperty("MessageType") &&
      !response?.[1]?.data?.hasOwnProperty("MessageType")
    ) {
      const [viewConfig, dataAdverse] = response;
      if (viewConfig?.data) {
        this.setColAdverseAffectionProcess(
          processDynamicColumns(
            colAdverseAffection,
            viewConfig?.data?.ColumnDefinitions?.Columns ?? []
          ) ?? []
        );
      }
      if (dataAdverse?.data) {
        const newData = dataAdverse?.data?.AdverseAffections?.map(
          (item: DTO_AdverseAffection, index: number) => ({
            ...item,
            Id: index + 1,
          })
        );
        this.setData(newData);
      }
    } else {
      errorResponse = {
        status: APIResponseStatus.BAD_REQUEST,
        error: "Load view configuration and data failed.",
      } as APIResponseError;
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._data = [];
    });
  };
}

const certificateAdverseAffectionsStoreContext = createContext(
  new CertificateAdverseAffectionsStore()
);
export const useCertificateAdverseAffectionsStore = () => {
  return useContext(certificateAdverseAffectionsStoreContext);
};
