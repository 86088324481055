/* eslint-disable no-useless-computed-key */
import { getDisplayTextWithDashes } from "@common/utils/common";
import { getter } from "@progress/kendo-data-query";

const animalTypeGetter = getter("animalType");
const dobGetter = getter("dob");
const genderGetter = getter("gender");
const microchipNumberGetter = getter("microchipNumber");
const tagNoGetter = getter("tagNo");
const addressGetter = getter("registeredAddress");
const ownerGetter = getter("owner");
const pensionNumberGetter = getter("PensionNumber");

export const localLawsFormValidator: any = (values: any): any => {
  if (Object.keys(values).length > 0) {
    return {};
  }

  if (
    animalTypeGetter(values) ||
    dobGetter(values) ||
    genderGetter(values) ||
    microchipNumberGetter(values) ||
    tagNoGetter(values) ||
    addressGetter(values) ||
    ownerGetter(values) ||
    pensionNumberGetter(values)
  ) {
    return {};
  }

  return {
    VALIDATION_SUMMARY: "Please fill atleast one of the following fields.",
    ["animalType"]: "You must select an existing Animal Type",
    ["dob"]: "You must select a Date Of Birth",
    ["gender"]: "You must select a Gender",
    ["microchipNumber"]: "You must enter Microchip Number",
    ["tagNo"]: "You must enter Tag Number",
    ["registeredAddress"]: "You must enter Registered Address",
    ["owner"]: "You must enter Animal Owner",
  };
};

export const LLBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `Local Laws ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      //don't have PermitHolder in list view mock data, require when integrate API
      selectedRow?.PermitHolder ?? "No Name",
      selectedRow?.Type,
    ]);
  },
};
