import { isString } from "lodash";
import React, { ReactElement, useCallback, useMemo } from "react";
import "./_index.scss";
export interface ICCPanelCustomHeaderProps {
  onToggle: () => void;
  isExpanded?: boolean;
}
export interface ICCPanelProps {
  title?: string | ReactElement;
  badge?: string | ReactElement;
  subTitle?: string | ReactElement;
  customHeader?: (headerProps: ICCPanelCustomHeaderProps) => ReactElement;
  component?: ReactElement;
  className?: string;
  isExpanded?: boolean;
  isDisabled?: boolean;
  isVisible?: boolean;
  isInvalid?: boolean;
  isKeepMounted?: boolean;
  onToggle?: () => void;
}

export const CCPanel = ({
  title,
  badge,
  subTitle,
  customHeader,
  component,
  className,
  isExpanded,
  isDisabled = false,
  isVisible = true,
  isInvalid = false,
  isKeepMounted: keepItemsMounted = false,
  onToggle,
}: ICCPanelProps) => {
  const handleOnClickHeader = useCallback(() => {
    if (isDisabled) return;
    if (onToggle) onToggle();
  }, [onToggle, isDisabled]);

  const getTitle = useCallback(
    (value?: string | ReactElement) => (isString(value) ? value : undefined),
    []
  );
  const panelClassName = useMemo(() => {
    let panelClass = "";
    if (className) panelClass += `${className} `;
    panelClass += `cc-panel`;
    if (!isExpanded) panelClass += ` cc-panel-collapsed`;
    if (isDisabled) panelClass += ` cc-panel-disabled`;
    if (isInvalid) panelClass += ` cc-panel-invalid`;

    return panelClass;
  }, [className, isExpanded, isInvalid, isDisabled]);

  if (!isVisible) return null;
  return (
    <div className={panelClassName}>
      {customHeader ? (
        <div className="cc-panel-custom-header">
          {customHeader({
            onToggle: handleOnClickHeader,
            isExpanded,
          })}
        </div>
      ) : (
        <div className="cc-panel-header" onClick={handleOnClickHeader}>
          <div className="cc-panel-header-left">
            {title && (
              <div className="cc-panel-header-title" title={getTitle(title)}>
                {title}
              </div>
            )}
            {badge && (
              <div className="cc-panel-header-badge" title={getTitle(badge)}>
                {badge}
              </div>
            )}
          </div>
          <div className="cc-panel-header-right">
            {subTitle && (
              <div
                className="cc-panel-header-sub-title"
                title={getTitle(subTitle)}
              >
                {subTitle}
              </div>
            )}
          </div>
        </div>
      )}
      <div className={`cc-panel-body`}>
        {isExpanded || keepItemsMounted ? component : null}
      </div>
    </div>
  );
};
