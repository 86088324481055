import { intersectionBy, isEmpty, isNil } from "lodash";

export const amountValidator = (value: any) => {
  if (isNil(value) || isNaN(value)) return "This field is required.";
};

export const formValidatorAssess = (
  values: any,
  assessmentLowercaseLabel: string
) => {
  if (!values || !values?.Assessments?.length) {
    return `At least one ${assessmentLowercaseLabel} is required to proceed.`;
  }
  return undefined;
};

export const checkClassificationExisted = (
  transactionTypeClassification: string = "",
  typeClassificationMode: string[] = []
): boolean => {
  if (isEmpty(transactionTypeClassification) || isEmpty(typeClassificationMode))
    return false;
  const listClassification = transactionTypeClassification.split(",") ?? [];
  const getListIntersectionClassification =
    intersectionBy(listClassification, typeClassificationMode) ?? [];
  return getListIntersectionClassification?.length > 0 ? true : false;
};
