import {
  apiCoreCheckIsSuperAdmin,
  apiCoreGetNotice,
  apiCoreGetNoticeTypesById,
  apiCoreGetNoticesLOVs,
  apiCoreSaveNotice,
} from "@app/core/components/common/utils";
import { Notice, NoticeType, NoticesLovs } from "@app/core/notices/[id]/model";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";

export const getNoticesById = async (
  noticeID: number
): Promise<APIResponse<IIdentityPacket<Notice | undefined>>> => {
  try {
    return await CoreAPIService.getClient().get<IIdentityPacket<Notice>>(
      apiCoreGetNotice(noticeID)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNoticesLOVs = async (
  bubbleUpsParent: BubbleUpIdentifier[] | undefined
): Promise<APIResponse<IIdentityPacket<NoticesLovs | undefined>>> => {
  try {
    return await CoreAPIService.getClient().post<IIdentityPacket<NoticesLovs>>(
      apiCoreGetNoticesLOVs(),
      bubbleUpsParent
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNoticesTypeById = async (
  noticeTypeID: number
): Promise<APIResponse<IIdentityPacket<NoticeType | undefined>>> => {
  try {
    return await CoreAPIService.getClient().get<IIdentityPacket<NoticeType>>(
      apiCoreGetNoticeTypesById(noticeTypeID)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNotice = async (
  notice: Notice
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreSaveNotice(),
      notice
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postCheckIsSuperAdmin = async (
  userID: number
): Promise<APIResponse<boolean>> => {
  try {
    return await CoreAPIService.getClient().post<any>(
      apiCoreCheckIsSuperAdmin(userID)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
